import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Tabs, Tab, Accordion, Redirect
}
from '../../noser-hris-component';
import {DropdownButton, Dropdown} from 'react-bootstrap';

import BlacklistModal from './Modal/BlacklistModal';

class BackgroundInvestigationCreate extends Component {
    constructor(props) {
        super(props);
        this.minDate = new moment(props.minDate)
        this.state = {
            startDate: new Date(),
            isloading:false,
            isshow:false,
            alerttype:"",
            message:"",
            color:"",
            fade:true,
            navigate    : false,

            clientDDL : [],
            clientId : "",
            client : "",
            memberName : "",
            positionDDL : [],
            position : "",
            positionId : "",
            date : "",
            subjectRelationship : "",
            subjectKnownDDL:[
                {"name": 'Select How Long Have You Known The Subject',  "value": "",},
                {"name":"1 To 6 Mos","value":"1 To 6 Mos"},
                {"name":"7 To 12 Mos","value":"7 To 12 Mos"},
                {"name":"1 Year","value":"1 Year"},
            ],
            subjectKnown : "",
            familyKnownYes : false,
            familyKnownNo : false,
            hasBadRecordYes : false,
            hasBadRecordNo : false,
            checkHasCrimeYes : false,
            checkHasCrimeNo : true,
            isDisabledHasCrime : true,
            natureOfCrime : "",
            checkVicesYes : false,
            checkVicesNo : true,
            disableKindOfVices : true,
            kindOfVices : "",
            checkSpouseWorkingYes : false,
            checkSpouseWorkingNo : true,
            isDisabledSpouse : true,
            spouseCompanyName : "",
            childrenDDL:[
                {"name": 'Select If The Subject Is Married, How Many Children Does She/he Have',  "value": "",},
                {"name":"1 - 3?","value":"1 - 3?"},
                {"name":"4 - 6?","value":"4 - 6?"},
            ],
            children : "",
            spouseJobNature : "",
            checkHouseOwn : true,
            checkSharer : false,
            checkRenter : false,
            disableAmountOfRent : true,
            amountOfRent : "",
            checkRecommendYes : true,
            checkRecommendNo : false,
            disableSubjectRecommended : true,
            stateReason : "",
            howLongInPreviousJob : "",
            lastJob : "",
            bestQualities : "",
            statusUponResignation : "",
            companyRecognition : "",
            subjectCommitYes : false,
            subjectCommitNo : true,
            subjectReceive : "",
            disableSubjectCommit : true,
            workingRelationship : "",
            supervisorAndPosition : "",
            recommendSubject : "",
            subjectForRehire : "",
            resourceName : "",
            resourceCNumber : "",
            resourceAddress : "",
            disableResource : true,
            resourceGrid : [],
            investigationSummary : "",
            ciRecommendation : "",

            investigationSummaryGrid: [
                {"name":"NO BAD RECORD","id":"1"},
                {"name":"WITH BAD RECORD","id":"1"},
            ],
            selectedInvestigationSummary: "",
            selectedInvestigationSummaryId: "",
            cIRecommendationGrid: [
                {"name":"RECOMMENDED","id":"1"},
                {"name":"NOT RECOMMENDED","id":"1"},
            ],
            selectedcIRecommendation: "",
            selectedcIRecommendationId: "",

            data : JSON.parse(sessionStorage.getItem("applicationformbackground_")),

            openModal:false
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.data = JSON.parse(sessionStorage.getItem("applicationformbackground_" + this.state.userinfo.userId + this.state.userinfo.employeeId))
        
        this.state.applicationId   =   this.state.data.id
        this.state.profileId       =   this.state.data.profileId
        this.state.clientName      =   this.state.data.client
        this.state.clientId        =   this.state.data.clientId
        this.state.memberName      =   this.state.data.employeeName
        this.state.position        =   this.state.data.position
        this.state.positionId      =   this.state.data.positionId
       // this.state.address          = this.state.data.applicantAddresses[0].houseNumber + ", " + this.state.data.applicantAddresses[0].streetName+ ", " + this.state.data.applicantAddresses[0].barangay + ", " + this.state.data.applicantAddresses[0].city + ", " + this.state.data.applicantAddresses[0].province
        this.state.assessedBy = this.state.userinfo.fullName
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.GetApplicationFormDetails();
    }
    handleModalShow = (e) =>{
        let obj = {
            "applicationFormId":this.state.applicationId,
            "positionId":this.state.positionId,
            "clientId" : this.state.clientId,
            "accountId" : "",
            "areaId":this.state.userinfo.areaId,
            "aquisitionId" : "",
            "statusId" : e,
        }
        this.setState({openModal:true})
        this.child.initialize(obj)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
    };
    GetApplicationFormDetails = async()=> {
       //this.setState({isloading:true})

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ApplicationId" : this.state.applicationId,
        };
        console.log("applicationParams")
        console.log(params)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationFormDetails",  params)
            .then(res => {
            const data = res.data;
            console.log("GetApplicationFormDetails")
            console.log(data)
            if(data.status==="1"){
                this.setState({
                    address :    data.applicantAddresses[0]["houseNumber"] + ", " +
                                        data.applicantAddresses[0]["streetName"] + ", " + 
                                        data.applicantAddresses[0]["barangay"] + ", " + 
                                        data.applicantAddresses[0]["city"] + ", " + 
                                        data.applicantAddresses[0]["province"] + ", " +
                                        data.applicantAddresses[0]["region"]
                })
            }
            if(data.status=="0"){
               this.setState({
                   isloading   :   false,
                   alerttype   :   "Error!",
                   isshow      :   true,
                   color       :   "danger",
                   message     :   data.message,
                   fade        :   true
               });
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleChangeDate = date => {
        this.setState({
            date: date,
            isshow:false,
        })
    }

    handleChangeSubjectKnownDDL  = (e) =>{
        this.setState({subjectKnown:e.target.value,isshow:false,})
    }

    handleChangeChildren  = (e) =>{
        this.setState({children:e.target.value,isshow:false,})
    }

    handleChangeInvestigationSummary= (e) => {
        ////console.log(e)
        // selectedInvestigationSummary: "",
        //     selectedInvestigationSummaryId: "",
            if (e.length > 0) {
            this.state.selectedInvestigationSummary = e[0].name
            this.state.selectedInvestigationSummaryId = e[0].id
        } else {
            this.state.selectedInvestigationSummary = ""
            this.state.selectedInvestigationSummaryId = ""
        }
        /* this.setState({
            isshow:false,
        }) */
    };

    handleChangecIRecommendation= (e) => {
        ////console.log(e)
        // selectedInvestigationSummary: "",
        //     selectedInvestigationSummaryId: "",
            if (e.length > 0) {
            this.state.selectedcIRecommendation = e[0].name
            this.state.selectedcIRecommendationId = e[0].id
        } else {
            this.state.selectedcIRecommendation = ""
            this.state.selectedcIRecommendationId = ""
        }
        /* this.setState({
            isshow:false,
        }) */
    };
    
    onChangeFamilySubjectYes  = (e) =>{
        this.state.familyKnownYes = e.target.checked
        if(this.state.familyKnownYes == true) {
            this.setState({
                familyKnownNo  :   false,
            })
        }
    }
    
    onChangeFamilySubjectNo  = (e) =>{
        this.state.familyKnownNo = e.target.checked
        if(this.state.familyKnownNo == true) {
            this.setState({
                familyKnownYes  :   false,
            })
        }
    }
    
    onChangeHasBadRecordYes  = (e) =>{
        this.state.hasBadRecordYes = e.target.checked
        if(this.state.hasBadRecordYes == true) {
            this.setState({
                hasBadRecordNo  :   false,
            })
        }
    }
    
    onChangeHasBadRecordNo  = (e) =>{
        this.state.hasBadRecordNo = e.target.checked
        if(this.state.hasBadRecordNo == true) {
            this.setState({
                hasBadRecordYes  :   false,
            })
        }
    }
    
    onChangeHasCrimeYes  = (e) =>{
        this.state.checkHasCrimeYes = e.target.checked
        if(this.state.checkHasCrimeYes == true) {
            this.setState({
                checkHasCrimeNo  :   false,
                isDisabledHasCrime :   false,
            })
        }
    }
    
    onChangeHasCrimeNo  = (e) =>{
        this.state.checkHasCrimeNo = e.target.checked
        if(this.state.checkHasCrimeNo == true) {
            this.setState({
                HasCrime  :   true,
                checkHasCrimeYes  		  :   false,
		        natureOfCrime : "",
                isDisabledHasCrime :   true,
            })
        }
    }
    
    onChangeHasVicesYes  = (e) =>{
        this.state.checkVicesYes = e.target.checked
        if(this.state.checkVicesYes == true) {
            this.setState({
                checkVicesNo  :   false,
                disableKindOfVices :   false,
            })
        }
    }
    
    onChangeHasVicesNo  = (e) =>{
        this.state.checkVicesNo = e.target.checked
        if(this.state.checkVicesNo == true) {
            this.setState({
                disableKindOfVices  :   true,
                checkVicesYes  		  :   false,
		        kindOfVices : "",
            })
        }
    }
    
    onChangeSpouseWorkingYes  = (e) =>{
        this.state.checkSpouseWorkingYes = e.target.checked
        if(this.state.checkSpouseWorkingYes == true) {
            this.setState({
                checkSpouseWorkingNo     :   false,
                isDisabledSpouse :   false,
            })
        }
    }
    
    onChangeSpouseWorkingNo  = (e) =>{
        this.state.checkSpouseWorkingNo = e.target.checked
        if(this.state.checkSpouseWorkingNo == true) {
            this.setState({
                checkSpouseWorkingYes  		  :   false,
                isDisabledSpouse  		  :   true,
		        children : "",
		        spouseCompanyName : "",
		        spouseJobNature : "",
            })
        }
    }
    
    onChangeHouseOwn  = (e) =>{
        this.state.checkHouseOwn = e.target.checked
        if(this.state.checkHouseOwn == true) {
            this.setState({
                checkSharer  :   false,
                checkRenter  :   false,
		        disableAmountOfRent : true,
                amountOfRent : "",
            })
        }
    }
    
    onChangeSharer  = (e) =>{
        this.state.checkSharer = e.target.checked
        if(this.state.checkSharer == true) {
            this.setState({
                checkHouseOwn  :   false,
                checkRenter  :   false,
		        disableAmountOfRent : true,
                amountOfRent : "",
            })
        }
    }
    
    onChangeRenter  = (e) =>{
        this.state.checkRenter = e.target.checked
        if(this.state.checkRenter == true) {
            this.setState({
                checkHouseOwn  :   false,
                checkSharer  :   false,
		        disableAmountOfRent : false,
            })
        }
    }
    
    onChangeRecommendYes  = (e) =>{
        this.state.checkRecommendYes = e.target.checked
        if(this.state.checkRecommendYes == true) {
            this.setState({
                checkRecommendNo  :   false,
                disableSubjectRecommended : true,
                stateReason : "",
            })
        }
    }
    
    onChangeRecommendNo  = (e) =>{
        this.state.checkRecommendNo = e.target.checked
        if(this.state.checkRecommendNo == true) {
            this.setState({
                checkRecommendYes  		  :   false,
                disableSubjectRecommended : false,
            })
        }
    }
    
    onChangeSubjectCommitYes  = (e) =>{
        this.state.subjectCommitYes = e.target.checked
        if(this.state.subjectCommitYes == true) {
            this.setState({
                subjectCommitNo  :   false,
                disableSubjectCommit : false,
                subjectReceive : "",
            })
        }
    }
    
    onChangeSubjectCommitNo  = (e) =>{
        this.state.subjectCommitNo = e.target.checked
        if(this.state.subjectCommitNo == true) {
            this.setState({
                subjectCommitYes  		  :   false,
                disableSubjectCommit : true,
            })
        }
    }

    onChangeAmountOfRent = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({amountOfRent: e.target.value,isshow:false})
        }
    };

    onChangeResourceCNumber = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({resourceCNumber: e.target.value,isshow:false})
        }
    };

    handleChange  = (e) =>{
        this.setState({[e.target.name]:e.target.value,isshow:false,})
    }

    handleAddResource = (e) =>{
        let resourceGrid = this.state.resourceGrid

        let today = new Date()
        let newId = moment(today).format('HHMMSS');
        const obj = {
            "id":newId.toString(),
            "name"          :  this.state.resourceName,
            "contactNumber" :   this.state.resourceCNumber,
            "address"    :   this.state.resourceAddress, 
            "isDeleted"    :   "0",  
        }
        resourceGrid.push(obj)      
        this.setState({
            resourceGrid:resourceGrid,
            isshow:false,
            resourceName : "",
            resourceCNumber : "",
            resourceAddress : "",
        })
        console.log(resourceGrid)
    }
    handleRemoveResource = (e) =>{
        let resourceGrid = this.state.resourceGrid.filter(x=>x.isDeleted=="0")
        this.state.resourceGrid = []
        this.setState({resourceGrid:resourceGrid,disableResource: resourceGrid.length===0 ? true : false})
    }

    ResourceGridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.resourceGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
        this.setState({disableResource:disable})
    }

    
    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    handleClickStatus = (statusId) => {

        let neweresourceGrid = []
        this.state.resourceGrid.map(function(itm,i) {
            neweresourceGrid.push({
                "Name"       :   itm.name,
                "Address" :   itm.address,
                "ContactNumber" :   itm.contactNumber,
            })
        }) 
        this.setState({isloading : true,});

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "StatusId":statusId,
            "ApplicationFormId" : this.state.applicationId,
            "DateInvestigation" :this.FormatDate(this.state.date),
            "SubjectKnown " : this.state.subjectRelationship,
            "HowLongSubjectKnown " : this.state.subjectKnown,
            "IsFamilyKnown " : (this.state.familyKnownYes)? "1" : "0",
            "HasBadRecord " : (this.state.hasBadRecordYes)? "1" : "0",
            "HasCrime " : (this.state.checkHasCrimeYes)? "1" : "0",
            "NatureOfCrime " : this.state.natureOfCrime,
            "HasVices " : (this.state.checkVicesYes)? "1" : "0",
            "Children " : this.state.children,
            "IsSpouseWorking " : (this.state.checkSpouseWorkingYes)? "1" : "0",
            "SpouseJobNature " : this.state.spouseJobNature,
            "SpouseCompanyName " : this.state.spouseCompanyName,
            "DoesSubjectOwnHouse " : (this.state.checkHouseOwn)? "1" : "0",
            "DoesSubjectRentHouse " : (this.state.checkRenter)? "1" : "0",
            "RentCost" : this.state.amountOfRent,
            "IsSubjectRecommended " : (this.state.checkRecommendYes)? "1" : "0",
            "IfNoStateReason " : this.state.stateReason,
            "LastJob " : this.state.lastJob,
            "HowLongInPreviousJob " : this.state.howLongInPreviousJob,
            "EmploymentStatusUponResignation " : this.state.statusUponResignation,
            "BestQualities " : this.state.bestQualities,
            "PreviousCompanyRecognition " : this.state.companyRecognition,
            "PreviousCompanyViolation " : this.state.companyViolation,
            "NatureOfViolation " : this.state.subjectReceive,
            "WorkingRelationship " : this.state.workingRelationship,
            "ImmediateSupervisorAndPosition " :this.state.supervisorAndPosition,
            "RecommendSubject " : this.state.recommendSubject,
            "SubjectForRehire " : this.state.subjectForRehire,
            "InvestigationSummary " : this.state.selectedInvestigationSummary,
            "CIRecommendation " : this.state.selectedcIRecommendation,
            "PreparedBy " : this.state.userinfo.userId,
            "NotedBy " :"0",     
            "resourcePersons" : neweresourceGrid
        }
        console.log("params")
        console.log(params)
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/AddInvestigation", params)
            .then(res => {
                const data = res.data;
                //console.log("Get submit");
                //console.log(data)
                if(data.status=="1"){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true,
                        navigate    :   true,
                    });
                }else {
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
            })
            .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };

    handleSubmit = async (StatusId) =>{
        this.setState({isloading:true,disableBtn:true})

        const param = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ApplicationFormId":this.state.applicationId,
            "PositionId":this.state.positionId,
           /// "IsPooled":ispooled,

            /* "ClientId": "1",
            "IpAddress": "0.0.0.0",
            "UserId": "1",
            "ApplicationFormId": "",
            "PositionId": "" */
            "StatusId": StatusId, /* --> blacklisted - 12, process - 3  */

            "EducationalId":this.state.educationId,
            "IsFreshGrad":(this.state.freshGraduate)? "1" : "0",
            "WorkExperienceMonths":this.state.workExperienceMonths,
            "WorkExperienceYears":this.state.workExperienceYear,
            "WithWorkExperience":(this.state.withWorkExperience)? "1" : "0",
            "BodyBuiltId":this.state.bodyId,
            "HairId":this.state.hairId,
            "ComplexionId":this.state.complexionId,
            "PostureId": this.state.postureId,
            "PersonalityId":this.state.personalityId,
            "MentalId":this.state.mentalId,
            "WorkExperienceId":this.state.professionalId,
            "TechnicalExperienceId":this.state.technicalId,
            "SupportGroupId":this.state.supportGroupId,
            "AssessmentId":this.state.assessmentId,
            "Comments":this.state.comments,
            "RecommendationId":this.state.preFinalId,
            "AssessedBy":this.state.userinfo.userId,
            "StoreOperationId":this.state.storeId,
        };
        console.log("param")
        console.log(param)

        
        await axios
        .post(
            AppConfiguration.Setting().noserapiendpoint + "Pooling/SubmitPooling",  param
        )
        .then(res => {
            const data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                name        :   ''
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
                name        :   ''
            })
        })
    };
    handleConfirm=()=>{
        this.setState({isShow:true})
    };
    handleClose=()=>{
        this.setState({isShow:false, pooling : true})
    }
    
    
    render() {
        const { navigate } = this.state;
        if (navigate) {
            return <Redirect to="/applicationform" push={true} />;
        }

        const resourceColumn = [
            {
                dataField: 'name',
                text: 'Name',
                headerStyle: () => {
                    return { width: "40%" };
                  }
            },
            {
                dataField: 'address',
                text: 'Address',
                headerStyle: () => {
                    return { width: "40%" };
                  }
            },
            {
                dataField: 'contactNumber',
                text: 'Contact Number',
                headerStyle: () => {
                    return { width: "20%" };
                  }
            },
        ]

        const resourceSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.resourceGrid.map(function(item,i){
                    if(item.id===row.id)
                        item.isDeleted = isSelect ? "1" : "0"
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableResource:isDisable})
            },
            onSelectAll: (isSelect, rows) => {
                let isDisable = true
                this.state.resourceGrid.map(function(itm){
                    itm.isDeleted=isSelect ? "1" : "0"
                    if(itm.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableResource:isDisable})
            }
        };
        return(
            <div>
                <Banner />
                <Container  className="mt-5" fluid>
                    <Card>
                        <Card.Header>RECRUITMENT >> BACKGROUND INVESTIGATION (CREATE) </Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                        <Typeahead 
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeClient}
                                            options={this.state.clientDDL}
                                            placeholder="Select Client" 
                                            defaultSelected={[this.state.clientName]}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                            <Form.Control 
                                                type="text"
                                                name="memberName"
                                                value={this.state.memberName}
                                                //onChange={this.onChangeLastName} 
                                                disabled 
                                            />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridCity">
                                        <Form.Label style={{fontWeight : "bold"}}>POSITION APPLIED</Form.Label>
                                        <Typeahead
                                            ref='clearPosition'
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangePosition}
                                            options={this.state.positionDDL}
                                            placeholder="Select Position" 
                                            defaultSelected={[this.state.position]}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold", marginLeft : 15}}>DATE</Form.Label>
                                        <Form.Group as={Col} controlId="formGridCity">
                                        <DatePicker
                                            ref='date'
                                            selected={this.state.date}
                                            onChange={this.handleChangeDate}
                                            minDate={this.minDate}
                                            value={this.state.date}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                            placeholderText="Select Date" 
                                        />
                                        </Form.Group>
                                    </Form.Group>
                                </Form.Row>
                                <Tabs className="mt-2" defaultActiveKey="default" transition={false} id="noanim-tab-example">
                                    <Tab eventKey="default" title="I. NEIGHBORHOOD CHECKING">
                                        <Card className="card-tab-no-border">
                                            <Card.Body>
                                                <Form.Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>WHAT IS YOUR RELATIONSHIP WITH THE SUBJECT ?</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="subjectRelationship"
                                                            value={this.state.subjectRelationship}
                                                            onChange={this.handleChange}  
                                                            //onChange={this.onChangeSubjectRelationship} 
                                                            autoComplete="off"
                                                            placeholder="Enter What Is Your Relationship With The Subject" 
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>HOW LONG HAVE YOU KNOWN THE SUBJECT ?</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.handleChangeSubjectKnownDDL}
                                                                value={this.state.subjectKnown}
                                                            >
                                                            {this.state.subjectKnownDDL.map(item => (
                                                                <option key={item.value} value={item.value}>
                                                                {item.name}
                                                                </option>
                                                            ))}
                                                            </Form.Control>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row className="mt-3">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>DOES THE FAMILY OF THE SUBJECT KNOWN TO THE RESOURCE PERSON ?</Form.Label>
                                                        <Form.Check
                                                            inline
                                                            type="checkbox" 
                                                            label="YES" 
                                                            name="familyKnownYes"
                                                            checked={this.state.familyKnownYes}
                                                            onChange={this.onChangeFamilySubjectYes}
                                                            style={{fontWeight : "bold", marginLeft : 12}}
                                                        />
                                                        <Form.Check 
                                                            inline
                                                            type="checkbox" 
                                                            label="NO" 
                                                            name="familyKnownNo"
                                                            checked={this.state.familyKnownNo}
                                                            onChange={this.onChangeFamilySubjectNo}
                                                            style={{fontWeight : "bold", marginLeft : 12}}
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row className="mt-3">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>IS THE SUBJECT KNOWN FOR ANY BAD RECORD IN THE COMMUNITY ?</Form.Label>
                                                        
                                                        <Form.Check
                                                            inline 
                                                            type="checkbox" 
                                                            label="YES" 
                                                            name="hasBadRecordYes"
                                                            checked={this.state.hasBadRecordYes}
                                                            onChange={this.onChangeHasBadRecordYes}
                                                            style={{fontWeight : "bold", marginLeft : 12}}
                                                        />
                                                        <Form.Check 
                                                            inline
                                                            type="checkbox" 
                                                            label="NO" 
                                                            name="hasBadRecordNo"
                                                            checked={this.state.hasBadRecordNo}
                                                            onChange={this.onChangeHasBadRecordNo}
                                                            style={{fontWeight : "bold", marginLeft : 12}}
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row className="mt-3">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>INVOLVEMENT IN CRIME OR SOCIAL OFFENSE ?</Form.Label>
                                                    <Form.Check
                                                        inline
                                                        type="checkbox" 
                                                        label="YES" 
                                                        name="checkHasCrimeYes"
                                                        checked={this.state.checkHasCrimeYes}
                                                        onChange={this.onChangeHasCrimeYes}
                                                        style={{fontWeight : "bold", marginLeft : 12}}
                                                    />
                                                    <Form.Check 
                                                        inline
                                                        type="checkbox" 
                                                        label="NO" 
                                                        name="checkHasCrimeNo"
                                                        checked={this.state.checkHasCrimeNo}
                                                        onChange={this.onChangeHasCrimeNo}
                                                        style={{fontWeight : "bold", marginLeft : 12}}
                                                    />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>IF YES, NATURE OF CRIME OR OFFENSE</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="natureOfCrime"
                                                            value={this.state.natureOfCrime}
                                                            onChange={this.handleChange}  
                                                            //onChange={this.onChangeNatureOfCrime}
                                                            disabled = { this.state.isDisabledHasCrime}
                                                            autoComplete="off"
                                                            placeholder="Enter IF YES, NATURE OF CRIME OR OFFENSE" 
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row className="mt-4">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>IF THE SUBJECT KNOWN FOR VICES LIKE GAMBLING, DRINKING, USE OF ILLEGAL DRUGS ?</Form.Label>
                                                    <Form.Check
                                                        inline
                                                        type="checkbox" 
                                                        label="YES" 
                                                        name="checkVicesYes"
                                                        checked={this.state.checkVicesYes}
                                                        onChange={this.onChangeHasVicesYes}
                                                        style={{fontWeight : "bold", marginLeft : 12}}
                                                    />
                                                    <Form.Check 
                                                        inline
                                                        type="checkbox" 
                                                        label="NO" 
                                                        name="checkVicesNo"
                                                        checked={this.state.checkVicesNo}
                                                        onChange={this.onChangeHasVicesNo}
                                                        style={{fontWeight : "bold", marginLeft : 12}}
                                                    />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>IF YES, WHAT KIND OF VICES</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="kindOfVices"
                                                            value={this.state.kindOfVices}
                                                            onChange={this.handleChange}  
                                                            //onChange={this.onChangeKindOfVices}
                                                            disabled = { this.state.disableKindOfVices}
                                                            autoComplete="off"
                                                            placeholder="Enter IF YES, WHAT KIND OF VICES" 
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row className="mt-4">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>IF THE SUBJECT IS MARRIED, IS THE SPOUSE WORKING ?</Form.Label>
                                                    <Form.Check
                                                        inline
                                                        type="checkbox" 
                                                        label="YES" 
                                                        name="checkSpouseWorkingYes"
                                                        checked={this.state.checkSpouseWorkingYes}
                                                        onChange={this.onChangeSpouseWorkingYes}
                                                        style={{fontWeight : "bold", marginLeft : 12}}
                                                    />
                                                    <Form.Check 
                                                        inline
                                                        type="checkbox" 
                                                        label="NO" 
                                                        name="checkSpouseWorkingNo"
                                                        checked={this.state.checkSpouseWorkingNo}
                                                        onChange={this.onChangeSpouseWorkingNo}
                                                        style={{fontWeight : "bold", marginLeft : 12}}
                                                    />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>IF YES, COMPANY NAME OF SPOUSE</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="spouseCompanyName"
                                                            value={this.state.spouseCompanyName}
                                                            onChange={this.handleChange}  
                                                            //onChange={this.onChangeSpouseCompanyName}
                                                            autoComplete="off"
                                                            placeholder="Enter IF YES, COMPANY NAME OF SPOUSE" 
                                                            disabled = { this.state.isDisabledSpouse}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>IF THE SUBJECT IS MARRIED, HOW MANY CHILDREN DOES SHE/HE HAVE</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.handleChangeChildren}
                                                            value={this.state.children}
                                                            disabled = { this.state.isDisabledSpouse}
                                                        >
                                                        {this.state.childrenDDL.map(item => (
                                                            <option key={item.value} value={item.value}>
                                                            {item.name}
                                                            </option>
                                                        ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>IF YES, NATURE OF SPOUSE JOB</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="spouseJobNature"
                                                            value={this.state.spouseJobNature}
                                                            placeholder="Enter IF YES, NATURE OF SPOUSE JOB"
                                                            onChange={this.handleChange}  
                                                            //onChange={this.onChangeSpouseJobNature}
                                                            autoComplete="off"
                                                            disabled = { this.state.isDisabledSpouse}
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row className="mt-4">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>WHAT IS THE SUBJECT'S CURRENT HOME OWNERSHIP STATUS ?</Form.Label>
                                                        <Form.Check
                                                            inline
                                                            type="checkbox" 
                                                            label="HOUSE OWNER" 
                                                            name="checkHouseOwn"
                                                            checked={this.state.checkHouseOwn}
                                                            onChange={this.onChangeHouseOwn}
                                                            style={{fontWeight : "bold", marginLeft : 12}}
                                                        />
                                                        <Form.Check 
                                                            inline
                                                            type="checkbox" 
                                                            label="SHARER" 
                                                            name="checkSharer"
                                                            checked={this.state.checkSharer}
                                                            onChange={this.onChangeSharer}
                                                            style={{fontWeight : "bold", marginLeft : 12}}
                                                        />
                                                        <Form.Check 
                                                            inline
                                                            type="checkbox" 
                                                            label="RENTER" 
                                                            name="checkRenter"
                                                            checked={this.state.checkRenter}
                                                            onChange={this.onChangeRenter}
                                                            style={{fontWeight : "bold", marginLeft : 12}}
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>IF RENTER, HOW MUCH IS THE RENT ?</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="amountOfRent"
                                                            value={this.state.amountOfRent} 
                                                            onChange={this.onChangeAmountOfRent}
                                                            disabled = { this.state.disableAmountOfRent}
                                                            autoComplete="off"
                                                            placeholder="Enter IF RENTER, HOW MUCH IS THE RENT" 
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row className="mt-4">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>WILL YOU RECOMMEND THE SUBJECT FOR THE POSITION HE IS APPLYING WITH US ?</Form.Label>
                                                    <Form.Check
                                                        inline
                                                        type="checkbox" 
                                                        label="YES" 
                                                        name="checkRecommendYes"
                                                        checked={this.state.checkRecommendYes}
                                                        onChange={this.onChangeRecommendYes}
                                                        style={{fontWeight : "bold", marginLeft : 12}}
                                                    />
                                                    <Form.Check 
                                                        inline
                                                        type="checkbox" 
                                                        label="NO" 
                                                        name="checkRecommendNo"
                                                        checked={this.state.checkRecommendNo}
                                                        onChange={this.onChangeRecommendNo}
                                                        style={{fontWeight : "bold", marginLeft : 12}}
                                                    />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>IF NO, PLEASE STATE THE REASON ?</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="stateReason"
                                                            value={this.state.stateReason}
                                                            placeholder="SELECT IF NO, PLEASE STATE THE REASON"
                                                            onChange={this.handleChange}  
                                                            //onChange={this.onChangeStateReason}
                                                            disabled = { this.state.disableSubjectRecommended}
                                                            autoComplete="off"
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                            </Card.Body>
                                        </Card>  
                                    </Tab>
                                    
                                    <Tab eventKey="employmentchecking" title="II. EMPLOYMENT CHECKING">
                                        <Card className="card-tab-no-border">
                                            <Card.Body>
                                                <Form.Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>WHAT’S THE SUBJECT LAST’S JOB</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="lastJob"
                                                            value={this.state.lastJob}
                                                            onChange={this.handleChange}  
                                                            //onChange={this.onChangeLastJob}
                                                            autoComplete="off"
                                                            placeholder="Enter WHAT’S THE SUBJECT LAST’S JOB" 
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>HOW LONG HAD HE/SHE WORKED IN THE PREVIOUS JOB</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="howLongInPreviousJob"
                                                            value={this.state.howLongInPreviousJob}
                                                            onChange={this.handleChange}  
                                                            //onChange={this.onChangeHowLongInPreviousJob}
                                                            autoComplete="off"
                                                            placeholder="Enter HOW LONG HAD HE/SHE WORKED IN THE PREVIOUS JOB" 
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>WHAT WAS THE SUBJECT’S EMPLOYMENT STATUS AT THE TIME OF RESIGNATION</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="statusUponResignation"
                                                            value={this.state.statusUponResignation}
                                                            onChange={this.handleChange}  
                                                            //onChange={this.onChangeStatusUponResignation}
                                                            autoComplete="off"
                                                            placeholder="Enter WHAT WAS THE SUBJECT’S EMPLOYMENT STATUS AT THE TIME OF RESIGNATION" 
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>WHAT ARE THE SUBJECT’S BEST QUALITIES OR NOTABLE TRAITS</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="bestQualities"
                                                            value={this.state.bestQualities}
                                                            onChange={this.handleChange}  
                                                            //onChange={this.onChangeBestQualities}
                                                            autoComplete="off"
                                                            placeholder="Enter WHAT ARE THE SUBJECT’S BEST QUALITIES OR NOTABLE TRAITS" 
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>DID SUBJECT RECEIVE ANY DISTINGUISH MARK OR RECOGNITION DURING THIS ENTIRE STAY IN THE COMPANY</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="companyRecognition"
                                                            value={this.state.companyRecognition}
                                                            onChange={this.handleChange}  
                                                            //onChange={this.onChangeCompanyRecognition}
                                                            autoComplete="off"
                                                            placeholder="Enter DID SUBJECT RECEIVE ANY DISTINGUISH MARK OR RECOGNITION DURING THIS ENTIRE STAY IN THE COMPANY" 
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row className="mt-4">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>DID THE SUBJECT COMMIT ANY VIOLATIONS OF THE COMPANY POLICIES ?</Form.Label>
                                                        <Form.Check
                                                            inline
                                                            type="checkbox" 
                                                            label="YES" 
                                                            name="subjectCommitYes"
                                                            checked={this.state.subjectCommitYes}
                                                            onChange={this.onChangeSubjectCommitYes}
                                                            style={{fontWeight : "bold", marginLeft : 12}}
                                                        />
                                                        <Form.Check 
                                                            inline
                                                            type="checkbox" 
                                                            label="NO" 
                                                            name="subjectCommitNo"
                                                            checked={this.state.subjectCommitNo}
                                                            onChange={this.onChangeSubjectCommitNo}
                                                            style={{fontWeight : "bold", marginLeft : 12}}
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>IF YES, STATE THE NATURE OF OFFENSE COMMITTED</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="subjectReceive"
                                                            value={this.state.subjectReceive}
                                                            onChange={this.handleChange}  
                                                            //onChange={this.onChangeSubjectReceive}
                                                            autoComplete="off"
                                                            placeholder="Enter IF YES, STATE THE NATURE OF OFFENSE COMMITTED" 
                                                            disabled={this.state.disableSubjectCommit}
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>HOW WAS THE SUBJECT WORKING RELATIONSHIP WITH SUPERIORS AND CO - EMPLOYEES</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="workingRelationship"
                                                            value={this.state.workingRelationship}
                                                            onChange={this.handleChange}  
                                                            //onChange={this.onChangeWorkingRelationship}
                                                            autoComplete="off"
                                                            placeholder="Enter HOW WAS THE SUBJECT WORKING RELATIONSHIP WITH SUPERIORS AND CO - EMPLOYEES" 
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>WHO WERE THE SUBJECT’S IMMEDIATE SUPERVISOR AND THE POSITION</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="supervisorAndPosition"
                                                            value={this.state.supervisorAndPosition}
                                                            onChange={this.handleChange}  
                                                            //onChange={this.onChangeSupervisorAndPosition}
                                                            autoComplete="off"
                                                            placeholder="Enter WHO WERE THE SUBJECT’S IMMEDIATE SUPERVISOR AND THE POSITION" 
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>WOULD YOU RECOMMEND THE SUBJECT TO US</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="recommendSubject"
                                                            value={this.state.recommendSubject}
                                                            onChange={this.handleChange}  
                                                            autoComplete="off"
                                                            placeholder="Enter WOULD YOU RECOMMEND THE SUBJECT TO US" 
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>IF GIVEN THE CHANCE, ARE YOU WILLING TO RE-HIRE THE SUBJECT? IF YES, WHY? IF NO, WHY</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="subjectForRehire"
                                                            value={this.state.subjectForRehire}
                                                            onChange={this.handleChange}  
                                                            autoComplete="off"
                                                            placeholder="Enter IF GIVEN THE CHANCE, ARE YOU WILLING TO RE-HIRE THE SUBJECT? IF YES, WHY? IF NO, WHY" 
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    
                                    <Tab eventKey="resoueceperson" title="RESOURCE PERSONS">
                                        <Card className="card-tab-no-border">
                                            <Card.Body>
                                                <Form.Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>NAME</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="resourceName"
                                                            value={this.state.resourceName}
                                                            onChange={this.handleChange} 
                                                            autoComplete="off"
                                                            placeholder="Enter Name"
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>CONTACT NUMBER</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="resourceCNumber"
                                                            value={this.state.resourceCNumber}
                                                            onChange={this.onChangeResourceCNumber}
                                                            autoComplete="off"
                                                            placeholder="Enter CONTACT NUMBER" 
                                                            maxLength="11"
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>ADDRESS</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="resourceAddress"
                                                            value={this.state.resourceAddress}
                                                            onChange={this.handleChange}  
                                                            autoComplete="off"
                                                            placeholder="Enter Address"
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                <ButtonToolbar className="mt-3">
                                                    <Button className="noser-button-mr1" variant="success" onClick = { this.handleAddResource }>Add</Button>
                                                    <Button disabled={this.state.disableResource} className="noser-button" variant="danger" onClick = { this.handleRemoveResource }>REMOVE</Button>
                                                </ButtonToolbar>
                                                <div className="mt-1">  
                                                    <div>
                                                        <Card.Header>Resource List</Card.Header>
                                                        <BootstrapTable
                                                            keyField = "id"
                                                            data = { this.state.resourceGrid }
                                                            columns = { resourceColumn }
                                                            //pagination={ paginationFactory({sizePerPageRenderer}) }
                                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                            rowClasses="noser-table-row-class"
                                                            striped
                                                            hover
                                                            condensed
                                                            cellEdit = { cellEditFactory({
                                                                mode: 'dbclick',
                                                                blurToSave: true,
                                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                                    this.ResourceGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                    }
                                                                })
                                                            }
                                                            selectRow = { resourceSelectRow }
                                    
                                                        />
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    
                                    <Tab eventKey="investigation" title="III. INVESTIGATION SUMMARY">
                                        <Card className="card-tab-no-border">
                                            <Card.Body>
                                                <Form.Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>INVESTIGATION SUMMARY</Form.Label>
                                                        <Col sm="5">
                                                            <Typeahead
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangeInvestigationSummary}
                                                                options={this.state.investigationSummaryGrid}
                                                                //type="text" 
                                                            // disabled = { this.state.disableChildren}
                                                            /> 
                                                        </Col>
                                                    </Form.Group>
                                                </Form.Row>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    
                                    <Tab eventKey="ci" title="IV. CI RECOMMENDATION">
                                        <Card className="card-tab-no-border">
                                            <Card.Body>
                                                <Form.Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>CI RECOMMENDATION</Form.Label>
                                                        <Col sm="5">
                                                            <Typeahead
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangecIRecommendation}
                                                                options={this.state.cIRecommendationGrid}
                                                                //type="text" 
                                                            // disabled = { this.state.disableChildren}
                                                            /> 
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>ASSESSED BY</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="assessedBy"
                                                            value={this.state.assessedBy}
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                        
                                </Tabs>
                                <div isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isShow ? 'display-block' : 'display-none'}>
                                    <Card>
                                        <Card.Body>
                                        <h5 className='no-text-transform'>are you sure you want to blacklist this applicant?</h5>
                                        <ButtonToolbar >
                                            
                                            <Button variant="primary" className="noser-button-mr1 ml-auto" onClick = {() => this.handleSubmit('12')} disabled={this.state.disableBtn}>confirm</Button>
                                            
                                            <Button variant="danger" className="noser-button-mr15" onClick={this.handleClose}>Close</Button>
                                        </ButtonToolbar>
                                        </Card.Body>
                                    </Card>

                                </div>
                                <ButtonToolbar className="mt-3">
                                    {/* <Button className="ml-auto noser-button-mr1" variant="success" onClick = {() => this.handleClickStatus('1')}>Save</Button> */}
                                    <Button variant="warning" className="ml-auto noser-button-mr1" onClick = {() => this.handleModalShow('10')}>POOLING</Button>
                                    <Button variant="dark" className="noser-button-mr1" onClick = { () => this.handleModalShow('12')}>Blacklisted</Button>
                                    <Button className="noser-button-mr1"   variant="primary" onClick = {() => this.handleClickStatus('13')}>Submit</Button>                                    
                                    <NavLink to="/applicationform">
                                        <Button  className="noser-button noser-button-mr1" variant="danger">Back</Button>
                                    </NavLink>
                                </ButtonToolbar>
                                        
                               {/*  <ButtonToolbar className="mt-3">
                                    <Button variant="dark" className="ml-auto noser-button-mr1" onClick = {() => this.handleSubmit('12')}>POOLING</Button> &nbsp;
                                    <Button className="noser-button-mr1" variant="success" onClick={() => this.handleClickStatus('1')}>Save</Button>&nbsp;
                                    <Button className="noser-button-mr1" variant="primary" onClick={() => this.handleClickStatus('13')}>Submit</Button>&nbsp;
                                    <Button className="noser-button-mr1"  href="/applicationform" variant="danger">Back</Button>
                                </ButtonToolbar> */}
                            </Form>
                        </Card.Body>
                    </Card>
                    <div style={{height : 15}}></div>
                </Container>
                <NoserLoading show={this.state.isloading} />
                <BlacklistModal 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div>
        )
    }
}

export default BackgroundInvestigationCreate



