import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';

class TransferEmployee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            isDisable       :   false,

            employeeList    :   [],
            clientList      :   [],
            locationList    :   [],

            clientId        :   "",
            clientToId      :   "",
            clientName      :   "",
            fromLocationId  :   "",
            fromLocationName:   "",
            locationId      :   "",

            employeeId      :   "",
            profileId       :   "",
            client          :   "",
            clientTo        :   "",
            location        :   "",
            position        :   "",
            salary          :   "",
            contractdateStart:  "",
            contractdateEnd :  "",
        }
    } 
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients()
    }
    formatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
      }
    handleSubmit = (e) => {
        this.setState({isloading:true,isDisable:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.employeeId,
            "ProfileId":this.state.profileId,
            "Client":this.state.clientName,
            "ClientTo":this.state.clientTo,
            "Location":this.state.location,
            "Position":this.state.position,
            "SalaryOffered":this.state.salary,
            "ContractDateStart":this.state.contractdateStart,
            "ContractDateEnd":this.state.contractdateEnd,
            "ToClientId":this.state.clientToId,
            "FromLocationId":this.state.fromLocationId,
            "ToLocationId":this.state.locationId,
        }
        console.log(params)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/TransferEmployee", params)
        .then(res => {
            const data = res.data
            this.setState({
                isloading   :   false,
                alerttype   :   data.status=="1" ? "Success!" : "Error!",
                isshow      :   true,
                color       :   data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
                isDisable   :   true
            })
        })
    }
    handleChangeClient = (e) =>{
        this.setState({
            alerttype   :   "",
            isshow      :   false,
            color       :   "",
            message     :   "",
            fade        :   false
        });
        if(e.length==0)
        {
            this.state.clientId= ''
            return
        }
        this.state.clientId= e[0].id
        this.state.clientName = e[0].name
        this.state.isDisable = false
        this.GetEmployees()
    }
    handleChangeClientTo = (e) =>{
        this.setState({
            alerttype   :   "",
            isshow      :   false,
            color       :   "",
            message     :   "",
            fade        :   false
        });
        if(e.length==0)
        {
            this.state.clientToId= ''
            this.state.clientName= ''
            return
        }
        this.state.clientToId = e[0].id
        this.state.clientTo = e[0].name
        this.GetClientLocation()
    }
    handleChangeEmployee = (e) =>{
        this.setState({
            alerttype   :   "",
            isshow      :   false,
            color       :   "",
            message     :   "",
            fade        :   false
        });
        if(e.length==0)
        {
            this.state.employeeId = ''
            this.state.fromLocationId = '' 
            this.state.clientId = ''
            this.state.profileId = ''
            this.state.location = ''
            this.state.position = ''
            this.state.salary = ''
            this.state.contractdateStart = ''
            this.state.contractdateEnd = ''
            return
        }

        this.state.employeeId = e[0].id
        this.state.fromLocationId = e[0].locationId
        this.state.clientId = e[0].clientId
        this.state.profileId = e[0].profileId
        this.state.location = e[0].locationName
        this.state.position = e[0].position
        this.state.salary = e[0].salaryOffered
        this.state.contractdateStart = e[0].contractDateStart
        this.state.contractdateEnd = e[0].contractDateEnd
        //this.state.fromLocationName = this.state.locationList.find(x=>x.id==e[0].locationId).name
    }
    handleChangeLocation = (e) => {
        this.setState({
            alerttype   :   "",
            isshow      :   false,
            color       :   "",
            message     :   "",
            fade        :   false
        });
        if(e.length==0)
        {
            this.state.locationId = ''
            return
        }
        this.state.locationId = e[0].id
    }
    GetEmployees(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            const data = res.data
            if(data.status=="1")
                this.setState({employeeList : data.employees,isloading:false}) 
            else
                this.setState({employeeList : [],isloading:false}) 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        
    }
    GetClients(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            this.setState(
                {
                    isloading:false,
                    clientList : res.data.clients ? res.data.clients : []
                });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetClientLocation(){
        this.setState({isloading:true})

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.clientTo,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        console.log(getParams)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", getParams)
        .then(res => {
            const data = res.data;
            this.setState({locationList : res.data.locations ? res.data.locations : [], isloading:false})
            if(data.locations.length=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }

    render(){
        return(
            <div>
                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header>Timekeeping >> Transfer Member</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeClient}
                                            options={this.state.clientList}
                                            placeholder="Select Client"

                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.handleChangeEmployee}
                                            options={this.state.employeeList}
                                            placeholder="Select Employee"
                                        />
                                    </Col>
                                </Form.Group>
                                {/* <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Form.Label>From Branch : {this.state.fromLocationName}</Form.Label>
                                    </Col>
                                </Form.Group> */}
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeClientTo}
                                            options={this.state.clientList}
                                            placeholder="Assign to Client"
                                        />
                                    </Col>
                                </Form.Group>
                               <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeLocation}
                                            options={this.state.locationList}
                                            placeholder="Assign to Location"

                                        />
                                    </Col>
                                </Form.Group>
                                <div className="mt-1">
                                    <ButtonToolbar>
                                        <Button disabled={this.state.isDisable}  className="ml-auto" variant="primary" variant="success" onClick={this.handleSubmit}>
                                            Submit
                                        </Button>
                                    </ButtonToolbar>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div>
        )
    }
}

export default TransferEmployee;