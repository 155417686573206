import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

class EditEmpSched extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            code        :   '',
            name        :   '',
            disableBtn  :   true,
            Selectedlocation    :   "",
            SelectedlocationId    :   "",
            locationDDL:[],
            getWorkScheduleList:[],
            SelectedworkSched    :   "",
            SelectedworkSchedId    :   "",
            selectedRow:[],
            
        }
    }

    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)

    }

    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }

    initialize=(row)=>{
        let locationDDLTemp = JSON.parse(sessionStorage.getItem("locationDDL"))
        let WorkScheduleTemp = JSON.parse(sessionStorage.getItem("getWorkScheduleList"))

        let locationIdTemp = locationDDLTemp.filter(x=>x.value===row.locationId)
        this.state.SelectedlocationId   =   locationIdTemp[0].value
        this.state.Selectedlocation     =   locationIdTemp[0].label

        let workSchedTemp = WorkScheduleTemp.filter(x=>x.value===row.workScheduleId)
        this.state.SelectedworkSchedId   =   workSchedTemp[0].value
        this.state.SelectedworkSched     =   workSchedTemp[0].label

        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            code        :   '',
            name        :   '',
            disableBtn  :   true,
            locationDDL :   JSON.parse(sessionStorage.getItem("locationDDL")),
            getWorkScheduleList :   JSON.parse(sessionStorage.getItem("getWorkScheduleList")),
            //Selectedlocation:row
            
        })

       

        this.state.selectedRow.push(row)

        console.log("sesion")
        console.log( this.state.Selectedlocation)
        console.log( this.state.SelectedworkSched)
        console.log(row)
        console.log(this.state.locationDDL)
        console.log(this.state.getWorkScheduleList)
      
    }
    
    handleModalClose = () => {
        this.props.onHide();            
    }

    handleChanged = (e) =>{
        this.setState({[e.target.name]: e.target.value.toUpperCase(),alerttype:"",isshow:false,color:"",message:"",fade:false})
    }

    onChangeLocation = (e) => {
        if(e.length == 0) {
            this.state.SelectedlocationId=""
            this.state.Selectedlocation=""
            return
        } 
      
        this.state.SelectedlocationId = e[0].value
        this.state.Selectedlocation = e[0].label
        /*
        let objArr = [{id:1,name:'PASELA, ROSSWELL CHARA'}]
        let nm = objArr.find(x=>x.id=1).name
        this.setState({selectedOptions:[nm]})
        */
    }

    onChangeWorkSched = (e) => {
        if(e.length == 0) {
            this.state.SelectedworkSchedId=""
            this.state.SelectedworkSched=""
            return
        } 
      
        this.state.SelectedworkSchedId = e[0].value
        this.state.SelectedworkSched = e[0].label
        /*
        let objArr = [{id:1,name:'PASELA, ROSSWELL CHARA'}]
        let nm = objArr.find(x=>x.id=1).name
        this.setState({selectedOptions:[nm]})
        */
    }

    handleSubmitSchedule = async (e) =>{

        this.setState({isloading:true,isDisable:true})
        let schedules = []
        let employeeId = JSON.parse(sessionStorage.getItem("selectedEmployeeId"))
        let payperiodId = JSON.parse(sessionStorage.getItem("selectedPayrollPeriodsId"))
        let userId = this.state.userinfo.userId
        

        schedules.push({
            "Id" : this.state.selectedRow[0].periodId,
            "EmployeeId" : employeeId,
            "PayPerioId" : payperiodId,
            "WorkScheduleId" : this.state.SelectedworkSchedId,
            "LocationId" :  this.state.SelectedlocationId,
            "Date" : this.state.selectedRow[0].date,
            "IsDeleted" : "0",
            "UserId" : userId
        })
     

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "periodWorkSchedules":schedules
        }

        console.log("params handleSubmitSchedule")
        console.log(params)
        
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "TimeKeeping/SaveEmployeeWorkSchedule", params)
        .then(res => {
            const data = res.data
            this.setState({
                isloading   :   false,
                alerttype   :   data.status=="1" ? "Success!" : "Error!",
                isshow      :   true,
                color       :   data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true
            });
            if(data.status=="1"){
                //this.employeeWorkScheduleTable()
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }

    render() {
        return(
        
            <Modal
                {...this.props}
                return
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton className="card-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Employee Schedule
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Typeahead
                                    labelKey='label'
                                    id="basic-example"
                                    onChange={this.onChangeLocation}
                                    options={this.state.locationDDL}
                                    selected={[this.state.Selectedlocation]}
                                    placeholder="Select Location"
                                    
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Typeahead
                                    labelKey='label'
                                    id="basic-example"
                                    onChange={this.onChangeWorkSched}
                                    options={this.state.getWorkScheduleList}
                                    selected={[this.state.SelectedworkSched]}
                                    placeholder="Select workSched"
                                    
                                /> 
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleSubmitSchedule }>Save</Button>&nbsp;
                        <Button variant="danger" className="noser-button-mr15" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default EditEmpSched