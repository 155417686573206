import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker,
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown, FormControl} from 'react-bootstrap';

import  InsertCOCReference from '../Maintenance/Modal/InsertCOCReference';
import { Input } from 'reactstrap';
import ImgsViewer from 'react-images-viewer'

 
class NoticeOfHearing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            disabledSave    :   false,

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            trainingRegisterTableList           :   [ { "typeOfCases" : "DOLE" } ],
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalCoCReferenceShow               :   false,
            category                :"",
            isLoading               :"",
            selectedEmployeeId      :[],
            selectedEmployeeNo      :[],
            selectedEmployeeBranch  :[],
            selectedPosition        :[],
            getClientList           :[],
            getEmployeeList         :[],
            getEmployeeNoList       :[],
            position                :[],
            location                :[],
            //addParagraph:[],
            values                  :[],            
            dateApplied             :new Date(),
            selectedClientName      :"",
            selectedEmployeeName    :"",
            selectedEmployeeNo      :[],
            irReferenceNo           :"",
            paragraph               :"",
            addedparagraph1         :"",
            addedparagraph2         :"",
            addedparagraph3         :"",
            addedparagraph4         :"",
            addedparagraph5         :"",
            addedparagraph6         :"",
            quotedStatement         :"",
            addedparagraph1Check    : false,
            cocReferences           : false,
            addedparagraph2Check    : false,
            addedparagraph3Check    : false,
            addedparagraph4Check    : false,
            addedparagraph5Check    : false,
            addedparagraph6Check    : false,
            quotedStatementCheck    : false,
            preparedBy              :"",
            prepareByPosition       :"",
            notedByPosition         :"",
            notedBy                 :"",
            receivedBy              :"",
            articleNo               :"",
            articleName             :"",
            sectionNo               :"",
            infraction              :"",
            category                :"",
            articleNo2              :"",
            articleName2            :"",
            sectionNo2              :"",
            infraction2             :"",
            category2               :"",
            sectionNo3              :"",
            infraction3             :"",
            category3               :"",          
            irDateIssued            :"",
            dateRecieveByER         :"",
            referenceNoNTE          :"",
            cocReferences           :"",
            eSignatureNotedBy       :"",
            eSignaturePreparedBy    :"",
            disabledGenerate        :true,
            memoTypeList            :[
                { 
                    "id":"1",
                    "name":"Late & Absenteeism"
                },
                { 
                    "id":"2",
                    "name":"AWOL 1ST Notice"
                },
                 { 
                    "id":"3",
                    "name":"AWOL 2ND Notice"
                },
                { 
                    "id":"4",
                    "name":"Notice of Hearing"
                },
                { 
                    "id":"5",
                    "name":"Recall"
                },
                { 
                    "id":"6",
                    "name":"Guarantor Demand Letter"
                },
            ],
            selectedmemoType        :"",
            disableEmployee         :false,
            employeePlaceHolder     :"",
            IRobjId                 :"",
            irReferenceNoData       :[],
            selectedirReferenceNo                 :"",
            selectedPreparedbyClientId                 :"",
            selectedPreparedbyClient                 :"",
            NOHObjId:"",
            checkNotApplicable:"",
        }
        /* this.handleSubmit = this.handleSubmit.bind(this); */
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        this.setState({isLoading:true,loadingText:"Loading client list..."})

        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();;
        //this.getNTErefNo();;
        let dataCheck = JSON.parse(sessionStorage.getItem("nextProcessCheck"))
        if(dataCheck == "1" || dataCheck === "1"){
            let IRData = JSON.parse(sessionStorage.getItem("IRData"))

            this.setState({
                selectedirReferenceNo               : IRData.irReferenceNo,
                selectedClientName          : IRData.client,
                selectedEmployeeName        : IRData.employeeName,
                selectedEmployeeNo          : IRData.employeeNo,
                position                    : IRData.position,
                selectedEmployeeBranch      : IRData.location,
                disableEmployee             : true,
                employeePlaceHolder         : IRData.employeeName,
                IRobjId                     : IRData.id
            })
        }
        if(dataCheck == "2" || dataCheck === "2"){
            let IRData = JSON.parse(sessionStorage.getItem("IRData"))
            if(IRData.status == "NOD" ){
                this.setState({
                    disabledSave:true
                })
            }
            if(IRData.status == "RecordNODPenalty" ){
                this.setState({
                    disabledSave:true
                })
            }
            this.getNOHByRefNo()
        }

        //this.getIRrefNo()

    }

    getNOHByRefNo=()=>{

        let IRData = JSON.parse(sessionStorage.getItem("IRData"))
        this.setState({  isloading:true })
        console.log("IRData")
        console.log( IRData.NOHReferenceNo )
        console.log( "IRData.irReferenceNo" )
        console.log( IRData.irReferenceNo )
        /* if(this.state.selectedEmployeeName !== ""){
            filter_data ={
                'ClientId' : this.state.selectedClientId,
                "employeeName": this.state.selectedEmployeeName
            }
        }
        if(this.state.selectedEmployeeName == ""){
            filter_data ={
                'ClientId' : this.state.selectedClientId,
            }
        } */
        let  filterNOH=IRData.NOHReferenceNo

        let filter_data ={"NOHreferenceNo" : filterNOH }
        
        const getParams ={
            "_collection" : "NOH",
            "filter_data" : filter_data
        }

        console.log("axios start getNOHbyDocNo")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data
            console.log("data axios start getNOHbyDocNo")
            console.log(data)
            if(data.NOH.length > 0){

                const itemUsersDataLists = this.buildListNOH(data["NOH"])
            
                this.state.itemUserDataList  =  itemUsersDataLists

                var obj = (data);
                var res = [];
                
                data.NOH.map(function(itm,i){
                    let strJson = "{" + itm.substring(46, itm.length)
                    let json = JSON.parse(strJson.replace(/'/g,'"'));
                    res.push(json)
                    console.log(json)
                })
                
                console.log("axios start res")
                console.log(res)
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                        
                    let obj = {

                        'id'                        :   this.state.itemUserDataList[i]['id'].replace('"', '').replace('"', ''),
                       
                    }

                    List.push(obj)
                    console.log("obj start obj")
                    console.log(List)
                }

                let IRData = JSON.parse(sessionStorage.getItem("IRData"))

                this.setState({

                    selectedClientName          : IRData.client,
                    selectedEmployeeName        : IRData.employeeName,
                    selectedEmployeeNo          : IRData.employeeNo,
                    position                    : IRData.position,
                    selectedEmployeeBranch      : IRData.location,

                    NOHObjId: List[0].id,
                    isloading:false,
                    //selectedClientName                : res[0].client,
                    selectedClientId              : res[0].ClientId,
                    //selectedEmployeeName          : res[0].employeeName,
                    //position                    : res[0].position,
                    //selectedEmployeeNo          : res[0].employeeNo,
                    //selectedEmployeeBranch      : res[0].location,
                    dateApplied                     : new Date(res[0].date),
                    referenceNoNTE              : res[0].NOHreferenceNo,
                    selectedirReferenceNo               : res[0].irReferenceNo,
                    preparedBy            : res[0].preparedBy,
                    prepareByPosition     : res[0].prepareByPosition,
                    notedBy               : res[0].notedBy,
                    notedByPosition       : res[0].notedByPosition,
                    receivedBy            : res[0].receivedBy,

                    paragraph             :   res[0].paragraph,
                    addedparagraph1       :   res[0].addedparagraph1,
                    addedparagraph2       :   res[0].addedparagraph2,
                    addedparagraph3       :   res[0].addedparagraph3,
                    addedparagraph4       :   res[0].addedparagraph4,
                    addedparagraph5       :   res[0].addedparagraph5,
                    addedparagraph6       :   res[0].addedparagraph6,
                    addedparagraph1Check    : res[0].addedparagraph1.length > 0 ? true:false,
                    addedparagraph2Check    : res[0].addedparagraph2.length > 0 ? true:false,
                    addedparagraph3Check    : res[0].addedparagraph3.length > 0 ? true:false,
                    addedparagraph4Check    : res[0].addedparagraph4.length > 0 ? true:false,
                    addedparagraph5Check    : res[0].addedparagraph5.length > 0 ? true:false,
                    addedparagraph6Check    : res[0].addedparagraph6.length > 0 ? true:false,
                })

              
/* 
                this.setState({
                    //selectedirReferenceNo               : IRData.irReferenceNo,
                  
                    disableEmployee             : true,
                    //employeePlaceHolder         : IRData.employeeName,
                    //IRobjId                     : IRData.id
                }) */
                
            }else{
                
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    buildListNOH(data) {
        // console.log("data start")
        // console.log(data )
        let itemList =[]
    
        let idList                      =[]

        for (let i = 0; i < data.length; i++) {            
    
            let s1 = data[i].split("', ")
           
            
            let IdClean0 = s1[0].split("': ")[1]           
            let idClean1 = IdClean0.replace("ObjectId(","").replace(")","").replace("{","").replace(", 'client","") 
              console.log("idClean1 start")
        console.log(idClean1 )
            idList.push(idClean1.replace("'","").replace("'","").replace(" ",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                         :   idList[i],
            
            }
            itemList.push(obj)
            console.log("itemList start")
            console.log(itemList )
            console.log("itemList" )
            
        }
        return itemList
    }

    /* getIRrefNo=()=>{
       
        let filter_data ={'status' : "NTE"}

        const getParams ={
            "_collection" : "EDMonitoring",
            "filter_data" : filter_data
        }

        console.log("axios start")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data

            if(data.EDMonitoring.length > 0){

                const itemUsersDataLists = this.buildListEDMonitoring(data["EDMonitoring"])
            
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                        
                    let obj = {

                        'id'                        :   this.state.itemUserDataList[i]['id'],
                        'quotedStatement'           :   this.state.itemUserDataList[i]['quotedStatement'],
                        'dateOfNTE'                 :   this.state.itemUserDataList[i]['dateOfNTE'],
                        'client'                    :   this.state.itemUserDataList[i]['client'],              
                        'cycleOfDays'               :   this.state.itemUserDataList[i]['cycleOfDays'],
                        'dateOfIncident'            :   this.state.itemUserDataList[i]['dateOfIncident'],
                        'dateOfNOD'                 :   this.state.itemUserDataList[i]['dateOfNOD'],
                        'dateOfNOH'                 :   this.state.itemUserDataList[i]['dateOfNOH'],
                        'dateRecordNodaPenalty'     :   this.state.itemUserDataList[i]['dateRecordNodaPenalty'],
                        'employeeName'              :   this.state.itemUserDataList[i]['employeeName'],
                        'employeeNo'                :   this.state.itemUserDataList[i]['employeeNo'],
                        'irReferenceNo'             :   this.state.itemUserDataList[i]['irReferenceNo'],              
                        'location'                  :   this.state.itemUserDataList[i]['location'],
                        'position'                  :   this.state.itemUserDataList[i]['position'],
                        'retailgroup'               :   this.state.itemUserDataList[i]['retailgroup'],
                        'status'                    :   this.state.itemUserDataList[i]['status'],
                        'article'                   :   this.state.itemUserDataList[i]['article'],
                        'category'                  :   this.state.itemUserDataList[i]['category'],
                        'section'                   :   this.state.itemUserDataList[i]['section'],
                        'isDraft'                   :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'                 :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'                 :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'               :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'                :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'              :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'                :   this.state.itemUserDataList[i]['isModified'],
                        'NTEReferenceNo'            :   this.state.itemUserDataList[i]['NTEReferenceNo'],
                        'NODReferenceNo'            :   this.state.itemUserDataList[i]['NODReferenceNo'],
                        'NOHReferenceNo'            :   this.state.itemUserDataList[i]['NOHReferenceNo'],
                        'ClientId'                    :   this.state.itemUserDataList[i]['ClientId'],
                        "NTHoffense"                :   this.state.itemUserDataList[i]['NTHoffense'],  
                        "penalty"                   :   this.state.itemUserDataList[i]['penalty'],  
                        "monetaryCharge"            :   this.state.itemUserDataList[i]['monetaryCharge'],  
                        "statusOfIssue"             :   this.state.itemUserDataList[i]['statusOfIssue'],
                        'action'                    :   "1",

                    }

                    if(obj.status === "NTE"){
                        List.push(obj)
                    }
                }

                this.setState({
                    irReferenceNoData: List,
                    isloading:false
                })
                
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    } */

    /////******DYNAMIC COMPONENT TASYO******/////////
    /* createUI(){
        return this.state.values.map((el, i) => 
            <div key={i} className="mt-3">
               <FormControl as="textarea" value={el||''} aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChange.bind(this, i)} />
               
            </div>          
        )
    }
   
    handleChange(i, event) {
        let values = [...this.state.values];
        values[i] = event.target.value;
        this.setState({ values });
        console.log(values)
    }
     
    addClick(){
       this.setState(prevState => ({ values: [...prevState.values, '']}))
    }
     
    removeClick(i){
        let values = [...this.state.values];
        values.splice(i,1);
        this.setState({ values });
    }
   
    handleSubmit(event) {
       alert('A name was submitted: ' + this.state.values.join(', '));
       event.preventDefault();
    } */
   


    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
            this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }
    
    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        console.log("Client selectedClientId " + this.state.selectedClientId );
        console.log("Client selectedClientName " + this.state.selectedClientName );
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})
        
        this.getEmployees();
    }
    
    getEmployees(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            console.log("Employee List start ");
            console.log(res.data);
            console.log("Employee List end");
            const data = res.data

            if(data.status=="1")
                this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
            else
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 
                
        })
        .catch(error=>{

           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })

        })
        
    }

    onChangeEmployeesList = (e) => {       

        if(e.length==0)
        {
            this.setState({getEmployeeListData: null, selectedPayrollpayPeriod: '', selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedEmployeeName = e[0].employeeName
        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeNo = e[0].employeeNo
        this.state.selectedEmployeeBranch = e[0].locationName
        this.state.position = e[0].position
        
        console.log("e[0]")
        console.log(e[0])
        console.log("e[0]")
        console.log("Work selectedEmployeeName   " + this.state.selectedEmployeeNo );
        console.log("Employee List start ");
        console.log(this.state.selectedEmployeeBranch);
        console.log("Employee List end");


       /*  this.setState({
            position:this.state.position,
            location:this.state.selectedEmployeeBranch

        }) */

        this.setState({isloading:false})

    }

    onChangePreparedbyClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedPreparedbyClient=""
            this.state.selectedPreparedbyClientId=""
            return
        } 
        this.state.selectedPreparedbyClientId = e[0].id
        this.state.selectedPreparedbyClient = e[0].name
        console.log("Client selectedClientId " + this.state.selectedClientId );
        console.log("Client selectedClientName " + this.state.selectedClientName );
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})
        
        this.getEmployeesPreparedby();
        //this.getEmployees();
    }

    getEmployeesPreparedby(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedPreparedbyClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            console.log("Employee List start ");
            console.log(res.data);
            console.log("Employee List end");
            const data = res.data

            if(data.status=="1")
                this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
            else
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 
                
        })
        .catch(error=>{

           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })

        })
        
    }

    onChangeEmployeesNo(e){

        this.setState({selectedEmployeeNo : e.target.value})
        //console.log(e.target.value)
    }

    onChangePosition(e){

        this.setState({position : e.target.value})
        //console.log(e.target.value)
    }

    onChangeLocation(e){

        this.setState({location : e.target.value})
        //console.log(e.target.value)
    }

    showTrainingRegisterModal = (e) => {

        this.setState({modalCoCReferenceShow: true})

    }

    handleModalClose = (e) =>{

        this.setState({modalCoCReferenceShow: false})
        this.getViewDraftMemo();;

    }

    onChangeIrRefNo = (e) => {       

        if(e.length==0)
        {
            this.state.selectedirReferenceNo = ""
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})
        this.state.selectedirReferenceNo = e[0].irReferenceNo
        console.log(this.state.selectedirReferenceNo)
        /* 
        this.state.selectedirReferenceNo = e[0].irReferenceNo
        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeNo = e[0].employeeNo
        this.state.selectedEmployeeBranch = e[0].locationName
        this.state.position = e[0].position */
        
      
       /*  this.setState({
            position:this.state.position,
            location:this.state.selectedEmployeeBranch

        }) */

       this.getEDByref()

    }

    getEDByref=()=>{
        let filter_data ={'irReferenceNo':this.state.selectedirReferenceNo}

        const getParams ={
            "_collection" : "EDMonitoring",
            "filter_data" : filter_data
        }

        console.log("axios start")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data

            if(data.EDMonitoring.length > 0){

                const itemUsersDataLists = this.buildListEDMonitoring(data["EDMonitoring"])
            
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                        
                    let obj = {
                        'id'                        :   this.state.itemUserDataList[i]['id'],
                        'quotedStatement'           :   this.state.itemUserDataList[i]['quotedStatement'],
                        'dateOfNTE'                 :   this.state.itemUserDataList[i]['dateOfNTE'],
                        'client'                    :   this.state.itemUserDataList[i]['client'],              
                        'cycleOfDays'               :   this.state.itemUserDataList[i]['cycleOfDays'],
                        'dateOfIncident'            :   this.state.itemUserDataList[i]['dateOfIncident'],
                        'dateOfNOD'                 :   this.state.itemUserDataList[i]['dateOfNOD'],
                        'dateOfNOH'                 :   this.state.itemUserDataList[i]['dateOfNOH'],
                        'dateRecordNodaPenalty'     :   this.state.itemUserDataList[i]['dateRecordNodaPenalty'],
                        'employeeName'              :   this.state.itemUserDataList[i]['employeeName'],
                        'employeeNo'                :   this.state.itemUserDataList[i]['employeeNo'],
                        'irReferenceNo'             :   this.state.itemUserDataList[i]['irReferenceNo'],              
                        'location'                  :   this.state.itemUserDataList[i]['location'],
                        'position'                  :   this.state.itemUserDataList[i]['position'],
                        'retailgroup'               :   this.state.itemUserDataList[i]['retailgroup'],
                        'status'                    :   this.state.itemUserDataList[i]['status'],
                        'article'                   :   this.state.itemUserDataList[i]['article'],
                        'category'                  :   this.state.itemUserDataList[i]['category'],
                        'section'                   :   this.state.itemUserDataList[i]['section'],
                        'isDraft'                   :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'                 :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'                 :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'               :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'                :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'              :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'                :   this.state.itemUserDataList[i]['isModified'],
                        'ClientId'                  :   this.state.itemUserDataList[i]['ClientId'],
                        "NTHoffense"                :   this.state.itemUserDataList[i]['NTHoffense'],  
                        "penalty"                   :   this.state.itemUserDataList[i]['penalty'],  
                        "monetaryCharge"            :   this.state.itemUserDataList[i]['monetaryCharge'],  
                        "statusOfIssue"             :   this.state.itemUserDataList[i]['statusOfIssue'], 
                        'action'                    :   "1",
                    }
                    List.push(obj)
                }

                this.setState({
                    IRobjId: List[0].id,
                    selectedClientName:List[0].client,
                    selectedClientId:List[0].ClientId,
                    employeePlaceHolder:List[0].employeeName,
                    selectedEmployeeName:List[0].employeeName,
                    selectedEmployeeNo:List[0].employeeNo,
                    position:List[0].position,
                    selectedEmployeeBranch:List[0].location,
                    isloading:false,
                    disableEmployee:true,
                })
                this.state.selectedClientName=List[0].client
                console.log(List[0].client)
                console.log(this.state.selectedClientName)
                
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }


    GridDataModified(oldValue, newValue, id, column) {
        ////console.log(id)
        this.state.trainingScheduleTableList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })

    }

    handleChangeDate = date => {
        
        this.setState({
            dateApplied: date,
            isshow:false,
        })

    }

    getNTErefNo=(e)=>{       

        /* let filter_data ={}

        const getParams ={
            "_collection" : "NTE",
            "filter_data" : filter_data
        }

        console.log("axios start")

        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data
            console.log("Raw Json111")
            console.log(data)
            console.log("End Raw Json111")
            var obj = (data);
            var res = [];
            
            data.TestNTE.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                res.push(json)
            })
            
            let refNotmp = ""
            refNotmp = res.length
            console.log("Parsed Json below")
            console.log(refNotmp)


            this.setState({
                referenceNoNTE: refNotmp,
                isloading:false
            })
                            
            this.handleSaveClick();
            
        })
        .catch(error=>{
            console.log("error: " + error)
        }) */
       
        this.setState({
           // referenceNoNTE: newIRRef,
            isloading:false
        })
        this.getIRobjID()  

       
            
    }
    //save NOH
    handleSaveClick=()=>{
        let newdate = new Date()
        let NTEDate        = moment(newdate).format('MMDDYYYY');
        let newIRRef        = "PHR"+this.state.selectedEmployeeNo+NTEDate
        if(this.state.createddate === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "please Enter Date",
                fade            :   true
            })
            return
        }

        if(this.state.dateApplied === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "please Enter Date",
                fade            :   true
            })
            return
        }
        
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');
        let datetmp = moment(this.state.dateApplied).format('MM/DD/YYYY');
        let data = {

            "client"                :   this.state.selectedClientName,
            "employeeName"          :   this.state.selectedEmployeeName,
            "position"              :   this.state.position,
            "employeeNo"            :   this.state.selectedEmployeeNo,
            "location"              :   this.state.selectedEmployeeBranch,
            "date"                  :   datetmp,
            "NOHreferenceNo"        :   newIRRef,
            "irReferenceNo"         :   this.state.selectedirReferenceNo,
            "paragraph"             :   this.state.paragraph,
            "addedparagraph1"       :   this.state.addedparagraph1.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph2"       :   this.state.addedparagraph2.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph3"       :   this.state.addedparagraph3.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph4"       :   this.state.addedparagraph4.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph5"       :   this.state.addedparagraph5.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph6"       :   this.state.addedparagraph6.replace(/'/g, '').replace(/"/g, ''),
            "quotedStatement"       :   this.state.quotedStatement.replace(/'/g, '').replace(/"/g, ''),
            "article"               :   "",   
            "section"               :   "",    
            "preparedBy"            :   this.state.preparedBy,
            "prepareByPosition"     :   this.state.prepareByPosition,
            "notedBy"               :   this.state.notedBy,
            "notedByPosition"       :   this.state.notedByPosition,
            "receivedBy"            :   this.state.receivedBy,
            //"memoType"              :   this.state.selectedmemoType,
            "status"                :   "",
            "isDraft"               :   "0",
            "isDeleted"             :   "0",
            "createdby"             :   this.state.userinfo.userId,
            "createddate"           :   createddate,
            "modifiedby"            :   'user007',
            "modifieddate"          :   '0',
            "isModified"            :   '0',

        }

        console.log(data)
        
        const addParams = {
          "_collection" : "NOH",
          "doc_data"    : data
        }

        console.log(addParams)
        
        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/add", addParams)
        //.post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            console.log("success")
            console.log(res.data)
            if(this.state.checkNotApplicable ==="1"){
                window.location.href='/NoticeOfDisiplinaryAction'
            }
           
            //this.updateIRstatus()
            // this.setState({
            //     isloading       :   false,
            //     alerttype       :   "Success!",
            //     isshow          :   true,
            //     color           :   "success",
            //     message         :   "Data had successfully Save" ,
            //     fade            :   true,
            //     disabledGenerate  :   false
            // })    
                
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    UpdateNOH=()=>{
        if(this.state.createddate === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "please Enter Date",
                fade            :   true
            })
            return
        }

        if(this.state.dateApplied === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "please Enter Date",
                fade            :   true
            })
            return
        }
        
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');
        let datetmp = moment(this.state.dateApplied).format('MM/DD/YYYY');
        let data = {

            "client"                :   this.state.selectedClientName,
            "employeeName"          :   this.state.selectedEmployeeName,
            "position"              :   this.state.position,
            "employeeNo"            :   this.state.selectedEmployeeNo,
            "location"              :   this.state.selectedEmployeeBranch,
            "date"                  :   datetmp,
            "NOHreferenceNo"        :   this.state.referenceNoNTE,
            "irReferenceNo"         :   this.state.selectedirReferenceNo,
            "paragraph"             :   this.state.paragraph,
            "addedparagraph1"       :   this.state.addedparagraph1.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph2"       :   this.state.addedparagraph2.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph3"       :   this.state.addedparagraph3.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph4"       :   this.state.addedparagraph4.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph5"       :   this.state.addedparagraph5.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph6"       :   this.state.addedparagraph6.replace(/'/g, '').replace(/"/g, ''),
            "quotedStatement"       :   this.state.quotedStatement.replace(/'/g, '').replace(/"/g, ''),
            "article"               :   "",   
            "section"               :   "",    
            "preparedBy"            :   this.state.preparedBy,
            "prepareByPosition"     :   this.state.prepareByPosition,
            "notedBy"               :   this.state.notedBy,
            "notedByPosition"       :   this.state.notedByPosition,
            "receivedBy"            :   this.state.receivedBy,
            "status"                :   "",
            "isDraft"               :   "0",
            "isDeleted"             :   "0",
            "createdby"             :   this.state.userinfo.userId,
            "createddate"           :   createddate,
            "modifiedby"            :   'user007',
            "modifieddate"          :   '0',
            "isModified"            :   '0',

        }

        console.log(data)
        
        const updateParams = {
          "_collection" : "NOH",
          "doc_data"    : data,
          "_id"         : this.state.NOHObjId,
        }

        console.log(updateParams)
        
        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/update_one", updateParams)
        //.post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            console.log("success")
            console.log(res.data)
            //this.updateIRstatus()
            // this.setState({
            //     isloading       :   false,
            //     alerttype       :   "Success!",
            //     isshow          :   true,
            //     color           :   "success",
            //     message         :   "Data had successfully Save" ,
            //     fade            :   true,
            //     disabledGenerate  :   false
            // })    
                
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    buildListEDMonitoring(data) {
        // console.log("data start")
        // console.log(data )
        console.log("data start")
        console.log(data )
        console.log("data" )
        let itemList =[]
    
        let idList                      =[]
        let quotedStatementList         =[]
        let dateOfNTEList               =[]
        let clientList                  =[]
        let createdbyList               =[]
        let createddateList             =[]
        let cycleOfDaysList             =[]
        let dateOfIncidentList          =[]
        let dateOfNODList               =[]
        let dateOfNOHList               =[]
        let dateRecordNodaPenaltyList   =[]
        let employeeNameList            =[]
        let employeeNoList              =[]
        let irReferenceNoList           =[]
        let isDeletedList               =[]
        let isDraftList                 =[]
        let isModifiedList              =[]
        let locationList                =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let positionList                =[]
        let retailgroupList             =[]
        let statusList                  =[]
        let articleList                 =[]
        let categoryList                =[]
        let sectionList                 =[]
        let NTEReferenceNoList                 =[]
        let NODReferenceNoList                 =[]
        let NOHReferenceNoList                 =[]
        let ClientIdList                 =[]

        let NTHoffenseList                 =[]
        let penaltyList                 =[]
        let monetaryChargeList                 =[]
        let statusOfIssueList                 =[]

        for (let i = 0; i < data.length; i++) {            
    
            let s1 = data[i].split("', '")
            console.log("s1 start")
            console.log(s1 )
            console.log("s1" )
          
            let IdClean0                            = s1[0].split("': ")[1]           
            let idClean1                            = IdClean0.replace("ObjectId(","").replace(")","").replace("{","").replace(", 'action","")  
            let quotedStatementTmp                  = s1[1].split("': ")[1]    
            let dateOfNTETmp                        = s1[2].split("': ")[1]           
            let clientTmp                        = s1[3].split("': ")[1]        
            let createdbyTmp                     = s1[4].split("': ")[1]          
            let createddateTmp                   = s1[5].split("': ")[1]
            let cycleOfDaysTmp                   = s1[6].split("': ")[1]
            let dateOfIncidentTmp                = s1[7].split("': ")[1]
            let dateOfNODTmp                     = s1[8].split("': ")[1]
            let dateOfNOHTmp                     = s1[9].split("': ")[1]
            let dateRecordNodaPenaltyTmp         = s1[10].split("': ")[1]
            let employeeNameTmp                  = s1[11].split("': ")[1]
            let employeeNoTmp                    = s1[12].split("': ")[1]
            let irReferenceNoTmp                 = s1[13].split("': ")[1]
            let isDeletedTmp                     = s1[14].split("': ")[1]
            let isDraftTmp                       = s1[15].split("': ")[1]
            let isModifiedListTmp                = s1[16].split("': ")[1]            
            let locationTmp                      = s1[17].split("': ")[1]
            let modifiedbyListTmp                = s1[18].split("': ")[1]
            let modifieddateListTmp              = s1[19].split("': ")[1]
            let positionTmp                      = s1[20].split("': ")[1]
            let retailgroupTmp                   = s1[21].split("': ")[1]
            let statusTmp                        = s1[22].split("': ")[1]
            let articleTmp                       = s1[23].split("': ")[1]
            let categoryTmp                      = s1[24].split("': ")[1]
            let sectionTmp                       = s1[25].split("': ")[1]
            let NTEReferenceNoTmp                = s1[26].split("': ")[1]
            let NODReferenceNoTmp                = s1[27].split("': ")[1]
            let NOHReferenceNoTmp                = s1[28].split("': ")[1]
            let ClientIdTmp                      = s1[29].split("': ")[1]
            let NTHoffenseTmp                    = s1[30].split("': ")[1]
            let penaltyTmp                       = s1[31].split("': ")[1]
            let monetaryChargeTmp                = s1[32].split("': ")[1]
            let statusOfIssueTmp                 = s1[33].split("': ")[1]

          

            idList.push(idClean1.replace("'","").replace("'","").replace(" ",""))
            quotedStatementList.push(quotedStatementTmp.replace("'",""))
            dateOfNTEList.push(dateOfNTETmp.replace("'","").replace(" ",""))           
            clientList.push(clientTmp.replace("'",""))
            createdbyList.push(createdbyTmp.replace("'",""))
            createddateList.push(createddateTmp.replace("'",""))
            cycleOfDaysList.push(cycleOfDaysTmp.replace("'",""))
            dateOfIncidentList.push(dateOfIncidentTmp.replace("'",""))
            dateOfNODList.push(dateOfNODTmp.replace("'",""))
            dateOfNOHList.push(dateOfNOHTmp.replace("'",""))
            dateRecordNodaPenaltyList.push(dateRecordNodaPenaltyTmp.replace("'",""))
            employeeNameList.push(employeeNameTmp.replace("'",""))
            employeeNoList.push(employeeNoTmp.replace("'",""))
            irReferenceNoList.push(irReferenceNoTmp.replace("'",""))
            isDeletedList.push(isDeletedTmp.replace("'",""))
            isDraftList.push(isDraftTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'",""))
            locationList.push(locationTmp.replace("'",""))
            positionList.push(positionTmp.replace("'",""))
            retailgroupList.push(retailgroupTmp.replace("'",""))
            statusList.push(statusTmp.replace("'",""))
            articleList.push(articleTmp.replace("'",""))
            categoryList.push(categoryTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            sectionList.push(sectionTmp.replace("'",""))
            NTEReferenceNoList.push(NTEReferenceNoTmp.replace("'",""))
            NODReferenceNoList.push(NODReferenceNoTmp.replace("'",""))
            NOHReferenceNoList.push(NOHReferenceNoTmp.replace("'",""))
            ClientIdList.push(ClientIdTmp.replace("'",""))

            NTHoffenseList.push(NTHoffenseTmp.replace("'",""))
            penaltyList.push(penaltyTmp.replace("'",""))
            monetaryChargeList.push(monetaryChargeTmp.replace("'",""))
            statusOfIssueList.push(statusOfIssueTmp.replace("'","").replace("'}",""))

          
            
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                         :   idList[i],
                'quotedStatement'            :   quotedStatementList[i],
                'dateOfNTE'                  :   dateOfNTEList[i],
                'client'                     :   clientList[i],              
                'cycleOfDays'                :   cycleOfDaysList[i],
                'dateOfIncident'             :   dateOfIncidentList[i],
                'dateOfNOD'                  :   dateOfNODList[i],
                'dateOfNOH'                  :   dateOfNOHList[i],
                'dateRecordNodaPenalty'      :   dateRecordNodaPenaltyList[i],
                'employeeName'               :   employeeNameList[i],
                'employeeNo'                 :   employeeNoList[i],
                'irReferenceNo'              :   irReferenceNoList[i],              
                'location'                   :   locationList[i],
                'position'                   :   positionList[i],
                'retailgroup'                :   retailgroupList[i],
                'status'                     :   statusList[i],
                'article'                    :   articleList[i],
                'category'                   :   categoryList[i],
                'section'                    :   sectionList[i],
                'isDraft'                    :   isDraftList[i],
                'isDeleted'                  :   isDeletedList[i],
                'createdby'                  :   createdbyList[i],
                'createddate'                :   createddateList[i],
                'modifiedby'                 :   modifiedbyList[i],
                'modifieddate'               :   modifieddateList[i],
                'isModified'                 :   isModifiedList[i],
                'NTEReferenceNo'             :   NTEReferenceNoList[i],
                'NODReferenceNo'             :   NODReferenceNoList[i],
                'NOHReferenceNo'             :   NOHReferenceNoList[i],
                'ClientId'                   :   ClientIdList[i],                
                "NTHoffense"                 :   NTHoffenseList[i],
                "penalty"                    :   penaltyList[i],
                "monetaryCharge"             :   monetaryChargeList[i],
                "statusOfIssue"              :   statusOfIssueList[i],
            
            }
            itemList.push(obj)
            console.log("itemList start")
            console.log(itemList )
            console.log("itemList" )
            
        }
        return itemList
    }

    getIRobjID=()=>{
       
        let filter_data ={'irReferenceNo':this.state.selectedirReferenceNo}

        const getParams ={
            "_collection" : "EDMonitoring",
            "filter_data" : filter_data
        }

        console.log("axios start")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data

            if(data.EDMonitoring.length > 0){

                const itemUsersDataLists = this.buildListEDMonitoring(data["EDMonitoring"])
            
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                        
                    let obj = {

                        'id'                        :   this.state.itemUserDataList[i]['id'],
                        'quotedStatement'           :   this.state.itemUserDataList[i]['quotedStatement'],
                        'dateOfNTE'                 :   this.state.itemUserDataList[i]['dateOfNTE'],
                        'client'                    :   this.state.itemUserDataList[i]['client'],              
                        'cycleOfDays'               :   this.state.itemUserDataList[i]['cycleOfDays'],
                        'dateOfIncident'            :   this.state.itemUserDataList[i]['dateOfIncident'],
                        'dateOfNOD'                 :   this.state.itemUserDataList[i]['dateOfNOD'],
                        'dateOfNOH'                 :   this.state.itemUserDataList[i]['dateOfNOH'],
                        'dateRecordNodaPenalty'     :   this.state.itemUserDataList[i]['dateRecordNodaPenalty'],
                        'employeeName'              :   this.state.itemUserDataList[i]['employeeName'],
                        'employeeNo'                :   this.state.itemUserDataList[i]['employeeNo'],
                        'irReferenceNo'             :   this.state.itemUserDataList[i]['irReferenceNo'],              
                        'location'                  :   this.state.itemUserDataList[i]['location'],
                        'position'                  :   this.state.itemUserDataList[i]['position'],
                        'retailgroup'               :   this.state.itemUserDataList[i]['retailgroup'],
                        'status'                    :   this.state.itemUserDataList[i]['status'],
                        'article'                   :   this.state.itemUserDataList[i]['article'],
                        'category'                  :   this.state.itemUserDataList[i]['category'],
                        'section'                   :   this.state.itemUserDataList[i]['section'],
                        'isDraft'                   :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'                 :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'                 :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'               :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'                :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'              :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'                :   this.state.itemUserDataList[i]['isModified'],
                        'NTEReferenceNo'            :   this.state.itemUserDataList[i]['NTEReferenceNo'],
                        'NODReferenceNo'            :   this.state.itemUserDataList[i]['NODReferenceNo'],
                        'NOHReferenceNo'            :   this.state.itemUserDataList[i]['NOHReferenceNo'],
                        'ClientId'                    :   this.state.itemUserDataList[i]['ClientId'],
                        "NTHoffense"                :   this.state.itemUserDataList[i]['NTHoffense'],  
                        "penalty"                   :   this.state.itemUserDataList[i]['penalty'],  
                        "monetaryCharge"            :   this.state.itemUserDataList[i]['monetaryCharge'],  
                        "statusOfIssue"             :   this.state.itemUserDataList[i]['statusOfIssue'],  
                        'action'                    :   "1",

                    }
                    List.push(obj)
                }

                this.setState({
                    IRobjId: List[0].id,
                    isloading:false
                })
    
                this.updateIRstatus(List)     
                
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    }

    updateIRstatus=(toirupdate)=>{

        console.log(toirupdate)
        console.log("updateIRstatus")
        let datetmp = moment(this.state.dateApplied).format('MM/DD/YYYY');

        let IRData = toirupdate[0]

        let newdate = new Date()
        let NTEDate        = moment(newdate).format('MMDDYYYY');
        let newIRRef        = "PHR"+this.state.selectedEmployeeNo+NTEDate


        let data = {
        
            "action"                : "1",
            "quotedStatement"       : toirupdate[0].quotedStatement,
            "dateOfNTE"             : toirupdate[0].dateOfNTE,
            "client"                : toirupdate[0].client,
            "createdby"             : toirupdate[0].createdby,
            "createddate"           : toirupdate[0].createddate,
            "cycleOfDays"           : toirupdate[0].cycleOfDays,
            "dateOfIncident"        : toirupdate[0].dateOfIncident,
            "dateOfNOD"             : toirupdate[0].dateOfNOD,
            "dateOfNOH"             : datetmp,            
            "dateRecordNodaPenalty" : toirupdate[0].dateRecordNodaPenalty,
            "employeeName"          : toirupdate[0].employeeName,
            "employeeNo"            : toirupdate[0].employeeNo,
            "irReferenceNo"         : toirupdate[0].irReferenceNo,
            "isDeleted"             : toirupdate[0].isDeleted,
            "isDraft"               : toirupdate[0].isDraft,
            "isModified"            : toirupdate[0].isModified,
            "location"              : toirupdate[0].location,
            "modifiedby"            : toirupdate[0].modifiedby,
            "modifieddate"          : toirupdate[0].modifieddate,
            "position"              : toirupdate[0].position,            
            "retailgroup"           : toirupdate[0].retailgroup,
            "status"                : "NOH",
            "article"               : toirupdate[0].article,
            "category"              : toirupdate[0].category,
            "section"               : toirupdate[0].section,
            "NTEReferenceNo"        : toirupdate[0].NTEReferenceNo,
            "NODReferenceNo"        : toirupdate[0].NODReferenceNo, 
            "NOHReferenceNo"        : newIRRef, 
            'ClientId'              : toirupdate[0].ClientId,
            "NTHoffense"            :toirupdate[0].NTHoffense,
            "penalty"               :toirupdate[0].penalty,
            "monetaryCharge"        :toirupdate[0].monetaryCharge,
            "statusOfIssue"         :"Pending",
        }

        const updateParams = {

            "_collection" : "EDMonitoring",
            "doc_data"    : data,
            "_id"         : this.state.IRobjId,

        }

        console.log("updateIRstatus")
        console.log(updateParams)       
        console.log("updateIRstatus")

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/update_one", updateParams)
        //.post("http://134.209.99.190:8088/action/update_one", updateParams)
        .then(res =>{

            this.setState({
                isloading           :   false,
                alerttype           :   "Success!",
                isshow              :   true,
                color               :   "success",
                message             :   "Data had successfully Saved" ,
                fade                :   true,
                disabledGenerate    :   false,
                disabledSave        :   true,
            
            })

            let dataCheck = JSON.parse(sessionStorage.getItem("nextProcessCheck"))
            if(dataCheck == "2" || dataCheck === "2"){
                this.UpdateNOH();
            }else{
                this.handleSaveClick();
                let nextProcessCheck = "0"
                sessionStorage.setItem("nextProcessCheck", JSON.stringify(nextProcessCheck)) 
            }
          
        
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })

        })

    }

    handleChangeParagraph=(e)=>{

        this.setState({
            paragraph   : e.target.value
        })

        //console.log(this.state.paragraph)
    }

    handleAddedParagraph1=(e)=>{

        if(this.state.addedparagraph5Check == true || this.state.addedparagraph5Check === true){
            
            this.setState({
                addedparagraph6Check:true
            })
        }

        if(this.state.addedparagraph4Check == true || this.state.addedparagraph4Check === true){
            
            this.setState({
                addedparagraph5Check:true
            })
        }

        if(this.state.addedparagraph3Check == true || this.state.addedparagraph3Check === true){
            
            this.setState({
                addedparagraph4Check:true
            })
        }

        if( this.state.addedparagraph2Check == true || this.state.addedparagraph2Check === true ){
            
            this.setState({
                addedparagraph3Check:true
            })
        }

        if(this.state.addedparagraph1Check == true || this.state.addedparagraph1Check === true){
            
            this.setState({
                addedparagraph2Check:true
            })

        }        
        
        this.setState({
            addedparagraph1Check:true
        })

        console.log("addedparagraph1Check start")
        console.log(this.state.addedparagraph1Check)
        console.log("addedparagraph1Check")

    }

    handleChangeAddedParagraph1=(e)=>{

        this.setState({
            addedparagraph1   : e.target.value
        })

        //console.log(this.state.addedparagraph1)

    }

    handleAddedParagraph2=(e)=>{
        this.setState({
            addedparagraph2Check:true
        })
    }

    handleChangeAddedParagraph2=(e)=>{

        this.setState({
            addedparagraph2   : e.target.value
        })
        //console.log(this.state.addedparagraph2)
    }

    handleAddedParagraph3=(e)=>{

        this.setState({
            addedparagraph3Check:true
        })
    }

    handleChangeAddedParagraph3=(e)=>{
        
        this.setState({
            addedparagraph3   : e.target.value
        })
        //console.log(this.state.addedparagraph3)
    }

    handleChangeAddedParagraph4=(e)=>{
        
        this.setState({
            addedparagraph4   : e.target.value
        })
        //console.log(this.state.addedparagraph3)
    }

    handleChangeAddedParagraph5=(e)=>{
        
        this.setState({
            addedparagraph5   : e.target.value
        })
        //console.log(this.state.addedparagraph3)
    }

    handleChangeAddedParagraph6=(e)=>{
        
        this.setState({
            addedparagraph6   : e.target.value
        })
        //console.log(this.state.addedparagraph3)
    }

    handleQuotedStatementCheck=(e)=>{
        this.setState({
            quotedStatementCheck:true
        })
    }

    handleChangeQuotedStatement=(e)=>{
        this.setState({
            quotedStatement   : e.target.value
        })
        //console.log(this.state.quotedStatement)
    }

    handleOnChangePreparedBy=(e)=>{
        // this.setState({
        //     preparedBy   : e.target.value
        // })
        // //console.log(this.state.preparedBy)

        if(e.length==0)
        {
            this.state.preparedBy = ""
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.preparedBy = e[0].employeeName
        this.state.prepareByPosition = e[0].position

        this.setState({isloading:false})
    }

    handleOnChangeNotedBy=(e)=>{
        // this.setState({
        //     notedBy   : e.target.value
        // })
        // //console.log(this.state.notedBy)

        if(e.length==0)
        {
            
            this.state.notedBy = ""
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.notedBy = e[0].employeeName
        this.state.notedByPosition = e[0].position

        this.setState({isloading:false})
    }

    handleOnChangePrepareByPosition=(e)=>{
        this.setState({
            prepareByPosition   : e.target.value
        })
        //console.log(this.state.prepareByPosition)
    }

    handleOnChangenotedByPosition=(e)=>{
        this.setState({
            notedByPosition   : e.target.value
        })
        //console.log(this.state.prepareByPosition)
    }

    handleOnChangeReceivedBy=(e)=>{
        this.setState({
            receivedBy   : e.target.value
        })
        //console.log(this.state.receivedBy)
    }

    handleOnChangeIrDateIssued=(e)=>{
        this.setState({
            irDateIssued   : e.target.value
        })
        console.log(this.state.irDateIssued)
    }

    handleOnChangeDateRecieveByER=(e)=>{
        this.setState({
            dateRecieveByER   : e.target.value
        })
        console.log(this.state.dateRecieveByER)
    }

   /*  handleOnChangeReferenceNoNTE=(e)=>{
        this.setState({
            referenceNoNTE   : e.target.value
        })
        console.log(this.state.referenceNoNTE)
    } */

    getViewDraftMemo=()=>{
        let viewdataCOCRef= JSON.parse(sessionStorage.getItem("viewdataCOCRefTmp"))

        if(viewdataCOCRef == "1" || viewdataCOCRef === "1" ){
            let dataCOCRefTmp = JSON.parse(sessionStorage.getItem("dataCOCRef"))

            this.setState({
                articleNo       :   dataCOCRefTmp.articleNo,
                articleName     :   dataCOCRefTmp.articleName,
                sectionNo       :   dataCOCRefTmp.sectionNo,
                infraction      :   dataCOCRefTmp.infraction,
                category        :   dataCOCRefTmp.category,
                articleNo2      :   dataCOCRefTmp.articleNo2,
                articleName2    :   dataCOCRefTmp.articleName2,
                sectionNo2      :   dataCOCRefTmp.sectionNo2,
                infraction2     :   dataCOCRefTmp.infraction2,
                category2       :   dataCOCRefTmp.category2,
                sectionNo3      :   dataCOCRefTmp.sectionNo3,
                infraction3     :   dataCOCRefTmp.infraction3,
                category3       :   dataCOCRefTmp.category3,

                cocReferences: true
            })

        }else{

            this.setState({
                cocReferences: false
            })

        }
    }

    handleToExportNTE=()=>{

        if(this.state.eSignatureNotedBy == ""){
           
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please insert e-signature",
                fade            :   true
            })
            return
            
        }

        if(this.state.eSignaturePreparedBy == ""){
          
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please insert e-signature",
                fade            :   true
            })
            return
          
        }
       
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');
        let newdate = moment(this.state.dateApplied).format('MM/DD/YYYY');
        let data = {
            "client"                :   this.state.selectedClientName,
            "employeeName"          :   this.state.selectedEmployeeName,
            "position"              :   this.state.position,
            "employeeNo"            :   this.state.selectedEmployeeNo,
            "location"              :   this.state.selectedEmployeeBranch,
            "dateOfNOH"                  :   newdate,
            "referenceNoNTE"        :   this.state.referenceNoNTE,
            "irReferenceNo"         :   this.state.irReferenceNo,
            "irDateIssued"          :   this.state.irDateIssued,
            "dateRecieveByER"       :   this.state.dateRecieveByER,
            "paragraph"             :   this.state.paragraph,
            "addedparagraph1"       :   this.state.addedparagraph1,
            "addedparagraph2"       :   this.state.addedparagraph2,
            "addedparagraph3"       :   this.state.addedparagraph3,
            "addedparagraph4"       :   this.state.addedparagraph4,
            "addedparagraph5"       :   this.state.addedparagraph5,
            "addedparagraph6"       :   this.state.addedparagraph6,
            "quotedStatement"       :   this.state.quotedStatement,
            "articleNo"             :   this.state.articleNo,
            "articleName"           :   this.state.articleName,
            "sectionNo"             :   this.state.sectionNo,
            "infraction"            :   this.state.infraction,
            "category"              :   this.state.category,
            //"memotype"              :   this.state.selectedmemoType,

            "articleNo2"             :   this.state.articleNo2,
            "articleName2"           :   this.state.articleName2,
            "sectionNo2"             :   this.state.sectionNo2,
            "infraction2"            :   this.state.infraction2,
            "category2"              :   this.state.category2,

            "sectionNo3"             :   this.state.sectionNo3,
            "infraction3"            :   this.state.infraction3,
            "category3"              :   this.state.category3,

            "preparedBy"            :   this.state.preparedBy,
            "prepareByPosition"     :   this.state.prepareByPosition,
            "notedBy"               :   this.state.notedBy,
            "notedByPosition"       :   this.state.notedByPosition,
            "receivedBy"            :   this.state.receivedBy,
            "eSignatureNotedBy"     :   this.state.eSignatureNotedBy,
            "eSignaturePreparedBy"  :   this.state.eSignaturePreparedBy,

            "isDraft"               :   "0",
            "isDeleted"             :   "0",
            "createdby"             :   this.state.userinfo.userId,
            "createddate"           :   createddate,
            "modifiedby"            :   'user007',
            "modifieddate"          :   '0',
            "isModified"            :   '0',   

        }

        console.log(data)

        sessionStorage.setItem("data", JSON.stringify(data))
        let viewDraftMemoTmp = "1"
        sessionStorage.setItem("viewDraftMemoTmp", JSON.stringify(viewDraftMemoTmp))


        window.location.href='/ExportNOH'

        /*if(this.state.selectedmemoType == "Late & Absenteeism"){
            window.location.href='/ExportNTE'
        }
         if(this.state.selectedmemoType == "AWOL 1ST Notice"){
            window.location.href='/ExportAwol1stNotice'
        }
        if(this.state.selectedmemoType == "AWOL 2ND Notice"){
            window.location.href='/ExportAwol2ndNotice'
        }
        if(this.state.selectedmemoType == "Notice of Hearing"){
            window.location.href='/ExportNOH'
        }
        if(this.state.selectedmemoType == "Recall"){
            window.location.href='/ExportRecall'
        }
        if(this.state.selectedmemoType == "Guarantor Demand Letter"){
            window.location.href='/ExportGuarantorDemandLetter'
        } */

    }

    handleOnChangeeSignatureNotedBy=(e)=>{

        const reader = new FileReader();
        reader.onload=()=>{
            if(reader.readyState === 2){
                this.setState({
                    eSignatureNotedBy:reader.result
                })
            }
        }
        reader.readAsDataURL(e.target.files[0])
    }

    handleOnChangeeSignaturePreparedBy=(e)=>{
 
        const reader = new FileReader();
        reader.onload=()=>{
            if(reader.readyState === 2){
                this.setState({
                    eSignaturePreparedBy:reader.result
                })
            }
        }
        reader.readAsDataURL(e.target.files[0])
    }

    onChangememoType = (e) => {       

        if(e.length==0)
        {
            this.setState({selectedmemoType: "" })          
            return
        }

        this.state.selectedmemoType = e[0].name

    }

    setBack=()=>{
        let whenBack = 0
        sessionStorage.setItem("nextProcessCheck", JSON.stringify(whenBack)) 

    }

    handleNotApplicable=()=>{
        this.state.checkNotApplicable = "1"
        this.getNTErefNo()
    }

    render() {        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            
                            <Card.Header>Notice Of Hearing</Card.Header>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Card.Body>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        IR Reference No.
                                    </Form.Label>
                                    <Col sm="2" style={{marginLeft : "-100px"}}>
                                        {/* <Typeahead
                                            labelKey='irReferenceNo'
                                            id="basic-example"
                                            onChange={this.onChangeIrRefNo}
                                            options={this.state.irReferenceNoData}
                                            //selected={[this.state.selectedEmployeeName]}
                                            disabled={this.state.disableEmployee}
                                            //placeholder={this.state.employeePlaceHolder}
                                        /> */}
                                        <Form.Control 
                                            type="text"
                                            name="irReferenceNo" 
                                            value={this.state.selectedirReferenceNo}
                                            onChange={this.onChangeEmployeesNo} 
                                            autoComplete="off"
                                            placeholder="Autofill"
                                            disabled={this.state.disableEmployee}
                                        />
                                    </Col>

                                </Form.Group>
                                <Card.Header className="mt-2">Employee Details</Card.Header>
                                <Form>
                                    
                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Client
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClientList}
                                                options={this.state.getClientList}
                                                selected={[this.state.selectedClientName]}
                                                disabled={this.state.disableEmployee}
                                                //placeholder={this.state.clientPlaceHolder}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee
                                        </Form.Label>
                                        <Col sm="7">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.onChangeEmployeesList}
                                                options={this.state.getEmployeeList}
                                                selected={[this.state.selectedEmployeeName]}
                                                disabled={this.state.disableEmployee}
                                                placeholder={this.state.employeePlaceHolder}
                                            />
                                        </Col>

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee No.
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeNo" 
                                                value={this.state.selectedEmployeeNo}
                                                onChange={this.onChangeEmployeesNo} 
                                                autoComplete="off"
                                                placeholder="Autofill"
                                                disabled={this.state.disableEmployee}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text"
                                                name="position" 
                                                value={this.state.position}
                                                onChange={this.onChangePosition} 
                                                autoComplete="off"
                                                placeholder="Autofill"
                                                disabled={this.state.disableEmployee}
                                            />
                                        </Col>
                                        <Col sm="2"></Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Location
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text"
                                                name="selectedLocation"
                                                value={this.state.selectedEmployeeBranch}
                                                onChange={this.onChangeLocation} 
                                                autoComplete="off"
                                                placeholder="Auto Fill"
                                                disabled={this.state.disableEmployee}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row}>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Date
                                        </Form.Label>
                                        <Col  sm="2" style={{marginLeft : "-1px"}}>
                                            <DatePicker
                                                ref='dateApplied'
                                                selected={this.state.dateApplied}
                                                onChange={this.handleChangeDate}
                                                minDate={this.minDate}
                                                value={this.props.dateApplied}
                                                dateFormat={"MM/dd/yyyy"}
                                                /* placeholderText="DATE APPLIED" */
                                                className="form-control"
                                            />
                                        </Col>


                                        <Form.Label row sm="2" style={{fontWeight : "bold",}}>
                                            NOH Reference No.
                                        </Form.Label>
                                        
                                        <Col sm="2" style={{marginLeft : "73px"}}>

                                            <Input
                                                labelKey='name'
                                                id="basic-example"
                                                //value={this.state.dateReported}
                                                className="form-control"
                                                //onChange={this.handleOnChangeReferenceNoNTE}
                                                autoComplete="off"
                                                value={this.state.referenceNoNTE}
                                                placeholder="AUTO GENERATE"
                                                disabled={true}
                                                
                                            />

                                        </Col>

                                    </Form.Group>

                                    {/* <Form.Group as={Row} controlId="formPlaintextEmail">

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Types
                                        </Form.Label>
                                        <Col sm="4">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangememoType}
                                                options={this.state.memoTypeList}
                                                placeholder="Select Memo Type"
                                            />
                                        </Col>
                                      
                                    </Form.Group> */}

                                   {/*  <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Button variant="success"  style={{minWidth:'80px',  marginLeft : "50px" }} onClick={this.handleUpdateClick}>
                                           Attached IR
                                        </Button>
                                    </Form.Group> */}
                                    
                                    <Form.Group as={Row} controlId="formPlaintextEmail"> </Form.Group>
                                    
                                    <Card.Header className="mt-3">Memorandum Details</Card.Header>
                                    
                                    <div className="mt-1">

                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                            <Col>
                                                <FormControl className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChangeParagraph} rows="3" value={this.state.paragraph} />
                                                
                                            </Col>                    
                                        </Form.Group>

                                        { this.state.quotedStatementCheck ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Quoted Statement)" onChange={this.handleChangeQuotedStatement} style={{fontWeight : "bold"}}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        }
                                        { this.state.addedparagraph1Check ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChangeAddedParagraph1} value={this.state.addedparagraph1}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        }
                                        
                                        { this.state.addedparagraph2Check ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChangeAddedParagraph2} value={this.state.addedparagraph2}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        }  

                                        { this.state.addedparagraph3Check ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChangeAddedParagraph3} value={this.state.addedparagraph3}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        } 

                                        { this.state.addedparagraph4Check ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChangeAddedParagraph4} value={this.state.addedparagraph4}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        }  

                                        { this.state.addedparagraph5Check ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChangeAddedParagraph5} value={this.state.addedparagraph5}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        }

                                        { this.state.addedparagraph6Check ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChangeAddedParagraph6} value={this.state.addedparagraph6}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        } 


                                        {/* <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">                                       
                                            <Col>
                                            
                                                {this.createUI()}  
                                            </Col>                                        
                                        </Form.Group>   */}                               
                                   
                                    </div>
                                    <ButtonToolbar sm={12} className="mt-4">
                                        <Col>                                            
                                            <Button variant="success" style={{minWidth:'280px'}} onClick={this.handleAddedParagraph1}>
                                                Add Paragraph
                                            </Button>                                            
                                        </Col>&nbsp;&nbsp;
                                        
                                       {/*  <Col>                                           
                                            <Button variant="success" style={{minWidth:'280px'}} onClick={this.handleQuotedStatementCheck}>
                                                Insert QuotedStatement
                                            </Button>                                           
                                        </Col>
                                        <Col>                                        
                                            <Button variant="success" style={{minWidth:'280px'}} onClick={this.showTrainingRegisterModal}>
                                                Insert COC Reference
                                            </Button>                                           
                                        </Col> */}
                                       {/*  <Col>
                                            <NavLink to="/home">
                                                <Button variant="success" href="/home" style={{minWidth:'280px'}}>
                                                    View Draft Memo
                                                </Button>
                                            </NavLink>
                                        </Col> */}
                                    </ButtonToolbar>

                                    {<Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Prepared By (Client) :
                                        </Form.Label>
                                        <Col sm="7" style={{marginLeft:"-125px"}}>
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangePreparedbyClientList}
                                                options={this.state.getClientList}
                                                selected={[this.state.selectedClientName]}
                                                //disabled={this.state.disableEmployee}
                                                //placeholder={this.state.clientPlaceHolder}
                                            />
                                        </Col>
                                    </Form.Group>}
                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Prepared By:
                                        </Form.Label>
                                      {/*   <Col sm="3">
                                            <Input
                                                type="text"
                                                name="name" 
                                                className="form-control"
                                                value={this.state.preparedBy}
                                                onChange={this.handleOnChangePreparedBy} 
                                                autoComplete="off"
                                            />
                                        </Col> */}

                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.handleOnChangePreparedBy}
                                                options={this.state.getEmployeeList}
                                                selected={[this.state.preparedBy]}
                                                //disabled={this.state.disableEmployee}
                                                //placeholder={this.state.employeePlaceHolder}
                                            />
                                        </Col>

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.prepareByPosition}
                                                onChange={this.handleOnChangePrepareByPosition} 
                                                autoComplete="off"
                                                //placeholder="Enter Company"
                                            />
                                        </Col>

                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Noted By:
                                        </Form.Label>
                                      {/*   <Col sm="3">
                                            <Input 
                                                type="text"
                                                name="name"
                                                value={this.state.notedBy}
                                                onChange={this.handleOnChangeNotedBy} 
                                                autoComplete="off"
                                                //placeholder="Enter Company"
                                            />
                                        </Col> */}

                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.handleOnChangeNotedBy}
                                                options={this.state.getEmployeeList}
                                                selected={[this.state.notedBy]}
                                                //disabled={this.state.disableEmployee}
                                                //placeholder={this.state.employeePlaceHolder}
                                            />
                                        </Col>

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.notedByPosition}
                                                onChange={this.handleOnChangenotedByPosition} 
                                                autoComplete="off"
                                                //placeholder="Enter Company"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Attached eSignature PreparedBy (Prepared By):
                                        </Form.Label>
                                        <Col sm="2" >                                                 
                                            <Input type="file" className="file" placeholder="eSignaturePreparedBy" variant="primary" accept="image"  onChange={this.handleOnChangeeSignaturePreparedBy}/>                                          
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Attached eSignature NotedBy (Noted By):
                                        </Form.Label>
                                        <Col sm="2" >                                                 
                                            <Input type="file" className="file" placeholder="eSignatureNotedBy" variant="primary" accept="image"  onChange={this.handleOnChangeeSignatureNotedBy}/>                                          
                                        </Col>
                                    </Form.Group>

                                   {/*  <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Received By:
                                        </Form.Label>
                                        <Col sm="5">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.receivedBy}
                                                onChange={this.handleOnChangeReceivedBy} 
                                                autoComplete="off"
                                                //placeholder="Enter Company"
                                            />
                                        </Col>
                                    </Form.Group> */}

                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                        <Col  sm={12}>
                                            <ButtonToolbar className="mt-5">
                                                <Button disabled={this.state.disabledSave} /* className="ml-auto" */ variant="success" onClick = { this.getNTErefNo } style={{minWidth:'60px'}}>Save</Button>&nbsp;&nbsp;&nbsp;
                                                <Button  href="/EmployeeDiciplineMonitoring" variant="danger" style={{minWidth:'60px'}} onClick={this.setBack}>Back</Button>&nbsp;&nbsp;
                                                {<Button variant="primary" style={{minWidth:'60px'}} onClick={this.handleNotApplicable}> Not Applicable </Button>}
                                               {/*  <NavLink disabled={this.state.disabledGenerate} to="/ExportNTE"  className="ml-auto"> */}
                                                    <Button /* disabled={this.state.disabledGenerate} */ variant="success" onClick={this.handleToExportNTE} /* href="/ExportNTE" */ style={{minHeight:"18px",minWidth:'60px', marginLeft:"1200px", marginTop:"-1px"}}>Generate NOH Memo</Button>&nbsp;&nbsp;
                                                {/* </NavLink> */}
                                            </ButtonToolbar>                                          
                                        </Col>                
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                   { <InsertCOCReference 
                        show={this.state.modalCoCReferenceShow}
                        onHide={this.handleModalClose}
                    />}
            </div> 
        )
    }

}

export  default NoticeOfHearing
