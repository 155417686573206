import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration, NumberFormat,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Tabs, Tab, Accordion, 
}
from '../../noser-hris-component';

class ManageEmployee201 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            disableEdit     :   true,
            disableresigned :   true,
            disablecardnumber:   false,

            profileDisable  :   true,
            editProfileText :   "EDIT",
            saveProfileBtn  :   true,

            recordDisable   :   true,
            editRecordText  :   "EDIT",
            saveRecordBtn   :   true,

            documentDisable :   true,
            editDocumentText:   "EDIT",
            saveDocumentBtn :   true,
            IsOneFile       :   "0",
            UploadedFile    :   "",

            clientId    :   "",
            employeeId  :   "",
            clientDDL   :   [],
            employeeDDL :   [],
            employeeList:   [],

            /*PROFILE*/
            nickName        :   "",
            firstName       :   "",
            middleName      :   "",
            lastName        :   "",
            homeNumber      :   "",
            officeNumber    :   "",
            mobileNumber    :   "",
            emailAddress    :   "",
            facebook        :   "",
            twitter         :   "",
            dateofBirth     :   "",
            age             :   "",
            placeofBirth    :   "",
            height          :   "",
            weight          :   "",
            languageSpoken  :   "",
            hobbies         :   "",
            contactName     :   "",
            contactNumber   :   "",
            contactRelation :   "",
            sssNumber       :   "",
            tinNumber       :   "",
            phicNumber      :   "",
            hdmfNumber      :   "",
            
            arrangementId   :   "",
            arrangement     :   "",
            bloodtypeId     :   "",
            bloodtype       :   "",
            nationalityId   :   "",
            nationality     :   "",
            religionId      :   "",
            religion        :   "",
            genderId        :   "",
            gender          :   "",
            civilstatusId   :   "",
            civilstatus     :   "",
            profileStatus   :   "",
            sourceEmployee   :   "",
            sourceProfile   :   "",

            arrangementDDL  :   [],
            bloodtypeDDL    :   [],
            nationalityDDL  :   [],
            religionDDL     :   [],
            genderDDL       :   [
                {"id":"1","name":"MALE"},
                {"id":"2","name":"FEMALE"}
            ],
            civilstatusDDL   :   [
                {"id":"1","name":"SINGLE"},
                {"id":"2","name":"MARRIED"},
                {"id":"3","name":"DIVORCED"},
                {"id":"4","name":"SEPARATED"}
            ],
            yesNoDDL    :   [
                { "value" : "1", "label" : "YES" },
                { "value" : "0", "label" : "NO" },
            ],
            /*201*/
            employeeNo      :   "",
            locationId      :   "",
            positionId      :   "",
            employmentId    :   "",
            cardtypeId      :   "",
            paytypeId       :   "",
            paymodeId       :   "",
            periodtypeId    :   "",
            scheduleId      :   "",
            approver1Id     :   "",
            approver2Id     :   "",
            salaryRate      :   "",
            seaRate         :   "",
            ecolaRate       :   "",
            colaRate        :   "",
            isdefaultLocation:  "",
            cardNumber      :   "",
            dateHired       :   "",
            dateResigned    :   "",
            contractStart   :   "",
            contractEnd     :   "",

            clientName      :   "",
            location        :   "",
            position        :   "",
            employment      :   "",
            cardtype        :   "",
            paytype         :   "",
            paymode         :   "",
            periodtype      :   "",
            schedule        :   "",
            approver1       :   "",
            approver2       :   "",
            remarks         :   "",

            suffixName      :   "",
            sssNumberRemarks       :   "",
            tinNumberRemarks       :   "",
            phicNumberRemarks      :   "",
            hdmfNumberRemarks      :   "",

            locationDDL    :   [],
            positionDDL    :   [],
            employmentDDL  :   [],
            cardtypeDDL    :   [],
            paytypeDDL     :   [],
            paymodeDDL     :   [],
            scheduleDDL    :   [],
            periodtypeDDL  :   [],
            periodtypeList :   [],
            approver1DDL   :   [],
            approver2DDL   :   [],
            

            backgroundGRID :   [],
            addressGRID    :   [],
            educationGRID  :   [],
            employmentGRID :   [],
            applicationGRID:   [],
            trainingGRID   :   [],
            documentGRID   :   [],
            librarianGRID  :   [],

            backgroundGRIDDel :   [],
            addressGRIDDel    :   [],
            educationGRIDDel  :   [],

            addrtypeDDL    :   [
                {"value":"0","label":"CURRENT ADDRESS"},
                {"value":"1","label":"REGISTERED ADDRESS"},
                {"value":"2","label":"PROVINCIAL ADDRESS"},
                {"value":"3","label":"CORRESPONDENCE ADDRESS"},
                {"value":"4","label":"FOREIGN ADDRESS"}
            ],
            roleDDL         :   [
                {"value":"0","label":"FATHER"},
                {"value":"1","label":"MOTHER"},
                {"value":"2","label":"SPOUSE"},
                {"value":"3","label":"BROTHER"},
                {"value":"4","label":"SISTER"},
                {"value":"5","label":"CHILD"},
            ],
            levelDDL         :   [
                {"value":"0","label":"COLLEGE"},
                {"value":"1","label":"HIGH SCHOOL"},
                {"value":"5","label":"SENIOR HIGH SCHOOL"},
                {"value":"4","label":"JUNIOR HIGH SCHOOL"},
                {"value":"2","label":"ELEMENTARY"},
                {"value":"3","label":"OTHERS"},
            ],
            regionDDL      :   [],
            provinceDDL    :   [],
            cityDDL        :   [],

            hmoId:"0",
            cabinetId:"",
            drawerId:"",
            hmo:"",
            hmoDate:"",
            cabinet:"",
            drawer:"",
            hmoDDL:[
                {
                    "id":"0",
                    "name":"W/O"
                },
                {
                    "id":"1",
                    "name":"WITH"
                },
                {
                    "id":"2",
                    "name":"WAIVER"
                }
            ],
            cabinetDDL:[],
            drawerDDL:[],

            hasEditSalaryAccess: true,
            hasViewSalaryAccess: false,
            hasEditPayCardTypeAccess: true,
            hasViewPayCardTypeAccess: false,
            hasEditPayCardNumberAccess: true,
            hasViewPayCardNumberAccess: false
        }

    }
    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        //this.state.data = JSON.parse(sessionStorage.getItem("employee201_" + this.state.userinfo.userId + this.state.userinfo.employeeId))
        this.GetClients()

        if(this.state.userinfo.roleId==="1"){
            this.state.hasEditSalaryAccess = false
            this.state.hasViewSalaryAccess = true
            this.state.hasEditPayCardTypeAccess = false
            this.state.hasViewPayCardTypeAccess = true
            this.state.hasEditPayCardNumberAccess = false
            this.state.hasViewPayCardNumberAccess = true
        }
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            console.log("data client")
            console.log(data)
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL : arr})
            }
            else{
                this.setState({clientDDL : data.clients})
            }
            console.log(this.state.clientDDL)
            this.GetProfiles()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetProfiles = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.clientId
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter", params)
        .then(res => {
            const data = res.data;
            let arr = []
            console.log("data profile")
            console.log(data)
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.profiles.filter(f => brr.some(item => item.referenceId === f.clientId))
                    }
                }
                this.setState({employeeDDL : arr,employeeList : arr})
            }
            else{
                this.setState({employeeDDL : data.profiles,employeeList:data.profiles})
            }
            //this.setState({employeeDDL : data.profiles,employeeList:data.profiles})
            console.log(this.state.employeeDDL)
            this.GetRegions()
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }
    GetNationalities = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId"     :   "",
            "DepartmentId"  :   "",
            "Name"          :   ""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetNationalities",  params)
        .then(res => {
            const data = res.data;
            this.setState({nationalityDDL:data.nationalities});
        })
        this.GetReligions()
    }
    GetReligions = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetReligions",  params)
        .then(res => {
            const data = res.data;
            this.setState({religionDDL:data.religions});
        })
        this.GetLivingArrangements()
    }
    GetLivingArrangements = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetLivingArrangements",  params)
        .then(res => {
            const data = res.data;
            this.setState({arrangementDDL:data.livingArrangements});
        })
        this.GetBloodTypes()
    }
    GetBloodTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetBloodTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({bloodtypeDDL:data.bloodTypes});
        })
        this.GetPositions()
    }
    GetPositions = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId"     :   "",
            "DepartmentId"  :   "",
            "Name"          :   ""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  params)
        .then(res => {
            const data = res.data;
            this.setState({positionDDL:data.positions});
        })
        this.GetPayrollTypes()
    }
    GetPayrollTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({paytypeDDL:data.payrollTypes});
        })
        this.GetPayrollModes()
    }
    GetPayrollModes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollModes",  params)
        .then(res => {
            const data = res.data;
            this.setState({paymodeDDL:data.payrollModes});
        })
        this.GetWorkSchedules()
    }
    GetWorkSchedules = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetWorkingSchedules",  params)
        .then(res => {
            const data = res.data;
            this.setState({scheduleDDL:data.workSchedules});
        })
        this.GetPeriodTypes()
    }
    GetPeriodTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "PayModeId"     :   ""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPeriodTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({periodtypeList:data.periodTypes});
            console.log(data)
        })
        this.GetEmployeeStatusTypes()
    }
    GetEmployeeStatusTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetEmployeeStatusTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({employmentDDL:data.statusTypes});
        })
        this.GetPayCardTypes()
    }
    GetPayCardTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayCardTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({cardtypeDDL:data.payCardTypes});
        })
        this.GetRegions()
    }
    GetRegions = async () => {

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":""
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetRegions",  params)
        .then(res => {
            let regionDDL = []
            res.data.regions.map(function(itm){
                regionDDL.push({
                    value : itm.id,
                    label : itm.name,
                })
            })
            this.setState({regionDDL:regionDDL})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetProvinces()
    }
    GetProvinces = async () => {
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Region": ""
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetProvinces",  params)
        .then(res => {
            let provinceDDL = []
            res.data.provinces.map(function(itm){
                provinceDDL.push({
                    value : itm.id,
                    label : itm.name,
                    regionId:itm.regionId
                })
            })
            this.setState({provinceDDL:provinceDDL})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetCities()
    }
    GetCities = async () => {

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":"",
            "ProvinceName": ""
        };
        await
         axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetCities",  params)
        .then(res => {
            let cityDDL = []
            res.data.cities.map(function(itm){
                cityDDL.push({
                    value : itm.id,
                    label : itm.name,
                    provinceId:itm.provinceId
                })
            })
            this.setState({cityDDL:cityDDL,isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        //this.GetCabinets()
    }
    GetCabinets = async() =>{
        this.setState({isloading:true,isDisable:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            //"Name" : this.state.roleName
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetCabinets", params)
        .then(res => {
            const data = res.data
            if(data.status=="1"){
                this.setState({
                    isloading   :   false,
                    cabinetDDL     :   data.cabinets
                });
            }
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    cabinetDDL     :   data.cabinets
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
        this.GetDrawers()
    }
    GetDrawers = async() =>{
        this.setState({isloading:true,isDisable:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name" : this.state.name
        }
        console.log(params)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDrawers", params)
        .then(res => {
            const data = res.data
            if(data.status=="1"){
                this.setState({
                    isloading   :   false,
                    drawerDDL     :   data.drawers
                });
            }
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    drawerDDL     :   data.drawers
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    GetEmployeeInformation = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.employeeId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeInformation", params)
        .then(res => {
            const data = res.data;
            if(data.status==="1"){

                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){

                    if(fieldaccess.filter(x=>x.accessCode==="0001" && x.fieldName==="SalaryRate" ).length>0){
                        let access = fieldaccess.find(x=>x.accessCode==="0001" && x.fieldName==="SalaryRate")
                        if(access.controlAccessDetails.filter(x=>x.isClient==="1" && x.referenceId===data.clientId).length>0){
                            this.state.hasEditSalaryAccess = access.hasEdit==="1" ? false : true
                            this.state.hasViewSalaryAccess = access.hasView==="1" ? true : false
                        }
                        if(access.controlAccessDetails.filter(x=>x.isClient==="0" && x.referenceId===data.positionId).length>0){
                            this.state.hasEditSalaryAccess = access.hasEdit==="1" ? false : true
                            this.state.hasViewSalaryAccess = access.hasView==="1" ? true : false
                        }
                    }

                    if(fieldaccess.filter(x=>x.accessCode==="0001" && x.fieldName==="PayCardType" ).length>0){
                        let access = fieldaccess.find(x=>x.accessCode==="0001" && x.fieldName==="PayCardType")
                        if(access.controlAccessDetails.filter(x=>x.isClient==="1" && x.referenceId===data.clientId).length>0){
                            this.state.hasEditPayCardTypeAccess = access.hasEdit==="1" ? false : true
                            this.state.hasViewPayCardTypeAccess = access.hasView==="1" ? true : false
                        }
                        if(access.controlAccessDetails.filter(x=>x.isClient==="0" && x.referenceId===data.positionId).length>0){
                            this.state.hasEditSalaryAccess = access.hasEdit==="1" ? false : true
                            this.state.hasViewSalaryAccess = access.hasView==="1" ? true : false
                        }
                    }

                    if(fieldaccess.filter(x=>x.accessCode==="0001" && x.fieldName==="PayCardNumber" ).length>0){
                        let access = fieldaccess.find(x=>x.accessCode==="0001" && x.fieldName==="PayCardNumber")
                        if(access.controlAccessDetails.filter(x=>x.isClient==="1" && x.referenceId===data.clientId).length>0){
                            this.state.hasEditPayCardNumberAccess = access.hasEdit==="1" ? false : true
                            this.state.hasViewPayCardNumberAccess = access.hasView==="1" ? true : false
                        }
                        if(access.controlAccessDetails.filter(x=>x.isClient==="0" && x.referenceId===data.positionId).length>0){
                            this.state.hasEditSalaryAccess = access.hasEdit==="1" ? false : true
                            this.state.hasViewSalaryAccess = access.hasView==="1" ? true : false
                        }
                    }
                }

                this.setState({
                    employeedetails : data,
                    employeeNo:data.employeeNo,
                    firstName:data.firstName,
                    middleName:data.middleName,
                    lastName:data.lastName,
                    nickName:data.nickName,
                    homeNumber:data.homePhoneNumber,
                    officeNumber:"",//this.state.employeedetails.nickName,
                    mobileNumber:data.mobileNumber,
                    emailAddress:data.emailAddress,
                    facebook:data.facebook,
                    twitter:data.twitter,
                    dateofBirth:data.dateOfBirth !=="" ? new Date(data.dateOfBirth) : "",
                    age:data.age,
                    placeofBirth:data.placeOfBirth,
                    height:data.height,
                    weight:data.weight,
                    languageSpoken:data.languageSpoken,
                    hobbies:data.hobbies,
                    contactName:data.emergencyContactName,
                    contactNumber:data.emergencyContactNumber,
                    contactRelation:data.emergencyContactRelation,
                    sssNumber:data.sssNumber,
                    tinNumber:data.tinNumber,
                    phicNumber:data.phicNumber,
                    hdmfNumber:data.hdmfNumber,
                    arrangementId:data.livingArrangementId,
                    arrangement:data.livingArrangement,
                    bloodtypeId:data.bloodTypeId,
                    bloodtype:data.bloodType,
                    nationalityId:data.nationalityId,
                    nationality:data.nationality,
                    religionId:data.religionId,
                    religion:data.religion,
                    genderId:data.genderId,
                    gender:data.gender,
                    civilstatusId: data.civilStatusId,
                    civilstatus:data.civilStatus,
                    profileStatus:data.profileStatus,

                    locationId:data.locationId,
                    positionId:data.positionId,
                    employmentId:data.employmentStatusId,
                    cardtypeId:data.payCardTypeId,
                    paytypeId:data.payTypeId,
                    paymodeId:data.payModeId,
                    periodtypeId:data.periodTypeId,
                    scheduleId:data.workScheduleId,
                    salaryRate:data.salaryRate,
                    seaRate:data.seaRate,
                    ecolaRate:data.ecolaRate,
                    colaRate:data.colaRate,
                    isdefaultLocation:data.isDefaultLocation,
                    cardNumber:data.payCardNumber,
                    dateHired:data.dateHired !=="" ? new Date(data.dateHired) : "",
                    dateResigned:data.dateResigned !=="" ? new Date(data.dateResigned) : "",
                    contractStart:data.contractDateStart !=="" ? new Date(data.contractDateStart) : "",
                    contractEnd:data.contractDateEnd !=="" ? new Date(data.contractDateEnd) : "",
                    clientName:data.client,
                    location:data.location,
                    position:data.position,
                    employment:data.employmentStatus,
                    cardtype:data.payCardType,
                    paytype:data.payType,
                    paymode:data.payMode,
                    periodtype:data.periodType,
                    schedule:data.workSchedule,
                    approver1Id:data.approverId1,
                    approver2Id:data.approverId2,
                    approver1:data.approver1,
                    approver2:data.approver2,
                    remarks:data.remarks,
                    periodtypeDDL:data.payModeId!=="" ? this.state.periodtypeList.filter(x=>x.payModeId===data.payModeId) : this.state.periodtypeList,

                    sourceProfile:data.sourceProfile,
                    sourceEmployee:data.sourceEmployee,

                    hmoId:data.hmoId,
                    cabinetId:data.cabinetId,
                    drawerId:data.drawerId,
                    hmo:data.hmo,
                    hmoDate:data.hmoDate,
                    cabinet:data.cabinet,
                    drawer:data.drawer,

                    suffixName:data.suffixName,
                    sssNumberRemarks:data.sssNumberRemarks,
                    tinNumberRemarks:data.tinNumberRemarks,
                    phicNumberRemarks:data.phicNumberRemarks,
                    hdmfNumberRemarks:data.hdmfNumberRemarks,
                })
                
                this.setState({
                    disablecardnumber:data.payCardTypeId=="4" ? true:false,
                    cardNumber:data.payCardTypeId=="4" ? "":data.payCardNumber,
                    disableEdit : false,
                })
            }
            else
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   data.message,
                fade        :   true,
                disableEdit :   true,
                employeeNo:"",
                firstName:"",
                middleName:"",
                lastName:"",
                nickName:"",
                homeNumber:"",
                officeNumber:"",//this.state.employeedetails.nickName,
                mobileNumber:"",
                emailAddress:"",
                facebook:"",
                twitter:"",
                dateofBirth:"",
                age:"",
                placeofBirth:"",
                height:"",
                weight:"",
                languageSpoken:"",
                hobbies:"",
                contactName:"",
                contactNumber:"",
                contactRelation:"",
                sssNumber:"",
                tinNumber:"",
                phicNumber:"",
                hdmfNumber:"",
                arrangementId:"",
                arrangement:"",
                bloodtypeId:"",
                bloodtype:"",
                nationalityId:"",
                nationality:"",
                religionId:"",
                religion:"",
                genderId:"",
                gender:"",
                civilstatusId:"",
                civilstatus:"",
                profileStatus:"",

                locationId:"",
                positionId:"",
                employmentId:"",
                cardtypeId:"",
                paytypeId:"",
                paymodeId:"",
                periodtypeId:"",
                scheduleId:"",
                schedule:"",
                salaryRate:"",
                seaRate:"",
                ecolaRate:"",
                colaRate:"",
                isdefaultLocation:"",
                cardNumber:"",
                dateHired:"",
                dateResigned:"",
                contractStart:"",
                contractEnd:"",
                clientName:"",
                location:"",
                position:"",
                employment:"",
                cardtype:"",
                paytype:"",
                paymode:"",
                periodtype:"",
                approver1Id:"",
                approver2Id:"",
                approver1:"",
                approver2:"",
                remarks:"",
                sourceProfile:"",
                sourceEmployee:"",

                hmoId:"",
                cabinetId:"",
                drawerId:"",
                hmo:"",
                hmoDate:"",
                cabinet:"",
                drawer:"",
                suffixName:"",
                sssNumberRemarks:"",
                tinNumberRemarks:"",
                phicNumberRemarks:"",
                hdmfNumberRemarks:""
            })
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetEmployeeInformationDetails()
    }
    GetEmployeeInformationDetails = async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "ProfileId":this.state.profileId,
            "EmployeeId":this.state.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileDetails", params)
        .then(res => {
            const data = res.data;
            if(data.status=="1")
                this.setState({
                    addressGRID: data.employeeAddresses,
                    backgroundGRID: data.employeeFamilyBackgrounds,
                    educationGRID: data.employeeEducations,
                    applicationGRID: data.employeeApplicationRecords,
                    employmentGRID: data.employmentRecords,
                    trainingGRID: data.employeeTrainings,
                    documentGRID: data.employeeDocuments,
                    librarianGRID:data.employeeLibrarianRequests,
                    disableEdit:false
                })
            else
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                })
            this.GetLocations()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetLocations = async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.clientName,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", params)
        .then(res => {
            const data = res.data;
            this.setState({locationDDL : data.locations ? data.locations : []})
            if(data.locations.length=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
       this.GetParamountEmployees()
    }
    GetParamountEmployees = async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetCoorEmployees", params)
        .then(res => {
            const data = res.data;
            this.setState({
                            isloading:false,
                            approver2DDL : data.employees,
                            approver1DDL:this.state.employeeList.filter(x=>x.clientId===this.state.clientId && x.id!==this.state.employeeId)
                        })
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleChangedClient = e =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            //this.setState({clientId:"",employeeDDL:this.state.employeeList}) 
            this.state.clientId = ""
            this.state.employeeDDL=this.state.employeeList
            return
        }
        this.state.clientId = e[0].id
        //this.state.employeeDDL = this.state.employeeList.filter(x=>x.clientId===e[0].id)
        //this.forceUpdate()
        this.setState({employeeDDL:this.state.employeeList.filter(x=>x.clientId===e[0].id),clientName:e[0].name})
        
    }
    handleChangedEmployee = e =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,isloading:false});
        if(e.length===0) {
            this.setState({employeeId:"",profileId:"",disableEdit:true}) 
            return
        }
        this.state.employeeId = e[0].id
        this.state.profileId = e[0].profileId
        this.state.clientId = e[0].clientId
        this.state.clientName = e[0].clientName
    }
    handleChangedNationality = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.nationalityId=""
            this.state.nationality=""
            return
        }
        this.state.nationalityId=e[0].id
        this.state.nationality=e[0].name
    }
    handleChangedReligion = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.religionId=""
            this.state.religion=""
            return
        }
        this.state.religionId=e[0].id
        this.state.religion=e[0].name
    }
    handleChangedGender = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.genderId=""
            this.state.gender=""
            return
        }
        this.state.genderId=e[0].id
        this.state.gender=e[0].name
    }
    handleChangedCivilStatus = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.civilstatusId=""
            this.state.civilstatus=""
            return
        }
        this.state.civilstatusId=e[0].id
        this.state.civilstatus=e[0].name
    }
    handleChangedArrangement = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.arrangementId=""
            this.state.arrangement=""
            return
        }
        this.state.arrangementId=e[0].id
        this.state.arrangement=e[0].name
    }
    handleChangedBloodType = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.bloodtypeId=""
            this.state.bloodtype=""
            return
        }
        this.state.bloodtypeId=e[0].id
        this.state.bloodtype=e[0].name
    }
    handleChangedLocation = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.locationId=""
            this.state.location=""
            return
        }
        this.state.locationId=e[0].id
        this.state.location=e[0].name
    }
    handleChangedPosition = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.positionId=""
            this.state.position=""
            return
        }
        this.state.positionId=e[0].id
        this.state.position=e[0].name
    }
    handleChangedEmployment = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.employmentId=""
            this.state.employment=""
            return
        }
        if( e[0].id==="3" ||
            e[0].id==="5" || 
            e[0].id==="11" ||
            e[0].id==="16" ||
            e[0].id==="17" ||
            e[0].id==="22" ||
            e[0].id==="23" ||
            e[0].id==="24"){
            this.setState({disableresigned:false})
        }
        else{
            this.setState({disableresigned:true})
        }
        this.state.employmentId=e[0].id
        this.state.employment=e[0].name
    }
    handleChangedPayCardType = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.cardtypeId=""
            this.state.cardtype=""
            this.state.disablecardnumber=true
            return
        }
        this.state.cardtypeId=e[0].id
        this.state.cardtype=e[0].name
        this.setState({
            disablecardnumber:e[0].id=="4" ? true:false,
            cardNumber:e[0].id=="4" ? "":this.state.cardNumber
        })
    }
    handleChangedPayType = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.paytypeId=""
            this.state.paytype=""
            return
        }
        this.state.paytypeId=e[0].id
        this.state.paytype=e[0].name
    }
    handleChangedPayMode = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.paymodeId=""
            this.state.paymode=""
            this.state.periodtypeDDL=this.state.periodtypeList
            return
        }
        this.state.paymodeId=e[0].id
        this.state.paymode=e[0].name
        this.setState({periodtypeDDL:this.state.periodtypeList.filter(x=>x.payModeId===e[0].id)})
    }
    handleChangedPeriodType = (e) => {
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.periodtypeId=""
            this.state.periodtype=""
            return
        }
        this.state.periodtypeId=e[0].id
        this.state.periodtype=e[0].name
    }
    handleChangedSchedule = (e) => {
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.scheduleId=""
            this.state.schedule=""
            return
        }
        this.state.scheduleId=e[0].id
        this.state.schedule=e[0].description
    }
    handleChangedApprover1 = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.approver1Id=""
            this.state.approver1=""
            return
        }
        this.state.approver1Id=e[0].id
        this.state.approver1=e[0].employeeName
    }
    handleChangedApprover2 = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.approver2Id=""
            this.state.approver2=""
            return
        }
        this.state.approver2Id=e[0].id
        this.state.approver2=e[0].employeeName
    }
    handleChangedDOB = date =>{
        this.setState({dateofBirth:date})
    }
    handleChangedDR = date =>{
        this.setState({dateResigned:date})
    }
    handleChangedEOC = date =>{
        this.setState({contractEnd:date})
    }
    handleChangedDate = name => date =>{
        this.setState({[name]:date})
    }
    handleChanged = (e) =>{
        if(e.target.name==="salaryRate" ||
            e.target.name==="ecolaRate" ||
            e.target.name==="colaRate" ||
            e.target.name==="seaRate"){
            if(e.target.value.split(".").length>2) return
            let dec = e.target.value.split(".")[1]
            if(typeof dec !='undefined')
                if(dec.length>2) return

        }
        //this.state[e.target.name] = e.target.value
        this.setState({[e.target.name]:e.target.value})
    }
    handleChangedCB = (e) =>{
        this.setState({[e.target.name]: e.target.checked})
    }
    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (('0' + (date.getMonth()+1)).slice(-2) + "/" + ('0' + (date.getDate())).slice(-2) + "/" + date.getFullYear()) : "")
    }
    handleSearchClick = async() => {
        this.GetEmployeeInformation()
    }
    handleClickEditProfile = () =>{
        if(this.state.editProfileText==="EDIT"){
            this.setState({
                editProfileText:"CANCEL",
                saveProfileBtn:false,
                //profileDisable:false
            })
        }else{
            this.setState({
                editProfileText:"EDIT",
                saveProfileBtn:true,
                //profileDisable:true
            })
        }
    }
    handleClickEditRecord = () =>{
        if(this.state.editRecordText==="EDIT"){
            this.setState({
                editRecordText:"CANCEL",
                saveRecordBtn:false,
                recordDisable:false
            })
        }else{
            this.setState({
                editRecordText:"EDIT",
                saveRecordBtn:true,
                recordDisable:true
            })
        }
    }
    handleClickEditDocument = () =>{
        if(this.state.editDocumentText==="EDIT"){
            this.setState({
                editDocumentText:"CANCEL",
                saveDocumentBtn:false,
                documentDisable:false
            })
        }else{
            this.setState({
                editDocumentText:"EDIT",
                saveDocumentBtn:true,
                documentDisable:true
            })
        }
    }
    handleAddAddress = () =>{
        if(this.state.profileId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "No selected employee.",
                fade        :   true
            })
            return
        }
        let addressGRID = this.state.addressGRID
        let newId = 0;
        if(addressGRID.length===0){
            let today = new Date()
            newId = parseInt(moment(today).format('HHMMSS'));
        }
        else{
            let today = new Date()
            const ids = addressGRID.map(x => x.id);
            const sorted = ids.sort((a, b) => a - b);
            const uid = moment(today).format('SS');
            newId = parseInt(sorted[sorted.length-1])+1//+uid;
        }

        addressGRID.push({
            "profileId":this.state.profileId,
            "id" :newId.toString(),
            "typeId":"",
            "regionId" : "",
            "region" : "",
            "provinceId" : "",
            "province" : "",
            "cityId" : "",
            "city" : "",
            "houseNumber" : "",
            "streetName" : "",
            "barangay" : "",
            "postalCode" : "",
            "isDeleted" : "0"
        })
        this.setState({addressGRID:addressGRID})
    }
    handleRemoveAddress = (id) =>{
        let addressGRID = this.state.addressGRID
        let addressGRIDDel = this.state.addressGRIDDel
        addressGRID.map(function(itm){
            if(itm.id===id){
                itm.isDeleted="1"
                addressGRIDDel.push(itm)
            }
        })
        this.setState({
            addressGRID:addressGRID.filter(x=>x.isDeleted==="0")
        })
    }
    handleClickBackground = () =>{
        if(this.state.profileId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "No selected employee.",
                fade        :   true
            })
            return
        }
        let backgroundGRID = this.state.backgroundGRID
        let newId = 0;
        if(backgroundGRID.length===0){
            let today = new Date()
            newId = parseInt(moment(today).format('HHMMSS'));
        }
        else{
            let today = new Date()
            const ids = backgroundGRID.map(x => x.id);
            const sorted = ids.sort((a, b) => a - b);
            const uid = moment(today).format('SS');
            newId = parseInt(sorted[sorted.length-1])+1//+uid;
        }

        backgroundGRID.push({
            "profileId":this.state.profileId,
            "id" :newId.toString(),
            "roleId":"",
            "name" : "",
            "age" : "",
            "occupation" : "",
            "company" : "",
            "isDeleted" : "0"
        })
        this.setState({backgroundGRID:backgroundGRID})
    }
    handleClickBackgroundRemove = (id) =>{
        let backgroundGRID = this.state.backgroundGRID
        let backgroundGRIDDel = this.state.backgroundGRIDDel
        backgroundGRID.map(function(itm){
            if(itm.id===id){
                itm.isDeleted="1"
                backgroundGRIDDel.push(itm)
            }
        })
        this.setState({backgroundGRID:backgroundGRID.filter(x=>x.isDeleted==="0")})
    }
    handleAddEducation = () =>{
        
        if(this.state.profileId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "No selected employee.",
                fade        :   true
            })
            return
        }
        let educationGRID = this.state.educationGRID
        let newId = 0;
        if(educationGRID.length===0){
            let today = new Date()
            newId = parseInt(moment(today).format('HHMMSS'));
        }
        else{
            let today = new Date()
            const ids = educationGRID.map(x => x.id);
            const sorted = ids.sort((a, b) => a - b);
            const uid = moment(today).format('SS');
            newId = parseInt(sorted[sorted.length-1])+1+uid;
        }

        educationGRID.push({
            "profileId":this.state.profileId,
            "id" :newId.toString(),
            "levelId":"",
            "schoolName" : "",
            "course" : "",
            "startYear" : "",
            "endYear" : "",
            "honorRecieved" : "",
            "isDeleted" : "0"
        })
        this.setState({educationGRID:educationGRID})
    }
    handleRemoveEducation = (id) =>{
        let educationGRID = this.state.educationGRID
        let educationGRIDDel = this.state.educationGRIDDel
        educationGRID.map(function(itm){
            if(itm.id===id){
                itm.isDeleted="1"
                educationGRIDDel.push(itm)
            }
        })
        this.setState({educationGRID:educationGRID.filter(x=>x.isDeleted==="0")})
    }
    handleSubmitProfile = async() =>{
        this.setState({
            isloading   :   true,
            isshow      :   false,
            fade        :   true,
            saveProfileBtn:  true,
            color       :   "",
            message     :   "",
            alerttype   :   ""
        })

        let address = this.state.addressGRID.concat(this.state.addressGRIDDel)
        let familyBackgrounds = this.state.backgroundGRID.concat(this.state.backgroundGRIDDel)
        let educations = this.state.educationGRID.concat(this.state.educationGRIDDel)
        
        if(address.filter(x=>x.typeId!=="" && x.regionId==="" && x.isDeleted==="0").length>0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please review address details for some invalid inputs.",
                fade        :   true,
                disablebutton:  false,
                saveProfileBtn:  false,
            });
            return
        }
        if(address.filter(x=>x.typeId!=="" && x.provinceId==="" && x.isDeleted==="0").length>0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please review address details for some invalid inputs.",
                fade        :   true,
                disablebutton:  false,
                saveProfileBtn:  false,
            });
            return
        }

        if(address.filter(x=>x.typeId!=="" && x.cityId==="" && x.isDeleted==="0").length>0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please review address details for some invalid inputs.",
                fade        :   true,
                disablebutton:  false,
                saveProfileBtn:  false,
            });
            return
        }

        if(educations.filter(x=>x.levelId!=="" && x.startYear==="" && x.isDeleted==="0").length>0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please review education details for some invalid inputs.",
                fade        :   true,
                disablebutton:  false,
                saveProfileBtn:  false,
            });
            return
        }

        if(educations.filter(x=>x.levelId!=="" && x.endYear==="" && x.isDeleted==="0").length>0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please review education details for some invalid inputs.",
                fade        :   true,
                disablebutton:  false,
                saveProfileBtn:  false,
            });
            return
        }
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,

            "Id":this.state.profileId,            
            "FirstName":this.state.firstName,
            "MiddleName":this.state.middleName,
            "LastName":this.state.lastName,
            "NickName":this.state.nickName,
            "PhoneNumber":this.state.homeNumber,
            "MobileNumber":this.state.mobileNumber,
            "EmailAddress":this.state.emailAddress,
            "DateOfBirth":this.FormatDate(this.state.dateofBirth),
            "PlaceOfBirth":this.state.placeofBirth,
            "NationalityId":this.state.nationalityId,
            "ReligionId":this.state.religionId,
            "GenderId":this.state.genderId,
            "Gender":this.state.gender,
            "CivilStatusId":this.state.civilstatusId,
            "CivilStatus":this.state.civilstatus,
            "LivingArrangementId":this.state.arrangementId,
            "BloodTypeId":this.state.bloodtypeId,
            "Height":this.state.height,
            "Weight":this.state.weight,
            "Hobbies":this.state.hobbies,
            "LanguageSpoken":this.state.languageSpoken,
            "Facebook":this.state.facebook,
            "Twitter":this.state.twitter,
            "SSSNumber":this.state.sssNumber,
            "PHICNumber":this.state.phicNumber,
            "HDMFNumber":this.state.hdmfNumber,
            "TINNumber":this.state.tinNumber,
            "EmergencyContactName":this.state.contactName,
            "EmergencyContactNumber":this.state.contactNumber,
            "EmergencyContactRelationship":this.state.contactRelation,

            "employeeAddresses":address.filter(x=>x.typeId!==""),
            "employeeFamilyBackgrounds":familyBackgrounds.filter(x=>x.roleId!==""),
            "employeeEducations":educations.filter(x=>x.levelId!==""),
        };
        
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/EditEmployeeProfile",  params)
        .then(res => {
            const data = res.data
            this.setState({isloading:false})
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    saveProfileBtn:  false,
                });
            } 
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true,
                    profileDisable:true,
                    saveProfileBtn:true,
                    editProfileText:"EDIT" 
                });
            } 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
                disablebutton:  false
            })
        })
    }
    handleSubmitRecord = async() =>{
        this.setState({
            isloading   :   true,
            isshow      :   false,
            fade        :   true,
            saveRecordBtn:  true,
            color       :   "",
            message     :   "",
            alerttype   :   ""
        })

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,

            "EmployeeId":this.state.employeeId,
            "ProfileId":this.state.profileId,
            "EmployeeNo":this.state.employeeNo,
            "IsDefaultLocation":this.state.isdefaultLocation ? "1" : "0",
            "LocationId":this.state.locationId,
            "PositionId":this.state.positionId,
            "EmploymentStatusTypeId":this.state.employmentId,
            "PayTypeId":this.state.paytypeId,
            "PayModeId":this.state.paymodeId,
            "PeriodTypeId":this.state.periodtypeId,
            "WorkScheduleId":this.state.scheduleId,
            "PayCardTypeId":this.state.cardtypeId,
            "PayCardNumber":this.state.cardNumber,
            "SalaryOffered":this.state.salaryRate,
            "SeaValue":this.state.seaRate,
            "EColaValue":this.state.ecolaRate,
            "ColaValue":this.state.colaRate,
            "DateHired":this.FormatDate(this.state.dateHired),
            "ContractDateStart":this.FormatDate(this.state.contractStart),
            "ContractDateEnd":this.FormatDate(this.state.contractEnd),
            "DateResigned":this.FormatDate(this.state.dateResigned),
            "ApproverId1":this.state.approver1Id,
            "ApproverId2":this.state.approver2Id,

            "Client":this.state.clientName,
            "Location":this.state.location,
            "Position":this.state.position,
            "Remarks":this.state.remarks,
        };
        
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/EditEmployeeRecord",  params)
        .then(res => {
            const data = res.data
            this.setState({isloading:false})
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    saveRecordBtn:  false,
                });
            } 
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true,
                    recordDisable:true,
                    saveRecordBtn:true,
                    editRecordText:"EDIT" 
                });
            } 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
                disablebutton:  false
            })
        })
    }
    handleSubmitDocument = async() =>{
        this.setState({
            isloading   :   true,
            isshow      :   false,
            fade        :   true,
            saveDocumentBtn:  true,
            color       :   "",
            message     :   "",
            alerttype   :   ""
        })
        const params = new FormData();
        params.append('IpAddress', "0.0.0.0");
        params.append('ClientId', this.state.userinfo.clientId);
        params.append('UserId', this.state.userinfo.userId);
        params.append('ProfileId', this.state.profileId);
        params.append('EmployeeNo', this.state.employeeNo);
        params.append('IsOneFile', this.state.IsOneFile);
        params.append("documents", JSON.stringify(this.state.documentGRID.filter(x=>x.isModified==="1")))

        if(this.state.IsOneFile==="1"){
            params.append("FileUpload", this.state.UploadedFile);
        }else{
            this.state.documentGRID.map(function(itm,idx){
                if(itm.fileName!==""){
                    params.append("FileUpload", itm.fileUpload);  
                }
            })
        }

        await
        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Employee/SaveEmployeeDocument",  params, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
                this.setState({
                    isloading           :   false,
                    alerttype           :   "Success!",
                    isshow              :   true,
                    color               :   "success",
                    message             :   data.message,
                    fade                :   true,
                    saveDocumentBtn     :   true,
                    editDocumentText    :   "EDIT"
                });
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                });
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true,
                isDisableApprove:   false,
                isDisableReject :   false
            })
        })
    }
    ValidNumeric(val)
    {
        var regex = /^\d+(.\d+)?$/;
        return !regex.test(val)
    }
    IsNumeric(evt)
    {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
        
    }
    IsDecimal(evt)
    {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }
    GridDataModifiedDoc(oldValue, newValue, row, column) {
        let documentGRID = this.state.documentGRID
        documentGRID.map(function(item,i) {
            if (item.id===row.id && newValue!==oldValue)
            {
                item.isModified="1"
                if(column==="isReceived" && newValue==="1"){
                    item.isSuspended="0"
                    item.dateReceived = null
                }
                if(column==="isReceived" && newValue==="0"){
                    item.isSuspended="1"
                    item.dateReceived=null
                }

                if(column==="isSuspended" && newValue==="1"){
                    item.isReceived="0"
                    item.dateReceived=null
                }
                if(column==="isSuspended" && newValue==="0"){
                    item.isReceived="1"
                    item.dateReceived = null
                }
                if(typeof item.dateReceived==='undefined')
                    item.dateReceived=null
            }
        })
        this.state.documentGRID = documentGRID
    }
    SetFile = rowid => e => {
        let documentGRID = this.state.documentGRID
        let uploadfile = ""
        let filename = ""
        let docid = this.state.docid
        if(e.target.value.length>0){
            uploadfile = e.target.files[0]
            uploadfile.id=this.state.docid
            filename = this.state.employeeNo.replace("-","") + "_" + this.state.doccode + ".pdf"
        }
        else{
            uploadfile = "";
        }
        if(uploadfile!=="" && documentGRID.filter(x=>x.originalfile===uploadfile.name).length>0){
            let id = documentGRID.filter(x=>x.originalfile===uploadfile.name).id
            documentGRID.map(function(itm){
                itm.fileName = itm.id===id ? "" : itm.fileName
            })
            this.state.documentGRID = documentGRID
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "File name already exist.",
                fade        :   true
            })
            alert("File name already exist.")
            return
        }
        documentGRID.map(function(itm,idx){
            if(itm.id===docid){
                itm.isModified = "1"
                itm.fileUpload = uploadfile
                itm.fileName = filename
                itm.originalfile = uploadfile.name
            }
        })
        this.setState({documentGRID:documentGRID,docid:"",doccode:""})
        //console.log(this.state.requiredDocsDG)
    }
    handleAttachedClick = row => e =>{
        this.setState({isloading:false,alerttype:"",isshow:false,color:"",message:"",fade:false});
        
        if(row.isSuspended==="1") {alert("Attachment is not applicable for suspended document."); return}
        if(row.dateReceived==="") {alert("Please enter date received."); return}
        if(row.dateReceived===null) {alert("Please enter date received."); return}
        this.state.docid = row.id
        this.state.doccode = row.documentCode
        this.inputElement.click(row.id)
    }
    SetOneFile = (e) => {
        if(e.target.value.length>0)
            this.setState({ UploadedFile: e.target.files[0],IsOneFile:"1"});
        else
            this.setState({ UploadedFile: "",IsOneFile:"0"});
    }
    handleActivateClick = async(row) =>{
        let stat = row.statusId === "0" ? "deactivate" : "activate"
        if (!window.confirm('Do you want to '+ stat +' this employment record?'))
            return
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "ProfileId": row.profileId,
            "EmployeeId": row.id,
            "StatusId": row.statusId==="0" ? "1" : "0"
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/ActivateEmployeeRecord", params)
        .then(res => {
            const data = res.data;
            this.setState({
                isloading           :   false,
                alerttype           :   data.status==="1" ? "Success!" : "Error!",
                isshow              :   true,
                color               :   data.status==="1" ? "success" : "danger",
                message             :   data.message,
                fade                :   true
            });
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }
    render() {
        
        const addrCol = [
        {
            dataField: 'typeId',
            text: 'TYPE',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'8%'}},
            style:{textAlign:'left',width:'8%'},
            formatter: (cell, row) => {
                if(row.typeId!=='' || row.typeId!==null || typeof row.typeId!=='undefined'){
                    if(this.state.addrtypeDDL.filter(x => x.value == cell).length>0)
                        return this.state.addrtypeDDL.find(x => x.value == cell).label;
                }
            },
            editor: {
                type: Type.SELECT,
                options: this.state.addrtypeDDL
            },
            validator: (newValue, row, column) => {
                if(this.state.addressGRID.filter(x=>x.typeId===newValue).length>0)
                return {
                    valid: false,
                    message:"Select address type already exist."
                    };
                return true;   
            }
        },
        {
            dataField: 'regionId',
            text: 'REGION',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'15%'}},
            style:{textAlign:'left',width:'15%'},
            formatter: (cell, row) => {
                if(row.regionId!=='' || row.regionId!==null || typeof row.regionId!=='undefined'){
                    if(this.state.regionDDL.filter(x => x.value == cell).length>0)
                        return this.state.regionDDL.find(x => x.value == cell).label;
                }
            },
            editor: {
                type: Type.SELECT,
                options: this.state.regionDDL
            },
            validator: (newValue, row, column) => {
                if (row.typeId==="") {
                    return {
                    valid: false,
                    message:"Please select address type."
                    };
                } 
                return true;   
            }
        },
        {
            dataField: 'provinceId',
            text: 'PROVINCE',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'15%'}},
            style:{textAlign:'left',width:'15%'},
            formatter: (cell, row) => {
                if(row.provinceId!=='' && row.provinceId!==null || typeof row.provinceId!=='undefined'){
                    if(this.state.provinceDDL.filter(x => x.value == cell).length>0)
                        return this.state.provinceDDL.find(x => x.value == cell).label;
                }
            },
            editor: {
                type: Type.SELECT,
                getOptions: (setOptions, { row, column }) => {
                    return this.state.provinceDDL.filter(x=>x.regionId==row.regionId);
                }
            },
            validator: (newValue, row, column) => {
                if (row.regionId==="") {
                    return {
                    valid: false,
                    message:"Please select region address."
                    };
                } 
                return true;   
            }
        },
        {
            dataField: 'cityId',
            text: 'CITY',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'15%'}},
            style:{textAlign:'left',width:'15%'},
            formatter: (cell, row) => {
                if(row.cityId!=='' && row.cityId!==null || typeof row.cityId!=='undefined'){
                    if(this.state.cityDDL.filter(x => x.value == cell).length>0)
                        return this.state.cityDDL.find(x => x.value == cell).label;
                }
            },
            editor: {
                type: Type.SELECT,
                getOptions: (setOptions, { row, column }) => {
                    return this.state.cityDDL.filter(x=>x.provinceId==row.provinceId);
                }
            },
            validator: (newValue, row, column) => {
                if (row.provinceId==="") {
                    return {
                    valid: false,
                    message:"Please select province address."
                    };
                } 
                return true;   
            }
        },
        {
            dataField: 'houseNumber',
            text: 'House Number',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'10%'}},
            style:{textAlign:'left',width:'10%'},
            validator: (newValue, row, column) => {
                if (row.cityId==="") {
                    return {
                    valid: false,
                    message:"Please select city address."
                    };
                } 
                return true;   
            }
        },
        {
            dataField: 'streetName',
            text: 'STREET NAME',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'10%'}},
            style:{textAlign:'left',width:'10%'},
            validator: (newValue, row, column) => {
                if (row.houseNumber==="") {
                    return {
                    valid: false,
                    message:"Please enter house number."
                    };
                } 
                return true;   
            }
        },
        {
            dataField: 'barangay',
            text: 'BARANGAY',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'10%'}},
            style:{textAlign:'left',width:'10%'},
            validator: (newValue, row, column) => {
                if (row.streetName==="") {
                    return {
                    valid: false,
                    message:"Please enter street name."
                    };
                } 
                return true;   
            }
        },
        {
            dataField: 'postalCode',
            text: 'POSTAL CODE',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'10%'}},
            style:{textAlign:'center',width:'10%'},
            validator: (newValue, row, column) => {
                if (this.ValidNumeric(newValue)) {
                    return {
                    valid: false,
                    message:"Value must be numeric."
                    };
                } 
                return true;   
            }
        },
        {
            dataField: 'action',
            text: 'ACTION',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'7%'}
            },
            style:{textAlign:'center',width:'7%'},
            formatter   :   (cell, row, isSelect) => {
                return (
                    <Button 
                        onClick={e => this.handleRemoveAddress(row.id)}
                        style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                        variant="link"
                >REMOVE</Button>
                );
            },
        }
    ]
    const fbCol = [
        {
            dataField: 'roleId',
            text: 'ROLE',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'10%'}},
            style:{textAlign:'left',width:'10%'},
            formatter: (cell, row) => {
                if(row.roleId!=='' || row.roleId!==null || typeof row.roleId!=='undefined'){
                    if(this.state.roleDDL.filter(x => x.value == cell).length>0)
                        return this.state.roleDDL.find(x => x.value == cell).label;
                }
            },
            editor: {
                type: Type.SELECT,
                options: this.state.roleDDL
            },
            validator: (newValue, row, column) => {
                if(this.state.backgroundGRID.filter(x=>x.roleId===newValue).length>0 && newValue==="0")
                return {
                    valid: false,
                    message:"Cannot set multiple for role father."
                    };
                if(this.state.backgroundGRID.filter(x=>x.roleId===newValue).length>0 && newValue==="1")
                return {
                    valid: false,
                    message:"Cannot set multiple for role mother."
                };
                if(this.state.backgroundGRID.filter(x=>x.roleId===newValue).length>0 && newValue==="2")
                return {
                    valid: false,
                    message:"Cannot set multiple for role spouse."
                };
                return true;   
            }
        },
        {
            dataField: 'name',
            text: 'Name',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'20%'}},
            style:{textAlign:'left',width:'20%'},
            validator: (newValue, row, column) => {
                if (row.roleId==="") {
                    return {
                    valid: false,
                    message:"Please enter role."
                    };
                } 
                return true;   
            }
        },
        {
            dataField: 'age',
            text: 'AGE',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'10%'}},
            style:{textAlign:'center',width:'10%'},
            validator: (newValue, row, column) => {
                if (row.name==="") {
                    return {
                        valid: false,
                        message:"Please enter name."
                    };
                } 
                if (this.ValidNumeric(newValue)) {
                    return {
                    valid: false,
                    message:"Value must be numeric."
                    };
                } 
                return true;   
            }
        },
        {
            dataField: 'occupation',
            text: 'OCCUPATION',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'15%'}},
            style:{textAlign:'center',width:'15%'},
            validator: (newValue, row, column) => {
                if (row.age==="") {
                    return {
                        valid: false,
                        message:"Please enter age."
                    };
                } 
                return true;   
            }
        },
        {
            dataField: 'company',
            text: 'COMPANY',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'38%'}},
            style:{textAlign:'left',width:'38%'},
            validator: (newValue, row, column) => {
                if (row.occupation==="") {
                    return {
                        valid: false,
                        message:"Please enter company."
                    };
                } 
                return true;   
            }
        },
        {
            dataField: 'action',
            text: 'ACTION',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'7%'}
            },
            style:{textAlign:'center',width:'7%'},
            formatter   :   (cell, row, isSelect) => {
                return (
                    <Button 
                    onClick={e=>this.handleClickBackgroundRemove(row.id)}
                        style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                        variant="link"
                >REMOVE</Button>
                );
            },
        }
    ]
    const eduCol = [
        {
            dataField: 'levelId',
            text: 'LEVEL',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'10%'}},
            style:{textAlign:'left',width:'10%'},
            formatter: (cell, row) => {
                if(row.levelId!=='' || row.levelId!==null || typeof row.levelId!=='undefined'){
                    if(this.state.levelDDL.filter(x => x.value == cell).length>0)
                        return this.state.levelDDL.find(x => x.value == cell).label;
                }
            },
            editor: {
                type: Type.SELECT,
                options: this.state.levelDDL
            },
            validator: (newValue, row, column) => {
                if(this.state.educationGRID.filter(x=>x.levelId===newValue).length>0)
                return {
                    valid: false,
                    message:"Selected level already exist."
                    };
                return true;   
            }
        },
        {
            dataField: 'schoolName',
            text: 'SCHOOL NAME',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'20%'}},
            style:{textAlign:'left',width:'20%'},
            validator: (newValue, row, column) => {
                if(row.levelId==="")
                return {
                    valid: false,
                    message:"Please select school level."
                    };
                return true;   
            }
        },
        {
            dataField: 'course',
            text: 'COURSE',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'15%'}},
            style:{textAlign:'left',width:'15%'},
            validator: (newValue, row, column) => {
                if(row.schoolName==="")
                return {
                    valid: false,
                    message:"Please enter school name."
                    };
                return true;   
            }
        },
        {
            dataField: 'startYear',
            text: 'START YEAR',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'10%'}},
            style:{textAlign:'center',width:'10%'},
            validator: (newValue, row, column) => {
                if(row.course==="")
                    return {
                        valid: false,
                        message:"Please enter course."
                };
                if (this.ValidNumeric(newValue)) {
                    return {
                    valid: false,
                    message:"Value must be numeric."
                    };
                } 
                return true;   
            }
        },
        {
            dataField: 'endYear',
            text: 'END YEAR',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'10%'}},
            style:{textAlign:'center',width:'10%'},
            validator: (newValue, row, column) => {
                if(row.startYear==="")
                    return {
                    valid: false,
                    message:"Please enter start year."
                };
                if (this.ValidNumeric(newValue)) {
                    return {
                    valid: false,
                    message:"Value must be numeric."
                    };
                } 
                return true;   
            }
        },
        {
            dataField: 'honor',
            text: 'HONOR',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'28%'}},
            style:{textAlign:'left',width:'28%'},
            validator: (newValue, row, column) => {
                if(row.endYear==="")
                    return {
                    valid: false,
                    message:"Please enter start year."
                };
                return true;   
            }
        },
        {
            dataField: 'action',
            text: 'ACTION',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'7%'}
            },
            style:{textAlign:'center',width:'7%'},
            formatter   :   (cell, row, isSelect) => {
                return (
                    <Button 
                        onClick={e=>this.handleRemoveEducation(row.id)}
                        style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                        variant="link"
                >REMOVE</Button>
                );
            },
        }
    ]
    const employmentCol =[
        {
            dataField: 'companyName',
            text: 'COMPANY',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'27%'}},
            style:{textAlign:'left',width:'27%'},
        },
        {
            dataField: 'branchName',
            text: 'BRANCH',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'25%'}},
            style:{textAlign:'left',width:'25%'},
        },
        {
            dataField: 'position',
            text: 'POSITION',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'18%'}},
            style:{textAlign:'left',width:'18%'},
        },
        {
            dataField: 'salaryRate',
            text: 'RATE',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'8%'}},
            style:{textAlign:'right',width:'8%'},
        },
        {
            dataField: 'periodCovered',
            text: 'PERIOD COVERED',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'15%'}},
            style:{textAlign:'center',width:'15%'},
        },
        // {
        //     dataField: 'reasonForLeaving',
        //     text: 'REASON',
        //     editable: false,
        //     headerStyle: (colum, colIndex) => {
        //         return { textAlign: 'left',width:'27%'}},
        //     style:{textAlign:'left',width:'27%'},
        // },
        {
            dataField: 'action',
            text: 'ACTION',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'7%'}
            },
            style:{textAlign:'center',width:'7%'},
            formatter   :   (cell, row, isSelect) => {
                return(<Button 
                        onClick={e => this.handleActivateClick(row)}
                        style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                        variant="link"
                >{row.statusId==="0" ? "DEACTIVATE" : "ACTIVATE"}</Button>)
            },
            
        }
    ]
    const applicationCol =[
        // {
        //     dataField: 'employeeName',
        //     text: 'EMPLOYEE',
        //     editable: false,
        //     headerStyle: (colum, colIndex) => {
        //         return { textAlign: 'left',width:'20%'}},
        //     style:{textAlign:'left',width:'20%'},
        // },
        {
            dataField: 'dateApplied',
            text: 'DATE APPLIED',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'10%'}},
            style:{textAlign:'center',width:'10%'},
        },
        {
            dataField: 'referenceNo',
            text: 'REFERENCE NO',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'10%'}},
            style:{textAlign:'center',width:'10%'},
        },
        {
            dataField: 'client',
            text: 'CLIENT',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'40%'}},
            style:{textAlign:'left',width:'40%'},
        },
        {
            dataField: 'position',
            text: 'POSITION',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'40%'}},
            style:{textAlign:'left',width:'40%'},
        },
    ]
    const trainingCol =[
        {
            dataField: 'trainingName',
            text: 'TRAINING',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'30%'}},
            style:{textAlign:'left',width:'30%'},
        },
        {
            dataField: 'dateAttended',
            text: 'DATE ATTENDED',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'10%'}},
            style:{textAlign:'center',width:'10%'},
        },
        {
            dataField: 'status',
            text: 'STATUS',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'60%'}},
            style:{textAlign:'left',width:'60%'},
        },
    ]
    const documentCol =[
        {
            dataField: 'document',
            text: 'DOCUMENT',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'25%'}},
            style:{textAlign:'left',width:'25%'},
        },
        {
            dataField: 'required',
            text: 'TYPE',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'10%'}},
            style:{textAlign:'center',width:'10%'},
        },
        {
            dataField: 'isReceived',
            text: 'RECEIVED',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'10%'}},
            style:{textAlign:'center',width:'10%'},
            formatter: (cell, row) => {
                if(row.isReceived!='' && row.isReceived!=null){
                    if(this.state.yesNoDDL.filter(x => x.value == cell).length==0)
                    return ""
                    else
                        return this.state.yesNoDDL.find(x => x.value == cell).label;
                }
            },
            editor: {
                type: Type.SELECT,
                options: this.state.yesNoDDL
            }
        },
        {
            dataField: 'dateReceived',
            text: 'DATE RECEIVED',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'10%'}},
            style:{textAlign:'center',width:'10%'},
            formatter: (cell) => { 
                let dateObj = cell;
                if (typeof cell !== 'object') dateObj = new Date(cell);
                if (cell != null){
                    let m = moment(dateObj);
                    return (m.isValid() ? (dateObj.getMonth()+1 + "/" + dateObj.getDate() + "/" + dateObj.getFullYear()) : "")
                }
            },
            editor: {
                type: Type.DATE,
                defaultValue: Date.now(),
                minDateValue: Date.now(),
            },
        },
        {
            dataField: 'isSuspended',
            text: 'SUSPENDED',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'10%'}},
            style:{textAlign:'center',width:'10%'},
            formatter: (cell, row) => {
                if(row.isSuspended!='' && row.isSuspended!=null){
                    if(this.state.yesNoDDL.filter(x => x.value == cell).length==0)
                    return ""
                    else
                        return this.state.yesNoDDL.find(x => x.value == cell).label;
                }
            },
            editor: {
                type: Type.SELECT,
                options: this.state.yesNoDDL
            }
        },
        {
            dataField: 'fileName',
            text: 'FILE',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'15%'}},
            style:{textAlign:'left',width:'15%'},
        },
        {
            dataField: 'uploadedDate',
            text: 'DATE UPLOADED',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'10%'}},
            style:{textAlign:'center',width:'10%'},
        },
        {
            dataField: 'action',
            text: 'ACTION',
            editable:true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'10%'}
            },
            style:{textAlign:'center',width:'10%'},
            formatter   :   (cell, row, isSelect) => {
                return (<div>
                    <Button onClick={this.handleAttachedClick(row)} style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                        variant="link">
                        ATTACH FILE
                    </Button>
                    <Form.Control ref={input => this.inputElement = input} style={{display: 'none'}} type="file" accept='.pdf' variant='link' onChange={this.SetFile(row.id)} />
                </div>);
            },
        }
    ]
    const librarianCol =[
        {
            dataField: 'requestType',
            text: 'REQUEST TYPE',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'10%'}},
            style:{textAlign:'center',width:'10%'},
        },
        {
            dataField: 'requestDate',
            text: 'REQUEST DATE',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'10%'}},
            style:{textAlign:'center',width:'10%'},
        },
        {
            dataField: 'requestByName',
            text: 'REQUEST BY NAME',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'20'}},
            style:{textAlign:'left',width:'20%'},
        },
        {
            dataField: 'branchName',
            text: 'OFFICE',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'15%'}},
            style:{textAlign:'left',width:'15%'},
        },
        {
            dataField: 'remarks',
            text: 'REMARKS',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'20%'}},
            style:{textAlign:'center',width:'20%'},
        },
        {
            dataField: 'approvedByName',
            text: 'APR / REJ BY',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'15%'}},
            style:{textAlign:'left',width:'15%'},
        },
        {
            dataField: 'approvedDate',
            text: 'APR /REJ DATE',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'10%'}},
            style:{textAlign:'center',width:'10%'},
        },
    ]
    const selectrowAddr = {
        mode: 'checkbox',
        hideSelectAll: true,
        clickToSelectAndEditCell: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            
        }
    };
    const selectrowFB = {
        mode: 'checkbox',
        hideSelectAll: true,
        clickToSelectAndEditCell: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            
        }
    };
    const selectrowEdu = {
        mode: 'checkbox',
        hideSelectAll: true,
        clickToSelectAndEditCell: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            
        }
    };
    const selectrowEmployment = {
        mode: 'checkbox',
        hideSelectAll: true,
        clickToSelectAndEditCell: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            
        }
    };
    const selectrowApplication = {
        mode: 'checkbox',
        hideSelectAll: true,
        clickToSelectAndEditCell: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            
        }
    };
    const selectrowTraining = {
        mode: 'checkbox',
        hideSelectAll: true,
        clickToSelectAndEditCell: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            
        }
    };
    const selectrowDocument = {
        mode: 'checkbox',
        hideSelectAll: true,
        clickToSelectAndEditCell: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            
        }
    };
    const selectrowlibrarian = {
        mode: 'checkbox',
        hideSelectAll: true,
        clickToSelectAndEditCell: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            
        }
    };
    return(
        <div>
            <Banner />
                <Container  className="mt-3" fluid>
                    <Card>
                        <Card.Header>MANAGE EMPLOYEE 201</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CLIENT
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            name="clientId"
                                            onChange={this.handleChangedClient}
                                            options={this.state.clientDDL}
                                            selected={[this.state.clientName]}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EMPLOYEE NAME
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            name="employeeId"
                                            onChange={this.handleChangedEmployee}
                                            options={this.state.employeeDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar className="mt-1">
                                    <Button variant="primary" className="ml-auto noser-button-mr1" onClick={ this.handleSearchClick }>Search</Button>
                                </ButtonToolbar>
                                <hr />
                            </Form>
                            <Tabs className="mt-2" defaultActiveKey="default" transition={false} id="noanim-tab-example">
                                <Tab eventKey="default" title="I. General Informations">
                                    <Accordion className="mt-2"  defaultActiveKey="0">
                                        <Card className="card-tab">
                                            <div className="card-header-tab">
                                                <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{color: "#FFFFFF"}}>
                                                            PROFILE INFORMATION
                                                </Accordion.Toggle>
                                            </div>
                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                    <Form >
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            SOURCE
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="sourceProfile"
                                                                    name="sourceProfile"
                                                                    value={this.state.sourceProfile}
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                            
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            STATUS
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="statusName"
                                                                    name="statusName"
                                                                    value={this.state.profileStatus}
                                                                    disabled
                                                                /> 
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            HMO
                                                            </Form.Label>
                                                            <Col sm="1">
                                                                {/* <Typeahead
                                                                    labelKey='name'
                                                                    id='hmoId'
                                                                    name='hmoId'
                                                                    onChange={this.handleChangedHMO}
                                                                    options={this.state.hmoDDL}
                                                                    selected={[this.state.hmo]}
                                                                    disabled={this.state.profileDisable}
                                                                /> */}
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="hmo"
                                                                    name="hmo"
                                                                    value={this.state.hmo}
                                                                    disabled
                                                                /> 
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            HMO DATE
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                {/* <DatePicker
                                                                    id="hmoDate"
                                                                    name="hmoDate"
                                                                    value={this.state.hmoDate}
                                                                    //selected={this.state.hmoDate}
                                                                    //onChange={this.handleChangedHMODate}
                                                                    //minDate={this.minDate}
                                                                    dateFormat={"MM/dd/yyyy"}
                                                                    className="form-control"
                                                                    autoComplete="off"
                                                                    disabled
                                                                /> */}
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="hmoDate"
                                                                    name="hmoDate"
                                                                    value={this.state.hmoDate}
                                                                    disabled
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            FIRST NAME
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="firstName"
                                                                    name="firstName"
                                                                    value={this.state.firstName}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.profileDisable}
                                                                /> 
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            MIDDLE NAME
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="middleName"
                                                                    name="middleName"
                                                                    value={this.state.middleName}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            LAST NAME
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="lastName"
                                                                    name="lastName"
                                                                    value={this.state.lastName}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            SUFFIX NAME
                                                            </Form.Label>
                                                            <Col sm="1">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="suffixName"
                                                                    name="suffixName"
                                                                    value={this.state.suffixName}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            NICK NAME
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="nickName"
                                                                    name="nickName"
                                                                    value={this.state.nickName}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            HOME PHONE NUMBER
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="homeNumber"
                                                                    name="homeNumber"
                                                                    value={this.state.homeNumber}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            OFFICE PHONE NUMBER
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="officeNumber"
                                                                    name="officeNumber"
                                                                    value={this.state.officeNumber}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            MOBILE NUMBER
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="mobileNumber"
                                                                    name="mobileNumber"
                                                                    value={this.state.mobileNumber}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            EMAIL ADDRESS
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="emailAddress"
                                                                    name="emailAddress"
                                                                    value={this.state.emailAddress}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            FACEBOOK
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="facebook"
                                                                    name="facebook"
                                                                    value={this.state.facebook}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            TWITTER
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="twitter"
                                                                    name="twitter"
                                                                    value={this.state.twitter}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            NATIONALITY
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                {/* <Typeahead
                                                                    labelKey='name'
                                                                    id='nationality'
                                                                    name='nationality'
                                                                    onChange={this.handleChangedNationality}
                                                                    options={this.state.nationalityDDL}
                                                                    selected={[this.state.nationality]}
                                                                    disabled={this.state.profileDisable}
                                                                /> */}
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="nationality"
                                                                    name="nationality"
                                                                    value={this.state.nationality}
                                                                    disabled
                                                                /> 
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            RELIGION
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                {/* <Typeahead
                                                                    labelKey='name'
                                                                    id='religionId'
                                                                    name='religionId'
                                                                    onChange={this.handleChangedReligion}
                                                                    options={this.state.religionDDL}
                                                                    selected={[this.state.religion]}
                                                                    disabled={this.state.profileDisable}
                                                                /> */}
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="religion"
                                                                    name="religion"
                                                                    value={this.state.religion}
                                                                    disabled
                                                                /> 
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            GENDER
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                {/* <Typeahead
                                                                    labelKey='name'
                                                                    name="genderId"
                                                                    id="genderId"
                                                                    onChange={this.handleChangedGender}
                                                                    options={this.state.genderDDL}
                                                                    selected={[this.state.gender]}
                                                                    disabled={this.state.profileDisable}
                                                                /> */}
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="gender"
                                                                    name="gender"
                                                                    value={this.state.gender}
                                                                    disabled
                                                                /> 
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            CIVIL STATUS
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                {/* <Typeahead
                                                                    labelKey='name'
                                                                    id='civilstatusId'
                                                                    name='civilstatusId'
                                                                    onChange={this.handleChangedCivilStatus}
                                                                    options={this.state.civilstatusDDL}
                                                                    selected={[this.state.civilstatus]}
                                                                    disabled={this.state.profileDisable}
                                                                /> */}
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="civilstatus"
                                                                    name="civilstatus"
                                                                    value={this.state.civilstatus}
                                                                    disabled
                                                                /> 
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            DATE OF BIRTH
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <DatePicker
                                                                    id="dateofBirth"
                                                                    name="dateofBirth"
                                                                    value={this.state.dateofBirth}
                                                                    selected={this.state.dateofBirth}
                                                                    onChange={this.handleChangedDOB}
                                                                    minDate={this.minDate}
                                                                    dateFormat={"MM/dd/yyyy"}
                                                                    className="form-control"
                                                                    autoComplete="off"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                                <Form.Label column sm="0" style={{width:'100px',fontWeight : "bold",textAlign:'right'}}>
                                                                AGE :
                                                                </Form.Label>
                                                                <Form.Label column sm="0" style={{marginLeft:'4px',fontWeight : "bold",color:'blue',textAlign:'right'}}>
                                                                    {this.state.age}
                                                                </Form.Label>
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            PLACE OF BIRTH
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="placeofBirth"
                                                                    name="placeofBirth"
                                                                    value={this.state.placeofBirth}
                                                                    onChange={this.handleChanged} 
                                                                    autoComplete="off"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            LIVING ARRANGEMENT
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                {/* <Typeahead
                                                                    labelKey='name'
                                                                    id='arrangementId'
                                                                    name='arrangementId'
                                                                    onChange={this.handleChangedArrangement}
                                                                    options={this.state.arrangementDDL}
                                                                    selected={[this.state.arrangement]}
                                                                    disabled={this.state.profileDisable}
                                                                /> */}
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="arrangement"
                                                                    name="arrangement"
                                                                    value={this.state.arrangement}
                                                                    disabled
                                                                /> 
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            BLOOD TYPE
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                {/* <Typeahead
                                                                    labelKey='name'
                                                                    id='bloodtypeId'
                                                                    name='bloodtypeId'
                                                                    onChange={this.handleChangedBloodType}
                                                                    options={this.state.bloodtypeDDL}
                                                                    selected={[this.state.bloodtype]}
                                                                    disabled={this.state.profileDisable}
                                                                /> */}
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="bloodtype"
                                                                    name="bloodtype"
                                                                    value={this.state.bloodtype}
                                                                    disabled
                                                                /> 
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            HEIGHT
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="height"
                                                                    name="height"
                                                                    value={this.state.height}
                                                                    autoComplete="off"
                                                                    onChange={this.handleChanged}
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            WEIGHT
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="weight"
                                                                    name="weight"
                                                                    value={this.state.weight}
                                                                    autoComplete="off"
                                                                    onChange={this.handleChanged}
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            LANGUAGE SPOKEN
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="languageSpoken"
                                                                    name="languageSpoken"
                                                                    value={this.state.languageSpoken}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            HOBBIES
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="hobbies"
                                                                    name="hobbies"
                                                                    onChange={this.handleChanged}
                                                                    value={this.state.hobbies}
                                                                    autoComplete="off"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            EMERGENCY CONTACT NAME
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="contactName"
                                                                    name="contactName"
                                                                    value={this.state.contactName}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            EMERGENCY CONTACT NUMBER
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="contactNumber"
                                                                    name="contactNumber"
                                                                    value={this.state.contactNumber}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            EMERGENCY CONTACT RELATION
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="contactRelation"
                                                                    name="contactRelation"
                                                                    value={this.state.contactRelation}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            SSS NUMBER
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <NumberFormat
                                                                    id="sssNumber"
                                                                    name="sssNumber"
                                                                    value={this.state.sssNumber}
                                                                    onChange={this.handleChanged} 
                                                                    autoComplete="off"
                                                                    format="##-#######-#"
                                                                    className="form-control"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            REMARKS
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="sssNumberRemarks"
                                                                    name="sssNumberRemarks"
                                                                    value={this.state.sssNumberRemarks}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    maxLength="2000"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            TIN NUMBER 
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <NumberFormat
                                                                    id="tinNumber"
                                                                    name="tinNumber"
                                                                    value={this.state.tinNumber}
                                                                    onChange={this.handleChanged} 
                                                                    autoComplete="off"
                                                                    format="###-###-###-###"
                                                                    className="form-control"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            REMARKS
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="tinNumberRemarks"
                                                                    name="tinNumberRemarks"
                                                                    value={this.state.tinNumberRemarks}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    maxLength="2000"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            PHIC NUMBER
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <NumberFormat
                                                                    id="phicNumber"
                                                                    name="phicNumber"
                                                                    value={this.state.phicNumber}
                                                                    onChange={this.handleChanged} 
                                                                    autoComplete="off"
                                                                    format="##-#########-#"
                                                                    className="form-control"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            REMARKS
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="phicNumberRemarks"
                                                                    name="phicNumberRemarks"
                                                                    value={this.state.phicNumberRemarks}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    maxLength="2000"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            HDMF NUMBER 
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <NumberFormat
                                                                    id="hdmfNumber"
                                                                    name="hdmfNumber"
                                                                    value={this.state.hdmfNumber}
                                                                    onChange={this.handleChanged} 
                                                                    autoComplete="off"
                                                                    format="####-####-####"
                                                                    className="form-control"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                            REMARKS
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="hdmfNumberRemarks"
                                                                    name="hdmfNumberRemarks"
                                                                    value={this.state.hdmfNumberRemarks}
                                                                    onChange={this.handleChanged}
                                                                    autoComplete="off"
                                                                    maxLength="2000"
                                                                    disabled={this.state.profileDisable}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </Form>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card className="card-tab">
                                            <div className="card-header-tab">
                                                <Accordion.Toggle as={Button} variant="link" eventKey="1" style={{color: "#FFFFFF"}}>
                                                ADDRESS
                                                </Accordion.Toggle>
                                            </div>
                                            <Accordion.Collapse eventKey="1">
                                                <div>
                                                <BootstrapTable
                                                    disabled={this.state.profileDisable}
                                                    striped
                                                    hover
                                                    condensed
                                                    keyField = "id"
                                                    data = { this.state.addressGRID }
                                                    columns = { addrCol}
                                                    selectRow = { selectrowAddr }
                                                    cellEdit = { cellEditFactory({ 
                                                        mode: 'dbclick', 
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => { 
                                                            //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }})
                                                    }
                                                    rowClasses="noser-table-row-class no-checkbox"
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                />
                                                <Button id="addrid"
                                                    disabled={this.state.profileDisable}
                                                    className="noser-button" 
                                                    variant="success" 
                                                    onClick = {this.handleAddAddress}
                                                    style={{marginLeft:'2px',marginBottom:'4px'}}
                                                >ADD</Button>
                                                </div> 
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card className="card-tab">
                                            <div className="card-header-tab">
                                                <Accordion.Toggle as={Button} variant="link" eventKey="2" style={{color: "#FFFFFF"}}>
                                                            FAMILY BACKGROUNDS
                                                </Accordion.Toggle>
                                            </div>
                                            <Accordion.Collapse eventKey="2">
                                                <div>
                                                    <BootstrapTable
                                                        striped
                                                        hover
                                                        condensed
                                                        keyField = "id"
                                                        data = { this.state.backgroundGRID }
                                                        columns = { fbCol}
                                                        selectRow = { selectrowFB }
                                                        cellEdit = { cellEditFactory({ 
                                                            mode: 'dbclick', 
                                                            blurToSave: true,
                                                            afterSaveCell: (oldValue, newValue, row, column) => { 
                                                                //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                            }})
                                                        }
                                                        rowClasses="noser-table-row-class no-checkbox"
                                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    />
                                                    <Button id="fbid"
                                                        disabled={this.state.profileDisable}
                                                        className="noser-button" 
                                                        variant="success" 
                                                        onClick = {this.handleClickBackground}
                                                        style={{marginLeft:'2px',marginBottom:'4px'}}
                                                    >ADD</Button>
                                                </div> 
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card className="card-tab">
                                            <div className="card-header-tab">
                                                <Accordion.Toggle as={Button} variant="link" eventKey="3" style={{color: "#FFFFFF"}}>
                                                            EDUCATIONAL ATTAINMENT
                                                </Accordion.Toggle>
                                            </div>
                                            <Accordion.Collapse eventKey="3">
                                                <div>
                                                    <BootstrapTable
                                                        striped
                                                        hover
                                                        condensed
                                                        keyField = "id"
                                                        data = { this.state.educationGRID }
                                                        columns = { eduCol}
                                                        selectRow = { selectrowEdu }
                                                        cellEdit = { cellEditFactory({ 
                                                            mode: 'dbclick', 
                                                            blurToSave: true,
                                                            afterSaveCell: (oldValue, newValue, row, column) => { 
                                                                //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                            }})
                                                        }
                                                        rowClasses="noser-table-row-class no-checkbox"
                                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    />
                                                    <Button id="eduid"
                                                        disabled={this.state.profileDisable}
                                                        className="noser-button" 
                                                        variant="success" 
                                                        onClick = {this.handleAddEducation}
                                                        style={{marginLeft:'2px',marginBottom:'4px'}}
                                                    >ADD</Button>
                                                </div>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                    <ButtonToolbar mt="5" style={{marginTop:'4px'}}>
                                        <Button disabled //disabled={this.state.disableEdit}  
                                                className="ml-auto noser-button-mr1" 
                                                variant="primary"
                                                onClick={this.handleClickEditProfile}
                                        >{this.state.editProfileText}</Button>
                                        <Button disabled //disabled={this.state.saveProfileBtn} 
                                                className="noser-button" variant="success"
                                                onClick={this.handleSubmitProfile}
                                        >SAVE</Button>
                                    </ButtonToolbar>
                                </Tab>
                                <Tab eventKey="employeerecord" title="II. Employee Records">
                                    <Card className="card-tab">
                                        <div className="card-header-tab"></div>
                                        <Card.Body>
                                            <Form>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    SOURCE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="sourceEmployee"
                                                            name="sourceEmployee"
                                                            value={this.state.sourceEmployee}
                                                            disabled={this.state.profileDisable}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        CLIENT
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text" 
                                                            id="clientName"
                                                            name="clientName"
                                                            value={this.state.clientName}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    EMPLOYEE NUMBER
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text" 
                                                            id="employeeNumber"
                                                            name="employeeNumber"
                                                            value={this.state.employeeNo}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    BRANCH / LOCATION
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        {/* <Typeahead 
                                                            labelKey='name'
                                                            id='locationId'
                                                            name='locationId'
                                                            onChange={this.handleChangedLocation}
                                                            options={this.state.locationDDL}
                                                            selected={[this.state.location]}
                                                            //disabled={this.state.recordDisable}
                                                            disabled
                                                        /> */}
                                                        <Form.Control 
                                                            type="text"
                                                            id="location"
                                                            name="location"
                                                            value={this.state.location}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Col sm="3">
                                                        <Form.Check type="checkbox" 
                                                            label="IS DEFAULT BRANCH" 
                                                            id="isdefaultLocation"
                                                            name="isdefaultLocation"
                                                            onChange={this.handleChangedCB}
                                                            style={{fontWeight : "bold",marginLeft:"2px"}}
                                                            checked={this.state.isdefaultLocation == "1" ? true:false}
                                                            //disabled={this.state.recordDisable}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    POSITION
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        {/* <Typeahead
                                                            labelKey='name'
                                                            id='positionId'
                                                            name='positionId'
                                                            onChange={this.handleChangedPosition}
                                                            options={this.state.positionDDL}
                                                            selected={[this.state.position]}
                                                            //disabled={this.state.recordDisable}
                                                            disabled
                                                        /> */}
                                                        <Form.Control 
                                                            type="text"
                                                            id="position"
                                                            name="position"
                                                            value={this.state.position}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    EMPLOYMENT STATUS
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        {/* <Typeahead
                                                            labelKey='name'
                                                            id='employmentId'
                                                            name='employmentId'
                                                            onChange={this.handleChangedEmployment}
                                                            options={this.state.employmentDDL}
                                                            selected={[this.state.employment]}
                                                            //disabled={this.state.recordDisable}
                                                            disabled
                                                        /> */}
                                                        <Form.Control 
                                                            type="text"
                                                            id="employment"
                                                            name="employment"
                                                            value={this.state.employment}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    DATE HIRED
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <DatePicker
                                                            id='dateHired'
                                                            name='dateHired'
                                                            minDate={this.minDate}
                                                            value={this.state.dateHired}
                                                            selected={this.state.dateHired}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    DATE RESIGNED
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <DatePicker
                                                            id='dateResigned'
                                                            name='dateResigned'
                                                            selected={this.state.dateResigned}
                                                            onChange={this.handleChangedDate('dateResigned')}
                                                            minDate={this.minDate}
                                                            value={this.state.dateResigned}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            readOnly={this.state.disableresigned}
                                                            //disabled={this.state.recordDisable}
                                                            disabled
                                                            autoComplete="off"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    CONTRACT DATE START
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <DatePicker
                                                            id='contractStart'
                                                            name='contractStart'
                                                            minDate={this.minDate}
                                                            value={this.state.contractStart}
                                                            selected={this.state.contractStart}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            //disabled={this.state.recordDisable}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    CONTRACT DATE END
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <DatePicker
                                                            id='contractEnd'
                                                            name='contractEnd'
                                                            onChange={this.handleChangedEOC}
                                                            minDate={this.minDate}
                                                            value={this.state.contractEnd}
                                                            selected={this.state.contractEnd}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            autoComplete="off"
                                                            //disabled={this.state.recordDisable}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}} className={this.state.hasViewPayCardTypeAccess ? 'display-block' : 'display-none'}>
                                                    PAY CARD TYPE
                                                    </Form.Label>
                                                    <Col sm="3" className={this.state.hasViewPayCardTypeAccess ? 'display-block' : 'display-none'}>
                                                        {/* <Typeahead
                                                            labelKey='name'
                                                            id='cardtypeId'
                                                            name='cardtypeId'
                                                            onChange={this.handleChangedPayCardType}
                                                            options={this.state.cardtypeDDL}
                                                            selected={[this.state.cardtype]}
                                                            //disabled={this.state.recordDisable}
                                                            disabled
                                                        /> */}
                                                        <Form.Control 
                                                            type="text"
                                                            id="cardtype"
                                                            name="cardtype"
                                                            value={this.state.cardtype}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}} className={this.state.hasViewPayCardNumberAccess ? 'display-block' : 'display-none'}>
                                                    PAY CARD NUMBER
                                                    </Form.Label>
                                                    <Col sm="3" className={this.state.hasViewPayCardNumberAccess ? 'display-block' : 'display-none'}>
                                                        <Form.Control 
                                                            type="text" 
                                                            id="cardNumber"
                                                            name="cardNumber"
                                                            onChange={this.handleChanged}
                                                            value={this.state.cardNumber}
                                                            autoComplete="off" 
                                                            //disabled={this.state.recordDisable}
                                                            readOnly={this.state.disablecardnumber}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    PAY TYPE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        {/* <Typeahead
                                                            labelKey='name'
                                                            id='paytypeId'
                                                            name='paytypeId'
                                                            onChange={this.handleChangedPayType}
                                                            options={this.state.paytypeDDL}
                                                            selected={[this.state.paytype]}
                                                            //disabled={this.state.recordDisable}
                                                            disabled
                                                        /> */}
                                                        <Form.Control 
                                                            type="text"
                                                            id="paytype"
                                                            name="paytype"
                                                            value={this.state.paytype}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    PAY MODE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        {/* <Typeahead
                                                            labelKey='name'
                                                            id='paymodeId'
                                                            name='paymodeId'
                                                            onChange={this.handleChangedPayMode}
                                                            options={this.state.paymodeDDL}
                                                            selected={[this.state.paymode]}
                                                            disabled={this.state.recordDisable}
                                                        /> */}
                                                        <Form.Control 
                                                            type="text"
                                                            id="paymode"
                                                            name="paymode"
                                                            value={this.state.paymode}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    WORK SCHEDULE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        {/* <Typeahead
                                                            labelKey='description'
                                                            id='scheduleId'
                                                            name='scheduleId'
                                                            onChange={this.handleChangedSchedule}
                                                            options={this.state.scheduleDDL}
                                                            selected={[this.state.schedule]}
                                                            disabled={this.state.recordDisable}
                                                        /> */}
                                                        <Form.Control 
                                                            type="text"
                                                            id="schedule"
                                                            name="schedule"
                                                            value={this.state.schedule}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    PERIOD TYPE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        {/* <Typeahead
                                                            labelKey='name'
                                                            id='periodtypeId'
                                                            name='periodtypeId'
                                                            onChange={this.handleChangedPeriodType}
                                                            options={this.state.periodtypeDDL}
                                                            selected={[this.state.periodtype]}
                                                            disabled={this.state.recordDisable}
                                                        /> */}
                                                        <Form.Control 
                                                            type="text"
                                                            id="periodtype"
                                                            name="periodtype"
                                                            value={this.state.periodtype}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}} className={this.state.hasViewSalaryAccess ? 'display-block' : 'display-none'}>
                                                    SALARY RATE
                                                    </Form.Label>
                                                    <Col sm="3" className={this.state.hasViewSalaryAccess ? 'display-block' : 'display-none'}>
                                                        <Form.Control 
                                                            type="text"
                                                            id="salaryRate"
                                                            name="salaryRate"
                                                            value={this.state.salaryRate}
                                                            onChange={this.handleChanged}
                                                            onKeyPress={this.IsDecimal.bind(this)}
                                                            maxLength="13"
                                                            autoComplete="off"
                                                            //disabled={this.state.recordDisable}
                                                            disabled
                                                        /> 
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    SEA RATE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="seaRate"
                                                            name="seaRate"
                                                            value={this.state.seaRate}
                                                            onChange={this.handleChanged}
                                                            onKeyPress={this.IsDecimal.bind(this)}
                                                            maxLength="13"
                                                            autoComplete="off"
                                                            //disabled={this.state.recordDisable}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    ECOLA RATE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="ecolaRate"
                                                            name="ecolaRate"
                                                            value={this.state.ecolaRate}
                                                            onChange={this.handleChanged}
                                                            onKeyPress={this.IsDecimal.bind(this)}
                                                            maxLength="13"
                                                            autoComplete="off"
                                                            //disabled={this.state.recordDisable}
                                                            disabled
                                                        /> 
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    COLA RATE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="colaRate"
                                                            name="colaRate"
                                                            value={this.state.colaRate}
                                                            onChange={this.handleChanged}
                                                            onKeyPress={this.IsDecimal.bind(this)}
                                                            maxLength="13"
                                                            autoComplete="off"
                                                            //disabled={this.state.recordDisable}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    APPROVER 1
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        {/* <Typeahead 
                                                            labelKey='employeeName'
                                                            id='approver1Id'
                                                            name='approver1Id'
                                                            onChange={this.handleChangedApprover1}
                                                            options={this.state.approver1DDL}
                                                            selected={[this.state.approver1]}
                                                            //disabled={this.state.recordDisable}
                                                            disabled
                                                        /> */}
                                                        <Form.Control 
                                                            type="text"
                                                            id="approver1"
                                                            name="approver1"
                                                            value={this.state.approver1}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    APPROVER 2
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        {/* <Typeahead 
                                                            labelKey='employeeName'
                                                            id='approver2Id'
                                                            name='approver2Id'
                                                            onChange={this.handleChangedApprover2}
                                                            options={this.state.approver2DDL}
                                                            selected={[this.state.approver2]}
                                                            //disabled={this.state.recordDisable}
                                                            disabled
                                                        /> */}
                                                        <Form.Control 
                                                            type="text"
                                                            id="approver2"
                                                            name="approver2"
                                                            value={this.state.approver2}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    REMARKS
                                                    </Form.Label>
                                                    <Col sm="10">
                                                        <Form.Control 
                                                            type="text"
                                                            id="remarks"
                                                            name="remarks"
                                                            value={this.state.remarks}
                                                            onChange={this.handleChanged} 
                                                            readOnly={this.state.disableresigned}
                                                            //disabled={this.state.recordDisable}
                                                            disabled
                                                            autoComplete="off"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Form>
                                            <ButtonToolbar mt="5" style={{marginTop:'4px'}}>
                                                <Button disabled //disabled={this.state.disableEdit}  
                                                        className="ml-auto noser-button-mr1" 
                                                        variant="primary"
                                                        onClick={this.handleClickEditRecord}
                                                >{this.state.editRecordText}</Button>
                                                <Button disabled //disabled={this.state.saveRecordBtn} 
                                                        className="noser-button" variant="success"
                                                        onClick={this.handleSubmitRecord}
                                                >SAVE</Button>
                                            </ButtonToolbar>
                                        </Card.Body>
                                    </Card>
                                </Tab>
                                <Tab eventKey="employmentrecord" title="III. Employment History">
                                    <Card className="card-tab">
                                        <div className="card-header-tab"></div>
                                        <div>
                                            <BootstrapTable
                                                striped
                                                hover
                                                condensed
                                                keyField = "id"
                                                data = { this.state.employmentGRID }
                                                columns = { employmentCol}
                                                selectRow = { selectrowEmployment }
                                                cellEdit = { cellEditFactory({ 
                                                    mode: 'dbclick', 
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => { 
                                                        //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }})
                                                }
                                                rowClasses="noser-table-row-class no-checkbox"
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            />
                                        </div>
                                    </Card>
                                </Tab>
                                <Tab eventKey="application" title="IV. Application History">
                                    <Card className="card-tab">
                                        <div className="card-header-tab"></div>
                                        <div>
                                            <BootstrapTable
                                                striped
                                                hover
                                                condensed
                                                keyField = "id"
                                                data = { this.state.applicationGRID }
                                                columns = { applicationCol}
                                                selectRow = { selectrowApplication }
                                                cellEdit = { cellEditFactory({ 
                                                    mode: 'dbclick', 
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => { 
                                                        //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }})
                                                }
                                                rowClasses="noser-table-row-class no-checkbox"
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            />
                                        </div>
                                    </Card>
                                </Tab>
                                <Tab eventKey="training" title="V. Trainings">
                                    <Card className="card-tab">
                                        <div className="card-header-tab"></div>
                                        <div>
                                            <BootstrapTable
                                                striped
                                                hover
                                                condensed
                                                keyField = "id"
                                                data = { this.state.trainingGRID }
                                                columns = { trainingCol}
                                                selectRow = { selectrowTraining }
                                                cellEdit = { cellEditFactory({ 
                                                    mode: 'dbclick', 
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => { 
                                                        //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }})
                                                }
                                                rowClasses="noser-table-row-class no-checkbox"
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            />
                                        </div>
                                    </Card>
                                </Tab>
                                <Tab eventKey="document" title="VI. Documents">
                                    <Card className="card-tab">
                                        <div className="card-header-tab"></div>
                                        <div>
                                            <BootstrapTable
                                                striped
                                                hover
                                                condensed
                                                keyField = "id"
                                                data = { this.state.documentGRID }
                                                columns = { documentCol}
                                                selectRow = { selectrowDocument }
                                                cellEdit = { cellEditFactory({ 
                                                    mode: 'dbclick', 
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => { 
                                                        this.GridDataModifiedDoc(oldValue, newValue, row, column.dataField)
                                                    }})
                                                }
                                                rowClasses="noser-table-row-class no-checkbox"
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            />
                                        </div>
                                        <Form.Control type="file" accept='.pdf' onChange={this.SetOneFile} style={{border:"1pt solid #ccc"}}/>
                                        <div style={{marginTop:'4px',marginBottom:'4px',marginRight:'4px'}}>
                                        <ButtonToolbar>
                                            <Button disabled={this.state.disableEdit}  
                                                    className="ml-auto noser-button-mr1" 
                                                    variant="primary"
                                                    onClick={this.handleClickEditDocument}
                                            >{this.state.editDocumentText}</Button>
                                            <Button disabled={this.state.saveDocumentBtn} 
                                                    className="noser-button" variant="success"
                                                    onClick={this.handleSubmitDocument}
                                            >SAVE</Button>
                                        </ButtonToolbar>
                                        </div>
                                    </Card>
                                </Tab>
                                <Tab eventKey="librarian" title="VII. Librarian">
                                    <Card className="card-tab">
                                        <div className="card-header-tab"></div>
                                        <Card.Body>
                                            <Form>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                CABINET
                                                </Form.Label>
                                                <Col sm="3">
                                                    {/* <Typeahead
                                                        labelKey='name'
                                                        id='cabinetId'
                                                        name='cabinetId'
                                                        onChange={this.handleChangedCabinet}
                                                        options={this.state.cabinetDDL}
                                                        selected={[this.state.cabinet]}
                                                        disabled={this.state.profileDisable}
                                                    /> */}
                                                    <Form.Control 
                                                            type="text"
                                                            id="cabinet"
                                                            name="cabinet"
                                                            value={this.state.cabinet}
                                                            disabled
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                DRAWER
                                                </Form.Label>
                                                <Col sm="3">
                                                    {/* <Typeahead
                                                        labelKey='name'
                                                        id='drawerId'
                                                        name='drawerId'
                                                        onChange={this.handleChangedDrawer}
                                                        options={this.state.drawerDDL}
                                                        selected={[this.state.drawer]}
                                                        disabled={this.state.profileDisable}
                                                    /> */}
                                                    <Form.Control 
                                                        type="text"
                                                        id="drawer"
                                                        name="drawer"
                                                        value={this.state.drawer}
                                                        disabled
                                                    />
                                                </Col>
                                            </Form.Group>
                                            </Form>
                                        </Card.Body>
                                        <div>
                                            <BootstrapTable
                                                striped
                                                hover
                                                condensed
                                                keyField = "id"
                                                data = { this.state.librarianGRID }
                                                columns = { librarianCol}
                                                selectRow = { selectrowlibrarian }
                                                cellEdit = { cellEditFactory({ 
                                                    mode: 'dbclick', 
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => { 
                                                        //this.GridDataModifiedDoc(oldValue, newValue, row, column.dataField)
                                                    }})
                                                }
                                                rowClasses="noser-table-row-class no-checkbox"
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            />
                                        </div>
                                    </Card>
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Container>
            <NoserLoading show={this.state.isloading} />
        </div> 
    )}

}
export default ManageEmployee201



