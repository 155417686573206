import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer
} 
from '../../noser-hris-component';
import CreateArea from './Modal/CreateArea';

class Area extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            openModal       :   false,
            disableBtn      :   true,

            areaCode        :   "",
            areaName        :   "",
            areasDDL        :   [],
            areaCodeDDL     :   [],
            areaNameDDL     :   [],
            areaGRID        :   []
        }
    }
    

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetAreas()
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.GetAreas()
    }
    GetAreas = async() =>{
        this.setState({isloading:true,alerttype:'',isshow:false,color:'',message:'',fade:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "",
            "Name"          :   ""
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetAreas",  params)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({ areaCodeDDL:data.areas,
                            areaNameDDL:data.areas,
                            areaGRID:data.areas,
                            areasDDL:data.areas,
                            isloading:false});

            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                })
            }
         })
    }
    handleChangedCode = (e) => {
        this.setState({alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.areaCode=""
            return
        } 
        this.setState({areaCode:e[0].code})
    }

    handleChangedDescription = (e) => {
        this.setState({alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.areaName=""
            return
        } 
        this.setState({areaName:e[0].name})
    }

    handleSearchClick = event => {
        this.setState({isloading:true})
        if(this.state.areaCode!=="" && this.state.areaName!==""){
            this.state.areaGRID = this.state.areasDDL.filter(x=>x.code===this.state.areaCode && x.name===this.state.areaName)
        }
        if(this.state.areaCode!=="" && this.state.areaName===""){
            this.state.areaGRID = this.state.areasDDL.filter(x=>x.code===this.state.areaCode)
        }
        if(this.state.areaCode==="" && this.state.areaName!==""){
            this.state.areaGRID = this.state.areasDDL.filter(x=>x.name===this.state.areaName)
        }
        if(this.state.areaCode==="" && this.state.areaName===""){
            this.state.areaGRID = this.state.areasDDL
        }
        this.setState({isloading:false})
    }

    handleSaveClick = async() => {
        this.setState({isLoading:true})
        const editAreaParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Areas"         :   this.state.areaGRID.filter(x=> x.isModified==="1")
        };

        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditArea", editAreaParams)
            .then(res => {
                const data = res.data;
                this.setState({
                    isloading       :   false,
                    alerttype       :   data.status==="1" ? "Success!" : "Error!",
                    isshow          :   true,
                    color           :   data.status==="1" ? "success" : "danger",
                    message         :   data.message,
                    fade            :   true
                });
             })
             .catch(error=>{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })
    }
    GridDataModified(oldValue, newValue, id, column) {
        this.setState({disableBtn:true})
        this.state.areaGRID.forEach(item => {
            item.rateTypeId="0"
            if (item.id === id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                this.setState({disableBtn:false})
            }
        })
    }

    render() {
        const columnAreaCode = [
            {
                dataField   : 'code',
                text        : 'Code',
                editable:true,
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%' }},
                style:{textAlign:'left',width:'10%'}
            },
            {
                dataField   : 'name',
                text        : 'Name',
                editable:true,
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'90%' }},
                style:{textAlign:'left',width:'90%'}
            }
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({disableBtn:true})
                this.state.areaGRID.forEach(item=>{
                    item.rateTypeId="0"
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted==="1"){
                        this.setState({disableBtn:false})
                    }
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };


    return(
            <div>
                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header className='login-card'>Areas</Card.Header>
                        <Card.Body>
                            <div>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Col sm={12}>
                                            <Typeahead
                                                labelKey='code'
                                                id="basic-example"
                                                onChange={this.handleChangedCode}
                                                options={this.state.areaCodeDDL}
                                                placeholder="Select Area Code"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Col sm={12}>
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangedDescription}
                                                options={this.state.areaNameDDL}
                                                placeholder="Select Description"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Col sm={12}>
                                            <ButtonToolbar>
                                                <Button variant="primary" className="ml-auto noser-button" onClick={ this.handleSearchClick }>Search</Button>
                                            </ButtonToolbar>
                                        </Col>
                                    </Form.Group>
                                    <div className="mt-1">
                                        <Card.Header>List Of Areas</Card.Header>
                                        <BootstrapTable
                                            keyField = "id"
                                            data = { this.state.areaGRID }
                                            columns = { columnAreaCode }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }
                                            selectRow = { selectRow }
                                            noDataIndication={ () => <div>No record found.</div> }
                                        />
                                        <ButtonToolbar sm={12}>
                                            <Button disabled={this.state.disableBtn} variant="success" className="ml-auto noser-button-mr1" onClick={this.handleSaveClick}>Save</Button>
                                            <Button  variant="primary" className='noser-button' onClick={this.handleModalShow}>Create</Button>
                                        </ButtonToolbar>
                                    </div>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
                <CreateArea 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div> 
        )
    }

}

export default Area 