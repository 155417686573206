import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import type from 'react-bootstrap-table2-editor';

class Client extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            
            clientId:"",
            clientName:"",
            clientDDL:[],
            clientGrid:[],
            clientList:[],
            yesNoDDL:[{"value" : "0","label":"INACTIVE"},{"value" : "1","label":"ACTIVE"}],
            yesNo1DDL:[{"value" : "0","label":"NO"},{"value" : "1","label":"YES"}]
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients();    
    }
    GetClients = async() => {
        this.setState({isloading:true})

        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "IsActive"      :   "2"
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            this.setState({ 
                clientDDL:res.data.clients,
                clientList:res.data.clients,
                clientGrid:res.data.clients,
                isloading:false
            })
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleChanged = (e) => {
        if (e.length === 0) {
            this.state.clientName = ""
            this.state.clientId = ""
            return
        }
        this.state.clientName = e[0].name
        this.state.clientId = e[0].id
    } 
    handleSearchClick = async(e) => {
        this.setState({isloading:true})

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":this.state.clientName,
            "IsActive":"2"
        };
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  params)
            .then(res => {
            const data = res.data;
            this.setState({ clientGrid:res.data.clients,  isloading:false})
            if(data.status=="0"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }

    handleSaveClick = async(e) => {
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "clients":this.state.clientGrid.filter(x=>x.isModified==="1")
        };
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Client/EditClient", params)
            .then(res => {
                const data = res.data;
                this.setState(
                    {
                        isloading:false,
                        alerttype:data.status=="1" ? "Success!" : "Error!",
                        isshow:true,
                        color:data.status=="1" ? "success" : "danger",
                        message:data.message ,
                        fade:true
                    });
                if(data.status==="1") this.handleSearchClick()
            })
            .catch(error=>{
                this.setState(
                {
                    isloading:false,
                    alerttype:"Error! ",
                    isshow:true,
                    color:"danger",
                    message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade:true
                })
            })
    }
    GridDataModified(oldValue, newValue, id, column) {
        this.state.clientGrid.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }
    render() {
        const columns1 = [
            {
                dataField: 'name',
                text: 'NAME',
                editable:true,
                headerStyle: (colum, colIndex) => {
                    return { width:'70%', textAlign: 'left'}},
                style:{textAlign:'left'}
            },
            {
                dataField: 'isAreDeduction',
                text: 'ARE DEDUCTION',
                editable:true,
                headerStyle: (colum, colIndex) => {
                    return { width:'10%', textAlign: 'center'}},
                style:{textAlign:'center'},
                formatter: (cell, row) => {
                    if(row.isAreDeduction!='' && row.isAreDeduction!=null){
                        if(this.state.yesNo1DDL.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.yesNo1DDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.yesNo1DDL
                }
            },
            {
                dataField: 'isHDMF',
                text: 'HDMF 30TH PAYOUT',
                editable:true,
                headerStyle: (colum, colIndex) => {
                    return { width:'10%', textAlign: 'center'}},
                style:{textAlign:'center'},
                formatter: (cell, row) => {
                    if(row.isHDMF!='' && row.isHDMF!=null){
                        if(this.state.yesNo1DDL.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.yesNo1DDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.yesNo1DDL
                }
            },
            {
                dataField: 'isActive',
                text: 'STATUS',
                editable:true,
                headerStyle: (colum, colIndex) => {
                    return { width:'10%', textAlign: 'center'}},
                style:{textAlign:'center'},
                formatter: (cell, row) => {
                    if(row.isActive!='' && row.isActive!=null){
                        if(this.state.yesNoDDL.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.yesNoDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.yesNoDDL
                }
            },
        ] 
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.clientGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        const rowEvents = {}

        return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>Clients</Card.Header>
                            <Card.Body>
                                <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Row>
                                        <Col sm={12}> 
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChanged}
                                                options={this.state.clientDDL}
                                                placeholder="Name"
                                            />  
                                        </Col>
                                    </Form.Row>
                                    <ButtonToolbar sm={12} className="mt-3">
                                        <Button variant="primary" className="noser-button-mr1 ml-auto" onClick={this.handleSearchClick}>
                                            Search
                                        </Button>
                                        <NavLink to="/ClientCreate">
                                            <Button  variant="primary" variant="success" className="noser-button">
                                                Create
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                    <Card.Header className="mt-3">List of Clients</Card.Header>
                                    <div>
                                        <BootstrapTable
                                            ref="tbl"
                                            caption={Noser.TableHeader({title:"Search Result"})}
                                            rowClasses="noser-table-row-class no-checkbox"
                                            striped
                                            hover
                                            condensed
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            keyField = "id"
                                            data = { this.state.clientGrid }
                                            columns = { columns1}
                                            selectRow = { selectRow }
                                            cellEdit = { cellEditFactory({
                                                    mode: 'dbclick',
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                                this.GridDataModified(oldValue, newValue, row.id, column)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }

                                            />
                                        <ButtonToolbar sm={12} className="mt-3">
                                                <Button variant="success" className="noser-button-mr1 ml-auto" onClick={this.handleSaveClick}>
                                                    Save
                                                </Button>
                                                <NavLink to="/home">
                                                    <Button variant="danger" href="/home" classname="noser-button">
                                                        Close
                                                    </Button>
                                                </NavLink>
                                            </ButtonToolbar>
                                    </div>
                                        
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                    <NoserLoading show={this.state.isloading} />
            </div> 
            
        )
    }
}

export default Client;