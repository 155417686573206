import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Accordion
} 
from '../../noser-hris-component';
import CreatePayrollAdjustment from './Modal/CreatePayrollAdjustment';


class PayrollAdjustment extends Component {
    state = {
        userinfo        :   [],
        isloading       :   false,
        isshow          :   false,
        alerttype       :   "",
        message         :   "",
        color           :   "",
        fade            :   true,
        disableDeleteBtn:   true,
        disableBtn      :   true,
        openModal       :   false,
        
        clientDDL       :   [],
        clientDGDDL     :   [],
        periodDDL       :   [],
        inclusionsDDL   :   [],
        periodList      :   [],
        employeeDDL     :   [],
        employeeDGDDL   :   [],
        employeeList    :   [],
        inclusiontypeDDL:   [],
        inclusionDDL    :   [],
        inclusionList   :   [],
        statusDDL       :   [
            {"id":"0","name":"For Payroll Process"},
            {"id":"1","name":"Payroll Processed"},
            {"id":"2","name":"Billed"},
        ],
        adjustmentGrid  :   [],
        newArr          : [],

        clientId        :   "",
        periodId        :   "",
        employeeId      :   "",
        inclusiontypeId :   "",
        inclusionId     :   "",
        statusId        :   "0"

    }

    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients()
        this.GetInclusions()
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" || 
                         this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            let arr = []
            
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL:arr,isloading:false})
                let client = []
                arr.map(function(itm){
                    client.push({
                        "value":itm.id,
                        "label":itm.name
                    })
                })
                this.setState({clientDGDDL:client})
            }
            else{
                let client = []
                data.clients.map(function(itm){
                    client.push({
                        "value":itm.id,
                        "label":itm.name
                    })
                })
                this.setState({clientDGDDL:client})
                this.setState({clientDDL:data.clients,isloading:false})
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetPayrollPeriods = async() => {
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   "",
            "IsProcessed"   :   "1"
         };
         let api = ""
        if(this.state.isSpecial==="0"){
            api = "Payroll/ProcessPayroll"
        }
        else{
            api = "Payroll/ProcessSpecialClientPayroll"
        }
        await axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollPeriods",  params
             )
             .then(res => {
                const data = res.data;
                console.log("GetPayrollPeriods")
                console.log(data)
                this.setState({periodDDL:data.payrollPeriods})
            })
        this.GetEmployees()
    }
    GetEmployees = async() =>{
        
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId": this.state.userinfo.roleId==="1" || 
                          this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter", params)
        .then(res => {
            const data = res.data;
            console.log("GetEmployeeProfileFilter")
            console.log(data)
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        console.log("brr")
                        console.log(brr)
                        arr = data.profiles.filter(f => brr.some(item => item.referenceId === f.clientId))
                    }
                }
                this.setState({employeeDDL:arr.filter(x=>x.status==="ACTIVE"),isloading:false})
                let employee = []
                arr.filter(x=>x.status==="ACTIVE").map(function(itm){
                    employee.push({
                        "value":itm.id,
                        "label":itm.employeeName
                    })
                })
                this.setState({employeeDGDDL:employee})
            }
            else{
                this.setState({employeeDDL:data.profiles.filter(x=>x.status==="ACTIVE"),isloading:false})
                let employee = []
                data.profiles.filter(x=>x.status==="ACTIVE").map(function(itm){
                    employee.push({
                        "value":itm.id,
                        "label":itm.employeeName
                    })
                })
                this.setState({employeeDGDDL:employee})
            }
            
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetInclusionTypes()
    }
    GetInclusionTypes=async()=>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":"",
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetInclusionTypes", params)
        .then(res => {
            const data = res.data;
            this.setState({inclusiontypeDDL : data.inclusionTypes}) 
        })
        .catch(error=>{
            this.setState(
            { 
                isloading:false,
                alerttype:"Error! ", 
                isshow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })  
        })
    }
    GetInclusions=async()=>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "TypeId":this.state.inclusiontypeId,
            "Name":"",
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetInclusions", params)
        .then(res => {
            const data = res.data;
            console.log("GetInclusions")
            console.log(data)
            let inclusions = []
            data.inclusions.map(function(itm){
                inclusions.push({
                    "value":itm.id,
                    "label":itm.name
                })
            })
            this.setState({inclusionsDDL:inclusions})
            this.setState({inclusionDDL:data.inclusions,inclusionList:data.inclusions,isloading:false}) 
        })
        .catch(error=>{
            this.setState(
            { 
                isloading:false,
                alerttype:"Error! ", 
                isshow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })  
        })
        
    }
    handleChangedClient = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.clientId= ''
            this.state.client=''
            this.state.employeeDDL=[]
            return
        }
        this.state.clientId=e[0].id
        this.GetPayrollPeriods()
    }
    handleChangedPeriod = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.periodId=''
            return
        }
        this.state.periodId=e[0].periodId
    }
    handleChangedEmployee = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.employeeId=''
            return
        }
        this.state.employeeId=e[0].id
    }
    handleChangedInclusionType = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.inclusiontypeId=''
            this.setState({inclusionDDL:this.state.inclusionList})
            return
        }
        this.state.inclusiontypeId=e[0].id
        //this.state.inclusionDDL=[] //this.state.inclusionList.filter(x=>x.typeId===e[0].id)
        this.setState({inclusionDDL:this.state.inclusionList.filter(x=>x.typeId===e[0].id)})
    }
    handleChangedInclusion = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.inclusionId=''
            return
        }
        this.state.inclusionId=e[0].id
    }
    GridDataModified(oldValue, newValue, id, column) {
        let isDisable = true
        this.state.adjustmentGrid.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            if(item.isModified=="1")
                isDisable = false
        })
        this.setState({disableBtn:isDisable})
    }
    handleSearchClick = () => {
        console.log("test")
        this.setState({isloading:true})
        // if(!this.state.clientId){
        //     this.setState({ 
        //         isloading:false,
        //         alerttype:"Error!", 
        //         isshow:true,
        //         color:"danger",
        //         message: "Please select client!",
        //         fade:true
        //     }) 
        //     return
        // }
        const params = {
            "IpAddress"     :"0.0.0.0",
            "ClientId"      :this.state.clientId,
            "UserId"        :this.state.userinfo.userId,
            "PayPeriodId"    :this.state.periodId,
            "EmployeeId"   :this.state.employeeId,
            "AdjustmentTypeId"    :this.state.inclusiontypeId,
            "ReferenceId"    :this.state.inclusionId,
        };
        console.log("params")
        console.log(params)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetPayrollAdjustments",  params)
        //.post("http://hris.paramountmpc.org:8083/Timekeeping/GetPayrollPeriods",  params)
        .then(res => {
            const data = res.data.adjustments;
            console.log("GetPayrollAdjustments")
            console.log(data)
            this.setState({
                adjustmentGrid  : data,
                isloading       : false
            })
        })
        .catch(error=>{
            this.setState(
            { 
                isloading:false,
                alerttype:"Error! ", 
                isshow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })  
        })
    }

    handleDeleteClick = () => {
        this.setState({
            newArr      :   [],
            isloading   :   true
        })

        for (let i = 0; i < this.state.adjustmentGrid.length; i++) {
            if (this.state.adjustmentGrid[i]["isDeleted"] == 1) {
                const obj = {
                    "Id"                :   this.state.adjustmentGrid[i]["id"],
                    "ClientId"          :   this.state.adjustmentGrid[i]["clientId"],
                    "PayPeriodId"       :   this.state.adjustmentGrid[i]["payPeriodId"],
                    "EmployeeId"        :   this.state.adjustmentGrid[i]["employeeId"],
                    "AdjustmentTypeId"  :   this.state.adjustmentGrid[i]["adjustmentTypeId"],
                    "ReferenceId"       :   this.state.adjustmentGrid[i]["referenceId"],
                    "NoofDays"          :   this.state.adjustmentGrid[i]["noofDays"],
                    "Amount"            :   this.state.adjustmentGrid[i]["amount"],
                    "Remarks"           :   this.state.adjustmentGrid[i]["remarks"],
                    "IsDeleted"         :   this.state.adjustmentGrid[i]["isDeleted"].toString()
                };

                this.state.newArr.push(obj);
            }
        }

        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "adjustments"   :   this.state.newArr
        };
        console.log("params")
        console.log(params)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/EditPayrollAdjustment",  params)
        //.post("http://hris.paramountmpc.org:8083/Timekeeping/GetPayrollPeriods",  params)
        .then(res => {
            const data = res.data;
            console.log("EditPayrollAdjustment")
            console.log(data)
            this.setState({
                isloading   :   false,
                alerttype   :   data.status==="1" ? "Success!" : "Error!",
                isshow      :   true,
                color       :   data.status==="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                disableDeleteBtn    : true,
            });
        })
        .catch(error=>{
            this.setState(
            { 
                isloading:false,
                alerttype:"Error! ", 
                isshow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })  
        })

    }

    handleSaveClick = () => {
        this.setState({
            newArr      :   [],
            //isloading   :   true
        })

        for (let i = 0; i < this.state.adjustmentGrid.length; i++) {
            if (this.state.adjustmentGrid[i]["isModified"] == 1) {
                const obj = {
                    "Id"                :   this.state.adjustmentGrid[i]["id"],
                    "ClientId"          :   this.state.adjustmentGrid[i]["clientId"],
                    "PayPeriodId"       :   this.state.adjustmentGrid[i]["payPeriodId"],
                    "EmployeeId"        :   this.state.adjustmentGrid[i]["employeeId"],
                    "AdjustmentTypeId"  :   this.state.adjustmentGrid[i]["adjustmentTypeId"],
                    "ReferenceId"       :   this.state.adjustmentGrid[i]["referenceId"],
                    "NoofDays"          :   this.state.adjustmentGrid[i]["noofDays"],
                    "Amount"            :   this.state.adjustmentGrid[i]["amount"],
                    "Remarks"           :   this.state.adjustmentGrid[i]["remarks"],
                    "IsDeleted"         :   this.state.adjustmentGrid[i]["isDeleted"].toString()
                };

                this.state.newArr.push(obj);
            }
        }

        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "adjustments"   :   this.state.newArr
        };
        console.log("params")
        console.log(params)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/EditPayrollAdjustment",  params)
        //.post("http://hris.paramountmpc.org:8083/Timekeeping/GetPayrollPeriods",  params)
        .then(res => {
            const data = res.data;
            console.log("EditPayrollAdjustment")
            console.log(data)
            this.setState({
                isloading   :   false,
                alerttype   :   data.status==="1" ? "Success!" : "Error!",
                isshow      :   true,
                color       :   data.status==="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                disableBtn    : true,
            });
            this.RefreshPage();
        })
        .catch(error=>{
            this.setState(
            { 
                isloading:false,
                alerttype:"Error! ", 
                isshow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })  
        })

    }
    GridDataModified(oldValue, newValue, id, column) {
        let isDisable = true
        this.state.adjustmentGrid.map(function(item,i){
            if(item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            if(item.isModified=="1")
                isDisable = false
        })
        this.setState({disableBtn:isDisable})
    }

    RefreshPage = () => {
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :"0.0.0.0",
            "ClientId"      :"",
            "UserId"        :this.state.userinfo.userId,
            "PayPeriodId"    :"",
            "EmployeeId"   :"",
            "AdjustmentTypeId"    :"",
            "ReferenceId"    :"",
        };
        console.log("params")
        console.log(params)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetPayrollAdjustments",  params)
        //.post("http://hris.paramountmpc.org:8083/Timekeeping/GetPayrollPeriods",  params)
        .then(res => {
            const data = res.data.adjustments;
            console.log("GetPayrollAdjustments")
            console.log(data)
            this.setState({
                adjustmentGrid  : data,
                isloading       : false
            })
        })
        .catch(error=>{
            this.setState(
            { 
                isloading:false,
                alerttype:"Error! ", 
                isshow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })  
        })
    }

    render() {
        const cols = [
            {
                dataField: 'clientId',
                text: 'CLIENT',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%' }},
                style:{textAlign:'left',width:'15%'},
                sort:true,
                formatter: (cell, row) => {
                    if(row.clientId!=='' && row.clientId!==null || typeof row.clientId!=='undefined'){
                        if(this.state.clientDGDDL.filter(x => x.value == cell).length>0)
                            return this.state.clientDGDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.clientDGDDL
                },
                validator: (newValue, row, column) => {
                    if (row.clientId==="") {
                      return {
                        valid: false,
                        message:"Please select client."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'employeeName',
                text: 'EMPLOYEE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%' }},
                style:{textAlign:'left',width:'15%'},
                sort:true,
            },
            {
                dataField: 'periodName',
                text: 'Payroll Period',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'left',width:'10%'},
                sort:true,
            },
            {
                dataField: 'referenceId',
                text: 'INCLUSION',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%' }},
                style:{textAlign:'left',width:'15%'},
                sort:true,
                formatter: (cell, row) => {
                    if(row.referenceId!=='' && row.referenceId!==null){
                        if(this.state.inclusionsDDL.filter(x => x.value === cell).length==0)
                        return ""
                        else
                        return this.state.inclusionsDDL.find(x => x.value === cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.inclusionsDDL.sort((a, b) => (a.label > b.label) ? 1 : -1)
                }
            },
            {
                dataField: 'noofDays',
                text: 'NO OF DAYS',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'center',width:'8%'},
                sort:true,
                validator: (newValue, row, column) => {
                  if (isNaN(newValue)) {
                    return {
                      valid: false,
                      message: 'no. of days should be numeric'
                    };
                  }
                  if (newValue === "") {
                    return {
                      valid: false,
                      message: 'please input value for no. of days'
                    };
                  }
                  return true;
                }
            },
            {
                dataField: 'amount',
                text: 'AMOUNT',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'right',width:'8%'},
                sort:true,
                validator: (newValue, row, column) => {
                  if (isNaN(newValue)) {
                    return {
                      valid: false,
                      message: 'amount should be numeric'
                    };
                  }
                  if (newValue === "") {
                    return {
                      valid: false,
                      message: 'please input value for amount'
                    };
                  }
                  return true;
                }
            },
            {
                dataField: 'statusName',
                text: 'STATUS',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center',width:'10%'},
                sort:true
            },
            {
                dataField: 'remarks',
                text: 'REMARKS',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'19%' }},
                style:{textAlign:'center',width:'19%'},
                sort:true
            },
        ]
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.adjustmentGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        //item.isModified = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableDeleteBtn:isDisable})
            }
        };

    return(
            <div>
                <Banner />
                    <Container className="themed-container" fluid={true}>
                        <Card className="mt-5">
                            <Card.Header>PAYROLL >> INCENTIVE ADJUSTMENTS</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Card>
                                    <Accordion defaultActiveKey="0">
                                        <div className="card-header-tab">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="1" style={{color: "#FFFFFF"}}>
                                                SEARCH FILTER
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <Form>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <Typeahead
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangedClient}
                                                                options={this.state.clientDDL}
                                                                placeholder="Select CLient"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <Typeahead
                                                                labelKey='payPeriod'
                                                                id="basic-example"
                                                                onChange={this.handleChangedPeriod}
                                                                options={this.state.periodDDL}
                                                                placeholder="Select Period"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <Typeahead
                                                                labelKey='employeeName'
                                                                id="basic-example"
                                                                onChange={this.handleChangedEmployee}
                                                                options={this.state.employeeDDL}
                                                                placeholder="Select EMPLOYEE"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <Typeahead
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangedInclusionType}
                                                                options={this.state.inclusiontypeDDL}
                                                                placeholder="Select INCLUSION TYPE"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <Typeahead
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangedInclusion}
                                                                options={this.state.inclusionDDL}
                                                                placeholder="Select INCLUSION"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <Typeahead
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangedStatus}
                                                                options={this.state.statusDDL}
                                                                placeholder="Select STATUS"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <ButtonToolbar>
                                                                <Button variant="primary" className="ml-auto noser-button" onClick={ this.handleSearchClick }>Search</Button>
                                                            </ButtonToolbar>
                                                        </Col>
                                                    </Form.Group>
                                                </Form>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Accordion>
                                    
                                    </Card>
                                    
                                    <div className="mt-2">
                                    <Card.Header>LIST OF INCENTIVE ADJUSTMENTS</Card.Header>
                                        <BootstrapTable
                                            keyField = "id"
                                            data = { this.state.adjustmentGrid }
                                            columns = { cols }
                                            selectRow = { selectRow }
                                            cellEdit = { cellEditFactory({ 
                                                    mode: 'dbclick', 
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => { 
                                                        this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            striped
                                            hover
                                            condensed
                                            loading={true}
                                            wrapperClasses="table-responsive"
                                            pagination={ paginationFactory({sizePerPageRenderer})}
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        />
                                        <ButtonToolbar sm={12}>
                                            <Button variant="danger" className="ml-auto noser-button-mr1" disabled={this.state.disableDeleteBtn} onClick={this.handleDeleteClick}>Delete</Button>
                                            <Button variant="success" className="noser-button-mr1" disabled={this.state.disableBtn} onClick={this.handleSaveClick}>Save</Button>
                                            <Button variant="primary" className="noser-button" onClick={this.handleModalShow}>CREATE</Button>
                                        </ButtonToolbar>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                <NoserLoading show={this.state.isloading} />
                <CreatePayrollAdjustment 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
                {/* <CreateRegion 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                /> */}
            </div> 
        )
    }

}

export  default PayrollAdjustment 