import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker,
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown, FormControl} from 'react-bootstrap';

import  InsertCOCReference from '../Maintenance/Modal/InsertCOCReference';
import { Input } from 'reactstrap';
import ImgsViewer from 'react-images-viewer'

 
class SenaCases extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            disabledSave    :   false,

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            trainingRegisterTableList           :   [ { "typeOfCases" : "DOLE" } ],
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalCoCReferenceShow               :   false,
            category                :"",
            isLoading               :"",
            selectedEmployeeId      :[],
            selectedEmployeeNo      :[],
            selectedEmployeeBranch  :[],
            selectedPosition        :[],
            getClientList           :[],
            getEmployeeList         :[],
            getEmployeeNoList       :[],
            position                :[],
            location                :[],
            //addParagraph:[],
            values                  :[ " "],            
            dateApplied             :"",
            selectedClientName      :"",
            selectedEmployeeName    :[],
            selectedEmployeeNo      :[],
            irReferenceNo           :"",
            paragraph               :"",
            addedparagraph1         :"",
            addedparagraph2         :"",
            addedparagraph3         :"",
            addedparagraph4         :"",
            addedparagraph5         :"",
            addedparagraph6         :"",
            quotedStatement         :"",
            addedparagraph1Check    : false,
            cocReferences           : false,
            addedparagraph2Check    : false,
            addedparagraph3Check    : false,
            addedparagraph4Check    : false,
            addedparagraph5Check    : false,
            addedparagraph6Check    : false,
            quotedStatementCheck    : false,
            preparedBy              :"",
            prepareByPosition       :"",
            notedByPosition       :"",
            notedBy                 :"",
            receivedBy              :"",
            articleNo               :"",
            articleName             :"",
            sectionNo               :"",
            infraction              :"",
            category                :"",
            articleNo2              :"",
            articleName2            :"",
            sectionNo2              :"",
            infraction2             :"",
            category2               :"",
            sectionNo3              :"",
            infraction3             :"",
            category3               :"",          
            irDateIssued            :"",
            dateRecieveByER         :"",
            referenceNoSena          :"",
            cocReferences           :"",
            eSignatureNotedBy       :"",
            eSignaturePreparedBy    :"",
            disabledGenerate        :true,
            complientData            :[
                { 
                    "id":"1",
                    "name":"MINIMUM WAGE"
                },
                { 
                    "id":"2",
                    "name":"COLA"
                },
                 { 
                    "id":"3",
                    "name":"NIGHT SHIFT DIFFERENTIAL"
                },
                { 
                    "id":"4",
                    "name":"OVERTIME PAY"
                },
                { 
                    "id":"5",
                    "name":"HOLIDAY PAY"
                },
                { 
                    "id":"6",
                    "name":"PREMIUM PAY FRO SPECIAL DAY"
                },
                { 
                    "id":"7",
                    "name":"PREMIUM PAY FOR REST DAY"
                },
                { 
                    "id":"8",
                    "name":"SERVICE CHARGE"
                },
                { 
                    "id":"9",
                    "name":"SERVICE INCENTIVE LEAVE"
                },
                { 
                    "id":"10",
                    "name":"13TH MONTH PAY"
                },
                { 
                    "id":"11",
                    "name":"MATERNITY LEAVE"
                },
                { 
                    "id":"12",
                    "name":"PATERNITY LEAVE"
                },
                { 
                    "id":"13",
                    "name":"PARENTAL LEAVE FOR SOLO PARENT"
                },
                { 
                    "id":"14",
                    "name":"LEAVE FOR VICTIMS OF VAWC"
                },
                { 
                    "id":"15",
                    "name":"ILLEGAL DEDUCTIONS"
                },
                { 
                    "id":"16",
                    "name":"CLAIM FOR LAST SALARY/FINAL PAY"
                },
                { 
                    "id":"17",
                    "name":"CLAIM FOR SEPARATION PAY/INDEMNITY PAY"
                },

                { 
                    "id":"18",
                    "name":"CLAIM FOR RETIREMENT BENEFITS"
                },
                { 
                    "id":"19",
                    "name":"CBA VIOLATIONS"
                },
                { 
                    "id":"20",
                    "name":"DELAYED PAYMENT/TIME OF PAYMENT"
                },
                { 
                    "id":"21",
                    "name":"NON-ISSUANCE OF CERTIFICATE OF EMPLOYMENT"
                },
                { 
                    "id":"22",
                    "name":"ILLEGAL DISMISSAL"
                },
                { 
                    "id":"23",
                    "name":"CONSTRUCTIVE DISMISSAL"
                },
                { 
                    "id":"24",
                    "name":"OTHER PERSONNEL ACTIONS"
                },
                { 
                    "id":"25",
                    "name":"OCCUPATIONAL SAFETY AND HEALTH VIOLATIONS"
                },
                { 
                    "id":"26",
                    "name":"REGULARIZATION/CONTRACTUALIZATION"
                },
                { 
                    "id":"27",
                    "name":"MALTREATMENT/HARRASSMENT"
                },
                { 
                    "id":"28",
                    "name":"OTHERS"
                },
            ],
            


            selectedComplient        :"",
            senaStatus            :[
                { 
                    "id":"1",
                    "name":"PENDING"
                },
                { 
                    "id":"2",
                    "name":"SETTLED"
                },
                 { 
                    "id":"3",
                    "name":"FOR NLRC"
                },
            ],
            selectedSenaStatus      :"",
            disableEmployee         :false,
            employeePlaceHolder     :"",
            IRobjId                 :"",
            irReferenceNoData       :[],
            selectedirReferenceNo        :"",
            selectedPreparedbyClientId   :"",
            selectedPreparedbyClient     :"",
            memberStatus                 :   "",
            amountOfNegativeClaims      :   "",
            doleOffice                 :   "",
            doleOfficer                 :   "",
            selectedClientId            :   "",
            senaConference1Date         :   "",
            senaConference2Date         :   "",
            retailgroupData             :[
                {
                    "id":"1",
                    "name":"RETAIL 1",
                    "value":"RETAIL 1",
                    "label":"RETAIL 1",
                },
                {
                    "id":"2",
                    "name":"RETAIL 2",
                    "value":"RETAIL 2",
                    "label":"RETAIL 2",
                },
                {
                    "id":"3",
                    "name":"RETAIL 3",
                    "value":"RETAIL 3",
                    "label":"RETAIL 3",
                },
                {
                    "id":"4",
                    "name":"RETAIL 4",
                    "value":"RETAIL 4",
                    "label":"RETAIL 4",
                },
                {
                    "id":"5",
                    "name":"NON-RETAIL 1",
                    "value":"NON-RETAIL 1",
                    "label":"NON-RETAIL 1",
                },
                {
                    "id":"6",
                    "name":"NON-RETAIL 2",
                    "value":"NON-RETAIL 2",
                    "label":"NON-RETAIL 2",
                },
                {
                    "id":"7",
                    "name":"INTERNAL",
                    "value":"INTERNAL",
                    "label":"INTERNAL",
                },

            ],
            selectedRetailGroup         :   "",
            conference1Venue            :   "",
            conference2Venue            :   "",

            //change
                isCheckedactualIlligalDismissalYes        : false,
                isCheckedactualIlligalDismissalNo         : false,
                isCheckedconstractiveIlligalDismissalYes   : false,
                isCheckedconstractiveIlligalDismissalNo    : false,
                isCheckednonpaymentPremholidayYes       : false,
                isCheckednonpaymentPremholidayNo        : false,
            //new
                isCheckednonpaymentsalaryWageYes       : false,
                isCheckednonpaymentsalaryWageNo        : false,

                isCheckedreinstatementYes                  : false,
                isCheckedreinstatementNo                   : false,
                isCheckedbackwageYes                  : false,
                isCheckedbackwageNo                   : false,

                isCheckednonpaymentseperationPayYes       : false,
                isCheckednonpaymentseperationPayNo        : false,
                isCheckedpaymentOfDividentYes       : false,
                isCheckedpaymentOfDividentNo        : false,
                isCheckedrefundOfmembershipfeeYes       : false,
                isCheckedrefundOfmembershipfeeNo        : false,
                isCheckedrefundOfcapitalbuildupYes       : false,
                isCheckedrefundOfcapitalbuildupNo        : false,
                isCheckedilligalSuspensionYes       : false,
                isCheckedilligalSuspensionNo        : false,
                isCheckedmoralExemplaryDmgYes       : false,
                isCheckedmoralExemplaryDmgNo        : false,
                isCheckedattysFeeYes       : false,
                isCheckedattysFeeNo        : false,
                isCheckedhazardPayYes       : false,
                isCheckedhazardPayNo        : false,
                isCheckedilligalStrikeLockoutYes       : false,
                isCheckedilligalStrikeLockoutNo        : false,
                isCheckedincentivesYes       : false,
                isCheckedincentivesNo        : false,
                isCheckedallowanceYes       : false,
                isCheckedallowanceNo        : false,
                isCheckedgovernmentmanContriYes       : false,
                isCheckedgovernmentmanContriNo        : false,
                isCheckedgovernmentmanLoanYes       : false,
                isCheckedgovernmentmanLoanNo        : false,
                isCheckedgovernmentmanBenefitsYes       : false,
                isCheckedgovernmentmanBenefitsNo        : false,
                isCheckedunexpiredPortionOfContractYes            : false,
                isCheckedunexpiredPortionOfContractNo             : false,
             
            //old

            hideCheckList                       : false,
            
            isCheckedminimunwageYes             : false,
            isCheckedminimunwageNo              : false,
            isCheckedCOLAYes                    : false,
            isCheckedCOLANo                     : false,
            isCheckedDelayedPaymentYes          : false,
            isCheckedDelayedPaymentNo           : false,
            isCheckedFinalPayYes                : false,
            isCheckedFinalPayNo                 : false,
            isCheckedRetirementBenefYes         : false,
            isCheckedRetirementBenefNo          : false,
            isCheckedIlligalDeducYes            : false,
            isCheckedIlligalDeducNo             : false,
            isCheckedRegularContractualYes      : false,
            isCheckedRegularContractualNo       : false,
            isCheckedmaternityLeaveYes          : false,
            isCheckedmaternityLeaveNo           : false,
            isCheckedpaternityLeaveYes          : false,
            isCheckedpaternityLeaveNo           : false,
            isCheckedparentalForSoloYes         : false,
            isCheckedparentalForSoloNo          : false,
            isCheckedVawcVictimsYes             : false,
            isCheckedVawcVictimsNo              : false,
            isCheckedHarrassmentMaltreatYes     : false, 
            isCheckedHarrassmentMaltreatNo      : false,
            isCheckednonIssuanceCOEYes          : false,
            isCheckednonIssuanceCOENo           : false,
            isCheckedSafetyHealthVioYes         : false,
            isCheckedSafetyHealthVioNo          : false,
            isCheckedOtherPersonalActionYes     : false,
            isCheckedOtherPersonalActionNo      : false,            
            isCheckedothersYes                  : false,
            isCheckedothersNo                   : false,
            isCheckedovertimePayYes             : false,
            isCheckedovertimePayNo              : false,
            isCheckedholidayPayYes              : false,
            isCheckedholidayPayNo               : false,
            isCheckedPremRestDayPayYes          : false,
            isCheckedPremRestDayPayNo           : false,
            isCheckedServiceIncentiveLeaveYes   : false,
            isCheckedServiceIncentiveLeaveNo    : false,
            isChecked13monthPayYes              : false,
            isChecked13monthPayNo               : false, 
            isCheckedServiceChargeYes           : false,
            isCheckedServiceChargeNo            : false,           
            isCheckednightShiftYes              : false,
            isCheckednightShiftNo               : false,
            isCheckedCBAviolationYes            : false,
            isCheckedCBAviolationNo             : false,
            isCheckedIndemnityPayYes            : false,
            isCheckedIndemnityPayNo             : false,
         

            employeeDetails:[
                {
                    id              :"1",
                    ClientName      :"",
                    ClientId        :"",
                    employeeName    :"",
                    employeeNo      :"",
                    locationName    :"",
                    position        :"",
                    retailGroup     :"",
                    statusOfMember  :"",
                }

            ],

            statusOfMemberData:[
                {
                    "id":"1",
                    "value":"active",
                    "label":"active",
                },
                {
                    "id":"2",
                    "value":"Inactive",
                    "label":"Inactive",
                }
            ],
            caseLiabilityData:[
                {
                    "id":"1",
                    "name":"POSSIBLE",
                },
                {
                    "id":"2",
                    "name":"PROBABLE",
                },
                {
                    "id":"3",
                    "name":"REMOTE",
                }
                ,
                {
                    "id":"2",
                    "name":"CLOSED",
                }
            ],

            caseTitle:"",
            caseNo:"",
            respondent:"",
            senaConference3Date:"",
            senaConference4Date:"",
            conference3Venue:"",
            conference4Venue:"",

            minutes1file:"",
            minutes2file:"",
            minutes3file:"",
            minutes4file:"",
            minutes1fileBase64:"",
            minutes2fileBase64:"",
            minutes3fileBase64:"",
            minutes4fileBase64:"",

            conference1Remarks:"",
            conference2Remarks:"",
            conference3Remarks:"",
            conference4Remarks:"",
           
            isCheckednonPaymentOfsalaryYes                  : false,
            isCheckednonPaymentOfsalaryNo                   : false,
            isCheckednonPaymentOfovertimeYes                  : false,
            isCheckednonPaymentOfovertimeNo                   : false,
            senaCasesRemarks:"",
            selectedcaseliability:"",
            senaObjID:"",
            
        }

        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        this.setState({isLoading:true,loadingText:"Loading client list..."})

        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();;
        let checkUpdate = JSON.parse(sessionStorage.getItem("SenaProcess"))
        if(checkUpdate === "1"){
            this.getSena()
        }
        //this.getNTErefNo();;

        //let nextProcessCheck = JSON.parse(sessionStorage.getItem("nextProcessCheck"))

      /*   if(nextProcessCheck === "1"){
            let SenaData = JSON.parse(sessionStorage.getItem("SenaData"))


            this.setState({

            })

        } */

    }

    getSena=(e)=>{
        let SenaDataTmp = JSON.parse(sessionStorage.getItem("SenaData"))

        let empDetails = SenaDataTmp.employeeDetails
        console.log("empDetails")
        console.log(empDetails)

        let _senaConference1Date         = ""
        let _senaConference2Date         = ""
        let _senaConference3Date         = ""
        let _senaConference4Date         = ""
        let _dateOfSenaReceived         = ""

        if(SenaDataTmp.senaConference1Date !== ""){
            if(SenaDataTmp.senaConference1Date !== "Invalid date"){
                _senaConference1Date = new Date(SenaDataTmp.senaConference1Date)//moment().format('MM/DD/YYYY');
            }
            if(SenaDataTmp.senaConference1Date === "Invalid date"){
                _senaConference1Date = ""
            }
        }
        if(SenaDataTmp.senaConference2Date !== ""){
            if(SenaDataTmp.senaConference2Date !== "Invalid date"){
                _senaConference2Date = new Date(SenaDataTmp.senaConference2Date)
            }
            if(SenaDataTmp.senaConference2Date === "Invalid date"){
                _senaConference2Date = ""
            }
        }
        if(SenaDataTmp.senaConference3Date !== ""){
            if(SenaDataTmp.senaConference3Date === "Invalid date"){
                _senaConference3Date = ""
                console.log("_senaConference3Date else")
                console.log(_senaConference3Date)
            }
            if(SenaDataTmp.senaConference3Date !== "Invalid date"){
                _senaConference3Date = new Date(SenaDataTmp.senaConference3Date)
                console.log("SenaDataTmp.senaConference3Date if")
                console.log(SenaDataTmp.senaConference3Date)
            }
            
        }
        if(SenaDataTmp.senaConference4Date !== ""){
            if(SenaDataTmp.senaConference4Date !== "Invalid date"){
                _senaConference4Date = new Date(SenaDataTmp.senaConference4Date)
            }
            if(SenaDataTmp.senaConference4Date === "Invalid date"){
                _senaConference4Date = ""
            }
        }
        if(SenaDataTmp.dateOfSenaReceived !== ""){
            if(SenaDataTmp.dateOfSenaReceived !== "Invalid date"){
                _dateOfSenaReceived = new Date(SenaDataTmp.dateOfSenaReceived)
            }
            if(SenaDataTmp.dateOfSenaReceived === "Invalid date"){
                _dateOfSenaReceived = ""
            }
        }

        if(SenaDataTmp.minimunwage === "Yes"){
            //minimunwageTMP = true
            this.setState({
                isCheckedminimunwageYes: true
            })
        }else{
            this.setState({
                isCheckedminimunwageNo: true
            })
        }

        if(SenaDataTmp.COLA === "Yes"){
            this.setState({
                isCheckedCOLAYes: true
            })
        }else{
            this.setState({
                isCheckedCOLANo: true
            })
        }

        if(SenaDataTmp.nightShift === "Yes"){
            this.setState({
                isCheckednightShiftYes: true
            })
        }else{
            this.setState({
                isCheckednightShiftNo: true
            })
        }

        if(SenaDataTmp.overtimePay === "Yes"){
            this.setState({
                isCheckedovertimePayYes: true
            })
        }else{
            this.setState({
                isCheckedovertimePayNo: true
            })
        }

        if(SenaDataTmp.holidayPay === "Yes"){
            this.setState({
                isCheckedholidayPayYes: true
            })
        }else{
            this.setState({
                isCheckedholidayPayYes: true
            })
        }

        if(SenaDataTmp.PremSpecialDayPay === "Yes"){
            this.setState({
                isCheckednonpaymentPremholidayYes: true
            })
        }else{
            this.setState({
                isCheckednonpaymentPremholidayNo: true
            })
        }

        if(SenaDataTmp.PremRestDayPay === "Yes"){
            this.setState({
                isCheckedPremRestDayPayYes: true
            })
        }else{
            this.setState({
                isCheckedPremRestDayPayNo: true
            })
        }

        if(SenaDataTmp.ServiceCharge === "Yes"){
            this.setState({
                isCheckedServiceChargeYes: true
            })
        }else{
            this.setState({
                isCheckedServiceChargeNo: true
            })
        }

        if(SenaDataTmp.ServiceIncentiveLeave === "Yes"){
            this.setState({
                isCheckedServiceIncentiveLeaveYes: true
            })
        }else{
            this.setState({
                isCheckedServiceIncentiveLeaveNo: true
            })
        }

        if(SenaDataTmp.thirteenmonthPay === "Yes"){
            this.setState({
                isChecked13monthPayYes: true
            })
        }else{
            this.setState({
                isChecked13monthPayNo: true
            })
        }

        if(SenaDataTmp.maternityLeave === "Yes"){
            this.setState({
                isCheckedmaternityLeaveYes: true
            })
        }else{
            this.setState({
                isCheckedmaternityLeaveNo: true
            })
        }

        if(SenaDataTmp.paternityLeave === "Yes"){
            this.setState({
                isCheckedpaternityLeaveYes: true
            })
        }else{
            this.setState({
                isCheckedpaternityLeaveNo: true
            })
        }

        if(SenaDataTmp.parentalForSolo === "Yes"){
            this.setState({
                isCheckedparentalForSoloYes: true
            })
        }else{
            this.setState({
                isCheckedparentalForSoloNo: true
            })
        }

        if(SenaDataTmp.VawcVictims === "Yes"){
            this.setState({
                isCheckedVawcVictimsYes: true
            })
        }else{
            this.setState({
                isCheckedVawcVictimsNo: true
            })
        }

        if(SenaDataTmp.IlligalDeduc === "Yes"){
            this.setState({
                isCheckedIlligalDeducYes: true
            })
        }else{
            this.setState({
                isCheckedIlligalDeducNo: true
            })
        }

        if(SenaDataTmp.FinalPay === "Yes"){
            this.setState({
                isCheckedFinalPayYes: true
            })
        }else{
            this.setState({
                isCheckedFinalPayNo: true
            })
        }

        if(SenaDataTmp.IndemnityPay === "Yes"){
            this.setState({
                isCheckedIndemnityPayYes: true
            })
        }else{
            this.setState({
                isCheckedIndemnityPayNo: true
            })
        }

        if(SenaDataTmp.RetirementBenef === "Yes"){
            this.setState({
                isCheckedRetirementBenefYes: true
            })
        }else{
            this.setState({
                isCheckedRetirementBenefNo: true
            })
        }

        if(SenaDataTmp.CBAviolation === "Yes"){
            this.setState({
                isCheckedCBAviolationYes: true
            })
        }else{
            this.setState({
                isCheckedCBAviolationNo: true
            })
        }

        if(SenaDataTmp.DelayedPayment === "Yes"){
            this.setState({
                isCheckedDelayedPaymentYes: true
            })
        }else{
            this.setState({
                isCheckedDelayedPaymentNo: true
            })
        }

        if(SenaDataTmp.nonIssuanceCOE === "Yes"){
            this.setState({
                isCheckednonIssuanceCOEYes: true
            })
        }else{
            this.setState({
                isCheckednonIssuanceCOENo: true
            })
        }

        if(SenaDataTmp.IlligalDismissal === "Yes"){
            this.setState({
                isCheckedactualIlligalDismissalYes: true
            })
        }else{
            this.setState({
                isCheckedactualIlligalDismissalNo: true
            })
        }

        if(SenaDataTmp.ConstructiveDismissal === "Yes"){
            this.setState({
                isCheckedconstractiveIlligalDismissalYes: true
            })
        }else{
            this.setState({
                isCheckedconstractiveIlligalDismissalNo: true
            })
        }

        if(SenaDataTmp.OtherPersonalAction === "Yes"){
            this.setState({
                isCheckedOtherPersonalActionYes: true
            })
        }else{
            this.setState({
                isCheckedOtherPersonalActionNo: true
            })
        }

        if(SenaDataTmp.SafetyHealthVio === "Yes"){
            this.setState({
                isCheckedSafetyHealthVioYes: true
            })
        }else{
            this.setState({
                isCheckedSafetyHealthVioNo: true
            })
        }

        if(SenaDataTmp.RegularContractual === "Yes"){
            this.setState({
                isCheckedRegularContractualYes: true
            })
        }else{
            this.setState({
                isCheckedRegularContractualNo: true
            })
        }

        if(SenaDataTmp.HarrassmentMaltreat === "Yes"){
            this.setState({
                isCheckedHarrassmentMaltreatYes: true
            })
        }else{
            this.setState({
                isCheckedHarrassmentMaltreatNo: true
            })
        }

        if(SenaDataTmp.nonpaymentsalaryWage === "Yes"){
            this.setState({
                isCheckednonpaymentsalaryWageYes: true
            })
        }else{
            this.setState({
                isCheckednonpaymentsalaryWageNo: true
            })
        }

        if(SenaDataTmp.nonpaymentseperationPay === "Yes"){
            this.setState({
                isCheckednonpaymentseperationPayYes: true
            })
        }else{
            this.setState({
                isCheckednonpaymentseperationPayNo: true
            })
        }

        if(SenaDataTmp.paymentOfDivident === "Yes"){
            this.setState({
                isCheckedpaymentOfDividentYes: true
            })
        }else{
            this.setState({
                isCheckedpaymentOfDividentNo: true
            })
        }

        if(SenaDataTmp.refundOfmembershipfee === "Yes"){
            this.setState({
                isCheckedrefundOfmembershipfeeYes: true
            })
        }else{
            this.setState({
                isCheckedrefundOfmembershipfeeNo: true
            })
        }

        if(SenaDataTmp.refundOfcapitalbuildup === "Yes"){
            this.setState({
                isCheckedrefundOfcapitalbuildupYes: true
            })
        }else{
            this.setState({
                isCheckedrefundOfcapitalbuildupNo: true
            })
        }

        if(SenaDataTmp.illigalSuspension === "Yes"){
            this.setState({
                isCheckedilligalSuspensionYes: true
            })
        }else{
            this.setState({
                isCheckedilligalSuspensionNo: true
            })
        }

        if(SenaDataTmp.moralExemplaryDmg === "Yes"){
            this.setState({
                isCheckedmoralExemplaryDmgYes: true
            })
        }else{
            this.setState({
                isCheckedmoralExemplaryDmgNo: true
            })
        }

        if(SenaDataTmp.attysFee === "Yes"){
            this.setState({
                isCheckedattysFeeYes: true
            })
        }else{
            this.setState({
                isCheckedattysFeeNo: true
            })
        }

        if(SenaDataTmp.hazardPay === "Yes"){
            this.setState({
                isCheckedhazardPayYes: true
            })
        }else{
            this.setState({
                isCheckedhazardPayNo: true
            })
        }

        if(SenaDataTmp.illigalStrikeLockout === "Yes"){
            this.setState({
                isCheckedilligalStrikeLockoutYes: true
            })
        }else{
            this.setState({
                isCheckedilligalStrikeLockoutNo: true
            })
        }

        if(SenaDataTmp.incentives === "Yes"){
            this.setState({
                isCheckedincentivesYes: true
            })
        }else{
            this.setState({
                isCheckedincentivesNo: true
            })
        }

        if(SenaDataTmp.allowance === "Yes"){
            this.setState({
                isCheckedallowanceYes: true
            })
        }else{
            this.setState({
                isCheckedallowanceNo: true
            })
        }

        if(SenaDataTmp.governmentmanContri === "Yes"){
            this.setState({
                isCheckedgovernmentmanContriYes: true
            })
        }else{
            this.setState({
                isCheckedgovernmentmanContriNo: true
            })
        }

        if(SenaDataTmp.isCheckedgovernmentmanLoanYes === "Yes"){
            this.setState({
                isCheckedgovernmentmanLoanYes: true
            })
        }else{
            this.setState({
                isCheckedgovernmentmanLoanNo: true
            })
        }

        if(SenaDataTmp.isCheckedgovernmentmanBenefitsYes === "Yes"){
            this.setState({
                isCheckedgovernmentmanBenefitsYes: true
            })
        }else{
            this.setState({
                isCheckedgovernmentmanBenefitsNo: true
            })
        }

        if(SenaDataTmp.isCheckedunexpiredPortionOfContractYes === "Yes"){
            this.setState({
                isCheckedunexpiredPortionOfContractYes: true
            })
        }else{
            this.setState({
                isCheckedunexpiredPortionOfContractNo: true
            })
        }


        if(SenaDataTmp.isCheckedreinstatementYes === "Yes"){
            this.setState({
                isCheckedreinstatementYes: true
            })
        }else{
            this.setState({
                isCheckedreinstatementNo: true
            })
        }

        if(SenaDataTmp.isCheckedbackwageYes === "Yes"){
            this.setState({
                isCheckedbackwageYes: true
            })
        }else{
            this.setState({
                isCheckedbackwageNo: true
            })
        }


        if(SenaDataTmp.others === "Yes"){
            this.setState({
                isCheckedothersYes: true
            })
        }else{
            this.setState({
                isCheckedothersNo: true
            })
        }

        this.setState({
            senaObjID:SenaDataTmp.id,
            employeeDetails: SenaDataTmp.employeeDetails,
            amountOfNegativeClaims: SenaDataTmp.amountOfNegativeClaims,
            doleOffice: SenaDataTmp.doleOffice,
            doleOfficer: SenaDataTmp.doleOfficer,
            conference1Venue: SenaDataTmp.conference1Venue,
            conference2Venue: SenaDataTmp.conference2Venue,
            selectedSenaStatus: SenaDataTmp.senaStatus,
            selectedcaseliability: SenaDataTmp.caseliability,
            caseNo: SenaDataTmp.caseNo,
            caseTitle: SenaDataTmp.caseTitle,
            conference1Remarks: SenaDataTmp.conference1Remarks,
            conference2Remarks: SenaDataTmp.conference2Remarks,
            conference3Remarks: SenaDataTmp.conference3Remarks,
            conference4Remarks: SenaDataTmp.conference4Remarks,
            conference3Venue: SenaDataTmp.conference3Venue,
            conference4Venue: SenaDataTmp.conference4Venue,
            respondent: SenaDataTmp.respondent,
            senaCasesRemarks: SenaDataTmp.senaCasesRemarks,
            referenceNoSena: SenaDataTmp.senaReferenceNo,

            senaConference1Date: _senaConference1Date,
            senaConference2Date: _senaConference2Date,
            senaConference3Date: _senaConference3Date,
            senaConference4Date: _senaConference4Date,
            dateApplied: _dateOfSenaReceived,
            
        })

    }

    getIRrefNo=()=>{
       
        let filter_data ={'status' : "NTE"}

        const getParams ={
            "_collection" : "EDMonitoring",
            "filter_data" : filter_data
        }

        console.log("axios start")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data

            if(data.EDMonitoring.length > 0){

                const itemUsersDataLists = this.buildListEDMonitoring(data["EDMonitoring"])
            
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                        
                    let obj = {

                        'id'                        :   this.state.itemUserDataList[i]['id'],
                        'quotedStatement'           :   this.state.itemUserDataList[i]['quotedStatement'],
                        'dateOfNTE'                 :   this.state.itemUserDataList[i]['dateOfNTE'],
                        'client'                    :   this.state.itemUserDataList[i]['client'],              
                        'cycleOfDays'               :   this.state.itemUserDataList[i]['cycleOfDays'],
                        'dateOfIncident'            :   this.state.itemUserDataList[i]['dateOfIncident'],
                        'dateOfNOD'                 :   this.state.itemUserDataList[i]['dateOfNOD'],
                        'dateOfNOH'                 :   this.state.itemUserDataList[i]['dateOfNOH'],
                        'dateRecordNodaPenalty'     :   this.state.itemUserDataList[i]['dateRecordNodaPenalty'],
                        'employeeName'              :   this.state.itemUserDataList[i]['employeeName'],
                        'employeeNo'                :   this.state.itemUserDataList[i]['employeeNo'],
                        'irReferenceNo'             :   this.state.itemUserDataList[i]['irReferenceNo'],              
                        'location'                  :   this.state.itemUserDataList[i]['location'],
                        'position'                  :   this.state.itemUserDataList[i]['position'],
                        'retailgroup'               :   this.state.itemUserDataList[i]['retailgroup'],
                        'status'                    :   this.state.itemUserDataList[i]['status'],
                        'article'                   :   this.state.itemUserDataList[i]['article'],
                        'category'                  :   this.state.itemUserDataList[i]['category'],
                        'section'                   :   this.state.itemUserDataList[i]['section'],
                        'isDraft'                   :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'                 :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'                 :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'               :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'                :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'              :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'                :   this.state.itemUserDataList[i]['isModified'],
                        'NTEReferenceNo'            :   this.state.itemUserDataList[i]['NTEReferenceNo'],
                        'NODReferenceNo'            :   this.state.itemUserDataList[i]['NODReferenceNo'],
                        'NOHReferenceNo'            :   this.state.itemUserDataList[i]['NOHReferenceNo'],
                        'ClientId'                    :   this.state.itemUserDataList[i]['ClientId'],
                        "NTHoffense"                :   this.state.itemUserDataList[i]['NTHoffense'],  
                        "penalty"                   :   this.state.itemUserDataList[i]['penalty'],  
                        "monetaryCharge"            :   this.state.itemUserDataList[i]['monetaryCharge'],  
                        "statusOfIssue"             :   this.state.itemUserDataList[i]['statusOfIssue'],
                        'action'                    :   "1",

                    }

                    if(obj.status === "NTE"){
                        List.push(obj)
                    }
                }

                this.setState({
                    irReferenceNoData: List,
                    isloading:false
                })
                
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    }

    /////******DYNAMIC COMPONENT TASYO******/////////
    createUI(){
        return this.state.values.map((el, i) => 
            <div key={i} className="mt-3">
              { <FormControl as="textarea" value={el||''} aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChangedynmic.bind(this, i)} />}
              {/* <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                        Client
                    </Form.Label> */}
                  {/*   <Col sm="11"> */}
                       {/*  <Typeahead
                            labelKey='name'
                            id="basic-example"
                            onChange={this.onChangeClientList.bind(this, i)}
                            options={this.state.getClientList}
                            //selected={[this.state.selectedClientName]}
                            //disabled={this.state.disableEmployee}
                            //placeholder={this.state.clientPlaceHolder}
                        /> */}
                  {/*   </Col> */}
              {/*   </Form.Group> */}
            </div>          
        )
    }
   
    handleChangedynmic(i, event) {
        let values = [...this.state.values];
        values[i] = event.target.value;
        this.setState({ values });
        console.log(values)
    }
     
    addClick(){

      /*   let newID = ""
        newID = this.state.employeeDetails.length
        let newData={
            "id"  : newID,
            "name": [],
        }

        this.state.employeeDetails.push(newData) */

       this.setState(prevState => ({ values: [...prevState.values, '']}))
    }
     
    removeClick(i){
        let values = [...this.state.values];
        values.splice(i,1);
        this.setState({ values });
    }
   
    handleSubmit(event) {
       alert('A name was submitted: ' + this.state.values.join(', '));
       event.preventDefault();
    }
   


    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            console.log("res.data.clients")
            console.log(res.data.clients)
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });

            this.state.getClientList.map(function(d){
                d.label = d.name
                d.value = d.name
            })

        })
        .catch(error=>{
            this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
        })
    }
    
    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        console.log("Client selectedClientId " + this.state.selectedClientId );
        console.log("Client selectedClientName " + this.state.selectedClientName );
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})
        
        this.getEmployees();
    }
    
    getEmployees=(filter)=>{
        this.setState({isloading:true})
       
        let clientFilter = this.state.getClientList.find(x => x.name === filter);
        console.log("filter")
        console.log(clientFilter)
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":clientFilter.id,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            console.log("Employee List start ");
            console.log(res.data);
            console.log("Employee List end");
            const data = res.data

            if(data.status=="1"){
                this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
            }else{
                this.setState({getEmployeeList : [ {"employeeName" : ""} ], getEmployeeNoList : [],isloading:false}) 
            } 

            this.state.getEmployeeList.map(function(d){
                d.label = d.employeeName
                d.value = d.employeeName
            })
                
        })
        .catch(error=>{

           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })

        })
        
    }

    onChangeEmployeesList = (e) => {       

        if(e.length==0)
        {
            this.setState({getEmployeeListData: null, selectedPayrollpayPeriod: '', selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedEmployeeName = e[0].employeeName
        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeNo = e[0].employeeNo
        this.state.selectedEmployeeBranch = e[0].locationName
        this.state.position = e[0].position
        
        console.log("e[0]")
        console.log(e[0])
        console.log("e[0]")
        console.log("Work selectedEmployeeName   " + this.state.selectedEmployeeNo );
        console.log("Employee List start ");
        console.log(this.state.selectedEmployeeBranch);
        console.log("Employee List end");


       /*  this.setState({
            position:this.state.position,
            location:this.state.selectedEmployeeBranch
        }) */

        this.setState({isloading:false})

    }

    onChangePreparedbyClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedPreparedbyClient=""
            this.state.selectedPreparedbyClientId=""
            return
        } 
        this.state.selectedPreparedbyClientId = e[0].id
        this.state.selectedPreparedbyClient = e[0].name
        console.log("Client selectedClientId " + this.state.selectedClientId );
        console.log("Client selectedClientName " + this.state.selectedClientName );
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})
        
        this.getEmployeesPreparedby();
        //this.getEmployees();
    }

    getEmployeesPreparedby(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedPreparedbyClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            console.log("Employee List start ");
            console.log(res.data);
            console.log("Employee List end");
            const data = res.data

            if(data.status=="1")
                this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
            else
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 
                
        })
        .catch(error=>{

           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })

        })
        
    }

    onChangeEmployeesNo(e){

        this.setState({selectedEmployeeNo : e.target.value})
        //console.log(e.target.value)
    }

    onChangePosition(e){

        this.setState({position : e.target.value})
        //console.log(e.target.value)
    }

    onChangeLocation(e){

        this.setState({location : e.target.value})
        //console.log(e.target.value)
    }

    onChangememberStatus=(e)=>{

        this.setState({memberStatus : e.target.value})
        //console.log(e.target.value)
    }

    onChangeamountOfNegativeClaims=(e)=>{

        this.setState({amountOfNegativeClaims : e.target.value})
        //console.log(e.target.value)
    }

    onChangedoleOffice=(e)=>{

        this.setState({doleOffice : e.target.value})
        //console.log(e.target.value)
    }

    onChangedoleOfficer=(e)=>{

        this.setState({doleOfficer : e.target.value})
        //console.log(e.target.value)
    }

    showTrainingRegisterModal = (e) => {

        this.setState({modalCoCReferenceShow: true})

    }

    handleModalClose = (e) =>{

        this.setState({modalCoCReferenceShow: false})
        this.getViewDraftMemo();;

    }

    onChangeIrRefNo = (e) => {       

        if(e.length==0)
        {
            this.state.selectedirReferenceNo = ""
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})
        this.state.selectedirReferenceNo = e[0].irReferenceNo
        console.log(this.state.selectedirReferenceNo)
        /* 
        this.state.selectedirReferenceNo = e[0].irReferenceNo
        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeNo = e[0].employeeNo
        this.state.selectedEmployeeBranch = e[0].locationName
        this.state.position = e[0].position */
        
      
       /*  this.setState({
            position:this.state.position,
            location:this.state.selectedEmployeeBranch

        }) */

       this.getEDByref()

    }

    getEDByref=()=>{
        let filter_data ={'irReferenceNo':this.state.selectedirReferenceNo}

        const getParams ={
            "_collection" : "EDMonitoring",
            "filter_data" : filter_data
        }

        console.log("axios start")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data

            if(data.EDMonitoring.length > 0){

                const itemUsersDataLists = this.buildListEDMonitoring(data["EDMonitoring"])
            
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                        
                    let obj = {
                        'id'                        :   this.state.itemUserDataList[i]['id'],
                        'quotedStatement'           :   this.state.itemUserDataList[i]['quotedStatement'],
                        'dateOfNTE'                 :   this.state.itemUserDataList[i]['dateOfNTE'],
                        'client'                    :   this.state.itemUserDataList[i]['client'],              
                        'cycleOfDays'               :   this.state.itemUserDataList[i]['cycleOfDays'],
                        'dateOfIncident'            :   this.state.itemUserDataList[i]['dateOfIncident'],
                        'dateOfNOD'                 :   this.state.itemUserDataList[i]['dateOfNOD'],
                        'dateOfNOH'                 :   this.state.itemUserDataList[i]['dateOfNOH'],
                        'dateRecordNodaPenalty'     :   this.state.itemUserDataList[i]['dateRecordNodaPenalty'],
                        'employeeName'              :   this.state.itemUserDataList[i]['employeeName'],
                        'employeeNo'                :   this.state.itemUserDataList[i]['employeeNo'],
                        'irReferenceNo'             :   this.state.itemUserDataList[i]['irReferenceNo'],              
                        'location'                  :   this.state.itemUserDataList[i]['location'],
                        'position'                  :   this.state.itemUserDataList[i]['position'],
                        'retailgroup'               :   this.state.itemUserDataList[i]['retailgroup'],
                        'status'                    :   this.state.itemUserDataList[i]['status'],
                        'article'                   :   this.state.itemUserDataList[i]['article'],
                        'category'                  :   this.state.itemUserDataList[i]['category'],
                        'section'                   :   this.state.itemUserDataList[i]['section'],
                        'isDraft'                   :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'                 :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'                 :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'               :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'                :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'              :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'                :   this.state.itemUserDataList[i]['isModified'],
                        'ClientId'                  :   this.state.itemUserDataList[i]['ClientId'],
                        "NTHoffense"                :   this.state.itemUserDataList[i]['NTHoffense'],  
                        "penalty"                   :   this.state.itemUserDataList[i]['penalty'],  
                        "monetaryCharge"            :   this.state.itemUserDataList[i]['monetaryCharge'],  
                        "statusOfIssue"             :   this.state.itemUserDataList[i]['statusOfIssue'], 
                        'action'                    :   "1",
                    }
                    List.push(obj)
                }

                this.setState({
                    IRobjId: List[0].id,
                    selectedClientName:List[0].client,
                    selectedClientId:List[0].ClientId,
                    employeePlaceHolder:List[0].employeeName,
                    selectedEmployeeName:List[0].employeeName,
                    selectedEmployeeNo:List[0].employeeNo,
                    position:List[0].position,
                    selectedEmployeeBranch:List[0].location,
                    isloading:false,
                    disableEmployee:true,
                })
                this.state.selectedClientName=List[0].client
                console.log(List[0].client)
                console.log(this.state.selectedClientName)
                
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }


    

    handleChangeDate = date => {
        
        this.setState({
            dateApplied: date,
            isshow:false,
        })

    }

    handleChangesenaConference1Date = date => {
        
        this.setState({
            senaConference1Date: date,
            isshow:false,
        })

    }

    handleChangesenaConference2Date = date => {
        
        this.setState({
            senaConference2Date: date,
            isshow:false,
        })

    }

    handleChangesenaConference3Date = date => {
        
        this.setState({
            senaConference3Date: date,
            isshow:false,
        })

    }

    handleChangesenaConference4Date = date => {
        
        this.setState({
            senaConference4Date: date,
            isshow:false,
        })

    }

    getNTErefNo=(e)=>{       

       
        let newdate = new Date()
        let NTEDate        = moment(newdate).format('MMDDYYYYHHMMSS');
        let newIRRef        = "PHR"+NTEDate
      /*   this.setState({
            referenceNoSena: newIRRef,
            isloading:false
        }) */
        console.log(NTEDate)
        this.handleSaveClick()
       
            
    }

    handleSaveClick=()=>{
        let checkUpdate = JSON.parse(sessionStorage.getItem("SenaProcess"))
        console.log("checkUpdate")
        console.log(checkUpdate)
       /*  if(checkUpdate === "1"){
            this.setState({
                isloading       :   false,
                alerttype       :   "warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "under development please click back to create a new one",
                fade            :   true
            })
            return
        } */

        if(this.state.employeeDetails[0].employeeName === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "please fill up Employee Details",
                fade            :   true
            })
            return
        }

        if(this.state.createddate === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "please Enter Date",
                fade            :   true
            })
            return
        }

        if(this.state.dateApplied === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "please Enter Date",
                fade            :   true
            })
            return
        }
        
        let createddate     = moment(new Date()).format('MM/DD/YYYY');
        let datetmp         = moment(this.state.dateApplied).format('MM/DD/YYYY');
        let _senaConference1Date         = moment(this.state.senaConference1Date).format('MM/DD/YYYY');
        let _senaConference2Date         = moment(this.state.senaConference2Date).format('MM/DD/YYYY');
        let _senaConference3Date         = moment(this.state.senaConference3Date).format('MM/DD/YYYY');
        let _senaConference4Date         = moment(this.state.senaConference4Date).format('MM/DD/YYYY');
        let newdate = new Date()
        let NTEDate        = moment(newdate).format('MMDDYYYYHHMMSS');
        let newIRRef        = "PHR"+NTEDate
        if(datetmp === "Invalid date"){
            datetmp = ""
        } 
        if(_senaConference1Date === "Invalid date"){
            _senaConference1Date = ""
        } 
        if(_senaConference2Date === "Invalid date"){
            _senaConference2Date = ""
        } 
        if(_senaConference3Date === "Invalid date"){
            _senaConference3Date = ""
        } 
        if(_senaConference4Date === "Invalid date"){
            _senaConference4Date = ""
        } 
       
        let minimunwageTMP                   =   ""
        let COLATMP                          =   ""
        let nightShiftTMP                    =   ""
        let overtimePayTMP                   =   ""
        let holidayPayTMP                    =   ""
        let PremSpecialDayPayTMP             =   ""
        let PremRestDayPayTMP                =   ""
        let ServiceChargeTMP                 =   ""
        let ServiceIncentiveLeaveTMP         =   ""
        let One3monthPayTMP                  =   ""
        let maternityLeaveTMP                =   ""
        let paternityLeaveTMP                =   ""
        let parentalForSoloTMP               =   ""
        let VawcVictimsTMP                   =   ""
        let IlligalDeducTMP                  =   ""
        let FinalPayTMP                      =   ""
        let IndemnityPayTMP                  =   ""
        let RetirementBenefTMP               =   ""
        let CBAviolationTMP                  =   ""
        let DelayedPaymentTMP                =   ""
        let nonIssuanceCOETMP                =   ""
        let IlligalDismissalTMP              =   ""
        let ConstructiveDismissalTMP         =   ""
        let OtherPersonalActionTMP           =   ""
        let SafetyHealthVioTMP               =   ""
        let RegularContractualTMP            =   ""
        let HarrassmentMaltreatTMP           =   ""
        let othersTMP                        =   ""
        let compliance                       =   ""

        let nonpaymentsalaryWageTMP                        =   ""
        let nonpaymentseperationPayTMP                        =   ""
        let paymentOfDividentTMP                        =   ""
        let refundOfmembershipfeeTMP                        =   ""
        let refundOfcapitalbuildupTMP                        =   ""
        let illigalSuspensionTMP                        =   ""
        let moralExemplaryDmgTMP                        =   ""
        let attysFeeTMP                        =   ""
        let hazardPayTMP                        =   ""
        let illigalStrikeLockoutTMP                        =   ""
        let incentivesTMP                        =   ""
        let allowanceTMP                        =   ""
        let governmentmanContriTMP                        =   ""
        let governmentmanLoanTMP                        =   ""
        let governmentmanBenefitsTMP                        =   ""
        let PortionOfContractTMP                        =   ""
        let reinstatementTMP                        =   ""
        let backwageTMP                        =   ""


        if(this.state.isCheckedminimunwageYes === true){
            minimunwageTMP = "MINIMUM WAGE" + ";"
        }
        if(this.state.isCheckedCOLAYes === true){
            COLATMP = "COLA" + ";"
        }
        if(this.state.isCheckednightShiftYes === true){
            nightShiftTMP = "NIGHT SHIFT DIFFERENTIAL" + ";"
        }
        if(this.state.isCheckedovertimePayYes === true){
            overtimePayTMP = "NON-PAYMENT OF OVERTIME PAY" + ";"
        }
        if(this.state.isCheckedholidayPayYes === true){
            holidayPayTMP = "NON-PAYMENT OF HOLIDAY PAY" + ";"
        }
        if(this.state.isCheckednonpaymentPremholidayYes === true){
            PremSpecialDayPayTMP = "NON-PAYMENT PREMIUM PAY FOR HOLIDAY" + ";"
        }
        if(this.state.isCheckedPremRestDayPayYes === true){
            PremRestDayPayTMP = "PREMIUM PAY FOR REST DAY" + ";"
        }
        if(this.state.isCheckedServiceChargeYes === true){
            ServiceChargeTMP = "NON-PAYMENT SERVICE CHARGES" + ";"
        }
        if(this.state.isCheckedServiceIncentiveLeaveYes === true){
            ServiceIncentiveLeaveTMP = "NON-PAYMENT SERVICE INCENTIVE LEAVE" + ";"
        }
        if(this.state.isChecked13monthPayYes === true){
            One3monthPayTMP = "NON-PAYMENT 13TH MONTH PAY" + ";"
        }
        if(this.state.isCheckedmaternityLeaveYes === true){
            maternityLeaveTMP = "MATERNITY LEAVE " + ";"
        }
        if(this.state.isCheckedpaternityLeaveYes === true){
            paternityLeaveTMP = "PATERNITY LEAVE" + ";"
        }
        if(this.state.isCheckedparentalForSoloYes === true){
            parentalForSoloTMP = "PARENTAL LEAVE FOR SOLO PARENT" + ";"
        }
        if(this.state.isCheckedVawcVictimsYes === true){
            VawcVictimsTMP = "LEAVE FOR VICTIMS OF VAWC" + ";"
        }
        if(this.state.isCheckedIlligalDeducYes === true){
            IlligalDeducTMP = "ILLEGAL DEDUCTIONS" + ";"
        }
        if(this.state.isCheckedFinalPayYes === true){
            FinalPayTMP = "CLAIM FOR LAST SALARY or FINAL PAY" + ";"
        }
        if(this.state.isCheckedIndemnityPayYes === true){
            IndemnityPayTMP = "CLAIM FOR SEPARATION PAY or INDEMNITY PAY" + ";"
        }
        if(this.state.isCheckedRetirementBenefYes === true){
            RetirementBenefTMP = "CLAIM FOR RETIREMENT BENEFITS" + ";"
        }
        if(this.state.isCheckedCBAviolationYes === true){
            CBAviolationTMP = "CBA VIOLATIONS" + ";"
        }
        if(this.state.isCheckedDelayedPaymentYes === true){
            DelayedPaymentTMP = "DELAYED PAYMENT" + ";"
        }
        if(this.state.isCheckednonIssuanceCOEYes === true){
            nonIssuanceCOETMP = "NON ISSUANCE OF CERTIFICATE OF EMPLOYMENT" + ";"
        }
        if(this.state.isCheckedactualIlligalDismissalYes === true){
            IlligalDismissalTMP = "ACTUAL ILLEGAL DISMISSAL" + ";"
        }
        if(this.state.isCheckedconstractiveIlligalDismissalYes === true){
            ConstructiveDismissalTMP = "ACTUAL CONSTRUCTIVE DISMISSAL" + ";"
        }
        if(this.state.isCheckedOtherPersonalActionYes === true){
            OtherPersonalActionTMP = "OTHER PERSONNEL ACTIONS" + ";"
        }
        if(this.state.isCheckedSafetyHealthVioYes === true){
            SafetyHealthVioTMP = "OCCUPATIONAL SAFETY AND HEALTH VIOLATIONS" + ";"
        }
        if(this.state.isCheckedRegularContractualYes === true){
            RegularContractualTMP = "REGULARIZATION or CONTRACTUALIZATION" + ";"
        }
        if(this.state.isCheckedHarrassmentMaltreatYes === true){
            HarrassmentMaltreatTMP = "MALTREATMENT or HARRASSMENT" + ";"
        }

        if(this.state.isCheckednonpaymentsalaryWageYes === true){
            nonpaymentsalaryWageTMP = "NON-PAYMENT OF SALARY or WAGES" + ";"
        }
        if(this.state.isCheckednonpaymentseperationPayYes === true){
            nonpaymentseperationPayTMP = "NON-PAYMENT FOR SEPARATION PAY" + ";"
        }
        if(this.state.isCheckedpaymentOfDividentYes === true){
            paymentOfDividentTMP = "PAYMENT OF DIVIDEND" + ";"
        }
        if(this.state.isCheckedrefundOfmembershipfeeYes === true){
            refundOfmembershipfeeTMP = "REFUIND OF MEMBERSHIP FEE" + ";"
        }
        if(this.state.isCheckedrefundOfcapitalbuildupYes === true){
            refundOfcapitalbuildupTMP = "REFUND OF CAPITAL BUILD UP" + ";"
        }
        if(this.state.isCheckedilligalSuspensionYes === true){
            illigalSuspensionTMP = "ILLEGAL SUSPENSION" + ";"
        }
        if(this.state.isCheckedmoralExemplaryDmgYes === true){
            moralExemplaryDmgTMP = "MORAL AND EXEMPLARY DAMAGES" + ";"
        }
        if(this.state.isCheckedattysFeeYes === true){
            attysFeeTMP = "ATTYS FEES" + ";"
        }
        if(this.state.isCheckedhazardPayYes === true){
            hazardPayTMP = "HAZARD PAY" + ";"
        }
        if(this.state.isCheckedilligalStrikeLockoutYes === true){
            illigalStrikeLockoutTMP = "ILLEGAL STRIKE/LOCKOUT" + ";"
        }
        if(this.state.isCheckedincentivesYes === true){
            incentivesTMP = "INCENTIVES" + ";"
        }
        if(this.state.isCheckedallowanceYes === true){
            allowanceTMP = "ALLOWANCE" + ";"
        }
        if(this.state.isCheckedgovernmentmanContriYes === true){
            governmentmanContriTMP = "GOVERNMENT MANDATORY CONTRIBUTION" + ";"
        }
        if(this.state.isCheckedgovernmentmanLoanYes === true){
            governmentmanLoanTMP = "GOVERNMENT MANDATORY LOAN" + ";"
        }
        if(this.state.isCheckedgovernmentmanBenefitsYes === true){
            governmentmanBenefitsTMP = "GOVERNMENT MANDATORY BENEFITS" + ";"
        }
        if(this.state.isCheckedunexpiredPortionOfContractYes === true){
            PortionOfContractTMP = "PAYMENT OF UNEXPIRED PORTION OF CONTRACT" + ";"
        }

        if(this.state.isCheckedreinstatementYes === true){
            reinstatementTMP = "REINSTATEMENT" + ";"
        }
        if(this.state.isCheckedbackwageYes === true){
            backwageTMP = "BACKWAGE" + ";"
        }

        if(this.state.isCheckedothersYes === true){
            othersTMP = "others" + ";"
        }
       
        compliance = minimunwageTMP + COLATMP + nightShiftTMP + overtimePayTMP + holidayPayTMP + PremSpecialDayPayTMP + PremRestDayPayTMP + ServiceChargeTMP + ServiceIncentiveLeaveTMP + One3monthPayTMP + maternityLeaveTMP + paternityLeaveTMP + parentalForSoloTMP + VawcVictimsTMP + IlligalDeducTMP + FinalPayTMP + IndemnityPayTMP + RetirementBenefTMP + CBAviolationTMP + DelayedPaymentTMP + nonIssuanceCOETMP + IlligalDismissalTMP + ConstructiveDismissalTMP + OtherPersonalActionTMP + SafetyHealthVioTMP + RegularContractualTMP + HarrassmentMaltreatTMP + nonpaymentsalaryWageTMP+ nonpaymentseperationPayTMP+ paymentOfDividentTMP+refundOfmembershipfeeTMP+refundOfcapitalbuildupTMP+illigalSuspensionTMP+moralExemplaryDmgTMP+ attysFeeTMP+hazardPayTMP+illigalStrikeLockoutTMP+ incentivesTMP+allowanceTMP+governmentmanContriTMP+governmentmanLoanTMP+governmentmanBenefitsTMP+PortionOfContractTMP+reinstatementTMP+backwageTMP +othersTMP  
        

        if(checkUpdate ===0){

            let data = {

                /* "client"                :   this.state.selectedClientName,
                'clientId'              :   this.state.selectedClientId, 
                "employeeName"          :   this.state.selectedEmployeeName,
                "employeeNo"            :   this.state.selectedEmployeeNo,
                "position"              :   this.state.position,           
                "location"              :   this.state.selectedEmployeeBranch, */
                "employeeDetails"       :   this.state.employeeDetails,
                "amountOfNegativeClaims":   this.state.amountOfNegativeClaims,
                "doleOffice"            :   this.state.doleOffice,
                "doleOfficer"           :   this.state.doleOfficer,
                "senaConference1Date"   :   _senaConference1Date,
                "senaConference2Date"   :   _senaConference2Date,
                "senaConference3Date"   :   _senaConference3Date,
                "senaConference4Date"   :   _senaConference4Date,
                "conference1Venue"      :   this.state.conference1Venue,
                "conference2Venue"      :   this.state.conference2Venue,
                "senaStatus"            :   this.state.selectedSenaStatus,
                "dateOfSenaReceived"    :   datetmp,
                "senaReferenceNo"       :   newIRRef,
                "caseNo"                :   this.state.caseNo,
                "caseTitle"             :   this.state.caseTitle,
    
                "conference1Remarks"             :   this.state.conference1Remarks,
                "conference2Remarks"             :   this.state.conference2Remarks,
                "conference3Remarks"             :   this.state.conference3Remarks,
                "conference4Remarks"             :   this.state.conference4Remarks,
    
                "conference3Venue"              :   this.state.conference3Venue,
                "conference4Venue"              :   this.state.conference4Venue,
                "respondent"                    :   this.state.respondent,
                "senaCasesRemarks"              :   this.state.senaCasesRemarks,
                "caseliability"                 :   this.state.selectedcaseliability,
    
                "minimunwage"                   :   this.state.isCheckedminimunwageYes              ?   "Yes":"No" ,
                "COLA"                          :   this.state.isCheckedCOLAYes                     ?   "Yes":"No" ,
                "nightShift"                    :   this.state.isCheckednightShiftYes               ?   "Yes":"No" ,
                "overtimePay"                   :   this.state.isCheckedovertimePayYes              ?   "Yes":"No" ,
                "holidayPay"                    :   this.state.isCheckedholidayPayYes               ?   "Yes":"No" ,
                "PremSpecialDayPay"             :   this.state.isCheckednonpaymentPremholidayYes    ?   "Yes":"No" ,
                "PremRestDayPay"                :   this.state.isCheckedPremRestDayPayYes           ?   "Yes":"No" ,
                "ServiceCharge"                 :   this.state.isCheckedServiceChargeYes            ?   "Yes":"No" ,
                "ServiceIncentiveLeave"         :   this.state.isCheckedServiceIncentiveLeaveYes    ?   "Yes":"No" ,
                "thirteenmonthPay"                    :   this.state.isChecked13monthPayYes               ?   "Yes":"No" ,
                "maternityLeave"                :   this.state.isCheckedmaternityLeaveYes           ?   "Yes":"No" ,
                "paternityLeave"                :   this.state.isCheckedpaternityLeaveYes           ?   "Yes":"No" ,
                "parentalForSolo"               :   this.state.isCheckedparentalForSoloYes          ?   "Yes":"No" ,
                "VawcVictims"                   :   this.state.isCheckedVawcVictimsYes              ?   "Yes":"No" ,
                "IlligalDeduc"                  :   this.state.isCheckedIlligalDeducYes             ?   "Yes":"No" ,
                "FinalPay"                      :   this.state.isCheckedFinalPayYes                 ?   "Yes":"No" ,
                "IndemnityPay"                  :   this.state.isCheckedIndemnityPayYes             ?   "Yes":"No" ,
                "RetirementBenef"               :   this.state.isCheckedRetirementBenefYes          ?   "Yes":"No" ,
                "CBAviolation"                  :   this.state.isCheckedCBAviolationYes             ?   "Yes":"No" ,
                "DelayedPayment"                :   this.state.isCheckedDelayedPaymentYes           ?   "Yes":"No" ,
                "nonIssuanceCOE"                :   this.state.isCheckednonIssuanceCOEYes           ?   "Yes":"No" ,
                "IlligalDismissal"              :   this.state.isCheckedactualIlligalDismissalYes         ?   "Yes":"No" ,
                "ConstructiveDismissal"         :   this.state.isCheckedconstractiveIlligalDismissalYes    ?   "Yes":"No" ,
                "OtherPersonalAction"           :   this.state.isCheckedOtherPersonalActionYes      ?   "Yes":"No" ,
                "SafetyHealthVio"               :   this.state.isCheckedSafetyHealthVioYes          ?   "Yes":"No" ,
                "RegularContractual"            :   this.state.isCheckedRegularContractualYes       ?   "Yes":"No" ,
                "HarrassmentMaltreat"           :   this.state.isCheckedHarrassmentMaltreatYes      ?   "Yes":"No" ,
                "otherPersonalActionYes"        :   this.state.isCheckedOtherPersonalActionYes                   ?   "Yes":"No" ,
                "others"                        :   this.state.isCheckedothersYes                   ?   "Yes":"No" ,
    
                "nonpaymentsalaryWage"          :   this.state.isCheckednonpaymentsalaryWageYes     ?   "Yes":"No" ,
                "nonpaymentseperationPay"       :   this.state.isCheckednonpaymentseperationPayYes  ?   "Yes":"No" ,
                "paymentOfDivident"             :   this.state.isCheckedpaymentOfDividentYes        ?   "Yes":"No" ,
                "refundOfmembershipfee"         :   this.state.isCheckedrefundOfmembershipfeeYes    ?   "Yes":"No" ,
                "refundOfcapitalbuildup"        :   this.state.isCheckedrefundOfcapitalbuildupYes   ?   "Yes":"No" ,
                "illigalSuspension"             :   this.state.isCheckedilligalSuspensionYes        ?   "Yes":"No" ,
                "moralExemplaryDmg"             :   this.state.isCheckedmoralExemplaryDmgYes        ?   "Yes":"No" ,
                "attysFee"                      :   this.state.isCheckedattysFeeYes                 ?   "Yes":"No" ,
                "hazardPay"                     :   this.state.isCheckedhazardPayYes                ?   "Yes":"No" ,
                "illigalStrikeLockout"          :   this.state.isCheckedilligalStrikeLockoutYes     ?   "Yes":"No" ,
                "incentives"                    :   this.state.isCheckedincentivesYes               ?   "Yes":"No" ,
                "allowance"                     :   this.state.isCheckedallowanceYes                ?   "Yes":"No" ,
                "governmentmanContri"           :   this.state.isCheckedgovernmentmanContriYes      ?   "Yes":"No" ,
                "governmentmanLoan"             :   this.state.isCheckedgovernmentmanLoanYes        ?   "Yes":"No" ,
                "governmentmanBenefits"         :   this.state.isCheckedgovernmentmanBenefitsYes    ?   "Yes":"No" ,
                "unexpiredPortionOfContract"    :   this.state.isCheckedunexpiredPortionOfContractYes   ?   "Yes":"No" ,
    
                "reinstatement"                 :   this.state.isCheckedreinstatementYes   ?   "Yes":"No" ,
                "backwage"                      :   this.state.isCheckedbackwageYes   ?   "Yes":"No" ,
    
                "complaint"                     :   compliance,
    
                "status"                        :   "",
                "isDraft"                       :   "0",
                "isDeleted"                     :   "0",
                "createdby"                     :   this.state.userinfo.userId,
                "createddate"                   :   createddate,
                "modifiedby"                    :   'user007',
                "modifieddate"                  :   '0',
                "isModified"                    :   '0',
    
            }

            const addParams = {
                "_collection" : "SenaCases",
                "doc_data"    : data
            }
    
            console.log("saved")
            console.log(addParams)
            
            axios
            .post(AppConfiguration.Setting().noserMongoDB + "action/add", addParams)
            .then(res =>{
                
                const data = res.data
    
                console.log("success")
                console.log(res.data)
    
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   "Data had successfully Saved" ,
                    fade            :   true,
                    disabledGenerate  :   false,
                    disabledSave    :   true,
                })    
                    
            })
            .catch(error =>{
                console.log("error: " + error)
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })

        }else{

            let data = {

                /* "client"                :   this.state.selectedClientName,
                'clientId'              :   this.state.selectedClientId, 
                "employeeName"          :   this.state.selectedEmployeeName,
                "employeeNo"            :   this.state.selectedEmployeeNo,
                "position"              :   this.state.position,           
                "location"              :   this.state.selectedEmployeeBranch, */
                "employeeDetails"       :   this.state.employeeDetails,
                "amountOfNegativeClaims":   this.state.amountOfNegativeClaims,
                "doleOffice"            :   this.state.doleOffice,
                "doleOfficer"           :   this.state.doleOfficer,
                "senaConference1Date"   :   _senaConference1Date,
                "senaConference2Date"   :   _senaConference2Date,
                "senaConference3Date"   :   _senaConference3Date,
                "senaConference4Date"   :   _senaConference4Date,
                "conference1Venue"      :   this.state.conference1Venue,
                "conference2Venue"      :   this.state.conference2Venue,
                "senaStatus"            :   this.state.selectedSenaStatus,
                "dateOfSenaReceived"    :   datetmp,
                "senaReferenceNo"       :   this.state.referenceNoSena,
                "caseNo"                :   this.state.caseNo,
                "caseTitle"             :   this.state.caseTitle,
    
                "conference1Remarks"             :   this.state.conference1Remarks,
                "conference2Remarks"             :   this.state.conference2Remarks,
                "conference3Remarks"             :   this.state.conference3Remarks,
                "conference4Remarks"             :   this.state.conference4Remarks,
    
                "conference3Venue"              :   this.state.conference3Venue,
                "conference4Venue"              :   this.state.conference4Venue,
                "respondent"                    :   this.state.respondent,
                "senaCasesRemarks"              :   this.state.senaCasesRemarks,
                "caseliability"                 :   this.state.selectedcaseliability,
    
                "minimunwage"                   :   this.state.isCheckedminimunwageYes              ?   "Yes":"No" ,
                "COLA"                          :   this.state.isCheckedCOLAYes                     ?   "Yes":"No" ,
                "nightShift"                    :   this.state.isCheckednightShiftYes               ?   "Yes":"No" ,
                "overtimePay"                   :   this.state.isCheckedovertimePayYes              ?   "Yes":"No" ,
                "holidayPay"                    :   this.state.isCheckedholidayPayYes               ?   "Yes":"No" ,
                "PremSpecialDayPay"             :   this.state.isCheckednonpaymentPremholidayYes    ?   "Yes":"No" ,
                "PremRestDayPay"                :   this.state.isCheckedPremRestDayPayYes           ?   "Yes":"No" ,
                "ServiceCharge"                 :   this.state.isCheckedServiceChargeYes            ?   "Yes":"No" ,
                "ServiceIncentiveLeave"         :   this.state.isCheckedServiceIncentiveLeaveYes    ?   "Yes":"No" ,
                "thirteenmonthPay"                    :   this.state.isChecked13monthPayYes               ?   "Yes":"No" ,
                "maternityLeave"                :   this.state.isCheckedmaternityLeaveYes           ?   "Yes":"No" ,
                "paternityLeave"                :   this.state.isCheckedpaternityLeaveYes           ?   "Yes":"No" ,
                "parentalForSolo"               :   this.state.isCheckedparentalForSoloYes          ?   "Yes":"No" ,
                "VawcVictims"                   :   this.state.isCheckedVawcVictimsYes              ?   "Yes":"No" ,
                "IlligalDeduc"                  :   this.state.isCheckedIlligalDeducYes             ?   "Yes":"No" ,
                "FinalPay"                      :   this.state.isCheckedFinalPayYes                 ?   "Yes":"No" ,
                "IndemnityPay"                  :   this.state.isCheckedIndemnityPayYes             ?   "Yes":"No" ,
                "RetirementBenef"               :   this.state.isCheckedRetirementBenefYes          ?   "Yes":"No" ,
                "CBAviolation"                  :   this.state.isCheckedCBAviolationYes             ?   "Yes":"No" ,
                "DelayedPayment"                :   this.state.isCheckedDelayedPaymentYes           ?   "Yes":"No" ,
                "nonIssuanceCOE"                :   this.state.isCheckednonIssuanceCOEYes           ?   "Yes":"No" ,
                "IlligalDismissal"              :   this.state.isCheckedactualIlligalDismissalYes         ?   "Yes":"No" ,
                "ConstructiveDismissal"         :   this.state.isCheckedconstractiveIlligalDismissalYes    ?   "Yes":"No" ,
                "OtherPersonalAction"           :   this.state.isCheckedOtherPersonalActionYes      ?   "Yes":"No" ,
                "SafetyHealthVio"               :   this.state.isCheckedSafetyHealthVioYes          ?   "Yes":"No" ,
                "RegularContractual"            :   this.state.isCheckedRegularContractualYes       ?   "Yes":"No" ,
                "HarrassmentMaltreat"           :   this.state.isCheckedHarrassmentMaltreatYes      ?   "Yes":"No" ,
                "otherPersonalActionYes"        :   this.state.isCheckedOtherPersonalActionYes                   ?   "Yes":"No" ,
                "others"                        :   this.state.isCheckedothersYes                   ?   "Yes":"No" ,
    
                "nonpaymentsalaryWage"          :   this.state.isCheckednonpaymentsalaryWageYes     ?   "Yes":"No" ,
                "nonpaymentseperationPay"       :   this.state.isCheckednonpaymentseperationPayYes  ?   "Yes":"No" ,
                "paymentOfDivident"             :   this.state.isCheckedpaymentOfDividentYes        ?   "Yes":"No" ,
                "refundOfmembershipfee"         :   this.state.isCheckedrefundOfmembershipfeeYes    ?   "Yes":"No" ,
                "refundOfcapitalbuildup"        :   this.state.isCheckedrefundOfcapitalbuildupYes   ?   "Yes":"No" ,
                "illigalSuspension"             :   this.state.isCheckedilligalSuspensionYes        ?   "Yes":"No" ,
                "moralExemplaryDmg"             :   this.state.isCheckedmoralExemplaryDmgYes        ?   "Yes":"No" ,
                "attysFee"                      :   this.state.isCheckedattysFeeYes                 ?   "Yes":"No" ,
                "hazardPay"                     :   this.state.isCheckedhazardPayYes                ?   "Yes":"No" ,
                "illigalStrikeLockout"          :   this.state.isCheckedilligalStrikeLockoutYes     ?   "Yes":"No" ,
                "incentives"                    :   this.state.isCheckedincentivesYes               ?   "Yes":"No" ,
                "allowance"                     :   this.state.isCheckedallowanceYes                ?   "Yes":"No" ,
                "governmentmanContri"           :   this.state.isCheckedgovernmentmanContriYes      ?   "Yes":"No" ,
                "governmentmanLoan"             :   this.state.isCheckedgovernmentmanLoanYes        ?   "Yes":"No" ,
                "governmentmanBenefits"         :   this.state.isCheckedgovernmentmanBenefitsYes    ?   "Yes":"No" ,
                "unexpiredPortionOfContract"    :   this.state.isCheckedunexpiredPortionOfContractYes   ?   "Yes":"No" ,
    
                "reinstatement"                 :   this.state.isCheckedreinstatementYes   ?   "Yes":"No" ,
                "backwage"                      :   this.state.isCheckedbackwageYes   ?   "Yes":"No" ,
    
                "complaint"                     :   compliance,
    
                "status"                        :   "",
                "isDraft"                       :   "0",
                "isDeleted"                     :   "0",
                "createdby"                     :   this.state.userinfo.userId,
                "createddate"                   :   createddate,
                "modifiedby"                    :   'user007',
                "modifieddate"                  :   '0',
                "isModified"                    :   '1',
    
            }

            const updateParams = {

                "_collection" : "SenaCases",
                "doc_data"    : data,
                "_id"         : this.state.senaObjID,
    
            }
    
            console.log("updateSena")
            console.log(updateParams)       
            console.log("updateSena")
    
            axios
            .post(AppConfiguration.Setting().noserMongoDB + "action/update_one", updateParams)
            .then(res =>{
    
                this.setState({
                    isloading           :   false,
                    alerttype           :   "Success!",
                    isshow              :   true,
                    color               :   "success",
                    message             :   "Data had successfully Updated" ,
                    fade                :   true,
                    disabledGenerate    :   false,
                    disabledSave        :   true,
                
                })
                let SenaProcessTmp = "0"
                sessionStorage.setItem("SenaProcess", JSON.stringify(SenaProcessTmp)) 
            
            })
            .catch(error =>{
                console.log("error: " + error)
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
    
            })
        }
        
        
    }

    handleChangeParagraph=(e)=>{

        this.setState({
            paragraph   : e.target.value
        })

        //console.log(this.state.paragraph)
    }

    handleAddedParagraph1=(e)=>{

        if(this.state.addedparagraph5Check == true || this.state.addedparagraph5Check === true){
            
            this.setState({
                addedparagraph6Check:true
            })
        }

        if(this.state.addedparagraph4Check == true || this.state.addedparagraph4Check === true){
            
            this.setState({
                addedparagraph5Check:true
            })
        }

        if(this.state.addedparagraph3Check == true || this.state.addedparagraph3Check === true){
            
            this.setState({
                addedparagraph4Check:true
            })
        }

        if( this.state.addedparagraph2Check == true || this.state.addedparagraph2Check === true ){
            
            this.setState({
                addedparagraph3Check:true
            })
        }

        if(this.state.addedparagraph1Check == true || this.state.addedparagraph1Check === true){
            
            this.setState({
                addedparagraph2Check:true
            })

        }        
        
        this.setState({
            addedparagraph1Check:true
        })

        console.log("addedparagraph1Check start")
        console.log(this.state.addedparagraph1Check)
        console.log("addedparagraph1Check")

    }

    handleChangeAddedParagraph1=(e)=>{

        this.setState({
            addedparagraph1   : e.target.value
        })

        //console.log(this.state.addedparagraph1)

    }

    handleAddedParagraph2=(e)=>{
        this.setState({
            addedparagraph2Check:true
        })
    }

    handleChangeAddedParagraph2=(e)=>{

        this.setState({
            addedparagraph2   : e.target.value
        })
        //console.log(this.state.addedparagraph2)
    }

    handleAddedParagraph3=(e)=>{

        this.setState({
            addedparagraph3Check:true
        })
    }

    handleChangeAddedParagraph3=(e)=>{
        
        this.setState({
            addedparagraph3   : e.target.value
        })
        //console.log(this.state.addedparagraph3)
    }

    handleChangeAddedParagraph4=(e)=>{
        
        this.setState({
            addedparagraph4   : e.target.value
        })
        //console.log(this.state.addedparagraph3)
    }

    onChangeconference1Venue=(e)=>{
        
        this.setState({
            conference1Venue   : e.target.value
        })
        //console.log(this.state.addedparagraph3)
    }

    onChangeconference2Venue=(e)=>{
        
        this.setState({
            conference2Venue   : e.target.value
        })
        //console.log(this.state.addedparagraph3)
    }

    onChangeconference3Venue=(e)=>{
        
        this.setState({
            conference3Venue   : e.target.value
        })
        //console.log(this.state.addedparagraph3)
    }

    onChangeconference4Venue=(e)=>{
        
        this.setState({
            conference4Venue   : e.target.value
        })
        //console.log(this.state.addedparagraph3)
    }

    onChangerespondent=(e)=>{
        
        this.setState({
            respondent   : e.target.value
        })
        //console.log(this.state.addedparagraph3)
    }

    handleChangeminutes1file=(e , target)=>{
        this.setState({
            minutes1file:e.target.value
        })

        if(e.target.files < 1 || !e.target.validity.valid){
            return
        }

        let base64data = ""
        this.fileToBase64(e.target.files[0], (err , result)=> {
            
            if(result){
                base64data = result
                console.log(result)
                this.state.minutes1fileBase64 = result
            }
        })
        console.log("base64data")
        console.log(base64data)
        console.log("base64data")
    }

    handleChangeminutes2file=(e , target)=>{
        this.setState({
            minutes2file:e.target.value
        })

        if(e.target.files < 1 || !e.target.validity.valid){
            return
        }

        let base64data = ""
        this.fileToBase64(e.target.files[0], (err , result)=> {
            
            if(result){
                base64data = result
                console.log(result)
                this.state.minutes2fileBase64 = result
            }
        })
        console.log("base64data")
        console.log(base64data)
        console.log("base64data")
    }

    handleChangeminutes3file=(e , target)=>{
        this.setState({
            minutes3file:e.target.value
        })

        if(e.target.files < 1 || !e.target.validity.valid){
            return
        }

        let base64data = ""
        this.fileToBase64(e.target.files[0], (err , result)=> {
            
            if(result){
                base64data = result
                console.log(result)
                this.state.minutes3fileBase64 = result
            }
        })
        console.log("base64data")
        console.log(base64data)
        console.log("base64data")
    }

    handleChangeminutes4file=(e , target)=>{
        this.setState({
            minutes4file:e.target.value
        })

        if(e.target.files < 1 || !e.target.validity.valid){
            return
        }

        let base64data = ""
        this.fileToBase64(e.target.files[0], (err , result)=> {
            
            if(result){
                base64data = result
                console.log(result)
                this.state.minutes4fileBase64 = result
            }
        })
        console.log("base64data")
        console.log(base64data)
        console.log("base64data")
    }

    onChangeconference1Remarks=(e)=>{
        this.setState({
            conference1Remarks   : e.target.value
        })
        //console.log(this.state.quotedStatement)
    }

    onChangeconference2Remarks=(e)=>{
        this.setState({
            conference2Remarks   : e.target.value
        })
        //console.log(this.state.quotedStatement)
    }

    onChangeconference3Remarks=(e)=>{
        this.setState({
            conference3Remarks   : e.target.value
        })
        //console.log(this.state.quotedStatement)
    }

    onChangeconference4Remarks=(e)=>{
        this.setState({
            conference4Remarks   : e.target.value
        })
        //console.log(this.state.quotedStatement)
    }

    handleQuotedStatementCheck=(e)=>{
        this.setState({
            quotedStatementCheck:true
        })
    }

    handleChangeQuotedStatement=(e)=>{
        this.setState({
            quotedStatement   : e.target.value
        })
        //console.log(this.state.quotedStatement)
    }

    handleOnChangePreparedBy=(e)=>{
        // this.setState({
        //     preparedBy   : e.target.value
        // })
        // //console.log(this.state.preparedBy)

        if(e.length==0)
        {
            this.setState({getEmployeeListData: null, selectedPayrollpayPeriod: '', selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.preparedBy = e[0].employeeName
        this.state.prepareByPosition = e[0].position

        this.setState({isloading:false})
    }

    onChangeComplaint=(e)=>{
        

        if(e.length==0)
        {
            this.state.selectedComplient = ""
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedComplient = e[0].name
       

        this.setState({isloading:false})
    }

    onChangeRetailGroup=(e)=>{
        

        if(e.length==0)
        {
            this.state.selectedRetailGroup = ""
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedRetailGroup = e[0].name
       

        this.setState({isloading:false})
    }

    onChangeSenaStatus=(e)=>{
      

        if(e.length==0)
        {
            this.state.selectedSenaStatus = ""
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedSenaStatus = e[0].name

        this.setState({isloading:false})
    }

    onChangecaseliability=(e)=>{
      

        if(e.length==0)
        {
            this.state.selectedcaseliability = ""
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedcaseliability = e[0].name

        this.setState({isloading:false})
    }

    handleOnChangeNotedBy=(e)=>{
        // this.setState({
        //     notedBy   : e.target.value
        // })
        // //console.log(this.state.notedBy)

        if(e.length==0)
        {
            this.setState({getEmployeeListData: null, selectedPayrollpayPeriod: '', selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.notedBy = e[0].employeeName
        this.state.notedByPosition = e[0].position

        this.setState({isloading:false})
    }

    handleOnChangePrepareByPosition=(e)=>{
        this.setState({
            prepareByPosition   : e.target.value
        })
        //console.log(this.state.prepareByPosition)
    }

    handleOnChangenotedByPosition=(e)=>{
        this.setState({
            notedByPosition   : e.target.value
        })
        //console.log(this.state.prepareByPosition)
    }

    handleOnChangeReceivedBy=(e)=>{
        this.setState({
            receivedBy   : e.target.value
        })
        //console.log(this.state.receivedBy)
    }

    handleOnChangeIrDateIssued=(e)=>{
        this.setState({
            irDateIssued   : e.target.value
        })
        console.log(this.state.irDateIssued)
    }

    handleOnChangeDateRecieveByER=(e)=>{
        this.setState({
            dateRecieveByER   : e.target.value
        })
        console.log(this.state.dateRecieveByER)
    }

    handleOnChangereferenceNoSena=(e)=>{
        this.setState({
            referenceNoSena   : e.target.value
        })
        console.log(this.state.referenceNoSena)
    }

    onChangecaseNo=(e)=>{
        this.setState({
            caseNo   : e.target.value
        })
        console.log(this.state.caseNo)
    }

    onChangesenaCasesRemarks=(e)=>{

        this.setState({
            senaCasesRemarks   : e.target.value
        })
        console.log(this.state.senaCasesRemarks)

    }

    onChangecaseTitle=(e)=>{
        this.setState({
            caseTitle   : e.target.value
        })
        console.log(this.state.caseTitle)
    }

    getViewDraftMemo=()=>{
        let viewdataCOCRef= JSON.parse(sessionStorage.getItem("viewdataCOCRefTmp"))

        if(viewdataCOCRef == "1" || viewdataCOCRef === "1" ){
            let dataCOCRefTmp = JSON.parse(sessionStorage.getItem("dataCOCRef"))

            this.setState({
                articleNo       :   dataCOCRefTmp.articleNo,
                articleName     :   dataCOCRefTmp.articleName,
                sectionNo       :   dataCOCRefTmp.sectionNo,
                infraction      :   dataCOCRefTmp.infraction,
                category        :   dataCOCRefTmp.category,
                articleNo2      :   dataCOCRefTmp.articleNo2,
                articleName2    :   dataCOCRefTmp.articleName2,
                sectionNo2      :   dataCOCRefTmp.sectionNo2,
                infraction2     :   dataCOCRefTmp.infraction2,
                category2       :   dataCOCRefTmp.category2,
                sectionNo3      :   dataCOCRefTmp.sectionNo3,
                infraction3     :   dataCOCRefTmp.infraction3,
                category3       :   dataCOCRefTmp.category3,

                cocReferences: true
            })

        }else{

            this.setState({
                cocReferences: false
            })

        }
    }

    handleToExportNTE=()=>{
       
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');
        let data = {
            "client"                :   this.state.selectedClientName,
            "employeeName"          :   this.state.selectedEmployeeName,
            "position"              :   this.state.position,
            "employeeNo"            :   this.state.selectedEmployeeNo,
            "location"              :   this.state.selectedEmployeeBranch,
            "date"                  :   this.state.dateApplied,
            "referenceNoSena"        :   this.state.referenceNoSena,
            "irDateIssued"          :   this.state.irDateIssued,
            "dateRecieveByER"       :   this.state.dateRecieveByER,
            "paragraph"             :   this.state.paragraph,
            "addedparagraph1"       :   this.state.addedparagraph1,
            "addedparagraph2"       :   this.state.addedparagraph2,
            "addedparagraph3"       :   this.state.addedparagraph3,
            "addedparagraph4"       :   this.state.addedparagraph4,
            "addedparagraph5"       :   this.state.addedparagraph5,
            "addedparagraph6"       :   this.state.addedparagraph6,
            "quotedStatement"       :   this.state.quotedStatement,
            "articleNo"             :   this.state.articleNo,
            "articleName"           :   this.state.articleName,
            "sectionNo"             :   this.state.sectionNo,
            "infraction"            :   this.state.infraction,
            "category"              :   this.state.category,
            //"memotype"              :   this.state.selectedmemoType,

            "articleNo2"             :   this.state.articleNo2,
            "articleName2"           :   this.state.articleName2,
            "sectionNo2"             :   this.state.sectionNo2,
            "infraction2"            :   this.state.infraction2,
            "category2"              :   this.state.category2,

            "sectionNo3"             :   this.state.sectionNo3,
            "infraction3"            :   this.state.infraction3,
            "category3"              :   this.state.category3,

            "preparedBy"            :   this.state.preparedBy,
            "prepareByPosition"     :   this.state.prepareByPosition,
            "notedBy"               :   this.state.notedBy,
            "notedByPosition"       :   this.state.notedByPosition,
            "receivedBy"            :   this.state.receivedBy,
            "eSignatureNotedBy"     :   this.state.eSignatureNotedBy,
            "eSignaturePreparedBy"  :   this.state.eSignaturePreparedBy,

            "isDraft"               :   "0",
            "isDeleted"             :   "0",
            "createdby"             :   this.state.userinfo.userId,
            "createddate"           :   createddate,
            "modifiedby"            :   'user007',
            "modifieddate"          :   '0',
            "isModified"            :   '0',   

        }

        console.log(data)

        sessionStorage.setItem("data", JSON.stringify(data))
        let viewDraftMemoTmp = "1"
        sessionStorage.setItem("viewDraftMemoTmp", JSON.stringify(viewDraftMemoTmp))


        window.location.href='/ExportDemandLetter'

        /*if(this.state.selectedmemoType == "Late & Absenteeism"){
            window.location.href='/ExportNTE'
        }
         if(this.state.selectedmemoType == "AWOL 1ST Notice"){
            window.location.href='/ExportAwol1stNotice'
        }
        if(this.state.selectedmemoType == "AWOL 2ND Notice"){
            window.location.href='/ExportAwol2ndNotice'
        }
        if(this.state.selectedmemoType == "Notice of Hearing"){
            window.location.href='/ExportNOH'
        }
        if(this.state.selectedmemoType == "Recall"){
            window.location.href='/ExportRecall'
        }
        if(this.state.selectedmemoType == "Guarantor Demand Letter"){
            window.location.href='/ExportGuarantorDemandLetter'
        } */

    }

    handleOnChangeeSignatureNotedBy=(e)=>{

        const reader = new FileReader();
        reader.onload=()=>{
            if(reader.readyState === 2){
                this.setState({
                    eSignatureNotedBy:reader.result
                })
            }
        }
        reader.readAsDataURL(e.target.files[0])
    }

    handleOnChangeeSignaturePreparedBy=(e)=>{
 
        const reader = new FileReader();
        reader.onload=()=>{
            if(reader.readyState === 2){
                this.setState({
                    eSignaturePreparedBy:reader.result
                })
            }
        }
        reader.readAsDataURL(e.target.files[0])
    }

    onChangememoType = (e) => {       

        if(e.length==0)
        {
            this.setState({selectedmemoType: "" })          
            return
        }

        this.state.selectedmemoType = e[0].name

    }

    setBack=()=>{
        let whenBack = "0"
        sessionStorage.setItem("SenaProcess", JSON.stringify(whenBack)) 

    }

    onChangesCheckedminimunwageYes = (event) => {
        console.log("1");
        //console.log(e.target.checked);
        console.log(event);
       /*  if(e.target.value == on){

        } */
        this.setState({
            isCheckedminimunwageYes: true,
            isCheckedminimunwageNo: false,
        })        

    }

    onChangesCheckedminimunwageNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedminimunwageNo: true,
            isCheckedminimunwageYes: false,
        })        

    }

    onChangesCheckedCOLAYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedCOLAYes: true,
            isCheckedCOLANo: false,
        })        

    }

    onChangesCheckedCOLANo = (e) => {
        console.log("1");
        this.setState({
            isCheckedCOLANo: true,
            isCheckedCOLAYes: false,
        })        

    }

    onChangesCheckednightShiftYes = (e) => {
        console.log("1");
        this.setState({
            isCheckednightShiftYes: true,
            isCheckednightShiftNo: false,
        })        

    }
    
    onChangesCheckednightShiftNo = (e) => {
        console.log("1");
        this.setState({
            isCheckednightShiftNo: true,
            isCheckednightShiftYes: false,
        })        

    }

    onChangesCheckedovertimePayYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedovertimePayYes: true,
            isCheckedovertimePayNo: false,
        })        

    }

    onChangesCheckedovertimePayNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedovertimePayNo: true,
            isCheckedovertimePayYes: false,
        })        

    }

    onChangesCheckedholidayPayYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedholidayPayYes: true,
            isCheckedholidayPayNo: false,
        })        

    }

    onChangesCheckedholidayPayNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedholidayPayNo: true,
            isCheckedholidayPayYes: false,
        })        

    }

    onChangesCheckedPremSpecialDayPayYes = (e) => {
        console.log("1");
        this.setState({
            isCheckednonpaymentPremholidayYes: true,
            isCheckednonpaymentPremholidayNo: false,
        })        

    }

    onChangesCheckedPremSpecialDayPayNo = (e) => {
        console.log("1");
        this.setState({
            isCheckednonpaymentPremholidayNo: true,
            isCheckednonpaymentPremholidayYes: false,
        })        

    }

    onChangesCheckedPremRestDayPayYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedPremRestDayPayYes: true,
            isCheckedPremRestDayPayNo: false,
        })        

    }

    onChangesCheckedPremRestDayPayNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedPremRestDayPayNo: true,
            isCheckedPremRestDayPayYes: false,
        })        

    }

    onChangesCheckedServiceChargeYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedServiceChargeYes: true,
            isCheckedServiceChargeNo: false,
        })        

    }

    onChangesCheckedServiceChargeNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedServiceChargeNo: true,
            isCheckedServiceChargeYes: false,
        })        

    }

    onChangesCheckedServiceIncentiveLeaveYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedServiceIncentiveLeaveYes: true,
            isCheckedServiceIncentiveLeaveNo: false,
        })        

    }

    onChangesCheckedServiceIncentiveLeaveNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedServiceIncentiveLeaveNo: true,
            isCheckedServiceIncentiveLeaveYes: false,
        })        

    }

    onChangesChecked13monthPayYes = (e) => {
        console.log("1");
        this.setState({
            isChecked13monthPayYes: true,
            isChecked13monthPayNo: false,
        })        

    }

    onChangesChecked13monthPayNo = (e) => {
        console.log("1");
        this.setState({
            isChecked13monthPayNo: true,
            isChecked13monthPayYes: false,
        })        

    }

    onChangesCheckedmaternityLeaveYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedmaternityLeaveYes: true,
            isCheckedmaternityLeaveNo: false,
        })        

    }

    onChangesCheckedmaternityLeaveNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedmaternityLeaveNo: true,
            isCheckedmaternityLeaveYes: false,
        })        

    }

    onChangesCheckedpaternityLeaveYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedpaternityLeaveYes: true,
            isCheckedpaternityLeaveNo: false,
        })        

    }

    onChangesCheckedpaternityLeaveNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedpaternityLeaveNo: true,
            isCheckedpaternityLeaveYes: false,
        })        

    }

    onChangesCheckedparentalForSoloYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedparentalForSoloYes: true,
            isCheckedparentalForSoloNo: false,
        })        

    }

    onChangesCheckedparentalForSoloNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedparentalForSoloNo: true,
            isCheckedparentalForSoloYes: false,
        })        

    }

    onChangesCheckedVawcVictimsYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedVawcVictimsYes: true,
            isCheckedVawcVictimsNo: false,
        })        

    }

    onChangesCheckedVawcVictimsNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedVawcVictimsNo: true,
            isCheckedVawcVictimsYes: false,
        })        

    }

    onChangesCheckedIlligalDeducYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedIlligalDeducYes: true,
            isCheckedIlligalDeducNo: false,
        })        

    }

    onChangesCheckedIlligalDeducNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedIlligalDeducNo: true,
            isCheckedIlligalDeducYes: false,
        })        

    }

    onChangesCheckedFinalPayYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedFinalPayYes: true,
            isCheckedFinalPayNo: false,
        })        

    }

    onChangesCheckedFinalPayNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedFinalPayNo: true,
            isCheckedFinalPayYes: false,
        })        

    }

    onChangesCheckedIndemnityPayYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedIndemnityPayYes: true,
            isCheckedIndemnityPayNo: false,
        })        

    }

    onChangesCheckedIndemnityPayNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedIndemnityPayNo: true,
            isCheckedIndemnityPayYes: false,
        })        

    }

    onChangesCheckedRetirementBenefYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedRetirementBenefYes: true,
            isCheckedRetirementBenefNo: false,
        })        

    }

    onChangesCheckedRetirementBenefNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedRetirementBenefNo: true,
            isCheckedRetirementBenefYes: false,
        })        

    }

    onChangesCheckedCBAviolationYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedCBAviolationYes: true,
            isCheckedCBAviolationNo: false,
        })        

    }

    onChangesCheckedCBAviolationNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedCBAviolationNo: true,
            isCheckedCBAviolationYes: false,
        })        

    }

    onChangesCheckedDelayedPaymentYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedDelayedPaymentYes: true,
            isCheckedDelayedPaymentNo: false,
        })        

    }

    onChangesCheckedDelayedPaymentNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedDelayedPaymentNo: true,
            isCheckedDelayedPaymentYes: false,
        })        

    }

    onChangesCheckednonIssuanceCOEYes = (e) => {
        console.log("1");
        this.setState({
            isCheckednonIssuanceCOEYes: true,
            isCheckednonIssuanceCOENo: false,
        })        

    }

    onChangesCheckednonIssuanceCOENo = (e) => {
        console.log("1");
        this.setState({
            isCheckednonIssuanceCOENo: true,
            isCheckednonIssuanceCOEYes: false,
        })        

    }

    onChangesCheckedIlligalDismissalYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedactualIlligalDismissalYes: true,
            isCheckedactualIlligalDismissalNo: false,
        })        

    }

    onChangesCheckedIlligalDismissalNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedactualIlligalDismissalNo: true,
            isCheckedactualIlligalDismissalYes: false,
        })        

    }

    onChangesCheckedConstructiveDismissalYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedconstractiveIlligalDismissalYes: true,
            isCheckedconstractiveIlligalDismissalNo: false,
        })        

    }

    onChangesCheckedConstructiveDismissalNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedconstractiveIlligalDismissalNo: true,
            isCheckedconstractiveIlligalDismissalYes: false,
        })        

    }

    onChangesCheckedOtherPersonalActionYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedOtherPersonalActionYes: true,
            isCheckedOtherPersonalActionNo: false,
        })        

    }

    onChangesCheckedOtherPersonalActionNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedOtherPersonalActionNo: true,
            isCheckedOtherPersonalActionYes: false,
        })        

    }

    onChangesCheckedSafetyHealthVioYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedSafetyHealthVioYes: true,
            isCheckedSafetyHealthVioNo: false,
        })        

    }

    onChangesCheckedSafetyHealthVioNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedSafetyHealthVioNo: true,
            isCheckedSafetyHealthVioYes: false,
        })        

    }

    onChangesCheckedRegularContractualYes = (e) => {
        
        console.log("1");
        this.setState({
            isCheckedRegularContractualYes: true,
            isCheckedRegularContractualNo: false,
        })        

    }

    onChangesCheckedRegularContractualNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedRegularContractualNo: true,
            isCheckedRegularContractualYes: false,
        })        

    }

    onChangesCheckedHarrassmentMaltreatYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedHarrassmentMaltreatYes: true,
            isCheckedHarrassmentMaltreatNo: false,
        })        

    }

    onChangesCheckedHarrassmentMaltreatNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedHarrassmentMaltreatNo: true,
            isCheckedHarrassmentMaltreatYes: false,
        })        

    }

    onChangesCheckedothersYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedothersYes: true,
            isCheckedothersNo: false,
        })        

    }

    onChangesCheckedothersNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedothersNo: true,
            isCheckedothersYes: false,
        })        

    }  

    onChangesCheckednonpaymentsalaryWageYes = (e) => {
        console.log("1");
        this.setState({
            isCheckednonpaymentsalaryWageYes: true,
            isCheckednonpaymentsalaryWageNo: false,
        })        

    }

    onChangesCheckednonpaymentsalaryWageNo = (e) => {
        console.log("1");
        this.setState({
            isCheckednonpaymentsalaryWageNo: true,
            isCheckednonpaymentsalaryWageYes: false,
        })        

    }

    onChangesCheckednonpaymentseperationPayYes = (e) => {
        console.log("1");
        this.setState({
            isCheckednonpaymentseperationPayYes: true,
            isCheckednonpaymentseperationPayNo: false,
        })        

    }

    onChangesCheckednonpaymentseperationPayNo = (e) => {
        console.log("1");
        this.setState({
            isCheckednonpaymentseperationPayNo: true,
            isCheckednonpaymentseperationPayYes: false,
        })        

    }

    onChangesCheckedpaymentOfDividentYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedpaymentOfDividentYes: true,
            isCheckedpaymentOfDividentNo: false,
        })        

    }

    onChangesCheckedpaymentOfDividentNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedpaymentOfDividentNo: true,
            isCheckedpaymentOfDividentYes: false,
        })        

    }

    onChangesCheckedrefundOfmembershipfeeYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedrefundOfmembershipfeeYes: true,
            isCheckedrefundOfmembershipfeeNo: false,
        })        

    }

    onChangesCheckedrefundOfmembershipfeeNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedrefundOfmembershipfeeNo: true,
            isCheckedrefundOfmembershipfeeYes: false,
        })        

    }

    onChangesCheckedrefundOfcapitalbuildupYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedrefundOfcapitalbuildupYes: true,
            isCheckedrefundOfcapitalbuildupNo: false,
        })        

    }

    onChangesCheckedrefundOfcapitalbuildupNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedrefundOfcapitalbuildupNo: true,
            isCheckedrefundOfcapitalbuildupYes: false,
        })        

    }

    onChangesCheckedilligalSuspensionYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedilligalSuspensionYes: true,
            isCheckedilligalSuspensionNo: false,
        })        

    }

    onChangesCheckedilligalSuspensionNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedilligalSuspensionNo: true,
            isCheckedilligalSuspensionYes: false,
        })        

    }

    onChangesCheckedmoralExemplaryDmgYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedmoralExemplaryDmgYes: true,
            isCheckedmoralExemplaryDmgNo: false,
        })        

    }

    onChangesCheckedmoralExemplaryDmgNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedmoralExemplaryDmgNo: true,
            isCheckedmoralExemplaryDmgYes: false,
        })        

    }

    onChangesCheckedattysFeeYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedattysFeeYes: true,
            isCheckedattysFeeNo: false,
        })        

    }

    onChangesCheckedattysFeeNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedattysFeeNo: true,
            isCheckedattysFeeYes: false,
        })        

    }

    onChangesCheckedhazardPayYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedhazardPayYes: true,
            isCheckedhazardPayNo: false,
        })        

    }

    onChangesCheckedhazardPayNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedhazardPayNo: true,
            isCheckedhazardPayYes: false,
        })        

    }

    onChangesCheckedilligalStrikeLockoutYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedilligalStrikeLockoutYes: true,
            isCheckedilligalStrikeLockoutNo: false,
        })        

    }

    onChangesCheckedilligalStrikeLockoutNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedilligalStrikeLockoutNo: true,
            isCheckedilligalStrikeLockoutYes: false,
        })        

    }

    onChangesCheckedincentivesYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedincentivesYes: true,
            isCheckedincentivesNo: false,
        })        

    }

    onChangesCheckedincentivesNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedincentivesNo: true,
            isCheckedincentivesYes: false,
        })        

    }

    onChangesCheckedallowanceYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedallowanceYes: true,
            isCheckedallowanceNo: false,
        })        

    }

    onChangesCheckedallowanceNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedallowanceNo: true,
            isCheckedallowanceYes: false,
        })        

    }

    onChangesCheckedgovernmentmanContriYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedgovernmentmanContriYes: true,
            isCheckedgovernmentmanContriNo: false,
        })        

    }

    onChangesCheckedgovernmentmanContriNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedgovernmentmanContriNo: true,
            isCheckedgovernmentmanContriYes: false,
        })        

    }

    onChangesCheckedgovernmentmanLoanYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedgovernmentmanLoanYes: true,
            isCheckedgovernmentmanLoanNo: false,
        })        

    }

    onChangesCheckedgovernmentmanLoanNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedgovernmentmanLoanNo: true,
            isCheckedgovernmentmanLoanYes: false,
        })        

    }

    onChangesCheckedgovernmentmanBenefitsYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedgovernmentmanBenefitsYes: true,
            isCheckedgovernmentmanBenefitsNo: false,
        })        

    }

    onChangesCheckedgovernmentmanBenefitsNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedgovernmentmanBenefitsNo: true,
            isCheckedgovernmentmanBenefitsYes: false,
        })        

    }

    onChangesCheckedunexpiredPortionOfContractYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedunexpiredPortionOfContractYes: true,
            isCheckedunexpiredPortionOfContractNo: false,
        })        

    }

    onChangesCheckedunexpiredPortionOfContractNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedunexpiredPortionOfContractNo: true,
            isCheckedunexpiredPortionOfContractYes: false,
        })        

    }

    onChangesCheckedbackwageYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedbackwageYes: true,
            isCheckedbackwageNo: false,
        })        

    }

    onChangesCheckedbackwageNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedbackwageNo: true,
            isCheckedbackwageYes: false,
        })        

    }

    onChangesCheckedreinstatementYes = (e) => {
        console.log("1");
        this.setState({
            isCheckedreinstatementYes: true,
            isCheckedreinstatementNo: false,
        })        

    }

    onChangesCheckedreinstatementNo = (e) => {
        console.log("1");
        this.setState({
            isCheckedreinstatementNo: true,
            isCheckedreinstatementYes: false,
        })        

    }
    
    onChangesHideCheckList = (e) => {
        console.log("1");
        this.setState({
            hideCheckList: !this.state.hideCheckList,
        })        

    } 

    GridDataModified(oldValue, newValue, id, column) {
        ////console.log(id)
        let checktmp = "0"
        let filter = ""
        let filter2 = ""
        let clientData = this.state.getClientList
        let employeeData = this.state.getEmployeeList
        this.state.employeeDetails.map(function(item,i) {
            
            if (item.id===id){
                checktmp = "1"
                item.isModified = newValue!=oldValue ? "1" : "0"
                filter= item.ClientName
                filter2= item.employeeName

                if(item.ClientName == newValue){
                    let newClientIdtmp = clientData.find(x => x.name === filter);

                    item.ClientId = newClientIdtmp.id

                }
                if(item.employeeName == newValue){
                    if(item.employeeName !== undefined){
                        let newemployeeNotmp = employeeData.find(x => x.employeeName === filter2);

                        item.employeeNo = newemployeeNotmp.employeeNo
                        item.position   = newemployeeNotmp.position
                        item.locationName = newemployeeNotmp.locationName
                    }
                   
                }

                

                console.log(item)
                
            }
               
        })

        if(checktmp === "1"){
            this.getEmployees(filter)
        }
        

    }

    AddEmployee=()=>{
        
        this.setState({
            isloading:true
        })
        let IDnew = this.state.employeeDetails.length + 1

        let addData = {
            id              :String(IDnew),
            ClientName      :"",
            ClientId        :"",
            employeeName    :"",
            employeeNo      :"",
            locationName    :"",
            position        :"",
            retailGroup     :"",
            statusOfMember  :"",
           
        }

        this.reloadGrid(addData)
       
    }

    reloadGrid=(addData)=>{
       
        this.state.employeeDetails.push(addData)
        console.log(this.state.employeeDetails)
        console.log(addData)
        this.setState({
            isloading : false
        })
    
    }

    render() {     
        
        const employeeDetailsColumn = [
            {

                dataField   : 'ClientName',
                text        : 'Client',
                editable    : true,
                editor: {
                    type: Type.SELECT,
                    options: this.state.getClientList,
                   
                },
                headerStyle : () => {
                    return { width  : "14.28%" };
                },
                style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', }
                

            },
            {

                dataField   : 'employeeName',
                text        : 'COMPLAINANTS (NAME)',
                editable    : true,
                editor: {
                    type: Type.SELECT,
                    options: this.state.getEmployeeList
                },
                headerStyle : () => {
                    return { width  : "14.28%" };
                },
                /* formatter: (cell, row) => {
                    if(row.employeeName !=='' || typeof row.employeeName === 'undefined'){
                        if(this.state.employeeDetails.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.employeeDetails.find(x => x.value == cell).value;
                    }
                }, */
                style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', }

            },
            {

                dataField   : 'employeeNo',
                text        : 'Employee No',
                editable    : false,
                headerStyle : () => {
                    return { width  : "14.28%" };
                },
                style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', }

            },
            {

                dataField   : 'position',
                text        : 'Position',
                editable    : false,
                headerStyle : () => {
                    return { width  : "14.28%" };
                },
                style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', }

            },
            {

                dataField   : 'locationName',
                text        : 'Location',
                editable    : false,
                headerStyle : () => {
                    return { width  : "14.28%" };
                },
                style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', }

            },
            {

                dataField   : 'retailGroup',
                text        : 'Retail Group',
                editable    : true,
                editor: {
                    type: Type.SELECT,
                    options: this.state.retailgroupData
                },
                headerStyle : () => {
                    return { width  : "14.28%" };
                },
                style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', }

            },
            {

                dataField   : 'statusOfMember',
                text        : 'status Of Member',
                editable    : true,
                editor: {
                    type: Type.SELECT,
                    options: this.state.statusOfMemberData
                },
                headerStyle : () => {
                    return { width  : "14.28%" };
                },
                style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', }

            },
        ]

        const selectRow = {

            mode: 'radio',
            //clickToSelectAndEditCell: true,
            //clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
                this.state.employeeDetails.map(function(item,i){

                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                                           
                    }
                    
                })

            }

        };

        const rowEvents = {

            onClick: (e, row, rowIndex) => {

            }
            
        };
        
        return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            
                            <Card.Header>Sena Cases</Card.Header>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Card.Body>
                                
                                
                                <Card.Header className="mt-2" style={{marginBottom:"5px" }}>Employee Details</Card.Header>

                                <Form>
                                    
                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.employeeDetails }
                                        columns = { employeeDetailsColumn }
                                        // pagination={ paginationFactory({sizePerPageRenderer}) }
                                        striped
                                        hover
                                        condensed
                                        noDataIndication={ () => <div>No record found.</div> }
                                        cellEdit = { cellEditFactory({
                                            mode: 'click',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                this.GridDataModified(oldValue, newValue, row.id, column.dataField , row)
                                                }
                                            })
                                        }
                                        //defaultSorted={ defaultSorted }
                                        selectRow={selectRow}

                                    />
                                    <Form.Group as={Row} controlId="formPlaintextEmail" >
                                        <Col  sm={12}>
                                            <ButtonToolbar className="mt-5">
                                                <Button className="ml-auto" variant="success" onClick = { this.AddEmployee } style={{minWidth:'60px',marginTop:"-20px"}}>Add</Button>
                                                
                                            </ButtonToolbar>                                          
                                        </Col>                
                                    </Form.Group>

                                    
                                    {/* <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Client
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClientList}
                                                options={this.state.getClientList}
                                                selected={[this.state.selectedClientName]}
                                                //disabled={this.state.disableEmployee}
                                                //placeholder={this.state.clientPlaceHolder}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee
                                        </Form.Label>
                                        <Col sm="7">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.onChangeEmployeesList}
                                                options={this.state.getEmployeeList}
                                                placeholder={this.state.employeePlaceHolder}
                                            />
                                        </Col>

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee No.
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeNo" 
                                                value={this.state.selectedEmployeeNo}
                                                onChange={this.onChangeEmployeesNo} 
                                                autoComplete="off"
                                                placeholder="Autofill"
                                                disabled={this.state.disableEmployee}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text"
                                                name="position" 
                                                value={this.state.position}
                                                onChange={this.onChangePosition} 
                                                autoComplete="off"
                                                placeholder="Autofill"
                                                disabled={this.state.disableEmployee}
                                            />
                                        </Col>
                                        <Col sm="2"></Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Location
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text"
                                                name="selectedLocation"
                                                value={this.state.selectedEmployeeBranch}
                                                onChange={this.onChangeLocation} 
                                                autoComplete="off"
                                                placeholder="Auto Fill"
                                                disabled={this.state.disableEmployee}
                                            />
                                        </Col>
                                    </Form.Group> */}

                                    <Card.Header className="mt-3">Sena Conference Details</Card.Header>
                                    <Form.Group as={Row} className="mt-3">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Case Number
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text"
                                                name="caseNo"
                                                value={this.state.caseNo}
                                                onChange={this.onChangecaseNo} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Case Title
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text"
                                                name="caseTitle"
                                                value={this.state.caseTitle}
                                                onChange={this.onChangecaseTitle} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        RESPONDENT:
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control 
                                                type="text"
                                                name=""
                                                value={this.state.respondent}
                                                onChange={this.onChangerespondent} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mt-3">
                                        

                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Date of sena received
                                        </Form.Label>
                                        <Col  sm="2" style={{marginLeft : "-1px"}}>
                                            <DatePicker
                                                ref='dateApplied'
                                                selected={this.state.dateApplied}
                                                onChange={this.handleChangeDate}
                                                minDate={this.minDate}
                                                value={this.props.dateApplied}
                                                dateFormat={"MM/dd/yyyy"}
                                                /* placeholderText="Current " */
                                                className="form-control"
                                            />
                                        </Col>

                                        <Form.Label row sm="2" style={{fontWeight : "bold",}}>
                                            Reference No.
                                        </Form.Label>
                                        
                                        <Col sm="2" style={{marginLeft : "73px"}}>

                                            <Input

                                                labelKey='name'
                                                id="basic-example"
                                                className="form-control"
                                                onChange={this.handleOnChangereferenceNoSena}
                                                autoComplete="off"
                                                value={this.state.referenceNoSena}
                                                placeholder="AUTO GENERATE"
                                                disabled={true}
                                                
                                            />

                                        </Col>

                                    </Form.Group>

                                    <Form.Group as={Row}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            SENA CONFERENCE 1 DATE
                                        </Form.Label>
                                        <Col  sm="2" style={{marginLeft : "-1px"}}>
                                            <DatePicker
                                                ref='senaConference1Date'
                                                selected={this.state.senaConference1Date}
                                                onChange={this.handleChangesenaConference1Date}
                                                minDate={this.minDate}
                                                value={this.props.senaConference1Date}
                                                dateFormat={"MM/dd/yyyy"}
                                                /* placeholderText="Current " */
                                                className="form-control"
                                            />
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            VENUE
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="conference1Venue"
                                                value={this.state.conference1Venue}
                                                onChange={this.onChangeconference1Venue} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm="2">
                                            <label  style={{fontWeight : "bold"}}>MINUTES: (ATTACH FILE)</label>
                                        </Col> 
                                        <Col sm="2">
                                            <Input type="file" style={{backgroundColor:"#008BFF",width:"150px"}} className="file" placeholder="attached file" variant="primary" onChange={this.handleChangeminutes1file} value={this.state.minutes1file} accept="application/pdf" /> 
                                        </Col> 

                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Remarks
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control 
                                                type="text"
                                                name="conference1Remarks"
                                                value={this.state.conference1Remarks}
                                                onChange={this.onChangeconference1Remarks} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            SENA CONFERENCE 2 DATE
                                        </Form.Label>
                                        <Col  sm="2" style={{marginLeft : "-1px"}}>
                                            <DatePicker
                                                ref='senaConference2Date'
                                                selected={this.state.senaConference2Date}
                                                onChange={this.handleChangesenaConference2Date}
                                                minDate={this.minDate}
                                                value={this.props.senaConference2Date}
                                                dateFormat={"MM/dd/yyyy"}
                                              
                                                className="form-control"
                                            />
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            VENUE
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="conference2Venue"
                                                value={this.state.conference2Venue}
                                                onChange={this.onChangeconference2Venue} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm="2">
                                            <label  style={{fontWeight : "bold"}}>MINUTES: (ATTACH FILE)</label>
                                        </Col> 
                                        <Col sm="2">
                                            <Input type="file" style={{backgroundColor:"#008BFF",width:"150px"}} className="file" placeholder="attached file" variant="primary" onChange={this.handleChangeminutes2file} value={this.state.minutes2file} accept="application/pdf" /> 
                                        </Col> 

                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Remarks
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control 
                                                type="text"
                                                name="conference2Remarks"
                                                value={this.state.conference2Remarks}
                                                onChange={this.onChangeconference2Remarks} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            SENA CONFERENCE 3 DATE
                                        </Form.Label>
                                        <Col  sm="2" style={{marginLeft : "-1px"}}>
                                            <DatePicker
                                                ref='senaConference2Date'
                                                selected={this.state.senaConference3Date}
                                                onChange={this.handleChangesenaConference3Date}
                                                minDate={this.minDate}
                                                value={this.props.senaConference3Date}
                                                dateFormat={"MM/dd/yyyy"}
                                              
                                                className="form-control"
                                            />
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            VENUE
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="conference2Venue"
                                                value={this.state.conference3Venue}
                                                onChange={this.onChangeconference3Venue} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm="2">
                                            <label  style={{fontWeight : "bold"}}>MINUTES: (ATTACH FILE)</label>
                                        </Col> 
                                        <Col sm="2">
                                            <Input type="file" style={{backgroundColor:"#008BFF",width:"150px"}} className="file" placeholder="attached file" variant="primary" onChange={this.handleChangeminutes3file} value={this.state.minutes3file} accept="application/pdf" /> 
                                        </Col> 

                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Remarks
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control 
                                                type="text"
                                                name="conference3Remarks"
                                                value={this.state.conference3Remarks}
                                                onChange={this.onChangeconference3Remarks} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            SENA CONFERENCE 4 DATE
                                        </Form.Label>
                                        <Col  sm="2" style={{marginLeft : "-1px"}}>
                                            <DatePicker
                                                ref='senaConference4Date'
                                                selected={this.state.senaConference4Date}
                                                onChange={this.handleChangesenaConference4Date}
                                                minDate={this.minDate}
                                                value={this.props.senaConference4Date}
                                                dateFormat={"MM/dd/yyyy"}
                                              
                                                className="form-control"
                                            />
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            VENUE
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="conference2Venue"
                                                value={this.state.conference4Venue}
                                                onChange={this.onChangeconference4Venue} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm="2">
                                            <label  style={{fontWeight : "bold"}}>MINUTES: (ATTACH FILE)</label>
                                        </Col> 
                                        <Col sm="2">
                                            <Input type="file" style={{backgroundColor:"#008BFF",width:"150px"}} className="file" placeholder="attached file" variant="primary" onChange={this.handleChangeminutes4file} value={this.state.minutes4file} accept="application/pdf" /> 
                                        </Col> 

                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Remarks
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control 
                                                type="text"
                                                name="conference4Remarks"
                                                value={this.state.conference4Remarks}
                                                onChange={this.onChangeconference4Remarks} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>

                                    {/* <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold",}}>
                                            SENA CONFERENCE 2 DATE
                                        </Form.Label>

                                        <Col  sm="2" style={{marginLeft : "-89px"}}>
                                            <DatePicker
                                                ref='senaConference2Date'
                                                selected={this.state.senaConference2Date}
                                                onChange={this.handleChangesenaConference2Date}
                                                minDate={this.minDate}
                                                value={this.props.senaConference2Date}
                                                dateFormat={"MM/dd/yyyy"}
                                              
                                                className="form-control"
                                            />
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            VENUE
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text"
                                                name="conference2Venue"
                                                value={this.state.conference2Venue}
                                                onChange={this.onChangeconference2Venue} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group> */}

                                  {/*   <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Retail Group
                                        </Form.Label>
                                        <Col sm="9">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeRetailGroup}
                                                options={this.state.retailgroupData}
                                                placeholder="Select"
                                            />
                                        </Col>
                                      
                                    </Form.Group> */}
                                    
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        {/* <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            member Status
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="memberStatus" 
                                                value={this.state.memberStatus}
                                                onChange={this.onChangememberStatus} 
                                                autoComplete="off"
                                               
                                               
                                            />
                                        </Col>  */}
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            amount Of Negative Claims
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text"
                                                name="amountOfNegativeClaims"
                                                value={this.state.amountOfNegativeClaims}
                                                onChange={this.onChangeamountOfNegativeClaims} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            DOLE OFFICE
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="doleOffice" 
                                                value={this.state.doleOffice}
                                                onChange={this.onChangedoleOffice} 
                                                autoComplete="off"
                                               
                                               
                                            />
                                        </Col> 
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            DOLE OFFICER:
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text"
                                                name="doleOfficer"
                                                value={this.state.doleOfficer}
                                                onChange={this.onChangedoleOfficer} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                       
                                       {/*  <Col sm="1"></Col> */}
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Sena Cases Status
                                        </Form.Label>
                                        <Col sm="4">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeSenaStatus}
                                                options={this.state.senaStatus}
                                                selected={[this.state.selectedSenaStatus]}
                                                //disabled={this.state.disableEmployee}
                                                placeholder="Select"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                       
                                       {/*  <Col sm="1"></Col> */}
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            CASE LIABILITY:
                                        </Form.Label>
                                        <Col sm="4">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangecaseliability}
                                                options={this.state.caseLiabilityData}
                                                selected={[this.state.selectedcaseliability]}
                                                //disabled={this.state.disableEmployee}
                                                placeholder="Select"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            remarks
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="senaCasesRemarks" 
                                                value={this.state.senaCasesRemarks}
                                                onChange={this.onChangesenaCasesRemarks} 
                                                autoComplete="off"
                                               
                                               
                                            />
                                        </Col> 
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Complaint : 
                                        </Form.Label>
                                        <Col  sm={2}>
                                            <ButtonToolbar >
                                                <Button variant="primary" onClick = { this.onChangesHideCheckList } style={{minWidth:'60px'}}> Show/Hide Check List </Button>                                                
                                            </ButtonToolbar>                                          
                                        </Col>  
                                    </Form.Group>

                                    {   this.state.hideCheckList ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" style={{marginLeft:"50px"}}>
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                NON-PAYMENT OF SALARY/WAGES
                                            </Form.Label>
                                            <Form.Row >
                                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                                    <Form.Check
                                                    
                                                        inline 
                                                        type="checkbox" 
                                                        label="Yes" 
                                                        name="isCheckednonpaymentsalaryWageYes"
                                                        checked={this.state.isCheckednonpaymentsalaryWageYes}
                                                        onChange={this.onChangesCheckednonpaymentsalaryWageYes}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)", width:"10px"}}

                                                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    
                                                    <Form.Check
                                                        inline 
                                                        type="checkbox" 
                                                        label="No" 
                                                        name="isCheckednonpaymentsalaryWageNo"
                                                        checked={this.state.isCheckednonpaymentsalaryWageNo}
                                                        onChange={this.onChangesCheckednonpaymentsalaryWageNo}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)"}}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>:null
                                    }
                                    {   this.state.hideCheckList ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" style={{marginLeft:"50px"}}>
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            NON-PAYMENT FOR SEPARATION PAY
                                            </Form.Label>
                                            <Form.Row >
                                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                                    <Form.Check
                                                    
                                                        inline 
                                                        type="checkbox" 
                                                        label="Yes" 
                                                        name="isCheckednonpaymentseperationPayYes"
                                                        checked={this.state.isCheckednonpaymentseperationPayYes}
                                                        onChange={this.onChangesCheckednonpaymentseperationPayYes}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)", width:"10px"}}

                                                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    
                                                    <Form.Check
                                                        inline 
                                                        type="checkbox" 
                                                        label="No" 
                                                        name="isCheckednonpaymentseperationPayNo"
                                                        checked={this.state.isCheckednonpaymentseperationPayNo}
                                                        onChange={this.onChangesCheckednonpaymentseperationPayNo}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)"}}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>:null
                                    }
                                    {   this.state.hideCheckList ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" style={{marginLeft:"50px"}}>
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            PAYMENT OF DIVIDEND
                                            </Form.Label>
                                            <Form.Row >
                                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                                    <Form.Check
                                                    
                                                        inline 
                                                        type="checkbox" 
                                                        label="Yes" 
                                                        name="isCheckedpaymentOfDividentYes"
                                                        checked={this.state.isCheckedpaymentOfDividentYes}
                                                        onChange={this.onChangesCheckedpaymentOfDividentYes}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)", width:"10px"}}

                                                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    
                                                    <Form.Check
                                                        inline 
                                                        type="checkbox" 
                                                        label="No" 
                                                        name="isCheckedpaymentOfDividentNo"
                                                        checked={this.state.isCheckedpaymentOfDividentNo}
                                                        onChange={this.onChangesCheckedpaymentOfDividentNo}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)"}}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>:null
                                    }
                                    {   this.state.hideCheckList ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" style={{marginLeft:"50px"}}>
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            REFUND OF MEMBERSHIP FEE
                                            </Form.Label>
                                            <Form.Row >
                                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                                    <Form.Check
                                                    
                                                        inline 
                                                        type="checkbox" 
                                                        label="Yes" 
                                                        name="isCheckedrefundOfmembershipfeeYes"
                                                        checked={this.state.isCheckedrefundOfmembershipfeeYes}
                                                        onChange={this.onChangesCheckedrefundOfmembershipfeeYes}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)", width:"10px"}}

                                                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    
                                                    <Form.Check
                                                        inline 
                                                        type="checkbox" 
                                                        label="No" 
                                                        name="isCheckedrefundOfmembershipfeeNo"
                                                        checked={this.state.isCheckedrefundOfmembershipfeeNo}
                                                        onChange={this.onChangesCheckedrefundOfmembershipfeeNo}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)"}}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>:null
                                    }
                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        BACKWAGE
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedbackwageYes"
                                                    checked={this.state.isCheckedbackwageYes}
                                                    onChange={this.onChangesCheckedbackwageYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedbackwageNo"
                                                    checked={this.state.isCheckedbackwageNo}
                                                    onChange={this.onChangesCheckedbackwageNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }
                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        reinstatement
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedreinstatementYes"
                                                    checked={this.state.isCheckedreinstatementYes}
                                                    onChange={this.onChangesCheckedreinstatementYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedreinstatementNo"
                                                    checked={this.state.isCheckedreinstatementNo}
                                                    onChange={this.onChangesCheckedreinstatementNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }
                                    {   this.state.hideCheckList ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" style={{marginLeft:"50px"}}>
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            REFUND OF CAPITAL BUILD UP
                                            </Form.Label>
                                            <Form.Row >
                                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                                    <Form.Check
                                                    
                                                        inline 
                                                        type="checkbox" 
                                                        label="Yes" 
                                                        name="isCheckedrefundOfcapitalbuildupYes"
                                                        checked={this.state.isCheckedrefundOfcapitalbuildupYes}
                                                        onChange={this.onChangesCheckedrefundOfcapitalbuildupYes}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)", width:"10px"}}

                                                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    
                                                    <Form.Check
                                                        inline 
                                                        type="checkbox" 
                                                        label="No" 
                                                        name="isCheckedrefundOfcapitalbuildupNo"
                                                        checked={this.state.isCheckedrefundOfcapitalbuildupNo}
                                                        onChange={this.onChangesCheckedrefundOfcapitalbuildupNo}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)"}}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>:null
                                    }
                                    {   this.state.hideCheckList ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" style={{marginLeft:"50px"}}>
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            ILLEGAL SUSPENSION
                                            </Form.Label>
                                            <Form.Row >
                                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                                    <Form.Check
                                                    
                                                        inline 
                                                        type="checkbox" 
                                                        label="Yes" 
                                                        name="isCheckedilligalSuspensionYes"
                                                        checked={this.state.isCheckedilligalSuspensionYes}
                                                        onChange={this.onChangesCheckedilligalSuspensionYes}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)", width:"10px"}}

                                                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    
                                                    <Form.Check
                                                        inline 
                                                        type="checkbox" 
                                                        label="No" 
                                                        name="isCheckedilligalSuspensionNo"
                                                        checked={this.state.isCheckedilligalSuspensionNo}
                                                        onChange={this.onChangesCheckedilligalSuspensionNo}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)"}}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>:null
                                    }
                                    {   this.state.hideCheckList ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" style={{marginLeft:"50px"}}>
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            MORAL AND EXEMPLARY DAMAGES
                                            </Form.Label>
                                            <Form.Row >
                                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                                    <Form.Check
                                                    
                                                        inline 
                                                        type="checkbox" 
                                                        label="Yes" 
                                                        name="isCheckedmoralExemplaryDmgYes"
                                                        checked={this.state.isCheckedmoralExemplaryDmgYes}
                                                        onChange={this.onChangesCheckedmoralExemplaryDmgYes}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)", width:"10px"}}

                                                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    
                                                    <Form.Check
                                                        inline 
                                                        type="checkbox" 
                                                        label="No" 
                                                        name="isCheckedmoralExemplaryDmgNo"
                                                        checked={this.state.isCheckedmoralExemplaryDmgNo}
                                                        onChange={this.onChangesCheckedmoralExemplaryDmgNo}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)"}}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>:null
                                    }
                                    {   this.state.hideCheckList ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" style={{marginLeft:"50px"}}>
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            ATTYS FEES
                                            </Form.Label>
                                            <Form.Row >
                                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                                    <Form.Check
                                                    
                                                        inline 
                                                        type="checkbox" 
                                                        label="Yes" 
                                                        name="isCheckedattysFeeYes"
                                                        checked={this.state.isCheckedattysFeeYes}
                                                        onChange={this.onChangesCheckedattysFeeYes}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)", width:"10px"}}

                                                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    
                                                    <Form.Check
                                                        inline 
                                                        type="checkbox" 
                                                        label="No" 
                                                        name="isCheckedattysFeeNo"
                                                        checked={this.state.isCheckedattysFeeNo}
                                                        onChange={this.onChangesCheckedattysFeeNo}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)"}}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>:null
                                    }
                                    
                                    {   this.state.hideCheckList ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" style={{marginLeft:"50px"}}>
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            HAZARD PAY
                                            </Form.Label>
                                            <Form.Row >
                                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                                    <Form.Check
                                                    
                                                        inline 
                                                        type="checkbox" 
                                                        label="Yes" 
                                                        name="isCheckedhazardPayYes"
                                                        checked={this.state.isCheckedhazardPayYes}
                                                        onChange={this.onChangesCheckedhazardPayYes}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)", width:"10px"}}

                                                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    
                                                    <Form.Check
                                                        inline 
                                                        type="checkbox" 
                                                        label="No" 
                                                        name="isCheckedhazardPayNo"
                                                        checked={this.state.isCheckedhazardPayNo}
                                                        onChange={this.onChangesCheckedhazardPayNo}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)"}}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>:null
                                    }
                                    {   this.state.hideCheckList ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" style={{marginLeft:"50px"}}>
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            ILLEGAL STRIKE/LOCKOUT
                                            </Form.Label>
                                            <Form.Row >
                                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                                    <Form.Check
                                                    
                                                        inline 
                                                        type="checkbox" 
                                                        label="Yes" 
                                                        name="isCheckedilligalStrikeLockoutYes"
                                                        checked={this.state.isCheckedilligalStrikeLockoutYes}
                                                        onChange={this.onChangesCheckedilligalStrikeLockoutYes}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)", width:"10px"}}

                                                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    
                                                    <Form.Check
                                                        inline 
                                                        type="checkbox" 
                                                        label="No" 
                                                        name="isCheckedilligalStrikeLockoutNo"
                                                        checked={this.state.isCheckedilligalStrikeLockoutNo}
                                                        onChange={this.onChangesCheckedilligalStrikeLockoutNo}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)"}}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>:null
                                    }
                                    {   this.state.hideCheckList ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" style={{marginLeft:"50px"}}>
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            INCENTIVES
                                            </Form.Label>
                                            <Form.Row >
                                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                                    <Form.Check
                                                    
                                                        inline 
                                                        type="checkbox" 
                                                        label="Yes" 
                                                        name="isCheckedincentivesYes"
                                                        checked={this.state.isCheckedincentivesYes}
                                                        onChange={this.onChangesCheckedincentivesYes}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)", width:"10px"}}

                                                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    
                                                    <Form.Check
                                                        inline 
                                                        type="checkbox" 
                                                        label="No" 
                                                        name="isCheckedincentivesNo"
                                                        checked={this.state.isCheckedincentivesNo}
                                                        onChange={this.onChangesCheckedincentivesNo}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)"}}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>:null
                                    }
                                    {   this.state.hideCheckList ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" style={{marginLeft:"50px"}}>
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            ALLOWANCE
                                            </Form.Label>
                                            <Form.Row >
                                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                                    <Form.Check
                                                    
                                                        inline 
                                                        type="checkbox" 
                                                        label="Yes" 
                                                        name="isCheckedallowanceYes"
                                                        checked={this.state.isCheckedallowanceYes}
                                                        onChange={this.onChangesCheckedallowanceYes}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)", width:"10px"}}

                                                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    
                                                    <Form.Check
                                                        inline 
                                                        type="checkbox" 
                                                        label="No" 
                                                        name="isCheckedallowanceNo"
                                                        checked={this.state.isCheckedallowanceNo}
                                                        onChange={this.onChangesCheckedallowanceNo}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)"}}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>:null
                                    }
                                    {   this.state.hideCheckList ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" style={{marginLeft:"50px"}}>
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            GOVERNMENT MANDATORY CONTRIBUTION
                                            </Form.Label>
                                            <Form.Row >
                                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                                    <Form.Check
                                                    
                                                        inline 
                                                        type="checkbox" 
                                                        label="Yes" 
                                                        name="isCheckedgovernmentmanContriYes"
                                                        checked={this.state.isCheckedgovernmentmanContriYes}
                                                        onChange={this.onChangesCheckedgovernmentmanContriYes}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)", width:"10px"}}

                                                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    
                                                    <Form.Check
                                                        inline 
                                                        type="checkbox" 
                                                        label="No" 
                                                        name="isCheckedgovernmentmanContriNo"
                                                        checked={this.state.isCheckedgovernmentmanContriNo}
                                                        onChange={this.onChangesCheckedgovernmentmanContriNo}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)"}}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>:null
                                    }
                                    {   this.state.hideCheckList ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" style={{marginLeft:"50px"}}>
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            GOVERNMENT MANDATORY LOAN
                                            </Form.Label>
                                            <Form.Row >
                                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                                    <Form.Check
                                                    
                                                        inline 
                                                        type="checkbox" 
                                                        label="Yes" 
                                                        name="isCheckedgovernmentmanLoanYes"
                                                        checked={this.state.isCheckedgovernmentmanLoanYes}
                                                        onChange={this.onChangesCheckedgovernmentmanLoanYes}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)", width:"10px"}}

                                                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    
                                                    <Form.Check
                                                        inline 
                                                        type="checkbox" 
                                                        label="No" 
                                                        name="isCheckedgovernmentmanLoanNo"
                                                        checked={this.state.isCheckedgovernmentmanLoanNo}
                                                        onChange={this.onChangesCheckedgovernmentmanLoanNo}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)"}}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>:null
                                    }
                                    {   this.state.hideCheckList ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" style={{marginLeft:"50px"}}>
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            GOVERNMENT MANDATORY BENEFITS
                                            </Form.Label>
                                            <Form.Row >
                                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                                    <Form.Check
                                                    
                                                        inline 
                                                        type="checkbox" 
                                                        label="Yes" 
                                                        name="isCheckedgovernmentmanBenefitsYes"
                                                        checked={this.state.isCheckedgovernmentmanBenefitsYes}
                                                        onChange={this.onChangesCheckedgovernmentmanBenefitsYes}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)", width:"10px"}}

                                                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    
                                                    <Form.Check
                                                        inline 
                                                        type="checkbox" 
                                                        label="No" 
                                                        name="isCheckedgovernmentmanBenefitsNo"
                                                        checked={this.state.isCheckedgovernmentmanBenefitsNo}
                                                        onChange={this.onChangesCheckedgovernmentmanBenefitsNo}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)"}}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>:null
                                    }
                                    
                                    {   this.state.hideCheckList ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" style={{marginLeft:"50px"}}>
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            PAYMENT OF UNEXPIRED PORTION OF CONTRACT
                                            </Form.Label>
                                            <Form.Row >
                                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                                    <Form.Check
                                                    
                                                        inline 
                                                        type="checkbox" 
                                                        label="Yes" 
                                                        name="isCheckedunexpiredPortionOfContractYes"
                                                        checked={this.state.isCheckedunexpiredPortionOfContractYes}
                                                        onChange={this.onChangesCheckedunexpiredPortionOfContractYes}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)", width:"10px"}}

                                                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    
                                                    <Form.Check
                                                        inline 
                                                        type="checkbox" 
                                                        label="No" 
                                                        name="isCheckedunexpiredPortionOfContractNo"
                                                        checked={this.state.isCheckedunexpiredPortionOfContractNo}
                                                        onChange={this.onChangesCheckedunexpiredPortionOfContractNo}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)"}}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" style={{marginLeft:"50px"}}>
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                MINIMUM WAGE
                                            </Form.Label>
                                            <Form.Row >
                                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                                    <Form.Check
                                                    
                                                        inline 
                                                        type="checkbox" 
                                                        label="Yes" 
                                                        name="isCheckedminimunwageYes"
                                                        checked={this.state.isCheckedminimunwageYes}
                                                        onChange={this.onChangesCheckedminimunwageYes}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)", width:"10px"}}

                                                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    
                                                    <Form.Check
                                                        inline 
                                                        type="checkbox" 
                                                        label="No" 
                                                        name="isCheckedminimunwageNo"
                                                        checked={this.state.isCheckedminimunwageNo}
                                                        onChange={this.onChangesCheckedminimunwageNo}
                                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)"}}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        COLA
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedCOLAYes"
                                                    checked={this.state.isCheckedCOLAYes}
                                                    onChange={this.onChangesCheckedCOLAYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedCOLANo"
                                                    checked={this.state.isCheckedCOLANo}
                                                    onChange={this.onChangesCheckedCOLANo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        NIGHT SHIFT DIFFERENTIAL
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckednightShiftYes"
                                                    checked={this.state.isCheckednightShiftYes}
                                                    onChange={this.onChangesCheckednightShiftYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckednightShiftNo"
                                                    checked={this.state.isCheckednightShiftNo}
                                                    onChange={this.onChangesCheckednightShiftNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        NON-PAYMENT OF OVERTIME PAY
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedovertimePayYes"
                                                    checked={this.state.isCheckedovertimePayYes}
                                                    onChange={this.onChangesCheckedovertimePayYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedovertimePayNo"
                                                    checked={this.state.isCheckedovertimePayNo}
                                                    onChange={this.onChangesCheckedovertimePayNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        NON-PAYMENT OF HOLIDAY PAY
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedholidayPayYes"
                                                    checked={this.state.isCheckedholidayPayYes}
                                                    onChange={this.onChangesCheckedholidayPayYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedholidayPayNo"
                                                    checked={this.state.isCheckedholidayPayNo}
                                                    onChange={this.onChangesCheckedholidayPayNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            NON-PAYMENT PREMIUM PAY FOR HOLIDAY
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckednonpaymentPremholidayYes"
                                                    checked={this.state.isCheckednonpaymentPremholidayYes}
                                                    onChange={this.onChangesCheckedPremSpecialDayPayYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckednonpaymentPremholidayNo"
                                                    checked={this.state.isCheckednonpaymentPremholidayNo}
                                                    onChange={this.onChangesCheckedPremSpecialDayPayNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }
                                    
                                    {   this.state.hideCheckList ?

                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            PREMIUM PAY FOR REST DAY
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedPremRestDayPayYes"
                                                    checked={this.state.isCheckedPremRestDayPayYes}
                                                    onChange={this.onChangesCheckedPremRestDayPayYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedPremRestDayPayNo"
                                                    checked={this.state.isCheckedPremRestDayPayNo}
                                                    onChange={this.onChangesCheckedPremRestDayPayNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }
                                    
                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        NON-PAYMENT SERVICE CHARGES
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedServiceChargeYes"
                                                    checked={this.state.isCheckedServiceChargeYes}
                                                    onChange={this.onChangesCheckedServiceChargeYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedServiceChargeNo"
                                                    checked={this.state.isCheckedServiceChargeNo}
                                                    onChange={this.onChangesCheckedServiceChargeNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        NON-PAYMENT SERVICE INCENTIVE LEAVE
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedServiceIncentiveLeaveYes"
                                                    checked={this.state.isCheckedServiceIncentiveLeaveYes}
                                                    onChange={this.onChangesCheckedServiceIncentiveLeaveYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedServiceIncentiveLeaveNo"
                                                    checked={this.state.isCheckedServiceIncentiveLeaveNo}
                                                    onChange={this.onChangesCheckedServiceIncentiveLeaveNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        NON-PAYMENT FOR 13 MONTH PAY
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isChecked13monthPayYes"
                                                    checked={this.state.isChecked13monthPayYes}
                                                    onChange={this.onChangesChecked13monthPayYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isChecked13monthPayNo"
                                                    checked={this.state.isChecked13monthPayNo}
                                                    onChange={this.onChangesChecked13monthPayNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        MATERNITY LEAVE
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedmaternityLeaveYes"
                                                    checked={this.state.isCheckedmaternityLeaveYes}
                                                    onChange={this.onChangesCheckedmaternityLeaveYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedmaternityLeaveNo"
                                                    checked={this.state.isCheckedmaternityLeaveNo}
                                                    onChange={this.onChangesCheckedmaternityLeaveNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        PATERNITY LEAVE
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedpaternityLeaveYes"
                                                    checked={this.state.isCheckedpaternityLeaveYes}
                                                    onChange={this.onChangesCheckedpaternityLeaveYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedpaternityLeaveNo"
                                                    checked={this.state.isCheckedpaternityLeaveNo}
                                                    onChange={this.onChangesCheckedpaternityLeaveNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            PARENTAL LEAVE FOR SOLO PARENT
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedparentalForSoloYes"
                                                    checked={this.state.isCheckedparentalForSoloYes}
                                                    onChange={this.onChangesCheckedparentalForSoloYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedparentalForSoloNo"
                                                    checked={this.state.isCheckedparentalForSoloNo}
                                                    onChange={this.onChangesCheckedparentalForSoloNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            LEAVE FOR VICTIMS OF VAWC
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedVawcVictimsYes"
                                                    checked={this.state.isCheckedVawcVictimsYes}
                                                    onChange={this.onChangesCheckedVawcVictimsYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedVawcVictimsNo"
                                                    checked={this.state.isCheckedVawcVictimsNo}
                                                    onChange={this.onChangesCheckedVawcVictimsNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            ILLEGAL DEDUCTIONS
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedIlligalDeducYes"
                                                    checked={this.state.isCheckedIlligalDeducYes}
                                                    onChange={this.onChangesCheckedIlligalDeducYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedIlligalDeducNo"
                                                    checked={this.state.isCheckedIlligalDeducNo}
                                                    onChange={this.onChangesCheckedIlligalDeducNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        CLAIM FOR LAST SALARY/FINAL PAY
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedFinalPayYes"
                                                    checked={this.state.isCheckedFinalPayYes}
                                                    onChange={this.onChangesCheckedFinalPayYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedFinalPayNo"
                                                    checked={this.state.isCheckedFinalPayNo}
                                                    onChange={this.onChangesCheckedFinalPayNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            CLAIM FOR SEPARATION PAY/INDEMNITY PAY
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedIndemnityPayYes"
                                                    checked={this.state.isCheckedIndemnityPayYes}
                                                    onChange={this.onChangesCheckedIndemnityPayYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedIndemnityPayNo"
                                                    checked={this.state.isCheckedIndemnityPayNo}
                                                    onChange={this.onChangesCheckedIndemnityPayNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            CLAIM FOR RETIREMENT BENEFITS
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedRetirementBenefYes"
                                                    checked={this.state.isCheckedRetirementBenefYes}
                                                    onChange={this.onChangesCheckedRetirementBenefYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedRetirementBenefNo"
                                                    checked={this.state.isCheckedRetirementBenefNo}
                                                    onChange={this.onChangesCheckedRetirementBenefNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            CBA VIOLATIONS
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedCBAviolationYes"
                                                    checked={this.state.isCheckedCBAviolationYes}
                                                    onChange={this.onChangesCheckedCBAviolationYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedCBAviolationNo"
                                                    checked={this.state.isCheckedCBAviolationNo}
                                                    onChange={this.onChangesCheckedCBAviolationNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            DELAYED PAYMENT/TIME OF PAYMENT
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedDelayedPaymentYes"
                                                    checked={this.state.isCheckedDelayedPaymentYes}
                                                    onChange={this.onChangesCheckedDelayedPaymentYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedDelayedPaymentNo"
                                                    checked={this.state.isCheckedDelayedPaymentNo}
                                                    onChange={this.onChangesCheckedDelayedPaymentNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            NON-ISSUANCE OF CERTIFICATE OF EMPLOYMENT
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckednonIssuanceCOEYes"
                                                    checked={this.state.isCheckednonIssuanceCOEYes}
                                                    onChange={this.onChangesCheckednonIssuanceCOEYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckednonIssuanceCOENo"
                                                    checked={this.state.isCheckednonIssuanceCOENo}
                                                    onChange={this.onChangesCheckednonIssuanceCOENo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        ACTUAL ILLEGAL DISMISSAL
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedactualIlligalDismissalYes"
                                                    checked={this.state.isCheckedactualIlligalDismissalYes}
                                                    onChange={this.onChangesCheckedIlligalDismissalYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedactualIlligalDismissalNo"
                                                    checked={this.state.isCheckedactualIlligalDismissalNo}
                                                    onChange={this.onChangesCheckedIlligalDismissalNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        ACTUAL CONSTRUCTIVE DISMISSAL
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedconstractiveIlligalDismissalYes"
                                                    checked={this.state.isCheckedconstractiveIlligalDismissalYes}
                                                    onChange={this.onChangesCheckedConstructiveDismissalYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedconstractiveIlligalDismissalNo"
                                                    checked={this.state.isCheckedconstractiveIlligalDismissalNo}
                                                    onChange={this.onChangesCheckedConstructiveDismissalNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        OTHER PERSONNEL ACTIONS
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedOtherPersonalActionYes"
                                                    checked={this.state.isCheckedOtherPersonalActionYes}
                                                    onChange={this.onChangesCheckedOtherPersonalActionYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedOtherPersonalActionNo"
                                                    checked={this.state.isCheckedOtherPersonalActionNo}
                                                    onChange={this.onChangesCheckedOtherPersonalActionNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        OCCUPATIONAL SAFETY AND HEALTH VIOLATIONS
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedSafetyHealthVioYes"
                                                    checked={this.state.isCheckedSafetyHealthVioYes}
                                                    onChange={this.onChangesCheckedSafetyHealthVioYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedSafetyHealthVioNo"
                                                    checked={this.state.isCheckedSafetyHealthVioNo}
                                                    onChange={this.onChangesCheckedSafetyHealthVioNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            REGULARIZATION/CONTRACTUALIZATION
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedRegularContractualYes"
                                                    checked={this.state.isCheckedRegularContractualYes}
                                                    onChange={this.onChangesCheckedRegularContractualYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedRegularContractualNo"
                                                    checked={this.state.isCheckedRegularContractualNo}
                                                    onChange={this.onChangesCheckedRegularContractualNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            MALTREATMENT/HARRASSMENT
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedHarrassmentMaltreatYes"
                                                    checked={this.state.isCheckedHarrassmentMaltreatYes}
                                                    onChange={this.onChangesCheckedHarrassmentMaltreatYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedHarrassmentMaltreatNo"
                                                    checked={this.state.isCheckedHarrassmentMaltreatNo}
                                                    onChange={this.onChangesCheckedHarrassmentMaltreatNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    {   this.state.hideCheckList ?
                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"50px"}}>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        OTHERS
                                        </Form.Label>
                                        <Form.Row >
                                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                                <Form.Check
                                                
                                                    inline 
                                                    type="checkbox" 
                                                    label="Yes" 
                                                    name="isCheckedothersYes"
                                                    checked={this.state.isCheckedothersYes}
                                                    onChange={this.onChangesCheckedothersYes}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)", width:"10px"}}

                                                />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                
                                                <Form.Check
                                                    inline 
                                                    type="checkbox" 
                                                    label="No" 
                                                    name="isCheckedothersNo"
                                                    checked={this.state.isCheckedothersNo}
                                                    onChange={this.onChangesCheckedothersNo}
                                                    style={{fontWeight : "bold", transform: "scale(1.1)"}}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>:null
                                    }

                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                        <Col  sm={12}>
                                            <ButtonToolbar className="mt-5">
                                               
                                                <Button disabled={this.state.disabledSave} className="ml-auto" variant="success" onClick = { this.getNTErefNo } style={{minWidth:'60px'}}>Save</Button>&nbsp;&nbsp;&nbsp;
                                                <Button  href="/home" variant="danger" style={{minWidth:'60px'}} onClick={this.setBack}>Back</Button>
                                            </ButtonToolbar>                                          
                                        </Col>                
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                   { <InsertCOCReference 
                        show={this.state.modalCoCReferenceShow}
                        onHide={this.handleModalClose}
                    />}
            </div> 
        )
    }

}

export  default SenaCases
