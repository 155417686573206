import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Redirect, DatePicker,
    Accordion,Modal
}
from '../../noser-hris-component';
import {CSVLink, CSVDownload} from 'react-csv';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { GetRequest, PostRequest } from '../../noser.dataaccess'

import 'devextreme/dist/css/dx.light.css';
import DataGrid, {Column,Editing,Paging,Selection,Sorting,Export,Summary,TotalItem, MasterDetail} from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Template } from 'devextreme-react/core/template';
import NButton from './DeductionBreakdown2';
import DetailTemplate from './DetailTemplate.js'; // expand row
import SettlementModal from './SettlementModal';

let ref = React.createRef();
class BatchClearancePayroll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true,
            openModal       :   false,
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            disablebtn      :   true,
            disabled        :   true,
            clientId        :   "",
            payrollDataArry :   [],
            clientDDL       :   [],
            employeeDDL     :   [],
            requiredDDL     :   [{"value":"1","label":"MANDATORY"},{"value":"0","label":"OPTIONAL"}],
            gridDataExcel   :   [],
            isshowExpand    :   false,
            reasonDDL       : [],
            statusId            : "",
            fromDateCreated     : "",
            toDateCreated       : "",
            fromClearanceDate   : "",
            toClearanceDate     : "",
            fromDateReceived    : "",
            toDateReceived      : "",
            fromLastWorkingDate : "",
            toLastWorkingDate   : "",
            datenow             : new Date(),
            batchNumber         : "",
            filteredBatch       : [],
            selectedId          : [],
            _tmpData            : [],
            dataListArr         : [],
            collectionArray     : [],
            statusDDL           : [],

            chargesArray        : [],
            totalBalanceArr     : [],
            payrollRemarks      : "",
            tmpData             : [],
            dataAreList         : [],
            datagridTmp         : [],

            deductionMaintenance    : [],
            inclusionMaintenance    : [],
            subsidiaryData          : [],
            clearanceData           : [],
            selectedRowKeys     : [],
            inclusionAre        : [],
            paycardMaintenance         : [],
            popupVisible : false,
            dividendLst : [],
            CbuDividend : [],
            BatchLists : [],
            onLoadbatch : [],
            AreInlcusion : [],
            EmployeePayroll : [],
            sortEmp : [],
            incluAre : [],
            // showCheckbox        : "always"
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.dataGridRef = React.createRef();
        this.onExporting = this.onExporting.bind(this);
        // this.rederCellValue = this.rederCellValue.bind(this);
        // this.contentReady = this.contentReady.bind(this);
        // this.selectionChanged = this.selectionChanged.bind(this);
    }

    componentDidMount(){
        let sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.GetPayLastPayroll()
        // this.GetDataClearance()
    };
    GetPayLastPayroll =async()=>{
        this.setState({ isloading : true })
        let lastPayroll = []
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   "",
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   "",
            "StatusId" : "0"
        };
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetClearanceEmployeeLastPayrolls",  params)
        .then(res => {
            const data = res.data;
            // // // // // // // console.log("GetClearanceEmployeeLastPayrolls")
            // // // // // // // console.log(data)
            this.GetClient()
            this.setState({EmployeePayroll: data.lastPayrolls})
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        // return lastPayroll
    }
    GetClient = async() =>{
        // // // // // // // console.log('GetClient')
        this.setState({isloading:true})
        let param = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" ||
                         this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", param)
        .then(res => {
            let data = res.data;
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL : arr,})
            }
            else{
                this.setState({clientDDL : data.clients,})
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetDeductionMaintenance()
    };
    GetDeductionMaintenance = async() =>{
        // console.log("GetDeductionMaintenance")
        this.setState({isloading:true})
        let params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId
        };
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDeductions", params)
        .then(res => {
            this.setState({
                deductionMaintenance : res.data.deductions.filter(x=>x.isInclusion==="0").sort((a, b) => parseFloat(a.priority) > parseFloat(b.priority) ? 1 : -1),
                inclusionMaintenance : res.data.deductions.filter(x=>x.isInclusion==="1")
            })
            // console.log("res.data.deductions")
            // console.log(res.data.deductions.filter(x=>x.isInclusion==="1"))
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetDividendCbuStaging()
    };
    // GetInclusionMaintenance = async() =>{
    //     // console.log("GetInclusionMaintenance")
    //     let params = {
    //         "IpAddress":"0.0.0.0",
    //         "ClientId":this.state.userinfo.clientId,
    //         "UserId":this.state.userinfo.userId
    //     };
    //     axios
    //     .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetInclusions", params)
    //     .then(res => {
    //         this.setState({inclusionMaintenance : res.data.inclusions})
    //         // // // // // // console.log("inc")
    //         // // // // // // console.log(res.data.inclusions)
    //     })
    //     .catch(error=>{
    //         this.setState(  {
    //             isloading       :   false,
    //             alerttype       :   "Error!",
    //             isshow          :   true,
    //             color           :   "danger",
    //             message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
    //             fade            :   true
    //         })
    //     })
    //     this.GetDividendCbuStaging()
    // };
    GetDividendCbuStaging = async() =>{
        // console.log("GetDividend")
        // try{
        let _filterData = {"isDeleted" : "0", "isInclusion" : "1", "isSubmittedToPayroll" : "1", "isClearance" : "0"}
            let resp = await GetRequest({
                url : AppConfiguration.Setting().noserareapi + "action/get",
                params : {
                    "dbname"      :  AppConfiguration.Setting().noseraredb,
                    // "_collection" : "SubsidiaryLedgerStaging",
                    "_collection" : "InclusionLedger",
                    "filter_data" : _filterData
                }
            })
            if(resp.status==="1"){
                this.setState({ 
                    CbuDividend : resp.ResultList, dividendLst : resp.ResultList.filter(x=>x.isInclusion==="0"), 
                    incluAre : resp.ResultList.filter(x=>x.isInclusion==="1" && x.deduction!=="CBU" && x.deduction!=="DIVIDEND")
                })
            }
            console.log("incluAre")
            console.log(this.state.incluAre)
        // }
        // catch(error){
        //     this.setState(  {
        //         isloading       :   false,
        //         alerttype       :   "Error!",
        //         isshow          :   true,
        //         color           :   "danger",
        //         message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
        //         fade            :   true
        //     })
        // }
        this.GetPayCardTypes()
    };
    GetPayCardTypes = async()=> {
        // console.log('GetPayCardTypes')
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayCardTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({paycardMaintenance:data.payCardTypes.filter(x=>x.id!=="3"&&x.id!=="5"&&x.id!=="7"&&x.id!=="8")});
            // // console.log("data.payCardTypes")
            // // console.log(data.payCardTypes)
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetDataClearance()
    };
    GetDataClearance = async() => { // grid data
        // console.log("GetDataClearance")
        this.setState({isloading:true})
        let param = {
            "IpAddress"         :   "0.0.0.0",
            "ClientId"          :   "",
            "UserId"            :   "35954",
            "ProfileId"         :   ""
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {

            let data = res.data.clearance;
            // console.log("data")
            // console.log(data)
            let _sortData = data.filter( x => x.isClearanceStatus === "7" && x.isFinalized === "0")
            let filterData = _sortData.sort((a, b) => a.employeeName > b.employeeName ? -1 : 1)
            
            this.setState({ dataClearanceTmp : filterData, /* isloading: false */})
        })
        this.GetSubsidiaryLedgerStaging();
    };
    GetSubsidiaryLedgerStaging = async() =>{
        // console.log("getSubsidiaryLedgerStaging")
        this.setState({ isloading : true })

        let dataList = this.state.dataClearanceTmp.sort((a, b) => a.employeeName > b.employeeName ? 1 : -1)
        let empNo = []
        // dataList.forEach((itm,idx)=> { 
        //     empNo.push({employeeNo: itm.employeeNo})
        // })
        
        // // console.log("empNo")
        // // console.log(empNo)
        for(let i = 0; i < dataList.length; i++){
            // // console.log('dataList[i]')
            // // console.log(dataList[i])
            let resp = await GetRequest({
                url : AppConfiguration.Setting().noserareapi + "action/get",
                params : {
                    "dbname"      :  AppConfiguration.Setting().noseraredb,
                    "_collection" : "SubsidiaryLedgerStaging",
                    "filter_data" : {"isDeleted" : '0', "employeeNo" : dataList[i].employeeNo}
                }
            })
            // // console.log("resp")
            // // console.log(resp)
            if(resp.status==="1"){
                this.setState({ subsidiaryData: /* resp.ResultList.length===0?[]: */resp.ResultList.filter(x => x.debit !== "0.00")})
            }
            else{
                // console.log(' 7 error else ')
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   resp.message,
                    fade            :   true,
                    subsidiaryData  :   []
                })
            }
        }
        this.GetModifiedData()
    };  
    GetModifiedData = async() =>{
        
        // console.log("GetModifiedData")
        let _inc = this.state.incluAre
        let LastPayroll = this.state.EmployeePayroll
        let cbudividn = this.state.CbuDividend

        
        let dataInc = []
        let dataSL = []
        let modifyData = []

        let dataList = this.state.dataClearanceTmp.sort((a, b) => a.employeeName > b.employeeName ? 1 : -1)
        dataList.map(function(itm,idx) { 
            let _tmpData = []
            let tmpDvdnd = []
            if(itm.isChild==="YES"){
                console.log("child")
                _tmpData = _inc.filter( x => x.employeeNo===itm.employeeNo && x.isFloating==="1")
                tmpDvdnd = cbudividn.filter( x => x.employeeNo===itm.employeeNo && x.isFloating==="1")
                // console.log("_tmpData")
                // console.log(_inc.filter( x => x.employeeNo===itm.employeeNo && x.isFloating==="1"))
            }
            else{
                console.log("parent")
                _tmpData = _inc.filter( x => x.employeeNo===itm.employeeNo && x.isFloating==="0")
                tmpDvdnd = cbudividn.filter( x => x.employeeNo===itm.employeeNo && x.isFloating==="0")
            }
            dataInc = _tmpData
            // console.log("_tmpData")
            // console.log(_tmpData)
            // let inclusion = _tmpData.filter( x => x.deductionId === "3" || x.deductionId === "5" || x.deductionId === "9" || x.deductionId === "51" || x.deductionId === "55")
            let _totInc = _tmpData.length>0?_tmpData.reduce((total, currentValue) => total + parseFloat(String(currentValue.balanceAmount).replace(',','')),0):0
            // console.log("_totInc")
            // console.log(_totInc)

            let _cbu = tmpDvdnd.filter(x=> x.employeeNo===itm.employeeNo && x.isInclusion === "1" && x.deductionId ==="5").length>0 ?
                parseFloat(String(tmpDvdnd.find(x=> x.employeeNo===itm.employeeNo && x.isInclusion === "1" && x.deductionId ==="5").balanceAmount).replace(',','')) : 0
            let _dividend = tmpDvdnd.filter(x=> x.employeeNo===itm.employeeNo && x.isInclusion === "1" && x.deduction === "DIVIDEND").length>0 ?
                parseFloat(String(tmpDvdnd.find(x=> x.employeeNo===itm.employeeNo && x.isInclusion === "1").balanceAmount).replace(',','')) : 0

            // COMPUTATIONS

            // console.log("itm.totalBalance")
            // console.log(itm.totalBalance)
            let _lastSalary = LastPayroll.filter(x=> x.profileId===itm.profileId).length>0 ?
                parseFloat(String(LastPayroll.filter(x=> x.profileId===itm.profileId).reduce((total, currentValue) => total + parseFloat(String(currentValue.lastPayroll).replace(',','')),0))) : 0.00
            let _adjustment = LastPayroll.filter(x=> x.profileId===itm.profileId).length>0 ?
                parseFloat(String(LastPayroll.filter(x=> x.profileId===itm.profileId).reduce((total, currentValue) => total + parseFloat(String(currentValue.adjustmentPayroll).replace(',','')),0))) : 0.00
            let _otherPayroll = LastPayroll.filter(x=> x.profileId===itm.profileId).length>0 ?
                parseFloat(String(LastPayroll.filter(x=> x.profileId===itm.profileId).reduce((total, currentValue) => total + parseFloat(String(currentValue.otherPayroll).replace(',','')),0))) : 0.00
            let _thirteenthMonth = LastPayroll.filter(x=> x.profileId===itm.profileId).length>0 ?
                parseFloat(String(LastPayroll.filter(x=> x.profileId===itm.profileId).reduce((total, currentValue) => total + parseFloat(String(currentValue.thirteenthMonth).replace(',','')),0))) : 0.00
            let _sil = LastPayroll.filter(x=> x.profileId===itm.profileId).length>0 ?
                parseFloat(String(LastPayroll.filter(x=> x.profileId===itm.profileId).reduce((total, currentValue) => total + parseFloat(String(currentValue.sil).replace(',','')),0))) : 0.00
            let _separation = LastPayroll.filter(x=> x.profileId===itm.profileId).length>0 ?
                parseFloat(String(LastPayroll.filter(x=> x.profileId===itm.profileId).reduce((total, currentValue) => total + parseFloat(String(currentValue.separationPay).replace(',','')),0))) : 0.00 

            let sttlmntFee = itm.settlementFee === "" || typeof itm.settlementFee ==="undefined" ? 0: itm.settlementFee
            
            let _grossPay = parseFloat(String(_lastSalary)) + parseFloat(String(_adjustment)) + parseFloat(String(_otherPayroll)) + parseFloat(String(_thirteenthMonth)) + parseFloat(String(_sil)) + parseFloat(String(_separation)) + parseFloat(String(_cbu))+ parseFloat(String(_dividend))+ parseFloat(String(sttlmntFee)) 
            let totalGross = parseFloat(_grossPay) + parseFloat(_totInc) 
            
            
            let netPayTmp = parseFloat(totalGross) - parseFloat(String(itm.totalBalance).replace(',',''))
            let _netPay = parseFloat(String(netPayTmp).replace(',','')).toFixed(2)
            let hc = parseFloat(idx + 1)

            modifyData.push({
                hc : hc,
                batchNumber : itm.batchNumber,
                clientName : itm.clientName,
                employeeNo : itm.employeeNo,
                employeeName : itm.employeeName,
                reasonForLeaving : itm.reasonForLeaving,
                dateStart : itm.dateStart,
                dateEnd : itm.lastWorkingDateMember,
                paycardNumber : itm.cashCardNumberMember,
                cbu : parseFloat(_cbu),
                dividend : parseFloat(_dividend),
                lastSalary : _lastSalary === "" ? 0 : _lastSalary,
                adjustment : typeof _adjustment === "undefined" || _adjustment === "" ? 0 : _adjustment,
                otherPayroll : typeof _otherPayroll === "undefined" || _otherPayroll === "" ? 0 : _otherPayroll,
                thirteenthMonth : _thirteenthMonth === "" ? 0 : _thirteenthMonth,
                sil : _sil === "" ? 0 : _sil,
                separationPay :_separation === "" || typeof _separation === "undefined" ? 0 : _separation,
                settlementFee : typeof sttlmntFee === "undefined" ? 0 : parseFloat(sttlmntFee),
                incentives : itm.incentives,
                grossPay : _grossPay,
                inclusions : totalGross === "" ? 0 : parseFloat(String(totalGross).replace(',','')),
                totalInclusion : parseFloat(String(_totInc).replace(',','')),
                totalBalance : parseFloat(String(itm.totalBalance).replace(',','')),
                netPay : _netPay === "" ? 0 : parseFloat(String(_netPay).replace(',','')),
                noAtm : itm.isCash === "1" || itm.isCheckMember === "1" || itm.PaycardTypeIdMember === "8" ? parseFloat(String(_netPay).replace(',','')) : 0,

                batchPayroll : itm.batchPayroll,
                typeOfCashCardMember : itm.typeOfCashCardMember,
                areRemarks : itm.areRemarks,
                clearanceDateMember : itm.clearanceDateMember,
                clearanceDeductions : itm.clearanceDeductions.sort((a, b) => parseFloat(a.priority) > parseFloat(b.priority) ? 1 : -1),
                clearanceDetails : itm.clearanceDetails,
                clearanceStatus : itm.clearanceStatus,
                clearedSubmit : itm.clearedSubmit,
                clientId : itm.clientId,
                closingRemarks : itm.closingRemarks,
                createdBy : itm.createdBy,
                createdByName : itm.createdByName,
                createdDate : itm.createdDate,
                cycleDays : itm.cycleDays,
                dateCreated : itm.dateCreated,
                dateForwardedToAmt : itm.dateForwardedToAmt,
                dateOfBirth : itm.dateOfBirth,
                dateReceivedByAdmin : itm.dateReceivedByAdmin,
                dateReceivedFromAmt : itm.dateReceivedFromAmt,
                dateReturnedByARE : itm.dateReturnedByARE,
                dateReturnedByClient : itm.dateReturnedByClient,
                dateReturnedByFinance : itm.dateReturnedByFinance,
                dateReturnedByLRD : itm.dateReturnedByLRD,
                dateReturnedByPayroll : itm.dateReturnedByPayroll,
                dateReturnedByRMT : itm.dateReturnedByRMT,
                dateReturnedByServices : itm.dateReturnedByServices,
                dateSettle : itm.dateSettle,
                dateSubmitted : itm.dateSubmitted,
                dateSubmittedByLead : itm.dateSubmittedByLead,
                dateSubmittedByStaff : itm.dateSubmittedByStaff,
                dateUpdatedByARE : itm.dateUpdatedByARE,
                dateUpdatedByClient : itm.dateUpdatedByClient,
                dateUpdatedByFinance : itm.dateUpdatedByFinance,
                dateUpdatedByLRD : itm.dateUpdatedByLRD,
                dateUpdatedByRMT : itm.dateUpdatedByRMT,
                dateUpdatedByServices : itm.dateUpdatedByServices,
                employeeId : itm.employeeId,
                finalized : itm.finalized,
                financeRemarks : itm.financeRemarks,
                firstName : itm.firstName,
                generation : itm.generation,
                // grossPay : itm.grossPay,
                hdmfNumber : itm.hdmfNumber,
                id : itm.id,
                isCOERequestFormMember : itm.isCOERequestFormMember,
                isCash : itm.isCash,
                isCashCardMember : itm.isCashCardMember,
                isCheckMember : itm.isCheckMember,
                isCheckedByRmt : itm.isCheckedByRmt,
                isClearanceStatus : itm.isClearanceStatus,
                isClearedClient : itm.isClearedClient,
                isClearedRmt : itm.isClearedRmt,
                isClearedSubmit : itm.isClearedSubmit,
                isDeleted : itm.isDeleted,
                isEligible : itm.isEligible,
                isEligibleClient : itm.isEligibleClient,
                isFinalized : itm.isFinalized,
                isForwardToARERmt : itm.isForwardToARERmt,
                isGenerated : itm.isGenerated,
                isHDMFNumberMember : itm.isHDMFNumberMember,
                isMlhuillierMember : itm.isMlhuillierMember,
                isModified : itm.isModified,
                isModifiedByARE : itm.isModifiedByARE,
                isNonEligibleClient : itm.isNonEligibleClient,
                isNotEligible : itm.isNotEligible,
                isPHICNumberMember : itm.isPHICNumberMember,
                isPalawanMember : itm.isPalawanMember,
                isResignationLetterMember : itm.isResignationLetterMember,
                isRetrunedServicesRmt : itm.isRetrunedServicesRmt,
                isReturnedRmt : itm.isReturnedRmt,
                isSSSNumberMember : itm.isSSSNumberMember,
                isSettleWithDole : itm.isSettleWithDole,
                isTINNumberMember : itm.isTINNumberMember,
                isTurnoverChecklistMember : itm.isTurnoverChecklistMember,
                isTypeOfCashCardMember : itm.isTypeOfCashCardMember,
                isValidIdMember : itm.isValidIdMember,
                lastName : itm.lastName,
                locationId : itm.locationId,
                locationName : itm.locationName,
                middleName : itm.middleName,
                modifiedBy : itm.modifiedBy,
                modifiedByName : itm.modifiedByName,
                modifiedDate : itm.modifiedDate,
                // netPay : itm.netPay,
                notesMember : itm.notesMember,
                paramountId : itm.paramountId,
                payPeriodId : itm.payPeriodId,
                paycardTypeIdMember : itm.paycardTypeIdMember,
                payrollPeriods : itm.payrollPeriods,
                payrollRemarks : itm.payrollRemarks,
                periodCovered : itm.periodCovered,
                phicNumber : itm.phicNumber,
                positionId : itm.positionId,
                positionName : itm.positionName,
                profileId : itm.profileId,
                reasonForLeaving : itm.reasonForLeaving,
                reasonForLeavingId : itm.reasonForLeavingId,
                remarksClient : itm.remarksClient,
                remarksLrd : itm.remarksLrd,
                remarksMember : itm.remarksMember,
                remarksRmt : itm.remarksRmt,
                resignationDateMember : itm.resignationDateMember,
                returnedByARE : itm.returnedByARE,
                returnedByClient : itm.returnedByClient,
                returnedByFinance : itm.returnedByFinance,
                returnedByLRD : itm.returnedByLRD,
                returnedByRMT : itm.returnedByRMT,
                returnedByServices : itm.returnedByServices,
                reviewStatus : itm.reviewStatus,
                reviewStatusResults : itm.reviewStatusResults,
                rowIndex : itm.rowIndex,
                servicesAttachments : itm.servicesAttachments,
                servicesRemarks : itm.servicesRemarks,
                // sil : itm.sil,
                sssNumber : itm.sssNumber,
                submissionDateMember : itm.submissionDateMember,
                submitted : itm.submitted,
                submittedByLead : itm.submittedByLead,
                submittedByStaff : itm.submittedByStaff,
                suffixName : itm.suffixName,
                timeStamp : itm.timeStamp,
                tinNumber : itm.tinNumber,
                // totalBalance : itm.totalBalance,
                updatedByARE : itm.updatedByARE,
                updatedByClient : itm.updatedByClient,
                updatedByFinance : itm.updatedByFinance,
                updatedByLRD : itm.updatedByLRD,
                updatedByRMT : itm.updatedByRMT,
                updatedByServices : itm.updatedByServices,
                validateByRmt : itm.validateByRmt,
                batchPayroll : itm.batchPayroll,
                dateHired : itm.dateHired,
                contractDateStart : itm.contractDateStart,
                contractDateEnd : itm.contractDateEnd,
                isParent : itm.isParent,
                isChild : itm.isChild,
                parentId : itm.parentId,
                
                dateSubmittedToPayroll : itm.dateSubmittedToPayroll,
                isAre : itm.isAre,
                isPayroll : itm.isPayroll,
                financePayoutDate : itm.financePayoutDate,
                isUpdatedByARE : itm.isUpdatedByARE,
                lastModifiedBy : itm.lastModifiedBy,
            })
        })
        //ATTACHED DEDUCTIONS & INCLUSION / DYNAMIC HEADER
        

        let uniqpaycards = [...new Map(modifyData.map((item) => [JSON.stringify(item), item])).values(),];
        // console.log("modifyData")
        // console.log(modifyData)
        // // console.log("this.state.paycardMaintenance")
        // // console.log(this.state.paycardMaintenance)
        let filterInclu = dataInc.filter(f => modifyData.some(item => f.employeeId === item.employeeId))
        let uniqinclusion = [...new Map(this.state.incluAre.map((item) => [JSON.stringify(item), item])).values(),];
        let empdeduction = []
        modifyData.forEach(itm=>{
            itm.clearanceDeductions.forEach(ded =>{
                empdeduction.push({
                    deductionId : ded.deductionId,
                    deduction : ded.deduction,
                    employeeId : ded.employeeId
                })
            })
        })
        let uniqdeduction = [...new Map(empdeduction.map((item) => [JSON.stringify(item), item])).values(),];
        
        modifyData.forEach(itm =>{
            let _tmpData= []
            if(itm.isChild==="YES"){
                console.log("child")
                _tmpData = this.state.incluAre.filter( x => x.employeeNo===itm.employeeNo && x.isFloating==="1")
            }
            else{
                console.log("parent")
                _tmpData = this.state.incluAre.filter( x => x.employeeNo===itm.employeeNo && x.isFloating==="0")
            }
            this.state.deductionMaintenance.sort((a, b) => parseFloat(a.priority) > parseFloat(b.priority) ? 1 : -1).filter(f => uniqdeduction.some(item => item.deductionId === f.id)).forEach(ded=>{
                itm[ded.name]   = itm.clearanceDeductions.filter(x=>x.employeeNo===itm.employeeNo && x.deductionId===ded.id && x.chargesAmount!=="0.00").length>0 ? 
                                    parseFloat(String(itm.clearanceDeductions.filter(x=>x.employeeNo===itm.employeeNo && x.deductionId===ded.id && x.chargesAmount!=="0.00").reduce((total, currentValue) => total + parseFloat(String(currentValue.balance).replace(',','')),0))) : 0.00
            })


            this.state.inclusionMaintenance.filter(f => uniqinclusion.some(item => f.id === item.deductionId)).forEach(inc=>{
                itm[inc.name]   = _tmpData.filter(x=>x.employeeNo===itm.employeeNo && x.deductionId===inc.id).length>0 ? 
                                    parseFloat(String(_tmpData.find(x=>x.employeeNo===itm.employeeNo && x.deductionId===inc.id).balanceAmount).replace(',','')) : 0.00
            })


            this.state.paycardMaintenance.filter(f => uniqpaycards.some(item => item.typeOfCashCardMember === f.name)).forEach(pc => {
                itm[pc.name] = itm.typeOfCashCardMember === pc.name ? parseFloat(String(itm.netPay).replace(',','')) : 0
            })

        })
        let _filteredBatch = dataList.filter(x=>x.batchNumber!== "")
        let _BatchNumberDDL = _filteredBatch.filter((thing, index, self) =>
            index === self.findIndex((t) => (
                t.batchPayroll === thing.batchPayroll
            ))
        )

        let newData = modifyData
        let trimFieldName = []
        if (modifyData.length > 0){
            
            // GRAND TOTAL

            this.state.dataClearanceTmp.forEach(data=>{
                // // // console.log(this.state.subsidiaryData.filter(x=> x.employeeId===data.employeeId))
                this.state.deductionMaintenance.sort((a, b) => parseFloat(a.priority) > parseFloat(b.priority) ? 1 : -1).filter(f => uniqdeduction.some(item => item.deductionId === f.id)).forEach(mnt=>{
                    data[mnt.name] = data.clearanceDeductions.filter(x=> x.employeeId===data.employeeId && x.deductionId===mnt.id).length>0 ? 
                                        data.clearanceDeductions.find(x=> x.employeeId===data.employeeId && x.deductionId===mnt.id).balance : 0
                })
                this.state.inclusionMaintenance.filter(f => uniqinclusion.some(item => f.id === item.inclusionId)).forEach(mnt=>{
                    data[mnt.name] = this.state.incluAre.filter(x=> x.employeeId===data.employeeId && x.deductionId===mnt.id).length>0 ? 
                                        this.state.incluAre.find(x=> x.employeeId===data.employeeId && x.deductionId===mnt.id).amount : 0
                })
                this.state.paycardMaintenance.filter(f => uniqpaycards.some(item => item.typeOfCashCardMember === f.name)).forEach(mnt => {
                    data[mnt.name] = mnt.typeOfCashCardMember === data.name ? modifyData.reduce((total, currentValue) => total + parseFloat(String(currentValue.netPay).replace(',','')),0) : 0
                })
            })
            const res = modifyData.reduce((result, item) => {
                const keys = Object.keys(item);
                keys.forEach(key => {
                    if (this.state.deductionMaintenance.filter(x=>x.name===key).length>0){
                        result[key] = result[key] ? result[key] + item[key] : item[key];
                    }
                    if (this.state.inclusionMaintenance.filter(x=>x.name===key).length>0){
                        result[key] = result[key] ? result[key] + item[key] : item[key];
                    }
                    if (this.state.paycardMaintenance.filter(x=>x.name===key).length>0){
                        result[key] = result[key] ? result[key] + item[key] : item[key];
                    }
                });
                return result;
            }, 
            { 
                "hc" : "",
                "batchNumber" : "",
                "employeeNo" : "",
                "reasonForLeaving" : "GRAND TOTAL : ",
                "clientName" : "",
                "employeeName" : "",
                "dateEnd" : "",
                "paycardNumber" : "",
                "cbu" : modifyData.reduce((total, currentValue) => total + parseFloat(String(currentValue.cbu).replace(',','')),0),
                "dividend" : modifyData.reduce((total, currentValue) => total + parseFloat(String(currentValue.dividend).replace(',','')),0),
                "lastSalary" : modifyData.reduce((total, currentValue) => total + parseFloat(String(currentValue.lastSalary).replace(',','')),0),
                "adjustment" : modifyData.reduce((total, currentValue) => total + parseFloat(String(currentValue.adjustment).replace(',','')),0),
                "otherPayroll" : modifyData.reduce((total, currentValue) => total + parseFloat(String(currentValue.otherPayroll).replace(',','')),0),
                "thirteenthMonth" : modifyData.reduce((total, currentValue) => total + parseFloat(String(currentValue.thirteenthMonth).replace(',','')),0),
                "sil" : modifyData.reduce((total, currentValue) => total + parseFloat(String(currentValue.sil).replace(',','')),0),
                "separationPay" : modifyData.reduce((total, currentValue) => total + parseFloat(String(currentValue.separationPay).replace(',','')),0),
                "settlementFee" : isNaN(modifyData.reduce((total, currentValue) => total + parseFloat(String(currentValue.settlementFee).replace(',','')),0)) ? "" : modifyData.reduce((total, currentValue) => total + parseFloat(String(currentValue.settlementFee).replace(',','')),0),
                "inclusions" : modifyData.reduce((total, currentValue) => total + parseFloat(String(currentValue.inclusions).replace(',','')),0),
                "grossPay" : modifyData.reduce((total, currentValue) => total + parseFloat(String(currentValue.grossPay).replace(',','')),0),
                "totalInclusion" : modifyData.reduce((total, currentValue) => total + parseFloat(String(currentValue.totalInclusion).replace(',','')),0),
                "totalBalance" : modifyData.reduce((total, currentValue) => total + parseFloat(String(currentValue.totalBalance).replace(',','')),0),
                "netPay" : modifyData.reduce((total, currentValue) => total + parseFloat(String(currentValue.netPay).replace(',','')),0),
                "noAtm" : modifyData.reduce((total, currentValue) => total + parseFloat(String(currentValue.noAtm).replace(',','')),0).toFixed(2),
            });
            modifyData.push(res);        
    
            // console.log("modifyData")
            // console.log(modifyData.reduce((total, currentValue) => total + parseFloat(String(currentValue.netPay).replace(',','')),0))
            
            this.setState({
                filteredBatch    : _BatchNumberDDL.sort((a, b) => a.batchPayroll > b.batchPayroll ? -1 : 1),
                sortEmp : dataList.sort((a, b) => a.employeeName < b.employeeName ? -1 : 1),
                clearanceData : modifyData,//.concat(this.state.dataClearanceTmp),
                isloading   : false,
            });
        }
        this.GetDisengagementMode()
    }
    GetEmployees = () => {
        this.setState({isloading:true})
        let param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeName"  :   "",
            "EmployeeNo"    :   "",
            "ClientName"    :   ""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees",  param)
        .then(res => {
            let data = res.data
            this.setState({
                employeeDDL        :   data.employees,
                isloading           :   false,
            })
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })

    };
    GetDisengagementMode = async() => {
        // console.log("GetDisengagementMode")
        this.setState({isloading:true})
        let param = {
            "IpAddress"         :   "0.0.0.0",
            "UserId"            :   this.state.userinfo.userId,
            "ClientId"          :   this.state.userinfo.clientId,
            "DisengagementId"   :   "",
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDisengagementMode",  param)
        .then(res => {
            let data = res.data.disengagementMode;
            this.setState({
                reasonDDL: data,
                isloading: false
            });

            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };




    /* Romel */
    FormatCommas(x) {
        x = x.toString();
        var pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(x))
            x = x.replace(pattern, "$1,$2");
        return x;
    }


    handleClickSearch = async() => {
        let arrLst = []
        let arrid = []
        if (this.state.batchNumber !== ""){
            // // // // // // // console.log("if")
            let dataTmp = this.state.clearanceData.filter( x => x.batchNumber === this.state.batchNumber)
            // // // // // // // console.log("dataTmp")
            // // // // // // // console.log(dataTmp)
            let modifyData = []
            let filterData = dataTmp.filter( x => x.isClearanceStatus === "7" && x.isFinalized === "0")
            // // // // // // // console.log("filterData")
            // // // // // // // console.log(filterData)
            let _inc = this.state.AreInlcusion
            // let dvdndArry = this.state.dividendLst
            let LastPayroll = this.state.EmployeePayroll
            let cbudividn = this.state.CbuDividend

            // Modify for cbu and dividend computations

            filterData.map(function(itm) {
                let _tmpData = _inc.filter( x => x.employeeNo===itm.employeeNo)
                // let inclusion = _tmpData.filter( x => x.deductionId === "3" || x.deductionId === "5" || x.deductionId === "9" || x.deductionId === "51" || x.deductionId === "55")
                let _totInc = _tmpData.length>0?_tmpData.reduce((total, currentValue) => total + parseFloat(String(currentValue.balanceAmount).replace(',','')),0):0
                // // // // // // // // console.log("_totInc")
                // // // // // // // // console.log(_totInc)
                let _cbu = cbudividn.filter(x=> x.employeeNo===itm.employeeNo && x.isInclusion === "1" && x.deductionId ==="5").length>0 ?
                    parseFloat(String(cbudividn.find(x=> x.employeeNo===itm.employeeNo && x.isInclusion === "1" && x.deductionId ==="5").balanceAmount).replace(',','')) : 0
                let _dividend = itm.dividend!=="" ? itm.dividend : cbudividn.filter(x=> x.employeeNo===itm.employeeNo && x.isInclusion === "1").length>0 ?
                    parseFloat(String(cbudividn.find(x=> x.employeeNo===itm.employeeNo && x.isInclusion === "1").balanceAmount).replace(',','')) : 0
                

                // COMPUTATIONS

                // // // // // // // // console.log("LastPayroll")
                // // // // // // // // console.log(LastPayroll)
                let _lastSalary = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                    parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).lastPayroll).replace(',','')) : 0
                let _adjustment = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                    parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).adjustmentPayroll).replace(',','')) : 0
                let _otherPayroll = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                    parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).otherPayroll).replace(',','')) : 0
                let _thirteenthMonth = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                    parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).thirteenthMonth).replace(',','')) : 0
                let _sil = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                    parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).sil).replace(',','')) : 0
                let _separation = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                    parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).separationPay).replace(',','')) : 0

                // // // // // // // // console.log("_separation")
                // // // // // // // // console.log(_separation)
                
                let _grossPay = parseFloat(String(_lastSalary)) + parseFloat(String(_adjustment)) + parseFloat(String(_otherPayroll)) + parseFloat(String(_thirteenthMonth)) + parseFloat(String(_sil)) + parseFloat(String(_separation)) + parseFloat(String(_cbu))+ parseFloat(String(_dividend))
                let totalGross = parseFloat(_grossPay) + parseFloat(_totInc) 
                // // // // // // // // // console.log('totalGross')
                // // // // // // // // // console.log(totalGross)
                // // // // // // // // // console.log('itm.totalBalance')
                // // // // // // // // // console.log(itm.totalBalance)
                
                let netPayTmp = parseFloat(totalGross) - parseFloat(String(itm.totalBalance).replace(',',''))
                let _netPay = parseFloat(String(netPayTmp).replace(',','')).toFixed(2)
                


                modifyData.push({
                    hc : itm.hc,
                    batchNumber : itm.batchNumber,
                    clientName : itm.clientName,
                    employeeNo : itm.employeeNo,
                    employeeName : itm.employeeName,
                    reasonForLeaving : itm.reasonForLeaving,
                    dateStart : itm.dateStart,
                    dateEnd : itm.lastWorkingDateMember,
                    paycardNumber : itm.cashCardNumberMember,
                    cbu : _cbu,
                    dividend : _dividend,
                    lastSalary : _lastSalary === "" ? 0 : _lastSalary,
                    adjustment : typeof _adjustment === "undefined" || _adjustment === "" ? 0 : _adjustment,
                    otherPayroll : typeof _otherPayroll === "undefined" || _otherPayroll === "" ? 0 : _otherPayroll,
                    thirteenthMonth : _thirteenthMonth === "" ? 0 : _thirteenthMonth,
                    sil : _sil === "" ? 0 : _sil,
                    separationPay :_separation === "" || typeof _separation === "undefined" ? 0 : _separation,
                    settlementFee :itm.settlementFee === "" || typeof itm.settlementFee === "undefined" ? 0 : itm.settlementFee,
                    incentives : itm.incentives,
                    grossPay : _grossPay,
                    inclusions : totalGross === "" ? 0 : parseFloat(String(totalGross).replace(',','')),
                    totalInclusion : parseFloat(String(_totInc).replace(',','')),
                    totalBalance : parseFloat(String(itm.totalBalance).replace(',','')),
                    netPay : _netPay === "" ? 0 : parseFloat(String(_netPay).replace(',','')),
                    noAtm : itm.isCash === "1" || itm.isCheckMember === "1" ? parseFloat(String(_netPay).replace(',','')) : 0,

                    batchPayroll : itm.batchPayroll,
                    typeOfCashCardMember : itm.typeOfCashCardMember,
                    areRemarks : itm.areRemarks,
                    clearanceDateMember : itm.clearanceDateMember,
                    clearanceDeductions : itm.clearanceDeductions.sort((a, b) => parseFloat(a.priority) > parseFloat(b.priority) ? 1 : -1),
                    clearanceDetails : itm.clearanceDetails,
                    clearanceStatus : itm.clearanceStatus,
                    clearedSubmit : itm.clearedSubmit,
                    clientId : itm.clientId,
                    closingRemarks : itm.closingRemarks,
                    createdBy : itm.createdBy,
                    createdByName : itm.createdByName,
                    createdDate : itm.createdDate,
                    cycleDays : itm.cycleDays,
                    dateCreated : itm.dateCreated,
                    dateForwardedToAmt : itm.dateForwardedToAmt,
                    dateOfBirth : itm.dateOfBirth,
                    dateReceivedByAdmin : itm.dateReceivedByAdmin,
                    dateReceivedFromAmt : itm.dateReceivedFromAmt,
                    dateReturnedByARE : itm.dateReturnedByARE,
                    dateReturnedByClient : itm.dateReturnedByClient,
                    dateReturnedByFinance : itm.dateReturnedByFinance,
                    dateReturnedByLRD : itm.dateReturnedByLRD,
                    dateReturnedByPayroll : itm.dateReturnedByPayroll,
                    dateReturnedByRMT : itm.dateReturnedByRMT,
                    dateReturnedByServices : itm.dateReturnedByServices,
                    dateSettle : itm.dateSettle,
                    dateSubmitted : itm.dateSubmitted,
                    dateSubmittedByLead : itm.dateSubmittedByLead,
                    dateSubmittedByStaff : itm.dateSubmittedByStaff,
                    dateUpdatedByARE : itm.dateUpdatedByARE,
                    dateUpdatedByClient : itm.dateUpdatedByClient,
                    dateUpdatedByFinance : itm.dateUpdatedByFinance,
                    dateUpdatedByLRD : itm.dateUpdatedByLRD,
                    dateUpdatedByRMT : itm.dateUpdatedByRMT,
                    dateUpdatedByServices : itm.dateUpdatedByServices,
                    employeeId : itm.employeeId,
                    finalized : itm.finalized,
                    financeRemarks : itm.financeRemarks,
                    firstName : itm.firstName,
                    generation : itm.generation,
                    // grossPay : itm.grossPay,
                    hdmfNumber : itm.hdmfNumber,
                    id : itm.id,
                    isCOERequestFormMember : itm.isCOERequestFormMember,
                    isCash : itm.isCash,
                    isCashCardMember : itm.isCashCardMember,
                    isCheckMember : itm.isCheckMember,
                    isCheckedByRmt : itm.isCheckedByRmt,
                    isClearanceStatus : itm.isClearanceStatus,
                    isClearedClient : itm.isClearedClient,
                    isClearedRmt : itm.isClearedRmt,
                    isClearedSubmit : itm.isClearedSubmit,
                    isDeleted : itm.isDeleted,
                    isEligible : itm.isEligible,
                    isEligibleClient : itm.isEligibleClient,
                    isFinalized : itm.isFinalized,
                    isForwardToARERmt : itm.isForwardToARERmt,
                    isGenerated : itm.isGenerated,
                    isHDMFNumberMember : itm.isHDMFNumberMember,
                    isMlhuillierMember : itm.isMlhuillierMember,
                    isModified : itm.isModified,
                    isModifiedByARE : itm.isModifiedByARE,
                    isNonEligibleClient : itm.isNonEligibleClient,
                    isNotEligible : itm.isNotEligible,
                    isPHICNumberMember : itm.isPHICNumberMember,
                    isPalawanMember : itm.isPalawanMember,
                    isResignationLetterMember : itm.isResignationLetterMember,
                    isRetrunedServicesRmt : itm.isRetrunedServicesRmt,
                    isReturnedRmt : itm.isReturnedRmt,
                    isSSSNumberMember : itm.isSSSNumberMember,
                    isSettleWithDole : itm.isSettleWithDole,
                    isTINNumberMember : itm.isTINNumberMember,
                    isTurnoverChecklistMember : itm.isTurnoverChecklistMember,
                    isTypeOfCashCardMember : itm.isTypeOfCashCardMember,
                    isValidIdMember : itm.isValidIdMember,
                    lastName : itm.lastName,
                    locationId : itm.locationId,
                    locationName : itm.locationName,
                    middleName : itm.middleName,
                    modifiedBy : itm.modifiedBy,
                    modifiedByName : itm.modifiedByName,
                    modifiedDate : itm.modifiedDate,
                    // netPay : itm.netPay,
                    notesMember : itm.notesMember,
                    paramountId : itm.paramountId,
                    payPeriodId : itm.payPeriodId,
                    paycardTypeIdMember : itm.paycardTypeIdMember,
                    payrollPeriods : itm.payrollPeriods,
                    payrollRemarks : itm.payrollRemarks,
                    periodCovered : itm.periodCovered,
                    phicNumber : itm.phicNumber,
                    positionId : itm.positionId,
                    positionName : itm.positionName,
                    profileId : itm.profileId,
                    reasonForLeavingId : itm.reasonForLeavingId,
                    remarksClient : itm.remarksClient,
                    remarksLrd : itm.remarksLrd,
                    remarksMember : itm.remarksMember,
                    remarksRmt : itm.remarksRmt,
                    resignationDateMember : itm.resignationDateMember,
                    returnedByARE : itm.returnedByARE,
                    returnedByClient : itm.returnedByClient,
                    returnedByFinance : itm.returnedByFinance,
                    returnedByLRD : itm.returnedByLRD,
                    returnedByRMT : itm.returnedByRMT,
                    returnedByServices : itm.returnedByServices,
                    reviewStatus : itm.reviewStatus,
                    reviewStatusResults : itm.reviewStatusResults,
                    rowIndex : itm.rowIndex,
                    servicesAttachments : itm.servicesAttachments,
                    servicesRemarks : itm.servicesRemarks,
                    // sil : itm.sil,
                    sssNumber : itm.sssNumber,
                    submissionDateMember : itm.submissionDateMember,
                    submitted : itm.submitted,
                    submittedByLead : itm.submittedByLead,
                    submittedByStaff : itm.submittedByStaff,
                    suffixName : itm.suffixName,
                    timeStamp : itm.timeStamp,
                    tinNumber : itm.tinNumber,
                    // totalBalance : itm.totalBalance,
                    updatedByARE : itm.updatedByARE,
                    updatedByClient : itm.updatedByClient,
                    updatedByFinance : itm.updatedByFinance,
                    updatedByLRD : itm.updatedByLRD,
                    updatedByRMT : itm.updatedByRMT,
                    updatedByServices : itm.updatedByServices,
                    validateByRmt : itm.validateByRmt,
                    batchPayroll : itm.batchPayroll,
                    dateHired : itm.dateHired,
                    contractDateStart : itm.contractDateStart,
                    contractDateEnd : itm.contractDateEnd,
                    isParent : itm.isParent,
                    isChild : itm.isChild,
                    parentId : itm.parentId,
                })
            })
            //ATTACHED DEDUCTIONS & INCLUSION

            let uniqpaycards = [...new Map(modifyData.map((item) => [JSON.stringify(item), item])).values(),];
            let uniqinclusion = [...new Map(this.state.AreInlcusion.map((item) => [JSON.stringify(item), item])).values(),];
            let empdeduction = []
            // // // // // // // console.log("modifyData")
            // // // // // // // console.log(modifyData)
            modifyData.forEach(itm=>{
                itm.clearanceDeductions.forEach(ded =>{
                    // // // // // // // // console.log("ded.employeeId")
                    // // // // // // // // console.log(ded.employeeId)
                    empdeduction.push({
                        deductionId : ded.deductionId,
                        deduction : ded.deduction,
                        employeeId : ded.employeeId
                    })
                })
            })
            let uniqdeduction = [...new Map(empdeduction.map((item) => [JSON.stringify(item), item])).values(),];

            modifyData.forEach(itm =>{
                this.state.deductionMaintenance.sort((a, b) => parseFloat(a.priority) > parseFloat(b.priority) ? 1 : -1).filter(f => uniqdeduction.some(item => item.deductionId === f.id)).forEach(ded=>{
                    itm[ded.name]   = this.state.subsidiaryData.filter(x=>x.employeeNo===itm.employeeNo && x.deductionId===ded.id && x.debit!=="0.00").length>0 ? 
                                        parseFloat(String(this.state.subsidiaryData.filter(x=>x.employeeNo===itm.employeeNo && x.deductionId===ded.id && x.debit!=="0.00").reduce((total, currentValue) => total + parseFloat(String(currentValue.debit).replace(',','')),0))) : 0.00
                    
                })
                this.state.inclusionMaintenance.filter(f => uniqinclusion.some(item => item.inclusionId === f.id)).forEach(inc=>{
                    itm[inc.name]   = this.state.AreInlcusion.filter(x=>x.employeeNo===itm.employeeNo && x.inclusionId===inc.id).length>0 ? 
                                            parseFloat(String(this.state.AreInlcusion.find(x=>x.employeeNo===itm.employeeNo && x.inclusionId===inc.id).amount).replace(',','')) : 0.00
                })
                this.state.paycardMaintenance.filter(f => uniqpaycards.some(item => item.paycardTypeIdMember === f.id)).forEach(pc => {
                    itm[pc.name] = itm.paycardTypeIdMember === pc.id ? parseFloat(String(itm.netPay).replace(',','')) : 0
                })
            })

            this.setState({ clearanceData: modifyData.sort((a, b) => new Date(a.id) > new Date(b.id) ? -1 : 1)})

        }
        else{
            // // // // // // // console.log("else")
            this.setState({isloading:true})
            let _inc = this.state.AreInlcusion
            // let dvdndArry = this.state.dividendLst
            let LastPayroll = this.state.EmployeePayroll
            let cbudividn = this.state.CbuDividend
            let param = {
                "IpAddress"             :   "0.0.0.0",
                "UserId"                :   this.state.userinfo.userId,
                "ClientId"              :   this.state.clientId,
                "ProfileId"             :   this.state.profileId,
                "ClearanceStatusId"     :   this.state.statusId,
                "FromDateCreated"       :   this.state.fromDateCreated,
                "ToDateCreated"         :   this.state.toDateCreated,
                "FromClearanceDate"     :   this.state.fromClearanceDate,
                "ToClearanceDate"       :   this.state.toClearanceDate,
                "FromDateReceive"       :   this.state.fromDateReceived,
                "ToDateReceive"         :   this.state.toDateReceived,
                "FromLastWorkingDate"   :   this.state.fromLastWorkingDate,
                "ToLastWorkingDate"     :   this.state.toLastWorkingDate,
                "BatchNumber"           :   this.state.batchNumber === "" ? "" : this.state.batchNumber,
            };
            await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
            .then(res => {
                let data = res.data.clearance;
                let _pushSrchData = []
                let _trainingFIlter = data.filter( x => x.isClearanceStatus === "7" )
                // let modifyDataTmp = this.state.reason === "" ? _trainingFIlter : _trainingFIlter.filter(x => x.reasonForLeaving === this.state.reason)
                // // // // // // // // console.log("modifyDataTmp")
                // // // // // // // // console.log(modifyDataTmp)

                // Modify for cbu and dividend computations

                _trainingFIlter.map(function(itm, idx) {
                    let _tmpData = _inc.filter( x => x.employeeNo===itm.employeeNo)
                    // let inclusion = _tmpData.filter( x => x.deductionId === "3" || x.deductionId === "5" || x.deductionId === "9" || x.deductionId === "51" || x.deductionId === "55")
                    let _totInc = _tmpData.length>0?_tmpData.reduce((total, currentValue) => total + parseFloat(String(currentValue.debit).replace(',','')),0):0
                    // // // // // // // // console.log("_totInc")
                    // // // // // // // // console.log(_totInc)
                    let _cbu = cbudividn.filter(x=> x.employeeNo===itm.employeeNo && x.isInclusion === "1" && x.deductionId ==="5").length>0 ?
                        parseFloat(String(cbudividn.find(x=> x.employeeNo===itm.employeeNo && x.isInclusion === "1" && x.deductionId ==="5").debit).replace(',','')) : 0
                    let _dividend = itm.dividend!=="" ? itm.dividend : cbudividn.filter(x=> x.employeeNo===itm.employeeNo && x.isInclusion === "2").length>0 ?
                        parseFloat(String(cbudividn.find(x=> x.employeeNo===itm.employeeNo && x.isInclusion === "2").debit).replace(',','')) : 0
                    
    
                    // COMPUTATIONS
    
                    // // // // // // // // console.log("LastPayroll")
                    // // // // // // // // console.log(LastPayroll)
                    let _lastSalary = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                        parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).lastPayroll).replace(',','')) : 0
                    let _adjustment = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                        parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).adjustmentPayroll).replace(',','')) : 0
                    let _otherPayroll = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                        parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).otherPayroll).replace(',','')) : 0
                    let _thirteenthMonth = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                        parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).thirteenthMonth).replace(',','')) : 0
                    let _sil = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                        parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).sil).replace(',','')) : 0
                    let _separation = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                        parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).separationPay).replace(',','')) : 0
    
                    // // // // // // // // console.log("_separation")
                    // // // // // // // // console.log(_separation)
                    
                    let _grossPay = parseFloat(String(_lastSalary)) + parseFloat(String(_adjustment)) + parseFloat(String(_otherPayroll)) + parseFloat(String(_thirteenthMonth)) + parseFloat(String(_sil)) + parseFloat(String(_separation)) + parseFloat(String(_cbu))+ parseFloat(String(_dividend))
                    let totalGross = parseFloat(_grossPay) + parseFloat(_totInc) 
                    // // // // // // // // // console.log('totalGross')
                    // // // // // // // // // console.log(totalGross)
                    // // // // // // // // // console.log('itm.totalBalance')
                    // // // // // // // // // console.log(itm.totalBalance)
                    
                    let netPayTmp = parseFloat(totalGross) - parseFloat(String(itm.totalBalance).replace(',',''))
                    let _netPay = parseFloat(String(netPayTmp).replace(',','')).toFixed(2)
                    
    
                    let _hc = parseFloat(idx+1)
                    _pushSrchData.push({
                        hc : _hc,
                        batchNumber : itm.batchNumber,
                        clientName : itm.clientName,
                        employeeNo : itm.employeeNo,
                        employeeName : itm.employeeName,
                        reasonForLeaving : itm.reasonForLeaving,
                        dateStart : itm.dateStart,
                        dateEnd : itm.lastWorkingDateMember,
                        paycardNumber : itm.cashCardNumberMember,
                        cbu : _cbu,
                        dividend : _dividend,
                        lastSalary : _lastSalary === "" ? 0 : _lastSalary,
                        adjustment : typeof _adjustment === "undefined" || _adjustment === "" ? 0 : _adjustment,
                        otherPayroll : typeof _otherPayroll === "undefined" || _otherPayroll === "" ? 0 : _otherPayroll,
                        thirteenthMonth : _thirteenthMonth === "" ? 0 : _thirteenthMonth,
                        sil : _sil === "" ? 0 : _sil,
                        separationPay :_separation === "" || typeof _separation === "undefined" ? 0 : _separation,
                        incentives : itm.incentives,
                        grossPay : _grossPay,
                        inclusions : totalGross === "" ? 0 : parseFloat(String(totalGross).replace(',','')),
                        totalInclusion : parseFloat(String(_totInc).replace(',','')),
                        totalBalance : parseFloat(String(itm.totalBalance).replace(',','')),
                        netPay : _netPay === "" ? 0 : parseFloat(String(_netPay).replace(',','')),
                        noAtm : itm.isCash === "1" || itm.isCheckMember === "1" ? parseFloat(String(_netPay).replace(',','')) : 0,
    
                        batchPayroll : itm.batchPayroll,
                        typeOfCashCardMember : itm.typeOfCashCardMember,
                        areRemarks : itm.areRemarks,
                        clearanceDateMember : itm.clearanceDateMember,
                        clearanceDeductions : itm.clearanceDeductions.sort((a, b) => parseFloat(a.priority) > parseFloat(b.priority) ? 1 : -1),
                        clearanceDetails : itm.clearanceDetails,
                        clearanceStatus : itm.clearanceStatus,
                        clearedSubmit : itm.clearedSubmit,
                        clientId : itm.clientId,
                        closingRemarks : itm.closingRemarks,
                        createdBy : itm.createdBy,
                        createdByName : itm.createdByName,
                        createdDate : itm.createdDate,
                        cycleDays : itm.cycleDays,
                        dateCreated : itm.dateCreated,
                        dateForwardedToAmt : itm.dateForwardedToAmt,
                        dateOfBirth : itm.dateOfBirth,
                        dateReceivedByAdmin : itm.dateReceivedByAdmin,
                        dateReceivedFromAmt : itm.dateReceivedFromAmt,
                        dateReturnedByARE : itm.dateReturnedByARE,
                        dateReturnedByClient : itm.dateReturnedByClient,
                        dateReturnedByFinance : itm.dateReturnedByFinance,
                        dateReturnedByLRD : itm.dateReturnedByLRD,
                        dateReturnedByPayroll : itm.dateReturnedByPayroll,
                        dateReturnedByRMT : itm.dateReturnedByRMT,
                        dateReturnedByServices : itm.dateReturnedByServices,
                        dateSettle : itm.dateSettle,
                        dateSubmitted : itm.dateSubmitted,
                        dateSubmittedByLead : itm.dateSubmittedByLead,
                        dateSubmittedByStaff : itm.dateSubmittedByStaff,
                        dateUpdatedByARE : itm.dateUpdatedByARE,
                        dateUpdatedByClient : itm.dateUpdatedByClient,
                        dateUpdatedByFinance : itm.dateUpdatedByFinance,
                        dateUpdatedByLRD : itm.dateUpdatedByLRD,
                        dateUpdatedByRMT : itm.dateUpdatedByRMT,
                        dateUpdatedByServices : itm.dateUpdatedByServices,
                        employeeId : itm.employeeId,
                        finalized : itm.finalized,
                        financeRemarks : itm.financeRemarks,
                        firstName : itm.firstName,
                        generation : itm.generation,
                        // grossPay : itm.grossPay,
                        hdmfNumber : itm.hdmfNumber,
                        id : itm.id,
                        isCOERequestFormMember : itm.isCOERequestFormMember,
                        isCash : itm.isCash,
                        isCashCardMember : itm.isCashCardMember,
                        isCheckMember : itm.isCheckMember,
                        isCheckedByRmt : itm.isCheckedByRmt,
                        isClearanceStatus : itm.isClearanceStatus,
                        isClearedClient : itm.isClearedClient,
                        isClearedRmt : itm.isClearedRmt,
                        isClearedSubmit : itm.isClearedSubmit,
                        isDeleted : itm.isDeleted,
                        isEligible : itm.isEligible,
                        isEligibleClient : itm.isEligibleClient,
                        isFinalized : itm.isFinalized,
                        isForwardToARERmt : itm.isForwardToARERmt,
                        isGenerated : itm.isGenerated,
                        isHDMFNumberMember : itm.isHDMFNumberMember,
                        isMlhuillierMember : itm.isMlhuillierMember,
                        isModified : itm.isModified,
                        isModifiedByARE : itm.isModifiedByARE,
                        isNonEligibleClient : itm.isNonEligibleClient,
                        isNotEligible : itm.isNotEligible,
                        isPHICNumberMember : itm.isPHICNumberMember,
                        isPalawanMember : itm.isPalawanMember,
                        isResignationLetterMember : itm.isResignationLetterMember,
                        isRetrunedServicesRmt : itm.isRetrunedServicesRmt,
                        isReturnedRmt : itm.isReturnedRmt,
                        isSSSNumberMember : itm.isSSSNumberMember,
                        isSettleWithDole : itm.isSettleWithDole,
                        isTINNumberMember : itm.isTINNumberMember,
                        isTurnoverChecklistMember : itm.isTurnoverChecklistMember,
                        isTypeOfCashCardMember : itm.isTypeOfCashCardMember,
                        isValidIdMember : itm.isValidIdMember,
                        lastName : itm.lastName,
                        locationId : itm.locationId,
                        locationName : itm.locationName,
                        middleName : itm.middleName,
                        modifiedBy : itm.modifiedBy,
                        modifiedByName : itm.modifiedByName,
                        modifiedDate : itm.modifiedDate,
                        // netPay : itm.netPay,
                        notesMember : itm.notesMember,
                        paramountId : itm.paramountId,
                        payPeriodId : itm.payPeriodId,
                        paycardTypeIdMember : itm.paycardTypeIdMember,
                        payrollPeriods : itm.payrollPeriods,
                        payrollRemarks : itm.payrollRemarks,
                        periodCovered : itm.periodCovered,
                        phicNumber : itm.phicNumber,
                        positionId : itm.positionId,
                        positionName : itm.positionName,
                        profileId : itm.profileId,
                        reasonForLeavingId : itm.reasonForLeavingId,
                        remarksClient : itm.remarksClient,
                        remarksLrd : itm.remarksLrd,
                        remarksMember : itm.remarksMember,
                        remarksRmt : itm.remarksRmt,
                        resignationDateMember : itm.resignationDateMember,
                        returnedByARE : itm.returnedByARE,
                        returnedByClient : itm.returnedByClient,
                        returnedByFinance : itm.returnedByFinance,
                        returnedByLRD : itm.returnedByLRD,
                        returnedByRMT : itm.returnedByRMT,
                        returnedByServices : itm.returnedByServices,
                        reviewStatus : itm.reviewStatus,
                        reviewStatusResults : itm.reviewStatusResults,
                        rowIndex : itm.rowIndex,
                        servicesAttachments : itm.servicesAttachments,
                        servicesRemarks : itm.servicesRemarks,
                        // sil : itm.sil,
                        sssNumber : itm.sssNumber,
                        submissionDateMember : itm.submissionDateMember,
                        submitted : itm.submitted,
                        submittedByLead : itm.submittedByLead,
                        submittedByStaff : itm.submittedByStaff,
                        suffixName : itm.suffixName,
                        timeStamp : itm.timeStamp,
                        tinNumber : itm.tinNumber,
                        // totalBalance : itm.totalBalance,
                        updatedByARE : itm.updatedByARE,
                        updatedByClient : itm.updatedByClient,
                        updatedByFinance : itm.updatedByFinance,
                        updatedByLRD : itm.updatedByLRD,
                        updatedByRMT : itm.updatedByRMT,
                        updatedByServices : itm.updatedByServices,
                        validateByRmt : itm.validateByRmt,
                        batchPayroll : itm.batchPayroll,
                        dateHired : itm.dateHired,
                        contractDateStart : itm.contractDateStart,
                        contractDateEnd : itm.contractDateEnd,
                    })
                })

                //ATTACHED DEDUCTIONS & INCLUSION
                
                let uniqpaycards = [...new Map(_trainingFIlter.map((item) => [JSON.stringify(item), item])).values(),];
                let uniqinclusion = [...new Map(this.state.AreInlcusion.map((item) => [JSON.stringify(item), item])).values(),];
                let empdeduction = []
                _trainingFIlter.forEach(itm=>{
                    itm.clearanceDeductions.forEach(ded =>{
                        // // // // // // // // console.log("ded.employeeId")
                        // // // // // // // // console.log(ded.employeeId)
                        empdeduction.push({
                            deductionId : ded.deductionId,
                            deduction : ded.deduction,
                            employeeId : ded.employeeId
                        })
                    })
                })
                // // // // // // // // console.log("modifyDataTmp")
                // // // // // // // // console.log(modifyDataTmp)
                let uniqdeduction = [...new Map(empdeduction.map((item) => [JSON.stringify(item), item])).values(),];
                _pushSrchData.forEach(itm =>{
                    this.state.deductionMaintenance.filter(f => uniqdeduction.some(item => item.deductionId === f.id)).forEach(ded=>{
                        itm[ded.name]   = this.state.subsidiaryData.filter(x=>x.employeeNo===itm.employeeNo && x.deductionId===ded.id && x.debit!=="0.00").length>0 ? 
                                            parseFloat(String(this.state.subsidiaryData.filter(x=>x.employeeNo===itm.employeeNo && x.deductionId===ded.id && x.debit!=="0.00").reduce((total, currentValue) => total + parseFloat(String(currentValue.debit).replace(',','')),0))) : 0.00
                        
                    })
                    this.state.inclusionMaintenance.filter(f => uniqinclusion.some(item => item.inclusionId === f.id)).forEach(inc=>{
                        itm[inc.name]   = this.state.AreInlcusion.filter(x=>x.employeeNo===itm.employeeNo && x.inclusionId===inc.id).length>0 ? 
                                                parseFloat(String(this.state.AreInlcusion.find(x=>x.employeeNo===itm.employeeNo && x.inclusionId===inc.id).amount).replace(',','')) : 0.00
                    })
                    this.state.paycardMaintenance.filter(f => uniqpaycards.some(item => item.paycardTypeIdMember === f.id)).forEach(pc => {
                        itm[pc.name] = itm.paycardTypeIdMember === pc.id ? parseFloat(String(itm.netPay).replace(',','')) : 0
                    })
                })
                // // // // // // // // console.log("_pushSrchData")
                // // // // // // // // console.log(_pushSrchData)
                this.setState({ clearanceData : _pushSrchData, isloading : false})
                if(data.status==="0"){
                    this.setState(  {
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   data.message,
                        fade            :   true
                    })
                }
            })
            .catch(error=>{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })
        }
    };
    GridExcellReports = () =>{
        let newGridData = []
        let dataSelected = this.state.payrollDataArry.filter( x=> x.isDeleted === "1")
        let _isSelected = []
        if(dataSelected.length === 0){
            _isSelected = this.state.payrollDataArry
        }else{
            _isSelected = dataSelected
        }
        for (let i = 0; i < _isSelected.length; i++){
            let obj = {
                "EMPLOYEE NAME"         :   _isSelected[i]["employeeName"],
                "CLIENT NAME"           :   _isSelected[i]["clientName"],
                "BRANCH / LOCATION"     :   _isSelected[i]["locationName"],
                "DATE START"            :   _isSelected[i]["dateStart"],
                "LAST WORKING DATE"     :   _isSelected[i]["lastWorkingDateMember"],
                "CLEARANCE DATE"        :   _isSelected[i]["clearanceDateMember"],
                "STATUS"                :   _isSelected[i]["clearanceStatus"],
                "COMPLETION DATE"       :   _isSelected[i]["completionDate"],
                "CYCLE DAYS"            :   _isSelected[i]["cycleDays"],
                "FULL NAME"             :   _isSelected[i]["createdByName"],
                "FULL NAME (UPDATEDBY )" :   _isSelected[i]["lastModifiedBy"],
            }
            newGridData.push(obj)
        }
        this.setState({gridDataExcel : newGridData})
    };
    handleChangeResignationDate = date =>{
        this.setState({ resignationDate: date, isshow:false , })
    };
    handleChangeClearanceDate = date =>{
        this.setState({ clearanceDate: date, isshow:false , })
    };
    handleChangeSubmissionDate = date =>{
        this.setState({ submissionDate: date, isshow:false , })
    };
    handleChangeWorkingDate = date =>{
        this.setState({ workingDate: date, isshow:false , })
    };
    handleChangeWorkingDate = date =>{
        this.setState({ workingDate: date, isshow:false , })
    };
    handleChangeFromLastWorkingDate = date =>{
        this.setState({ fromLastWorkingDate: date, isshow:false , })
    };
    handleChangeToLastWorkingDate = date =>{
        this.setState({ toLastWorkingDate: date, isshow:false , })
    };
    handleChangeToDateReceived = date =>{
        this.setState({ toDateReceived: date, isshow:false , })
    };
    handleChangeFromDateReceived = date =>{
        this.setState({ fromDateReceived: date, isshow:false , })
    };
    handleChangeToClearanceDate = date =>{
        this.setState({ toClearanceDate: date, isshow:false , })
    };
    handleChangeFromClearanceDate = date =>{
        this.setState({ fromClearanceDate: date, isshow:false , })
    };
    handleChangeFromDateCreated = date =>{
        this.setState({ fromDateCreated: date, isshow:false , })
    };
    handleChangeToDateCreated = date =>{
        this.setState({ toDateCreated: date, isshow:false , })
    };
    handleChangedBatchNumber = async(e) =>{
        if(e.length == 0) {
            this.state.batchNumber   =   ""
            this.setState({selectedHeaderId : [], batchNumber : ""/* , isshowExpand: false */,})
            this.FilterBatch();
        } else{
            this.state.batchNumber   =   e[0].batchNumber
            this.setState({
                // isshow:false,

            })
        }
    };
    FilterBatch = async() =>{
        let filterbatch = this.state.payrollDataArry.filter (x => x.batchNumber === this.state.batchNumber)
        this.setState({ payrollDataArry : filterbatch})
    };
    handleChangeClient = (e) => {
        if(e.length == 0) {
            this.state.clientId     =   ""
            this.state.clientName   =   ""
            return
        }
        this.state.clientId     =   e[0].id
        this.state.clientName   =   e[0].name
         this.setState({
            isshow:false,

         })
        this.GetEmployees();
        // this.refBatch.current.clear()
    };
    handleChangedBatchNumber = async(e) =>{
        if(e.length == 0) {
            this.state.batchNumber   =   ""
            this.setState({ payrollDataArry : this.state._tmpData, selectedId : [], batchNumber : "" })
        } else{
            this.state.batchNumber   =   e[0].batchNumber
            this.setState({
                isshow:false,
            })
        }
    };
    Selectbatch = async(batchSelected) =>{
        let trainngBatch = this.state.payrollDataArry.filter( x => x.batchNumber === batchSelected)
    };
    handleChangeEmployee = (e) => {

        if(e.length == 0) {
            this.state.employeeId   =   ""
            this.state.profileId    =   ""
            this.state.client       =   ""
            this.state.lastName     =   ""
            this.state.firstName    =   ""
            this.state.middleName   =   ""
            this.state.dateOfBirth  =   ""
            this.state.positionName =   ""
            this.state.contractDateStart  =   ""
            this.state.phicNo = ""
            this.state.sssNo = ""
            this.state.tinNo = ""
            this.state.pagibigNo = ""
        } else {

            this.state.employeeId   =   e[0].id
            this.state.profileId    =   e[0].profileId
            this.state.client       =   e[0].clientName
            this.state.lastName     =   e[0].lastName
            this.state.firstName    =   e[0].firstName
            this.state.middleName   =   e[0].middleName
            this.state.dateOfBirth  =   e[0].dateOfBirth
            this.state.positionName =   e[0].position
            this.state.locationName =   e[0].locationName
            this.state.contractDateStart  =   e[0].contractDateStart
            this.state.phicNo       =   e[0].phicNumber
            this.state.sssNo        =   e[0].sssNumber
            this.state.tinNo        =   e[0].tinNumber
            this.state.pagibigNo    =   e[0].hdmfNumber
        }
        this.getEmployeeClearance();
    };
    getEmployeeClearance = async() =>{
        let param = {
            "IpAddress"             :   "0.0.0.0",
            "UserId"                :   this.state.userinfo.userId,
            "ClientId"              :   this.state.userinfo.clientId,
            "ProfileId"             :   this.state.userinfo.profileId,
            "DisengagementModeId"   :   this.state.disengagementModeId,
            "ClearanceDate"         :   this.state.clearanceDate,
            "ResignationDate"       :   this.state.resignationDate,
            "SubmissionDate"        :   this.state.submissionDate,
            "LastWorkingDate"       :   this.state.workingDate,
            "ReasonForLeaving"      :   this.state.reason
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            let data = res.data.clearance;

            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    handleChangedReason = (e) => {
        if (e.length === 0){
            this.setState({ reason : "" })
        } else {
            this.setState({ reason : e[0].name })
        }
    };
    handleClickRefresh = () =>{
        window.location.reload(true)
    };
    handleClickFinalized = async() =>{
        // let arr = []
        this.setState({isloading:true})
        let date = (this.state.datenow) ? moment(this.state.datenow).format('MM/DD/YYYY') : ""
        let slctdData = this.state.clearanceData.filter(f => this.state.selectedRowKeys.some(item => item === f.employeeNo))

        if(slctdData.length === 0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select atleast 1 to process",
                fade        :   true
            })
            return
        }
        let arrLst = []
        slctdData.filter(x=>x.employeeNo!=="").forEach(itm => {

            let _clearanceStatus = ""

            if ( itm.isClearanceStatus === "0"
                    || itm.isClearanceStatus === "1"
                    || itm.isClearanceStatus === "2"
                )
            {
                if (itm.reasonForLeaving === "DOLE CASES"){
                    _clearanceStatus = "3"
                }else {
                    _clearanceStatus = "4"
                }
            }
            else{
                _clearanceStatus = String(parseFloat(itm.isClearanceStatus)  + 1)
            }
            let _userId = this.state.userinfo.userId
            
            // let paycardid = ""
            // if(itm.typeOfCashCardMember==="BDO"){
            //     paycardid = "2"
            // }
            // else if(itm.typeOfCashCardMember==="METROBANK"){
            //     paycardid = "6"
            // }
            // else if(itm.typeOfCashCardMember==="UNION BANK"){
            //     paycardid = "14"
            // }
            // else if(itm.typeOfCashCardMember==="M-LHUILLIER"){
            //     paycardid = "4"
            // }
            // else if(itm.typeOfCashCardMember==="PALAWAN"){
            //     paycardid = "15"
            // }
            // else { 
            //     // console.log("eeror on paycard id")
            //     paycardid = ""
            // }
            arrLst.push({
                "Id" : itm.id,
                "ClientId":itm.clientId,
                "ProfileId":itm.profileId,
                "EmployeeId":itm.employeeId,
                "PayPeriodId":itm.payPeriodId,
                "DateCreated":(itm.dateCreated) ? moment(itm.dateCreated).format('MM/DD/YYYY') : "",
                "ClearanceDateMember":(itm.clearanceDateMember) ? moment(itm.clearanceDateMember).format('MM/DD/YYYY') : "",
                "ResignationDateMember":(itm.resignationDateMember) ? moment(itm.resignationDateMember).format('MM/DD/YYYY') : "",
                "SubmissionDateMember":(itm.submissionDateMember) ? moment(itm.submissionDateMember).format('MM/DD/YYYY') : "",
                "LastWorkingDateMember":(itm.dateEnd) ? moment(itm.dateEnd).format('MM/DD/YYYY') : "",
                "DateSubmitted":(itm.dateSubmitted) ? moment(itm.dateSubmitted).format('MM/DD/YYYY') : "",
                "IsSSSNumberMember":itm.isSSSNumberMember,
                "IsTINNumberMember":itm.isTINNumberMember,
                "IsPHICNumberMember":itm.isPHICNumberMember,
                "IsHDMFNumberMember":itm.isHDMFNumberMember,
                "IsCashCardMember":itm.isCashCardMember,
                "IsValidIdMember":itm.isValidIdMember,
                "IsResignationLetterMember":itm.isResignationLetterMember,
                "IsTurnoverChecklistMember":itm.isTurnoverChecklistMember,
                "IsCOERequestFormMember":itm.isCOERequestFormMember,
                "IsTypeOfCashCardMember":itm.isTypeOfCashCardMember, // cash card mode of payment
                "PaycardTypeIdMember":itm.paycardTypeIdMember,
                "TypeOfCashCardMember":itm.typeOfCashCardMember,
                "CashCardNumberMember":itm.paycardNumber,
                "IsCheckMember":itm.isCheckMember,
                "IsMlhuillierMember":itm.isMlhuillierMember,
                "IsPalawanMember":itm.isPalawanMember,
                "RemarksMember":itm.remarksMember,
                "NotesMember":itm.notesMember,
                "ReasonForLeaving":itm.reasonForLeaving,
                "DateForwardedToAmt": itm.dateForwardedToAmt,
                "DateReceivedFromAmt": itm.dateReceivedFromAmt,
                "IsEligible":itm.isEligible,
                "IsNotEligible":itm.isNotEligible,
                "SssNumber": itm.sssNumber,
                "TinNumber": itm.tinNumber,
                "PhicNumber": itm.phicNumber,
                "HdmfNumber": itm.hdmfNumber,
                "IsClearedClient":itm.isClearedClient,
                "IsEligibleClient":itm.isEligibleClient,
                "IsNonEligibleClient":itm.isNonEligibleClient,
                "RemarksClient":itm.remarksClient,

                "IsClearedRmt":itm.isNonEligibleClient,
                "IsReturnedRmt":itm.isNonEligibleClient,
                "ValidateByRmt":itm.isNonEligibleClient,
                "IsForwardToARERmt":itm.isNonEligibleClient ,
                "IsRetrunedServicesRmt":itm.isNonEligibleClient,
                "IsCheckedByRmt":itm.isCheckedByRmt,
                "RemarksRmt":itm.remarksRmt,

                "RemarksLrd":itm.remarksLrd,
                "ServicesRemarks":itm.servicesRemarks ,

                "IsClearanceStatus": _clearanceStatus,
                "clearanceDetails" :itm.clearanceDetails,

                /* ADDITIONAL */
                "DateUpdatedByClient": date,
                "UpdatedByClient":_userId,

                "DateUpdatedByLRD":date,
                "UpdatedByLRD":_userId,

                "DateUpdatedByRMT":date,
                "UpdatedByRMT":_userId,

                "DateUpdatedByServices":itm.dateUpdatedByServices,
                "UpdatedByServices":_userId,

                "DateUpdatedByARE":itm.dateUpdatedByARE,
                "UpdatedByARE":itm.isUpdatedByARE,

                "DateUpdatedByFinance":date,
                "UpdatedByFinance":_userId,
                "DateSubmittedByStaff":date,
                "SubmittedByStaff": _userId,
                "DateSubmittedByLead":date,
                "SubmittedByLead":_userId,

                "DateReturnedByClient":itm.dateReturnedByClient,
                "ReturnedByClient":_userId,
                "DateReturnedByLRD":itm.dateReturnedByLRD,
                "ReturnedByLRD":_userId,
                "DateReturnedByRMT":itm.dateReturnedByRMT,
                "ReturnedByRMT":_userId,
                "DateReturnedByServices":itm.dateReturnedByServices,
                "ReturnedByServices":_userId,
                "DateReturnedByARE":itm.dateReturnedByARE,
                "ReturnedByARE":_userId,
                "DateReturnedByFinance":itm.dateReturnedByFinance,
                "ReturnedByFinance":_userId,

                "DateSettle"        : itm.dateSettle,
                "IsSettleWithDole"  :  itm.isSettleWithDole,

                "DateReceivedByAdmin" : itm.dateReceivedByAdmin,
                "IsCash" : itm.isCash,
                "BatchNumber" : itm.batchNumber,
                "servicesAttachments" : itm.servicesAttachments,
                "clearanceDeductions":itm.clearanceDeductions,


                // 21/07/2023
                "ParamountId" : itm.paramountId === "" ? "0" : itm.paramountId,
                "LastSalary" : String(itm.lastSalary),
                "Incentives" : String(itm.incentives),
                "Sil" : String(itm.sil),
                "ThirteenthMonth" : String(itm.thirteenthMonth),
                "Inclusions" : String(itm.inclusions),
                "GrossPay" : String(itm.grossPay),
                "NetPay" : String(itm.netPay),
                "totalBalance" : String(itm.totalBalance),
                "PeriodCovered" : itm.periodCovered,
                "IsModifiedByARE" : "0",
                "SeparationPay" : String(itm.separationPay),
                // "ReviewStatus" : "0",
                "DateReturnedByPayroll" : "",
                "AreRemarks" : itm.areRemarks,
                "PayrollRemarks" : itm.payrollRemarks,
                "FinanceRemarks" : itm.financeRemarks,
                // "IsClearedSubmit" : "0",
                "IsGenerated" : "0",
                "IsFinalized" : "1",
                // "ContractDateStart"  : typeof itm.contractDateStart === "undefined" ? "" : itm.contractDateStart,
                // "ContractDateEnd"  : typeof itm.contractDateEnd === "undefined" ? "" : itm.contractDateEnd,
                "DateResigned" : typeof itm.dateResigned === "undefined" ? "" : itm.dateResigned,
                "DateHired" : typeof itm.dateHired === "undefined" ? "" : itm.dateHired,
                'BatchPayroll' : "",
                "Adjustment" : typeof itm.adjustment === "undefined" ? "" : String(itm.adjustment),
                "OtherPayroll" : typeof itm.otherPayroll === "undefined" ? "" : String(itm.otherPayroll),
                "Cbu" : typeof itm.cbu === "undefined" ? "" : String(itm.cbu),
                "Dividend" : typeof itm.dividend === "undefined" ? "" : String(itm.dividend),
                "TotalInclusion" : typeof itm.totalInclusion === "undefined" ? "" : String(itm.totalInclusion),
                "IsParent" : itm.isParent,
                "IsChild" : itm.isChild,
                "ParentId" : itm.parentId,
                "SettlementFee" : String(itm.settlementFee),
                
                "DateSubmittedToPayroll" : itm.dateSubmittedToPayroll,
                "ReviewStatus" : "2",
                "IsClearedSubmit" : "1",
                "IsAre" : "1",
                "IsPayroll" : "1",
                "FinancePayoutDate" : itm.financePayoutDate,
                "LastModifiedBy" : this.state.userinfo.fullName
                
                /* END */

            })
            this.setState({ profileId : itm.profileId })
        })
        let param = {
            "IpAddress"             :"0.0.0.0",
            "UserId"                :this.state.userinfo.userId,
            "ClientId"              :this.state.userinfo.clientId,
            "clearance"             :arrLst.filter(x=>x.netPay!=="")
        }
        console.log("finalized payroll param")
        console.log(param)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
        .then(res => {
            let data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   res.data.status=="1" ? "Clearance successfully finalized." : data.message,
                fade        :   true
            });
            this.GetDataClearance();
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    };
    handleClickReturn = async(statusId) => {
        this.setState({isloading:true})
        let date = (this.state.datenow) ? moment(this.state.datenow).format('MM/DD/YYYY') : ""
        let arrLst = []
        // let dedArr = []

        let _slctdData = this.state.clearanceData.filter(f => this.state.selectedRowKeys.some(item => item === f.employeeNo))

        if (_slctdData.length === "0") {
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Please select atleast 1 clearance to process.",
                fade            :   true
            })
            return
        }

        _slctdData.filter(x=>x.employeeNo!=="").forEach(itm => {
            let _clearanceStatus = ""
            if (itm.isClearanceStatus === "2"){
                if (itm.reasonForLeaving === "Dole Cases"){
                    _clearanceStatus = "0"
                }else {
                    _clearanceStatus = String(parseFloat(itm.isClearanceStatus) - 1)
                }
            }else{
                _clearanceStatus = String(parseFloat(itm.isClearanceStatus) - 1)
            }
            arrLst.push({
                "Id" : itm.id,
                "ClientId":itm.clientId,
                "ProfileId":itm.profileId,
                "EmployeeId":itm.employeeId,
                "PayPeriodId":itm.payPeriodId,
                "DateCreated":(itm.dateCreated) ? moment(itm.dateCreated).format('MM/DD/YYYY') : "",
                "ClearanceDateMember":(itm.clearanceDateMember) ? moment(itm.clearanceDateMember).format('MM/DD/YYYY') : "",
                "ResignationDateMember":(itm.resignationDateMember) ? moment(itm.resignationDateMember).format('MM/DD/YYYY') : "",
                "SubmissionDateMember":(itm.submissionDateMember) ? moment(itm.submissionDateMember).format('MM/DD/YYYY') : "",
                "LastWorkingDateMember":(itm.dateEnd) ? moment(itm.dateEnd).format('MM/DD/YYYY') : "",
                "DateSubmitted":(itm.dateSubmitted) ? moment(itm.dateSubmitted).format('MM/DD/YYYY') : "",
                "IsSSSNumberMember":itm.isSSSNumberMember,
                "IsTINNumberMember":itm.isTINNumberMember,
                "IsPHICNumberMember":itm.isPHICNumberMember,
                "IsHDMFNumberMember":itm.isHDMFNumberMember,
                "IsCashCardMember":itm.isCashCardMember,
                "IsValidIdMember":itm.isValidIdMember,
                "IsResignationLetterMember":itm.isResignationLetterMember,
                "IsTurnoverChecklistMember":itm.isTurnoverChecklistMember,
                "IsCOERequestFormMember":itm.isCOERequestFormMember,
                "IsTypeOfCashCardMember":itm.isTypeOfCashCardMember, // cash card mode of payment
                "PaycardTypeIdMember":itm.paycardTypeIdMember,
                "TypeOfCashCardMember":itm.typeOfCashCardMember,
                "CashCardNumberMember":itm.paycardNumber,
                "IsCheckMember":itm.isCheckMember,
                "IsMlhuillierMember":itm.isMlhuillierMember,
                "IsPalawanMember":itm.isPalawanMember,
                "RemarksMember":itm.remarksMember,
                "NotesMember":itm.notesMember,
                "ReasonForLeaving":itm.reasonForLeaving,
                "DateForwardedToAmt": itm.dateForwardedToAmt,
                "DateReceivedFromAmt": itm.dateReceivedFromAmt,
                "IsEligible":itm.isEligible,
                "IsNotEligible":itm.isNotEligible,
                "SssNumber": itm.sssNumber,
                "TinNumber": itm.tinNumber,
                "PhicNumber": itm.phicNumber,
                "HdmfNumber": itm.hdmfNumber,

                "IsClearedClient":itm.isClearedClient,
                "IsEligibleClient":itm.isEligibleClient,
                "IsNonEligibleClient":itm.isNonEligibleClient,
                "RemarksClient":itm.remarksClient,

                "IsClearedRmt":itm.isNonEligibleClient,
                "IsReturnedRmt":itm.isNonEligibleClient,
                "ValidateByRmt":itm.isNonEligibleClient,
                "IsForwardToARERmt":itm.isNonEligibleClient ,
                "IsRetrunedServicesRmt":itm.isNonEligibleClient,
                "IsCheckedByRmt":itm.isCheckedByRmt,
                "RemarksRmt":itm.remarksRmt,

                "RemarksLrd":itm.remarksLrd,
                "ServicesRemarks":itm.servicesRemarks ,

                "IsClearanceStatus": _clearanceStatus,
                "clearanceDetails" :itm.clearanceDetails,

                /* ADDITIONAL */
                "DateUpdatedByClient": date,
                "UpdatedByClient":this.state.userinfo.userId,

                "DateUpdatedByLRD":date,
                "UpdatedByLRD":this.state.userinfo.userId,

                "DateUpdatedByRMT":date,
                "UpdatedByRMT":this.state.userinfo.userId,

                "DateUpdatedByServices":itm.dateUpdatedByServices,
                "UpdatedByServices":this.state.userinfo.userId,

                "DateUpdatedByARE":itm.dateReturnedByARE,
                "UpdatedByARE":itm.isUpdatedByARE,

                "DateUpdatedByFinance":date,
                "UpdatedByFinance":this.state.userinfo.userId,
                "DateSubmittedByStaff":date,
                "SubmittedByStaff": this.state.userinfo.userId,
                "DateSubmittedByLead":date,
                "SubmittedByLead":this.state.userinfo.userId,

                "DateReturnedByClient":itm.dateReturnedByClient,
                "ReturnedByClient":this.state.userinfo.userId,
                "DateReturnedByLRD":itm.dateReturnedByLRD,
                "ReturnedByLRD":this.state.userinfo.userId,
                "DateReturnedByRMT":itm.dateReturnedByRMT,
                "ReturnedByRMT":this.state.userinfo.userId,
                "DateReturnedByServices":itm.dateReturnedByServices,
                "ReturnedByServices":this.state.userinfo.userId,
                "DateReturnedByARE":itm.dateReturnedByARE,
                "ReturnedByARE":this.state.userinfo.userId,
                "DateReturnedByFinance":itm.dateReturnedByFinance,
                "ReturnedByFinance":this.state.userinfo.userId,

                "DateSettle"        : itm.dateSettle,
                "IsSettleWithDole"  :  itm.isSettleWithDole,

                "DateReceivedByAdmin" : itm.dateReceivedByAdmin,
                "IsCash" : itm.isCash,
                "BatchNumber" : itm.batchNumber,
                "servicesAttachments" : itm.servicesAttachments,
                "clearanceDeductions":itm.clearanceDeductions,


                // 21/07/2023
                "ParamountId" : itm.paramountId === "" ? "0" : itm.paramountId,
                "LastSalary" : String(itm.lastSalary),
                "Incentives" : String(itm.incentives),
                "Sil" : String(itm.sil),
                "ThirteenthMonth" : String(itm.thirteenthMonth),
                "Inclusions" : String(itm.inclusions),
                "GrossPay" : String(itm.grossPay),
                "NetPay" : String(itm.netPay),
                "totalBalance" : String(itm.totalBalance),
                "SeparationPay" : String(itm.separationPay),
                "IsModifiedByARE" : "0",
                "DateReturnedByPayroll" : date,
                "AreRemarks" : itm.areRemarks,
                "PayrollRemarks" : itm.payrollRemarks !== "" ? itm.payrollRemarks : this.state.payrollRemarks,
                "FinanceRemarks" : itm.financeRemarks,
                "ReviewStatus" : "0",
                "IsClearedSubmit" : "0",    
                "IsGenerated" : "0",
                "IsFinalized" : "0",
                // "ContractDateStart"  : typeof itm.contractDateStart === "undefined" ? "" : itm.contractDateStart,
                // "ContractDateEnd"  : typeof itm.contractDateEnd === "undefined" ? "" : itm.contractDateEnd,
                "DateResigned" : typeof itm.dateResigned === "undefined" ? "" : itm.dateResigned,
                "DateHired" : typeof itm.dateHired === "undefined" ? "" : itm.dateHired,
                'BatchPayroll' : "",
                "Adjustment" : typeof itm.adjustment === "undefined" ? "" : String(itm.adjustment),
                "OtherPayroll" : typeof itm.otherPayroll === "undefined" ? "" : String(itm.otherPayroll),
                "Cbu" : typeof itm.cbu === "undefined" ? "" : String(itm.cbu),
                "Dividend" : typeof itm.dividend === "undefined" ? "" : String(itm.dividend),
                "TotalInclusion" : String(itm.totalInclusion),
                "IsParent" : itm.isParent,
                "IsChild" : itm.isChild,
                "ParentId" : itm.parentId,
                "SettlementFee" : String(itm.settlementFee),
                
                "DateSubmittedToPayroll" : "",
                "IsAre" : "0",
                "IsPayroll" : "0",
                "FinancePayoutDate" : itm.financePayoutDate,
                "LastModifiedBy" : this.state.userinfo.fullName
                /* END */

            })
            // this.setState({ profileId : itm.profileId })
        })
        let param = {
            "IpAddress"             :"0.0.0.0",
            "UserId"                :this.state.userinfo.userId,
            "ClientId"              :this.state.userinfo.clientId,
            "clearance"             :arrLst
        }
        // // // // // // // // // console.log("Return param")
        // // // // // // // // // console.log(param)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
        .then(res => {
            let data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true
            });
            this.GetDataClearance();
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    };
    handleChangeFromClearanceDate = date =>{
        this.setState({ fromClearanceDate: date, isshow:false})
    };
    handleChangeToClearanceDate = date =>{
        this.setState({ toClearanceDate: date, isshow:false})
    };
    handleChangeFromDateReceived = date =>{
        this.setState({ fromDateReceived: date, isshow:false})
    };
    handleChangeToDateReceived = date =>{
        this.setState({ toDateReceived: date, isshow:false})
    };
    handleChangeFromLastWorkingDate = date =>{
        this.setState({ fromLastWorkingDate: date, isshow:false})
    };
    handleChangeToLastWorkingDate = date =>{
        this.setState({ toLastWorkingDate: date, isshow:false})
    };
    handleChangedPayrollRemarks = (e) =>{
        this.setState({ payrollRemarks : e.target.value.toUpperCase()})
    };
    handleChangeStatus = (e) =>{
        if (e.length === 0){
            this.state.statusId = ""
        } else {
            this.state.statusId = e[0].id
        }
    };
    handleShowBreakdown = (e) =>{
        // // // // // // // // // // // // // // console.log(e.grossPay)
        // // // // // // // // // // // // // console.log(e)
        // let _grossPay = parseFloat(String(e.grossPay).replace(',','').replace(',','').replace(',','').replace(',',''))
        // let _totalBalance = parseFloat(String(e.totalBalance).replace(',','').replace(',','').replace(',','').replace(',',''))
        // if(_grossPay > _totalBalance){
        //     this.setState({
        //         isloading   :   false,
        //         alerttype   :   "Error!",
        //         isshow      :   true,
        //         color       :   "danger",
        //         message     :   "No deductions are required as the amount is positive",
        //         fade        :   true
        //     })
        // } else {
        //     this.setState({openModal:true})
        //     this.child.initialize(e)
        // }
    };

    // dev express

    onCellPrepared =(e)=>{// cell customize (css)
        // // // // // // // // // console.log("e")
        // // // // // // // // // console.log(e)

        e.cellElement.style.cssText = "text-transform: lowercase;"
        if(e.rowType==="data"){
            e.cellElement.align = "center"
            if(e.column.dataField ==="CBU" ){
                e.cellElement.align = "right"
                e.cellElement.previousElementSibling.align = "right"
                e.cellElement.style.cssText = "text-align: left;width:300px"
            } 
            else if(e.column.dataField ==="SIL" ){
                e.cellElement.align = "right"
                e.cellElement.previousElementSibling.align = "right"
                e.cellElement.style.cssText = "text-align: left;width:450px"
            }

            e.values.forEach((x,idx)=>{
                e.values[idx] = e.values[idx] === undefined ? 0 : e.values[idx]
            })

            //e.values[e.column.columnIndex] = "0"
        }
        if(e.rowType==="header"){
            if(e.column.dataField ==="CBU" ){
                e.cellElement.style.cssText = "text-align: left;width:300px"
            }
            else if(e.column.dataField ==="SIL" ){
                e.cellElement.style.cssText = "text-align: left;width:350px"
            }
            e.cellElement.align = "center"
            e.cellElement.textContent = e.cellElement.textContent !==undefined ? e.cellElement.textContent.toUpperCase() : e.cellElement.textContent
        }
        if(e.rowType==="totalFooter"){

            
            if  ( e.column.dataField!=="batchNumber" &&
                    e.column.dataField!=="clientName" &&
                    e.column.dataField!=="employeeNo" &&
                    e.column.dataField!=="employeeName" &&
                    e.column.dataField!=="paycardNumber" &&
                    e.column.dataField!=="dateEnd" &&
                    e.column.dataField!=="hc" &&
                    e.column.dataField!=="reasonForLeaving" &&
                    e.column.dataField!=="" &&
                    e.column.dataField!==undefined
                ){
                
                e.summaryItems =[{
                    column:e.column.dataField,
                    summaryType:"sum",
                    alignment:"right",
                    displayFormat: "{0}"
                }]
                e.totalItem.summaryCells[e.columnIndex] = [{
                    // // // // // // // // // console.log(e.column.dataField)
                    column:e.column.dataField,
                    summaryType:"sum",
                    alignment:"right",
                    displayFormat: "{0}"
                }]
                
                
                e.cellElement.innerText = parseFloat(this.state.clearanceData.reduce(( total, currentValue) => total = parseFloat(total) + parseFloat(String(currentValue[e.column.dataField]).replace(',','')),0)).toFixed(2);
                e.cellElement.textContent = parseFloat(this.state.clearanceData.reduce(( total, currentValue) => total = parseFloat(total) + parseFloat(String(currentValue[e.column.dataField]).replace(',','')),0)).toFixed(2);
            }
            //e.cellElement.style.backgroundColor = 'red'
            e.cellElement.style.alignContent = 'right'
            e.cellElement.style.cssText = "text-align: right"
            e.cellElement.align = "right"
            // // // // // // // console.log("e.totalItem.summaryCells[e.columnIndex]")
            // // // // // // // console.log(e.totalItem.summaryCells[e.columnIndex])
        }
    };
    handleSelectedItems =(e)=>{ // select items
        if(e.fullName === 'selectedRowKeys') {
            this.setState({
                selectedRowKeys: e.value
            })
        }
    };
    onFocusedCellChanged = (e) =>{
        // // // // // // // // // // // console.log("cell changed")
        // // // // // // // // // // // console.log(e)
    };
    onFocusedCellChanging = (e) =>{ // cell modification
        var nf = new Intl.NumberFormat();
        // e.rows.cell[0].column.allowEditing = false
        e.rows.forEach(itm =>{
            if (e.newRowIndex === itm.rowIndex){
                let tmpgross = itm.data.grossPay===""?"0":itm.data.grossPay

                let grosstot = parseFloat(String(tmpgross).replace(",","").replace(",","").replace(",","").replace(",",""))
                let totalBalanceTmp = parseFloat(String(itm.data.totalBalance).replace(",","").replace(",","").replace(",","").replace(",",""))


                if(itm.data.settlementFee!==""){

                    itm.data.grossPay = parseFloat(itm.data.grossPay ) + parseFloat(itm.data.settlementFee)

                    // let netpayTmp = parseFloat(grosstot).toFixed(2) - parseFloat(totalBalanceTmp).toFixed(2)
                    // itm.data.netPay =  parseFloat(netpayTmp).toFixed(2)
                    // // // // // // // // // // console.log("itm.data.netPay")
                    // // // // // // // // // // console.log(itm.data.netPay)

                    // itm.data.inclusions = parseFloat(String(itm.data.grossPay).replace(',','')) + parseFloat(itm.data.totalInclusion)

                }
                // if(itm.data.adjustment !== ""){
                //     let test1 = parseFloat(itm.data.adjustment) + parseFloat(itm.data.lastSalary)  + parseFloat(itm.data.cbu) + parseFloat(itm.data.dividend)
                //     itm.data.grossPay = parseFloat(test1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

                //     let netpayTmp = parseFloat(grosstot).toFixed(2) - parseFloat(totalBalanceTmp).toFixed(2)
                //     itm.data.netPay =  parseFloat(netpayTmp).toFixed(2)

                //     itm.data.inclusions = parseFloat(String(itm.data.grossPay).replace(',','')) + parseFloat(itm.data.totalInclusion)
                // }
                // if(itm.data.otherPayroll !== ""){
                //     let test1 = parseFloat(itm.data.otherPayroll) + parseFloat(itm.data.lastSalary) + parseFloat(itm.data.adjustment) + parseFloat(itm.data.cbu) + parseFloat(itm.data.dividend)
                //     itm.data.grossPay = parseFloat(test1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

                //     let netpayTmp = parseFloat(grosstot).toFixed(2) - parseFloat(totalBalanceTmp).toFixed(2)
                //     itm.data.netPay =  parseFloat(netpayTmp).toFixed(2)

                //     itm.data.inclusions = parseFloat(String(itm.data.grossPay).replace(',','')) + parseFloat(itm.data.totalInclusion)
                // }
                // if(itm.data.thirteenthMonth !== ""){
                //     let test1 = parseFloat(itm.data.adjustment) + parseFloat(itm.data.otherPayroll) + parseFloat(itm.data.thirteenthMonth) + parseFloat(itm.data.lastSalary)  + parseFloat(itm.data.cbu) + parseFloat(itm.data.dividend)
                //     itm.data.grossPay = parseFloat(test1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

                //     let netpayTmp = parseFloat(grosstot).toFixed(2) - parseFloat(totalBalanceTmp).toFixed(2)
                //     itm.data.netPay =  parseFloat(netpayTmp).toFixed(2)

                //     itm.data.inclusions = parseFloat(String(itm.data.grossPay).replace(',','')) + parseFloat(itm.data.totalInclusion)
                // }
                // if(itm.data.sil !== ""){
                //     itm.data.grossPay = parseFloat(itm.data.sil)  + parseFloat(itm.data.lastSalary) + parseFloat(itm.data.thirteenthMonth) + parseFloat(itm.data.adjustment) + parseFloat(itm.data.otherPayroll) + parseFloat(itm.data.cbu) + parseFloat(itm.data.dividend)

                //     let netpayTmp = parseFloat(grosstot).toFixed(2) - parseFloat(totalBalanceTmp).toFixed(2)
                //     itm.data.netPay =  parseFloat(netpayTmp).toFixed(2)

                //     itm.data.inclusions = parseFloat(String(itm.data.grossPay).replace(',','')) + parseFloat(itm.data.totalInclusion)
                // }
                // if(itm.data.separationPay !== ""){
                //     itm.data.grossPay = parseFloat(itm.data.separationPay) + parseFloat(itm.data.lastSalary) + parseFloat(itm.data.sil) + parseFloat(itm.data.thirteenthMonth) + parseFloat(itm.data.adjustment) + parseFloat(itm.data.otherPayroll)  + parseFloat(itm.data.cbu) + parseFloat(itm.data.dividend)

                //     let netpayTmp = parseFloat(grosstot).toFixed(2) - parseFloat(totalBalanceTmp).toFixed(2)
                //     itm.data.netPay =  parseFloat(netpayTmp).toFixed(2)

                //     itm.data.inclusions = parseFloat(String(itm.data.grossPay).replace(',','')) + parseFloat(itm.data.totalInclusion)
                // }
                // if(itm.data.incentives !== ""){
                //     itm.data.grossPay = parseFloat(itm.data.incentives) + parseFloat(itm.data.lastSalary) + parseFloat(itm.data.separationPay) + parseFloat(itm.data.sil) + parseFloat(itm.data.thirteenthMonth) + parseFloat(itm.data.adjustment) + parseFloat(itm.data.otherPayroll)   + parseFloat(itm.data.cbu) + parseFloat(itm.data.dividend)

                //     let netpayTmp = parseFloat(grosstot).toFixed(2) - parseFloat(totalBalanceTmp).toFixed(2)
                //     itm.data.netPay =  parseFloat(netpayTmp).toFixed(2)

                //     itm.data.inclusions = parseFloat(String(itm.data.grossPay).replace(',','')) + parseFloat(itm.data.totalInclusion)
                // }

                // this.state.paycardMaintenance.forEach(pc=>{
                //     if(itm.data.paycardTypeIdMember===pc.id){

                //         itm.values[85] = "20222"
                //         e.columns.forEach((col,idx)=>{
                //             if(col.name===pc.name)
                //             {
                //                 // let tmpnetpay = itm.data.netPay
                //                 this.state.clearanceData.find(x=>x.id===itm.data.id)[pc.name]= parseFloat(String(itm.data.netPay).replace(',',''))
                //             }
                //         })
                //     }
                // })
            }
        })
    };
    customizeColumns = (cols) => {
        // // // // // // // // // // // // // // console.log("cols")
        // // // // // // // // // // // // // // console.log(cols)
        let colHide = [
            "id","areRemarks","clearanceDateMember","clearanceDetails","clearanceDeductions","clearanceStatus","clearedSubmit","clientId","closingRemarks","createdBy","createdByName","createdDate","cycleDays","dateCreated",
            "dateOfBirth","dateReceivedByAdmin","dateReceivedFromAmt","dateReturnedByARE","dateReturnedByClient","dateReturnedByFinance","dateReturnedByLRD","dateReturnedByPayroll","dateReturnedByRMT","dateReturnedByServices",
            "dateSettle","dateStart","dateSubmitted","dateSubmittedByLead","dateSubmittedByStaff","dateUpdatedByARE","dateUpdatedByClient","dateUpdatedByFinance","dateUpdatedByLRD","dateUpdatedByRMT","dateUpdatedByServices",
            "employeeId","finalized","financeRemarks","firstName","generation","hdmfNumber","id","isCOERequestFormMember","isCash","isCashCardMember","isCheckMember","isCheckedByRmt","isClearanceStatus","isClearedClient","isClearedRmt",
            "isClearedSubmit","isDeleted","isEligible","isEligibleClient","isFinalized","isForwardToARERmt","isGenerated","isHDMFNumberMember","isModified","isModifiedByARE","isNonEligibleClient","isNotEligible","isPHICNumberMember",
            "isResignationLetterMember","isRetrunedServicesRmt","isReturnedRmt","isSSSNumberMember","isSettleWithDole","isTINNumberMember","isTurnoverChecklistMember","isTypeOfCashCardMember","isValidIdMember","lastName","locationId",
            "locationName","middleName","modifiedBy","modifiedByName","modifiedDate","notesMember","paramountId","payPeriodId","paycardTypeIdMember","payrollPeriods","payrollRemarks","periodCovered","phicNumber","positionId","positionName",
            "profileId","lastWorkingDateMember","reasonForLeavingId","remarksClient","remarksLrd","remarksMember","remarksRmt","resignationDateMember","returnedByARE","returnedByClient",'returnedByFinance',"returnedByLRD","returnedByRMT",
            "returnedByServices","reviewStatus","reviewStatusResults","rowIndex","servicesAttachments","servicesRemarks","sssNumber","submissionDateMember","submitted","submittedByLead","submittedByStaff","suffixName","timeStamp",
            "tinNumber","updatedByARE","updatedByClient","updatedByFinance","updatedByLRD","updatedByRMT","updatedByServices","validateByRmt","clearanceDeductions","isUpdatedByClient","isUpdatedByLRD","isUpdatedByRMT",
            "isUpdatedByServices","isUpdatedByARE","isUpdatedByFinance","isReturnedByClient","isReturnedByLRD","isReturnedByRMT","isReturnedByServices","isReturnedByARE","isReturnedByFinance","isSubmittedByStaff","isSubmittedByLead",
            "dateForwardedToAmt","isPalawanMember","isMlhuillierMember","typeOfCashCardMember","incentives","batchPayroll", "contractDateStart","contractDateEnd","dateHired","isParent","isChild","parentId","dateSubmittedToPayroll","isAre","isPayroll",
            "financePayoutDate","lastModifiedBy"
        ]
        let dedCol = []
        let incCol = []
        let pcCol = []
        let totNetpay = {}
        let totDed = {}
        let inc = {}
        let totGross ={}
        let noAtm = {}
        let totIdx = 0
        let thrtntmnth = {}

        cols.forEach((fld,idx) => {
            // // // // // // // // // // // console.log('fld')
            // // // // // // // // // // // console.log(fld)
            if(colHide.includes(fld.dataField)){
                fld.visible = false
            } 
            else {
                fld.visible = true
            }
            // // // // // // // // // // // // // console.log("fld.caption")
            // // // // // // // // // // // // // console.log(typeof fld.caption === "undefined")
            fld.caption = typeof fld.caption === "undefined" ? "" :  (fld.caption).toUpperCase()

            if(fld.dataField==="thirteenthMonth"){
                // delete cols[idx];
                // totIdx = idx
                fld.caption = "13TH MONTH"
                thrtntmnth = fld
            }
            if(fld.dataField==="settlementFee"){
                // delete cols[idx];
                // totIdx = idx
                fld.caption = "FINANCIAL ASSISTANCE"
                thrtntmnth = fld
            }
            if(fld.dataField==="inclusions"){
                delete cols[idx];
                totIdx = idx
                fld.caption = "TOTAL GROSS"
                totGross = fld
            }
            if(fld.dataField==="totalInclusion"){
                delete cols[idx];
                totIdx = idx
                fld.caption = "TOTAL INCLUSION"
                inc = fld
            }
            if(fld.dataField==="noAtm"){
                delete cols[idx];
                totIdx = idx
                fld.caption = "NO ATM"
                noAtm = fld
            }
            if(fld.dataField==="netPay"){
                delete cols[idx];
                totIdx = idx
                fld.caption = "NET PAY"
                totNetpay = fld
            }
            if(fld.dataField==="totalBalance"){
                delete cols[idx];
                totIdx = idx
                fld.caption = "TOTAL DEDUCTIONS"
                totDed = fld
            }

            if(this.state.deductionMaintenance.filter(x=>x.name === fld.dataField).length>0){
                    //fld.visible = false
                    delete cols[idx];
                    dedCol.push(fld.dataField)
                    // // // // // console.log("fld.dataField")
                    // // // // // console.log(fld.dataField)
                    //cols.splice(idx,1)

            }
            else if(this.state.inclusionMaintenance.filter(x=>x.name === fld.dataField).length>0){
                // if(fld.dataField === this.state.inclusionMaintenance[0].InclusionName){
                //     // // // // // // // // // // // // // console.log(idx)
                // }
                delete cols[idx];
                incCol.push(fld.dataField)
                //cols.splice(idx,1)
            }
            else if(this.state.paycardMaintenance.filter(x=>x.name === fld.dataField).length>0){
                // // // // // // // // // // console.log("this.state.paycardMaintenance.filter(x=>x.name === fld.dataField)")
                // // // // // // // // // // console.log(this.state.paycardMaintenance.filter(x=>x.name === fld.dataField))
                // if(fld.dataField === this.state.inclusionMaintenance[0].InclusionName){
                //     // // // // // // // // // // // // // console.log(idx)
                // }
                delete cols[idx];
                pcCol.push(fld.dataField)
                //cols.splice(idx,1)
            }

        });
        cols.push({
            caption : "INCLUSIONS",
            columns : incCol
        })
        cols.push(inc)
        cols.push(totGross)
        cols.push({
            caption : "DEDUCTIONS",
            columns : dedCol
        })
        cols.push(totDed)
        cols.push(totNetpay)
        // console.log("cols")
        // console.log(cols)
        cols.push({
            caption:"ACTION",
            cellTemplate : "cellContent"

        })
        cols.push({
            caption : "TAG AMOUNT OF PAYCARD",
            columns : pcCol
        })
        cols.push(noAtm)
        // cols.push({caption:"TOTAL INCLUSIONS"})
    };
    onExporting = (e)=> {
        // // // // // // // // // // console.log("e export")
        // // // // // // // // // // console.log(e)
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('PayrollModule');
        // console.log("workbook")
        // console.log(workbook)
        // console.log("e")
        // console.log(e)

        exportDataGrid({
            component: e.component,
            worksheet,
            topLeftCell: { row: 4, column: 1 },
        }).then((cellRange,component) => {
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PayrollModule.xlsx');
            });
        });
    };
    calculateSelectedRow (options) {
        // // // // // // // // // console.log("options")
        // // // // // // // // // console.log(options)
    };
    // contentReady(e) {
    //     if (!e.component.getSelectedRowKeys().length) { e.component.selectRowsByIndexes(0); }
    // }
    
    // selectionChanged(e) {
    //     e.component.collapseAll(-1);
    //     e.component.expandRow(e.currentSelectedRowKeys[0]);
    // }
    // rowEventHandler = (e) =>{
    //     // // // // // // // console.log("event handler")
    //     // // // // // // // console.log(e)
    // }
    // onRowClick = (e) =>{ 
    //     var key = e.component.getKeyByRowIndex(e.rowIndex);  
    //     var expanded = e.component.isRowExpanded(key);  
    //     if (expanded) {  
    //         e.component.collapseRow(key);  
    //     }  
    //     else {  
    //         e.component.expandRow(key);  
    //     }  
    // };




    // Settlement fee uploading
    
    handleModalShow = (e) =>{
        // // console.log("this.state.dataClearanceTmp")
        // // console.log(this.state.dataClearanceTmp)
        this.setState({openModal:true})
        this.child.initialize(this.state.dataClearanceTmp)
    };
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.GetSubsidiaryLedgerStaging()
        // this.GetDataClearance()
    };
    render() {
        var nf = new Intl.NumberFormat();
        let { navigate } = this.state;
        if(navigate){
            return <Redirect to="/clearance" push={true}/>
        };

        let { SearchBar, ClearSearchButton } = Search;

        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>CLEARANCE &#62;&#62; PAYROLL MODULE</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div>
                                    {this.state.message}
                                </Alert>
                                <div>
                                    <Accordion className="mt-2">
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{color: "#FFFFFF"}}>
                                                    CLICK TO SEARCH
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        CLIENT
                                                        </Form.Label>
                                                        <Col>
                                                            <Typeahead
                                                                ref={this.client}
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangeClient}
                                                                options={this.state.clientDDL}
                                                                placeholder='SELECT CLIENT'
                                                                //selected={[this.state.clientName]}
                                                            />
                                                        </Col>
                                                        <Col sm="1">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        EMPLOYEE
                                                        </Form.Label>
                                                        <Col>
                                                            <Typeahead
                                                                ref={this.employee}
                                                                labelKey='employeeName'
                                                                id="basic-example"
                                                                onChange={this.handleChangeEmployee}
                                                                options={this.state.sortEmp}
                                                                placeholder='SELECT EMPLOYEE'
                                                                //defaultSelected={[this.state.employeeName]}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        REASON FOR LEAVING
                                                        </Form.Label>
                                                        <Col>
                                                            <Typeahead
                                                                ref={this.refReason}
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangedReason}
                                                                options={this.state.reasonDDL}
                                                                placeholder='Select reason for leaving'
                                                                value = {this.state.reason}

                                                            />
                                                        </Col>
                                                        <Col sm="1">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            Status
                                                        </Form.Label>
                                                        <Col>
                                                            <Typeahead
                                                                ref={this.name}
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangeStatus}
                                                                options={this.state.statusDDL}
                                                                placeholder='SELECT STATUS'
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        From Date Created
                                                        </Form.Label>
                                                        <Col>
                                                            <DatePicker
                                                                ref='clearanceDate'
                                                                selected={this.state.fromDateCreated}
                                                                onChange={this.handleChangeFromDateCreated}
                                                                value={this.props.fromDateCreated}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                // placeholder='SELECT CLEARANCE DATE'
                                                                // wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                        <Col sm="1">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        To Date Created
                                                        </Form.Label>
                                                        <Col>
                                                            <DatePicker
                                                                ref='clearanceDate'
                                                                selected={this.state.toDateCreated}
                                                                onChange={this.handleChangeToDateCreated}
                                                                value={this.props.toDateCreated}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                // placeholder='SELECT CLEARANCE DATE'
                                                                // wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        From Clearance Date
                                                        </Form.Label>
                                                        <Col>
                                                            <DatePicker
                                                                ref='clearanceDate'
                                                                selected={this.state.fromClearanceDate}
                                                                onChange={this.handleChangeFromClearanceDate}
                                                                value={this.props.fromClearanceDate}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                // placeholder='SELECT CLEARANCE DATE'
                                                                // wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                        <Col sm="1">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        To Clearance Date
                                                        </Form.Label>
                                                        <Col>
                                                            <DatePicker
                                                                ref='clearanceDate'
                                                                selected={this.state.toClearanceDate}
                                                                onChange={this.handleChangeToClearanceDate}
                                                                value={this.props.toClearanceDate}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                // placeholder='SELECT CLEARANCE DATE'
                                                                // wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        From Date Received
                                                        </Form.Label>
                                                        <Col>
                                                            <DatePicker
                                                                ref='clearanceDate'
                                                                selected={this.state.fromDateReceived}
                                                                onChange={this.handleChangeFromDateReceived}
                                                                value={this.props.fromDateReceived}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                // placeholder='SELECT CLEARANCE DATE'
                                                                // wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                        <Col sm="1">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        To Date Received
                                                        </Form.Label>
                                                        <Col>
                                                            <DatePicker
                                                                ref='clearanceDate'
                                                                selected={this.state.toDateReceived}
                                                                onChange={this.handleChangeToDateReceived}
                                                                value={this.props.toDateReceived}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                // placeholder='SELECT CLEARANCE DATE'
                                                                // wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        From Last Working Date
                                                        </Form.Label>
                                                        <Col>
                                                            <DatePicker
                                                                ref='clearanceDate'
                                                                selected={this.state.fromLastWorkingDate}
                                                                onChange={this.handleChangeFromLastWorkingDate}
                                                                value={this.props.fromLastWorkingDate}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                // placeholder='SELECT CLEARANCE DATE'
                                                                // wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                        <Col sm="1">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        To Last Working Date
                                                        </Form.Label>
                                                        <Col>
                                                            <DatePicker
                                                                ref='clearanceDate'
                                                                selected={this.state.toLastWorkingDate}
                                                                onChange={this.handleChangeToLastWorkingDate}
                                                                value={this.props.toLastWorkingDate}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                // placeholder='SELECT CLEARANCE DATE'
                                                                // wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail" className='mb-5'>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            RESiGNATION DATE
                                                        </Form.Label>
                                                        <Col>
                                                            <DatePicker
                                                                ref='resignationDate'
                                                                selected={this.state.resignationDate}
                                                                onChange={this.handleChangeResignationDate}
                                                                minDate={this.minDate}
                                                                value={this.props.resignationDate}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                // placeholder='SELECT RESIGNATION DATE'
                                                                wrapperClassName="datepicker"
                                                            />
                                                        </Col>
                                                        <Col sm="1">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            BATCH NUMBER
                                                        </Form.Label>
                                                        <Col>
                                                            <Typeahead
                                                                ref={this.refBatch}
                                                                labelKey='batchNumber'
                                                                id="basic-example"
                                                                onChange={this.handleChangedBatchNumber}
                                                                options={this.state.filteredBatch}
                                                                placeholder='Select Batch number'
                                                                // multiple
                                                                // clearButton
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                <div style={{height : "50px"}}></div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </div>
                                <ButtonToolbar className="mt-3">
                                    <Button variant="primary" className="ml-auto noser-button-mr1 noser-button" onClick={ this.handleClickRefresh }>
                                        refresh
                                    </Button>
                                    <Button onClick={ this.handleModalShow } className="noser-button-mr1 noser-button" variant="success" >
                                        Upload financial Assistance
                                    </Button>
                                    <Button variant="primary" className="noser-button" onClick={ this.handleClickSearch }>
                                        Search
                                    </Button>
                                    {/* <Button variant="info" className="noser-button" >
                                        <CSVLink style={{ color : "white" }} onClick ={this.GridExcellReports} filename= "Batch_Clearance.xls" data={this.state.gridDataExcel}>Export</CSVLink>
                                    </Button> */}
                                </ButtonToolbar>
                                <div className="mt-2 mb-4" style={{ overflow: 'auto'}}>

                                    <Card.Header>PAYROLL</Card.Header>
                                    <DataGrid
                                        // ref={this.dataGridRef}
                                        customizeColumns={this.customizeColumns}
                                        // onCellPrepared={this.onCellPrepared}
                                        onFocusedCellChanging={this.onFocusedCellChanging}
                                        // onFocusedCellChanged={this.onFocusedCellChanged}
                                        dataSource={this.state.clearanceData}
                                        // dataSource={this.state.dataClearanceTmp}
                                        columnMinWidth={80}
                                        keyExpr="employeeNo"
                                        showBorders={true}
                                        columnAutoWidth={true}
                                        allowColumnResizing={true}
                                        showRowLines={true}
                                        focusedRowEnabled={true}
                                        onOptionChanged={this.handleSelectedItems}
                                        onExporting={this.onExporting}
                                        noDataText = "No record found."
                                        // format = 'currency'

                                        // rowCollapsed = {this.rowEventHandler}
                                        // allowColumnReordering={true}
                                        // onRowClick={this.onRowClick}
                                    >
                                        <Paging defaultPageSize="10" />
                                        <Export enabled={true} allowExportSelectedData={true}/>
                                        <Template name={"cellContent"} component={NButton} />
                                        <Selection mode='multiple' selectAllMode='allPages' showCheckBoxesMode="always" /> 
                                        <Sorting mode="none"/>
                                        <Editing  mode="cell" allowUpdating={false} />
                                        <MasterDetail
                                            enabled={true}
                                            component={DetailTemplate}
                                        />
                                        <Summary calculateCustomSummary={this.calculateSelectedRow}>
                                           {/*  <TotalItem
                                                // column={this.state.clearanceData}
                                                column="netPay"
                                                summaryType="sum"
                                                showInColumn=""
                                                displayFormat="{0}"
                                                alignment="right"
                                            />
                                            <TotalItem
                                                // column={this.state.clearanceData}
                                                column="cbu"
                                                summaryType="sum"
                                                showInColumn=""
                                                displayFormat="{0}"
                                                alignment="right"
                                            />
                                            <TotalItem
                                                // column={this.state.clearanceData}
                                                column="dividend"
                                                summaryType="sum"
                                                showInColumn=""
                                                displayFormat="{0}"
                                                alignment="right"
                                            />
                                            <TotalItem
                                                // column={this.state.clearanceData}
                                                column="lastSalary"
                                                summaryType="sum"
                                                showInColumn=""
                                                displayFormat="{0}"
                                                alignment="right"
                                            />
                                            <TotalItem
                                                // column={this.state.clearanceData}
                                                column="adjustment"
                                                summaryType="sum"
                                                showInColumn=""
                                                displayFormat="{0}"
                                                alignment="right"
                                            />
                                            <TotalItem
                                                // column={this.state.clearanceData}
                                                column="otherPayroll"
                                                summaryType="sum"
                                                showInColumn=""
                                                displayFormat="{0}"
                                                alignment="right"
                                            />
                                            <TotalItem
                                                // column={this.state.clearanceData}
                                                column="thirteenthMonth"
                                                summaryType="sum"
                                                showInColumn=""
                                                displayFormat="{0}"
                                                alignment="right"
                                            />
                                            <TotalItem
                                                // column={this.state.clearanceData}
                                                column="sil"
                                                summaryType="sum"
                                                showInColumn=""
                                                displayFormat="{0}"
                                                alignment="right"
                                            />
                                            <TotalItem
                                                // column={this.state.clearanceData}
                                                column="separationPay"
                                                summaryType="sum"
                                                showInColumn=""
                                                displayFormat="{0}"
                                                alignment="right"
                                            />
                                            <TotalItem
                                                // column={this.state.clearanceData}
                                                column="incentives"
                                                summaryType="sum"
                                                showInColumn=""
                                                displayFormat="{0}"
                                                alignment="right"
                                            />
                                            <TotalItem
                                                // column={this.state.clearanceData}
                                                column="grossPay"
                                                summaryType="sum"
                                                showInColumn=""
                                                displayFormat="{0}"
                                                alignment="right"
                                            />
                                            <TotalItem
                                                // column={this.state.clearanceData}
                                                column="inclusions"
                                                summaryType="sum"
                                                showInColumn=""
                                                displayFormat="{0}"
                                                alignment="right"
                                            />
                                            <TotalItem
                                                // column={this.state.clearanceData}
                                                column="totalInclusion"
                                                summaryType="sum"
                                                showInColumn=""
                                                displayFormat="{0}"
                                                alignment="right"
                                            />
                                            <TotalItem
                                                // column={this.state.clearanceData}
                                                column="totalBalance"
                                                summaryType="sum"
                                                showInColumn=""
                                                displayFormat="{0}"
                                                alignment="right"
                                            />
                                            <TotalItem
                                                // column={this.state.clearanceData}
                                                column="noAtm"
                                                summaryType="sum"
                                                showInColumn=""
                                                displayFormat="{0}"
                                                alignment="right"
                                            />
                                            <TotalItem
                                                // column={this.state.clearanceData}
                                                column="INCLUSIONS"
                                                summaryType="sum"
                                                showInColumn=""
                                                displayFormat="{0}"
                                                alignment="right"
                                            />
                                            <TotalItem
                                                // column={this.state.clearanceData}
                                                column="DEDUCTIONS"
                                                summaryType="sum"
                                                showInColumn=""
                                                displayFormat="{0}"
                                                alignment="right"
                                            />*/}
                                        </Summary> 
                                        {/* <MasterDetail enabled={false} render={renderDetail} /> */}
                                    </DataGrid>
                                </div>
                                <hr style={{marginTop : "2%"}} />
                                <ButtonToolbar sm={12}>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Remarks
                                    </Form.Label>
                                    <Col sm='4'>
                                        <Form.Control
                                            ref="payrollRemarks"
                                            name="payrollRemarks"
                                            onChange={this.handleChangedPayrollRemarks}
                                            autoComplete="off"
                                            value={this.state.payrollRemarks}
                                        />
                                    </Col>
                                    <Button className="ml-auto noser-button-mr1 noser-button" variant="info" onClick={ this.handleClickFinalized }>
                                        finalize
                                    </Button>
                                    <Button onClick={this.handleClickReturn } className="noser-button"  variant="info">
                                        return
                                    </Button>
                                </ButtonToolbar>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
                <SettlementModal 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div>
        );
    }
}

export default BatchClearancePayroll