import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../noser-hris-component';

class CreateHoliday extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            regionId    :   '',
            name        :   '',
            disableBtn  :   true,
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            regionId    :   '',
            regionName  :   '',
            provinceId  :   '',
            name        :   '',
            disableBtn  :   true,
            clientListDDL   :   [],
            holidayTypeListDDL: [],
            coverageListDDL :   [
                {"name":"National","id":"National"},
                {"name":"Regional","id":"Regional"},
                {"name":"Provincial","id":"Provincial"},
                {"name":"City/Town","id":"City"}
            ],
            rpcListDDL      :   [],
            holidayTypeId   :   "",
            holidayName     :   "",
            coverageId      :   "",
            locationId      :   "0",
            clientId        :   "0",
            holidayDate     :   new Date(),
            clientspecific  :   true
        })
        this.GetHolidayTypes()
    }
    GetClients = async (e) => {
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            let data = res.data
            console.log(data)
            this.setState({isloading:false,clientListDDL : res.data.clients ? res.data.clients : []});
        })
        .catch(error=>{
            this.setState(
            { 
                isLoading:false,
                alertType:"Error! ", 
                isShow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })  
        })
    }
    GetHolidayTypes = async () =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId
        };

        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetHolidayTypes",  params)
        .then(res => {
            const data = res.data;
            if(data.status==="1"){
                this.setState({holidayTypeListDDL: data.holidayTypes})
                this.GetClients()       
            }
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    } 

    handleSubmit = (e) =>{
        this.setState({isloading:true})

        if(this.state.holidayTypeId===''){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select holiday type.",
                fade        :   true
            })
            return 
        }

        if(this.state.holidayName===''){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter holiday name.",
                fade        :   true
            })
            return 
        }

        if(this.state.coverageId===''){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select coverage.",
                fade        :   true
            })
            return 
        }

        if(this.state.coverageId!=='National' && this.state.locationId==='0'){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select region/province/city.",
                fade        :   true
            })
            return 
        }

        if(!this.state.clientspecific && this.state.clientId=='0'){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client name.",
                fade        :   true
            })
            return 
        }
        const param = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "Coverage":this.state.coverageId.toString().toUpperCase(),
            "LocationId": (this.state.coverageId=="National") ? "0" : this.state.locationId,
            "HolidayTypeId":this.state.holidayTypeId,
            "HolidayName":this.state.holidayName,
            "Date":this.formatDate(this.state.holidayDate)
        }
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/AddHoliday",  param
            )
            .then(res => {
                const data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true,
                });
                if(data.status==="1"){
                    this.refs.holidayType.clear()
                    this.refs.coverage.clear() 
                    this.refs.location.clear() 
                    this.refs.clients.clear()
                    this.setState({holidayName:'',locationId:'',clientId:'0',holidayTypeId:'',coverageId:'',clientspecific:true})
                }
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true,
                    name        :   ''
                })
            })
    }
    handleModalClose = () => {
        this.props.onHide();            
    }
    handleChangeHolidayType = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        this.state.holidayTypeId = e[0].length===0 ? "" : e[0].id
    }
    handleChangeHoliday = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        this.setState({[e.target.name]: e.target.value})
    }
    handleChangeCoverage = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length==0){
            this.state.coverageId = ""
            return
        }
        
        let url = ""
        let coverage = e[0].name
        this.state.coverageId = e[0].id
        if(coverage=="National"){
            this.setState({ rpcList: [{"name":"","id":""}],isloading:false });
            return
        }
        if(coverage=="Regional")
            url = AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetRegions";
        if(coverage=="Provincial")
            url = AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetProvinces";
        if(coverage=="City/Town")
            url = AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetCities";
        
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":""
        };
    
        axios
            .post(url,  params)
            .then(res => {
                const data = res.data;
                if(coverage=="Regional")
                    this.setState({ rpcListDDL: data.regions }); 
                if(coverage=="Provincial")
                    this.setState({ rpcListDDL: data.provinces }); 
                if(coverage=="City/Town")
                    this.setState({ rpcListDDL: data.cities }); 
            }) 
            this.setState({isloading:false})
    }
    handleChangeLocation = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        this.state.locationId = e[0].length===0 ? "0" : e[0].id
    }
    handleChangeHolidayDate = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        this.setState({holidayDate: e})
    }
    handleChangeCheckbox = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        this.state.clientspecific = e.target.checked
        this.state.clientId = e.target.checked ? "0" : this.state.clientId
    }
    handleChangeClient = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        this.state.clientId = e[0].length===0 ? "0" : e[0].id
    }
    formatDate(date) {
        var day = date.getDate();
        var month = date.getMonth()+1;
        var year = date.getFullYear();
        return month + '/' + day + '/' + year;
    }
    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        CREATE HOLIDAY
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Typeahead
                                    ref='holidayType'
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangeHolidayType}
                                    options={this.state.holidayTypeListDDL}
                                    placeholder="Select holiday type"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='holidayName'
                                    type="text" 
                                    placeholder="Enter holiday name" 
                                    onChange={this.handleChangeHoliday} 
                                    autoComplete="off" 
                                    value={this.state.holidayName}
                                    
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Typeahead
                                    ref='coverage'
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangeCoverage}
                                    options={this.state.coverageListDDL}
                                    placeholder="Select coverage"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Typeahead
                                    ref='location'
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangeLocation}
                                    options={this.state.rpcListDDL}
                                    placeholder="Select [Region,Province,City]"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <DatePicker
                                    ref='holidayDate'
                                    selected={this.state.holidayDate}
                                    onChange={this.handleChangeHolidayDate}
                                    minDate={this.minDate}
                                    value={this.props.holidayDate}
                                    dateFormat={"MM/dd/yyyy"}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Check
                                    onChange={ this.handleChangeCheckbox }
                                    type="checkbox" 
                                    name="clientspecific"
                                    label="Apply to All" 
                                    defaultChecked={this.state.clientspecific}
                                    value={this.state.clientspecific}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Typeahead
                                    labelKey='name'
                                    name="client"
                                    onChange={this.handleChangeClient}
                                    options={this.state.clientListDDL}
                                    placeholder="Please Select Client"
                                    disabled = { this.state.clientspecific}
                                    ref="clients"
                                />
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleSubmit }>Save</Button>
                        <Button variant="danger" className="noser-button-mr15" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default CreateHoliday