import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

class BlacklistModal extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            name        :   '',
            disableBtn  :   true,
            modalLabel  :   "",

            data        :   {}
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        console.log(e)
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            name        :   '',
            disableBtn  :   false,

            data        :   e,
            remarks     :   "",
            modalLabel    :   e.statusId==="12" ? "BLACKLIST APPLICANT" : "POOL APPLICANT"
        })
    }
    
    handleChange  = (e) =>{
        this.setState({[e.target.name]:e.target.value,isshow:false,})
    }
    handleSubmit = async() =>{

        console.log("this.state.data")
        console.log(this.state.data)
        if(this.state.remarks==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter remarks.",
                fade        :   true,
                disableBtn  :   false
            })
            return 
        }
        this.setState({isloading:true,disableBtn:true})
        const param = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "PoolingId":this.state.data.poolingId,
            "ApplicationFormId":this.state.data.applicationFormId,
            "PositionId":this.state.data.positionId,
            "StatusId": this.state.data.statusId, /* --> blacklisted - 12, process - 3  */
            "Remarks": this.state.remarks
        }
        
        await axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Pooling/SubmitPooling",  param
            )
            .then(res => {
                const data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true,
                    name        :   ''
                });
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true,
                    name        :   ''
                })
            })
    }
    handleModalClose = () => {
        this.props.onHide();            
    }
    
    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton className="card-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.state.modalLabel}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                REMARKS
                            </Form.Label>
                            <Col sm="11">
                                <Form.Control 
                                    id="remarks"
                                    name="remarks"
                                    //type="text" 
                                    value={this.state.remarks}
                                    as="textarea" rows={3}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Form.Group>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = {() => this.handleSubmit('2')} disabled={this.state.disableBtn}>SUBMIT</Button>
                        <Button variant="danger" className="noser-button-mr15" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default BlacklistModal