import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { Input  } from 'reactstrap';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import AdminCasesSearch from '../Maintenance/AdminCasesSearch';
 
class AdminCasesCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            trainingRegisterTableList          :   [ { "typeOfCases" : "DOLE" } ],
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalTrainingRegisterShow           :   false,

            category    :"",
            caseName    :"",
            caseType    :"",

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }

    showTrainingRegisterModal = (e) => {
        this.setState({modalTrainingRegisterShow: true})
    }
    handleModalClose = (e) =>{
        //reload or refresh data here
        //alert("Data pass from child : " + e)
        this.setState({modalTrainingRegisterShow: false})
    }


    GridDataModified(oldValue, newValue, id, column) {
        ////console.log(id)
        this.state.trainingScheduleTableList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }
    
    onChangeCategory=(e)=>{
        this.setState({
            category:e.target.value
        })
        console.log(this.state.category)
    }
    onChangeCaseName=(e)=>{
        this.setState({
            caseName:e.target.value
        })
        console.log(this.state.caseName)
    }
    onChangeCaseType=(e)=>{
        this.setState({
            caseType:e.target.value
        })
        console.log(this.state.caseType)
    }

    handleSave=(e)=>{
        
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');

        let data = {
          "caseType"        :   this.state.caseType,
          "caseName"        :   this.state.caseName,
          "category"        :   this.state.category,
          "isDraft"         :   "0",
          "isDeleted"       :   "0",
          "createdby"       :   "user007",
          "createddate"     :   createddate,
          "modifiedby"      :   'user007',
          "modifieddate"    :   '0',
          "isModified"      :   '0',    
        }
        console.log(data)
        
        const addParams = {
          "_collection" : "TestAdminCases",
          "doc_data"    : data
        }
          
        axios
        .post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
          const data = res.data
          console.log("success")
          console.log(data)
        })
        .catch(error =>{
          console.log("error: " + error)
        })
    }

    render() {
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>Maintenance >> Cases Maintenance >> Administrative >> Search </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Case Type
                                        </Form.Label>
                                        <Col sm="11">
                                            <Input
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeCaseType}
                                                type="text" 
                                                placeholder=""
                                                value={this.state.caseType}
                                                
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Case Name
                                        </Form.Label>
                                        <Col sm="4">
                                            <Input
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeCaseName}
                                                type="text" 
                                                placeholder=""
                                                value={this.state.caseName}
                                                
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Category
                                        </Form.Label>
                                        <Col sm="4">
                                            <Input
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeCategory}
                                                type="text" 
                                                placeholder=""
                                                value={this.state.category}
                                                
                                            />
                                        </Col>
                                    </Form.Group>
                                    
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                    
                                    </Form.Group>
                                   
                                    <ButtonToolbar sm={12}>
                                        <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleSave}>
                                            Save
                                        </Button>&nbsp;&nbsp;
                                        <NavLink to="/home">
                                            <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                                Close
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                   
            </div> 
        )
    }

}

export  default AdminCasesCreate
