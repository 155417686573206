import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Redirect,Accordion
} 
from '../../noser-hris-component';
//import SourcingModal from './Modal/SourcingModal';
//import PreliminaryModal from '././Modal/PreliminaryModal'


class ClientEndorsement extends Component {
    state = {
        userinfo        :   [],
        isloading       :   false,
        isshow          :   false,
        alerttype       :   "",
        message         :   "",
        color           :   "",
        fade            :   true,
        disableBtn      :   true,
        openModal       :   false,
        applicationGrid :   [],
        applicationDDL  :   [],
        clientDDL       :   [],
        employeeDDL     :   [],
        employeeList    :   [],
        emplist: [],
        testtwo:[],
        testdata: [],

        clientId        :   "",
        referenceNo     :   "",
        statusId        :   "",
        tinNumber       :   "",
        dateApplied : "",
        ApplicationFormId:  "",
        employeeName: "",
        age: "",
        FromAge: "",
        ToAge: "",
        position : "",
        positionId : "",
        profileId : "",

        postionDDL   :   [],
        positonDGDDL: [],
        EffectivityMonth: "",
        petsa:"",
        tblClientArrLst : [],
        tblPositionArrLst : [],

        acctTypeDDL:[],
        branchDDL:[],
        branchId:""
    }

    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        this.GetClients()
        let a = JSON.parse(sessionStorage.getItem("userData"))
        //console.log(a)
        if(a.roleId === "14"){
            this.setState({ isShow : true,})
        }
    };

    GetClients = async()=> {
        this.setState({isloading:true})
        const clientParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  clientParams)
        .then(res => {
            const data = res.data.clients;
            let client = []
            data.map(function(itm){
                client.push({
                    "value":itm.id,
                    "label":itm.name
                })
            })
            this.setState({tblClientArrLst:client,clientDDL:data})
            this.GetAccountTypes()
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    GetAccountTypes = async()=>{
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Name"          :   ""
        };

        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetAccountTypes",  params)
        .then(res => {
            const data = res.data.accountTypes;
            this.setState({ acctTypeDDL : data});
            this.GetPosition()
        })
    }
    GetPosition = async() => {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId"     :   "",
            "DepartmentId"  :   "",
            "Name"          :   ""
        };

        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  params)
        .then(res => {
            const data = res.data.positions;
            ////console.log("Get positions");
            ////console.log(data);
            let a = []
            for (let i = 0; i < data.length; i++) {
                const pos = {
                    value : data[i]["id"],
                    label : data[i]["name"],
                };
                this.state.positonDGDDL.push(pos);
                a.push(pos)
            };
            console.log("a")
            console.log(a)

            this.setState({ postionDDL : data});
            this.GetApplicationFormFilter()
            //this.GetApplicationFormForProcessing()
        })
    };

    GetApplicationFormFilter = async()=> {
        this.setState({isloading:true})
        const params = {
            "IpAddress" :   "0.0.0.0",
            "ClientId"  :   this.state.clientId,
            "UserId"    :   this.state.userinfo.userId,
            "RoleId" : this.state.userinfo.roleId,
            "AreId" : this.state.userinfo.areaId,
            "TypeId"    :   "2",//"9"
            "StatusId": "13",
        };
        console.log("GetApplicationFormFilter")
        console.log("params")
        console.log(params)
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationFormForProcessingFilter",  params)
        .then(res => {
            const data = res.data;
            console.log(data)
           this.setState({
            isloading       :   false,
            employeeDDL :   data.applicationForms,
           })
           //console.log(this.state.employeeDDL)
            this.GetApplicationFormForProcessing();
           //this.handleSearchClick()
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
       

    };

    GetApplicationFormForProcessing = () => {
        this.setState({isloading:true})
        let petsa = moment(this.state.EffectivityMonth).format("MM/DD/YYYY");
        this.state.petsa = petsa
        console.log("petsa")
        console.log(petsa)
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ReferenceNo":this.state.referenceNo,
            "ProfileId":this.state.profileId,
            "PositionId": this.state.positionId,
            "StatusId": "13",
            "TINNumber":this.state.tinNumber,
            "ApplicationFormId" : this.state.ApplicationFormId,
            "DateApplied" : this.state.petsa,
            "RoleId" : this.state.userinfo.roleId,
            "AreId" : this.state.userinfo.areaId,
        };
        
        console.log("params")
        console.log(params)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationFormForProcessing",  params)
            .then(res => {
            const data = res.data;
            console.log("data")
            console.log(data)
            this.setState({
                applicationGrid :   data.applicationForms,
                isloading       :   false
            });

            if(data.status=="0"){
               this.setState({
                   isloading   :   false,
                   alerttype   :   "Error!",
                   isshow      :   true,
                   color       :   "danger",
                   message     :   data.message,
                   fade        :   true
               });
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };

    GetApplicationList = async()=> {
        this.setState({applicationFormListGrid:[], applicationsList:[], isloading:true})

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ReferenceNo":this.state.referenceNo,
            "PositionId":"",
            "StatusId":"13",
            "TINNumber":this.state.tinNumber,
            "ApplicationFormId" : this.state.ApplicationFormId,
            "RoleId" : this.state.userinfo.roleId,
            "AreId" : this.state.userinfo.areaId,
        };
        console.log("GetApplicationList")
        console.log(params)
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationForms",  params)
            .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({applicationGrid:data.applicationForms,isloading:false});
            if(data.status=="0"){
               this.setState({
                   isloading   :   false,
                   alerttype   :   "Error!",
                   isshow      :   true,
                   color       :   "danger",
                   message     :   data.message,
                   fade        :   true
               });
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };

    /* GetApplicationListPreload = async()=> {
        this.setState({applicationFormListGrid:[], applicationsList:[], isloading:true})

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ReferenceNo":this.state.referenceNo,
            "PositionId":this.state.positionId,
            "StatusId": "3",
            "TINNumber":this.state.tinNumber,
            "RoleId" : this.state.userinfo.roleId,
            "AreId" : this.state.userinfo.areaId,
            "ApplicationFormId" : this.state.ApplicationFormId,
        };
        console.log("GetApplicationListPreload")
        console.log(params)
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationFormPreload",  params)
            .then(res => {
            const data = res.data;
            let a = data.applicationForms.filter(x=>x.statusId=="3")
            console.log(a)
            this.setState({applicationGrid:a,isloading:false});
            if(data.status=="0"){
               this.setState({
                   isloading   :   false,
                   alerttype   :   "Error!",
                   isshow      :   true,
                   color       :   "danger",
                   message     :   data.message,
                   fade        :   true
               });
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }; */

    /* GetApplications = async() => {
        console.log("GetApplications")
        this.setState({disableBtn:true});
        //let petsa = moment(this.state.EffectivityMonth).format("MM/DD/YYYY");
        //console.log("this.state.EffectivityMonth")
        //console.log(this.state.EffectivityMonth)
        if(this.state.EffectivityMonth === ""){
            this.state.petsa = ""
        }else{
            this.state.petsa = moment(this.state.EffectivityMonth).format("MM/DD/YYYY");
        }


        //this.state.petsa = petsa
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ReferenceNo":this.state.referenceNo,
            "PositionId":"",
            "StatusId":this.state.statusId,
            "TINNumber":this.state.tinNumber,
            "ApplicationFormId" : this.state.ApplicationFormId,
        };
        //console.log("params")
        //console.log(params)
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Pooling/GetSourcings",  params)
        .then(res => {
            const data = res.data
            console.log("sourcing")
            console.log(data)
            
            if(data.status=="1"){
                this.setState({
                    isloading:false,
                    applicationGrid:data.sourcings,
                    testdata: data.sourcings,
                });
            }
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                });
            }
         })
         .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
        //this.GetApplicationFormFilter()
    }; */
    
    handleChange = (e) =>{
        //console.log(e.target.value)
        this.setState({[e.target.name]: e.target.value})
    };

    handleChangeClient = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length == 0) {
            this.state.clientId     =   ""
            this.state.clientName   =   ""
            this.state.employeeDDL  =   this.state.employeeList
            return
        }
        this.state.clientId     =   e[0].id
        this.state.clientName   =   e[0].name
        console.log( e[0].id)
        
       // this.state.employeeDDL  =   this.state.testdata.filter(x=>x.clientId===e[0].id);
        //this.GetApplicationFormFilter()
        //this.GetApplications()
        //let a = this.state.employeeList.filter(x=>x.clientId===e[0].id);
        //console.log("handleChangeClien eventt")
        //console.log(e)
        //console.log(e[0].id)

    };

    handleChangeEmployee = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length == 0) {
            this.state.ApplicationFormId    =   ""
            this.state.profileId            =   ""
            this.state.referenceNo          =   ""
            this.state.tinNumber            =   ""
            this.state.statusId             =   ""
            this.state.employeeName = ""

            //this.state.clientId     =   ""
            //this.state.clientName   =   ""
            return
        }
        //console.log(e)
        //console.log(e[0].clientId)
        //console.log(e[0].client)
        this.state.ApplicationFormId    =   e[0].ApplicationFormId
       // this.state.clientId     =   e[0].clientId
       //this.state.clientName   =   e[0].client
        this.state.profileId = e[0].profileId
       // this.state.applicationGrid  =   this.state.testdata.filter(x=>x.clientId===e[0].clientId);
       // this.state.employeeDDL  =   this.state.testdata.filter(x=>x.applicationFormNo===e[0].applicationFormNo);
    };

    handelReferenceNo=(e)=>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length == 0) {
            this.state.ApplicationFormId    =   ""
            this.state.profileId            =   ""
            this.state.referenceNo          =   ""
            this.state.tinNumber            =   ""
            this.state.statusId             =   ""
            this.state.employeeName = ""

            this.state.clientId     =   ""
            this.state.clientName   =   ""
            return
        }
        //console.log(e)
        //console.log(e[0].clientId)
        //console.log(e[0].client)
        this.state.ApplicationFormId    =   e[0].ApplicationFormId
        this.state.clientId     =   e[0].clientId
        this.state.clientName   =   e[0].client
        this.state.profileId = e[0].profileId
    };

    handleDateApplied=(e)=>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length == 0) {
            this.state.ApplicationFormId    =   ""
            this.state.profileId            =   ""
            this.state.referenceNo          =   ""
            this.state.tinNumber            =   ""
            this.state.statusId             =   ""
            this.state.employeeName = ""

            this.state.clientId     =   ""
            this.state.clientName   =   ""
            this.state.dateApplied = ""
         
            return
        }
        //console.log(e)
        //console.log(e[0].clientId)
        //console.log(e[0].client)
        this.state.ApplicationFormId    =   e[0].ApplicationFormId
        this.state.clientId     =   e[0].clientId
        this.state.clientName   =   e[0].client
        this.state.dateApplied = e[0].dateApplied
        this.state.profileId = e[0].profileId
    };

    handleChangedPosition=(e)=>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length == 0) {
            this.state.positionId=""
            return
        }; 
        //console.log(e)
       // //console.log(e[0].position)
        ////console.log(e[0].positionId)
        //his.state.ApplicationFormId    =   e[0].ApplicationFormId
        // this.state.clientId     =   e[0].clientId
        // this.state.clientName   =   e[0].client
        this.state.position = e[0].name
        this.state.positionId = e[0].id
        //this.state.profileId = e[0].profileId
    }

    handleChangeEffectivityMonth = e => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});

        //let a = moment(date).format("MM/DD/YYYY");
        //console.log("date.")
        //console.log(e)
        /* this.setState({
            EffectivityMonth: e
        }); */

        if(e == null) {
            this.state.EffectivityMonth = ""
            return
        }
        this.state.EffectivityMonth = e
        
    };

    handleSearchClick = (e) => {
        this.GetApplicationFormFilter()
    }

    GridDataModified(oldValue, newValue, id, column) {
        //console.log(id)
        this.state.applicationGrid.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }

    handleClickedEdit = (row) =>{
        row.isPooling = "1"
        sessionStorage.setItem("viewtoto_" + this.state.userinfo.userId + this.state.userinfo.employeeId, null)
        sessionStorage.setItem("viewapplicationform_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
        this.setState({navigate:true})
    };

    handleUpdateClick=(e)=>{
        //console.log("handleUpdateClick")
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.setState({isloading:true,isDisable:true})
        
        let inclusions  = []     
        
        for (let i = 0; i < this.state.applicationGrid.length; i++) {
            if (this.state.applicationGrid[i]["isModified"] == 1) {
                let obj = this.state.applicationGrid[i]
                inclusions.push(obj);
            } 
        };

        let a = inclusions
        a.forEach(element => {
            element.applicationFormId = element.id
        });

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "applicationforms": a,
        };

        //console.log("params")
        //console.log(params)


        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Pooling/DeleteApplicationForm", params)
            .then(res => {
                //this.refreshList();
                const data = res.data;
                //console.log(data)
                if(data.status=="1"){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Success!",
                        isshow          :   true,
                        color           :   "success",
                        message         :   data.message,
                        fade            :   true
                    });
                    this.GetApplications()
                }
                else{
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   data.message,
                        fade            :   true
                    })
                }
             })
             .catch(error=>{
                this.setState(
                {
                    isLoading:false,
                    AlertType:"Error! ",
                    Show:true,
                    Color:"danger",
                    Message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    Fade:true
                })
            })
    };

    handleModalShow = (e) =>{
        this.setState({openModal:true})
        this.child.initialize(e)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.GetApplicationFormFilter()
    };
    lipat=(row)=>{
       // console.log(this.state.wdata)
       console.log(row)
        sessionStorage.setItem("applicationformendorsement_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
        this.setState({ClientEndorsementCreate:true})
    }

    render() {
        const {ClientEndorsementCreate} = this.state;
        if (ClientEndorsementCreate) {
            // <Redirect to="/applicationformview" push={true} />;
            return <Redirect to="/ClientEndorsementCreate" push={true} />;
        } ;
        //ApplicationFormEdit
        

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            bgColor: (row, rowIndex) => (rowIndex > 1 ? '#AEBABA' : '#B3B4B4'),
            onSelect: (row, isSelect, rowIndex, e) => {
             //console.log("row")
             //console.log(row)
                this.state.applicationGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if(isSelect)
                    {
                        if(i==rowIndex)
                        item.IsSelected=1
                    }
                    else
                    {
                        if(i==rowIndex)
                        item.IsSelected=0
                    }
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                console.log("rowEvents")
                console.log(e)
                console.log(row)
                sessionStorage.setItem("editapplicationform_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))

            }
        }

        const cols = [
            {
                dataField: 'applicationFormNo',
                text: 'REFERENCE NO',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center',width:'10%'},
                sort:true
            },
            {
                dataField: 'employeeName',
                text: 'APPLICANT NAME',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'25%' }},
                style:{textAlign:'left',width:'25%'},
                sort:true,
                formatter   :   (cell, row, isSelect) => {
                    if(row.statusId!=="19"){
                        return (
                            <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}}
                                variant="link" onClick={e => this.handleClickedEdit(row)}
                        >{cell}</Button>
                        );
                    }
                    else{
                        return (
                            cell
                        );
                    }
                }
            },
            {
                dataField: 'position',
                text: 'POSITION',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'18%' }},
                style:{textAlign:'left',width:'18%'},
                sort:true
            },//positonDGDDL
            // {
            //     dataField: 'positionId',
            //     text: 'POSITION',
            //     headerStyle: () => {
            //         return { width: "18%" };
            //     },
            //     formatter: (cell, row) => {
            //         console.log("row.positionId")
            //         console.log(row.positionId)
            //         if(row.positionId!=='' && row.positionId!==null || typeof row.positionId!=='undefined'){
            //             if(this.state.positonDGDDL.filter(x => x.value == cell).length>0)
            //                 return this.state.positonDGDDL.find(x => x.value == cell).label;
            //         }
            //     },
            //     editor: {
            //         type: Type.SELECT,
            //         options: this.state.positonDGDDL
            //     },
            // },
            {
                dataField: 'client',
                text: 'CLIENT',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'30%' }},
                style:{textAlign:'left',width:'30%'},
                sort:true
            },
            // {
            //     dataField: 'clientId',
            //     text: 'CLIENT',
            //     headerStyle: () => {
            //         return { width: "22%" };
            //     },
            //     formatter: (cell, row) => {
            //         if(row.clientId!=='' && row.clientId!==null || typeof row.clientId!=='undefined'){
            //             if(this.state.tblClientArrLst.filter(x => x.value == cell).length>0)
            //                 return this.state.tblClientArrLst.find(x => x.value == cell).label;
            //         }
            //     },
            //     editor: {
            //         type: Type.SELECT,
            //         options: this.state.tblClientArrLst
            //     },
            // },
            
            {
                dataField: 'dateApplied',
                text: 'date applied',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'center',width:'8%'},
                sort:true
            },
            {
                dataField: '',
                text: 'ACTION',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'center',width:'8%'},
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link" onClick={e => this.lipat(row)}
                        >Process</Button>
                        );
                },
            },
        ]
       
        const sizePerPageRenderer = ({
            options,
            currSizePerPage,
            onSizePerPageChange
            }) => (
                <div className="btn-group" role="group">
                    {
                    options.map((option) => {
                        const isSelect = currSizePerPage === `${option.page}`;
                        return (
                        <button
                            key={ option.text }
                            type="button"
                            onClick={ () => onSizePerPageChange(option.page) }
                            className={ `btn ${isSelect ? 'btn-primary' : 'btn-success'}` }
                        >
                            { option.text }
                        </button>
                        );
                    })
                    }
                </div>
            );

        const options = {
            sizePerPageRenderer
        };

        return(
       
            <div>
                <Banner />
                    <Container className="themed-container" fluid={true}>
                        <Card className="mt-5">
                            <Card.Header>RECRUITMENT >> CLIENT ENDORSEMENT</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Card>
                                        <Accordion defaultActiveKey="1">
                                            <div className="card-header-tab">
                                                <Accordion.Toggle as={Button} variant="link" eventKey="1" style={{color: "#FFFFFF"}}>
                                                    SEARCH FILTER
                                                </Accordion.Toggle>
                                            </div>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body>
                                                  <Form.Row>
                                                        <Col sm={1}>
                                                            <Form.Label style={{fontWeight : "bold"}} className='mt-1'>CLIENT</Form.Label>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Typeahead 
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangeClient}
                                                                options={this.state.clientDDL}
                                                                placeholder="Select Client" 
                                                            />
                                                        </Col >
                                                        <Col sm={2}></Col>
                                                        <Col sm={1}>
                                                            <Form.Label style={{fontWeight : "bold"}} className='mt-1'>APPLICANT NAME</Form.Label>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Typeahead 
                                                                labelKey='memberName'
                                                                id="basic-example"
                                                                onChange={this.handleChangeEmployee}
                                                                options={this.state.employeeDDL}
                                                                placeholder="SELECT APPLICANT  "
                                                            />
                                                        </Col >
                                                  </Form.Row>
                                                  <Form.Row className='mt-3'>
                                                        <Col sm={1}>
                                                            <Form.Label style={{fontWeight : "bold"}} className='mt-1'>REFERENCE NO.</Form.Label>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Typeahead 
                                                                labelKey='applicationFormNo'
                                                                id="basic-example"
                                                                onChange={this.handelReferenceNo}
                                                                options={this.state.applicationGrid}
                                                                placeholder="REFERENCE NO." 
                                                            />
                                                        </Col >
                                                        <Col sm={2}></Col>
                                                        <Col sm={1}>
                                                            <Form.Label style={{fontWeight : "bold"}} className='mt-1'>POSITION APPLIED</Form.Label>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Typeahead 
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangedPosition}
                                                                options={this.state.postionDDL}
                                                                placeholder="SELECT POSITION " 
                                                            />
                                                        </Col >

                                                        {/* <Col sm={4}>
                                                            <DatePicker
                                                                ref='EffectivityMonth'
                                                                selected={this.state.EffectivityMonth}
                                                                onChange={this.handleChangeEffectivityMonth}
                                                                //minDate={this.minDate}
                                                                value={this.props.EffectivityMonth}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                            />
                                                        </Col > */}
                                                  </Form.Row>
                                                  {/* <Form.Row className='mt-3'>
                                                        <Col sm={1}>
                                                            <Form.Label style={{fontWeight : "bold"}} className='mt-1'>DATE APPLIED</Form.Label>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <DatePicker
                                                                ref='EffectivityMonth'
                                                                selected={this.state.EffectivityMonth}
                                                                onChange={this.handleChangeEffectivityMonth}
                                                                //minDate={this.minDate}
                                                                value={this.props.EffectivityMonth}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                placeholder="SELECT DATE" 
                                                            />
                                                        </Col >
                                                        <Col sm={4}></Col>
                                                        
                                                        <Col sm={1}>
                                                            <Form.Label style={{fontWeight : "bold"}} className='mt-1'>AGE FROM</Form.Label>
                                                        </Col>
                                                        <Col sm={1}>
                                                            <Form.Control
                                                                id="basic-example"
                                                                name='FromAge'
                                                                type="text" 
                                                                onChange={this.handleChange} 
                                                                autoComplete="off" 
                                                                value={this.state.FromAge}
                                                                //placeholder="AGE from " 
                                                                
                                                            />
                                                        </Col >
                                                        <Col sm={1}></Col>
                                                        <Col sm={1}>
                                                            <Form.Label style={{fontWeight : "bold"}} className='mt-1'>AGE TO</Form.Label>
                                                        </Col>
                                                        <Col sm={1}>
                                                            <Form.Control
                                                                id="basic-example"
                                                                name='ToAge'
                                                                type="text" 
                                                                onChange={this.handleChange} 
                                                                autoComplete="off" 
                                                                value={this.state.ToAge}
                                                               // placeholder="AGE to " 
                                                            />
                                                        </Col >
                                                  </Form.Row> */}
                                                    <ButtonToolbar className="mt-4">
                                                        <Button variant="primary" className="ml-auto noser-button-mr1" onClick={ this.handleSearchClick }>
                                                            Search
                                                        </Button>
                                                        <NavLink to="/applicationformcreate"/* to="ApplicationFormReview" */>
                                                            <Button className="noser-button"  variant="success">New Applicant</Button>
                                                        </NavLink>
                                                    </ButtonToolbar>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Accordion>
                                    </Card>

                                    
                                    <div className="mt-2">
                                        <Card.Header>Record</Card.Header>
                                        <BootstrapTable
                                            keyField = "id"
                                            data = { this.state.applicationGrid }
                                            columns = { cols }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }
                                            selectRow = { selectRow }
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        />

                                        <div isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isShow ? 'display-block' : 'display-none'}> 
                                            <ButtonToolbar sm={12}>
                                                <Button variant="danger" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleUpdateClick}>
                                                    DELETE
                                                </Button>
                                            </ButtonToolbar>
                                        </div>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                <NoserLoading show={this.state.isloading} />
                {/* <PreliminaryModal 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                /> */}
            </div> 
        )
    }

}

export  default ClientEndorsement