import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

class ShortListed extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            disableBtn  :   true,

            title       :   "",
            positionDDL :   [],
            positionId  :   "",
            sldata      :   {}
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        console.log("e")
        console.log(e)
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            
            //title       :   e.title,
            sldata      :   e,
            dateApplied :   moment(new Date(e.dateApplied)).format("MM/DD/YYYY"),
            applicantName:  e.name,
            poolstatusid:"",
            positionId : ""
        })
        this.GetPositions()
    }
    
    GetPositions = async()=> {
        this.setState({isloading:true})
        const positionParams = {
            "IpAddress"     : "0.0.0.0",
            "ClientId"      : this.state.userinfo.clientId,
            "UserId"        : this.state.userinfo.userId,
            "SectionId"     : "",
            "DepartmentId"  : "",
            "Name"          : ""
        };
        await
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  positionParams)
        .then(res => {
            const data = res.data;
            this.setState({
                positionDDL : data.positions,
                isloading   : false
            });
        })
        
    }
    handleChangedDDL = (e) =>{
        if(e.length == 0) {
            this.setState({isshow:false,poolstatusid:"",disableBtn:true})
            return
        }
        this.setState({isshow:false,poolstatusid:e[0].id,disableBtn:false})
    }
    handleSubmitPool = async()=>{
        this.setState({isloading:true})

        let user = []
        let profile = []

        const userparam = {
        "_collection" : "UserCollection",
        "filter_data" : {"isDeleted": "0", userId: this.state.sldata.employeeId}
        }
        await axios
        .post(AppConfiguration.Setting().jobspaceapi + "action/get", userparam)
        .then(res => {

            let data = res.data
            let newstr2=""
            data.UserCollection.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let strJson2 = itm.substring(18, itm.length)
                newstr2 = strJson2.split("')")[0]
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                json.headerId = newstr2
                user.push(json)
            })  
        })
        .catch(error =>{
            alert("error in GetCandidates function: " + error)
        })
        console.log("UserCollection")
        console.log(user)
        
        const profileparam = {
          "_collection" : "CandidatesProfile",
          "filter_data" : { "UserId" : this.state.sldata.employeeId }
        }

        await axios
        .post(AppConfiguration.Setting().jobspaceapi + "action/get", profileparam)
        .then(res => {

            let data = res.data
            let newstr2=""
            data.CandidatesProfile.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let strJson2 = itm.substring(18, itm.length)
                newstr2 = strJson2.split("')")[0]
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                json.headerId = newstr2
                profile.push(json)
            })   
        })
        .catch(error =>{
            alert("error in GetCandidates function: " + error)
        })
        console.log("CandidatesProfile")
        console.log(profile)
        
        const params = {
            "IpAddress"                 : "0.0.0.0",
            "ClienttId"                 : this.state.userinfo.clientId,
            "UserId"                    : this.state.userinfo.userId,
            "IsRehire"                  : this.state.sldata.isPhr,
            "ProfileId"                 : this.state.profileId,
            "StatusId"                  : "3",
            "PositionId"                : this.state.positionId,
            "DateApplied"               : this.state.dateApplied,
            "Title"                     : profile[0].title==="" || profile[0].title===undefined? "NA" : profile[0].title,
            "FirstName"                 : profile[0].FirstName,
            "NickName"                  : profile[0].NickName,
            "MiddleName"                : profile[0].MiddleName,
            "LastName"                  : profile[0].LastName,
            "SuffixName"                : profile[0].SuffixName,
            "EmailAddress"              : profile[0].EmailAddress,
            "MobileNumber"              : profile[0].MobileNumber,
            "HomePhoneNumber"           : profile[0].HomePhoneNumber,
            "Facebook"                  : profile[0].Facebook,
            "Twitter"                   : profile[0].Twitter,
            "NationalityId"             : profile[0].NationalityId,
            "ReligionId"                : profile[0].ReligionId,
            "Gender"                    : profile[0].Gender==="" || profile[0].Gender===undefined? "NA" : profile[0].Gender,
            "CivilStatus"               : profile[0].CivilStatus,
            "DateOfBirth"               : profile[0].DateOfBirth,
            "AgeYear"                   : new Date(profile[0].DateOfBirth).getFullYear(),
            "AgeMonth"                  : new Date(profile[0].DateOfBirth).getMonth(),
            "PlaceOfBirth"              : profile[0].PlaceOfBirth,
            "Height"                    : profile[0].Height,
            "Weight"                    : profile[0].Weight,
            "SpecialSkills"             : "",
            "LanguageSpoken"            : "",
            "EmergencyContactName"      : profile[0].EmergencyContactName,
            "EmergencyContactRelation"  : profile[0].EmergencyContactRelation,
            "EmergencyContactNumber"    : profile[0].EmergencyContactNumber,
            "Hobbies"                   : "",
            "TINNumber"                 : profile[0].TINNumber,
            "SSSNumber"                 : profile[0].SSSNumber,
            "PHICNumber"                : profile[0].PHICNumber,
            "HDMFNumber"                : profile[0].HDMFNumber,
            "LivingArrangementId"       : profile[0].LivingArrangementId,
            "BloodTypeId"               : profile[0].BloodTypeId,
            "RegistrationId"            : "0",

            "applicantAddresses" :[],
            "educationAttainments"  : [],
            "employmentHistories"   : [],
            "familyBackgrounds"     : [],
            "others"                : [],
            "characterReferences"   : [],
        }
        console.log("Submit params")
        console.log(params)
        
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/AddApplicationForm",  params )
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   "Your application is successfully submited. Please log in to know the status of your  Job Application",
                    fade        :   true,
                    checkDisclaimer : false,
                    disablebutton : true,
                    // navigate    :   true,
                });
                this.UpdateShortlisted()
            }
            else {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: data.message,
                    fade:true
                });
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }
    handleChangePosition = (e) => {
        if(e.length == 0) {
            this.state.positionId     =   ""
            this.state.positionName   =   ""
            this.setState({isshow:false,disableBtn:true})
            return
        }
        this.state.positionId     =   e[0].id
        this.state.positionName   =   e[0].name
        this.setState({isshow:false,disableBtn:false})
    }
    UpdateShortlisted = async() =>{
        const param = {
            "dbname"      :  AppConfiguration.Setting().nosererpdbdev,
            "_collection" : "Candidates",
            "doc_data"    : {
                "city"           : this.state.sldata.city,
                "companyName"    : this.state.sldata.companyName,
                "contact"        : this.state.sldata.contact,
                "createdBy"      : this.state.sldata.createdBy,
                "createdDate"    : this.state.sldata.createdDate,
                "dataPrivacy"    : this.state.sldata.dataPrivacy,
                "dateApplied"    : this.state.sldata.dateApplied,
                "educLevel"      : this.state.sldata.educLevel,
                "email"          : this.state.sldata.email,
                "employementType": this.state.sldata.employementType,
                "endDate"        : this.state.sldata.endDate,
                "expectedSalary" : this.state.sldata.expectedSalary,
                "experience"     : this.state.sldata.experience,
                "headerId"       : this.state.sldata.experience,
                "industry"       : this.state.sldata.industry,
                "isDeleted"      : this.state.sldata.isDeleted,
                "isModified"     : this.state.sldata.isModified,
                "isPhr"          : this.state.sldata.isPhr,
                "isRegister"     : this.state.sldata.isRegister,
                "jobDepartment"  : this.state.sldata.jobDepartment,
                "jobDocNo"       : this.state.sldata.jobDocNo,
                "level"          : this.state.sldata.level,
                "message"        : this.state.sldata.message,
                "modifiedDate"   : this.state.sldata.modifiedDate,
                "modifiedby"     : this.state.sldata.modifiedby,
                "name"           : this.state.sldata.name,
                "postingId"      : this.state.sldata.postingId,
                "qualification"  : this.state.sldata.qualification,
                "resume"         : this.state.sldata.resume,
                "status"         : this.state.sldata.status,
                "title"          : this.state.sldata.title
            },
            "employeeId" : this.state.sldata.employeeId
        }
        
        await axios
        .post(AppConfiguration.Setting().jobspaceapi + "action/update_one", param)
        .then(res =>{
        })
        .catch(error =>{
          console.log("error: " + error)
        })
    }
    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton className="card-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        <span>PROCESS SHORTLISTED</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Group as={Row}>
                            <Form.Label disabled column sm="3" style={{fontWeight : "bold"}}>
                                DATE APPLIED
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control 
                                    type="text"
                                    name="street"
                                    value={this.state.dateApplied}
                                    disabled
                                />
                                {/* <Form.Label disabled style={{fontWeight : "bold"}}>
                                    {this.state.dateApplied}
                                </Form.Label> */}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label disabled column sm="3" style={{fontWeight : "bold"}}>
                                APPLICANT NAME
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control 
                                    type="text"
                                    name="street"
                                    value={this.state.applicantName}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formGridCity">
                            <Form.Label column sm="3" style={{fontWeight : "bold"}}>POSITION APPLIED</Form.Label>
                            <Col sm={9}>
                            <Typeahead
                                ref='positionref'
                                labelKey='name'
                                id="basic-example"
                                onChange={this.handleChangePosition}
                                options={this.state.positionDDL}
                                placeholder="Select Position" 
                            />
                            </Col>
                        </Form.Group>
                        <br />
                        <br />
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = {this.handleSubmitPool} disabled={this.state.disableBtn}>Submit</Button>
                        <Button variant="danger" className="noser-button-mr15" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default ShortListed