import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown, FormControl} from 'react-bootstrap';

//import InsertPenaltyStatement from '../Maintenance/Modal/InsertPenaltyStatement';
import  InsertCOCReference from '../Maintenance/Modal/InsertCOCReference';

import { Input } from 'reactstrap';
//import { toHaveAttribute } from '@testing-library/jest-dom';

const showSecond = false;
const str = showSecond ? 'HH:mm:ss' : 'HH:mm';

class NoticeOfDisiplinaryAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            disableTakeExam :   true,
            disableSubmit   :   false,

            modalInsertPenalty: false,
            modalCOCReference: false,
            modalCoCReferenceShow:false,

            isLoading:"",

            selectedEmployeeName:"",
            selectedEmployeeId:[],
            selectedEmployeeNo:[],
            selectedEmployeeBranch:[],
            selectedPosition:[],
            selectedEmployeeBranch:[],

            getClientList:[],
            getEmployeeList : [],
            getEmployeeNoList: [],
            location:[],
            explanationLetter:[],
            selectedClientName:"",
            selectedClientId:"",


            paragraph:"",
            addedparagraph1:"",
            quotedStatement:"",
            locatdateion:"",
            date:"",
            nodaRefNo:"",
            nteRefNo:"",
            dateReceive:"",
            attachNTEBase64:"",
            explanationLetterBase64:"",
            attachNTE:"",
            explanationLetter:"",
            quotedStatementCheck:false,
            cocReferences:"",
            articleNo:"",
            articleName:"",
            articleNo2:"",
            articleName2:"",
            sectionNo:"",
            infraction:"",
            category:"",
            sectionNo2:"",
            infraction2:"",
            category2:"",
            sectionNo3:"",
            infraction3:"",
            preparedBy:"",
            notedBy:"",
            receivedby:"",
            prepareByPosition:"",
            addedparagraph1Check:false,
            addedparagraph2Check:false,
            addedparagraph3Check:false,
            addedparagraph4Check:false,
            addedparagraph5Check:false,
            addedparagraph6Check:false,
            paragraph:"",
            addedparagraph1:"",
            addedparagraph2:"",
            addedparagraph3:"",
            addedparagraph4:"",
            addedparagraph5:"",
            addedparagraph6:"",
            disableEdit:false,
            employeePlaceHolder:"",
            irReferenceNo:"",
            IRobjId:"",
            selectedPreparedbyClientId:"",
            selectedPreparedbyClient:"",
            eSignatureNotedBy       :"",
            eSignaturePreparedBy    :"",
            getEmployeepreparedBy    :[],
            articleDataList:[],
            sectionDataList:[],
            articleDataList2:[],
            sectionDataList2:[],
            articleDataList3:[],
            sectionDataList3:[],
            articleDataList4:[],
            sectionDataList4:[],
            articleDataList5:[],
            sectionDataList5:[],
            insertImage:"",
            NODObjId:"",

        }
    }

    componentDidMount(){

        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        console.log(this.state.userinfo)

        this.setState({isLoading:true,loadingText:"Loading client list..."})

        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();;

        let dataCheck = JSON.parse(sessionStorage.getItem("nextProcessCheck"))

        if(dataCheck == "1" || dataCheck === "1"){

            let IRData = JSON.parse(sessionStorage.getItem("IRData"))

            this.setState({

                receivedby                  : IRData.employeeName,
                irReferenceNo               : IRData.irReferenceNo,
                nteRefNo                    : IRData.NTEReferenceNo,
                selectedClientName          : IRData.client,
                selectedEmployeeName        : IRData.employeeName,
                selectedEmployeeNo          : IRData.employeeNo,
                selectedPosition            : IRData.position,
                selectedEmployeeBranch      : IRData.location,
                disableEdit                 : true,
                employeePlaceHolder         : IRData.employeeName,
                dateReceive                 : new Date(IRData.dateOfNTE),
                
            })

            this.state.receivedBy = IRData.employeeName
            console.log(IRData.employeeName)
            console.log(this.state.receivedBy)

        }
        if(dataCheck == "2" || dataCheck === "2"){
            let IRData = JSON.parse(sessionStorage.getItem("IRData"))
            if(IRData.status == "RecordNODPenalty" ){
                this.setState({
                    disableSubmit:true
                })
            }
            this.getNODbyDocNo()
        }

    }

    getNODbyDocNo=()=>{

        let IRData = JSON.parse(sessionStorage.getItem("IRData"))
        this.setState({  isloading:true })
        // console.log("IRData")
        // console.log( IRData.NODreferenceNo )
        // console.log( "IRData.irReferenceNo" )
        // console.log( IRData.irReferenceNo )
        /* if(this.state.selectedEmployeeName !== ""){
            filter_data ={
                'ClientId' : this.state.selectedClientId,
                "employeeName": this.state.selectedEmployeeName
            }
        }
        if(this.state.selectedEmployeeName == ""){
            filter_data ={
                'ClientId' : this.state.selectedClientId,
            }
        } */
        let  filterNOD=IRData.NODReferenceNo

        let filter_data ={"nodaRefNo" : filterNOD }
        
        const getParams ={
            "_collection" : "NOD",
            "filter_data" : filter_data
        }

        console.log("axios start getNODbyDocNo")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data
            console.log("axios start getNODbyDocNo")
            console.log(data)
            if(data.NOD.length > 0){

                const itemUsersDataLists = this.buildListNOD(data["NOD"])
            
                this.state.itemUserDataList  =  itemUsersDataLists

                var obj = (data);
                var res = [];
                
                data.NOD.map(function(itm,i){
                    let strJson = "{" + itm.substring(46, itm.length)
                    let json = JSON.parse(strJson.replace(/'/g,'"'));
                    res.push(json)
                    console.log(json)
                })
                
                console.log("axios start res")
                console.log(res)
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                        
                    let obj = {

                        'id'                        :   this.state.itemUserDataList[i]['id'].replace('"', '').replace('"', ''),
                       
                    }

                    List.push(obj)
                    console.log("obj start obj")
                    console.log(List)
                }

                this.setState({
                    NODObjId: List[0].id,
                    isloading:false,
                    articleDataList             :      res[0].articleList1,  
                    articleDataList2            :      res[0].articleList2 ,   
                    articleDataList3            : res[0].articleList3,   
                    articleDataList4            : res[0].articleList4,   
                    articleDataList5            : res[0].articleList5,   
                    sectionDataList             : res[0].sectionList1,
                    sectionDataList2            : res[0].sectionList2,
                    sectionDataList3            : res[0].sectionList3,
                    sectionDataList4            : res[0].sectionList4,
                    sectionDataList5            : res[0].sectionList5,
                    selectedClientName          : res[0].client,
                    selectedClientId            : res[0].ClientId,
                    selectedEmployeeName        : res[0].employeeName,
                    selectedPosition            : res[0].position,
                    selectedEmployeeNo          : res[0].employeeNo,
                    selectedEmployeeBranch      : res[0].location,
                    date                    : new Date(res[0].date),
                    nteRefNo                : res[0].nteRefNo,
                    nodaRefNo               : res[0].nodaRefNo,
                    dateReceive             :new Date(IRData.dateOfNTE),
                    
                    preparedBy            : res[0].preparedBy,
                    prepareByPosition     : res[0].prepareByPosition,
                    notedBy               : res[0].notedBy,
                    notedByPosition       : res[0].notedByPosition,
                    receivedby            : res[0].receivedBy,

                    paragraph             :   res[0].paragraph,
                    addedparagraph1       :   res[0].addedparagraph1,
                    addedparagraph2       :   res[0].addedparagraph2,
                    addedparagraph3       :   res[0].addedparagraph3,
                    addedparagraph4       :   res[0].addedparagraph4,
                    addedparagraph5       :   res[0].addedparagraph5,
                    addedparagraph6       :   res[0].addedparagraph6,
                    quotedStatement       :   res[0].quotedStatement,
                    addedparagraph1Check    : res[0].addedparagraph1.length > 0 ? true:false,
                    addedparagraph2Check    : res[0].addedparagraph2.length > 0 ? true:false,
                    addedparagraph3Check    : res[0].addedparagraph3.length > 0 ? true:false,
                    addedparagraph4Check    : res[0].addedparagraph4.length > 0 ? true:false,
                    addedparagraph5Check    : res[0].addedparagraph5.length > 0 ? true:false,
                    addedparagraph6Check    : res[0].addedparagraph6.length > 0 ? true:false,
                    quotedStatementCheck    : res[0].quotedStatement.length > 0 ? true:false,

                    irReferenceNo               : IRData.irReferenceNo,
                })
                
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    buildListNOD(data) {
        // console.log("data start")
        // console.log(data )
        let itemList =[]
    
        let idList                      =[]

        for (let i = 0; i < data.length; i++) {            
    
            let s1 = data[i].split("', ")
           
            
            let IdClean0                            = s1[0].split("': ")[1]           
            let idClean1                            = IdClean0.replace("ObjectId(","").replace(")","").replace("{","").replace(", 'client","")  
            idList.push(idClean1.replace("'","").replace("'","").replace(" ",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                         :   idList[i],
            
            }
            itemList.push(obj)
            console.log("itemList start")
            console.log(itemList )
            console.log("itemList" )
            
        }
        return itemList
    }


    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {

            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
            
            console.log("this.state.getClientList")
            console.log(this.state.getClientList)
            console.log("this.state.getClientList")
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }


    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        console.log("Client selectedClientId " + this.state.selectedClientId );
        console.log("Client selectedClientName " + this.state.selectedClientName );
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})
        
        this.getEmployees();
    }


    getEmployees(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            console.log("Employee List start ");
            console.log(res.data);
            console.log("Employee List end");
            const data = res.data
            if(data.status=="1"){
                this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
                console.log("this.state.getEmployeeList")
                console.log(this.state.getEmployeeList)
                console.log("this.state.getEmployeeList")
            }else{
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 
            }
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
        
    }

    onChangeEmployeesList = (e) => {
       
        if(e.length==0)
        {
            this.setState({getEmployeeListData: null, selectedPayrollpayPeriod: '', selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
          
            return
        }
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedEmployeeName = e[0].employeeName
        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeNo = e[0].employeeNo
        this.state.selectedEmployeeBranch = e[0].locationName
        this.state.selectedPosition = e[0].position
        this.state.receivedby = e[0].employeeName
        /*    console.log("Work selectedEmployeeName   " + this.state.selectedEmployeeNo );
        console.log("Employee List start ");
        console.log(this.state.selectedEmployeeBranch);
        console.log("Employee List end"); */

        /*   if(this.state.selectedPayrollpayPeriod == ""){
            this.state.selectedPayrollPeriodsId = e[0].payPeriodId
            this.state.selectedPayrollpayPeriod = e[0].payPeriod
        } */

        //this.employeeTransactionsList();


       /*  this.setState({
            position:this.state.selectedPosition,
            location:this.state.selectedEmployeeBranch

        }) */
        this.setState({isloading:false})
    }

   /*  onChangeEmployeesNo(e){
        this.setState({selectedEmployeeNo : e.target.value})
        console.log(e.target.value)
    }
    onChangePosition(e){
        this.setState({position : e.target.value})
        console.log(e.target.value)
    }
    onChangeLocation(e){
        this.setState({location : e.target.value})
        console.log(e.target.value)
    } */

    onChangePreparedbyClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedPreparedbyClient=""
            this.state.selectedPreparedbyClientId=""
            return
        } 
        this.state.selectedPreparedbyClientId = e[0].id
        this.state.selectedPreparedbyClient = e[0].name
        console.log("Client selectedClientId " + this.state.selectedClientId );
        console.log("Client selectedClientName " + this.state.selectedClientName );
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})
        
        this.getEmployeesPreparedby();
        //this.getEmployees();
    }

    getEmployeesPreparedby(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedPreparedbyClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            console.log("Employee List start ");
            console.log(res.data);
            console.log("Employee List end");
            const data = res.data

            if(data.status=="1")
                this.setState({getEmployeepreparedBy : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
            else
                this.setState({getEmployeepreparedBy : [], getEmployeeNoList : [],isloading:false}) 
                
        })
        .catch(error=>{

           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })

        })
        
    }


    handleInsertPenalty = () => {
        //alert("Test")
        this.setState({
            modalInsertPenalty:   true
        })
    }

    handleCocReference = ()=>{
        this.setState({
            modalCOCReference:   true
        })
    }

    handleModalClosePenalty = (e) =>{
        this.setState({
            modalInsertPenalty:   false,
        })
    }

    handleModalShowCOC = (e) =>{
        this.setState({
            modalCoCReferenceShow: true,
        })
    }

    fileToBase64=(file, cb)=>{
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () { 
            cb(null , reader.result)
        }
        reader.onerror = function (error) {
            cb(error,null )
        }
    }


    handleExplanationLetter=(e , target)=>{
        this.setState({
            explanationLetter:e.target.value
        })
        // console.log("explanationLetter")
        // console.log(e.target.value)
        // console.log(e.target)
        // console.log(target)
        // console.log("explanationLetter")

        if(e.target.files < 1 || !e.target.validity.valid){
            return
        }

        let base64data = ""
        this.fileToBase64(e.target.files[0], (err , result)=> {
            
            if(result){
                base64data = result
                console.log(result)
                this.state.explanationLetterBase64 = result
            }
        })
        console.log("base64data")
        console.log(base64data)
        console.log("base64data")
    }

    handleAttachNTE=(e , target)=>{
        this.setState({
            attachNTE:e.target.value
        })

        // console.log("attachNTE")
        // console.log(e.target.value)
        // console.log("attachNTE")

        if(e.target.files < 1 || !e.target.validity.valid){
            return
        }

        let base64data = ""
        this.fileToBase64(e.target.files[0], (err , result)=> {
            
            if(result){
                base64data = result
                console.log(result)
                this.state.attachNTEBase64 = result
            }
        })

    }

    handleChangedate =date=>{
        this.setState({
            date: date
        })
    }

    handleChangedateReceive =date=>{
        this.setState({
            dateReceive: date
        })
    }

    handleModalClose = (e) =>{
        
        this.setState({modalCoCReferenceShow: false})
        this.getViewDraftMemo();;

    }

    getViewDraftMemo=()=>{
        let viewdataCOCRef= JSON.parse(sessionStorage.getItem("viewdataCOCRefTmp"))

        if(viewdataCOCRef == "1" || viewdataCOCRef === "1" ){
            let dataCOCRefarticle1Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefarticle1"))
            let dataCOCRefarticle2Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefarticle2"))
            let dataCOCRefarticle3Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefarticle3"))
            let dataCOCRefarticle4Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefarticle4"))
            let dataCOCRefarticle5Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefarticle5"))
            let dataCOCRefsection1Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefsection1"))
            let dataCOCRefsection2Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefsection2"))
            let dataCOCRefsection3Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefsection3"))
            let dataCOCRefsection4Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefsection4"))
            let dataCOCRefsection5Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefsection5"))

            this.setState({
                articleDataList:dataCOCRefarticle1Tmp,
                sectionDataList:dataCOCRefsection1Tmp,

                articleDataList2:dataCOCRefarticle2Tmp,
                sectionDataList2:dataCOCRefsection2Tmp,

                articleDataList3:dataCOCRefarticle3Tmp,
                sectionDataList3:dataCOCRefsection3Tmp,

                articleDataList4:dataCOCRefarticle4Tmp,
                sectionDataList4:dataCOCRefsection4Tmp,

                articleDataList5:dataCOCRefarticle5Tmp,
                sectionDataList5:dataCOCRefsection5Tmp,
               

                cocReferences: true
            })
          
        }else{

            this.setState({
                cocReferences: false
            })

        }
    }

    handleChangeParagraph=(e)=>{
        this.setState({
            paragraph   : e.target.value
        })

        //console.log(this.state.paragraph)
    }

    handleAddedParagraph1=(e)=>{

        if(this.state.addedparagraph5Check == true || this.state.addedparagraph5Check === true){
            
            this.setState({
                addedparagraph6Check:true
            })
        }

        if(this.state.addedparagraph4Check == true || this.state.addedparagraph4Check === true){
            
            this.setState({
                addedparagraph5Check:true
            })
        }

        if(this.state.addedparagraph3Check == true || this.state.addedparagraph3Check === true){
            
            this.setState({
                addedparagraph4Check:true
            })
        }

        if(this.state.addedparagraph2Check == true || this.state.addedparagraph2Check === true){
            
            this.setState({
                addedparagraph3Check:true
            })
        }

        if(this.state.addedparagraph1Check == true || this.state.addedparagraph1Check === true){
            
            this.setState({
                addedparagraph2Check:true
            })
        }

        console.log("start")
        this.setState({
            addedparagraph1Check:true
        })
     
    }

    handleChangeAddedParagraph1=(e)=>{
        this.setState({
            addedparagraph1:e.target.value
        })
        
    }

    handleChangeAddedParagraph2=(e)=>{
        this.setState({
            addedparagraph2:e.target.value
        })
        
    }

    handleChangeAddedParagraph3=(e)=>{
        this.setState({
            addedparagraph3:e.target.value
        })
        
    }

    handleChangeAddedParagraph4=(e)=>{
        this.setState({
            addedparagraph4:e.target.value
        })
        
    }

    handleChangeAddedParagraph5=(e)=>{
        this.setState({
            addedparagraph5:e.target.value
        })
        
    }

    handleChangeAddedParagraph6=(e)=>{
        this.setState({
            addedparagraph6:e.target.value
        })
        
    }

    handleQuotedStatementCheck=(e)=>{
        this.setState({
            quotedStatementCheck:true
        })
    }

    handleChangeQuotedStatement=(e)=>{
        this.setState({
            quotedStatement   : e.target.value
        })
        //console.log(this.state.quotedStatement)
    }

    handleOnChangePreparedBy=(e)=>{
        // this.setState({
        //     preparedBy   : e.target.value
        // })
        // //console.log(this.state.preparedBy)

        if(e.length==0)
        {
            this.state.preparedBy = ""
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.preparedBy = e[0].employeeName
        this.state.prepareByPosition = e[0].position
        console.log(e[0])
        console.log(this.state.preparedBy)
        console.log(this.state.prepareByPosition)

        this.setState({
            isloading:false,
        })
        console.log("after loading false")
        console.log(this.state.preparedBy)
    }

    handleOnChangeNotedBy=(e)=>{
        // this.setState({
        //     notedBy   : e.target.value
        // })
        // //console.log(this.state.notedBy)

        if(e.length==0)
        {
            this.state.notedBy = ""
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.notedBy = e[0].employeeName
        this.state.notedByPosition = e[0].position
        console.log(this.state.notedBy)
        console.log(this.state.notedByPosition)

        this.setState({
            isloading:false,
            notedBy: e[0].employeeName,
            notedByPosition: e[0].position,
        })
    }

    handleOnChangePrepareByPosition=(e)=>{
        this.setState({
            prepareByPosition   : e.target.value
        })
        //console.log(this.state.prepareByPosition)
    }

    handleOnChangeReceivedBy=(e)=>{
        this.setState({
            receivedby   : e.target.value
        })
        console.log(this.state.receivedby)
    }

    getNODARefNo = (e)=>{
        /* this.setState({
            isloading:true
        })

        let filter_data ={}
        const getParams ={
            "_collection" : "NODA",
            "filter_data" : filter_data
        }
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            const data = res.data
            console.log("Raw Json")
            console.log(data)
            console.log("End Raw Json")
            var obj = (data);
            var res = [];
            
            data.NODA.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                res.push(json)
            })

            console.log("Parsed Json below")
            console.log(res)
            let refNo = JSON.stringify(res.length)

            this.setState({
                nodaRefNo:refNo,
            })
                 
        })
        .catch(error=>{
            console.log("error: " + error)
        })

        */
        
        this.setState({
           // nodaRefNo: newIRRef,
            isloading:false
        })
        let dataCheck = JSON.parse(sessionStorage.getItem("nextProcessCheck"))
        if(dataCheck == "2" || dataCheck === "2"){
           this.UpdateNOD()
        }else{

            this.handleSaveClick()

        }
        

    }

    //save NOD
    handleSaveClick =(e)=>{

        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');
        let _date = moment(this.state.date).format('MM/DD/YYYY');
        let _dateReceive = moment(this.state.dateReceive).format('MM/DD/YYYY');
        let newdate = new Date()
        let NTEDate        = moment(newdate).format('MMDDYYYY');
        let newNODRef        = "PHR"+this.state.selectedEmployeeNo+NTEDate
        
        if(this.state.date === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "please Enter Date",
                fade            :   true
            })
            return
        }

        if(this.state.dateReceived === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "please Enter date Receive",
                fade            :   true
            })
            return
        }

        let data = {

            "client"                :   this.state.selectedClientName,
            "employeeName"          :   this.state.selectedEmployeeName,
            "position"              :   this.state.selectedPosition,
            "employeeNo"            :   this.state.selectedEmployeeNo,
            "location"              :   this.state.selectedEmployeeBranch,
            "date"                  :   _date,
            "nodaRefNo"             :   newNODRef,
            "nteRefNo"              :   this.state.nteRefNo,
            "dateReceive"           :   _dateReceive,
            "paragraph"             :   this.state.paragraph.replace(/'/g, '').replace(/"/g, ''),
            "quotedStatement"       :   this.state.quotedStatement.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph1"       :   this.state.addedparagraph1.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph2"       :   this.state.addedparagraph2.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph3"       :   this.state.addedparagraph3.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph4"       :   this.state.addedparagraph4.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph5"       :   this.state.addedparagraph5.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph6"       :   this.state.addedparagraph6.replace(/'/g, '').replace(/"/g, ''),
            "articleList1"          :   this.state.articleDataList,  
            "articleList2"          :   this.state.articleDataList2,   
            "articleList3"          :   this.state.articleDataList3,   
            "articleList4"          :   this.state.articleDataList4,   
            "articleList5"          :   this.state.articleDataList5,   
            "sectionList1"          :   this.state.sectionDataList,
            "sectionList2"          :   this.state.sectionDataList2,
            "sectionList3"          :   this.state.sectionDataList3,
            "sectionList4"          :   this.state.sectionDataList4,
            "sectionList5"          :   this.state.sectionDataList5,
            "preparedBy"            :   this.state.preparedBy,
            "prepareByPosition"     :   this.state.prepareByPosition,
            "notedBy"               :   this.state.notedBy,
            "notedByPosition"       :   this.state.notedByPosition,
            "receivedBy"            :   this.state.receivedby,
            "isDraft"               :   "0",
            "isDeleted"             :   "0",
            "createdby"             :   this.state.userinfo.userId,
            "createddate"           :   createddate,
            "modifiedby"            :   'user007',
            "modifieddate"          :   '0',
            "isModified"            :   '0',    
            // "attachNTE"             :   this.state.attachNTEBase64,
            // "explanationLetter"     :   this.state.explanationLetterBase64,           

        }
       
        const addParams = {
          "_collection" : "NOD",
          "doc_data"    : data
        }
        console.log("save")
        console.log(addParams)
        console.log("save")
        

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/add", addParams)
        //.post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            console.log("success")
            console.log(res.data)
            this.setState({
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Data had successfully Save" ,
                fade            :   true,
                disableSubmit   :   true,
            })   
            this.getIRobjID()
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    UpdateNOD =(e)=>{

        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');
        let _date = moment(this.state.date).format('MM/DD/YYYY');
        let _dateReceive = moment(this.state.dateReceive).format('MM/DD/YYYY');
        let newdate = new Date()
        let NTEDate        = moment(newdate).format('MMDDYYYY');
        let newNODRef        = "PHR"+this.state.selectedEmployeeNo+NTEDate
        
        if(this.state.date === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "please Enter Date",
                fade            :   true
            })
            return
        }

        if(this.state.dateReceived === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "please Enter date Receive",
                fade            :   true
            })
            return
        }

        let data = {

            "client"                :   this.state.selectedClientName,
            "employeeName"          :   this.state.selectedEmployeeName,
            "position"              :   this.state.selectedPosition,
            "employeeNo"            :   this.state.selectedEmployeeNo,
            "location"              :   this.state.selectedEmployeeBranch,
            "date"                  :   _date,
            "nodaRefNo"             :   this.state.nodaRefNo,
            "nteRefNo"              :   this.state.nteRefNo,
            "dateReceive"           :   _dateReceive,
            "paragraph"             :   this.state.paragraph.replace(/'/g, '').replace(/"/g, ''),
            "quotedStatement"       :   this.state.quotedStatement.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph1"       :   this.state.addedparagraph1.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph2"       :   this.state.addedparagraph2.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph3"       :   this.state.addedparagraph3.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph4"       :   this.state.addedparagraph4.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph5"       :   this.state.addedparagraph5.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph6"       :   this.state.addedparagraph6.replace(/'/g, '').replace(/"/g, ''),
            "articleList1"          :   this.state.articleDataList,  
            "articleList2"          :   this.state.articleDataList2,   
            "articleList3"          :   this.state.articleDataList3,   
            "articleList4"          :   this.state.articleDataList4,   
            "articleList5"          :   this.state.articleDataList5,   
            "sectionList1"          :   this.state.sectionDataList,
            "sectionList2"          :   this.state.sectionDataList2,
            "sectionList3"          :   this.state.sectionDataList3,
            "sectionList4"          :   this.state.sectionDataList4,
            "sectionList5"          :   this.state.sectionDataList5,
            "preparedBy"            :   this.state.preparedBy,
            "prepareByPosition"     :   this.state.prepareByPosition,
            "notedBy"               :   this.state.notedBy,
            "notedByPosition"       :   this.state.notedByPosition,
            "receivedBy"            :   this.state.receivedby,
            "isDraft"               :   "0",
            "isDeleted"             :   "0",
            "createdby"             :   this.state.userinfo.userId,
            "createddate"           :   createddate,
            "modifiedby"            :   'user007',
            "modifieddate"          :   '0',
            "isModified"            :   '0',    
            // "attachNTE"             :   this.state.attachNTEBase64,
            // "explanationLetter"     :   this.state.explanationLetterBase64,           

        }
       
        const updateParams = {
          "_collection" : "NOD",
          "doc_data"    : data,
          "_id"         : this.state.NODObjId,
        }
        console.log("save")
        console.log(updateParams)
        console.log("save")
        //this.getIRobjID()

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/update_one", updateParams)
        //.post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            // console.log("success")
            // console.log(res.data)
            this.setState({
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Data had successfully Save" ,
                fade            :   true,
                disableSubmit   :   true,
            })   
            this.getIRobjID()
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    buildListEDMonitoring(data) {
        // console.log("data start")
        // console.log(data )
        // console.log("data start")
        // console.log(data )
        // console.log("data" )
        let itemList =[]
    
        let idList                      =[]
        let quotedStatementList         =[]
        let dateOfNTEList               =[]
        let clientList                  =[]
        let createdbyList               =[]
        let createddateList             =[]
        let cycleOfDaysList             =[]
        let dateOfIncidentList          =[]
        let dateOfNODList               =[]
        let dateOfNOHList               =[]
        let dateRecordNodaPenaltyList   =[]
        let employeeNameList            =[]
        let employeeNoList              =[]
        let irReferenceNoList           =[]
        let isDeletedList               =[]
        let isDraftList                 =[]
        let isModifiedList              =[]
        let locationList                =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let positionList                =[]
        let retailgroupList             =[]
        let statusList                  =[]
        let articleList                 =[]
        let categoryList                =[]
        let sectionList                 =[]
        let NTEReferenceNoList                 =[]
        let NODReferenceNoList                 =[]
        let NOHReferenceNoList                 =[]
        let ClientIdList                 =[]

        let NTHoffenseList                 =[]
        let penaltyList                 =[]
        let monetaryChargeList                 =[]
        let statusOfIssueList                 =[]

        for (let i = 0; i < data.length; i++) {            
    
            let s1 = data[i].split("', '")
            // console.log("s1 start")
            // console.log(s1 )
            // console.log("s1" )
          
            let IdClean0                            = s1[0].split("': ")[1]           
            let idClean1                            = IdClean0.replace("ObjectId(","").replace(")","").replace("{","").replace(", 'action","")  
            let quotedStatementTmp                  = s1[1].split("': ")[1]    
            let dateOfNTETmp                        = s1[2].split("': ")[1]           
            let clientTmp                        = s1[3].split("': ")[1]        
            let createdbyTmp                     = s1[4].split("': ")[1]          
            let createddateTmp                   = s1[5].split("': ")[1]
            let cycleOfDaysTmp                   = s1[6].split("': ")[1]
            let dateOfIncidentTmp                = s1[7].split("': ")[1]
            let dateOfNODTmp                     = s1[8].split("': ")[1]
            let dateOfNOHTmp                     = s1[9].split("': ")[1]
            let dateRecordNodaPenaltyTmp         = s1[10].split("': ")[1]
            let employeeNameTmp                  = s1[11].split("': ")[1]
            let employeeNoTmp                    = s1[12].split("': ")[1]
            let irReferenceNoTmp                 = s1[13].split("': ")[1]
            let isDeletedTmp                     = s1[14].split("': ")[1]
            let isDraftTmp                       = s1[15].split("': ")[1]
            let isModifiedListTmp                = s1[16].split("': ")[1]            
            let locationTmp                      = s1[17].split("': ")[1]
            let modifiedbyListTmp                = s1[18].split("': ")[1]
            let modifieddateListTmp              = s1[19].split("': ")[1]
            let positionTmp                      = s1[20].split("': ")[1]
            let retailgroupTmp                   = s1[21].split("': ")[1]
            let statusTmp                        = s1[22].split("': ")[1]
            let articleTmp                       = s1[23].split("': ")[1]
            let categoryTmp                      = s1[24].split("': ")[1]
            let sectionTmp                       = s1[25].split("': ")[1]
            let NTEReferenceNoTmp                = s1[26].split("': ")[1]
            let NODReferenceNoTmp                = s1[27].split("': ")[1]
            let NOHReferenceNoTmp                = s1[28].split("': ")[1]
            let ClientIdTmp                      = s1[29].split("': ")[1]
            let NTHoffenseTmp                    = s1[30].split("': ")[1]
            let penaltyTmp                       = s1[31].split("': ")[1]
            let monetaryChargeTmp                = s1[32].split("': ")[1]
            let statusOfIssueTmp                 = s1[33].split("': ")[1]
          

            idList.push(idClean1.replace("'","").replace("'","").replace(" ",""))
            quotedStatementList.push(quotedStatementTmp.replace("'",""))
            dateOfNTEList.push(dateOfNTETmp.replace("'","").replace(" ",""))           
            clientList.push(clientTmp.replace("'",""))
            createdbyList.push(createdbyTmp.replace("'",""))
            createddateList.push(createddateTmp.replace("'",""))
            cycleOfDaysList.push(cycleOfDaysTmp.replace("'",""))
            dateOfIncidentList.push(dateOfIncidentTmp.replace("'",""))
            dateOfNODList.push(dateOfNODTmp.replace("'",""))
            dateOfNOHList.push(dateOfNOHTmp.replace("'",""))
            dateRecordNodaPenaltyList.push(dateRecordNodaPenaltyTmp.replace("'",""))
            employeeNameList.push(employeeNameTmp.replace("'",""))
            employeeNoList.push(employeeNoTmp.replace("'",""))
            irReferenceNoList.push(irReferenceNoTmp.replace("'",""))
            isDeletedList.push(isDeletedTmp.replace("'",""))
            isDraftList.push(isDraftTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'",""))
            locationList.push(locationTmp.replace("'",""))
            positionList.push(positionTmp.replace("'",""))
            retailgroupList.push(retailgroupTmp.replace("'",""))
            statusList.push(statusTmp.replace("'",""))
            articleList.push(articleTmp.replace("'",""))
            categoryList.push(categoryTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            sectionList.push(sectionTmp.replace("'",""))
            NTEReferenceNoList.push(NTEReferenceNoTmp.replace("'",""))
            NODReferenceNoList.push(NODReferenceNoTmp.replace("'",""))
            NOHReferenceNoList.push(NOHReferenceNoTmp.replace("'",""))
            ClientIdList.push(ClientIdTmp.replace("'",""))

            NTHoffenseList.push(NTHoffenseTmp.replace("'",""))
            penaltyList.push(penaltyTmp.replace("'",""))
            monetaryChargeList.push(monetaryChargeTmp.replace("'",""))
            statusOfIssueList.push(statusOfIssueTmp.replace("'","").replace("'}",""))
            
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                         :   idList[i],
                'quotedStatement'            :   quotedStatementList[i],
                'dateOfNTE'                  :   dateOfNTEList[i],
                'client'                     :   clientList[i],              
                'cycleOfDays'                :   cycleOfDaysList[i],
                'dateOfIncident'             :   dateOfIncidentList[i],
                'dateOfNOD'                  :   dateOfNODList[i],
                'dateOfNOH'                  :   dateOfNOHList[i],
                'dateRecordNodaPenalty'      :   dateRecordNodaPenaltyList[i],
                'employeeName'               :   employeeNameList[i],
                'employeeNo'                 :   employeeNoList[i],
                'irReferenceNo'              :   irReferenceNoList[i],              
                'location'                   :   locationList[i],
                'position'                   :   positionList[i],
                'retailgroup'                :   retailgroupList[i],
                'status'                     :   statusList[i],
                'article'                    :   articleList[i],
                'category'                   :   categoryList[i],
                'section'                    :   sectionList[i],
                'isDraft'                    :   isDraftList[i],
                'isDeleted'                  :   isDeletedList[i],
                'createdby'                  :   createdbyList[i],
                'createddate'                :   createddateList[i],
                'modifiedby'                 :   modifiedbyList[i],
                'modifieddate'               :   modifieddateList[i],
                'isModified'                 :   isModifiedList[i],
                'NTEReferenceNo'             :   NTEReferenceNoList[i],
                'NODReferenceNo'             :   NODReferenceNoList[i],
                'NOHReferenceNo'             :   NOHReferenceNoList[i],
                'ClientId'                   :   ClientIdList[i],                
                "NTHoffense"                 :   NTHoffenseList[i],
                "penalty"                    :   penaltyList[i],
                "monetaryCharge"             :   monetaryChargeList[i],
                "statusOfIssue"              :   statusOfIssueList[i],
            
            }
            itemList.push(obj)
            // console.log("itemList start")
            // console.log(itemList )
            // console.log("itemList" )
            
        }
        return itemList
    }

    getIRobjID=()=>{
       
        let filter_data ={'irReferenceNo':this.state.irReferenceNo}

        const getParams ={
            "_collection" : "EDMonitoring",
            "filter_data" : filter_data
        }

        console.log("axios start")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data

            if(data.EDMonitoring.length > 0){

                const itemUsersDataLists = this.buildListEDMonitoring(data["EDMonitoring"])
            
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                        
                    let obj = {

                        'id'                        :   this.state.itemUserDataList[i]['id'],
                        'quotedStatement'           :   this.state.itemUserDataList[i]['quotedStatement'],
                        'dateOfNTE'                 :   this.state.itemUserDataList[i]['dateOfNTE'],
                        'client'                    :   this.state.itemUserDataList[i]['client'],              
                        'cycleOfDays'               :   this.state.itemUserDataList[i]['cycleOfDays'],
                        'dateOfIncident'            :   this.state.itemUserDataList[i]['dateOfIncident'],
                        'dateOfNOD'                 :   this.state.itemUserDataList[i]['dateOfNOD'],
                        'dateOfNOH'                 :   this.state.itemUserDataList[i]['dateOfNOH'],
                        'dateRecordNodaPenalty'     :   this.state.itemUserDataList[i]['dateRecordNodaPenalty'],
                        'employeeName'              :   this.state.itemUserDataList[i]['employeeName'],
                        'employeeNo'                :   this.state.itemUserDataList[i]['employeeNo'],
                        'irReferenceNo'             :   this.state.itemUserDataList[i]['irReferenceNo'],              
                        'location'                  :   this.state.itemUserDataList[i]['location'],
                        'position'                  :   this.state.itemUserDataList[i]['position'],
                        'retailgroup'               :   this.state.itemUserDataList[i]['retailgroup'],
                        'status'                    :   this.state.itemUserDataList[i]['status'],
                        'article'                   :   this.state.itemUserDataList[i]['article'],
                        'category'                  :   this.state.itemUserDataList[i]['category'],
                        'section'                   :   this.state.itemUserDataList[i]['section'],
                        'isDraft'                   :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'                 :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'                 :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'               :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'                :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'              :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'                :   this.state.itemUserDataList[i]['isModified'],
                        'NTEReferenceNo'            :   this.state.itemUserDataList[i]['NTEReferenceNo'],
                        'NODReferenceNo'            :   this.state.itemUserDataList[i]['NODReferenceNo'],
                        'NOHReferenceNo'            :   this.state.itemUserDataList[i]['NOHReferenceNo'],
                        'ClientId'                    :   this.state.itemUserDataList[i]['ClientId'],
                        "NTHoffense"                :   this.state.itemUserDataList[i]['NTHoffense'],  
                        "penalty"                   :   this.state.itemUserDataList[i]['penalty'],  
                        "monetaryCharge"            :   this.state.itemUserDataList[i]['monetaryCharge'],  
                        "statusOfIssue"             :   this.state.itemUserDataList[i]['statusOfIssue'],  
                        'action'                    :   "1",

                    }
                    List.push(obj)
                }

                this.setState({
                    IRobjId: List[0].id,
                    isloading:false
                })
    
                this.updateIRstatus(List)     
                
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    }

    updateIRstatus=(toirupdate)=>{
        console.log(toirupdate)
        console.log("updateIRstatus")
        let dataCheck = JSON.parse(sessionStorage.getItem("nextProcessCheck"))
      /*   if(dataCheck == "2" || dataCheck === "2"){
           this.UpdateNOD()
        } */
        let datetmp = moment(this.state.date).format('MM/DD/YYYY');

        let IRData = toirupdate[0]

        let joinArticle = ""
        let article1    =""
        let article2    =""
        if(this.state.articleNo !== "" || this.state.articleNo != ""){
           
            article1 = this.state.articleNo+";"

        }
        if(this.state.articleNo2 !== "" || this.state.articleNo2 != ""){

           article2 = this.state.articleNo2+";"
            
        }  
        joinArticle = article1+article2
        
        let joinCategory = ""
        let category1    =""
        let category2    =""
        let category3    =""
        if(this.state.category3 !== "" || this.state.category3 != ""){
            category3 = this.state.category3+";"

        }
        if(this.state.category2 !== "" || this.state.category2 != ""){
            category2 = this.state.category2+";"
            
        }
        if(this.state.category !== "" || this.state.category != ""){
            category1 = this.state.category+";"
            
        }
        joinCategory = category1+category2+category3

        let joinsection     = ""
        let sectionNo       =""
        let sectionNo2      =""
        let sectionNo3      =""
        if(this.state.sectionNo3 !== "" || this.state.sectionNo3 != ""){
            sectionNo3 = this.state.sectionNo3+";"

        }
        if(this.state.sectionNo2 !== "" || this.state.sectionNo2 != ""){
            sectionNo2 = this.state.sectionNo2+";"
            
        }
        if(this.state.sectionNo !== "" || this.state.sectionNo != ""){
            sectionNo = this.state.sectionNo+";"
            
        }
        joinsection = sectionNo+sectionNo2+sectionNo3
        let newdate = new Date()
        let NTEDate        = moment(newdate).format('MMDDYYYY');
        let newNODRef        = "PHR"+this.state.selectedEmployeeNo+NTEDate

        let data = {
        
            "action"                : "1",
            "quotedStatement"       : toirupdate[0].quotedStatement,
            "dateOfNTE"             : toirupdate[0].dateOfNTE,
            "client"                : toirupdate[0].client,
            "createdby"             : toirupdate[0].createdby,
            "createddate"           : toirupdate[0].createddate,
            "cycleOfDays"           : toirupdate[0].cycleOfDays,
            "dateOfIncident"        : toirupdate[0].dateOfIncident,
            "dateOfNOD"             : datetmp,
            "dateOfNOH"             : toirupdate[0].dateOfNOH,            
            "dateRecordNodaPenalty" : toirupdate[0].dateRecordNodaPenalty,
            "employeeName"          : toirupdate[0].employeeName,
            "employeeNo"            : toirupdate[0].employeeNo,
            "irReferenceNo"         : toirupdate[0].irReferenceNo,
            "isDeleted"             : toirupdate[0].isDeleted,
            "isDraft"               : toirupdate[0].isDraft,
            "isModified"            : toirupdate[0].isModified,
            "location"              : toirupdate[0].location,
            "modifiedby"            : toirupdate[0].modifiedby,
            "modifieddate"          : toirupdate[0].modifieddate,
            "position"              : toirupdate[0].position,            
            "retailgroup"           : toirupdate[0].retailgroup,
            "status"                : "NOD",
            "article"               : toirupdate[0].article,
            "category"              : toirupdate[0].category,
            "section"               : toirupdate[0].section,
            "NTEReferenceNo"        : toirupdate[0].NTEReferenceNo,
            "NODReferenceNo"        : dataCheck == "2" ? this.state.nodaRefNo:newNODRef,
            "NOHReferenceNo"        : toirupdate[0].NOHReferenceNo,
            'ClientId'              : toirupdate[0].ClientId,
            "NTHoffense"            :toirupdate[0].NTHoffense,
            "penalty"               :toirupdate[0].penalty,
            "monetaryCharge"        :toirupdate[0].monetaryCharge,
            "statusOfIssue"         :"Pending",


        }

        const updateParams = {

            "_collection" : "EDMonitoring",
            "doc_data"    : data,
            "_id"         : toirupdate[0].id,

        }

        console.log("updateIRstatus")
        console.log(updateParams)       
        console.log("updateIRstatus")

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/update_one", updateParams)
        //.post("http://134.209.99.190:8088/action/update_one", updateParams)
        .then(res =>{

            this.setState({
                isloading           :   false,
                alerttype           :   "Success!",
                isshow              :   true,
                color               :   "success",
                message             :   "Data had successfully Save" ,
                fade                :   true,
                disabledGenerate    :   false,
                disabledSave        :   true,
            
            })
            let nextProcessCheck = "0"
            sessionStorage.setItem("nextProcessCheck", JSON.stringify(nextProcessCheck)) 
        
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })

        })

    }

    handleToExportNODA=()=>{

        if(this.state.eSignatureNotedBy == ""){
           
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please insert e-signature",
                fade            :   true
            })
            return
            
        }

        if(this.state.eSignaturePreparedBy == ""){
          
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please insert e-signature",
                fade            :   true
            })
            return
          
        }

        if(this.state.eSignatureNotedBy == null){
           
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please insert e-signature",
                fade            :   true
            })
            return
            
        }

        if(this.state.eSignaturePreparedBy == null){
          
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please insert e-signature",
                fade            :   true
            })
            return
          
        } 

        if(this.state.eSignatureNotedBy == undefined){
           
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please insert e-signature",
                fade            :   true
            })
            return
            
        }

        if(this.state.eSignaturePreparedBy == undefined){
          
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please insert e-signature",
                fade            :   true
            })
            return
          
        }
       
        let createddate         = moment(this.state.createddate).format('MM/DD/YYYY');
        let _dateApplied        = moment(this.state.date).format('MM/DD/YYYY');
        let _dateReceive        = moment(this.state.dateReceive).format('MM/DD/YYYY');
        

        let data = {
            "client"                :   this.state.selectedClientName,
            "employeeName"          :   this.state.selectedEmployeeName,
            "position"              :   this.state.selectedPosition,
            "employeeNo"            :   this.state.selectedEmployeeNo,
            "location"              :   this.state.selectedEmployeeBranch,
            "date"                  :   _dateApplied,
            "nodaRefNo"             :   this.state.nodaRefNo,
            "nteRefNo"              :   this.state.nteRefNo,
            "dateReceive"           :   _dateReceive,
            "attachNTE"             :   this.state.attachNTE,
            "explanationLetter"     :   this.state.explanationLetter,
            "paragraph"             :   this.state.paragraph,
            "quotedStatement"       :   this.state.quotedStatement,
            "addedparagraph1"       :   this.state.addedparagraph1,
            "addedparagraph2"       :   this.state.addedparagraph2,
            "addedparagraph3"       :   this.state.addedparagraph3,
            "addedparagraph4"       :   this.state.addedparagraph4,
            "addedparagraph5"       :   this.state.addedparagraph5,
            "addedparagraph6"       :   this.state.addedparagraph6,

            "preparedBy"            :   this.state.preparedBy,
            "prepareByPosition"     :   this.state.prepareByPosition,
            "notedBy"               :   this.state.notedBy,
            "notedByPosition"       :   this.state.notedByPosition,
            "receivedBy"            :   this.state.receivedby,
            "eSignatureNotedBy"     :   this.state.eSignatureNotedBy,
            "eSignaturePreparedBy"  :   this.state.eSignaturePreparedBy,
            "insertImage"           :   this.state.insertImage,
            

            "isDraft"               :   "0",
            "isDeleted"             :   "0",
            "createdby"             :   this.state.userinfo.userId,
            "createddate"           :   createddate,
            "modifiedby"            :   'user007',
            "modifieddate"          :   '0',
            "isModified"            :   '0',  

            "articleDataList":this.state.articleDataList, 
            "sectionDataList":this.state.sectionDataList ,
            "articleDataList2":this.state.articleDataList2 ,
            "sectionDataList2":this.state.sectionDataList2 ,
            "articleDataList3":this.state.articleDataList3 ,
            "sectionDataList3":this.state.sectionDataList3 ,
            "articleDataList4":this.state.articleDataList4 ,
            "sectionDataList4":this.state.sectionDataList4 ,
            "articleDataList5":this.state.articleDataList5 ,
            "sectionDataList5":this.state.sectionDataList5 ,
            

        }

        console.log("data generate memo")
        console.log(data)

        sessionStorage.setItem("data", JSON.stringify(data))
        let viewDraftMemoTmp = "1"
        sessionStorage.setItem("viewDraftMemoTmp", JSON.stringify(viewDraftMemoTmp))

        window.location.href='/ExportNoda'

    }

    
    onChangeNteRefNo=(e)=>{
        this.setState({
            nteRefNo   : e.target.value
        })
        //console.log(this.state.receivedBy)
    }

    handleOnChangeeSignatureNotedBy=(e)=>{

        const reader = new FileReader();
        reader.onload=()=>{
            if(reader.readyState === 2){
                this.setState({
                    eSignatureNotedBy:reader.result
                })
            }
        }
        reader.readAsDataURL(e.target.files[0])
    }

    handleOnChangeeSignaturePreparedBy=(e)=>{
 
        const reader = new FileReader();
        reader.onload=()=>{
            if(reader.readyState === 2){
                this.setState({
                    eSignaturePreparedBy:reader.result
                })
            }
        }
        reader.readAsDataURL(e.target.files[0])
    }

    setBack=()=>{
        let whenBack = 0
        sessionStorage.setItem("nextProcessCheck", JSON.stringify(whenBack)) 

    }

    handleOnChangeinsertImage=(e)=>{

        const reader = new FileReader();
        reader.onload=()=>{
            if(reader.readyState === 2){
                this.setState({
                    insertImage:reader.result
                })
            }
        }
        reader.readAsDataURL(e.target.files[0])
    }

    render() {
        const sectionColumn = [
            {

                dataField   : 'sectionNo',
                text        : 'sectionNo',
                editable    : true,
                editor: {
                    type: Type.SELECT,
                    options: this.state.sectionList,
                   
                },
                headerStyle : () => {
                    return { width  : "50%" };
                },
                style:{whiteSpace: 'nowrap', }
                

            },
            {

                dataField   : 'infraction',
                text        : 'infraction',
                editable    : false,
                headerStyle : () => {
                    return { width  : "50%" };
                },
                
                style:{whiteSpace: 'nowrap', }

            },
            {

                dataField   : 'category',
                text        : 'category',
                editable    : false,
              
                headerStyle : () => {
                    return { width  : "50%" };
                },
                style:{ whiteSpace: 'nowrap', }

            },
           
        ]
        const articleColumn = [
            {

                dataField   : 'articleNo',
                text        : 'Article No',
                editable    : true,
                editor: {
                    type: Type.SELECT,
                    options: this.state.articleList,
                   
                },
                headerStyle : () => {
                    return { width  : "50%" };
                },
                style:{whiteSpace: 'nowrap', }
                

            },
            {

                dataField   : 'articleName',
                text        : 'Article Nane',
                editable    : false,
                headerStyle : () => {
                    return { width  : "50%" };
                },
                
                style:{ whiteSpace: 'nowrap', }

            },
           
        ]
        
        return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>NOTICE OF DECISION</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Card.Header>Employee Details</Card.Header>
                                    <Form.Group as={Row} className="mt-4" controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Client
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClientList}
                                                options={this.state.getClientList}
                                                disabled={this.state.disableEdit}
                                                selected={[this.state.selectedClientName]}
                                                placeholder="Select Client"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee
                                        </Form.Label>
                                        <Col sm="8">
                                           { <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.onChangeEmployeesList}
                                                options={this.state.getEmployeeList}
                                                disabled={this.state.disableEdit}
                                                selected={[this.state.selectedEmployeeName]}
                                                placeholder={this.state.employeePlaceHolder}
                                            />}
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Emp No.
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeNo"
                                                value={this.state.selectedEmployeeNo}
                                                disabled={this.state.disableEdit}
                                                //onChange={this.onChangePosition} 
                                                autoComplete="off"
                                                placeholder="Autofill"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position
                                        </Form.Label>
                                        <Col sm="6">
                                            <Form.Control 
                                                type="text"
                                                name="selectedPosition"
                                                value={this.state.selectedPosition}
                                                onChange={this.onChangePosition} 
                                                disabled={this.state.disableEdit}
                                                autoComplete="off"
                                                placeholder="Autofill"
                                            />
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        </Form.Label>
                                        <Col sm="4">
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Location
                                        </Form.Label>
                                        <Col sm="11">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeBranch"
                                                value={this.state.selectedEmployeeBranch}
                                                onChange={this.onChangeLocation} 
                                                disabled={this.state.disableEdit}
                                                autoComplete="off"
                                                placeholder="Auto Fill"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Date
                                        </Form.Label>
                                        <Col sm="2">
                                            <DatePicker
                                                ref='date'
                                                selected={this.state.date}
                                                onChange={this.handleChangedate}
                                                value={this.state.date}
                                                dateFormat={"MM/dd/yyyy"}
                                                style={{height:'40px !important'}}
                                                size="40"
                                                className="form-control"
                                            />
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            NOD Reference No.
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="nodaRefNo"
                                                value={this.state.nodaRefNo}
                                                onChange={this.onChangeNodaRefNo} 
                                                autoComplete="off"
                                                placeholder="System Generated"
                                                disabled={true}
                                            />
                                        </Col>
                                        <Col sm="4">
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            NTE Reference No.
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                type="text"
                                                name="nteRefNo"
                                                value={this.state.nteRefNo}
                                                onChange={this.onChangeNteRefNo} 
                                                disabled={this.state.disableEdit}
                                                autoComplete="off"
                                                placeholder="Enter NTE Ref No."
                                            />
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Date of NTE
                                        </Form.Label>
                                        <Col sm="2">
                                            <DatePicker
                                                ref='date'
                                                selected={this.state.dateReceive}
                                                onChange={this.handleChangedateReceive}
                                                value={this.state.dateReceive}
                                                dateFormat={"MM/dd/yyyy"}
                                                style={{height:'40px !important'}}
                                                size="40"
                                                className="form-control"
                                                disabled = {this.state.disableEdit}
                                            />
                                        </Col>
                                    </Form.Group>
                                   {/*  <Form.Group as={Row} className="mt-4" controlId="formPlaintextEmail">
                                        <Col sm="4">
                                        <label>Attached signed NTE</label>
                                        <Input type="file" style={{backgroundColor:"#008BFF"}} className="file" placeholder="attached file" variant="primary" accept="application/pdf" onChange={(e)=>this.handleAttachNTE(e)}/>  
                                        </Col>
                                        <Col sm="4">
                                        <label>Attached Member Explanation Letter</label>
                                        <Input type="file" style={{backgroundColor:"#008BFF"}} className="file" placeholder="attached file" variant="primary" accept="application/pdf" onChange={(e)=>this.handleExplanationLetter(e)}/> 
                                        </Col> 
                                                              
                                    </Form.Group> */}

                                    <Card.Header className="mt-5">Memorandum Details</Card.Header>

                                    <div className="mt-1">

                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                            <Col>
                                                <FormControl className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChangeParagraph} rows="3" value={this.state.paragraph}/>
                                                
                                            </Col>                    
                                        </Form.Group>

                                        { this.state.addedparagraph1Check ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChangeAddedParagraph1} value={this.state.addedparagraph1}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        }

                                        { this.state.addedparagraph2Check ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph )" onChange={this.handleChangeAddedParagraph2} value={this.state.addedparagraph2}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        }  

                                        { this.state.addedparagraph3Check ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph )" onChange={this.handleChangeAddedParagraph3} value={this.state.addedparagraph3}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        }

                                        { this.state.addedparagraph4Check ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph )" onChange={this.handleChangeAddedParagraph4} value={this.state.addedparagraph4}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        }

                                        { this.state.addedparagraph5Check ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph )" onChange={this.handleChangeAddedParagraph5} value={this.state.addedparagraph5}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        }
                                        { this.state.addedparagraph6Check ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph )" onChange={this.handleChangeAddedParagraph6} value={this.state.addedparagraph6}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        }
                                        { this.state.quotedStatementCheck ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl  className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Quoted Statement)" onChange={this.handleChangeQuotedStatement} style={{fontWeight : "bold"}} value={this.state.quotedStatement}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        }
                                        
                                        { this.state.cocReferences ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Form.Label column sm="1" style={{fontWeight : "bold" , fontSize:15}}>
                                                    COC DETAILS
                                                </Form.Label> 
                                            </Form.Group>:null
                                        } 
                                        {  this.state.articleDataList.length > 0  ?
                                            <Card.Body as={Row} controlId="formPlaintextEmail" className="mt-1">
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.articleDataList }
                                                    columns = { articleColumn }
                                                    striped
                                                    hover
                                                    condensed

                                                />
                                            </Card.Body>:null
                                        } 
                                        { this.state.sectionDataList.length > 0  ?
                                            <Card.Body as={Row} controlId="formPlaintextEmail" style={{marginTop:"-30px"}}>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.sectionDataList }
                                                    columns = { sectionColumn }
                                                    striped
                                                    hover
                                                    condensed

                                                />
                                            </Card.Body>:null
                                        } 
                                        
                                        { this.state.articleDataList2.length > 0 ?
                                            <Card.Body as={Row} controlId="formPlaintextEmail" className="mt-1">
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.articleDataList2 }
                                                    columns = { articleColumn }
                                                    striped
                                                    hover
                                                    condensed

                                                />
                                            </Card.Body>:null
                                        } 
                                        { this.state.sectionDataList2.length > 0  ?
                                            <Card.Body as={Row} controlId="formPlaintextEmail" style={{marginTop:"-30px"}} >
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.sectionDataList2 }
                                                    columns = { sectionColumn }
                                                    striped
                                                    hover
                                                    condensed

                                                />
                                            </Card.Body>:null
                                        } 
                                        { this.state.articleDataList3.length > 0 ?
                                            <Card.Body as={Row} controlId="formPlaintextEmail" className="mt-1">
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.articleDataList3 }
                                                    columns = { articleColumn }
                                                    striped
                                                    hover
                                                    condensed

                                                />
                                            </Card.Body>:null
                                        } 
                                        { this.state.sectionDataList3.length > 0  ?
                                            <Card.Body as={Row} controlId="formPlaintextEmail"  style={{marginTop:"-30px"}}>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.sectionDataList3 }
                                                    columns = { sectionColumn }
                                                    striped
                                                    hover
                                                    condensed

                                                />
                                            </Card.Body>:null
                                        } 
                                        { this.state.articleDataList4.length > 0 ?
                                            <Card.Body as={Row} controlId="formPlaintextEmail" className="mt-1">
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.articleDataList4 }
                                                    columns = { articleColumn }
                                                    striped
                                                    hover
                                                    condensed

                                                />
                                            </Card.Body>:null
                                        } 
                                        { this.state.sectionDataList4.length > 0  ?
                                            <Card.Body as={Row} controlId="formPlaintextEmail"  style={{marginTop:"-30px"}}>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.sectionDataList4 }
                                                    columns = { sectionColumn }
                                                    striped
                                                    hover
                                                    condensed

                                                />
                                            </Card.Body>:null
                                        } 
                                        
                                        { this.state.articleDataList5.length > 0 ?
                                            <Card.Body as={Row} controlId="formPlaintextEmail" className="mt-1">
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.articleDataList5 }
                                                    columns = { articleColumn }
                                                    striped
                                                    hover
                                                    condensed

                                                />
                                            </Card.Body>:null
                                        } 
                                        { this.state.sectionDataList5.length > 0  ?
                                            <Card.Body as={Row} controlId="formPlaintextEmail" style={{marginTop:"-30px"}} >
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.sectionDataList5 }
                                                    columns = { sectionColumn }
                                                    striped
                                                    hover
                                                    condensed

                                                />
                                            </Card.Body>:null
                                        } 
                                        

                                    </div>

                                    <ButtonToolbar sm={12} className="mt-4">
                                        <Col>                                            
                                            <Button variant="success" style={{minWidth:'280px'}} onClick={this.handleAddedParagraph1}>
                                                Add Paragraph
                                            </Button>                                            
                                        </Col>&nbsp;&nbsp;
                                        
                                        <Col>                                           
                                            <Button variant="success" style={{minWidth:'280px'}} onClick={this.handleQuotedStatementCheck}>
                                                Insert QuotedStatement
                                            </Button>                                           
                                        </Col>
                                        <Col>                                        
                                            <Button variant="success" style={{minWidth:'280px'}} onClick={this.handleModalShowCOC}>
                                                Insert COC Reference
                                            </Button>                                           
                                        </Col>
                                      
                                    </ButtonToolbar>

                                    <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                                      
                                    </Form.Group> 

                                   {/*  <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                                        <Col sm="4">
                                        <Button variant="success" size="md" block >Insert Quoted Statement</Button>
                                        </Col>
                                        <Col sm="4">
                                        <Button variant="success" size="md" block onClick={this.handleModalShowCOC} >Insert COC Reference</Button>
                                        </Col>
                                        <Col sm="4">
                                        <Button variant="success" size="md" block >View Draft Memo</Button>
                                        </Col>
                                    </Form.Group> */}

                                     <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Prepared By (Client) :
                                        </Form.Label>
                                        <Col sm="7" style={{marginLeft:"-125px"}}>
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangePreparedbyClientList}
                                                options={this.state.getClientList}
                                                //selected={[this.state.selectedClientName]}
                                                //disabled={this.state.disableEmployee}
                                                //placeholder={this.state.clientPlaceHolder}
                                            />
                                        </Col>
                                    </Form.Group>
                                     <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Prepared By:
                                        </Form.Label>

                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.handleOnChangePreparedBy}
                                                options={this.state.getEmployeepreparedBy}
                                                selected={[this.state.preparedBy]}
                                                //disabled={this.state.disableEmployee}
                                                //placeholder={this.state.employeePlaceHolder}
                                            />
                                        </Col>

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.prepareByPosition}
                                                onChange={this.handleOnChangePrepareByPosition} 
                                                autoComplete="off"
                                                //placeholder="Enter Company"
                                            />
                                        </Col>

                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Noted By:
                                        </Form.Label>

                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.handleOnChangeNotedBy}
                                                options={this.state.getEmployeepreparedBy}
                                                selected={[this.state.notedBy]}
                                                //disabled={this.state.disableEmployee}
                                                //placeholder={this.state.employeePlaceHolder}
                                            />
                                        </Col>

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.notedByPosition}
                                                //onChange={this.handleOnChangenotedByPosition} 
                                                autoComplete="off"
                                                //placeholder="Enter Company"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Received by
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="receivedby"
                                                value={this.state.receivedby}
                                                onChange={this.handleOnChangeReceivedBy} 
                                                autoComplete="off"
                                                disabled={true}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Insert Image:
                                        </Form.Label>
                                        <Col sm="2" >                                                 
                                            <Input type="file" className="file" placeholder="Insert Image" variant="primary" accept="image"  onChange={this.handleOnChangeinsertImage}/>                                          
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Attached eSignature PreparedBy (Prepared By):
                                        </Form.Label>
                                        <Col sm="2" >                                                 
                                            <Input type="file" className="file" placeholder="eSignaturePreparedBy" variant="primary" accept="image"  onChange={this.handleOnChangeeSignaturePreparedBy}/>                                          
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Attached eSignature NotedBy (Noted By):
                                        </Form.Label>
                                        <Col sm="2" >                                                 
                                            <Input type="file" className="file" placeholder="eSignatureNotedBy" variant="primary" accept="image"  onChange={this.handleOnChangeeSignatureNotedBy}/>                                          
                                        </Col>
                                    </Form.Group>

                                  {/*   <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Col sm="3">
                                            <Button variant="success"  className="mt-3" style={{minWidth:'60px'}} onClick={this.handleToExportNODA} href="/ExportNTE" > Generate NODA Memo </Button>
                                        </Col>
                                        <Col sm="7">
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                    </Form.Group> */}

                                   {/*  <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginRight:"5px"}}>
                                        <Button variant="success"  className="ml-auto "  style={{minWidth:'60px'}} onClick = { this.handleSaveClick }>Save</Button>&nbsp;&nbsp;
                                        <Button variant="danger" href="/home" style={{minWidth:'60px'}} >Close</Button>
                                    </Form.Group> */}

                                    <ButtonToolbar className="mt-5">

                                        <Button disabled={this.state.disableSubmit} /* className="ml-auto" */ variant="success" onClick = { this.getNODARefNo } style={{minWidth:'60px'}}>Save</Button>&nbsp;&nbsp;&nbsp;
                                        <Button  href="/EmployeeDiciplineMonitoring" variant="danger" style={{minWidth:'60px'}} onClick={this.setBack}>Close</Button>
                                       
                                        <Button className="ml-auto" variant="success" onClick={this.handleToExportNODA}  style={{minHeight:"18px",minWidth:'60px',}}>Generate NOD Memo</Button>&nbsp;&nbsp;
                                   
                                       
                                       
                                    </ButtonToolbar>  

                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />
                   {/* <InsertPenaltyStatement 
                        show={this.state.modalInsertPenalty}
                        onHide={this.handleModalClosePenalty}
                    /> */} 
                    {<InsertCOCReference
                        show={this.state.modalCoCReferenceShow}
                        onHide={this.handleModalClose}
                    />}
            </div> 
        )
    }
}

export  default NoticeOfDisiplinaryAction 
