import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Redirect
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import BlacklistModal from './Modal/BlacklistModal';
class JobOfferCreate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo        :   [],
            data            :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            navigate        :   false,
            disablecard     :   false,
            disablebutton   :   false,
            minDate         :   "",


            clientDDL       :   [],
            positionDDL     :   [],
            locationDDL     :   [],
            employmentDDL   :   [],
            paytypeDDL      :   [],
            paymodeDDL      :   [],
            paycardtypeDDL  :   [],
            taxexemptionDDL :   [],
            taxtypeDDL      :   [],
            taxcodeDDL      :   [],
            deductionDDL    :   [],
            basic13thMonthDDL:  [],
            tmnprofileDDL   :   [],
            yesNoDDL        :   [
                {"value":"0","label":"NO"},
                {"value":"1","label":"YES"}
            ],
            requiredDDL        :   [
                {"value":"0","label":"OPTIONAL"},
                {"value":"1","label":"MANDATORY"}
            ],
            documentGRID    :   [],

            profileId       :   "",
            applicationId   :   "",
            employeeName    :   "",
            clientId        :   "",
            clientName      :   "",
            positionId      :   "",
            position        :   "",

            locationId      :   "",
            isdefaultBranch :   "",
            employmentId    :   "",
            salaryRate      :   "",
            dateHired       :   '',
            contractstartDate:  '',
            contractendDate :   '',
            paytypeId       :   "",
            paymodeId       :   "",
            paycardtypeId   :   "",
            paycardNumber   :   "",
            tmnprofileId    :   "",
            ecolaRate       :   "",
            colaRate        :   "",
            seaRate         :   "",
            taxexemptionId  :   "",
            taxcodeId       :   "",
            isbasicPay      :   "",
            deductionsssId  :   "",
            deductionphicId :   "",
            deductionhdmfId :   "",
            basic13thmonthId:   "",
            daysperMonth    :   "",
            daysperYear     :   "",
            openModal:false

        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.data = JSON.parse(sessionStorage.getItem("applicationform_" + this.state.userinfo.userId + this.state.userinfo.employeeId))
        
        this.state.applicationId   =   this.state.data.id
        this.state.profileId       =   this.state.data.profileId
        this.state.clientName      =   this.state.data.client
        this.state.clientId        =   this.state.data.clientId
        this.state.employeeName    =   this.state.data.employeeName
        this.state.position        =   this.state.data.position
        this.state.positionId      =   this.state.data.positionId
        
    }

    componentDidMount(){
        this.GetDocuments()
    }
    handleModalShow = (e) =>{
        let obj = {
            "applicationFormId":this.state.applicationId,
            "positionId":this.state.positionId,
            "clientId" : this.state.clientId,
            "accountId" : "",
            "areaId":this.state.userinfo.areaId,
            "aquisitionId" : "",
            "statusId" : e,
        }
        this.setState({openModal:true})
        this.child.initialize(obj)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
    };
    GetDocuments = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.data.clientId,
            "PositionId"    :   this.state.data.positionId,
        }
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetDocuments", params
            )
            .then(res => {
                const data = res.data;
                this.setState({documentGRID:data.documents})
            }) 
        this.GetLocations()
    }
    GetLocations = async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.clientName,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", params)
        .then(res => {
            const data = res.data;
            this.setState({locationDDL : data.locations ? data.locations : []})
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
       this.GetPayrollTypes()
    }
    GetPayrollTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({paytypeDDL:data.payrollTypes});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetPayrollModes()
    }
    GetPayrollModes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollModes",  params)
        .then(res => {
            const data = res.data;
            this.setState({paymodeDDL:data.payrollModes});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetEmploymentTypes()
    }
    GetEmploymentTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetEmployeeStatusTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({employmentDDL:data.statusTypes});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetPayCardTypes()
    }
    GetPayCardTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayCardTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({paycardtypeDDL:data.payCardTypes});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetTaxTypes()
    }
    GetTaxTypes = async() => {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "0012"
        };

        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  params)
        .then(res => {
            const data = res.data;
            this.setState({ taxtypeDDL:data.dataReferences });
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetTaxExemptions()
    }
    GetTaxExemptions = async() => {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "0013"
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  params)
        .then(res => {
            const data = res.data;
            this.setState({ taxexemptionDDL:data.dataReferences });
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetDeductionTypes()
    }
    GetDeductionTypes = async() => {
        const departmentCodeParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "0016"
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  departmentCodeParams)
        .then(res => {
            const data = res.data;
            this.setState({deductionDDL:data.dataReferences});
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetBasisFor13Months()
    }
    GetBasisFor13Months = async() => {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "0017"
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  params)
        .then(res => {
            const data = res.data;
            this.setState({basic13thMonthDDL:data.dataReferences,isloading:false});
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangedDDL = name => e =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,isloading:false});
        if(e.length===0) {this.setState({[name]:""}); return}
        this.setState({[name]:e[0].id})

        if(name==="paycardtypeId" && e[0].id==="4"){
            this.setState({disablecard:true,paycardNumber:""})
        }
        if(name==="paycardtypeId" && e[0].id!=="4"){
            this.setState({disablecard:false})
        }
    }
    handleChangedDate = name => date =>{
        this.setState({[name]:date})
        if(name==="dateHired"){
            this.state.minDate=new Date(date)
            this.state.contractstartDate=new Date(date)
            this.state.contractendDate=new Date(date)
        }
    }
    handleChangedCB = (e) =>{
        this.setState({[e.target.name]: e.target.checked})
    }
    handleChanged = (e) =>{
        if(e.target.name==="salaryRate" ||
            e.target.name==="ecolaRate" ||
            e.target.name==="colaRate" ||
            e.target.name==="seaRate"){
            if(e.target.value.split(".").length>2) return
            let dec = e.target.value.split(".")[1]
            if(typeof dec !='undefined')
                if(dec.length>2) return

        }
        this.setState({[e.target.name]:e.target.value})
    }
    FormatDate(date) {
        let m = moment(date, 'MM/DD/YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }
    IsNumeric(evt)
    {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
        
    }
    IsDecimal(evt)
    {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }
    GridDataModified(oldValue, newValue, row, column) {
        let documentGRID = this.state.documentGRID
        documentGRID.map(function(item,i) {
            if (item.id===row.id && newValue!==oldValue)
            {
                if(column==="isReceived" && newValue==="1"){
                    item.isSuspended="0"
                }
                if(column==="isReceived" && newValue==="0"){
                    item.isSuspended="1"
                    item.dateReceived = null
                }

                if(column==="isSuspended" && newValue==="1"){
                    item.isReceived="0"
                    item.dateReceived=null
                }
                if(column==="isSuspended" && newValue==="0"){
                    item.isReceived="1"
                    item.dateReceived = null
                }
                if(typeof item.dateReceived==='undefined')
                    item.dateReceived=null
            }
        })
        this.state.documentGRID = documentGRID
        console.log(this.state.documentGRID)
    }
    handleSubmitClick = async(statusid) => {
        this.setState({isloading:true,disablebutton:true})
        let documentGRID = this.state.documentGRID
        
        if(documentGRID.filter(x=>x.isRequired==="1" && x.isReceived==="1" && x.dateReceived===null).length>0){
            let doc = documentGRID.find(x=>x.isRequired==="1" && x.isReceived==="1" && x.dateReceived===null).name
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter date received for " + doc + ".",
                fade        :   true,
                disablebutton:  false
            })
            return
        }

        if(documentGRID.filter(x=>x.isRequired==="1" && x.isReceived==="1" && x.isSuspended==="1").length>0){
            let doc = documentGRID.find(x=>x.isRequired==="1" && x.isReceived==="1" && x.isSuspended==="1").name
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   doc + " cannot be submitted, received and suspended column are both YES.",
                fade        :   true,
                disablebutton:  false
            })
            return
        }
        if(documentGRID.filter(x=>x.isRequired==="1" && x.isReceived==="0" && x.isSuspended==="0").length>0){
            let doc = documentGRID.find(x=>x.isRequired==="1" && x.isReceived==="0" && x.isSuspended==="0").name
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   doc + " cannot be submitted, received and suspended column are both NO.",
                fade        :   true,
                disablebutton:  false
            })
            return
        }
        console.log(documentGRID)
        const params = {
            "IpAddress"             :   "0.0.0.0",
            "ClientId"              :   this.state.clientId,
            "UserId"                :   this.state.userinfo.userId,
            "ApplicationFormId"     :   this.state.applicationId,
            "LocationId"            :   this.state.locationId,
            "IsDefaultLocation"     :   (this.state.isdefaultBranch)? "1" : "0",
            "PositionId"            :   this.state.positionId,
            "SalaryOffered"         :   this.state.salaryRate,
            "DateHired"             :   this.FormatDate(this.state.dateHired),
            "ContractDateStart"     :   this.FormatDate(this.state.contractstartDate),
            "ContractDateEnd"       :   this.FormatDate(this.state.contractendDate),
            "PayTypeId"             :   this.state.paytypeId,
            "PayModeId"             :   this.state.paymodeId,
            "EcolaValue"            :   this.state.ecolaRate,
            "SeaValue"              :   this.state.seaRate,
            "ColaValue"             :   this.state.colaRate,
            "TaxExemptionTypeId"    :   this.state.taxexemptionId,
            "TaxTypeId"             :   this.state.taxtypeId,
            "TaxCodeId"             :   "0",
            "IsOBP"                 :   (this.state.isbasicPay)? "1" : "0",
            "WorkingDaysPerMonth"   :   this.state.daysperMonth,
            "WorkingDaysPerYear"    :   this.state.daysperYear,
            "DeductionBasisSSS"     :   this.state.deductionsssId,
            "DeductionBasisHDMF"    :   this.state.deductionhdmfId,
            "DeductionBasisPHIC"    :   this.state.deductionphicId,
            "BasisFor13thMonth"     :   this.state.basic13thmonthId,
            "EmployeeStatusTypeId"  :   this.state.employmentId,
            "PayCardTypeId"         :   this.state.paycardtypeId,
            "PayCardNumber"         :   this.state.paycardNumber,
            "TMNProfileId"          :   "N/A",
            "PreparedBy"            :   this.state.userinfo.userId,
            "Conforme"              :   this.state.userinfo.fullName,
            "ProfileId"             :   this.state.profileId,
            "StatusId"              :   statusid,
            "documents"             :   documentGRID,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/AddJobOffer",  params)
        .then(res => {
                const data = res.data;
            if(data.status=="1"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true,
                    disablebutton:  true
                });
            
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    disablebutton:  false
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true,
                disablebutton:  false
            })
        })
    };

    handleSubmit = async (StatusId) =>{
        this.setState({isloading:true,disableBtn:true})

        const param = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ApplicationFormId":this.state.applicationId,
            "PositionId":this.state.positionId,
           /// "IsPooled":ispooled,

            /* "ClientId": "1",
            "IpAddress": "0.0.0.0",
            "UserId": "1",
            "ApplicationFormId": "",
            "PositionId": "" */
            "StatusId": StatusId, /* --> blacklisted - 12, process - 3  */

            "EducationalId":this.state.educationId,
            "IsFreshGrad":(this.state.freshGraduate)? "1" : "0",
            "WorkExperienceMonths":this.state.workExperienceMonths,
            "WorkExperienceYears":this.state.workExperienceYear,
            "WithWorkExperience":(this.state.withWorkExperience)? "1" : "0",
            "BodyBuiltId":this.state.bodyId,
            "HairId":this.state.hairId,
            "ComplexionId":this.state.complexionId,
            "PostureId": this.state.postureId,
            "PersonalityId":this.state.personalityId,
            "MentalId":this.state.mentalId,
            "WorkExperienceId":this.state.professionalId,
            "TechnicalExperienceId":this.state.technicalId,
            "SupportGroupId":this.state.supportGroupId,
            "AssessmentId":this.state.assessmentId,
            "Comments":this.state.comments,
            "RecommendationId":this.state.preFinalId,
            "AssessedBy":this.state.userinfo.userId,
            "StoreOperationId":this.state.storeId,
        };
        console.log("param")
        console.log(param)

        
        await axios
        .post(
            AppConfiguration.Setting().noserapiendpoint + "Pooling/SubmitPooling",  param
        )
        .then(res => {
            const data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                name        :   ''
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
                name        :   ''
            })
        })
    };

    handleConfirm=()=>{
        this.setState({isShow:true})
    };
    handleClose=()=>{
        this.setState({isShow:false, pooling : true})
    }


    render() {
        const { navigate } = this.state;
        if (navigate) {
            return <Redirect to="/applicationform" push={true} />;
        } 

        const docCol = [
            {
                dataField: 'name',
                text: 'Documents',
                editable : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'50%'}},
                style:{textAlign:'left',width:'50%'}
            },
            {
                dataField: 'isRequired',
                text: 'Type',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%'}},
                style:{textAlign:'center',width:'15%'},
                formatter: (cell, row) => {
                    if(row.isRequired !=='' || typeof row.isRequired === 'undefined'){
                        if(this.state.requiredDDL.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.requiredDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.requiredDDL
                },
            },
            {
                dataField: 'isReceived',
                text: 'Received',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center',width:'10%'},
                formatter: (cell, row) => {
                    if(row.isReceived !=='' || typeof row.isReceived === 'undefined'){
                        if(this.state.yesNoDDL.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.yesNoDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.yesNoDDL
                },
                validator: (newValue, row, column) => {
                    // if (newValue =="1" && row.isSuspended=="1") {
                    //   return {
                    //     valid: false,
                    //     message: "Please select correctly at Column RECEIVED / SUSPENDED"
                    //   };
                    // }
                    // return true;
                }
            },
            {
                
                dataField: 'dateReceived',
                text: 'DATE RECEIVED',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%'}},
                style:{textAlign:'center',width:'15%'},
                formatter: (cell) => { 
                    let dateObj = cell;
                    if(cell === "") return
                    if (typeof cell !== 'object') dateObj = new Date(cell);
                    if (cell != null){
                        let m = moment(dateObj);
                        return (m.isValid() ? (dateObj.getMonth()+1 + "/" + dateObj.getDate() + "/" + dateObj.getFullYear()) : "")
                    }
                },
                editor: {
                    type: Type.DATE,
                    defaultValue: Date.now(),
                    minDateValue: Date.now(),
                },
                validator: (newValue, row, column) => {
                    // if (row.isReceived=="0") {
                    //   return {
                    //     valid: false,
                    //     message: "Not allowed"
                    //   };
                    // }
                    // return true;
                }
            },
            {
                dataField: 'isSuspended',
                text: 'Suspended',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center',width:'10%'},
                formatter: (cell, row) => {
                    if(row.isSuspended !=='' || typeof row.isSuspended === 'undefined'){
                        if(this.state.yesNoDDL.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.yesNoDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.yesNoDDL
                },
                validator: (newValue, row, column) => {
                    // if (newValue =="1" && row.isReceived=="1") {
                    //   return {
                    //     valid: false,
                    //     message: "Please select correctly at Column RECEIVED / SUSPENDED"
                    //   };
                    // }
                    // return true;
                }
            },
                
        ] 
    
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        const rowEvents = {
            onClick: (e, row, rowIndex) => {

            }
        };
        return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>RECRUITMENT >> JOB OFFER (CREATE)</Card.Header>
                            <Card.Body  className="mb-3">
                                <Form >
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        CLIENT
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text" 
                                                autoComplete="off" 
                                                name="clientName"
                                                value={this.state.clientName}
                                                readOnly
                                            /> 
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        FULL NAME
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text" 
                                                autoComplete="off" 
                                                name="employeeName"
                                                value={this.state.employeeName}
                                                readOnly
                                            /> 
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        POSITION APPLIED
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text" 
                                                autoComplete="off" 
                                                name="position"
                                                value={this.state.position}
                                                readOnly
                                            /> 
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        BRANCH
                                        </Form.Label>
                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                name="locationId"
                                                onChange={this.handleChangedDDL('locationId')}
                                                options={this.state.locationDDL}
                                                readOnly
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Col sm="5">
                                            <Form.Check 
                                                type="checkbox" 
                                                label="Is Default Branch" 
                                                name="isdefaultBranch"
                                                onChange={e => this.handleChangedCB(e)}
                                                style={{fontWeight : "bold"}}
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        EMPLOYEE STATUS TYPE
                                        </Form.Label>
                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                name="employmentId"
                                                onChange={this.handleChangedDDL('employmentId')}
                                                options={this.state.employmentDDL}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        SALARY OFFERED
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                ref="salaryRate"
                                                name="salaryRate"
                                                value={this.state.salaryRate}
                                                onChange={this.handleChanged}
                                                onKeyPress={this.IsDecimal.bind(this)}
                                                maxLength="13"
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        DATE HIRED
                                        </Form.Label>
                                        <Col sm="2">
                                            <DatePicker
                                                ref='dateHired'
                                                selected={this.state.dateHired}
                                                onChange={this.handleChangedDate("dateHired")}
                                                minDate={this.state.minDate}
                                                value={this.props.dateHired}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        CONTRACT DATE START
                                        </Form.Label>
                                        <Col sm="3">
                                            <DatePicker
                                                ref='contractstartDate'
                                                selected={this.state.contractstartDate}
                                                onChange={this.handleChangedDate("contractstartDate")}
                                                minDate={this.state.minDate}
                                                value={this.props.contractstartDate}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        CONTRACT DATE END
                                        </Form.Label>
                                        <Col sm="3">
                                            <DatePicker
                                                ref='contractendDate'
                                                selected={this.state.contractendDate}
                                                onChange={this.handleChangedDate("contractendDate")}
                                                minDate={this.state.minDate}
                                                value={this.props.contractendDate}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        PAY TYPE
                                        </Form.Label>
                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='name'
                                                id="paytypeId"
                                                name="paytypeId"
                                                onChange={this.handleChangedDDL('paytypeId')}
                                                options={this.state.paytypeDDL}
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        PAY MODE
                                        </Form.Label>
                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='name'
                                                id="paymodeId"
                                                name="paymodeId"
                                                onChange={this.handleChangedDDL('paymodeId')}
                                                options={this.state.paymodeDDL}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        PAY CARD TYPE
                                        </Form.Label>
                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='name'
                                                id="paycardtypeId"
                                                name="paycardtypeId"
                                                onChange={this.handleChangedDDL('paycardtypeId')}
                                                options={this.state.paycardtypeDDL}
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        PAY CARD NUMBER
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                ref="paycardNumber"
                                                name="paycardNumber"
                                                value={this.state.paycardNumber}
                                                onChange={this.handleChanged}
                                                disabled={this.state.disablecard}
                                                autoComplete="off"
                                                maxLength="30"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        TMN PROFILE ID
                                        </Form.Label>
                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='name'
                                                id="tmnprofileId"
                                                name="tmnprofileId"
                                                onChange={this.handleChangedDDL('tmnprofileId')}
                                                options={this.state.tmnprofileDDL}
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        ECOLA
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                ref="ecolaRate"
                                                name="ecolaRate"
                                                value={this.state.ecolaRate}
                                                onChange={this.handleChanged}
                                                onKeyPress={this.IsDecimal.bind(this)}
                                                autoComplete="off"
                                                maxLength="13"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        SEA
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                ref="seaRate"
                                                name="seaRate"
                                                value={this.state.seaRate}
                                                onChange={this.handleChanged}
                                                onKeyPress={this.IsDecimal.bind(this)}
                                                autoComplete="off"
                                                maxLength="13"
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        COLA
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                ref="colaRate"
                                                name="colaRate"
                                                value={this.state.colaRate}
                                                onChange={this.handleChanged}
                                                onKeyPress={this.IsDecimal.bind(this)}
                                                autoComplete="off"
                                                maxLength="13"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        TAX EXEMPTION
                                        </Form.Label>
                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='name'
                                                id="taxexemptionId"
                                                name="taxexemptionId"
                                                onChange={this.handleChangedDDL('taxexemptionId')}
                                                options={this.state.taxexemptionDDL}
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        TAX TYPE
                                        </Form.Label>
                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='name'
                                                id="taxtypeId"
                                                name="taxtypeId"
                                                onChange={this.handleChangedDDL('taxtypeId')}
                                                options={this.state.taxtypeDDL}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Col sm="5">
                                            <Form.Check 
                                                type="checkbox" 
                                                label="Is Optimized Basic Pay" 
                                                id="isbasicPay"
                                                name="isbasicPay"
                                                onChange={e => this.handleChangedCB(e)}
                                                style={{fontWeight : "bold"}}
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        ALPHANUMERIC TAX CODE
                                        </Form.Label>
                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='name'
                                                id="taxcodeId"
                                                name="taxcodeId"
                                                onChange={this.handleChangedDDL('taxcodeId')}
                                                options={this.state.taxcodeDDL}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        DEDUCTION BASIS SSS
                                        </Form.Label>
                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='name'
                                                id="deductionsssId"
                                                name="deductionsssId"
                                                onChange={this.handleChangedDDL('deductionsssId')}
                                                options={this.state.deductionDDL}
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        DEDUCTION BASIS HDMF
                                        </Form.Label>
                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='name'
                                                id="deductionhdmfId"
                                                name="deductionhdmfId"
                                                onChange={this.handleChangedDDL('deductionhdmfId')}
                                                options={this.state.deductionDDL}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        DEDUCTION BASIS PHIC
                                        </Form.Label>
                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='name'
                                                id="deductionphicId"
                                                name="deductionphicId"
                                                onChange={this.handleChangedDDL('deductionphicId')}
                                                options={this.state.deductionDDL}
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        BASIS FOR 13th MONTH
                                        </Form.Label>
                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic13thmonthId"
                                                name="basic13thmonthId"
                                                onChange={this.handleChangedDDL('basic13thmonthId')}
                                                options={this.state.basic13thMonthDDL}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        WORKING DAYS PER MONTH
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                ref="daysperMonth"
                                                name="daysperMonth"
                                                value={this.state.daysperMonth}
                                                onChange={this.handleChanged}
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        WORKING DAYS PER YEAR
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                ref="daysperYear"
                                                name="daysperYear"
                                                value={this.state.daysperYear}
                                                onChange={this.handleChanged}
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Card.Header className="mt-5">EMPLOYEE LIST OF REQUIREMENTS</Card.Header>
                                    <div >
                                        <BootstrapTable
                                            keyField = "id"
                                            data = { this.state.documentGRID }
                                            columns = { docCol }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            rowClasses="noser-table-row-class no-checkbox"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                        this.GridDataModified(oldValue, newValue, row, column.dataField)
                                                    }
                                                })
                                            }
                                            selectRow = { selectRow }
                                            rowEvents={ rowEvents }
                                        />
                                    </div>
                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        ASSESSED BY
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text" 
                                                autoComplete="off" 
                                                /* name="selectedClient" */
                                                value={this.state.userinfo.fullName}
                                                readOnly
                                            /> 
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        CONFORME
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                ref="conforme"
                                                name="conforme"
                                                value={this.state.employeeName}
                                                autoComplete="off"
                                                readOnly
                                            />  
                                        </Col>
                                    </Form.Group>
                                    <div isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isShow ? 'display-block' : 'display-none'}>
                                        <Card>
                                            <Card.Body>
                                            <h5 className='no-text-transform'>are you sure you want to blacklist this applicant?</h5>
                                            <ButtonToolbar >
                                                
                                                <Button variant="primary" className="noser-button-mr1 ml-auto" onClick = {() => this.handleSubmit('12')} disabled={this.state.disableBtn}>confirm</Button>
                                                
                                                <Button variant="danger" className="noser-button-mr15" onClick={this.handleClose}>Close</Button>
                                            </ButtonToolbar>
                                            </Card.Body>
                                        </Card>

                                    </div>
                                    <ButtonToolbar className="mt-3">
                                        {/* <Button className="ml-auto noser-button-mr1" variant="success" onClick = {() => this.handleSubmitClick('1')}>Save</Button> */}
                                        <Button variant="warning" className="ml-auto noser-button-mr1" onClick = {() => this.handleModalShow('10')}>POOLING</Button>
                                        <Button variant="dark" className="noser-button-mr1" onClick = { () => this.handleModalShow('12')}>Blacklisted</Button>
                                        <Button className="noser-button-mr1"   variant="primary" onClick = {() => this.handleSubmitClick('2')}>Submit</Button>                                    
                                        <NavLink to="/applicationform">
                                            <Button  className="noser-button noser-button-mr1" variant="danger">Back</Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                    
                                    {/* <ButtonToolbar>
                                    <   Button variant="dark" className="ml-auto noser-button-mr1" onClick = {() => this.handleSubmit('12')}>POOLING</Button>
                                        <Button disabled={this.state.disablebutton}  className=" noser-button-mr1" variant="success" onClick = {() => this.handleSubmitClick('1')}>Save</Button>
                                        <Button disabled={this.state.disablebutton} className="noser-button-mr1"   variant="primary" onClick = {() => this.handleSubmitClick('2')}>Submit</Button>
                                        <Button  href="/applicationform" className="noser-button" variant="danger">Back</Button>
                                    </ButtonToolbar> */}
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                <NoserLoading show={this.state.isloading} />
                <BlacklistModal 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div> 
        )
    }

}

export  default JobOfferCreate
