import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Redirect,Accordion,DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

class ApplicationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,      

            clientDDL : [],
            clientId : "",
            employeeDDL : [],
            referenceNo : "",
            statusId : "",
            tinNumber : "",
            applicationFormNo : "",
            applicationGrid : [],
            applicationFormId : "",
            postionDDL   :   [],
            positonDGDDL: [],
            EffectivityMonth: "",
            petsa:"",
            positionId : ""
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClient(); 
        this.GetPosition();
    };

    GetClient = async()=> {
        this.setState({isloading:true})
        const clientParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  clientParams)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL:data.clients});
            this.GetApplicationFormFilter()
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };

    GetApplicationFormFilter = async()=> {
        this.setState({isloading:true})
        const params = {
            "IpAddress" :   "0.0.0.0",
            "ClientId"  :   this.state.clientId,
            "UserId"    :   this.state.userinfo.userId,
            "RoleId"    :   this.state.userinfo.roleId,
            "AreaId"    :   this.state.userinfo.areaId,
            "TypeId"    :   "1",
        };
        console.log("GetApplicationFormFilter")
        console.log("params")
        console.log(params)
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationFormFilter",  params)
        .then(res => {
            const data = res.data;
            this.setState({employeeDDL:data.applicationForms,isloading:false})
            console.log(this.state.employeeDDL)
            this.GetApplicationListPreload();
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    };

    GetApplicationList = async()=> {
        this.setState({applicationFormListGrid:[], applicationsList:[], isloading:true})
        let a = this.state.EffectivityMonth
        let petsa
        if(a === ""){
            petsa = ""
        }else{
            petsa = moment(this.state.EffectivityMonth).format("MM/DD/YYYY");
        }
        //let petsa = moment(this.state.EffectivityMonth).format("MM/DD/YYYY");
        console.log(petsa)
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "AreaId":this.state.userinfo.areaId,
            "ReferenceNo":this.state.referenceNo,
            "PositionId": this.state.positionId,
            "DateApplied" :petsa,
            //"StatusId":this.state.statusId,
            "TINNumber":this.state.tinNumber,
            "ApplicationFormId" : this.state.applicationFormId,
        };
        console.log("GetApplicationList")
        console.log(params)
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationForms",  params)
            .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({applicationGrid:data.applicationForms,isloading:false});
            if(data.status=="0"){
               this.setState({
                   isloading   :   false,
                   alerttype   :   "Error!",
                   isshow      :   true,
                   color       :   "danger",
                   message     :   data.message,
                   fade        :   true
               });
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };

    GetApplicationListPreload = async()=> {
        this.setState({applicationFormListGrid:[], applicationsList:[], isloading:true})
        let petsa = moment(this.state.EffectivityMonth).format("MM/DD/YYYY");
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "AreaId":this.state.userinfo.areaId,
            "ReferenceNo":this.state.referenceNo,
            "PositionId":"",
            "StatusId":this.state.statusId,
            "TINNumber":this.state.tinNumber,
            "ApplicationFormId" : this.state.applicationFormId,
            "DateApplied" :petsa,
        };
        console.log("GetApplicationListPreload")
        console.log(params)
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationFormPreload",  params)
            .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({applicationGrid:data.applicationForms,isloading:false});
            if(data.status=="0"){
               this.setState({
                   isloading   :   false,
                   alerttype   :   "Error!",
                   isshow      :   true,
                   color       :   "danger",
                   message     :   data.message,
                   fade        :   true
               });
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    
    GetPosition = () => {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId"     :   "",
            "DepartmentId"  :   "",
            "Name"          :   ""
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  params)
        .then(res => {
            const data = res.data.positions;
            //console.log("Get positions");
            //console.log(data);
            for (let i = 0; i < data.length; i++) {
                const pos = {
                    value : data[i]["id"],
                    label : data[i]["name"],
                };
                this.state.positonDGDDL.push(pos);
            }

            this.setState({ postionDDL : data });
         })
    };
    
    handleChangeClient = (e) => {
        if(e.length == 0) {
            this.state.clientId     =   ""
            this.state.clientName   =   ""
            return
        }
        this.state.clientId     =   e[0].id
        this.state.clientName   =   e[0].name
        this.setState({
            isshow:false,
        })
        console.log("handleChangeClient")
        console.log(e)
        this.GetApplicationFormFilter();
    }

    handleChangeEmployee = (e) => {
        
        if(e.length == 0) {
            this.state.applicationFormId    =   ""
            this.state.profileId            =   ""
            this.state.referenceNo          =   ""
            this.state.tinNumber            =   ""
            this.state.statusId             =   ""
            return
        }
        this.state.applicationFormId    =   e[0].id
        this.state.profileId            =   e[0].profileId
        this.state.referenceNo          =   e[0].referenceNo
        this.state.tinNumber            =   e[0].tinNumber
        this.state.statusId             =   e[0].statusId
        //this.state.EffectivityMonth= e[0].dateApplied
        this.setState({
            isshow:false,
        })
        console.log("handleChangeEmployee")
        console.log(e)
        //this.GetApplicationList();
    };

    handleChangedPosition = (e) => {
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.positionId=""
            return
        } 
        this.state.positionId = e[0].id
        this.state.position = e[0].name
    };

    handleChangeEffectivityMonth = date => {

        let a = moment(date).format("MM/DD/YYYY");
        console.log(a)
        //this.state.EffectivityMonth = a
        this.setState({
            EffectivityMonth: date
        });
        console.log("date")
        console.log(date)
    };

    handleSearchClick = () => {
        this.GetApplicationList()
        
    };

    handleClickedRedirect = (row) => {
        if(row.statusId==="2"){
            sessionStorage.setItem("applicationformSourcing" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigateSourcing:true})
        }else if(row.statusId==="3"){
            sessionStorage.setItem("applicationformprelim_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigatepreliminary:true})
        }
        else if(row.statusId==="4"){
            sessionStorage.setItem("applicationformexam_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigateexam:true})
        }
        else if(row.statusId==="6"){
            sessionStorage.setItem("applicationformbackground_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigatebackground:true})
        }
        else if(row.statusId==="7"){
            sessionStorage.setItem("applicationforminterviews_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigateinterviews:true})
        }
        else if(row.statusId==="8"){
            sessionStorage.setItem("applicationform_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigatejoboffer:true})
        }
        else if(row.statusId==="12"){
            sessionStorage.setItem("applicationform_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigatePooling:true})
        }
        else if(row.statusId==="13"){
            sessionStorage.setItem("applicationformendorsement_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigateendorsement:true})
        }
        else if(row.statusId==="19"){
            sessionStorage.setItem("applicationformrehire_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigateapplication:true})
        }
        else {
            sessionStorage.setItem("editapplicationform_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigate:true})
        }
    }
    handleClickedEdit = (row) =>{
        if(row.statusId!=="19"){
            sessionStorage.setItem("editapplicationform_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigate:true})
        }
    };

    downloadForm=()=>{
       // sessionStorage.setItem("examResult", JSON.stringify(row))
        // console.log(row)
         this.setState({navigate:true})
    }
    handleClickedEdit = (row) =>{
        row.IsMonitoring = "1"
        row.isPooling = "1"
        sessionStorage.setItem("viewtoto_" + this.state.userinfo.userId + this.state.userinfo.employeeId, null)
        sessionStorage.setItem("viewapplicationform_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
        this.setState({navigate:true})
    };
    render() {
        const {navigate} = this.state;
        if (navigate) {
            return <Redirect to="/ApplicationFormEdit" push={true} />;
        } ;
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                row.IsMonitoring = "1"
                sessionStorage.setItem("editapplicationform_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))

            }
        }
        let applicationColumn = []
        if(this.state.userinfo.roleId==="36") {
            applicationColumn = [
                {
                    dataField: 'employeeName',
                    text: 'FULL NAME',
                    headerStyle: () => {
                        return { width: "20%" };
                    },
                    formatter   :   (cell, row, isSelect) => {
                        if(row.statusId!=="19"){
                            return (
                                <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}}
                                    variant="link" onClick={e => this.handleClickedEdit(row)}
                            >{cell}</Button>
                            );
                        }
                        else{
                            return (
                                cell
                            );
                        }
                    }
                },
                {
                    dataField: 'applicationFormNo',
                    text: 'REFERENCE NO.',
                    headerStyle: () => {
                        return { width: "10%" };
                    },
                },
                {
                    dataField: 'client',
                    text: 'CLIENT/S',
                    headerStyle: () => {
                        return { width: "25%" };
                    },
                },
                {
                    dataField: 'position',
                    text: 'POSITION/s APPLIED',
                    headerStyle: () => {
                        return { width: "20%" };
                    },
                },
                {
                    dataField: 'status',
                    text: 'STATUS',
                    editable:false,
                    headerStyle: () => {
                        return { width: "10%" };
                    },
                    
                },
                {
                    dataField: 'sourcing',
                    text: 'SOURCING',
                    editable:false,
                    headerStyle: () => {
                        return { width: "15%" };
                    },
                    
                },
            ] 
        }
        else{
            applicationColumn = [
                {
                    dataField: 'employeeName',
                    text: 'FULL NAME',
                    headerStyle: () => {
                        return { width: "20%" };
                    },
                    formatter   :   (cell, row, isSelect) => {
                        if(row.statusId!=="19"){
                            return (
                                <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}}
                                    variant="link" onClick={e => this.handleClickedEdit(row)}
                            >{cell}</Button>
                            );
                        }
                        else{
                            return (
                                cell
                            );
                        }
                    }
                },
                {
                    dataField: 'applicationFormNo',
                    text: 'REFERENCE NO.',
                    headerStyle: () => {
                        return { width: "10%" };
                    },
                },
                {
                    dataField: 'client',
                    text: 'CLIENT/S',
                    headerStyle: () => {
                        return { width: "25%" };
                    },
                },
                {
                    dataField: 'position',
                    text: 'POSITION/s APPLIED',
                    headerStyle: () => {
                        return { width: "20%" };
                    },
                },
                {
                    dataField: 'status',
                    text: 'STATUS',
                    editable:false,
                    headerStyle: () => {
                        return { width: "10%" };
                    },
                    
                },
                {
                    dataField: 'aquisition',
                    text: 'AQUISITION',
                    editable:false,
                    headerStyle: () => {
                        return { width: "15%" };
                    },
                    
                },
            ] 
        }
        
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.applicationFormListGrid.map(function(item,i){
                   
                    if(item.id===row.id)
                        {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })

            }
        };
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>RECRUITMENT >> APPLICATION FORM</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Card>
                                        <Accordion defaultActiveKey="1">
                                            <div className="card-header-tab">
                                                <Accordion.Toggle as={Button} variant="link" eventKey="1" style={{color: "#FFFFFF"}}>
                                                    SEARCH FILTER
                                                </Accordion.Toggle>
                                            </div>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                                            <Typeahead 
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangeClient}
                                                                options={this.state.clientDDL}
                                                                placeholder="Select Client" 
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold"}}>employee name</Form.Label>
                                                            <Typeahead 
                                                                labelKey='employeeName'
                                                                id="basic-example"
                                                                onChange={this.handleChangeEmployee}
                                                                options={this.state.applicationGrid}
                                                                placeholder="Select employee" 
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>

                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold"}}>POSITION</Form.Label>
                                                            <Typeahead 
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangedPosition}
                                                                options={this.state.postionDDL}
                                                                placeholder="Select Position" 
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity"></Form.Group>
                                                        <Form.Group className='mt-4' as={Col} controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold"}}>date applied</Form.Label>&nbsp;&nbsp;
                                                            <DatePicker
                                                                ref='EffectivityMonth'
                                                                selected={this.state.EffectivityMonth}
                                                                onChange={this.handleChangeEffectivityMonth}
                                                               // minDate={this.minDate}
                                                                value={this.props.EffectivityMonth}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>

                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>TIN NUMBER</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="tinNumber"
                                                                value={this.state.tinNumber}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>REFERENCE NO</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="referenceNo"
                                                                value={this.state.referenceNo}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>

                                                    {/* <Form.Row>
                                                        <Form.Group as={Col} controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                                            <Typeahead 
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangeClient}
                                                                options={this.state.clientDDL}
                                                                placeholder="Select Client" 
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                                            <Typeahead 
                                                                labelKey='memberName'
                                                                id="basic-example"
                                                                onChange={this.handleChangeEmployee}
                                                                options={this.state.employeeDDL}
                                                                placeholder="Select Employee" 
                                                            />
                                                        </Form.Group>
                                                    </Form.Row> */}

                                                    <ButtonToolbar className="mt-1">
                                                        <NavLink to="/ApplicationFormDownload">
                                                            <Button  variant="warning">
                                                                download form
                                                            </Button>
                                                        </NavLink>
                                                        <Button variant="info" className="ml-auto" onClick={ this.handleSearchClick }>
                                                            Search
                                                        </Button>
                                                    </ButtonToolbar>
                                                    {/* <ButtonToolbar className="mt-2">
                                                        <NavLink to="/ApplicationFormDownload">
                                                            <Button  variant="warning">
                                                                download form
                                                            </Button>
                                                        </NavLink>
                                                    </ButtonToolbar> */}
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Accordion>
                                    </Card>
                                    
                                    
                                    <div className="mt-2">  
                                        <div>
                                            <Card.Header>APPLICATION FORM LIST</Card.Header>
                                            <BootstrapTable
                                                /* caption={Noser.TableHeader({title:"RECORD"})} */
                                                keyField = "id"
                                                data = { this.state.applicationGrid }
                                                columns = { applicationColumn }
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                rowClasses="noser-table-row-class no-checkbox"
                                                striped
                                                hover
                                                condensed
                                                cellEdit = { cellEditFactory({
                                                    mode: 'dbclick',
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                        //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                                rowEvents={ rowEvents }
                                                selectRow = { selectRow }
                                                //rowEvents={ requestMonitoringRowEvents }
                        
                                            />
                                        </div>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />
            </div> 
        )
    }

}

export  default ApplicationForm 
