import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import CasesCreate from '../Maintenance/CasesCreate';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

 
class DoleComplianceMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            //employeeDetailList           :   [],
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalTrainingRegisterShow           :   false,
            selectedClientName                  :   [],            
            getClientList                       :   [],

            employeeDetailsTableList                  :   [

                // {
                //     "id" : "1",
                //     "client" : "client",
                //     "location" : "location",
                //     "caseNo" : "caseNo",
                //     "MembersInvolved" : "MembersInvolved",
                //     "refNo" : "refNo",
                //     "dateOfAssessment" : "dateOfAssessment",
                //     "inspector" : "inspector",
                //     "findings" : "findings",
                //     "dateOfConference" : "dateOfConference",
                //     "submittedDoc" : "submittedDoc",
                //     "docDateNOI" : "docDateNOI",
                //     "dateReceived" : "dateReceived",
                //     "hearingOfficer" : "hearingOfficer",
                //     "mandatoryFindings" : "mandatoryFindings",
                //     "mandatoryDateOfConference" : "mandatoryDateOfConference",
                //     "minutesOfMeeting" : "minutesOfMeeting",
                //     "mandatorySubmittedDoc" : "mandatorySubmittedDoc",
                //     "mandatoryDocDate" : "mandatoryDocDate",
                //     "decision" : "decision",
                //     "counsel" : "counsel",
                //     "latestUpdate" : "latestUpdate",
                //     "statusDate" : "statusDate",
                //     "remarks" : "remarks",
                //     "updateStatus" : "updateStatus",
                //     "documentAttached" : "documentAttached",
                //     "action" : "edit",
                // }


            ],
            clientName:[],
            particularList: [
                {
                    "id" : "For Resolution",
                    "name" : "For Resolution"
                },
                {
                    "id" : "Pending",
                    "name" : "Pending"
                },
                {
                    "id" : "For Appeal",
                    "name" : "For Appeal"
                },
                {
                    "id" : "Closed",
                    "name" : "Closed"
                }
                
               
            ],
            selectedparticular      :   '',
            selectedparticularId    :   '',

            filterForResolution     :"For Resolution",
            filterPending           :"Pending",
            filterForAppeal         :"For Appeal",
            filterResolved          :"Closed",
            totalDoleCompliance     :"Total Dole Compliance",

            countForResolution     :[],
            countPending           :[],
            countForAppeal         :[],
            countResolved          :[],
            countTotal             :[],
            
            ListForResolution     :[],
            ListPending           :[],
            ListForAppeal         :[],
            ListResolved          :[],

            statusTableList       :[],
            TotalStatus           :[],
           

            

        }
        
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        let checkTmp = "0"
        sessionStorage.setItem("checkUpdate", JSON.stringify(checkTmp))
        //this.getTestDoleCompliance()
        this.getClientList();;
        //this.getMonitoringStatus();
        //this.getAllStatusTable()
        //this.getDoleCompliancePending();
        //this.getTestDoleComplianceClosed();
        //this.getTestDoleComplianceForResolution();
        //this.getTestDoleComplianceForAppeal();
        //this.getTestDoleComplianceTotalAll();
        this.getAllDoleCompliance()
    }

    getAllDoleCompliance =()=>{
       
        let filter_data ={}
        const getParams ={
            "_collection" : "DoleCompliance",
            "filter_data" : filter_data
        }

        console.log("axios start")

        axios
        
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{

            const data = res.data           
            console.log("DoleCompliance") 
            console.log(data) 
            var obj = (data);
            var res = [];
            let List =[]
            let pendingList = []
            let settledList = []
            let DismissedList = []
            let arrynewRetail1 = []
            let arrynewRetail2 = []
            let arrynewRetail3 = []
            let arrynewRetail4 = []
            let arrynewnonRetail1 = []
            let arrynewnonRetail2 = []
            let arrynewInternal = []
            
            data.DoleCompliance.map(function(itm,i){
                let schedOfhearing1 = ""
                let schedOfhearing2 = ""
                let purpose1 = ""
                let purpose2 = ""

               let strJson99 = "{" + itm.substring(0, itm.length).split("'),")[0]
               let cleanId = strJson99.split("ObjectId('")[1]
               console.log("cleanId")
               console.log(cleanId)

                let strJson = "{" + itm.substring(46, itm.length)
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                json.id = cleanId
             
                console.log("json")
                console.log(json)

                // for (let i = 0; i < json.scheduleOfHearingCourtDetails.length; i++) {
                //     console.log("loop d222222   ")
                //     let obj={
                //         "scheduleOfHearing" : json.scheduleOfHearingCourtDetails[i]["scheduleOfHearing"],
                //         "purpose" : json.scheduleOfHearingCourtDetails[i]["purpose"],
                //     }

                //     if(schedOfhearing1 !==""){
                //         console.log("if d222222   ")
                //         schedOfhearing1 = schedOfhearing1 + json.scheduleOfHearingCourtDetails[i]["scheduleOfHearing"]+";"+ " "
                //         purpose1 = purpose1 + json.scheduleOfHearingCourtDetails[i]["purpose"]+";"+ " "
                //     }else{
                //         console.log("else d222222   ")
                //         schedOfhearing1 = json.scheduleOfHearingCourtDetails[i]["scheduleOfHearing"]+";"+ " "
                //         purpose1 = json.scheduleOfHearingCourtDetails[i]["purpose"]+";" + " "
                //     }
                    
                // }

                // for (let i = 0; i < json.scheduleOfHearingDetails.length; i++) {
                //     console.log("loop d222222   ")
                //     let obj={
                //         "scheduleOfHearing" : json.scheduleOfHearingDetails[i]["scheduleOfHearing"],
                //         "purpose" : json.scheduleOfHearingDetails[i]["purpose"],
                //     }

                //     if(schedOfhearing2 !==""){
                //         console.log("if d222222   ")
                //         schedOfhearing2 = schedOfhearing2 + json.scheduleOfHearingDetails[i]["scheduleOfHearing"]+";"+ " "
                //         purpose2 = purpose2 + json.scheduleOfHearingDetails[i]["purpose"]+";"+ " "
                //     }else{
                //         console.log("else d222222   ")
                //         schedOfhearing2 = json.scheduleOfHearingDetails[i]["scheduleOfHearing"]+";"+ " "
                //         purpose2 = json.scheduleOfHearingDetails[i]["purpose"]+";" + " "
                //     }
                    
                // }

                // if(json.status == "Pending"){
                //     pendingList.push(json)
                // }
                // if(json.status == "Dismissed"){
                //     DismissedList.push(json)
                // }
                // if(json.status == "Settled"){
                //     settledList.push(json)
                // }

               

                res.push(json)

                

                res.map(function(d){
                    // d.schedOfhearing1 = schedOfhearing1
                    // d.purpose1 = purpose1
                    // d.schedOfhearing2 = schedOfhearing2
                    // d.purpose2 = purpose2
                    d.action = "EDIT"

                    // if(d.retailGroup === "RETAIL 1"){
                    //     arrynewRetail1.push(d)
                    //     console.log(d)
                    // }
                    // if(d.retailGroup === "RETAIL 2"){
                    //     arrynewRetail2.push(d)
                    //     console.log(d)
                    // }
                    // if(d.retailGroup === "RETAIL 3"){
                    //     arrynewRetail3.push(d)
                    //     console.log(d)
                    // }
                    // if(d.retailGroup === "RETAIL 4"){
                    //     arrynewRetail4.push(d)
                    //     console.log(d)
                    // }
                    // if(d.retailGroup === "NON-RETAIL 1"){
                    //     arrynewnonRetail1.push(d)
                    //     console.log(d)
                    // }
                    // if(d.retailGroup === "NON-RETAIL 1"){
                    //     arrynewnonRetail2.push(d)
                    //     console.log(d)
                    // }
                    // if(d.retailGroup === "INTERNAL"){
                    //     arrynewInternal.push(d)
                    //     console.log(d)
                    // }

                })
            })

            console.log("countTotalTmp Parsed Json below")
            console.log(res)

            let countTotalTmp = JSON.stringify(res.length)
          
            console.log(" pendingList")
            console.log(pendingList.length)

            let statusList = [
                {
                    id:"0",
                    pending:pendingList.length,
                    settled:settledList.length,
                    dismissed:DismissedList.length,
                    total:countTotalTmp
                }
            ]

            //TABLE SUMMARY 2
            let retailGroupTmp = [{
                "retail1":String(arrynewRetail1.length),
                "retail2":String(arrynewRetail2.length),
                "retail3":String(arrynewRetail3.length),
                "retail4":String(arrynewRetail4.length),
                "internal":String(arrynewInternal.length),
                "nonRetail1":String(arrynewnonRetail1.length),
                "nonRetail2":String(arrynewnonRetail2.length),
            }]

            this.setState({
                //statusTableList:statusList,
                employeeDetailsTableList:res,
                //retailSummaryGrid:retailGroupTmp
            })

           /*  const itemUsersDataLists = this.buildList(data["TestCriminalCases"])
            this.state.itemUserDataList  =  itemUsersDataLists

            let idListTmp = []
            for (let d = 0; d < this.state.itemUserDataList.length; d++) {
                let obj1 = {
                    'id'                :   this.state.itemUserDataList[d]['id'],
                }
                idListTmp.push(obj1)
            }

            for (let i = 0; i < res.length; i++) {
                let obj = {
                    'id'                :   idListTmp[i]["id"].replace("{","").replace("}",""),
                    'client'            :   res[i]['client'],
                    'employeeName'      :   res[i]['employeeName'],
                    'position'          :   res[i]['position'],
                    'employeeNo'        :   res[i]['employeeNo'],
                    'location'          :   res[i]['location'],
                    'dateCaseFiled'     :   res[i]['dateCaseFiled'],
                    'caseFiled'         :   res[i]['caseFiled'],
                    'venue'             :   res[i]['venue'],
                    'SchedOfHearing'    :   res[i]['SchedOfHearing'],
                    'prosecutor'        :   res[i]['prosecutor'],
                    'legal'             :   res[i]['legal'],
                    'latestUpdate'      :   res[i]['latestUpdate'],
                    'updateDate'        :   res[i]['updateDate'],
                    'remarks'           :   res[i]['remarks'],
                    'status'            :   res[i]['status'],
                    'file'              :   res[i]['file'],
                    'counsel'           :   res[i]['counsel'],
                    'action'            :   "edit",
                    
                    
                }  
                
                List.push(obj)   
                console.log("employeeDetail ListList")
                console.log(List)
                console.log("employeeDetailList List")             
                            
            }  */

            // this.setState({
            //     countTotal:countTotalTmp,
            //     employeeDetailList:List,
            // })


           // this.getCriminalCasesPending();
            

        })
        .catch(error=>{
            console.log("error: " + error)
        })
    }

    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            //console.log("Client List start ");
            //console.log(res.data);
            //console.log("Client List end");
            const data = res.data
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }

    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        this.setState({
            isloading   :false,
            isshow      :false,
            color       :"",
            message     :"",
            fade        :true ,
            clientName  : e[0].name
        })
       let clientTmp = e[0].name
       //this.getTestDoleCompliance();;       
        
    }

    getDoleCompliancePending =()=>{
       
        let filter_data ={'updateStatus':this.state.filterPending}
        const getParams ={
            "_collection" : "DoleCompliance",
            "filter_data" : filter_data
        }
        //console.log("axios start")
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
            const itemUsersDataLists = this.buildList(data["TestDoleCompliance"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            let countPendingTmp = this.state.itemUserDataList.length
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                let obj = {
                    'id'                            :   this.state.itemUserDataList[i]['id'],
                    'client'                        :   this.state.itemUserDataList[i]['client'],
                    'location'                      :   this.state.itemUserDataList[i]['location'],
                    'caseNo'                        :   this.state.itemUserDataList[i]['caseNo'],
                    'MembersInvolved'               :   this.state.itemUserDataList[i]['MembersInvolved'],
                    'refNo'                         :   this.state.itemUserDataList[i]['refNo'],
                    'dateOfAssessment'              :   this.state.itemUserDataList[i]['dateOfAssessment'],
                    'inspector'                     :   this.state.itemUserDataList[i]['inspector'],
                    'findings'                      :   this.state.itemUserDataList[i]['findings'],
                    'dateOfConference'              :   this.state.itemUserDataList[i]['dateOfConference'],
                    'submittedDoc'                  :   this.state.itemUserDataList[i]['submittedDoc'],
                    'docDateNOI'                    :   this.state.itemUserDataList[i]['docDateNOI'],
                    'dateReceived'                  :   this.state.itemUserDataList[i]['dateReceived'],
                    'hearingOfficer'                :   this.state.itemUserDataList[i]['hearingOfficer'],
                    'mandatoryFindings'             :   this.state.itemUserDataList[i]['mandatoryFindings'],
                    'mandatoryDateOfConference'     :   this.state.itemUserDataList[i]['mandatoryDateOfConference'],
                    'minutesOfMeeting'              :   this.state.itemUserDataList[i]['minutesOfMeeting'],
                    'mandatorySubmittedDoc'         :   this.state.itemUserDataList[i]['mandatorySubmittedDoc'],
                    'mandatoryDocDate'              :   this.state.itemUserDataList[i]['mandatoryDocDate'],
                    'decision'                      :   this.state.itemUserDataList[i]['decision'],
                    'counsel'                       :   this.state.itemUserDataList[i]['counsel'],                        
                    'latestUpdate'                  :   this.state.itemUserDataList[i]['latestUpdate'],
                    'statusDate'                    :   this.state.itemUserDataList[i]['statusDate'],
                    'remarks'                       :   this.state.itemUserDataList[i]['remarks'],
                    'updateStatus'                  :   this.state.itemUserDataList[i]['updateStatus'],
                    'documentAttached'              :   this.state.itemUserDataList[i]['documentAttached'],                      
                    'isDraft'                       :   this.state.itemUserDataList[i]['isDraft'],
                    'isDeleted'                     :   this.state.itemUserDataList[i]['isDeleted'],
                    'createdby'                     :   this.state.itemUserDataList[i]['createdby'],
                    'createddate'                   :   this.state.itemUserDataList[i]['createddate'],
                    'modifiedby'                    :   this.state.itemUserDataList[i]['modifiedby'],
                    'modifieddate'                  :   this.state.itemUserDataList[i]['modifieddate'],
                    'isModified'                    :   this.state.itemUserDataList[i]['isModified'],
                    
                }                

                List.push(obj)
                console.log(List)                    
                this.setState({
                    countPending:countPendingTmp
                })                   
            }                              
            this.getAllStatusTable();
        })
        .catch(error=>{
            //console.log("error: " + error)
        })
    }

    getTestDoleComplianceClosed =()=>{
       
        let filter_data ={'updateStatus':this.state.filterResolved}
        const getParams ={
            "_collection" : "DoleCompliance",
            "filter_data" : filter_data
        }
        //console.log("axios start")
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
            //console.log("res.data start")
            //console.log(res.data)
            //console.log("res.data end")
        
            const itemUsersDataLists = this.buildList(data["TestDoleCompliance"])

            this.state.itemUserDataList  =  itemUsersDataLists
            let countClosedTmp = this.state.itemUserDataList.length            
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                let obj = {
                    'id'                            :   this.state.itemUserDataList[i]['id'],
                    'client'                        :   this.state.itemUserDataList[i]['client'],
                    'location'                      :   this.state.itemUserDataList[i]['location'],
                    'caseNo'                        :   this.state.itemUserDataList[i]['caseNo'],
                    'MembersInvolved'               :   this.state.itemUserDataList[i]['MembersInvolved'],
                    'refNo'                         :   this.state.itemUserDataList[i]['refNo'],
                    'dateOfAssessment'              :   this.state.itemUserDataList[i]['dateOfAssessment'],
                    'inspector'                     :   this.state.itemUserDataList[i]['inspector'],
                    'findings'                      :   this.state.itemUserDataList[i]['findings'],
                    'dateOfConference'              :   this.state.itemUserDataList[i]['dateOfConference'],
                    'submittedDoc'                  :   this.state.itemUserDataList[i]['submittedDoc'],
                    'docDateNOI'                    :   this.state.itemUserDataList[i]['docDateNOI'],
                    'dateReceived'                  :   this.state.itemUserDataList[i]['dateReceived'],
                    'hearingOfficer'                :   this.state.itemUserDataList[i]['hearingOfficer'],
                    'mandatoryFindings'             :   this.state.itemUserDataList[i]['mandatoryFindings'],
                    'mandatoryDateOfConference'     :   this.state.itemUserDataList[i]['mandatoryDateOfConference'],
                    'minutesOfMeeting'              :   this.state.itemUserDataList[i]['minutesOfMeeting'],
                    'mandatorySubmittedDoc'         :   this.state.itemUserDataList[i]['mandatorySubmittedDoc'],
                    'mandatoryDocDate'              :   this.state.itemUserDataList[i]['mandatoryDocDate'],
                    'decision'                      :   this.state.itemUserDataList[i]['decision'],
                    'counsel'                       :   this.state.itemUserDataList[i]['counsel'],                        
                    'latestUpdate'                  :   this.state.itemUserDataList[i]['latestUpdate'],
                    'statusDate'                    :   this.state.itemUserDataList[i]['statusDate'],
                    'remarks'                       :   this.state.itemUserDataList[i]['remarks'],
                    'updateStatus'                  :   this.state.itemUserDataList[i]['updateStatus'],
                    'documentAttached'              :   this.state.itemUserDataList[i]['documentAttached'],                      
                    'isDraft'                       :   this.state.itemUserDataList[i]['isDraft'],
                    'isDeleted'                     :   this.state.itemUserDataList[i]['isDeleted'],
                    'createdby'                     :   this.state.itemUserDataList[i]['createdby'],
                    'createddate'                   :   this.state.itemUserDataList[i]['createddate'],
                    'modifiedby'                    :   this.state.itemUserDataList[i]['modifiedby'],
                    'modifieddate'                  :   this.state.itemUserDataList[i]['modifieddate'],
                    'isModified'                    :   this.state.itemUserDataList[i]['isModified'],
                    
                }                

                List.push(obj)
                //console.log(List)                    
                this.setState({
                    countResolved:countClosedTmp

                })                   
            }                              
            this.getAllStatusTable()
        })
        .catch(error=>{
            //console.log("error: " + error)
        })
    }

    getTestDoleComplianceForResolution =()=>{
       
        let filter_data ={'updateStatus':this.state.filterForResolution}
        const getParams ={
            "_collection" : "DoleCompliance",
            "filter_data" : filter_data
        }
        //console.log("axios start")
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
            //console.log("res.data start")
            //console.log(res.data)
            //console.log("res.data end")
        
            const itemUsersDataLists = this.buildList(data["TestDoleCompliance"])
            
            this.state.itemUserDataList  =  itemUsersDataLists

            let countForResolutionTmp = this.state.itemUserDataList.length
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                let obj = {
                    'id'                            :   this.state.itemUserDataList[i]['id'],
                    'client'                        :   this.state.itemUserDataList[i]['client'],
                    'location'                      :   this.state.itemUserDataList[i]['location'],
                    'caseNo'                        :   this.state.itemUserDataList[i]['caseNo'],
                    'MembersInvolved'               :   this.state.itemUserDataList[i]['MembersInvolved'],
                    'refNo'                         :   this.state.itemUserDataList[i]['refNo'],
                    'dateOfAssessment'              :   this.state.itemUserDataList[i]['dateOfAssessment'],
                    'inspector'                     :   this.state.itemUserDataList[i]['inspector'],
                    'findings'                      :   this.state.itemUserDataList[i]['findings'],
                    'dateOfConference'              :   this.state.itemUserDataList[i]['dateOfConference'],
                    'submittedDoc'                  :   this.state.itemUserDataList[i]['submittedDoc'],
                    'docDateNOI'                    :   this.state.itemUserDataList[i]['docDateNOI'],
                    'dateReceived'                  :   this.state.itemUserDataList[i]['dateReceived'],
                    'hearingOfficer'                :   this.state.itemUserDataList[i]['hearingOfficer'],
                    'mandatoryFindings'             :   this.state.itemUserDataList[i]['mandatoryFindings'],
                    'mandatoryDateOfConference'     :   this.state.itemUserDataList[i]['mandatoryDateOfConference'],
                    'minutesOfMeeting'              :   this.state.itemUserDataList[i]['minutesOfMeeting'],
                    'mandatorySubmittedDoc'         :   this.state.itemUserDataList[i]['mandatorySubmittedDoc'],
                    'mandatoryDocDate'              :   this.state.itemUserDataList[i]['mandatoryDocDate'],
                    'decision'                      :   this.state.itemUserDataList[i]['decision'],
                    'counsel'                       :   this.state.itemUserDataList[i]['counsel'],                        
                    'latestUpdate'                  :   this.state.itemUserDataList[i]['latestUpdate'],
                    'statusDate'                    :   this.state.itemUserDataList[i]['statusDate'],
                    'remarks'                       :   this.state.itemUserDataList[i]['remarks'],
                    'updateStatus'                  :   this.state.itemUserDataList[i]['updateStatus'],
                    'documentAttached'              :   this.state.itemUserDataList[i]['documentAttached'],                      
                    'isDraft'                       :   this.state.itemUserDataList[i]['isDraft'],
                    'isDeleted'                     :   this.state.itemUserDataList[i]['isDeleted'],
                    'createdby'                     :   this.state.itemUserDataList[i]['createdby'],
                    'createddate'                   :   this.state.itemUserDataList[i]['createddate'],
                    'modifiedby'                    :   this.state.itemUserDataList[i]['modifiedby'],
                    'modifieddate'                  :   this.state.itemUserDataList[i]['modifieddate'],
                    'isModified'                    :   this.state.itemUserDataList[i]['isModified'],
                    
                }                

                List.push(obj)
                //console.log(List)                    
                this.setState({
                    countForResolution:countForResolutionTmp

                })                   
            }                              
            this.getAllStatusTable()
        })
        .catch(error=>{
            //console.log("error: " + error)
        })
    }

    getTestDoleComplianceForAppeal =()=>{
       
        let filter_data ={'updateStatus':this.state.filterForAppeal}
        const getParams ={
            "_collection" : "DoleCompliance",
            "filter_data" : filter_data
        }
        //console.log("axios start")
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
            //console.log("res.data start")
            //console.log(res.data)
            //console.log("res.data end")
        
            const itemUsersDataLists = this.buildList(data["TestDoleCompliance"])
            
            this.state.itemUserDataList  =  itemUsersDataLists

            let countForAppealTmp = this.state.itemUserDataList.length
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                let obj = {
                    'id'                            :   this.state.itemUserDataList[i]['id'],
                    'client'                        :   this.state.itemUserDataList[i]['client'],
                    'location'                      :   this.state.itemUserDataList[i]['location'],
                    'caseNo'                        :   this.state.itemUserDataList[i]['caseNo'],
                    'MembersInvolved'               :   this.state.itemUserDataList[i]['MembersInvolved'],
                    'refNo'                         :   this.state.itemUserDataList[i]['refNo'],
                    'dateOfAssessment'              :   this.state.itemUserDataList[i]['dateOfAssessment'],
                    'inspector'                     :   this.state.itemUserDataList[i]['inspector'],
                    'findings'                      :   this.state.itemUserDataList[i]['findings'],
                    'dateOfConference'              :   this.state.itemUserDataList[i]['dateOfConference'],
                    'submittedDoc'                  :   this.state.itemUserDataList[i]['submittedDoc'],
                    'docDateNOI'                    :   this.state.itemUserDataList[i]['docDateNOI'],
                    'dateReceived'                  :   this.state.itemUserDataList[i]['dateReceived'],
                    'hearingOfficer'                :   this.state.itemUserDataList[i]['hearingOfficer'],
                    'mandatoryFindings'             :   this.state.itemUserDataList[i]['mandatoryFindings'],
                    'mandatoryDateOfConference'     :   this.state.itemUserDataList[i]['mandatoryDateOfConference'],
                    'minutesOfMeeting'              :   this.state.itemUserDataList[i]['minutesOfMeeting'],
                    'mandatorySubmittedDoc'         :   this.state.itemUserDataList[i]['mandatorySubmittedDoc'],
                    'mandatoryDocDate'              :   this.state.itemUserDataList[i]['mandatoryDocDate'],
                    'decision'                      :   this.state.itemUserDataList[i]['decision'],
                    'counsel'                       :   this.state.itemUserDataList[i]['counsel'],                        
                    'latestUpdate'                  :   this.state.itemUserDataList[i]['latestUpdate'],
                    'statusDate'                    :   this.state.itemUserDataList[i]['statusDate'],
                    'remarks'                       :   this.state.itemUserDataList[i]['remarks'],
                    'updateStatus'                  :   this.state.itemUserDataList[i]['updateStatus'],
                    'documentAttached'              :   this.state.itemUserDataList[i]['documentAttached'],                      
                    'isDraft'                       :   this.state.itemUserDataList[i]['isDraft'],
                    'isDeleted'                     :   this.state.itemUserDataList[i]['isDeleted'],
                    'createdby'                     :   this.state.itemUserDataList[i]['createdby'],
                    'createddate'                   :   this.state.itemUserDataList[i]['createddate'],
                    'modifiedby'                    :   this.state.itemUserDataList[i]['modifiedby'],
                    'modifieddate'                  :   this.state.itemUserDataList[i]['modifieddate'],
                    'isModified'                    :   this.state.itemUserDataList[i]['isModified'],
                    
                }                

                List.push(obj)
                //console.log(List)                    
                this.setState({
                    countForAppeal:countForAppealTmp

                })                  
            }                              
            this.getAllStatusTable()
        })
        .catch(error=>{
            //console.log("error: " + error)
        })
    }

    getTestDoleComplianceTotalAll =()=>{
       
        let filter_data ={}
        const getParams ={
            "_collection" : "DoleCompliance",
            "filter_data" : filter_data
        }
        //console.log("axios start")
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
            console.log("res.data start")
            console.log(res.data)
            console.log("res.data end")
        
            const itemUsersDataLists = this.buildList(data["TestDoleCompliance"])
            
            this.state.itemUserDataList  =  itemUsersDataLists

            let countTotalAllTmp = this.state.itemUserDataList.length
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                let obj = {
                    'id'                            :   this.state.itemUserDataList[i]['id'],
                    'client'                        :   this.state.itemUserDataList[i]['client'],
                    'location'                      :   this.state.itemUserDataList[i]['location'],
                    'caseNo'                        :   this.state.itemUserDataList[i]['caseNo'],
                    'MembersInvolved'               :   this.state.itemUserDataList[i]['MembersInvolved'],
                    'refNo'                         :   this.state.itemUserDataList[i]['refNo'],
                    'dateOfAssessment'              :   this.state.itemUserDataList[i]['dateOfAssessment'],
                    'inspector'                     :   this.state.itemUserDataList[i]['inspector'],
                    'findings'                      :   this.state.itemUserDataList[i]['findings'],
                    'dateOfConference'              :   this.state.itemUserDataList[i]['dateOfConference'],
                    'submittedDoc'                  :   this.state.itemUserDataList[i]['submittedDoc'],
                    'docDateNOI'                    :   this.state.itemUserDataList[i]['docDateNOI'],
                    'dateReceived'                  :   this.state.itemUserDataList[i]['dateReceived'],
                    'hearingOfficer'                :   this.state.itemUserDataList[i]['hearingOfficer'],
                    'mandatoryFindings'             :   this.state.itemUserDataList[i]['mandatoryFindings'],
                    'mandatoryDateOfConference'     :   this.state.itemUserDataList[i]['mandatoryDateOfConference'],
                    'minutesOfMeeting'              :   this.state.itemUserDataList[i]['minutesOfMeeting'],
                    'mandatorySubmittedDoc'         :   this.state.itemUserDataList[i]['mandatorySubmittedDoc'],
                    'mandatoryDocDate'              :   this.state.itemUserDataList[i]['mandatoryDocDate'],
                    'decision'                      :   this.state.itemUserDataList[i]['decision'],
                    'counsel'                       :   this.state.itemUserDataList[i]['counsel'],                        
                    'latestUpdate'                  :   this.state.itemUserDataList[i]['latestUpdate'],
                    'statusDate'                    :   this.state.itemUserDataList[i]['statusDate'],
                    'remarks'                       :   this.state.itemUserDataList[i]['remarks'],
                    'updateStatus'                  :   this.state.itemUserDataList[i]['updateStatus'],
                    'documentAttached'              :   this.state.itemUserDataList[i]['documentAttached'],  
                    'action'                        :   "edit",                    
                    'isDraft'                       :   this.state.itemUserDataList[i]['isDraft'],
                    'isDeleted'                     :   this.state.itemUserDataList[i]['isDeleted'],
                    'createdby'                     :   this.state.itemUserDataList[i]['createdby'],
                    'createddate'                   :   this.state.itemUserDataList[i]['createddate'],
                    'modifiedby'                    :   this.state.itemUserDataList[i]['modifiedby'],
                    'modifieddate'                  :   this.state.itemUserDataList[i]['modifieddate'],
                    'isModified'                    :   this.state.itemUserDataList[i]['isModified'],
                    
                }                

                List.push(obj)
                //console.log(List)                    
                this.setState({
                    countTotal:countTotalAllTmp,
                    employeeDetailsTableList:List

                })              
            }                              
            this.getAllStatusTable()
        })
        .catch(error=>{
            //console.log("error: " + error)
        })
    }
    
    getAllStatusTable=()=>{

        let allStatus           =[]
        
        let id1Tmp              ='1'
        let id2Tmp              ='2'
        let id3Tmp              ='3'
        let id4Tmp              ='4'
        let id5Tmp              ='5'
        let isDraftTmp          ='0'
        let isDeletedTmp        ='0'
        let createdbyTmp        ='0'
        let createddateTmp      ='0'
        let modifiedbyTmp       ='0'
        let modifieddateTmp     ='0'
        let isModifiedTmp       ='0'

        
        let filterForResolutionTmp = this.state.filterForResolution
        let resolutionCountTmp = this.state.countForResolution
        let obj1 = {
            'id'                    :   id1Tmp,
            'status'                :   filterForResolutionTmp,
            'isDraft'               :   isDraftTmp,
            'isDeleted'             :   isDeletedTmp,
            'createdby'             :   createdbyTmp,
            'createddate'           :   createddateTmp,
            'modifiedby'            :   modifiedbyTmp,
            'modifieddate'          :   modifieddateTmp,
            'isModified'            :   isModifiedTmp,
            'count'                 :   resolutionCountTmp,
            //'persentage'            :   pendingListTmp['persentage'],
        }
        allStatus.push(obj1)

        let filterForAppealTmp = this.state.filterForAppeal
        let countForAppealTmp = this.state.countForAppeal
        let obj2 = {
            'id'                    :   id2Tmp,
            'status'                :   filterForAppealTmp,
            'isDraft'               :   isDraftTmp,
            'isDeleted'             :   isDeletedTmp,
            'createdby'             :   createdbyTmp,
            'createddate'           :   createddateTmp,
            'modifiedby'            :   modifiedbyTmp,
            'modifieddate'          :   modifieddateTmp,
            'isModified'            :   isModifiedTmp,
            'count'                 :   countForAppealTmp,
            //'persentage'            :   pendingListTmp['persentage'],
        }
        allStatus.push(obj2)

        let filterPendingTmp = this.state.filterPending
        let countPendingTmp = this.state.countPending
        let obj3 = {
            'id'                    :   id3Tmp,
            'status'                :   filterPendingTmp,
            'isDraft'               :   isDraftTmp,
            'isDeleted'             :   isDeletedTmp,
            'createdby'             :   createdbyTmp,
            'createddate'           :   createddateTmp,
            'modifiedby'            :   modifiedbyTmp,
            'modifieddate'          :   modifieddateTmp,
            'isModified'            :   isModifiedTmp,
            'count'                 :   countPendingTmp,
            //'persentage'            :   pendingListTmp['persentage'],
        }
        allStatus.push(obj3)

        let filterResolvedTmp = this.state.filterResolved
        let countResolvedTmp = this.state.countResolved
        let obj4 = {
            'id'                    :   id4Tmp,
            'status'                :   filterResolvedTmp,
            'isDraft'               :   isDraftTmp,
            'isDeleted'             :   isDeletedTmp,
            'createdby'             :   createdbyTmp,
            'createddate'           :   createddateTmp,
            'modifiedby'            :   modifiedbyTmp,
            'modifieddate'          :   modifieddateTmp,
            'isModified'            :   isModifiedTmp,
            'count'                 :   countResolvedTmp,
            //'persentage'            :   pendingListTmp['persentage'],
        }
        allStatus.push(obj4)

        let totalDoleComplianceTmp = this.state.totalDoleCompliance
        let countTotalTmp = this.state.countTotal
        let obj5 = {
            'id'                    :   id5Tmp,
            'status'                :   totalDoleComplianceTmp,
            'isDraft'               :   isDraftTmp,
            'isDeleted'             :   isDeletedTmp,
            'createdby'             :   createdbyTmp,
            'createddate'           :   createddateTmp,
            'modifiedby'            :   modifiedbyTmp,
            'modifieddate'          :   modifieddateTmp,
            'isModified'            :   isModifiedTmp,
            'count'                 :   countTotalTmp,
            //'persentage'            :   pendingListTmp['persentage'],
        }
        allStatus.push(obj5)

        this.setState({
            statusTableList:allStatus
        })
       
    }

   /*  showTrainingRegisterModal = (e) => {
        this.setState({modalTrainingRegisterShow: true})
    } */
    handleModalClose = (e) =>{
        this.setState({modalTrainingRegisterShow: false})

    }

    GridDataModified(oldValue, newValue, id, column) {
        //////console.log(id)
        this.state.employeeDetailsTableList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }
    
    getMonitoringStatus =()=>{
       
        let filter_data ={}
        const getParams ={
            "_collection" : "MonitoringStatus",
            "filter_data" : filter_data
        }
        //console.log("axios start")
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
           
            const itemUsersDataLists = this.buildListTestMonitoringStatus(data["TestMonitoringStatus"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
          
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                let obj = {
                    'id'                    :   this.state.itemUserDataList[i]['id'],
                    'status'                :   this.state.itemUserDataList[i]['status'],
                    'isDraft'               :   this.state.itemUserDataList[i]['isDraft'],
                    'isDeleted'             :   this.state.itemUserDataList[i]['isDeleted'],
                    'createdby'             :   this.state.itemUserDataList[i]['createdby'],
                    'createddate'           :   this.state.itemUserDataList[i]['createddate'],
                    'modifiedby'            :   this.state.itemUserDataList[i]['modifiedby'],
                    'modifieddate'          :   this.state.itemUserDataList[i]['modifieddate'],
                    'isModified'            :   this.state.itemUserDataList[i]['isModified'],
                   
                }
                List.push(obj) 
                
            }                                     

        })
        .catch(error=>{
            //console.log("error: " + error)
        })
    }
    
    buildListTestMonitoringStatus(data) {
        ////console.log("data start")
        let itemList =[]
    
        let idList                      =[]
        let statusList                  =[]
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
        
    
        for (let i = 0; i < data.length; i++) {           
    
            let s1 = data[i].split("':")
            
            let idClean                     = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let statusTmp                   = s1[2].split("',")[0]
            let isDraftListTmp              = s1[3].split("',")[0]
            let isDeletedListTmp            = s1[4].split("',")[0]
            let createdbyListTmp            = s1[5].split("',")[0]
            let createddateListTmp          = s1[6].split("',")[0]
            let modifiedbyListTmp           = s1[7].split("',")[0]
            let modifieddateListTmp         = s1[8].split("',")[0]
            let isModifiedListTmp           = s1[9].split("',")[0]

        
            idList.push(IdClean2.replace(" '",""))
            statusList.push(statusTmp.replace(" '",""))
            
            isDraftList.push(isDraftListTmp.replace(" '",""))
            isDeletedList.push(isDeletedListTmp.replace(" '",""))
            createdbyList.push(createdbyListTmp.replace(" '",""))
            createddateList.push(createddateListTmp.replace(" '",""))
            modifiedbyList.push(modifiedbyListTmp.replace(" '",""))
            modifieddateList.push(modifieddateListTmp.replace(" '",""))
            isModifiedList.push(isModifiedListTmp.replace(" '","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'status'                :   statusList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }
               
        return itemList
    }

    getTestDoleCompliance =()=>{
       
        let filter_data ={'client':this.state.selectedClientName}
        const getParams ={
            "_collection" : "DoleCompliance",
            "filter_data" : filter_data
        }
       
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            /* let data = res.data
                   
            const itemUsersDataLists = this.buildList(data["TestDoleCompliance"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                let obj = {
                    'id'                            :   this.state.itemUserDataList[i]['id'],
                    'client'                        :   this.state.itemUserDataList[i]['client'],
                    'location'                      :   this.state.itemUserDataList[i]['location'],
                    'caseNo'                        :   this.state.itemUserDataList[i]['caseNo'],
                    'MembersInvolved'               :   this.state.itemUserDataList[i]['MembersInvolved'],
                    'refNo'                         :   this.state.itemUserDataList[i]['refNo'],
                    'dateOfAssessment'              :   this.state.itemUserDataList[i]['dateOfAssessment'],
                    'inspector'                     :   this.state.itemUserDataList[i]['inspector'],
                    'findings'                      :   this.state.itemUserDataList[i]['findings'],
                    'dateOfConference'              :   this.state.itemUserDataList[i]['dateOfConference'],
                    'submittedDoc'                  :   this.state.itemUserDataList[i]['submittedDoc'],
                    'docDateNOI'                    :   this.state.itemUserDataList[i]['docDateNOI'],
                    'dateReceived'                  :   this.state.itemUserDataList[i]['dateReceived'],
                    'hearingOfficer'                :   this.state.itemUserDataList[i]['hearingOfficer'],
                    'mandatoryFindings'             :   this.state.itemUserDataList[i]['mandatoryFindings'],
                    'mandatoryDateOfConference'     :   this.state.itemUserDataList[i]['mandatoryDateOfConference'],
                    'minutesOfMeeting'              :   this.state.itemUserDataList[i]['minutesOfMeeting'],
                    'mandatorySubmittedDoc'         :   this.state.itemUserDataList[i]['mandatorySubmittedDoc'],
                    'mandatoryDocDate'              :   this.state.itemUserDataList[i]['mandatoryDocDate'],
                    'decision'                      :   this.state.itemUserDataList[i]['decision'],
                    'counsel'                       :   this.state.itemUserDataList[i]['counsel'],                        
                    'latestUpdate'                  :   this.state.itemUserDataList[i]['latestUpdate'],
                    'statusDate'                    :   this.state.itemUserDataList[i]['statusDate'],
                    'remarks'                       :   this.state.itemUserDataList[i]['remarks'],
                    'updateStatus'                  :   this.state.itemUserDataList[i]['updateStatus'],
                    'documentAttached'              :   this.state.itemUserDataList[i]['documentAttached'],                      
                    'isDraft'                       :   this.state.itemUserDataList[i]['isDraft'],
                    'isDeleted'                     :   this.state.itemUserDataList[i]['isDeleted'],
                    'createdby'                     :   this.state.itemUserDataList[i]['createdby'],
                    'createddate'                   :   this.state.itemUserDataList[i]['createddate'],
                    'modifiedby'                    :   this.state.itemUserDataList[i]['modifiedby'],
                    'modifieddate'                  :   this.state.itemUserDataList[i]['modifieddate'],
                    'isModified'                    :   this.state.itemUserDataList[i]['isModified'],
                    
                }                

                List.push(obj)
                                 
                this.setState({
                    employeeDetailsTableList:List,
                    isloading:false
                })                  
            }   */            
            

            const data = res.data
            console.log("Raw Json")
            console.log(data)
            console.log("End Raw Json")
            var obj = (data);
            var res = [];
            
            data.TestDoleCompliance.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                res.push(json)
            })
            console.log("Parsed Json below")
            console.log(res)

            this.setState({
                employeeDetailsTableList:res,
                isloading:false
            }) 

        })
        .catch(error=>{
            this.setState({
                isloading:false
            })  
            console.log("error: " + error)
        })
    } 
        
    buildList(data) {
        ////console.log("data start")
        let itemList =[]
    
        let idList                          =[]
        let clientList                      =[]
        let locationList                    =[]
        let caseNoList                      =[]
        let MembersInvolvedList             =[]
        let refNoList                       =[]
        let dateOfAssessmentList            =[]
        let inspectorList                   =[]
        let findingsList                    =[]
        let dateOfConferenceList            =[]
        let submittedDocList                =[]
        let docDateNOIList                  =[]
        let dateReceivedList                =[]
        let hearingOfficerList              =[]
        let mandatoryFindingsList           =[]
        let mandatoryDateOfConferenceList   =[]
        let minutesOfMeetingList            =[]
        let mandatorySubmittedDocList       =[]
        let mandatoryDocDateList            =[]
        let decisionList                    =[]
        let counselList                     =[]
        let latestUpdateList                =[]
        let statusDateList                  =[]
        let remarksList                     =[]
        let updateStatusList                =[]
        let documentAttachedList            =[]        
        let isDraftList                     =[]
        let isDeletedList                   =[]
        let createdbyList                   =[]
        let createddateList                 =[]
        let modifiedbyList                  =[]
        let modifieddateList                =[]
        let isModifiedList                  =[]
        
    
        for (let i = 0; i < data.length; i++) {
            
    
            let s1 = data[i].split("':")
            
            let idClean                         = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                        = idClean.split("',")[0]
            let clientTmp                       = s1[2].split("', '")[0]
            let locationTmp                     = s1[3].split(",")[0]
            let caseNoTmp                       = s1[4].split("',")[0]
            let MembersInvolvedTmp              = s1[5].split("',")[0]
            let refNoTmp                        = s1[6].split("',")[0]
            let dateOfAssessmentTmp             = s1[7].split("',")[0]
            let inspectorTmp                    = s1[8].split("',")[0]
            let findingsTmp                     = s1[9].split("',")[0]
            let dateOfConferenceTmp             = s1[10].split("',")[0]
            let submittedDocTmp                 = s1[11].split("',")[0]
            let docDateNOITmp                   = s1[12].split("',")[0]
            let dateReceivedTmp                 = s1[13].split("',")[0]
            let hearingOfficerTmp               = s1[14].split("',")[0]
            let mandatoryFindingsTmp            = s1[15].split("',")[0]
            let mandatoryDateOfConferenceTmp    = s1[16].split("',")[0]
            let minutesOfMeetingTmp             = s1[17].split("',")[0]
            let mandatorySubmittedDocTmp        = s1[18].split("',")[0]
            let mandatoryDocDateTmp             = s1[19].split("',")[0]
            let decisionTmp                     = s1[20].split("',")[0]
            let counselTmp                      = s1[21].split("',")[0]
            let latestUpdateTmp                 = s1[22].split("',")[0]
            let statusDateTmp                   = s1[23].split("',")[0]
            let remarksTmp                      = s1[24].split("',")[0]
            let updateStatusTmp                 = s1[25].split("',")[0]
            let documentAttachedTmp             = s1[26].split("',")[0]            
            let isDraftListTmp                  = s1[27].split("',")[0]
            let isDeletedListTmp                = s1[28].split("',")[0]
            let createdbyListTmp                = s1[29].split("',")[0]
            let createddateListTmp              = s1[30].split("',")[0]
            let modifiedbyListTmp               = s1[31].split("',")[0]
            let modifieddateListTmp             = s1[32].split("',")[0]
            let isModifiedListTmp               = s1[33].split("',")[0]

        
            idList.push(IdClean2.replace(" '",""))
            clientList.push(clientTmp.replace(" '",""))
            locationList.push(locationTmp.replace(" '","").replace("'",""))
            caseNoList.push(caseNoTmp.replace(" '",""))
            MembersInvolvedList.push(MembersInvolvedTmp.replace(" '",""))
            refNoList.push(refNoTmp.replace(" '",""))
            dateOfAssessmentList.push(dateOfAssessmentTmp.replace(" '",""))
            inspectorList.push(inspectorTmp.replace(" '",""))
            findingsList.push(findingsTmp.replace(" '",""))
            dateOfConferenceList.push(dateOfConferenceTmp.replace(" '",""))
            submittedDocList.push(submittedDocTmp.replace(" '",""))
            docDateNOIList.push(docDateNOITmp.replace(" '",""))
            dateReceivedList.push(dateReceivedTmp.replace(" '",""))
            hearingOfficerList.push(hearingOfficerTmp.replace(" '",""))
            mandatoryFindingsList.push(mandatoryFindingsTmp.replace(" '",""))
            mandatoryDateOfConferenceList.push(mandatoryDateOfConferenceTmp.replace(" '",""))
            minutesOfMeetingList.push(minutesOfMeetingTmp.replace(" '",""))
            mandatorySubmittedDocList.push(mandatorySubmittedDocTmp.replace(" '",""))
            mandatoryDocDateList.push(mandatoryDocDateTmp.replace(" '",""))
            decisionList.push(decisionTmp.replace(" '",""))
            counselList.push(counselTmp.replace(" '",""))
            latestUpdateList.push(latestUpdateTmp.replace(" '",""))
            statusDateList.push(statusDateTmp.replace(" '",""))
            remarksList.push(remarksTmp.replace(" '",""))
            updateStatusList.push(updateStatusTmp.replace(" '",""))
            documentAttachedList.push(documentAttachedTmp.replace(" '",""))                       
            isDraftList.push(isDraftListTmp.replace(" '",""))
            isDeletedList.push(isDeletedListTmp.replace(" '",""))
            createdbyList.push(createdbyListTmp.replace(" '",""))
            createddateList.push(createddateListTmp.replace(" '",""))
            modifiedbyList.push(modifiedbyListTmp.replace(" '",""))
            modifieddateList.push(modifieddateListTmp.replace(" '",""))
            isModifiedList.push(isModifiedListTmp.replace(" '","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                            :   idList[i],
                'client'                        :   clientList[i],
                'location'                      :   locationList[i],
                'caseNo'                        :   caseNoList[i],
                'MembersInvolved'               :   MembersInvolvedList[i],
                'refNo'                         :   refNoList[i],
                'dateOfAssessment'              :   dateOfAssessmentList[i],
                'inspector'                     :   inspectorList[i],
                'findings'                      :   findingsList[i],
                'dateOfConference'              :   dateOfConferenceList[i],
                'submittedDoc'                  :   submittedDocList[i],
                'docDateNOI'                    :   docDateNOIList[i],
                'dateReceived'                  :   dateReceivedList[i],
                'hearingOfficer'                :   hearingOfficerList[i],
                'mandatoryFindings'             :   mandatoryFindingsList[i],
                'mandatoryDateOfConference'     :   mandatoryDateOfConferenceList[i],
                'minutesOfMeeting'              :   minutesOfMeetingList[i],
                'mandatorySubmittedDoc'         :   mandatorySubmittedDocList[i],
                'mandatoryDocDate'              :   mandatoryDocDateList[i],
                'decision'                      :   decisionList[i],
                'counsel'                       :   counselList[i],
                'latestUpdate'                  :   latestUpdateList[i],
                'statusDate'                    :   statusDateList[i],
                'remarks'                       :   remarksList[i],
                'updateStatus'                  :   updateStatusList[i],
                'documentAttached'              :   documentAttachedList[i],                
                'isDraft'                       :   isDraftList[i],
                'isDeleted'                     :   isDeletedList[i],
                'createdby'                     :   createdbyList[i],
                'createddate'                   :   createddateList[i],
                'modifiedby'                    :   modifiedbyList[i],
                'modifieddate'                  :   modifieddateList[i],
                'isModified'                    :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }        
      
        return itemList
    }

    getEmployeeDetails =(_objfd)=>{
       
        //let filter_data ={'updateStatus':this.state.selectedparticular}
        const getParams ={
            "_collection" : "DoleCompliance",
            "filter_data" : _objfd
        }
       
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
           /*  let data = res.data
            const itemUsersDataLists = this.buildList(data["TestDoleCompliance"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                let obj = {
                    'id'                            :   this.state.itemUserDataList[i]['id'],
                    'client'                        :   this.state.itemUserDataList[i]['client'],
                    'location'                      :   this.state.itemUserDataList[i]['location'],
                    'caseNo'                        :   this.state.itemUserDataList[i]['caseNo'],
                    'MembersInvolved'               :   this.state.itemUserDataList[i]['MembersInvolved'],
                    'refNo'                         :   this.state.itemUserDataList[i]['refNo'],
                    'dateOfAssessment'              :   this.state.itemUserDataList[i]['dateOfAssessment'],
                    'inspector'                     :   this.state.itemUserDataList[i]['inspector'],
                    'findings'                      :   this.state.itemUserDataList[i]['findings'],
                    'dateOfConference'              :   this.state.itemUserDataList[i]['dateOfConference'],
                    'submittedDoc'                  :   this.state.itemUserDataList[i]['submittedDoc'],
                    'docDateNOI'                    :   this.state.itemUserDataList[i]['docDateNOI'],
                    'dateReceived'                  :   this.state.itemUserDataList[i]['dateReceived'],
                    'hearingOfficer'                :   this.state.itemUserDataList[i]['hearingOfficer'],
                    'mandatoryFindings'             :   this.state.itemUserDataList[i]['mandatoryFindings'],
                    'mandatoryDateOfConference'     :   this.state.itemUserDataList[i]['mandatoryDateOfConference'],
                    'minutesOfMeeting'              :   this.state.itemUserDataList[i]['minutesOfMeeting'],
                    'mandatorySubmittedDoc'         :   this.state.itemUserDataList[i]['mandatorySubmittedDoc'],
                    'mandatoryDocDate'              :   this.state.itemUserDataList[i]['mandatoryDocDate'],
                    'decision'                      :   this.state.itemUserDataList[i]['decision'],
                    'counsel'                       :   this.state.itemUserDataList[i]['counsel'],                        
                    'latestUpdate'                  :   this.state.itemUserDataList[i]['latestUpdate'],
                    'statusDate'                    :   this.state.itemUserDataList[i]['statusDate'],
                    'remarks'                       :   this.state.itemUserDataList[i]['remarks'],
                    'updateStatus'                  :   this.state.itemUserDataList[i]['updateStatus'],
                    'documentAttached'              :   this.state.itemUserDataList[i]['documentAttached'],                      
                    'isDraft'                       :   this.state.itemUserDataList[i]['isDraft'],
                    'isDeleted'                     :   this.state.itemUserDataList[i]['isDeleted'],
                    'createdby'                     :   this.state.itemUserDataList[i]['createdby'],
                    'createddate'                   :   this.state.itemUserDataList[i]['createddate'],
                    'modifiedby'                    :   this.state.itemUserDataList[i]['modifiedby'],
                    'modifieddate'                  :   this.state.itemUserDataList[i]['modifieddate'],
                    'isModified'                    :   this.state.itemUserDataList[i]['isModified'],
                    
                }                

                List.push(obj)                  
                this.setState({
                    employeeDetailsTableList:List,
                    isloading:false
                })                   
            }   */     
            
            const data = res.data
            console.log("Raw Json")
            console.log(data)
            console.log("End Raw Json")
            var obj = (data);
            var res = [];
            
            data.TestDoleCompliance.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                res.push(json)
            })
            console.log("Parsed Json below")
            console.log(res)

            this.setState({
                employeeDetailsTableList:res,
                isloading:false
            }) 

        })
        .catch(error=>{
            console.log("error: " + error)
        })
    } 

    onChangeparticular = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedparticular     :   '',
                selectedparticularId   :   ''
            })
            return
        }
        this.state.selectedparticular = e[0].name
        this.state.selectedparticularId = e[0].id
       
        this.setState({
            
            isshow   :   true,
            selectedparticular : e[0].id

        })
        
    }

    handleChangeSearch = () => {
       
        this.setState({
            isloading:true
        })

        let _fdata = this.helperBuildCriteria()
        let _filterData = _fdata.split(",")
        let _objfd = {}

        let jsonData = {};
        for (let i = 0; i < _filterData.length - 1; i++) { 
            let columnName = _filterData[i].split(":")[0]
            _objfd[columnName] = _filterData[i].split(":")[1];
        }
        console.log("filter_data")
        console.log(_objfd)

        this.getEmployeeDetails(_objfd);
    }

    helperBuildCriteria = () => {
        
        let _filterData = ""
        let _client = ""
        let _status = ""

        if( this.state.selectedClientName != "" || this.state.selectedClientName !== ""){
            _client = "client" +":" + this.state.selectedClientName + ","
        }
        if(this.state.selectedparticular != "" || this.state.selectedparticular !== ""){
            _status = "updateStatus" + ":" + this.state.selectedparticular + ","
        }

        if(this.state.selectedClientName == "" || this.state.selectedClientName === ""){
            _filterData = "isDeleted" + ":" + "0,"+ _status
        }else if(this.state.selectedparticular == "" || this.state.selectedparticular === ""){
            _filterData = "isDeleted" + ":" + "0,"+_client 
        }else if(this.state.selectedClientName == "" || this.state.selectedClientName === "" && this.state.selectedparticular == "" || this.state.selectedparticular === ""){
            _filterData = "isDeleted" + ":" + "0,"
        }
        //_filterData = "isDeleted" + ":" + "0,"+_client + _status
        return _filterData
    }

    
    handleToDoleCompliance=(row)=>{
        console.log(row.id)

        let rowtmp = row.id

        sessionStorage.setItem("updateCompliance", JSON.stringify(row))
        let checkTmp = "1"
        sessionStorage.setItem("checkUpdate", JSON.stringify(checkTmp))
        
    }

    handleChangeSearch = () => {
       

        let _fdata = this.buildFilterData()
        let _filterData = _fdata.split(",")
        let _objfd = {}

        let jsonData = {};
        for (let i = 0; i < _filterData.length - 1; i++) { 
            let columnName = _filterData[i].split(":")[0]
            _objfd[columnName] = _filterData[i].split(":")[1];
        }
        console.log("filter_data")
        console.log(_objfd)

        this.handleSearch(_objfd);
    }

    buildFilterData(){
        let finalfilterData = ""
        let arry1 = []
        let NTEDate             = moment(this.state.dateApplied).format('MM/DD/YYYY');
        let NTEDate2            = ""
        let selectedsenaStaus   = ""
        let _selectedretailGroup = ""
        let selectedClientId    = ""
        let _counsel = ""

        
       
        if(this.state.selectedClientId !== ""){
            selectedClientId = "client" + ":" + this.state.selectedClientName+ ","/* + " " */
        }
        if(this.state.selectedparticular !== ""){
            selectedsenaStaus = "status" + ":" + this.state.selectedparticular+ ","/* + " " */
        }
        // if(this.state.selectedRetailGroup !== ""){
        //     _selectedretailGroup = "retailGroup" + ":" + this.state.selectedRetailGroup+ ","/* + " " */
        // }
        // if(this.state.counsel !== ""){
        //     _counsel = "counsel" + ":" + this.state.counsel+ ","/* + " " */
        // }
        let isDeletedTMP = "isDeleted" +":"+"0"

        finalfilterData =   selectedsenaStaus + selectedClientId + isDeletedTMP
        return finalfilterData
    
    }

    handleSearch =(_obj)=>{
        let clear = []
        this.state.employeeDetailList = clear
        this.setState({ isloading:true })

        let filter_data ={}

        const getParams ={
            "_collection" : "DoleCompliance",
            "filter_data" : _obj
        }

        console.log("axios start")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data
            console.log("res.data handleSearch")
            console.log(res.data)
            if(data.DoleCompliance.length > 0){ 
                
                var obj = (data);
                var res = [];
                
                data.DoleCompliance.map(function(itm,i){
                    let strJson = "{" + itm.substring(46, itm.length)
                    let json = JSON.parse(strJson.replace(/'/g,'"'));
                    res.push(json)
                })

                console.log("res.map handleSearch")
                console.log(res)

                this.setState({
                    employeeDetailList: res,
                    isloading:false
                })
                
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    } 




    render() {
        const { ExportCSVButton } = CSVExport;

        const statusColumn = [
            
            {
                dataField   : 'status',
                text:'Status',
                headerStyle : () => {
                    return { width  : "865px" };
                }
            },
            {
                dataField: 'count',
                text: 'Count',
                headerStyle : () => {
                    return { width  : "870px" };
                }
            },
            /* {
                dataField   : 'persentage',
                text        : '%',
                headerStyle : () => {
                    return { width  : "33.3333333333%" };
                }
            }, */
            /*
            {
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={this.showTrainingRegisterModal}
                        >View Details</Button>
                    );
                },
            }, */
        ]

        const selectStatusRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.statusTableList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        
        const employeeDetailsColumn = [
            {
                dataField   : 'caseNo',
                text:'CASE NO.',
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'refNo',
                text: 'REFERENCE NO.',
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'client',
                text        : 'INVOLVED PRINCIPAL / CLIENT ',
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'location',
                text        : 'OUTLET',
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'MembersInvolved',
                text        : 'NO. OF MEMBERS INVOLVED (NOTICE OF RESULT)' ,
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'dateOfAssessment',
                text        : 'DATE OF ASSESSMENT (NOTICE OF RESULT)',
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'inspector',
                text        : 'INSPECTOR (NOTICE OF RESULT)',
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'findings',
                text        : 'FINDINGS (NOTICE OF RESULT)',
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'dateOfConference',
                text        : 'DATE OF CONFERENCE (NOTICE OF RESULT)',
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'GLSNOI',
                text        : 'SUBMITTED DOCUMENTS GLS (NOTICE OF RESULT)',
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'OSHNOI',
                text        : 'SUBMITTED DOCUMENTS OSH (NOTICE OF RESULT)',
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'dateReceived',
                text        : 'DATE RECEIVED (MANDATORY CONF.)',
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'hearingOfficer',
                text        : 'HEARING OFFICER (MANDATORY CONF.)',
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },            
            {
                dataField   : 'mandatoryFindings',
                text        : 'FINDINGS (MANDATORY CONF.)',
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'mandatoryDateOfConference',
                text        : 'DATE OF CONFERENCE (MANDATORY CONF.)',
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'time',
                text        : 'Time OF CONFERENCE (MANDATORY CONF.)',
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'minutesOfMeeting',
                text        : ' MINUTES OF THE MEETING (MANDATORY CONF.)',
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'GLSManCon',
                text        : 'SUBMITTED DOCUMENTS GLS (MANDATORY CONF.)',
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'OSHManCon',
                text        : 'SUBMITTED DOCUMENTS OSH (MANDATORY CONF.)',
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'decision',
                text        : 'DECISION',
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'remarks',
                text        : 'REMARKS',
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'updateStatus',
                text        : 'STATUS',
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'counsel',
                text        : 'COUNSEL / COOP. REP.',
                headerStyle : () => {
                    return { width  : "50%",  whiteSpace: 'nowrap' };
                }
            },

            {
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                },

                formatter   :   (cell, row, isSelect) => {
                    if (cell)
                    return (
                        
                        <ButtonToolbar className="mt-5">                                               
                            <NavLink to="/DoleCompliance" >
                                <Button variant="Link" onClick={(e)=> this.handleToDoleCompliance(row)} href="/DoleCompliance" style={{color: "#1c6dfe"}}>Edit</Button>&nbsp;&nbsp;
                            </NavLink>
                        </ButtonToolbar>  
                    );
                },
            },
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.employeeDetailsTableList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>DOLE COMPLIANCE MONITORING</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Card.Header>SUMMARY</Card.Header>                                 
                                    
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                    </Form.Group>

                                    {/* <div className="mt-1">
                                        <BootstrapTable                                       
                                            keyField = "id"
                                            data = { this.state.statusTableList }
                                            columns = { statusColumn }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }
                                            selectRow = { selectStatusRow }

                                        />
                                    </div>  */}
                                    <ToolkitProvider
                                        striped
                                        hover
                                        condensed
                                        rowClasses="noser-table-row-class"
                                        rowEvents={ rowEvents }
                                        selectRow = { selectRow }
                                        keyField="id"   
                                        data={ this.state.statusTableList }
                                        columns={ statusColumn }
                                        exportCSV={ {
                                            fileName: "Dole Compliance Monitoring Summary.csv",
                                        } }
                                    >
                                        {
                                            props => (
                                                <div className="mt-1" >
                                                    <hr />
                                                    <BootstrapTable { ...props.baseProps } />
                                                    <ButtonToolbar>
                                                        <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export Summary</ExportCSVButton>
                                                    </ButtonToolbar>
                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>


                                    <Card.Header className="mt-4">REPORT FILTERING</Card.Header>

                                      <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Client
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeClientList}
                                            options={this.state.getClientList}
                                            placeholder="Select Client"
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Status
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='id'
                                            id="basic-example"
                                            onChange={this.onChangeparticular}
                                            options={this.state.particularList}
                                            //placeholder="select penalty type"
                                        />
                                    </Col>
                                </Form.Group>

                                <ButtonToolbar>
                                    <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleChangeSearch}>
                                        Search
                                    </Button> 
                                    <Button variant="primary" style={{minWidth:'60px', marginLeft:"3px"}} onClick={this.getAllDoleCompliance}>
                                        refresh
                                    </Button> 
                                </ButtonToolbar>

                                {/* <ButtonToolbar>
                                    <Button className="btn btn-info ml-auto" variant="primary" style={{minWidth:'60px'}} onClick={this.handleChangeSearch}>
                                        Search
                                    </Button>
                                    
                                </ButtonToolbar> */}
                                
                                <ToolkitProvider
                                    keyField="id"   
                                    data={ this.state.employeeDetailsTableList }
                                    columns={ employeeDetailsColumn }
                                    exportCSV={ {
                                        fileName: "Dole Compliance Monitoring.csv",
                                    } }
                                >
                                    {
                                        props => (
                                            <div>
                                                <hr />
                                                <BootstrapTable { ...props.baseProps } />
                                                <ButtonToolbar>
                                                    <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export Report</ExportCSVButton>                                                       
                                                    {/* <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleUpdateClick}>
                                                        Save
                                                    </Button> */}&nbsp;&nbsp;
                                                    <NavLink to="/home">
                                                        <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                                            Close
                                                        </Button>
                                                    </NavLink>
                                                </ButtonToolbar>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                    {/* <TrainingRegisterModal 
                        show={this.state.modalTrainingRegisterShow}
                        onHide={this.handleModalClose}
                    /> */}
            </div> 
        )
    }

}

export  default DoleComplianceMonitoring 
