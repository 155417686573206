import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

class CreatePayrollAdjustment extends Component {
    constructor(props) {
        super(props)
        this.module = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            
            clientId    :   '',
            payperiodId :   '',
            employeeId  :   '',
            typeId      :   '',
            referenceId :   '',
            noofDays    :   '0',
            amount      :   '',
            remarks     :   '',

            clientDDL       :   [],
            periodDDL       :   [],
            periodList      :   [],
            employeeDDL     :   [],
            employeeList    :   [],
            inclusiontypeDDL:   [],
            inclusionDDL    :   [],

            clientId        :   "",
            periodId        :   "",
            employeeId      :   "",
            inclusiontypeId :   "",
            inclusionId     :   "",
            noofdays        :   "",
            amount          :   "",
            remarks         :   ""
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            
            clientId    :   '',
            payperiodId :   '',
            employeeId  :   '',
            typeId      :   '',
            referenceId :   '',
            noofDays    :   '',
            amount      :   '',
            remarks     :   '',

            clientDDL       :   [],
            periodDDL       :   [],
            periodList      :   [],
            employeeDDL     :   [],
            employeeList    :   [],
            inclusiontypeDDL:   [],
            inclusionDDL    :   [],

            clientId        :   "",
            periodId        :   "",
            employeeId      :   "",
            inclusiontypeId :   "",
            inclusionId     :   "",
            noofdays        :   "",
            amount          :   "",
            remarks          :   ""
        })
        this.GetClients()
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" || 
                         this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            let arr = []
            
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL:arr,isloading:false})
            }
            else{
                this.setState({clientDDL:data.clients,isloading:false})
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetPayrollPeriods = async() => {
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   "",
            "IsProcessed"   :   "1"
         };
         let api = ""
        if(this.state.isSpecial==="0"){
            api = "Payroll/ProcessPayroll"
        }
        else{
            api = "Payroll/ProcessSpecialClientPayroll"
        }
        await axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollPeriods",  params
             )
             .then(res => {
                const data = res.data;
                console.log(data.payrollPeriods)
                this.setState({periodDDL:data.payrollPeriods})
            })
        this.GetEmployees()
    }
    GetEmployees = async() =>{
        
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId": this.state.userinfo.roleId==="1" || 
                          this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter", params)
        .then(res => {
            const data = res.data;
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        console.log("brr")
                        console.log(brr)
                        arr = data.profiles.filter(f => brr.some(item => item.referenceId === f.clientId))
                    }
                }
                this.setState({employeeDDL:arr.filter(x=>x.status==="ACTIVE"),isloading:false})
            }
            else{
                this.setState({employeeDDL:data.profiles.filter(x=>x.status==="ACTIVE"),isloading:false})
            }
            
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetInclusionTypes()
    }
    GetInclusionTypes=async()=>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":"",
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetInclusionTypes", params)
        .then(res => {
            const data = res.data;
            this.setState({inclusiontypeDDL : data.inclusionTypes}) 
        })
        .catch(error=>{
            this.setState(
            { 
                isLoading:false,
                alertType:"Error! ", 
                isShow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })  
        })
        this.GetInclusions()
    }
    GetInclusions=async()=>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "TypeId":this.state.inclusiontypeId,
            "Name":"",
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetInclusions", params)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({inclusionDDL:data.inclusions,inclusionList:data.inclusions,isloading:false}) 
        })
        .catch(error=>{
            this.setState(
            { 
                isLoading:false,
                alertType:"Error! ", 
                isShow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })  
        })
        
    }
    handleChangedClient = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.clientId= ''
            this.state.client=''
            this.state.employeeDDL=[]
            return
        }
        this.state.clientId=e[0].id
        this.GetPayrollPeriods()
    }
    handleChangedPeriod = (e) =>{
        console.log(e[0].periodId)
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.periodId=''
            return
        }
        this.state.periodId=e[0].periodId
    }
    handleChangedEmployee = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.employeeId=''
            return
        }
        this.state.employeeId=e[0].id
    }
    handleChangedInclusionType = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.inclusiontypeId=''
            this.setState({inclusionDDL:this.state.inclusionList})
            return
        }
        this.state.inclusiontypeId=e[0].id
        this.setState({inclusionDDL:this.state.inclusionList.filter(x=>x.typeId===e[0].id)})
    }
    handleChangedInclusion = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.inclusionId=''
            return
        }
        this.state.inclusionId=e[0].id
    }
    handleSubmit = (e) =>{
        this.setState({isloading:true})
        if(this.state.name==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter role name.",
                fade        :   true
            })
            return 
        }

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "PayPeriodId":this.state.periodId,
            "EmployeeId":this.state.employeeId,
            "AdjustmentTypeId":"1",
            "ReferenceId":this.state.inclusionId,
            "NoofDays":this.state.noofDays,
            "Amount":this.state.amount,
            "Remarks":this.state.remarks,
        }
        console.log("params")
        console.log(params)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/AddPayrollAdjustment", params)
        .then(res => {
            const data = res.data;
            console.log("data")
            console.log(data)
            this.setState({
                isloading   :   false,
                alerttype   :   data.status==="1" ? "Success!" : "Error!",
                isshow      :   true,
                color       :   data.status==="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true
            });
        })
        .catch(error=>{
            this.setState(
            { 
                isLoading:false,
                alertType:"Error! ", 
                isShow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })  
        })
    }
    handleChanged = (e) =>{
        if(e.target.name==="amount"){
            if(e.target.value.split(".").length>2) return
            let dec = e.target.value.split(".")[1]
            if(typeof dec !='undefined')
                if(dec.length>2) return

        }
        this.setState({[e.target.name]:e.target.value})
    }
    handleModalClose = () => {
        this.props.onHide()            
    }
    handleChange = (e) =>{
        this.setState({[e.target.name]: e.target.value.toUpperCase()})
    }
    IsNumeric(evt)
    {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
        
    }
    IsDecimal(evt)
    {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }
    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        CREATE INCENTIVE ADJUSTMENT
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Group as={Row} controlId="formHorizontalEmail">
                            <Col sm={12}>
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangedClient}
                                    options={this.state.clientDDL}
                                    placeholder="Select CLient"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formHorizontalEmail">
                            <Col sm={12}>
                                <Typeahead
                                    labelKey='payPeriod'
                                    id="basic-example"
                                    onChange={this.handleChangedPeriod}
                                    options={this.state.periodDDL}
                                    placeholder="Select Period"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formHorizontalEmail">
                            <Col sm={12}>
                                <Typeahead
                                    labelKey='employeeName'
                                    id="basic-example"
                                    onChange={this.handleChangedEmployee}
                                    options={this.state.employeeDDL}
                                    placeholder="Select EMPLOYEE"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formHorizontalEmail">
                            <Col sm={12}>
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangedInclusionType}
                                    options={this.state.inclusiontypeDDL}
                                    placeholder="Select INCLUSION TYPE"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formHorizontalEmail">
                            <Col sm={12}>
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangedInclusion}
                                    options={this.state.inclusionDDL}
                                    placeholder="Select INCLUSION"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formHorizontalEmail">
                            <Col sm={6}>
                                <Form.Control 
                                    id="noofdays"
                                    name="noofdays"
                                    type="text" 
                                    value={this.state.noofdays}
                                    placeholder="Enter No Of Day(s)"
                                    autoComplete="off"
                                    onChange={this.handleChanged}
                                    onKeyPress={this.IsNumeric.bind(this)}
                                    maxLength="2"
                                />
                            </Col>
                            <Col sm={6}>
                            <Form.Control 
                                    id="amount"
                                    name="amount"
                                    type="text" 
                                    value={this.state.amount}
                                    placeholder="Enter Amount"
                                    autoComplete="off"
                                    onChange={this.handleChanged}
                                    onKeyPress={this.IsDecimal.bind(this)}
                                    maxLength="13"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formHorizontalEmail">
                            <Col sm={12}>
                                <Form.Control 
                                    as="textarea"
                                    id="remarks"
                                    name="remarks"
                                    value={this.state.remarks}
                                    onChange={this.handleChanged} 
                                    autoComplete="off"
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleSubmit }>Submit</Button>
                        <Button variant="danger"  className="ml-auto noser-button-mr15" onClick={this.handleModalClose}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default CreatePayrollAdjustment