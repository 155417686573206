import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker,
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown, FormControl} from 'react-bootstrap';


import { Input } from 'reactstrap';

const Tonow = moment().hour(0).minute(0);
const showSecond = false;
const str = showSecond ? 'HH:mm:ss' : 'HH:mm';
 
class DoleCompliance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            
            batchNoAutocomplete                 :   [],
            
            
            modalCoCReferenceShow               :   false,

            category                :"",

            isLoading               :"",

           
            selectedEmployeeId      :[],
            selectedEmployeeNo      :[],
            selectedEmployeeBranch  :[],
            selectedPosition        :[],

            getClientList           :[],
            getEmployeeList         :[],
            getEmployeeNoList       :[],
       
            values                  :[],
            
            dateTo                  :"",
            dateFrom                :"",
            year                    :"",

            selectedClientName      :"",
            selectedEmployeeName    :[],
            selectedEmployeeNo      :[],

            outlet                      :"",
            caseNo                      :"",
            MembersInvolved             :"",
            refNo                       :"",
            dateOfAssessment            :"",
            inspector                   :"",
            findings                    :"",
            dateOfConference            :"",
            submittedDoc                :"",
            docDateNOI                  :"",            
            dateReceived                :"",
            hearingOfficer              :"",
            mandatoryFindings           :"",
            mandatoryDateOfConference   :"",
            minutesOfMeeting            :"",
            mandatorySubmittedDoc       :"",
            mandatoryDocDate            :"",         
            decision                    :"",
            counsel                     :"",
            latestUpdate                :"",
            statusDate                  :"",
            remarks                     :"",
            updateStatus                :"",
            documentAttached            :"",
            updateId                    :"",
            saveStatus                  :"",
            disableSave:false,
            particularList: [
                {
                    "id" : "For Resolution",
                    "name" : "For Resolution"
                },
                {
                    "id" : "Pending",
                    "name" : "Pending"
                },
                {
                    "id" : "For Appeal",
                    "name" : "For Appeal"
                },
                {
                    "id" : "Closed",
                    "name" : "Closed"
                }
                
            ],
 	        selectedparticular      :   '',
            selectedparticularId    :   '',
            attachedFindingsNOI:"",
            attachedFindingsNOIBase64:"",
            GLSNOI          :"",
            OSHNOI          :"",
            docDateGLSNOI   :"",
            docDateOSHNOI   :"",
            GLSManCon       :"",
            OSHManCon       :"",
            docDateGLSManCon:"",
            docDateOSHManCon:"",
            timeOfIncident  :moment(),

        }
        /* this.handleSubmit = this.handleSubmit.bind(this); */
        this.onChangeTo=this.onChangeTo.bind(this);
    }

    componentDidMount(){

        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        this.setState({isLoading:true,loadingText:"Loading client list..."})

        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();;

        let checkUpdate =[]
        checkUpdate = sessionStorage.getItem("updateCompliance")
        if(checkUpdate !== ""){
            console.log("getUpdate")
            this.updateCompliance()
            //let dataTmp = sessionStorage.getItem("updateCompliance")
            this.setState({
                saveStatus:"1"
            })

        }else{
            this.setState({
                saveStatus:"0"
            })
        }

    }

    updateCompliance=(e)=>{

        console.log("updateCompliance start")
        let dataFilterTmp=[]
        dataFilterTmp = JSON.parse(sessionStorage.getItem("updateCompliance"))
        
        console.log(dataFilterTmp)
        console.log(dataFilterTmp.dateOfAssessment)

        let dateOfAssessmenttmp = new Date()
        let dateOfConferencetmp = new Date()
        let docDateNOItmp = new Date()
        let dateReceivedtmp = new Date()
        let mandatoryDateOfConferencetmp = new Date()
        let mandatoryDocDatetmp = new Date()
        let statusDatetmp = new Date()


        if(dataFilterTmp.dateOfAssessment != "" || dataFilterTmp.dateOfAssessment !== ""){
            dateOfAssessmenttmp = new Date(dataFilterTmp.dateOfAssessment)
        }
        if(dataFilterTmp.dateOfConference != "" || dataFilterTmp.dateOfConference !== ""){
            dateOfConferencetmp = new Date(dataFilterTmp.dateOfConference)
        }
        if(dataFilterTmp.docDateNOI != "" || dataFilterTmp.docDateNOI !== ""){
            docDateNOItmp = new Date(dataFilterTmp.docDateNOI)
        }
        if(dataFilterTmp.dateReceived != "" || dataFilterTmp.dateReceived !== ""){
            dateReceivedtmp = new Date(dataFilterTmp.dateReceived)
        }
        if(dataFilterTmp.mandatoryDateOfConference != "" || dataFilterTmp.mandatoryDateOfConference !== ""){
            mandatoryDateOfConferencetmp = new Date(dataFilterTmp.mandatoryDateOfConference)
        }
        if(dataFilterTmp.mandatoryDocDate != "" || dataFilterTmp.mandatoryDocDate !== ""){
            mandatoryDocDatetmp = new Date(dataFilterTmp.mandatoryDocDate)
        }
        if(dataFilterTmp.statusDate != "" || dataFilterTmp.statusDate !== ""){
            statusDatetmp = new Date(dataFilterTmp.statusDate)
        }

           
        this.setState({
            updateId:dataFilterTmp.id,
            selectedClientName:dataFilterTmp.client,
            outlet:dataFilterTmp.location,
            caseNo:dataFilterTmp.caseNo,
            MembersInvolved:dataFilterTmp.MembersInvolved,
            refNo:dataFilterTmp.refNo,
            dateOfAssessment:dateOfAssessmenttmp,
            inspector:dataFilterTmp.inspector,
            findings:dataFilterTmp.findings,
            dateOfConference:dateOfConferencetmp,
            submittedDoc:dataFilterTmp.submittedDoc,
            docDateNOI:docDateNOItmp,
            dateReceived:dateReceivedtmp,
            hearingOfficer:dataFilterTmp.hearingOfficer,
            mandatoryFindings:dataFilterTmp.mandatoryFindings,
            mandatoryDateOfConference:mandatoryDateOfConferencetmp,
            minutesOfMeeting:dataFilterTmp.minutesOfMeeting,
            mandatorySubmittedDoc:dataFilterTmp.mandatorySubmittedDoc,
            mandatoryDocDate:mandatoryDocDatetmp,
            decision:dataFilterTmp.decision,
            counsel:dataFilterTmp.counsel,
            latestUpdate:dataFilterTmp.latestUpdate,
            statusDate:statusDatetmp,
            remarks:dataFilterTmp.remarks,
            selectedparticular:dataFilterTmp.updateStatus,
            documentAttached:dataFilterTmp.documentAttached,
            GLSNOI          :dataFilterTmp.GLSNOI,
            OSHNOI          :dataFilterTmp.OSHNOI,
            GLSManCon       :dataFilterTmp.GLSManCon,
            OSHManCon       :dataFilterTmp.OSHManCon,

        })              
            
    
    }

    onChangeTo(timeOfIncident) {
        
        this.state.timeOfIncident = timeOfIncident && timeOfIncident.format(str)
        console.log("format");
        console.log( this.state.timeOfIncident);
     }

    handleChangeDateTo = date => {
        ////console.log(date)
        this.setState({
            dateTo: date,
            isshow:false,
        })
        console.log(this.state.dateTo)
    }

    handleChangeDateFrom = date => {
        ////console.log(date)
        this.setState({
            dateFrom: date,
            isshow:false,
        })
        console.log(this.state.dateFrom)
    }

    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }
    
    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        console.log("Client selectedClientId " + this.state.selectedClientId );
        console.log("Client selectedClientName " + this.state.selectedClientName );
        this.setState({isloading:false,isshow:false,color:"",message:"",fade:true})
        
        this.getEmployees();
    }

    getEmployees(){

        this.setState({isloading:true})

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + /* "Employee/GetEmployees" */"Employee/GetEmployeeProfileFilter", getParams)
        .then(res => {
            console.log("Employee List start ");
            console.log(res.data);
            console.log("Employee List end");
            const data = res.data

            if(data.status=="1"){

                this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
            
            }else{
                
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 

            }
               
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
        
    }

    onChangeEmployeesList = (e) => {       

        if(e.length==0)
        {
            this.state.selectedEmployeeName=""
            this.state.selectedEmployeeNo=""
            this.state.selectedEmployeeBranch=""
            this.state.selectedPosition=""
            return
        }
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedEmployeeName = e[0].employeeName
        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeNo = e[0].employeeNo
        this.state.selectedEmployeeBranch = e[0].locationName
        this.state.selectedPosition = e[0].position
       
        console.log("Work selectedEmployeeName   " + this.state.selectedEmployeeNo );
        console.log("Employee List start ");
        console.log(this.state.selectedEmployeeBranch);
        console.log("Employee List end");

      /*   if(this.state.selectedPayrollpayPeriod == ""){
            this.state.selectedPayrollPeriodsId = e[0].payPeriodId
            this.state.selectedPayrollpayPeriod = e[0].payPeriod
        } */

        //this.employeeTransactionsList();


        this.setState({
            position:this.state.selectedPosition,
            location:this.state.selectedEmployeeBranch

        })
        this.setState({isloading:false})
    }
    onChangeEmployeesNo(e){
        this.setState({selectedEmployeeNo : e.target.value})
        //console.log(e.target.value)
    }

    handleClose=(e)=>{
        sessionStorage.setItem("updateCompliance", "")
    }
       
    // save dole compliance
    handleSaveClick=()=>{

        let createddate                 = moment(this.state.createddate).format('MM/DD/YYYY');
        let _dateOfAssessment           = moment(this.state.dateOfAssessment).format('MM/DD/YYYY');
        let _dateOfConference           = moment(this.state.dateOfConference).format('MM/DD/YYYY');
        let _docDateNOI                 = moment(this.state.docDateNOI).format('MM/DD/YYYY');
        let _dateReceived               = moment(this.state.dateReceived).format('MM/DD/YYYY');
        let _mandatoryDateOfConference  = moment(this.state.mandatoryDateOfConference).format('MM/DD/YYYY');
        let _mandatoryDocDate           = moment(this.state.mandatoryDocDate).format('MM/DD/YYYY');
        let _statusDate                 = moment(this.state.statusDate).format('MM/DD/YYYY');

        if(createddate == "Invalid date"){
            
            createddate = ""

        }
        if(_dateOfAssessment == "Invalid date"){
            
            _dateOfAssessment = ""

        }
        if(_dateOfConference == "Invalid date"){
            
            _dateOfConference = ""

        }
        if(_docDateNOI == "Invalid date"){
            
            _docDateNOI = ""

        }
        if(_dateReceived == "Invalid date"){
            
            _dateReceived = ""

        }
        if(_mandatoryDateOfConference == "Invalid date"){
            
            _mandatoryDateOfConference = ""

        }
        if(_mandatoryDocDate == "Invalid date"){
            
            _mandatoryDocDate = ""

        }
        if(_statusDate == "Invalid date"){
            
            _statusDate = ""

        }


        this.setState({ isloading   :   true, })

        if(this.state.saveStatus == "0" || this.state.saveStatus === "0"){

            let data = {
                "client"                        :   this.state.selectedClientName,
                "location"                      :   this.state.outlet,
                "caseNo"                        :   this.state.caseNo,
                "MembersInvolved"               :   this.state.MembersInvolved,
                "refNo"                         :   this.state.refNo,
                "dateOfAssessment"              :   _dateOfAssessment,
                "inspector"                     :   this.state.inspector,
                "findings"                      :   this.state.findings,
                "dateOfConference"              :   _dateOfConference,
                "submittedDoc"                  :   this.state.submittedDoc,
                "docDateNOI"                    :   _docDateNOI,
                "dateReceived"                  :   _dateReceived,
                "hearingOfficer"                :   this.state.hearingOfficer,
                "mandatoryFindings"             :   this.state.mandatoryFindings,
                "mandatoryDateOfConference"     :   _mandatoryDateOfConference,
                "minutesOfMeeting"              :   this.state.minutesOfMeeting,
                "mandatorySubmittedDoc"         :   this.state.mandatorySubmittedDoc,
                "mandatoryDocDate"              :   _mandatoryDocDate,
                "decision"                      :   this.state.decision,
                "counsel"                       :   this.state.counsel,
                "latestUpdate"                  :   this.state.latestUpdate,
                "statusDate"                    :   _statusDate,
                "remarks"                       :   this.state.remarks,
                "updateStatus"                  :   this.state.selectedparticular,
                "GLSNOI"                        :   this.state.GLSNOI,
                "OSHNOI"                        :   this.state.OSHNOI,
                //"docDateGLSNOI"                 :   this.state.docDateGLSNOI,
                //"docDateOSHNOI"                 :   this.state.docDateOSHNOI,
                "GLSManCon"                     :   this.state.GLSManCon,
                "OSHManCon"                     :   this.state.OSHManCon,
                //"docDateGLSManCon"              :   this.state.docDateGLSManCon,
                //"docDateOSHManCon"              :   this.state.docDateOSHManCon,
                "time"                          :   this.state.timeOfIncident,
                //"documentAttached"              :   this.state.documentAttached,
                "isDraft"                       :   "0",
                "isDeleted"                     :   "0",
                "createdby"                     :   "user007",
                "createddate"                   :   createddate,
                "modifiedby"                    :   'user007',
                "modifieddate"                  :   '0',
                "isModified"                    :   '0',

            }
            console.log(data) 

            const addParams = {
                "_collection" : "DoleCompliance",
                "doc_data"    : data
            }

            console.log("addParams") 
            console.log(addParams) 
            console.log("addParams") 

            axios
            .post(AppConfiguration.Setting().noserMongoDB + "action/add", addParams)
            .then(res =>{
                if(this.state.attachedFindingsNOIBase64 !== ""){
                    this.saveDocsNOI()
                }
                if(this.state.attachedFindingsManConBase64 !== ""){
                    this.saveDocsManCon()
                }
                
                this.setState({
                    selectedClientName:"",
                    outlet:"",
                    caseNo:"",
                    MembersInvolved:"",
                    refNo:"",
                    dateOfAssessment:"",
                    inspector:"",
                    findings:"",
                    dateOfConference:"",
                    submittedDoc:"",
                    docDateNOI:"",
                    dateReceived:"",
                    hearingOfficer:"",
                    mandatoryFindings:"",
                    mandatoryDateOfConference:"",
                    minutesOfMeeting:"",
                    mandatorySubmittedDoc:"",
                    mandatoryDocDate:"",
                    decision:"",
                    counsel:"",
                    latestUpdate:"",
                    statusDate:"",
                    remarks:"",
                    updateStatus:"",
                    documentAttached:"",
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   "Data had successfully Save" ,
                    fade            :   true,
                    disableSave:true,
                
                })
                sessionStorage.setItem("updateCompliance", "")
                
            
            })
            .catch(error =>{
                console.log("error: " + error)
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })

        }else{

            let data = {
                "client"                        :   this.state.selectedClientName,
                "location"                      :   this.state.outlet,
                "caseNo"                        :   this.state.caseNo,
                "MembersInvolved"               :   this.state.MembersInvolved,
                "refNo"                         :   this.state.refNo,
                "dateOfAssessment"              :   _dateOfAssessment,
                "inspector"                     :   this.state.inspector,
                "findings"                      :   this.state.findings,
                "dateOfConference"              :   _dateOfConference,
                "submittedDoc"                  :   this.state.submittedDoc,
                "docDateNOI"                    :   _docDateNOI,
                "dateReceived"                  :   _dateReceived,
                "hearingOfficer"                :   this.state.hearingOfficer,
                "mandatoryFindings"             :   this.state.mandatoryFindings,
                "mandatoryDateOfConference"     :   _mandatoryDateOfConference,
                "minutesOfMeeting"              :   this.state.minutesOfMeeting,
                "mandatorySubmittedDoc"         :   this.state.mandatorySubmittedDoc,
                "mandatoryDocDate"              :   _mandatoryDocDate,
                "decision"                      :   this.state.decision,
                "counsel"                       :   this.state.counsel,
                "latestUpdate"                  :   this.state.latestUpdate,
                "statusDate"                    :   _statusDate,
                "remarks"                       :   this.state.remarks,
                "updateStatus"                  :   this.state.selectedparticular,
                "GLSNOI"                        :   this.state.GLSNOI,
                "OSHNOI"                        :   this.state.OSHNOI,
                //"docDateGLSNOI"                 :   this.state.docDateGLSNOI,
                //"docDateOSHNOI"                 :   this.state.docDateOSHNOI,
                "GLSManCon"                     :   this.state.GLSManCon,
                "OSHManCon"                     :   this.state.OSHManCon,
                //"docDateGLSManCon"              :   this.state.docDateGLSManCon,
                //"docDateOSHManCon"              :   this.state.docDateOSHManCon,
                "time"                          :   this.state.timeOfIncident,
                //"documentAttached"              :   this.state.documentAttached,
                "isDraft"                       :   "0",
                "isDeleted"                     :   "0",
                "createdby"                     :   "user007",
                "createddate"                   :   createddate,
                "modifiedby"                    :   'user007',
                "modifieddate"                  :   '0',
                "isModified"                    :   '0',

            }
            console.log(data) 

            const updateParams = {
                "_collection" : "DoleCompliance",
                "doc_data"    : data,
                "_id"          : this.state.updateId,
            }

            console.log("updateParams")

            console.log(updateParams) 

            axios
            .post(AppConfiguration.Setting().noserMongoDB + "action/update_one", updateParams)
            .then(res =>{
                if(this.state.attachedFindingsNOIBase64 !== ""){
                    this.saveDocsNOI()
                }
                if(this.state.attachedFindingsManConBase64 !== ""){
                    this.saveDocsManCon()
                }

                this.setState({
                    selectedClientName:"",
                    outlet:"",
                    caseNo:"",
                    MembersInvolved:"",
                    refNo:"",
                    dateOfAssessment:"",
                    inspector:"",
                    findings:"",
                    dateOfConference:"",
                    submittedDoc:"",
                    docDateNOI:"",
                    dateReceived:"",
                    hearingOfficer:"",
                    mandatoryFindings:"",
                    mandatoryDateOfConference:"",
                    minutesOfMeeting:"",
                    mandatorySubmittedDoc:"",
                    mandatoryDocDate:"",
                    decision:"",
                    counsel:"",
                    latestUpdate:"",
                    statusDate:"",
                    remarks:"",
                    updateStatus:"",
                    documentAttached:"",
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   "Data had successfully Save" ,
                    fade            :   true,
                    disableSave:true,
                
                })
                sessionStorage.setItem("updateCompliance", "")
            
            })
            .catch(error =>{
                console.log("error: " + error)
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })
        }

    }

    saveDocsNOI=()=>{

        if( this.state.attachedFindingsNOIBase64 === "" ){
            let data = {
                "refNoDoleCompliance"           :   this.state.refNo,
                "documentAttached"              :   this.state.attachedFindingsNOIBase64,
                "isDraft"                       :   "0",
                "isDeleted"                     :   "0",
                "createdby"                     :   "user007",
                "createddate"                   :   "",
                "modifiedby"                    :   'user007',
                "modifieddate"                  :   '0',
                "isModified"                    :   '0',

            }
            console.log(data) 
            console.log(data.refNoDoleCompliance) 

            const addParams = {
                "_collection" : "DoleComplianceDocsNOI",
                "doc_data"    : data
            }

            console.log("addParams") 
            console.log(addParams) 
            console.log("addParams") 

            axios
            .post(AppConfiguration.Setting().noserMongoDB + "action/add", addParams)
            .then(res =>{

            })
            .catch(error =>{
                console.log("error: " + error)
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })
        }
       
    }

    saveDocsManCon=()=>{

        let data = {
            "refNoDoleCompliance"           :   this.state.refNo,
            "documentAttached"              :   this.state.attachedFindingsManConBase64,
            "isDraft"                       :   "0",
            "isDeleted"                     :   "0",
            "createdby"                     :   "user007",
            "createddate"                   :   "",
            "modifiedby"                    :   'user007',
            "modifieddate"                  :   '0',
            "isModified"                    :   '0',

        }
        console.log(data) 
        console.log(data.refNoDoleCompliance) 

        const addParams = {
            "_collection" : "DoleComplianceDocsManCon",
            "doc_data"    : data
        }

        console.log("addParams") 
        console.log(addParams) 
        console.log("addParams") 

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/add", addParams)
        .then(res =>{

        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
       
    }

    handleOnChangeoutlet=(e)=>{
      
        this.setState({
            outlet   : e.target.value
        })
        console.log(this.state.outlet)
    } 

    handleOnChangecaseNo=(e)=>{
        this.setState({
            caseNo   : e.target.value
        })
        console.log(this.state.caseNo)
    }

    handleOnChangeMembersInvolved=(e)=>{
        this.setState({
            MembersInvolved   : e.target.value
        })
        console.log(this.state.MembersInvolved)
    }

    handleOnChangerefNo=(e)=>{
        this.setState({
            refNo   : e.target.value
        })
        console.log(this.state.refNo)
    }

    handleOnChangedateOfAssessment =date=>{
        this.setState({
            dateOfAssessment: date,
            isshow:false,
        })
    }

    handleOnChangeinspector=(e)=>{
        this.setState({
            inspector   : e.target.value
        })
        console.log(this.state.inspector)
    }

    handleOnChangefindings=(e)=>{
        this.setState({
            findings   : e.target.value
        })
        console.log(this.state.findings)
    }

    handleOnChangedateOfConference =date=>{
        this.setState({
            dateOfConference: date,
            isshow:false,
        })

    }

    handleOnChangehearingOfficer=(e)=>{
        this.setState({
            hearingOfficer   : e.target.value
        })
        console.log(this.state.hearingOfficer)
    }

    handleOnChangesubmittedDoc=(e)=>{
        this.setState({
            submittedDoc   : e.target.value
        })
        console.log(this.state.submittedDoc)
    }

    handleOnChangedocDateNOI =date=>{

        this.setState({
            docDateNOI: date,
            isshow:false,
        })

    }

    handleOnChangeGLSNOI=(e)=>{
        this.setState({
            GLSNOI   : e.target.value
        })
        console.log(this.state.GLSNOI)
    }

    handleOnChangedocDateGLSNOI =date=>{

        this.setState({
            docDateGLSNOI: date,
            isshow:false,
        })

    }

    handleOnChangeOSHNOI=(e)=>{
        this.setState({
            OSHNOI   : e.target.value
        })
        console.log(this.state.OSHNOI)
    }

    handleOnChangedocDateOSHNOI =date=>{

        this.setState({
            docDateOSHNOI: date,
            isshow:false,
        })

    }

    handleOnChangeGLSManCon=(e)=>{
        this.setState({
            GLSManCon   : e.target.value
        })
        console.log(this.state.GLSManCon)
    }

    handleOnChangedocDateGLSManCon =date=>{

        this.setState({
            docDateGLSManCon: date,
            isshow:false,
        })

    }

    handleOnChangeOSHManCon=(e)=>{
        this.setState({
            OSHManCon   : e.target.value
        })
        console.log(this.state.OSHManCon)
    }

    handleOnChangedocDateOSHManCon =date=>{

        this.setState({
            docDateOSHManCon: date,
            isshow:false,
        })

    }

    handleOnChangedateReceived =date=>{
         this.setState({
            dateReceived: date,
            isshow:false,
        })

    }

    handleOnChangehearingOfficer=(e)=>{
        this.setState({
            hearingOfficer   : e.target.value
        })
        console.log(this.state.hearingOfficer)
    }

    handleOnChangemandatoryFindings=(e)=>{
        this.setState({
            mandatoryFindings   : e.target.value
        })
        console.log(this.state.mandatoryFindings)
    }

    onChangemandatoryDateOfConference =date=>{

        this.setState({
            mandatoryDateOfConference: date,
            isshow:false,
        })

    }

    handleOnChangeminutesOfMeeting=(e)=>{
        this.setState({
            minutesOfMeeting   : e.target.value
        })
        console.log(this.state.minutesOfMeeting)
    }

    handleOnChangemandatorySubmittedDoc=(e)=>{
        this.setState({
            mandatorySubmittedDoc   : e.target.value
        })
        console.log(this.state.mandatorySubmittedDoc)
    }

    onChangemandatoryDocDate =date=>{
        this.setState({
            mandatoryDocDate: date,
            isshow:false,
        })
    }

    handleOnChangedecision=(e)=>{
        this.setState({
            decision   : e.target.value
        })
        console.log(this.state.decision)
    }

    handleOnChangecounsel=(e)=>{
        this.setState({
            counsel   : e.target.value
        })
        console.log(this.state.counsel)
    }

    handleOnChangelatestUpdate=(e)=>{
        this.setState({
            latestUpdate   : e.target.value
        })
        console.log(this.state.latestUpdate)
    }

    handleOnChangestatusDate =date=>{
        this.setState({
            statusDate: date,
            isshow:false,
        })
    }

    handleOnChangeremarks=(e)=>{
        this.setState({
            remarks   : e.target.value
        })
        console.log(this.state.remarks)
    }

    handleOnChangeupdateStatus=(e)=>{
        this.setState({
            updateStatus   : e.target.value
        })
        console.log(this.state.updateStatus)
    }

    handleOnChangeAttachedDoc=(e)=>{
        let fileTmp = e.target.value
        this.setState({
            documentAttached   : fileTmp
        })
        console.log(this.state.documentAttached)
    }

    onChangeparticular = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedparticular     :   '',
                selectedparticularId   :   ''
            })
            return
        }
        this.state.selectedparticular = e[0].name
        this.state.selectedparticularId = e[0].id
       
        this.setState({
            isshow   :   false,
           selectedparticular : e[0].id

        })
       
    }

    fileToBase64=(file, cb)=>{
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () { 
            cb(null , reader.result)
        }
        reader.onerror = function (error) {
            cb(error,null )
        }
    }


    handleChangeattachedFindingsNOI=(e , target)=>{
        this.setState({
            attachedFindingsNOI:e.target.value
        })

        if(e.target.files < 1 || !e.target.validity.valid){
            return
        }

        let base64data = ""
        this.fileToBase64(e.target.files[0], (err , result)=> {
            
            if(result){
                base64data = result
                console.log(result)
                this.state.attachedFindingsNOIBase64 = result
            }
        })
        console.log("base64data")
        console.log(base64data)
        console.log("base64data")
    }
   
    render() {        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>DOLE COMPLIANCE</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Card.Header className="mt-3">PRICIPAL / CLIENT INVOLVED</Card.Header>
                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Client
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClientList}
                                                selected={[this.state.selectedClientName]}
                                                options={this.state.getClientList}
                                                placeholder="Select Client"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Outlet
                                        </Form.Label>
                                        <Col sm="11">
                                            <Input
                                                type="text"
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.handleOnChangeoutlet}
                                                value={this.state.outlet}
                                                autoComplete="off"
                                            />
                                        </Col>                 
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Case No.
                                        </Form.Label>
                                        <Col sm="5">
                                            <Form.Control 
                                                type="text"
                                                name="caseNo" 
                                                value={this.state.caseNo}
                                                onChange={this.handleOnChangecaseNo} 
                                                autoComplete="off"
                                               
                                            />
                                        </Col>
                                        <Col sm="2"></Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            No. Of Members Involved
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName"
                                                value={this.state.MembersInvolved}
                                                onChange={this.handleOnChangeMembersInvolved} 
                                                autoComplete="off"
                                               
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Ref No.
                                        </Form.Label>
                                        <Col sm="4" /* style={{marginLeft : "-1px"}} */>
                                            <Input
                                                labelKey='year'
                                                id="basic-example"
                                                value={this.state.refNo}
                                                className="form-control"
                                                onChange={this.handleOnChangerefNo}
                                                //placeholder="Enter Ground Of Seperation"
                                                autocomplete="off"
                                                
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Card.Header className="mt-3">NOTICE OF INSPECTION</Card.Header>
                                    <div className="mt-3">
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                DATE OF ASSESSMENT
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <DatePicker
                                                    ref='dateOfAssessment'
                                                    selected={this.state.dateOfAssessment}
                                                    onChange={this.handleOnChangedateOfAssessment}
                                                    minDate={this.minDate}
                                                    value={this.props.dateOfAssessment}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    placeholderText="SELECT DATE"
                                                    className="form-control"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                               INSPECTOR
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.inspector}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeinspector}
                                                    //placeholder="Enter Date Filed"
                                                    autocomplete="off"
                                                    
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                FINDINGS
                                            </Form.Label>
                                            <Col sm="6" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.findings}
                                                    className="form-control"
                                                    onChange={this.handleOnChangefindings}
                                                    //placeholder="Enter Date Received"
                                                    autocomplete="off"
                                                    
                                                />
                                            </Col>
                                           
                                                <label  style={{fontWeight : "bold"}}>ADD ATTACHMENT</label>
                                           
                                            <Col sm="3">
                                                <Input type="file" style={{backgroundColor:"#008BFF",width:"150px"}} className="file" placeholder="attached file" variant="primary" onChange={this.handleChangeattachedFindingsNOI} value={this.state.attachedFindingsNOI} accept="application/pdf" /> 
                                            </Col>  
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                DATE OF CONFERENCE
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <DatePicker
                                                    ref='dateOfConference'
                                                    selected={this.state.dateOfConference}
                                                    onChange={this.handleOnChangedateOfConference}
                                                    minDate={this.minDate}
                                                    value={this.props.dateOfConference}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    placeholderText="SELECT DATE"
                                                    className="form-control"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                SUBMITTED DOCUMENTS
                                            </Form.Label>
                                            <Col sm="10" >
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.submittedDoc}
                                                    className="form-control"
                                                    onChange={this.handleOnChangesubmittedDoc}
                                                    //placeholder="Enter Schedule Of Hearing"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                            {/* <Col sm="1" >
                                                <DatePicker
                                                    ref='docDateNOI'
                                                    selected={this.state.docDateNOI}
                                                    onChange={this.handleOnChangedocDateNOI}
                                                    minDate={this.minDate}
                                                    value={this.props.docDateNOI}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    placeholderText="SELECT DATE"
                                                    className="form-control"
                                                />
                                            </Col> */}
                                        </Form.Group>    
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                GLS
                                            </Form.Label>
                                            <Col sm="10" >
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.GLSNOI}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeGLSNOI}
                                                    //placeholder="Enter Schedule Of Hearing"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                            {/* <Col sm="1" >
                                                <DatePicker
                                                    ref='docDateGLSNOI'
                                                    selected={this.state.docDateGLSNOI}
                                                    onChange={this.handleOnChangedocDateGLSNOI}
                                                    minDate={this.minDate}
                                                    value={this.props.docDateGLSNOI}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    placeholderText="SELECT DATE"
                                                    className="form-control"
                                                />
                                            </Col> */}
                                        </Form.Group> 
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                OSH
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.OSHNOI}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeOSHNOI}
                                                    //placeholder="Enter Schedule Of Hearing"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                            {/* <Col sm="1">
                                                <DatePicker
                                                    ref='docDateOSHNOI'
                                                    selected={this.state.docDateOSHNOI}
                                                    onChange={this.handleOnChangedocDateOSHNOI}
                                                    minDate={this.minDate}
                                                    value={this.props.docDateOSHNOI}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    placeholderText="SELECT DATE"
                                                    className="form-control"
                                                />
                                            </Col> */}
                                        </Form.Group>                                    

                                    </div>

                                    <Card.Header className="mt-3">MANDATORY CONFERENCE</Card.Header>

                                    <div className="mt-3">
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                DATE RECEIVED
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <DatePicker
                                                    ref='dateReceived'
                                                    selected={this.state.dateReceived}
                                                    onChange={this.handleOnChangedateReceived}
                                                    minDate={this.minDate}
                                                    value={this.props.dateReceived}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    placeholderText="SELECT DATE"
                                                    className="form-control"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                HEARING OFFICER
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.hearingOfficer}
                                                    className="form-control"
                                                    onChange={this.handleOnChangehearingOfficer}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                FINDINGS
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.mandatoryFindings}
                                                    className="form-control"
                                                    onChange={this.handleOnChangemandatoryFindings}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                DATE OF CONFERENCE
                                            </Form.Label>
                                            <Col sm="1" /* style={{marginLeft : "-1px"}} */>
                                                <DatePicker
                                                    ref='mandatoryDateOfConference'
                                                    selected={this.state.mandatoryDateOfConference}
                                                    onChange={this.onChangemandatoryDateOfConference}
                                                    minDate={this.minDate}
                                                    value={this.props.mandatoryDateOfConference}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    placeholderText="SELECT DATE"
                                                    className="form-control"
                                                />
                                            </Col>
                                            
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Time
                                            </Form.Label>
                                            <Col sm="3">
                                                <TimePicker 
                                                    showSecond={showSecond}
                                                    //value={this.state.timeOfIncident}
                                                    defaultValue={this.state.timeOfIncident}
                                                    className="xxx"
                                                    onChange={this.onChangeTo}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                MINUTES OF THE MEETING
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.minutesOfMeeting}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeminutesOfMeeting}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                SUBMITTED DOCUMENTS
                                            </Form.Label>
                                            <Col sm="9" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.mandatorySubmittedDoc}
                                                    className="form-control"
                                                    onChange={this.handleOnChangemandatorySubmittedDoc}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                            <Col sm="1" /* style={{marginLeft : "-1px"}} */>
                                                <DatePicker
                                                    ref='mandatoryDocDate'
                                                    selected={this.state.mandatoryDocDate}
                                                    onChange={this.onChangemandatoryDocDate}
                                                    minDate={this.minDate}
                                                    value={this.props.mandatoryDocDate}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    placeholderText="SELECT DATE"
                                                    className="form-control"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                GLS
                                            </Form.Label>
                                            <Col sm="10" >
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.GLSManCon}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeGLSManCon}
                                                    //placeholder="Enter Schedule Of Hearing"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                            {/* <Col sm="1" >
                                                <DatePicker
                                                    ref='docDateGLSManCon'
                                                    selected={this.state.docDateGLSManCon}
                                                    onChange={this.handleOnChangedocDateGLSManCon}
                                                    minDate={this.minDate}
                                                    value={this.props.docDateGLSManCon}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    placeholderText="SELECT DATE"
                                                    className="form-control"
                                                />
                                            </Col> */}
                                        </Form.Group> 
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                OSH
                                            </Form.Label>
                                            <Col sm="10">
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.OSHManCon}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeOSHManCon}
                                                    //placeholder="Enter Schedule Of Hearing"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                            {/* <Col sm="1">
                                                <DatePicker
                                                    ref='docDateOSHManCon'
                                                    selected={this.state.docDateOSHManCon}
                                                    onChange={this.handleOnChangedocDateOSHManCon}
                                                    minDate={this.minDate}
                                                    value={this.props.docDateOSHManCon}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    placeholderText="SELECT DATE"
                                                    className="form-control"
                                                />
                                            </Col> */}
                                        </Form.Group>      
                                        
                                    </div>

                                    <Card.Header className="mt-3">UPDATE AND STATUS</Card.Header>

                                    <div className="mt-3">
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Decision
                                            </Form.Label>
                                            <Col sm="11" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.decision}
                                                    className="form-control"
                                                    onChange={this.handleOnChangedecision}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Counsel
                                            </Form.Label>
                                            <Col sm="11" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.counsel}
                                                    className="form-control"
                                                    onChange={this.handleOnChangecounsel}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Latest Update
                                            </Form.Label>
                                            <Col sm="11" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.latestUpdate}
                                                    className="form-control"
                                                    onChange={this.handleOnChangelatestUpdate}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                DATE
                                            </Form.Label>
                                            <Col sm="2" /* style={{marginLeft : "-1px"}} */>
                                                <DatePicker
                                                    ref='statusDate'
                                                    selected={this.state.statusDate}
                                                    onChange={this.handleOnChangestatusDate}
                                                    minDate={this.minDate}
                                                    value={this.props.statusDate}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    className="form-control"
                                                    placeholderText="SELECT DATE"
                                                /> 
                                            </Col>
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                REMARKS
                                            </Form.Label>
                                            <Col sm="4" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.remarks}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeremarks}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                        {/* <ButtonToolbar sm={2}>
                                            <Col className="mr-auto">
                                                <ButtonToolbar className="mt-1">                                                   
                                                    <Button className="mr-auto" variant="success" onClick={this.handleToExportNTE} href="/ExportNTE" style={{minHeight:"18px",minWidth:'60px', marginLeft:"129px", marginTop:"-1px"}}>ADD</Button>                                                   
                                                </ButtonToolbar>                                          
                                            </Col>                                                                      
                                        </ButtonToolbar> */}
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Status
                                            </Form.Label>
                                            <Col sm="11">
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangeparticular}
                                                    selected={[this.state.selectedparticular]}
                                                    options={this.state.particularList}
                                                    //placeholder="select penalty type"
                                                />
                                            </Col>
                                        </Form.Group>
                                       

                                    </div>

                                    {/* <Card.Header className="mt-3">DOCUMENTS ATTACHED</Card.Header> */}

                                    <div className="mt-3">
                                       {/*  <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Col sm="4">                                                 
                                                <Input type="file" className="file" placeholder="attached file" variant="primary"  onChange={this.handleOnChangeAttachedDoc}/>                                          
                                            </Col>                                           
                                        </Form.Group> */}
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                                         
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formPlaintextEmail" className="mt-4">
                                            
                                            <ButtonToolbar sm={12}>
                                                <Button disabled={this.state.disableSave} variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleSaveClick}>
                                                    Save
                                                </Button>&nbsp;&nbsp;
                                                <NavLink to="/DoleComplianceMonitoring">
                                                    <Button variant="danger" href="/DoleComplianceMonitoring" onClick={this.handleClose} style={{minWidth:'60px'}}>
                                                        Close
                                                    </Button>
                                                </NavLink>
                                            </ButtonToolbar>
                                            
                                        </Form.Group>
                                    </div>

                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                   
            </div> 
        )
    }

}

export  default DoleCompliance
