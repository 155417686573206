import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, ReactDOM,
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Tabs, Tab, Accordion,Redirect
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import Resizer from "react-image-file-resizer";
import paramountLogo from "../../icon/paramountLogo.jpg";
import { Label } from 'reactstrap';
import './ApplicationFormDownload.css'






class ApplicationFormCreate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: new Date(),
            // selected: [],
            userinfo  :   [],
            isloading :   false,
            isshow    :   false,
            alerttype :   "",
            message   :   "",
            color     :   "",
            fade      :   true, 
            
            disableRemoveEducation:true,

            clientDDL       : [],
            clientId        : "",
            clientName      : "",
            positionDDL     : [],
            positionId      : "",
            positionName    : "",
            dateApplied     : "",
            titleDDL: [
                {name: 'Select Name Title', value: "",},
                {name: 'MR.',               value: 'Mr.',},
                {name: 'MS.',               value: 'Ms.',},
                {name: 'MRS.',              value: 'Mrs.',},
            ],
            suffixDDL: [
                {name: 'Select Suffix Name', value: "",},
                {name: 'SR',               value: 'SR',},
                {name: 'JR',               value: 'JR',},
                {name: 'I',              value: 'I',},
                {name: 'II',              value: 'II',},
                {name: 'III',              value: 'III',},
                {name: 'IV',              value: 'IV',},
                {name: 'V',              value: 'V',},
                {name: 'VI',              value: 'VI',},
                {name: 'VII',              value: 'VII',},
                {name: 'VIII',              value: 'VIII',},
                {name: 'IX',              value: 'IX',},
                {name: 'X',              value: 'X',},
            ],
            titleValue  : "",
            fName       : "",
            nName       : "",
            mName       : "",
            lName       : "",
            sName       : "",
            typeDDL: [
                {name: 'Select Address Type',   value: "",},
                {name: 'CURRENT ADDRESS',       value: '0',},
                {name: 'REGISTERED ADDRESS',    value: '1',},
                {name: 'PROVINCIAL ADDRESS',    value: "2",},
                {name: 'CORRESPONDENCE ADDRESS',value: '3',},
                {name: 'FOREIGN ADDRESS',       value: '4',},
            ],
            typeId      : "",
            regionDDL   : [],
            regionId    : "",
            regionName  : "",
            provinceDDL : [],
            provinceId  : "",
            provinceName: "",
            cityDDL     : [],
            cityId      : "",
            cityName    : "",
            houseNumber : "",
            street      : "",
            barangay    : "",
            postalCode  : "",
            hpNumber    : "",
            opNumber    : "",
            mobileNumber: "",
            emailAddress: "",
            facebook    : "",
            twitter     : "",
            nationalityDDL: [
                {name: 'Select Citizenship',value: "",},
                {name: 'FILIPINO',          value: '1',},
                {name: 'OTHERS',            value: '2',},
            ],
            nationalityId : "",
            religionDDL: [
            ],
            religionId : "",
            genderDDL: [
                {name: 'Select Gender', value: "",},
                {name: 'MALE',          value: 'MALE',},
                {name: 'FEMALE',        value: 'FEMALE',},
            ],
            genderValue : "",
            civilStatusDDL: [
                {name: 'Select Civil Status', value: "",},
                {name:"SINGLE",         value:"SINGLE"},
                {name:"MARRIED",        value:"MARRIED"},
                {name:"DIVORCED",       value:"DIVORCED"},
                {name:"SEPARATED",      value:"SEPARATED"}
            ],
            civilStatusValue : "",
            ageYear  :   "",//,new Date().getFullYear(),
            ageMonth :   "",//,new Date().getMonth()+1,
            dateOfBirth : "",
            fromStart : "",
            toEnd: "",
            totalAge : "",
            placeOfBirth : "",
            height : "",
            weight : "",
            sSkills : "",
            lSpoken : "",
            ecName : "",
            relationship : "",
            ecNumber : "",
            sss : "",
            tin : "",
            phic : "",
            pagibig : "",


            levelDDL  :    [
                {"name": 'Select Level', "value": "",},
                {"name":"COLLEGE","value":"0"},
                {"name":"HIGH SCHOOL","value":"1"},
                {"name":"SENIOR HIGH SCHOOL","value":"5"},
                {"name":"JUNIOR HIGH SCHOOL","value":"4"},
                {"name":"ELEMENTARY","value":"2"},
                {"name":"OTHERS","value":"3"},
            ],
            levelDDLtwo  :    [
                {"id":"1","name":"COLLEGE","levelId":"0", "isModified":"0","isDeleted":"0"},
                {"id":"2","name":"HIGH SCHOOL","levelId":"1", "isModified":"0","isDeleted":"0"},
                {"id":"3","name":"SENIOR HIGH SCHOOL","levelId":"5", "isModified":"0","isDeleted":"0"},
                {"id":"4","name":"JUNIOR HIGH SCHOOL","levelId":"4", "isModified":"0","isDeleted":"0"},
                {"id":"5","name":"ELEMENTARY","levelId":"2", "isModified":"0","isDeleted":"0"},
                //{"id":"6","name":"OTHERS","levelId":"3", "isModified":"0","isDeleted":"0"},
            ],
            levelId : "",
            tblLevelArrLst  :   [],
            schoolName:"",
            course:"",
            startYear:"",
            endYear:"",
            honorsAward:"",
            educationGrid : [],

            checkIsFreshGraduate : false,
            isEmploymentDisabled : false,
            erCompanyName : "",
            erCompanyAddress : "",
            erPosition : "",
            erPeriodCover : "",
            erSalary : "",
            erSupervisor : "",
            erCNumber : "",
            erRFLeaving : "",
            erTCIncome : "",
            erWHTax : "",
            erYOCompensation : "",
            erMPAOB : "",
            erDBenefits : "",
            erMDeduction : "",
            erNTSOFOC: "",
            erBasicSalary : "",
            erTMPOB : "",
            erTSPFOC : "",
            erBPFMWE : "",
            erHPFHWE : "",
            erOPFHWE : "",
            erNDPFHWE : "",
            erRemarks : "",
            employmentRecordGrid : [],
            disableRemoveEmploymentRecord : true,

            roleDDL  :    [
                {"name": 'Select Role', "value": "",},
                {"name":"FATHER",       "value":"0"},
                {"name":"MOTHER",       "value":"1"},
                {"name":"SPOUSE",       "value":"2"},
                {"name":"BROTHER",      "value":"3"},
                {"name":"SISTER",       "value":"4"},
                {"name":"CHILD",        "value":"5"},
            ],
            roleDDLtwo  :    [
                
                {"id":"1","name":"FATHER",       "roleId":"0"},
                {"id":"2","name":"MOTHER",       "roleId":"1"},
                {"id":"3","name":"SPOUSE",       "roleId":"2"},
                {"id":"4","name":"BROTHER",      "roleId":"3"},
                {"id":"5","name":"SISTER",       "roleId":"4"},
                {"id":"6","name":"CHILD",        "roleId":"5"},
            ],
            tblRoleArrLst : [],
            roleId : "",
            fbName : "",
            fbAge : "",
            fbOccupation : "",
            fbCOSchool : "",
            fbCNumber : "",
            disableBackground : true,
            familyBackgroundGrid : [],

            tblJobOpeningArrLst : [],
            jobOpeningDDL  :    [
                // {"name": 'Select Job Opening',  "value": "",},
                // {"name":"BESTJOBS",             "value":"1"},
                // {"name":"CLASSIFIED ADS",       "value":"2"},
                // {"name":"FIELD COLLECTORS",     "value":"3"},
                // {"name":"JOBSTREET",            "value":"4"},
                // {"name":"OTHERS",               "value":"5"},
                // {"name":"PESO",                 "value":"6"},
                // {"name":"REFERRAL",             "value":"7"},
            ],
            jobSourceId1 : "",
            jobSourceId : "",
            jobSource : "",
            oReferredBy : "",
            checkORelativeYes : false,
            checkORelativeNo : true,
            disabledRelative : false,
            oRelativeName : "",
            oCNumber : "",
            oRelationship : "",
            oJobTitle : "",
            disableOthers : true,

            checkConvictedYes : false,
            checkConvictedNo : true,
            oCrime : "",
            disabledConvicted : false,

            checkLaborUnionYes : false,
            checkLaborUnionNo : true,
            disabledLaborUnion : true,
            luDetails : "",
            checkChronicDiseaseYes : false,
            checkChronicDiseaseNo : true,
            disabledDisease : true,

            tblDiseaseArrLst : [],
            diseaseDDL  :    [
                {"name": 'Select Chronic Disease',  "value": "",},
                {"name":"ALLERGIES",            "value":"1"},
                {"name":"ASTHMA",               "value":"2"},
                {"name":"DIABETES",             "value":"3"},
                {"name":"HEPA B",               "value":"4"},
                {"name":"HYPERTENSION",         "value":"5"},
                {"name":"N/A",                  "value":"6"},
                {"name":"OTHERS",               "value":"7"}
            ],
            diagnoseDeseaseId : "",
            othersGrid : [],

            referralDDL:[],
            referralLst:[],
            disablereferral: true,
            referralId:"",
            referenceName : "",
            referenceOccupation : "",
            referenceCOSchool : "",
            referenceCNumber : "",
            referenceGrid : [],
            disableReference : true,

            checkDisclaimer : false,
            disablebutton : true,

            arrangementId       :   "",
            arrangement         :   "",
            bloodtypeId         :   "",
            bloodtype           :   "",

            arrangementDDL      :   [],
            bloodtypeDDL        :   [],

            firstNamekeycode    :   "",
            middleNamekeycode   :   "",
            lastNamekeycode     :   "",
            nickNamekeycode     :   "",
            newImage: "",
            newImageShow: false,
            photoLabel : true,
            uploadResume : "",

            //w value af
            clientIdAH : "",
            clientNameAH: "",
            positionIdAH: "",
            positionNameAH:"",
            refNoAF : "",
            dateAppliedAF: "",
            remarksAF : "",
            applicationHistoryGrid: [],
            typeofcharacterreference:[
                {"name": 'Select Role', "value": ""},
                {"name":"Previous Employment", "value" : "0"},
                {"name":"Neighbor", "value" : "1"}
            ],
            tbltypeofcharacterreferenceArrLst : [],
            typeofcharacterreferenceId : "",
            typeofcharacterreferenceName : "",
            wstartDate : "",
            wendDate: "",
            //others
            referealOthersTA: true,
            referealOthersTXT : false,

            fileImage : "",
        }
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
        this.fileUploadChangedHandler = this.fileUploadChangedHandler.bind(this);
    }


    componentDidMount() {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        
        this.GetClient(); 
        this.GetPosition();
        this.GetRegion();
        this.GetLevel();
        this.GetRole();
        this.Gettypeofcharacterreference();
        this.GetDisease();
        this.GetJobOpening();
        if(this.state.checkORelativeNo === true) {
            this.setState({
                disabledRelative : true,
                oRelativeName : "",
                oCNumber : "",
                oRelationship : "",
                oJobTitle : "",
            })
        }
        if(this.state.checkConvictedNo === true) {
            this.setState({
                disabledConvicted : true,
                oCrime : "",
            })
        }
        if(this.state.checkLaborUnionNo === true) {
            this.setState({
                disabledLaborUnion : true,
                luDetails : "",
            })
        }
        if(this.state.checkChronicDiseaseNo === true) {
            this.setState({
                disabledDisease : true,
                diagnoseDeseaseId : "",
            })
        }
        this.autofillEducation()
        
        this.state.referenceGrid = [
            {
                "id": moment(new Date()).format('HHMMSS') + 1,
                "typeId" : "",
                "typeofCharacterReferenceName" : "",
                "name"          :   "",
                "contactNumber" :   "",
                "occupation"    :   "",
                "company"       :   "",
                "userId"        :   this.state.userinfo.userId,
                "isDeleted"    :    "0",  
            },
            {
                "id": moment(new Date()).format('HHMMSS') + 2,
                "typeId" : "",
                "typeofCharacterReferenceName" : "",
                "name"          :   "",
                "contactNumber" :   "",
                "occupation"    :   "",
                "company"       :   "",
                "userId"        :   this.state.userinfo.userId,
                "isDeleted"    :    "0",  
            },
            {
                "id": moment(new Date()).format('HHMMSS') + 3,
                "typeId" : "",
                "typeofCharacterReferenceName" : "",
                "name"          :   "",
                "contactNumber" :   "",
                "occupation"    :   "",
                "company"       :   "",
                "userId"        :   this.state.userinfo.userId,
                "isDeleted"    :    "0",  
            }
        ]
    };



    GetLivingArrangements = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetLivingArrangements",  params)
        .then(res => {
            const data = res.data;
            this.setState({arrangementDDL:data.livingArrangements});
        })
        this.GetBloodTypes()
    }
    GetBloodTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetBloodTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({bloodtypeDDL:data.bloodTypes});
        })
        this.GetReferralList()
    }
    GetReligions = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetReligions",  params)
        .then(res => {
            const data = res.data;
            let religionDDL = []
            religionDDL.push({"value":"","name":"Select Religion"})
            data.religions.map(function(itm){
                religionDDL.push({"value":itm.id,"name":itm.name})
            })
            this.setState({religionDDL:religionDDL});
        })
        this.GetLivingArrangements()
    }
    GetClient = async()=> {
        this.setState({isloading:true})
        const clientParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  clientParams)
        .then(res => {
            const data = res.data;
            this.setState({
                clientDDL   :   data.clients,
                isloading   :   false
            });
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangedArrangement = e => {
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.arrangementId= ''
            this.state.arrangement=''
            return
        }
        this.state.arrangementId=e[0].id
        this.state.arrangement=e[0].name
    }
    handleChangedBloodType = e => {
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.bloodtypeId= ''
            this.state.bloodtype=''
            return
        }
        this.state.bloodtypeId=e[0].id
        this.state.bloodtype=e[0].name
    };

    handleChangeClient = (e) => {
        if(e.length == 0) {
            this.state.clientId     =   ""
            this.state.clientName   =   ""
            return
        }
        this.state.clientId     =   e[0].id
        this.state.clientName   =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    GetPosition = async()=> {
        this.setState({isloading:true})
        const positionParams = {
            "IpAddress"     : "0.0.0.0",
            "ClientId"      : this.state.userinfo.clientId,
            "UserId"        : this.state.userinfo.userId,
            "SectionId"     : "",
            "DepartmentId"  : "",
            "Name"          : ""
        };
        await
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  positionParams)
        .then(res => {
            const data = res.data;
            this.setState({
                positionDDL : data.positions,
                isloading   : false
            });
        })
        
    }
    
    handleChangePosition = (e) => {
        if(e.length == 0) {
            this.state.positionId     =   ""
            this.state.positionName   =   ""
            return
        }
        this.state.positionId     =   e[0].id
        this.state.positionName   =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    handleChangeDateApplied = date => {
        this.setState({
            dateApplied: date,
            isshow:false,
        })
    }
    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }
    FormatDate2(date) {
        let m = moment(date, 'MM-YYYY');
        return (m.isValid() ? (date.getMonth()+1  + "/" + date.getFullYear()) : "")
    }

    
    handleChangeTitle = (e) => {
        this.setState({ titleValue: e.target.value,isshow:false, });
    };

    onChangeFirstName = (e) => {
        this.setState({ fName: e.target.value,isshow:false, });
    }

    onChangeNickName = (e) => {
        this.setState({ nName: e.target.value,isshow:false });
    }

    onChangeMiddleName = (e) => {
        this.setState({ mName: e.target.value,isshow:false });
    }

    onChangeLastName = (e) => {
        this.setState({ lName: e.target.value,isshow:false });
    }
    onChangeSuffix = (e) => {
        this.setState({ sName: e.target.value,isshow:false });
    }
    handleChangeType = (e) => {
        this.setState({ typeId: e.target.value,isshow:false });
    };

    GetRegion = async()=> {
        this.setState({isloading:true});
        const params = {
            "IpAddress" : "0.0.0.0",
            "ClientId"  : this.state.userinfo.clientId,
            "UserId"    : this.state.userinfo.userId,
            "Name"      : ""
        };
        await
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetRegions",  params)
        .then(res => {
            const data = res.data
            this.setState({
                regionDDL   :   data.regions,
                isloading   :   false
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }

    handleChangeRegion = (e) => {
        if(e.length == 0) {
            this.state.regionId     =   ""
            this.state.regionName   =   ""
            return
        }
        this.state.regionId     =   e[0].id
        this.state.regionName   =   e[0].name
        this.setState({
            isshow:false,
        })
        this.GetProvince();
    }

    GetProvince = async()=> {
        this.setState({isloading:true});
        const provinceParams = {
            "IpAddress" : "0.0.0.0",
            "ClientId"  : this.state.userinfo.clientId,
            "UserId"    : this.state.userinfo.userId,
            "Region"    : this.state.regionName
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetProvinces",  provinceParams)
        .then(res => {
            const data = res.data;
            this.setState({
                provinceDDL : data.provinces,isloading:false
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    GetReferralList = async() =>{
        this.setState({isloading: true})
        
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":"",
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter", params)
        .then(res => {
            const data = res.data;
            console.log("referral")
            console.log(data)
            this.setState({referralLst:data.profiles.filter(x=>x.status==="ACTIVE"),isloading:false});
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handledOnSearch = (e) =>{
        console.log("handledOnSearch")
        console.log(e)
        if(e===""){
            this.setState({referralDDL:[]})
        }
        else{
            this.setState({referralDDL:this.state.referralLst.filter(x => x.employeeName.toLowerCase().startsWith(e))})
            
        }
    }
    handleChangeReferral = (e) => {
        if(e.length == 0) {
            this.state.referralId     =   ""
            this.state.oReferredBy   =   ""
            return
        }
        this.state.referralId     =   e[0].id
        this.state.oReferredBy   =   e[0].employeeName
        this.setState({isshow:false,
        })
    }
    handleChangeProvince = (e) => {
        if(e.length == 0) {
            this.state.provinceId     =   ""
            this.state.provinceName   =   ""
            return
        }
        this.state.provinceId     =   e[0].id
        this.state.provinceName   =   e[0].name
        this.setState({
            isshow:false,
        })
        this.GetCity();
    }

    GetCity = async()=> {
        this.setState({isloading:true})
        const params = {
            "IpAddress" : "0.0.0.0",
            "ClientId"  : this.state.userinfo.clientId,
            "UserId"    : this.state.userinfo.userId,
            "RegionId"  : this.state.regionId,
            "ProvinceId": this.state.provinceId,
            "Name"      : ""
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetCities",  params)
        .then(res => {
            const data = res.data;
            this.setState({
                cityDDL   : data.cities,
                isloading : false
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })

    }

    handleChangeCity = (e) => {
        if(e.length == 0) {
            this.state.cityId     =   ""
            this.state.cityName   =   ""
            return
        }
        this.state.cityId     =   e[0].id
        this.state.cityName   =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    onChangeHouseNumber = (e) => {
        // const re = /^[0-9\b]+$/;
        // if (e.target.value === '' || re.test(e.target.value)) {
        //     this.setState({ houseNumber: e.target.value,isshow:false, })
        // }
        this.setState({ houseNumber: e.target.value,isshow:false, });
    };

    onChangeStreet = (e) => {
        this.setState({ street: e.target.value,isshow:false, });
    };

    onChangeBarangay = (e) => {
        this.setState({ barangay: e.target.value,isshow:false, });
    };

    onChangePostalCode = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ postalCode: e.target.value,isshow:false, })
        }
        //this.setState({ postalCode: e.target.value,isshow:false, });
    };

    onChangeHPNumber = (e) => {
        //this.setState({ hpNumber: e.target.value,isshow:false, });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ hpNumber: e.target.value,isshow:false, })
        }
    };

    onChangeOPNumber = (e) => {
        //this.setState({ opNumber: e.target.value,isshow:false, });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ opNumber: e.target.value,isshow:false, })
        }
    };

    onChangeMobileNumber = (e) => {
        //this.setState({ mobileNumber: e.target.value,isshow:false, });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ mobileNumber: e.target.value,isshow:false, })
        }
    };

    onChangeEmailAddress = (e) => {
        this.setState({ emailAddress: e.target.value,isshow:false, });
    };

    onChangeFacebook = (e) => {
        this.setState({ facebook: e.target.value,isshow:false, });
    };

    onChangeFacebook = (e) => {
        this.setState({ facebook: e.target.value,isshow:false, });
    };

    onChangeTwitter = (e) => {
        this.setState({ twitter: e.target.value,isshow:false, });
    };

    handleChangeCitizenship = (e) => {
        this.setState({ nationalityId: e.target.value,isshow:false, });
    };

    handleChangeReligion = (e) => {
        this.setState({ religionId: e.target.value,isshow:false, });
    };

    handleChangeGender = (e) => {
        this.setState({ genderValue: e.target.value,isshow:false });
    };

    handleChangeCivilStatus = (e) => {
        this.setState({ civilStatusValue: e.target.value,isshow:false });
    };

    calculate_age = (date) => {
        var today = new Date();
        var birthDate = new Date(date); 
        //console.log("get bod-->",birthDate) // create a date object directly from `dob1` argument
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
        }
        //console.log('my age', age_now);
        this.setState({
            totalAge : age_now
        });
        return age_now;
    }

    handleChangeDateOfBirth = date => {
        
        this.setState({
            dateOfBirth: date,
            isshow:false,
        });
        var today = new Date();
        var birthDate = new Date(date);
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
        {
            age_now--;
        }
        this.state.totalAge = age_now
        this.state.ageMonth = date.getMonth()+1
        this.state.ageYear = birthDate.getFullYear()    
        /* if(date > null) {
            this.state.ageMonth = date.getMonth()+1
            this.state.ageYear = date.getFullYear()
        } */

    };

    handleChangeFromStart = date => {
        this.setState({
            fromStart: date,
            //toEnd :newEndDate,
            isshow:false,
        });
    };
    handleChangeToEnd = date => {
        this.setState({
            //fromStart: date,
            toEnd :date,
            isshow:false,
        });
    };

    onChangeAgeMonth(e){
        this.setState({totalAge:e.target.value});
    }

    onChangePlaceOfBirth = (e) => {
        this.setState({ placeOfBirth: e.target.value,isshow:false });
    };

    onChangeHeight = (e) => {
        this.setState({ height: e.target.value,isshow:false });
    };

    onChangeWeight = (e) => {
        this.setState({ weight: e.target.value,isshow:false });
    };

    onChangeSkills = (e) => {
        this.setState({ sSkills: e.target.value,isshow:false });
    };

    onChangeLSpoken = (e) => {
        this.setState({ lSpoken: e.target.value,isshow:false });
    };

    onChangeECName = (e) => {
        this.setState({ ecName: e.target.value,isshow:false });
        /* const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ ecName: e.target.value,isshow:false, })
        } */
    };

    onChangeRelationship = (e) => {
        this.setState({ relationship: e.target.value,isshow:false });
    };

    onChangeECNumber = (e) => {
        //this.setState({ ecNumber: e.target.value,isshow:false });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ ecNumber: e.target.value,isshow:false, })
        }
          
    };

    onChangeSSS = (e) => {
        this.setState({sss: e.target.value,isshow:false})
    }

    onChangeTin = (e) => {
        this.setState({tin: e.target.value,isshow:false})
    }

    onChangePhilHealth = (e) => {
        this.setState({phic: e.target.value,isshow:false})
    }

    onChangePagibig = (e) => {
        this.setState({pagibig: e.target.value,isshow:false})
    }

    handleChangeLevel = (e) => {
        this.setState({ levelId: e.target.value,isshow:false });
        console.log(e.target.value)
    };

    onChangeSchoolName = (e) => {
        this.setState({ schoolName: e.target.value,isshow:false });
    };

    onChangeCourse = (e) => {
        this.setState({ course: e.target.value,isshow:false });
    };

    onChangeHonorsAward = (e) => {
        this.setState({ honorsAward: e.target.value,isshow:false });
    };

    onChangeStartYear = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ startYear: e.target.value,isshow:false, })
        }
        //this.setState({ startYear: e.target.value,isshow:false });
    };

    onChangeEndYear = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ endYear: e.target.value,isshow:false, })
        }
        //this.setState({ endYear: e.target.value,isshow:false });
    };

    handleAddEducation = (e) =>{
        let educationGrid = this.state.educationGrid
       

        let today = new Date()
        let newId = moment(today).format('HHMMSS');
        const obj = {
            "id":newId.toString(),
            "levelId"       :   this.state.levelId,
            "schoolName"    :   this.state.schoolName,
            "course"        :   this.state.course,
            "startYear"     :   this.state.startYear,
            "endYear"       :   this.state.endYear,
            "honorRecieved" :   this.state.honorsAward,
            "contactPerson" :   "",
            "contactNumber" :   "",
            "isModified"    :   "0", 
            "isDeleted"    :   "0",   

            //"level":this.state.level,
        }
        /* let a =[]
        a.push(obj)

        this.state.levelDDL.forEach(element => {
            console.log("element")
            console.log(element)
            a.forEach(elements => {
                console.log("elements")
                console.log(elements)
            });
        }); */

        educationGrid.push(obj)  
        console.log("educationGrid") 
        console.log(educationGrid)    
        this.setState({
            isshow:false,
            educationGrid:educationGrid,
            levelId:"",
            schoolName:"",
            course:"",
            startYear:"",
            endYear:"",
            honorsAward:"",
        })

        //console.log(educationGrid)
    }
    handleRemoveEducation = (e) =>{
        let educationGrid = this.state.educationGrid.filter(x=>x.isDeleted=="0")
        this.state.educationGrid = []
        this.setState({educationGrid:educationGrid,disableRemoveEducation: educationGrid.length===0 ? true : false})
    }

    EducationGridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.educationGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
        this.setState({disableRemoveEducation:disable})
    }

    GetLevel() {
        for (let i = 0; i < this.state.levelDDL.length; i++) {
            const obj = {
                value : this.state.levelDDL[i]["value"],
                label : this.state.levelDDL[i]["name"],
            };
            this.state.tblLevelArrLst.push(obj);
        }
        
    }

    onChangeERCompanyName = (e) => {
        this.setState({ erCompanyName: e.target.value,isshow:false });
    };

    onChangeERCompanyAddress = (e) => {
        this.setState({ erCompanyAddress: e.target.value,isshow:false });
    };

    onChangeERPosition = (e) => {
        this.setState({ erPosition: e.target.value,isshow:false });
    };

    onChangeERCPeriodCover = (e) => {
        this.setState({ erPeriodCover: e.target.value,isshow:false });
    };

    onChangeERSalary = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ erSalary: e.target.value,isshow:false, })
        }
        //this.setState({ erSalary: e.target.value,isshow:false });
    };

    onChangeERSupervisor = (e) => {
        this.setState({ erSupervisor: e.target.value,isshow:false });
    };

    onChangeERCNumber = (e) => {
        //this.setState({ erCNumber: e.target.value,isshow:false });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ erCNumber: e.target.value,isshow:false, })
        }
    };

    onChangeERRFLeaving = (e) => {
        this.setState({ erRFLeaving: e.target.value,isshow:false });
    };

    onChangeERCIncome = (e) => {
        this.setState({ erTCIncome: e.target.value,isshow:false });
    };

    onChangeERWHTax = (e) => {
        this.setState({ erWHTax: e.target.value,isshow:false });
    };

    onChangeERYOCompensation = (e) => {
        this.setState({ erYOCompensation: e.target.value,isshow:false });
    };

    onChangeERMPAOB = (e) => {
        this.setState({ erMPAOB: e.target.value,isshow:false });
    };

    onChangeERDBenefits = (e) => {
        this.setState({ erDBenefits: e.target.value,isshow:false });
    };

    onChangeERMDeduction = (e) => {
        this.setState({ erMDeduction: e.target.value,isshow:false });
    };

    onChangeERNTSOFOC = (e) => {
        this.setState({ erNTSOFOC: e.target.value,isshow:false });
    };

    onChangeERBasicSalary = (e) => {
        this.setState({ erBasicSalary: e.target.value,isshow:false });
    };

    onChangeERTMPOB = (e) => {
        this.setState({ erTMPOB: e.target.value,isshow:false });
    };

    onChangeERTSPFOC = (e) => {
        this.setState({ erTSPFOC: e.target.value,isshow:false });
    };

    onChangeERBPFMWE = (e) => {
        this.setState({ erBPFMWE: e.target.value,isshow:false });
    };

    onChangeERHPFHWE = (e) => {
        this.setState({ erHPFHWE: e.target.value,isshow:false });
    };

    onChangeEROPFHWE = (e) => {
        this.setState({ erOPFHWE: e.target.value,isshow:false });
    };

    onChangeERNDPFHWE = (e) => {
        this.setState({ erNDPFHWE: e.target.value,isshow:false });
    };

    onChangeERRemarks = (e) => {
        this.setState({ erRemarks: e.target.value,isshow:false, });
    };
    onChangesFreshGraduate  = (e) => {
        let employmentRecordGrid = this.state.employmentRecordGrid
        this.state.checkIsFreshGraduate = e.target.checked
        if(this.state.checkIsFreshGraduate == true) {
            
            let today = new Date()
            let newId = moment(today).format('HHMMSS');
            const obj = {
                "id":newId.toString(),
                "companyName"               :   "N/A",
                "companyAddress"            :   "N/A",
                "position"                  :   "N/A",
                "salary"                    :   "0",
                "periodCovered"             :   "N/A",
                "isModified"                :   "0",
                "supervisor"                :   this.state.erSupervisor,
                "contactNumber"             :   this.state.erCNumber,
                "reasonForLeaving"          :   "N/A",
                "taxableCompensationIncome" :   this.state.erTCIncome,
                "withholdingTax"            :   this.state.erWHTax,
                "yearOfCompensation"        :   this.state.erYOCompensation,
                "nTThirteenMonthPay"        :   this.state.erMPAOB,
                "deminimis"                 :   this.state.erDBenefits,
                "nTMandatoryDeduction"      :   this.state.erMDeduction,
                "nTSalaries"                :   this.state.erNTSOFOC,
                "basicSalary"               :   this.state.erBasicSalary,
                "taxableThirteenMonthPay"   :   this.state.erTMPOB,
                "taxableSalaries"           :   this.state.erTSPFOC,
                "basicPayMWE"               :   this.state.erBPFMWE,
                "holidayPayMWE"             :   this.state.erHPFHWE,
                "overtimePayMWE"            :   this.state.erOPFHWE,
                "nightDiffPayMWE"           :   this.state.erNDPFHWE,
                "remarks"                   :   this.state.erRemarks,
                "isDeleted"    :   "0",
            }
            employmentRecordGrid.push(obj)      
            this.setState({
                employmentRecordGrid:employmentRecordGrid,
                isshow:false,
                isEmploymentDisabled : true,
            })
            //console.log(employmentRecordGrid)
        } else {     
            this.setState({
                employmentRecordGrid: [],
                isshow:false,
                isEmploymentDisabled : false,
            })
        }
    }

    handleAddEmploymentRecord = (e) =>{
        let employmentRecordGrid = this.state.employmentRecordGrid


        if(this.state.erCompanyName===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter company name.",
                fade        :   false
            });
            return
        }

        if(this.state.erCompanyAddress===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter company address.",
                fade        :   false
            });
            return
        }

        if(this.state.erPosition===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter position.",
                fade        :   false
            });
            return
        }

        if(this.state.erSalary===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please salary.",
                fade        :   false
            });
            return
        }

        if(this.state.fromStart===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select period covered date from.",
                fade        :   false
            });
            return
        }
        if(this.state.toEnd===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select period covered date to.",
                fade        :   false
            });
            return
        }
        /* if(this.state.erPeriodCover===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please period covered.",
                fade        :   false
            });
            return
        } */
        console.log(this.state.fromStart)
        console.log(this.state.toEnd)
        console.log(this.state.fromStart  + this.state.toEnd)

        let a = this.FormatDate2(this.state.fromStart)
        let b = this.FormatDate2(this.state.toEnd)
        let c = a + " " + b
        console.log(a + " "+ b)
        console.log(c)
        let today = new Date()
        let newId = moment(today).format('HHMMSS');
        const obj = {
            "id":newId.toString(),
            "companyName"               :   this.state.erCompanyName,
            "companyAddress"            :   this.state.erCompanyAddress,
            "position"                  :   this.state.erPosition,
            "salary"                    :   this.state.erSalary,
            "supervisor"                :   this.state.erSupervisor,
            "contactNumber"             :   this.state.erCNumber,
            "reasonForLeaving"          :   this.state.erRFLeaving,
            "fromStart"                 :   this.FormatDate(this.state.fromStart),
            "toEnd"                     :   this.FormatDate(this.state.toEnd),
            "PeriodCovered"             :   c,
            //"periodCovered"             :   this.state.erPeriodCover,
            "taxableCompensationIncome" :   this.state.erTCIncome,
            "withholdingTax"            :   this.state.erWHTax,
            "yearOfCompensation"        :   this.state.erYOCompensation,
            "nTThirteenMonthPay"        :   this.state.erMPAOB,
            "deminimis"                 :   this.state.erDBenefits,
            "nTMandatoryDeduction"      :   this.state.erMDeduction,
            "nTSalaries"                :   this.state.erNTSOFOC,
            "basicSalary"               :   this.state.erBasicSalary,
            "taxableThirteenMonthPay"   :   this.state.erTMPOB,
            "taxableSalaries"           :   this.state.erTSPFOC,
            "basicPayMWE"               :   this.state.erBPFMWE,
            "holidayPayMWE"             :   this.state.erHPFHWE,
            "overtimePayMWE"            :   this.state.erOPFHWE,
            "nightDiffPayMWE"           :   this.state.erNDPFHWE,
            "remarks"                   :   this.state.erRemarks,
            "isModified"                :   "0",
            "isDeleted"    :   "0",  
        }
        employmentRecordGrid.push(obj)      
        this.setState({
            employmentRecordGrid:employmentRecordGrid,
            isshow:false,
            
            erCompanyName : "",
            erCompanyAddress : "",
            erPosition : "",
            erPeriodCover : "",
            erSalary : "",
            erSupervisor : "",
            erCNumber : "",
            erRFLeaving : "",
            erTCIncome : "",
            erWHTax : "",
            erYOCompensation : "",
            erMPAOB : "",
            erDBenefits : "",
            erMDeduction : "",
            erNTSOFOC: "",
            erBasicSalary : "",
            erTMPOB : "",
            erTSPFOC : "",
            erBPFMWE : "",
            erHPFHWE : "",
            erOPFHWE : "",
            erNDPFHWE : "",
            erRemarks : "",
            fromStart : "",
            toEnd : "",
        })
        //console.log(employmentRecordGrid)
    }
    handleRemoveEmploymentRecord = (e) =>{
        let employmentRecordGrid = this.state.employmentRecordGrid.filter(x=>x.isDeleted=="0")
        this.state.employmentRecordGrid = []
        this.setState({employmentRecordGrid:employmentRecordGrid,disableRemoveEmploymentRecord: employmentRecordGrid.length===0 ? true : false})
    }

    EmploymentGridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.employmentRecordGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
       // this.setState({disableRemoveEducation:disable})
    }

    applicationHistoryGridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.applicationHistoryGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
       // this.setState({disableRemoveEducation:disable})
    }



    handleChangeRole = (e) => {
        this.setState({ roleId: e.target.value,role: e.target.name,isshow:false });
    };
    handleChangetypeofcharacterreference=(e)=>{
        console.log(e.target.value)
        console.log( e.target.name)
        this.setState({ typeofcharacterreferenceId: e.target.value,isshow:false });
    }

    onChangeFBName = (e) => {
        this.setState({ fbName: e.target.value,isshow:false });
    };

    onChangeFBAge = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({fbAge: e.target.value,isshow:false})
        }
    };

    onChangeFBOccupation = (e) => {
        this.setState({ fbOccupation: e.target.value,isshow:false });
    };

    onChangeFBCOSchool = (e) => {
        this.setState({ fbCOSchool: e.target.value,isshow:false });
    };

    onChangeFBCNumber = (e) => {
        //this.setState({ fbCNumber: e.target.value,isshow:false });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ fbCNumber: e.target.value,isshow:false, })
        }
    };

    GetRole() {
        for (let i = 0; i < this.state.roleDDL.length; i++) {
            const obj = {
                value : this.state.roleDDL[i]["value"],
                label : this.state.roleDDL[i]["name"],
            };
            this.state.tblRoleArrLst.push(obj);
        }
        
    }
    Gettypeofcharacterreference() {
        for (let i = 0; i < this.state.typeofcharacterreference.length; i++) {
            const obj = {
                value : this.state.typeofcharacterreference[i]["value"],
                label : this.state.typeofcharacterreference[i]["name"],
            };
            this.state.tbltypeofcharacterreferenceArrLst.push(obj);
        }
        
    }

    handleAddFamilybackground = (e) =>{
        let familyBackgroundGrid = this.state.familyBackgroundGrid


        let today = new Date()
        let newId = moment(today).format('HHMMSS');
        const obj = {
            "id":newId.toString(),
            "roleId"        :   this.state.roleId,
            "name"          :   this.state.fbName,
            "contactNumber" :   this.state.fbCNumber,
            "age"           :   this.state.fbAge,
            "occupation"    :   this.state.fbOccupation,
            "company"       :   this.state.fbCOSchool,
            "userId"        :   this.state.userinfo.userId,
            "isModified"    :   "0",
            "isDeleted"     :   "0",  
            "role"          :   this.state.role,
        }
        familyBackgroundGrid.push(obj)      
        this.setState({familyBackgroundGrid:familyBackgroundGrid,isshow:false})
    }
    handleRemoveBackground = (e) =>{
        this.state.familyBackgroundGrid.forEach((itm,idx) => {
            if(idx<=5){
                itm.isDeleted="0"
            }
            
        })
        let familyBackgroundGrid = this.state.familyBackgroundGrid.filter(x=>x.isDeleted=="0")
        this.setState({familyBackgroundGrid:familyBackgroundGrid,disableBackground: familyBackgroundGrid.length===0 ? true : false})
    }

    FamilyBackgroundGridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.familyBackgroundGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
       // this.setState({disableRemoveEducation:disable})
    }



    handleChangeJobOpening = (e) => {
        let jobSourceId = e.target.value
        let hasReferral = this.state.jobOpeningDDL.find(x=>x.value===jobSourceId).hasReferral
        if(hasReferral==="1"){
            if(jobSourceId === "10"){
                this.refs.refReferredBy.clear()
                this.setState({
                    jobSourceId: jobSourceId,
                    jobSourceId1: e.target.value,
                    disablereferral:false,
                    referealOthersTA : true,
                    referealOthersTXT : false,
                    isshow:false,
                })
            }
            else{
                this.refs.refReferredBy.clear()
                this.setState({
                    jobSourceId: jobSourceId,
                    jobSourceId1: e.target.value,
                    disablereferral:false,
                    referealOthersTA : false,
                    referealOthersTXT : true,
                    isshow:false,
                })
            }
        }
        else{
            this.refs.refReferredBy.clear()
                this.setState({
                    jobSourceId: jobSourceId,
                    jobSourceId1: e.target.value,
                    disablereferral:true,
                    referealOthersTA : true,
                    referealOthersTXT : false,
                    isshow:false,
                })
        }
        // if(jobSourceId === "9" ||  jobSourceId === "7"){
        //     this.refs.refReferredBy.clear()
        //     this.setState({
        //         jobSourceId: jobSourceId,
        //         disablereferral:false,
        //         referealOthersTA : false,
        //         referealOthersTXT : true,
        //         isshow:false,
        //     })
        // }else if(jobSourceId === "10"){
        //     this.refs.refReferredBy.clear()
        //     this.setState({
        //         jobSourceId: jobSourceId,
        //         disablereferral:false,
        //         referealOthersTA : true,
        //         referealOthersTXT : false,
        //         isshow:false,
        //     })
        // }else if(jobSourceId!=="7" ||  jobSourceId === "9" || jobSourceId === "10"){
        //     this.refs.refReferredBy.clear()
        //     this.setState({
        //         jobSourceId: jobSourceId,
        //         isshow:false,
        //         disablereferral:true,
        //         referealOthersTA : true,
        //         referealOthersTXT : false,
        //         isshow:false,
        //     })
        // }
    }

    onChangeOthersRefferedBy = (e) => {
        this.setState({ oReferredBy: e.target.value,isshow:false });
    };
    

    
    onChangeORelativeYes  = (e) => {
        this.state.checkORelativeYes = e.target.checked
        if(this.state.checkORelativeYes == true) {
            this.setState({
                checkORelativeNo    :   false,
                disabledRelative    : false,
            })
        }
    }
    
    onChangeORelativeNo  = (e) => {
        this.state.checkORelativeNo = e.target.checked
        if(this.state.checkORelativeNo == true) {
            this.setState({
                checkORelativeYes   :   false,
                disabledRelative    : true,
                oRelativeName : "",
                oCNumber : "",
                oRelationship : "",
                oJobTitle : "",
            })
        }
    }

    onChangeORelativeName = (e) => {
        this.setState({ oRelativeName: e.target.value,isshow:false });
    };

    onChangeOCNumber = (e) => {
        //this.setState({ oCNumber: e.target.value,isshow:false });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ oCNumber: e.target.value,isshow:false, })
        }
    };

    onChangeORelationship = (e) => {
        this.setState({ oRelationship: e.target.value,isshow:false });
    };

    onChangeOJobTitle = (e) => {
        this.setState({ oJobTitle: e.target.value,isshow:false });
    };
    
    onChangeOthersConvictedYes  = (e) =>{
        this.state.checkConvictedYes = e.target.checked
        if(this.state.checkConvictedYes == true) {
            this.setState({
                checkConvictedNo  :   false,
                disabledConvicted  		  :   false,
            })
        }
    }
    
    onChangeOthersConvictedNo  = (e) =>{
        this.state.checkConvictedNo = e.target.checked
        if(this.state.checkConvictedNo == true) {
            this.setState({
                disabledConvicted  :   true,
                checkConvictedYes  		  :   false,
                crime : "",
            })
        }
    }
    
    onChangeOthersLaborUnionYes  = (e) =>{
        this.state.checkLaborUnionYes = e.target.checked
        if(this.state.checkLaborUnionYes == true) {
            this.setState({
                checkLaborUnionNo  :   false,
                disabledLaborUnion  :   false,
            })
        }
    }

    onChangeOCrime = (e) => {
        this.setState({ oCrime: e.target.value,isshow:false });
    };
    
    onChangeOthersLaborUnionNo  = (e) =>{
        this.state.checkLaborUnionNo = e.target.checked
        if(this.state.checkLaborUnionNo == true) {
            this.setState({
                checkLaborUnionYes  :   false,
                disabledLaborUnion  :   true,
            })
        }
    }

    onChangeLUDetails = (e) => {
        this.setState({ luDetails: e.target.value,isshow:false });
    };
    
    onChangeOthersChronicDiseaseYes  = (e) =>{
        this.state.checkChronicDiseaseYes = e.target.checked
        if(this.state.checkChronicDiseaseYes == true) {
            this.setState({
                checkChronicDiseaseNo  :   false,
                disabledDisease  :   false,
            })
        }
    }
    
    onChangeOthersChronicDiseaseNo  = (e) =>{
        this.state.checkChronicDiseaseNo = e.target.checked
        if(this.state.checkChronicDiseaseNo == true) {
            this.setState({
                checkChronicDiseaseYes  :   false,
                disabledDisease :   true,
                diagnoseDeseaseId : "",
            })
        }
    }

    handleChangeDisease = (e) => {
        this.setState({ diagnoseDeseaseId: e.target.value,chronicDisease: e.target.name,isshow:false });
    };

    GetJobOpening = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetJobOpenings",  params)
        .then(res => {
            const data = res.data;
            let jobOpeningDDL = []
            let tblJobOpeningArrLst = []
            jobOpeningDDL.push({"value":"","name":"Select Job Opening"})
            data.jobOpenings.map(function(itm){
                jobOpeningDDL.push({"value":itm.id,"name":itm.name, "hasReferral":itm.hasReferral})
                tblJobOpeningArrLst.push({"value" : itm.id,"label" : itm.name, "hasReferral":itm.hasReferral})
            })
            this.setState({jobOpeningDDL:jobOpeningDDL,tblJobOpeningArrLst:tblJobOpeningArrLst});
        })
        this.GetReligions();
    }

    GetDisease() {
        for (let i = 0; i < this.state.diseaseDDL.length; i++) {
            const obj = {
                value : this.state.diseaseDDL[i]["value"],
                label : this.state.diseaseDDL[i]["name"],
            };
            this.state.tblDiseaseArrLst.push(obj);
        }
        
    }
    
    handleAddOthers = (e) =>{
        if(this.state.checkORelativeYes === true) {
            if(this.state.oRelativeName === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter name",
                    fade:true
                });
                return
            }
            if(this.state.oCNumber === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter contact number",
                    fade:true
                });
                return
            }
            if(this.state.oRelationship === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter relationship",
                    fade:true
                });
                return
            }
            if(this.state.oJobTitle === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter job title",
                    fade:true
                });
                return
            }
        }
        
        if(this.state.checkConvictedYes === true) {
            if(this.state.oCrime === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter details",
                    fade:true
                });
                return
            }
        }
        
        if(this.state.checkLaborUnionYes === true) {
            if(this.state.luDetails === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter details",
                    fade:true
                });
                return
            }
        }
        
        if(this.state.checkChronicDiseaseYes === true) {
            if(this.state.diagnoseDeseaseId === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please select details",
                    fade:true
                });
                return
            }
        }

        let othersGrid = this.state.othersGrid

        let today = new Date()
        let newId = moment(today).format('HHMMSS');
        const obj = {
            "id":newId.toString(),
            "applicationFormId":"1",
            "jobSourceId":this.state.jobSourceId,
            "refferedBy":this.state.oReferredBy,
            "hasCompanyRelative":(this.state.checkORelativeYes)? "1" : "0",
            "relativeName":this.state.oRelativeName,
            "relativeRelation":this.state.oRelationship,
            "contactNumber":this.state.oCNumber,
            "relativePosition":this.state.oJobTitle,
            "isCrimeConvicted":(this.state.checkConvictedYes)? "1" : "0",
            "crimeDescription":this.state.oCrime,
            "isLaborUnion":(this.state.checkLaborUnionYes)? "1" : "0",
            "laborUnionDescription":this.state.luDetails,
            "diagnoseDeseaseId":this.state.diagnoseDeseaseId,
            "userId":this.state.userinfo.userId,
            "isModified"    :   "0",
            "isDeleted"    :   "0",  
            "jobopening"       :   this.state.jobSource,
            "diagnoseDesease":this.state.chronicDisease
        }
        othersGrid.push(obj)      
        this.setState({
            othersGrid:othersGrid,
            isshow:false,
            jobSourceId : "",
            checkConvictedYes : false,
            checkConvictedNo : true,
            oCrime : "",
            disabledConvicted : true,
            oReferredBy : "",
            checkLaborUnionYes : false,
            checkLaborUnionNo : true,
            disabledLaborUnion : true,
            luDetails : "",
            checkChronicDiseaseYes : false,
            checkChronicDiseaseNo : true,
            disabledDisease : true,
            diagnoseDeseaseId : "",
        })
        //console.log(othersGrid)
    }
    handleRemoveOthers = (e) =>{
        let othersGrid = this.state.othersGrid.filter(x=>x.isDeleted=="0")
        this.state.othersGrid = []
        this.setState({othersGrid:othersGrid,disableOthers: othersGrid.length===0 ? true : false})
    }

    OtherGridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.othersGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
       // this.setState({disableRemoveEducation:disable})
    }


    onChangeReferenceName = (e) => {
        this.setState({ referenceName: e.target.value,isshow:false });
    };

    onChangeReferencOccupation = (e) => {
        this.setState({ referenceOccupation: e.target.value,isshow:false });
    };

    onChangeReferenceCOSchool = (e) => {
        this.setState({ referenceCOSchool: e.target.value,isshow:false });
    };

    onChangeReferenceCNumber = (e) => {
        //this.setState({ referenceCNumber: e.target.value,isshow:false });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ referenceCNumber: e.target.value,isshow:false, })
        }
         
    };

    handleAddReference = (e) =>{
        if(this.state.typeofcharacterreferenceId === "") {
		    this.setState({
		        isloading:false,
		        alerttype:"Error!",
		        isshow:true,
		        color:"danger",
		        message: "please enter type of character reference",
		        fade:true
		    });
		    return
        }
		if(this.state.referenceName === "") {
		    this.setState({
		        isloading:false,
		        alerttype:"Error!",
		        isshow:true,
		        color:"danger",
		        message: "please enter name",
		        fade:true
		    });
		    return
        }
		if(this.state.referenceOccupation === "") {
		    this.setState({
		        isloading:false,
		        alerttype:"Error!",
		        isshow:true,
		        color:"danger",
		        message: "please enter occupation",
		        fade:true
		    });
		    return
        }
		if(this.state.referenceCOSchool === "") {
		    this.setState({
		        isloading:false,
		        alerttype:"Error!",
		        isshow:true,
		        color:"danger",
		        message: "please enter company or school",
		        fade:true
		    });
		    return
        }
		if(this.state.referenceCNumber === "") {
		    this.setState({
		        isloading:false,
		        alerttype:"Error!",
		        isshow:true,
		        color:"danger",
		        message: "please enter contact number",
		        fade:true
		    });
		    return
        }
        
        let referenceGrid = this.state.referenceGrid

        let today = new Date()
        let newId = moment(today).format('HHMMSS');
        const obj = {
            "id":newId.toString(),
            "typeId" : this.state.typeofcharacterreferenceId,
            "typeofCharacterReferenceName" : this.state.typeofcharacterreferenceName,
            "name"          :  this.state.referenceName,
            "contactNumber" :   this.state.referenceCNumber,
            "occupation"    :   this.state.referenceOccupation,
            "company"       :   this.state.referenceCOSchool,
            "userId"        :this.state.userinfo.userId,
            "isDeleted"    :   "0",  
        }
        console.log("obj")
        console.log(obj)
        referenceGrid.push(obj)    
        console.log("referenceGrid")  
        console.log(referenceGrid)  
        this.setState({
            referenceGrid:referenceGrid,
            isshow:false,
            typeofcharacterreferenceId :"",
            referenceName : "",
            referenceOccupation : "",
            referenceCOSchool : "",
            referenceCNumber : "",
        })
        console.log(referenceGrid)
    }
    handleRemoveReference = (e) =>{
        let referenceGrid = this.state.referenceGrid.filter(x=>x.isDeleted=="0")
        this.state.referenceGrid = []
        this.setState({referenceGrid:referenceGrid,disableReference: referenceGrid.length===0 ? true : false})
    }

    ReferenceGridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.referenceGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
       // this.setState({disableRemoveEducation:disable})
    }
    
    onChangeDisclaimer  = (e) =>{
        this.state.checkDisclaimer = e.target.checked
        if(this.state.checkDisclaimer == true) {
            this.setState({
                disablebutton :   false,
            })
        } else {
            this.setState({
                disablebutton :   true,
            })
        }
    }
    
    handleClickStatus = async(statusId) => {
        console.log("employmentRecordGrid")
        console.log(this.state.employmentRecordGrid)
        
        if(this.state.clientId === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select client",
                fade:true
            });
            return
        }
        if(this.state.positionId === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select position",
                fade:true
            });
            return
        }
        if(this.state.dateApplied === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select date applied",
                fade:true
            });
            return
        };
        if(this.state.newImage === ""){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please upload ID picture",
                fade:true
            });
            return
        };
        if(this.state.titleValue === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select name title",
                fade:true
            });
            return
        }
        if(this.state.fName === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter first name",
                fade:true
            });
            return
        }
        if(this.state.nName === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter nick name",
                fade:true
            });
            return
        }
        if(this.state.lName === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter last name",
                fade:true
            });
            return
        }
        if(this.state.typeId === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select address type",
                fade:true
            });
            return
        }
        if(this.state.regionId === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select region",
                fade:true
            });
            return
        }
        if(this.state.provinceId === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select province",
                fade:true
            });
            return
        }
        if(this.state.cityId === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select city",
                fade:true
            });
            return
        }
        if(this.state.houseNumber === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter houseNumber",
                fade:true
            });
            return
        }
        if(this.state.street === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter street",
                fade:true
            });
            return
        }
        if(this.state.barangay === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter barangay",
                fade:true
            });
            return
        }
        if(this.state.postalCode === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter postalCode",
                fade:true
            });
            return
        }
        if(this.state.emailAddress === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter email address",
                fade:true
            });
            return
        }
        if(this.state.mobileNumber === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter mobile number",
                fade:true
            });
            return
        }
        if(this.state.hpNumber === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter home phone number",
                fade:true
            });
            return
        }
        if(this.state.facebook === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter facebook",
                fade:true
            });
            return
        }
        if(this.state.twitter === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter twitter",
                fade:true
            });
            return
        }
        if(this.state.nationalityId === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select nationality",
                fade:true
            });
            return
        }
        if(this.state.religionId === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select religion",
                fade:true
            });
            return
        }
        if(this.state.genderValue === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select gender",
                fade:true
            });
            return
        }
        if(this.state.civilStatusValue === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select civil status",
                fade:true
            });
            return
        }
        if(this.state.dateOfBirth === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please select date of birth",
                fade:true
            });
            return
        }
        if(this.state.placeOfBirth === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter place of birth",
                fade:true
            });
            return
        }
        if(this.state.height === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter height",
                fade:true
            });
            return
        }
        if(this.state.weight === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter weight",
                fade:true
            });
            return
        }
        if(this.state.sSkills === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter special skills",
                fade:true
            });
            return
        }
        if(this.state.lSpoken === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter language spoken",
                fade:true
            });
            return
        }
        if(this.state.ecName === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter emergency contact name",
                fade:true
            });
            return
        }
        if(this.state.relationship === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter emergency contact relation",
                fade:true
            });
            return
        }
        if(this.state.ecNumber === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter emergency contact number",
                fade:true
            });
            return
        }
        if(this.state.tin === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter tin number",
                fade:true
            });
            return
        }
        if(this.state.sss === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter sss number",
                fade:true
            });
            return
        }
        if(this.state.phic === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter philhealth number",
                fade:true
            });
            return
        }
        if(this.state.pagibig === "") {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please enter pagibig number",
                fade:true
            });
            return
        };
        if(18 > this.state.totalAge){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "Age below 18 is not valid",
                fade:true
            });
            return
        };

        if(this.IsValidMobile(this.state.mobileNumber)){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "Mobile number should start with 09 followed by 9 digits.",
                fade:true
            });
            return
        }
        if(this.IsValidMobile(this.state.ecNumber)){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "Mobile number should start with 09 followed by 9 digits.",
                fade:true
            });
            return
        }

        if(this.state.uploadResume === ""){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please upload resume",
                fade:true
            });
            return
        };

        
        let educationGridtemp = this.state.educationGrid
        let b = educationGridtemp.filter((item) => item.schoolName !== "");
        if(b.length === 0){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please input all fields under school name",
                fade:true
            });
            return
        }else if(b.length < 2){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "fields under school name must be aleast 2",
                fade:true
            });
            return
        };
        let c = educationGridtemp.filter((item) => item.course !== "");
        if(c.length === 0){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please input all fields under course",
                fade:true
            });
            return
        }else if(c.length < 2){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "fields under course must be aleast 2",
                fade:true
            });
            return
        };
        let d = educationGridtemp.filter((item) => item.startYear !== "");
        if(d.length === 0){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please input all fields under start year",
                fade:true
            });
            return
        }else if(d.length < 2){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "fields under start year must be aleast 2",
                fade:true
            });
            return
        };
        let e = educationGridtemp.filter((item) => item.endYear !== "");
        if(e.length === 0){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please input all fields under end year",
                fade:true
            });
            return
        }else if(e.length < 2){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "fields under end year must be aleast 2",
                fade:true
            });
            return
        };

        if(!this.state.educationGrid.length) {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please input all fields under education",
                fade:true
            });
            return
        };
       
        if(!this.state.employmentRecordGrid.length) {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please input some fields under employment record",
                fade:true
            });
            return
        };
        
        if(!this.state.familyBackgroundGrid.length) {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please input all fields under family background",
                fade:true
            });
            return
        }
        let familyBackgroundGridtmp = this.state.familyBackgroundGrid
        let a = familyBackgroundGridtmp.filter((item) => item.name !== "");
        if(a.length === 0){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please input all fields under family Background NAME",
                fade:true
            });
            return
        };
        let aa = familyBackgroundGridtmp.filter((item) => item.contactNumber !== "");
        if(aa.length === 0){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please input all fields under family Background contact number",
                fade:true
            });
            return
        };
        let aaa = familyBackgroundGridtmp.filter((item) => item.age !== "");
        if(aaa.length === 0){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please input all fields under family Background age",
                fade:true
            });
            return
        };
        let aaaa = familyBackgroundGridtmp.filter((item) => item.occupation !== "");
        if(aaaa.length === 0){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please input all fields under family Background occupation",
                fade:true
            });
            return
        };
        let aaaaa = familyBackgroundGridtmp.filter((item) => item.company !== "");
        if(aaaaa.length === 0){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please input all fields under family Background company",
                fade:true
            });
            return
        };

        
        if(this.state.referenceGrid.filter(x=>x.typeId!=="").length===0) {
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "please input all fields under reference",
                fade:true
            });
            return
        }
        if (this.state.referenceGrid.filter(x=>x.typeId!=="").length < 3){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "character references must be 3 or more",
                fade:true
            });
            return
        }

        let neweducationGrid = []
        this.state.educationGrid.map(function(itm,i) {
            neweducationGrid.push({
                "LevelId"       :   itm.levelId,
                "ContactPerson" :   itm.contactPerson,
                "ContactNumber" :   itm.contactNumber,
                "SchoolName"    :   itm.schoolName,
                "Course"        :   itm.course,
                "StartYear"     :   itm.startYear,
                "EndYear"       :   itm.endYear,
                "HonorRecieved" :   itm.honorRecieved,
            })
        })          
        
        let newemploymentRecordGrid = []
        this.state.employmentRecordGrid.map(function(itm,i) {
            let a = itm.fromStart
            let b = itm.toEnd
            let c = a + " " + b
            newemploymentRecordGrid.push({
                "CompanyName"               :   itm.companyName,
                "CompanyAddress"            :   itm.companyAddress,
                "Position"                  :   itm.position,
                "Salary"                    :   itm.salary,
                "Supervisor"                :   itm.supervisor,
                "ContactNumber"             :   itm.contactNumber,
                "ReasonForLeaving"          :   itm.reasonForLeaving,
                "PeriodStart"               :   itm.fromStart,
                "PeriodEnd"                 :   itm.toEnd,
                "PeriodCovered"             :   itm.PeriodCovered,
                "TaxableCompensationIncome" :   itm.taxableCompensationIncome,
                "WithholdingTax"            :   itm.withholdingTax,
                "YearOfCompensation"        :   itm.yearOfCompensation,
                "NTThirteenMonthPay"        :   itm.nTThirteenMonthPay,
                "Deminimis"                 :   itm.deminimis,
                "NTMandatoryDeduction"      :   itm.nTMandatoryDeduction,
                "NTSalaries"                :   itm.nTSalaries,
                "BasicSalary"               :   itm.basicSalary,
                "TaxableThirteenMonthPay"   :   itm.taxableThirteenMonthPay,
                "TaxableSalaries"           :   itm.taxableSalaries,
                "BasicPayMWE"               :   itm.basicPayMWE,
                "HolidayPayMWE"             :   itm.holidayPayMWE,
                "OvertimePayMWE"            :   itm.overtimePayMWE,
                "NightDiffPayMWE"           :   itm.nightDiffPayMWE,
                "Remarks"                   :   itm.remarks,
            })
        })                    

        let newfamilyBackgroundGrid = []
        this.state.familyBackgroundGrid.map(function(itm,i) {
            newfamilyBackgroundGrid.push({
                "RoleId"        :   itm.roleId,
                "Name"          :   itm.name,
                "ContactNumber" :   itm.contactNumber,
                "Age"           :   itm.age,
                "Occupation"    :   itm.occupation,
                "Company"       :   itm.company,
                "UserId":itm.userId
            })
        })             

        /*VALIDATE OTHERS*/
        let newothersGrid = []
        if(this.state.checkORelativeYes === true) {
            if(this.state.oRelativeName === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter name",
                    fade:true
                });
                return
            }
            if(this.state.oCNumber === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter contact number",
                    fade:true
                });
                return
            }
            if(this.state.oRelationship === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter relationship",
                    fade:true
                });
                return
            }
            if(this.state.oJobTitle === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter job title",
                    fade:true
                });
                return
            }
        }
        
        if(this.state.checkConvictedYes === true) {
            if(this.state.oCrime === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter details",
                    fade:true
                });
                return
            }
        }
        
        if(this.state.checkLaborUnionYes === true) {
            if(this.state.luDetails === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter details",
                    fade:true
                });
                return
            }
        }
        
        if(this.state.checkChronicDiseaseYes === true) {
            if(this.state.diagnoseDeseaseId === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please select details",
                    fade:true
                });
                return
            }
        }

        newothersGrid.push({

            "JobSourceId":this.state.jobSourceId,
            "RefferedBy":this.state.oReferredBy,
            "HasCompanyRelative":(this.state.checkORelativeYes)? "1" : "0",
            "RelativeName":this.state.oRelativeName,
            "RelativeRelation":this.state.oRelationship,
            "RelativePosition":this.state.oJobTitle,
            "RelativeContactNumber":this.state.oCNumber,
            "IsCrimeConvicted":(this.state.checkConvictedYes)? "1" : "0",
            "CrimeDescription":this.state.oCrime,
            "IsLaborUnion":(this.state.checkLaborUnionYes)? "1" : "0",
            "LaborUnionDescription":this.state.luDetails,
            "DiagnoseDeseaseId":this.state.diagnoseDeseaseId,
            "UserId":this.state.userinfo.userId
        })
                 
        /*END OTHERS*/
        
        let newreferenceGrid = []
        this.state.referenceGrid.map(function(itm,i) {
            newreferenceGrid.push({
                "TypeId" : itm.typeId,
                "Name"          :  itm.name,
                "ContactNumber" :   itm.contactNumber,
                "Occupation"    :   itm.occupation,
                "Company"       :   itm.company,
                "UserId"        :itm.userId,
            })
        })
        

       this.setState({isloading:true})
        

        const params = {
            "IpAddress"                 : "0.0.0.0",
            "ClientId"                  : this.state.clientId,
            "UserId"                    : this.state.userinfo.userId,
            "StatusId"                  : statusId,
            "PositionId"                : this.state.positionId,
            "DateApplied"               : this.FormatDate(this.state.dateApplied),
            "Title"                     : this.state.titleValue,
            "FirstName"                 : this.state.fName,
            "NickName"                  : this.state.nName,
            "MiddleName"                : this.state.mName,
            "LastName"                  : this.state.lName,
            "SuffixName"                : this.state.sName,
            "EmailAddress"              : this.state.emailAddress,
            "MobileNumber"              : this.state.mobileNumber,
            "HomePhoneNumber"           : this.state.hpNumber,
            "Facebook"                  : this.state.facebook,
            "Twitter"                   : this.state.twitter,
            "NationalityId"             : this.state.nationalityId,
            "ReligionId"                : this.state.religionId,
            "Gender"                    : this.state.genderValue,
            "CivilStatus"               : this.state.civilStatusValue,
            "DateOfBirth"               : this.FormatDate(this.state.dateOfBirth),
            "AgeYear"                   : this.state.ageYear,
            "AgeMonth"                  : this.state.ageMonth,
            "PlaceOfBirth"              : this.state.placeOfBirth,
            "Height"                    : this.state.height,
            "Weight"                    : this.state.weight,
            "SpecialSkills"             : this.state.sSkills,
            "LanguageSpoken"            : this.state.lSpoken,
            "EmergencyContactName"      : this.state.ecName,
            "EmergencyContactRelation"  : this.state.relationship,
            "EmergencyContactNumber"    : this.state.ecNumber,
            "Hobbies"                   : "",
            "TINNumber"                 : this.state.tin,
            "SSSNumber"                 : this.state.sss,
            "PHICNumber"                : this.state.phic,
            "HDMFNumber"                : this.state.pagibig,
            "LivingArrangementId"       : this.state.arrangementId,
            "BloodTypeId"               : this.state.bloodtypeId,

            "applicantAddresses" :[{
                "TypeId"      : this.state.typeId,
                "RegionId"    : this.state.regionId,
                "Region"      : this.state.regionName,
                "ProvinceId"  : this.state.provinceId,
                "Province"    : this.state.provinceName,
                "CityId"      : this.state.cityId,
                "City"        : this.state.cityName,
                "HouseNumber" : this.state.houseNumber,
                "StreetName"  : this.state.street,
                "Barangay"    : this.state.barangay,
                "PostalCode"  : this.state.postalCode,
            }],
            "educationAttainments"  : neweducationGrid,
            "employmentHistories"   : newemploymentRecordGrid,
            "familyBackgrounds"     : newfamilyBackgroundGrid,
            "others"                : newothersGrid,
            "characterReferences"   : newreferenceGrid,
        }
        
        let editOK = "0"
        let idOK = "0"
        let resumeOK = "0"

        let editMsg = ""
        let idMsg = ""
        let resumeMsg = ""
        let id = ""
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/AddApplicationForm",  params )
        .then(res => {
            const data = res.data;

            if(data.status=="1"){
                editOK = "1"
                editMsg = data.message
                id = data.applicationFormId
            }
            else {
                editOK = "0"
                editMsg = data.message
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: data.message,
                    fade:true
                });
                return
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
            return
        })

        const formData = new FormData();
        formData.append('IpAddress', "0.0.0.0");
        formData.append('ClientId', this.state.clientId);
        formData.append('UserId', this.state.userinfo.userId);
        formData.append('TypeId', "0");
        formData.append('ApplicationFormId', id);
        formData.append('File', this.state.fileImage);
        
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/UploadFile",  formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(res => {
            const data = res.data;
            console.log("ID")
            console.log(data)
            idOK = data.status
            idMsg = data.message
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
            idOK = "0"
            idMsg = this.state.message
            return
        })
        
        var formData1 = new FormData();
        formData1.append('IpAddress', "0.0.0.0");
        formData1.append('ClientId', this.state.clientId);
        formData1.append('UserId', this.state.userinfo.userId);
        formData1.append('TypeId', "1");
        formData1.append('ApplicationFormId', id);
        formData1.append('File', this.state.uploadResume);
        
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/UploadFile",  formData1, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(res => {
            const data = res.data;
            console.log("resume")
            console.log(data)
            resumeOK = data.status
            resumeMsg = this.state.message
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
            resumeOK = "0"
            resumeMsg = this.state.message
            return
        })
        console.log("0")
        console.log("idOK")
        console.log(idOK)
        console.log("resumeOK")
        console.log(resumeOK)
        if(idOK=="1" && resumeOK=="1"){
            console.log("1")
            this.setState({
                isloading   :   false,
                alerttype   :   "Success!",
                isshow      :   true,
                color       :   "success",
                message     :   editMsg,
                fade        :   true,
                checkDisclaimer : false,
                disablebutton : true,
                disablebuttonsubmit : true,
                navigate    :   true,
            });
        }
        if(idOK=="0" && resumeOK=="1"){
            console.log("2")
            this.setState({
                isloading   :   false,
                alerttype   :   "Success!",
                isshow      :   true,
                color       :   "info",
                message     :   "Record successfully saved, but with error upon uploading of ID PICTURE.",
                fade        :   true,
                checkDisclaimer : false,
                disablebutton : true,
                disablebuttonsubmit : true,
                navigate    :   true,
            });
        }
        if(idOK==="1" && resumeOK==="0"){
            console.log("3")
            this.setState({
                isloading   :   false,
                alerttype   :   "Success!",
                isshow      :   true,
                color       :   "info",
                message     :   "Record successfully saved, but with error upon uploading of Resume.",
                fade        :   true,
                checkDisclaimer : false,
                disablebutton : true,
                disablebuttonsubmit : true,
                navigate    :   true,
            });
        }
    };

    handleNickNameDoubleSpaces = (e) =>{
        var theEvent = e || window.event;
        //var key = theEvent.keyCode || theEvent.which;
        if(this.state.nickNamekeycode === "" && e.which === 32){
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }

        if(this.state.nickNamekeycode === 32 && e.which === 32){
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }
        this.state.nickNamekeycode = e.which
    }
    handleFirstNameDoubleSpaces = (e) =>{
        var theEvent = e || window.event;
        //var key = theEvent.keyCode || theEvent.which;
        if(this.state.firstNamekeycode === "" && e.which === 32){
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }

        if(this.state.firstNamekeycode === 32 && e.which === 32){
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }
        this.state.firstNamekeycode = e.which
    }
    handleMiddleNameDoubleSpaces = (e) =>{
        var theEvent = e || window.event;
        //var key = theEvent.keyCode || theEvent.which;
        if(this.state.middleNamekeycode === "" && e.which === 32){
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }

        if(this.state.middleNamekeycode === 32 && e.which === 32){
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }
        this.state.middleNamekeycode = e.which
    }
    handleLastNameDoubleSpaces = (e) =>{
        var theEvent = e || window.event;
        //var key = theEvent.keyCode || theEvent.which;
        if(this.state.lastNamekeycode === "" && e.which === 32){
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }

        if(this.state.lastNamekeycode === 32 && e.which === 32){
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }
        this.state.lastNamekeycode = e.which
    };

    fileChangedHandler(event) {
        
        var fileInput = false;
        if (event.target.files[0]) {
          fileInput = true;
        }
        if (fileInput) {
          try {
            this.setState({fileImage: event.target.files[0]});
            Resizer.imageFileResizer(
              event.target.files[0],
              1,
              200,
              "JPEG",
              100,
              0,
              (uri) => {
                console.log(uri);
                this.setState({ newImage: uri, newImageShow:true, photoLabel:false });
              },
              "base64",
              200,
              200
            );
          } catch (err) {
            console.log(err);
          }
        }
    };

    //w function application history
    onChangewsd = date => {
        console.log("newStartDate")
        console.log(date)
       this.setState({wstartdate : date})
        // thi
        // setStartDate(newStartDate);
        // setEndDate(newEndDate);
    };
    wenddate = date => {
        console.log("newendDate")
        console.log(date)
        this.setState({wstartdate : date})
       
        // thi
        // setStartDate(newStartDate);
        // setEndDate(newEndDate);
    };
    handleChangeClientAH=(e)=>{
        if(e.length == 0) {
            this.state.clientIdAH     =   ""
            this.state.clientIdAH   =   ""
            return
        }
        this.state.clientIdAH     =   e[0].id
        this.state.clientNameAH   =   e[0].name
    };
    handleChangePositionAH = (e) => {
        if(e.length == 0) {
            this.state.positionIdAH     =   ""
            this.state.positionNameAH   =   ""
            return
        }
        this.state.positionIdAH     =   e[0].id
        this.state.positionNameAH   =   e[0].name
    };
    onChangeRefNoAF = (e) => {
        this.setState({ refNoAF: e.target.value,isshow:false });
    };
    handleChangeDateAppliedAF = date => {
        this.setState({dateAppliedAF: date,isshow:false,})
    };
    onChangeRemarks = (e) => {
        this.setState({ remarksAF: e.target.value,isshow:false });  
    };
    handleAddApplicationHistory = (e) =>{
        let a = this.state.applicationHistoryGrid
        let today = new Date()
        let newId = moment(today).format('HHMMSS');
        let b = moment(this.state.dateAppliedAF).format("MM/DD/YYYY");
        const obj = {
            "id"            :   newId.toString(),
            "clientName"    :   this.state.clientNameAH,
            "positionAF"      :   this.state.positionNameAH,
            "referenceNo"   :   this.state.refNoAF,
            "dateApplied"   :   this.state.dateAppliedAF,
            "formatedDate" : b,
            "remarks"       :   this.state.remarksAF,
            "isModified"    :   "0",
            "isDeleted"     :   "0",  
        };
        a.push(obj) 
        console.log(a)
        this.setState({
            applicationHistoryGrid : a,  
            isshow:false,
            clientNameAH : "",
            positionNameAH : "",
            refNoAF:"",
            dateAppliedAF:"",
            remarksAF : "",
        })

    };

    handleRemoveApplicationHistory = (e) =>{
        let applicationHistoryGrid = this.state.applicationHistoryGrid.filter(x=>x.isDeleted=="0")
        this.state.employmentRecordGrid = []
        this.setState({applicationHistoryGrid:applicationHistoryGrid,disableRemoveEmploymentRecord: applicationHistoryGrid.length===0 ? true : false})
    };
    fileUploadChangedHandler=(event)=>{
        var fileInput = false;
        if (event.target.files[0]) {
          fileInput = true;
        }

        console.log("event.target.files[0]")
        console.log(event.target.files[0])


        
        this.setState({ uploadResume : event.target.files[0]})
    }
    //w function application history
    
    //w grid function 
    autofillEducation=(i)=>{
        let a = this.state.educationGrid
        let b = this.state.levelDDLtwo
        
        b.forEach(element => {
           //element.id = (tmp+1).toString()
            element.schoolName = this.state.schoolName
            element.course        =   this.state.course
            element.startYear     =   this.state.startYear
            element.endYear       =   this.state.endYear
            element.honorRecieved =   this.state.honorsAward
            element.contactPerson =   ""
            element.contactNumber =   ""
        });

        
        console.log("autofillEducation")
        console.log(b)
        this.setState({educationGrid : b }) 
        this.autofillFamilyBackground()
    };
    autofillFamilyBackground=()=>{
        let a = this.state.familyBackgroundGrid
        let b = this.state.roleDDLtwo
        let newId = moment(new Date()).format('HHMMSS');
        b.forEach((itm,idx) => {
            itm.id = (newId + (idx+1)).toString()
            itm.name = this.state.fbName
            itm.contactNumber = this.state.fbCNumber
            itm.age = this.state.fbAge
            itm.occupation = this.state.fbOccupation
            itm.company = this.state.fbCOSchool
            itm.userId = this.state.userinfo.userId
            itm.role = this.state.role
            itm.isDeleted = "0"
         })
         console.log("asdasdasd")
         console.log(b)
         
         this.setState({familyBackgroundGrid : b }) 
    }
    //w grid function 
    handleValidate = async()=>{
        this.setState({alerttype:'',isshow:false,color:'',message:'',fade:true,isloading:true})
        const param ={
            "IpAddress"                 : "0.0.0.0",
            "ClientId"                  : this.state.clientId,
            "UserId"                    : this.state.userinfo.userId,
            "FirstName"                 : this.state.fName,
            "NickName"                  : this.state.nName,
            "MiddleName"                : this.state.mName,
            "LastName"                  : this.state.lName,
            "DateOfBirth"               : this.FormatDate(this.state.dateOfBirth),
            "TINNumber"                 : this.state.tin,
            "SSSNumber"                 : this.state.sss,
            "PHICNumber"                : this.state.phic,
            "HDMFNumber"                : this.state.pagibig,
        }

        await axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Recruitment/ValidateApplicant",  param
            )
            .then(res => {
                const data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true,
                    name        :   ''
                });
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true,
                    name        :   ''
                })
            })
    }
    IsValidMobile(val){
        var regex = /^0(9|4)\d{9}$/;
        return !regex.test(val)
    }
    ValidateMobileNumber = (e) =>{
        this.setState({isloading:true})
        if(this.IsValidMobile(e.target.value)){
            alert(e.target.name==="mobileNumber" ? "Mobile number should start with 09 followed by 9 digits." : "Emergency contact number should start with 09 followed by 9 digits.")
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: e.target.name==="mobileNumber" ? "Mobile number should start with 09 followed by 9 digits." : "Emergency contact number should start with 09 followed by 9 digits.",
                fade:true
            });
            return
        }
        this.setState({isloading:false})
    }
    render() {
        const { navigate } = this.state;
        if (navigate) {
            return <Redirect to="/sourcing" push={true} />;
        } 

        const educationColumn = [
            {
                dataField: 'levelId',
                text: 'Level',
                headerStyle: () => {
                    return { width: "15%" };
                },
                formatter: (cell, row) => {
                    if(row.levelId!='' && row.levelId!=null){
                        return this.state.tblLevelArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblLevelArrLst
                }
            },
            {
                dataField: 'schoolName',
                text: 'School Name',
                ediatable : false,
                headerStyle: () => {
                    return { width: "30%" };
                  }
            },
            {
                dataField: 'course',
                text: 'Course',
                headerStyle: () => {
                    return { width: "20%" };
                  }
            },
            {
                dataField: 'startYear',
                text: 'Start Year',
                headerStyle: () => {
                    return { width: "10%" };
                  }
            },
            {
                dataField: 'endYear',
                text: 'End Year',
                headerStyle: () => {
                    return { width: "10%" };
                  }
            },
            {
                dataField: 'honorRecieved',
                text: 'Honor Recieved',
                headerStyle: () => {
                    return { width: "15%" };
                  }
            },
        ]

        const educationSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.educationGrid.map(function(item,i){
                    if(item.id===row.id)
                        item.isDeleted = isSelect ? "1" : "0"
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableRemoveEducation:isDisable})
            },
            onSelectAll: (isSelect, rows) => {
                let isDisable = true
                this.state.educationGrid.map(function(itm){
                    itm.isDeleted=isSelect ? "1" : "0"
                    if(itm.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableRemoveEducation:isDisable})
            }
        };

        const employmentRecordColumn = [
            {
                dataField: 'companyName',
                text: 'Company_Name',
                headerStyle: () => {
                    return { width: "20%" };
                }
                
            },
            {
                dataField: 'companyAddress',
                text: 'Company_Address',
                headerStyle: () => {
                    return { width: "20%" };
                }
            },
            {
                dataField: 'position',
                text: 'Position',
                headerStyle: () => {
                    return { width: "20%" };
                }
            },
            /* {
                dataField: 'periodCovered',
                text: 'Period_Covered',
                headerStyle: () => {
                    return { width: "20%" };
                }
            }, */
            {
                dataField: 'fromStart',
                text: 'from start',
                headerStyle: () => {
                    return { width: "10%" };
                }
            },
            {
                dataField: 'toEnd',
                text: 'to end',
                headerStyle: () => {
                    return { width: "10%" };
                }
            },
            {
                dataField: 'reasonForLeaving',
                text: 'reason for leaving',
                headerStyle: () => {
                    return { width: "25%" };
                }
            },
            {
                dataField: 'salary',
                text: 'Salary',
                headerStyle: () => {
                    return { width: "20%" };
                },
                style:{textAlign:'right',whiteSpace:'nowrap'}
            },
        ]

        const employmentRecordSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.employmentRecordGrid.map(function(item,i){
                    if(item.id===row.id)
                        item.isDeleted = isSelect ? "1" : "0"
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableRemoveEmploymentRecord:isDisable})
            },
            onSelectAll: (isSelect, rows) => {
                let isDisable = true
                this.state.employmentRecordGrid.map(function(itm){
                    itm.isDeleted=isSelect ? "1" : "0"
                    if(itm.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableRemoveEmploymentRecord:isDisable})
            }
        };

        const applicationHistorySelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.applicationHistoryGrid.map(function(item,i){
                    if(item.id===row.id)
                        item.isDeleted = isSelect ? "1" : "0"
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableRemoveEmploymentRecord:isDisable})
            },
            onSelectAll: (isSelect, rows) => {
                let isDisable = true
                this.state.applicationHistoryGrid.map(function(itm){
                    itm.isDeleted=isSelect ? "1" : "0"
                    if(itm.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableRemoveEmploymentRecord:isDisable})
            }
        };

        

        const familyBackgroundColumn = [
            {
                dataField: 'roleId',
                text: 'Role',
                headerStyle: () => {
                    return { width: "15%" };
                },
                formatter: (cell, row) => {
                    if(row.roleId!='' && row.roleId!=null){
                        return this.state.tblRoleArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblRoleArrLst
                },
                validator: (newValue, row, column) => {
                    
                    if(this.state.familyBackgroundGrid.filter(x=>x.roleId===newValue).length>0 && newValue==="0")
                    return {
                        valid: false,
                        message:"Cannot set multiple for role father."
                      };
                    if(this.state.familyBackgroundGrid.filter(x=>x.roleId===newValue).length>0 && newValue==="1")
                    return {
                        valid: false,
                        message:"Cannot set multiple for role mother."
                    };
                    if(this.state.familyBackgroundGrid.filter(x=>x.roleId===newValue).length>0 && newValue==="2")
                    return {
                        valid: false,
                        message:"Cannot set multiple for role spouse."
                    };
                    return true;   
                }
            },
            {
                dataField: 'name',
                text: 'Name',
                headerStyle: () => {
                    return { width: "20%" };
                },
                editCellStyle: {
                  fontWeight: 'bold'
                }
            },
            {
                dataField: 'contactNumber',
                text: 'Contact Number',
                headerStyle: () => {
                    return { width: "15%" };
                },
                validator: (newValue, row, column) => {
                    if(this.IsValidMobile(newValue))
                    return {
                        valid: false,
                        message:'Contact number should start with 09 followed by 9 digits.'
                      };
                    return true;
                },
            },
            {
                dataField: 'age',
                text: 'Age',
                headerStyle: () => {
                    return { width: "10%" };
                    }
            },
            {
                dataField: 'occupation',
                text: 'Occupation',
                headerStyle: () => {
                    return { width: "20%" };
                }
            },
            {
                dataField: 'company',
                text: 'Company',
                headerStyle: () => {
                    return { width: "20%" };
                }
            },
        ]

        const familyBackgroundSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.familyBackgroundGrid.map(function(item,i){
                    if(item.id===row.id)
                        item.isDeleted = isSelect ? "1" : "0"
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableBackground:isDisable})
            },
            onSelectAll: (isSelect, rows) => {
                let isDisable = true
                this.state.familyBackgroundGrid.map(function(itm){
                    itm.isDeleted=isSelect ? "1" : "0"
                    if(itm.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableBackground:isDisable})
            }
        };

        const othersColumn = [
            {
                dataField: 'jobSourceId',
                text: 'Job Opening',
                headerStyle: () => {
                    return { width: "10%" };
                },
                formatter: (cell, row) => {
                    if(row.jobSourceId!='' && row.jobSourceId!=null){
                        return this.state.tblJobOpeningArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblJobOpeningArrLst
                }
                /* headerStyle: () => {
                    return { width: "10%" };
                },
                formatter: (cell, row) => {
                    if(row.roleId!='' && row.roleId!=null){
                        return this.state.tblRoleArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblRoleArrLst
                } */
            },
            {
                dataField: 'refferedBy',
                text: 'Preffered By',
                headerStyle: () => {
                    return { width: "15%" };
                    }
            },
            /* {
                dataField: 'presentlyConnected',
                text: 'Presently Connected',
                headerStyle: () => {
                    return { width: "5%" };
                    }
            }, */
            {
                dataField: 'relativeName',
                text: 'Name',
                headerStyle: () => {
                    return { width: "15%" };
                    }
            },
            {
                dataField: 'relativeRelation',
                text: 'Relationship',
                headerStyle: () => {
                    return { width: "10%" };
                    }
            },
            {
                dataField: 'relativePosition',
                text: 'Job Title',
                headerStyle: () => {
                    return { width: "10%" };
                    }
            },
            {
                dataField: 'contactNumber',
                text: 'Contact Number',
                headerStyle: () => {
                    return { width: "10%" };
                    }
            },
            /* {
                dataField: 'convictedWithACrime',
                text: 'Convicted With A Crime',
                headerStyle: () => {
                    return { width: "5%" };
                    }
            }, */
            {
                dataField: 'crimeDescription',
                text: 'Give Details Crime',
                headerStyle: () => {
                    return { width: "10%" };
                    }
            },
            /* {
                dataField: 'labourUnion',
                text: 'Member Of A Labour Union',
                headerStyle: () => {
                    return { width: "5%" };
                    }
            }, */
            {
                dataField: 'laborUnionDescription',
                text: 'Give Details Union',
                headerStyle: () => {
                    return { width: "10%" };
                    }
            },
            {
                dataField: 'diagnoseDeseaseId',
                text: 'Suffered or Diagnosed',
                headerStyle: () => {
                    return { width: "10%" };
                },
                formatter: (cell, row) => {
                    if(row.diagnoseDeseaseId!='' && row.diagnoseDeseaseId!=null){
                        return this.state.tblDiseaseArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblDiseaseArrLst
                }
            },
        ]

        const othersSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.othersGrid.map(function(item,i){
                    if(item.id===row.id)
                        item.isDeleted = isSelect ? "1" : "0"
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableOthers:isDisable})
            },
            onSelectAll: (isSelect, rows) => {
                let isDisable = true
                this.state.othersGrid.map(function(itm){
                    itm.isDeleted=isSelect ? "1" : "0"
                    if(itm.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableOthers:isDisable})
            }
        };

        const referenceColumn = [
            {
                dataField: 'typeId',
                text: 'type of character eference',
                headerStyle: () => {
                    return { width: "15%" };
                },
                formatter: (cell, row) => {
                    if(row.typeId!='' && row.typeId!=null){
                        return this.state.tbltypeofcharacterreferenceArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tbltypeofcharacterreferenceArrLst
                }
            },
            {
                dataField: 'name',
                text: 'Name',
                headerStyle: () => {
                    return { width: "25%" };
                  }
            },
            {
                dataField: 'contactNumber',
                text: 'Contact Number',
                headerStyle: () => {
                    return { width: "20%" };
                  },
                validator: (newValue, row, column) => {
                    if(this.IsValidMobile(newValue))
                    return {
                        valid: false,
                        message:'Contact number should start with 09 followed by 9 digits.'
                      };
                    return true;
                },
            },
            {
                dataField: 'occupation',
                text: 'Occupation',
                headerStyle: () => {
                    return { width: "25%" };
                  }
            },
            {
                dataField: 'company',
                text: 'Company',
                headerStyle: () => {
                    return { width: "30%" };
                  }
            },
        ]
        
        const APPLICATIONHISTORYColumn = [
            {
                dataField: 'clientName',
                text: 'Client Name',
                headerStyle: () => {
                    return { width: "25%" };
                }
                
            },
            {
                dataField: 'positionAF',
                text: 'Position',
                headerStyle: () => {
                    return { width: "25%" };
                }
            },
            {
                dataField: 'referenceNo',
                text: 'reference No',
                headerStyle: () => {
                    return { width: "25%" };
                }
            },
            {
                dataField: 'formatedDate',
                text: 'date Applied',
                headerStyle: () => {
                    return { width: "25%" };
                },
                
            },
            
        ]

        const referenceSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.referenceGrid.map(function(item,i){
                    if(item.id===row.id)
                        item.isDeleted = isSelect ? "1" : "0"
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableReference:isDisable})
            },
            onSelectAll: (isSelect, rows) => {
                let isDisable = true
                this.state.referenceGrid.map(function(itm){
                    itm.isDeleted=isSelect ? "1" : "0"
                    if(itm.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableReference:isDisable})
            }
        };


        return(
            <div>
                <Banner />
                    <Container  className="mt-3" fluid>
                        <Card>
                            <Card.Header>RECRUITMENT >> APPLICATION FORM (CREATE)</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                            <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                            <Typeahead 
                                                ref='clientref'
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangeClient}
                                                options={this.state.clientDDL}
                                                placeholder="Select Client" 
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="mt-2">
                                        <Form.Group as={Col} controlId="formGridCity">
                                            <Form.Label style={{fontWeight : "bold"}}>POSITION APPLIED</Form.Label>
                                            <Typeahead
                                                ref='positionref'
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangePosition}
                                                options={this.state.positionDDL}
                                                placeholder="Select Position" 
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                            <Form.Label style={{fontWeight : "bold", marginLeft : 15}}>DATE APPLIED</Form.Label>
                                            <Form.Group as={Col} controlId="formGridCity">
                                            <DatePicker
                                                ref='dateApplied'
                                                selected={this.state.dateApplied}
                                                onChange={this.handleChangeDateApplied}
                                                minDate={this.minDate}
                                                value={this.props.dateApplied}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                wrapperClassName="datepicker"
                                                placeholderText="Select Date Applied" 
                                            />
                                            </Form.Group>
                                        </Form.Group>
                                    </Form.Row>
                                    <Tabs className="mt-2" defaultActiveKey="default" transition={false} id="noanim-tab-example">
                                        <Tab eventKey="default" title="I. GENERAL INFORMATION">
                                            <Card className="card-tab-no-border">
                                                <Card.Body>
                                                    
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold"}}></Form.Label>
{/*                                                             <Form.Control
                                                                as="select"
                                                                onChange={this.handleChangeTitle}
                                                                value={this.state.titleValue}
                                                            >
                                                            {this.state.titleDDL.map(item => (
                                                                <option key={item.value} value={item.value}>
                                                                {item.name}
                                                                </option>
                                                            ))}
                                                            </Form.Control> */}
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <input type="file" name='newImage' onChange={this.fileChangedHandler} />
                                                            
                                                            <div className='mt-2  photoAFC'>
                                                                
                                                                    <div className='aa'>
                                                                        <div /* isOpen={this.state.isshow} */ color={this.state.color} fade={this.state.fade} className={this.state.photoLabel ? 'display-block' : 'display-none'}>
                                                                            <h1 className='ml-5 mt-5 fontcolorwhite'>2 X 2 </h1>
                                                                            <Label className='ml-5 mt-5 photolabelAFC'>2 X 2 photo</Label>
                                                                            <h1  className='ml-5 mt-5 fontcolorwhite'> 2 X 2</h1>
                                                                        </div>
                                                                        <div /* isOpen={this.state.isshow} */ color={this.state.color} fade={this.state.fade} className={this.state.newImageShow ? 'display-block' : 'display-none'}>
                                                                            <img /* className='photoAdjust' */ src={this.state.newImage} alt="" />
                                                                        </div>
                                                                    </div>
                                                                
                                                            </div>
                                                        </Form.Group>
                                                    </Form.Row>

                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold"}}>TITLE</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.handleChangeTitle}
                                                                value={this.state.titleValue}
                                                            >
                                                            {this.state.titleDDL.map(item => (
                                                                <option key={item.value} value={item.value}>
                                                                {item.name}
                                                                </option>
                                                            ))}
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>NICK NAME</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="nName"
                                                                value={this.state.nName}
                                                                onChange={this.onChangeNickName} 
                                                                autoComplete="off"
                                                                placeholder="Enter Nick name" 
                                                                onKeyPress={this.handleNickNameDoubleSpaces}
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>FIRST NAME</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="fName"
                                                                value={this.state.fName}
                                                                onChange={this.onChangeFirstName} 
                                                                autoComplete="off"
                                                                placeholder="Enter First name" 
                                                                onKeyPress={this.handleFirstNameDoubleSpaces}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>MIDDLE NAME</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="mName"
                                                                value={this.state.mName}
                                                                onChange={this.onChangeMiddleName} 
                                                                autoComplete="off"
                                                                placeholder="Enter Middle name" 
                                                                onKeyPress={this.handleMiddleNameDoubleSpaces}
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>LAST NAME</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="lName"
                                                                value={this.state.lName}
                                                                onChange={this.onChangeLastName} 
                                                                autoComplete="off"
                                                                placeholder="Enter Last name" 
                                                                onKeyPress={this.handleLastNameDoubleSpaces}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>SUFFIX NAME</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.onChangeSuffix}
                                                                value={this.state.sName}
                                                            >
                                                            {this.state.suffixDDL.map(item => (
                                                                <option key={item.value} value={item.value}>
                                                                {item.name}
                                                                </option>
                                                            ))}
                                                            </Form.Control>
                                                            {/* <Form.Control 
                                                                type="text"
                                                                name="sName"
                                                                value={this.state.sName}
                                                                onChange={this.onChangeSuffixName} 
                                                                autoComplete="off"
                                                                placeholder="Enter Suffix name" 
                                                            /> */}
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold", marginLeft : 15}}>DATE OF BIRTH</Form.Label>
                                                            <Form.Group as={Col} controlId="formGridCity">
                                                                <DatePicker
                                                                    ref='dateOfBirth'
                                                                    selected={this.state.dateOfBirth}
                                                                    onChange={this.handleChangeDateOfBirth}
                                                                    minDate={this.minDate}
                                                                    value={this.props.dateOfBirth}
                                                                    dateFormat={"MM/dd/yyyy"}
                                                                    placeholderText="Select Date Of Birth "
                                                                    className="form-control"
                                                                    wrapperClassName="datepicker"
                                                                    //showMonthDropdown
                                                                    //showYearDropdown
                                                                    //dropdownMode= "scroll"
                                                                    
                                                                />
                                                            </Form.Group>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold"}}>AGE</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="totalAge"
                                                                value={this.state.totalAge}
                                                                onChange={this.onChangeAgeMonth.bind(this)}
                                                                autoComplete="off"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>SSS #</Form.Label>
                                                                <NumberFormat
                                                                    name="sss"
                                                                    value={this.state.sss}
                                                                    onChange={this.onChangeSSS} 
                                                                    autoComplete="off"
                                                                    format="##-#######-#"
                                                                    className="form-control"
                                                                    placeholder="Enter SSS Number"
                                                                />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>TIN #</Form.Label>
                                                            <NumberFormat
                                                                name="tin"
                                                                value={this.state.tin}
                                                                onChange={this.onChangeTin} 
                                                                autoComplete="off"
                                                                format="###-###-###-###"
                                                                className="form-control"
                                                                placeholder="Enter TIN Number"
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>PHILHEALTH #</Form.Label>
                                                            <NumberFormat
                                                                name="phic"
                                                                value={this.state.phic}
                                                                onChange={this.onChangePhilHealth} 
                                                                autoComplete="off"
                                                                format="##-#########-#"
                                                                className="form-control"
                                                                placeholder="Enter PHILHEALTH Number"
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>PAGIBIG #</Form.Label>
                                                            <NumberFormat
                                                                name="pagibig"
                                                                value={this.state.pagibig}
                                                                onChange={this.onChangePagibig} 
                                                                autoComplete="off"
                                                                format="####-####-####"
                                                                className="form-control"
                                                                placeholder="Enter PAGIBIG Number"
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                    <ButtonToolbar >
                                                        <Button variant="success" style={{width:'100%'}} onClick = {() => this.handleValidate()}>Check Applicant Name / Mandatory Number</Button>
                                                    </ButtonToolbar>
                                                    </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>TYPE</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.handleChangeType}
                                                                value={this.state.typeId}
                                                            >
                                                            {this.state.typeDDL.map(item => (
                                                                <option key={item.value} value={item.value}>
                                                                {item.name}
                                                                </option>
                                                            ))}
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>REGION</Form.Label>
                                                                <Typeahead
                                                                    ref='regionref'
                                                                    labelKey='name'
                                                                    id="basic-example"
                                                                    onChange={this.handleChangeRegion}
                                                                    options={this.state.regionDDL}
                                                                    placeholder="Select Region"
                                                                />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>PROVINCE</Form.Label>
                                                                <Typeahead
                                                                    ref='provinceref'
                                                                    labelKey='name'
                                                                    id="basic-example"
                                                                    onChange={this.handleChangeProvince}
                                                                    options={this.state.provinceDDL}
                                                                    placeholder="Select Province"
                                                                />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>CITY/MUNICIPALITY</Form.Label>
                                                                <Typeahead    
                                                                    ref='cityref'                              
                                                                    labelKey='name'
                                                                    id="basic-examplex"
                                                                    onChange={this.handleChangeCity}
                                                                    options={this.state.cityDDL}
                                                                    placeholder="Select City"
                                                                />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>HOUSE NUMBER</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="houseNumber"
                                                                value={this.state.houseNumber}
                                                                placeholder="Enter House Number"
                                                                onChange={this.onChangeHouseNumber} 
                                                                autoComplete="off"  
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>STREET</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="street"
                                                                value={this.state.street}
                                                                placeholder="Enter Street"
                                                                onChange={this.onChangeStreet} 
                                                                autoComplete="off"  
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>BARANGAY</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="barangay"
                                                                value={this.state.barangay}
                                                                placeholder="Enter barangay"
                                                                onChange={this.onChangeBarangay} 
                                                                autoComplete="off"  
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>POSTAL CODE</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="postalCode"
                                                                value={this.state.postalCode}
                                                                placeholder="Enter Postal Code"
                                                                onChange={this.onChangePostalCode} 
                                                                autoComplete="off"  
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>HOME PHONE NUMBER</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="hpNumber"
                                                                value={this.state.hpNumber}
                                                                placeholder="Enter Home Phone Number"
                                                                onChange={this.onChangeHPNumber} 
                                                                autoComplete="off"        
                                                                maxLength="8"  
                                                            />
                                                            {/* <NumberFormat
                                                                name="hpNumber"
                                                                value={this.state.hpNumber}
                                                                onChange={this.onChangeHPNumber} 
                                                                autoComplete="off"
                                                                format="####-####"
                                                                className="form-control"
                                                                placeholder="Enter Home Phone Number"
                                                            /> */}
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>OFFICE PHONE NUMBER</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="opNumber"
                                                                value={this.state.opNumber}
                                                                placeholder="Enter Office Phone Number"
                                                                onChange={this.onChangeOPNumber} 
                                                                autoComplete="off"        
                                                                maxLength="8"   
                                                            />
                                                            {/* <NumberFormat
                                                                name="opNumber"
                                                                value={this.state.opNumber}
                                                                onChange={this.onChangeOPNumber} 
                                                                autoComplete="off"
                                                                format="####-####"
                                                                className="form-control"
                                                                placeholder="Enter Office Phone Number"
                                                            /> */}
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>MOBILE NUMBER</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="mobileNumber"
                                                                value={this.state.mobileNumber}
                                                                placeholder="Enter Mobile Number"
                                                                onChange={this.onChangeMobileNumber} 
                                                                autoComplete="off"          
                                                                maxLength="11"
                                                                //onKeyPress={this.IsNumeric.bind(this)}
                                                                onBlur={this.ValidateMobileNumber}
                                                            />
                                                            {/* <NumberFormat
                                                                name="mobileNumber"
                                                                value={this.state.mobileNumber}
                                                                onChange={this.onChangeMobileNumber} 
                                                                autoComplete="off"
                                                                format="####-####-###"
                                                                className="form-control"
                                                                placeholder="Enter Mobile Number"
                                                            /> */}
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formBasicEmail">
                                                            <Form.Label style={{fontWeight : "bold"}}>EMAIL ADDRESS</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="emailAddress"
                                                                value={this.state.emailAddress}
                                                                placeholder="Enter Email Address"
                                                                onChange={this.onChangeEmailAddress} 
                                                                autoComplete="off"
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>FACEBOOK</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="facebook"
                                                                value={this.state.facebook}
                                                                placeholder="Enter Facebook"
                                                                onChange={this.onChangeFacebook} 
                                                                autoComplete="off"
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>TWITTER</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="twitter"
                                                                value={this.state.twitter}
                                                                placeholder="Enter Twitter"
                                                                onChange={this.onChangeTwitter} 
                                                                autoComplete="off"
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold"}}>CITIZENSHIP</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.handleChangeCitizenship}
                                                                value={this.state.nationalityId}
                                                            >
                                                            {this.state.nationalityDDL.map(item => (
                                                                <option key={item.value} value={item.value}>
                                                                {item.name}
                                                                </option>
                                                            ))}
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold"}}>RELIGION</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.handleChangeReligion}
                                                                value={this.state.religionId}
                                                            >
                                                            {this.state.religionDDL.map(item => (
                                                                <option key={item.value} value={item.value}>
                                                                {item.name}
                                                                </option>
                                                            ))}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold"}}>GENDER</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.handleChangeGender}
                                                                value={this.state.genderValue}
                                                            >
                                                            {this.state.genderDDL.map(item => (
                                                                <option key={item.value} value={item.value}>
                                                                {item.name}
                                                                </option>
                                                            ))}
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold"}}>CIVIL STATUS</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.handleChangeCivilStatus}
                                                                value={this.state.civilStatusValue}
                                                            >
                                                            {this.state.civilStatusDDL.map(item => (
                                                                <option key={item.value} value={item.value}>
                                                                {item.name}
                                                                </option>
                                                            ))}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Form.Row> 
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>LIVING ARRANGEMENT</Form.Label>
                                                            <Typeahead
                                                                labelKey='name'
                                                                id='arrangementId'
                                                                name='arrangementId'
                                                                options={this.state.arrangementDDL}
                                                                onChange={this.handleChangedArrangement}
                                                                placeholder="Select Living Arrangment"
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>BLOOD TYPE</Form.Label>
                                                            <Typeahead
                                                                labelKey='name'
                                                                id='bloodtypeId'
                                                                name='bloodtypeId'
                                                                options={this.state.bloodtypeDDL}
                                                                onChange={this.handleChangedBloodType}
                                                                placeholder="Select Blood Type"
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>PLACE OF BIRTH</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="placeOfBirth"
                                                                value={this.state.placeOfBirth}
                                                                placeholder="Enter Place Of Birth"
                                                                onChange={this.onChangePlaceOfBirth} 
                                                                autoComplete="off"
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>HEIGHT</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="height"
                                                                value={this.state.height}
                                                                placeholder="Enter Height"
                                                                onChange={this.onChangeHeight} 
                                                                autoComplete="off"
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>WEIGHT</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="weight"
                                                                value={this.state.weight}
                                                                placeholder="Enter Weight"
                                                                onChange={this.onChangeWeight} 
                                                                autoComplete="off"
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>SPECIAL SKILLS</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="sSkills"
                                                                value={this.state.sSkills}
                                                                placeholder="Enter Special Skills"
                                                                onChange={this.onChangeSkills} 
                                                                autoComplete="off"
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>LANGUAGE SPOKEN</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="lSpoken"
                                                                value={this.state.lSpoken}
                                                                placeholder="Enter Language Spoken"
                                                                onChange={this.onChangeLSpoken} 
                                                                autoComplete="off"
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>EMERGENCY CONTACT NAME</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="ecName"
                                                                value={this.state.ecName}
                                                                placeholder="Enter Emergency Contact Name"
                                                                onChange={this.onChangeECName} 
                                                                autoComplete="off"
                                                                //maxLength="11"
                                                                //onKeyPress={this.IsNumeric.bind(this)}
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>RELATIONSHIP</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="relationship"
                                                                value={this.state.relationship}
                                                                placeholder="Enter Relationship"
                                                                onChange={this.onChangeRelationship} 
                                                                autoComplete="off"
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>EMERGENCY CONTACT NUMBER</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="ecNumber"
                                                                value={this.state.ecNumber}
                                                                placeholder="Enter Emergency Contact Number"
                                                                onChange={this.onChangeECNumber} 
                                                                autoComplete="off"         
                                                                maxLength="11" 
                                                                onBlur={this.ValidateMobileNumber}
                                                            />
                                                            {/* <NumberFormat
                                                                name="ecNumber"
                                                                value={this.state.ecNumber}
                                                                onChange={this.onChangeECNumber} 
                                                                autoComplete="off"
                                                                format="####-####-###"
                                                                className="form-control"
                                                                placeholder="Enter Emergency Contact Number"
                                                            /> */}
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold"}}>UPLOAD RESUME</Form.Label> &nbsp;&nbsp;&nbsp;
                                                            <input type="file" name='uploadResume' onChange={this.fileUploadChangedHandler} />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}></Form.Label> &nbsp;&nbsp;&nbsp;
                                                            {/* <input type="file" onChange={this.fileUploadChangedHandler} /> */}
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Card.Body>
                                            </Card>
                                        </Tab>
                                        
                                        <Tab eventKey="education" title="II. EDUCATION">
                                            <Card className="card-tab-no-border">
                                                <Card.Body>
                                                    
                                                        
                                                    {/* <ButtonToolbar className="mt-3">
                                                        <Button variant="success" onClick = { this.handleAddEducation }>Add</Button>
                                                        <Button className="noser-button-mr1" disabled={this.state.disableRemoveEducation} className="noser-button" variant="danger" onClick = { this.handleRemoveEducation }>REMOVE</Button>
                                                    </ButtonToolbar> */}
                                                    <div className="mt-1">  
                                                        <div>
                                                            <Card.Header>EDUCATION LIST</Card.Header>
                                                            <BootstrapTable
                                                                keyField = "id"
                                                                data = { this.state.educationGrid }
                                                                columns = { educationColumn }
                                                                //pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                rowClasses="noser-table-row-class"
                                                                striped
                                                                hover
                                                                condensed
                                                                cellEdit = { cellEditFactory({
                                                                    mode: 'dbclick',
                                                                    blurToSave: true,
                                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                                        this.EducationGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                        }
                                                                    })
                                                                }
                                                                selectRow = { educationSelectRow }
                                        
                                                            />
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                                
                                        </Tab>

                                        <Tab eventKey="employment" title=" III. EMPLOYMENT RECORD (START WITH LATEST EMPLOYER)">
                                            <Card className="card-tab-no-border">
                                                <Card.Body>
                                                    <Form.Row className="mt-3">
                                                        <Form.Group as={Col} controlId="formBasicCheckbox">
                                                            <Form.Check
                                                                inline 
                                                                type="checkbox" 
                                                                label="FRESH GRADUATE ?" 
                                                                name="isFreshGraduate"
                                                                checked={this.state.checkIsFreshGraduate}
                                                                onChange={this.onChangesFreshGraduate}
                                                                style={{fontWeight : "bold", marginTop : "3px"}}
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>COMPANY NAME</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="erCompanyName"
                                                                value={this.state.erCompanyName}
                                                                onChange={this.onChangeERCompanyName} 
                                                                autoComplete="off"
                                                                placeholder="Enter Company Name" 
                                                                disabled={this.state.isEmploymentDisabled}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>COMPANY ADDRESS</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="erCompanyAddress"
                                                                value={this.state.erCompanyAddress}
                                                                onChange={this.onChangeERCompanyAddress} 
                                                                autoComplete="off"
                                                                placeholder="Enter Company Address" 
                                                                disabled={this.state.isEmploymentDisabled}
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>POSITION</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="erEmptyField"
                                                                value={this.state.erPosition}
                                                                onChange={this.onChangeERPosition} 
                                                                autoComplete="off"
                                                                placeholder="Enter Position" 
                                                                disabled={this.state.isEmploymentDisabled}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group className='mt-2' as={Col} controlId="formGridCity">
                                                            <Form.Label  style={{fontWeight : "bold"}}>PERIOD COVERED</Form.Label>&nbsp;&nbsp;
                                                          
                                                            <DatePicker
                                                                    ref='fromStart'
                                                                    selected={this.state.fromStart}
                                                                    onChange={this.handleChangeFromStart}
                                                                    minDate={this.minDate}
                                                                    value={this.props.fromStart}
                                                                    dateFormat={"MM/dd/yyyy"}
                                                                    placeholderText="Select Date from "
                                                                    className="form-control"
                                                                    wrapperClassName="datepicker"
                                                                    //showMonthYearPicker
                                                                    //showMonthDropdown
                                                                    //showYearDropdown
                                                                    //dropdownMode= "scroll"
                                                                    
                                                            />&nbsp; &nbsp;
                                                            <DatePicker
                                                                    ref='toEnd'
                                                                    selected={this.state.toEnd}
                                                                    onChange={this.handleChangeToEnd}
                                                                    minDate={this.minDate}
                                                                    value={this.props.toEnd}
                                                                    dateFormat={"MM/dd/yyyy"}
                                                                    placeholderText="Select Date to "
                                                                    className="form-control"
                                                                    wrapperClassName="datepicker"
                                                                    //showMonthYearPicker
                                                                    //showMonthDropdown
                                                                    //showYearDropdown
                                                                    //dropdownMode= "scroll"
                                                                    
                                                            />
                                                            {/* <Form.Control 
                                                                type="text"
                                                                name="erPeriodCover"
                                                                value={this.state.erPeriodCover}
                                                                onChange={this.onChangeERCPeriodCover} 
                                                                autoComplete="off"
                                                                placeholder="Enter Period Cover" 
                                                                disabled={this.state.isEmploymentDisabled}
                                                            /> */}
                                                            
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col}  controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>SALARY</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="erSalary"
                                                                value={this.state.erSalary}
                                                                onChange={this.onChangeERSalary} 
                                                                autoComplete="off"
                                                                placeholder="Enter Salary" 
                                                                disabled={this.state.isEmploymentDisabled}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>Reason for leaving</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="erRFLeaving"
                                                                value={this.state.erRFLeaving}
                                                                onChange={this.onChangeERRFLeaving} 
                                                                autoComplete="off"
                                                                placeholder="Enter Reason For Leaving" 
                                                                //disabled={this.state.isEmploymentDisabled}
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    {/* <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>PasdasdRED</Form.Label>
                                                            <DatePicker
                                                                    ref='fromStart'
                                                                    selected={this.state.fromStart}
                                                                    onChange={this.handleChangeFromStart}
                                                                    minDate={this.minDate}
                                                                    value={this.props.fromStart}
                                                                    dateFormat={"MM/dd/yyyy"}
                                                                    placeholderText="Select Date Of Birth "
                                                                    className="form-control"
                                                                    wrapperClassName="datepicker"
                                                                    //showMonthDropdown
                                                                    //showYearDropdown
                                                                    //dropdownMode= "scroll"
                                                                    
                                                                />
                                                        </Form.Group> */}
                                                    <ButtonToolbar className="mt-3">
                                                        <Button className="noser-button-mr1" variant="success" onClick = { this.handleAddEmploymentRecord }>Add</Button>
                                                        <Button className="noser-button" disabled={this.state.disableRemoveEmploymentRecord} className="noser-button" variant="danger" onClick = { this.handleRemoveEmploymentRecord }>REMOVE</Button>
                                                    </ButtonToolbar>
                                                    <div className="mt-1">  
                                                        <div>
                                                            <Card.Header>EMPLOYMENT RECORDS LIST</Card.Header>
                                                            <BootstrapTable
                                                                keyField = "id"
                                                                data = { this.state.employmentRecordGrid }
                                                                columns = { employmentRecordColumn }
                                                                //pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                rowClasses="noser-table-row-class"
                                                                striped
                                                                hover
                                                                condensed
                                                                cellEdit = { cellEditFactory({
                                                                    mode: 'dbclick',
                                                                    blurToSave: true,
                                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                                        this.EmploymentGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                        }
                                                                    })
                                                                }
                                                                selectRow = { employmentRecordSelectRow }
                                        
                                                            />
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                                
                                        </Tab>


                                        <Tab eventKey="familybackground" title="IV. FAMILY BACKGROUND">
                                            <Card className="card-tab-no-border">
                                                <Card.Body>
                                                    
                                                    <ButtonToolbar className="mt-3">
                                                        <Button className="noser-button-mr1" variant="success" onClick = { this.handleAddFamilybackground }>Add</Button>
                                                        <Button className="noser-button-mr1" disabled={this.state.disableBackground} className="noser-button" variant="danger" onClick = { this.handleRemoveBackground }>REMOVE</Button>
                                                    </ButtonToolbar>
                                                    <div className="mt-1">  
                                                        <div>
                                                            <Card.Header>Family Background List</Card.Header>
                                                            <BootstrapTable
                                                                keyField = "id"
                                                                data = { this.state.familyBackgroundGrid }
                                                                columns = { familyBackgroundColumn }
                                                                //pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                rowClasses="noser-table-row-class"
                                                                striped
                                                                hover
                                                                condensed
                                                                cellEdit = { cellEditFactory({
                                                                    mode: 'dbclick',
                                                                    blurToSave: true,
                                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                                        this.FamilyBackgroundGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                        }
                                                                    })
                                                                }
                                                                selectRow = { familyBackgroundSelectRow }
                                        
                                                            />
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>   
                                        </Tab>
                                                                
                                        <Tab eventKey="others" title="V. OTHERS">
                                            <Card className="card-tab-no-border">
                                                <Card.Body>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>HOW DID YOU LEARN ABOUT THIS JOB OPENING ?</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.handleChangeJobOpening}
                                                                value={this.state.jobSourceId}
                                                            >
                                                            {this.state.jobOpeningDDL.map(item => (
                                                                <option key={item.value}  value={item.value}>
                                                                {item.name}
                                                                </option>
                                                            ))}
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>REFERRED BY</Form.Label>
                                                            <div>
                                                                <div /* isOpen={this.state.isshow} */ color={this.state.color} fade={this.state.fade} className={this.state.referealOthersTA ? 'display-block' : 'display-none'}>
                                                                    <Typeahead
                                                                        ref="refReferredBy"
                                                                        labelKey='employeeName'
                                                                        id="basic-example"
                                                                        onChange={this.handleChangeReferral}
                                                                        options={this.state.referralDDL}
                                                                        placeholder="Select Referral"
                                                                        disabled={this.state.disablereferral}
                                                                        onInputChange={this.handledOnSearch}
                                                                    />
                                                                </div>
                                                                <div color={this.state.color} fade={this.state.fade} className={this.state.referealOthersTXT ? 'display-block' : 'display-none'}>
                                                                    <Form.Control 
                                                                        type="text"
                                                                        name="oReferredBy"
                                                                        value={this.state.oReferredBy}
                                                                        onChange={this.onChangeOthersRefferedBy} 
                                                                        autoComplete="off"
                                                                        placeholder="Enter Reffered By" 
                                                                        disabled={this.state.disablereferral}
                                                                    /> 
                                                                </div>
                                                            </div>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <hr/>
                                                    <div style={{height : 5}}></div>
                                                    <Form.Row className="mt-3">
                                                        <Form.Group as={Col} sm="5" controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>DO YOU HAVE RELATIVES OR FRIENDS PRESENTLY CONNECTED WITH THIS COOPERATIVE ?</Form.Label>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formBasicCheckbox">
                                                            <Form.Check
                                                                inline 
                                                                type="checkbox" 
                                                                label="YES" 
                                                                name="checkORelativeYes"
                                                                checked={this.state.checkORelativeYes}
                                                                onChange={this.onChangeORelativeYes}
                                                                style={{fontWeight : "bold", marginTop : "3px"}}
                                                            />
                                                            <Form.Check 
                                                                inline
                                                                type="checkbox" 
                                                                label="NO" 
                                                                name="checkORelativeNo"
                                                                checked={this.state.checkORelativeNo}
                                                                onChange={this.onChangeORelativeNo}
                                                                style={{fontWeight : "bold", marginTop : "3px", marginLeft : 12}}
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>NAME</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="oRelativeName"
                                                                value={this.state.oRelativeName}
                                                                onChange={this.onChangeORelativeName} 
                                                                autoComplete="off"
                                                                placeholder="Enter NAME" 
                                                                disabled={ this.state.disabledRelative}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>CONTACT NUMBER</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="oCNumber"
                                                                value={this.state.oCNumber}
                                                                placeholder="Enter Contact Number"
                                                                onChange={this.onChangeOCNumber} 
                                                                autoComplete="off"  
                                                                disabled={ this.state.disabledRelative}            
                                                                maxLength="11" 
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>RELATIONSHIP</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="oRelationship"
                                                                value={this.state.oRelationship}
                                                                onChange={this.onChangeORelationship} 
                                                                autoComplete="off"
                                                                placeholder="Enter Relationship"
                                                                disabled={ this.state.disabledRelative}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>JOB TITLE</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="oJobTitle"
                                                                value={this.state.oJobTitle}
                                                                onChange={this.onChangeOJobTitle} 
                                                                autoComplete="off"
                                                                placeholder="Enter Job Title"
                                                                disabled={ this.state.disabledRelative}
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <hr/>
                                                    <div style={{height : 5}}></div>
                                                    <Form.Row className="mt-3">
                                                        <Form.Group as={Col} sm="3" controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>HAVE YOU BEEN CONVICTED OF A CRIME ?</Form.Label>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formBasicCheckbox">
                                                            <Form.Check
                                                                inline 
                                                                type="checkbox" 
                                                                label="YES" 
                                                                name="checkConvictedYes"
                                                                checked={this.state.checkConvictedYes}
                                                                onChange={this.onChangeOthersConvictedYes}
                                                                style={{fontWeight : "bold", marginTop : "3px"}}
                                                            />
                                                            <Form.Check 
                                                                inline
                                                                type="checkbox" 
                                                                label="NO" 
                                                                name="checkConvictedNo"
                                                                checked={this.state.checkConvictedNo}
                                                                onChange={this.onChangeOthersConvictedNo}
                                                                style={{fontWeight : "bold", marginTop : "3px", marginLeft : 12}}
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>IF YES, PLEASE GIVE DETAILS</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="oCrime"
                                                                value={this.state.oCrime}
                                                                onChange={this.onChangeOCrime} 
                                                                autoComplete="off"
                                                                placeholder="Enter If Yes, Please Give Details"
                                                                disabled={ this.state.disabledConvicted}
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <hr/>
                                                    <div style={{height : 5}}></div>
                                                    <Form.Row className="mt-3">
                                                        <Form.Group as={Col} sm="3" controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>HAVE YOU BEEN A MEMBER OF A LABOR UNION ?</Form.Label>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formBasicCheckbox">
                                                            <Form.Check
                                                                inline 
                                                                type="checkbox" 
                                                                label="YES" 
                                                                name="checkLaborUnionYes"
                                                                checked={this.state.checkLaborUnionYes}
                                                                onChange={this.onChangeOthersLaborUnionYes}
                                                                style={{fontWeight : "bold", marginTop : "3px"}}
                                                            />
                                                            <Form.Check 
                                                                inline
                                                                type="checkbox" 
                                                                label="NO" 
                                                                name="checkLaborUnionNo"
                                                                checked={this.state.checkLaborUnionNo}
                                                                onChange={this.onChangeOthersLaborUnionNo}
                                                                style={{fontWeight : "bold", marginTop : "3px", marginLeft : 12}}
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>IF YES, GIVE DETAILS</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="luDetails"
                                                                value={this.state.luDetails}
                                                                onChange={this.onChangeLUDetails} 
                                                                autoComplete="off"
                                                                placeholder="Enter If Yes, Give Details"
                                                                disabled={ this.state.disabledLaborUnion}
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <hr/>
                                                    <div style={{height : 5}}></div>
                                                    <Form.Row className="mt-3">
                                                        <Form.Group as={Col} sm="6" controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>HAVE YOU SUFFERED OR BEEN DIAGNOSED TO HAVE ANY OF THE FF. CHRONIC DISEASE(S) OR LIKES ?</Form.Label>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formBasicCheckbox">
                                                            <Form.Check
                                                                inline 
                                                                type="checkbox" 
                                                                label="YES" 
                                                                name="checkChronicDiseaseYes"
                                                                checked={this.state.checkChronicDiseaseYes}
                                                                onChange={this.onChangeOthersChronicDiseaseYes}
                                                                style={{fontWeight : "bold", marginTop : "3px"}}
                                                            />
                                                            <Form.Check 
                                                                inline
                                                                type="checkbox" 
                                                                label="NO" 
                                                                name="checkChronicDiseaseNo"
                                                                checked={this.state.checkChronicDiseaseNo}
                                                                onChange={this.onChangeOthersChronicDiseaseNo}
                                                                style={{fontWeight : "bold", marginTop : "3px", marginLeft : 12}}
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row >
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.handleChangeDisease}
                                                                value={this.state.diagnoseDeseaseId}
                                                                disabled = { this.state.disabledDisease} 
                                                            >
                                                            {this.state.diseaseDDL.map(item => (
                                                                <option key={item.value} value={item.value}>
                                                                {item.name}
                                                                </option>
                                                            ))}
                                                            
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Card.Body>
                                            </Card>
                                                
                                        </Tab>
                                        
                                        <Tab eventKey="reference" title="VI. REFERENCES (NOT RELATED TO YOUR FAMILY)">
                                            <Card className="card-tab-no-border">
                                                <Card.Body>
                                                <Card.Header>Reference List</Card.Header>
                                                            <BootstrapTable
                                                                keyField = "id"
                                                                data = { this.state.referenceGrid }
                                                                columns = { referenceColumn }
                                                                //pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                rowClasses="noser-table-row-class"
                                                                striped
                                                                hover
                                                                condensed
                                                                cellEdit = { cellEditFactory({
                                                                    mode: 'dbclick',
                                                                    blurToSave: true,
                                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                                        this.ReferenceGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                        }
                                                                    })
                                                                }
                                                                selectRow = { referenceSelectRow }
                                        
                                                            />
                                                </Card.Body>
                                            </Card>
                                        </Tab>

                                        {/* w new added */}
                                        {/* <Tab eventKey="applicationhistory" title="&#8550; APPLICATION HISTORY">
                                            <Card className="card-tab-no-border">
                                                <Card.Body>
                                                    <Form.Row>
                                                        <Form.Group as={Col}  controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                                            <Typeahead 
                                                                ref='clientref'
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangeClientAH}
                                                                options={this.state.clientDDL}
                                                                placeholder="Select Client" 
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>POSITION</Form.Label>
                                                            <Typeahead
                                                                ref='positionref'
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangePositionAH}
                                                                options={this.state.positionDDL}
                                                                placeholder="Select Position" 
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    
                                                    
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>reference No.</Form.Label>
                                                            <Form.Control 
                                                                type="text"
                                                                name="refNoAF"
                                                                value={this.state.refNoAF}
                                                                onChange={this.onChangeRefNoAF} 
                                                                autoComplete="off"
                                                                placeholder="Enter reference No." 
                                                                //disabled={this.state.isEmploymentDisabled}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridAddress1">
                                                            <Form.Label style={{fontWeight : "bold", marginLeft : 15}}>DATE APPLIED</Form.Label>
                                                            <Form.Group as={Col} controlId="formGridCity">
                                                                <DatePicker
                                                                    ref='dateApplied'
                                                                    selected={this.state.dateAppliedAF}
                                                                    onChange={this.handleChangeDateAppliedAF}
                                                                    minDate={this.minDate}
                                                                    value={this.props.dateAppliedAF}
                                                                    dateFormat={"MM/dd/yyyy"}
                                                                    className="form-control"
                                                                    wrapperClassName="datepicker"
                                                                    placeholderText="Select Date Applied" 
                                                                />
                                                            </Form.Group>
                                                        </Form.Group>
                                                        
                                                    </Form.Row>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <Form.Label style={{fontWeight : "bold"}}>remarks or comment</Form.Label>
                                                            <Form.Control 
                                                                // type="text"
                                                                name="remarksAF"
                                                                value={this.state.remarksAF}
                                                                onChange={this.onChangeRemarks} 
                                                                autoComplete="off"
                                                                placeholder="Enter comments" 
                                                                // disabled={this.state.isEmploymentDisabled}

                                                                as="textarea" rows={3}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                        </Form.Group>
                                                    </Form.Row>
                                                   
                                                    <ButtonToolbar className="mt-3">
                                                        <Button className="noser-button-mr1" variant="success" onClick = { this.handleAddApplicationHistory }>Add</Button>
                                                        <Button className="noser-button" disabled={this.state.disableRemoveEmploymentRecord} className="noser-button" variant="danger" onClick = { this.handleRemoveEmploymentRecord }>REMOVE</Button>
                                                    </ButtonToolbar>
                                                    <div className="mt-1">  
                                                        <div>
                                                            <Card.Header>APPLICATION HISTORY  LIST</Card.Header>
                                                            <BootstrapTable
                                                                keyField = "id"
                                                                data = { this.state.applicationHistoryGrid }
                                                                columns = { APPLICATIONHISTORYColumn }
                                                                //pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                rowClasses="noser-table-row-class"
                                                                striped
                                                                hover
                                                                condensed
                                                                cellEdit = { cellEditFactory({
                                                                    mode: 'dbclick',
                                                                    blurToSave: true,
                                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                                        this.applicationHistoryGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                        }
                                                                    })
                                                                }
                                                                selectRow = { applicationHistorySelectRow }
                                        
                                                            />
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                            
                                        </Tab> */}
                                    </Tabs>

                                   
                                    <hr/>
                                    <div style={{height : 5}}></div>
                                    <Card>
                                        <Card.Body>
                                            <Form.Row className="mt-3">
                                                <Form.Group as={Col} sm="1" controlId="formBasicCheckbox">
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                                    <Form.Check
                                                        inline 
                                                        type="checkbox" 
                                                        label="I HAVE READ AND AGREE TO THE TERMS AND CONDITIONS AND PRIVACY POLICY." 
                                                        name="checkDisclaimer"
                                                        checked={this.state.checkDisclaimer}
                                                        onChange={this.onChangeDisclaimer}
                                                        style={{fontWeight : "bold", marginTop : "3px"}}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row className="mt-1">
                                                <Form.Group as={Col} sm="1" controlId="formBasicCheckbox">
                                                </Form.Group>
                                                <Card.Text>
                                                    IN COMPLIANCE WITH THE DATA PRIVACY ACT (DPA) OF 2012, AND ITS IMPLEMENTING RULES AND REGULATIONS (IRR) EFFECTIVE SINCE SEPTEMBER 8, 2016, I VOLUNTARILY COMPLY AND I AM AUTHORIZING
                                                    <br/>
                                                    THE SUBMISSION THE ABOVE STATED DOCUMENTS TO MY EMPLOYER -PARAMOUNT HUMAN RESOURCE MULTI-PURPOSE COOPERATIVE.
                                                </Card.Text>
                                            </Form.Row>
                                            <Form.Row className="mt-3">
                                                <Form.Group as={Col} sm="1" controlId="formBasicCheckbox">
                                                </Form.Group>
                                                <Form.Label style={{fontWeight : "bold"}}>AS SUCH I AGREE AND AUTHORIZE PHRMPC TO:</Form.Label>
                                            </Form.Row>
                                            <Form.Row className="mt-1">
                                                <Form.Group as={Col} sm="2" controlId="formBasicCheckbox">
                                                </Form.Group>
                                                <Card.Text>
                                                    1. USE MY PERSONAL INFORMATION DURING THE COURSE OF MY EMPLOYMENT WITH THE COOPERATIVE IN RELATION TO THE SYSTEMS AND PROCESSES WHICH MAY BE REQUIRED
                                                    <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;BY THE COOPERATIVE SUCH AS BUT NOT LIMITED TO, THOSE RELATED TO MY APPLICATION, SALARY AND BENEFITS PROCESSING, GOVERNMENT MANDATED BENEFITS PROCESSING.
                                                </Card.Text>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} sm="2" controlId="formBasicCheckbox">
                                                </Form.Group>
                                                <Card.Text>
                                                    2. RETAIN THE INFORMATION FOR A PERIOD OF FIVE YEARS FROM THE CESSATION DATE OF MY EMPLOYMENT. I AGREE THAT MY INFORMATION MAY BE DESTROYED AFTER THIS PERIOD.
                                                </Card.Text>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} sm="2" controlId="formBasicCheckbox">
                                                </Form.Group>
                                                <Card.Text>
                                                    3. SHARE MY INFORMATION TO AFFILIATE COMPANIES FOR ANY LEGITIMATE BUSINESS PURPOSE SUCH AS BUT NOT LIMITED TO; TRANSFER OF EMPLOYMENT,
                                                    <br/>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;PROMOTION, AND OTHER EMPLOYEE MOVEMENT.
                                                </Card.Text>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} sm="2" controlId="formBasicCheckbox">
                                                </Form.Group>
                                                <Card.Text>
                                                    4. I AM ASSURED THAT SECURITY SYSTEMS ARE EMPLOYED TO PROTECT MY INFORMATION.
                                                </Card.Text>
                                            </Form.Row>
                                        </Card.Body>
                                    </Card>
                                    <ButtonToolbar className="mt-3">
                                        {/* <Button disabled={this.state.disablebutton}  className="ml-auto noser-button-mr1" variant="success" onClick = {() => this.handleClickStatus('1')}>SAVE AS DRAFT</Button> */}
                                        <Button disabled={this.state.disablebutton} className="ml-auto noser-button-mr1"   variant="primary" onClick = {() => this.handleClickStatus('2')}>SUBMIT</Button>
                                        <Button  href="/sourcing" className="noser-button" variant="danger">BACK</Button>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                        <div style={{height : 15}}></div>
                    </Container>
                    <NoserLoading show={this.state.isloading} />
            </div>
        )
    }

}

export  default ApplicationFormCreate;
