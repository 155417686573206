import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { tsNullKeyword } from '@babel/types';

 
class RecallMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            statusTableList                     :   [],
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalTrainingRegisterShow           :   false,
            selectedClientName                  :   [],            
            getClientList                       :   [],
            getEmployeeList                     :   [],
            employeeDetailList                  :   [],
            particularList: [
                {
                    "id" : "Closed",
                    "name" : "Closed"
                },
                {
                    "id" : "Pending",
                    "name" : "Pending"
                },
                
               
            ],
            selectedparticular      :   '',
            selectedparticularId    :   '',
            countStatus             :'Pending',
            pendingsList            :[],
            closedList              :[],
            fliterClose             :'Closed',
            statusPendingList       :[],
            statusClosedList        :[],
            totalStatusList         :[],
            totalDoleCasesList      :[], 
            totalClosedCount        :[],  

            client                  :"",  
            selectedEmployeeName    :"", 
            selectedEmployeeId      :"", 
            selectedEmployeeNo      :"", 
            selectedEmployeeBranch  :"", 
            selectedPosition        :"", 
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getMonitoringList();
        this.getClientList();;
        /* this.getMonitoryByClient();; */
        
        
    }

    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            //console.log("Client List start ");
            //console.log(res.data);
            //console.log("Client List end");
            const data = res.data
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }

    onChangeClientList = (e) => {
        let clear = []
        this.state.employeeDetailList = clear
        if(e.length == 0) {
            this.state.client=""
            //this.state.selectedClientId=""
            return
        } 
        //this.state.selectedClientId = e[0].id
        this.state.client = e[0].name
        this.setState({
            isloading:true,
            //isshow:true,
            color:"",
            message:"",
            fade:true ,
            client: e[0].name
        })
       
        //this.getMonitoryByClient();;
        this.getEmployees();;
       
        
    }

    getEmployees(){

        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            console.log("Employee List start ");
            console.log(res.data);
            console.log("Employee List end");
            const data = res.data
            if(data.status=="1")
                this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
            else
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })

        
    }

    onChangeEmployeesList = (e) => {
       

        if(e.length==0)
        {
            this.setState({getEmployeeListData: null, selectedPayrollpayPeriod: '', selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
          
            return
        }
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedEmployeeName = e[0].employeeName
        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeNo = e[0].employeeNo
        this.state.selectedEmployeeBranch = e[0].locationName
        this.state.selectedPosition = e[0].position
       
        // console.log("Work selectedEmployeeName   " + this.state.selectedEmployeeNo );
        // console.log("Employee List start ");
        // console.log(this.state.selectedEmployeeNo);
        // console.log("Employee List end");

        // this.setState({
        //     // position        :this.state.selectedPosition,
        //     // location        :this.state.selectedEmployeeBranch,
        //     // saveEmployeeNo  : e[0].employeeNo

        // })
        this.setState({isloading:false})
    }

   /*  showTrainingRegisterModal = (e) => {
        this.setState({modalTrainingRegisterShow: true})
    } */
    handleModalClose = (e) =>{
        //reload or refresh data here
        //alert("Data pass from child : " + e)
        this.setState({modalTrainingRegisterShow: false})
    }

    GridDataModified(oldValue, newValue, id, column) {
        //////console.log(id)
        this.state.employeeDetailList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }

    getMonitoringList =()=>{

        let filter_data ={}

        const getParams ={
            "_collection" : "NOR",
            "filter_data" : filter_data
        }

        console.log("axios start")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data

            if(data.NOR.length > 0){

                const itemUsersDataLists = this.buildListNORMonitoring(data["NOR"])
            
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                        
                    let obj = {

                        'id'                    :   this.state.itemUserDataList[i]['id'],
                        'NORRefNo'              :   this.state.itemUserDataList[i]['NORRefNo'],
                        'client'                :   this.state.itemUserDataList[i]['client'],       
                        'clientId'              :   this.state.itemUserDataList[i]['clientId'],        
                        'employeeName'          :   this.state.itemUserDataList[i]['employeeName'],
                        'location'              :   this.state.itemUserDataList[i]['location'],
                        'position'              :   this.state.itemUserDataList[i]['position'],
                        'employeeNo'            :   this.state.itemUserDataList[i]['employeeNo'],        
                        'recallDate'            :   this.state.itemUserDataList[i]['recallDate'],
                        'recallEffectivityDate' :   this.state.itemUserDataList[i]['recallEffectivityDate'],
                        'paragraph'             :   this.state.itemUserDataList[i]['paragraph'],
                        'addedparagraph1'       :   this.state.itemUserDataList[i]['addedparagraph1'],
                        'addedparagraph2'       :   this.state.itemUserDataList[i]['addedparagraph2'],
                        'addedparagraph3'       :   this.state.itemUserDataList[i]['addedparagraph3'],
                        'addedparagraph4'       :   this.state.itemUserDataList[i]['addedparagraph4'],
                        'addedparagraph5'       :   this.state.itemUserDataList[i]['addedparagraph5'],
                        'addedparagraph6'       :   this.state.itemUserDataList[i]['addedparagraph6'],
                        'preparedBy'            :   this.state.itemUserDataList[i]['preparedBy'],
                        'prepareByPosition'     :   this.state.itemUserDataList[i]['prepareByPosition'],           
                        'notedBy'               :   this.state.itemUserDataList[i]['notedBy'],
                        'notedByPosition'       :   this.state.itemUserDataList[i]['notedByPosition'],
                        'status'                :   this.state.itemUserDataList[i]['status'],                        
                        'isDraft'               :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'             :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'             :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'           :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'            :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'          :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'            :   this.state.itemUserDataList[i]['isModified'],
                        'action'                :   "1",

                    }

                    List.push(obj)

                }

                this.setState({
                    employeeDetailList: List,
                    isloading:false
                })
                
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    } 

    buildListNORMonitoring(data) {
        // console.log("data start")
        // console.log(data )
        console.log("data start")
        console.log(data )
        console.log("data" )
        let itemList =[]
    
        let idList                      =[]
        let NORRefNoList                =[]
        let clientList                  =[]
        let createdbyList               =[]
        let createddateList             =[]
        let recallDateList              =[]
        let recallEffectivityDateList   =[]
        let paragraphList               =[]
        let addedparagraph1List         =[]
        let addedparagraph2List         =[]
        let addedparagraph3List         =[]
        let addedparagraph4List         =[]
        let addedparagraph5List         =[]
        let addedparagraph6List         =[]
        let employeeNameList            =[]
        let employeeNoList              =[]
        let isDeletedList               =[]
        let isDraftList                 =[]
        let isModifiedList              =[]
        let locationList                =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let positionList                =[]
        let statusList                  =[]
        let preparedByList              =[]
        let prepareByPositionList       =[]
        let notedByList                 =[]
        let notedByPositionList         =[]
        let ClientIdList                =[]
    
        
        for (let i = 0; i < data.length; i++) {            
    
            let s1 = data[i].split("', ")
            let s2 = data[i].split(", ")
          
            let IdClean0                    = s1[0].split("': ")[1]  
           
            let idClean1                    = IdClean0.replace("ObjectId(","").replace(")","").replace("{","").replace(", 'NORRefNo","") 
            let NORRefNoTmp                 = s2[1].split("': ")[1]            
            let clientTmp                   = s1[1].split("': ")[1]  
            let ClientIdTmp                 = s1[2].split("': ")[1]
            let employeeNameTmp             = s1[3].split("': ")[1]
            let positionTmp                 = s1[4].split("': ")[1]
            let employeeNoTmp               = s1[5].split("': ")[1]
            let locationTmp                 = s1[6].split("': ")[1]  
            let recallDateTmp               = s1[7].split("': ")[1]
            let recallEffectivityDateTmp    = s1[8].split("': ")[1]
            let paragraphTmp                = s1[9].split("': ")[1]
            let addedparagraph1Tmp          = s1[10].split("': ")[1]
            let addedparagraph2Tmp          = s1[11].split("': ")[1]
            let addedparagraph3Tmp          = s1[12].split("': ")[1]
            let addedparagraph4Tmp          = s1[13].split("': ")[1]
            let addedparagraph5Tmp          = s1[14].split("': ")[1]
            let addedparagraph6Tmp          = s1[15].split("': ")[1]
            let preparedByTmp               = s1[16].split("': ")[1]
            let prepareByPositionTmp        = s1[17].split("': ")[1]
            let notedByTmp                  = s1[18].split("': ")[1]
            let notedByPositionTmp          = s1[19].split("': ")[1]
            let statusTmp                   = s1[20].split("': ")[1]
            let isDraftTmp                  = s1[21].split("': ")[1]
            let isDeletedTmp                = s1[22].split("': ")[1]
            let createdbyTmp                = s1[23].split("': ")[1]          
            let createddateTmp              = s1[24].split("': ")[1]
            let modifiedbyListTmp           = s1[25].split("': ")[1]
            let modifieddateListTmp         = s1[26].split("': ")[1]
            let isModifiedListTmp           = s1[27].split("':")[1]    

            idList.push(idClean1.replace("'","").replace("'","").replace(" ",""))
            NORRefNoList.push(NORRefNoTmp.replace("'","").replace("'",""))
            clientList.push(clientTmp.replace("'",""))
            ClientIdList.push(ClientIdTmp.replace("'",""))
            createdbyList.push(createdbyTmp.replace("'",""))
            createddateList.push(createddateTmp.replace("'",""))
            recallDateList.push(recallDateTmp.replace("'",""))
            recallEffectivityDateList.push(recallEffectivityDateTmp.replace("'",""))
            paragraphList.push(paragraphTmp.replace("'",""))
            addedparagraph1List.push(addedparagraph1Tmp.replace("'",""))
            addedparagraph2List.push(addedparagraph2Tmp.replace("'",""))
            addedparagraph3List.push(addedparagraph3Tmp.replace("'",""))
            addedparagraph4List.push(addedparagraph4Tmp.replace("'",""))
            addedparagraph5List.push(addedparagraph5Tmp.replace("'",""))
            addedparagraph6List.push(addedparagraph6Tmp.replace("'",""))
            employeeNameList.push(employeeNameTmp.replace("'",""))
            employeeNoList.push(employeeNoTmp.replace("'",""))
            isDeletedList.push(isDeletedTmp.replace("'",""))
            isDraftList.push(isDraftTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'}",""))
            locationList.push(locationTmp.replace("'",""))
            positionList.push(positionTmp.replace("'",""))
            statusList.push(statusTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            preparedByList.push(preparedByTmp.replace("'",""))
            prepareByPositionList.push(prepareByPositionTmp.replace("'",""))
            notedByList.push(notedByTmp.replace("'",""))
            notedByPositionList.push(notedByPositionTmp.replace("'",""))
            
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'NORRefNo'              :   NORRefNoList[i],
                'client'                :   clientList[i],   
                'clientId'              :   ClientIdList[i],  
                'employeeName'          :   employeeNameList[i],
                'employeeNo'            :   employeeNoList[i],
                'location'              :   locationList[i],
                'position'              :   positionList[i],         
                'recallDate'            :   recallDateList[i],
                'recallEffectivityDate' :   recallEffectivityDateList[i],
                'paragraph'             :   paragraphList[i],
                'addedparagraph1'       :   addedparagraph1List[i],
                'addedparagraph2'       :   addedparagraph2List[i],
                'addedparagraph3'       :   addedparagraph3List[i], 
                'addedparagraph4'       :   addedparagraph4List[i], 
                'addedparagraph5'       :   addedparagraph5List[i],
                'addedparagraph6'       :   addedparagraph6List[i],  
                'preparedBy'            :   preparedByList[i],
                'prepareByPosition'     :   prepareByPositionList[i],
                'notedBy'               :   notedByList[i],
                'notedByPosition'       :   notedByPositionList[i],               
                'status'                :   statusList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],

            }
            itemList.push(obj)
            console.log("itemList start")
            console.log(itemList )
            console.log("itemList" )
            
        }
        return itemList
    }


    buildListMonitoringStatus(data) {
        ////console.log("data start")
        let itemList =[]
    
        let idList                      =[]
        let statusList                  =[]
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
        
    
        for (let i = 0; i < data.length; i++) {           
    
            let s1 = data[i].split("':")
            
            let idClean                     = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let statusTmp                   = s1[2].split("',")[0]
            let isDraftListTmp              = s1[3].split("',")[0]
            let isDeletedListTmp            = s1[4].split("',")[0]
            let createdbyListTmp            = s1[5].split("',")[0]
            let createddateListTmp          = s1[6].split("',")[0]
            let modifiedbyListTmp           = s1[7].split("',")[0]
            let modifieddateListTmp         = s1[8].split("',")[0]
            let isModifiedListTmp           = s1[9].split("',")[0]

        
            idList.push(IdClean2.replace("'",""))
            statusList.push(statusTmp.replace("'",""))
            
            isDraftList.push(isDraftListTmp.replace("'",""))
            isDeletedList.push(isDeletedListTmp.replace("'",""))
            createdbyList.push(createdbyListTmp.replace("'",""))
            createddateList.push(createddateListTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'status'                :   statusList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }
               
        return itemList
    }

        
    buildList(data) {
        ////console.log("data start")
        let itemList =[]
    
        let idList                          =[]
        let selectedClientNameList          =[]
        let selectedEmployeeNameList        =[]
        let selectedPositionList            =[]
        let selectedEmployeeNoList          =[]
        let selectedEmployeeBranchList      =[]
        let groundOfSeperationList          =[]
        let caseNumeberList                 =[]
        let dateFiledList                   =[]
        let dateReceivedList                =[]
        let complaintList                   =[]
        let scheduleOfHearingList           =[]
        let venueList                       =[]
        let hearingOfficerList              =[]
        let caseNoNLRCList                  =[]
        let mandatoryDateFiledList          =[]
        let mandatoryDateReceivedList       =[]
        let mandatoryComplaintList          =[]
        let mandatoryScheduleOfHearingList  =[]
        let mandatoryVenueList              =[]
        let mandatoryLaborArbiterList       =[]
        let particularsMomStConfMeetingList =[]
        let particularsMomNdConfMeetingList =[]
        let particularsQuitclaimList        =[]
        let particularsMonetaryClaimList    =[]
        let positionPaperList               =[]
        let replyList                       =[]
        let rejoinderList                   =[]
        let decisionList                    =[]
        let counselList                     =[]
        let latestUpdateList                =[]
        let statusDateList                  =[]
        let remarksList                     =[]
        let updateStatusList                =[]
        let attachedDocList                 =[]
        let reportedByEmployeeList          =[]
        let reportedByPositionList          =[]
        let reportedByClientList            =[]

        let isDraftList                     =[]
        let isDeletedList                   =[]
        let createdbyList                   =[]
        let createddateList                 =[]
        let modifiedbyList                  =[]
        let modifieddateList                =[]
        let isModifiedList                  =[]
        
    
        for (let i = 0; i < data.length; i++) {
            
    
            let s1 = data[i].split("':")
            
            let idClean                         = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                        = idClean.split("',")[0]
            let selectedClientNameTmp           = s1[2].split("',")[0]
            let selectedEmployeeNameTmp         = s1[3].split("',")[0]
            let selectedPositionTmp             = s1[4].split("',")[0]
            let selectedEmployeeNoTmp           = s1[5].split("',")[0]
            let selectedEmployeeBranchTmp       = s1[6].split("',")[0]
            let groundOfSeperationTmp           = s1[7].split("',")[0]
            let caseNumeberTmp                  = s1[8].split("',")[0]
            let dateFiledTmp                    = s1[9].split("',")[0]
            let dateReceivedTmp                 = s1[10].split("',")[0]
            let complaintTmp                    = s1[11].split("',")[0]
            let scheduleOfHearingTmp            = s1[12].split("',")[0]
            let venueTmp                        = s1[13].split("',")[0]
            let hearingOfficerTmp               = s1[14].split("',")[0]
            let caseNoNLRCTmp                   = s1[15].split("',")[0]
            let mandatoryDateFiledTmp           = s1[16].split("',")[0]
            let mandatoryDateReceivedTmp        = s1[17].split("',")[0]
            let mandatoryComplaintTmp           = s1[18].split("',")[0]
            let mandatoryScheduleOfHearingTmp   = s1[19].split("',")[0]
            let mandatoryVenueTmp               = s1[20].split("',")[0]
            let mandatoryLaborArbiterTmp        = s1[21].split("',")[0]
            let particularsMomStConfMeetingTmp  = s1[22].split("',")[0]
            let particularsMomNdConfMeetingTmp  = s1[23].split("',")[0]
            let particularsQuitclaimTmp         = s1[24].split("',")[0]
            let particularsMonetaryClaimTmp     = s1[25].split("',")[0]
            let positionPaperTmp                = s1[26].split("',")[0]
            let replyTmp                        = s1[27].split("',")[0]
            let rejoinderTmp                    = s1[28].split("',")[0]
            let decisionTmp                     = s1[29].split("',")[0]
            let counselTmp                      = s1[30].split("',")[0]
            let latestUpdateTmp                 = s1[31].split("',")[0]
            let statusDateTmp                   = s1[32].split("',")[0]
            let remarksTmp                      = s1[33].split("',")[0]
            let updateStatusTmp                 = s1[34].split("',")[0]
            let attachedDocTmp                  = s1[35].split("',")[0]
            let reportedByEmployeeTmp           = s1[36].split("',")[0]
            let reportedByPositionTmp           = s1[37].split("',")[0]
            let reportedByClientTmp             = s1[38].split("',")[0]

            let isDraftListTmp                  = s1[39].split("',")[0]
            let isDeletedListTmp                = s1[40].split("',")[0]
            let createdbyListTmp                = s1[41].split("',")[0]
            let createddateListTmp              = s1[42].split("',")[0]
            let modifiedbyListTmp               = s1[43].split("',")[0]
            let modifieddateListTmp             = s1[44].split("',")[0]
            let isModifiedListTmp               = s1[45].split("',")[0]

        
            idList.push(IdClean2.replace(" '",""))
            selectedClientNameList.push(selectedClientNameTmp.replace(" '",""))
            selectedEmployeeNameList.push(selectedEmployeeNameTmp.replace(" '",""))
            selectedPositionList.push(selectedPositionTmp.replace(" '",""))
            selectedEmployeeNoList.push(selectedEmployeeNoTmp.replace(" '",""))
            selectedEmployeeBranchList.push(selectedEmployeeBranchTmp.replace(" '",""))
            groundOfSeperationList.push(groundOfSeperationTmp.replace(" '",""))
            caseNumeberList.push(caseNumeberTmp.replace(" '",""))
            dateFiledList.push(dateFiledTmp.replace(" '",""))
            dateReceivedList.push(dateReceivedTmp.replace(" '",""))
            complaintList.push(complaintTmp.replace(" '",""))
            scheduleOfHearingList.push(scheduleOfHearingTmp.replace(" '",""))
            venueList.push(venueTmp.replace(" '",""))
            hearingOfficerList.push(hearingOfficerTmp.replace(" '",""))
            caseNoNLRCList.push(caseNoNLRCTmp.replace(" '",""))
            mandatoryDateFiledList.push(mandatoryDateFiledTmp.replace(" '",""))
            mandatoryDateReceivedList.push(mandatoryDateReceivedTmp.replace(" '",""))
            mandatoryComplaintList.push(mandatoryComplaintTmp.replace(" '",""))
            mandatoryScheduleOfHearingList.push(mandatoryScheduleOfHearingTmp.replace(" '",""))
            mandatoryVenueList.push(mandatoryVenueTmp.replace(" '",""))
            mandatoryLaborArbiterList.push(mandatoryLaborArbiterTmp.replace(" '",""))
            particularsMomStConfMeetingList.push(particularsMomStConfMeetingTmp.replace(" '",""))
            particularsMomNdConfMeetingList.push(particularsMomNdConfMeetingTmp.replace(" '",""))
            particularsQuitclaimList.push(particularsQuitclaimTmp.replace(" '",""))
            particularsMonetaryClaimList.push(particularsMonetaryClaimTmp.replace(" '",""))
            positionPaperList.push(positionPaperTmp.replace(" '",""))
            replyList.push(replyTmp.replace(" '",""))
            rejoinderList.push(rejoinderTmp.replace(" '",""))
            decisionList.push(decisionTmp.replace(" '",""))
            counselList.push(counselTmp.replace(" '",""))
            latestUpdateList.push(latestUpdateTmp.replace(" '",""))
            statusDateList.push(statusDateTmp.replace(" '",""))
            remarksList.push(remarksTmp.replace(" '",""))
            updateStatusList.push(updateStatusTmp.replace(" '","")) 
            attachedDocList.push(attachedDocTmp.replace(" '",""))
            reportedByEmployeeList.push(reportedByEmployeeTmp.replace(" '",""))
            reportedByPositionList.push(reportedByPositionTmp.replace(" '",""))
            reportedByClientList.push(reportedByClientTmp.replace(" '",""))

            isDraftList.push(isDraftListTmp.replace(" '",""))
            isDeletedList.push(isDeletedListTmp.replace(" '",""))
            createdbyList.push(createdbyListTmp.replace(" '",""))
            createddateList.push(createddateListTmp.replace(" '",""))
            modifiedbyList.push(modifiedbyListTmp.replace(" '",""))
            modifieddateList.push(modifieddateListTmp.replace(" '",""))
            isModifiedList.push(isModifiedListTmp.replace(" '","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                            :   idList[i],
                'selectedClientName'            :   selectedClientNameList[i],
                'selectedEmployeeName'          :   selectedEmployeeNameList[i],
                'selectedPosition'              :   selectedPositionList[i],
                'selectedEmployeeNo'            :   selectedEmployeeNoList[i],
                'selectedEmployeeBranch'        :   selectedEmployeeBranchList[i],
                'groundOfSeperation'            :   groundOfSeperationList[i],
                'caseNumeber'                   :   caseNumeberList[i],
                'dateFiled'                     :   dateFiledList[i],
                'dateReceived'                  :   dateReceivedList[i],
                'complaint'                     :   complaintList[i],
                'scheduleOfHearing'             :   scheduleOfHearingList[i],
                'venue'                         :   venueList[i],
                'hearingOfficer'                :   hearingOfficerList[i],
                'caseNoNLRC'                    :   caseNoNLRCList[i],
                'mandatoryDateFiled'            :   mandatoryDateFiledList[i],
                'mandatoryDateReceived'         :   mandatoryDateReceivedList[i],
                'mandatoryComplaint'            :   mandatoryComplaintList[i],
                'mandatoryScheduleOfHearing'    :   mandatoryScheduleOfHearingList[i],
                'mandatoryVenue'                :   mandatoryVenueList[i],
                'mandatoryLaborArbiter'         :   mandatoryLaborArbiterList[i],
                'particularsMomStConfMeeting'   :   particularsMomStConfMeetingList[i],
                'particularsMomNdConfMeeting'   :   particularsMomNdConfMeetingList[i],
                'particularsQuitclaim'          :   particularsQuitclaimList[i],
                'particularsMonetaryClaim'      :   particularsMonetaryClaimList[i],
                'positionPaper'                 :   positionPaperList[i],
                'reply'                         :   replyList[i],
                'rejoinder'                     :   rejoinderList[i],
                'decision'                      :   decisionList[i],
                'counsel'                       :   counselList[i],
                'latestUpdate'                  :   latestUpdateList[i],
                'statusDate'                    :   statusDateList[i],
                'remarks'                       :   remarksList[i],
                'updateStatus'                  :   updateStatusList[i],
                'attachedDoc'                   :   attachedDocList[i],
                'reportedByEmployee'            :   reportedByEmployeeList[i],
                'reportedByPosition'            :   reportedByPositionList[i],
                'reportedByClient'              :   reportedByClientList[i],

                'isDraft'                       :   isDraftList[i],
                'isDeleted'                     :   isDeletedList[i],
                'createdby'                     :   createdbyList[i],
                'createddate'                   :   createddateList[i],
                'modifiedby'                    :   modifiedbyList[i],
                'modifieddate'                  :   modifieddateList[i],
                'isModified'                    :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }
        
        return itemList
    }

    getMonitoryByClient =()=>{
       
        let filter_data ={'client':this.state.client}

        const getParams ={
            "_collection" : "IncidentReport",
            "filter_data" : filter_data
        }

        console.log("axios start")

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data
            console.log("getMonitoringList Raw Json111")
            console.log(data)
            console.log("getMonitoringList End Raw Json111")
            var obj = (data);
            var res = [];
            
            data.IncidentReport.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                res.push(json)
            })

            res.map(function(d){
                d.id = res.length++
                d.action = "1"
            })

            this.setState({
                employeeDetailList: res,
                isloading:false
            })
            
        })
        .catch(error=>{
            console.log("error: " + error)
        })
             
    } 
    
    handleExportCVS=(e,props)=>{
       
        props.onExport();
        
    }

    onChangeparticular = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedparticular     :   '',
                selectedparticularId   :   ''
            })
            return
        }
        this.state.selectedparticular = e[0].name
        this.state.selectedparticularId = e[0].id
       
        this.setState({
            isshow   :   false,
           selectedparticular : e[0].name

        })
        //console.log(this.state.selectedparticular)
        
    }

    nextProcess=(row)=>{
        console.log(row)
        sessionStorage.setItem("IRData", JSON.stringify(row))
        let nextProcessCheck = "1"
        sessionStorage.setItem("nextProcessCheck", JSON.stringify(nextProcessCheck)) 

        if(row.status === "IR" || row.status == "IR" ){
            window.location.href='/noticememorandum'
        }
        /* if(row.status === "NTE" || row.status == "NTE" ){
            window.location.href='/NoticeOfDisiplinaryAction'
        } */

       /*  let rowtmp = row.id

       */

        
    }


    render() {
        const { ExportCSVButton } = CSVExport;
        
        const statusColumn = [
            
            {
                dataField   : 'status',
                text:'Status',
                headerStyle : () => {
                    return { minWidth  : "865px"};
                }
            },
            {
                dataField: 'count',
                text: 'Count',
                headerStyle : () => {
                    return { minWidth  : "870px" };
                }
            },
           /*  {
                dataField   : 'persentage',
                text        : '%',
                headerStyle : () => {
                    return { width  : "33.3333333333%" };
                }
            }, */
            /*{
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={this.showTrainingRegisterModal}
                        >View Details</Button>
                    );
                },
            }, */
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.statusTableList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };

        const employeeDetailColumn = [

            {
                dataField   : 'employeeName',
                text        : 'Employee Name',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'client',
                text:'Client Involved',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'location',
                text        : 'Location',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'position',
                text        : 'Position',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },            
            {
                dataField   : 'NORRefNo',
                text:'RECALL REFERENCE NUMBER',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'recallEffectivityDate',
                text        : 'RECALL EFFECTIVITY',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'retailGroup',
                text        : 'RETAIL GROUP',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },            
            /* {
                dataField   : 'employeeNo',
                text        : 'Employee No',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },            
            
            {
                dataField   : 'timeOfincident',
                text        : 'Time Of Incident',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'processStatus',
                text        : 'status',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            }, */
            
           /*  {
                dataField   : 'action',
                text        : 'Proceed to next Process',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (cell)
                    return (
                        <ButtonToolbar className="mt-1">     
                            <Button variant="Link" onClick={(e)=> this.nextProcess(row)} style={{color: "#1c6dfe"}}>Process</Button>&nbsp;&nbsp;
                        </ButtonToolbar>  
                    );
                },
            }, */
        ]

        const selectemployeeDetailRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.employeeDetailList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEmployeeDetailEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
       
        
    return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header> Recall Monitoring </Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>                
                                
                                <Form.Group as={Row} controlId="formPlaintextEmail">  </Form.Group>

                                <Card.Header className="mt-3">REPORT FILTERING</Card.Header>

                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    Client
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeClientList}
                                            options={this.state.getClientList}
                                            placeholder="Select Client"
                                        />
                                    </Col>
                                </Form.Group>
                                {/* <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Employee
                                    </Form.Label>
                                    <Col sm="7">
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.onChangeEmployeesList}
                                            options={this.state.getEmployeeList}
                                            //selected={[this.state.selectedEmployeeName]}
                                            //disabled={this.state.disableEmployee}
                                            //placeholder={this.state.employeePlaceHolder}
                                        />
                                    </Col>
                                </Form.Group> */}
                                
                                <ToolkitProvider
                                    keyField="id"   
                                    data={ this.state.employeeDetailList }
                                    columns={ employeeDetailColumn }
                                    exportCSV={ {
                                        fileName: "Dole Cases Monitoring.csv",
                                    } }
                                >
                                    {
                                        props => (
                                            <div>
                                                <hr />
                                                <BootstrapTable { ...props.baseProps } />
                                                <ButtonToolbar>
                                                    <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export Report</ExportCSVButton>                                                       
                                                    {/* <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleUpdateClick}>
                                                        Save
                                                    </Button> */}&nbsp;&nbsp;
                                                    <NavLink to="/home">
                                                        <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                                            Close
                                                        </Button>
                                                    </NavLink>
                                                </ButtonToolbar>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                            </Form>
                        </Card.Body>
                    </Card>                    
                </Container>
                <NoserLoading show={this.state.isloading} />
                  
            </div> 
        )
    }

}

export  default RecallMonitoring 
