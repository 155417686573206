import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import type from 'react-bootstrap-table2-editor';

class ManningRecordApprove extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            selected: [],
            disablebutton   :   true,

            employeeRecordList:[],
            employeeRecordListGrid:[],
            newEmployeeRecordsList:[],
            statusName:"",
            statusId:"2",
            employeeName:"",
            employeeId:"",
            clientId:"",
            clientName:"",
            
            
            statusDDL:[
                {"id":"2","name":"FOR APPROVAL"},
                {"id":"4","name":"APPROVED"},
                {"id":"5","name":"REJECTED"}
            ],
            clientDDL:[],
            locationDDL:[],
            paycardTypeDDL:[],
            positionDDL:[],
            employeeStatusDDL:[]
        }
         
    }

    componentDidMount(){
        //const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        //sleep(1000).then(() => {this.setState({isLoading:false})})
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients();
        if(this.state.userinfo.roleId == "1" || this.state.userinfo.roleId == "8"){
            this.state.statusId = "2"
            this.GetEmployeeList();
        }
        if(this.state.userinfo.roleId == "5" || this.state.userinfo.roleId == "26"){
            this.state.statusId = "1"
            this.GetEmployeeList();
        }
    }
    GetEmployeeList = async() => {
        this.setState({
            employeeRecordList:[],
            isloading:true
        })
        const submittedParams = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.clientId,
            "RoleId":this.state.userinfo.roleId,
            "EmployeeId":this.state.employeeId,
            "StatusId":this.state.statusId
        };
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetEmployeeRecordForApprovals",  submittedParams)
        .then(res => {
            const data = res.data;
            this.setState({ employeeRecordList: data.employeeRecords,isloading:false});
           
        })
    } 
    GetClients = async () => {
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
            .then(res => {
                this.setState({clientDDL : res.data.clients})
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true,
                })
            })
        this.GetClientLocations()
    }
    GetClientLocations = async () => {
        //this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientName":this.state.clientName
        }
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", params)
            .then(res => {
                let locations = []
                res.data.locations.map(function(itm,idx){
                    locations.push({
                        "clientname":itm.clientName,
                        "value" : itm.id,
                        "label" : itm.name
                    })
                })
                this.setState({locationDDL : locations})
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true,
                })
            })
        this.GetPositions()
    }
    GetPositions = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId"     :   "",
            "DepartmentId"  :   "",
            "Name"          :   ""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  params)
        .then(res => {
            const data = res.data;
            let positionLst = []
                data.positions.map(function(itm){
                    positionLst.push({
                        "value" : itm.id,
                        "label" : itm.name
                    })
            })
            this.setState({positionDDL:positionLst});
        })
        this.GetEmploymentStatus()
    }
    GetEmploymentStatus = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetEmployeeStatusTypes",  params)
        .then(res => {
            const data = res.data;
            let employeeStatusLst = []
                data.statusTypes.map(function(itm){
                    employeeStatusLst.push({
                        "value" : itm.id,
                        "label" : itm.name
                    })
            })
            this.setState({employeeStatusDDL:employeeStatusLst});
        })
        this.GetPayCardTypes()
    }
    GetPayCardTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayCardTypes",  params)
        .then(res => {
            const data = res.data;
            let paycardTypeLst = []
                data.payCardTypes.map(function(itm){
                    paycardTypeLst.push({
                        "value" : itm.id,
                        "label" : itm.name
                    })
            })
            this.setState({paycardTypeDDL:paycardTypeLst});
        })
        this.handleSearchClick()
    }
    handleChangeEmployee= (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if (e.length == 0) {
            this.setState({employeeName: "", employeeId:""})
            return
        }
        this.setState({employeeName : e[0].name,employeeId : e[0].employeeId,})
    }
    handleChangeClient= (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length == 0) {
            this.state.clientName=""
            this.state.clientId=""
            return
        } 
        this.state.clientId = e[0].id
        this.state.clientName = e[0].name
        this.GetEmployeeList();
    }
    handleChangeStatus = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length == 0) {
            this.state.statusName = ""
            this.state.statusId = ""
            return
        } 
        this.state.statusName = e[0].name
        this.state.statusId = e[0].id
        this.GetEmployeeList();
    } 
    handleSearchClick = async (event) => {
        this.setState({employeeRecordListGrid:[],isloading:true})

        const searchParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "EmployeeId":this.state.employeeId,
             "StatusId":this.state.statusId,
        };
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetEmployeeRecordForApprovals",  searchParams)
            .then(res => {
            const data = res.data;
            this.setState({employeeRecordListGrid: data.employeeRecords, isloading:false});
            // this.processGrid();
            if(data.status!="1"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
            })
            .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }

    handleApproveClick = event => {
        let newEmployeeRecordsList = []
        this.setState({newEmployeeRecordsList: [],isLoading:true})
        for (let i = 0; i < this.state.employeeRecordListGrid.length; i++) {
            if(this.state.employeeRecordListGrid[i]["isModified"] == 1){
                if(this.state.userinfo.roleId== 5)
                    {this.state.employeeRecordListGrid[i]["statusId"] ="2"}
                    if(this.state.userinfo.roleId== 1 || this.state.userinfo.roleId== 1)
                    { this.state.employeeRecordListGrid[i]["statusId"] ="4"}
                const obj = {
                    // ProfileId:this.state.employeeRecordListGrid[i]["profileId"],
                    ApprovalId:this.state.employeeRecordListGrid[i]["id"],
                    StatusId: this.state.employeeRecordListGrid[i]["statusId"]
                };

                this.state.newEmployeeRecordsList.push(obj);
            }
        }

        const approveParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "approvedEmployees":this.state.newEmployeeRecordsList
            };
            axios
                .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/SubmitApprovedEmployeeRecord", approveParams)
                .then(res => {
                    const data = res.data;
                    this.setState({isLoading:false})
                    this.refreshPage();
                    if(data.status=="1"){
                        this.setState({
                                isloading:false,
                                alerttype:"Success!",
                                isshow:true,
                                color:"success",
                                message: data.message,
                                fade:true
                            });
                        }
                        else {
                            this.setState({
                                isloading:false,
                                alerttype:"Error!",
                                isshow:true,
                                color:"danger",
                                message: data.message,
                                fade:true
                            });
                        }
                    })
        
            }

    handleRejectClick = event => {
        let newEmployeeRecordsList = []
        this.setState({newEmployeeRecordsList: [],isLoading:true})
        for (let i = 0; i < this.state.employeeRecordListGrid.length; i++) {
            if(this.state.employeeRecordListGrid[i]["isModified"] == 1){
            
            if(this.state.userinfo.roleId== 5)
                {this.state.employeeRecordListGrid[i]["statusId"] ="3"}
            if(this.state.userinfo.roleId== 1 || this.state.userinfo.roleId== 1)
                { this.state.employeeRecordListGrid[i]["statusId"] ="5"}

            const obj = {
                ApprovalId:this.state.employeeRecordListGrid[i]["id"],
                StatusId: this.state.employeeRecordListGrid[i]["statusId"]
            };

                this.state.newEmployeeRecordsList.push(obj);
            }
        }

        const approveParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "approvedEmployees":this.state.newEmployeeRecordsList
            };
            axios
                .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/SubmitEmployeeRecordForApproval", approveParams)
                .then(res => {
                    const data = res.data;
                    this.setState({isLoading:false})
                    this.refreshPage();
                    if(data.status=="1"){
                        this.setState({
                                isloading:false,
                                alerttype:"Success!",
                                isshow:true,
                                color:"success",
                                message: data.message,
                                fade:true
                            });
                        }
                        else {
                            this.setState({
                                isloading:false,
                                alerttype:"Error!",
                                isshow:true,
                                color:"danger",
                                message: data.message,
                                fade:true
                            });
                        }
                    })
        
            }
    GridDataModified(oldValue, newValue, id, column) {
        this.state.employeeRecordListGrid.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }
    IsDecimal(val)
    {
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        return !regex.test(val)
    }
    render() {
        const cols = [
            {
                dataField: 'status',
                text: 'STATUS',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'20%'}
            },
            {
                dataField: 'createdDate',
                text: 'SUBMITTED DATE',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'20%',whiteSpace:'nowrap'}},
                style:{textAlign:'center',width:'20%'}
            },
            {
                dataField: 'employeeName',
                text: 'EMPLOYEE NAME',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'20%'}
            },
            {
                dataField: 'clientName',
                text: 'CLIENT NAME',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'20%'},
            },
            {
                dataField: 'locationId',
                text: 'BRANCH',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'20%'},
                formatter: (cell, row) => {
                    if(row.locationId!='' && row.locationId!=null){
                        if(this.state.locationDDL.filter(x => x.value == cell).length>0)
                            return this.state.locationDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    getOptions: (setOptions, { row, column }) => {
                        return this.state.locationDDL.filter(x=>x.clientname==row.clientName);
                    }
                }
            },
            {
                dataField: 'salaryOffered',
                text: 'SALARY RATE',
                editable:true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%',whiteSpace:'nowrap'}},
                style:{textAlign:'right',width:'15%'},
                validator: (newValue, row, column) => {
                    if (this.IsDecimal(newValue)) {
                      return {
                        valid: false
                      };
                    }
                    if(newValue.split(".").length>2)
                    return {
                        valid: false
                      };
                    return true;
                }
            },
            {
                dataField: 'seaValue',
                text: 'SEA RATE',
                editable:true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%',whiteSpace:'nowrap'}},
                style:{textAlign:'right',width:'15%'},
                validator: (newValue, row, column) => {
                    if (this.IsDecimal(newValue)) {
                      return {
                        valid: false
                      };
                    }
                    if(newValue.split(".").length>2)
                    return {
                        valid: false
                      };
                    return true;
                }
            },
            {
                dataField: 'colaValue',
                text: 'COLA RATE',
                editable:true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%',whiteSpace:'nowrap'}},
                style:{textAlign:'right',width:'15%'},
                validator: (newValue, row, column) => {
                    if (this.IsDecimal(newValue)) {
                      return {
                        valid: false
                      };
                    }
                    if(newValue.split(".").length>2)
                    return {
                        valid: false
                      };
                    return true;
                }
            },
            {
                dataField: 'ecolaValue',
                text: 'ECOLA RATE',
                editable:true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%',whiteSpace:'nowrap'}},
                style:{textAlign:'right',width:'15%'},
                validator: (newValue, row, column) => {
                    if (this.IsDecimal(newValue)) {
                      return {
                        valid: false
                      };
                    }
                    if(newValue.split(".").length>2)
                    return {
                        valid: false
                      };
                    return true;
                }
            },
            {
                dataField: 'positionId',
                text: 'JOB POSITION',
                editable:true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'20%',whiteSpace:'nowrap'}},
                style:{textAlign:'center',width:'20%'},
                formatter: (cell, row) => {
                    if(row.positionId!='' && row.positionId!=null){
                        if(this.state.positionDDL.filter(x => x.value == cell).length>0)
                            return this.state.positionDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.positionDDL
                }
            },
            {
                dataField: 'employeeStatusTypeId',
                text: 'EMPLOYMENT STATUS',
                editable:true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'20%'},
                formatter: (cell, row) => {
                    if(row.employeeStatusTypeId!='' && row.employeeStatusTypeId!=null){
                        if(this.state.employeeStatusDDL.filter(x => x.value == cell).length>0)
                            return this.state.employeeStatusDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.employeeStatusDDL
                }
            },
            {
                dataField: 'payCardTypeId',
                text: 'PAY CARD TYPE',
                editable:true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%',whiteSpace:'nowrap'}},
                style:{textAlign:'center',width:'15%'},
                formatter: (cell, row) => {
                    if(row.payCardTypeId!='' && row.payCardTypeId!=null){
                        if(this.state.paycardTypeDDL.filter(x => x.value == cell).length>0)
                            return this.state.paycardTypeDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.paycardTypeDDL
                }
            },
            {
                dataField: 'payCardNumber',
                text: 'PAY CARD NUMBER',
                editable:true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%',whiteSpace:'nowrap'}},
                style:{textAlign:'center',width:'15%'}
            
            },
            {
                dataField: 'contractDateStart',
                text: 'CONTRACT DATE START',
                editable:true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%',whiteSpace:'nowrap'}},
                style:{textAlign:'center',width:'15%'},
                formatter: (cell) => {
                    if(cell!=""){
                        let dateObj = cell;
                        if (typeof cell !== 'object') {
                        dateObj = new Date(cell);
                        }
                        return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${('0' + dateObj.getUTCDate()).slice(-2)}/${dateObj.getUTCFullYear()}`;
                    }
                },
                editor: {
                type: Type.DATE
                }
            },
            {
                dataField: 'contractDateEnd',
                text: 'CONTRACT DATE END',
                editable:true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%',whiteSpace:'nowrap'}},
                style:{textAlign:'center',width:'15%'},
                formatter: (cell) => {
                    if(cell!=""){
                        let dateObj = cell;
                        if (typeof cell !== 'object') {
                        dateObj = new Date(cell);
                        }
                        return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${('0' + dateObj.getUTCDate()).slice(-2)}/${dateObj.getUTCFullYear()}`;
                    }
                },
                editor: {
                type: Type.DATE
                }
            },
            {
                dataField: 'dateOfDeactivation',
                text: 'DATE OF DEACTIVATION',
                editable:true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%',whiteSpace:'nowrap'}},
                style:{textAlign:'center',width:'15%'},
                formatter: (cell) => {
                    if(cell!=""){
                        let dateObj = cell;
                        if (typeof cell !== 'object') {
                        dateObj = new Date(cell);
                        }
                        return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${('0' + dateObj.getUTCDate()).slice(-2)}/${dateObj.getUTCFullYear()}`;
                    }
                },
                editor: {
                type: Type.DATE
                }
            },
            // {
            //     dataField: 'action',
            //     text: 'ACTION',
            //     editable:false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'10%'}},
            //     style:{textAlign:'center',width:'15%'},
            //     formatter   :   (cell, row, isSelect) => {
            //             return (
            //                 <NavLink to={{pathname:"/ViewRecord",params:{data:row} }}>
            //                     <Button variant="link" style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
            //                     href="/ViewRecord"
            //                     >View</Button>
            //                 </NavLink>
            //             );
            //     },        
            // }
        ]   
       
       
            const selectRow = {
                mode: 'checkbox',
                //clickToSelect: true,
                clickToSelectAndEditCell: true,
                onSelect: (row, isSelect, rowIndex, e) => {
                            let disable = true
                            //row.isModified = isSelect ? "1" : "0"
                            this.state.employeeRecordListGrid.map(function(item,idx){
                                if(item.id===row.id)
                                {
                                    if(row.statusId==="1" || row.statusId==="2"){
                                        item.isModified = isSelect ? "1" : "0"
                                    }
                                }
                                if (item.isModified=="1")
                                disable =  false
                            })
                            this.setState({disablebutton:disable})
                        },
                onSelectAll: (isSelect, rows) => {
                    rows.map(function(itm){
                        itm.isModified = isSelect ? "1" : "0"
                    })
                }
              };
              
    
            const rowEvents = {
                onClick: (e, row, rowIndex) => {
                  //alert(e.row);
                  
                }
            };

        return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>MANNING >> EMPLOYEE RECORDS (APPROVALS)</Card.Header>
                            <Card.Body>
                                <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Row>
                                        <Col sm={12} className="mt-3">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangeClient}
                                                options={this.state.clientDDL}
                                                placeholder="SELECT CLIENT"
                                            />
                                        </Col> 
                                        <Col sm={12} className="mt-3">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangeStatus}
                                                options={this.state.statusDDL}
                                                placeholder="SELECT STATUS"
                                            /> 
                                        </Col>
                                        <Col sm={12} className="mt-3">
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.handleChangeEmployee}
                                            options={this.state.employeeRecordList}
                                            placeholder="SELECT EMPLOYEE"
                                            />       
                                        </Col>
                                    </Form.Row>
                                    <ButtonToolbar sm={12} className="mt-3">
                                        <Button className="ml-auto noser-button" variant="primary" onClick={this.handleSearchClick}>
                                            Search
                                        </Button>
                                    </ButtonToolbar>
                                    <div className="mt-3">
                                        <Card.Header>EMPLOYEE LIST</Card.Header>
                                        <BootstrapTable
                                            ref="tbl"
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            keyField = "id"
                                            data = { this.state.employeeRecordListGrid }
                                            columns = { cols}
                                            selectRow = { selectRow }
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column)
                                                }
                                            })}
                                            rowEvents={ rowEvents }
                                        />
                                     </div>
                                     <ButtonToolbar sm={12} className="mt-3">
                                        <Button disabled={this.state.disablebutton} variant="success" className="ml-auto noser-button-mr1" onClick={this.handleApproveClick}>
                                            Approve
                                        </Button>
                                        <Button disabled={this.state.disablebutton} variant="danger" className="noser-button" onClick={this.handleRejectClick}>
                                           Reject
                                        </Button>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                    <NoserLoading show={this.state.isloading} />
            </div> 
            
        )
    }
}

export default ManningRecordApprove;