import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, ReactDOM,
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Tabs, Tab, Accordion,Redirect
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import Resizer from "react-image-file-resizer";
import paramountLogo from "../../icon/paramountLogo.jpg";
import { Label } from 'reactstrap';
import {CipherString} from '../../noser-sec'

class OnlineLink extends Component {
    constructor(props) {
        super(props)
        this.state = {
           // url : "http://localhost:8080/onlineapplication",
            url : "",
            userinfo  :   [],
            token : CipherString('phr')
        }
    }

    componentDidMount() {
        console.log("OnlineLink screen")
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        let a = JSON.parse(sessionStorage.getItem("userData"))
        let tmp = AppConfiguration.Setting().publicurl + "onlineapplication"
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('phr')
        const cy = CipherString(token)
        let userId = parseFloat(a.userId)
        this.state.url = AppConfiguration.Setting().publicurl + "onlineapplication" +"?"+ this.state.token+"="+userId
      
        console.log(userId)
       let url = tmp +"?"+"phr"+"="+userId
        this.setState({url : url })

    }

    render() {

        return(
            <div>
                <Banner />
                <Container  className="mt-3" fluid>
                    <Card>
                        <Card.Header>ONLINE APPLICATION (LINK)</Card.Header>
                        <Card.Body>
                       
                            <Row className='mt-5'>
                                <Col sm={1}><h5 className='mt-2 no-text-transform ml-3'>Copy this link</h5></Col>
                                <Col>
                                    <Form.Control
                                        value={this.state.url}
                                        defaultValue={this.state.url}
                                        autoComplete="off"
                                        placeholder="" 
                                        className='no-text-transform'
                                        //readOnly
                                    />
                                </Col>
                               {/*  <Col sm={1}></Col> */}
                                <Col sm={6}></Col>
                            </Row>
                        </Card.Body>
                    </Card>

                </Container>
            </div>
        )
    }

};

export default OnlineLink;
