import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown, Image,FormControl} from 'react-bootstrap';

import  TrainingRegisterModal from '../Training/TrainingModal/TrainingRegisterModal'
import { Input } from 'reactstrap';
import { isDoWhileStatement } from '@babel/types';
import Pdf from "react-to-pdf";
import ReactToPrint from "react-to-print";
import paramountLogo from "../../icon/paramountLogo.jpg"
import paraFooter from "../../icon/paraFooter.jpg"
import ImgsViewer from 'react-images-viewer'

const ref = React.createRef();


class ComponentToPrint extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            regexp: /^[0-9\b]+$/,

            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            isLoading           :"",

            id                  :"",
            client              :"",
            employeeName        :"",
            position            :"",
            employeeNo          :"",
            location            :"",
            date                :"",
            irReferenceNo       :"",
            paragraph           :"",
            addedparagraph1     :"",
            addedparagraph2     :"",
            addedparagraph3     :"",
            addedparagraph4     :"",
            addedparagraph5     :"",
            addedparagraph6     :"",
            quotedStatement     :"",
            preparedBy          :"",
            prepareByPosition   :"",
            notedByPosition   :"",
            notedBy             :"",
            receivedBy          :"",
            isDraft             :"",
            isDeleted           :"",
            createdby           :"",
            createddate         :"",
            modifiedby          :"",
            modifieddate        :"",
            isModified          :"",

            articleNo               :"",
            articleName             :"",
            sectionNo               :"",
            infraction              :"",
            category                :"",

            articleNo2              :"", 
            articleName2            :"",
            sectionNo2              :"",
            infraction2             :"",
            category2               :"",

            sectionNo3              :"",
            infraction3             :"",
            category3               :"",

            irDateIssued            :"",
            dateRecieveByER         :"",
            referenceNoNTE          :"",
            cocReferences           :"",
            eSignatureNotedBy       :"",    
            eSignaturePreparedBy    :"",       
            
            departmentCodeAutocomplete:"",
            irIdCheck               :"",
            dateNow                 :"2/31/2021",
            paragraph1              :"",            
            logo                    :"https://assets.bossjob.com/companies/13161/logo/3Mwts6BLTZy6I6WhqQm9B79bIhq8dILG7uqq7SHl.png",
            lastName                :"",

        }
    }

    componentDidMount(){

        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        this.setState({isLoading:true,loadingText:"Loading client list..."})
        this.state.irIdCheck = JSON.parse(sessionStorage.getItem("irId"))
        this.getViewDraftMemo()
       
    }
    
    onChangePosition=(e)=>{
        this.setState({
            position:e.target.value
        })
    }

    getViewDraftMemo=()=>{
        let dataNTE = JSON.parse(sessionStorage.getItem("data"))
        let dataNTE1 = JSON.parse(sessionStorage.getItem("viewDraftMemoTmp"))

       /*  console.log("dataNTE")
        console.log(dataNTE.location)
        console.log("dataNTE") */

        let newDate = new Date(dataNTE.createddate)        
        let createddate = moment(newDate).format('MM');
        let createddate2 = moment(newDate).format('YYYY');
        let createddate3 = moment(newDate).format('DD');
        console.log(createddate)
        console.log(createddate2)

        let monthLeters = ""
        if(createddate == "01"){
            monthLeters = "January"
        }
        if(createddate == "02"){
            monthLeters = "February"
        }
        if(createddate == "03"){
            monthLeters = "March"
        }
        if(createddate == "04"){
            monthLeters = "April"
        }
        if(createddate == "05"){
            monthLeters = "May"
        }
        if(createddate == "06"){
            monthLeters = "June"
        }
        if(createddate == "07"){
            monthLeters = "July"
        }
        if(createddate == "08"){
            monthLeters = "August"
        }
        if(createddate == "09"){
            monthLeters = "September"
        }
        if(createddate == "10"){
            monthLeters = "October"
        }
        if(createddate == "11"){
            monthLeters = "November"
        }
        if(createddate == "12"){
            monthLeters = "December"
        }

        let wordsDate = createddate3+" "+monthLeters+" "+createddate2
        
        let getLastName1 = dataNTE.employeeName.split(",")[0]
        console.log("getLastName1.")
        console.log(getLastName1)

        this.setState({
          
            quotedStatement     :   dataNTE.quotedStatement,
            employeeName        :   dataNTE.employeeName,
            location            :   dataNTE.location,
            dateNow             :   wordsDate,
            preparedBy          :   dataNTE.preparedBy ,
            prepareByPosition   :   dataNTE.prepareByPosition ,
            notedBy             :   dataNTE.notedBy ,
            notedByPosition     :   dataNTE.notedByPosition ,

            paragraph           :   dataNTE.paragraph,
            addedparagraph1     :   dataNTE.addedparagraph1,
            addedparagraph2     :   dataNTE.addedparagraph2,
            addedparagraph3     :   dataNTE.addedparagraph3,
            addedparagraph4     :   dataNTE.addedparagraph4,
            addedparagraph5     :   dataNTE.addedparagraph5,
            addedparagraph6     :   dataNTE.addedparagraph6,
            articleNo           :   dataNTE.articleNo , 
            articleName         :   dataNTE.articleName,
            sectionNo           :   dataNTE.sectionNo,
            infraction          :   dataNTE.infraction,
            category            :   dataNTE.category,

            articleNo2           :   dataNTE.articleNo2 , 
            articleName2         :   dataNTE.articleName2,
            sectionNo2           :   dataNTE.sectionNo2,
            infraction2          :   dataNTE.infraction2,
            category2            :   dataNTE.category2,

            sectionNo3           :   dataNTE.sectionNo3,
            infraction3          :   dataNTE.infraction3,
            category3            :   dataNTE.category3,
            eSignatureNotedBy    :   dataNTE.eSignatureNotedBy,
            eSignaturePreparedBy :   dataNTE.eSignaturePreparedBy,
            lastName             :   getLastName1,

        })
      
    }

    setBack=()=>{
        let whenBack = 0
        sessionStorage.setItem("nextProcessCheck", JSON.stringify(whenBack)) 

    }

    render() {
        return( <>
            <Container className="mt-0" fluid>
              
                        <Form style={{backgroundColor:"#FFFFFF"}}>
                            <Col>
                                <Image src={paramountLogo} style={{width:"82%", height:"120%", marginLeft:"20px" , marginTop:"15px"}}/>
                            </Col>

                            <Form.Group as={Col} sm="10" className="mt-3">                                    
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22,marginTop:"1px", marginLeft:"25px"}}>
                                {this.state.dateNow}
                                </Form.Label>                
                            </Form.Group>

                            <Form.Group as={Col} sm="10">                                    
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22,marginTop:"-6px", marginLeft:"25px"}}>
                                    {this.state.employeeName}
                                </Form.Label> 
                                                     
                            </Form.Group>

                            <Form.Group as={Col} sm="10">                                    
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22,marginTop:"-6px", marginLeft:"30%"}}>
                                    SUBJECT
                                </Form.Label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22,marginTop:"-1px", marginLeft:"6px"}}>:</Form.Label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 22, marginLeft:"31px"}}>
                                    TERMINATION LETTER                             
                                </Form.Label>                                
                            </Form.Group>

                            <Form.Group as={Col} sm="10">                                    
                                <Form.Label className="no-text-transform-li" row sm="1" style={{fontWeight : "bold" ,fontSize: 22,marginTop:"-6px", marginLeft:"25px"}}>
                                    Dear Mr/Ms. {this.state.lastName}
                                </Form.Label> 
                                                     
                            </Form.Group>

                            { this.state.paragraph !== "" ? 
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Col>
                                        <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap", }}>
                                            {this.state.paragraph}
                                        </Form.Label>  
                                    </Col>                                    
                                </Form.Group>:null
                            }

                            { this.state.addedparagraph1 !== "" ? 
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Col>
                                        <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap", }}>
                                            {this.state.addedparagraph1}
                                        </Form.Label>  
                                    </Col>                                    
                                </Form.Group>:null
                            }

                            { this.state.addedparagraph2 !== "" ? 
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Col>
                                        <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px" , whiteSpace: "pre-wrap",}}>
                                            {this.state.addedparagraph2}
                                        </Form.Label>  
                                    </Col>                                    
                                </Form.Group>:null
                            }

                            { this.state.addedparagraph3 !== "" ? 
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Col>
                                        <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px" , whiteSpace: "pre-wrap",}}>
                                            {this.state.addedparagraph3}
                                        </Form.Label>  
                                    </Col>                                    
                                </Form.Group>:null
                            }

                            { this.state.addedparagraph4 !== "" ? 
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Col>
                                        <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px" , whiteSpace: "pre-wrap",}}>
                                            {this.state.addedparagraph4}
                                        </Form.Label>  
                                    </Col>                                    
                                </Form.Group>:null
                            }

                            { this.state.addedparagraph5 !== "" ? 
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Col>
                                        <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px" , whiteSpace: "pre-wrap",}}>
                                            {this.state.addedparagraph5}
                                        </Form.Label>  
                                    </Col>                                    
                                </Form.Group>:null
                            }

                            { this.state.addedparagraph6 !== "" ? 
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Col>
                                        <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px" , whiteSpace: "pre-wrap",}}>
                                            {this.state.addedparagraph6}
                                        </Form.Label>  
                                    </Col>                                    
                                </Form.Group>:null
                            }

                            

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column style={{fontWeight : "bold",fontSize: 22,marginLeft:"40px"}}>
                                    Prepared By :
                                </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 22, marginLeft:"20px",marginTop:"-25px"}}>
                                    <Col sm="5">                                       
                                       <Image src={this.state.eSignaturePreparedBy} style={{width:"240px", height:"70px", textAlign:"center",justifyContent:"center", }}/>
                                    </Col>
                                </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column style={{fontWeight : "bold",fontSize: 22, marginTop:"-20px",marginLeft:"40px"}}>
                                    {this.state.preparedBy}
                                </Form.Label>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 18, marginTop:"-19px", marginLeft:"40px"}}>
                                    {this.state.prepareByPosition}
                                </Form.Label>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="1" style={{fontWeight : "bold",fontSize: 22,marginLeft:"40px"}}>
                                    Noted By :
                                </Form.Label>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail"> </Form.Group>

                          {/*   <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 22, marginLeft:"40px"}}>
                                    <Col sm="10">
                                       
                                    </Col>
                                </Form.Label>
                            </Form.Group> */}

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 22, marginLeft:"20px",marginTop:"-25px"}}>
                                    <Col sm="5">                                       
                                       <Image src={this.state.eSignatureNotedBy} style={{width:"240px", height:"70px", textAlign:"center",justifyContent:"center", }}/>
                                    </Col>
                                </Form.Label>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 22,marginLeft:"40px" , marginTop:"-10px"}}>
                                    {this.state.notedBy}
                                </Form.Label>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 18, marginTop:"-19px", marginLeft:"40px"}}>
                                    {this.state.notedByPosition}
                                </Form.Label>
                            </Form.Group>

                           

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 22, marginLeft:"40px"}}>
                                    Received by :
                                </Form.Label>

                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                            <h1 className="mt-5" style={{fontWeight : "bold" ,backgroundColor:'#000000', width:"27%", height:"4px",marginLeft:"49px", }} > </h1>
                            </Form.Group>
                           
                            
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                
                                <Form.Label className="no-text-transform-li" sm="5" style={{fontWeight : "bold" ,fontSize: 22, marginLeft:"55px", borderTopColor:"#000000"}}>
                                    {this.state.employeeName}
                                </Form.Label> 
                               
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 18,marginTop:"-20px" , marginLeft:"38px"}}>
                                    Sign over printed name / Date
                                </Form.Label>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 18,marginTop:"-20px" , marginLeft:"38px"}}>
                                   cc:  201 File
                                </Form.Label>
                            </Form.Group>


                            { 
                             
                                <Image src={paraFooter} style={{marginLeft:"290px",/* width:"20%", */ height:"150px", textAlign:"center",justifyContent:"center"}}/>
                          
                            }
                        </Form>
                    
                
            </Container>
            <NoserLoading show={this.state.isloading} />

            <TrainingRegisterModal 
                show={this.state.modalTrainingRegisterShow}
                onHide={this.handleModalClose}
                onRefModal={ref => (this.child = ref)}
            />
           
            </>
            
        )
    }

}


class ExportAwolTermination extends React.Component {
    render() {
      return (
        <div>
        
            <ComponentToPrint ref={(el) => (this.componentRef = el)} />
            <Col sm="3" className="mt-3" as={Row}>
                <ReactToPrint
                    trigger={() => <button>Print this out!</button>}
                    content={() => this.componentRef}
                />
                 <ButtonToolbar sm={12}>
                    <NavLink to="/AwolMontoring">
                        <Button variant="danger" href="/AwolMontoring" style={{minWidth:'60px',marginLeft:"10px"}} onClick={this.setBack}>
                            Close
                        </Button>
                    </NavLink>
                </ButtonToolbar>
            </Col>
        </div>
      );
    }
  }

export default ExportAwolTermination
