import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import CasesCreate from '../Maintenance/CasesCreate';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { Input } from 'reactstrap';

 
class CriminalCasesMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade                        :true, 
            color                       :"",
            isshow                      :false,
            message                     :"",
            userinfo                    :[],
            isloading                   :false,
            alerttype                   :"",

            batchAutocomplete           :[],
            clientAutocomplete          :[],
            positionAutocomplete        :[],
            trainingAutocomplete        :[],
            batchNoAutocomplete         :[],
            dateFrom                    :"",
            dateTo                      :"",
            modalTrainingRegisterShow   :false,
            selectedClientName          :[],            
            getClientList               :[],
            employeeDetailList          :[],
            clientName                  :[],

            particularList              :[ {"id" : "Pending","name" : "Pending"} , {"id" : "Closed","name" : "Closed"}  ],
            selectedparticular          :'',
            selectedparticularId        :'',

            filterPending               :"Pending",
            filterResolved              :"Closed",
            totalCriminalCases          :"Total Criminal Cases",

            countPending                :"",
            countClosed                 :"",
            countTotal                  :"",

            statusTableList             :[],
            TotalStatus                 :[],
            summaryDetailsList2:[],
            retailSummaryGrid:[],

            retailgroupData             :[
                {
                    "id":"1",
                    "value":"RETAIL 1",
                    "label":"RETAIL 1"
                },
                {
                    "id":"2",
                    "value":"RETAIL 2",
                    "label":"RETAIL 2"
                },
                {
                    "id":"3",
                    "value":"RETAIL 3",
                    "label":"RETAIL 3"
                },
                {
                    "id":"4",
                    "value":"RETAIL 4",
                    "label":"RETAIL 4"
                },
                {
                    "id":"5",
                    "value":"NON-RETAIL 1",
                    "label":"NON-RETAIL 1"
                },
                {
                    "id":"6",
                    "value":"NON-RETAIL 2",
                    "label":"NON-RETAIL 2"
                },
                {
                    "id":"7",
                    "value":"INTERNAL",
                    "label":"INTERNAL"
                },

            ],
            selectedRetailGroup         :   "",
            counsel         :   "",


        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        //this.getMaintenanceCase()
        this.getClientList();;
        this.getCriminalCasesTotalAll()
        let checkTmp = "0"
        sessionStorage.setItem("checkUpdate", checkTmp)
        
        this.setState({ isloading : true,})
        
       

    }

    handlechangeRetailGroup=(e)=>{
       
        if(e.length==0)
        {
            this.setState({
                selectedRetailGroup    :   '',
            })
            return
        }
        this.state.selectedRetailGroup= e[0].value
      
    }

    getCriminalCasesPending =()=>{
       
        let filter_data ={'status':this.state.filterPending}
        const getParams ={
            "_collection" : "TestCriminalCases",
            "filter_data" : filter_data
        }
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            /* let data = res.data
        
            const itemUsersDataLists = this.buildList(data["TestCriminalCases"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            let countPendingTmp = this.state.itemUserDataList.length
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                let obj = {
                    'id'                :   this.state.itemUserDataList[i]['id'],
                    'client'            :   this.state.itemUserDataList[i]['client'],
                    'employeeName'      :   this.state.itemUserDataList[i]['employeeName'],
                    'position'          :   this.state.itemUserDataList[i]['position'],
                    'employeeNo'        :   this.state.itemUserDataList[i]['employeeNo'],
                    'location'          :   this.state.itemUserDataList[i]['location'],
                    'dateCaseFiled'     :   this.state.itemUserDataList[i]['dateCaseFiled'],
                    'caseFiled'         :   this.state.itemUserDataList[i]['caseFiled'],
                    'venue'             :   this.state.itemUserDataList[i]['venue'],
                    'SchedOfHearing'    :   this.state.itemUserDataList[i]['SchedOfHearing'],
                    'prosecutor'        :   this.state.itemUserDataList[i]['prosecutor'],
                    'legal'             :   this.state.itemUserDataList[i]['legal'],
                    'latestUpdate'      :   this.state.itemUserDataList[i]['latestUpdate'],
                    'updateDate'        :   this.state.itemUserDataList[i]['updateDate'],
                    'remarks'           :   this.state.itemUserDataList[i]['remarks'],
                    'status'            :   this.state.itemUserDataList[i]['status'],
                    'file'              :   this.state.itemUserDataList[i]['file'],   
                    'counsel'           :   this.state.itemUserDataList[i]['counsel'],                      
                    'isDraft'           :   this.state.itemUserDataList[i]['isDraft'],
                    'isDeleted'         :   this.state.itemUserDataList[i]['isDeleted'],
                    'createdby'         :   this.state.itemUserDataList[i]['createdby'],
                    'createddate'       :   this.state.itemUserDataList[i]['createddate'],
                    'modifiedby'        :   this.state.itemUserDataList[i]['modifiedby'],
                    'modifieddate'      :   this.state.itemUserDataList[i]['modifieddate'],
                    'isModified'        :   this.state.itemUserDataList[i]['isModified'],
                    
                }               

                List.push(obj)
                ////console.log("countPendingTmp")    
                ////console.log(countPendingTmp)
                ////console.log("countPendingTmp")                
                                 
            } 
            this.setState({
                countPending:countPendingTmp
            })   */

            const data = res.data
            var obj = (data);
            var res = [];
            
            data.TestCriminalCases.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                res.push(json)
            })
           // //console.log(" TestCriminalCases Parsed Json below")
            ////console.log(res)
            let countPendingTmp = JSON.stringify(res.length)
         
            this.setState({
                countPending:countPendingTmp
            }) 


            this.getCriminalCasesClosed();                           
            
        })
        .catch(error=>{
            //////console.log("error: " + error)
        })
    }

    getCriminalCasesClosed =()=>{
       
        let filter_data ={'status':this.state.filterResolved}
        const getParams ={
            "_collection" : "TestCriminalCases",
            "filter_data" : filter_data
        }
        //////console.log("axios start")
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
           /*  let data = res.data        
            const itemUsersDataLists = this.buildList(data["TestCriminalCases"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            let countClosedTmp = this.state.itemUserDataList.length
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                let obj = {
                    'id'                :   this.state.itemUserDataList[i]['id'],
                    'client'            :   this.state.itemUserDataList[i]['client'],
                    'employeeName'      :   this.state.itemUserDataList[i]['employeeName'],
                    'position'          :   this.state.itemUserDataList[i]['position'],
                    'employeeNo'        :   this.state.itemUserDataList[i]['employeeNo'],
                    'location'          :   this.state.itemUserDataList[i]['location'],
                    'dateCaseFiled'     :   this.state.itemUserDataList[i]['dateCaseFiled'],
                    'caseFiled'         :   this.state.itemUserDataList[i]['caseFiled'],
                    'venue'             :   this.state.itemUserDataList[i]['venue'],
                    'SchedOfHearing'    :   this.state.itemUserDataList[i]['SchedOfHearing'],
                    'prosecutor'        :   this.state.itemUserDataList[i]['prosecutor'],
                    'legal'             :   this.state.itemUserDataList[i]['legal'],
                    'latestUpdate'      :   this.state.itemUserDataList[i]['latestUpdate'],
                    'updateDate'        :   this.state.itemUserDataList[i]['updateDate'],
                    'remarks'           :   this.state.itemUserDataList[i]['remarks'],
                    'status'            :   this.state.itemUserDataList[i]['status'],
                    'file'              :   this.state.itemUserDataList[i]['file'],
                    'counsel'           :   this.state.itemUserDataList[i]['counsel'],                        
                    'isDraft'           :   this.state.itemUserDataList[i]['isDraft'],
                    'isDeleted'         :   this.state.itemUserDataList[i]['isDeleted'],
                    'createdby'         :   this.state.itemUserDataList[i]['createdby'],
                    'createddate'       :   this.state.itemUserDataList[i]['createddate'],
                    'modifiedby'        :   this.state.itemUserDataList[i]['modifiedby'],
                    'modifieddate'      :   this.state.itemUserDataList[i]['modifieddate'],
                    'isModified'        :   this.state.itemUserDataList[i]['isModified'],
                    
                }               

                               
            }  
            //List.push(obj)            
            this.setState({
                countClosed:countClosedTmp
            })  
            //this.getCriminalCasesTotalAll();  */  
            
            const data = res.data
            var obj = (data);
            var res = [];
            
            data.TestCriminalCases.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                res.push(json)
            })
            ////console.log(" TestCriminalCases Parsed Json below")
            ////console.log(res)
            let countClosedTmp = JSON.stringify(res.length)
            //console.log("countClosedTmp")
            //console.log(countClosedTmp)
            //console.log("countClosedTmp")

            this.setState({
                countClosed:countClosedTmp,
                //employeeDetailList:res
            }) 

            this.getAllStatusTable();
        })
        .catch(error=>{
            //////console.log("error: " + error)
        })
    }

    getCriminalCasesTotalAll =()=>{
       
        let filter_data ={}
        const getParams ={
            "_collection" : "TestCriminalCases",
            "filter_data" : filter_data
        }

        console.log("axios start")

        axios
        
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{

            const data = res.data           
            console.log("getTestCriminalCasesTotalAll") 
            console.log(data) 
            var obj = (data);
            var res = [];
            let List =[]
            let pendingList = []
            let settledList = []
            let DismissedList = []
            let arrynewRetail1 = []
            let arrynewRetail2 = []
            let arrynewRetail3 = []
            let arrynewRetail4 = []
            let arrynewnonRetail1 = []
            let arrynewnonRetail2 = []
            let arrynewInternal = []
            
            data.TestCriminalCases.map(function(itm,i){
                
                let schedOfhearing1 = ""
                let schedOfhearing2 = ""
                let purpose1 = ""
                let purpose2 = ""

                //    let strJson99 = "{" + itm.substring(0, itm.length).split("'),")[0]
                //    let cleanId = strJson99.split("ObjectId('")[1]
                //    console.log("cleanId")
                //    console.log(cleanId)
                let strJson2 = "{" + itm.substring(1085, itm.length)
                console.log("strJson2")
                console.log(strJson2)
                let strJson = "{" + itm.substring(46, itm.length)
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                //json.id = cleanId
             
                console.log("json")
                console.log(json)

                for (let i = 0; i < json.scheduleOfHearingCourtDetails.length; i++) {
                    console.log("loop d222222   ")
                    let obj={
                        "scheduleOfHearing" : json.scheduleOfHearingCourtDetails[i]["scheduleOfHearing"],
                        "purpose" : json.scheduleOfHearingCourtDetails[i]["purpose"],
                    }

                    if(schedOfhearing1 !==""){
                        console.log("if d222222   ")
                        schedOfhearing1 = schedOfhearing1 + json.scheduleOfHearingCourtDetails[i]["scheduleOfHearing"]+";"+ " "
                        purpose1 = purpose1 + json.scheduleOfHearingCourtDetails[i]["purpose"]+";"+ " "
                    }else{
                        console.log("else d222222   ")
                        schedOfhearing1 = json.scheduleOfHearingCourtDetails[i]["scheduleOfHearing"]+";"+ " "
                        purpose1 = json.scheduleOfHearingCourtDetails[i]["purpose"]+";" + " "
                    }
                    
                }

                for (let i = 0; i < json.scheduleOfHearingDetails.length; i++) {
                    console.log("loop d222222   ")
                    let obj={
                        "scheduleOfHearing" : json.scheduleOfHearingDetails[i]["scheduleOfHearing"],
                        "purpose" : json.scheduleOfHearingDetails[i]["purpose"],
                    }

                    if(schedOfhearing2 !==""){
                        console.log("if d222222   ")
                        schedOfhearing2 = schedOfhearing2 + json.scheduleOfHearingDetails[i]["scheduleOfHearing"]+";"+ " "
                        purpose2 = purpose2 + json.scheduleOfHearingDetails[i]["purpose"]+";"+ " "
                    }else{
                        console.log("else d222222   ")
                        schedOfhearing2 = json.scheduleOfHearingDetails[i]["scheduleOfHearing"]+";"+ " "
                        purpose2 = json.scheduleOfHearingDetails[i]["purpose"]+";" + " "
                    }
                    
                }

                if(json.status == "Pending"){
                    pendingList.push(json)
                }
                if(json.status == "Dismissed"){
                    DismissedList.push(json)
                }
                if(json.status == "Settled"){
                    settledList.push(json)
                }

               

                res.push(json)

                

                res.map(function(d){
                    d.schedOfhearing1 = schedOfhearing1
                    d.purpose1 = purpose1
                    d.schedOfhearing2 = schedOfhearing2
                    d.purpose2 = purpose2
                    d.action = "EDIT"

                    if(d.retailGroup === "RETAIL 1"){
                        arrynewRetail1.push(d)
                        console.log(d)
                    }
                    if(d.retailGroup === "RETAIL 2"){
                        arrynewRetail2.push(d)
                        console.log(d)
                    }
                    if(d.retailGroup === "RETAIL 3"){
                        arrynewRetail3.push(d)
                        console.log(d)
                    }
                    if(d.retailGroup === "RETAIL 4"){
                        arrynewRetail4.push(d)
                        console.log(d)
                    }
                    if(d.retailGroup === "NON-RETAIL 1"){
                        arrynewnonRetail1.push(d)
                        console.log(d)
                    }
                    if(d.retailGroup === "NON-RETAIL 1"){
                        arrynewnonRetail2.push(d)
                        console.log(d)
                    }
                    if(d.retailGroup === "INTERNAL"){
                        arrynewInternal.push(d)
                        console.log(d)
                    }

                })
            })
            console.log("countTotalTmp Parsed Json below")
            console.log(res)

            let countTotalTmp = JSON.stringify(res.length)
          
            console.log(" pendingList")
            console.log(pendingList.length)

            let statusList = [
                {
                    id:"0",
                    pending:pendingList.length,
                    settled:settledList.length,
                    dismissed:DismissedList.length,
                    total:countTotalTmp
                }
            ]

            //TABLE SUMMARY 2
            let retailGroupTmp = [{
                "retail1":String(arrynewRetail1.length),
                "retail2":String(arrynewRetail2.length),
                "retail3":String(arrynewRetail3.length),
                "retail4":String(arrynewRetail4.length),
                "internal":String(arrynewInternal.length),
                "nonRetail1":String(arrynewnonRetail1.length),
                "nonRetail2":String(arrynewnonRetail2.length),
            }]

            this.setState({
                statusTableList:statusList,
                employeeDetailList:res,
                retailSummaryGrid:retailGroupTmp
            })

           /*  const itemUsersDataLists = this.buildList(data["TestCriminalCases"])
            this.state.itemUserDataList  =  itemUsersDataLists

            let idListTmp = []
            for (let d = 0; d < this.state.itemUserDataList.length; d++) {
                let obj1 = {
                    'id'                :   this.state.itemUserDataList[d]['id'],
                }
                idListTmp.push(obj1)
            }

            for (let i = 0; i < res.length; i++) {
                let obj = {
                    'id'                :   idListTmp[i]["id"].replace("{","").replace("}",""),
                    'client'            :   res[i]['client'],
                    'employeeName'      :   res[i]['employeeName'],
                    'position'          :   res[i]['position'],
                    'employeeNo'        :   res[i]['employeeNo'],
                    'location'          :   res[i]['location'],
                    'dateCaseFiled'     :   res[i]['dateCaseFiled'],
                    'caseFiled'         :   res[i]['caseFiled'],
                    'venue'             :   res[i]['venue'],
                    'SchedOfHearing'    :   res[i]['SchedOfHearing'],
                    'prosecutor'        :   res[i]['prosecutor'],
                    'legal'             :   res[i]['legal'],
                    'latestUpdate'      :   res[i]['latestUpdate'],
                    'updateDate'        :   res[i]['updateDate'],
                    'remarks'           :   res[i]['remarks'],
                    'status'            :   res[i]['status'],
                    'file'              :   res[i]['file'],
                    'counsel'           :   res[i]['counsel'],
                    'action'            :   "edit",
                    
                    
                }  
                
                List.push(obj)   
                console.log("employeeDetail ListList")
                console.log(List)
                console.log("employeeDetailList List")             
                            
            }  */

            // this.setState({
            //     countTotal:countTotalTmp,
            //     employeeDetailList:List,
            // })


           // this.getCriminalCasesPending();
            

        })
        .catch(error=>{
            console.log("error: " + error)
        })
    }

    getAllStatusTable=()=>{

        let allStatus           =[]
        
        let id1Tmp              ='1'
        let id2Tmp              ='2'
        let id3Tmp              ='3'
        let id4Tmp              ='4'
        let id5Tmp              ='5'
        let isDraftTmp          ='0'
        let isDeletedTmp        ='0'
        let createdbyTmp        ='0'
        let createddateTmp      ='0'
        let modifiedbyTmp       ='0'
        let modifieddateTmp     ='0'
        let isModifiedTmp       ='0'

        
       

        let filterPendingTmp = this.state.filterPending
        let countPendingTmp = this.state.countPending
        let obj1 = {
            'id'                    :   id1Tmp,
            'status'                :   filterPendingTmp,
            'isDraft'               :   isDraftTmp,
            'isDeleted'             :   isDeletedTmp,
            'createdby'             :   createdbyTmp,
            'createddate'           :   createddateTmp,
            'modifiedby'            :   modifiedbyTmp,
            'modifieddate'          :   modifieddateTmp,
            'isModified'            :   isModifiedTmp,
            'count'                 :   this.state.countPending,
            //'persentage'            :   pendingListTmp['persentage'],
        }
        allStatus.push(obj1)

        //console.log("obj1")  
        //console.log(allStatus)  
        //console.log("obj1")  

        let filterResolvedTmp = this.state.filterResolved
        let countResolvedTmp = this.state.countClosed
        let obj2 = {
            'id'                    :   id2Tmp,
            'status'                :   filterResolvedTmp,
            'isDraft'               :   isDraftTmp,
            'isDeleted'             :   isDeletedTmp,
            'createdby'             :   createdbyTmp,
            'createddate'           :   createddateTmp,
            'modifiedby'            :   modifiedbyTmp,
            'modifieddate'          :   modifieddateTmp,
            'isModified'            :   isModifiedTmp,
            'count'                 :   this.state.countClosed,
            //'persentage'            :   pendingListTmp['persentage'],
        }
        allStatus.push(obj2)

        //console.log("obj2")  
        //console.log(allStatus)  
        //console.log("obj2")  

        let totalCriminalCasesTmp = this.state.totalCriminalCases
        let countTotalTmp = this.state.countTotal
        let obj3 = {
            'id'                    :   id3Tmp,
            'status'                :   totalCriminalCasesTmp,
            'isDraft'               :   isDraftTmp,
            'isDeleted'             :   isDeletedTmp,
            'createdby'             :   createdbyTmp,
            'createddate'           :   createddateTmp,
            'modifiedby'            :   modifiedbyTmp,
            'modifieddate'          :   modifieddateTmp,
            'isModified'            :   isModifiedTmp,
            'count'                 :   this.state.countTotal,
            //'persentage'            :   pendingListTmp['persentage'],
        }
        allStatus.push(obj3)

        //console.log("obj3")  
        //console.log(allStatus)  
        //console.log("obj3")  

        this.setState({
            statusTableList:allStatus,
            isloading : false,
        })
       
    }

    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            ////console.log("Client List start ");
            ////console.log(res.data);
            ////console.log("Client List end");
            const data = res.data
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }

    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        this.setState({
           // isloading:true,
            isshow:false,
            color:"",
            message:"",
            fade:true ,
            clientName: e[0].name
        })
       let clientTmp = e[0].name
       this.getCriminalCase();;
        
    }

   /*  showTrainingRegisterModal = (e) => {
        this.setState({modalTrainingRegisterShow: true})
    } */
    handleModalClose = (e) =>{
        //reload or refresh data here
        //alert("Data pass from child : " + e)
        this.setState({modalTrainingRegisterShow: false})
    }


    GridDataModified(oldValue, newValue, id, column) {
        ////////console.log(id)
        this.state.employeeDetailList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }

    getCriminalCase =()=>{
       
        let filter_data ={"client":this.state.selectedClientName}
        const getParams ={
            "_collection" : "TestCriminalCases",
            "filter_data" : filter_data
        }
        ////console.log("axios start")
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            /* let data = res.data
            console.log("res.data start")
            console.log(res.data)
            console.log("res.data end")
        
            const itemUsersDataLists = this.buildList(data["TestCriminalCases"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                let obj = {
                    'id'                :   this.state.itemUserDataList[i]['id'],
                    'client'            :   this.state.itemUserDataList[i]['client'],
                    'employeeName'      :   this.state.itemUserDataList[i]['employeeName'],
                    'position'          :   this.state.itemUserDataList[i]['position'],
                    'employeeNo'        :   this.state.itemUserDataList[i]['employeeNo'],
                    'location'          :   this.state.itemUserDataList[i]['location'],
                    'dateCaseFiled'     :   this.state.itemUserDataList[i]['dateCaseFiled'],
                    'caseFiled'         :   this.state.itemUserDataList[i]['caseFiled'],
                    'venue'             :   this.state.itemUserDataList[i]['venue'],
                    'SchedOfHearing'    :   this.state.itemUserDataList[i]['SchedOfHearing'],
                    'prosecutor'        :   this.state.itemUserDataList[i]['prosecutor'],
                    'legal'             :   this.state.itemUserDataList[i]['legal'],
                    'latestUpdate'      :   this.state.itemUserDataList[i]['latestUpdate'],
                    'updateDate'        :   this.state.itemUserDataList[i]['updateDate'],
                    'remarks'           :   this.state.itemUserDataList[i]['remarks'],
                    'status'            :   this.state.itemUserDataList[i]['status'],
                    'file'              :   this.state.itemUserDataList[i]['file'], 
                    'counsel'           :   this.state.itemUserDataList[i]['counsel'],                       
                    'isDraft'           :   this.state.itemUserDataList[i]['isDraft'],
                    'isDeleted'         :   this.state.itemUserDataList[i]['isDeleted'],
                    'createdby'         :   this.state.itemUserDataList[i]['createdby'],
                    'createddate'       :   this.state.itemUserDataList[i]['createddate'],
                    'modifiedby'        :   this.state.itemUserDataList[i]['modifiedby'],
                    'modifieddate'      :   this.state.itemUserDataList[i]['modifieddate'],
                    'isModified'        :   this.state.itemUserDataList[i]['isModified'],
                    
                }                

                List.push(obj)
                console.log(List)
                
                this.setState({
                    employeeDetailList:List

                })
                
            } */

            const data = res.data
            console.log("Raw Json111")
            console.log(data)
            console.log("End Raw Json111")
            var obj = (data);
            var res = [];
            
            data.TestCriminalCases.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                res.push(json)
            })

              
            this.setState({
                employeeDetailList:res

            })
                            

        })
        .catch(error=>{
            console.log("error: " + error)
        })
    } 
        
    buildList(data) {
        //////console.log("data start")
        let itemList =[]
    
        let idList                      =[]
        let clientList                  =[]
        let employeeNameList            =[]
        let positionList                =[]
        let employeeNoList              =[]
        let locationList                =[]
        let dateCaseFiledList           =[]
        let caseFiledList               =[]
        let venueList                   =[]
        let SchedOfHearingList          =[]
        let prosecutorList              =[]
        let legalList                   =[]
        let latestUpdateList            =[]
        let updateDateList              =[]
        let remarksList                 =[]
        let statusList                  =[]
        let fileList                    =[]
        let counselList                 =[]     
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
        
    
        for (let i = 0; i < data.length; i++) {
            
    
            let s1 = data[i].split("':")
            
            let idClean                     = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            /* let clientTmp                   = s1[2].split("',")[0]
            let employeeNameTmp             = s1[3].split("',")[0]
            let positionTmp                 = s1[4].split("',")[0]
            let employeeNoTmp               = s1[5].split("',")[0]
            let locationTmp                 = s1[6].split("',")[0]
            let dateCaseFiledTmp            = s1[7].split("',")[0]
            let caseFiledTmp                = s1[8].split("',")[0]
            let venueTmp                    = s1[9].split("',")[0]
            let SchedOfHearingTmp           = s1[10].split("',")[0]
            let prosecutorTmp               = s1[11].split("',")[0]
            let legalTmp                    = s1[12].split("',")[0]
            let latestUpdateTmp             = s1[13].split("',")[0]
            let updateDateTmp               = s1[14].split("',")[0]
            let remarksTmp                  = s1[15].split("',")[0]
            let statusTmp                   = s1[16].split("',")[0]
            let fileTmp                     = s1[17].split("',")[0] 
            let counselTmp                  = s1[18].split("',")[0]            
            let isDraftListTmp              = s1[19].split("',")[0]
            let isDeletedListTmp            = s1[20].split("',")[0]
            let createdbyListTmp            = s1[21].split("',")[0]
            let createddateListTmp          = s1[22].split("',")[0]
            let modifiedbyListTmp           = s1[23].split("',")[0]
            let modifieddateListTmp         = s1[24].split("',")[0]
            let isModifiedListTmp           = s1[25].split("',")[0] */

        
            idList.push(IdClean2.replace(" '",""))
           /*  clientList.push(clientTmp.replace(" '",""))
            employeeNameList.push(employeeNameTmp.replace(" '",""))
            positionList.push(positionTmp.replace(" '",""))
            employeeNoList.push(employeeNoTmp.replace(" '",""))
            locationList.push(locationTmp.replace(" '",""))
            dateCaseFiledList.push(dateCaseFiledTmp.replace(" '",""))
            caseFiledList.push(caseFiledTmp.replace(" '",""))
            venueList.push(venueTmp.replace(" '",""))
            SchedOfHearingList.push(SchedOfHearingTmp.replace(" '",""))
            prosecutorList.push(prosecutorTmp.replace(" '",""))
            legalList.push(legalTmp.replace(" '",""))
            latestUpdateList.push(latestUpdateTmp.replace(" '",""))
            updateDateList.push(updateDateTmp.replace(" '",""))
            remarksList.push(remarksTmp.replace(" '",""))
            statusList.push(statusTmp.replace(" '",""))
            fileList.push(fileTmp.replace(" '",""))  
            counselList.push(counselTmp.replace(" '",""))                     
            isDraftList.push(isDraftListTmp.replace(" '",""))
            isDeletedList.push(isDeletedListTmp.replace(" '",""))
            createdbyList.push(createdbyListTmp.replace(" '",""))
            createddateList.push(createddateListTmp.replace(" '",""))
            modifiedbyList.push(modifiedbyListTmp.replace(" '",""))
            modifieddateList.push(modifieddateListTmp.replace(" '",""))
            isModifiedList.push(isModifiedListTmp.replace(" '","").replace("'}","")) */
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
               /*  'client'                :   clientList[i],
                'employeeName'          :   employeeNameList[i],
                'position'              :   positionList[i],
                'employeeNo'            :   employeeNoList[i],
                'location'              :   locationList[i],
                'dateCaseFiled'         :   dateCaseFiledList[i],
                'caseFiled'             :   caseFiledList[i],
                'venue'                 :   venueList[i],
                'SchedOfHearing'        :   SchedOfHearingList[i],
                'prosecutor'            :   prosecutorList[i],
                'legal'                 :   legalList[i],
                'latestUpdate'          :   latestUpdateList[i],
                'updateDate'            :   updateDateList[i],
                'remarks'               :   remarksList[i],
                'status'                :   statusList[i],
                'file'                  :   fileList[i],
                'counsel'               :   counselList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i], */
            
            }
            itemList.push(obj)
        }
        
       
        return itemList
    }

    onChangeparticular = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedparticular     :   '',
                selectedparticularId   :   ''
            })
            return
        }
        this.state.selectedparticular = e[0].name
        this.state.selectedparticularId = e[0].id
       
        this.setState({
            isshow   :   false,
           selectedparticular : e[0].id

        })
        
    }

    getMonitoringList =(e)=>{
        let clear = []
        this.state.employeeDetailList = clear
       
        let filter_data ={}
        const getParams ={
            "_collection" : "TestCriminalCases",
            "filter_data" : filter_data
        }

        console.log("getParams")
        console.log(getParams)
        console.log("getParams")

        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
           

            const data = res.data
            
            var obj = (data);
            var res = [];
            
            data.TestCriminalCases.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                res.push(json)
            })

            console.log("Raw Json111")
            console.log(res)
            console.log("End Raw Json111")
            
            
            this.setState({
                employeeDetailList:res,
                isloading:false

            })   
            

        })
        .catch(error=>{
            console.log("error: " + error)
        })
    }

    handleToCriminalCases=(row)=>{
        console.log(row.id)

        let rowtmp = row.id

        sessionStorage.setItem("updateCriminalCases", JSON.stringify(row))
        let checkTmp = "1"
        sessionStorage.setItem("checkUpdate", checkTmp)
        
    }

    handleChangeSearch = () => {
       

        let _fdata = this.buildFilterData()
        let _filterData = _fdata.split(",")
        let _objfd = {}

        let jsonData = {};
        for (let i = 0; i < _filterData.length - 1; i++) { 
            let columnName = _filterData[i].split(":")[0]
            _objfd[columnName] = _filterData[i].split(":")[1];
        }
        console.log("filter_data")
        console.log(_objfd)

        this.handleSearch(_objfd);
    }

    buildFilterData(){
        let finalfilterData = ""
        let arry1 = []
        let NTEDate             = moment(this.state.dateApplied).format('MM/DD/YYYY');
        let NTEDate2            = ""
        let selectedsenaStaus   = ""
        let _selectedretailGroup = ""
        let selectedClientId    = ""
        let _counsel = ""

        
       
        if(this.state.selectedClientId !== ""){
            selectedClientId = "client" + ":" + this.state.selectedClientName+ ","/* + " " */
        }
        if(this.state.selectedparticular !== ""){
            selectedsenaStaus = "status" + ":" + this.state.selectedparticular+ ","/* + " " */
        }
        if(this.state.selectedRetailGroup !== ""){
            _selectedretailGroup = "retailGroup" + ":" + this.state.selectedRetailGroup+ ","/* + " " */
        }
        if(this.state.counsel !== ""){
            _counsel = "counsel" + ":" + this.state.counsel+ ","/* + " " */
        }
        let isDeletedTMP = "isDeleted" +":"+"0"

        finalfilterData =   _counsel + _selectedretailGroup + selectedsenaStaus + selectedClientId + isDeletedTMP
        return finalfilterData
    
    }

    handleSearch =(_obj)=>{
        let clear = []
        this.state.employeeDetailList = clear
        this.setState({ isloading:true })

        let filter_data ={}

        const getParams ={
            "_collection" : "TestCriminalCases",
            "filter_data" : _obj
        }

        console.log("axios start")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data
            console.log("res.data handleSearch")
            console.log(res.data)
            if(data.TestCriminalCases.length > 0){ 
                
                var obj = (data);
                var res = [];
                
                data.TestCriminalCases.map(function(itm,i){
                    let strJson = "{" + itm.substring(46, itm.length)
                    let json = JSON.parse(strJson.replace(/'/g,'"'));
                    res.push(json)
                })

                console.log("res.map handleSearch")
                console.log(res)

                this.setState({
                    employeeDetailList: res,
                    isloading:false
                })
                
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    } 

    handleOnChangeCounsel=(e)=>{
        this.setState({
            counsel   : e.target.value
        })
        console.log(this.state.counsel)
    }

    render() {
        const { ExportCSVButton } = CSVExport;

        const statusColumn = [
            
            {
                dataField   : 'pending',
                text:'Pending',
                headerStyle : () => {
                    return { width  : "500px" };
                },
                style:{ whiteSpace: 'nowrap', }
            },
            {
                dataField: 'settled',
                text: 'Settled',
                headerStyle : () => {
                    return  { width  : "500px"};
                }
            },
            {
                dataField: 'dismissed',
                text: 'Dismissed',
                headerStyle : () => {
                    return  { width  : "500px"};
                }
            },
            {
                dataField: 'total',
                text: 'Total',
                headerStyle : () => {
                    return  { width  : "500px"};
                }
            },
            /* {
                dataField   : 'persentage',
                text        : '%',
                headerStyle : () => {
                    return { width  : "33.3333333333%" };
                }
            }, */
            /*
            {
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={this.showTrainingRegisterModal}
                        >View Details</Button>
                    );
                },
            }, */
        ]

        const employeeDetailColumn = [
            {
                dataField   : 'employeeName',
                text:"MEMBER'S NAME",
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'client',
                text: 'DEPT / CLIENT',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'position',
                text: 'POSITION',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'retailGroup',
                text: 'RETAIL GROUP',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'casetitle',
                text: 'CASE TITLE (P.O. Level)',
                headerStyle : () => {
                    return { width  : "12%" ,  whiteSpace: 'nowrap'};
                }
            },
            {
                dataField: 'dateCaseFiled',
                text: 'DATE CASE FILED (P.O. Level)',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'caseFiled',
                text: 'CASE FILED (P.O. Level)',
                headerStyle : () => {
                    return { width  : "12%" ,  whiteSpace: 'nowrap'};
                }
            },
            {
                dataField: 'caseNo',
                text: 'CASE No. (P.O. Level)',
                headerStyle : () => {
                    return { width  : "12%" ,  whiteSpace: 'nowrap'};
                }
            },
            {
                dataField: 'venue',
                text: 'VENUE (P.O. Level)',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'schedOfhearing2',
                text: 'DATE OF HEARING (P.O. Level)',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'purpose2',
                text: 'PURPOSE (P.O. Level)',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'prosecutor',
                text: 'PROSECUTOR (P.O. Level)',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'caseTitleDOJ',
                text: 'CASE TITLE (DOJ Level)',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'caseNoDOJ',
                text: 'CASE NO (DOJ Level)',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'venueDOJ',
                text: 'VENUE (DOJ Level)',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'selectedAppealFiled',
                text: 'PETITON/APPEAL FILED BY (DOJ Level)',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'dateAppealfiled',
                text: 'DATE OF FILING (DOJ Level)',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'selectedOppositionFiled',
                text: 'COMMENT/OPPOSITION (DOJ Level)',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'dateOppositionfiled',
                text: 'DATE OF FILING (DOJ Level)',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'casetitleCourt',
                text: 'CASE TITLE (COURT Level)',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'dateInfoFiled',
                text: 'DATE INFORMATION FILED (COURT Level)',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'caseFiledCourt',
                text: 'CASE FILED (COURT Level)',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'caseNoCourt',
                text: 'CASE NO (COURT Level)',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'court',
                text: 'COURT',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'venueCourt',
                text: 'VENUE (COURT Level)',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'schedOfhearing1',
                text: 'SCHEDULE OF HEARING (COURT Level)',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'purpose1',
                text: 'PURPOSE (COURT Level)',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'judge',
                text: 'JUDGE (COURT Level)',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'counsel',
                text: 'COUNSEL',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'status',
                text: 'CASE STATUS',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField: 'latestUpdate',
                text: 'LATEST UPDATE ',
                headerStyle : () => {
                    return { width  : "12%",  whiteSpace: 'nowrap' };
                }
            },



            
            {
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "11%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (cell)
                    return (
                        
                        <ButtonToolbar className="mt-5">                                               
                            <NavLink to="/CriminalCases" >
                                <Button variant="Link" onClick={(e)=> this.handleToCriminalCases(row)} href="/CriminalCases" style={{color: "#1c6dfe"}}>Edit</Button>&nbsp;&nbsp;
                            </NavLink>
                        </ButtonToolbar>  
                    );
                },
            },
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.employeeDetailList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        const selectstatusRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.statusTableList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };

        const statusColumn2 = [
            
            {
                dataField   : 'nonRetail1',
                text:'NON RETAIL 1',
                editable:false,
                headerStyle : () => {
                    return { width  : "230px"};
                }
            },
            {
                dataField   : 'nonRetail2',
                text:'NO RETAIL 2',
                editable:false,
                headerStyle : () => {
                    return { width  : "230px"};
                }
            },
            {
                dataField: 'retail1',
                text: 'RETAIL 1',
                editable:false,
                headerStyle : () => {
                    return { width  : "230px" };
                }
            },
            {
                dataField   : 'retail2',
                text        : 'RETAIL 2',
                editable:false,
                headerStyle : () => {
                    return { width  : "230px" };;
                }
            },
            {
                dataField   : 'retail3',
                text        : 'RETAIL 3',
                editable:false,
                headerStyle : () => {
                    return { width  : "230px" };;
                }
            },
            {
                dataField   : 'retail4',
                text        : 'RETAIL 4',
                editable:false,
                headerStyle : () => {
                    return { width  : "230px" };;
                }
            },
            {
                dataField   : 'internal',
                text        : 'INTERNAL',
                editable:false,
                headerStyle : () => {
                    return { width  : "230px" };;
                }
            },
            /*{
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={this.showTrainingRegisterModal}
                        >View Details</Button>
                    );
                },
            }, */
        ]
        
        return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>CRIMINAL CASSES MONITORING</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>

                                    <Card.Header>SUMMARY</Card.Header>
                                  
                                    
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                    
                                    </Form.Group>
                                   
                                    <ToolkitProvider
                                        striped
                                        hover
                                        condensed
                                        rowClasses="noser-table-row-class"
                                        rowEvents={ rowEvents }
                                        selectRow = { selectRow }
                                        keyField="id"   
                                        data={ this.state.statusTableList }
                                        columns={ statusColumn }
                                        exportCSV={ {
                                            fileName: "Criminal Cases Monitoring Summary 1.csv",
                                        } }
                                    >
                                        {
                                            props => (
                                                <div className="mt-1" >
                                                    <hr />
                                                    <BootstrapTable { ...props.baseProps } />
                                                    <ButtonToolbar>
                                                        <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export Summary</ExportCSVButton>
                                                    </ButtonToolbar>
                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>

                                    <ToolkitProvider
                                        keyField="id"   
                                        data={ this.state.retailSummaryGrid }
                                        columns={ statusColumn2 }
                                        exportCSV={ {
                                            fileName: "NOSER Retail Group.csv",
                                        } }
                                    >
                                        {
                                            props => (
                                                <div>
                                                    <hr />
                                                    <BootstrapTable { ...props.baseProps } />
                                                    <ButtonToolbar>
                                                    { <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export Report</ExportCSVButton>        }                                               
                                                        
                                                    </ButtonToolbar>
                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>

                                    <Card.Header className="mt-4">REPORT FILTERING</Card.Header>

                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        Client
                                    </Form.Label>
                                    <Col sm="10">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeClientList}
                                            options={this.state.getClientList}
                                            placeholder="Select Client"
                                        />
                                    </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        Status
                                    </Form.Label>
                                        <Col sm="10">
                                            <Typeahead
                                                labelKey='id'
                                                id="basic-example"
                                                onChange={this.onChangeparticular}
                                                options={this.state.particularList}
                                                //placeholder="select penalty type"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        Retail Group
                                    </Form.Label>
                                        <Col sm="10">
                                            <Typeahead
                                                labelKey='value'
                                                id="basic-example"
                                                onChange={this.handlechangeRetailGroup}
                                                selected={[this.state.selectedRetailGroup]}
                                                options={this.state.retailgroupData}
                                                //placeholder="select penalty type"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Counsel
                                            </Form.Label>
                                            <Col sm="10" >
                                                <Input
                                                    id="basic-example"
                                                    value={this.state.legal}
                                                    className="form-control"
                                                    onChange={this.handlelegal}
                                                    //placeholder="Enter Schedule Of Hearing"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                           
                                        </Form.Group> 
                                    

                                    <ButtonToolbar>
                                        <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleChangeSearch}>
                                            Search
                                        </Button> 
                                        <Button variant="primary" style={{minWidth:'60px', marginLeft:"3px"}} onClick={this.getCriminalCasesTotalAll}>
                                            refresh
                                        </Button> 
                                    </ButtonToolbar>
                                
                                
                                    <ToolkitProvider
                                        keyField="id"   
                                        data={ this.state.employeeDetailList }
                                        columns={ employeeDetailColumn }
                                        exportCSV={ {
                                            fileName: "Criminal Case Monitoring.csv",
                                        } }
                                    >
                                        {
                                            props => (
                                                <div>
                                                    <hr />
                                                    {/* <Button>123testi</Button> */}
                                                    <BootstrapTable { ...props.baseProps } />
                                                    <ButtonToolbar>
                                                        <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export Report</ExportCSVButton>                                                       
                                                    {/*  <Button variant="success" className="" style={{minWidth:'60px'}} onClick={this.handleUpdateClick}>
                                                            Save
                                                        </Button> */}&nbsp;&nbsp;
                                                        <NavLink to="/home">
                                                            <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                                                Close
                                                            </Button>
                                                        </NavLink>
                                                    </ButtonToolbar>
                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                    {/* <TrainingRegisterModal 
                        show={this.state.modalTrainingRegisterShow}
                        onHide={this.handleModalClose}
                    /> */}
            </div> 
        )
    }

}

export  default CriminalCasesMonitoring 
