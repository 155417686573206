import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import { Input } from 'reactstrap';

const showSecond = false;
const str = showSecond ? 'HH:mm:ss' : 'HH:mm';

class RecordNodaPenalty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            disableTakeExam :   true,
            disableSubmit   :   true,

            modalInsertPenalty: false,
            modalCOCReference: false,

            isLoading                   :"",

            selectedEmployeeName        :"",
            selectedEmployeeId          :[],
            selectedEmployeeNo          :[],
            selectedEmployeeBranch      :[],
            selectedPosition            :[],
            selectedEmployeeBranch      :[],

            getClientList               :[],
            getEmployeeList             :[],
            getEmployeeNoList           :[],
            position                    :[],
            location                    :[],
            explanationLetter           :[],

            saveEmployeeNo                      :"",
            currentDate                         :"",
            nodaRefNo                           :"",
            nodaDate                            :"",
            attachedIR                          :"",
            attachedSIGNEDNTE                   :"",
            attachedWRITTENEXPLANATIONS         :"",
            attachedPROOFS                      :"",
            attachedSIGNEDNOH                   :"",
            attachedCASESUMMARY                 :"",
            attachedNodaCopy                    :"",
            attachedIRBase64                    :"",
            attachedSIGNEDNTEBase64             :"",
            attachedWRITTENEXPLANATIONSBase64   :"",
            attachedPROOFSBase64                :"",
            attachedSIGNEDNOHBase64             :"",
            attachedCASESUMMARYBase64           :"",
            attachedNodaCopyBase64              :"",
            furtherAction                       :"",
            remarks                             :"",
            dateReceived                        :"",
            penalty                             :"",
            offenseSection                  :"",
            suspension                          :"",
            monitaryValue                       :"",
            applicableDateTo                    :"",
            applicableDateFrom                  :"",
            furtherActionsList                  :[],
            selectedfurtherAction               :'',
            selectedfurtherActionId             :'',
            checkBoxYes                         :'',
            checkBoxNo                          :false,
            disable                             :true,
            disableRemarks                      :true,
            disableAction                       :true,
            penaltyTypesList                    :[],
            selectedpenaltyType                 :'',
            selectedpenaltyTypeId               :'',
            suspensionInput                     :false,
            penaltyVar                          :"",
            terminationInput                    :false,
            terminationDate                     :"",
            disableEdit                         : false,
            employeePlaceHolder                 : "",
            clientPlaceHolder                   : "",
            cycleDays                           : "",
            caseStatus                          :[
                {
                    id : "1",
                    status: "Pending"
                },
                {
                    id : "2",
                    status: "Closed"
                },
            ],
            selectedCaseStatus:"",
            
            IRobjId:"",
            selectedClientId:"",
            selectedClientName:"",
            retailgroupData             :[
                {
                    "id":"1",
                    "name":"RETAIL 1"
                },
                {
                    "id":"2",
                    "name":"RETAIL 2"
                },
                {
                    "id":"3",
                    "name":"RETAIL 3"
                },
                {
                    "id":"4",
                    "name":"RETAIL 4"
                },
                {
                    "id":"5",
                    "name":"NON-RETAIL 1"
                },
                {
                    "id":"6",
                    "name":"NON-RETAIL 2"
                },
                {
                    "id":"7",
                    "name":"INTERNAL"
                },

            ],
            selectedRetailGroup         :   "",
            dateOfIncidentReport         :   "",
            disableSave     :   false,
            recordNODObjId:"",
            
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        console.log(this.state.userinfo)

        this.setState({isLoading:true,loadingText:"Loading client list..."})

        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();;
        //this.getfurtherAction();;
        this.getPenaltyType();;
        this.handleChangeSuspesionInput();
        let dataCheck = JSON.parse(sessionStorage.getItem("nextProcessCheck"))

        if(dataCheck == "1" || dataCheck === "1"){

            let IRData = JSON.parse(sessionStorage.getItem("IRData"))

           


            this.setState({
                clientPlaceHolder           :IRData.client,
                irReferenceNo               : IRData.irReferenceNo,
                IRobjId                     : IRData.id,
                selectedClientName          : IRData.client,
                selectedClientId            : IRData.ClientId,
                selectedEmployeeName        : IRData.employeeName,
                saveEmployeeNo              : IRData.employeeNo,
                selectedPosition            : IRData.position,
                selectedEmployeeBranch      : IRData.location,
                disableEdit                 : true,
                employeePlaceHolder         : IRData.employeeName,
                nodaRefNo                   : IRData.NODReferenceNo, 
                offenseSection              : IRData.section,
                dateOfIncidentReport        : IRData.dateOfIncident,    
                nodaDate        : new Date(IRData.dateOfNOD),  

                
            })

        }
        if(dataCheck == "2" || dataCheck === "2"){
            this.getRecordNODbyDocNo()
        }

        
    }

    getRecordNODbyDocNo=()=>{

        let IRData = JSON.parse(sessionStorage.getItem("IRData"))
        this.setState({  isloading:true })
      
        let  filterNOD=IRData.NODReferenceNo

        let filter_data ={"nodRefNo" : filterNOD }
        
        const getParams ={
            "_collection" : "RecordNodPenalty",
            "filter_data" : filter_data
        }

        console.log("axios start getNODbyDocNo")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data
            console.log("axios start getNODbyDocNo")
            console.log(data)
            if(data.RecordNodPenalty.length > 0){

                const itemUsersDataLists = this.buildListNOD(data["RecordNodPenalty"])
            
                this.state.itemUserDataList  =  itemUsersDataLists

                var obj = (data);
                var res = [];
                
                data.RecordNodPenalty.map(function(itm,i){
                    let strJson = "{" + itm.substring(46, itm.length)
                    let json = JSON.parse(strJson.replace(/'/g,'"'));
                    res.push(json)
                    console.log(json)
                })
                
                console.log("axios start res")
                console.log(res)
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                        
                    let obj = {

                        'id'                        :   this.state.itemUserDataList[i]['id'].replace('"', '').replace('"', ''),
                       
                    }

                    List.push(obj)
                    console.log("obj start obj")
                    console.log(List)
                }

                this.setState({
                    dateOfIncidentReport        : IRData.dateOfIncident, 
                    recordNODObjId: List[0].id,
                    isloading:false,
                    selectedClientName          : res[0].client,
                    selectedClientId            : res[0].clientId,
                    selectedEmployeeName        : res[0].employeeName,
                    selectedPosition            : res[0].position,
                    saveEmployeeNo              : res[0].employeeNo,
                    selectedEmployeeBranch      : res[0].location,
                    currentDate                 : new Date(res[0].currentDate),
                    nodaDate                    : new Date(res[0].nodDate),
                    nodaRefNo                   : res[0].nodRefNo,
                    selectedpenaltyType         : res[0].penalty,
                    monitaryValue               : res[0].monitaryValue,
                    offenseSection              : res[0].offenseSection,
                    selectedRetailGroup         : res[0].retailGroup,
                    selectedCaseStatus          : res[0].caseStatus,
                    cycleDays                   : res[0].cycleOfdays,

                    
                })
                
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    buildListNOD(data) {
        // console.log("data start")
        // console.log(data )
        let itemList =[]
    
        let idList                      =[]

        for (let i = 0; i < data.length; i++) {            
    
            let s1 = data[i].split("', ")
           
            
            let IdClean0                            = s1[0].split("': ")[1]           
            let idClean1                            = IdClean0.replace("ObjectId(","").replace(")","").replace("{","").replace(", 'recordNODRefNo" , "")

            idList.push(idClean1.replace("'","").replace("'","").replace(" ",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                         :   idList[i],
            
            }
            itemList.push(obj)

            console.log("itemList start")
            console.log(itemList )
            console.log("itemList" )
            
        }
        return itemList
    }

    getClientList(){

        this.setState({isloading:true})
        const getParams = {
            "IpAddress" :   "0.0.0.0",
            "ClientId"  :   this.state.userinfo.clientId,
            "UserId"    :   this.state.userinfo.userId,
        }

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            this.setState({
                isloading       :   false,
                getClientList   :   res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
        })

    }

    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        console.log("Client selectedClientId " + this.state.selectedClientId );
        console.log("Client selectedClientName " + this.state.selectedClientName );
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})
        
        this.getEmployees();
    }

    getEmployees(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            console.log("Employee List start ");
            console.log(res.data);
            console.log("Employee List end");
            const data = res.data
            if(data.status=="1")
                this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
            else
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
        
    }

    onChangeEmployeesList = (e) => {
       

        if(e.length==0)
        {
            this.setState({getEmployeeListData: null, selectedPayrollpayPeriod: '', selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
          
            return
        }
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedEmployeeName = e[0].employeeName
        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeNo = e[0].employeeNo
        this.state.selectedEmployeeBranch = e[0].locationName
        this.state.selectedPosition = e[0].position
       
        console.log("Work selectedEmployeeName   " + this.state.selectedEmployeeNo );
        console.log("Employee List start ");
        console.log(this.state.selectedEmployeeNo);
        console.log("Employee List end");

      /*   if(this.state.selectedPayrollpayPeriod == ""){
            this.state.selectedPayrollPeriodsId = e[0].payPeriodId
            this.state.selectedPayrollpayPeriod = e[0].payPeriod
        } */

        //this.employeeTransactionsList();


        this.setState({
            position        :this.state.selectedPosition,
            location        :this.state.selectedEmployeeBranch,
            saveEmployeeNo  : e[0].employeeNo

        })
        this.setState({isloading:false})
    }

    onChangeEmployeesNo(e){
        this.setState({selectedEmployeeNo : e.target.value})
        console.log(e.target.value)
    }

    onChangePosition(e){
        this.setState({position : e.target.value})
        console.log(e.target.value)
    }

    onChangeLocation(e){
        this.setState({location : e.target.value})
        console.log(e.target.value)
    }

    handleInsertPenalty = () => {
        //alert("Test")
        this.setState({
            modalInsertPenalty:   true
        })
    }

    handleCocReference = ()=>{
        this.setState({
            modalCOCReference:   true
        })
    }

    handleModalClosePenalty = (e) =>{
        this.setState({
            modalInsertPenalty:   false,
        })
    }

    handleModalCloseCOC = (e) =>{
        this.setState({
            modalCOCReference: false,
        })
    }

    fileToBase64=(file, cb)=>{
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () { 
            cb(null , reader.result)
        }
        reader.onerror = function (error) {
            cb(error,null )
        }
    }


    handleChangeattachedIR=(e , target)=>{
        this.setState({
            attachedIR:e.target.value
        })

        if(e.target.files < 1 || !e.target.validity.valid){
            return
        }

        let base64data = ""
        this.fileToBase64(e.target.files[0], (err , result)=> {
            
            if(result){
                base64data = result
                console.log(result)
                this.state.attachedIRBase64 = result
            }
        })
        console.log("base64data")
        console.log(base64data)
        console.log("base64data")
    }

    handleChangeattachedSIGNEDNTE=(e , target)=>{
        this.setState({
            attachedSIGNEDNTE:e.target.value
        })

        if(e.target.files < 1 || !e.target.validity.valid){
            return
        }

        let base64data = ""
        this.fileToBase64(e.target.files[0], (err , result)=> {
            
            if(result){
                base64data = result
                console.log(result)
                this.state.attachedSIGNEDNTEBase64 = result
            }
        })
        console.log("base64data")
        console.log(base64data)
        console.log("base64data")
    }

    handleChangeattachedWRITTENEXPLANATIONS=(e , target)=>{
        this.setState({
            attachedWRITTENEXPLANATIONS:e.target.value
        })

        if(e.target.files < 1 || !e.target.validity.valid){
            return
        }

        let base64data = ""
        this.fileToBase64(e.target.files[0], (err , result)=> {
            
            if(result){
                base64data = result
                console.log(result)
                this.state.attachedWRITTENEXPLANATIONSBase64 = result
            }
        })
        console.log("base64data")
        console.log(base64data)
        console.log("base64data")
    }

    handleChangeattachedPROOFS=(e , target)=>{
        this.setState({
            attachedPROOFS:e.target.value
        })

        if(e.target.files < 1 || !e.target.validity.valid){
            return
        }

        let base64data = ""
        this.fileToBase64(e.target.files[0], (err , result)=> {
            
            if(result){
                base64data = result
                console.log(result)
                this.state.attachedPROOFSBase64 = result
            }
        })
        console.log("base64data")
        console.log(base64data)
        console.log("base64data")
    }

    handleChangeattachedSIGNEDNOH=(e , target)=>{
        this.setState({
            attachedSIGNEDNOH:e.target.value
        })

        if(e.target.files < 1 || !e.target.validity.valid){
            return
        }

        let base64data = ""
        this.fileToBase64(e.target.files[0], (err , result)=> {
            
            if(result){
                base64data = result
                console.log(result)
                this.state.attachedSIGNEDNOHBase64 = result
            }
        })
        console.log("base64data")
        console.log(base64data)
        console.log("base64data")
    }

    handleChangeAttachedNodaCopy=(e , target)=>{
        this.setState({
            AttachedNodaCopy:e.target.value
        })

        if(e.target.files < 1 || !e.target.validity.valid){
            return
        }

        let base64data = ""
        this.fileToBase64(e.target.files[0], (err , result)=> {
            
            if(result){
                base64data = result
                console.log(result)
                this.state.AttachedNodaCopyBase64 = result
            }
        })
        console.log("base64data")
        console.log(base64data)
        console.log("base64data")
    }

    handleChangeAttachedCaseSummary=(e , target)=>{
        this.setState({
            attachedCASESUMMARY:e.target.value
        })

        if(e.target.files < 1 || !e.target.validity.valid){
            return
        }

        let base64data = ""
        this.fileToBase64(e.target.files[0], (err , result)=> {
            
            if(result){
                base64data = result
                console.log(result)
                this.state.attachedCASESUMMARYBase64 = result
            }
        })
        console.log("base64data")
        console.log(base64data)
        console.log("base64data")
    }

    //save recordnodpenalty

    checkBeforeSave=()=>{
        let dataCheck = JSON.parse(sessionStorage.getItem("nextProcessCheck"))
        if(dataCheck == "2" || dataCheck === "2"){
           this.UpdateRecordNOD()
        }else{
            this.handleSave()
        }
    }

    handleSave=(e)=>{

        //console.log(this.state.furtherAction)
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');
        let _nodaDate = moment(this.state.nodaDate).format('MM/DD/YYYY');
        let dateReceived = moment(this.state.dateReceived).format('MM/DD/YYYY');
        let currentDate = moment(this.state.currentDate).format('MM/DD/YYYY');
        let cycleDays = moment(this.state.cycleDays).format('MM/DD/YYYY');
        let _newDate = moment(new Date()).format('MMDDYYYY');
        let refNo   =   "PHR" + this.state.saveEmployeeNo + _newDate
        //let irDate = new Date(IRData.dateOfIncident) + currentDate



        let data = {   

            "recordNODRefNo"                :   refNo,
            "client"                        :   this.state.selectedClientName,
            "clientId"                      :   this.state.selectedClientId,
            "employeeName"                  :   this.state.selectedEmployeeName,
            "position"                      :   this.state.selectedPosition,
            "employeeNo"                    :   this.state.saveEmployeeNo,
            "location"                      :   this.state.selectedEmployeeBranch,
            "currentDate"                   :   currentDate,
            "nodRefNo"                      :   this.state.nodaRefNo,
            "nodDate"                       :   _nodaDate,
            "penalty"                       :   this.state.selectedpenaltyType,
            "monitaryValue"                 :   this.state.monitaryValue,
            "offenseSection"                :   this.state.offenseSection,
            "retailGroup"                   :   this.state.selectedRetailGroup,
            "caseStatus"                    :   this.state.selectedCaseStatus,
            "cycleOfdays"                   :   String(this.state.cycleDays),
            "isDraft"                       :   "0",
            "isDeleted"                     :   "0",
            "createdby"                     :   this.state.userinfo.userId,
            "createddate"                   :   createddate,
            "modifiedby"                    :   'user007',
            "modifieddate"                  :   '0',
            "isModified"                    :   '0',
           
        }
        
        
        const addParams = {
          "_collection" : "RecordNodPenalty",
          "doc_data"    : data
        }

        console.log("save RNODPenalty")
        console.log(addParams)
        //this.getIRobjID()
       
        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/add", addParams)
        //.post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            console.log("success")
            console.log(data)
            
            this.getIRobjID() 
            if(this.state.attachedIRBase64.length > 0){
                this.saveIRDocs()
            }
            
            this.setState({
                
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Data had successfully Saved" ,
                fade            :   true,
                disableSave     :   true,
            
            })
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured on saving NOD Penalty while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })

        })
    }

    UpdateRecordNOD=(e)=>{

        //console.log(this.state.furtherAction)
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');
        let _nodaDate = moment(this.state.nodaDate).format('MM/DD/YYYY');
        let dateReceived = moment(this.state.dateReceived).format('MM/DD/YYYY');
        let currentDate = moment(this.state.currentDate).format('MM/DD/YYYY');
        let cycleDays = moment(this.state.cycleDays).format('MM/DD/YYYY');
        let _newDate = moment(new Date()).format('MMDDYYYY');
        let refNo   =   "PHR" + this.state.saveEmployeeNo + _newDate
        //let irDate = new Date(IRData.dateOfIncident) + currentDate



        let data = {   

            "recordNODRefNo"                :   refNo,
            "client"                        :   this.state.selectedClientName,
            "clientId"                      :   this.state.selectedClientId,
            "employeeName"                  :   this.state.selectedEmployeeName,
            "position"                      :   this.state.selectedPosition,
            "employeeNo"                    :   this.state.saveEmployeeNo,
            "location"                      :   this.state.selectedEmployeeBranch,
            "currentDate"                   :   currentDate,
            "nodRefNo"                      :   this.state.nodaRefNo,
            "nodDate"                       :   _nodaDate,
            "penalty"                       :   this.state.selectedpenaltyType,
            "monitaryValue"                 :   this.state.monitaryValue,
            "offenseSection"                :   this.state.offenseSection,
            "retailGroup"                   :   this.state.selectedRetailGroup,
            "caseStatus"                    :   this.state.selectedCaseStatus,
            "cycleOfdays"                   :   String(this.state.cycleDays),
            "isDraft"                       :   "0",
            "isDeleted"                     :   "0",
            "createdby"                     :   this.state.userinfo.userId,
            "createddate"                   :   createddate,
            "modifiedby"                    :   'user007',
            "modifieddate"                  :   '0',
            "isModified"                    :   '0',
           
        }
        
        
        const updateParams = {
          "_collection" : "RecordNodPenalty",
          "doc_data"    : data,
          "_id"         : this.state.recordNODObjId,
        }

        console.log(updateParams)
       
        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/update_one", updateParams)
        //.post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            console.log("success")
            console.log(data)
            
            this.getIRobjID() 
            if(this.state.attachedIRBase64.length > 0){
                this.saveIRDocs()
            }
            
            this.setState({
                
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Data had successfully Saved" ,
                fade            :   true,
                disableSave     :   true,
            
            })
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured on saving NOD Penalty while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })

        })
    }

    buildListEDMonitoring(data) {
        // console.log("data start")
        // console.log(data )
        console.log("data start")
        console.log(data )
        console.log("data" )
        let itemList =[]
    
        let idList                      =[]
        let quotedStatementList         =[]
        let dateOfNTEList               =[]
        let clientList                  =[]
        let createdbyList               =[]
        let createddateList             =[]
        let cycleOfDaysList             =[]
        let dateOfIncidentList          =[]
        let dateOfNODList               =[]
        let dateOfNOHList               =[]
        let dateRecordNodaPenaltyList   =[]
        let employeeNameList            =[]
        let employeeNoList              =[]
        let irReferenceNoList           =[]
        let isDeletedList               =[]
        let isDraftList                 =[]
        let isModifiedList              =[]
        let locationList                =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let positionList                =[]
        let retailgroupList             =[]
        let statusList                  =[]
        let articleList                 =[]
        let categoryList                =[]
        let sectionList                 =[]
        let NTEReferenceNoList                 =[]
        let NODReferenceNoList                 =[]
        let NOHReferenceNoList                 =[]
        let ClientIdList                 =[]

        let NTHoffenseList                 =[]
        let penaltyList                 =[]
        let monetaryChargeList                 =[]
        let statusOfIssueList                 =[]

        for (let i = 0; i < data.length; i++) {            
    
            let s1 = data[i].split("', '")
            console.log("s1 start")
            console.log(s1 )
            console.log("s1" )
          
            let IdClean0                            = s1[0].split("': ")[1]           
            let idClean1                            = IdClean0.replace("ObjectId(","").replace(")","").replace("{","").replace(", 'action","")  
            let quotedStatementTmp                  = s1[1].split("': ")[1]    
            let dateOfNTETmp                        = s1[2].split("': ")[1]           
            let clientTmp                        = s1[3].split("': ")[1]        
            let createdbyTmp                     = s1[4].split("': ")[1]          
            let createddateTmp                   = s1[5].split("': ")[1]
            let cycleOfDaysTmp                   = s1[6].split("': ")[1]
            let dateOfIncidentTmp                = s1[7].split("': ")[1]
            let dateOfNODTmp                     = s1[8].split("': ")[1]
            let dateOfNOHTmp                     = s1[9].split("': ")[1]
            let dateRecordNodaPenaltyTmp         = s1[10].split("': ")[1]
            let employeeNameTmp                  = s1[11].split("': ")[1]
            let employeeNoTmp                    = s1[12].split("': ")[1]
            let irReferenceNoTmp                 = s1[13].split("': ")[1]
            let isDeletedTmp                     = s1[14].split("': ")[1]
            let isDraftTmp                       = s1[15].split("': ")[1]
            let isModifiedListTmp                = s1[16].split("': ")[1]            
            let locationTmp                      = s1[17].split("': ")[1]
            let modifiedbyListTmp                = s1[18].split("': ")[1]
            let modifieddateListTmp              = s1[19].split("': ")[1]
            let positionTmp                      = s1[20].split("': ")[1]
            let retailgroupTmp                   = s1[21].split("': ")[1]
            let statusTmp                        = s1[22].split("': ")[1]
            let articleTmp                       = s1[23].split("': ")[1]
            let categoryTmp                      = s1[24].split("': ")[1]
            let sectionTmp                       = s1[25].split("': ")[1]
            let NTEReferenceNoTmp                = s1[26].split("': ")[1]
            let NODReferenceNoTmp                = s1[27].split("': ")[1]
            let NOHReferenceNoTmp                = s1[28].split("': ")[1]
            let ClientIdTmp                      = s1[29].split("': ")[1]
            let NTHoffenseTmp                    = s1[30].split("': ")[1]
            let penaltyTmp                       = s1[31].split("': ")[1]
            let monetaryChargeTmp                = s1[32].split("': ")[1]
            let statusOfIssueTmp                 = s1[33].split("': ")[1]

          

            idList.push(idClean1.replace("'","").replace("'","").replace(" ",""))
            quotedStatementList.push(quotedStatementTmp.replace("'",""))
            dateOfNTEList.push(dateOfNTETmp.replace("'","").replace(" ",""))           
            clientList.push(clientTmp.replace("'",""))
            createdbyList.push(createdbyTmp.replace("'",""))
            createddateList.push(createddateTmp.replace("'",""))
            cycleOfDaysList.push(cycleOfDaysTmp.replace("'",""))
            dateOfIncidentList.push(dateOfIncidentTmp.replace("'",""))
            dateOfNODList.push(dateOfNODTmp.replace("'",""))
            dateOfNOHList.push(dateOfNOHTmp.replace("'",""))
            dateRecordNodaPenaltyList.push(dateRecordNodaPenaltyTmp.replace("'",""))
            employeeNameList.push(employeeNameTmp.replace("'",""))
            employeeNoList.push(employeeNoTmp.replace("'",""))
            irReferenceNoList.push(irReferenceNoTmp.replace("'",""))
            isDeletedList.push(isDeletedTmp.replace("'",""))
            isDraftList.push(isDraftTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'",""))
            locationList.push(locationTmp.replace("'",""))
            positionList.push(positionTmp.replace("'",""))
            retailgroupList.push(retailgroupTmp.replace("'",""))
            statusList.push(statusTmp.replace("'",""))
            articleList.push(articleTmp.replace("'",""))
            categoryList.push(categoryTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            sectionList.push(sectionTmp.replace("'",""))
            NTEReferenceNoList.push(NTEReferenceNoTmp.replace("'",""))
            NODReferenceNoList.push(NODReferenceNoTmp.replace("'",""))
            NOHReferenceNoList.push(NOHReferenceNoTmp.replace("'",""))
            ClientIdList.push(ClientIdTmp.replace("'",""))

            NTHoffenseList.push(NTHoffenseTmp.replace("'",""))
            penaltyList.push(penaltyTmp.replace("'",""))
            monetaryChargeList.push(monetaryChargeTmp.replace("'",""))
            statusOfIssueList.push(statusOfIssueTmp.replace("'","").replace("'}",""))

          
            
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                         :   idList[i],
                'quotedStatement'            :   quotedStatementList[i],
                'dateOfNTE'                  :   dateOfNTEList[i],
                'client'                     :   clientList[i],              
                'cycleOfDays'                :   cycleOfDaysList[i],
                'dateOfIncident'             :   dateOfIncidentList[i],
                'dateOfNOD'                  :   dateOfNODList[i],
                'dateOfNOH'                  :   dateOfNOHList[i],
                'dateRecordNodaPenalty'      :   dateRecordNodaPenaltyList[i],
                'employeeName'               :   employeeNameList[i],
                'employeeNo'                 :   employeeNoList[i],
                'irReferenceNo'              :   irReferenceNoList[i],              
                'location'                   :   locationList[i],
                'position'                   :   positionList[i],
                'retailgroup'                :   retailgroupList[i],
                'status'                     :   statusList[i],
                'article'                    :   articleList[i],
                'category'                   :   categoryList[i],
                'section'                    :   sectionList[i],
                'isDraft'                    :   isDraftList[i],
                'isDeleted'                  :   isDeletedList[i],
                'createdby'                  :   createdbyList[i],
                'createddate'                :   createddateList[i],
                'modifiedby'                 :   modifiedbyList[i],
                'modifieddate'               :   modifieddateList[i],
                'isModified'                 :   isModifiedList[i],
                'NTEReferenceNo'             :   NTEReferenceNoList[i],
                'NODReferenceNo'             :   NODReferenceNoList[i],
                'NOHReferenceNo'             :   NOHReferenceNoList[i],
                'ClientId'                   :   ClientIdList[i],                
                "NTHoffense"                 :   NTHoffenseList[i],
                "penalty"                    :   penaltyList[i],
                "monetaryCharge"             :   monetaryChargeList[i],
                "statusOfIssue"              :   statusOfIssueList[i],
            
            }
            itemList.push(obj)
            console.log("itemList start")
            console.log(itemList )
            console.log("itemList" )
            
        }
        return itemList
    }

    getIRobjID=()=>{
        let IRData = JSON.parse(sessionStorage.getItem("IRData"))
        let filter_data ={'irReferenceNo':IRData.irReferenceNo}

        const getParams ={
            "_collection" : "EDMonitoring",
            "filter_data" : filter_data
        }

        console.log("axios start")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data

            if(data.EDMonitoring.length > 0){

                const itemUsersDataLists = this.buildListEDMonitoring(data["EDMonitoring"])
            
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                        
                    let obj = {

                        'id'                        :   this.state.itemUserDataList[i]['id'],
                        'quotedStatement'           :   this.state.itemUserDataList[i]['quotedStatement'],
                        'dateOfNTE'                 :   this.state.itemUserDataList[i]['dateOfNTE'],
                        'client'                    :   this.state.itemUserDataList[i]['client'],              
                        'cycleOfDays'               :   this.state.itemUserDataList[i]['cycleOfDays'],
                        'dateOfIncident'            :   this.state.itemUserDataList[i]['dateOfIncident'],
                        'dateOfNOD'                 :   this.state.itemUserDataList[i]['dateOfNOD'],
                        'dateOfNOH'                 :   this.state.itemUserDataList[i]['dateOfNOH'],
                        'dateRecordNodaPenalty'     :   this.state.itemUserDataList[i]['dateRecordNodaPenalty'],
                        'employeeName'              :   this.state.itemUserDataList[i]['employeeName'],
                        'employeeNo'                :   this.state.itemUserDataList[i]['employeeNo'],
                        'irReferenceNo'             :   this.state.itemUserDataList[i]['irReferenceNo'],              
                        'location'                  :   this.state.itemUserDataList[i]['location'],
                        'position'                  :   this.state.itemUserDataList[i]['position'],
                        'retailgroup'               :   this.state.itemUserDataList[i]['retailgroup'],
                        'status'                    :   this.state.itemUserDataList[i]['status'],
                        'article'                   :   this.state.itemUserDataList[i]['article'],
                        'category'                  :   this.state.itemUserDataList[i]['category'],
                        'section'                   :   this.state.itemUserDataList[i]['section'],
                        'isDraft'                   :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'                 :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'                 :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'               :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'                :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'              :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'                :   this.state.itemUserDataList[i]['isModified'],
                        'NTEReferenceNo'            :   this.state.itemUserDataList[i]['NTEReferenceNo'],
                        'NODReferenceNo'            :   this.state.itemUserDataList[i]['NODReferenceNo'],
                        'NOHReferenceNo'            :   this.state.itemUserDataList[i]['NOHReferenceNo'],
                        'ClientId'                    :   this.state.itemUserDataList[i]['ClientId'],
                        "NTHoffense"                :   this.state.itemUserDataList[i]['NTHoffense'],  
                        "penalty"                   :   this.state.itemUserDataList[i]['penalty'],  
                        "monetaryCharge"            :   this.state.itemUserDataList[i]['monetaryCharge'],  
                        "statusOfIssue"             :   this.state.itemUserDataList[i]['statusOfIssue'], 
                        'action'                    :   "1",

                    }
                    List.push(obj)
                }
                console.log("List ed")
                console.log(List)
                this.state.IRobjId = List[0].id
                this.setState({
                    IRobjId: List[0].id,
                    isloading:false
                })
    
                this.updateIRstatus(List)     
                
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    }

    updateIRstatus=(List)=>{
        console.log("List")
        console.log(List)
        let IRData = JSON.parse(sessionStorage.getItem("IRData"))
        
       
        let newDate = moment(new Date()).format('MM/DD/YYYY');
        let currentDate = moment(this.state.currentDate).format('MM/DD/YYYY');
        let cycleDays = moment(this.state.cycleDays).format('MM/DD/YYYY');

        let data = {
        
            "action"                : "1",
            "quotedStatement"       : IRData.quotedStatement,
            "dateOfNTE"             : IRData.dateOfNTE,
            "client"                : IRData.client,
            "createdby"             : this.state.userinfo.userId,
            "createddate"           : newDate,
            "cycleOfDays"           : this.state.cycleDays,
            "dateOfIncident"        : IRData.dateOfIncident,
            "dateOfNOD"             : IRData.dateOfNOD,
            "dateOfNOH"             : IRData.dateOfNOH,            
            "dateRecordNodaPenalty" : currentDate,
            "employeeName"          : IRData.employeeName,
            "employeeNo"            : IRData.employeeNo,
            "irReferenceNo"         : IRData.irReferenceNo,
            "isDeleted"             : IRData.isDeleted,
            "isDraft"               : IRData.isDraft,
            "isModified"            : "1",
            "location"              : IRData.location,
            "modifiedby"            : this.state.userinfo.userId,
            "modifieddate"          : newDate,
            "position"              : IRData.position,            
            "retailgroup"           : this.state.selectedRetailGroup,
            "status"                : "RecordNODPenalty",
            "article"               : IRData.article,
            "category"              : IRData.category,
            "section"               : IRData.section,
            "NTEReferenceNo"        : IRData.NTEReferenceNo,
            "NODReferenceNo"        : IRData.NODReferenceNo, 
            "NOHReferenceNo"        : IRData.NOHReferenceNo, 
            'ClientId'              : IRData.ClientId,
            "NTHoffense"            : this.state.offenseSection,
            "penalty"               : this.state.selectedpenaltyType,
            "monetaryCharge"        : this.state.monitaryValue,
            "statusOfIssue"         : this.state.selectedCaseStatus,

        }

        const updateParams = {

            "_collection" : "EDMonitoring",
            "doc_data"    : data,
            "_id"         : this.state.IRobjId,

        }

        console.log("updateEDMonitoring")
        console.log(updateParams)       
        console.log("updateEDMonitoring")

       /*  axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/update_one", updateParams)
        //.post("http://134.209.99.190:8088/action/update_one", updateParams)
        .then(res =>{

            this.setState({
                isloading           :   false,
                alerttype           :   "Success!",
                isshow              :   true,
                color               :   "success",
                message             :   "Data had successfully Saved" ,
                fade                :   true,
                disabledGenerate    :   false,
                disabledSave        :   true,
            
            })
           
            let nextProcessCheck = "0"
            sessionStorage.setItem("nextProcessCheck", JSON.stringify(nextProcessCheck)) 
        
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured on updating ED monitoring while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })

        }) */

    }

    saveIRDocs = () => {

        let _newDate = moment(new Date()).format('MMDDYYYY');
        let refNo   =   "PHR" + this.state.saveEmployeeNo + _newDate

        let data = {
            
            "recordNODPenaltyFile"  :   this.state.attachedIRBase64,
            "recordNODRefNo"        :   refNo,
        }

        const addParams = {
            "_collection" : "IRDocs",
            "doc_data"    : data
        }

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/add", addParams)
        //.post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            console.log("success")
            console.log(data)
            // this.setState({
            //     isloading       :   false,
            //     alerttype       :   "Success!",
            //     isshow          :   true,
            //     color           :   "success",
            //     message         :   "Data had successfully Save" ,
            //     fade            :   true
            
            // })
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured on saving document while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })

        }) 
    }

    saveSignedNTEDocs = () => {

        let _newDate = moment(new Date()).format('MMDDYYYY');
        let refNo   =   "PHR" + this.state.saveEmployeeNo + _newDate

        let data = {
            "recordNODRefNo"    :   refNo,
            "NTEDoc"             :   this.state.attachedSIGNEDNTEBase64,
        }

        const addParams = {
            "_collection" : "SignedNTEDocs",
            "doc_data"    : data
        }

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/add", addParams)
        //.post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            console.log("success")
            console.log(data)
            // this.setState({
            //     // currentDate         :"",
            //     // nodaRefNo           :"",
            //     // nodaDate            :"",
            //     // AttachedNodaCopy    :"",
            //     // furtherAction       :"",
            //     // remarks             :"",
            //     // dateReceived        :"",
            //     // penalty             :"",
            //     // offenseSection  :"",
            //     // suspension          :"",
            //     // monitaryValue       :"",
            //     // applicableDateTo    :"",
            //     // applicableDateFrom  :"",
            //     isloading       :   false,
            //     alerttype       :   "Success!",
            //     isshow          :   true,
            //     color           :   "success",
            //     message         :   "Data had successfully Saved" ,
            //     fade            :   true
            
            // })
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })

        }) 
    }

    saveWrittenExplinationDocs = () => {

        let _newDate = moment(new Date()).format('MMDDYYYY');
        let refNo   =   "PHR" + this.state.saveEmployeeNo + _newDate

        let data = {
            "recordNODRefNo"        :   refNo,
            "WrittenExplinationDoc" :   this.state.attachedWRITTENEXPLANATIONSBase64,
        }

        const addParams = {
            "_collection" : "WrittenExplinationDocs",
            "doc_data"    : data
        }

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/add", addParams)
        //.post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            console.log("success")
            console.log(data)
            // this.setState({
            //     // currentDate         :"",
            //     // nodaRefNo           :"",
            //     // nodaDate            :"",
            //     // AttachedNodaCopy    :"",
            //     // furtherAction       :"",
            //     // remarks             :"",
            //     // dateReceived        :"",
            //     // penalty             :"",
            //     // offenseSection  :"",
            //     // suspension          :"",
            //     // monitaryValue       :"",
            //     // applicableDateTo    :"",
            //     // applicableDateFrom  :"",
            //     isloading           :   false,
            //     alerttype           :   "Success!",
            //     isshow              :   true,
            //     color               :   "success",
            //     message             :   "Data had successfully Save" ,
            //     fade                :   true
            
            // })
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })

        }) 

    }

    saveProofsDocs = () => {

        let _newDate = moment(new Date()).format('MMDDYYYY');
        let refNo   =   "PHR" + this.state.saveEmployeeNo + _newDate

        let data = {
            "recordNODRefNo"    :   refNo,
            "ProofsDoc"         :   this.state.attachedPROOFSBase64,
        }

        const addParams = {
            "_collection" : "ProofsDocs",
            "doc_data"    : data
        }

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/add", addParams)
        //.post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            console.log("success")
            console.log(data)
            // this.setState({
                
            //     isloading       :   false,
            //     alerttype       :   "Success!",
            //     isshow          :   true,
            //     color           :   "success",
            //     message         :   "Data had successfully Save" ,
            //     fade            :   true
            
            // })
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })

        }) 

    }

    saveSignedNOHDocs = () => {

        let _newDate = moment(new Date()).format('MMDDYYYY');
        let refNo   =   "PHR" + this.state.saveEmployeeNo + _newDate

        let data = {
            "recordNODRefNo"    :   refNo,
            "NOHDoc"            :   this.state.attachedSIGNEDNOHBase64,
        }

        const addParams = {
            "_collection" : "SignedNOHDocs",
            "doc_data"    : data
        }

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/add", addParams)
        //.post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            console.log("success")
            console.log(data)
            // this.setState({
                
            //     isloading       :   false,
            //     alerttype       :   "Success!",
            //     isshow          :   true,
            //     color           :   "success",
            //     message         :   "Data had successfully Save" ,
            //     fade            :   true
            
            // })
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })

        }) 
    }

    saveCasesSummaryDocs = () => {

        let _newDate = moment(new Date()).format('MMDDYYYY');
        let refNo   =   "PHR" + this.state.saveEmployeeNo + _newDate

        let data = {
            "recordNODRefNo"    :   refNo,
            "CasesSummaryDoc"   :   this.state.attachedCASESUMMARYBase64,
        }

        const addParams = {
            "_collection" : "CasesSummaryDocs",
            "doc_data"    : data
        }

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/add", addParams)
        //.post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            console.log("success")
            console.log(data)
            // this.setState({
               
            //     isloading       :   false,
            //     alerttype       :   "Success!",
            //     isshow          :   true,
            //     color           :   "success",
            //     message         :   "Data had successfully Save" ,
            //     fade            :   true
            
            // })
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })

        }) 

    }

    saveSignedNODDocs = () => {

        let _newDate = moment(new Date()).format('MMDDYYYY');
        let refNo   =   "PHR" + this.state.saveEmployeeNo + _newDate

        let data = {
            "recordNODRefNo"    :   refNo,
            "CasesSummaryDoc"   :   this.state.AttachedNodaCopyBase64,
        }

        const addParams = {
            "_collection" : "SignedNODDocs",
            "doc_data"    : data
        }

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/add", addParams)
        //.post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            console.log("success")
            console.log(data)
            // this.setState({
            //     // currentDate         :"",
            //     // nodaRefNo           :"",
            //     // nodaDate            :"",
            //     // AttachedNodaCopy    :"",
            //     // furtherAction       :"",
            //     // remarks             :"",
            //     // dateReceived        :"",
            //     // penalty             :"",
            //     // offenseSection  :"",
            //     // suspension          :"",
            //     // monitaryValue       :"",
            //     // applicableDateTo    :"",
            //     // applicableDateFrom  :"",
            //     isloading       :   false,
            //     alerttype       :   "Success!",
            //     isshow          :   true,
            //     color           :   "success",
            //     message         :   "Data had successfully Save" ,
            //     fade            :   true
            
            // })
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })

        }) 

    }

    handleChangeDate = date => {
        ////console.log(date)
        this.setState({
            currentDate: date,
            isshow:false,
        })
        // console.log(this.state.currentDate)
        // let cycleDays = moment(date).format('MM/DD/YYYY');
        // let currentDate = new Date(cycleDays)

        // let irDate = new Date(this.state.dateOfIncidentReport)

        //

        const date1 = new Date(date);
        const date2 = new Date(this.state.dateOfIncidentReport);
        let qwertyu = this.getDifferenceInDays(date1, date2)
        console.log("qwertyu")
        console.log(qwertyu)
        this.state.cycleDays = String(qwertyu)
        
        // var result = new Date(date);
        // result.setDate(result.getDate() + qwertyu);
        // console.log("result")
        // console.log(result)


        //console.log(this.getDifferenceInDays(date1, date2));
        //console.log(getDifferenceInHours(date1, date2));
        //console.log(getDifferenceInMinutes(date1, date2));
        //console.log(getDifferenceInSeconds(date1, date2));

    } 
    getDifferenceInDays(date1, date2) {
        const diffInMs = Math.abs(date2 - date1);
        return diffInMs / (1000 * 60 * 60 * 24);
    }

    handleChangenodaRefNo=(e)=>{
        this.setState({
            nodaRefNo:e.target.value
        })
        console.log(e.target.value)
    }

    handleChangenodaDate = date => {
        ////console.log(date)
        this.setState({
            nodaDate: date,
            isshow:false,
        })
        console.log(this.state.nodaDate)
    } 

    handleChangeCycleDays = date => {
        ////console.log(date)
        this.setState({
            cycleDays: date,
            isshow:false,
        })
        console.log(this.state.cycleDays)
    } 

    getfurtherAction =(e)=>{
       
        let filter_data ={}
        const getParams ={
            "_collection" : "NodaFurtherAction",
            "filter_data" : filter_data
        }
            console.log("axios start")
            axios
            .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
            //.post("http://134.209.99.190:8088/action/get" , getParams)
            .then(res =>{
                let data = res.data
                console.log("res.data start")
                console.log(res.data)
                console.log("res.data end")
            
                const itemUsersDataLists = this.buildList(data["NodaFurtherAction"])
                
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                    let obj = {
                        'id'                    :   this.state.itemUserDataList[i]['id'],
                        'furtherAction'         :   this.state.itemUserDataList[i]['furtherAction'],
                       /*  'isDraft'               :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'             :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'             :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'           :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'            :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'          :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'            :   this.state.itemUserDataList[i]['isModified'], */
                        
                    }
                    List.push(obj)
                    console.log(List)
                    
                    this.setState({
                        furtherActionsList:List

                    })
                   
                }
                
            })
            .catch(error=>{
                console.log("error: " + error)
            })
    } 

    onChangecaseStatus = (e) => {
        if(e.length == 0)
        {
            this.setState({
                selectedCaseStatus     :   '',
            })
            return
        }
        
        this.state.selectedCaseStatus = e[0].status
       
        this.setState({
            selectedCaseStatus : e[0].status

        })
        console.log(this.state.selectedCaseStatus)
    }

    
        
    buildList(data) {
        //console.log("data start")
        let itemList =[]
    
        let idList                      =[]
        let furtherActionList           =[]
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
        
    
        for (let i = 0; i < data.length; i++) {
            
    
            let s1 = data[i].split("':")
            
            let idClean                     = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let furtherActionTmp            = s1[2].split("',")[0]
            let isDraftListTmp              = s1[3].split("',")[0]
            let isDeletedListTmp            = s1[4].split("',")[0]
            let createdbyListTmp            = s1[5].split("',")[0]
            let createddateListTmp          = s1[6].split("',")[0]
            let modifiedbyListTmp           = s1[7].split("',")[0]
            let modifieddateListTmp         = s1[8].split("',")[0]
            let isModifiedListTmp           = s1[9].split("',")[0]

        
            idList.push(IdClean2.replace("'",""))
            furtherActionList.push(furtherActionTmp.replace("'",""))
           
            isDraftList.push(isDraftListTmp.replace("'",""))
            isDeletedList.push(isDeletedListTmp.replace("'",""))
            createdbyList.push(createdbyListTmp.replace("'",""))
            createddateList.push(createddateListTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'furtherAction'         :   furtherActionList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }
        
       /*  console.log("itemList start")
        console.log(itemList)
        console.log("itemList End") */
        return itemList
    }

    handleChangeremarks=(e)=>{
        this.setState({
            remarks:e.target.value
        })
        console.log(e.target.value)
    }

    handleChangedateReceived = date => {
        ////console.log(date)
        this.setState({
            dateReceived: date,
            isshow:false,
        })
        console.log(this.state.dateReceived)
    } 

    handleChangeoffenseSection=(e)=>{
        this.setState({
            offenseSection:e.target.value
        })
        console.log(e.target.value)
    }

    handleChangesuspension=(e)=>{
        this.setState({
            suspension:e.target.value
        })
        console.log(e.target.value)
    }

    handleChangemonitaryValue=(e)=>{
        this.setState({
            monitaryValue:e.target.value
        })
        console.log(e.target.value)
    }

    applicableDateTo=(e)=>{
        this.setState({
            applicableDateTo:e.target.value
        })
        console.log(e.target.value)
    }

    handleChangeapplicableDateFrom=(e)=>{
        this.setState({
            applicableDateFrom:e.target.value
        })
        console.log(e.target.value)
    }

    onChangeRetailGroup=(e)=>{
        

        if(e.length==0)
        {
            this.state.selectedRetailGroup = ""
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedRetailGroup = e[0].name
       

        this.setState({isloading:false})
    }

    handleChangeCheckBoxNo=(e)=>{
        this.setState({
            checkBoxNo:!this.state.checkBoxNo
        })
        console.log(this.state.checkBoxNo)
       
       this.handleDisable()
    }
    
    handleChangeterminationDate=(e)=>{
        this.setState({
            terminationDate:e.target.value
        })
        console.log(e.target.value)
    }
    
    handleDisable(){
        if(this.state.checkBoxNo === true){
            this.setState({
                disable:true,
                dateReceived:"",
            })
        }else{
            this.setState({
                disable:false
            })
        }

    }

    handleChangeCheckBoxYes=(e)=>{
        this.setState({
            checkBoxYes:!this.state.checkBoxYes
        })
        console.log(this.state.checkBoxYes)
        if(this.state.checkBoxYes === true){
            this.setState({
                disableAction:true,
                disableRemarks:true,
                remarks:'',
            })
        }else{
            this.setState({
                disableAction:false,
                disableRemarks:false,
            })
        }
        
    }

    getPenaltyType =()=>{
       
        let filter_data ={}

        const getParams ={
            "_collection" : "PenaltyType",
            "filter_data" : filter_data
        }

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
            console.log("res.data penalty Type start")
            console.log(res.data)
            console.log("res.data penalty Type end")
        
            const itemUsersDataLists = this.buildListpenaltyType(data["PenaltyType"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                let obj = {
                    'id'                    :   this.state.itemUserDataList[i]['id'],
                    'penaltyType'           :   this.state.itemUserDataList[i]['penaltyType'],
                    /* 'isDraft'               :   this.state.itemUserDataList[i]['isDraft'],
                    'isDeleted'             :   this.state.itemUserDataList[i]['isDeleted'],
                    'createdby'             :   this.state.itemUserDataList[i]['createdby'],
                    'createddate'           :   this.state.itemUserDataList[i]['createddate'],
                    'modifiedby'            :   this.state.itemUserDataList[i]['modifiedby'],
                    'modifieddate'          :   this.state.itemUserDataList[i]['modifieddate'],
                    'isModified'            :   this.state.itemUserDataList[i]['isModified'], */
                    
                }
                List.push(obj)
                
                this.setState({
                    penaltyTypesList:List

                })

            }

        })
        .catch(error=>{
            console.log("error: " + error)
        })
    } 
        
    buildListpenaltyType(data) {
        //console.log("data start")
        let itemList =[]
    
        let idList                      =[]
        let penaltyTypeList             =[]
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
        
    
        for (let i = 0; i < data.length; i++) {
            
    
            let s1 = data[i].split("':")
            
            let idClean                     = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let penaltyTypeTmp              = s1[2].split("',")[0]
            let isDraftListTmp              = s1[3].split("',")[0]
            let isDeletedListTmp            = s1[4].split("',")[0]
            let createdbyListTmp            = s1[5].split("',")[0]
            let createddateListTmp          = s1[6].split("',")[0]
            let modifiedbyListTmp           = s1[7].split("',")[0]
            let modifieddateListTmp         = s1[8].split("',")[0]
            let isModifiedListTmp           = s1[9].split("',")[0]

        
            idList.push(IdClean2.replace("'",""))
            penaltyTypeList.push(penaltyTypeTmp.replace("'","").replace(" ",""))
           
            isDraftList.push(isDraftListTmp.replace("'",""))
            isDeletedList.push(isDeletedListTmp.replace("'",""))
            createdbyList.push(createdbyListTmp.replace("'",""))
            createddateList.push(createddateListTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'penaltyType'           :   penaltyTypeList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }
        
        return itemList
    }

    handleChangepenalty=(e)=>{
        if(e.length == 0)
        {
            this.setState({
                selectedpenaltyType     :   '',
                selectedpenaltyTypeId   :   ''
            })
            return
        }
        this.state.selectedpenaltyType = e[0].penaltyType
        this.state.selectedpenaltyTypeId = e[0].id
       
        this.setState({
            isshow              :   false,
            selectedpenaltyType :   e[0].penaltyType,
            penaltyVar          :   e[0].penaltyType
        })

        console.log(this.state.selectedpenaltyTypeId)
        console.log("this.state.selectedpenaltyTypeId")
        
      
    } 

    handleChangeSuspesionInput(){
        if(this.state.selectedpenaltyType === " TERMINATION"){
            this.setState({
                terminationInput:true
            })
            console.log("TERMINATION")
        }
        else if(this.state.selectedpenaltyType === " SUSPENSION"){
            this.setState({
                suspensionInput:true
            })
            console.log("this.state.suspensionInput")
            console.log(this.state.suspensionInput)
        }else{
            console.log("false handleChangeSuspesionInput")
            //console.log(this.state.suspensionInput)
        }

        if(this.state.selectedpenaltyType === " TERMINATION"){
            this.setState({
                suspensionInput:false
            })
        }else if(this.state.selectedpenaltyType === " SUSPENSION"){
            this.setState({
                terminationInput:false
            })
          
        }else if(this.state.selectedpenaltyType === ""){
            this.setState({
                terminationInput:false
            })
            this.setState({
                suspensionInput:false
            })
           
        }else{
            this.setState({
                terminationInput:false
            })
            this.setState({
                suspensionInput:false
            })
           
        }
       
    }

    render() {
        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>Record NOD Penalty</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Card.Header>Employee Details</Card.Header>
                                    <Form.Group as={Row} className="mt-4" controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Client
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClientList}
                                                options={this.state.getClientList}
                                                disabled={this.state.disableEdit}
                                                selected={[this.state.selectedClientName]}
                                                placeholder={this.state.clientPlaceHolder}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee
                                        </Form.Label>
                                        <Col sm="8">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.onChangeEmployeesList}
                                                options={this.state.getEmployeeList}
                                                selected={[this.state.selectedEmployeeName]}
                                                placeholder={this.state.employeePlaceHolder}
                                                disabled={this.state.disableEdit}
                                                
                                            />
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Emp No.
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName"
                                                value={this.state.saveEmployeeNo}
                                                //onChange={this.onChangePosition} 
                                                autoComplete="off"
                                                placeholder="Autofill"
                                                disabled={this.state.disableEdit}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position
                                        </Form.Label>
                                        <Col sm="6">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName"
                                                value={this.state.selectedPosition}
                                                onChange={this.onChangePosition} 
                                                autoComplete="off"
                                                placeholder="Autofill"
                                                disabled={this.state.disableEdit}
                                            />
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        </Form.Label>
                                        
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Location
                                        </Form.Label>
                                        <Col sm="11">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName"
                                                value={this.state.selectedEmployeeBranch}
                                                onChange={this.onChangeLocation} 
                                                autoComplete="off"
                                                disabled={this.state.disableEdit}
                                                placeholder="Auto Fill"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Date
                                        </Form.Label>
                                        <Col sm="2">
                                            <DatePicker
                                                ref='currentDate'
                                                selected={this.state.currentDate}
                                                onChange={this.handleChangeDate}
                                                minDate={this.minDate}
                                                value={this.props.currentDate}
                                                dateFormat={"MM/dd/yyyy"}
                                                placeholderText="Current Date"
                                                className="form-control"
                                            />
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            NOD Reference No.
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="nodaRefNo"
                                                value={this.state.nodaRefNo}
                                                onChange={this.handleChangenodaRefNo} 
                                                autoComplete="off"
                                                placeholder="Enter NODA Reference No."
                                                disabled={true}
                                            />
                                        </Col>
                                        <Col sm="4">
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            NOD DATE
                                        </Form.Label>
                                        <Col sm="2">
                                            <DatePicker
                                                ref='nodaDate'
                                                selected={this.state.nodaDate}
                                                onChange={this.handleChangenodaDate}
                                                minDate={this.minDate}
                                                value={this.props.nodaDate}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                disabled={this.state.disableEdit}
                                            />
                                        </Col>

                                        
                                                                                
                                    </Form.Group>

                                    <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                                        <Col sm="4">
                                            <label  style={{fontWeight : "bold"}}>ATTACH FILES: UPLOADING OF DOCS</label>
                                        </Col>        
                                    </Form.Group>

                                    <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                                        <Col sm="4">
                                            <label  style={{fontWeight : "bold"}}>Cases File</label>
                                            <Input type="file" style={{backgroundColor:"#008BFF",width:"150px"}} className="file" placeholder="attached file" variant="primary" onChange={this.handleChangeattachedIR} value={this.state.attachedIR} accept="application/pdf" /> 
                                        </Col>  
                                        {/* <Col sm="4">
                                            <label  style={{fontWeight : "bold"}}>SIGNED NTE</label>
                                            <Input type="file" style={{backgroundColor:"#008BFF",width:"150px"}} className="file" placeholder="attached file" variant="primary" onChange={this.handleChangeattachedSIGNEDNTE} value={this.state.attachedSIGNEDNTE} accept="application/pdf" /> 
                                        </Col>      
                                        <Col sm="4">
                                            <label  style={{fontWeight : "bold"}}>WRITTEN EXPLANATIONS</label>
                                            <Input type="file" style={{backgroundColor:"#008BFF",width:"150px"}} className="file" placeholder="attached file" variant="primary" onChange={this.handleChangeattachedWRITTENEXPLANATIONS} value={this.state.attachedWRITTENEXPLANATIONS} accept="application/pdf" /> 
                                        </Col>    */}
                                    </Form.Group>
                                    {/* <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                                       
                                        <Col sm="4">
                                            <label  style={{fontWeight : "bold"}}>PROOFS</label>
                                            <Input type="file" style={{backgroundColor:"#008BFF",width:"150px"}} className="file" placeholder="attached file" variant="primary" onChange={this.handleChangeattachedPROOFS} value={this.state.attachedPROOFS} accept="application/pdf" /> 
                                        </Col>       
                                        <Col sm="4">
                                            <label  style={{fontWeight : "bold"}}>SIGNED NOH</label>
                                            <Input type="file" style={{backgroundColor:"#008BFF",width:"150px"}} className="file" placeholder="attached file" variant="primary" onChange={this.handleChangeattachedSIGNEDNOH} value={this.state.attachedSIGNEDNOH} accept="application/pdf" /> 
                                        </Col> 
                                        <Col sm="4">
                                            <label  style={{fontWeight : "bold"}}>CASE SUMMARY</label>
                                            <Input type="file" style={{backgroundColor:"#008BFF",width:"150px"}} className="file" placeholder="attached file" variant="primary" onChange={this.handleChangeAttachedCaseSummary} value={this.state.attachedCASESUMMARY} accept="application/pdf" /> 
                                        </Col>    
                                    </Form.Group>
                                    <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                                        <Col sm="4">
                                            <label  style={{fontWeight : "bold"}}>SIGNED NOD</label>
                                            <Input type="file" style={{backgroundColor:"#008BFF",width:"150px"}} className="file" placeholder="attached file" variant="primary" onChange={this.handleChangeAttachedNodaCopy} value={this.state.attachedNodaCopy} accept="application/pdf" /> 
                                        </Col>        
                                    </Form.Group> */}

                                    {/* <Form.Label column sm="3" style={{fontWeight : "bold"}}>
                                        NODA Received and Acknowledge by Member
                                    </Form.Label>                                    

                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-2">
                                        <Col sm="1" style={{marginLeft:"50px", marginTop:"6px"}}>
                                            <Input
                                                type='checkbox'
                                                name='No'
                                                onChange={this.handleChangeCheckBoxYes}
                                            />
                                            <label style={{fontWeight : "bold" }}>No</label>
                                        </Col>                                       
                                        <Form.Label column sm="2" style={{fontWeight : "bold" ,marginLeft:"-10px"}}>
                                            Action
                                        </Form.Label>
                                        <Col sm="2">
                                            <Typeahead
                                                onChange={this.onChangefurtherAction}
                                                options={this.state.furtherActionsList} 
                                                placeholder="Select Further Action"
                                                labelKey='furtherAction'
                                                id="basic-example"
                                                disabled={this.state.disableAction}
                                              
                                            />
                                        </Col>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="remarks"
                                                value={this.state.remarks}
                                                onChange={this.handleChangeremarks} 
                                                autoComplete="off"
                                                placeholder="Remarks"
                                                disabled={this.state.disableRemarks}
                                            />
                                        </Col>                                                                                  
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Col sm="1" style={{marginLeft:"50px", marginTop:"6px"}}>
                                            <Input
                                                type='checkbox'
                                                name='Yes'
                                                onChange={this.handleChangeCheckBoxNo}
                                            />
                                            <label style={{fontWeight : "bold" }}>Yes</label>
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold" ,marginLeft:"-10px"}}>
                                            Date Received 
                                        </Form.Label>
                                        <Col sm="2">
                                            
                                            <DatePicker
                                                ref='dateReceived'
                                                selected={this.state.dateReceived}
                                                onChange={this.handleChangedateReceived}
                                                minDate={this.minDate}
                                                value={this.props.dateReceived}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                disabled={this.state.disable}
                                            />
                                        </Col>                                                                                                                        
                                    </Form.Group> */}

                                    <Card.Header>Applicable Penalty</Card.Header>

                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Penalty
                                        </Form.Label>
                                        <Col sm="3">
                                            <Typeahead
                                                onChange={this.handleChangepenalty}
                                                options={this.state.penaltyTypesList} 
                                                selected={[this.state.selectedpenaltyType]}
                                                placeholder="Select Penalty Type"
                                                labelKey='penaltyType'
                                                id="basic-example"
                                                //disabled={this.state.disableAction}
                                              
                                            />
                                        </Col>  
                                        <Col sm="2">  </Col>    
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Monetary Value
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                type="text"
                                                name="monitaryValue"
                                                value={this.state.monitaryValue}
                                                onChange={this.handleChangemonitaryValue} 
                                                autoComplete="off"
                                                placeholder="Enter Amount"
                                            />
                                        </Col>                                                                                                                    
                                    </Form.Group> 
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                           Offense Section
                                        </Form.Label>
                                        <Col sm="10">
                                            <Form.Control 
                                                type="text"
                                                name="offenseSection"
                                                value={this.state.offenseSection}
                                                onChange={this.handleChangeoffenseSection} 
                                                autoComplete="off"
                                                //placeholder="Enter Date Received"
                                            />
                                        </Col>                                                                                                                        
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                           Retail Group
                                        </Form.Label>
                                        <Col sm="10">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeRetailGroup}
                                                options={this.state.retailgroupData}
                                                selected={[this.state.selectedRetailGroup]}
                                                placeholder="Select"
                                            />
                                        </Col>                                                                                                                        
                                    </Form.Group>
                                     
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Case Status
                                        </Form.Label>
                                        <Col sm="2">
                                            <Typeahead
                                                labelKey='status'
                                                id="basic-example"
                                                onChange={this.onChangecaseStatus}
                                                options={this.state.caseStatus}
                                                selected={[this.state.selectedCaseStatus]}
                                            />
                                        </Col>

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Cycle Days
                                        </Form.Label>
                                        <Col sm="2">
                                            {/* <DatePicker
                                                ref='nodaDate'
                                                selected={this.state.cycleDays}
                                                onChange={this.handleChangeCycleDays}
                                                minDate={this.minDate}
                                                value={this.props.nodaDate}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                            /> */}
                                             <Form.Control 
                                                    type="text"
                                                    name="cycleDays"
                                                    value={this.state.cycleDays}
                                                    //onChange={this.handleChangeterminationDate} 
                                                    autoComplete="off"
                                                    //placeholder="Enter Date"
                                                    disabled={true}
                                                />
                                        </Col>

                                                                                
                                    </Form.Group>                                   

                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginRight:"5px"}}>
                                        <Button disabled={this.state.disableSave} variant="success"  className="ml-auto "  style={{minWidth:'60px'}} onClick={this.checkBeforeSave} >Save</Button>&nbsp;&nbsp;
                                        <Button variant="danger" style={{minWidth:'60px'}} href="/EmployeeDiciplineMonitoring" >Close</Button>
                                    </Form.Group>

                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />
                   {/* <InsertPenaltyStatement 
                        show={this.state.modalInsertPenalty}
                        onHide={this.handleModalClosePenalty}
                    /> 
                    <InsertCOCReference
                        show={this.state.modalCOCReference}
                        onHide={this.handleModalCloseCOC}
                    /> */}
            </div> 
        )
    }

}

export  default RecordNodaPenalty 
