import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar,
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker,Text
    } 
    from '../../noser-hris-component';  
    import './ApplicationFormDownload.css'
    //import "./ExamResultDownload.css";
   // /Users/admin/Desktop/productBago/src/icon/paraLogoIr.jpg
   ///Users/admin/Desktop/productBago/src/icon/paramountLogo.jpg
    import paramountLogo from "../../icon/paramountLogo.jpg";

import ReactToPrint from "react-to-print";
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { Label } from 'reactstrap';
import Card from 'react-bootstrap/Card';
import color from '@material-ui/core/colors/amber';
const ref = React.createRef();

class ComponentToPrint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            wdata1 : [],
            wdata2 : [],
            applicantAddresses:[],
            educationAttainments : [],
            collage : [],
            highSchool : [],
            seniorHigh : [],
            juniorHigh  : [],
            elementary  : [],
            company1 : [],
            company2 : [],
            company3 : [],
            spouse : [],
            father : [],
            mother : [],
            brotherSister : [],
            brotherSister1 : [],
            brotherSister2 : [],
            brotherSister3 : [],
            child : [],
            child1 : [],
            child2 : [],
            characterName1 : [],
            characterName2 : [],
            characterName3 : [],
            _sname1 : "",
            _sname11 : "",
            _sname2 : "",
            _sname22 : "",
            _sname3 : "",
            _sname33 : "",
            employmentHistories : [],
            
            jobOpeningDDL  :    [
                {"name": 'Select Job Opening',  "value": "",},
                {"name":"BESTJOBS",             "value":"1"},
                {"name":"CLASSIFIED ADS",       "value":"2"},
                {"name":"FIELD COLLECTORS",     "value":"3"},
                {"name":"JOBSTREET",            "value":"4"},
                {"name":"OTHERS",               "value":"5"},
                {"name":"PESO",                 "value":"6"},
                {"name":"REFERRAL",             "value":"7"},
            ],
            diseaseDDL  :    [
                {"name": 'Select Chronic Disease',  "value": "",},
                {"name":"ALLERGIES",            "value":"1"},
                {"name":"ASTHMA",               "value":"2"},
                {"name":"DIABETES",             "value":"3"},
                {"name":"HEPA B",               "value":"4"},
                {"name":"HYPERTENSION",         "value":"5"},
                {"name":"N/A",                  "value":"6"},
                {"name":"OTHERS",               "value":"7"}
            ],
            tblJobOpeningArrLst : [],
            jobSourceId : "",
            jobSource : "",
            tblDiseaseArrLst : [],
            diagnoseDeseaseId : "",
            jobStreet : false,
            classifiedAds : false,
            peso :false,
            others : false,
            referral : false,
            referredBy : "",
            crimeYes: false,
            crimeNo : false,
            crimeDescription : "",
            laborYes: false,
            laborNo: false,
            laborUnionDescription : "",
            allergies  :false,
            hypertension :false,
            asthma: false,
            allergies : false,
            others : false,
            hepa : false,
            diabetes : false,
            na : false,
            hasCompanyRelative : "",
            relativeRelation : "",
            relativeName : "",
            date : new Date(),
            date2 : "",
            fullname : "",
            jobSourceId : "",
            jobSource : "",
            jobOpeningDDL  :    [],
            jobSourceName : "",

        }
        this.state.wdata1 = JSON.parse(sessionStorage.getItem("wdata1"))
        this.state.wdata2 = JSON.parse(sessionStorage.getItem("wdata2"))
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.setState({isloading : true})

        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.wdata1 = JSON.parse(sessionStorage.getItem("wdata1"))
        this.state.wdata2 = JSON.parse(sessionStorage.getItem("wdata2"))
        let a = JSON.parse(sessionStorage.getItem("wdata1"))
        console.log(a)
        let b = a.firstName + " " + a.middleName + " " + a.lastName
        console.log(b)
        this.state.fullname = b
       
       this.GetDisease() 
       this.GetJobOpening() 
       
    };
    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    };

    test=async()=>{
        let datetmp = this.FormatDate(new Date)
        this.state.date2 = datetmp

        let a = JSON.parse(sessionStorage.getItem("wdata2"))
        console.log(a)
        let b = a.applicantAddresses[0]
        let c = a.educationAttainments[0]
        let d = a.employmentHistories
        let f = a.familyBackgrounds
        let g = a.characterReferences
        let others = a.others
        console.log(others)
        this.state.employmentHistories = d

        this.state.applicantAddresses = b
        this.state.educationAttainments = c
        let collage = a.educationAttainments.filter(x=>x.levelId=="0")
        this.state.collage = collage[0]
        let highSchool = a.educationAttainments.filter(x=>x.levelId=="1")
        this.state.highSchool = highSchool[0]
        let seniorHigh = a.educationAttainments.filter(x=>x.levelId=="5")
        this.state.seniorHigh = seniorHigh[0]
        let juniorHigh = a.educationAttainments.filter(x=>x.levelId=="4")
        this.state.juniorHigh = juniorHigh[0]
        let elementary = a.educationAttainments.filter(x=>x.levelId=="2")
        this.state.elementary = elementary[0]
        d.forEach((itm,idx) => {
            itm.iid= parseFloat(idx+1)
        })
        let e = d
        let company1 = e.filter(x=>x.iid=="1")
        console.log(company1)
        let _sname1 = ""
        let _sname11 = ""
        if(company1[0].companyName == "N/A"){
            this.state._sname1 = _sname1
            this.state._sname11 = _sname11
            this.state.company1 = company1[0]
        }else{
            let _list1 =  company1[0].periodCovered.split(" ")
            
            if (_list1[0].length > 2) {
                _sname1 = _list1[0].substring(0, 7)
            } 
            if (_list1[1].length >2) {
                _sname11 = _list1[1].substring(0, 7)
            } 
            this.state._sname1 = _sname1
            this.state._sname11 = _sname11
            this.state.company1 = company1[0]
        }
        
        
        let tmpcompany3 =[]

        if(e.length >1){
            console.log("true")
            let company2 = e.filter(x=>x.iid=="2")
            let _list2 =  company2[0].periodCovered.split(" ")
            let _sname2 = ""
            let _sname22 = ""
            if (_list2[0].length > 2) {
                _sname2 = _list2[0].substring(0, 6)
            } 
            if (_list2[0].length >2) {
                _sname22 = _list2[1].substring(0, 6)
            } 
            this.state._sname2 = _sname2
            this.state._sname22 = _sname22
            this.state.company2 = company2[0]
            
            let company3 = e.filter(x=>x.iid=="3")
            /* let _list3 =  company3[0].periodCovered.split(" ")
            let _sname3 = ""
            let _sname33 = ""
            if (_list3[0].length > 3) {
                _sname3 = _list3[0].substring(0, 6)
            } 
            if (_list3[1].length >3) {
                _sname33 = _list3[1].substring(0, 6)
            } 
            this.state._sname3 = _sname3
            this.state._sname33 = _sname33
            this.state.company3 = company3[0] */
            tmpcompany3.push(company3[0])
        }
        //console.log(f)
        let spouse = f.filter(x=>x.roleId=="2")
        this.state.spouse = spouse[0]
        let father = f.filter(x=>x.roleId=="0")
        this.state.father = father[0]
        let mother = f.filter(x=>x.roleId=="1")
        this.state.mother = mother[0]
        let brotherSister = f.filter(x=>x.roleId=="3" || x.roleId=="4" )
        this.state.brotherSister = brotherSister[0]
        this.state.brotherSister1 = brotherSister[0]
        this.state.brotherSister2 = brotherSister[1]
        this.state.brotherSister3 = brotherSister[2]
        let chichi= []
        let child = f.filter(x=>x.roleId=="5")
        console.log(child)
        this.state.child1 = child[0]
        if(child[1] === true){
            this.state.child2 = child[1]
        }else{
            let obj = {
                "name" : "",
                "age" : "",
                "occupation" : "",
                "company" : "",
            }
            this.state.child2.push(obj)
        }

        //this.state.child2 = child[1]
        g.forEach((itm,idx) => {
            itm.iid= parseFloat(idx+1)
        })
        let h = g
        let characterName1 = h.filter(x=>x.iid=="1")
        let characterName2 = h.filter(x=>x.iid=="2")
        let characterName3 = h.filter(x=>x.iid=="3")

        this.state.characterName1 = characterName1[0]
        this.state.characterName2 = characterName2[0]
        this.state.characterName3 = characterName3[0]

        //jobSourceId
      //
        let otherstmp = others.filter(x=>x.jobSourceId!=="")
        console.log(otherstmp[0].jobSourceId)
        let jobtmp = this.state.tblJobOpeningArrLst.find(x => x.value ==otherstmp[0].jobSourceId ).label;
        console.log(jobtmp)
        this.setState({jobSourceName : jobtmp,
            referredBy :otherstmp[0].refferedBy
        })
        
       /*  console.log(otherstmp[0].jobSourceId)
        if(otherstmp[0].jobSourceId === "2"){
            this.setState({classifiedAds : true})
        }else if(otherstmp[0].jobSourceId === "4"){
            this.setState({jobStreet : true})
        }else if(otherstmp[0].jobSourceId === "6"){
            this.setState({peso : true})
        }else if(otherstmp[0].jobSourceId === "5"){
            this.setState({others : true})
        }else if(otherstmp[0].jobSourceId === "7"){
            this.setState({referral : true,
                referredBy : otherstmp[0].refferedBy
            })
        }; */


        if(others[0].isCrimeConvicted === "1"){
            this.setState({crimeYes : true,
                crimeDescription :others[0].crimeDescription
            })
        }else{ this.setState({crimeNo : true}) }
        if(others[0].isLaborUnion === "1"){
            this.setState({laborYes : true,
                laborUnionDescription :others[0].laborUnionDescription
            })
        }else{ this.setState({laborNo : true}) }
        
        if(others[0].diagnoseDeseaseId === "1"){
            this.setState({allergies : true})
        }else if(others[0].diagnoseDeseaseId === "2"){
            this.setState({asthma : true})
        }else if(others[0].diagnoseDeseaseId === "3"){
            this.setState({diabetes : true})
        }else if(others[0].diagnoseDeseaseId === "4"){
            this.setState({hepa : true})
        }else if(others[0].diagnoseDeseaseId === "5"){
            this.setState({hypertension : true})
        }else if(others[0].diagnoseDeseaseId === "6"){
            this.setState({na : true})
        }else if(others[0].diagnoseDeseaseId === "7"){
            this.setState({others : true})
        }

        if(others[0].hasCompanyRelative === "1"){
            this.setState({
                hasCompanyRelative : "yes",
                relativeRelation : others[0].relativeRelation,
                relativeName :  others[0].relativeName
            })
        }
        
        
        
        
        this.setState({isloading : false})
    };

   /*  GetJobOpening() {
        for (let i = 0; i < this.state.jobOpeningDDL.length; i++) {
            const obj = {
                value : this.state.jobOpeningDDL[i]["value"],
                label : this.state.jobOpeningDDL[i]["name"],
            };
            this.state.tblJobOpeningArrLst.push(obj);
            console.log(obj)
           // console.log(obj)
        }
    }; */

    GetDisease() {
        for (let i = 0; i < this.state.diseaseDDL.length; i++) {
            const obj = {
                value : this.state.diseaseDDL[i]["value"],
                label : this.state.diseaseDDL[i]["name"],
            };
            this.state.tblDiseaseArrLst.push(obj);
            console.log(obj)
        }  
    };
    GetJobOpening = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetJobOpenings",  params)
        .then(res => {
            const data = res.data;
            let jobOpeningDDL = []
            let tblJobOpeningArrLst = []
            jobOpeningDDL.push({"value":"","name":"Select Job Opening"})
            data.jobOpenings.map(function(itm){
                jobOpeningDDL.push({"value":itm.id,"name":itm.name})
                tblJobOpeningArrLst.push({"value" : itm.id,"label" : itm.name})
            })
            this.setState({jobOpeningDDL:jobOpeningDDL,tblJobOpeningArrLst:tblJobOpeningArrLst});
            console.log("tblJobOpeningArrLst")
            console.log(tblJobOpeningArrLst)
        })
        //this.GetReligions();
        this.test();
    }



    render() {
        var nf = new Intl.NumberFormat();
        const FormatCommas = (x) =>{
            x = x.toString();
            var pattern = /(-?\d+)(\d{3})/;
            while (pattern.test(x))
                x = x.replace(pattern, "$1,$2");
            return x;
        }

        return(
            <div>
                <Container className="" fluid>
                    <div style={{color: 'black'}} className='mt -2 cardwone'>
                        <Form>
                            <Form.Group as={Row } controlId="formPlaintextEmail">
                                <h1 className='headerP '>PARAMOUNT HUMAN RESOURCE MULTI-PURPOSE COOPERATIVE</h1>
                                <div className=' ml-auto cardpic aaa'>
                                    <div className='pad'>
                                        <Label className='ml-4 mt-2 photolabel'>2x2</Label>
                                        <Label  className='ml-1 photolabel'>Photo</Label>
                                    </div>
                                </div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Form.Group>
                            <div className='aa mtminus'>
                                <Form.Group as={Row } className='aa'>
                                    <h5 className='no-text-transform'>Unit 503 Hollywood Square Building</h5>
                                </Form.Group>
                                <Form.Group as={Row } className='aa'>
                                <   h5 className='no-text-transform'>West Avenue Brgy. Nayong Kanluran, Quezon City</h5>
                                </Form.Group>
                                <Form.Group as={Row } className='aa'>
                                <   h5 className='no-text-transform'>Website: www.paramountmpc.org</h5>
                                </Form.Group>
                                <Form.Group as={Row } className='aa'>
                                    <h1>APPLICATION FORM</h1>
                                </Form.Group>
                            </div>
                            <div>
                                <Form.Text className='lebel-one'>PLS WRITE LEGIBLY AND DO NOT LEAVE ANY SPACES BLANK, WRITE NONE OR N/A (NOT APPLICABLE) FOR NO ENTRIES.</Form.Text>
                            </div>
                            <div>
                                <Form.Group as={Row } className=''>
                                    <Form.Text className='lebel-one ml-4'>POSITION APPLIED: {this.state.wdata1.position}</Form.Text>
                                    <Form.Text className='lebel-one ml-auto'>DATE:&nbsp;{this.state.wdata1.dateApplied} </Form.Text>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </Form.Group>
                            </div>
                            <div>
                                <Label className='GENERALINFORMATION ml-2'>&#8544;. GENERAL INFORMATION</Label>
                            </div>
                           
                                <Form.Group as={Row}>
                                    <div  className=' surnameCard aa'>
                                        <Label className='surnamelebel mt-1'>surname</Label>
                                    </div>
                                    <div className=' middlenameCard aa'>
                                        <Label className='surnamelebel mt-1'>first name</Label>
                                    </div>
                                    <div className=' middlenameCard aa'>
                                        <Label className='surnamelebel mt-1'>middle name</Label>
                                    </div>
                                    <div className=' nicknameCard aa'>
                                        <Label className='surnamelebel mt-1'>nick name</Label>
                                    </div>
                                </Form.Group>
                                <Form.Group as={Row} className="minusCardMt">
                                    <div  className=' surnameCard  aaa'>
                                        <Label className='presentlebel2 mt-1'>{this.state.wdata1.lastName}</Label>
                                    </div>
                                    <div className=' middlenameCard aaa'>
                                        <Label className='presentlebel2 mt-1'>{this.state.wdata1.firstName}</Label>
                                    </div>
                                    <div className=' middlenameCard aaa'>
                                        <Label className='presentlebel2 mt-1'>{this.state.wdata1.middleName}</Label>   
                                    </div>
                                    <div className=' nicknameCard aaa'>
                                        <Label className='presentlebel2 mt-1'>{this.state.wdata1.nickName}</Label>
                                    </div>
                                </Form.Group>
                                {/* <div style={{color: 'black'}} className='mt-5 cardw'></div> */}
                                <div>
                                    <Form.Group as={Row} className='presentADD'>
                                        <Label className='presentlebel2 ml-2 '>present address : &nbsp;&nbsp;&nbsp;&nbsp;{this.state.wdata1.currentAddress}</Label>
                                    </Form.Group >
                                </div>
                                <div>
                                    <Form.Group as={Row} className='presentADD'>
                                        <Label className='presentlebel2 ml-2'>PROVINCIAL address :&nbsp;&nbsp;&nbsp;&nbsp;{this.state.applicantAddresses.province}</Label>
                                    </Form.Group >
                                </div>
                                <Form.Group as={Row}>
                                    <div  className=' TELEPHONENUMBER '>
                                        <Label className='presentlebel2 ml-2 '>TELEPHONE NUMBER (HOME) : &nbsp;{this.state.wdata1.homePhoneNumber}</Label>
                                    </div>
                                    <div className=' MOBILENUMBER'>
                                        <Label className='presentlebel2 ml-2 '>MOBILE NUMBER : &nbsp;{this.state.wdata1.mobileNumber}</Label>
                                    </div>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <div  className=' dateofbirth '>
                                        <Label className='presentlebel2 ml-2 '>date of birth: &nbsp;{this.state.wdata1.dateOfBirth}</Label>
                                    </div>
                                    <div className=' placeofbirth'>
                                        <Label className='presentlebel2 ml-2 '>place of birth : &nbsp;{this.state.wdata1.placeOfBirth}</Label>
                                    </div>
                                    <div className=' citizen'>
                                        <Label className='presentlebel2 ml-2 '>Citizenship : &nbsp;{this.state.wdata1.nationality}</Label>
                                    </div>
                                    <div className=' age'>
                                        <Label className='presentlebel2 ml-2 '>age :  &nbsp;{this.state.wdata1.age}</Label>
                                    </div>
                                    <div className=' height'>
                                        <Label className='presentlebel2 ml-2 '>height : &nbsp;{this.state.wdata1.height}</Label>
                                    </div>
                                    <div className=' weight'>
                                        <Label className='presentlebel2 ml-2 '>weight :&nbsp;{this.state.wdata1.weight}</Label>
                                    </div>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <div  className=' sex '>
                                        <Label className='presentlebel2 ml-2 '>sex: :&nbsp;{this.state.wdata1.gender}</Label>
                                    </div>
                                    <div className=' civilstatus'>
                                        <Label className='presentlebel2 ml-2 '>civil status : &nbsp;{this.state.wdata1.civilStatus}</Label>
                                    </div>
                                    <div className=' religion'>
                                        <Label className='presentlebel2 ml-2 '>religion : &nbsp;{this.state.wdata1.religion}</Label>
                                    </div>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <div  className=' specialskill '>
                                        <Label className='presentlebel2 ml-2 '>special skill : &nbsp;{this.state.wdata1.specialSkills}</Label>
                                    </div>
                                    <div className=' languagespoken'>
                                        <Label className='presentlebel2 ml-2 '>language spoken : &nbsp;{this.state.wdata1.languageSpoken}</Label>
                                    </div>
                                   
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <div  className=' specialskill '>
                                        <Label className='presentlebel2 ml-2 '>person to notify incase of emergency : &nbsp;{this.state.wdata1.emergencyContactName}</Label>
                                    </div>
                                    <div className=' relationship'>
                                        <Label className='presentlebel2 ml-2 '>relationship : &nbsp;{this.state.wdata1.emergencyContactRelation}</Label>
                                    </div>
                                    <div className=' MOBILENUMBER'>
                                        <Label className='presentlebel2 ml-2 '>number :&nbsp;{this.state.wdata1.emergencyContactNumber}</Label>
                                    </div>
                                   
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <div  className=' sss '>
                                        <Label className='presentlebel2 ml-2 '>sss # : &nbsp;{this.state.wdata1.sssNumber}</Label>
                                    </div>
                                    <div className=' philhealth'>
                                        <Label className='presentlebel2 ml-2 '>philhealth # :&nbsp;{this.state.wdata1.phicNumber}</Label>
                                    </div>
                                    <div className=' philhealth'>
                                        <Label className='presentlebel2 ml-2 '>pag-ibig # :&nbsp;{this.state.wdata1.hdmfNumber}</Label>
                                    </div>
                                    <div className=' MOBILENUMBER'>
                                        <Label className='presentlebel2 ml-2 '>Tin # :&nbsp;{this.state.wdata1.tinNumber}</Label>
                                    </div>
                                    {/* <div className=' MOBILENUMBER'>
                                        <Label className='presentlebel ml-2 '>other :</Label>
                                    </div> */}
                                </Form.Group>
                            
                            <div>
                                <Label className='GENERALINFORMATION ml-2 mt-1'>&#8545;. education</Label>
                            </div>
                            
                                <Form.Group as={Row}>
                                        
                                    <div  className='school aaa '>
                                        <Label className='presentlebel   '>school/university</Label>
                                    </div>
                                    <div className=' course aaa'>
                                        <Label className='presentlebel  '>course/degree</Label>
                                    </div>
                                    <div className=' fromto aaa '>
                                        <Label className='presentlebel  '>from</Label>
                                    </div>
                                    <div className=' fromto aaa'>
                                        <Label className='presentlebel  '>to</Label>
                                    </div>
                                    <div className=' honor aaa'>
                                        <Label className='presentlebel  '>honor(s)/award(s) Recieved</Label>
                                    </div>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    
                                    <div  className='collage  '>
                                        <Label className='presentlebel3  mt-2 '> COLLEGE:&nbsp;{this.state.collage.schoolName}</Label>
                                    </div>
                                    <div className=' DEGREE aaa'>
                                        <Label className='presentlebel3  mt-2 '>{this.state.collage.course}</Label>
                                    </div>
                                    <div className=' to aaa '>
                                        <Label className='presentlebel3  mt-2 '>{this.state.collage.startYear}</Label>
                                    </div>
                                    <div className=' to aaa'>
                                        <Label className='presentlebel3  mt-2 '>{this.state.collage.endYear}</Label>
                                    </div>
                                    <div className=' recieved aaa'>
                                        <Label className='presentlebel3  mt-2 '>{this.state.collage.honorRecieved}</Label>
                                    </div>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <div  className='collage  '>
                                         <Label className='presentlebel3  mt-2 '>HIGH SCHOOL:&nbsp;{this.state.highSchool.schoolName}</Label>
                                    </div>
                                    <div className=' DEGREE aaa'>
                                        <Label className='presentlebel3  mt-2 '>{this.state.highSchool.course}</Label>
                                    </div>
                                    <div className=' to aaa '>
                                        <Label className='presentlebel3  mt-2 '>{this.state.highSchool.startYear}</Label>
                                    </div>
                                    <div className=' to aaa'>
                                        <Label className='presentlebel3  mt-2 '>{this.state.highSchool.endYear}</Label>
                                    </div>
                                    <div className=' recieved aaa'>
                                    <Label className='presentlebel3  mt-2 '>{this.state.highSchool.honorRecieved}</Label>
                                    </div>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <div  className='collage  '>
                                        <Label className='presentlebel3  mt-2 '>SENIOR HIGH:&nbsp;{this.state.seniorHigh.schoolName}</Label>
                                    </div>
                                    <div className=' DEGREE aaa'>
                                        <Label className='presentlebel3  mt-2 '>{this.state.seniorHigh.course}</Label>
                                    </div>
                                    <div className=' to aaa '>
                                        <Label className='presentlebel3  mt-2 '>{this.state.seniorHigh.startYear}</Label>
                                    </div>
                                    <div className=' to aaa'>
                                        <Label className='presentlebel3  mt-2 '>{this.state.seniorHigh.endYear}</Label>
                                    </div>
                                    <div className=' recieved aaa'>
                                        <Label className='presentlebel3  mt-2 '>{this.state.seniorHigh.honorRecieved}</Label>
                                    </div>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <div  className='collage  '>
                                        <Label className='presentlebel3  mt-2 '>JUNIOR HIGH:&nbsp;{this.state.juniorHigh.schoolName}</Label>
                                    </div>
                                    <div className=' DEGREE aaa'>
                                        <Label className='presentlebel3  mt-2 '>{this.state.juniorHigh.course}</Label>
                                    </div>
                                    <div className=' to aaa '>
                                        <Label className='presentlebel3  mt-2 '>{this.state.juniorHigh.startYear}</Label>
                                    </div>
                                    <div className=' to aaa'>
                                        <Label className='presentlebel3  mt-2 '>{this.state.juniorHigh.endYear}</Label>
                                    </div>
                                    <div className=' recieved aaa'>
                                        <Label className='presentlebel3  mt-2 '>{this.state.juniorHigh.honorRecieved}</Label>
                                    </div>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <div  className='collage  '>
                                        <Label className='presentlebel3  mt-2 '>ELEMENTARY:&nbsp;{this.state.elementary.schoolName}</Label>
                                    </div>
                                    <div className=' DEGREE aaa'>
                                        <Label className='presentlebel3  mt-2 '>{this.state.elementary.course}</Label>
                                    </div>
                                    <div className=' to aaa '>
                                        <Label className='presentlebel3  mt-2 '>{this.state.elementary.startYear}</Label>
                                    </div>
                                    <div className=' to aaa'>
                                        <Label className='presentlebel3  mt-2 '>{this.state.elementary.endYear}</Label>
                                    </div>
                                    <div className=' recieved aaa'>
                                        <Label className='presentlebel3  mt-2 '>{this.state.elementary.honorRecieved}</Label>
                                    </div>
                                </Form.Group>
                                {/* <Form.Group as={Row}>
                                    <div  className='collage  '>
                                        <Label className='presentlebel  mt-2 '>post GRADUATE</Label>
                                    </div>
                                    <div className=' DEGREE aaa'></div>
                                    <div className=' to aaa '> </div>
                                    <div className=' to aaa'></div>
                                    <div className=' recieved aaa'></div>
                                </Form.Group> */}
                                {/* <Form.Group as={Row}>
                                    <div  className='collage  '>
                                        <Label className='presentlebel  mt-2 '>other</Label>
                                    </div>
                                    <div className=' DEGREE aaa'></div>
                                    <div className=' to aaa '> </div>
                                    <div className=' to aaa'> </div>
                                    <div className=' recieved aaa'> </div>
                                </Form.Group> */}
                            
                            <div>
                                <Label className='GENERALINFORMATION ml-2 mt-2 '>&#8546;. employment record (start with your latest employer)</Label>
                            </div>
                            <div style={{color: 'black'}} className=' '>
                                <Form.Group as={Row}>
                                    <div className=' nameofcompany aaa mt-2'>
                                        <Label className='presentlebel  mt-3'>name of company</Label>
                                    </div>
                                    <div className=' address aaa mt-2'>
                                        <Label className='presentlebel  mt-4'>address</Label>
                                    </div>
                                    <div>
                                        <Form.Group as={Row} className=' dateemployed  aaa '>
                                            <Label className='presentlebel'>date employed</Label>
                                        </Form.Group>
                                        <Form.Group as={Row} className='  aaa'>
                                            <div  className=' tofrom aaa'>
                                                <Label className='presentlebel aaa mt-2'>from</Label>
                                            </div>
                                            <div  className=' tofromcard aaa'>
                                            <   Label className='presentlebel aaa mt-2'>to</Label>
                                            </div>  
                                        </Form.Group>
                                    </div>
                                    <div  className=' position mt-2 aaa '>
                                        <Label className='presentlebel mt-4'>position</Label>
                                    </div>
                                     <div  className=' reasonL aaa mt-2 '>
                                        <Label className='presentlebel mt-2'>reason for leaving</Label>
                                    </div>
                                    <div  className=' salary aaa  mt-2 '>
                                        <Label className='presentlebel mt-4'>salary</Label>
                                    </div>
                                    {/* <div className=' immediate  mt-2 aaa'>
                                        <Label className='presentlebel  mt-2'>name of immediate supervisor/position/contact number</Label>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                    
                                </Form.Group>
                                
                                 

                                
                                 <Form.Group as={Row}>
                                    <div className='blankCompany aaa'>
                                        <Label className='presentlebel3  mt-2'>{this.state.company1.companyName}</Label>
                                    </div>
                                    <div className='blankAddress aaa'>
                                        <Label className='presentlebel3  mt-2'>{this.state.company1.companyAddress}</Label>
                                    </div>
                                   <div className='blankfrom aaa'>
                                        <Label className='presentlebel3  mt-2'>{this.state._sname1}</Label>
                                    </div>
                                    <div className='blankto aaa'>
                                        <Label className='presentlebel3  mt-2'>{this.state._sname11}</Label>
                                    </div>
                                     <div className='blankposition aaa'>
                                        <Label className='presentlebel3  mt-2'>{this.state.company1.position}</Label>
                                    </div>
                                     <div className='reasonLblank aaa'>
                                        <Label className='presentlebel3  mt-2'>{this.state.company1.reasonForLeaving}</Label>
                                    </div>
                                    <div className='blanksalary aaa'>
                                        <Label className='presentlebel3  mt-2'>{this.state.company1.salary}</Label>
                                    </div>
                                   
                                </Form.Group>
                                
                                <Form.Group as={Row}>
                                    <div className='blankCompanytwotwotwo aaa'>
                                        <Label className='presentlebel3  mt-2'>{this.state.company2.companyName}</Label>
                                    </div>
                                    <div className='blankAddresstwotwotwo aaa'>
                                        <Label className='presentlebel3  mt-2'>{this.state.company2.companyAddress}</Label>
                                    </div>
                                    <div className='blankfromtwotwotwo aaa'>
                                        <Label className='presentlebel3  mt-2'>{this.state._sname2}</Label>
                                    </div>
                                    <div className='blanktotwotwotwo aaa'>
                                        <Label className='presentlebel3  mt-2'>{this.state._sname22}</Label>
                                    </div>
                                    <div className='blankpositiontwotwotwo aaa'>
                                        <Label className='presentlebel3  mt-2'>{this.state.company2.position}</Label>
                                    </div>
                                     <div className='reasonLblanktwotwotwo aaa'>
                                        <Label className='presentlebel3  mt-2'>{this.state.company2.reasonForLeaving}</Label>
                                     </div>
                                    <div className='blanksalarytwotwotwo aaa'>
                                        <Label className='presentlebel3  mt-2'>{this.state.company2.salary}</Label>
                                    </div>
                                  
                                </Form.Group>
                                {/* <Form.Group as={Row}>
                                    <div className='blankCompanytwotwo aaa'>
                                        <Label className='presentlebel  mt-2'>{this.state.company3.companyName}</Label>
                                    </div>
                                    <div className='blankAddresstwotwo aaa'>
                                        <Label className='presentlebel  mt-2'>{this.state.company3.companyAddress}</Label>
                                    </div>
                                    <div className='blanktotwotwo aaa'>
                                        <Label className='presentlebel  mt-2'>{this.state._sname3}</Label>
                                    </div>
                                    <div className='blanktotwotwo aaa'>
                                        <Label className='presentlebel  mt-2'>{this.state._sname33}</Label>
                                    </div>
                                    <div className='blankpositiontwotwo aaa'>
                                    <Label className='presentlebel  mt-2'>{this.state.company3.position}</Label>
                                    </div>
                                     <div className='reasonLblanktwotwo aaa'>
                                     <Label className='presentlebel  mt-2'></Label>
                                     </div>
                                    <div className='blanksalarytwotwo aaa'>
                                    <Label className='presentlebel  mt-2'>{this.state.company3.salary}</Label>
                                    </div>
                                   <div className='blankimmediatetwotwo aaa'>

                                    </div> 
                                </Form.Group> */}
                            </div>
                            <div>
                                <Label className='GENERALINFORMATION ml-2 mt-2'>&#8547;. family background</Label>
                            </div>
                            <div style={{color: 'black'}} className='mt-1 '>
                                <Form.Group as={Row}>
                                    <div  className=' nameFCard aa'>
                                        <Label className='surnamelebel mt-1'>name</Label>
                                    </div>
                                    <div className=' ageFCard aa'>
                                        <Label className='surnamelebel mt-1'>age</Label>
                                    </div>
                                    <div className=' middlenameCard aa'>
                                        <Label className='surnamelebel mt-1'>occupation</Label>
                                    </div>
                                    <div className=' nicknameCard aaa'>
                                        <Label className='companyschoollebel mt-1 aaa'>company/school</Label>
                                    </div>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <div  className=' nameFblankCard aa'>
                                        <Label className='presentlebel3 mt-2 ml-1'>spouse :&nbsp;{this.state.spouse.name}</Label>
                                    </div>
                                    <div className=' ageFblankCard aaa'>
                                        <Label className='presentlebel3 mt-1'>{this.state.spouse.age}</Label>
                                    </div>
                                    <div className=' occuationblankCard aaa'>
                                        <Label className='presentlebel3 mt-1'>{this.state.spouse.occupation}</Label>
                                    </div>
                                    <div className=' companyschoolblankCard aaa'>
                                        <Label className='presentlebel3 mt-1 '>{this.state.spouse.company}</Label>
                                    </div>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <div  className=' nameFblankCard aa'>
                                        <Label className='presentlebel3 mt-2 ml-1'>father:&nbsp;{this.state.father.name}</Label>
                                    </div>
                                    <div className=' ageFblankCard aaa'>
                                        <Label className='presentlebel3 mt-1'>{this.state.father.age}</Label>
                                    </div>
                                    <div className=' occuationblankCard aaa'>
                                        <Label className='presentlebel3 mt-1'>{this.state.father.occupation}</Label>
                                    </div>
                                    <div className=' companyschoolblankCard aaa'>
                                        <Label className='presentlebel3 mt-1 '>{this.state.father.company}</Label>
                                    </div>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <div  className=' nameFblankCard aa'>
                                        <Label className='presentlebel3 mt-2 ml-1'>mother:&nbsp;{this.state.mother.name}</Label>
                                    </div>
                                    <div className=' ageFblankCard aaa'>
                                        <Label className='presentlebel3 mt-1'>{this.state.mother.age}</Label>
                                    </div>
                                    <div className=' occuationblankCard aaa'>
                                        <Label className='presentlebel3 mt-1'>{this.state.mother.occupation}</Label>
                                    </div>
                                    <div className=' companyschoolblankCard aaa'>
                                        <Label className='presentlebel3 mt-1 '>{this.state.mother.company}</Label>
                                    </div>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <div  className=' nameFblankCard aa'>
                                        <Label className='companyschoollebel mt-2 ml-1'>brother(s)/sister(s)</Label>
                                    </div>
                                    <div className=' ageFblankCard aa'>
                                        <Label className='surnamelebel mt-1'></Label>
                                    </div>
                                    <div className=' occuationblankCard aa'>
                                        <Label className='surnamelebel mt-1'></Label>
                                    </div>
                                    <div className=' companyschoolblankCard aa'>
                                        <Label className='companyschoollebel mt-1 aaa'></Label>
                                    </div>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <div  className=' nameFblankCard '>
                                        <Label className='presentlebel3 mt-2 ml-1'>{this.state.brotherSister1.name }</Label>
                                    </div>
                                    <div className=' ageFblankCard aaa'>
                                        <Label className='presentlebel3 mt-1'>{this.state.brotherSister1.age }</Label>
                                    </div>
                                    <div className=' occuationblankCard aaa'>
                                        <Label className='presentlebel3 mt-1'>{this.state.brotherSister1.occupation}</Label>
                                    </div>
                                    <div className=' companyschoolblankCard aaa'>
                                        <Label className='presentlebel3 mt-1 '>{this.state.brotherSister1.company}</Label>
                                    </div>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <div  className=' nameFblankCard aa'>
                                        <Label className='presentlebel3 mt-2 ml-1'>{this.state.brotherSister2.name }</Label>
                                    </div>
                                    <div className=' ageFblankCard aaa'>
                                        <Label className='presentlebel3 mt-1'>{this.state.brotherSister2.age }</Label>
                                    </div>
                                    <div className=' occuationblankCard aaa'>
                                        <Label className='presentlebel3 mt-1'>{this.state.brotherSister2.occupation }</Label>
                                    </div>
                                    <div className=' companyschoolblankCard aaa'>
                                        <Label className='presentlebel3 mt-1 '>{this.state.brotherSister2.company }</Label>
                                    </div>
                                </Form.Group>
                                {/* <Form.Group as={Row}>
                                    <div  className=' nameFblankCard aa'>
                                        <Label className='presentlebel2 mt-2 ml-1'>{this.state.brotherSister3.name }</Label>
                                    </div>
                                    <div className=' ageFblankCard aa'>
                                        <Label className='surnamelebel mt-1'>{this.state.brotherSister3.age }</Label>
                                    </div>
                                    <div className=' occuationblankCard aa'>
                                        <Label className='surnamelebel mt-1'>{this.state.brotherSister3.occupation }</Label>
                                    </div>
                                    <div className=' companyschoolblankCard aaa'>
                                        <Label className='companyschoollebel mt-1 '>{this.state.brotherSister3.company }</Label>
                                    </div>
                                </Form.Group> */}
                            </div>
                            <div>
                                <Label className='companyschoollebel ml-2 mt-2'> name of children / age (even if single)</Label>
                            </div>
                            <div style={{color: 'black'}} className=' nameOfChildrenCard'>
                                <Form.Group as={Row}>
                                    <div  className=' nameFblankCardblank aaa'>
                                        <Label className='presentlebel3 mt-2 ml-1'>{this.state.child1.name }</Label>
                                    </div>
                                    <div className=' ageFblankCardw aaa'>
                                        <Label className='presentlebel3 mt-1'>{this.state.child1.age }</Label>
                                    </div>
                                    <div className=' occuationblankCardw aaa'>
                                        <Label className='presentlebel3 mt-1'>{this.state.child1.occupation }</Label>
                                    </div>
                                    <div className=' companyschoolblankCardw aaa'>
                                        <Label className='presentlebel3 mt-1 '>{this.state.child1.company }</Label>
                                    </div>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <div  className=' nameFblankCard aaa'>
                                        <Label className='presentlebel3 mt-2 ml-1'>{this.state.child2.name }</Label>
                                    </div>
                                    <div className=' ageFblankCard aaa'>
                                        <Label className='presentlebel3 mt-1'>{this.state.child2.age }</Label>
                                    </div>
                                    <div className=' occuationblankCard aaa'>
                                        <Label className='presentlebel3 mt-1'>{this.state.child2.occupation }</Label>
                                    </div>
                                    <div className=' companyschoolblankCard aaa'>
                                        <Label className='presentlebel3 mt-1 '>{this.state.child2.company }</Label>
                                    </div>
                                </Form.Group>
                            </div>
                            <div>
                                <Label className='GENERALINFORMATION ml-2 mt-2'>&#8548;. trainings and seminars attended</Label>
                            </div>
                            <Form.Group as={Row}>
                                <div  className=' nameFCard aa'>
                                    <Label className='surnamelebel mt-1'>title</Label>
                                </div>
                                <div className=' ageFCard aa'>
                                    <Label className='surnamelebel mt-1'>date</Label>
                                </div>
                                <div className=' middlenameCard aa'>
                                    <Label className='surnamelebel mt-1'>provider</Label>
                                </div>
                                <div className=' nicknameCard aaa'>
                                    <Label className='companyschoollebel mt-1 aaa'>venue</Label>
                                </div>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <div  className=' nameFblankCard aa'>
                                    <Label className='companyschoollebel mt-2 ml-1'></Label>
                                </div>
                                <div className=' ageFblankCard aa'>
                                    <Label className='surnamelebel mt-1'></Label>
                                </div>
                                <div className=' occuationblankCard aa'>
                                    <Label className='surnamelebel mt-1'></Label>
                                </div>
                                <div className=' companyschoolblankCard aa'>
                                    <Label className='companyschoollebel mt-1 aaa'></Label>
                                </div>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <div  className=' nameFblankCard aa'>
                                    <Label className='companyschoollebel mt-2 ml-1'></Label>
                                </div>
                                <div className=' ageFblankCard aa'>
                                    <Label className='surnamelebel mt-1'></Label>
                                </div>
                                <div className=' occuationblankCard aa'>
                                    <Label className='surnamelebel mt-1'></Label>
                                </div>
                                <div className=' companyschoolblankCard aa'>
                                    <Label className='companyschoollebel mt-1 aaa'></Label>
                                </div>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <div  className=' nameFblankCard aa'>
                                    <Label className='companyschoollebel mt-2 ml-1'></Label>
                                </div>
                                <div className=' ageFblankCard aa'>
                                    <Label className='surnamelebel mt-1'></Label>
                                </div>
                                <div className=' occuationblankCard aa'>
                                    <Label className='surnamelebel mt-1'></Label>
                                </div>
                                <div className=' companyschoolblankCard aa'>
                                    <Label className='companyschoollebel mt-1 aaa'></Label>
                                </div>
                            </Form.Group>
                            <div>
                                <Label className='GENERALINFORMATION ml-2 mt-2'>&#8549;. family or relatives working in the goverment</Label>
                            </div>
                            <Form.Group as={Row}>
                                <div  className=' namefrCard aaa'>
                                    <Label className='companyschoollebel mt-2 ml-1 aaa'>name</Label>
                                </div>
                                <div  className=' namefrgCard aaa'>
                                    <Label className='companyschoollebel mt-2 ml-1 aaa'>relationship</Label>
                                </div>
                                <div  className=' namefrggCard aaa'>
                                    <Label className='companyschoollebel mt-2 ml-1 aaa'>position</Label>
                                </div>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <div  className=' namefrCardw aaa'>
                                    <Label className='companyschoollebel mt-2 ml-1 aaa'></Label>
                                </div>
                                <div  className=' namefrgCardw aaa'>
                                    <Label className='companyschoollebel mt-2 ml-1 aaa'></Label>
                                </div>
                                <div  className=' namefrggCardw aaa'>
                                    <Label className='companyschoollebel mt-2 ml-1 aaa'></Label>
                                </div>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <div  className=' namefrCardw aaa'>
                                    <Label className='companyschoollebel mt-2 ml-1 aaa'></Label>
                                </div>
                                <div  className=' namefrgCardw aaa'>
                                    <Label className='companyschoollebel mt-2 ml-1 aaa'></Label>
                                </div>
                                <div  className=' namefrggCardw aaa'>
                                    <Label className='companyschoollebel mt-2 ml-1 aaa'></Label>
                                </div>
                            </Form.Group>
                            
                            
                        </Form>
                    </div>
                    <div style={{color: 'black'}} className='mt -2 cardwhite'></div>
                    <div style={{color: 'black'}} className='mt -2 cardwtwo'>
                        <Form>
                            <Row>
                                <Label className='GENERALINFORMATION ml-2 mt-2'>&#8550;. others</Label>
                            </Row>
                            <Label className='fontsizemedium mt-2'>how did you learn about the job opening?</Label>
                            
                            <div  className="mb-3 ml-5">
                                {/* <Form.Check
                                    inline
                                    label="classified ads"
                                    name="classifiedAds"
                                    type="checkbox" 
                                    checked={this.state.classifiedAds}
                                    // id={`inline-${type}-1`}
                                />
                                <Form.Check
                                    className='ml-4'
                                    inline
                                    label="jobstreet"
                                    name="jobStreet"
                                    type="checkbox" 
                                    checked={this.state.jobStreet}
                                />
                                <Form.Check
                                    className='ml-4'
                                    inline
                                    label="peso"
                                    name="peso"
                                    type="checkbox" 
                                    checked={this.state.peso}
                                />
                                <Form.Check
                                    className='ml-4'
                                    inline
                                    label="others"
                                    name="others"
                                    type="checkbox" 
                                    checked={this.state.others}
                                />*/}
                                {/* <Form.Check
                                    className='ml-4'
                                    inline
                                    label={this.state.jobSourceName}
                                    name="referral"
                                    type="checkbox" 
                                    checked={this.state.referral}
                                />  */}
                                <Label className='fontsizesmall '>{this.state.jobSourceName}:</Label>
                                <Label className='fontsizesmall ml-5 '>{this.state.referredBy}</Label>
                                
                                
                                {/* //{this.state.referral} */}
                            </div>
                                
                            
                            {/* <Label>REFERRED BY : {this.state.referral}</Label> */}
                            <Label className='fontsizemedium mt-2 mb-2'>have you been convicted of a crime?</Label>
                            {['checkbox'].map((type) => (
                                <div key={`inline-${type}`} className="mb-3 ml-5">
                                <Form.Check
                                    inline
                                    label="yes"
                                    name="crimeYes"
                                    type="checkbox" 
                                    checked={this.state.crimeYes}
                                />
                                <Form.Check
                                    className='ml-4'
                                    inline
                                    label="no"
                                    name="crimeNo"
                                    type="checkbox" 
                                    checked={this.state.crimeNo}
                                />
                                <Label className='ml-4 fontsizesmall'> If yes, please give details: {this.state.crimeDescription}</Label>
                                
                                </div>
                            ))}
                            <Label className='fontsizemedium mt-2 mb-2'>have you been a member of labor union?</Label>
                            {['checkbox'].map((type) => (
                                <div key={`inline-${type}`} className="mb-3 ml-5">
                                <Form.Check
                                    inline
                                    label="yes"
                                    name="laborYes"
                                    type="checkbox" 
                                    checked={this.state.laborYes}
                                />
                                <Form.Check
                                    className='ml-4'
                                    inline
                                    label="no"
                                    name="laborNo"
                                    type="checkbox" 
                                    checked={this.state.laborNo}
                                />
                                <Label className='ml-4 fontsizesmall'> If yes, please give details: {this.state.laborUnionDescription}</Label>
                                
                                </div>
                            ))}
                            <Label className='fontsizemedium mt-2 mb-2'>have you been suffred from or been doiagnosed to have any of this following chronic disease(s) or like?</Label>
                            {['checkbox'].map((type) => (
                                <div key={`inline-${type}`} className="mb-3 ml-5">
                                <Form.Check
                                    inline
                                    label="hypertension"
                                    name="hypertension"
                                    type="checkbox" 
                                    checked={this.state.hypertension}
                                />
                                <Form.Check
                                    className='ml-4'
                                    inline
                                    label="asthma"
                                    name="asthma"
                                    type="checkbox" 
                                    checked={this.state.asthma}
                                />
                                <Form.Check
                                    className='ml-4'
                                    inline
                                    label="allergies"
                                    name="allergies"
                                    type="checkbox" 
                                    checked={this.state.allergies}
                                />
                                <Form.Check
                                    className='ml-4'
                                    inline
                                    label="others"
                                    name="others"
                                    type="checkbox" 
                                    checked={this.state.others}
                                />
                                <Form.Check
                                    className='ml-4'
                                    inline
                                    label="hepa "
                                    name="hepa"
                                    type="checkbox" 
                                    checked={this.state.hepa} 
                                />
                                
                                <Form.Check
                                    className='ml-4'
                                    inline
                                    label="diabetes"
                                    name="diabetes"
                                    type="checkbox" 
                                    checked={this.state.diabetes}
                                />
                                <Label className='ml-4 fontsizesmall'>others: _________________</Label>
                                
                                </div>
                            ))}
                            <Form.Group as={Row}>
                                <Label className='ml-3 fontsizemedium'>have you ever applied in phrmpc before? : _______________</Label>
                                <Label className='ml-5 fontsizemedium'>when? : _______________</Label>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Label className='ml-3 fontsizemedium'>do you know any phrmpc member? : {this.state.hasCompanyRelative}</Label>
                                <Label className='ml-5 fontsizemedium'>name? : {this.state.relativeName}</Label>
                                <Label className='ml-auto fontsizemedium'>relationship? : {this.state.relativeRelation}</Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Label className='ml-3 fontsizemedium'>do you know any employee working in the same industry ? : _______________</Label>
                                <Label className='ml-5 fontsizemedium'>name? : _______________</Label>
                                <Label className='ml-5 fontsizemedium'>relationship? : _______________</Label>
                            </Form.Group>
                            <Row>
                                <Label className='GENERALINFORMATION ml-2 mt-2'>&#8551;. CHARACTERS REFERENCES</Label>
                            </Row>
                            <Label className='no-text-transform fontsizesmall'>(Those who can vouch for your moral character and integrity. Exclude relatives, former employer and politicians)</Label>
                            <Form.Group as={Row}>
                                <div  className=' surnameCard aa'>
                                    <Label className='surnamelebel mt-1'>name</Label>
                                </div>
                                <div className=' middlenameCard aa'>
                                    <Label className='surnamelebel mt-1'>occupation</Label>
                                </div>
                                <div className=' middlenameCard aa'>
                                    <Label className='surnamelebel mt-1'>company/school</Label>
                                </div>
                                <div className=' nicknameCard aa'>
                                    <Label className='surnamelebel mt-1'>contact #</Label>
                                </div>
                            </Form.Group>

                            {/* <Label className='presentlebel2 mt-2 ml-1'>mother:&nbsp;{this.state.mother.name}</Label>
                                    </div>
                                    <div className=' ageFblankCard aa'>
                                        <Label className='surnamelebel mt-1'>{this.state.mother.age}</Label> */}
                            
                            <Form.Group as={Row} className="minusCardMt">
                                <div  className=' surnameCard aa'>
                                    <Label className='presentlebel2 mt-2 ml-1'>{this.state.characterName1.name}</Label>              
                                </div>
                                <div className=' middlenameCard aa'>
                                    <Label className='surnamelebel  mt-1'>{this.state.characterName1.occupation}</Label>
                                </div>
                                <div className=' middlenameCard aa'> 
                                    <Label className='surnamelebel  mt-1'>{this.state.characterName1.company}</Label>
                                </div>
                                <div className=' nicknameCard aa'>
                                    <Label className='surnamelebel  mt-1'>{this.state.characterName1.contactNumber}</Label>
                                </div>
                            </Form.Group>
                            <Form.Group as={Row} className="minusCardMt">
                                <div  className=' surnameCard aa'>
                                    <Label className='presentlebel2 mt-2 ml-1'>{this.state.characterName2.name}</Label>   
                                </div>
                                <div className=' middlenameCard aa'>
                                    <Label className='surnamelebel  mt-1'>{this.state.characterName2.occupation}</Label>
                                </div>
                                <div className=' middlenameCard aa'> 
                                    <Label className='surnamelebel  mt-1'>{this.state.characterName2.company}</Label>
                                </div>
                                <div className=' nicknameCard aa'>
                                    <Label className='surnamelebel  mt-1'>{this.state.characterName1.contactNumber}</Label>
                                </div>
                            </Form.Group>
                            <Form.Group as={Row} className="minusCardMt">
                                <div  className=' surnameCard aa'>
                                    <Label className='presentlebel2 mt-2 ml-1'>{this.state.characterName3.name}</Label>   
                                </div>
                                <div className=' middlenameCard aa'>
                                    <Label className='surnamelebel  mt-1'>{this.state.characterName3.occupation}</Label>
                                </div>
                                <div className=' middlenameCard aa'> 
                                    <Label className='surnamelebel  mt-1'>{this.state.characterName3.company}</Label>
                                </div>
                                <div className=' nicknameCard aa'>
                                    <Label className='surnamelebel  mt-1'>{this.state.characterName3.contactNumber}</Label>
                                </div>
                            </Form.Group>
                            <Form.Group as={Row} className="minusCardMt">
                                <div  className=' surnameCard aa'></div>
                                <div className=' middlenameCard aa'></div>
                                <div className=' middlenameCard aa'> </div>
                                <div className=' nicknameCard aa'></div>
                            </Form.Group>
                            <Label className='no-text-transform mt-3 fontsizesmall'>Explain why you should be considered for the position (at least 50 words)</Label>
                            <div className='whiteline'></div>
                            <div className='whiteline'></div>
                            <div className='whiteline'></div>
                            <div className='whiteline'></div>
                            <div className='whiteline'></div>
                            <div className='whiteline'></div>
                            <p className='aa no-text-transform mt-3 fontsizesmall ml-2 mb '>
                                I understand that any misrepresentation or falsification  or any omission of facts, of whatever nature required by this application shale considered sufficient cause for my dismissal at anytime during my membership with the cooperative. I also understand that the cooperative may conduct background investigation as part of processing my application.                         
                            </p>
                            {/* <Form.Group as={Row} className="minusCardMt">
                                <div className='signatureLine2'>
                                    <Label className="fullnamelabel">{this.state.fullname}</Label>
                                </div>
                                <div className='dateLine ml-auto'></div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Form.Group> */}
                            <Form.Group as={Row} className="mt">
                                <div className='signatureLine aaa'><Label className="fullnamelabel">{this.state.fullname}</Label></div>
                                <div className='dateLine ml-auto aaa'><Label className='presentlebel'>{this.state.date2}</Label></div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Form.Group>
                            <Form.Group as={Row}/*  className="minusCardMt" */>
                                <Label className='fontsizesmall applicantsignature'>applicant signature over printed name</Label>
                                <Label className='fontsizesmall ml-auto dateee'>date</Label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Form.Group>
                            <div className='lostbox mt-2'>
                                <Row>
                                    <Label className='GENERALINFORMATION ml-5 mt-3'>for HR's use only :</Label>
                                </Row>
                                <div className='aaa'>
                                    <Label className='GENERALINFORMATION mb-3'>psychological profile</Label>
                                </div>
                                <Form.Group as={Row} className=''>
                                    <Label className='examtaken GENERALINFORMATION ml-5'> exam taken</Label>
                                    <Label className='examtaken GENERALINFORMATION marginLminus6'> raw score</Label>
                                    <Label className='examtaken GENERALINFORMATION marginLminus6'> %</Label>
                                    <Label className='examtaken GENERALINFORMATION'>description</Label>
                                </Form.Group>
                                <Form.Group as={Row} className=''>
                                    <Label className='examtaken  ml-5 fontsizesmall'> iq exam</Label>
                                    <Label className='examtaken rawscoreline marginLminus5 '> </Label>
                                    <Label className='examtaken rawscoreline mlbb '> </Label>
                                    <Label className='DESCRIPTIONline   '> </Label>
                                </Form.Group>
                                <Form.Group as={Row} className=''>
                                    <Label className='examtaken  ml-5 fontsizesmall'> aptitude</Label>
                                    <Label className='examtaken rawscoreline marginLminus5 '> </Label>
                                    <Label className='examtaken rawscoreline mlbb '> </Label>
                                    <Label className='DESCRIPTIONline   '> </Label>
                                </Form.Group>
                                <Form.Group as={Row} className=''>
                                    <Label className='examtaken  ml-5 fontsizesmall'> other indexes</Label>
                                    <Label className='examtaken rawscoreline marginLminus5 '> </Label>
                                    <Label className='examtaken rawscoreline mlbb '> </Label>
                                    <Label className='DESCRIPTIONline   '> </Label>
                                </Form.Group>
                                <Form.Group as={Row} className=''>
                                    <Label className='examtaken  ml-5 fontsizesmall'> personality</Label>
                                    <Label className='personalitylineone '> </Label>
                                </Form.Group>
                                <div className='personalitylinetwo mb-3'></div>
                                <div className='personalitylinetwo mb-3'></div>
                                <div className='personalitylinetwo mb-3'></div>
                                <div className='personalitylinetwo mb-5'></div>
                                
                                
                            </div>
                       </Form>

                    </div>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div>
        )
    }

}

class ApplicationFormDownload extends React.Component {
    render() {
      return (
        <div>
            <ComponentToPrint ref={(el) => (this.componentRef = el)} />
            <div style={{height : "20px"}}></div>
            <div style={{textAlign : "center", fontSize : "16px"}}>
                <ReactToPrint 
                    trigger={() => <button>print form</button>}
                    content={() => this.componentRef}
                />
               
            </div>
           
            <div style={{height : "40px"}}></div>
            

        </div>
      );
    }
}
  
export default ApplicationFormDownload
