import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer
}
from '../../noser-hris-component';
import CreateHoliday from './CreateHoliday';

class Holiday extends Component {
    constructor(props) {
    super(props);
    this.minDate = new moment(props.minDate)
    this.state = {
        userinfo        :   [],
        isloading       :   false,
        isshow          :   false,
        alerttype       :   "",
        message         :   "",
        color           :   "",
        fade            :   true,
        disableBtn      :   true,
        openModal       :   false,
        holidayListGrid :   [],
        holidayListDDL  :   [],
        holidayTypeListDDL: [],
        holidayTypeGridDDL: [],
        coverageListDDL :   [{"name":"National"},{"name":"Regional"},{"name":"Provincial"},{"name":"City/Town"}],
        coverageDDLGrid :   [
                            {"value":"National","label":"National"},
                            {"value":"Regional","label":"Regional"},
                            {"value":"Provincial","label":"Provincial"},
                            {"value":"City/Town","label":"City/Town"}],
        rpcListDDL      :   [],

        holidayName     :   "",
        holidayType     :   "",
        coverageName    :   "",
        locationName    :   "",
        holidayDate     :   ""
    }
}
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetHolidayTypes()
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
    }
    handleModalClose = (e) =>{
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.GetHolidayTypes()
        this.setState({openModal:false})
    }
    GetHolidayTypes = async () =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId
        };

        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetHolidayTypes",  params)
        .then(res => {
            const data = res.data;
            if(data.status==="1"){
                let holidaytypes = []
                data.holidayTypes.map(function(itm){
                    holidaytypes.push({
                        "value":itm.id,
                        "label":itm.name
                    })
                })
                this.setState({holidayTypeGridDDL:holidaytypes,holidayTypeListDDL: data.holidayTypes}) 
                this.GetHolidays()         
            }
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    } 

    GetHolidays = async () => {
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":"0",
            "UserId":this.state.userinfo.userId,
            "HolidayType":this.state.holidayType,
            "HolidayName":this.state.holidayName,
            "Coverage":this.state.coverageName,
            "Location":this.state.locationName,
            "Date":""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetHolidays",  params)
        .then(res => {
            const data = res.data;
            console.log(data)
            if(data.status==="1"){
                this.setState({ holidayListGrid: data.holidays,holidayListDDL: data.holidays,isloading:false});               
            }
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleSearchClick = async (e) =>{
        this.setState({isloading:true})
        
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":"0",
            "UserId":this.state.userinfo.userId,
            "HolidayType":this.state.holidayType,
            "HolidayName":this.state.holidayName,
            "Coverage":this.state.coverageName,
            "Location":this.state.locationName,
            "Date":""
        };
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetHolidays",  params)
            .then(res => {
                const data = res.data;
                if(data.status=="1"){
                    this.setState({ holidayListGrid: data.holidays,isloading:false }); 
                }
                if(data.status==="0") {
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
            })
            .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }
    handleChangeHolidayType = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        this.state.holidayType = e.length===0 ? "" :e[0].name
    }
    handleChangeHoliday = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        this.state.holidayName = e.length===0 ? "" :e[0].name
    }
    handleChangeLocation = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        this.state.locationName = e.length===0 ? "" :e[0].name
    }
    handleChangeCoverage = (e) =>{
        var url = "";
        var coverage = "";
        if(e.length==0) return;
        coverage = e[0].name
        this.setState({ coverageName: coverage });
        if(coverage=="National"){
            this.setState({ rpcListDDL: [] });
            return
        }
        if(coverage=="Regional")
            url = AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetRegions";
        if(coverage=="Provincial")
            url = AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetProvinces";
        if(coverage=="City/Town")
            url = AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetCities";
            
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":""
            };

        axios
            .post(url,  params)
            .then(res => {
                const data = res.data;
                if(coverage=="Regional")
                    this.setState({ rpcListDDL: data.regions.sort((a, b) => (a.name > b.name) ? 1 : -1) })
                if(coverage=="Provincial")
                    this.setState({ rpcListDDL: data.provinces.sort((a, b) => (a.name > b.name) ? 1 : -1) })
                if(coverage=="City/Town")
                    this.setState({ rpcListDDL: data.cities.sort((a, b) => (a.name > b.name) ? 1 : -1) }) 
            })
            .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleSaveClick = async (e) => {
        this.setState({isloading:true})

        const  params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Holidays":this.state.holidayListGrid.filter(x=>x.isModified==="1")
        };
        
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditHoliday",   params)
            .then(res => {
                const data = res.data;
                if(data.status=="1"){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true,
                        disableBtn  :   true
                    });
                    this.handleSearchClick(e)
                }
                else {
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
            })
            .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    GridDataModified(oldValue, newValue, holidayId, column) {
        let isDisable = true
        this.state.holidayListGrid.map(function(item,i){
            if (item.id===holidayId)
                item.isModified = newValue!=oldValue ? "1" : "0"
            if(item.isModified=="1")
                isDisable = false
        })
        this.setState({disableBtn:isDisable})
    }
render() {
    const cols = [
        {
            dataField: 'holidayTypeId',
            text: 'TYPE',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'5%' }},
            style:{textAlign:'left',width:'5%'},
            editable:true,
            formatter: (cell, row) => {
                if(row.holidayTypeId!=='' && row.holidayTypeId!==null){
                    if(this.state.holidayTypeGridDDL.filter(x => x.value === cell).length==0)
                    return ""
                else
                    return this.state.holidayTypeGridDDL.find(x => x.value === cell).label;
                }
            },
            editor: {
                type: Type.SELECT,
                options: this.state.holidayTypeGridDDL
            }
        },
        {
            dataField: 'coverage',
            text: 'COVERAGE',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'8%' }},
            style:{textAlign:'left',width:'8%'},
            sort:true
        },
        {
            dataField: 'location',
            text: 'LOCATION',
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'20%' }},
            style:{textAlign:'left',width:'20%'},
            sort:true
        },
        {
            dataField: 'holidayName',
            text: 'HOLIDAY',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'42%' }},
            style:{textAlign:'left',width:'42%'},
            sort:true
        },
        {
            dataField: 'date',
            text: 'DATE',
            editable: true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'25%' }},
            style:{textAlign:'left',width:'25%'},
            sort:true,
            formatter: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                  dateObj = new Date(cell);
                }
                //return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${('0' + dateObj.getUTCDate()).slice(-2)}/${dateObj.getUTCFullYear()}`;
		var day = dateObj.getDate();
                var month = dateObj.getMonth()+1;
                var year = dateObj.getFullYear();
                return month + '/' + day + '/' + year;
              },
              editor: {
                type: Type.DATE
              }
        }
    ]
    const selectRow = {
        mode: 'checkbox',
        clickToSelectAndEditCell: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            let isDisable = true
            this.state.holidayListGrid.map(function(item,i){
                if(item.id===row.id){
                    item.isDeleted = isSelect ? "1" : "0"
                    item.isModified = isSelect ? "1" : "0"
                }
                if(item.isDeleted=="1")
                    isDisable=false
            })
            this.setState({disableBtn:isDisable})
        }
    };
    
    return(
            <div>
            <Banner />
            <Container className="themed-container" fluid={true}>
                <Card className="mt-5">
                <Card.Header>COMMON MAINTNENANCE >> HOLIDAYS</Card.Header>
                <Card.Body>
                    <Form>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Group as={Row} controlId="formHorizontalEmail">
                            <Col sm={12}>
                               <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangeHolidayType}
                                    options={this.state.holidayTypeListDDL}
                                    placeholder="Select Holiday Type"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formHorizontalEmail">
                            <Col sm={12}>
                               <Typeahead
                                    labelKey='holidayName'
                                    id="basic-example"
                                    onChange={this.handleChangeHoliday}
                                    options={this.state.holidayListDDL}
                                    placeholder="Select Holiday Name"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formHorizontalEmail">
                            <Col sm={12}>
                               <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangeCoverage}
                                    options={this.state.coverageListDDL}
                                    placeholder="Select Coverage"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formHorizontalEmail">
                            <Col sm={12}>
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangeLocation}
                                    options={this.state.rpcListDDL}
                                    placeholder="Select [Region,Province,City]"
                                />
                            </Col>
                        </Form.Group>
                        <ButtonToolbar >
                            <Button variant="primary" className="ml-auto noser-button" onClick = { this.handleSearchClick }>Search</Button>
                        </ButtonToolbar>
                    </Form>
                    <div className="mt-3">
                        <Card.Header>LIST OF HOLIDAYS</Card.Header>
                            <BootstrapTable
                                keyField = "id"
                                data = { this.state.holidayListGrid }
                                columns = { cols }
                                selectRow = { selectRow }
                                cellEdit = { cellEditFactory({ 
                                        mode: 'dbclick', 
                                        blurToSave: true,
                                        afterSaveCell: (oldValue, newValue, row, column) => { 
                                            this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                        }
                                    })
                                }
                                striped
                                hover
                                condensed
                                loading={true}
                                wrapperClasses="table-responsive"
                                pagination={ paginationFactory({sizePerPageRenderer})}
                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }   
                            />
                        </div>
                        <ButtonToolbar>
                            <Button variant="success" className="ml-auto noser-button-mr1" disabled={this.state.disableBtn} onClick = { this.handleSaveClick }>Save</Button>
                            <Button variant="primary" className="noser-button" onClick = { this.handleModalShow }>CREATE</Button>
                        </ButtonToolbar>
                </Card.Body>
            </Card>
            </Container>
            <NoserLoading show={this.state.isloading} />
            <CreateHoliday
                show={this.state.openModal}
                onHide={this.handleModalClose}
                onRefModal={ref => (this.child = ref)}
            />
        </div>
    )
}

}
export  default Holiday
