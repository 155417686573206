import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration, NumberFormat,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Tabs, Tab, Accordion
} 
from '../../noser-hris-component';

class ManageEmployee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            disablecard     :   true,
            disableresigned :   true,
            disablebutton   :   true,
            
            clientId        :   "",
            client          :   "",
            employeeId      :   "",
            profileId       :   "",
            employeeName    :   "",
            statusId        :   "",
            
            clientDDL       :   [],
            employeeDDL     :   [],
            employeeList    :   [],

            /*PROFILE*/
            profileStatus   :   "",
            nickName        :   "",
            firstName       :   "",
            middleName      :   "",
            lastName        :   "",
            suffixName      :   "",
            homeNumber      :   "",
            officeNumber    :   "",
            mobileNumber    :   "",
            emailAddress    :   "",
            facebook        :   "",
            twitter         :   "",
            dateofBirth     :   "",
            placeofBirth    :   "",
            height          :   "",
            weight          :   "",
            languageSpoken  :   "",
            hobbies         :   "",
            contactName     :   "",
            contactNumber   :   "",
            contactRelation :   "",
            sssNumber       :   "",
            tinNumber       :   "",
            phicNumber      :   "",
            hdmfNumber      :   "",
            
            arrangementId   :   "",
            arrangement     :   "",
            bloodtypeId     :   "",
            bloodtype       :   "",
            nationalityId   :   "",
            nationality     :   "",
            religionId      :   "",
            religion        :   "",
            genderId        :   "",
            gender          :   "",
            civilstatusId   :   "",
            civilstatus     :   "",

            arrangementDDL  :   [],
            bloodtypeDDL    :   [],
            nationalityDDL  :   [],
            religionDDL     :   [],
            genderDDL       :   [
                {"id":"1","name":"MALE"},
                {"id":"2","name":"FEMALE"}
            ],
            civilstatusDDL   :   [
                {"id":"1","name":"SINGLE"},
                {"id":"2","name":"MARRIED"},
                {"id":"3","name":"DIVORCED"},
                {"id":"4","name":"SEPERATED"}
            ],
            
            /*201*/
            locationId      :   "",
            positionId      :   "",
            employmentId    :   "",
            cardtypeId      :   "",
            paytypeId       :   "",
            paymodeId       :   "",
            approver1Id     :   "",
            approver2Id     :   "",
            salaryRate      :   "",
            seaRate         :   "",
            ecolaRate       :   "",
            colaRate        :   "",
            isdefaultLocation:  "",
            cardNumber      :   "",
            dateHired       :   "",
            dateResigned    :   "",
            contractStart   :   "",
            contractEnd     :   "",

            location        :   "",
            position        :   "",
            employment      :   "",
            cardtype        :   "",
            paytype         :   "",
            paymode         :   "",
            approver1       :   "",
            approver2       :   "",
            remarks         :   "",

            locationDDL    :   [],
            positionDDL    :   [],
            employmentDDL  :   [],
            cardtypeDDL    :   [],
            paytypeDDL     :   [],
            paymodeDDL     :   [],
            approver1DDL   :   [],
            approver2DDL   :   [],

            backgroundGRID   :   [],
            addressGRID    :   [],
            educationGRID  :   [],

            backgroundGRIDDel :   [],
            addressGRIDDel    :   [],
            educationGRIDDel  :   [],

            addrtypeDDL    :   [
                {"value":"0","label":"CURRENT ADDRESS"},
                {"value":"1","label":"REGISTERED ADDRESS"},
                {"value":"2","label":"PROVINCIAL ADDRESS"},
                {"value":"3","label":"CORRESPONDENCE ADDRESS"},
                {"value":"4","label":"FOREIGN ADDRESS"}
            ],
            roleDDL         :   [
                {"value":"0","label":"FATHER"},
                {"value":"1","label":"MOTHER"},
                {"value":"2","label":"SPOUSE"},
                {"value":"3","label":"BROTHER"},
                {"value":"4","label":"SISTER"},
                {"value":"5","label":"CHILD"},
            ],
            levelDDL         :   [
                {"value":"0","label":"COLLEGE"},
                {"value":"1","label":"HIGH SCHOOL"},
                {"value":"2","label":"ELEMENTARY"},
                {"value":"3","label":"OTHERS"},
            ],
            regionDDL      :   [],
            provinceDDL    :   [],
            cityDDL        :   [],

            employeedetails:[],

            isDisabledField : true,
            paycardHistoryDDL:[]
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients()
        this.GetNationalities()
    } 
    componentDidMount(){
        this.state.statusId = this.state.userinfo.roleId === "1" ||  
                                this.state.userinfo.roleId === "8" ? "4" : "2"

        
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL : arr})
            }
            else{
            	this.setState({clientDDL : data.clients})
            }
            //this.setState({clientDDL :data.clients,isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetEmployeeList()
    }
    GetEmployeeList = async() =>{
        this.setState({isloading:true})
        
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter", params)
        .then(res => {
            const data = res.data;
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.profiles.filter(f => brr.some(item => item.referenceId === f.clientId))
                    }
                }
                this.setState({employeeDDL : arr,employeeList : arr,isloading:false})
            }
            else{
            	this.setState({employeeDDL : data.profiles,employeeList : data.profiles,isloading:false})
            }
            //this.setState({employeeDDL : data.employees.filter(x=>x.isActive==="1"),employeeList : data.employees.filter(x=>x.isActive==="1"),isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetLocations = async(client) =>{
        this.setState({isloading:true})

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": client,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", params)
        .then(res => {
            const data = res.data;
            this.setState({locationDDL : data.locations ? data.locations : []})
            if(data.locations.length=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
       this.GetParamountEmployees()
    }
    GetParamountEmployees = async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetCoorEmployees", params)
        .then(res => {
            const data = res.data;
            this.setState({approver2DDL : data.employees})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetEmployeeInformation()
    }
    GetEmployeeInformation = async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.employeeId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeInformation", params)
        .then(res => {
            const data = res.data;
            if(data.status==="1"){
                this.setState({
                    employeedetails : data,
                    profileStatus:data.profileStatus,
                    firstName:data.firstName,
                    middleName:data.middleName,
                    suffixName:data.suffixName,
                    lastName:data.lastName,
                    nickName:data.nickName,
                    homeNumber:data.homePhoneNumber,
                    officeNumber:"",//this.state.employeedetails.nickName,
                    mobileNumber:data.mobileNumber,
                    emailAddress:data.emailAddress,
                    facebook:data.facebook,
                    twitter:data.twitter,
                    dateofBirth:data.dateOfBirth !=="" ? new Date(data.dateOfBirth) : "",
                    placeofBirth:data.placeOfBirth,
                    height:data.height,
                    weight:data.weight,
                    languageSpoken:data.languageSpoken,
                    hobbies:data.hobbies,
                    contactName:data.emergencyContactName,
                    contactNumber:data.emergencyContactNumber,
                    contactRelation:data.emergencyContactRelation,
                    sssNumber:data.sssNumber,
                    tinNumber:data.tinNumber,
                    phicNumber:data.phicNumber,
                    hdmfNumber:data.hdmfNumber,
                    arrangementId:data.livingArrangementId,
                    arrangement:data.livingArrangement,
                    bloodtypeId:data.bloodTypeId,
                    bloodtype:data.bloodType,
                    nationalityId:data.nationalityId,
                    nationality:data.nationality,
                    religionId:data.religionId,
                    religion:data.religion,
                    genderId:data.genderId,
                    gender:data.gender,
                    civilstatusId: data.civilStatusId,
                    civilstatus:data.civilStatus,
        
                    locationId:data.locationId,
                    positionId:data.positionId,
                    employmentId:data.employmentStatusId,
                    cardtypeId:data.payCardTypeId,
                    paytypeId:data.payTypeId,
                    paymodeId:data.payModeId,
                    salaryRate:data.salaryRate,
                    seaRate:data.seaRate,
                    ecolaRate:data.ecolaRate,
                    colaRate:data.colaRate,
                    isdefaultLocation:data.isDefaultLocation,
                    cardNumber:data.payCardNumber,
                    dateHired:data.dateHired !=="" ? new Date(data.dateHired) : "",
                    dateResigned:data.dateResigned !=="" ? new Date(data.dateResigned) : "",
                    contractStart:data.contractDateStart !=="" ? new Date(data.contractDateStart) : "",
                    contractEnd:data.contractDateEnd !=="" ? new Date(data.contractDateEnd) : "",
                    location:data.location,
                    position:data.position,
                    employment:data.employmentStatus,
                    cardtype:data.payCardType,
                    paytype:data.payType,
                    paymode:data.payMode,
                    approverId1:data.approverId1,
                    approverId2:data.approverId2,
                    approver1:data.approver1,
                    approver2:data.approver2,
                    remarks:""
                })
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetEmployeeInformationDetails()
    }
    GetEmployeeInformationDetails = async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "ProfileId":this.state.profileId,
            "EmployeeId":this.state.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileDetails", params)
        .then(res => {
            const data = res.data;
            this.setState({
                addressGRID : data.employeeAddresses.length>0 ? data.employeeAddresses : [],
                afamilyGRID: data.employeeFamilyBackgrounds.length>0 ? data.employeeFamilyBackgrounds : [],
                educationGRID: data.employeeEducations.length>0 ? data.employeeEducations : [],
                isloading:false
            })
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetPayCardHistory()
    }
    GetPayCardHistory = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   this.state.employeeId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Manning/GetEmployeePayCards",  params)
        .then(res => {
            const data = res.data;
            this.setState({paycardHistoryDDL:data.payCards});
            console.log(data)
        })
    }
    GetNationalities = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId"     :   "",
            "DepartmentId"  :   "",
            "Name"          :   ""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetNationalities",  params)
        .then(res => {
            const data = res.data;
            this.setState({nationalityDDL:data.nationalities});
        })
        this.GetReligions()
    }
    GetReligions = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetReligions",  params)
        .then(res => {
            const data = res.data;
            this.setState({religionDDL:data.religions});
        })
        this.GetLivingArrangements()
    }
    GetLivingArrangements = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetLivingArrangements",  params)
        .then(res => {
            const data = res.data;
            this.setState({arrangementDDL:data.livingArrangements});
        })
        this.GetBloodTypes()
    }
    GetBloodTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetBloodTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({bloodtypeDDL:data.bloodTypes});
        })
        this.GetPositions()
    }
    GetPositions = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId"     :   "",
            "DepartmentId"  :   "",
            "Name"          :   ""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  params)
        .then(res => {
            const data = res.data;
            this.setState({positionDDL:data.positions});
        })
        this.GetPayrollTypes()
    }
    GetPayrollTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({paytypeDDL:data.payrollTypes});
        })
        this.GetPayrollModes()
    }
    GetPayrollModes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollModes",  params)
        .then(res => {
            const data = res.data;
            this.setState({paymodeDDL:data.payrollModes});
        })
        this.GetEmployeeStatusTypes()
    }
    GetEmployeeStatusTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetEmployeeStatusTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({employmentDDL:data.statusTypes});
        })
        this.GetPayCardTypes()
    }
    GetPayCardTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayCardTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({cardtypeDDL:data.payCardTypes});
        })
        this.GetRegions()
    }
    GetRegions = async () => {

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":""
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetRegions",  params)
        .then(res => {
            let regionDDL = []
            res.data.regions.map(function(itm){
                regionDDL.push({
                    value : itm.id,
                    label : itm.name,
                })
            })
            this.setState({regionDDL:regionDDL})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetProvinces()
    }
    GetProvinces = async () => {
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Region": ""
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetProvinces",  params)
        .then(res => {
            let provinceDDL = []
            res.data.provinces.map(function(itm){
                provinceDDL.push({
                    value : itm.id,
                    label : itm.name,
                    regionId:itm.regionId
                })
            })
            this.setState({provinceDDL:provinceDDL})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetCities()
    }
    GetCities = async () => {

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":"",
            "ProvinceName": ""
        };
        await
         axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetCities",  params)
        .then(res => {
            let cityDDL = []
            res.data.cities.map(function(itm){
                cityDDL.push({
                    value : itm.id,
                    label : itm.name,
                    provinceId:itm.provinceId
                })
            })
            this.setState({cityDDL:cityDDL})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleChangedClient = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.clientId= ''
            this.state.client=''
            this.state.employeeDDL=this.state.employeeList
            //this.state.employeeDDL=[]
            //this.GetEmployeeList()
            return
        }
        this.state.clientId=e[0].id
        this.setState({employeeDDL:this.state.employeeList.filter(x=>x.clientId===e[0].id),client:e[0].name})
    }
    handleKeyDownClient = (e) => {
        if (e.key === 'Enter') {
            
        }
    }
    handleChangedEmployee = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        
        if(e.length===0){
            this.state.employeeId= ''
            this.state.profileId= ''
            this.state.employeeName=''
            this.state.disablebutton = true
            return
        }
        this.state.employeeId= e[0].id
        this.state.profileId= e[0].profileId
        this.state.employeeName=e[0].employeeName
        this.GetLocations(e[0].client)
        this.state.approver1DDL = this.state.employeeDDL.filter(x=>x.id!==this.state.employeeId)
        this.state.disablebutton = false
    }
    handleChangedNationality = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.nationalityId=""
            this.state.nationality=""
            return
        }
        this.state.nationalityId=e[0].id
        this.state.nationality=e[0].name
    }
    handleChangedReligion = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.religionId=""
            this.state.religion=""
            return
        }
        this.state.religionId=e[0].id
        this.state.religion=e[0].name
    }
    handleChangedGender = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.genderId=""
            this.state.gender=""
            return
        }
        this.state.genderId=e[0].id
        this.state.gender=e[0].name
    }
    handleChangedCivilStatus = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.civilstatusId=""
            this.state.civilstatus=""
            return
        }
        this.state.civilstatusId=e[0].id
        this.state.civilstatus=e[0].name
    }
    handleChangedArrangement = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.arrangementId=""
            this.state.arrangement=""
            return
        }
        this.state.arrangementId=e[0].id
        this.state.arrangement=e[0].name
    }
    handleChangedBloodType = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.bloodtypeId=""
            this.state.bloodtype=""
            return
        }
        this.state.bloodtypeId=e[0].id
        this.state.bloodtype=e[0].name
    }
    handleChangedLocation = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.locationId=""
            this.state.location=""
            return
        }
        this.state.locationId=e[0].id
        this.state.location=e[0].name
    }
    handleChangedPosition = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.positionId=""
            this.state.position=""
            return
        }
        this.state.positionId=e[0].id
        this.state.position=e[0].name
    }
    handleChangedEmployment = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.employmentId=""
            this.state.employment=""
            return
        }
        if(e[0].id==="5" || 
                e[0].id==="11" ||
                e[0].id==="17" ||
                e[0].id==="22" ||
                e[0].id==="23" ||
                e[0].id==="24"){
            this.setState({disableresigned:false})
        }
        else{
            this.setState({disableresigned:true})
        }
        this.state.employmentId=e[0].id
        this.state.employment=e[0].name
    }
    handleChangedPayCardType = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.cardtypeId=""
            this.state.cardtype=""
            return
        }
        
        if(e[0].id==="4"){
            this.setState({ disablecard: true,cardNumber:""})
        }
        else{
            this.setState({ disablecard: false})
        }
        this.state.cardtypeId=e[0].id
        this.state.cardtype=e[0].name
        if(this.state.paycardHistoryDDL.filter(x=>x.payCardTypeId===e[0].id).length>0){
            this.state.cardNumber = this.state.paycardHistoryDDL.find(x=>x.payCardTypeId===e[0].id).payCardNumber
        }
        else{
            this.state.cardNumber = ""
        }
    }
    handleChangedPayCard = (e) => {
        // const re = /^[0-9\b]+$/;
        // if (e.target.value === '' || re.test(e.target.value)) {
        //     this.setState({ cardNumber: e.target.value,isshow:false, })
        // }
        this.setState({ cardNumber: e.target.value,isshow:false, })
    };
    handleChangedPayType = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.paytypeId=""
            this.state.paytype=""
            return
        }
        this.state.paytypeId=e[0].id
        this.state.paytype=e[0].name
    }
    handleChangedPayMode = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.paymodeId=""
            this.state.paymode=""
            return
        }
        this.state.paymodeId=e[0].id
        this.state.paymode=e[0].name
    }
    handleChangedApprover1 = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.approver1Id=""
            this.state.approver1=""
            return
        }
        this.state.approver1Id=e[0].id
        this.state.approver1=e[0].employeeName
    }
    handleChangedApprover2 = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.approver2Id=""
            this.state.approver2=""
            return
        }
        this.state.approver2Id=e[0].id
        this.state.approver2=e[0].employeeName
    }
    
    handleChangedDOB = date =>{
        this.setState({dateofBirth:date})
    }
    handleChangedDR = date =>{
        this.setState({dateResigned:date})
    }
    handleChangedEOC = date =>{
        this.setState({contractDateEnd:date})
    }
    handleChangedDDL = name => e =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,isloading:false});
        if(e.length===0) {
            this.setState({[name]:""});
        }
        else{
            this.setState({[name]:e[0].id})

            if(name==="cardtypeId" && e[0].id==="4"){
                this.setState({disablecard:true,cardNumber:""})
            }
            if(name==="cardtypeId" && e[0].id!=="4"){
                this.setState({disablecard:false})
            }
            
            if(name==="employmentId"){
                if(e[0].id==="5" || 
                    e[0].id==="11" ||
                    e[0].id==="17" ||
                    e[0].id==="22" ||
                    e[0].id==="23" ||
                    e[0].id==="24"){
                        this.setState({disableresigned:false})
                }
                else{
                    this.setState({disableresigned:true})
                }
            }
        }
    }
    handleChangedDate = name => date =>{
        this.setState({[name]:date})
    }
    handleChanged = (e) =>{
        if(e.target.name==="salaryRate" ||
            e.target.name==="ecolaRate" ||
            e.target.name==="colaRate" ||
            e.target.name==="seaRate"){
            if(e.target.value.split(".").length>2) return
            let dec = e.target.value.split(".")[1]
            if(typeof dec !='undefined')
                if(dec.length>2) return

        }
        //this.state[e.target.name] = e.target.value
        this.setState({[e.target.name]:e.target.value})
    }
    handleChangedCB = (e) =>{
        this.setState({[e.target.name]: e.target.checked})
    }
    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (('0' + (date.getMonth()+1)).slice(-2) + "/" + ('0' + (date.getDate())).slice(-2) + "/" + date.getFullYear()) : "")
    }
    ValidNumeric(val)
    {
        var regex = /^\d+(.\d+)?$/;
        return !regex.test(val)
    }
    IsNumeric(evt)
    {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
        
    }
    IsDecimal(evt)
    {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }
    handleAddAddress = () =>{
        if(this.state.profileId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "No selected employee.",
                fade        :   true
            })
            return
        }
        let addressGRID = this.state.addressGRID
        let newId = 0;
        if(addressGRID.length===0){
            let today = new Date()
            newId = parseInt(moment(today).format('HHMMSS'));
        }
        else{
            let today = new Date()
            const ids = addressGRID.map(x => x.id);
            const sorted = ids.sort((a, b) => a - b);
            const uid = moment(today).format('SS');
            newId = parseInt(sorted[sorted.length-1])+1//+uid;
        }

        addressGRID.push({
            "profileId":this.state.profileId,
            "id" :newId.toString(),
            "typeId":"",
            "regionId" : "",
            "region" : "",
            "provinceId" : "",
            "province" : "",
            "cityId" : "",
            "city" : "",
            "houseNumber" : "",
            "streetName" : "",
            "barangay" : "",
            "postalCode" : "",
            "isDeleted" : "0"
        })
        this.setState({addressGRID:addressGRID})
    }
    handleRemoveAddress = (id) =>{
        let addressGRID = this.state.addressGRID
        let addressGRIDDel = this.state.addressGRIDDel
        addressGRID.map(function(itm){
            if(itm.id===id){
                itm.isDeleted="1"
                addressGRIDDel.push(itm)
            }
        })
        this.setState({
            addressGRID:addressGRID.filter(x=>x.isDeleted==="0")
        })
    }
    handleClickBackground = () =>{
        if(this.state.profileId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "No selected employee.",
                fade        :   true
            })
            return
        }
        let backgroundGRID = this.state.backgroundGRID
        let newId = 0;
        if(backgroundGRID.length===0){
            let today = new Date()
            newId = parseInt(moment(today).format('HHMMSS'));
        }
        else{
            let today = new Date()
            const ids = backgroundGRID.map(x => x.id);
            const sorted = ids.sort((a, b) => a - b);
            const uid = moment(today).format('SS');
            newId = parseInt(sorted[sorted.length-1])+1//+uid;
        }

        backgroundGRID.push({
            "profileId":this.state.profileId,
            "id" :newId.toString(),
            "roleId":"",
            "name" : "",
            "age" : "",
            "occupation" : "",
            "company" : "",
            "isDeleted" : "0"
        })
        this.setState({backgroundGRID:backgroundGRID})
    }
    handleClickBackgroundRemove = (id) =>{
        let backgroundGRID = this.state.backgroundGRID
        let backgroundGRIDDel = this.state.backgroundGRIDDel
        backgroundGRID.map(function(itm){
            if(itm.id===id){
                itm.isDeleted="1"
                backgroundGRIDDel.push(itm)
            }
        })
        this.setState({backgroundGRID:backgroundGRID.filter(x=>x.isDeleted==="0")})
    }

    handleAddEducation = () =>{
        
        if(this.state.profileId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "No selected employee.",
                fade        :   true
            })
            return
        }
        let educationGRID = this.state.educationGRID
        let newId = 0;
        if(educationGRID.length===0){
            let today = new Date()
            newId = parseInt(moment(today).format('HHMMSS'));
        }
        else{
            let today = new Date()
            const ids = educationGRID.map(x => x.id);
            const sorted = ids.sort((a, b) => a - b);
            const uid = moment(today).format('SS');
            newId = parseInt(sorted[sorted.length-1])+1+uid;
        }

        educationGRID.push({
            "profileId":this.state.profileId,
            "id" :newId.toString(),
            "levelId":"",
            "schoolName" : "",
            "course" : "",
            "startYear" : "",
            "endYear" : "",
            "honorRecieved" : "",
            "isDeleted" : "0"
        })
        this.setState({educationGRID:educationGRID})
    }
    handleRemoveEducation = (id) =>{
        let educationGRID = this.state.educationGRID
        let educationGRIDDel = this.state.educationGRIDDel
        educationGRID.map(function(itm){
            if(itm.id===id){
                itm.isDeleted="1"
                educationGRIDDel.push(itm)
            }
        })
        this.setState({educationGRID:educationGRID.filter(x=>x.isDeleted==="0")})
    }
    handleSubmitClick = async () => {
        this.setState({
            isloading   :   true,
            isshow      :   false,
            fade        :   true,
            disablebutton:  true,
            color       :   "",
            message     :   "",
            alerttype   :   ""
        })

        /* let address = this.state.addressGRID.concat(this.state.addressGRIDDel)
        let familyBackgrounds = this.state.backgroundGRID.concat(this.state.backgroundGRIDDel)
        let educations = this.state.educationGRID.concat(this.state.educationGRIDDel)
        
        if(address.filter(x=>x.typeId!=="" && x.regionId==="" && x.isDeleted==="0").length>0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please review address details for some invalid inputs.",
                fade        :   true,
                disablebutton:  false
            });
            return
        }
        if(address.filter(x=>x.typeId!=="" && x.provinceId==="" && x.isDeleted==="0").length>0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please review address details for some invalid inputs.",
                fade        :   true,
                disablebutton:  false
            });
            return
        }

        if(address.filter(x=>x.typeId!=="" && x.cityId==="" && x.isDeleted==="0").length>0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please review address details for some invalid inputs.",
                fade        :   true,
                disablebutton:  false
            });
            return
        }

        if(educations.filter(x=>x.levelId!=="" && x.startYear==="" && x.isDeleted==="0").length>0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please review education details for some invalid inputs.",
                fade        :   true,
                disablebutton:  false
            });
            return
        }

        if(educations.filter(x=>x.levelId!=="" && x.endYear==="" && x.isDeleted==="0").length>0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please review education details for some invalid inputs.",
                fade        :   true,
                disablebutton:  false
            });
            return
        } */

        if(this.state.cardtypeId!=="4" && this.state.cardNumber===''){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please input card number.",
                fade        :   true,
                disablebutton:  false
            });
            return
        }
        
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "UserEmployeeId":this.state.userinfo.employeeId,

            "EmployeeId":this.state.employeeId,
            "ProfileId":this.state.profileId,
            "EmployeeName":this.state.employeeName,
            
            "FirstName":"",
            "MiddleName":this.state.middleName,
            "SuffixName":this.state.suffixName,
            "LastName":"",
            "NickName":"",
            "PhoneNumber":"",
            "MobileNumber":"",
            "EmailAddress":"",
            "DateOfBirth":"",
            "PlaceOfBirth":"",
            "NationalityId":"",
            "ReligionId":"",
            "GenderId":"",
            "Gender":"",
            "CivilStatusId":"",
            "CivilStatus":"",
            "LivingArrangementId":"",
            "BloodTypeId":"",
            "Height":"",
            "Weight":"",
            "Hobbies":"",
            "LanguageSpoken":"",
            "Facebook":"",
            "Twitter":"",
            "SSSNumber":"",
            "PHICNumber":"",
            "HDMFNumber":"",
            "TINNumber":"",
            "EmergencyContactName":"",
            "EmergencyContactNumber":"",
            "EmergencyContactRelation":"",
            
            "IsDefaultLocation":"",
            "LocationId":"",
            "PositionId":"",
            "EmploymentStatusTypeId":"",
            "PayTypeId":"",
            "PayModeId":"",
            "PayCardTypeId":this.state.cardtypeId,
            "PayCardNumber":this.state.cardNumber,
            "SalaryOffered":"",
            "SeaValue":"",
            "EColaValue":"",
            "ColaValue":"",
            "DateHired":"",
            "ContractDateStart":"",
            "ContractDateEnd":"",
            "DateResigned":"",
            "ApproverId1":"",
            "ApproverId2":"",
            "Remarks":this.state.remarks,
            "address":[],
            "familyBackgrounds":[],
            "educations":[],
        };

        //console.log(params)
        
         await
       axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Manning/SubmitEmployeeInformation",  params)
        .then(res => {
            const data = res.data
            this.setState({isloading:false})
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    disablebutton:  false
                });
            } 
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
            } 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
                disablebutton:  false
            })
        })
    }
    render(){
        const addrCol = [
            {
                dataField: 'typeId',
                text: 'TYPE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'8%'}},
                style:{textAlign:'left',width:'8%'},
                formatter: (cell, row) => {
                    if(row.typeId!=='' || row.typeId!==null || typeof row.typeId!=='undefined'){
                        if(this.state.addrtypeDDL.filter(x => x.value == cell).length>0)
                            return this.state.addrtypeDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.addrtypeDDL
                },
                validator: (newValue, row, column) => {
                    if(this.state.addressGRID.filter(x=>x.typeId===newValue).length>0)
                    return {
                        valid: false,
                        message:"Select address type already exist."
                      };
                    return true;   
                }
            },
            {
                dataField: 'regionId',
                text: 'REGION',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%'}},
                style:{textAlign:'left',width:'15%'},
                formatter: (cell, row) => {
                    if(row.regionId!=='' || row.regionId!==null || typeof row.regionId!=='undefined'){
                        if(this.state.regionDDL.filter(x => x.value == cell).length>0)
                            return this.state.regionDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.regionDDL
                },
                validator: (newValue, row, column) => {
                    if (row.typeId==="") {
                      return {
                        valid: false,
                        message:"Please select address type."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'provinceId',
                text: 'PROVINCE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%'}},
                style:{textAlign:'left',width:'15%'},
                formatter: (cell, row) => {
                    if(row.provinceId!=='' && row.provinceId!==null || typeof row.provinceId!=='undefined'){
                        if(this.state.provinceDDL.filter(x => x.value == cell).length>0)
                            return this.state.provinceDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    getOptions: (setOptions, { row, column }) => {
                        return this.state.provinceDDL.filter(x=>x.regionId==row.regionId);
                    }
                },
                validator: (newValue, row, column) => {
                    if (row.regionId==="") {
                      return {
                        valid: false,
                        message:"Please select region address."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'cityId',
                text: 'CITY',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%'}},
                style:{textAlign:'left',width:'15%'},
                formatter: (cell, row) => {
                    if(row.cityId!=='' && row.cityId!==null || typeof row.cityId!=='undefined'){
                        if(this.state.cityDDL.filter(x => x.value == cell).length>0)
                            return this.state.cityDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    getOptions: (setOptions, { row, column }) => {
                        return this.state.cityDDL.filter(x=>x.provinceId==row.provinceId);
                    }
                },
                validator: (newValue, row, column) => {
                    if (row.provinceId==="") {
                      return {
                        valid: false,
                        message:"Please select province address."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'houseNumber',
                text: 'House Number',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'13%'}},
                style:{textAlign:'left',width:'13%'},
                validator: (newValue, row, column) => {
                    if (row.cityId==="") {
                      return {
                        valid: false,
                        message:"Please select city address."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'streetName',
                text: 'STREET NAME',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%'}},
                style:{textAlign:'left',width:'10%'},
                validator: (newValue, row, column) => {
                    if (row.houseNumber==="") {
                      return {
                        valid: false,
                        message:"Please enter house number."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'barangay',
                text: 'BARANGAY',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%'}},
                style:{textAlign:'left',width:'10%'},
                validator: (newValue, row, column) => {
                    if (row.streetName==="") {
                      return {
                        valid: false,
                        message:"Please enter street name."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'postalCode',
                text: 'POSTAL CODE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'7%'}},
                style:{textAlign:'left',width:'7%'},
                validator: (newValue, row, column) => {
                    if (this.ValidNumeric(newValue)) {
                      return {
                        valid: false,
                        message:"Value must be numeric."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'action',
                text: 'ACTION',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'7%'}
                },
                style:{textAlign:'center',width:'7%'},
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button 
                            onClick={e => this.handleRemoveAddress(row.id)}
                            style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link"
                    >REMOVE</Button>
                    );
                },
            }
        ]
        const fbCol = [
            {
                dataField: 'roleId',
                text: 'ROLE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%'}},
                style:{textAlign:'left',width:'10%'},
                formatter: (cell, row) => {
                    if(row.roleId!=='' || row.roleId!==null || typeof row.roleId!=='undefined'){
                        if(this.state.roleDDL.filter(x => x.value == cell).length>0)
                            return this.state.roleDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.roleDDL
                },
                validator: (newValue, row, column) => {
                    if(this.state.backgroundGRID.filter(x=>x.roleId===newValue).length>0 && newValue==="0")
                    return {
                        valid: false,
                        message:"Cannot set multiple for role father."
                      };
                    if(this.state.backgroundGRID.filter(x=>x.roleId===newValue).length>0 && newValue==="1")
                    return {
                        valid: false,
                        message:"Cannot set multiple for role mother."
                    };
                    if(this.state.backgroundGRID.filter(x=>x.roleId===newValue).length>0 && newValue==="2")
                    return {
                        valid: false,
                        message:"Cannot set multiple for role spouse."
                    };
                    return true;   
                }
            },
            {
                dataField: 'name',
                text: 'Name',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%'}},
                style:{textAlign:'left',width:'20%'},
                validator: (newValue, row, column) => {
                    if (row.roleId==="") {
                      return {
                        valid: false,
                        message:"Please enter role."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'age',
                text: 'AGE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center',width:'10%'},
                validator: (newValue, row, column) => {
                    if (row.name==="") {
                        return {
                          valid: false,
                          message:"Please enter name."
                        };
                    } 
                    if (this.ValidNumeric(newValue)) {
                      return {
                        valid: false,
                        message:"Value must be numeric."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'occupation',
                text: 'OCCUPATION',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%'}},
                style:{textAlign:'center',width:'15%'},
                validator: (newValue, row, column) => {
                    if (row.age==="") {
                        return {
                          valid: false,
                          message:"Please enter age."
                        };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'company',
                text: 'COMPANY',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'38%'}},
                style:{textAlign:'left',width:'38%'},
                validator: (newValue, row, column) => {
                    if (row.occupation==="") {
                        return {
                          valid: false,
                          message:"Please enter company."
                        };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'action',
                text: 'ACTION',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'7%'}
                },
                style:{textAlign:'center',width:'7%'},
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button 
                        onClick={e=>this.handleClickBackgroundRemove(row.id)}
                            style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link"
                    >REMOVE</Button>
                    );
                },
            }
        ]
        const eduCol = [
            {
                dataField: 'levelId',
                text: 'LEVEL',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%'}},
                style:{textAlign:'left',width:'10%'},
                formatter: (cell, row) => {
                    if(row.levelId!=='' || row.levelId!==null || typeof row.levelId!=='undefined'){
                        if(this.state.levelDDL.filter(x => x.value == cell).length>0)
                            return this.state.levelDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.levelDDL
                },
                validator: (newValue, row, column) => {
                    if(this.state.educationGRID.filter(x=>x.levelId===newValue).length>0)
                    return {
                        valid: false,
                        message:"Selected level already exist."
                      };
                    return true;   
                }
            },
            {
                dataField: 'schoolName',
                text: 'SCHOOL NAME',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%'}},
                style:{textAlign:'left',width:'20%'},
                validator: (newValue, row, column) => {
                    if(row.levelId==="")
                    return {
                        valid: false,
                        message:"Please select school level."
                      };
                    return true;   
                }
            },
            {
                dataField: 'course',
                text: 'COURSE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%'}},
                style:{textAlign:'left',width:'15%'},
                validator: (newValue, row, column) => {
                    if(row.schoolName==="")
                    return {
                        valid: false,
                        message:"Please enter school name."
                      };
                    return true;   
                }
            },
            {
                dataField: 'startYear',
                text: 'START YEAR',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center',width:'10%'},
                validator: (newValue, row, column) => {
                    if(row.course==="")
                        return {
                            valid: false,
                            message:"Please enter course."
                    };
                    if (this.ValidNumeric(newValue)) {
                      return {
                        valid: false,
                        message:"Value must be numeric."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'endYear',
                text: 'END YEAR',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%'}},
                style:{textAlign:'left',width:'10%'},
                validator: (newValue, row, column) => {
                    if(row.startYear==="")
                        return {
                        valid: false,
                        message:"Please enter start year."
                    };
                    if (this.ValidNumeric(newValue)) {
                      return {
                        valid: false,
                        message:"Value must be numeric."
                      };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'honor',
                text: 'HONOR',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'28%'}},
                style:{textAlign:'left',width:'28%'},
                validator: (newValue, row, column) => {
                    if(row.endYear==="")
                        return {
                        valid: false,
                        message:"Please enter start year."
                    };
                    return true;   
                }
            },
            {
                dataField: 'action',
                text: 'ACTION',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'7%'}
                },
                style:{textAlign:'center',width:'7%'},
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button 
                            onClick={e=>this.handleRemoveEducation(row.id)}
                            style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link"
                    >REMOVE</Button>
                    );
                },
            }
        ]
        const selectrowAddr = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        const selectrowFB = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        const selectrowEdu = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        return(
            <div>
                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header>Finance >> Manage Employee</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            name="clientId"
                                            onChange={this.handleChangedClient}
                                            options={this.state.clientDDL}
                                            placeholder="Select Client"
                                            selected={[this.state.client]}

                                            // labelKey='name'
                                            // id="basic-example"
                                            // onChange={this.handleChangedClient}
                                            // onKeyDown={this.handleKeyDownClient}
                                            // options={this.state.clientDDL}
                                            // placeholder="Select Client"

                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.handleChangedEmployee}
                                            options={this.state.employeeDDL}
                                            placeholder="Select Employee"
                                        />
                                    </Col>
                                </Form.Group>
                            </Form>
                            <Tabs className="mt-2" defaultActiveKey="default" transition={false} id="noanim-tab-example">
                                <Tab eventKey="default" title="Employee Profiles">
                                        <Accordion className="mt-2"  defaultActiveKey="0">
                                        <Card className="card-tab">
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{color: "#FFFFFF"}}>
                                                        PROFILE INFORMATION
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                    <Form >
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                            STATUS
                                                            </Form.Label>
                                                            <Col sm="3">
                                                                <Form.Control 
                                                                    type="text"
                                                                    id="statusName"
                                                                    name="statusName"
                                                                    value={this.state.profileStatus}
                                                                    disabled
                                                                /> 
                                                            </Col>
                                                        </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    FIRST NAME
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="firstName"
                                                            name="firstName"
                                                            value={this.state.firstName}
                                                            onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        /> 
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    MIDDLE NAME
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="middleName"
                                                            name="middleName"
                                                            value={this.state.middleName}
                                                            onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                {/* <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    NICK NAME
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="nickName"
                                                            name="nickName"
                                                            value={this.state.nickName}
                                                            onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    LAST NAME
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="lastName"
                                                            name="lastName"
                                                            value={this.state.lastName}
                                                            onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group> */}
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    LAST NAME
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="lastName"
                                                            name="lastName"
                                                            value={this.state.lastName}
                                                            onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    SUFFIX NAME
                                                    </Form.Label>
                                                    <Col sm="1">
                                                        <Form.Control 
                                                            type="text"
                                                            id="suffixName"
                                                            name="suffixName"
                                                            value={this.state.suffixName}
                                                            onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    NICK NAME
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="nickName"
                                                            name="nickName"
                                                            value={this.state.nickName}
                                                            onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    HOME PHONE NUMBER
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="homeNumber"
                                                            name="homeNumber"
                                                            value={this.state.homeNumber}
                                                            onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    OFFICE PHONE NUMBER
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="officeNumber"
                                                            name="officeNumber"
                                                            value={this.state.officeNumber}
                                                            onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    MOBILE NUMBER
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="mobileNumber"
                                                            name="mobileNumber"
                                                            value={this.state.mobileNumber}
                                                            onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    EMAIL ADDRESS
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="emailAddress"
                                                            name="emailAddress"
                                                            value={this.state.emailAddress}
                                                            onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    FACEBOOK
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="facebook"
                                                            name="facebook"
                                                            value={this.state.facebook}
                                                            onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    TWITTER
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="twitter"
                                                            name="twitter"
                                                            value={this.state.twitter}
                                                            onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    NATIONALITY
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='nationality'
                                                            name='nationality'
                                                            //onChange={e => this.handleChangedDDL('nationalityId')}
                                                            onChange={this.handleChangedNationality}
                                                            options={this.state.nationalityDDL}
                                                            selected={[this.state.nationality]}
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    RELIGION
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='religionId'
                                                            name='religionId'
                                                            //onChange={e => this.handleChangedDDL('religionId')}
                                                            onChange={this.handleChangedReligion}
                                                            options={this.state.religionDDL}
                                                            selected={[this.state.religion]}
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    GENDER
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            name="genderId"
                                                            id="genderId"
                                                            //onChange={this.handleChangedDDL("genderId")}
                                                            onChange={this.handleChangedGender}
                                                            options={this.state.genderDDL}
                                                            selected={[this.state.gender]}
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    CIVIL STATUS
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='civilstatusId'
                                                            name='civilstatusId'
                                                            //onChange={e => this.handleChangedDDL('civilstatusId')}
                                                            onChange={this.handleChangedCivilStatus}
                                                            options={this.state.civilstatusDDL}
                                                            selected={[this.state.civilstatus]}
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    DATE OF BIRTH
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <DatePicker
                                                            id="dateofBirth"
                                                            name="dateofBirth"
                                                            value={this.state.dateofBirth}
                                                            selected={this.state.dateofBirth}
                                                            onChange={this.handleChangedDOB}
                                                            minDate={this.minDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    PLACE OF BIRTH
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="placeofBirth"
                                                            name="placeofBirth"
                                                            value={this.state.placeofBirth}
                                                            onChange={this.handleChanged} 
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    LIVING ARRANGEMENT
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='arrangementId'
                                                            name='arrangementId'
                                                            onChange={this.handleChangedArrangement}
                                                            options={this.state.arrangementDDL}
                                                            selected={[this.state.arrangement]}
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    BLOOD TYPE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='bloodtypeId'
                                                            name='bloodtypeId'
                                                            onChange={this.handleChangedBloodType}
                                                            options={this.state.bloodtypeDDL}
                                                            selected={[this.state.bloodtype]}
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    HEIGHT
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="height"
                                                            name="height"
                                                            value={this.state.height}
                                                            autoComplete="off"
                                                            onChange={this.handleChanged}
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    WEIGHT
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="weight"
                                                            name="weight"
                                                            value={this.state.weight}
                                                            autoComplete="off"
                                                            onChange={this.handleChanged}
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    LANGUAGE SPOKEN
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="languageSpoken"
                                                            name="languageSpoken"
                                                            value={this.state.languageSpoken}
                                                            onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    HOBBIES
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="hobbies"
                                                            name="hobbies"
                                                            onChange={this.handleChanged}
                                                            value={this.state.hobbies}
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    EMERGENCY CONTACT NAME
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="contactName"
                                                            name="contactName"
                                                            value={this.state.contactName}
                                                            onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    EMERGENCY CONTACT NUMBER
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="contactNumber"
                                                            name="contactNumber"
                                                            value={this.state.contactNumber}
                                                            onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    EMERGENCY CONTACT RELATION
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="contactRelation"
                                                            name="contactRelation"
                                                            value={this.state.contactRelation}
                                                            onChange={this.handleChanged}
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    SSS NUMBER
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <NumberFormat
                                                            id="sssNumber"
                                                            name="sssNumber"
                                                            value={this.state.sssNumber}
                                                            onChange={this.handleChanged} 
                                                            autoComplete="off"
                                                            format="##-#######-#"
                                                            className="form-control"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    TIN NUMBER 
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <NumberFormat
                                                            id="tinNumber"
                                                            name="tinNumber"
                                                            value={this.state.tinNumber}
                                                            onChange={this.handleChanged} 
                                                            autoComplete="off"
                                                            format="##-#######-#"
                                                            className="form-control"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    PHIC NUMBER
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <NumberFormat
                                                            id="phicNumber"
                                                            name="phicNumber"
                                                            value={this.state.phicNumber}
                                                            onChange={this.handleChanged} 
                                                            autoComplete="off"
                                                            format="##-#######-#"
                                                            className="form-control"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    HDMF NUMBER 
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <NumberFormat
                                                            id="hdmfNumber"
                                                            name="hdmfNumber"
                                                            value={this.state.hdmfNumber}
                                                            onChange={this.handleChanged} 
                                                            autoComplete="off"
                                                            format="##-#######-#"
                                                            className="form-control"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Form>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        {/* <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="1" style={{color: "#FFFFFF"}}>
                                                        EMPLOYEE ADDRESSESS
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <div>
                                                <BootstrapTable
                                                    striped
                                                    hover
                                                    condensed
                                                    keyField = "id"
                                                    data = { this.state.addressGRID }
                                                    columns = { addrCol}
                                                    selectRow = { selectrowAddr }
                                                    cellEdit = { cellEditFactory({ 
                                                        mode: 'dbclick', 
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => { 
                                                            //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }})
                                                    }
                                                    rowClasses="noser-table-row-class no-checkbox"
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                />
                                               <div style={{marginLeft:'5px',marginBottom:'10x'}}>
                                                    <Button id="addrid"
                                                    className="noser-button" 
                                                    variant="success" 
                                                    onClick = {this.handleAddAddress}
                                                    >ADD</Button>
                                                </div>
                                                </div> 
                                            </Accordion.Collapse> 
                                        </Card>
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="2" style={{color: "#FFFFFF"}}>
                                                        FAMILY BACKGROUND
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="2">
                                                <div>
                                                    <BootstrapTable
                                                        striped
                                                        hover
                                                        condensed
                                                        keyField = "id"
                                                        data = { this.state.backgroundGRID }
                                                        columns = { fbCol}
                                                        selectRow = { selectrowFB }
                                                        cellEdit = { cellEditFactory({ 
                                                            mode: 'dbclick', 
                                                            blurToSave: true,
                                                            afterSaveCell: (oldValue, newValue, row, column) => { 
                                                                //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                            }})
                                                        }
                                                        rowClasses="noser-table-row-class no-checkbox"
                                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    />
                                                    div style={{marginLeft:'5px',marginBottom:'10x'}}>
                                                        <Button id="fbid"
                                                        className="noser-button" 
                                                        variant="success" 
                                                        onClick = {this.handleClickBackground}
                                                        >ADD</Button>
                                                    </div>
                                                </div> 
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="3" style={{color: "#FFFFFF"}}>
                                                        EDUCATION ATTAINMENT
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="3">
                                                <div>
                                                    <BootstrapTable
                                                        striped
                                                        hover
                                                        condensed
                                                        keyField = "id"
                                                        data = { this.state.educationGRID }
                                                        columns = { eduCol}
                                                        selectRow = { selectrowEdu }
                                                        cellEdit = { cellEditFactory({ 
                                                            mode: 'dbclick', 
                                                            blurToSave: true,
                                                            afterSaveCell: (oldValue, newValue, row, column) => { 
                                                                //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                            }})
                                                        }
                                                        rowClasses="noser-table-row-class no-checkbox"
                                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    />
                                                    <div style={{marginLeft:'5px',marginBottom:'10x'}}>
                                                        <Button id="eduid"
                                                        className="noser-button" 
                                                        variant="success" 
                                                        onClick = {this.handleAddEducation}
                                                        >ADD</Button>
                                                    </div>
                                                </div>
                                            </Accordion.Collapse>
                                        </Card> */}
                                        </Accordion>
                                </Tab>
                                <Tab eventKey="201" title="Employee Records">
                                    <Accordion className="mt-2"  defaultActiveKey="0">
                                    <Card className="card-tab">
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{color: "#FFFFFF"}}></Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <Form>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    BRANCH / LOCATION
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead 
                                                            labelKey='name'
                                                            id='locationId'
                                                            name='locationId'
                                                            onChange={this.handleChangedLocation}
                                                            options={this.state.locationDDL}
                                                            selected={[this.state.location]}
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Col sm="3">
                                                        <Form.Check type="checkbox" 
                                                            label="IS DEFAULT BRANCH" 
                                                            id="isdefaultLocation"
                                                            name="isdefaultLocation"
                                                            onChange={this.handleChangedCB}
                                                            style={{fontWeight : "bold",marginLeft:"2px"}}
                                                            checked={this.state.isdefaultLocation == "1" ? true:false}
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    POSITION
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='positionId'
                                                            name='positionId'
                                                            onChange={this.handleChangedPosition}
                                                            options={this.state.positionDDL}
                                                            selected={[this.state.position]}
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    EMPLOYMENT STATUS
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='employmentId'
                                                            name='employmentId'
                                                            //onChange={e => this.handleChangedDDL('employmentId')}
                                                            onChange={this.handleChangedEmployment}
                                                            options={this.state.employmentDDL}
                                                            selected={[this.state.employment]}
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    DATE HIRED
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <DatePicker
                                                            id='dateHired'
                                                            name='dateHired'
                                                            //onChange={this.handleChangedDate('dateHired')}
                                                            minDate={this.minDate}
                                                            value={this.state.dateHired}
                                                            selected={this.state.dateHired}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    DATE RESIGNED
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <DatePicker
                                                            id='dateResigned'
                                                            name='dateResigned'
                                                            selected={this.state.dateResigned}
                                                            onChange={this.handleChangedDate('dateResigned')}
                                                            minDate={this.minDate}
                                                            value={this.state.dateResigned}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            disabled={this.state.isDisabledField}
                                                            autoComplete="off"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    CONTRACT DATE START
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <DatePicker
                                                            id='contractStart'
                                                            name='contractStart'
                                                            minDate={this.minDate}
                                                            value={this.state.contractStart}
                                                            selected={this.state.contractStart}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    CONTRACT DATE END
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <DatePicker
                                                            id='contractEnd'
                                                            name='contractEnd'
                                                            onChange={this.handleChangedEOC}
                                                            minDate={this.minDate}
                                                            value={this.state.contractEnd}
                                                            selected={this.state.contractEnd}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    PAY CARD TYPE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='cardtypeId'
                                                            name='cardtypeId'
                                                            onChange={this.handleChangedPayCardType}
                                                            options={this.state.cardtypeDDL}
                                                            selected={[this.state.cardtype]}
                                                            //disabled={this.state.disablecard}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    PAY CARD NUMBER
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text" 
                                                            id="cardNumber"
                                                            name="cardNumber"
                                                            onChange={this.handleChangedPayCard}
                                                            value={this.state.cardNumber}
                                                            disabled={this.state.disablecard}
                                                            autoComplete="off" 
                                                            onKeyPress={this.IsNumeric.bind(this)}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    PAY TYPE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='paytypeId'
                                                            name='paytypeId'
                                                            onChange={this.handleChangedPayType}
                                                            options={this.state.paytypeDDL}
                                                            selected={[this.state.paytype]}
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    PAY MODE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='paymodeId'
                                                            name='paymodeId'
                                                            onChange={this.handleChangedPayMode}
                                                            options={this.state.paymodeDDL}
                                                            selected={[this.state.paymode]}
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    SALARY RATE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="salaryRate"
                                                            name="salaryRate"
                                                            value={this.state.salaryRate}
                                                            onChange={this.handleChanged}
                                                            onKeyPress={this.IsDecimal.bind(this)}
                                                            maxLength="13"
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        /> 
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    SEA RATE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="seaRate"
                                                            name="seaRate"
                                                            value={this.state.seaRate}
                                                            onChange={this.handleChanged}
                                                            onKeyPress={this.IsDecimal.bind(this)}
                                                            maxLength="13"
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    ECOLA RATE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="ecolaRate"
                                                            name="ecolaRate"
                                                            value={this.state.ecolaRate}
                                                            onChange={this.handleChanged}
                                                            onKeyPress={this.IsDecimal.bind(this)}
                                                            maxLength="13"
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        /> 
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    COLA RATE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="colaRate"
                                                            name="colaRate"
                                                            value={this.state.colaRate}
                                                            onChange={this.handleChanged}
                                                            onKeyPress={this.IsDecimal.bind(this)}
                                                            maxLength="13"
                                                            autoComplete="off"
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    APPROVER 1
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead 
                                                            labelKey='employeeName'
                                                            id='approver1Id'
                                                            name='approver1Id'
                                                            onChange={this.handleChangedApprover1}
                                                            options={this.state.approver1DDL}
                                                            selected={[this.state.approver1]}
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    APPROVER 2
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead 
                                                            labelKey='employeeName'
                                                            id='approver2Id'
                                                            name='approver2Id'
                                                            onChange={this.handleChangedApprover2}
                                                            options={this.state.approver2DDL}
                                                            selected={[this.state.approver2]}
                                                            disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    REMARKS
                                                    </Form.Label>
                                                    <Col sm="10">
                                                        <Form.Control 
                                                            type="text"
                                                            id="remarks"
                                                            name="remarks"
                                                            value={this.state.remarks}
                                                            onChange={this.handleChanged} 
                                                            autoComplete="off"
                                                            multiline={true}
                                                            //disabled={this.state.isDisabledField}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Form>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    </Accordion>
                                </Tab>
                            </Tabs>
                        </Card.Body>
                        <ButtonToolbar mt="5" style={{marginRight:'13px',marginBottom:'10px'}}>
                            <Button disabled={this.state.disablebutton}  className="ml-auto noser-button-mr1" variant="success" onClick = {this.handleSubmitClick}>Submit</Button>
                            <Button  href="/manageemployees" className="noser-button" variant="warning">Back</Button>
                        </ButtonToolbar>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div>
        )
    }

}
export default ManageEmployee;
