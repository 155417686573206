import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

//import  TrainingRegisterModal from './TrainingModal/TrainingRegisterModal'
 
class IRList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            trainingRegisterTableList           :   [],
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalTrainingRegisterShow           :   false,

            irList              :[],
            dateReported        :"",
            venueOfIncident     :"",
            dateOfIncident      :"",
            timeOfIncident      :"",
            employeeName        :"",
            reportedByEmployee  :"",
            position            :"",
            reportedByPosition  :"",
            reportedDept        :"",
            client              :"",
            reportedByDept      :"",
            question1           :"",
            question2           :"",
            question3           :"",
            question4           :"",
            createddate         :"",
            irId                :"",
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getIncidentReport()
    }
    getIncidentReport =()=>{
        console.log("filter start")
        let filter_data ={}

        const getParams ={
            "_collection" : "TestIncidentReport",
            "filter_data" : filter_data
        }
            console.log("axios start")
            axios
            .post("http://134.209.99.190:8088/action/get" , getParams)
            .then(res =>{
                let data = res.data
                //console.log("res.data start")
                //console.log(res.data)
                //console.log("res.data end")
            
                const itemUsersDataLists = this.buildList(data["TestIncidentReport"])
                
                this.state.itemUserDataList  =  itemUsersDataLists
                console.log("blank lisnt")
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                    let obj = {
                        'id'                    :   this.state.itemUserDataList[i]['id'],
                        'client'                :   this.state.itemUserDataList[i]['client'],
                        'employeeName'          :   this.state.itemUserDataList[i]['employeeName'],
                        'position'              :   this.state.itemUserDataList[i]['position'],
                        'employeeNo'            :   this.state.itemUserDataList[i]['employeeNo'],
                        'nonEmployee'           :   this.state.itemUserDataList[i]['nonEmployee'],
                        'dateReported'          :   this.state.itemUserDataList[i]['dateReported'],
                        'dateOfIncident'        :   this.state.itemUserDataList[i]['dateOfIncident'],
                        'venueOfIncident'       :   this.state.itemUserDataList[i]['venueOfIncident'],
                        'timeOfIncident'        :   this.state.itemUserDataList[i]['timeOfIncident'],
                        'irReferenceNo'         :   this.state.itemUserDataList[i]['irReferenceNo'],
                        'question1'             :   this.state.itemUserDataList[i]['question1'],
                        'question2'             :   this.state.itemUserDataList[i]['question2'],
                        'question3'             :   this.state.itemUserDataList[i]['question3'],
                        'question4'             :   this.state.itemUserDataList[i]['question4'],
                        'reportedByClient'      :   this.state.itemUserDataList[i]['reportedByClient'],
                        'reportedByEmployee'    :   this.state.itemUserDataList[i]['reportedByEmployee'],
                        'reportedByPosition'    :   this.state.itemUserDataList[i]['reportedByPosition'],
                        'reportedByDept'        :   this.state.itemUserDataList[i]['reportedByDept'],
                        'isDraft'               :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'             :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'             :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'           :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'            :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'          :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'            :   this.state.itemUserDataList[i]['isModified'],
                        
                    }
                    List.push(obj)
                    
                    
                    this.setState({
                        irList:List,
                        dateReported:obj.dateReported,
                        venueOfIncident:obj.venueOfIncident,
                        dateOfIncident:obj.dateOfIncident,
                        timeOfIncident:obj.timeOfIncident,
                        employeeName:obj.employeeName,
                        reportedByEmployee:obj.reportedByEmployee,
                        position:obj.position,
                        reportedByPosition:obj.reportedByPosition,
                        reportedDept:obj.reportedDept,
                        client:obj.client,
                        reportedByDept:obj.reportedByDept,
                        question1:obj.question1,
                        question2:obj.question2,
                        question3:obj.question3,
                        question4:obj.question4,
                        createddate:obj.createddate,
                    })
                   
                }
                
                console.log("List start ")
                console.log(List)
                console.log("List end")

            })
            .catch(error=>{
                //console.log("error: " + error)
            })
        } 
        
    buildList(data) {
        //console.log("data start")
        let itemList =[]
    
        let idList                      =[]
        let clientList                  =[]
        let employeeNameList            =[]
        let positionList                =[]
        let employeeNoList              =[]
        let nonEmployeeList             =[]
        let dateReportedList            =[]
        let dateOfIncidentList          =[]
        let venueOfIncidentList         =[]
        let timeOfIncidentList          =[]
        let irReferenceNoList           =[]
        let question1List               =[]
        let question2List               =[]
        let question3List               =[]
        let question4List               =[]
        let reportedByClientList        =[]
        let reportedByEmployeeList      =[]
        let reportedByPositionList      =[]
        let reportedByDeptList          =[]
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
        
    
        for (let i = 0; i < data.length; i++) {
            
    
            let s1 = data[i].split("':")
            
            let idClean                     = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let clientTmp                   = s1[2].split("',")[0]
            let employeeNameListTmp         = s1[3].split("',")[0]
            let positionListTmp             = s1[4].split("',")[0]
            let employeeNoListTmp           = s1[5].split(",")[0]
            let nonEmployeeListListTmp      = s1[6].split("',")[0]
            let dateReportedListTmp         = s1[7].split("',")[0]
            let dateOfIncidentListTmp       = s1[8].split("',")[0]
            let venueOfIncidentListTmp      = s1[9].split("',")[0]
            let timeOfIncidentListTmp       = s1[10].split("',")[0]
            let irReferenceNoListTmp        = s1[11].split("',")[0]
            let question1ListTmp            = s1[12].split("',")[0]
            let question2ListTmp            = s1[13].split("',")[0]
            let question3ListTmp            = s1[14].split("',")[0]
            let question4ListTmp            = s1[15].split("',")[0]
            let reportedByClientListTmp     = s1[16].split("',")[0]
            let reportedByEmployeeListTmp   = s1[17].split("',")[0]
            let reportedByPositionListTmp   = s1[18].split("',")[0]
            let reportedByDeptListTmp       = s1[19].split("',")[0]
            let isDraftListTmp              = s1[20].split("',")[0]
            let isDeletedListTmp            = s1[21].split("',")[0]
            let createdbyListTmp            = s1[22].split("',")[0]
            let createddateListTmp          = s1[23].split("',")[0]
            let modifiedbyListTmp           = s1[24].split("',")[0]
            let modifieddateListTmp         = s1[25].split("',")[0]
            let isModifiedListTmp           = s1[26].split("',")[0]

        
            idList.push(IdClean2.replace("'","").replace(" ",""))
            clientList.push(clientTmp.replace("'",""))
            console.log(idList)
            employeeNameList.push(employeeNameListTmp.replace("'",""))
            positionList.push(positionListTmp.replace("'",""))
            employeeNoList.push(employeeNoListTmp.replace("'","").replace(" '",""))
            nonEmployeeList.push(nonEmployeeListListTmp.replace("'",""))
            dateReportedList.push(dateReportedListTmp.replace("'",""))
            dateOfIncidentList.push(dateOfIncidentListTmp.replace("'",""))
            venueOfIncidentList.push(venueOfIncidentListTmp.replace("'",""))
            timeOfIncidentList.push(timeOfIncidentListTmp.replace("'",""))
            irReferenceNoList.push(irReferenceNoListTmp.replace("'",""))
            question1List.push(question1ListTmp.replace("'",""))
            question2List.push(question2ListTmp.replace("'",""))
            question3List.push(question3ListTmp.replace("'",""))
            question4List.push(question4ListTmp.replace("'",""))
            reportedByClientList.push(reportedByClientListTmp.replace("'",""))
            reportedByEmployeeList.push(reportedByEmployeeListTmp.replace("'",""))
            reportedByPositionList.push(reportedByPositionListTmp.replace("'",""))
            reportedByDeptList.push(reportedByDeptListTmp.replace("'",""))
            isDraftList.push(isDraftListTmp.replace("'",""))
            isDeletedList.push(isDeletedListTmp.replace("'",""))
            createdbyList.push(createdbyListTmp.replace("'",""))
            createddateList.push(createddateListTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'client'                :   clientList[i],
                'employeeName'          :   employeeNameList[i],
                'position'              :   positionList[i],
                'employeeNo'            :   employeeNoList[i],
                'nonEmployee'           :   nonEmployeeList[i],
                'dateReported'          :   dateReportedList[i],
                'dateOfIncident'        :   dateOfIncidentList[i],
                'venueOfIncident'       :   venueOfIncidentList[i],
                'timeOfIncident'        :   timeOfIncidentList[i],
                'irReferenceNo'         :   irReferenceNoList[i],
                'question1'             :   question1List[i],
                'question2'             :   question2List[i],
                'question3'             :   question3List[i],
                'question4'             :   question4List[i],
                'reportedByClient'      :   reportedByClientList[i],
                'reportedByEmployee'    :   reportedByEmployeeList[i],
                'reportedByPosition'    :   reportedByPositionList[i],
                'reportedByDept'        :   reportedByDeptList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }
        
       /*  console.log("itemList start")
        console.log(itemList)
        console.log("itemList End") */
        return itemList
    }

    showTrainingRegisterModal = (e) => {
        this.setState({modalTrainingRegisterShow: true})
    }
    handleModalClose = (e) =>{
        //reload or refresh data here
        //alert("Data pass from child : " + e)
        this.setState({modalTrainingRegisterShow: false})
    }


    GridDataModified(oldValue, newValue, id, column) {
        ////console.log(id)
        this.state.trainingScheduleTableList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }
    viewIR=()=>{
        sessionStorage.setItem("irId", JSON.stringify(this.state.irId))
        //console.log("id1")
        //console.log(this.props.content)
        //console.log("id1")
    }

    render() {
        
        const irColumn = [
            {
                dataField   : 'createddate',
                text        : 'Date',
                headerStyle : () => {
                    return { width  : "45%" };
                }
            },
            {
                dataField: 'employeeName',
                text: 'Person To Be Reported',
                headerStyle : () => {
                    return { width  : "45%" };
                }
            },
            /* {
                dataField   : 'position',
                text        : 'Position',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'client',
                text        : 'Client',
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField   : 'register',
                text        : 'Register',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'attended',
                text        : 'Attended',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            }, */
            {
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (row)
                    return (
                        <NavLink to="/ExportIncidentReport">
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" href="/ExportIncidentReport" onClick={this.viewIR}
                        >View Details</Button>
                        </NavLink>
                    );
                },
            },
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.irList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        console.log("id")
                        console.log(row.id)
                        console.log("id")
                       
                    }
                   
                })
                let irIDTMP=[]
                let objIr={
                    "id":row.id
                }
                irIDTMP.push(objIr)
                this.setState({
                    irId:irIDTMP
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
               
            }
        };
        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>Cases >> Incident Report List</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    {/* <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            TRAINING
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeTraining}
                                                options={this.state.trainingAutocomplete}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            POSITION
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangePosition}
                                                options={this.state.positionAutocomplete}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            CLIENT
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClient}
                                                options={this.state.clientAutocomplete}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            BATCH NO.
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeBatchNo}
                                                options={this.state.batchNoAutocomplete}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            TRAINING DATE
                                        </Form.Label>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            DATE FROM
                                        </Form.Label>
                                        <Col sm="1">
                                            <DatePicker
                                                ref='dateFrom'
                                                selected={this.state.dateFrom}
                                                onChange={this.handleChangeDateFrom}
                                                minDate={this.minDate}
                                                value={this.props.dateFrom}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                            />
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            DATE TO
                                        </Form.Label>
                                        <Col sm="1">
                                            <DatePicker
                                                ref='dateTo'
                                                selected={this.state.dateTo}
                                                onChange={this.handleChangeDateTo}
                                                minDate={this.minDate}
                                                value={this.props.dateTo}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <ButtonToolbar className="mt-1">
                                        <Button variant="success" className="ml-auto" onClick={ this.handleSearchClick }>
                                            Search
                                        </Button>&nbsp;&nbsp;
                                        <Button  variant="success" href="trainingregistercreate">
                                            Create
                                        </Button>
                                    </ButtonToolbar> */}

                                    <Card.Header className="mt-3">IR List</Card.Header>
                                    <div className="mt-1">
                                        <BootstrapTable
                                            /* caption={Noser.TableHeader({title:"RECORD"})} */
                                            keyField = "id"
                                            data = { this.state.irList }
                                            columns = { irColumn }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }
                                            selectRow = { selectRow }

                                        />
                                    </div>
                                    <ButtonToolbar sm={12}>
                                       {/*  <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleUpdateClick}>
                                            Generate Report
                                        </Button>&nbsp;&nbsp; */}
                                        <NavLink to="/home">
                                            <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                                Close
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                    {/* <TrainingRegisterModal 
                        show={this.state.modalTrainingRegisterShow}
                        onHide={this.handleModalClose}
                    /> */}
            </div> 
        )
    }

}

export  default IRList
