import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown, Image,FormControl} from 'react-bootstrap';

import  TrainingRegisterModal from '../Training/TrainingModal/TrainingRegisterModal'
import { Input } from 'reactstrap';
import { isDoWhileStatement } from '@babel/types';
import Pdf from "react-to-pdf";
import ReactToPrint from "react-to-print";
import paramountLogo from "../../icon/paramountLogo.jpg"
import paraFooter from "../../icon/paraFooter.jpg"
import ImgsViewer from 'react-images-viewer'

const ref = React.createRef();


class ComponentToPrint extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            regexp: /^[0-9\b]+$/,

            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            isLoading           :"",

            id                  :"",
            client              :"",
            employeeName        :"",
            position            :"",
            employeeNo          :"",
            location            :"",
            date                :"",
            irReferenceNo       :"",
            paragraph           :"",
            addedparagraph1     :"",
            addedparagraph2     :"",
            addedparagraph3     :"",
            addedparagraph4     :"",
            addedparagraph5     :"",
            addedparagraph6     :"",
            addedparagraph7     :"",
            addedparagraph8     :"",
            quotedStatement     :"",
            highlightedAmount     :"",
            preparedBy          :"",
            prepareByPosition   :"",
            notedByPosition   :"",
            notedBy             :"",
            receivedBy          :"",
            isDraft             :"",
            isDeleted           :"",
            createdby           :"",
            createddate         :"",
            modifiedby          :"",
            modifieddate        :"",
            isModified          :"",

            articleNo               :"",
            articleName             :"",
            sectionNo               :"",
            infraction              :"",
            category                :"",

            articleNo2              :"", 
            articleName2            :"",
            sectionNo2              :"",
            infraction2             :"",
            category2               :"",

            sectionNo3              :"",
            infraction3             :"",
            category3               :"",

            irDateIssued            :"",
            dateRecieveByER         :"",
            referenceNoNTE          :"",
            cocReferences           :"",
            eSignatureNotedBy       :"",    
            eSignaturePreparedBy    :"",       
            
            departmentCodeAutocomplete:"",
            irIdCheck               :"",
            dateNow                 :"2/31/2021",
            paragraph1              :"",            
            logo                    :"https://assets.bossjob.com/companies/13161/logo/3Mwts6BLTZy6I6WhqQm9B79bIhq8dILG7uqq7SHl.png",
            lastName                :"",
            address                 :"",
        }
    }

    componentDidMount(){

        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        this.setState({isLoading:true,loadingText:"Loading client list..."})
        this.state.irIdCheck = JSON.parse(sessionStorage.getItem("irId"))
        this.getViewDraftMemo()
       
    }
    
    onChangePosition=(e)=>{
        this.setState({
            position:e.target.value
        })
    }

    getViewDraftMemo=()=>{
        let dataNTE = JSON.parse(sessionStorage.getItem("data"))
        let dataNTE1 = JSON.parse(sessionStorage.getItem("viewDraftMemoTmp"))

       /*  console.log("dataNTE")
        console.log(dataNTE.location)
        console.log("dataNTE") */

        let newDate = new Date(dataNTE.createddate)        
        let createddate = moment(newDate).format('MM');
        let createddate2 = moment(newDate).format('YYYY');
        let createddate3 = moment(newDate).format('DD');
        console.log(createddate)
        console.log(createddate2)

        let monthLeters = ""
        if(createddate == "01"){
            monthLeters = "January"
        }
        if(createddate == "02"){
            monthLeters = "February"
        }
        if(createddate == "03"){
            monthLeters = "March"
        }
        if(createddate == "04"){
            monthLeters = "April"
        }
        if(createddate == "05"){
            monthLeters = "May"
        }
        if(createddate == "06"){
            monthLeters = "June"
        }
        if(createddate == "07"){
            monthLeters = "July"
        }
        if(createddate == "08"){
            monthLeters = "August"
        }
        if(createddate == "09"){
            monthLeters = "September"
        }
        if(createddate == "10"){
            monthLeters = "October"
        }
        if(createddate == "11"){
            monthLeters = "November"
        }
        if(createddate == "12"){
            monthLeters = "December"
        }

        let wordsDate = createddate3+" "+monthLeters+" "+createddate2
        
        let getLastName1 = dataNTE.employeeName.split(",")[0]
        let newlastname=getLastName1.charAt(0).toUpperCase() + getLastName1.slice(1).toLowerCase();

        //let newLastNamefirstLetter = newLastName[0]
        //let newLastNameSecondLetter = newLastName[0]
        console.log("getLastName1.")
        console.log(getLastName1)

        this.setState({
          
            highlightedAmount     :   dataNTE.highlightedAmount,
            employeeName        :   dataNTE.employeeName,
            location            :   dataNTE.location,
            dateNow             :   wordsDate,
            preparedBy          :   dataNTE.preparedBy ,
            prepareByPosition   :   dataNTE.prepareByPosition ,
            notedBy             :   dataNTE.notedBy ,
            notedByPosition     :   dataNTE.notedByPosition ,
            address             :   dataNTE.address,
            paragraph           :   dataNTE.paragraph,
            addedparagraph1     :   dataNTE.addedparagraph1,
            addedparagraph2     :   dataNTE.addedparagraph2,
            addedparagraph3     :   dataNTE.addedparagraph3,
            addedparagraph4     :   dataNTE.addedparagraph4,
            addedparagraph5     :   dataNTE.addedparagraph5,
            addedparagraph6     :   dataNTE.addedparagraph6,
            addedparagraph7     :   dataNTE.addedparagraph7,
            addedparagraph8     :   dataNTE.addedparagraph8,
            quotedStatement     :   dataNTE.quotedStatement,
            articleNo           :   dataNTE.articleNo , 
            articleName         :   dataNTE.articleName,
            sectionNo           :   dataNTE.sectionNo,
            infraction          :   dataNTE.infraction,
            category            :   dataNTE.category,

            articleNo2           :   dataNTE.articleNo2 , 
            articleName2         :   dataNTE.articleName2,
            sectionNo2           :   dataNTE.sectionNo2,
            infraction2          :   dataNTE.infraction2,
            category2            :   dataNTE.category2,

            sectionNo3           :   dataNTE.sectionNo3,
            infraction3          :   dataNTE.infraction3,
            category3            :   dataNTE.category3,
            eSignatureNotedBy    :   dataNTE.eSignatureNotedBy,
            eSignaturePreparedBy :   dataNTE.eSignaturePreparedBy,
            lastName             :   newlastname,

        })

        let highlightedAmountToCount = dataNTE.highlightedAmount.length
        console.log("highlightedAmountToCount")
        console.log(highlightedAmountToCount)
        let paragrapToCount = dataNTE.paragraph.length
        console.log("paragrapToCount")
        console.log(paragrapToCount)
        let quotedStatementToCount = dataNTE.quotedStatement.length
        console.log("quotedStatementToCount")
        console.log(quotedStatementToCount)
        let paragrapToCount1 = dataNTE.addedparagraph1.length
        console.log("paragrapToCount1")
        console.log(paragrapToCount1)
        let paragrapToCount2 = dataNTE.addedparagraph2.length
        console.log("paragrapToCount2")
        console.log(paragrapToCount2)
        let paragrapToCount3 = dataNTE.addedparagraph3.length
        console.log("paragrapToCount3")
        console.log(paragrapToCount3)
        let paragrapToCount4 = dataNTE.addedparagraph4.length
        console.log("paragrapToCount4")
        console.log(paragrapToCount4)
        let paragrapToCount5 = dataNTE.addedparagraph5.length
        console.log("paragrapToCount5")
        console.log(paragrapToCount5)
        let paragrapToCount6 = dataNTE.addedparagraph6.length
        console.log("paragrapToCount6")
        console.log(paragrapToCount6)
        let paragrapToCount7 = dataNTE.addedparagraph7.length
        console.log("paragrapToCount7")
        console.log(paragrapToCount7)
        let paragrapToCount8 = dataNTE.addedparagraph8.length
        console.log("paragrapToCount8")
        console.log(paragrapToCount8)
        //let insertedImageSize = dataNTE.insertedImageSize
       //console.log("insertedImageSize")
        //console.log(insertedImageSize)

      /*   const uploadedImage = dataNTE.insertImage
        var image = new Image();

        image.src = uploadedImage;

        console.log("image.naturalWidth,image.naturalHeight");
        console.log(image.naturalWidth,image.naturalHeight); */

       /*  let totalOfInfrac1 = 0
        let totalOfInfrac2 = 0
        let totalOfInfrac3 = 0
        let totalOfInfrac4 = 0
        let totalOfInfrac5 = 0
        
        if(dataNTE.sectionDataList.length > 0){
            let infraction1Tpm = []
            dataNTE.sectionDataList.map(function(d){
                infraction1Tpm.push(d.infraction.length)
            })
            const reducer = (accumulator, curr) => accumulator + curr;
            totalOfInfrac1 = infraction1Tpm.reduce(reducer)
            console.log("reducer1111123132123123");
            console.log(totalOfInfrac1);
        }

        if(dataNTE.sectionDataList2.length > 0){
            let infraction2Tpm = []
            dataNTE.sectionDataList2.map(function(d){
                infraction2Tpm.push(d.infraction.length)
            })
            const reducer2 = (accumulator, curr) => accumulator + curr;
            totalOfInfrac2 = infraction2Tpm.reduce(reducer2)
            console.log("reducer2");
            console.log(totalOfInfrac2);
        }
        
        if(dataNTE.sectionDataList3.length > 0){
            let infraction3Tpm = []
            dataNTE.sectionDataList3.map(function(d){
                infraction3Tpm.push(d.infraction.length)
            })
            const reducer3 = (accumulator, curr) => accumulator + curr;
            totalOfInfrac3 = infraction3Tpm.reduce(reducer3)
            console.log("reducer3");
            console.log(totalOfInfrac3);
        }

        if(dataNTE.sectionDataList4.length > 0){
            let infraction4Tpm = []
            dataNTE.sectionDataList4.map(function(d){
                infraction4Tpm.push(d.infraction.length)
            })
            const reducer4 = (accumulator, curr) => accumulator + curr;
            totalOfInfrac4 = infraction4Tpm.reduce(reducer4)
            console.log("reducer4");
            console.log(totalOfInfrac4);
        }

        if(dataNTE.sectionDataList5.length > 0){
            let infraction5Tpm = []
            dataNTE.sectionDataList5.map(function(d){
                infraction5Tpm.push(d.infraction.length)
            })
            const reducer5 = (accumulator, curr) => accumulator + curr;
            totalOfInfrac5 = infraction5Tpm.reduce(reducer5)
            console.log("reducer5");
            console.log(totalOfInfrac5);
        } */

        let allParagraph = highlightedAmountToCount+paragrapToCount+quotedStatementToCount+paragrapToCount1+paragrapToCount2+paragrapToCount3+paragrapToCount4+paragrapToCount5+paragrapToCount6+paragrapToCount7+paragrapToCount8/* +totalOfInfrac1+totalOfInfrac2+totalOfInfrac3 */
        console.log("allParagraph")
        console.log(allParagraph)

        /* NOTE : MAX Size ON Screnn 10320 */

        if(allParagraph > 529 && allParagraph < 541){
            console.log("height > 529 && height < 541")
            this.setState({
                footerTobtm:{marginTop:"70px",marginLeft:"390px",}
            })
        }
        if(allParagraph > 910 && allParagraph < 921){
            console.log("height > 920 && height < 921")
            this.setState({
                footerTobtm:{marginTop:"-61px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 500 && allParagraph <= 599){
            console.log("height > 600 && height < 699")
            this.setState({
                footerTobtm:{marginTop:"-10px",marginLeft:"390px",}
            })
        }
        if(allParagraph >= 600 && allParagraph <= 699){
            console.log("height > 600 && height < 699")
            this.setState({
                footerTobtm:{marginTop:"2000px",marginLeft:"480px",}
            })
        }
        if(allParagraph >= 700 && allParagraph <= 799){
            console.log("height > 700 && height < 799")
            this.setState({
                footerTobtm:{marginTop:"2000px",marginLeft:"480px",}
            })
        }
        if(allParagraph >= 800 && allParagraph <= 899){
            console.log("height > 800 && height < 899")
            this.setState({
                footerTobtm:{marginTop:"1400px",marginLeft:"300px",}
            })
        }
        if(allParagraph >= 900 && allParagraph <= 1000){
            console.log("height > 900 && height < 1000")
            this.setState({
                footerTobtm:{marginTop:"1340px",marginLeft:"300px",}
            })
        }
        if(allParagraph >= 1001 && allParagraph <= 1100){
            console.log("height > 1001 && height < 1100")
            this.setState({
                footerTobtm:{marginTop:"98px",marginLeft:"300px",}
            })
        }
        if(allParagraph >= 1101 && allParagraph <= 1200){
            console.log("height > 1101 && height < 1200")
            this.setState({
                footerTobtm:{marginTop:"50px",marginLeft:"300px",}
            })
        }

        if(allParagraph >= 1201 && allParagraph <= 1301){
            console.log("height > 1200 && height < 1301")
            this.setState({
                footerTobtm:{marginTop:"-20px",marginLeft:"300px",}
            })
        }

        if(allParagraph >= 1300 && allParagraph <= 1401){
            console.log("height > 1300 && height < 1401")
            this.setState({
                footerTobtm:{marginTop:"-20px",marginLeft:"300px",}
            })
        }

        if(allParagraph >= 1400 && allParagraph <= 1501){
            console.log("height > 1300 && height < 1401")
            this.setState({
                footerTobtm:{marginTop:"-10px",marginLeft:"300px",}
            })
        }

        if(allParagraph >= 1500 && allParagraph < 1601){
            console.log("height > 1300 && height < 1401")
            this.setState({
                footerTobtm:{marginTop:"1450px",marginLeft:"300px",}
            })
        }

        if(allParagraph >= 1600 && allParagraph <= 1700){
            console.log("height > 1300 && height < 1401")
            this.setState({
                footerTobtm:{marginTop:"1400px",marginLeft:"300px",}
            })
        }

        if(allParagraph >= 1701 && allParagraph <= 1800){
            console.log("height > 1300 && height < 1401")
            this.setState({
                footerTobtm:{marginTop:"1380px",marginLeft:"300px",}
            })
        }

        if(allParagraph >= 1801 && allParagraph <= 1900){
            console.log("height > 1300 && height < 1401")
            this.setState({
                footerTobtm:{marginTop:"1350px",marginLeft:"300px",}
            })
        }

        if(allParagraph >= 1901 && allParagraph <= 2000){
            console.log("height > 1901 && height < 2000")
            this.setState({
                footerTobtm:{marginTop:"1280px",marginLeft:"300px",}
            })
        }

        if(allParagraph >= 2001 && allParagraph <= 2100){
            console.log("height > 2001 && height < 2100")
            this.setState({
                footerTobtm:{marginTop:"1250px",marginLeft:"300px",}
            })
        }

        if(allParagraph >= 2101 && allParagraph <= 2200){
            console.log("height > 2101 && height < 2200")
            this.setState({
                footerTobtm:{marginTop:"1180px",marginLeft:"300px",}
            })
        }

        if(allParagraph >= 2201 && allParagraph <= 2300){
            console.log("height > 2101 && height < 2200")
            this.setState({
                footerTobtm:{marginTop:"1150px",marginLeft:"300px",}
            })
        }

        if(allParagraph >= 2301 && allParagraph <= 2400){
            console.log("height > 2101 && height < 2200")
            this.setState({
                footerTobtm:{marginTop:"1100px",marginLeft:"300px",}
            })
        }
      
    }

    setBack=()=>{
        let whenBack = 0
        sessionStorage.setItem("nextProcessCheck", JSON.stringify(whenBack)) 

    }

    render() {

        return( <>

            <Container className="mt-0" fluid>
              
                        <Form style={{backgroundColor:"#FFFFFF"}}>
                            <Col>
                                <Image src={paramountLogo} style={{width:"82%", height:"120%", marginLeft:"20px" , marginTop:"15px"}}/>
                            </Col>

                            <Form.Group as={Col} sm="10" className="mt-3">                                    
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22,marginTop:"1px", marginLeft:"25px"}}>
                                {this.state.dateNow}
                                </Form.Label>                
                            </Form.Group>

                            <Form.Group as={Col} sm="10">                                    
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22,marginTop:"-6px", marginLeft:"25px",marginBottom:"-10px"}}>
                                    Mr/Ms. {this.state.employeeName}
                                </Form.Label>                                         
                            </Form.Group>
                            <Form.Group as={Col} sm="10">                                    
                                <Form.Label sm="1"  style={{fontWeight : "bold" ,fontSize: 18,marginTop:"-30px", marginLeft:"25px"}}>
                                    {this.state.address}
                                </Form.Label>   
                            </Form.Group>

                           

                            <Form.Group as={Col} sm="10">                                    
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22,marginTop:"-6px", marginLeft:"30%"}}>
                                    SUBJECT
                                </Form.Label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22,marginTop:"-1px", marginLeft:"6px"}}>:</Form.Label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 22, marginLeft:"31px"}}>
                                    NEGATIVE CLAIMS                           
                                </Form.Label>                                
                            </Form.Group>

                            <Form.Group as={Col} sm="10" style={{textTransform:"capitalize"}}>                                    
                                <Form.Label className="no-text-transform-li" row sm="1" style={{fontWeight : "bold" ,fontSize: 22,marginTop:"-6px", marginLeft:"25px",textTransform:"capitalize"}}>
                                    Dear Mr/Ms. {this.state.lastName}
                                </Form.Label> 
                                                     
                            </Form.Group>

                            { this.state.paragraph !== "" ? 
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Col>
                                        <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap", }}>
                                            {this.state.paragraph}
                                        </Form.Label>  
                                    </Col>                                    
                                </Form.Group>:null
                            }

                            { this.state.addedparagraph1 !== "" ? 
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Col>
                                        <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap", textEmphasisColor:"#ff00ff",textEmphasis:"Highlight"}}>
                                            {this.state.addedparagraph1}
                                        </Form.Label>  
                                    </Col>                                    
                                </Form.Group>:null
                            }

                            { this.state.addedparagraph2 !== "" ? 
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Col>
                                        <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px" , whiteSpace: "pre-wrap", }}>
                                            {this.state.addedparagraph2}
                                        </Form.Label>  
                                    </Col>                                    
                                </Form.Group>:null
                            }
                            { this.state.highlightedAmount !== "" ? 
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Col>
                                        <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px" , whiteSpace: "pre-wrap", fontWeight : "bold" ,}}>
                                            {this.state.highlightedAmount}
                                        </Form.Label>  
                                    </Col>                                    
                                </Form.Group>:null
                            }

                            { this.state.addedparagraph3 !== "" ? 
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Col>
                                        <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px" , whiteSpace: "pre-wrap",}}>
                                            {this.state.addedparagraph3}
                                        </Form.Label>  
                                    </Col>                                    
                                </Form.Group>:null
                            }
                           

                            { this.state.addedparagraph4 !== "" ? 
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Col>
                                        <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px" , whiteSpace: "pre-wrap",}}>
                                            {this.state.addedparagraph4}
                                        </Form.Label>  
                                    </Col>                                    
                                </Form.Group>:null
                            }

                            { this.state.addedparagraph5 !== "" ? 
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Col>
                                        <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px" , whiteSpace: "pre-wrap",}}>
                                            {this.state.addedparagraph5}
                                        </Form.Label>  
                                    </Col>                                    
                                </Form.Group>:null
                            }

                            { this.state.quotedStatement !== "" ? 
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Col>
                                        <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px" , whiteSpace: "pre-wrap", fontWeight : "bold" ,}}>
                                            {this.state.quotedStatement}
                                        </Form.Label>  
                                    </Col>                                    
                                </Form.Group>:null
                            }

                            { this.state.addedparagraph6 !== "" ? 
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Col>
                                        <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px" , whiteSpace: "pre-wrap",}}>
                                            {this.state.addedparagraph6}
                                        </Form.Label>  
                                    </Col>                                    
                                </Form.Group>:null
                            }

                            { this.state.addedparagraph7 !== "" ? 
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Col>
                                        <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px" , whiteSpace: "pre-wrap",}}>
                                            {this.state.addedparagraph7}
                                        </Form.Label>  
                                    </Col>                                    
                                </Form.Group>:null
                            }

                            { this.state.addedparagraph8 !== "" ? 
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Col>
                                        <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px" , whiteSpace: "pre-wrap",}}>
                                            {this.state.addedparagraph8}
                                        </Form.Label>  
                                    </Col>                                    
                                </Form.Group>:null
                            }

                            

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column style={{fontWeight : "bold",fontSize: 22,marginLeft:"40px"}}>
                                   Sincerely Yours,
                                </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column style={{fontWeight : "bold",fontSize: 22,marginLeft:"40px"}}>
                                   
                                </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 22, marginLeft:"20px",marginTop:"-25px"}}>
                                    <Col sm="5">                                       
                                       <Image src={this.state.eSignaturePreparedBy} style={{width:"240px", height:"70px", textAlign:"center",justifyContent:"center", }}/>
                                    </Col>
                                </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column style={{fontWeight : "bold",fontSize: 22, marginTop:"-20px",marginLeft:"40px"}}>
                                    {this.state.preparedBy},
                                </Form.Label>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2" style={{fontWeight : "bold",fontSize: 18, marginTop:"-19px", marginLeft:"40px"}}>
                                    {this.state.prepareByPosition}
                                </Form.Label>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 22, marginLeft:"40px"}}>
                                    Received by :
                                </Form.Label>

                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 22, marginLeft:"40px"}}>
                                {this.state.employeeName}
                                </Form.Label>

                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginTop:"-40px"}}>
                                <h1 className="mt-5" style={{fontWeight : "bold" ,backgroundColor:'#000000', width:"30%", height:"4px",marginLeft:"49px", }} > </h1>
                                <h1 className="mt-5" style={{fontWeight : "bold" ,backgroundColor:'#000000', width:"14%", height:"4px",marginLeft:"43%", }} > </h1>
                            </Form.Group>
                           
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                
                                <Form.Label className="no-text-transform-li" sm="5" style={{fontWeight : "bold" ,fontSize: 22, marginLeft:"50px", borderTopColor:"#000000"}}>
                                   {"(Sign Over Printed Name)"}
                                </Form.Label> 
                               
                                <Form.Label className="no-text-transform-li" sm="1" style={{fontWeight : "bold" ,fontSize: 22, marginLeft:"54%", borderTopColor:"#000000"}}>
                                    Date
                                </Form.Label> 
                               
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 22,marginTop:"-20px" , marginLeft:"38px"}}>
                                   cc:  201 File
                                </Form.Label>
                            </Form.Group>


                            { 
                             
                             <Image ref={ (footerElement) => { this.footerElement = footerElement } } src={paraFooter} style={this.state.footerTobtm}/>
                          
                            }

                        </Form>
                    
                
            </Container>
            <NoserLoading show={this.state.isloading} />

            <TrainingRegisterModal 
                show={this.state.modalTrainingRegisterShow}
                onHide={this.handleModalClose}
                onRefModal={ref => (this.child = ref)}
            />
           
            </>
            
        )
    }

}


class ExportDemandLetter extends React.Component {
    render() {
      return (
        <div>
        
            <ComponentToPrint ref={(el) => (this.componentRef = el)} />
            <Col sm="3" className="mt-3" as={Row}>
                <ReactToPrint
                    trigger={() => <button>Print this out!</button>}
                    content={() => this.componentRef}
                />
                 <ButtonToolbar sm={12}>
                    <NavLink to="/home">
                        <Button variant="danger" href="/home" style={{minWidth:'60px',marginLeft:"10px"}} onClick={this.setBack}>
                            Close
                        </Button>
                    </NavLink>
                </ButtonToolbar>
            </Col>
        </div>
      );
    }
  }

export default ExportDemandLetter
