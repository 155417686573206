import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Redirect,Accordion,
    Tabs, Tab
} 
from '../../noser-hris-component';
import SourcingModal from './Modal/SourcingModal';
import ShortListed from './Modal/Shortlisted';

class Sourcing extends Component {
    state = {
        userinfo        :   [],
        isloading       :   false,
        isshow          :   false,
        alerttype       :   "",
        message         :   "",
        color           :   "",
        fade            :   true,
        disableBtn      :   true,
        openModal       :   false,
        applicationGrid :   [],
        applicationDDL  :   [],
        clientDDL       :   [],
        employeeDDL     :   [],
        employeeList    :   [],
        emplist: [],
        testtwo:[],
        testdata: [],

        clientId        :   "",
        referenceNo     :   "",
        statusId        :   "",
        tinNumber       :   "",
        dateApplied : "",
        ApplicationFormId:  "",
        employeeName: "",
        age: "",
        FromAge: "",
        ToAge: "",
        position : "",
        positionId : "",
        profileId : "",

        postionDDL   :   [],
        positonDGDDL: [],
        EffectivityMonth: "",
        petsa:"",
        tblClientArrLst : [],
        tblPositionArrLst : [],

        acctTypeDDL:[],
        branchDDL:[],
        branchId:"",

        shortlistedGRID:[],
        openShortlistedModal:false,
        disableSLBtn:true,
        typeId:""
    }

    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        this.GetClients()
        let a = JSON.parse(sessionStorage.getItem("userData"))
        //console.log(a)
        if(a.roleId === "14"){
            this.setState({ isShow : true,})
        }
    };

    GetClients = async()=> {
        this.setState({isloading:true})
        const clientParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  clientParams)
        .then(res => {
            const data = res.data.clients;
            let client = []
            data.map(function(itm){
                client.push({
                    "value":itm.id,
                    "label":itm.name
                })
            })
            this.setState({tblClientArrLst:client,clientDDL:data})
            this.GetAccountTypes()
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    GetAccountTypes = async()=>{
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Name"          :   ""
        };

        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetAccountTypes",  params)
        .then(res => {
            const data = res.data.accountTypes;
            this.setState({ acctTypeDDL : data});
            this.GetPosition()
        })
    }
    GetPosition = async() => {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId"     :   "",
            "DepartmentId"  :   "",
            "Name"          :   ""
        };

        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  params)
        .then(res => {
            const data = res.data.positions;
            ////console.log("Get positions");
            ////console.log(data);
            let a = []
            for (let i = 0; i < data.length; i++) {
                const pos = {
                    value : data[i]["id"],
                    label : data[i]["name"],
                };
                this.state.positonDGDDL.push(pos);
                a.push(pos)
            };
            console.log("a")
            console.log(a)

            this.setState({ postionDDL : data});
            this.GetApplicationFormFilter()
        })
    };

    GetApplicationFormFilter = async()=> {
        this.setState({isloading:true})
        // const params = {
        //     "IpAddress" :   "0.0.0.0",
        //     "ClientId"  :   this.state.clientId,
        //     "UserId"    :   this.state.userinfo.userId,
        //     "TypeId"    :   "9",//"9"
        //     "RoleId"    :   this.state.userinfo.roleId,
        //     "AreaId"    :   this.state.userinfo.areaId,
        //     "StatusId"  :   "2"
        // };
        // await
        // axios
        // .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationFormFilter",  params)
        // .then(res => {
        //     const data = res.data;
        //    this.setState({employeeDDL:data.applicationForms})
        //    this.handleSearchClick()
        // })
        // .catch(error=>{
        //     this.setState(  {
        //         isloading       :   false,
        //         alerttype       :   "Error!",
        //         isshow          :   true,
        //         color           :   "danger",
        //         message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
        //         fade            :   true
        //     })
        // })
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ReferenceNo":this.state.referenceNo,
            "PositionId":this.state.position,
            "PositionId" : this.state.positionId,
            "StatusId":"2",//this.state.statusId,
            "ApplicationFormId" : this.state.ApplicationFormId,
            "Age": this.state.age,
            "DateApplied": this.state.petsa,
            "FromAge": this.state.FromAge,
            "ToAge": this.state.ToAge,
            "ProfileId" : this.state.profileId,
            "isDeleted" : "0",
            "RoleId"    :   this.state.userinfo.roleId,
            "AreaId"    :   this.state.userinfo.areaId,
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Pooling/GetSourcings",  params)
        .then(res => {
            const data = res.data
            console.log("sourcing")
            console.log(data)
            
            if(data.status=="1"){
                this.setState({
                    employeeDDL:data.sourcings,
                    testdata: data.sourcings,
                });
                this.handleSearchClick()
            }
            else{
                this.setState({
                    //isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                });
            }
         })
         .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })

    };

    GetApplications = async() => {
        console.log("GetApplications")
        this.setState({disableBtn:true});
        if(this.state.EffectivityMonth === ""){
            this.state.petsa = ""
        }else{
            this.state.petsa = moment(this.state.EffectivityMonth).format("MM/DD/YYYY");
        }


        //this.state.petsa = petsa
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ReferenceNo":this.state.referenceNo,
            "PositionId":this.state.position,
            "PositionId" : this.state.positionId,
            "StatusId":"2",//this.state.statusId,
            "ApplicationFormId" : this.state.ApplicationFormId,
            "Age": this.state.age,
            "DateApplied": this.state.petsa,
            "FromAge": this.state.FromAge,
            "ToAge": this.state.ToAge,
            "ProfileId" : this.state.profileId,
            "isDeleted" : "0",
            "RoleId"    :   this.state.userinfo.roleId,
            "AreaId"    :   this.state.userinfo.areaId,
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Pooling/GetSourcings",  params)
        .then(res => {
            const data = res.data
            console.log("sourcing")
            console.log(data)
            
            if(data.status=="1"){
                this.setState({
                    applicationGrid:data.sourcings,
                    testdata: data.sourcings,
                });
            }
            else{
                this.setState({
                    //isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                });
            }
         })
         .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
        this.GetShortListed()
    };
    GetShortListed = async() => {

        //let filter_data = {"isDeleted": "0", "status" : "SL", compantName: this.state.userinfo.companyName }
        //let filter_data = {"isDeleted": "0", "status" : "SL", "isPhr" : "0" }
        let filter_data = {"isDeleted": "0", "status" : "SL"}
        let tmpdata = []
        const Params = {
          "_collection" : "Candidates",
          "filter_data" : filter_data
        }
        await axios
        .post(AppConfiguration.Setting().jobspaceapi + "action/get", Params)
        .then(res => {

            let data = res.data
            let newstr2=""
            data.Candidates.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let strJson2 = itm.substring(18, itm.length)
                newstr2 = strJson2.split("')")[0]
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                json.headerId = newstr2
                tmpdata.push(json)
            })  
            tmpdata.forEach(itm=>{
                itm.phr = itm.isPhr==="1"? "YES":"NO"
            })
            this.setState({shortlistedGRID:tmpdata,isloading:false})  
        })
        .catch(error =>{
            console.log("error in GetCandidates function: " + error)
            this.setState({shortlistedGRID:[],isloading:false})
        })
        console.log("Candidates")
        console.log(tmpdata)
    }
    handleChange = (e) =>{
        //console.log(e.target.value)
        this.setState({[e.target.name]: e.target.value})
    };

    handleChangeClient = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length == 0) {
            this.state.clientId     =   ""
            this.state.clientName   =   ""
            this.state.employeeDDL  =   this.state.employeeList
            return
        }
        this.state.clientId     =   e[0].id
        this.state.clientName   =   e[0].name
        this.GetApplicationFormFilter()

    };

    handleChangeEmployee = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length == 0) {
            this.state.ApplicationFormId    =   ""
            this.state.profileId            =   ""
            this.state.referenceNo          =   ""
            this.state.tinNumber            =   ""
            this.state.statusId             =   ""
            this.state.employeeName         =   ""
            return
        }
        this.state.ApplicationFormId    =   e[0].id
        this.state.profileId = e[0].profileId
    };

    handelReferenceNo=(e)=>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length == 0) {
            this.state.ApplicationFormId    =   ""
            this.state.profileId            =   ""
            this.state.referenceNo          =   ""
            this.state.tinNumber            =   ""
            this.state.statusId             =   ""
            this.state.employeeName = ""

            this.state.clientId     =   ""
            this.state.clientName   =   ""
            return
        }
        this.state.ApplicationFormId    =   e[0].ApplicationFormId
        this.state.clientId     =   e[0].clientId
        this.state.clientName   =   e[0].client
        this.state.profileId = e[0].profileId
    };

    handleDateApplied=(e)=>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length == 0) {
            this.state.ApplicationFormId    =   ""
            this.state.profileId            =   ""
            this.state.referenceNo          =   ""
            this.state.tinNumber            =   ""
            this.state.statusId             =   ""
            this.state.employeeName = ""

            this.state.clientId     =   ""
            this.state.clientName   =   ""
            this.state.dateApplied = ""
         
            return
        }
        this.state.ApplicationFormId    =   e[0].ApplicationFormId
        this.state.clientId     =   e[0].clientId
        this.state.clientName   =   e[0].client
        this.state.dateApplied = e[0].dateApplied
        this.state.profileId = e[0].profileId
    };

    handleChangedPosition=(e)=>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length == 0) {
            this.state.positionId=""
            return
        };
        this.state.position = e[0].name
        this.state.positionId = e[0].id
    }

    handleChangeEffectivityMonth = e => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e == null) {
            this.state.EffectivityMonth = ""
            return
        }
        this.state.EffectivityMonth = e
        
    };

    handleSearchClick = (e) => {
        this.GetApplications()
    }

    GridDataModified(oldValue, newValue, id, column) {
        this.state.applicationGrid.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }

    handleClickedEdit = (row) =>{
        row.IsMonitoring = "1"
        row.isPooling = "1"
        sessionStorage.setItem("viewtoto_" + this.state.userinfo.userId + this.state.userinfo.employeeId, null)
        sessionStorage.setItem("viewapplicationform_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
        this.setState({navigate:true})
    };

    handleUpdateClick=(e)=>{
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.setState({isloading:true,isDisable:true})
        
        let inclusions  = []     
        
        for (let i = 0; i < this.state.applicationGrid.length; i++) {
            if (this.state.applicationGrid[i]["isModified"] == 1) {
                let obj = this.state.applicationGrid[i]
                inclusions.push(obj);
            } 
        };

        let a = inclusions
        a.forEach(element => {
            element.applicationFormId = element.id
        });

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "applicationforms": a,
        };
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Pooling/DeleteApplicationForm", params)
            .then(res => {
                const data = res.data;
                if(data.status=="1"){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Success!",
                        isshow          :   true,
                        color           :   "success",
                        message         :   data.message,
                        fade            :   true
                    });
                    this.GetApplications()
                }
                else{
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   data.message,
                        fade            :   true
                    })
                }
             })
             .catch(error=>{
                this.setState(
                {
                    isLoading:false,
                    AlertType:"Error! ",
                    Show:true,
                    Color:"danger",
                    Message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    Fade:true
                })
            })
    };

    handleModalShow = (e,typeId) =>{
        if(typeId==="0"){
            this.setState({openShortlistedModal:true,typeId:typeId})
            this.child1.initialize(this.state.shortlistedGRID.find(x=>x.isProcess==="1"))
        }
        if(typeId==="1"){
            this.setState({openModal:true,typeId:typeId})
            this.child.initialize(e)
        }
    }
    handleModalClose = (e) =>{
        if(this.state.typeId==="0"){
            this.setState({openShortlistedModal:false})
        }
        if(this.state.typeId==="1"){
            this.setState({openModal:false})
        }
        this.GetApplications()
    };
    
    render() {
        const {navigate} = this.state;
        if (navigate) {
            return <Redirect to="/ApplicationFormEdit" push={true} />;
        }
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            bgColor: (row, rowIndex) => (rowIndex > 1 ? '#AEBABA' : '#B3B4B4'),
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.applicationGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if(isSelect)
                    {
                        if(i==rowIndex)
                        item.IsSelected=1
                    }
                    else
                    {
                        if(i==rowIndex)
                        item.IsSelected=0
                    }
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                row.IsMonitoring = "1"
                sessionStorage.setItem("editapplicationform_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))

            }
        }
        const cols = [
            {
                dataField: 'applicationFormNo',
                text: 'REFERENCE NO',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'center',width:'8%'},
                //sort:true
            },
            {
                dataField: 'employeeName',
                text: 'APPLICANT NAME',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%' }},
                style:{textAlign:'left',width:'20%'},
                //sort:true,
                formatter   :   (cell, row, isSelect) => {
                    if(row.statusId!=="19"){
                        return (
                            <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}}
                                variant="link" onClick={e => this.handleClickedEdit(row)}
                        >{cell}</Button>
                        );
                    }
                    else{
                        return (
                            cell
                        );
                    }
                }
            },
            {
                dataField: 'position',
                text: 'POSITION',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'18%' }},
                style:{textAlign:'left',width:'18%'},
                //sort:true
            },
            {
                dataField: 'client',
                text: 'CLIENT',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'30%' }},
                style:{textAlign:'left',width:'30%'},
                //sort:true
            },
            {
                dataField: 'dateApplied',
                text: 'DATE APPLIED',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center',width:'10%'},
                //sort:true
            },
            {
                dataField: 'age',
                text: 'AGE',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'6%' }},
                style:{textAlign:'center',width:'6%'},
                //sort:true
            },
            {
                dataField: '',
                text: 'ACTION',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'center',width:'8%'},
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link" onClick={e => this.handleModalShow(row,'1')}
                        >Process</Button>
                        );
                },
            },
        ]
        const slCol = [
            { dataField: 'postingId', text: 'Reference No',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%', }
                },
                style:{textAlign:'center'} 
            },
            { dataField: 'name', text: 'Applicant Name',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'25%', }
                },
                style:{textAlign:'left'},
                // formatter   :   (cell, row, isSelect) => {
                //     return (
                //         <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}}
                //             variant="link" onClick={e => this.handleClickedView(row)}
                //         >{cell}</Button>
                //         );
                // }
                
            },
            { dataField: 'title', text: 'Position Applied',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'32%', }
                },
                style:{textAlign:'left'} 
            },
            { dataField: 'dateApplied', text: 'Date Applied',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%', }
                },
                style:{textAlign:'center'} 
            },
            { dataField: 'phr', text: 'PHR',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%', }
                },
                style:{textAlign:'center'} 
            },
            { dataField: 'contact', text: 'CONTACT NO',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%', }
                },
                style:{textAlign:'left'} 
            },
        ]
        const selectslRow = {
            mode: 'radio',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.shortlistedGRID.map(function(item,i){
                    if(item.headerId===row.headerId)
                    {
                        item.isProcess = isSelect ? "1" : "0"
                    }
                    if(item.isProcess=="1")
                        isDisable=false
                })
                this.setState({disableSLBtn:isDisable})
            }
        };
        return(
       
            <div>
                <Banner />
                    <Container className="themed-container" fluid={true}>
                        <Card className="mt-5">
                            <Card.Header>RECRUITMENT >> Sourcing</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Card>
                                        <Accordion defaultActiveKey="1">
                                            <div className="card-header-tab">
                                                <Accordion.Toggle as={Button} variant="link" eventKey="1" style={{color: "#FFFFFF"}}>
                                                    SEARCH FILTER
                                                </Accordion.Toggle>
                                            </div>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body>
                                                  <Form.Row>
                                                        <Col sm={1}>
                                                            <Form.Label style={{fontWeight : "bold"}} className='mt-1'>CLIENT</Form.Label>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Typeahead 
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangeClient}
                                                                options={this.state.clientDDL}
                                                                placeholder="Select Client" 
                                                            />
                                                        </Col >
                                                        <Col sm={2}></Col>
                                                        <Col sm={1}>
                                                            <Form.Label style={{fontWeight : "bold"}} className='mt-1'>APPLICANT NAME</Form.Label>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Typeahead 
                                                                labelKey='employeeName'
                                                                id="basic-example"
                                                                onChange={this.handleChangeEmployee}
                                                                options={this.state.employeeDDL}
                                                                placeholder="SELECT APPLICANT  "
                                                            />
                                                        </Col >
                                                  </Form.Row>
                                                  <Form.Row className='mt-3'>
                                                        <Col sm={1}>
                                                            <Form.Label style={{fontWeight : "bold"}} className='mt-1'>REFERENCE NO.</Form.Label>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Typeahead 
                                                                labelKey='applicationFormNo'
                                                                id="basic-example"
                                                                onChange={this.handelReferenceNo}
                                                                options={this.state.applicationGrid}
                                                                placeholder="REFERENCE NO." 
                                                            />
                                                        </Col >
                                                        <Col sm={2}></Col>
                                                        <Col sm={1}>
                                                            <Form.Label style={{fontWeight : "bold"}} className='mt-1'>POSITION APPLIED</Form.Label>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Typeahead 
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangedPosition}
                                                                options={this.state.postionDDL}
                                                                placeholder="SELECT POSITION " 
                                                            />
                                                        </Col >

                                                        {/* <Col sm={4}>
                                                            <DatePicker
                                                                ref='EffectivityMonth'
                                                                selected={this.state.EffectivityMonth}
                                                                onChange={this.handleChangeEffectivityMonth}
                                                                //minDate={this.minDate}
                                                                value={this.props.EffectivityMonth}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                            />
                                                        </Col > */}
                                                  </Form.Row>
                                                  <Form.Row className='mt-3'>
                                                        <Col sm={1}>
                                                            <Form.Label style={{fontWeight : "bold"}} className='mt-1'>DATE APPLIED</Form.Label>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <DatePicker
                                                                ref='EffectivityMonth'
                                                                selected={this.state.EffectivityMonth}
                                                                onChange={this.handleChangeEffectivityMonth}
                                                                //minDate={this.minDate}
                                                                value={this.props.EffectivityMonth}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                placeholder="SELECT DATE" 
                                                            />
                                                        </Col >
                                                        <Col sm={4}></Col>
                                                        
                                                        <Col sm={1}>
                                                            <Form.Label style={{fontWeight : "bold"}} className='mt-1'>AGE FROM</Form.Label>
                                                        </Col>
                                                        <Col sm={1}>
                                                            <Form.Control
                                                                id="basic-example"
                                                                name='FromAge'
                                                                type="text" 
                                                                onChange={this.handleChange} 
                                                                autoComplete="off" 
                                                                value={this.state.FromAge}
                                                                //placeholder="AGE from " 
                                                                
                                                            />
                                                        </Col >
                                                        <Col sm={1}></Col>
                                                        <Col sm={1}>
                                                            <Form.Label style={{fontWeight : "bold"}} className='mt-1'>AGE TO</Form.Label>
                                                        </Col>
                                                        <Col sm={1}>
                                                            <Form.Control
                                                                id="basic-example"
                                                                name='ToAge'
                                                                type="text" 
                                                                onChange={this.handleChange} 
                                                                autoComplete="off" 
                                                                value={this.state.ToAge}
                                                               // placeholder="AGE to " 
                                                            />
                                                        </Col >

                                                        {/* <Col sm={4}>
                                                            <DatePicker
                                                                ref='EffectivityMonth'
                                                                selected={this.state.EffectivityMonth}
                                                                onChange={this.handleChangeEffectivityMonth}
                                                                //minDate={this.minDate}
                                                                value={this.props.EffectivityMonth}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                            />
                                                        </Col > */}
                                                  </Form.Row>
                                                    <ButtonToolbar className="mt-4">
                                                        <Button variant="primary" className="ml-auto noser-button-mr1" onClick={ this.handleSearchClick }>
                                                            Search
                                                        </Button>
                                                        <NavLink to="/applicationformcreate"/* to="ApplicationFormReview" */>
                                                            <Button className="noser-button-mr1"  variant="success">New Applicant</Button>
                                                        </NavLink>
                                                        <Button variant="success" className="noser-button" onClick={ this.GetShortListed }>
                                                            Get Short Listed
                                                        </Button>
                                                    </ButtonToolbar>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Accordion>
                                    </Card>
                                    <Tabs style={{marginTop:"5px"}} onSelect={this.handleSelect} activeKey={this.state.key} defaultActiveKey="1" transition={false} id="noanim-tab-example" >
                                        <Tab eventKey="0" title="SHORTLISTED APPLICANT">
                                        <Card.Header className="card-tab"></Card.Header>
                                            <BootstrapTable
                                                keyField = "headerId"
                                                data = { this.state.shortlistedGRID }
                                                columns = { slCol }
                                                selectRow = { selectslRow }
                                                cellEdit = { cellEditFactory({ 
                                                        mode: 'dbclick', 
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => { 
                                                            this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                                striped
                                                hover
                                                condensed
                                                loading={true}
                                                wrapperClasses="table-responsive"
                                                pagination={ paginationFactory({sizePerPageRenderer})}
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            />
                                            <ButtonToolbar sm={12}>
                                                <Button disabled={this.state.disableSLBtn} variant="primary" className="ml-auto noser-button" onClick={() => this.handleModalShow({},'0')}>PROCESS</Button>
                                            </ButtonToolbar>
                                        </Tab>
                                        <Tab eventKey="1" title="LIST OF APPLICANT">
                                            <Card.Header className="card-tab"></Card.Header>
                                            <BootstrapTable
                                                keyField = "id"
                                                data = { this.state.applicationGrid }
                                                columns = { cols }
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                cellEdit = { cellEditFactory({
                                                    mode: 'dbclick',
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                        this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                                rowEvents={ rowEvents }
                                                selectRow = { selectRow }
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            />

                                            <div isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isShow ? 'display-block' : 'display-none'}> 
                                                <ButtonToolbar sm={12}>
                                                    <Button variant="danger" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleUpdateClick}>
                                                        DELETE
                                                    </Button>
                                                </ButtonToolbar>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                <NoserLoading show={this.state.isloading} />
                <SourcingModal 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
                <ShortListed
                    show={this.state.openShortlistedModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child1 = ref)}
                />
            </div> 
        )
    }

}

export  default Sourcing