import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

const showSecond = false;
const str = showSecond ? 'HH:mm:ss' : 'HH:mm';

class EmployeePostTrainingExam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            disableEmployeeNo:  false,
            disableDate     :   false,
            disableTakeExam :   true,
            disableSubmit   :   true,

            requestTypeId   :   "",
            referenceId     :   "",
            trainingId      :   "",
            examId          :   "",
            examDate        :   "",
            profileId       :   "",
            employeeId      :   "",
            employeeName    :   "",
            clientName      :   "",
            positionName    :   "",
            instructions    :   "",
            timeLimit       :   "",
            examDate        :   new Date(),

            examinationList :[],
            postExaminationList:[],
            postExaminationOrig:[],
            choices             :[],
            totalItems      :   "0",
            currentItem     :   "0",
            disableNext     :   true,
            disablePrev     :   true,
            timeelapsed     :   "00:00:00",
            time: {}, seconds: 5
        }
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }
    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        if(this.state.userinfo.roleId=="2"){
            this.state.employeeNo=this.state.userinfo.employeeNo
            this.state.disableEmployeeNo = true
            this.GetEmployeeDetails()
        }
    }
    handleChangeEmployeeNo = (e) =>{
        this.setState({employeeNo:e.target.value,isshow:false})
    }
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.GetEmployeeDetails(e)
        }
    }
    GetEmployeeDetails = async(e) =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeNo"    :   this.state.employeeNo,
            "StatusId"      :   "3",
        };

        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetEmployeeForExam",  params)
        .then(res => {
            const data = res.data;
            //this.setState({isloading:false});
            if(data.status=="0"){
                this.setState({
                    alerttype:"",
                    isshow:true,
                    color:"danger",
                    message:data.message,
                    fade:false,
                    isloading:false,
                    profileId:"",
                    employeeId:"",
                    employeeName:"",
                    clientName:"",
                    positionName:"",
                    requestTypeId:"",
                    referenceId:"",
                    trainingId:"",
                    examId:"",
                    instructions:"",
                    timeLimit:"",
                    disableTakeExam:true,
                    examinationList :[],
                    postExaminationList:[],
                    totalItems      :   "0",
                    currentItem     :   "0",
                    questionName    :   "",
                    questionId      :   "",
                    choices         :   [],
                    disableNext:true,
                    disablePrev:true,
                    disableSubmit   :   true,
                });
            }
            else{
                this.setState({
                    alerttype:"",
                    isshow:false,
                    color:"danger",
                    message:"",
                    fade:false,
                    isloading:false,
                    referenceId:data.id,
                    profileId:data.id,
                    employeeId:data.employeeId,
                    employeeName:data.employeeName,
                    clientName:data.client,
                    positionName:data.position,
                    trainingId:data.trainingId,
                    examId:data.examId,
                    instructions:data.instructions,
                    timeLimit:data.timeLimit*60,
                    timeelapsed:this.TimeElapsed(parseInt(data.timeLimit)),
                    disableTakeExam:false,
                    disableNext:true,
                    disablePrev:true,
                    disableSubmit:true,
                });
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleTakeExamClick = async()=>{
        this.setState({isloading:true,isDisable:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "TrainingId" : this.state.trainingId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetPostExaminations", params)
        .then(res => {
            const data = res.data
            if(data.postExaminations.length>0){
                if(data.postExaminations[0].questions.length>0){
                    data.postExaminations[0].questions.map(function(itm){
                        itm.choices.map(function(choice){
                            choice.answer = "0"
                        })
                    })
                    let idx = Math.floor(Math.random() * data.postExaminations[0].questions.length);
                    this.setState({
                        isloading:false,isshow:false,
                        totalItems:data.postExaminations[0].questions.length,
                        postExaminationList:data.postExaminations[0].questions,
                        postexamId:data.postExaminations[0].id,
                        questionId:data.postExaminations[0].questions[idx].id,
                        questionName:data.postExaminations[0].questions[idx].name,
                        choices:data.postExaminations[0].questions[idx].choices,
                        disableTakeExam:true,
                        time:this.secondsToTime(parseInt(data.postExaminations[0].timeLimit)*60),
                        seconds:parseInt(data.postExaminations[0].timeLimit)*60,
                        currentItem:0,
                        disableNext:false,
                        disablePrev:true,
                        disableSubmit:false,
                        disableEmployeeNo:true,
                        disableDate:true,
                    })
                    let examList =[]
                    examList.push(data.postExaminations[0].questions[idx])
                    this.state.examinationList = examList
                    this.startTimer()
                }
                else{
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   "No list of questions define for examination.",
                        fade        :   true
                    });
                }
            }
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    TimeElapsed(num)
    { 
        var hours = this.FormattedHours("00",Math.floor(num / 60),"l");  
        var minutes = this.FormattedHours("00",num % 60,"l");
        return hours + ":" + minutes + ":00";         
    }
    FormattedHours(pad, user_str, pad_pos)
    {
        if (typeof user_str === 'undefined') return pad;
        if (pad_pos == 'l') return (pad + user_str).slice(-pad.length);
        else return (user_str + pad).substring(0, pad.length);
    }
    secondsToTime(secs){
        let hours = Math.floor(secs / (60 * 60));
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
        let obj = {
          "h": this.FormattedHours("00",hours,"l"),
          "m": this.FormattedHours("00",minutes,"l"),
          "s": this.FormattedHours("00",seconds,"l")
        };
        return obj;
    }
    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
          this.timer = setInterval(this.countDown, 1000);
        }
    }
    countDown() {
        let seconds = this.state.seconds - 1;
        if (seconds == 0) { 
            clearInterval(this.timer);
            this.setState({disablePrev:true,disableNext:true})
        }
        let time = this.secondsToTime(seconds)
        this.setState({
            time: time,//this.secondsToTime(seconds),
            seconds: seconds,
            timeelapsed : time.h + ":" + time.m + ":" + time.s
        });

    }
    handlePrevClick = () =>{
        
        let currentItem = this.state.currentItem-1;
        this.state.currentItem = currentItem
        let nextQuestion = this.state.examinationList[currentItem]
        this.setState({
            disableNext : false,
            disablePrev : currentItem<=0 ? true : false,
            questionId : nextQuestion.id,
            questionName : nextQuestion.name,
            choices : nextQuestion.choices,
            currentItem: currentItem
        })
    }
    handleNextClick = () =>{
        
        let currentItem = this.state.currentItem+1;
        this.state.currentItem = currentItem
        let postExamList = this.state.postExaminationList
        let examList = this.state.examinationList
        
        if(postExamList.length!=examList.length)
        {
            let filteredArray  = postExamList.filter(function(array_el){
                return examList.filter(function(anotherOne_el){
                    return anotherOne_el.id == array_el.id;
                }).length == 0
            });
    
            let idx = Math.floor(Math.random() * filteredArray.length);
            let nextQuestion = filteredArray[idx]
            examList.push(nextQuestion)
            this.setState({
                examinationList : examList,
                disableNext : (currentItem+1)>=this.state.postExaminationList.length? true : false,
                disablePrev : examList.length>=2 ? false : true,
                questionId : nextQuestion.id,
                questionName : nextQuestion.name,
                choices : nextQuestion.choices,
                currentItem: currentItem
            })
        }
        else
        {
            let nextQuestion = this.state.examinationList[currentItem]
            this.setState({
                disableNext : (currentItem+1)>=this.state.postExaminationList.length? true : false,
                disablePrev : false,
                questionId : nextQuestion.id,
                questionName : nextQuestion.name,
                choices : nextQuestion.choices,
                currentItem: currentItem
            })
        }
    }
    handleChangeExamDate = date => {
        this.setState({examDate: date})
    };
    handleChangeChoice = (e)=>{
        
        this.state.choices.map(function(itm){
            itm.answer = "0"
            itm.answer = e.target.name==itm.id && e.target.checked ? "1" : "0"
            // if(e.target.name==itm.id){
            //     itm.answer = e.target.checked ? "1" : "0"
            // }
        })
    }
    handleSubmitClick = async(e)=>{
        this.setState({isloading:true,disableSubmit:true})
        let examList = this.state.examinationList
        let postExamList = this.state.postExaminationList

        let unansweredQuestion  = postExamList.filter(function(array_el){
            return examList.filter(function(anotherOne_el){
                return anotherOne_el.id == array_el.id;
            }).length == 0
        });
        unansweredQuestion.map((itm)=>{
            examList.push(itm)
        })
        let details=[]
        let obj={}
        examList.map((question)=>{
            if(question.choices.filter(x=>x.answer==="1").length>0){
                question.choices.filter(x=>x.answer==="1").map((itm)=>{
                    obj = {
                        "ProfileId":this.state.profileId,
                        "TrainingId":this.state.trainingId,
                        "ExamId":question.examId,
                        "QuestionId":itm.questionId,
                        "ChoiceId":itm.id 
                    }
                })
            }
            else{
                obj = {
                    "ProfileId":this.state.profileId,
                    "TrainingId":this.state.trainingId,
                    "ExamId":question.examId,
                    "QuestionId":question.id,
                    "ChoiceId":""
                }
            }
            details.push(obj)
        })
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "RequestTypeId" : "6",
            "ReferenceId" : this.state.referenceId,
            "EmployeeId" : this.state.employeeId,
            "ProfileId" : this.state.profileId,
            "TrainingId" : this.state.trainingId,
            "ExamId" : this.state.examId,
            "IsPreTest" : "0",
            "ExamDate" : this.state.examDate,
            "Duration" : ((this.state.timeLimit - this.state.seconds) / 60).toFixed(2).toString(),
            "details" : details
        }
        await axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Training/SaveEmployeeExam",  params
            )
            .then(res => {
            const data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   data.status==="1" ? "Success!" : "Error!",
                isshow      :   true,
                color       :   data.status==="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true
            });
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    render() {
        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>TRAINING >> EMPLOYEE POST TRAINING EXAM</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            EMPLOYEE NO.
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                ref="employeeNo"
                                                name="employeeNo"
                                                value={this.state.employeeNo}
                                                onChange={this.handleChangeEmployeeNo}
                                                onKeyDown={this.handleKeyDown}
                                                autoComplete="off"
                                                disabled={this.state.disableEmployeeNo}
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            CLIENT
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                name="clientName"
                                                value={this.state.clientName}
                                                disabled
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            EMPLOYEE NAME
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                name="employeeName"
                                                value={this.state.employeeName}
                                                disabled
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            POSITION
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                name="positionName"
                                                value={this.state.positionName}
                                                disabled
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            EXAM INSTRUCTION
                                        </Form.Label>
                                        <Col sm="11">
                                            <Form.Control 
                                                as="textarea"
                                                rows={3}
                                                name="instructions"
                                                value={this.state.instructions}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Col sm="1">
						                    <Button disabled={this.state.disableTakeExam} className="ml-auto" variant="success" onClick = { this.handleTakeExamClick }>Take Exam</Button>
                                        </Col>
                                        <Form.Label column sm="3" style={{fontWeight : "bold"}}>
                                            TIME REMAINING (Start once TAKE EXAM is click)
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                name="duration"
                                                value={this.state.timeelapsed}
                                                disabled
                                            />
                                        </Col>
                                        <Col sm="2" style={{display:'none'}}></Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            EXAM DATE
                                        </Form.Label>
                                        <Col sm="2">
                                            <DatePicker
                                                ref='examDate'
                                                selected={this.state.examDate}
                                                onChange={this.handleChangeExamDate}
                                                minDate={this.minDate}
                                                value={this.props.examDate}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                disabled={this.state.disableDate}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Card.Header className="mt-3">Examination Questions:</Card.Header>
                                    <Form.Row className="mt-4">
                                        <Form.Group as={Col} sm={12}  controlId="formGridPassword">
                                            <Form.Label style={{fontWeight : "bold", fontSize : "15px"}}>
                                                {this.state.questionName}
                                            </Form.Label>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} sm={1}>
                                        </Form.Group>
                                        {
                                            this.state.choices.map((itm,idx)=>{
                                                return <Form.Group as={Col} sm={2}>
                                                    <Form.Check 
                                                        inline
                                                        type="radio" 
                                                        label={itm.name} 
                                                        name={itm.id}
                                                        value={itm.answer==="1" ? true:false}
                                                        checked={itm.answer==="1" ? true:false}
                                                        onChange = {this.handleChangeChoice} 
                                                        //value={this.state.isRequired}
                                                    />
                                                    {/* <input 
                                                        type="radio" 
                                                        checked={itm.answer==="1" ? true:false}
                                                        key={idx+100}
                                                        onChange = {(e)=>this.handleChangeChoice(e)} 
                                                        value={idx} /> */}
                                                </Form.Group>
                                            })
                                        }
                                    </Form.Row>
                                    <ButtonToolbar>
                                        <Button disabled={this.state.disablePrev} className="noser-button-mr1" variant="info" onClick={this.handlePrevClick}>PREVIOUS</Button>
                                        <Button disabled={this.state.disableNext} className="noser-button" variant="info" onClick={this.handleNextClick}>NEXT</Button>

                                        <Button disabled={this.state.disableSubmit} className="ml-auto noser-button-mr1" variant="success" onClick = { this.handleSubmitClick }>submit</Button>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />
            </div> 
        )
    }

}

export  default EmployeePostTrainingExam 
