import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker,
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown, FormControl} from 'react-bootstrap';

//import  InsertCOCReference from '../Maintenance/Modal/InsertCOCReference';
import { Input } from 'reactstrap';
 
class CriminalCases extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
          
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalCoCReferenceShow               :   false,

            category                :"",

            isLoading               :"",

           
            selectedEmployeeId      :[],
            selectedEmployeeNo      :[],
            selectedEmployeeBranch  :[],
            selectedPosition        :[],

            getClientList           :[],
            getEmployeeList         :[],
            getEmployeeNoList       :[],
            position                :[],
            location                :[],

            //addParagraph:[],
            values                  :[],
            
            dateApplied             :"",
            selectedClientName      :"",
            selectedEmployeeName    :[],
            selectedEmployeeNo      :[],           

            dateCaseFiled           :"",
            caseFiled               :"",
            venue                   :"",
            SchedOfHearing          :"",          
            prosecutor              :"",
            legal                   :"",
            latestUpdate            :"",
            updateDate              :"",
            remarks                 :"",
            status                  :"",
            AttachedDoc             :"",
            particularList: [
                {
                    "id" : "Pending",
                    "name" : "Pending"
                },
                {
                    "id" : "Dismissed",
                    "name" : "Dismissed"
                },
                {
                    "id" : "Settled",
                    "name" : "Settled"
                }
            ],
 	        selectedparticular      :   '',
            selectedparticularId    :   '',
            counsel:"",
            disableEmployee         :true,
            clientPlaceHolder       :"",
            employeePlaceHolder     :"",
            employeeNoPlaceHolder   :"",
            positionPlaceHolder     :"",
            locationPlaceHolder     :"",
            

            casetitle:"",
            caseNo:"",
            scheduleOfHearingDetails                   :   [ {
                id              :"0",
                scheduleOfHearing      :"",
                purpose        :"",
                isDeleted:"0",
               
            }],
            AppealFiledList:[
                {
                    "id":"0",
                    "name":"Complainant"
                },
                {
                    "id":"1",
                    "name":"Respondent"
                },
                {
                    "id":"2",
                    "name":"Both"
                },
            ],
            selectedAppealFiled:"",
            selectedOppositionFiled:"",
            dateAppealfiled:"", 
            dateOppositionfiled:"",
            caseNoDOJ:"",
            venueDOJ:"",

            casetitleCourt:"",
            caseNoCourt:"",
            scheduleOfHearingCourtDetails                   :   [ {
                id              :"0",
                scheduleOfHearing      :"",
                purpose        :"",
                isDeleted:"0",
               
            }],
            court:"",
            judge:"",
            venueCourt:"",
            retailGroup:"",
            disableSave:false,
            caseTitleDOJ:"",
            retailgroupData             :[
                {
                    "id":"1",
                    "value":"RETAIL 1",
                    "label":"RETAIL 1"
                },
                {
                    "id":"2",
                    "value":"RETAIL 2",
                    "label":"RETAIL 2"
                },
                {
                    "id":"3",
                    "value":"RETAIL 3",
                    "label":"RETAIL 3"
                },
                {
                    "id":"4",
                    "value":"RETAIL 4",
                    "label":"RETAIL 4"
                },
                {
                    "id":"5",
                    "value":"NON-RETAIL 1",
                    "label":"NON-RETAIL 1"
                },
                {
                    "id":"6",
                    "value":"NON-RETAIL 2",
                    "label":"NON-RETAIL 2"
                },
                {
                    "id":"7",
                    "value":"INTERNAL",
                    "label":"INTERNAL"
                },

            ],
            selectedRetailGroup         :   "",
        }
        /* this.handleSubmit = this.handleSubmit.bind(this); */
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        this.setState({isLoading:true,loadingText:"Loading client list..."})

        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();;

        let checkUpdate =[]
        checkUpdate = sessionStorage.getItem("updateCriminalCases")
        if(checkUpdate !== ""){
            console.log("getUpdate")
            this.updateCriminalCases()
            //let dataTmp = sessionStorage.getItem("updateCompliance")
            this.setState({
                saveStatus:"1",
                disableEmployee:true,
            })

        }else{
            this.setState({
                saveStatus:"0",
                disableEmployee:false,
            })
        }


    }

    GridDataModified(oldValue, newValue, id, column) {
        ////console.log(id)
       
        this.state.scheduleOfHearingDetails.map(function(item,i) {
            
            if (item.id===id){
               
                item.isModified = newValue!=oldValue ? "1" : "0"
                 
                let newDate = new Date(item.scheduleOfHearing)        
                let createddate = moment(newDate).format('MM');
                let createddate2 = moment(newDate).format('YYYY');
                let createddate3 = moment(newDate).format('DD');
                console.log(createddate)
                console.log(createddate2)
        
                let monthLeters = ""
                if(createddate == "01"){
                    monthLeters = "January"
                }
                if(createddate == "02"){
                    monthLeters = "February"
                }
                if(createddate == "03"){
                    monthLeters = "March"
                }
                if(createddate == "04"){
                    monthLeters = "April"
                }
                if(createddate == "05"){
                    monthLeters = "May"
                }
                if(createddate == "06"){
                    monthLeters = "June"
                }
                if(createddate == "07"){
                    monthLeters = "July"
                }
                if(createddate == "08"){
                    monthLeters = "August"
                }
                if(createddate == "09"){
                    monthLeters = "September"
                }
                if(createddate == "10"){
                    monthLeters = "October"
                }
                if(createddate == "11"){
                    monthLeters = "November"
                }
                if(createddate == "12"){
                    monthLeters = "December"
                }
        
                let wordsDate = createddate3+" "+monthLeters+" "+createddate2

                item.scheduleOfHearing = wordsDate
                
            }
               
        })

    }

    GridDataModified2(oldValue, newValue, id, column) {
        ////console.log(id)
       
        this.state.scheduleOfHearingCourtDetails.map(function(item,i) {
            
            if (item.id===id){
               
                item.isModified = newValue!=oldValue ? "1" : "0"
                 
                let newDate = new Date(item.scheduleOfHearing)        
                let createddate = moment(newDate).format('MM');
                let createddate2 = moment(newDate).format('YYYY');
                let createddate3 = moment(newDate).format('DD');
                console.log(createddate)
                console.log(createddate2)
        
                let monthLeters = ""
                if(createddate == "01"){
                    monthLeters = "January"
                }
                if(createddate == "02"){
                    monthLeters = "February"
                }
                if(createddate == "03"){
                    monthLeters = "March"
                }
                if(createddate == "04"){
                    monthLeters = "April"
                }
                if(createddate == "05"){
                    monthLeters = "May"
                }
                if(createddate == "06"){
                    monthLeters = "June"
                }
                if(createddate == "07"){
                    monthLeters = "July"
                }
                if(createddate == "08"){
                    monthLeters = "August"
                }
                if(createddate == "09"){
                    monthLeters = "September"
                }
                if(createddate == "10"){
                    monthLeters = "October"
                }
                if(createddate == "11"){
                    monthLeters = "November"
                }
                if(createddate == "12"){
                    monthLeters = "December"
                }
        
                let wordsDate = createddate3+" "+monthLeters+" "+createddate2

                item.scheduleOfHearing = wordsDate
                
            }
               
        })

    }

    updateCriminalCases=(e)=>{
        console.log("updateCriminalCases start")
        let dataFilterTmp=[]
        dataFilterTmp = JSON.parse(sessionStorage.getItem("updateCriminalCases"))
        
        this.setState({

            updateId:dataFilterTmp.id,
            clientPlaceHolder:dataFilterTmp.client,
            employeePlaceHolder:dataFilterTmp.employeeName,
            employeeNoPlaceHolder:dataFilterTmp.employeeNo,
            positionPlaceHolder:dataFilterTmp.position,
            locationPlaceHolder:dataFilterTmp.location,
            prosecutor:dataFilterTmp.prosecutor,
            dateCaseFiled:new Date(dataFilterTmp.dateCaseFiled),
            caseFiled:dataFilterTmp.caseFiled,
            venue:dataFilterTmp.venue,
            SchedOfHearing:new Date(dataFilterTmp.SchedOfHearing),
            legal:dataFilterTmp.counsel,
            updateDate:new Date(dataFilterTmp.updateDate),
            selectedparticular:dataFilterTmp.status,
            casetitle:dataFilterTmp.casetitle,
            caseNo:dataFilterTmp.caseNo,
            scheduleOfHearingDetails:dataFilterTmp.scheduleOfHearingDetails,
            selectedAppealFiled:dataFilterTmp.selectedAppealFiled,
            selectedRetailGroup:dataFilterTmp.retailGroup,
            selectedOppositionFiled:dataFilterTmp.selectedOppositionFiled,
            dateOppositionfiled:new Date(dataFilterTmp.dateOppositionfiled),
            dateAppealfiled:new Date(dataFilterTmp.dateAppealfiled),
            caseNoDOJ:dataFilterTmp.caseNoDOJ,
            casetitleCourt:dataFilterTmp.casetitleCourt, 
            caseNoCourt:dataFilterTmp.caseNoCourt,
            scheduleOfHearingCourtDetails:dataFilterTmp.scheduleOfHearingCourtDetails,
            court:dataFilterTmp.court,
            judge:dataFilterTmp.judge,
            venueCourt:dataFilterTmp.venueCourt,
            latestUpdate:dataFilterTmp.latestUpdate,
            caseTitleDOJ:dataFilterTmp.caseTitleDOJ,
            venueDOJ:dataFilterTmp.venueDOJ, 
            dateInfoFiled:new Date(dataFilterTmp.dateInfoFiled),
            caseFiledCourt:dataFilterTmp.caseFiledCourt, 
            
        })              

        
    
    }

    /////******DYNAMIC COMPONENT TASYO******/////////
    /* createUI(){
        return this.state.values.map((el, i) => 
            <div key={i} className="mt-3">
               <FormControl as="textarea" value={el||''} aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChange.bind(this, i)} />
               
            </div>          
        )
    }
   
    handleChange(i, event) {
        let values = [...this.state.values];
        values[i] = event.target.value;
        this.setState({ values });
        console.log(values)
    }
     
    addClick(){
       this.setState(prevState => ({ values: [...prevState.values, '']}))
    }
     
    removeClick(i){
        let values = [...this.state.values];
        values.splice(i,1);
        this.setState({ values });
    }
   
    handleSubmit(event) {
       alert('A name was submitted: ' + this.state.values.join(', '));
       event.preventDefault();
    } */

    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            let data = res.data
          
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }

    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        console.log("Client selectedClientId " + this.state.selectedClientId );
        console.log("Client selectedClientName " + this.state.selectedClientName );
        //this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})
        
        this.getEmployees();
    }

    getEmployees(){
        this.setState({isloading:true})

        const getParams = {

            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""

        }

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees"/* "Employee/GetEmployeeProfileFilter" */, getParams)
        .then(res => {          
           
            const data = res.data
            console.log("Employee List start ");
            console.log(data);
            console.log("Employee List end");

            if(data.status=="1"){
                console.log("tur==")
                this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false,})
            }else{
                console.log("else==")
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 
            }
                
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
        
    }

    onChangeEmployeesList = (e) => {       

        if(e.length==0)
        {
            console.log("123123")
            this.state.selectedEmployeeName=""
            this.state.selectedEmployeeNo=""
            this.state.selectedEmployeeBranch=""
            this.state.selectedPosition=""

          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})
        console.log("45678")
        this.state.selectedEmployeeName = e[0].employeeName
        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeNo = e[0].employeeNo
        this.state.selectedEmployeeBranch = e[0].locationName
        this.state.selectedPosition = e[0].position
       
        console.log("Work selectedEmployeeName   " + this.state.selectedEmployeeNo );
        console.log("Employee List start ");
        console.log(this.state.selectedEmployeeBranch);
        console.log("Employee List end");

      /*   if(this.state.selectedPayrollpayPeriod == ""){
            this.state.selectedPayrollPeriodsId = e[0].payPeriodId
            this.state.selectedPayrollpayPeriod = e[0].payPeriod
        } */

        //this.employeeTransactionsList();
       
        this.setState({isloading:false})
    }
    
    onChangeEmployeesNo(e){
        this.setState({selectedEmployeeNo : e.target.value})
        //console.log(e.target.value)
    }
   
    handleChangeDate = date => {
        ////console.log(date)
        this.setState({
            dateApplied: date,
            isshow:false,
        })
    }

    /* handleAddParagraph(){
        return this.state.addParagraph.map((el,i)=>
            <div className="mt-2" key={i}>

                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                    <Col>
                        <FormControl as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph)" />
                    </Col>
                </Form.Group>                                   
       
            </div>
        )
    } */

    // save criminal case
    handleSaveClick=()=>{

        // if( ){
            
        //     this.setState({
        //         isloading       :   false,
        //         alerttype       :   "Error!",
        //         isshow          :   true,
        //         color           :   "danger",
        //         message         :   "please fill up status",
        //         fade            :   true
        //     })

        // }

        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');
        let _dateCaseFiled = moment(this.state.dateCaseFiled).format('MM/DD/YYYY');
        let _dateAppealfiled = moment(this.state.dateAppealfiled).format('MM/DD/YYYY');
        let _dateOppositionfiled = moment(this.state.dateOppositionfiled).format('MM/DD/YYYY'); 
        let _dateInfoFiled = moment(this.state.dateInfoFiled).format('MM/DD/YYYY');

        if(createddate == "Invalid date"){
            
            createddate = ""

        }
        if(_dateCaseFiled == "Invalid date"){
            
            _dateCaseFiled = ""

        }
        if(_dateAppealfiled == "Invalid date"){
            
            _dateAppealfiled = ""

        }
        if(_dateOppositionfiled == "Invalid date"){
            
            _dateOppositionfiled = ""

        }
        if(_dateInfoFiled == "Invalid date"){
            
            _dateInfoFiled = ""

        }
       
        if(this.state.saveStatus == "0" || this.state.saveStatus === "0"){

            this.setState({ isloading   :   true, })

            

            let data = {

                "client"                    :   this.state.selectedClientName,
                "employeeName"              :   this.state.selectedEmployeeName,
                "position"                  :   this.state.selectedPosition,
                "employeeNo"                :   this.state.selectedEmployeeNo,
                "location"                  :   this.state.selectedEmployeeBranch,
                "retailGroup"               :   this.state.selectedRetailGroup,
                "casetitle"                 :   this.state.casetitle,
                "dateCaseFiled"             :   _dateCaseFiled,
                "caseFiled"                 :   this.state.caseFiled,
                "caseNo"                    :   this.state.caseNo,
                "venue"                     :   this.state.venue,
                "scheduleOfHearingDetails"  :   this.state.scheduleOfHearingDetails,
                "prosecutor"                :   this.state.prosecutor,
                "counsel"                   :   this.state.legal,
                "selectedAppealFiled"       :   this.state.selectedAppealFiled,
                "selectedOppositionFiled"   :   this.state.selectedOppositionFiled,
                "dateAppealfiled"           :   _dateAppealfiled,
                "dateOppositionfiled"       :   _dateOppositionfiled,
                "dateInfoFiled"             :   _dateInfoFiled,
                "caseNoDOJ"                 :   this.state.caseNoDOJ,
                "casetitleCourt"            :   this.state.casetitleCourt,
                "caseNoCourt"               :   this.state.caseNoCourt, 
                "caseFiledCourt"            :   this.state.caseFiledCourt,
                "scheduleOfHearingCourtDetails" :   this.state.scheduleOfHearingCourtDetails,
                "court"                     :   this.state.court,
                "judge"                     :   this.state.judge,
                "venueCourt"                :   this.state.venueCourt,
                "venueDOJ"                  :   this.state.venueDOJ,
                "latestUpdate"              :   this.state.latestUpdate,
                "status"                    :   this.state.selectedparticular,
                "caseTitleDOJ"              :   this.state.caseTitleDOJ, 
                "isDraft"               :   "0",
                "isDeleted"             :   "0",
                "createdby"             :   "user007",
                "createddate"           :   createddate,
                "modifiedby"            :   'user007',
                "modifieddate"          :   '0',
                "isModified"            :   '0',  
                
            }
            
            const addParams = {
                "_collection" : "TestCriminalCases",
                "doc_data"    : data
            }
            console.log("addParams")
            console.log(addParams)
            console.log("addParams")

            axios
            .post(AppConfiguration.Setting().noserMongoDB + "action/add", addParams)
            //.post("http://134.209.99.190:8088/action/add", addParams)
            .then(res =>{
                const data = res.data
            
                this.setState({
                    selectedClientName      :"",
                    selectedEmployeeName    :"",
                    selectedPosition        :"",
                    selectedEmployeeNo      :"",
                    selectedEmployeeBranch  :"",
                    dateCaseFiled           :"",
                    caseFiled               :"",
                    venue                   :"",
                    SchedOfHearing          :"",
                    prosecutor              :"",
                    legal                   :"",
                    latestUpdate            :"",
                    updateDate              :"",
                    remarks                 :"",
                    status                  :"", 
                    AttachedDoc             :"", 
                    counsel                 :"",       
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   "Data had successfully Save" ,
                    fade            :   true,
                    disableSave:true,
                
                })
            
            })
            .catch(error =>{
                console.log("error: " + error)
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })

        }else{

            this.setState({ isloading   :   true, })
          
            let data = {

                "client"                    :   this.state.clientPlaceHolder,
                "employeeName"              :   this.state.employeePlaceHolder,
                "position"                  :   this.state.positionPlaceHolder,
                "employeeNo"                :   this.state.employeeNoPlaceHolder,
                "location"                  :   this.state.locationPlaceHolder,
                "retailGroup"               :   this.state.selectedRetailGroup,
                "casetitle"                 :   this.state.casetitle,
                "dateCaseFiled"             :   _dateCaseFiled,
                "caseFiled"                 :   this.state.caseFiled,
                "caseNo"                    :   this.state.caseNo,
                "venue"                     :   this.state.venue,
                "scheduleOfHearingDetails"  :   this.state.scheduleOfHearingDetails,
                "prosecutor"                :   this.state.prosecutor,
                "counsel"                   :   this.state.legal,
                "selectedAppealFiled"       :   this.state.selectedAppealFiled,
                "selectedOppositionFiled"   :   this.state.selectedOppositionFiled,
                "dateAppealfiled"           :   _dateAppealfiled,
                "dateOppositionfiled"       :   _dateOppositionfiled,
                "dateInfoFiled"             :   _dateInfoFiled,
                "caseNoDOJ"                 :   this.state.caseNoDOJ,
                "casetitleCourt"            :   this.state.casetitleCourt,
                "caseNoCourt"               :   this.state.caseNoCourt, 
                "caseFiledCourt"            :   this.state.caseFiledCourt,
                "scheduleOfHearingCourtDetails" :   this.state.scheduleOfHearingCourtDetails,
                "court"                     :   this.state.court,
                "judge"                     :   this.state.judge,
                "venueCourt"                :   this.state.venueCourt,
                "venueDOJ"                  :   this.state.venueDOJ,
                "latestUpdate"              :   this.state.latestUpdate,
                "status"                    :   this.state.selectedparticular,
                "caseTitleDOJ"              :   this.state.caseTitleDOJ, 
                "isDraft"               :   "0",
                "isDeleted"             :   "0",
                "createdby"             :   "user007",
                "createddate"           :   createddate,
                "modifiedby"            :   'user007',
                "modifieddate"          :   '0',
                "isModified"            :   '0',  
                
            }
            
            const updateParams = {
                "_collection" : "TestCriminalCases",
                "doc_data"    : data,
                "_id"         : this.state.updateId,
            }

            console.log("updateParams")
            console.log(updateParams)
            console.log("updateParams")
            
            axios
            .post(AppConfiguration.Setting().noserMongoDB + "action/update_one", updateParams)
            //.post("http://134.209.99.190:8088/action/update_one", updateParams)
            .then(res =>{
                const data = res.data
            
                this.setState({
                    selectedClientName      :"",
                    selectedEmployeeName    :"",
                    selectedPosition        :"",
                    selectedEmployeeNo      :"",
                    selectedEmployeeBranch  :"",
                    dateCaseFiled           :"",
                    caseFiled               :"",
                    venue                   :"",
                    SchedOfHearing          :"",
                    prosecutor              :"",
                    legal                   :"",
                    latestUpdate            :"",
                    updateDate              :"",
                    status                  :"", 
                    AttachedDoc             :"", 
                    counsel                 :"",       
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   "Data had successfully Save" ,
                    fade            :   true
                
                })
            
            })
            .catch(error =>{
                console.log("error: " + error)
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })

        }
        
    }

    handleClose=(e)=>{

        sessionStorage.setItem("updateCriminalCases", "")
        sessionStorage.setItem("checkUpdate", "0")

    }
    
    handleChangedateCaseFiled = date => {
        
        console.log(date)

        this.setState({
            dateCaseFiled: date,
            isshow:false,
        })

    }

    handleAddedSchedOfHearing = date => {
        ////console.log(date)

        this.setState({
            SchedOfHearing: date,
            isshow:false,
        })

    }

    handleAddedcaseFiled=(e)=>{
        this.setState({
            caseFiled:e.target.value
        })
    }

    handlechangeRetailGroup=(e)=>{
       
        if(e.length==0)
        {
            this.setState({
                selectedRetailGroup    :   '',
            })
            return
        }
        this.state.selectedRetailGroup= e[0].value
      
    }

    handleAddedcaseFiledCourt=(e)=>{
        this.setState({
            caseFiledCourt:e.target.value
        })
    }

    handleChangevenue=(e)=>{
        this.setState({
            venue   : e.target.value
        })
        //console.log(this.state.addedparagraph1)
    }

    handleChangecourt=(e)=>{
        this.setState({
            court   : e.target.value
        })
        //console.log(this.state.addedparagraph1)
    }

    handleChangejudge   =(e)=>{
        this.setState({
            judge      : e.target.value
        })
        //console.log(this.state.addedparagraph1)
    }

    handleChangevenueCourt=(e)=>{
        this.setState({
            venueCourt   : e.target.value
        })
        //console.log(this.state.addedparagraph1)
    }

    handleChangeprosecutor=(e)=>{
        this.setState({
            prosecutor   : e.target.value
        })
        //console.log(this.state.addedparagraph2)
    }

    handlelegal=(e)=>{
        this.setState({
            legal : e.target.value
        })
    }

    handleChangelatestUpdate=(e)=>{
        this.setState({
            latestUpdate   : e.target.value
        })
        //console.log(this.state.addedparagraph3)
    }
 
    handleChangeupdateDate = date => {
        ////console.log(date)

        this.setState({
            dateAppealfiled: date,
            isshow:false,
        })

    }

    handleChangedateOppositionfiled = date => {
        ////console.log(date)

        this.setState({
            dateOppositionfiled: date,
            isshow:false,
        })

    }

    handleChangedateInfoFiled = date => {
        ////console.log(date)

        this.setState({
            dateInfoFiled: date,
            isshow:false,
        })

    }

    handleOnChangeremarks=(e)=>{
        this.setState({
            remarks   : e.target.value
        })
        //console.log(this.state.preparedBy)
    }

    handleOnChangecaseNoDOJ=(e)=>{
        this.setState({
            caseNoDOJ   : e.target.value
        })
        //console.log(this.state.preparedBy)
    }

    handleOnChangevenueDOJ=(e)=>{
        this.setState({
            venueDOJ   : e.target.value
        })
        //console.log(this.state.preparedBy)
    }

    handleOnChangestatus=(e)=>{
        this.setState({
            status   : e.target.value
        })
        //console.log(this.state.notedBy)
    }

    onChangeCaseTitle=(e)=>{
        this.setState({
            casetitle   : e.target.value
        })
        //console.log(this.state.notedBy)
    }

    onChangeCaseNo=(e)=>{
        this.setState({
            caseNo   : e.target.value
        })
        //console.log(this.state.notedBy)
    }

    onChangeCaseTitleCourt=(e)=>{
        this.setState({
            casetitleCourt   : e.target.value
        })
        //console.log(this.state.notedBy)
    }

    handleOnChangecaseTitleDOJ=(e)=>{
        this.setState({
            caseTitleDOJ   : e.target.value
        })
        //console.log(this.state.notedBy)
    }
    


    onChangeCaseNoCourt=(e)=>{
        this.setState({
            caseNoCourt   : e.target.value
        })
        //console.log(this.state.notedBy)
    }

    handleOnChangeAttachedDoc=(e)=>{
        let fileTmp = e.target.value
        this.setState({
            AttachedDoc   : fileTmp
        })
        console.log(this.state.AttachedDoc)
    }

    handleOnChangeCounsel=(e)=>{
        this.setState({
            counsel   : e.target.value
        })
        console.log(this.state.counsel)
    }
    
    onChangeparticular = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedparticular     :   '',
                selectedparticularId   :   ''
            })
            return
        }
        this.state.selectedparticular = e[0].name
        this.state.selectedparticularId = e[0].id
       
        this.setState({
            isshow   :   false,
            selectedparticular : e[0].id
        })
        console.log(this.state.selectedparticular)
      
    }

    onChangeAppealFiled = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedAppealFiled     :   '',
            })
            return
        }
        this.state.selectedAppealFiled = e[0].name

        console.log(this.state.selectedAppealFiled)
      
    }

    onChangeOppositionFiled = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedOppositionFiled     :   '',
            })
            return
        }
        this.state.selectedOppositionFiled = e[0].name

        console.log(this.state.selectedOppositionFiled)
      
    }

    AddEmployee=()=>{
        
        this.setState({
            isloading:true
        })
        let IDnew = this.state.scheduleOfHearingDetails.length + 1

        let addData = {
            id              :String(IDnew),
            scheduleOfHearing      :"",
            purpose        :"",
            isDeleted:"0",
           
        }

        this.reloadGrid(addData)
       
    }

    reloadGrid=(addData)=>{
       
        this.state.scheduleOfHearingDetails.push(addData)
        console.log(this.state.scheduleOfHearingDetails)
        console.log(addData)
        this.setState({
            isloading : false
        })
    
    }

    removesched=(e)=>{
        
        this.setState({
            isloading:true
        })

        let removeData = []
        this.state.scheduleOfHearingDetails.map(function(d){
            let newID = removeData.length
            if(d.isDeleted =="0"){
                d.id = String(newID)
                removeData.push(d)              
            }
            
        })
        this.reloadRemoveGrid(removeData)
       
    }

    reloadRemoveGrid=(removeData)=>{
       
        this.state.scheduleOfHearingDetails = removeData
        console.log(this.state.scheduleOfHearingDetails)
        console.log(removeData)
        this.setState({
            isloading : false
        })
    
    }

    

    AddEmployee2=()=>{
        
        this.setState({
            isloading:true
        })
        let IDnew = this.state.scheduleOfHearingCourtDetails.length + 1

        let addData2 = {
            id              :String(IDnew),
            scheduleOfHearing      :"",
            purpose        :"",
            isDeleted:"0",
           
        }

        this.reloadGrid2(addData2)
       
    }

    reloadGrid2=(addData2)=>{
       
        this.state.scheduleOfHearingCourtDetails.push(addData2)
        console.log(this.state.scheduleOfHearingCourtDetails)
        console.log(addData2)
        this.setState({
            isloading : false
        })
    
    }

    removesched2=(e)=>{
        
        this.setState({
            isloading:true
        })

        let removeData2 = []
        this.state.scheduleOfHearingCourtDetails.map(function(d){
            let newID = removeData2.length
            if(d.isDeleted =="0"){
                d.id = String(newID)
                removeData2.push(d)    
               // d.isSelect = "0"          
            }
            
        })
        this.reloadRemoveGrid2(removeData2)
       
    }

    reloadRemoveGrid2=(removeData2)=>{
       
        this.state.scheduleOfHearingCourtDetails = removeData2
        console.log(this.state.scheduleOfHearingCourtDetails)
        console.log(removeData2)
        this.setState({
            isloading : false
        })
    
    }


    render() {    
        
        const scheduleOfHearingColumn = [
            {

                dataField   : 'scheduleOfHearing',
                text        : 'Schedule Of Hearing',
                editable    : true,
               
                headerStyle : () => {
                    return { width  : "50%" };
                },
                style:{/* textAlign: 'right', */ whiteSpace: 'nowrap', },
                // formatter: (cell) => {
                //     let dateObj = cell;
                //     if (typeof cell !== 'object') {
                //     dateObj = new Date(cell);
                //     }
                //     //return `${('0' + dateObj.getUTCDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
                // },
                editor: {
                    type: Type.DATE
                }
                

            },
            {

                dataField   : 'purpose',
                text        : 'Purpose',
                editable    : true,
              
                headerStyle : () => {
                    return { width  : "50%" };
                },
                style:{ whiteSpace: 'nowrap', }

            },
            
        ]

        const selectRow = {

            mode: 'checkbox',
            //clickToSelectAndEditCell: true,
            //clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
                this.state.scheduleOfHearingDetails.map(function(item,i){

                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                                           
                    }
                    
                })

            }

        };

        const rowEvents = {

            onClick: (e, row, rowIndex) => {

            }
            
        };

        const selectRow2 = {

            mode: 'checkbox',
            //clickToSelectAndEditCell: true,
            //clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
                this.state.scheduleOfHearingCourtDetails.map(function(item,i){

                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                                           
                    }
                    
                })

            }

        };

        const rowEvents2 = {

            onClick: (e, row, rowIndex) => {

            }
            
        };

        return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>Criminal Cases</Card.Header>
                            <Card.Body>
                                <Card.Header className="mt-2">Employee INFORMATION</Card.Header>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Client
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClientList}
                                                options={this.state.getClientList}
                                                disabled={this.state.disableEmployee}
                                                placeholder={this.state.clientPlaceHolder}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Member
                                        </Form.Label>
                                        <Col sm="7">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.onChangeEmployeesList}
                                                options={this.state.getEmployeeList}
                                                placeholder={this.state.employeePlaceHolder}
                                                disabled={this.state.disableEmployee}
                                            /> 
                                        </Col>

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Member No.
                                        </Form.Label>
                                        <Col sm="3">
                                           
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeNo" 
                                                value={this.state.selectedEmployeeNo} 
                                                disabled={this.state.disableEmployee}
                                                placeholder={this.state.employeeNoPlaceHolder}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text"
                                                name="selectedPosition" 
                                                value={this.state.selectedPosition}
                                                disabled={this.state.disableEmployee}
                                                placeholder={this.state.positionPlaceHolder}
                                            />
                                        </Col>
                                        <Col sm="2"></Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Location
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName"
                                                value={this.state.selectedEmployeeBranch}
                                                disabled={this.state.disableEmployee}
                                                placeholder={this.state.locationPlaceHolder}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Retail Group
                                        </Form.Label>
                                        <Col sm="4">
                                            {/* <Form.Control 
                                                type="text"
                                                name="selectedretailGroup" 
                                                onChange={this.handlechangeRetailGroup}
                                                value={this.state.selectedretailGroup}
                                                disabled={this.state.disableEmployee}
                                                placeholder={this.state.retailGroupPlaceHolder}
                                            /> */}
                                              <Typeahead
                                                    labelKey='value'
                                                    id="basic-example"
                                                    onChange={this.handlechangeRetailGroup}
                                                    selected={[this.state.selectedRetailGroup]}
                                                    options={this.state.retailgroupData}
                                                    //placeholder="select penalty type"
                                                />
                                        </Col>
                                        
                                    </Form.Group>

                                    <Card.Header className="mt-3">Prosecutor Level</Card.Header>
                                    <div className="mt-3">
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                CASE TITLE
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    id="basic-example"
                                                    value={this.state.casetitle}
                                                    className="form-control"
                                                    onChange={this.onChangeCaseTitle}
                                                    //placeholder="Enter Date Filed"
                                                    autocomplete="off"
                                                    
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                DATE CASE FILED
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <DatePicker
                                                    ref='dateCaseFiled'
                                                    selected={this.state.dateCaseFiled}
                                                    onChange={this.handleChangedateCaseFiled}
                                                    minDate={this.minDate}
                                                    value={this.props.dateCaseFiled}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    /* placeholderText="DATE APPLIED" */
                                                    className="form-control"
                                                />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                CASE FILED
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.caseFiled}
                                                    className="form-control"
                                                    onChange={this.handleAddedcaseFiled}
                                                    //placeholder="Enter Date Filed"
                                                    autocomplete="off"
                                                    
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                CASE No
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    id="basic-example"
                                                    value={this.state.caseNo}
                                                    className="form-control"
                                                    onChange={this.onChangeCaseNo}
                                                    //placeholder="Enter Date Filed"
                                                    autocomplete="off"
                                                    
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                VENUE
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.venue}
                                                    className="form-control"
                                                    onChange={this.handleChangevenue}
                                                    //placeholder="Enter Date Received"
                                                    autocomplete="off"
                                                    
                                                />
                                            </Col>
                                        </Form.Group>
                                        <BootstrapTable
                                            keyField = "id"
                                            data = { this.state.scheduleOfHearingDetails }
                                            columns = { scheduleOfHearingColumn }
                                            // pagination={ paginationFactory({sizePerPageRenderer}) }
                                            striped
                                            hover
                                            condensed
                                            noDataIndication={ () => <div>No record found.</div> }
                                            cellEdit = { cellEditFactory({
                                                mode: 'click',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField , row)
                                                    }
                                                })
                                            }
                                            //defaultSorted={ defaultSorted }
                                            selectRow={selectRow}

                                        />
                                        <Form.Group as={Col} controlId="formPlaintextEmail" className="mt-4">
                                            
                                            <ButtonToolbar sm={12}>
                                                <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.AddEmployee}>
                                                    Add
                                                </Button>&nbsp;&nbsp;
                                                <Button variant="danger" style={{minWidth:'60px'}} onClick={this.removesched}>
                                                    Remove
                                                </Button>&nbsp;&nbsp;
                                               
                                            </ButtonToolbar>
                                            
                                        </Form.Group>
                                      
                                        {/* <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                SCHEDULE OF HEARING
                                            </Form.Label>
                                            <Col sm="10">
                                                <DatePicker
                                                    ref='SchedOfHearing'
                                                    selected={this.state.SchedOfHearing}
                                                    onChange={this.handleAddedSchedOfHearing}
                                                    minDate={this.minDate}
                                                    value={this.props.SchedOfHearing}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    className="form-control"
                                                />
                                            </Col>
                                        </Form.Group> */}
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                PROSECUTOR
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    id="basic-example"
                                                    value={this.state.prosecutor}
                                                    className="form-control"
                                                    onChange={this.handleChangeprosecutor}
                                                    //placeholder="Enter Schedule Of Hearing"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                           
                                        </Form.Group> 
                                       
                                    </div>
                                    
                                    <Card.Header className="mt-3">DOJ Level</Card.Header>

                                    <div className="mt-3">                                       
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Petition/Appeal filed by: 
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangeAppealFiled}
                                                    selected={[this.state.selectedAppealFiled]}
                                                    options={this.state.AppealFiledList}
                                                    //placeholder="select penalty type"
                                                />
                                            </Col>
                                        </Form.Group>
                                        {/* <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Counsel
                                            </Form.Label>
                                            <Col sm="11" >
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.counsel}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeCounsel}
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group> */}
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Date of filing:
                                            </Form.Label>
                                            <Col sm="2" /* style={{marginLeft : "-1px"}} */>
                                                <DatePicker
                                                    ref='updateDate'
                                                    selected={this.state.dateAppealfiled}
                                                    onChange={this.handleChangeupdateDate}
                                                    minDate={this.minDate}
                                                    value={this.props.updateDate}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    /* placeholderText="DATE APPLIED" */
                                                    className="form-control"
                                                />
                                            </Col>
                                           {/*  <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                REMARKS
                                            </Form.Label>
                                            <Col sm="4" >
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.remarks}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeremarks}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col> */}
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Comment/Opposition filed by
                                            </Form.Label>
                                            <Col sm="10" >
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangeOppositionFiled}
                                                    selected={[this.state.selectedOppositionFiled]}
                                                    options={this.state.AppealFiledList}
                                                    //placeholder="select penalty type"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Date of filing:
                                            </Form.Label>
                                            <Col sm="2" /* style={{marginLeft : "-1px"}} */>
                                                <DatePicker
                                                    ref='updateDate'
                                                    selected={this.state.dateOppositionfiled}
                                                    onChange={this.handleChangedateOppositionfiled}
                                                    minDate={this.minDate}
                                                    value={this.props.updateDate}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    /* placeholderText="DATE APPLIED" */
                                                    className="form-control"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                CASE TITLE:
                                            </Form.Label>
                                            <Col sm="4" >
                                                <Input
                                                    id="basic-example"
                                                    value={this.state.caseTitleDOJ}
                                                    className="form-control"
                                                    onChange={this.handleOnChangecaseTitleDOJ}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                CASE NUMBER:
                                            </Form.Label>
                                            <Col sm="4" >
                                                <Input
                                                    id="basic-example"
                                                    value={this.state.caseNoDOJ}
                                                    className="form-control"
                                                    onChange={this.handleOnChangecaseNoDOJ}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2    " style={{fontWeight : "bold"}}>
                                                VENUE
                                            </Form.Label>
                                            <Col sm="4" >
                                                <Input
                                                    id="basic-example"
                                                    value={this.state.venueDOJ}
                                                    className="form-control"
                                                    onChange={this.handleOnChangevenueDOJ}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        
                                    </div>

                                    <Card.Header className="mt-3"> Court Level </Card.Header>

                                    <div className="mt-3">
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                CASE TITLE
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    id="basic-example"
                                                    value={this.state.casetitleCourt}
                                                    className="form-control"
                                                    onChange={this.onChangeCaseTitleCourt}
                                                    //placeholder="Enter Date Filed"
                                                    autocomplete="off"
                                                    
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            DATE INFORMATION FILED:
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <DatePicker
                                                    ref='dateInfoFiled'
                                                    selected={this.state.dateInfoFiled}
                                                    onChange={this.handleChangedateInfoFiled}
                                                    minDate={this.minDate}
                                                    value={this.props.dateInfoFiled}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    /* placeholderText="DATE APPLIED" */
                                                    className="form-control"
                                                />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                CASE FILED
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.caseFiledCourt}
                                                    className="form-control"
                                                    onChange={this.handleAddedcaseFiledCourt}
                                                    //placeholder="Enter Date Filed"
                                                    autocomplete="off"
                                                    
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                CASE No
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    id="basic-example"
                                                    value={this.state.caseNoCourt}
                                                    className="form-control"
                                                    onChange={this.onChangeCaseNoCourt}
                                                    //placeholder="Enter Date Filed"
                                                    autocomplete="off"
                                                    
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Court
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    id="basic-example"
                                                    value={this.state.court}
                                                    className="form-control"
                                                    onChange={this.handleChangecourt}
                                                    //placeholder="Enter Date Received"
                                                    autocomplete="off"
                                                    
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                JUDGE
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    id="basic-example"
                                                    value={this.state.judge}
                                                    className="form-control"
                                                    onChange={this.handleChangejudge}
                                                    //placeholder="Enter Date Received"
                                                    autocomplete="off"
                                                    
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                VENUE
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    id="basic-example"
                                                    value={this.state.venueCourt}
                                                    className="form-control"
                                                    onChange={this.handleChangevenueCourt}
                                                    //placeholder="Enter Date Received"
                                                    autocomplete="off"

                                                />
                                            </Col>
                                        </Form.Group>
                                        <BootstrapTable
                                            keyField = "id"
                                            data = { this.state.scheduleOfHearingCourtDetails }
                                            columns = { scheduleOfHearingColumn }
                                            // pagination={ paginationFactory({sizePerPageRenderer}) }
                                            striped
                                            hover
                                            condensed
                                            noDataIndication={ () => <div>No record found.</div> }
                                            cellEdit = { cellEditFactory({
                                                mode: 'click',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified2(oldValue, newValue, row.id, column.dataField , row)
                                                    }
                                                })
                                            }
                                            //defaultSorted={ defaultSorted }
                                            selectRow={selectRow2}

                                        />
                                        <Form.Group as={Col} controlId="formPlaintextEmail" className="mt-4">
                                            
                                            <ButtonToolbar sm={12}>
                                                <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.AddEmployee2}>
                                                    Add
                                                </Button>&nbsp;&nbsp;
                                                <Button variant="danger" style={{minWidth:'60px'}} onClick={this.removesched2}>
                                                    Remove
                                                </Button>&nbsp;&nbsp;
                                               
                                            </ButtonToolbar>
                                            
                                        </Form.Group>
                                      
                                        {/* <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                SCHEDULE OF HEARING
                                            </Form.Label>
                                            <Col sm="10">
                                                <DatePicker
                                                    ref='SchedOfHearing'
                                                    selected={this.state.SchedOfHearing}
                                                    onChange={this.handleAddedSchedOfHearing}
                                                    minDate={this.minDate}
                                                    value={this.props.SchedOfHearing}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    className="form-control"
                                                />
                                            </Col>
                                        </Form.Group> */}
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Counsel
                                            </Form.Label>
                                            <Col sm="10" >
                                                <Input
                                                    id="basic-example"
                                                    value={this.state.legal}
                                                    className="form-control"
                                                    onChange={this.handlelegal}
                                                    //placeholder="Enter Schedule Of Hearing"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                           
                                        </Form.Group> 
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Latest Update
                                            </Form.Label>
                                            <Col sm="10" >
                                                <Input
                                                    id="basic-example"
                                                    value={this.state.latestUpdate}
                                                    className="form-control"
                                                    onChange={this.handleChangelatestUpdate}
                                                    //placeholder="Enter Schedule Of Hearing"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                           
                                        </Form.Group> 
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Case Status
                                            </Form.Label>
                                            <Col sm="10">
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangeparticular}
                                                    selected={[this.state.selectedparticular]}
                                                    options={this.state.particularList}
                                                    //placeholder="select penalty type"
                                                />
                                            </Col>
                                        </Form.Group>
                                       {/*  <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                LEGAL
                                            </Form.Label>
                                            <Col sm="10" >
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.legal}
                                                    className="form-control"
                                                    onChange={this.handlelegal}
                                                    //placeholder="Enter Schedule Of Hearing"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                           
                                        </Form.Group> */}
                                       
                                    </div>

                                    <div className="mt-3">
                                       {/*  <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Col sm="4" >                                                 
                                                <Input type="file" className="file" placeholder="attached file" variant="primary"  onChange={this.handleOnChangeAttachedDoc}/>                                          
                                            </Col>                                            
                                            
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">

                                        
                                        </Form.Group> */}
                                        <Form.Group as={Col} controlId="formPlaintextEmail" className="mt-4">
                                            
                                            <ButtonToolbar sm={12}>
                                                <Button disabled={this.state.disableSave} variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleSaveClick}>
                                                    Save
                                                </Button>&nbsp;&nbsp;
                                                <NavLink to="/CriminalCasesMonitoring">
                                                    <Button variant="danger" href="/CriminalCasesMonitoring" style={{minWidth:'60px'}} onClick={this.handleClose}>
                                                        Close
                                                    </Button>
                                                </NavLink>
                                            </ButtonToolbar>
                                            
                                        </Form.Group>
                                    </div>


                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                    {/* <InsertCOCReference 
                        show={this.state.modalCoCReferenceShow}
                        onHide={this.handleModalClose}
                    /> */}
            </div> 
        )
    }

}

export  default CriminalCases
