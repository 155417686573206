import {
  React,Component, Nav, Navbar, NavDropdown, NavDropdownMenu, DropdownSubmenu , AppConfiguration, axios
} 
from '../../noser-hris-component';
import '../../noser-hris.css';
import { Form, FormControl } from 'react-bootstrap';

//import 'react-push-menu/styles/component.css';
//import { PushMenu, usePushMenu } from 'react-push-menu';
//import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state={
      userinfo:[],
      featuresLst:[]
    }
  }
componentDidMount(){
  this.setState({
    userinfo : JSON.parse(sessionStorage.getItem("userData")),
    featuresLst : JSON.parse(sessionStorage.getItem("userAccess"))
  })
}


  render() {
    // const Content = () =>{
    //   const { toggleMenu } = usePushMenu()
    //   return (
    //     <div
    //       onClick={() => {
    //         toggleMenu();
    //       }}
    //     >
    //       trigger
    //     </div>
    //   );
    // };
    // const data = {
    //   menu: {
    //     header: 'All Categories',
    //     children: [
    //       {
    //         name: 'About',
    //         id: 1,
    //         link: '/about',
    //         children: [
    //           { name: 'Mission', id: 11, link: null, children: [] },
    //           { name: 'Objectives', id: 12, link: null, children: [] },
    //           {
    //             name: 'Goals',
    //             id: 13,
    //             link: '/about/goals',
    //             children: [
    //               { name: 'Charity', id: 131, link: null, children: [] },
    //               { name: 'Clean Environment Plan', id: 132, link: null, children: [] },
    //             ],
    //           },
    //         ],
    //       },
    //       { name: 'Services', id: 2, link: '/services', children: [] },
    //       { name: 'People', id: 3, link: '/people', children: [] },
    //       { name: 'Careers', id: 4, link: '/careers', children: [] },
    //       { name: 'Contact', id: 5, link: null, children: [] },
    //     ],
    //   },
    // };
    return (
    //   <PushMenu 
    //     backIcon={<FaChevronLeft />}
    //     openOnMount
    //     expanderComponent={FaChevronRight}
    //     nodes={data.menu}
    //     propMap={{ url: 'link' }}
    //     type={'cover'}
    //     menuTrigger='rpm-trigger'
    //   >
    //     <Content />
    //   </PushMenu>
    // );
      <div>
        <Navbar expand="lg" fixed="top" >
          <Navbar.Brand href="/home">
            <div>
              <div style={{textAlign:'left',fontSize:'14px'}}>Noser</div>
              <div className="banner-hris">Human Resource Information System</div>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav >
              <Nav.Link href="/home">HOME</Nav.Link>
              {
                this.state.featuresLst.map((menu) =>
                {
                  if(menu.isActive){
                    return (<NavDropdownMenu title={menu.name} id="basic-nav-dropdown">
                        {
                          menu.subModules.map((submenu) =>
                          {
                            if(submenu.isActive){
                              if(submenu.isParent=="1")
                              {
                                if(submenu.hasDivider=="1"){
                                  return (<div>
                                            <NavDropdown.Divider />
                                            <DropdownSubmenu title={submenu.name} id="basic-nav-dropdown" style={{marginLeft:'10px'}}>
                                            {
                                              submenu.items.map((item) =>
                                                {
                                                  if(item.isActive){
                                                    if(item.hasDivider=="1"){
                                                      return (<div>
                                                              <NavDropdown.Divider />
                                                              <NavDropdown.Item id={item.id} href={item.url}>{item.name}</NavDropdown.Item>
                                                            </div>)
                                                    }
                                                    else{
                                                      return <NavDropdown.Item id={item.id} href={item.url}>{item.name}</NavDropdown.Item>
                                                    }
                                                  }
                                                  else{
                                                    return <NavDropdown.Item id={item.id} style={{display:'none'}}></NavDropdown.Item>
                                                  }
                                                }
                                              )
                                            }
                                          </DropdownSubmenu>
                                          </div>)
                                }
                                else{
                                  return <DropdownSubmenu title={submenu.name} id="basic-nav-dropdown" style={{marginLeft:'10px'}}>
                                        {
                                          submenu.items.map((item) =>
                                            {
                                              if(item.isActive){
                                                if(item.hasDivider=="1"){
                                                  return (<div>
                                                          <NavDropdown.Divider />
                                                          <NavDropdown.Item id={item.id} href={item.url}>{item.name}</NavDropdown.Item>
                                                         </div>)
                                                }
                                                else{
                                                  return <NavDropdown.Item id={item.id} href={item.url}>{item.name}</NavDropdown.Item>
                                                }
                                              }
                                              else{
                                                return <NavDropdown.Item id={item.id} style={{display:'none'}}></NavDropdown.Item>
                                              }
                                            }
                                          )
                                        }
                                      </DropdownSubmenu>
                                }
                              }
                              else
                              {
                                if(submenu.hasDivider=="1"){
                                  return (<div>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href={submenu.url}>{submenu.name}</NavDropdown.Item>
                                   </div>)
                                }
                                else{
                                  return <NavDropdown.Item href={submenu.url}>{submenu.name}</NavDropdown.Item>
                                }
                              }
                            }
                            else{
                              return (<NavDropdown.Item style={{display:'none'}}></NavDropdown.Item>)
                            }
                            
                          }
                        )}
                    </NavDropdownMenu>)
                  }
                  else
                  {
                    return (<NavDropdown.Item style={{display:'none'}}></NavDropdown.Item>)
                  }
                })
              }
              </Nav>
            <Nav className="ml-auto">
              <Form>
              <Form.Group controlId="formHorizontalEmail">

              </Form.Group>
              </Form>
            </Nav>
            <Nav className="ml-auto">
              <Nav.Link href="/">Logout</Nav.Link>
              <Nav.Link href="/ChangePassword">Change Password</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div style={{height: "50px"}}></div>
      </div>
    );
  }
}

export default Banner;
