import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker,
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown, FormControl} from 'react-bootstrap';

import  InsertCOCReference from '../Maintenance/Modal/InsertCOCReference';
import { Input } from 'reactstrap';
import ImgsViewer from 'react-images-viewer'

 
class Awol1stNotice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            disabledSave    :   false,

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            trainingRegisterTableList           :   [ { "typeOfCases" : "DOLE" } ],
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalCoCReferenceShow               :   false,
            category                :"",
            isLoading               :"",
            selectedEmployeeId      :[],
            selectedEmployeeNo      :[],
            selectedEmployeeBranch  :[],
            selectedPosition        :[],
            getClientList           :[],
            getEmployeeList         :[],
            getEmployeeNoList       :[],
            position                :[],
            location                :[],
            //addParagraph:[],
            values                  :[],            
            dateApplied             :"",
            selectedClientName      :"",
            selectedEmployeeName    :"",
            selectedEmployeeNo      :[],
            irReferenceNo           :"",
            paragraph               :"",
            addedparagraph1         :"",
            addedparagraph2         :"",
            addedparagraph3         :"",
            addedparagraph4         :"",
            addedparagraph5         :"",
            addedparagraph6         :"",
            quotedStatement         :"",
            addedparagraph1Check    : false,
            cocReferences           : false,
            addedparagraph2Check    : false,
            addedparagraph3Check    : false,
            addedparagraph4Check    : false,
            addedparagraph5Check    : false,
            addedparagraph6Check    : false,
            quotedStatementCheck    : false,
            preparedBy              :"",
            prepareByPosition       :"",
            notedByPosition       :"",
            notedBy                 :"",
            receivedBy              :"",
            articleNo               :"",
            articleName             :"",
            sectionNo               :"",
            infraction              :"",
            category                :"",
            articleNo2              :"",
            articleName2            :"",
            sectionNo2              :"",
            infraction2             :"",
            category2               :"",
            sectionNo3              :"",
            infraction3             :"",
            category3               :"",          
            irDateIssued            :"",
            dateRecieveByER         :"",
            referenceNoAWOL          :"",
            cocReferences           :"",
            eSignatureNotedBy       :"",
            eSignaturePreparedBy    :"",
            disabledGenerate        :true,
            memoTypeList            :[
                { 
                    "id":"1",
                    "name":"Late & Absenteeism"
                },
                { 
                    "id":"2",
                    "name":"AWOL 1ST Notice"
                },
                 { 
                    "id":"3",
                    "name":"AWOL 2ND Notice"
                },
                { 
                    "id":"4",
                    "name":"Notice of Hearing"
                },
                { 
                    "id":"5",
                    "name":"Recall"
                },
                { 
                    "id":"6",
                    "name":"Guarantor Demand Letter"
                },
            ],
            selectedmemoType        :"",
            disableEmployee         :false,
            employeePlaceHolder     :"",
            IRobjId                 :"",
            selectedClientId        :"",
            selectedPreparedbyClientName        :"",
            selectedPreparedbyClientId        :"",
            address    :"",
            lastDayOfDuty    :"",
            articleDataList:[],
            sectionDataList:[],
            articleDataList2:[],
            sectionDataList2:[],
            articleDataList3:[],
            sectionDataList3:[],
            articleDataList4:[],
            sectionDataList4:[],
            articleDataList5:[],
            sectionDataList5:[],
            // selectedPreparedbyClientName        :"",
            // selectedPreparedbyClientName        :"",
        }
        /* this.handleSubmit = this.handleSubmit.bind(this); */
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        this.setState({isLoading:true,loadingText:"Loading client list..."})

        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();;
        //this.getNTErefNo();;
   

    }

    /////******DYNAMIC COMPONENT TASYO******/////////
    /* createUI(){
        return this.state.values.map((el, i) => 
            <div key={i} className="mt-3">
               <FormControl as="textarea" value={el||''} aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChange.bind(this, i)} />
               
            </div>          
        )
    }
   
    handleChange(i, event) {
        let values = [...this.state.values];
        values[i] = event.target.value;
        this.setState({ values });
        console.log(values)
    }
     
    addClick(){
       this.setState(prevState => ({ values: [...prevState.values, '']}))
    }
     
    removeClick(i){
        let values = [...this.state.values];
        values.splice(i,1);
        this.setState({ values });
    }
   
    handleSubmit(event) {
       alert('A name was submitted: ' + this.state.values.join(', '));
       event.preventDefault();
    } */
   


    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
            this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }
    
    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        console.log("Client selectedClientId " + this.state.selectedClientId );
        console.log("Client selectedClientName " + this.state.selectedClientName );
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})
        
        this.getEmployees();
    }

    onChangePreparedbyClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedPreparedbyClientName=""
            this.state.selectedPreparedbyClientId=""
            return
        } 
        this.state.selectedPreparedbyClientId = e[0].id
        this.state.selectedPreparedbyClient = e[0].name
        console.log("Client selectedClientId " + this.state.selectedClientId );
        console.log("Client selectedClientName " + this.state.selectedClientName );
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})
        
        this.getEmployeesPreparedby();
        //this.getEmployees();
    }

    getEmployeesPreparedby(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedPreparedbyClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            console.log("Employee List start ");
            console.log(res.data);
            console.log("Employee List end");
            const data = res.data

            if(data.status=="1")
                this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
            else
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 
                
        })
        .catch(error=>{

           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })

        })
        
    }
    
    getEmployees(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            console.log("Employee List start ");
            console.log(res.data);
            console.log("Employee List end");
            const data = res.data

            if(data.status=="1")
                this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
            else
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 
                
        })
        .catch(error=>{

           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })

        })
        
    }

    onChangeEmployeesList = (e) => {       

        if(e.length==0)
        {
            this.setState({getEmployeeListData: null, selectedPayrollpayPeriod: '', selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedEmployeeName = e[0].employeeName
        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeNo = e[0].employeeNo
        this.state.selectedEmployeeBranch = e[0].locationName
        this.state.position = e[0].position
        
        console.log("e[0]")
        console.log(e[0])
        console.log("e[0]")
        console.log("Work selectedEmployeeName   " + this.state.selectedEmployeeNo );
        console.log("Employee List start ");
        console.log(this.state.selectedEmployeeBranch);
        console.log("Employee List end");


       /*  this.setState({
            position:this.state.position,
            location:this.state.selectedEmployeeBranch

        }) */

        this.setState({isloading:false})

    }

    onChangeEmployeesNo(e){

        this.setState({selectedEmployeeNo : e.target.value})
        //console.log(e.target.value)
    }

    onChangePosition(e){

        this.setState({position : e.target.value})
        //console.log(e.target.value)
    }

    onChangeLocation(e){

        this.setState({location : e.target.value})
        //console.log(e.target.value)
    }

    showTrainingRegisterModal = (e) => {

        this.setState({modalCoCReferenceShow: true})

    }

    handleModalClose = (e) =>{

        this.setState({modalCoCReferenceShow: false})
        this.getViewDraftMemo();;

    }


    GridDataModified(oldValue, newValue, id, column) {
        ////console.log(id)
        this.state.trainingScheduleTableList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })

    }

    handleChangeDate = date => {
        
        this.setState({
            dateApplied: date,
            isshow:false,
        })

    }

    handleChangelastDayOfDuty = date => {
        
        this.setState({
            lastDayOfDuty: date,
            isshow:false,
        })

    }

    getNTErefNo=(e)=>{       

        /* let filter_data ={}

        const getParams ={
            "_collection" : "NTE",
            "filter_data" : filter_data
        }

        console.log("axios start")

        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data
            console.log("Raw Json111")
            console.log(data)
            console.log("End Raw Json111")
            var obj = (data);
            var res = [];
            
            data.TestNTE.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                res.push(json)
            })
            
            let refNotmp = ""
            refNotmp = res.length
            console.log("Parsed Json below")
            console.log(refNotmp)


            this.setState({
                referenceNoAWOL: refNotmp,
                isloading:false
            })
                            
            this.handleSaveClick();
            
        })
        .catch(error=>{
            console.log("error: " + error)
        }) */
        let newdate         = new Date()
        let NTEDate         = moment(newdate).format('MMDDYYYY');
        let newIRRef        = "PHR"+this.state.selectedEmployeeNo+NTEDate
        this.setState({
            referenceNoAWOL: newIRRef,
            isloading:false
        })
        this.handleSaveClick();
            
    }

    handleSaveClick=()=>{

        if(this.state.createddate === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please enter date",
                fade            :   true
            })
            return
        }
        if(this.state.dateApplied === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please enter date",
                fade            :   true
            })
            return
        }
        if(this.state.lastDayOfDuty === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please enter date",
                fade            :   true
            })
            return
        }
        
        let createddate     = moment(this.state.createddate).format('MM/DD/YYYY');
        let datetmp         = moment(this.state.dateApplied).format('MM/DD/YYYY');
        let reIRDate        = moment(new Date()).format('MMDDYYYY');
        let newIRRef        = "PHR" + this.state.employeeNo + reIRDate

        let data = {

            "client"                :   this.state.selectedClientName,
            "ClientId"              :   this.state.selectedClientId,
            "employeeName"          :   this.state.selectedEmployeeName,
            "position"              :   this.state.position,
            "employeeNo"            :   this.state.selectedEmployeeNo,
            "location"              :   this.state.selectedEmployeeBranch,
            "preparedBy"            :   this.state.preparedBy,
            "prepareByPosition"     :   this.state.prepareByPosition,
            "notedBy"               :   this.state.notedBy,
            "receivedBy"            :   this.state.selectedEmployeeName,
            "date"                  :   datetmp,
            "referenceNoAWOL"       :   newIRRef,
            "status"                :   "0",
            "isDraft"               :   "0",
            "isDeleted"             :   "0",
            "createdby"             :   this.state.userinfo.userId,
            "createddate"           :   createddate,
            "modifiedby"            :   'user007',
            "modifieddate"          :   '0',           

            "paragraph"             :   this.state.paragraph.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph1"       :   this.state.addedparagraph1.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph2"       :   this.state.addedparagraph2.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph3"       :   this.state.addedparagraph3.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph4"       :   this.state.addedparagraph4.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph5"       :   this.state.addedparagraph5.replace(/'/g, '').replace(/"/g, ''),
            "addedparagraph6"       :   this.state.addedparagraph6.replace(/'/g, '').replace(/"/g, ''),
            "quotedStatement"       :   this.state.quotedStatement.replace(/'/g, '').replace(/"/g, ''),

            "isModified"            :   '0',
            "articleDataList":this.state.articleDataList, 
            "sectionDataList":this.state.sectionDataList ,
            "articleDataList2":this.state.articleDataList2 ,
            "sectionDataList2":this.state.sectionDataList2 ,
            "articleDataList3":this.state.articleDataList3 ,
            "sectionDataList3":this.state.sectionDataList3 ,
            "articleDataList4":this.state.articleDataList4 ,
            "sectionDataList4":this.state.sectionDataList4 ,
            "articleDataList5":this.state.articleDataList5 ,
            "sectionDataList5":this.state.sectionDataList5 ,

        }

        console.log(data)
        
        const addParams = {
          "_collection" : "AWOL1stNotice",
          "doc_data"    : data
        }

        console.log(addParams)
            
          
        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/add", addParams)
        //.post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            console.log("success")
            console.log(res.data)
            this.setState({
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Data had successfully Saved" ,
                fade            :   true,
                disabledGenerate  :   false,
                disabledSave  :   true
            })   
            this.saveAWOLMonitoring()   
            //this.getIRobjID()        
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    saveAWOLMonitoring=()=>{

        let createddate     = moment(this.state.createddate).format('MM/DD/YYYY');
        let _dateReported   = moment(this.state.dateReported).format('MM/DD/YYYY');
        let _dateOfIncident = moment(this.state.dateOfIncident).format('MM/DD/YYYY');
        let reIRDate        = moment(new Date()).format('MMDDYYYY');
        let datetmp         = moment(this.state.dateApplied).format('MM/DD/YYYY');
        let newIRRef        = "PHR" + this.state.selectedEmployeeNo + reIRDate

        let data = {

            "referenceNoAWOL"        :   newIRRef,
            "employeeName"          :   this.state.selectedEmployeeName,
            "employeeNo"            :   this.state.selectedEmployeeNo,
            "client"                :   this.state.selectedClientName,
            "ClientId"              :   this.state.selectedClientId,
            "location"              :   this.state.selectedEmployeeBranch,
            "address"               :   this.state.address,
            "position"              :   this.state.position,
            "lastDayOfDuty"         :   this.state.lastDayOfDuty,
            "awol1stNoticeDate"     :   datetmp,
            "awol2ndNoticeDate"     :   "",
            "awolNOHDate"           :   "",
            "awolTerminationDate"   :   "",
            "terminationDate"       :   "",
            "statusDocument"        :   "AWOL1ST",
            "status"                :   "pending",
                   
            "isDeleted"             :   "0",
            "isDraft"               :   "0",
            "createdby"             :   this.state.userinfo.userId,
            "createddate"           :   createddate,
            'isModified'            :   "",
            'modifiedby'            :   "",
            'modifieddate'          :   "",
          
           
        }       
        
        const addParams = {
          "_collection" : "AWOLMonitoring",
          "doc_data"    : data
        }
        console.log(addParams)
          
        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/add", addParams)
        //.post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            console.log("success")
            console.log(data)

        })
        .catch(error =>{
            console.log("error: " + error)
        })
    }

    handleChangeParagraph=(e)=>{

        this.setState({
            paragraph   : e.target.value
        })

        //console.log(this.state.paragraph)
    }

    handleAddedParagraph1=(e)=>{

        if(this.state.addedparagraph5Check == true || this.state.addedparagraph5Check === true){
            
            this.setState({
                addedparagraph6Check:true
            })
        }

        if(this.state.addedparagraph4Check == true || this.state.addedparagraph4Check === true){
            
            this.setState({
                addedparagraph5Check:true
            })
        }

        if(this.state.addedparagraph3Check == true || this.state.addedparagraph3Check === true){
            
            this.setState({
                addedparagraph4Check:true
            })
        }

        if( this.state.addedparagraph2Check == true || this.state.addedparagraph2Check === true ){
            
            this.setState({
                addedparagraph3Check:true
            })
        }

        if(this.state.addedparagraph1Check == true || this.state.addedparagraph1Check === true){
            
            this.setState({
                addedparagraph2Check:true
            })

        }        
        
        this.setState({
            addedparagraph1Check:true
        })

        console.log("addedparagraph1Check start")
        console.log(this.state.addedparagraph1Check)
        console.log("addedparagraph1Check")

    }

    handleChangeAddedParagraph1=(e)=>{

        this.setState({
            addedparagraph1   : e.target.value
        })

        //console.log(this.state.addedparagraph1)

    }

    handleAddedParagraph2=(e)=>{
        this.setState({
            addedparagraph2Check:true
        })
    }

    handleChangeAddedParagraph2=(e)=>{

        this.setState({
            addedparagraph2   : e.target.value
        })
        //console.log(this.state.addedparagraph2)
    }

    handleAddedParagraph3=(e)=>{

        this.setState({
            addedparagraph3Check:true
        })
    }

    handleChangeAddedParagraph3=(e)=>{
        
        this.setState({
            addedparagraph3   : e.target.value
        })
        //console.log(this.state.addedparagraph3)
    }

    handleChangeAddedParagraph4=(e)=>{
        
        this.setState({
            addedparagraph4   : e.target.value
        })
        //console.log(this.state.addedparagraph3)
    }

    handleChangeAddedParagraph5=(e)=>{
        
        this.setState({
            addedparagraph5   : e.target.value
        })
        //console.log(this.state.addedparagraph3)
    }

    handleChangeAddedParagraph6=(e)=>{
        
        this.setState({
            addedparagraph6   : e.target.value
        })
        //console.log(this.state.addedparagraph3)
    }

    handleQuotedStatementCheck=(e)=>{
        this.setState({
            quotedStatementCheck:true
        })
    }

    handleChangeQuotedStatement=(e)=>{
        this.setState({
            quotedStatement   : e.target.value
        })
        //console.log(this.state.quotedStatement)
    }

    handleOnChangePreparedBy=(e)=>{
        // this.setState({
        //     preparedBy   : e.target.value
        // })
        // //console.log(this.state.preparedBy)

        if(e.length==0)
        {
            this.state.preparedBy = ""
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.preparedBy = e[0].employeeName
        this.state.prepareByPosition = e[0].position

        this.setState({isloading:false})
    }

    handleOnChangeNotedBy=(e)=>{
        // this.setState({
        //     notedBy   : e.target.value
        // })
        // //console.log(this.state.notedBy)

        if(e.length==0)
        {
            this.state.notedBy = ""
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.notedBy = e[0].employeeName
        this.state.notedByPosition = e[0].position

        this.setState({isloading:false})
    }

    handleOnChangePrepareByPosition=(e)=>{
        this.setState({
            prepareByPosition   : e.target.value
        })
        //console.log(this.state.prepareByPosition)
    }

    handleOnChangenotedByPosition=(e)=>{
        this.setState({
            notedByPosition   : e.target.value
        })
        //console.log(this.state.prepareByPosition)
    }

    handleOnChangeReceivedBy=(e)=>{
        this.setState({
            receivedBy   : e.target.value
        })
        //console.log(this.state.receivedBy)
    }

    handleOnChangeIrDateIssued=(e)=>{
        this.setState({
            irDateIssued   : e.target.value
        })
        console.log(this.state.irDateIssued)
    }

    handleOnChangeDateRecieveByER=(e)=>{
        this.setState({
            dateRecieveByER   : e.target.value
        })
        console.log(this.state.dateRecieveByER)
    }

    handleOnChangeAddress=(e)=>{
        this.setState({
            address   : e.target.value
        })
        console.log(this.state.address)
    }

   /*  handleOnChangereferenceNoAWOL=(e)=>{
        this.setState({
            referenceNoAWOL   : e.target.value
        })
        console.log(this.state.referenceNoAWOL)
    } */

    getViewDraftMemo=()=>{
        
        let viewdataCOCRef= JSON.parse(sessionStorage.getItem("viewdataCOCRefTmp"))

        if(viewdataCOCRef == "1" || viewdataCOCRef === "1" ){
            let dataCOCRefarticle1Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefarticle1"))
            let dataCOCRefarticle2Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefarticle2"))
            let dataCOCRefarticle3Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefarticle3"))
            let dataCOCRefarticle4Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefarticle4"))
            let dataCOCRefarticle5Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefarticle5"))
            let dataCOCRefsection1Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefsection1"))
            let dataCOCRefsection2Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefsection2"))
            let dataCOCRefsection3Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefsection3"))
            let dataCOCRefsection4Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefsection4"))
            let dataCOCRefsection5Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefsection5"))

            this.setState({
                articleDataList:dataCOCRefarticle1Tmp,
                sectionDataList:dataCOCRefsection1Tmp,

                articleDataList2:dataCOCRefarticle2Tmp,
                sectionDataList2:dataCOCRefsection2Tmp,

                articleDataList3:dataCOCRefarticle3Tmp,
                sectionDataList3:dataCOCRefsection3Tmp,

                articleDataList4:dataCOCRefarticle4Tmp,
                sectionDataList4:dataCOCRefsection4Tmp,

                articleDataList5:dataCOCRefarticle5Tmp,
                sectionDataList5:dataCOCRefsection5Tmp,
               

                cocReferences: true
            })
          
        }else{

            this.setState({
                cocReferences: false
            })

        }
    }

    handleToExportNTE=()=>{

        if(this.state.eSignatureNotedBy == ""){
           
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please insert e-signature",
                fade            :   true
            })
            return
            
        }

        if(this.state.eSignaturePreparedBy == ""){
          
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please insert e-signature",
                fade            :   true
            })
            return
          
        }

        if(this.state.eSignatureNotedBy == null){
           
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please insert e-signature",
                fade            :   true
            })
            return
            
        }

        if(this.state.eSignaturePreparedBy == null){
          
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please insert e-signature",
                fade            :   true
            })
            return
          
        } 

        if(this.state.eSignatureNotedBy == undefined){
           
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please insert e-signature",
                fade            :   true
            })
            return
            
        }

        if(this.state.eSignaturePreparedBy == undefined){
          
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please insert e-signature",
                fade            :   true
            })
            return
          
        }
       
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');
        let data = {
            "client"                :   this.state.selectedClientName,
            "employeeName"          :   this.state.selectedEmployeeName,
            "position"              :   this.state.position,
            "employeeNo"            :   this.state.selectedEmployeeNo,
            "location"              :   this.state.selectedEmployeeBranch,
            "date"                  :   this.state.dateApplied,
            "referenceNoAWOL"        :   this.state.referenceNoAWOL,
            "irReferenceNo"         :   this.state.irReferenceNo,
            "irDateIssued"          :   this.state.irDateIssued,
            "dateRecieveByER"       :   this.state.dateRecieveByER,
            "paragraph"             :   this.state.paragraph,
            "addedparagraph1"       :   this.state.addedparagraph1,
            "addedparagraph2"       :   this.state.addedparagraph2,
            "addedparagraph3"       :   this.state.addedparagraph3,
            "addedparagraph4"       :   this.state.addedparagraph4,
            "addedparagraph5"       :   this.state.addedparagraph5,
            "addedparagraph6"       :   this.state.addedparagraph6,
            "quotedStatement"       :   this.state.quotedStatement,
            

            "preparedBy"             :   this.state.preparedBy,
            "prepareByPosition"      :   this.state.prepareByPosition,
            "notedBy"                :   this.state.notedBy,
            "notedByPosition"        :   this.state.notedByPosition,
            "receivedBy"             :   this.state.receivedBy,
            "eSignatureNotedBy"      :   this.state.eSignatureNotedBy,
            "eSignaturePreparedBy"   :   this.state.eSignaturePreparedBy,
            "address"                :   this.state.address,     

            "isDraft"               :   "0",
            "isDeleted"             :   "0",
            "createdby"             :   this.state.userinfo.userId,
            "createddate"           :   createddate,
            "modifiedby"            :   'user007',
            "modifieddate"          :   '0',
            "isModified"            :   '0',   

            "articleDataList":this.state.articleDataList, 
            "sectionDataList":this.state.sectionDataList ,
            "articleDataList2":this.state.articleDataList2 ,
            "sectionDataList2":this.state.sectionDataList2 ,
            "articleDataList3":this.state.articleDataList3 ,
            "sectionDataList3":this.state.sectionDataList3 ,
            "articleDataList4":this.state.articleDataList4 ,
            "sectionDataList4":this.state.sectionDataList4 ,
            "articleDataList5":this.state.articleDataList5 ,
            "sectionDataList5":this.state.sectionDataList5 ,

        }

        console.log(data)

        sessionStorage.setItem("data", JSON.stringify(data))
        let viewDraftMemoTmp = "1"
        sessionStorage.setItem("viewDraftMemoTmp", JSON.stringify(viewDraftMemoTmp))


        window.location.href='/ExportAwol1stNotice'

        /*if(this.state.selectedmemoType == "Late & Absenteeism"){
            window.location.href='/ExportNTE'
        }
         if(this.state.selectedmemoType == "AWOL 1ST Notice"){
            window.location.href='/ExportAwol1stNotice'
        }
        if(this.state.selectedmemoType == "AWOL 2ND Notice"){
            window.location.href='/ExportAwol2ndNotice'
        }
        if(this.state.selectedmemoType == "Notice of Hearing"){
            window.location.href='/ExportNOH'
        }
        if(this.state.selectedmemoType == "Recall"){
            window.location.href='/ExportRecall'
        }
        if(this.state.selectedmemoType == "Guarantor Demand Letter"){
            window.location.href='/ExportGuarantorDemandLetter'
        } */

    }

    handleOnChangeeSignatureNotedBy=(e)=>{

        const reader = new FileReader();
        reader.onload=()=>{
            if(reader.readyState === 2){
                this.setState({
                    eSignatureNotedBy:reader.result
                })
            }
        }
        reader.readAsDataURL(e.target.files[0])
    }

    

    handleOnChangeeSignaturePreparedBy=(e)=>{
 
        const reader = new FileReader();
        reader.onload=()=>{
            if(reader.readyState === 2){
                this.setState({
                    eSignaturePreparedBy:reader.result
                })
            }
        }
        reader.readAsDataURL(e.target.files[0])
    }

    onChangememoType = (e) => {       

        if(e.length==0)
        {
            this.setState({selectedmemoType: "" })          
            return
        }

        this.state.selectedmemoType = e[0].name

    }

    onChangeIrRefNo=(e)=>{
        this.setState({
            irReferenceNo:e.target.value
        })
    }

    setBack=()=>{
        let whenBack = 0
        sessionStorage.setItem("nextProcessCheck", JSON.stringify(whenBack)) 

    }

    render() {      
        const sectionColumn = [
            {

                dataField   : 'sectionNo',
                text        : 'sectionNo',
                editable    : true,
                editor: {
                    type: Type.SELECT,
                    options: this.state.sectionList,
                   
                },
                headerStyle : () => {
                    return { width  : "50%" };
                },
                style:{whiteSpace: 'nowrap', }
                

            },
            {

                dataField   : 'infraction',
                text        : 'infraction',
                editable    : false,
                headerStyle : () => {
                    return { width  : "50%" };
                },
                
                style:{whiteSpace: 'nowrap', }

            },
            {

                dataField   : 'category',
                text        : 'category',
                editable    : false,
              
                headerStyle : () => {
                    return { width  : "50%" };
                },
                style:{ whiteSpace: 'nowrap', }

            },
           
        ]
        const articleColumn = [
            {

                dataField   : 'articleNo',
                text        : 'Article No',
                editable    : true,
                editor: {
                    type: Type.SELECT,
                    options: this.state.articleList,
                   
                },
                headerStyle : () => {
                    return { width  : "50%" };
                },
                style:{whiteSpace: 'nowrap', }
                

            },
            {

                dataField   : 'articleName',
                text        : 'Article Nane',
                editable    : false,
                headerStyle : () => {
                    return { width  : "50%" };
                },
                
                style:{ whiteSpace: 'nowrap', }

            },
           
        ]

        return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            
                            <Card.Header>AWOL 1st Notice</Card.Header>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Card.Body>
                                <Card.Header className="mt-2">Employee Details</Card.Header>
                                <Form>
                                    
                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Client
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClientList}
                                                options={this.state.getClientList}
                                                selected={[this.state.selectedClientName]}
                                                disabled={this.state.disableEmployee}
                                                //placeholder={this.state.clientPlaceHolder}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee
                                        </Form.Label>
                                        <Col sm="7">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.onChangeEmployeesList}
                                                options={this.state.getEmployeeList}
                                                //selected={[this.state.selectedEmployeeName]}
                                                disabled={this.state.disableEmployee}
                                                placeholder={this.state.employeePlaceHolder}
                                            />
                                        </Col>

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee No.
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeNo" 
                                                value={this.state.selectedEmployeeNo}
                                                onChange={this.onChangeEmployeesNo} 
                                                autoComplete="off"
                                                placeholder="Autofill"
                                                disabled={this.state.disableEmployee}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text"
                                                name="position" 
                                                value={this.state.position}
                                                onChange={this.onChangePosition} 
                                                autoComplete="off"
                                                placeholder="Autofill"
                                                disabled={this.state.disableEmployee}
                                            />
                                        </Col>
                                        <Col sm="2"></Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Location
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text"
                                                name="selectedLocation"
                                                value={this.state.selectedEmployeeBranch}
                                                onChange={this.onChangeLocation} 
                                                autoComplete="off"
                                                placeholder="Auto Fill"
                                                disabled={this.state.disableEmployee}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row}>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Date
                                        </Form.Label>
                                        <Col  sm="2" style={{marginLeft : "-1px"}}>
                                            <DatePicker
                                                ref='dateApplied'
                                                selected={this.state.dateApplied}
                                                onChange={this.handleChangeDate}
                                                minDate={this.minDate}
                                                value={this.props.dateApplied}
                                                dateFormat={"MM/dd/yyyy"}
                                                /* placeholderText="DATE APPLIED" */
                                                className="form-control"
                                            />
                                        </Col>


                                        <Form.Label row sm="2" style={{fontWeight : "bold",}}>
                                            AWOL Reference No.
                                        </Form.Label>
                                        
                                        <Col sm="2" style={{marginLeft : "73px"}}>

                                            <Input
                                                labelKey='name'
                                                id="basic-example"
                                                //value={this.state.dateReported}
                                                className="form-control"
                                                //onChange={this.handleOnChangereferenceNoAWOL}
                                                autoComplete="off"
                                                value={this.state.referenceNoAWOL}
                                                placeholder="AUTO GENERATE"
                                                disabled={true}
                                                
                                            />

                                        </Col>

                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            address
                                        </Form.Label>
                                        <Col sm="6">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.address}
                                                onChange={this.handleOnChangeAddress} 
                                                autoComplete="off"
                                                //placeholder="Enter Company"
                                            />
                                        </Col>

                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Last Day Of Duty
                                        </Form.Label>
                                        <Col  sm="2" style={{marginLeft : "-30px"}}>
                                            <DatePicker
                                                ref='lastDayOfDuty'
                                                selected={this.state.lastDayOfDuty}
                                                onChange={this.handleChangelastDayOfDuty}
                                                minDate={this.minDate}
                                                value={this.props.lastDayOfDuty}
                                                dateFormat={"MM/dd/yyyy"}
                                                /* placeholderText="DATE APPLIED" */
                                                className="form-control"
                                            />
                                        </Col>

                                    </Form.Group>
                                    
                                    <Form.Group as={Row} controlId="formPlaintextEmail"> </Form.Group>
                                    
                                    <Card.Header className="mt-3">Memorandum Details</Card.Header>
                                    
                                    <div className="mt-1">

                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                            <Col>
                                                <FormControl className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChangeParagraph} rows="3"/>
                                                
                                            </Col>                    
                                        </Form.Group>

                                        { this.state.quotedStatementCheck ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl as="textarea" aria-label="With textarea" placeHolder="(Enter Quoted Statement)" onChange={this.handleChangeQuotedStatement} style={{fontWeight : "bold"}}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        }
                                        { this.state.addedparagraph1Check ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChangeAddedParagraph1}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        }
                                        { this.state.cocReferences ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Form.Label column sm="1" style={{fontWeight : "bold" , fontSize:15}}>
                                                    COC DETAILS
                                                </Form.Label> 
                                            </Form.Group>:null
                                        } 
                                        {  this.state.articleDataList.length > 0  ?
                                            <Card.Body as={Row} controlId="formPlaintextEmail" className="mt-1">
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.articleDataList }
                                                    columns = { articleColumn }
                                                    striped
                                                    hover
                                                    condensed

                                                />
                                            </Card.Body>:null
                                        } 
                                        { this.state.sectionDataList.length > 0  ?
                                            <Card.Body as={Row} controlId="formPlaintextEmail" style={{marginTop:"-30px"}}>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.sectionDataList }
                                                    columns = { sectionColumn }
                                                    striped
                                                    hover
                                                    condensed

                                                />
                                            </Card.Body>:null
                                        } 
                                        
                                        { this.state.articleDataList2.length > 0 ?
                                            <Card.Body as={Row} controlId="formPlaintextEmail" className="mt-1">
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.articleDataList2 }
                                                    columns = { articleColumn }
                                                    striped
                                                    hover
                                                    condensed

                                                />
                                            </Card.Body>:null
                                        } 
                                        { this.state.sectionDataList2.length > 0  ?
                                            <Card.Body as={Row} controlId="formPlaintextEmail" style={{marginTop:"-30px"}} >
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.sectionDataList2 }
                                                    columns = { sectionColumn }
                                                    striped
                                                    hover
                                                    condensed

                                                />
                                            </Card.Body>:null
                                        } 
                                        { this.state.articleDataList3.length > 0 ?
                                            <Card.Body as={Row} controlId="formPlaintextEmail" className="mt-1">
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.articleDataList3 }
                                                    columns = { articleColumn }
                                                    striped
                                                    hover
                                                    condensed

                                                />
                                            </Card.Body>:null
                                        } 
                                        { this.state.sectionDataList3.length > 0  ?
                                            <Card.Body as={Row} controlId="formPlaintextEmail"  style={{marginTop:"-30px"}}>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.sectionDataList3 }
                                                    columns = { sectionColumn }
                                                    striped
                                                    hover
                                                    condensed

                                                />
                                            </Card.Body>:null
                                        } 
                                        { this.state.articleDataList4.length > 0 ?
                                            <Card.Body as={Row} controlId="formPlaintextEmail" className="mt-1">
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.articleDataList4 }
                                                    columns = { articleColumn }
                                                    striped
                                                    hover
                                                    condensed

                                                />
                                            </Card.Body>:null
                                        } 
                                        { this.state.sectionDataList4.length > 0  ?
                                            <Card.Body as={Row} controlId="formPlaintextEmail"  style={{marginTop:"-30px"}}>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.sectionDataList4 }
                                                    columns = { sectionColumn }
                                                    striped
                                                    hover
                                                    condensed

                                                />
                                            </Card.Body>:null
                                        } 
                                        
                                        { this.state.articleDataList5.length > 0 ?
                                            <Card.Body as={Row} controlId="formPlaintextEmail" className="mt-1">
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.articleDataList5 }
                                                    columns = { articleColumn }
                                                    striped
                                                    hover
                                                    condensed

                                                />
                                            </Card.Body>:null
                                        } 
                                        { this.state.sectionDataList5.length > 0  ?
                                            <Card.Body as={Row} controlId="formPlaintextEmail" style={{marginTop:"-30px"}} >
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.sectionDataList5 }
                                                    columns = { sectionColumn }
                                                    striped
                                                    hover
                                                    condensed

                                                />
                                            </Card.Body>:null
                                        } 
                                        
                                        
                                        { this.state.addedparagraph2Check ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChangeAddedParagraph2}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        }  

                                        { this.state.addedparagraph3Check ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChangeAddedParagraph3}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        } 

                                        { this.state.addedparagraph4Check ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChangeAddedParagraph4}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        }  

                                        { this.state.addedparagraph5Check ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChangeAddedParagraph5}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        }

                                        { this.state.addedparagraph6Check ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl className="no-text-transform-li" as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChangeAddedParagraph6}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        } 


                                        {/* <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">                                       
                                            <Col>
                                            
                                                {this.createUI()}  
                                            </Col>                                        
                                        </Form.Group>   */}                               
                                   
                                    </div>
                                    <ButtonToolbar sm={12} className="mt-4">
                                        <Col>                                            
                                            <Button variant="success" style={{minWidth:'280px'}} onClick={this.handleAddedParagraph1}>
                                                Add Paragraph
                                            </Button>                                            
                                        </Col>&nbsp;&nbsp;
                                        
                                        <Col>                                           
                                            <Button variant="success" style={{minWidth:'280px'}} onClick={this.handleQuotedStatementCheck}>
                                                Insert QuotedStatement
                                            </Button>                                           
                                        </Col>
                                        <Col>                                        
                                            <Button variant="success" style={{minWidth:'280px'}} onClick={this.showTrainingRegisterModal}>
                                                Insert COC Reference
                                            </Button>                                           
                                        </Col>
                                       {/*  <Col>
                                            <NavLink to="/home">
                                                <Button variant="success" href="/home" style={{minWidth:'280px'}}>
                                                    View Draft Memo
                                                </Button>
                                            </NavLink>
                                        </Col> */}
                                    </ButtonToolbar>

                                    {<Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Prepared By (Client) :
                                        </Form.Label>
                                        <Col sm="7" style={{marginLeft:"-125px"}}>
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangePreparedbyClientList}
                                                options={this.state.getClientList}
                                                //selected={[this.state.selectedClientName]}
                                                //disabled={this.state.disableEmployee}
                                                //placeholder={this.state.clientPlaceHolder}
                                            />
                                        </Col>
                                    </Form.Group>}
                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Prepared By:
                                        </Form.Label>
                                      {/*   <Col sm="3">
                                            <Input
                                                type="text"
                                                name="name" 
                                                className="form-control"
                                                value={this.state.preparedBy}
                                                onChange={this.handleOnChangePreparedBy} 
                                                autoComplete="off"
                                            />
                                        </Col> */}

                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.handleOnChangePreparedBy}
                                                options={this.state.getEmployeeList}
                                                //selected={[this.state.selectedEmployeeName]}
                                                //disabled={this.state.disableEmployee}
                                                //placeholder={this.state.employeePlaceHolder}
                                            />
                                        </Col>

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.prepareByPosition}
                                                onChange={this.handleOnChangePrepareByPosition} 
                                                autoComplete="off"
                                                //placeholder="Enter Company"
                                            />
                                        </Col>

                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Noted By:
                                        </Form.Label>
                                      {/*   <Col sm="3">
                                            <Input 
                                                type="text"
                                                name="name"
                                                value={this.state.notedBy}
                                                onChange={this.handleOnChangeNotedBy} 
                                                autoComplete="off"
                                                //placeholder="Enter Company"
                                            />
                                        </Col> */}

                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.handleOnChangeNotedBy}
                                                options={this.state.getEmployeeList}
                                                //selected={[this.state.selectedEmployeeName]}
                                                //disabled={this.state.disableEmployee}
                                                //placeholder={this.state.employeePlaceHolder}
                                            />
                                        </Col>

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.notedByPosition}
                                                onChange={this.handleOnChangenotedByPosition} 
                                                autoComplete="off"
                                                //placeholder="Enter Company"
                                            />
                                        </Col>
                                    </Form.Group>


                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Attached eSignaturePreparedBy (Prepared By):
                                        </Form.Label>
                                        <Col sm="2" >                                                 
                                            <Input type="file" className="file" placeholder="eSignaturePreparedBy" variant="primary" accept="image"  onChange={this.handleOnChangeeSignaturePreparedBy}/>                                          
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Attached eSignatureNotedBy (Noted By):
                                        </Form.Label>
                                        <Col sm="2" >                                                 
                                            <Input type="file" className="file" placeholder="eSignatureNotedBy" variant="primary" accept="image"  onChange={this.handleOnChangeeSignatureNotedBy}/>                                          
                                        </Col>
                                    </Form.Group>

                                   {/*  <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Received By:
                                        </Form.Label>
                                        <Col sm="5">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.receivedBy}
                                                onChange={this.handleOnChangeReceivedBy} 
                                                autoComplete="off"
                                                //placeholder="Enter Company"
                                            />
                                        </Col>
                                    </Form.Group> */}

                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                        <Col  sm={12}>
                                            <ButtonToolbar className="mt-5">
                                                <Button disabled={this.state.disabledSave} /* className="ml-auto" */ variant="success" onClick = { this.getNTErefNo } style={{minWidth:'60px'}}>Save</Button>&nbsp;&nbsp;&nbsp;
                                                <Button  href="/AwolMontoring" variant="danger" style={{minWidth:'60px'}} onClick={this.setBack}>Back</Button>
                                               {/*  <NavLink disabled={this.state.disabledGenerate} to="/ExportNTE"  className="ml-auto"> */}
                                                    <Button /* disabled={this.state.disabledGenerate} */ variant="success" onClick={this.handleToExportNTE} /* href="/ExportNTE" */ style={{minHeight:"18px",minWidth:'60px', marginLeft:"1200px", marginTop:"-1px"}}>Generate AWOL 1st Memo</Button>&nbsp;&nbsp;
                                                {/* </NavLink> */}
                                            </ButtonToolbar>                                          
                                        </Col>                
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                   { <InsertCOCReference 
                        show={this.state.modalCoCReferenceShow}
                        onHide={this.handleModalClose}
                    />}
            </div> 
        )
    }

}

export  default Awol1stNotice
