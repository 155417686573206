import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, useState, Tabs, Tab
} 
from '../../noser-hris-component';
import "./card.css"
import { BsBell, BsFillArchiveFill } from 'react-icons/bs';


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            name            :   "",
            
            isShow:false,
            Message1:"",
            Message2:"",
            employeeDDL:[],
            employeeList:[],
            userinfo:[],

        }
    }
    
    componentDidMount(){
        const userinfo = JSON.parse(sessionStorage.getItem("userData"))
        sessionStorage.setItem("employeeapprovalFilter_"  + userinfo.userId + userinfo.employeeId, null)
        //this.GetEmployeeList()
        this.GetEmployeeInformation()
        this.wdata()
       
    };

    wdata=()=>{
        this.setState({
            isShow : true,
            Message1 : "Notification",
            Message2 : "Maternity Leave is about to End"
        })
    };

    // GetEmployeeList = async() =>{
    //     console.log("1")
    //     const userinfo = JSON.parse(sessionStorage.getItem("userData"))
    //     this.setState({isloading:true})
    //     console.log("GetEmployeeList")
    //    // console.log(userinfo[0].userId)
        
    //     const params = {
    //         "IpAddress":"0.0.0.0",
    //         "ClientId":"",
    //         "UserId":userinfo.userId,
    //         "EmployeeId":"",//this.state.userinfo.roleId==="1" || 
    //                       //this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
    //     }
    //     await axios
    //     .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter", params)
    //     .then(res => {
    //         const data = res.data;
    //         console.log("data")
    //         console.log(data)
    //         console.log("data.profiles")
    //         console.log(data.profiles)
    //         this.setState({employeeDDL : data.profiles, employeeList : data.profiles, isloading:false})
    //         let arr = []
    //         // if(this.state.userinfo.roleId!=="1"){
    //         //     let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
    //         //     if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
    //         //         let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
    //         //         if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
    //         //             let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
    //         //             //console.log("brr")
    //         //             //console.log(brr)
    //         //             arr = data.profiles.filter(f => brr.some(item => item.referenceId === f.clientId))
    //         //         }
    //         //     }
    //         //     this.setState({employeeDDL : arr,employeeList : arr})
    //         // }
    //         // else{
    //         //     this.setState({employeeDDL : data.profiles, employeeList : data.profiles})
    //         // }
    //         //this.setState({employeeDDL : data.profiles,employeeList : data.profiles})
    //         //this.GetNationalities()
    //     })
    //     .catch(error=>{
    //         this.setState({
    //             isloading   :   false,
    //             alerttype   :   "Error!",
    //             isshow      :   true,
    //             color       :   "danger",
    //             message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
    //             fade        :   true
    //         })
    //     })
    // };

    GetEmployeeInformation = async() =>{
        const userinfo = JSON.parse(sessionStorage.getItem("userData"))
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":"",
            "UserId":userinfo.userId,
            "EmployeeId":"",
        }
        console.log(params)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeInformation", params)
        .then(res => {
            const data = res.data;
            console.log("3 ")
            console.log(data)
            if(data.status==="1"){

                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))

                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){

                    if(fieldaccess.filter(x=>x.accessCode==="0001" && x.fieldName==="SalaryRate" ).length>0){
                        let access = fieldaccess.find(x=>x.accessCode==="0001" && x.fieldName==="SalaryRate")
                        if(access.controlAccessDetails.filter(x=>x.isClient==="1" && x.referenceId===data.clientId).length>0){
                            this.state.hasEditSalaryAccess = access.hasEdit==="1" ? false : true
                            this.state.hasViewSalaryAccess = access.hasView==="1" ? true : false
                        }
                        if(access.controlAccessDetails.filter(x=>x.isClient==="0" && x.referenceId===data.positionId).length>0){
                            this.state.hasEditSalaryAccess = access.hasEdit==="1" ? false : true
                            this.state.hasViewSalaryAccess = access.hasView==="1" ? true : false
                        }
                    }

                    if(fieldaccess.filter(x=>x.accessCode==="0001" && x.fieldName==="PayCardType" ).length>0){
                        let access = fieldaccess.find(x=>x.accessCode==="0001" && x.fieldName==="PayCardType")
                        if(access.controlAccessDetails.filter(x=>x.isClient==="1" && x.referenceId===data.clientId).length>0){
                            this.state.hasEditPayCardTypeAccess = access.hasEdit==="1" ? false : true
                            this.state.hasViewPayCardTypeAccess = access.hasView==="1" ? true : false
                        }
                        if(access.controlAccessDetails.filter(x=>x.isClient==="0" && x.referenceId===data.positionId).length>0){
                            this.state.hasEditSalaryAccess = access.hasEdit==="1" ? false : true
                            this.state.hasViewSalaryAccess = access.hasView==="1" ? true : false
                        }
                    }

                    if(fieldaccess.filter(x=>x.accessCode==="0001" && x.fieldName==="PayCardNumber" ).length>0){
                        let access = fieldaccess.find(x=>x.accessCode==="0001" && x.fieldName==="PayCardNumber")
                        if(access.controlAccessDetails.filter(x=>x.isClient==="1" && x.referenceId===data.clientId).length>0){
                            this.state.hasEditPayCardNumberAccess = access.hasEdit==="1" ? false : true
                            this.state.hasViewPayCardNumberAccess = access.hasView==="1" ? true : false
                        }
                        if(access.controlAccessDetails.filter(x=>x.isClient==="0" && x.referenceId===data.positionId).length>0){
                            this.state.hasEditSalaryAccess = access.hasEdit==="1" ? false : true
                            this.state.hasViewSalaryAccess = access.hasView==="1" ? true : false
                        }
                    }
                }


                this.setState({
                    employeedetails : data,
                    employeeNo:data.employeeNo,
                    profileStatus:data.profileStatus,
                    firstName:data.firstName,
                    middleName:data.middleName,
                    lastName:data.lastName,
                    nickName:data.nickName,
                    homeNumber:data.homePhoneNumber,
                    officeNumber:"",//this.state.employeedetails.nickName,
                    mobileNumber:data.mobileNumber,
                    emailAddress:data.emailAddress,
                    facebook:data.facebook,
                    twitter:data.twitter,
                    dateofBirth:data.dateOfBirth !=="" ? new Date(data.dateOfBirth) : "",
                    placeofBirth:data.placeOfBirth,
                    height:data.height,
                    weight:data.weight,
                    languageSpoken:data.languageSpoken,
                    hobbies:data.hobbies,
                    contactName:data.emergencyContactName,
                    contactNumber:data.emergencyContactNumber,
                    contactRelation:data.emergencyContactRelation,
                    sssNumber:data.sssNumber,
                    tinNumber:data.tinNumber,
                    phicNumber:data.phicNumber,
                    hdmfNumber:data.hdmfNumber,
                    arrangementId:data.livingArrangementId,
                    arrangement:data.livingArrangement,
                    bloodtypeId:data.bloodTypeId,
                    bloodtype:data.bloodType,
                    nationalityId:data.nationalityId,
                    nationality:data.nationality,
                    religionId:data.religionId,
                    religion:data.religion,
                    genderId:data.genderId,
                    gender:data.gender,
                    civilstatusId: data.civilStatusId,
                    civilstatus:data.civilStatus,
        
                    locationId:data.locationId,
                    positionId:data.positionId,
                    employmentId:data.employmentStatusId,
                    cardtypeId:data.payCardTypeId,
                    paytypeId:data.payTypeId,
                    paymodeId:data.payModeId,
                    periodtypeId:data.periodTypeId,
                    scheduleId:data.workScheduleId,
                    salaryRate:data.salaryRate,
                    seaRate:data.seaRate,
                    ecolaRate:data.ecolaRate,
                    colaRate:data.colaRate,
                    isdefaultLocation:data.isDefaultLocation,
                    cardNumber:data.payCardNumber,
                    dateHired:data.dateHired !=="" ? new Date(data.dateHired) : "",
                    dateResigned:data.dateResigned !=="" ? new Date(data.dateResigned) : "",
                    contractStart:data.contractDateStart !=="" ? new Date(data.contractDateStart) : "",
                    contractEnd:data.contractDateEnd !=="" ? new Date(data.contractDateEnd) : "",
                    location:data.location,
                    position:data.position,
                    employment:data.employmentStatus,
                    cardtype:data.payCardType,
                    paytype:data.payType,
                    paymode:data.payMode,
                    periodtype:data.periodType,
                    schedule:data.workSchedule,
                    approver1Id:data.approverId1,
                    approver2Id:data.approverId2,
                    approver1:data.approver1,
                    approver2:data.approver2,
                    remarks:data.remarks,
                    mobileNumberEffectivityDate:"",
                    civilStatusEffectivityDate:"",
                    locationEffectivityDate:"",
                    positionEffectivityDate:"",
                    employmentStatusEffectivityDate:"",
                    dateResignedEffectivityDate:"",
                    contractDateEndEffectivityDate:"",
                    payCardTypeEffectivityDate:"",
                    payCardNumberEffectivityDate:"",
                    payTypeEffectivityDate:"",
                    payModeEffectivityDate:"",
                    workScheduleEffectivityDate:"",
                    periodTypeEffectivityDate:"",
                    salaryRateEffectivityDate:"",
                    seaRateEffectivityDate:"",
                    eColaRateEffectivityDate:"",
                    colaRateEffectivityDate:"",
                    approver1EffectivityDate:"",
                    approver2EffectivityDate:"",
                    periodtypeDDL:data.payModeId!=="" ? this.state.periodtypeList.filter(x=>x.payModeId===data.payModeId) : this.state.periodtypeList,

                    hmoId:data.hmoId,
                    cabinetId:data.cabinetId,
                    drawerId:data.drawerId,
                    hmo:data.hmo,
                    cabinet:data.cabinet,
                    drawer:data.drawer,

                    suffixName:data.suffixName,
                    sssNumberRemarks:data.sssNumberRemarks,
                    tinNumberRemarks:data.tinNumberRemarks,
                    phicNumberRemarks:data.phicNumberRemarks,
                    hdmfNumberRemarks:data.hdmfNumberRemarks,

                    paycardstatusId:data.payCardStatusId,
                    paycardStatus:data.payCardStatus,
                    hmoDate:data.hmoDate ==="" ? "" : new Date(data.hmoDate)
                })
                if(data.hmoId === "1"){
                    if(data.withATD === "1"){
                        this.setState({
                            isWithAtd : true
                        })
                    }else {
                        this.setState({
                            isWithAtd : false
                        })
                    }
                    this.setState({
                        disableWithHmmo : false,
                        hdmfNumberRemarks:data.hdmfNumberRemarks,
                        policyNumber:data.policyNumber,
                        cardNumberHmo:data.cardNumber,
                        assurance:data.hmoProvider,
                    })
                }else {
                    this.setState({
                        disableWithHmmo : true,
                    })
                }
                this.GetPayCardHistory()
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
       // this.GetEmployeeInformationDetails()
    }

    render() {
        return (
            <div >
                <Container fluid>
                    <Banner />
                        
                    <div className="noser-home">
                    {/* <span>Noser Hris © 2019-2020. All Rights Reserved</span> */}
                   
                    </div>

                   { <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                        <div className={this.state.color}></div> 
                        {this.state.message}
                    </Alert>}

                    <div isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isShow ? 'display-block' : 'display-none'}> 
                        <Row className='mt-4'>
                            <Card className='ml-auto green card-size border marg ' >
                            <div>
                                {/* <h3 className='mt-2 ml-2'> MANNING</h3> */}
                                <ButtonToolbar sm={12} className="mt-3">
                                    <h4 className='ml-2'>{this.state.Message1}</h4> <BsBell color='yellow' className='siz'/>
                                    <h5 className='ml-2'>{this.state.Message2}</h5>
                                    <NavLink className="ml-auto mb-3" to="/manageemployeeapprovals"  href="/manageemployeeapprovals">View
                                        {/*  <Button style={{minWidth:"60px",marginRight:"1pt"}}    href="/manageemployeeapprovals">
                                            Close
                                        </Button>
                                        <Nav.Link href="#link">Link</Nav.Link> */}
                                    </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;
                                    </ButtonToolbar>
                                </div>
                            </Card> &nbsp;&nbsp;&nbsp;&nbsp;
                        </Row>
                    </div>
                </Container>
                <NoserLoading show={this.state.isloading} />

            </div>
        );
    }
}

export default Home;
