import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Redirect
} 
from '../../noser-hris-component';
import type from 'react-bootstrap-table2-editor';

import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

class RequestMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            navigate : false,

            requestDDL : [
                {"name" : "TRAINING", "id" : "1"},
                {"name" : "RE TRAINING", "id" : "2"},
                {"name" : "ORIENTATION", "id" : "3"},
                {"name" : "RE ORIENTATION", "id" : "4"},
                {"name" : "PRE EXAM", "id" : "5"},
                {"name" : "POST EXAM", "id" : "6"},
                {"name" : "EXAM RETAKE", "id" : "7"},
                {"name" : "CHECK BACK", "id" : "8"},
                {"name" : "COMPLETED", "id" : "9"},
            ],
            statusDDL : [
                {"name" : "For Training Schedule", "id" : "1"},
                {"name" : "Training Registered", "id" : "2"},
                {"name" : "For Post Examination", "id" : "3"},
                {"name" : "For Evaluation", "id" : "4"},
                {"name" : "For Pre Examination", "id" : "7"},
                {"name" : "Evaluation Failed", "id" : "8"},
                {"name" : "Completed", "id" : "9"}
            ],
            positionDDL : [],
            clientDDL   : [],
            employeeDDL : [],
            trainingDDL : [],
            requestMonitoringGrid : [],

            clientId            : "",
            requestTypeId       : "",
            selectedPositionId  : "",
            profileId           : "",
            dDate               : "",
            statusId            : "1"
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetPosition();
    }

    onChangeRequest = (e) => {
        if(e.length == 0) {
            this.state.requestTypeId	=   ""
            return
        }
        this.state.requestTypeId	    =   e[0].id
    }

    GetPosition = async()=> {
        this.setState({isloading:true})
        const positionParams = {
            "IpAddress"     : "0.0.0.0",
            "ClientId"      : this.state.userinfo.clientId,
            "UserId"        : this.state.userinfo.userId,
            "SectionId"     : "",
            "DepartmentId"  : "",
            "Name"          : ""
        };
        await
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  positionParams)
        .then(res => {
            const data = res.data;
            this.setState({
                positionDDL : data.positions,
                isloading   : false
            });
        })
        this.GetClients();
    }
    
    handleChangePosition = (e) => {
        if(e.length == 0) {
            this.state.positionId     =   ""
            this.state.positionName   =   ""
            return
        }
        this.state.positionId     =   e[0].id
        this.state.positionName   =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    GetClients = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  param)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL:data.clients,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
        this.GetTrainings();
    }

    onChangeClient = (e) => {
        if(e.length == 0) {
            this.state.clientId=""
            return
        } 
        this.state.clientId = e[0].id

        this.setState({
            isshow : false,
        });
        this.GetTrainings();
        this.GetEmployee();
    }

    GetEmployee = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeList",  param)
        .then(res => {
            const data = res.data;
            ////console.log("Get EmployeeList");
            ////console.log(data);
            this.setState({employeeDDL:data.employees,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    onChangeEmployee = (e) => {
        if(e.length == 0) {
            this.state.profileId=""
            return
        } 
        this.state.profileId = e[0].profileId

        this.setState({
            isshow : false,
        });
    }

    GetTrainings = async()=> {
        this.setState({isloading:true})
        const param = {
            "IpAddress"   : "0.0.0.0",
            "UserId"      : this.state.userinfo.userId,
            "ClientId"    : this.state.clientId,
            "TrainingId"  : "",
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetTrainings",  param)
        .then(res => {
                const data = res.data;
                ////console.log("Get Trainings");
                ////console.log(data);
                this.setState({ trainingDDL:data.trainings, isloading : false })
                if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
                
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.OnloadData();
    }

    onChangeTraining = (e) => {

        if(e.length == 0) {
            this.state.trainingId	=   ""
            return
        }
        this.state.trainingId	    =   e[0].id
    }
    onChangeStatus = (e) => {

        if(e.length == 0) {
            this.state.statusId=""
            return
        }
        this.state.statusId=e[0].id
    }
    handleChangeDate = date => {
        this.setState({
            dDate: date,
            isshow:false,
        });

    }

    handleSearchClick = () => {
        this.OnloadData();
    }
    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    OnloadData = async() => {
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.clientId,
            "RequestTypeId" :   this.state.requestTypeId,
            "PositionId"    :   this.state.positionId,
            "ProfileId"     :   this.state.profileId,
            "TrainingId"    :   this.state.trainingId,
            "StatusId"      :   this.state.statusId,
            "DeploymentDate":   this.FormatDate(this.state.dDate),
        };
        ////console.log("params");
        ////console.log(params);
        await
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Training/GetTrainingRequestMonitorings",  params)
        .then(res => {
            const data = res.data;
            console.log("Get Request Monitorings");
            console.log(data);
            if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
            this.state.requestMonitoringGrid = []
            this.setState({ requestMonitoringGrid  : data.requestMonitorings,isloading   :   false });
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    onClickDataRow = (row) => {
        this.setState({
            navigate            :   true
        })
        
        sessionStorage.setItem("requestMonitoringDataRow", JSON.stringify(row))
    }

    onClickCancelPreExam = (row) => {
        if (window.confirm('Do you want to cancel ' + row.status + " ? ")) {
            const params = {
                "IpAddress"     :   "0.0.0.0",
                "UserId"        :   this.state.userinfo.userId,
                "ClientId"      :   row.clientId,
                "Id"            :   row.id,
                "StatusId"      :   "2",
            };
            //console.log("params");
            //console.log(params);
            
            axios.post(AppConfiguration.Setting().noserapiendpoint + "Training/CancelEmployeeExam",  params)
            .then(res => {
                const data = res.data;
                ////console.log("Cancel Employee Exam");
                ////console.log(data);
                if(data.status=="1"){
                    this.OnloadData();
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true,
                    });
                
                }
                else {
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true,
                    });
                }
            })
            .catch(error=>{
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })

        }
    }

    onClickCancelPost = (row) => {
        if (window.confirm('Do you want to cancel ' + row.status + " ? ")) {
            const params = {
                "IpAddress"     :   "0.0.0.0",
                "UserId"        :   this.state.userinfo.userId,
                "ClientId"      :   row.clientId,
                "Id"            :   row.id,
                "StatusId"      :   "4",
            };
            //console.log("params");
            //console.log(params);
            
            axios.post(AppConfiguration.Setting().noserapiendpoint + "Training/CancelEmployeeExam",  params)
            .then(res => {
                const data = res.data;
                ////console.log("Cancel Employee Exam");
                ////console.log(data);
                if(data.status=="1"){
                    this.OnloadData();
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true,
                    });
                
                }
                else {
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true,
                    });
                }
            })
            .catch(error=>{
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })

        } else {
            
        }
        
     
    }
    CancelTraining = (row) => {
        if (window.confirm('Do you want to cancel employee ' + row.requestType + ' for ' + row.employeeName + " ? ")) {
            const params = {
                "IpAddress"     :   "0.0.0.0",
                "UserId"        :   this.state.userinfo.userId,
                "ClientId"      :   row.clientId,
                "Id"            :   row.id,
                "StatusId"      :   "2",
            };
            //console.log("params");
            //console.log(params);
            
            axios.post(AppConfiguration.Setting().noserapiendpoint + "Training/CancelEmployeeTraining",  params)
            .then(res => {
                const data = res.data;
                ////console.log("Cancel Employee Exam");
                ////console.log(data);
                if(data.status=="1"){
                    this.OnloadData();
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true,
                    });
                
                }
                else {
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true,
                    });
                }
            })
            .catch(error=>{
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })

        }
    }
    render() {
        const { navigate } = this.state;
        if (navigate) {
            return <Redirect to="/trainingscheduleregistration" push={true} />;
        } 

        const requestMonitoringColumn = [
            {
                text	    : 'Date',
                editable    :   false,
                dataField   :   "submittedDate",
                headerStyle: (colum, colIndex) => {
                    return { width:'5%', textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                text	    : 'Request',
                editable    :   false,
                dataField   :   "requestType",
                headerStyle: (colum, colIndex) => {
                    return { width:'10%', textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                text	    : 'Status',
                editable    :   false,
                dataField   :   "status",
                headerStyle: (colum, colIndex) => {
                    return { width:'10%', textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                text	    : 'Requested BY',
                editable    :   false,
                dataField   :   "requestedBy",
                headerStyle: (colum, colIndex) => {
                    return { width:'8%', textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                text	    : 'Client',
                editable    :   false,
                dataField   :   "client",
                headerStyle: (colum, colIndex) => {
                    return { width:'18%', textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                text	    : 'Employee',
                editable    :   false,
                dataField   :   "employeeName",
                headerStyle: (colum, colIndex) => {
                    return { width:'10%', textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                text	    : 'Employee No.',
                editable    :   false,
                dataField   :   "employeeNo",
                headerStyle: (colum, colIndex) => {
                    return { width:'8%', textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                text	    : 'Position',
                editable    :   false,
                dataField   :   "position",
                headerStyle: (colum, colIndex) => {
                    return { width:'10%', textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                text	    : 'Training',
                editable    :   false,
                dataField   :   "training",
                headerStyle: (colum, colIndex) => {
                    return { width:'10%', textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                text	    : 'Deployment Date',
                editable    :   false,
                dataField   :   "deploymentDate",
                headerStyle: (colum, colIndex) => {
                    return { width:'6%', textAlign: 'center',whiteSpace:'nowrap'}},
                style:{textAlign:'center',whiteSpace:'nowrap'}
            },
            /* {
                text	    : 'Available Schedule',
                editable    :   false,
                dataField   :   "scheduleName",
                headerStyle : () => {
                    return { width  : "7%" };
                }
            }, */
            {
                text	    : 'Training Session',
                editable    :   false,
                dataField   :   "sessionName",
                headerStyle: (colum, colIndex) => {
                    return { width:'6%', textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                text	    : 'Batch No.',
                editable    :   false,
                dataField   :   "batchNo",
                headerStyle: (colum, colIndex) => {
                    return { width:'6%', textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                dataField   : 'action',
                text        : 'Action',
                headerStyle: (colum, colIndex) => {
                    return { width:'7%', textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'},
                formatter   :   (cell, row, isSelect) => {
                    this.state.employeeName = row.employeeName
                    if (row.statusId === "1")
                    return (
                    <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                        variant="link" onClick={e => this.onClickDataRow(row)}
                    >Create Schedule</Button>
                        
                    );
                    if (row.statusId === "7")
                    return (
                    <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                        variant="link" onClick={e => this.onClickCancelPreExam(row)}
                    >Cancel</Button>
                        
                    );
                    if (row.statusId === "3")
                    return (
                    <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                        variant="link" onClick={e => this.onClickCancelPost(row)}
                    >Cancel</Button>
                        
                    );
                },
            },
            {
                dataField   : 'action',
                text        : 'Action',
                headerStyle: (colum, colIndex) => {
                    return { width:'7%', textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'},
                formatter   :   (cell, row, isSelect) => {
                    this.state.employeeName = row.employeeName
                    if (row.statusId === "1")
                    return (
                    <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                        variant="link" onClick={e => this.CancelTraining(row)}
                    >Remove</Button>
                        
                    );
                },
            },
        ] 

            const requestMonitoringSelectRow = {
                mode: 'checkbox',
                clickToSelectAndEditCell: true,
                onSelect: (row, isSelect, rowIndex, e) => {
                    this.state.requestMonitoringGrid.map(function(item,i){
                        if(item.id===row.id)
                        {
                            item.isDeleted = isSelect ? "1" : "0"
                            item.isModified = isSelect ? "1" : "0"
                        }
                    })
                }
            };
    
            const requestMonitoringRowEvents = {
                onClick: (e, row, rowIndex) => {
                }
            };

        return(
            <div>

            <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header>TRAINING >> REQUEST MONITORING</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        REQUEST TYPE
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeRequest}
                                            options={this.state.requestDDL}
                                    />
                                    </Col>
                                </Form.Group> 
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label style={{fontWeight : "bold"}} column sm="1">
                                POSITION
                                </Form.Label>
                                <Col sm="11">
                                    <Typeahead
                                        ref='clearPosition'
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangePosition}
                                        options={this.state.positionDDL}
                                    />
                                </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CLIENT
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeClient}
                                            options={this.state.clientDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EMPLOYEE
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.onChangeEmployee }
                                            options={this.state.employeeDDL}
                                        /> 
                                    </Col>
                                </Form.Group> 
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        TRAINING
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeTraining }
                                            options={this.state.trainingDDL}
                                        /> 
                                    </Col>
                                </Form.Group> 
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        STATUS
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeStatus }
                                            options={this.state.statusDDL}
                                        /> 
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        DEPLOYMENT DATE
                                    </Form.Label>
                                    <Col sm="11">
                                        <DatePicker
                                            ref='dDate'
                                            selected={this.state.dDate}
                                            onChange={this.handleChangeDate}
                                            minDate={this.minDate}
                                            value={this.props.dDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                            
                                        />
                                    </Col>
                                </Form.Group> 

                                <ButtonToolbar sm={12}>
                                    <Button variant="success" className="ml-auto" onClick = { this.handleSearchClick }>
                                        SEARCH
                                    </Button>
                                </ButtonToolbar>
                                <div className="mt-5">  
                                    <div>
                                        <Card.Header>REQUEST LIST</Card.Header>
                                        <BootstrapTable
                                            /* caption={Noser.TableHeader({title:"RECORD"})} */
                                            keyField = "id"
                                            data = { this.state.requestMonitoringGrid }
                                            columns = { requestMonitoringColumn }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            rowClasses="noser-table-row-class no-checkbox"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            selectRow = { requestMonitoringSelectRow }
                                            rowEvents={ requestMonitoringRowEvents }
                    
                                        />
                                    </div>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div> 
            
        )
    }
}

export default RequestMonitoring;