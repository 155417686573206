import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';

import { Input  } from 'reactstrap';
import { DropdownButton, Dropdown} from 'react-bootstrap';

 
class LateAbsentCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            trainingRegisterTableList          :   [],
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalTrainingRegisterShow           :   false,

            selectedClientName          :"",
            absentAllowed   :"",
            latesAllowed    :"",
            latesAllowance  :"",
            UTAllowed:"",
            UTAllowance:"",
            getClientList   :[],
            clientName      :"",
            particulars     :"",
           
           /*  particularList: [
                {
                    "id" : "late",
                    "name" : "Late"
                },
                {
                    "id" : "absent",
                    "name" : "Absent"
                },
                {
                    "id" : "underTime",
                    "name" : "Under Time"
                },
               
            ], */
            selectedparticular     :   '',
            selectedparticularId   :   '',

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();;
    }
    GetDepartment() {
        const departmentCodeParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "",
            "Name"          :   ""
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDepartments",  departmentCodeParams)
        .then(res => {
            const data = res.data;
            console.log("Get Departments Code");
            console.log(data);
            console.log("Get Departments Code");
            this.setState({
                departmentCodeAutocomplete  :   data.departments
            });
        })

        console.log(this.state.departmentCodeAutocomplete)
    }

    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            console.log("Client List start ");
            console.log(res.data);
            console.log("Client List end");
            const data = res.data
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }
    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.client = e[0].name
        this.setState({
            isloading:false,
            isshow:false,
            color:"",
            message:"",
            fade:true ,
            clientName: e[0].name
        })
       
       
    }
    onChangeparticular = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedparticular     :   '',
                selectedparticularId   :   ''
            })
            return
        }
        this.state.selectedparticular = e[0].name
        this.state.selectedparticularId = e[0].id
       
        this.setState({
            isshow   :   false,
           selectedparticular : e[0].name

        })
        console.log(this.state.selectedparticular)
    }
    onChangeAbsentAllowed=(e)=>{
        this.setState({
            absentAllowed:e.target.value
        })
        console.log(this.state.absentAllowed)
    }
    onChangeLatesAllowed=(e)=>{
        this.setState({
            latesAllowed:e.target.value
        })
        console.log(this.state.latesAllowed)
    }
    onChangeLatesAllowance=(e)=>{
        this.setState({
            latesAllowance:e.target.value
        })
        console.log(this.state.latesAllowance)
    }
    onChangeUTAllowed=(e)=>{
        this.setState({
            UTAllowed:e.target.value
        })
        console.log(this.state.UTAllowed)
    }
    onChangeUTAllowance=(e)=>{
        this.setState({
            UTAllowance:e.target.value
        })
        console.log(this.state.UTAllowance)
    }
    handleParticulars=(e)=>{
        this.setState({
            particulars:e.target.value
        })
    }
    
    handleSave=(e)=>{
        
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');

        let data = {
          "clientName"      :   this.state.clientName,
          "particulars"     :   this.state.selectedparticular,
          "absentAllowed"   :   this.state.absentAllowed,
          "latesAllowed"    :   this.state.latesAllowed,
          "latesAllowance"  :   this.state.latesAllowance,
          "UTAllowed"       :   this.state.UTAllowed,
          "UTAllowance"     :   this.state.UTAllowance,

          "isDraft"         :   "0",
          "isDeleted"       :   "0",
          "createdby"       :   "user007",
          "createddate"     :   createddate,
          "modifiedby"      :   'user007',
          "modifieddate"    :   '0',
          "isModified"      :   '0',    
        }
        console.log(data)
        
        const addParams = {
          "_collection" : "TestLateAbsent",
          "doc_data"    : data
        }
          
        axios
        .post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
          const data = res.data
          console.log("success")
          console.log(data)
          this.setState({
            clientName      :"",
            absentAllowed   :"",
            latesAllowed    :"",
            latesAllowance  :"",
            UTAllowed       :"",
            UTAllowance     :"",
            isloading       :   false,
            alerttype       :   "Success!",
            isshow          :   true,
            color           :   "success",
            message         :   "Data had successfully Save" ,
            fade            :   true
          })
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    render() {     
     
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>Maintenance >> Allowable Absent and Late >> Create </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Client
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClientList}
                                                options={this.state.getClientList}
                                                placeholder="Select Client"
                                            />
                                            
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                    
                                    </Form.Group>

                                    <Card.Header className="mt-3">Allowable</Card.Header>
                                    <div className="mt-3">
                                        <Form.Group as={Row} controlId="formPlaintextEmail" >
                                            {/* <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Particular
                                            </Form.Label>
                                            <Col sm="5" >
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeparticular}
                                                options={this.state.particularList}
                                                //placeholder="Select Billing Template"
                                            />

                                            </Col> */}
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail" >
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Absent Allowed Monthly
                                            </Form.Label>
                                            <Col sm="3" >
                                                <Input
                                                    onChange={this.onChangeAbsentAllowed}
                                                    value={this.state.absentAllowed}
                                                    className="form-control"
                                                    placeholder="(Enter Number of Time)"
                                                />
                                            </Col>                                           
                                        </Form.Group>
                                        
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Late Allowed Monthly
                                            </Form.Label>
                                            <Col sm="3">
                                                <Input
                                                    onChange={this.onChangeLatesAllowed}
                                                    value={this.state.latesAllowed}
                                                    className="form-control"
                                                    placeholder="(Enter Number of Time)"
                                                />                                                
                                            </Col>
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Late Allowance
                                            </Form.Label>
                                            <Col sm="3">
                                                <Input
                                                    onChange={this.onChangeLatesAllowance}
                                                    value={this.state.latesAllowance}
                                                    className="form-control"
                                                    placeholder="(Enter Number of Minutes)"
                                                />                                                
                                            </Col>
                                        </Form.Group>                                    

                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                               Under Time Allowed Monthly
                                            </Form.Label>
                                            <Col sm="3">
                                                <Input
                                                    onChange={this.onChangeUTAllowed}
                                                    value={this.state.UTAllowed}
                                                    className="form-control"
                                                    placeholder="(Enter Number of Time)"
                                                />
                                            </Col>
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Under Time Allowance
                                            </Form.Label>
                                            <Col sm="3">
                                                <Input
                                                    onChange={this.onChangeUTAllowance}
                                                    value={this.state.UTAllowance}
                                                    className="form-control"
                                                    placeholder="(Enter Number of Minutes)"
                                                />
                                            </Col>
                                        </Form.Group>
                                    </div>
                                    <ButtonToolbar sm={12}>
                                        <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleSave}>
                                           Save
                                        </Button>&nbsp;&nbsp;
                                        <NavLink to="/home">
                                            <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                                Close
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                    
            </div> 
        )
    }

}

export  default LateAbsentCreate
