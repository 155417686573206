import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker,
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown, FormControl} from 'react-bootstrap';


import { Input } from 'reactstrap';
 
class DoleCases extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            
            batchNoAutocomplete                 :   [],
            
            
            modalCoCReferenceShow               :   false,

            category                :"",
            clientPlaceHolder                :"",
            employeePlaceHolder                :"",
            employeeNoPlaceHolder                :"",
            positionPlaceHolder                :"",
            locationPlaceHolder                :"",

            isLoading               :"",

           
            selectedEmployeeId      :[],
            selectedEmployeeNo      :[],
            selectedEmployeeBranch  :[],
            selectedPosition        :[],

            getClientList           :[],
            getEmployeeList         :[],
            getEmployeeNoList       :[],
            position                :[],
            location                :[],

           
            values                  :[],
            
            dateTo             :"",
            dateFrom           :"",
            year               :"",

            selectedClientName      :"",
            selectedEmployeeName    :[],
            selectedEmployeeNo      :[],

            groundOfSeperation              :"",
            caseNumeber                     :"",
            dateFiled                       :"",
            dateReceived                    :"",
            complaint                       :"",
            scheduleOfHearing               :"",
            venue                           :"",
            hearingOfficer                  :"",
            
            caseNoNLRC                      :"",
            mandatoryDateFiled              :"",
            mandatoryDateReceived           :"",
            mandatoryComplaint              :"",
            mandatoryScheduleOfHearing      :"",
            mandatoryVenue                  :"",
            mandatoryLaborArbiter           :"",
            mandatoryDateReceived           :"",

            particularsMomStConfMeeting     :"",
            particularsMomNdConfMeeting     :"",
            particularsQuitclaim            :"",
            particularsMonetaryClaim        :"",

            positionPaper                   :"",
            reply                           :"",
            rejoinder                       :"",
            decision                        :"",
            counsel                         :"",
            latestUpdate                    :"",
            statusDate                      :"",
            remarks                         :"",
            updateStatus                    :"",
            AttachedDoc                     :"",

            reportedByEmployee              :"",
            reportedByClient                :"",
            reportedByPosition              :"",
            AttachedDocBase64               :"",
            getClientListReportedBy:[],
            reportedByClientid:"",
            disableEmployee:true,

            particularList: [
                {
                    "id"    : "Pending",
                    "name"  : "Pending"
                },
                {
                    "id"    : "Closed",
                    "name"  : "Closed"
                }
               
            ],
            selectedparticular          :   '',
            selectedparticularId        :   '',
            getEmployeeListReportedBy   :[],
            updateId                    :"",
            saveStatus                  :"",


        }
        /* this.handleSubmit = this.handleSubmit.bind(this); */
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        this.setState({isLoading:true,loadingText:"Loading client list..."})

        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();;
        this.getClientListReportedBy();;

        let checkUpdate =[]
        checkUpdate = sessionStorage.getItem("updateDoleCase")
        if(checkUpdate !== ""){
            console.log("getUpdate")
            this.updateDoleCases()
            //let dataTmp = sessionStorage.getItem("updateCompliance")
            this.setState({
                saveStatus:"1",
                disableEmployee:true,
            })

        }else{
            this.setState({
                saveStatus:"0",
                disableEmployee:false,
            })
        }


    }

    updateDoleCases=(e)=>{
        console.log("updateCompliance start")
        let dataFilterTmp=[]
        dataFilterTmp = JSON.parse(sessionStorage.getItem("updateDoleCase"))
        
        console.log( dataFilterTmp.selectedClientName)


        let dateFiledtmp = ""
        let dateReceivedtmp = ""
        let mandatoryDateFiledtmp = ""
        let mandatoryDateReceivedtmp = ""
        let statusDatetmp = ""

        if(dataFilterTmp.dateFiled != "" || dataFilterTmp.dateFiled !== ""){
            dateFiledtmp = new Date(dataFilterTmp.dateFiled)
        }
        if(dataFilterTmp.dateReceived != "" || dataFilterTmp.dateReceived !== ""){
            dateReceivedtmp = new Date(dataFilterTmp.dateReceived)
        }
        if(dataFilterTmp.mandatoryDateFiled != "" || dataFilterTmp.mandatoryDateFiled !== ""){
            mandatoryDateFiledtmp = new Date(dataFilterTmp.mandatoryDateFiled)
        }
        if(dataFilterTmp.mandatoryDateReceived != "" || dataFilterTmp.mandatoryDateReceived !== ""){
            mandatoryDateReceivedtmp = new Date(dataFilterTmp.mandatoryDateReceived)
        }
        if(dataFilterTmp.statusDate != "" || dataFilterTmp.statusDate !== ""){
            statusDatetmp = new Date(dataFilterTmp.statusDate)
        }

        this.setState({

            updateId:dataFilterTmp.id,
            clientPlaceHolder:dataFilterTmp.selectedClientName,
            employeePlaceHolder:dataFilterTmp.selectedEmployeeName,
            employeeNoPlaceHolder:dataFilterTmp.selectedEmployeeNo,
            positionPlaceHolder:dataFilterTmp.selectedPosition,
            locationPlaceHolder:dataFilterTmp.selectedEmployeeBranch,
            caseNumeber:dataFilterTmp.caseNumeber,
            groundOfSeperation:dataFilterTmp.groundOfSeperation,
            complaint:dataFilterTmp.complaint,
            dateFiled:dateFiledtmp,
            dateReceived:dateReceivedtmp,
            scheduleOfHearing:dataFilterTmp.scheduleOfHearing,
            venue:dataFilterTmp.venue,
            hearingOfficer:dataFilterTmp.hearingOfficer,
            caseNoNLRC:dataFilterTmp.caseNoNLRC,
            mandatoryDateFiled:mandatoryDateFiledtmp,
            mandatoryDateReceived:mandatoryDateReceivedtmp,
            mandatoryComplaint:dataFilterTmp.mandatoryComplaint,
            mandatoryScheduleOfHearing:dataFilterTmp.mandatoryScheduleOfHearing,
            mandatoryVenue:dataFilterTmp.mandatoryVenue,
            mandatoryLaborArbiter:dataFilterTmp.mandatoryLaborArbiter,
            particularsMomStConfMeeting:dataFilterTmp.particularsMomStConfMeeting,
            particularsMomNdConfMeeting:dataFilterTmp.particularsMomNdConfMeeting,
            particularsQuitclaim:dataFilterTmp.particularsQuitclaim,
            particularsMonetaryClaim:dataFilterTmp.particularsMonetaryClaim,
            reply:dataFilterTmp.reply,
            rejoinder:dataFilterTmp.rejoinder,
            decision:dataFilterTmp.decision,
            counsel:dataFilterTmp.counsel,
            latestUpdate:dataFilterTmp.latestUpdate,
            statusDate:statusDatetmp,
            remarks:dataFilterTmp.remarks,
            selectedparticular:dataFilterTmp.updateStatus,
            AttachedDoc:dataFilterTmp.AttachedDoc,
            positionPaper:dataFilterTmp.positionPaper,
            
        })   
    
    }

    handleChangeDateTo = date => {
        ////console.log(date)
        this.setState({
            dateTo: date,
            isshow:false,
        })
        console.log(this.state.dateTo)
    }

    handleChangeDateFrom = date => {
        ////console.log(date)
        this.setState({
            dateFrom: date,
            isshow:false,
        })
        console.log(this.state.dateFrom)
    }

    getClientList(){
        
        this.setState({isloading:true})

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {

            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });

        })
        .catch(error=>{

           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })

        })

    }

    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        console.log("Client selectedClientId " + this.state.selectedClientId );
        console.log("Client selectedClientName " + this.state.selectedClientName );
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})
        
        this.getEmployees();
    }
    
    getEmployees(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            console.log("Employee List start ");
            console.log(res.data);
            console.log("Employee List end");
            const data = res.data
            if(data.status=="1")
                this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
            else
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
        
    }

    onChangeEmployeesList = (e) => {       

        if(e.length==0)
        {
            this.state.selectedEmployeeName=""
            this.state.selectedEmployeeNo=""
            this.state.selectedEmployeeBranch=""
            this.state.selectedPosition=""
            return
        }
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedEmployeeName = e[0].employeeName
        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeNo = e[0].employeeNo
        this.state.selectedEmployeeBranch = e[0].locationName
        this.state.selectedPosition = e[0].position
       
        console.log("Work selectedEmployeeName   " + this.state.selectedEmployeeNo );
        console.log("Employee List start ");
        console.log(this.state.selectedEmployeeBranch);
        console.log("Employee List end");

        this.setState({
            position:this.state.selectedPosition,
            location:this.state.selectedEmployeeBranch

        })
        this.setState({isloading:false})
    }

   /*  onChangeEmployeesNo(e){
        this.setState({selectedEmployeeNo : e.target.value})
        //console.log(e.target.value)
    }
    onChangePosition(e){
        this.setState({position : e.target.value})
        //console.log(e.target.value)
    }
    onChangeLocation(e){
        this.setState({location : e.target.value})
        //console.log(e.target.value)
    }   */

    handleClose=(e)=>{
        sessionStorage.setItem("updateDoleCase", "")
    }

    handleSaveClick=()=>{

        if(this.state.selectedparticular == "" || this.state.selectedparticular === ""  ){

            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "please fill up status/date",
                fade            :   true
            })

        }else{

            this.setState({ isloading   :   true, })

            let createddate             = ""
            let _dateFiled              = ""
            let _dateReceived           = ""
            let _mandatoryDateFiled     = ""
            let _mandatoryDateReceived  = ""
            let _statusDate             = ""
    
            if(this.state.dateFiled != "" || this.state.dateFiled !== ""){
                _dateFiled  = moment(this.state.dateFiled).format('MM/DD/YYYY');
            }
            if(this.state.dateReceived != "" || this.state.dateReceived !== ""){
                _dateReceived   = moment(this.state.dateReceived).format('MM/DD/YYYY');
            }
            if(this.state.mandatoryDateFiled != "" || this.state.mandatoryDateFiled !== ""){
                _mandatoryDateFiled = moment(this.state.mandatoryDateFiled).format('MM/DD/YYYY');
            }
            if(this.state.mandatoryDateReceived != "" || this.state.mandatoryDateReceived !== ""){
                _mandatoryDateReceived  = moment(this.state.mandatoryDateReceived).format('MM/DD/YYYY');
            }
            if(this.state.statusDate != "" || this.state.statusDate !== ""){
                _statusDate = moment(this.state.statusDate).format('MM/DD/YYYY');
            }

            if(this.state.saveStatus == "0" || this.state.saveStatus === "0"){
               
                
                let data = {
                    "client"                        :   this.state.selectedClientName,
                    "employeeName"                  :   this.state.selectedEmployeeName,
                    "position"                      :   this.state.selectedPosition,
                    "employeeNo"                    :   this.state.selectedEmployeeNo,
                    "location"                      :   this.state.selectedEmployeeBranch,
                    "groundOfSeperation"            :   this.state.groundOfSeperation,
                    "caseNumeber"                   :   this.state.caseNumeber,
                    "dateFiled"                     :   _dateFiled,
                    "dateReceived"                  :   _dateReceived,
                    "complaint"                     :   this.state.complaint,
                    "scheduleOfHearing"             :   this.state.scheduleOfHearing,
                    "venue"                         :   this.state.venue,
                    "hearingOfficer"                :   this.state.hearingOfficer,
                    "caseNoNLRC"                    :   this.state.caseNoNLRC,
                    "mandatoryDateFiled"            :   _mandatoryDateFiled,
                    "mandatoryDateReceived"         :   _mandatoryDateReceived,
                    "mandatoryComplaint"            :   this.state.mandatoryComplaint,
                    "mandatoryScheduleOfHearing"    :   this.state.mandatoryScheduleOfHearing,
                    "mandatoryVenue"                :   this.state.mandatoryVenue,
                    "mandatoryLaborArbiter"         :   this.state.mandatoryLaborArbiter,            
                    "particularsMomStConfMeeting"   :   this.state.particularsMomStConfMeeting,
                    "particularsMomNdConfMeeting"   :   this.state.particularsMomNdConfMeeting,
                    "particularsQuitclaim"          :   this.state.particularsQuitclaim,
                    "particularsMonetaryClaim"      :   this.state.particularsMonetaryClaim,
                    "positionPaper"                 :   this.state.positionPaper,
                    "reply"                         :   this.state.reply,
                    "rejoinder"                     :   this.state.rejoinder,
                    "decision"                      :   this.state.decision,
                    "counsel"                       :   this.state.counsel,
                    "latestUpdate"                  :   this.state.latestUpdate,
                    "statusDate"                    :   _statusDate,
                    "remarks"                       :   this.state.remarks,
                    "updateStatus"                  :   this.state.selectedparticular,
                    "attachedDoc"                   :   this.state.AttachedDoc,
                    "reportedByEmployee"            :   this.state.reportedByEmployee,
                    "reportedByPosition"            :   this.state.reportedByPosition,
                    "reportedByClient"              :   this.state.reportedByClient,
                    "isDraft"                       :   "0",
                    "isDeleted"                     :   "0",
                    "createdby"                     :   "user007",
                    "createddate"                   :   createddate,
                    "modifiedby"                    :   'user007',
                    "modifieddate"                  :   '0',
                    "isModified"                    :   '0',    
                    
                }        
            
                const addParams = {
                    "_collection" : "DoleCases",
                    "doc_data"    : data
                }
                
                axios
                .post(AppConfiguration.Setting().noserMongoDB + "action/add", addParams)
                //.post("http://134.209.99.190:8088/action/add", addParams)
                .then(res =>{
    
                    this.setState({

                        selectedClientName:"",
                        selectedEmployeeName:"",
                        selectedPosition:"",
                        selectedEmployeeNo:"",
                        selectedEmployeeBranch:"",
                        groundOfSeperation:"",
                        caseNumeber:"",
                        dateFiled:"",
                        dateReceived:"",
                        complaint:"",
                        scheduleOfHearing:"",
                        venue:"",
                        hearingOfficer:"",
                        caseNoNLRC:"",
                        mandatoryDateFiled:"",                    
                        mandatoryComplaint:"",
                        mandatoryScheduleOfHearing:"",
                        mandatoryVenue:"",
                        mandatoryLaborArbiter:"",
                        mandatoryDateReceived:"",
                        particularsMomStConfMeeting:"",
                        particularsMomNdConfMeeting:"",
                        particularsQuitclaim:"",
                        particularsMonetaryClaim:"",
                        positionPaper:"",
                        reply:"",
                        rejoinder:"",
                        decision:"",
                        counsel:"",
                        latestUpdate:"",
                        statusDate:"",
                        remarks:"",
                        updateStatus:"",
                        reportedByEmployee:"",
                        reportedByClient:"",
                        reportedByPosition:"",
                        isloading       :   false,
                        alerttype       :   "Success!",
                        isshow          :   true,
                        color           :   "success",
                        message         :   "Data had successfully Save" ,
                        fade            :   true
                    
                    })
                    sessionStorage.setItem("updateDoleCase", "")
                
                })
                .catch(error =>{
                    console.log("error: " + error)
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                        fade            :   true
                    })
    
                })

            }else{
                
                let data = {
                    "client"                        :   this.state.clientPlaceHolder,
                    "employeeName"                  :   this.state.employeePlaceHolder,
                    "position"                      :   this.state.positionPlaceHolder,
                    "employeeNo"                    :   this.state.employeeNoPlaceHolder,
                    "location"                      :   this.state.locationPlaceHolder,
                    "groundOfSeperation"            :   this.state.groundOfSeperation,
                    "caseNumeber"                   :   this.state.caseNumeber,
                    "dateFiled"                     :   _dateFiled,
                    "dateReceived"                  :   _dateReceived,
                    "complaint"                     :   this.state.complaint,
                    "scheduleOfHearing"             :   this.state.scheduleOfHearing,
                    "venue"                         :   this.state.venue,
                    "hearingOfficer"                :   this.state.hearingOfficer,
                    "caseNoNLRC"                    :   this.state.caseNoNLRC,
                    "mandatoryDateFiled"            :   _mandatoryDateFiled,
                    "mandatoryDateReceived"         :   _mandatoryDateReceived,
                    "mandatoryComplaint"            :   this.state.mandatoryComplaint,
                    "mandatoryScheduleOfHearing"    :   this.state.mandatoryScheduleOfHearing,
                    "mandatoryVenue"                :   this.state.mandatoryVenue,
                    "mandatoryLaborArbiter"         :   this.state.mandatoryLaborArbiter,            
                    "particularsMomStConfMeeting"   :   this.state.particularsMomStConfMeeting,
                    "particularsMomNdConfMeeting"   :   this.state.particularsMomNdConfMeeting,
                    "particularsQuitclaim"          :   this.state.particularsQuitclaim,
                    "particularsMonetaryClaim"      :   this.state.particularsMonetaryClaim,
                    "positionPaper"                 :   this.state.positionPaper,
                    "reply"                         :   this.state.reply,
                    "rejoinder"                     :   this.state.rejoinder,
                    "decision"                      :   this.state.decision,
                    "counsel"                       :   this.state.counsel,
                    "latestUpdate"                  :   this.state.latestUpdate,
                    "statusDate"                    :   _statusDate,
                    "remarks"                       :   this.state.remarks,
                    "updateStatus"                  :   this.state.selectedparticular,
                    
                    "reportedByEmployee"            :   this.state.reportedByEmployee,
                    "reportedByPosition"            :   this.state.reportedByPosition,
                    "reportedByClient"              :   this.state.reportedByClient,
                    "isDraft"                       :   "0",
                    "isDeleted"                     :   "0",
                    "createdby"                     :   "user007",
                    "createddate"                   :   createddate,
                    "modifiedby"                    :   'user007',
                    "modifieddate"                  :   '0',
                    "isModified"                    :   '0', 
                    "attachedDoc"                   :   this.state.AttachedDocBase64,   
                    
                }        
            
                const updateParams = {

                    "_collection" : "DoleCases",
                    "doc_data"    : data,
                    "_id"         : this.state.updateId,

                }
                
                axios
                .post(AppConfiguration.Setting().noserMongoDB + "action/update_one", updateParams)
                //.post("http://134.209.99.190:8088/action/update_one", updateParams)
                .then(res =>{
    
                    this.setState({
                        selectedClientName:"",
                        selectedEmployeeName:"",
                        selectedPosition:"",
                        selectedEmployeeNo:"",
                        selectedEmployeeBranch:"",
                        groundOfSeperation:"",
                        caseNumeber:"",
                        dateFiled:"",
                        dateReceived:"",
                        complaint:"",
                        scheduleOfHearing:"",
                        venue:"",
                        hearingOfficer:"",
                        caseNoNLRC:"",
                        mandatoryDateFiled:"",                    
                        mandatoryComplaint:"",
                        mandatoryScheduleOfHearing:"",
                        mandatoryVenue:"",
                        mandatoryLaborArbiter:"",
                        mandatoryDateReceived:"",
                        particularsMomStConfMeeting:"",
                        particularsMomNdConfMeeting:"",
                        particularsQuitclaim:"",
                        particularsMonetaryClaim:"",
                        positionPaper:"",
                        reply:"",
                        rejoinder:"",
                        decision:"",
                        counsel:"",
                        latestUpdate:"",
                        statusDate:"",
                        remarks:"",
                        updateStatus:"",
                        reportedByEmployee:"",
                        reportedByClient:"",
                        reportedByPosition:"",
                        isloading       :   false,
                        alerttype       :   "Success!",
                        isshow          :   true,
                        color           :   "success",
                        message         :   "Data had successfully Save" ,
                        fade            :   true
                    
                    })
                    sessionStorage.setItem("updateDoleCase", "")
                
                })
                .catch(error =>{
                    console.log("error: " + error)
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                        fade            :   true
                    })
    
                })
            }

        }

    }
    
    handleOnChangeGroundOfSeperation=(e)=>{
        this.setState({
            groundOfSeperation   : e.target.value
        })

    }

    handleOnChangeCaseNumeber=(e)=>{
        this.setState({
            caseNumeber   : e.target.value
        })
    }

    handleOnChangeDateFiled = date =>{
      
        this.setState({
            dateFiled: date,
            isshow:false,
        })
       
    }

    handleOnChangedateReceived = date =>{
       
        this.setState({
            dateReceived: date,
            isshow:false,
        })

    }

    handleOnChangecomplaint=(e)=>{
        this.setState({
            complaint   : e.target.value
        })
        
    }

    handleOnChangescheduleOfHearing=(e)=>{
        this.setState({
            scheduleOfHearing   : e.target.value
        })
        console.log(this.state.scheduleOfHearing)
    }

    handleOnChangevenue=(e)=>{
        this.setState({
            venue   : e.target.value
        })
        console.log(this.state.venue)
    }

    handleOnChangehearingOfficer=(e)=>{
        this.setState({
            hearingOfficer   : e.target.value
        })
        console.log(this.state.hearingOfficer)
    }

    handleOnChangecaseNoNLRC=(e)=>{
        this.setState({
            caseNoNLRC   : e.target.value
        })
        console.log(this.state.caseNoNLRC)
    }

    onChangemandatoryDateFiled =date=>{     
       
        this.setState({
            mandatoryDateFiled: date,
            isshow:false,
        })

    }

    onChangemandatoryDateReceived =date=>{
      
        this.setState({
            mandatoryDateReceived: date,
            isshow:false,
        })

    }

    handleOnChangemandatoryComplaint=(e)=>{
        this.setState({
            mandatoryComplaint   : e.target.value
        })
        console.log(this.state.mandatoryComplaint)
    }

    handleOnChangemandatoryScheduleOfHearing=(e)=>{
        this.setState({
            mandatoryScheduleOfHearing   : e.target.value
        })
        console.log(this.state.mandatoryScheduleOfHearing)
    }

    handleOnChangemandatoryVenue=(e)=>{
        this.setState({
            mandatoryVenue   : e.target.value
        })
        console.log(this.state.mandatoryVenue)
    }

    handleOnChangemandatoryLaborArbiter=(e)=>{
        this.setState({
            mandatoryLaborArbiter   : e.target.value
        })
        console.log(this.state.mandatoryLaborArbiter)
    } 

    handleOnChangeparticularsMomStConfMeeting=(e)=>{
        this.setState({
            particularsMomStConfMeeting   : e.target.value
        })
        console.log(this.state.particularsMomStConfMeeting)
    }
    handleOnChangeparticularsMomNdConfMeeting=(e)=>{
        this.setState({
            particularsMomNdConfMeeting   : e.target.value
        })
        console.log(this.state.particularsMomNdConfMeeting)
    }

    handleOnChangeparticularsQuitclaim=(e)=>{
        this.setState({
            particularsQuitclaim   : e.target.value
        })
        console.log(this.state.particularsQuitclaim)
    }

    handleOnChangeparticularsMonetaryClaim=(e)=>{
        this.setState({
            particularsMonetaryClaim   : e.target.value
        })
        console.log(this.state.particularsMonetaryClaim)
    }

    handleOnChangepositionPaper=(e)=>{
        this.setState({
            positionPaper   : e.target.value
        })
        console.log(this.state.positionPaper)
    }

    handleOnChangereply=(e)=>{
        this.setState({
            reply   : e.target.value
        })
        console.log(this.state.reply)
    }

    handleOnChangerejoinder=(e)=>{
        this.setState({
            rejoinder   : e.target.value
        })
        console.log(this.state.rejoinder)
    }

    handleOnChangedecision=(e)=>{

        this.setState({
            decision   : e.target.value
        })

    }
    handleOnChangecounsel=(e)=>{
        this.setState({
            counsel   : e.target.value
        })
    }

    handleOnChangelatestUpdate=(e)=>{
        this.setState({
            latestUpdate   : e.target.value
        })
    }

    handleOnChangestatusDate =date=>{

        this.setState({
            statusDate: date,
            isshow:false,
        })

    }

    handleOnChangeremarks=(e)=>{

        this.setState({
            remarks   : e.target.value
        })

    }

    handleOnChangeupdateStatus=(e)=>{

        this.setState({
            updateStatus   : e.target.value
        })

    }

    fileToBase64=(file, cb)=>{
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () { 
            cb(null , reader.result)
        }
        reader.onerror = function (error) {
            cb(error,null )
        }
    }

    handleOnChangeAttachedDoc=(e)=>{
        let fileTmp = e.target.value
        this.setState({
            AttachedDoc   : fileTmp
        })
        if(e.target.files < 1 || !e.target.validity.valid){
            return
        }

        let base64data = ""
        this.fileToBase64(e.target.files[0], (err , result)=> {
            
            if(result){
                base64data = result
                console.log(result)
                this.state.AttachedDocBase64 = result
            }
        })
        console.log("base64data")
        console.log(base64data)
        console.log("base64data")

    }

    getClientListReportedBy(){
        
        this.setState({isloading:true})

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {

            this.setState({
                isloading:false,
                getClientListReportedBy : res.data.clients ? res.data.clients : []
            });

        })
        .catch(error=>{

           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })

       })

    }

    onChangeReportedByClient=(e)=>{

        if(e.length == 0) {
            this.state.reportedByClient=""
            this.state.reportedByClientid=""
            return
        } 

        this.state.reportedByClient = e[0].name
        this.state.reportedByClientid = e[0].id

        this.setState({

            isloading:true,
            isshow:false,
            color:"",
            message:"",
            fade:true ,

        })
        
        this.getEmployeesReportedBy();

    }

    getEmployeesReportedBy(){

        this.setState({isloading:true})

        const getParams = {

            "IpAddress":"0.0.0.0",
            "ClientId":this.state.reportedByClientid,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":"",

        }

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter", getParams)
        .then(res => {

            console.log("Employee List start ");
            console.log(res.data);
            console.log("Employee List end");

            const data = res.data

            if(data.status=="1")
                this.setState({getEmployeeListReportedBy : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
            else
                this.setState({getEmployeeListReportedBy : [], getEmployeeNoList : [],isloading:false}) 
      
        })
        .catch(error=>{

           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })

       })

        
    }

    onChangeReportedEmployee=(e)=>{

        if(e.length==0)
        {
            this.setState({getEmployeeListData: null, selectedPayrollpayPeriod: '', selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
          
            return
        }
        this.setState({/* isloading:true, */isshow:false,color:"",message:"",fade:true})

        this.state.reportedByEmployee = e[0].employeeName
        this.state.reportedByPosition = e[0].position

        this.setState({isloading:false})
    }
    
    onChangeparticular = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedparticular     :   '',
                selectedparticularId   :   ''
            })
            return
        }
        this.state.selectedparticular = e[0].name
        this.state.selectedparticularId = e[0].id
       
        this.setState({
            isshow   :   false,
           selectedparticular : e[0].id

        })
       
    }


    render() {        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>DOLE CASES</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Card.Header className="mt-3">EMPLOYEE INFORMATION</Card.Header>
                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Client
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                //selected={[this.state.selectedClientName]}
                                                onChange={this.onChangeClientList}
                                                options={this.state.getClientList}
                                                disabled={this.state.disableEmployee}
                                                placeholder={this.state.clientPlaceHolder}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee
                                        </Form.Label>
                                        <Col sm="7">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                //selected={[this.state.selectedEmployeeName]}
                                                onChange={this.onChangeEmployeesList}
                                                options={this.state.getEmployeeList}
                                                placeholder={this.state.employeePlaceHolder}
                                                disabled={this.state.disableEmployee}
                                                
                                                
                                            />
                                        </Col>

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee No.
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeNo" 
                                                value={this.state.selectedEmployeeNo}
                                                //onChange={this.onChangePosition} 
                                                disabled={this.state.disableEmployee}
                                                placeholder={this.state.employeeNoPlaceHolder}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text"
                                                name="selectedPosition" 
                                                value={this.state.selectedPosition}
                                                disabled={this.state.disableEmployee}
                                                placeholder={this.state.positionPlaceHolder}
                                            />
                                        </Col>
                                        <Col sm="2"></Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Location
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeBranch"
                                                value={this.state.selectedEmployeeBranch}
                                                disabled={this.state.disableEmployee}
                                                placeholder={this.state.locationPlaceHolder}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Ground Of Seperation
                                        </Form.Label>
                                        <Col sm="4" /* style={{marginLeft : "-1px"}} */>
                                            <Input
                                                labelKey='year'
                                                id="basic-example"
                                                value={this.state.groundOfSeperation}
                                                className="form-control"
                                                onChange={this.handleOnChangeGroundOfSeperation}
                                                placeholder="Enter Ground Of Seperation"
                                                autocomplete="off"
                                                
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Card.Header className="mt-3">SINGLE ENTRY NOTICE APPROACH (SENA)</Card.Header>
                                    <div className="mt-3">
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Case Number
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.caseNumeber}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeCaseNumeber}
                                                    //placeholder="Enter Case Numeber"
                                                    autocomplete="off"
                                                    
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Date Filed
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <DatePicker
                                                    ref='dateFiled'
                                                    selected={this.state.dateFiled}
                                                    onChange={this.handleOnChangeDateFiled}
                                                    minDate={this.minDate}
                                                    value={this.props.dateFiled}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    /* placeholderText="DATE APPLIED" */
                                                    className="form-control"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Date Received
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <DatePicker
                                                    ref='dateReceived'
                                                    selected={this.state.dateReceived}
                                                    onChange={this.handleOnChangedateReceived}
                                                    minDate={this.minDate}
                                                    value={this.props.dateReceived}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    /* placeholderText="DATE APPLIED" */
                                                    className="form-control"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Complaint
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='Complaint'
                                                    id="basic-example"
                                                    value={this.state.complaint}
                                                    className="form-control"
                                                    onChange={this.handleOnChangecomplaint}
                                                    //placeholder="Enter Complaint"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Schedule Of Hearing
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.scheduleOfHearing}
                                                    className="form-control"
                                                    onChange={this.handleOnChangescheduleOfHearing}
                                                    //placeholder="Enter Schedule Of Hearing"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Venue
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.venue}
                                                    className="form-control"
                                                    onChange={this.handleOnChangevenue}
                                                    //placeholder="Enter Venue"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Hearing Officer
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.hearingOfficer}
                                                    className="form-control"
                                                    onChange={this.handleOnChangehearingOfficer}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>

                                    </div>

                                    <Card.Header className="mt-3">MANDATORY CONFERENCE</Card.Header>

                                    <div className="mt-3">
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                NLRC Case No
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.caseNoNLRC}
                                                    className="form-control"
                                                    onChange={this.handleOnChangecaseNoNLRC}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Date Filed
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <DatePicker
                                                    ref='mandatoryDateFiled'
                                                    selected={this.state.mandatoryDateFiled}
                                                    onChange={this.onChangemandatoryDateFiled}
                                                    minDate={this.minDate}
                                                    value={this.props.mandatoryDateFiled}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    /* placeholderText="DATE APPLIED" */
                                                    className="form-control"
                                                />

                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Date Received
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                 <DatePicker
                                                    ref='mandatoryDateReceived'
                                                    selected={this.state.mandatoryDateReceived}
                                                    onChange={this.onChangemandatoryDateReceived}
                                                    minDate={this.minDate}
                                                    value={this.props.mandatoryDateReceived}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    /* placeholderText="DATE APPLIED" */
                                                    className="form-control"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Complaint
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.mandatoryComplaint}
                                                    className="form-control"
                                                    onChange={this.handleOnChangemandatoryComplaint}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Schedule Of Hearing
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.mandatoryScheduleOfHearing}
                                                    className="form-control"
                                                    onChange={this.handleOnChangemandatoryScheduleOfHearing}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Venue
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.mandatoryVenue}
                                                    className="form-control"
                                                    onChange={this.handleOnChangemandatoryVenue}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Labor Arbiter
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.mandatoryLaborArbiter}
                                                    className="form-control"
                                                    onChange={this.handleOnChangemandatoryLaborArbiter}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                    </div>

                                    <Card.Header className="mt-3">PARTICULARS</Card.Header>

                                    <div className="mt-3">
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Mom St Conf Meeting
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.particularsMomStConfMeeting}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeparticularsMomStConfMeeting}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Mom Nd Conf Meeting
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.particularsMomNdConfMeeting}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeparticularsMomNdConfMeeting}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Quitclaim
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.particularsQuitclaim}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeparticularsQuitclaim}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Monetary Claim
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.particularsMonetaryClaim}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeparticularsMonetaryClaim}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>

                                    </div>

                                    <Card.Header className="mt-3">SCHEDULE OF SUBMISSION</Card.Header>

                                    <div className="mt-3">
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Position Paper
                                            </Form.Label>
                                            <Col sm="11" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.positionPaper}
                                                    className="form-control"
                                                    onChange={this.handleOnChangepositionPaper}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Reply
                                            </Form.Label>
                                            <Col sm="11" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.reply}
                                                    className="form-control"
                                                    onChange={this.handleOnChangereply}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Rejoinder
                                            </Form.Label>
                                            <Col sm="11" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.rejoinder}
                                                    className="form-control"
                                                    onChange={this.handleOnChangerejoinder}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        
                                    </div>

                                    <Card.Header className="mt-3">UPDATE AND STATUS</Card.Header>

                                    <div className="mt-3">
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Decision
                                            </Form.Label>
                                            <Col sm="11" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.decision}
                                                    className="form-control"
                                                    onChange={this.handleOnChangedecision}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Counsel
                                            </Form.Label>
                                            <Col sm="11" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.counsel}
                                                    className="form-control"
                                                    onChange={this.handleOnChangecounsel}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Latest Update
                                            </Form.Label>
                                            <Col sm="11" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.latestUpdate}
                                                    className="form-control"
                                                    onChange={this.handleOnChangelatestUpdate}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                DATE
                                            </Form.Label>
                                            <Col sm="2" /* style={{marginLeft : "-1px"}} */>
                                                <DatePicker
                                                    ref='statusDate'
                                                    selected={this.state.statusDate}
                                                    onChange={this.handleOnChangestatusDate}
                                                    minDate={this.minDate}
                                                    value={this.props.statusDate}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    /* placeholderText="DATE APPLIED" */
                                                    className="form-control"
                                                />
                                            </Col>
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                REMARKS
                                            </Form.Label>
                                            <Col sm="4" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.remarks}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeremarks}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <ButtonToolbar sm={2}>
                                                {/* <Col className="mr-auto">
                                                    <ButtonToolbar className="mt-1">                                                   
                                                        <Button className="mr-auto" variant="success" onClick={this.handleToExportNTE} href="/ExportNTE" style={{minHeight:"18px",minWidth:'60px', marginLeft:"129px", marginTop:"-1px"}}>ADD</Button>                                                   
                                                    </ButtonToolbar>                                          
                                                </Col>   */}                                                                    
                                            </ButtonToolbar>
                                        </Form.Group>

                                        { <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Status
                                            </Form.Label>
                                            <Col sm="11">
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    selected={[this.state.selectedparticular]}
                                                    onChange={this.onChangeparticular}
                                                    options={this.state.particularList}
                                                    //placeholder="select penalty type"
                                                />
                                            </Col>
                                        </Form.Group>}
                                        
                                    </div>
                                   {/*  <Card.Header className="mt-5">REPORTED BY </Card.Header>

                                    <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Client
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeReportedByClient}
                                                options={this.state.getClientListReportedBy}
                                                placeholder="Select Client"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee
                                        </Form.Label>
                                        <Col sm="4">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.onChangeReportedEmployee}
                                                options={this.state.getEmployeeListReportedBy}
                                                placeholder="Select Employee"
                                                autocomplete="false"
                                            />
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position 
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                type="text"
                                                name="reportedByPosition"
                                                value={this.state.reportedByPosition}
                                                onChange={this.onChangeReportedPosition} 
                                                autoComplete="off"
                                                placeholder="Auto Fill"
                                            />
                                        </Col>
                                      
                                    </Form.Group> */}

                                    <Card.Header className="mt-3">DOCUMENTS ATTACHED</Card.Header>

                                    <div className="mt-3">
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Col sm="4" >                                                 
                                                <Input type="file" className="file" placeholder="attached file" variant="primary"  accept="application/pdf"  onChange={this.handleOnChangeAttachedDoc}/>                                          
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">

                                          {/*   <ButtonToolbar sm={2}>
                                                <Col className="mr-auto">
                                                    <ButtonToolbar className="mt-5">                                                   
                                                        <Button className="mr-auto" variant="success" onClick={this.handleToExportNTE} href="/ExportNTE" style={{minHeight:"18px",minWidth:'60px',}}>Add</Button>                                                   
                                                    </ButtonToolbar>                                          
                                                </Col>                                                                      
                                            </ButtonToolbar> */}
                                        
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formPlaintextEmail" className="mt-4">
                                            
                                            <ButtonToolbar sm={12}>
                                                <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleSaveClick}>
                                                    Save
                                                </Button>&nbsp;&nbsp;
                                                <NavLink to="/home">
                                                    <Button variant="danger" href="/home" onClick={this.handleClose} style={{minWidth:'60px'}}>
                                                        Close
                                                    </Button>
                                                </NavLink>
                                            </ButtonToolbar>
                                            
                                        </Form.Group>
                                    </div>

                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                   
            </div> 
        )
    }

}

export  default DoleCases
