import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { tsNullKeyword } from '@babel/types';

 
class MemorandumMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            statusTableList                     :   [],
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalTrainingRegisterShow           :   false,
            selectedClientName                  :   [],            
            getClientList                       :   [],
            employeeDetailList                  :   [],
            particularList: [
                {
                    "id" : "Closed",
                    "name" : "Closed"
                },
                {
                    "id" : "Pending",
                    "name" : "Pending"
                },
                
               
            ],
            selectedparticular      :   '',
            selectedparticularId    :   '',
            countStatus             :'Pending',
            pendingsList            :[],
            closedList              :[],
            fliterClose             :'Closed',
            statusPendingList       :[],
            statusClosedList        :[],
            totalStatusList         :[],
            totalDoleCasesList      :[], 
            totalClosedCount        :[],  
            client                  :[],

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        //this.getDoleCasesTotalCount();
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getMonitoring();
        //this.getClosedStatus();
        this.getClientList();;
        /* this.getMemorandumList();; */
        //this.getPendingCount();
        //this.getTotalClosedCount();
        //this.getTotalstatus();
        
        
    }

    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            //console.log("Client List start ");
            //console.log(res.data);
            //console.log("Client List end");
            const data = res.data
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }

    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.client=""
            return
        } 
        //this.state.selectedClientId = e[0].id
        this.state.client = e[0].name
        this.setState({
           // isloading:true,
            isshow:false,
            color:"",
            message:"",
            fade:true ,
            clientName: e[0].name
        })
       let clientTmp = e[0].name
       console.log(clientTmp)
       this.getMemorandumList();;
        
    }

   /*  showTrainingRegisterModal = (e) => {
        this.setState({modalTrainingRegisterShow: true})
    } */
    handleModalClose = (e) =>{
        //reload or refresh data here
        //alert("Data pass from child : " + e)
        this.setState({modalTrainingRegisterShow: false})
    }

    GridDataModified(oldValue, newValue, id, column) {
        //////console.log(id)
        this.state.employeeDetailList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }

    getMonitoring =()=>{
       
       let filter_data ={}

        const getParams ={
            "_collection" : "TestNTE",
            "filter_data" : filter_data
        }

        console.log("axios start")

        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data
            console.log("Raw Json111")
            console.log(data)
            console.log("End Raw Json111")
            var obj = (data);
            var res = [];
            
            data.TestNTE.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                res.push(json)
            })

            this.setState({
                employeeDetailList: res,
                isloading:false
            })
            
        })
        .catch(error=>{
            console.log("error: " + error)
        })
    }

    buildListTestMonitoringStatus(data) {
        ////console.log("data start")
        let itemList =[]
    
        let idList                      =[]
        let statusList                  =[]
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
        
    
        for (let i = 0; i < data.length; i++) {           
    
            let s1 = data[i].split("':")
            
            let idClean                     = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let statusTmp                   = s1[2].split("',")[0]
            let isDraftListTmp              = s1[3].split("',")[0]
            let isDeletedListTmp            = s1[4].split("',")[0]
            let createdbyListTmp            = s1[5].split("',")[0]
            let createddateListTmp          = s1[6].split("',")[0]
            let modifiedbyListTmp           = s1[7].split("',")[0]
            let modifieddateListTmp         = s1[8].split("',")[0]
            let isModifiedListTmp           = s1[9].split("',")[0]

        
            idList.push(IdClean2.replace("'",""))
            statusList.push(statusTmp.replace("'",""))
            
            isDraftList.push(isDraftListTmp.replace("'",""))
            isDeletedList.push(isDeletedListTmp.replace("'",""))
            createdbyList.push(createdbyListTmp.replace("'",""))
            createddateList.push(createddateListTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'status'                :   statusList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }
               
        return itemList
    }

    getDoleCasesTotalCount =()=>{
    
        let filter_data ={}
        const getParams ={
            "_collection" : "TestDoleCases",
            "filter_data" : filter_data
        }
            console.log("axios start")
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
            console.log("res.getPendingCount start")
            console.log(res.data)
            console.log("res.getPendingCount end")
        
            const itemUsersDataLists = this.buildList(data["TestDoleCases"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                    
                let obj = {
                    'id'                            :   this.state.itemUserDataList[i]['id'],
                    'selectedClientName'            :   this.state.itemUserDataList[i]['selectedClientName'],
                    'selectedEmployeeName'          :   this.state.itemUserDataList[i]['selectedEmployeeName'],
                    'selectedPosition'              :   this.state.itemUserDataList[i]['selectedPosition'],
                    'selectedEmployeeNo'            :   this.state.itemUserDataList[i]['selectedEmployeeNo'],
                    'selectedEmployeeBranch'        :   this.state.itemUserDataList[i]['selectedEmployeeBranch'],
                    'groundOfSeperation'            :   this.state.itemUserDataList[i]['groundOfSeperation'],
                    'caseNumeber'                   :   this.state.itemUserDataList[i]['caseNumeber'],
                    'dateFiled'                     :   this.state.itemUserDataList[i]['dateFiled'],
                    'dateReceived'                  :   this.state.itemUserDataList[i]['dateReceived'],
                    'complaint'                     :   this.state.itemUserDataList[i]['complaint'],
                    'scheduleOfHearing'             :   this.state.itemUserDataList[i]['scheduleOfHearing'],
                    'venue'                         :   this.state.itemUserDataList[i]['venue'],
                    'hearingOfficer'                :   this.state.itemUserDataList[i]['hearingOfficer'],
                    'caseNoNLRC'                    :   this.state.itemUserDataList[i]['caseNoNLRC'],
                    'mandatoryDateFiled'            :   this.state.itemUserDataList[i]['mandatoryDateFiled'],
                    'mandatoryDateReceived'         :   this.state.itemUserDataList[i]['mandatoryDateReceived'],
                    'mandatoryComplaint'            :   this.state.itemUserDataList[i]['mandatoryComplaint'],
                    'mandatoryScheduleOfHearing'    :   this.state.itemUserDataList[i]['mandatoryScheduleOfHearing'],
                    'mandatoryVenue'                :   this.state.itemUserDataList[i]['mandatoryVenue'],
                    'mandatoryLaborArbiter'         :   this.state.itemUserDataList[i]['mandatoryLaborArbiter'],                        
                    'particularsMomStConfMeeting'   :   this.state.itemUserDataList[i]['particularsMomStConfMeeting'],
                    'particularsMomNdConfMeeting'   :   this.state.itemUserDataList[i]['particularsMomNdConfMeeting'],
                    'particularsQuitclaim'          :   this.state.itemUserDataList[i]['particularsQuitclaim'],
                    'particularsMonetaryClaim'      :   this.state.itemUserDataList[i]['particularsMonetaryClaim'],
                    'positionPaper'                 :   this.state.itemUserDataList[i]['positionPaper'],
                    'reply'                         :   this.state.itemUserDataList[i]['reply'],
                    'rejoinder'                     :   this.state.itemUserDataList[i]['rejoinder'],
                    'decision'                      :   this.state.itemUserDataList[i]['decision'],
                    'counsel'                       :   this.state.itemUserDataList[i]['counsel'],
                    'latestUpdate'                  :   this.state.itemUserDataList[i]['latestUpdate'],
                    'statusDate'                    :   this.state.itemUserDataList[i]['statusDate'],
                    'remarks'                       :   this.state.itemUserDataList[i]['remarks'],
                    'updateStatus'                  :   this.state.itemUserDataList[i]['updateStatus'], 
                    'attachedDoc'                   :   this.state.itemUserDataList[i]['attachedDoc'],
                    'reportedByEmployee'            :   this.state.itemUserDataList[i]['reportedByEmployee'],
                    'reportedByPosition'            :   this.state.itemUserDataList[i]['reportedByPosition'],
                    'reportedByClient'              :   this.state.itemUserDataList[i]['reportedByClient'],   
                    'action'                        :   "edit",                      
                    'isDraft'                       :   this.state.itemUserDataList[i]['isDraft'],
                    'isDeleted'                     :   this.state.itemUserDataList[i]['isDeleted'],
                    'createdby'                     :   this.state.itemUserDataList[i]['createdby'],
                    'createddate'                   :   this.state.itemUserDataList[i]['createddate'],
                    'modifiedby'                    :   this.state.itemUserDataList[i]['modifiedby'],
                    'modifieddate'                  :   this.state.itemUserDataList[i]['modifieddate'],
                    'isModified'                    :   this.state.itemUserDataList[i]['isModified'],
                    /*   'count'                         :   countTpm,
                    'totalcases'                    :   totalcases,
                    'pending'                       :   pendingText,
                    'closed'                        :   closedText, */
                }
                let countTmp = this.state.itemUserDataList.length;                

                List.push(obj)

                
                this.setState({
                    totalDoleCasesList:countTmp,
                    employeeDetailList:List
                })                
            }

            this.getTotalstatus();
            this.getClosedStatus();                  

        })
        .catch(error=>{
            console.log("error: " + error)
        })
    }

    getPendingCount =()=>{
    
        let filter_data ={'updateStatus':this.state.countStatus}
        const getParams ={
            "_collection" : "TestDoleCases",
            "filter_data" : filter_data
        }
            //console.log("axios start")
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
            //console.log("res.getPendingCount start")
            //console.log(res.data)
            //console.log("res.getPendingCount end")
        
            const itemUsersDataLists = this.buildList(data["TestDoleCases"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                    
                let obj = {
                    'id'                            :   this.state.itemUserDataList[i]['id'],
                    'selectedClientName'            :   this.state.itemUserDataList[i]['selectedClientName'],
                    'selectedEmployeeName'          :   this.state.itemUserDataList[i]['selectedEmployeeName'],
                    'selectedPosition'              :   this.state.itemUserDataList[i]['selectedPosition'],
                    'selectedEmployeeNo'            :   this.state.itemUserDataList[i]['selectedEmployeeNo'],
                    'selectedEmployeeBranch'        :   this.state.itemUserDataList[i]['selectedEmployeeBranch'],
                    'groundOfSeperation'            :   this.state.itemUserDataList[i]['groundOfSeperation'],
                    'caseNumeber'                   :   this.state.itemUserDataList[i]['caseNumeber'],
                    'dateFiled'                     :   this.state.itemUserDataList[i]['dateFiled'],
                    'dateReceived'                  :   this.state.itemUserDataList[i]['dateReceived'],
                    'complaint'                     :   this.state.itemUserDataList[i]['complaint'],
                    'scheduleOfHearing'             :   this.state.itemUserDataList[i]['scheduleOfHearing'],
                    'venue'                         :   this.state.itemUserDataList[i]['venue'],
                    'hearingOfficer'                :   this.state.itemUserDataList[i]['hearingOfficer'],
                    'caseNoNLRC'                    :   this.state.itemUserDataList[i]['caseNoNLRC'],
                    'mandatoryDateFiled'            :   this.state.itemUserDataList[i]['mandatoryDateFiled'],
                    'mandatoryDateReceived'         :   this.state.itemUserDataList[i]['mandatoryDateReceived'],
                    'mandatoryComplaint'            :   this.state.itemUserDataList[i]['mandatoryComplaint'],
                    'mandatoryScheduleOfHearing'    :   this.state.itemUserDataList[i]['mandatoryScheduleOfHearing'],
                    'mandatoryVenue'                :   this.state.itemUserDataList[i]['mandatoryVenue'],
                    'mandatoryLaborArbiter'         :   this.state.itemUserDataList[i]['mandatoryLaborArbiter'],                        
                    'particularsMomStConfMeeting'   :   this.state.itemUserDataList[i]['particularsMomStConfMeeting'],
                    'particularsMomNdConfMeeting'   :   this.state.itemUserDataList[i]['particularsMomNdConfMeeting'],
                    'particularsQuitclaim'          :   this.state.itemUserDataList[i]['particularsQuitclaim'],
                    'particularsMonetaryClaim'      :   this.state.itemUserDataList[i]['particularsMonetaryClaim'],
                    'positionPaper'                 :   this.state.itemUserDataList[i]['positionPaper'],
                    'reply'                         :   this.state.itemUserDataList[i]['reply'],
                    'rejoinder'                     :   this.state.itemUserDataList[i]['rejoinder'],
                    'decision'                      :   this.state.itemUserDataList[i]['decision'],
                    'counsel'                       :   this.state.itemUserDataList[i]['counsel'],
                    'latestUpdate'                  :   this.state.itemUserDataList[i]['latestUpdate'],
                    'statusDate'                    :   this.state.itemUserDataList[i]['statusDate'],
                    'remarks'                       :   this.state.itemUserDataList[i]['remarks'],
                    'updateStatus'                  :   this.state.itemUserDataList[i]['updateStatus'], 
                    'attachedDoc'                   :   this.state.itemUserDataList[i]['attachedDoc'],
                    'reportedByEmployee'            :   this.state.itemUserDataList[i]['reportedByEmployee'],
                    'reportedByPosition'            :   this.state.itemUserDataList[i]['reportedByPosition'],
                    'reportedByClient'              :   this.state.itemUserDataList[i]['reportedByClient'],                       
                    'isDraft'                       :   this.state.itemUserDataList[i]['isDraft'],
                    'isDeleted'                     :   this.state.itemUserDataList[i]['isDeleted'],
                    'createdby'                     :   this.state.itemUserDataList[i]['createdby'],
                    'createddate'                   :   this.state.itemUserDataList[i]['createddate'],
                    'modifiedby'                    :   this.state.itemUserDataList[i]['modifiedby'],
                    'modifieddate'                  :   this.state.itemUserDataList[i]['modifieddate'],
                    'isModified'                    :   this.state.itemUserDataList[i]['isModified'],
                    /*   'count'                         :   countTpm,
                    'totalcases'                    :   totalcases,
                    'pending'                       :   pendingText,
                    'closed'                        :   closedText, */
                }
                let countTmp = this.state.itemUserDataList.length;                

                List.push(obj)
                //console.log("getPendingCount1")
                //console.log(countTmp)
                //console.log("getPendingCount1")

                
                this.setState({
                    pendingsCount:countTmp

                })
                
            }
            this.getMonitoring()                  

        })
        .catch(error=>{
            //console.log("error: " + error)
        })
    }

    getTotalClosedCount =()=>{
    
        let filter_data ={'updateStatus':this.state.fliterClose}
        const getParams ={
            "_collection" : "TestDoleCases",
            "filter_data" : filter_data
        }
            //console.log("axios start")
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
        
            const itemUsersDataLists = this.buildList(data["TestDoleCases"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                    
                let obj = {
                    'id'                            :   this.state.itemUserDataList[i]['id'],
                    'selectedClientName'            :   this.state.itemUserDataList[i]['selectedClientName'],
                    'selectedEmployeeName'          :   this.state.itemUserDataList[i]['selectedEmployeeName'],
                    'selectedPosition'              :   this.state.itemUserDataList[i]['selectedPosition'],
                    'selectedEmployeeNo'            :   this.state.itemUserDataList[i]['selectedEmployeeNo'],
                    'selectedEmployeeBranch'        :   this.state.itemUserDataList[i]['selectedEmployeeBranch'],
                    'groundOfSeperation'            :   this.state.itemUserDataList[i]['groundOfSeperation'],
                    'caseNumeber'                   :   this.state.itemUserDataList[i]['caseNumeber'],
                    'dateFiled'                     :   this.state.itemUserDataList[i]['dateFiled'],
                    'dateReceived'                  :   this.state.itemUserDataList[i]['dateReceived'],
                    'complaint'                     :   this.state.itemUserDataList[i]['complaint'],
                    'scheduleOfHearing'             :   this.state.itemUserDataList[i]['scheduleOfHearing'],
                    'venue'                         :   this.state.itemUserDataList[i]['venue'],
                    'hearingOfficer'                :   this.state.itemUserDataList[i]['hearingOfficer'],
                    'caseNoNLRC'                    :   this.state.itemUserDataList[i]['caseNoNLRC'],
                    'mandatoryDateFiled'            :   this.state.itemUserDataList[i]['mandatoryDateFiled'],
                    'mandatoryDateReceived'         :   this.state.itemUserDataList[i]['mandatoryDateReceived'],
                    'mandatoryComplaint'            :   this.state.itemUserDataList[i]['mandatoryComplaint'],
                    'mandatoryScheduleOfHearing'    :   this.state.itemUserDataList[i]['mandatoryScheduleOfHearing'],
                    'mandatoryVenue'                :   this.state.itemUserDataList[i]['mandatoryVenue'],
                    'mandatoryLaborArbiter'         :   this.state.itemUserDataList[i]['mandatoryLaborArbiter'],                        
                    'particularsMomStConfMeeting'   :   this.state.itemUserDataList[i]['particularsMomStConfMeeting'],
                    'particularsMomNdConfMeeting'   :   this.state.itemUserDataList[i]['particularsMomNdConfMeeting'],
                    'particularsQuitclaim'          :   this.state.itemUserDataList[i]['particularsQuitclaim'],
                    'particularsMonetaryClaim'      :   this.state.itemUserDataList[i]['particularsMonetaryClaim'],
                    'positionPaper'                 :   this.state.itemUserDataList[i]['positionPaper'],
                    'reply'                         :   this.state.itemUserDataList[i]['reply'],
                    'rejoinder'                     :   this.state.itemUserDataList[i]['rejoinder'],
                    'decision'                      :   this.state.itemUserDataList[i]['decision'],
                    'counsel'                       :   this.state.itemUserDataList[i]['counsel'],
                    'latestUpdate'                  :   this.state.itemUserDataList[i]['latestUpdate'],
                    'statusDate'                    :   this.state.itemUserDataList[i]['statusDate'],
                    'remarks'                       :   this.state.itemUserDataList[i]['remarks'],
                    'updateStatus'                  :   this.state.itemUserDataList[i]['updateStatus'], 
                    'attachedDoc'                   :   this.state.itemUserDataList[i]['attachedDoc'],
                    'reportedByEmployee'            :   this.state.itemUserDataList[i]['reportedByEmployee'],
                    'reportedByPosition'            :   this.state.itemUserDataList[i]['reportedByPosition'],
                    'reportedByClient'              :   this.state.itemUserDataList[i]['reportedByClient'],                       
                    'isDraft'                       :   this.state.itemUserDataList[i]['isDraft'],
                    'isDeleted'                     :   this.state.itemUserDataList[i]['isDeleted'],
                    'createdby'                     :   this.state.itemUserDataList[i]['createdby'],
                    'createddate'                   :   this.state.itemUserDataList[i]['createddate'],
                    'modifiedby'                    :   this.state.itemUserDataList[i]['modifiedby'],
                    'modifieddate'                  :   this.state.itemUserDataList[i]['modifieddate'],
                    'isModified'                    :   this.state.itemUserDataList[i]['isModified'],
                   
                }
                let countTmp = this.state.itemUserDataList.length;                

                List.push(obj)
                console.log("getPendingCount1")
                console.log(countTmp)
                console.log("getPendingCount1")

                
                this.setState({
                    totalClosedCount:countTmp

                })
                
            }
            this.getClosedStatus();                 

        })
        .catch(error=>{
            //console.log("error: " + error)
        })
    }
        
    buildList(data) {
        ////console.log("data start")
        let itemList =[]
    
        let idList                          =[]
        let selectedClientNameList          =[]
        let selectedEmployeeNameList        =[]
        let selectedPositionList            =[]
        let selectedEmployeeNoList          =[]
        let selectedEmployeeBranchList      =[]
        let groundOfSeperationList          =[]
        let caseNumeberList                 =[]
        let dateFiledList                   =[]
        let dateReceivedList                =[]
        let complaintList                   =[]
        let scheduleOfHearingList           =[]
        let venueList                       =[]
        let hearingOfficerList              =[]
        let caseNoNLRCList                  =[]
        let mandatoryDateFiledList          =[]
        let mandatoryDateReceivedList       =[]
        let mandatoryComplaintList          =[]
        let mandatoryScheduleOfHearingList  =[]
        let mandatoryVenueList              =[]
        let mandatoryLaborArbiterList       =[]
        let particularsMomStConfMeetingList =[]
        let particularsMomNdConfMeetingList =[]
        let particularsQuitclaimList        =[]
        let particularsMonetaryClaimList    =[]
        let positionPaperList               =[]
        let replyList                       =[]
        let rejoinderList                   =[]
        let decisionList                    =[]
        let counselList                     =[]
        let latestUpdateList                =[]
        let statusDateList                  =[]
        let remarksList                     =[]
        let updateStatusList                =[]
        let attachedDocList                 =[]
        let reportedByEmployeeList          =[]
        let reportedByPositionList          =[]
        let reportedByClientList            =[]

        let isDraftList                     =[]
        let isDeletedList                   =[]
        let createdbyList                   =[]
        let createddateList                 =[]
        let modifiedbyList                  =[]
        let modifieddateList                =[]
        let isModifiedList                  =[]
        
    
        for (let i = 0; i < data.length; i++) {
            
    
            let s1 = data[i].split("':")
            
            let idClean                         = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                        = idClean.split("',")[0]
            let selectedClientNameTmp           = s1[2].split("',")[0]
            let selectedEmployeeNameTmp         = s1[3].split("',")[0]
            let selectedPositionTmp             = s1[4].split("',")[0]
            let selectedEmployeeNoTmp           = s1[5].split("',")[0]
            let selectedEmployeeBranchTmp       = s1[6].split("',")[0]
            let groundOfSeperationTmp           = s1[7].split("',")[0]
            let caseNumeberTmp                  = s1[8].split("',")[0]
            let dateFiledTmp                    = s1[9].split("',")[0]
            let dateReceivedTmp                 = s1[10].split("',")[0]
            let complaintTmp                    = s1[11].split("',")[0]
            let scheduleOfHearingTmp            = s1[12].split("',")[0]
            let venueTmp                        = s1[13].split("',")[0]
            let hearingOfficerTmp               = s1[14].split("',")[0]
            let caseNoNLRCTmp                   = s1[15].split("',")[0]
            let mandatoryDateFiledTmp           = s1[16].split("',")[0]
            let mandatoryDateReceivedTmp        = s1[17].split("',")[0]
            let mandatoryComplaintTmp           = s1[18].split("',")[0]
            let mandatoryScheduleOfHearingTmp   = s1[19].split("',")[0]
            let mandatoryVenueTmp               = s1[20].split("',")[0]
            let mandatoryLaborArbiterTmp        = s1[21].split("',")[0]
            let particularsMomStConfMeetingTmp  = s1[22].split("',")[0]
            let particularsMomNdConfMeetingTmp  = s1[23].split("',")[0]
            let particularsQuitclaimTmp         = s1[24].split("',")[0]
            let particularsMonetaryClaimTmp     = s1[25].split("',")[0]
            let positionPaperTmp                = s1[26].split("',")[0]
            let replyTmp                        = s1[27].split("',")[0]
            let rejoinderTmp                    = s1[28].split("',")[0]
            let decisionTmp                     = s1[29].split("',")[0]
            let counselTmp                      = s1[30].split("',")[0]
            let latestUpdateTmp                 = s1[31].split("',")[0]
            let statusDateTmp                   = s1[32].split("',")[0]
            let remarksTmp                      = s1[33].split("',")[0]
            let updateStatusTmp                 = s1[34].split("',")[0]
            let attachedDocTmp                  = s1[35].split("',")[0]
            let reportedByEmployeeTmp           = s1[36].split("',")[0]
            let reportedByPositionTmp           = s1[37].split("',")[0]
            let reportedByClientTmp             = s1[38].split("',")[0]

            let isDraftListTmp                  = s1[39].split("',")[0]
            let isDeletedListTmp                = s1[40].split("',")[0]
            let createdbyListTmp                = s1[41].split("',")[0]
            let createddateListTmp              = s1[42].split("',")[0]
            let modifiedbyListTmp               = s1[43].split("',")[0]
            let modifieddateListTmp             = s1[44].split("',")[0]
            let isModifiedListTmp               = s1[45].split("',")[0]

        
            idList.push(IdClean2.replace(" '",""))
            selectedClientNameList.push(selectedClientNameTmp.replace(" '",""))
            selectedEmployeeNameList.push(selectedEmployeeNameTmp.replace(" '",""))
            selectedPositionList.push(selectedPositionTmp.replace(" '",""))
            selectedEmployeeNoList.push(selectedEmployeeNoTmp.replace(" '",""))
            selectedEmployeeBranchList.push(selectedEmployeeBranchTmp.replace(" '",""))
            groundOfSeperationList.push(groundOfSeperationTmp.replace(" '",""))
            caseNumeberList.push(caseNumeberTmp.replace(" '",""))
            dateFiledList.push(dateFiledTmp.replace(" '",""))
            dateReceivedList.push(dateReceivedTmp.replace(" '",""))
            complaintList.push(complaintTmp.replace(" '",""))
            scheduleOfHearingList.push(scheduleOfHearingTmp.replace(" '",""))
            venueList.push(venueTmp.replace(" '",""))
            hearingOfficerList.push(hearingOfficerTmp.replace(" '",""))
            caseNoNLRCList.push(caseNoNLRCTmp.replace(" '",""))
            mandatoryDateFiledList.push(mandatoryDateFiledTmp.replace(" '",""))
            mandatoryDateReceivedList.push(mandatoryDateReceivedTmp.replace(" '",""))
            mandatoryComplaintList.push(mandatoryComplaintTmp.replace(" '",""))
            mandatoryScheduleOfHearingList.push(mandatoryScheduleOfHearingTmp.replace(" '",""))
            mandatoryVenueList.push(mandatoryVenueTmp.replace(" '",""))
            mandatoryLaborArbiterList.push(mandatoryLaborArbiterTmp.replace(" '",""))
            particularsMomStConfMeetingList.push(particularsMomStConfMeetingTmp.replace(" '",""))
            particularsMomNdConfMeetingList.push(particularsMomNdConfMeetingTmp.replace(" '",""))
            particularsQuitclaimList.push(particularsQuitclaimTmp.replace(" '",""))
            particularsMonetaryClaimList.push(particularsMonetaryClaimTmp.replace(" '",""))
            positionPaperList.push(positionPaperTmp.replace(" '",""))
            replyList.push(replyTmp.replace(" '",""))
            rejoinderList.push(rejoinderTmp.replace(" '",""))
            decisionList.push(decisionTmp.replace(" '",""))
            counselList.push(counselTmp.replace(" '",""))
            latestUpdateList.push(latestUpdateTmp.replace(" '",""))
            statusDateList.push(statusDateTmp.replace(" '",""))
            remarksList.push(remarksTmp.replace(" '",""))
            updateStatusList.push(updateStatusTmp.replace(" '","")) 
            attachedDocList.push(attachedDocTmp.replace(" '",""))
            reportedByEmployeeList.push(reportedByEmployeeTmp.replace(" '",""))
            reportedByPositionList.push(reportedByPositionTmp.replace(" '",""))
            reportedByClientList.push(reportedByClientTmp.replace(" '",""))

            isDraftList.push(isDraftListTmp.replace(" '",""))
            isDeletedList.push(isDeletedListTmp.replace(" '",""))
            createdbyList.push(createdbyListTmp.replace(" '",""))
            createddateList.push(createddateListTmp.replace(" '",""))
            modifiedbyList.push(modifiedbyListTmp.replace(" '",""))
            modifieddateList.push(modifieddateListTmp.replace(" '",""))
            isModifiedList.push(isModifiedListTmp.replace(" '","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                            :   idList[i],
                'selectedClientName'            :   selectedClientNameList[i],
                'selectedEmployeeName'          :   selectedEmployeeNameList[i],
                'selectedPosition'              :   selectedPositionList[i],
                'selectedEmployeeNo'            :   selectedEmployeeNoList[i],
                'selectedEmployeeBranch'        :   selectedEmployeeBranchList[i],
                'groundOfSeperation'            :   groundOfSeperationList[i],
                'caseNumeber'                   :   caseNumeberList[i],
                'dateFiled'                     :   dateFiledList[i],
                'dateReceived'                  :   dateReceivedList[i],
                'complaint'                     :   complaintList[i],
                'scheduleOfHearing'             :   scheduleOfHearingList[i],
                'venue'                         :   venueList[i],
                'hearingOfficer'                :   hearingOfficerList[i],
                'caseNoNLRC'                    :   caseNoNLRCList[i],
                'mandatoryDateFiled'            :   mandatoryDateFiledList[i],
                'mandatoryDateReceived'         :   mandatoryDateReceivedList[i],
                'mandatoryComplaint'            :   mandatoryComplaintList[i],
                'mandatoryScheduleOfHearing'    :   mandatoryScheduleOfHearingList[i],
                'mandatoryVenue'                :   mandatoryVenueList[i],
                'mandatoryLaborArbiter'         :   mandatoryLaborArbiterList[i],
                'particularsMomStConfMeeting'   :   particularsMomStConfMeetingList[i],
                'particularsMomNdConfMeeting'   :   particularsMomNdConfMeetingList[i],
                'particularsQuitclaim'          :   particularsQuitclaimList[i],
                'particularsMonetaryClaim'      :   particularsMonetaryClaimList[i],
                'positionPaper'                 :   positionPaperList[i],
                'reply'                         :   replyList[i],
                'rejoinder'                     :   rejoinderList[i],
                'decision'                      :   decisionList[i],
                'counsel'                       :   counselList[i],
                'latestUpdate'                  :   latestUpdateList[i],
                'statusDate'                    :   statusDateList[i],
                'remarks'                       :   remarksList[i],
                'updateStatus'                  :   updateStatusList[i],
                'attachedDoc'                   :   attachedDocList[i],
                'reportedByEmployee'            :   reportedByEmployeeList[i],
                'reportedByPosition'            :   reportedByPositionList[i],
                'reportedByClient'              :   reportedByClientList[i],

                'isDraft'                       :   isDraftList[i],
                'isDeleted'                     :   isDeletedList[i],
                'createdby'                     :   createdbyList[i],
                'createddate'                   :   createddateList[i],
                'modifiedby'                    :   modifiedbyList[i],
                'modifieddate'                  :   modifieddateList[i],
                'isModified'                    :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }
        
        return itemList
    }

    getMemorandumList =()=>{

        let filter_data ={'client':this.state.client}

        const getParams ={
            "_collection" : "TestNTE",
            "filter_data" : filter_data
        }

        console.log("axios start")
        console.log(getParams)

        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data
            console.log("Raw Json")
            console.log(data)
            console.log("End Raw Json")
            var obj = (data);
            var res = [];
            
            data.TestNTE.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                res.push(json)
            })

            this.setState({
                employeeDetailList: res,
                isloading:false
            })
            
        })
        .catch(error=>{
            console.log("error: " + error)
        })
        
    } 
    
    handleExportCVS=(e,props)=>{
       
        props.onExport();
        
    }

    onChangeparticular = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedparticular     :   '',
                selectedparticularId   :   ''
            })
            return
        }
        this.state.selectedparticular = e[0].name
        this.state.selectedparticularId = e[0].id
       
        this.setState({
            isshow   :   false,
           selectedparticular : e[0].name

        })
        //console.log(this.state.selectedparticular)
        this.getMemorandumListByStatus();
    }

    handleToDoleCases=(row)=>{
        console.log(row.id)

        let rowtmp = row.id

        sessionStorage.setItem("updateDoleCase", JSON.stringify(row))
        let checkTmp = "1"
        sessionStorage.setItem("checkUpdate", JSON.stringify(checkTmp))
        
    }


    render() {
        const { ExportCSVButton } = CSVExport;
        
        const statusColumn = [
            
            {
                dataField   : 'status',
                text:'Status',
                headerStyle : () => {
                    return { minWidth  : "865px"};
                }
            },
            {
                dataField: 'count',
                text: 'Count',
                headerStyle : () => {
                    return { minWidth  : "870px" };
                }
            },
           /*  {
                dataField   : 'persentage',
                text        : '%',
                headerStyle : () => {
                    return { width  : "33.3333333333%" };
                }
            }, */
            /*{
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={this.showTrainingRegisterModal}
                        >View Details</Button>
                    );
                },
            }, */
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.statusTableList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };

        const employeeDetailColumn = [
            
            {
                dataField   : 'client',
                text:'Client',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },  
            {
                dataField   : 'employeeName',
                text        : 'Employee Name',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'position',
                text        : 'Position',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'location',
                text        : 'Location',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'date',
                text        : 'Date',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'referenceNoNTE',
                text        : 'NTE REFERENCE NO',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'irReferenceNo',
                text        : 'IR Reference No.',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'memoType',
                text        : 'Memo Type',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'status',
                text        : 'status',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            

           /*  {
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (cell)
                    return (
                        
                        <ButtonToolbar className="mt-5">                                               
                            <NavLink to="/DoleCases" >
                                <Button variant="Link" onClick={(e)=> this.handleToDoleCases(row)} href="/DoleCases" style={{color: "#1c6dfe"}}>Edit</Button>&nbsp;&nbsp;
                            </NavLink>
                        </ButtonToolbar>  
                    );
                },
            }, */
        ]

        const selectemployeeDetailRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.employeeDetailList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEmployeeDetailEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
       
        
    return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>MEMORANDUM MONITORING</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>

                                {/* <Card.Header>SUMMARY</Card.Header>                                
                                
                                <Form.Group as={Row} controlId="formPlaintextEmail"> </Form.Group>

                                <ToolkitProvider
                                   
                                    striped
                                    hover
                                    condensed
                                    rowClasses="noser-table-row-class"
                                    rowEvents={ rowEvents }
                                    selectRow = { selectRow }
                                    keyField="id"   
                                    data={ this.state.statusTableList }
                                    columns={ statusColumn }
                                    exportCSV={ {
                                        fileName: "Dole Cases Monitoring Summary.csv",
                                    } }
                                >
                                    {
                                        props => (
                                            <div className="mt-1" >
                                                <hr />
                                                <BootstrapTable { ...props.baseProps } />
                                                <ButtonToolbar>
                                                    <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export Summary</ExportCSVButton>
                                                </ButtonToolbar>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider> */}

                                <Card.Header className="mt-3">REPORT FILTERING</Card.Header>

                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Client
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeClientList}
                                            options={this.state.getClientList}
                                            placeholder="Select Client"
                                        />
                                    </Col>
                                </Form.Group>

                               {/*  <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Status
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeparticular}
                                            options={this.state.particularList}
                                            //placeholder="select penalty type"
                                        />
                                    </Col>
                                </Form.Group> */}
                                
                                <ToolkitProvider
                                    keyField="id"   
                                    data={ this.state.employeeDetailList }
                                    columns={ employeeDetailColumn }
                                    exportCSV={ {
                                        fileName: "Dole Cases Monitoring.csv",
                                    } }
                                >
                                    {
                                        props => (
                                            <div>
                                                <hr />
                                                <BootstrapTable { ...props.baseProps } />
                                                <ButtonToolbar>
                                                    <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export Report</ExportCSVButton>                                                       
                                                    {/* <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleUpdateClick}>
                                                        Save
                                                    </Button> */}&nbsp;&nbsp;
                                                    <NavLink to="/home">
                                                        <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                                            Close
                                                        </Button>
                                                    </NavLink>
                                                </ButtonToolbar>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                            </Form>
                        </Card.Body>
                    </Card>                    
                </Container>
                <NoserLoading show={this.state.isloading} />
                  
            </div> 
        )
    }

}

export  default MemorandumMonitoring 
