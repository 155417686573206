import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Redirect
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

class PostTrainingExamination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            disableSaveButton:true,

            trainingId      :   "",
            examId          :   "",
            examName        :   "",
            trainingDDL     :   [],
            examDDL         :   [],
            requiredDDL     :   [{"value":"1","label":"MANDATORY"},{"value":"0","label":"OPTIONAL"}],
            yesnoDDL        :   [{ "value" : 1, "label" : "CORRECT" },{ "value" : 0, "label" : "INCORRECT" }],
            examGrid        :   []
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetTrainings()
        this.GetPostExamFilter()
    }
    GetTrainings = async() => {
        this.setState({isloading:true})

        const param = {
            "IpAddress":   "0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":"",
            "TrainingId":"",
            "Name":"",
        };
        await axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetTrainings",  param
            )
            .then(res => {
                const data = res.data;
                this.setState({trainingDDL:data.trainings,isloading:false })
                if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
                
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    }
    GetPostExamFilter = async() => {
        this.setState({isloading:true})

        const param = {
            "IpAddress":   "0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.userinfo.clientId,
            "TrainingId":this.state.trainingId,
            "Name":"",
        };
        await axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Training/GetPostExaminationFilter",  param
            )
            .then(res => {
                const data = res.data;
                this.setState({examDDL:data.postExaminations,isloading:false })
                if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
                
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    }
    handleChangedTraining = (e) => {
        if(e.length == 0) {
            this.state.trainingId=""
            return
        }
        this.state.selectedTrainingId=e[0].id
        this.setState({isshow:false})
        this.GetPostExamFilter()
    }
    handleChangedExam = (e) => {
        if(e.length === 0) {
            this.state.examId=""
            this.state.examName=""
            return
        }
        this.state.examId=e[0].id
        this.state.examName=e[0].name
        this.setState({isshow:false})
    }
    handleSearchClick = async() => {
        this.setState({isloading:true})

        const param = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.userinfo.clientId,
            "TrainingId"    :   this.state.trainingId,
            "Name"          :   this.state.examName
        };
        await axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Training/GetPostExaminations",  param
            )
            .then(res => {
                const data = res.data;
                this.setState({examGrid:data.postExaminations,isloading:false})
                if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
                
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    }
    handleSaveClick = async() =>{
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.userinfo.clientId,
            "postExaminations" :this.state.examGrid.filter(x=>x.isModified=="1")
        };
        console.log(param)
        return 
        await axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Training/EditPostExamination",  param
            )
            .then(res => {
            const data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   data.status==="1" ? "Success!" : "Error!",
                isshow      :   true,
                color       :   data.status==="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true
            });
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        }) 
    }
    GridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.examGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
        this.setState({disableSaveButton:disable})
    }
    QuestionGridModified(oldValue, newValue, row, column) {
        let disable = true
        this.state.examGrid.map(function(item,i) {
            item.questions.map(function(question){
                if (question.id===row.id){
                    question.isModified = newValue!=oldValue ? "1" : "0"
                    item.isModified = question.isModified
                    disable = newValue!=oldValue ? false : true
                }
            })
        })
        this.setState({disableSaveButton:disable})
    }
    ChoiceGridModified(oldValue, newValue, row, column) {
        let disable = true
        this.state.examGrid.map(function(item,i) {
            item.questions.map(function(question){
                question.choices.map(function(choice){
                    if (choice.id===row.id){
                        choice.isModified = newValue!=oldValue ? "1" : "0"
                        item.isModified = choice.isModified
                        disable = newValue!=oldValue ? false : true
                    }
                })
            })
        })
        this.setState({disableSaveButton:disable})
    }
    render() {
        const examCol = [
            {
                dataField: 'training',
                text: 'Training',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField: 'name',
                text: 'Exam Name',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField: 'isRequired',
                text: 'REQUIRED',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center'},
                editor: {
                    type: Type.SELECT,
                    options: this.state.requiredDDL
                  },
                formatter: (cell, row) => {
                    if(row.isRequired!=='' && row.isRequired!==null){
                        return this.state.requiredDDL.find(x => x.value == cell).label;
                    }
                }
            },
            {
                dataField   : 'noOfItems',
                text        : 'No. Of Items',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center'},
            },
            {
                dataField   : 'timeLimit',
                text        : 'Time Limit',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center'},
            },
            {
                dataField   : 'passingScore',
                text        : 'Passing Score',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center'},
            },
            {
                dataField   : 'instructions',
                text        : 'Instruction',
                headerStyle : () => {
                    return { width  : "30%" };
                }
            }
        ]
        const questionCol =[
            {
                dataField: 'name',
                text: 'Questions',
                editable: true,
                headerStyle : () => {
                    return { width  : "60%" };
                }
            },
            {
                dataField: 'answer',
                text: 'Answer',
                editable: true,
                headerStyle : () => {
                    return { width  : "40%" };
                }
            }
        ]
        const choiceCol =[
            {
                dataField: 'name',
                text: 'Choice',
                editable: true,
                headerStyle : () => {
                    return { width  : "50%" };
                }
            },
            {
                dataField: 'answer',
                text: 'Answer',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'50%'}},
                style:{textAlign:'left'},
                editor: {
                    type: Type.SELECT,
                    options: this.state.yesnoDDL
                  },
                formatter: (cell, row) => {
                    if(row.answer!=='' && row.answer!==null){
                        return this.state.yesnoDDL.find(x => x.value == cell).label;
                    }
                }
            }
        ]
        const selectQuestionRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                // let isDisable = true
                // row.questions.map(function(itm){
                //     itm.isDeleted=isSelect ? "1" : "0"
                //     if(itm.isDeleted=="1")
                //         isDisable=false
                // })
                // this.setState({disableSaveButton:isDisable})
            },
            onSelectAll: (isSelect, rows) => {
                // let isDisable = true
                // this.state.questionGrid.map(function(itm){
                //     itm.isDeleted=isSelect ? "1" : "0"
                //     if(itm.isDeleted=="1")
                //         isDisable=false
                // })
                // this.setState({
                //     disableRemoveButton:isDisable,
                //     disableAddButton:parseInt(this.state.noOfItems)<=this.state.questionGrid.length ? true:false
                // })
            }
        };
        const selectChoiceRow = {
            mode: 'checkbox',
            hideSelectAll: true
        };
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.examGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableSaveButton:isDisable})
            }
        };
        const expandQuestion = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <div>
                    <BootstrapTable
                        keyField = "id"
                        data = { row.questions }
                        columns = { questionCol }
                        rowClasses="noser-table-row-class no-checkbox"
                        striped
                        hover
                        condensed
                        selectRow = { selectQuestionRow }
                        cellEdit = { cellEditFactory({ 
                            mode: 'dbclick', 
                            blurToSave: true,
                            afterSaveCell: (oldValue, newValue, row, column) => { 
                                this.QuestionGridModified(oldValue, newValue, row, column.dataField)
                            }
                        })}
                        expandRow={ expandChoices }
                    />
                </div>
            ),
            showExpandColumn: true
          };
        const expandChoices = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <div>
                    <BootstrapTable
                        keyField = "id"
                        data = { row.choices }
                        columns = { choiceCol }
                        rowClasses="noser-table-row-class no-checkbox"
                        striped
                        hover
                        condensed
                        selectRow = { selectChoiceRow }
                        cellEdit = { cellEditFactory({ 
                            mode: 'dbclick', 
                            blurToSave: true,
                            afterSaveCell: (oldValue, newValue, row, column) => { 
                                this.ChoiceGridModified(oldValue, newValue, row, column.dataField)
                            }
                        })}
                        noDataIndication={ () =>  <div style={{width:'100%'}}>specific answer</div> }
                    />
                </div>
            ),
            showExpandColumn: true
          };
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>MAINTENANCE >> TRAINING MAINTENANCE  >> POST TRAINING EXAMINATION</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            TRAINING NAME
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangedTraining}
                                                options={this.state.trainingDDL}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            EXAM NAME
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangedExam}
                                                options={this.state.examDDL}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <ButtonToolbar className="mt-1">
                                        <Button variant="success" className="ml-auto noser-button" onClick={ this.handleSearchClick }>Search</Button>
                                    </ButtonToolbar>
                                    <Card.Header className="mt-3">List Of Post Examinations</Card.Header>
                                    <div>
                                        <BootstrapTable
                                            keyField = "id"
                                            data = { this.state.examGrid }
                                            columns = { examCol }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            selectRow = { selectRow }
                                            expandRow={ expandQuestion }
                                        />
                                    </div>
                                    <ButtonToolbar sm={12}>
                                        <Button disabled={this.state.disableSaveButton} variant="success" className="ml-auto noser-button-mr1" onClick={this.handleSaveClick}>Save</Button>
                                        <NavLink to="/posttrainingexaminationcreate" >
                                            <Button className="noser-button"  variant="primary">
                                                Create
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />
            </div> 
        )
    }

}

export  default PostTrainingExamination 
