import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import AdminCasesCreate from '../Maintenance/AdminCasesCreate';

 
class AdminCasesSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            trainingRegisterTableList          :   [ ],
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalTrainingRegisterShow           :   false,

            category:"",

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getMaintenanceCase()
    }

    showTrainingRegisterModal = (e) => {
        this.setState({modalTrainingRegisterShow: true})
    }
    handleModalClose = (e) =>{
        //reload or refresh data here
        //alert("Data pass from child : " + e)
        this.setState({modalTrainingRegisterShow: false})
    }


    GridDataModified(oldValue, newValue, id, column) {
        ////console.log(id)
        this.state.trainingScheduleTableList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }

   
    getMaintenanceCase =()=>{
       
        let filter_data ={}
        const getParams ={
            "_collection" : "TestAdminCases",
            "filter_data" : filter_data
        }
            console.log("axios start")
            axios
            .post("http://134.209.99.190:8088/action/get" , getParams)
            .then(res =>{
                let data = res.data
                console.log("res.data start")
                console.log(res.data)
                console.log("res.data end")
            
                const itemUsersDataLists = this.buildList(data["TestAdminCases"])
                
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                    let obj = {
                        'id'                    :   this.state.itemUserDataList[i]['id'],
                        'caseType'                :   this.state.itemUserDataList[i]['caseType'],
                        'caseName'                :   this.state.itemUserDataList[i]['caseName'],
                        'category'                :   this.state.itemUserDataList[i]['category'],
                        'isDraft'               :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'             :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'             :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'           :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'            :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'          :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'            :   this.state.itemUserDataList[i]['isModified'],
                        
                    }
                    List.push(obj)
                    console.log(List)
                    
                    this.setState({
                        trainingRegisterTableList:List

                    })
                   
                }
                
               

            })
            .catch(error=>{
                //console.log("error: " + error)
            })
        } 
        
    buildList(data) {
        //console.log("data start")
        let itemList =[]
    
        let idList                      =[]
        let caseTypeList                  =[]
        let caseNameList                  =[]
        let categoryList                  =[]
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
        
    
        for (let i = 0; i < data.length; i++) {
            
    
            let s1 = data[i].split("':")
            
            let idClean                     = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let caseTypeTmp                   = s1[2].split("',")[0]
            let caseNameTmp                   = s1[3].split("',")[0]
            let categoryTmp                   = s1[4].split("',")[0]
            let isDraftListTmp              = s1[5].split("',")[0]
            let isDeletedListTmp            = s1[6].split("',")[0]
            let createdbyListTmp            = s1[7].split("',")[0]
            let createddateListTmp          = s1[8].split("',")[0]
            let modifiedbyListTmp           = s1[9].split("',")[0]
            let modifieddateListTmp         = s1[10].split("',")[0]
            let isModifiedListTmp           = s1[11].split("',")[0]

        
            idList.push(IdClean2.replace("'",""))
            caseTypeList.push(caseTypeTmp.replace("'",""))
            caseNameList.push(caseNameTmp.replace("'",""))
            categoryList.push(categoryTmp.replace("'",""))
            isDraftList.push(isDraftListTmp.replace("'",""))
            isDeletedList.push(isDeletedListTmp.replace("'",""))
            createdbyList.push(createdbyListTmp.replace("'",""))
            createddateList.push(createddateListTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'caseType'                :   caseTypeList[i],
                'caseName'                :   caseNameList[i],
                'category'                :   categoryList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }
        
      
        return itemList
    }

    render() {
        
        const trainingRegisterColumn = [
            {
                dataField   : 'caseName',
                text        : 'Cases name',
                headerStyle : () => {
                    return { width  : "50%" };
                }
            },
            {
                dataField   : 'category',
                text        : 'Category',
                headerStyle : () => {
                    return { width  : "50%" };
                }
            },
            /* {
                dataField: 'training',
                text: 'Training',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'position',
                text        : 'Position',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'client',
                text        : 'Client',
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField   : 'register',
                text        : 'Register',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'attended',
                text        : 'Attended',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={this.showTrainingRegisterModal}
                        >View Details</Button>
                    );
                },
            }, */
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.trainingRegisterTableList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>Maintenance >> Cases Maintenance >> Administrative >> Search </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Case Type
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeTraining}
                                                options={this.state.trainingAutocomplete}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Case Name
                                        </Form.Label>
                                        <Col sm="4">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeTraining}
                                                options={this.state.trainingAutocomplete}
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Category
                                        </Form.Label>
                                        <Col sm="4">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeTraining}
                                                options={this.state.trainingAutocomplete}
                                            />
                                        </Col>
                                    </Form.Group>
                                    
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                    
                                    </Form.Group>
                                   

                                    <ButtonToolbar className="mt-1">
                                        <Button variant="success" className="ml-auto" onClick={ this.handleSearchClick }>
                                            Search
                                        </Button>&nbsp;&nbsp;
                                        <NavLink to="/AdminCasesCreate">
                                            <Button variant="success" href="/AdminCasesCreate" style={{minWidth:'60px'}}>
                                                Create
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>

                                    <Card.Header className="mt-3">List Type of Case</Card.Header>
                                    <div className="mt-1">
                                        <BootstrapTable
                                            /* caption={Noser.TableHeader({title:"RECORD"})} */
                                            keyField = "id"
                                            data = { this.state.trainingRegisterTableList }
                                            columns = { trainingRegisterColumn }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }
                                            selectRow = { selectRow }

                                        />
                                    </div>
                                    <ButtonToolbar sm={12}>
                                        <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleUpdateClick}>
                                            Generate Report
                                        </Button>&nbsp;&nbsp;
                                        <NavLink to="/home">
                                            <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                                Close
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                   
            </div> 
        )
    }

}

export  default AdminCasesSearch
