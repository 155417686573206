import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker,
}
from '../../noser-hris-component';
import filterFactory, { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import {CSVLink, CSVDownload} from 'react-csv';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { DropdownButton, Dropdown} from 'react-bootstrap';

class CustomerService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true,
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            FromDate        :   "",
            ToDate          :   "",

            caseData        :   [],
            ListData        :   [],
            createddate	    : new Date(),
            newDays         : new Date(),
            type            :   "",
            title           :   "",
            description     :   "",
            reply           :   "",
            image           :   "",
            attachment      :   [],
            caseId          :   "",
            users           :   "",
            priorityLevel   :   "",
            deptAccess      :   "",
            totalDays       :   "",
            isDisabledsearch    : false,
            selectedType    : "",
            selectedStatus  : "",
            selectedClientName  : "",

            getPayTypes                     : '',
            selectedEmployeeId              : "",
            selectedWorkScheduleIdParam     : "",
            getPayModes                     : '',
            getLocationNames                : '',
            getEmployeeStatusTypes          : '',
            getSalaryOffered                : '',
            periodId                        : "",
            payPeriodSelectedId             : '',
            payPeriodSelected               :  "",
            clientId                        :  "",
            userId                          :  "",


            getEmployeeList                     :   [],
            getEmployeeListData                 :   [],
            concernSelectHrOfficer              :   [],
            trainingScheduleTableList           :   [],
            getClientList                       :   [],
            GetClientLocationsAutocomplete      :   [],
            selectedClientId                    :   [],
            typeList                            :   [],
            getPayTypesList                     :   [],
            getPayModesList                     :   [],
            getWorkScheduleList                 :   [],
            getPayCardTypesList                 :   [],
            getPayrollPeriodId                  :   [],
            getUsersAutocomplete                :   [],
            userName                            :   [],
            //getEmployeeListId                   :   "",


            newRowList              :   [],
            clearData               :   [],
            selectedHROfficer       :   "",
            selectedEmployeeName    :   "",
            tblEmployeeArrLst       :   [],
            newListCaseData         :   [],
            dataRow                 :   [],
            newListDataRow          :   [],
            gridDataCsv             :   [],
            //gridData                :   [],
            dataEmployeeProfile     :   [],

        }
    }

    componentDidMount(){

        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.setState({userId: this.state.userinfo.userId})

        this.getClientList();
        this.GetCaseList();
        this.getHrOfficer();
        // this.GetEmployeeProfile();


    }

    GridDataModified(oldValue, newValue, id, column) {
        ////// // console.log(id)
        this.state.trainingScheduleTableList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
                // // console.log(item.isModified)
        })
    }

    handleRefreshClick = () => {
        window.location.reload();
        this.GetCaseList()
        this.setState({
            getClientList : [],
        })
    }

/****************** START GET CASE DATA *****************************/

    GetCaseList () {

        /* let userId = await UserInfoComponent.getUserId();
        let userData = await UserInfoComponent.getLogInInfo(userId)
        const [value] = await Promise.all([
            AsyncStorage.getItem(userId),
        ]);
        const json = JSON.parse(value);
        let dataUserTmp = json.data[0].dataUser
        let userFullName = dataUserTmp.fullName
        let roleId = dataUserTmp.roleId
        let roleName = dataUserTmp.roleName */

        let filter_data = { "isDeleted" : "0" }

        /* if (roleId == "1" || roleId == "8" ) {
            filter_data = {}

        }else{
            filter_data = {"createdby" : userId}
        }  */

        const getParams = {
            "_collection" : "CreateCase",
            "filter_data" : filter_data
        }
        axios
            .post("http://43.255.218.198:8086/action/get" , getParams)
            // .post("http://139.59.122.4:8094/action/get" , getParams)
            .then(res => {
            const data = res.data
            // console.log("data")
            // console.log(data)
            const itemDataLists = this.buildListGetCaseList(data["CreateCase"])
            // // // console.log("List Start")

            let List = []
            let newCaseData = []

            for (let i = 0; i < itemDataLists.length; i++) {
                //let UserFullname = this.state.getClientList.filter(x => x.employeeId === itemDataLists[i]['employeeId'].replace(" '","").replace("'",""))
                let priorityLevelTmp1=""
                let statusTmp1=""

                if (itemDataLists[i]['status'] === "" || itemDataLists[i]['priorityLevel'] === "") {
                    statusTmp1 = "New"
                    priorityLevelTmp1 = "1"
                    //// // console.log("priorityLevelTmp =1")
                    //// // console.log("statusTmp new")
                } else if(itemDataLists[i]['status'] !== "" || itemDataLists[i]['priorityLevel'] !== ""){
                    statusTmp1 = itemDataLists[i]['status'].replace(" '","").replace("'","")
                    priorityLevelTmp1 = itemDataLists[i]['priorityLevel'].replace(" '","").replace("'","")
                // // // console.log("priorityLevelTmp ok")
                    //// // console.log("statusTmp ok")

                }

                let obj = {
                    'id'            :   itemDataLists[i]['id'].replace(" '","").replace("'",""),
                    'type'          :   itemDataLists[i]['type'].replace(" '","").replace("'",""),
                    'title'         :   itemDataLists[i]['title'].replace(" '","").replace("'",""),
                    'description'   :   itemDataLists[i]['description'].replace(" '","").replace("'",""),
                    'reply'         :   "",
                    'user'          :   itemDataLists[i]['user'],
                    'status'        :   statusTmp1,
                    'priorityLevel' :   priorityLevelTmp1,
                    'employeeId'    :   itemDataLists[i]['employeeId'].replace(" '","").replace("'",""),
                    'deptAccess'    :   itemDataLists[i]['deptAccess'],//.replace(" '","").replace("'",""),
                    'clientid'    	:   itemDataLists[i]['clientid'],//.replace(" '","").replace("'",""),
                    'isDeleted'     :   itemDataLists[i]['isDeleted'].replace(" '","").replace("'",""),
                    'isDraft'       :   itemDataLists[i]['isDraft'].replace(" '","").replace("'",""),
                    'createdby'     :   itemDataLists[i]['createdby'].replace(" '","").replace("'",""),
                    'createddate'   :   itemDataLists[i]['createddate'].replace(" '","").replace("'",""),
                    'modifiedby'    :   itemDataLists[i]['modifiedby'].replace(" '","").replace("'",""),
                    'modifieddate'  :   itemDataLists[i]['modifieddate'].replace(" '","").replace("'",""),
                    'isModified'    :   itemDataLists[i]['isModified'].replace(" '","").replace("'",""),
                }

                List.push(obj)

            }
            this.setState({ caseData: List.sort((a, b) => new Date(a.createddate) < new Date(b.createddate) ? 1 : -1)})

            // this.setState({ caseData: List.sort((a, b) => a.daysOld < b.daysOld ? 1: -1)})

            // // // console.log(List)
            // // // console.log("List END")

            for (let i = 0; i < this.state.caseData.length; i++){

                    //const test = new Date(endDate) - new Date(startDate)
                    //const FormatDate = test / (1000 * 60 * 60 * 24);
                    //// // console.log(FormatDate)

                if (this.state.caseData[i]['status'] === "CLOSED" || this.state.caseData[i]['status'] === "Closed" || this.state.caseData[i]['status'] === "closed"){

                    const DiffToStopCounting   = new Date(this.state.caseData[i]['modifieddate']) - new Date(this.state.caseData[i]['createddate'])
                    const stopDays = DiffToStopCounting / (1000 * 60 * 60 * 24);
                    // const diffInMs   = new Date(endDate) - new Date(startDate)
                    // const FormatDate = stopDays / (1000 * 60 * 60 * 24);

                    let obj = {
                        'id'            :   this.state.caseData[i]['id'],
                        'type'          :   this.state.caseData[i]['type'],
                        'title'         :   this.state.caseData[i]['title'],
                        'description'   :   this.state.caseData[i]['description'],
                        'reply'         :   this.state.caseData[i]['reply'],
                        'user'          :   this.state.caseData[i]['user'],
                        'status'        :   this.state.caseData[i]['status'],
                        'priorityLevel' :   this.state.caseData[i]['priorityLevel'],
                        'employeeId'    :   this.state.caseData[i]['employeeId'],
                        'deptAccess'    :   this.state.caseData[i]['deptAccess'],
                        'clientid'    	:   this.state.caseData[i]['clientid'],
                        'isDeleted'     :   this.state.caseData[i]['isDeleted'],
                        'isDraft'       :   this.state.caseData[i]['isDraft'],
                        'createdby'     :   this.state.caseData[i]['createdby'],
                        'createdby'     :   this.state.caseData[i]['createdby'],
                        'createddate'   :   this.state.caseData[i]['createddate'],
                        'modifiedby'    :   this.state.caseData[i]['modifiedby'],
                        'modifieddate'  :   this.state.caseData[i]['modifieddate'],
                        'isModified'    :   this.state.caseData[i]['isModified'],
                        "daysOld"       :   String(stopDays).replace("-","")
                    }
                    newCaseData.push(obj)
                }else {
                    const startDate  = this.state.caseData[i]['createddate'];
                    const endDate  = moment(this.state.newDays).format('MM/DD/YYYY');
                    const diffInMs   = new Date(endDate) - new Date(startDate)
                    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

                    let obj = {
                        'id'            :   this.state.caseData[i]['id'],
                        'type'          :   this.state.caseData[i]['type'],
                        'title'         :   this.state.caseData[i]['title'],
                        'description'   :   this.state.caseData[i]['description'],
                        'reply'         :   this.state.caseData[i]['reply'],
                        'user'          :   this.state.caseData[i]['user'],
                        'status'        :   this.state.caseData[i]['status'],
                        'priorityLevel' :   this.state.caseData[i]['priorityLevel'],
                        'employeeId'    :   this.state.caseData[i]['employeeId'],
                        'deptAccess'    :   this.state.caseData[i]['deptAccess'],
                        'clientid'    	:   this.state.caseData[i]['clientid'],
                        'isDeleted'     :   this.state.caseData[i]['isDeleted'],
                        'isDraft'       :   this.state.caseData[i]['isDraft'],
                        'createdby'     :   this.state.caseData[i]['createdby'],
                        'createddate'   :   this.state.caseData[i]['createddate'],
                        'modifiedby'    :   this.state.caseData[i]['modifiedby'],
                        'modifieddate'  :   this.state.caseData[i]['modifieddate'],
                        'isModified'    :   this.state.caseData[i]['isModified'],
                        "daysOld"       :   diffInDays
                    }
                    newCaseData.push(obj)
                }
            }
            this.setState({caseData : newCaseData})
        })
        .catch(error=>{
            //// // console.log("error: " + error)
        })

    }
    buildListGetCaseList = (data) => {

        let itemList =[]

        let idList              =[]
        let typeList            =[]
        let clientIdList        =[]
        let titleList           =[]
        let descriptionList     =[]
        let replyList           =[]
        let userList            =[]
        let statusList          =[]
        let priorityLevelList   =[]
        let employeeIdList      =[]
        let deptAccessList      =[]
        let isDeletedList       =[]
        let isDraftList         =[]
        let createdbyList       =[]
        let createddateList     =[]
        let modifiedbydList     =[]
        let modifieddateList    =[]
        let isModifiedList      =[]

        for (let i = 0; i < data.length; i++) {

            let s1 = data[i].split(":")
            // console.log("start s1")
            // console.log(s1)
            // console.log("end s1")

            // if (s1[10].split(",")[1].replace(" ","").replace("'","").replace("'","") === 'clientid'){

            //     let idClean = s1[1].replace("ObjectId(","").replace(")","").replace("{","").split(",")[0]
            //     let typTmp = s1[2].split(",")[0]/* .replace("'" , "").replace("'" , "") */
            //     let titleTmp = s1[3].split(",")[0]
            //     let descrTmp = s1[4].split(",")[0]
            //     let replyTmp = s1[5].split(",")[0]
            //     let userTmp = s1[6].split("',")[0]
            //     let statusTmp = s1[7].split(",")[0]
            //     let priorityLevelTmp = s1[8].split(",")[0]
            //     let employeeIdTmp = s1[9].split(",")[0]
            //     let deptAccessTmp = s1[10].split(",")[0]
            //     let clientid = s1[11].split(",")[0]
            //     let isDeletedTmp = s1[12].split(",")[0]
            //     let isDraftTmp = s1[13].split(",")[0]
            //     let createdbyTmp = s1[14].split(",")[0]
            //     let createddateTmp = s1[15].split(",")[0]
            //     let modifiedbydTmp = s1[16].split(",")[0]
            //     let modifieddateTmp = s1[17].split(",")[0]
            //     let isModifiedTmp = s1[18].split(",")[0].replace("}","")
            //     idList.push(idClean)
            //     typeList.push(typTmp)
            //     titleList.push(titleTmp)
            //     descriptionList.push(descrTmp)
            //     replyList.push(replyTmp)
            //     userList.push(userTmp)
            //     statusList.push(statusTmp)
            //     priorityLevelList.push(priorityLevelTmp)
            //     employeeIdList.push(employeeIdTmp)
            //     deptAccessList.push(deptAccessTmp)
            //     clientIdList.push(clientid)
            //     isDeletedList.push(isDeletedTmp)
            //     isDraftList.push(isDraftTmp)
            //     createdbyList.push(createdbyTmp)
            //     createddateList.push(createddateTmp)
            //     modifiedbydList.push(modifiedbydTmp)
            //     modifieddateList.push(modifieddateTmp)
            //     isModifiedList.push(isModifiedTmp)

            // }else{

                let idClean = s1[1].replace("ObjectId(","").replace(")","").replace("{","").split(",")[0]
                let typTmp = s1[2].split(",")[0]/* .replace("'" , "").replace("'" , "") */
                let titleTmp = s1[3].split(",")[0]
                let descrTmp = s1[4].split(",")[0]
                let replyTmp = s1[5].split(",")[0]
                let userTmp = s1[6].split("',")[0]
                let statusTmp = s1[7].split(",")[0]
                let priorityLevelTmp = s1[8].split(",")[0]
                let employeeIdTmp = s1[9].split(",")[0]
                let deptAccessTmp = s1[10].split(",")[0]
                let isDeletedTmp = s1[11].split(",")[0]
                let isDraftTmp = s1[12].split(",")[0]
                let createdbyTmp = s1[13].split(",")[0]
                let createddateTmp = s1[14].split(",")[0]
                let modifiedbydTmp = s1[15].split(",")[0]
                let modifieddateTmp = s1[16].split(",")[0]
                let isModifiedTmp = s1[17].split(",")[0]
                let clientid = s1[18].split(",")[0].replace("}","")
                
                // let clientid = s1[11].split(",")[0]

                idList.push(idClean)
                typeList.push(typTmp)
                titleList.push(titleTmp)
                descriptionList.push(descrTmp)
                replyList.push(replyTmp)
                userList.push(userTmp)
                statusList.push(statusTmp)
                priorityLevelList.push(priorityLevelTmp)
                employeeIdList.push(employeeIdTmp)
                deptAccessList.push(deptAccessTmp)
                clientIdList.push(clientid)
                isDeletedList.push(isDeletedTmp)
                isDraftList.push(isDraftTmp)
                createdbyList.push(createdbyTmp)
                createddateList.push(createddateTmp)
                modifiedbydList.push(modifiedbydTmp)
                modifieddateList.push(modifieddateTmp)
                isModifiedList.push(isModifiedTmp)
            }
        // }
        // // // console.log(clientIdList)
        // // // console.log("clientIdList")
        for (let i = 0; i < idList.length; i++) {

            let obj = {
                "id"            :   idList[i].replace(" ","").replace("'","").replace("'",""),
                "type"          :   typeList[i].replace(" ","").replace("'","").replace("'",""),
                "title"         :   titleList[i].replace(" ","").replace("'","").replace("'",""),
                "description"   :   descriptionList[i].replace(" ","").replace("'","").replace("'",""),
                "reply"         :   replyList[i].replace(" ","").replace("'","").replace("'",""),
                "user"          :   userList[i].replace(" ","").replace("'","").replace("'",""),
                "status"        :   statusList[i].replace(" ","").replace("'","").replace("'",""),
                "priorityLevel" :   priorityLevelList[i].replace(" ","").replace("'","").replace("'",""),
                "employeeId"    :   employeeIdList[i].replace(" ","").replace("'","").replace("'",""),
                "deptAccess"    :   deptAccessList[i].replace(" ","").replace("'","").replace("'",""),
                "clientid"    	:   clientIdList[i].replace(" ","").replace("'","").replace("'",""),
                "isDeleted"     :   isDeletedList[i].replace(" ","").replace("'","").replace("'",""),
                "isDraft"       :   isDraftList[i].replace(" ","").replace("'","").replace("'",""),
                "createdby"     :   createdbyList[i].replace(" ","").replace("'","").replace("'",""),
                "createddate"   :   createddateList[i].replace(" ","").replace("'","").replace("'",""),
                "modifiedby"    :   modifiedbydList[i].replace(" ","").replace("'","").replace("'",""),
                "modifieddate"  :   modifieddateList[i].replace(" ","").replace("'","").replace("'",""),
                "isModified"    :   isModifiedList[i].replace(" ","").replace("'","").replace("'",""),

            }

            itemList.push(obj)

        }
        // console.log("itemList")
        // console.log(itemList)
        return itemList
    }



    GetEmployeeProfile =(Client)=> {
        const EmployeeProfile = {
            "IpAddress" :"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId": Client,
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter",  EmployeeProfile)
        .then(res => {
            const data = res.data;
            console.log("Get Employee Profile Name");
            console.log(data);
            this.setState({ dataEmployeeProfile  : data.profiles });
        })
    }

/********************* END GET CASE DATA *****************************/


/********************* GET HR OFFICERS *******************************/

    getHrOfficer(){
        //this.setState({isloading:true})
        const getParams = {
            "IpAddress" :"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        //// // console.log("getparams")
        //// // console.log(getParams)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetUsers" , getParams)
        .then(res => {
            // console.log("res")
            // console.log(res)
            this.setState(
                {
                    isloading:false,
                    getUsersAutocomplete    : res.data.users,

                    //selectedHROfficer       : res.data.users.userName,

                });
            //// // console.log(this.state.getEmployeeListId+ " :employee ID")
            //// // console.log(this.state.selectedHROfficer)
        })
    }

    /*********************END GET HR OFFICERS ******************************/


    /********************* On CHANGE ITEMS *********************************/
    handleChangeFromDate = date => {
        this.setState({
            FromDate: date,
            //isshow          :   false,
        });
        // // console.log(this.formatDate(this.state.FromDate));
    };

    handleChangeToDate = date => {

        this.setState({
            ToDate: date,
        });
    };

    formatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
      }

    handleGoButton = (e) => {

        //// // console.log("go button")
        let tmpList     = this.state.newRowList
        let tmpList0    = this.state.caseData
        let tmpList1    = []
        let listClear   = []

        this.setState({caseData: listClear})

        //// // console.log("tmpList0 lenght: " + tmpList.length)
        //// // console.log("tmpList0 lenght: " + tmpList0.length)
        //// // console.log("go button 1")
            for (let x = 0; x < tmpList0.length; x++) {

                //// // console.log("tmpLXSLTProcessorist0 : " + tmpList0[x]["description"])
                //// // console.log("go button: " + x)
                let hrOfficer = ""
                let isDeleted = "0"
                let isModified = "0"
                let findInxedValue = this.findRecord(x)

                if (findInxedValue) {
                    hrOfficer = this.state.selectedHROfficer
                    isModified = "1"
                    isDeleted = "1"
                } else {
                    hrOfficer = tmpList0[x]["deptAccess"]
                }

                let obj = {
                    "user"          :   tmpList0[x]["user"],
                    "type"          :   tmpList0[x]["type"],
                    "status"        :   tmpList0[x]["status"],
                    "title"         :   tmpList0[x]["title"],
                    "description"   :   tmpList0[x]["description"],
                    "deptAccess"    :   hrOfficer,

                    'id'            :   tmpList0[x]["id"],
                    'reply'         :   tmpList0[x]["reply"],
                    'priorityLevel' :   tmpList0[x]["priorityLevel"],
                    'employeeId'    :   tmpList0[x]["employeeId"],
                    'isDeleted'     :   isDeleted,
                    'isDraft'       :   tmpList0[x]["isDraft"],
                    'createdby'     :   tmpList0[x]["createdby"],
                    'createddate'   :   tmpList0[x]["createddate"],
                    'modifiedby'    :   tmpList0[x]["modifiedby"],
                    'modifieddate'  :   tmpList0[x]["modifieddate"],
                    'isModified'    :   isModified,
                }
                tmpList1.push(obj)
                //// // console.log(newRowListTmp)
            }
        this.setState({caseData: tmpList1})
    }

    handleSave = () => {
        this.setState({
            isloading   :   true
        })
        //// // console.log("listDataRow")
        //// // console.log(this.state.caseData)
        let tmpList0    = this.state.caseData
        //let newDataRow  = {}
        for (let x = 0; x < tmpList0.length; x++) {
            //// // console.log("this.findRecord(x)")
            //// // console.log(this.findRecord(x))

            let findRowId = this.findRecord(x)
            if(findRowId == true) {
                if(this.state.dataRowId == tmpList0[x]["id"]) {
                    let obj = {
                        //"id"            :   tmpList0[x]["id"],
                        "type"          :   tmpList0[x]["type"],
                        "title"         :   tmpList0[x]["title"],
                        "description"   :   tmpList0[x]["description"],
                        "reply"         :   tmpList0[x]["reply"],
                        "user"          :   tmpList0[x]["user"],
                        'status'        :   tmpList0[x]["status"],
                        'priorityLevel' :   tmpList0[x]["priorityLevel"],
                        'employeeId'    :   tmpList0[x]["employeeId"],
                        'deptAccess'    :   tmpList0[x]["deptAccess"],
                        'isDeleted'     :   tmpList0[x]["isDeleted"],
                        'isDraft'       :   tmpList0[x]["isDraft"],
                        'createdby'     :   tmpList0[x]["createdby"],
                        'createddate'   :   tmpList0[x]["createddate"],
                        'modifiedby'    :   tmpList0[x]["modifiedby"],
                        'modifieddate'  :   tmpList0[x]["modifieddate"],
                        'isModified'    :   tmpList0[x]["isModified"],
                    }
                    //// // console.log(obj)
                    const updateParams = {

                    "_collection" : "CreateCase",
                    "doc_data"    : obj,
                    "_id"         : this.state.dataRowId,

                    }
                    // // console.log("updateParams")
                    // // console.log(updateParams)
                    axios
                    .post("http://43.255.218.198:8086/action/update_one", updateParams)
                    .then(res =>{
                        this.setState({
                            isloading   :   false
                        })
                        this.getCaseList()
                    })
                    .catch(error =>{
                    // // console.log("error: " + error)
                    this.setState({message: "Error: Please contact system admin."})
                    })
                }
            }
        }
    }


    findRecord(indexToFind) {

        let ret = false;
        let tmpList = this.state.newRowList

        for (let i = 0; i < tmpList.length; i++) {

            let rowIdextoFind = tmpList[i]["rowid"]
            if (indexToFind == tmpList[i]["rowid"] || indexToFind === tmpList[i]["rowid"]) {
                ret = true
                return ret
            }
        }
        return ret

    }

    onChangeType = (e) =>{

        if(e.length == 0 ){
            this.state.selectedType=""
            return
        }
        this.state.selectedType = e[0].item
        //this.setState({type:this.state.selectedType})
        // // console.log(this.state.selectedType + " :type")



    }

    onChangeStatus= (e) =>{
        if(e.length == 0){
            this.selectedStatus = ""
            return
        }
       this.state.selectedStatus = e[0].key
       // // console.log(this.state.selectedStatus + " :selectedStatus")
    }

    onChangeHrOfficer = (e) =>{

        //// // console.log(e)
        if(e.length == 0) {
            this.state.selectedUserName =""
            //this.state.userId   =""
            return
        }
        this.setState({isloading:true})

        this.state.selectedUserName = e[0].userName
        //this.state.userId = e[0].name

        this.setState({isloading:false,})
        this.setState({selectedHROfficer: this.state.selectedUserName})

    }

    onChangeEmployeesList = (e) => {

        if(e.length==0)
        {
            this.setState({
                selectedEmployeeId      :   "",
                selectedEmployeeName    :   ""
            })
            return
        }
        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeName = e[0].employeeName
		let SplitName = String(e[0].employeeName).split(",")
		 // // console.log(SplitName[0])
		this.setState({ selectedEmployeeId:e[0].id, selectedEmployeeName: SplitName[0] })
        // // // console.log(this.state.selectedEmployeeId)
    }

/************************ END On CHANGE ITEMS *****************************/

/*************************** GET EMPLOYEES ********************************/


    getEmployeesData(){
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":this.state.selectedEmployeeName,
            "EmployeeNo":this.state.selectedEmployeeNo,
            "ClientName":this.state.selectedClientName
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            console.log("EmployKee List Data");
            console.log(res.data);
            const data = res.data
            if(data.status=="1")
            {
                this.setState({getEmployeeListData : data.employees, isloading:false})
                /* this.setEmployeeCurrentInfo() */
            }
            else
            {
                this.setState({getEmployeeListData : [],isloading:false})
            }


        })
        .catch(error=>{
            this.setState(
            {
                isloading:false,
                alertType:"Error! ",
                isShow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })
        })

    }

    GetPayperiodPeriodsId(periodId){
        //// // console.log("Get Payroll Periods Id");
        let PayPeriodName =''
        //// // console.log(this.state.getPayrollPeriodId)
        this.state.getPayrollPeriodId.map(function(item,idx){
            if(periodId==item.periodId)
            PayPeriodName = item.payPeriod
        })
        this.setState({selectedPayrollPeriodsName:PayPeriodName})
    }

    employeeWorkScheduleTable() {

        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.selectedClientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   this.state.selectedEmployeeId,
            "PayPeriodId"   :   this.state.selectedPayrollPeriodsId
        }
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetEmployeePayPeriodWorkSchedules", getParams)
            .then(res => {
                const data      =   res.data
                //// // console.log("Get Employee PayPeriod Work Schedules Table ");
                //// // console.log(data)
                var obj         =   {}
                var schedule    =   []
                var isValid     =   true;
                if(data.status  =   "1"){

                    if(data.payPeriodWorkSchedule   ==  null)
                    {
                        isValid = false
                        data.payPeriodWorkSchedule  =   []
                    }
                    data.payPeriodWorkSchedule.map(function(item, idx){
                        if(item.periodWorkSchedule  ==  null)
                        {
                            isValid = false
                        }
                        else
                        {
                            obj = {
                                date                :   item.date,
                                branch              :   item.periodWorkSchedule.location,
                                workSchedule        :   item.periodWorkSchedule.description,
                                timeIn              :   item.periodWorkSchedule.startTime,
                                timeOut             :   item.periodWorkSchedule.endTime,
                                firstBreakOut       :   '',
                                firstBreakIn        :   '',
                                secondBreakOut      :   '',
                                secondBreakIn       :   '',
                                thirdBreakOut       :   '',
                                thirdBreakIn        :   '',
                                fourthBreakOut      :   '',
                                fourthBreakIn       :   '',
                                overtime            :   '00:00',
                            }
                            item.periodWorkSchedule.workScheduleDetails.map(function(item, idx){
                                if(idx==1){
                                    obj.firstBreakOut = item.startTime
                                    obj.firstBreakIn = item.endTime
                                }
                                if(idx==2){
                                    obj.secondBreakOut = item.startTime
                                    obj.secondBreakIn = item.endTime
                                }
                                if(idx==3){
                                    obj.thirdBreakOut = item.startTime
                                    obj.thirdBreakIn = item.endTime
                                }
                            })
                            schedule.push(obj)
                        }
                    })
                    if(!isValid){
                        this.setState({
                            isloading       :   false,
                            alerttype       :   "Information!",
                            isshow          :   true,
                            color           :   "info",
                            message         :   "Employee has no working schedule.",
                            fade            :   true,
                            employeeWorkScheduleTable:[]
                        })
                    }
                    else
                    this.setState({
                        employeeWorkScheduleTable   :   schedule,
                        isShow                      :   false,
                        isloading                   :   false
                    })
                }
            })
    }

    getEmployees(){

        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            // console.log("Employee List ");
            // console.log(res.data);
            const data = res.data
                this.setState({getEmployeeList : data.employees,isloading:false})

        })
        .catch(error=>{
        this.setState(  {
            isloading       :   false,
            alerttype       :   "Error!",
            isshow          :   true,
            color           :   "danger",
            message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
            fade            :   true
        })
    })

    }
/****************** END GET EMPLOYEES *********************/

/****************** START GET CLIENTS *********************/

    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        }
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        console.log("Client selectedClientId " + this.state.selectedClientId );

        this.getEmployees();
        let Client = e[0].id
        this.GetEmployeeProfile(Client);

    }
    GetClientId(name) {
        //// // console.log("Client ID List ");
        let GetClientId = ''
        for (let i = 0; i <= this.state.getClientList.length; i++) {
            if (this.state.getClientList[i]["name"] === name) {
                GetClientId = this.state.getClientList[i]['id'];
                //// // console.log(GetClientId);
                break;
            }
        }
        return GetClientId
    }
    getClientList(){
        this.setState({
            isloading:true
        })
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
			// // console.log(res.data.clients)
			// // console.log("res.data.clients")
            this.setState(
                {
                    isloading:false,
                    getClientList : res.data.clients ? res.data.clients : []
                });
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }

    getClientLocation(){
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.selectedClientName,
            "City": "",
            "Province": "",
            "Region": ""

        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", getParams)
        .then(res => {
            //// // console.log("Client Location");
            //// // console.log(res.data.locations);
            this.setState({clientLocationList : res.data.locations ? res.data.locations : []})
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }

    GetPosition() {
        const departmentCodeParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId"     :   "",
            "DepartmentId"  :   "",
            "Name"          :   ""
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  departmentCodeParams)
        .then(res => {
            const data = res.data;
            //// // console.log("Get Position Name");
            //// // console.log(data);
            this.setState({ positionAutocomplete  : data.positions });
         })
    }
/******************* END GET CLIENTS  *****************************/

/******************* WORK SCHEDULE ********************************/
    getWorkSchedule(){
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "Location": this.state.selectedLocationName,
        }

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "TimeKeeping/GetWorkingSchedules", getParams)
        .then(res => {
            //// // console.log("Get Work Schedule");
            //// // console.log(res.data.workSchedules);
            this.setState({
                getWorkScheduleList : res.data.workSchedules
            })
        })
    }
/********************* END WORK SCHEDULE *****************************/


    getGridData = (rowIndex) => {
        //// // console.log(rowIndex)
        let caseDataListTmp = this.state.caseData.filter(x => x.isDeleted === "1")

		// // console.log(caseDataListTmp)
		// // console.log("caseDataListTmp")

		// let FilterNotClosed = caseDataListTmp.filter(x => x.status !== "CLOSED" )

        let newGridData = []
        for (let i = 0; i < caseDataListTmp.length; i++){
            //// // console.log("equal")
            let obj = {
                "DATE"             :   caseDataListTmp[i]["createddate"],
                "MEMBER"           :   caseDataListTmp[i]["user"],
                "TYPE"             :   caseDataListTmp[i]["type"],
                "STATUS"           :   caseDataListTmp[i]["status"],
                "TITLE"            :   caseDataListTmp[i]["title"],
                "DESCRIPTION"      :   caseDataListTmp[i]["description"],
                "SLA"              :   caseDataListTmp[i]["priorityLevel"],
                "AGING"            :   caseDataListTmp[i]["daysOld"],
                "HR OFFICER"       :   caseDataListTmp[i]["deptAccess"],
            }
            newGridData.push(obj)
        }
        //// // console.log(newGridData)
        this.setState({gridDataCsv : newGridData})
        //// // console.log(this.state.gridDataCsv)
    }

    handleClickSearch = () => {
		this.setState({ isloading : true })

        // console.log(this.state.selectedEmployeeId === "")
        // console.log(this.state.selectedStatus === "" )
        // console.log(this.state.selectedClientName)
        console.log(this.state.selectedType === "")
        // console.log(this.state.ToDate === "")
        // console.log(this.state.FromDate === "")
        
        let DateFrom = moment(this.state.ToDate).format('MMYYYY');
        let DateTo = moment(this.state.FromDate).format('MMYYYY');
        let _toDate = moment(this.state.ToDate).format('YYYYMMDD');
        let _fromDate = moment(this.state.FromDate).format('YYYYMMDD');
        
        if ( this.state.selectedClientId.length !== 0 && this.state.selectedEmployeeId === "" && this.state.selectedType === "" &&  this.state.selectedStatus === "" && this.state.ToDate === "" && this.state.FromDate === "" ){

            let clientid = this.state.dataEmployeeProfile.filter( x => x.clientId === this.state.selectedClientId)
            console.log("1")
            let newArray = []
            for ( let i = 0; i < clientid.length; i++){
                // console.log(i)
                for ( let x = 0; x < this.state.caseData.length; x++){
                    // console.log(x)
                    // console.log(clientid[i]['id'] === this.state.caseData[x]['employeeId'])
                    if (clientid[i]['id'] === this.state.caseData[x]['employeeId']){
                        let newArry = {
                            'id'            :   this.state.caseData[x]['id'],
                            'type'          :   this.state.caseData[x]['type'],
                            'title'         :   this.state.caseData[x]['title'],
                            'description'   :   this.state.caseData[x]['description'],
                            'reply'         :   this.state.caseData[x]['reply'],
                            'user'          :   this.state.caseData[x]['user'],
                            'status'        :   this.state.caseData[x]['status'],
                            'priorityLevel' :   this.state.caseData[x]['priorityLevel'],
                            'employeeId'    :   this.state.caseData[x]['employeeId'],
                            'deptAccess'    :   this.state.caseData[x]['deptAccess'],
                            'clientid'    	:   this.state.caseData[x]['clientid'],
                            'isDeleted'     :   this.state.caseData[x]['isDeleted'],
                            'isDraft'       :   this.state.caseData[x]['isDraft'],
                            'createdby'     :   this.state.caseData[x]['createdby'],
                            'createddate'   :   this.state.caseData[x]['createddate'],
                            'modifiedby'    :   this.state.caseData[x]['modifiedby'],
                            'modifieddate'  :   this.state.caseData[x]['modifieddate'],
                            'isModified'    :   this.state.caseData[x]['isModified'],
                            'daysOld'       :   this.state.caseData[x]['daysOld'],
                        }
                        newArray.push(newArry)
                        // console.log(newArry)
                        // console.log('newArry')
                    }
                }
            }
            this.setState({ caseData : newArray, isloading: false, isDisabledsearch : true})

        } else if (this.state.selectedClientId.length !== 0 && this.state.selectedEmployeeId === "" && this.state.selectedType === "" &&  this.state.selectedStatus === "" && this.state.ToDate !== "" && this.state.FromDate !== "" ) {
            
            let clientid = this.state.dataEmployeeProfile.filter( x => x.clientId === this.state.selectedClientId)
            // let clientid = clientidTmp.filter( x => moment(x.createddate).format('MMYYYY')>= DateTo && moment(x.createddate).format('MMYYYY')<= DateFrom )
            console.log("clientid")
            console.log(clientid)
            console.log("2")
            let newArray2 = []
            for ( let i = 0; i < clientid.length; i++){
                // console.log(i)
                for ( let x = 0; x < this.state.caseData.length; x++){
                    // console.log(x)
                    // console.log(clientid[i]['id'] === this.state.caseData[x]['employeeId'])
                    if (clientid[i]['id'] === this.state.caseData[x]['employeeId']){
                        let newArry = {
                            'id'            :   this.state.caseData[x]['id'],
                            'type'          :   this.state.caseData[x]['type'],
                            'title'         :   this.state.caseData[x]['title'],
                            'description'   :   this.state.caseData[x]['description'],
                            'reply'         :   this.state.caseData[x]['reply'],
                            'user'          :   this.state.caseData[x]['user'],
                            'status'        :   this.state.caseData[x]['status'],
                            'priorityLevel' :   this.state.caseData[x]['priorityLevel'],
                            'employeeId'    :   this.state.caseData[x]['employeeId'],
                            'deptAccess'    :   this.state.caseData[x]['deptAccess'],
                            'clientid'    	:   this.state.caseData[x]['clientid'],
                            'isDeleted'     :   this.state.caseData[x]['isDeleted'],
                            'isDraft'       :   this.state.caseData[x]['isDraft'],
                            'createdby'     :   this.state.caseData[x]['createdby'],
                            'createddate'   :   this.state.caseData[x]['createddate'],
                            'modifiedby'    :   this.state.caseData[x]['modifiedby'],
                            'modifieddate'  :   this.state.caseData[x]['modifieddate'],
                            'isModified'    :   this.state.caseData[x]['isModified'],
                            'daysOld'       :   this.state.caseData[x]['daysOld'],
                        }
                        newArray2.push(newArry)
                        // console.log(newArry)
                        // console.log('newArry')  && moment(x.createddate).format('MMYYYY')>= DateTo && moment(x.createddate).format('MMYYYY')<= DateFrom 
                    }
                }
            }
            let DataTmp = newArray2.filter( x => moment(x.createddate).format('MMYYYY')>= DateTo && moment(x.createddate).format('MMYYYY')<= DateFrom )
            this.setState({ caseData : DataTmp, isloading: false, isDisabledsearch : true})

        } else if (this.state.selectedClientId.length !== 0 && this.state.selectedEmployeeId === "" && this.state.selectedType !== "" &&  this.state.selectedStatus === "" && this.state.ToDate !== "" && this.state.FromDate !== "") {
            
            let clientid = this.state.dataEmployeeProfile.filter( x => x.clientId === this.state.selectedClientId )
            // let clientid = clientidTmp.filter( x => moment(x.createddate).format('MMYYYY')>= DateTo && moment(x.createddate).format('MMYYYY')<= DateFrom )
            // console.log("clientid")
            // console.log(clientid)
            console.log("3")
            let newArray3 = []
            for ( let i = 0; i < clientid.length; i++){
                // console.log(i)
                for ( let x = 0; x < this.state.caseData.length; x++){
                    // console.log(x)
                    // console.log(clientid[i]['id'] === this.state.caseData[x]['employeeId'])
                    if (clientid[i]['id'] === this.state.caseData[x]['employeeId']){
                        let newArry = {
                            'id'            :   this.state.caseData[x]['id'],
                            'type'          :   this.state.caseData[x]['type'],
                            'title'         :   this.state.caseData[x]['title'],
                            'description'   :   this.state.caseData[x]['description'],
                            'reply'         :   this.state.caseData[x]['reply'],
                            'user'          :   this.state.caseData[x]['user'],
                            'status'        :   this.state.caseData[x]['status'],
                            'priorityLevel' :   this.state.caseData[x]['priorityLevel'],
                            'employeeId'    :   this.state.caseData[x]['employeeId'],
                            'deptAccess'    :   this.state.caseData[x]['deptAccess'],
                            'clientid'    	:   this.state.caseData[x]['clientid'],
                            'isDeleted'     :   this.state.caseData[x]['isDeleted'],
                            'isDraft'       :   this.state.caseData[x]['isDraft'],
                            'createdby'     :   this.state.caseData[x]['createdby'],
                            'createddate'   :   this.state.caseData[x]['createddate'],
                            'modifiedby'    :   this.state.caseData[x]['modifiedby'],
                            'modifieddate'  :   this.state.caseData[x]['modifieddate'],
                            'isModified'    :   this.state.caseData[x]['isModified'],
                            'daysOld'       :   this.state.caseData[x]['daysOld'],
                        }
                        newArray3.push(newArry)
                        // console.log(newArry)
                        // console.log('newArry')  && moment(x.createddate).format('MMYYYY')>= DateTo && moment(x.createddate).format('MMYYYY')<= DateFrom 
                    }
                }
            }
            let DataTmp = newArray3.filter( x => moment(x.createddate).format('MMYYYY')>= DateTo && moment(x.createddate).format('MMYYYY')<= DateFrom && x.type === this.state.selectedType)
            this.setState({ caseData : DataTmp, isloading: false, isDisabledsearch : true})
            console.log("mel?")
            
        } else if (this.state.selectedClientId.length !== 0 && this.state.selectedEmployeeId === "" && this.state.selectedType !== "" &&  this.state.selectedStatus !== "" && this.state.ToDate !== "" && this.state.FromDate !== "") {
    
            let clientid = this.state.dataEmployeeProfile.filter( x => x.clientId === this.state.selectedClientId )
            // let clientid = clientidTmp.filter( x => moment(x.createddate).format('MMYYYY')>= DateTo && moment(x.createddate).format('MMYYYY')<= DateFrom )
            // console.log("clientid")
            // console.log(clientid)
            console.log("4")
            let newArray4 = []
            for ( let i = 0; i < clientid.length; i++){
                // console.log(i)
                for ( let x = 0; x < this.state.caseData.length; x++){
                    // console.log(x)
                    // console.log(clientid[i]['id'] === this.state.caseData[x]['employeeId'])
                    if (clientid[i]['id'] === this.state.caseData[x]['employeeId']){
                        let newArry = {
                            'id'            :   this.state.caseData[x]['id'],
                            'type'          :   this.state.caseData[x]['type'],
                            'title'         :   this.state.caseData[x]['title'],
                            'description'   :   this.state.caseData[x]['description'],
                            'reply'         :   this.state.caseData[x]['reply'],
                            'user'          :   this.state.caseData[x]['user'],
                            'status'        :   this.state.caseData[x]['status'],
                            'priorityLevel' :   this.state.caseData[x]['priorityLevel'],
                            'employeeId'    :   this.state.caseData[x]['employeeId'],
                            'deptAccess'    :   this.state.caseData[x]['deptAccess'],
                            'clientid'    	:   this.state.caseData[x]['clientid'],
                            'isDeleted'     :   this.state.caseData[x]['isDeleted'],
                            'isDraft'       :   this.state.caseData[x]['isDraft'],
                            'createdby'     :   this.state.caseData[x]['createdby'],
                            'createddate'   :   this.state.caseData[x]['createddate'],
                            'modifiedby'    :   this.state.caseData[x]['modifiedby'],
                            'modifieddate'  :   this.state.caseData[x]['modifieddate'],
                            'isModified'    :   this.state.caseData[x]['isModified'],
                            'daysOld'       :   this.state.caseData[x]['daysOld'],
                        }
                        newArray4.push(newArry)
                        console.log(newArry)
                        console.log('newArry') 
                    }
                }
            }
            let DataTmp = newArray4.filter( x => moment(x.createddate).format('MMYYYY')>= DateTo && moment(x.createddate).format('MMYYYY')<= DateFrom && x.type === this.state.selectedType && x.status === this.state.selectedStatus )
            this.setState({ caseData : DataTmp, isloading: false, isDisabledsearch : true})
    
        } else if ( this.state.selectedClientId.length === 0 && this.state.selectedType === "" && this.state.selectedStatus === "" && this.state.ToDate !== "" && this.state.FromDate !== "") {

            let newArray5 = this.state.caseData.filter(x => moment(x.createddate).format('MMYYYY')>= DateTo && moment(x.createddate).format('MMYYYY')<= DateFrom)
            this.setState({caseData : newArray5, isloading : false, isDisabledsearch : true})

        } else if (this.state.selectedClientId.length === 0 && this.state.selectedType !== "" && this.state.selectedStatus === "" && this.state.ToDate !== "" && this.state.FromDate !== "") {

            let newArray6 = this.state.caseData.filter(x => moment(x.createddate).format('MMYYYY')>= DateTo && moment(x.createddate).format('MMYYYY')<= DateFrom && x.type === this.state.selectedType)
            this.setState({caseData : newArray6, isloading : false, isDisabledsearch : true})
            console.log("mel?")

        } else {

            // console.log(this.state.selectedClientId.length === 0)
            let newArray = this.state.caseData.filter(x => moment(x.createddate).format('MMYYYY')=== DateTo || moment(x.createddate).format('MMYYYY')=== DateFrom || x.type === this.state.selectedType || x.status === this.state.selectedStatus && x.user === this.state.selectedEmployeeName)
            this.setState({caseData : newArray, isloading : false, isDisabledsearch : true})
            console.log("else condition")

        }

    }

    render() {

        const { ExportCSVButton } = CSVExport;

        const selectType = [
            {item : "SSS"               },
            {item : "Phil.Health"       },
            {item : "Pag-Ibig"          },
            {item : "DTR"               },
            {item : "Loans"             },
            {item : "Leave"             },
            {item : "Records"           },
            {item : "Members-Concerns"  },
            {item : "Hr-Concerns"       },
            {item : "Payslip"           },
            {item : "COE"               },
            {item : "Absent-Letter"     },
            {item : "Others"            },
            {item : "Suggestions"       },
        ]

        const selectStatus = [
            {key : "New"        },
            {key : "In Progress"},
            {key : "Closed"     },
        ]

        const mobileConcernColumn = [
            {
                dataField   : 'createddate',
                text        : 'Date',
                editable    : false,
                headerStyle : () => {
                    return { width  : "5%" };
                },
            },
            {
                dataField   : 'user',
                text        : 'Member',
                editable    : false,
                headerStyle : () => {
                    return { width  : "15%" };
                },
            },
            {
                dataField   : 'type',
                text        : 'Type',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'status',
                text        : 'Status',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'title',
                text        : 'Title',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'description',
                text        : 'Description',
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField   : 'priorityLevel',
                text        : 'SLA',
                headerStyle : () => {
                    return { width  : "5%" };
                }
            },
            {
                dataField   : 'daysOld',
                text        : 'Ageing',
                headerStyle : () => {
                    return { width  : "5%" };
                }
            },
            {
                dataField   : 'deptAccess',
                text        : 'HR Officer',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.dataRowId =  row.id
                this.state.caseData.map(function(item,idx){
                    if(idx==rowIndex)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        // item.isModified = isSelect ? "1" : "0"
                    }
                })
                //// // console.log(row.id)
                this.state.user = row.user
                this.state.type = row.type
                this.state.status = row.status
                this.state.title = row.title
                this.state.description = row.description

                let newRowListTmp = this.state.newRowList
                //// // console.log(newRowListTmp)
                let arrayClear = [];

                //this.setState({newRowList: arrayClear})

                if(isSelect){

                    let obj = {
                        "rowid"         :   rowIndex,
                        "user"          :   row.user,
                        "type"          :   row.type,
                        "status"        :   row.status,
                        "title"         :   row.title,
                        "description"   :   row.description,

                        'id'            :   row.id,
                        'reply'         :   row.reply,
                        'priorityLevel' :   row.priorityLevel,
                        'employeeId'    :   row.employeeId,
                        'deptAccess'    :   row.deptAccess,
                        'isDeleted'     :   row.isDeleted,
                        'isDraft'       :   row.isDraft,
                        'createdby'     :   row.createdby,
                        'createddate'   :   row.createddate,
                        'modifiedby'    :   row.modifiedby,
                        'modifieddate'  :   row.modifieddate,
                        'isModified'    :   row.isModified,
                    }
                    newRowListTmp.push(obj)
                    // // console.log(newRowListTmp + " :NEWROLIST")
                    this.setState({newRowList: newRowListTmp})
                    //this.setState({gridData : newRowListTmp})
                   // // console.log(this.state.newRowList)
                } else  {

                    let tmpIndex;

                    for (let i = 0; i < newRowListTmp.length; i++) {

                        if (newRowListTmp[i]["rowid"] == rowIndex || newRowListTmp[i]["rowid"] === rowIndex)
                        {
                            //// // console.log("unchecked row index # " + rowIndex + " found")
                            newRowListTmp.splice(i, 1)
                        }
                    }

                    this.setState({newRowList: newRowListTmp})

                    //// // console.log("unchecked row # " + rowIndex)
                    //// // console.log(this.state.newRowList)
                }
            },
			onSelectAll: (isSelect, rows) => {
			let isDisable = true
			this.state.caseData.map(function(itm){
				itm.isDeleted=isSelect ? "1" : "0"
				if(itm.isDeleted=="1")
					isDisable=false
			})
			// this.setState({isDisabledRemoved:isDisable})
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {

            }
        };
        const minDate = new Date();
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>View Concerns</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div>
                                        {this.state.message}
                                    </Alert>
                                    <Form.Row>
                                        <Form.Group as={Col} sm={5} controlId="formGridEmail">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClientList}
                                                options={this.state.getClientList}
                                                placeholder="Select Concern Client"

                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm={6} className="ml-auto" controlId="formPlaintextEmail">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.onChangeEmployeesList}
                                                options={this.state.getEmployeeList}
                                                placeholder="Select Employee"
                                                autocomplete="false"
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} sm={5} controlId="formPlaintextEmail">
                                            <DatePicker
                                                ref='FromDate'
                                                selected={this.state.FromDate}
                                                onChange={this.handleChangeFromDate}
                                                minDate={this.minDate}
                                                value={this.state.FromDate}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                placeholderText="Period Date From"
                                                />
                                        </Form.Group>
                                        <Form.Group as={Col} sm={6} className="ml-auto" controlId="formPlaintextEmail">
                                            <DatePicker
                                                ref='ToDate'
                                                selected={this.state.ToDate}
                                                onChange={this.handleChangeToDate}
                                                minDate={this.minDate}
                                                value={this.state.ToDate}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                placeholderText="Period Date To"
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} sm={5} controlId="formPlaintextEmail">
                                            <Typeahead
                                                labelKey='item'
                                                id="basic-example"
                                                onChange={this.onChangeType}
                                                options={selectType}
                                                //autocomplete="true"
                                                placeholder="Select Type"
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm={6} className="ml-auto" controlId="formPlaintextEmail">
                                            <Typeahead
                                                labelKey='key'
                                                id="basic-example"
                                                onChange={this.onChangeStatus}
                                                options={selectStatus}
                                                //autocomplete="true"
                                                placeholder="Select Status"
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <ButtonToolbar style={{marginTop : "3%"}}>
                                        <Button variant="success" className="ml-auto" onClick={ this.handleRefreshClick }>
                                            Refresh
                                        </Button>&nbsp;&nbsp;
                                        <Button variant="success" disabled = {this.state.isDisabledsearch} onClick={ this.handleClickSearch }>
                                            Search
                                        </Button>
                                    </ButtonToolbar>
                                        <div className="mt-1">
                                            <BootstrapTable
                                                /* caption={Noser.TableHeader({title:"RECORD"})} */
                                                keyField = "id"
                                                data = { this.state.caseData }
                                                columns={mobileConcernColumn}
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                cellEdit = { cellEditFactory({
                                                    mode: 'dbclick',
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                        this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                                rowEvents={ rowEvents }
                                                selectRow = { selectRow }
                                            />
                                            <ButtonToolbar sm={12}>
                                                <CSVLink className="btn btn-info ml-auto"onClick ={this.getGridData} filename= "Concern Listing.csv" data={this.state.gridDataCsv}>Export CSV!!</CSVLink>
                                                {/* <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export CSV!!</ExportCSVButton> */}
                                            </ButtonToolbar>
                                        </div>
                                    <Form.Group as={Row} className="mt-3 ml-auto" controlId="formPlaintextEmail">
                                        <Form.Label style={{fontWeight : "bold"}}>
                                            Assigned selected concern to:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='userName'
                                                id="basic-example"
                                                onChange={this.onChangeHrOfficer}
                                                options={this.state.getUsersAutocomplete}
                                                placeholder="Select HR Officer"
                                            />
                                        </Col>
                                        <ButtonToolbar sm={12}>
                                            <Button variant="success" className="ml-auto" onClick={ this.handleGoButton} style={{minWidth:'60px'}}>
                                                Go
                                            </Button>
                                        &nbsp;&nbsp;
                                        </ButtonToolbar>
                                    </Form.Group>
                                   <ButtonToolbar sm={12}>
                                        <Button variant="success" className="ml-auto" onClick={ this.handleSave} style={{minWidth:'60px'}}>
                                            Save
                                        </Button>&nbsp;&nbsp;
                                        <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                            Close
                                        </Button>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>

                    </Container>
                    <NoserLoading show={this.state.isloading} />
            </div>
        )
    }

}

export  default CustomerService
