import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown, Image,FormControl} from 'react-bootstrap';

import  TrainingRegisterModal from '../Training/TrainingModal/TrainingRegisterModal'
import { Input } from 'reactstrap';
import { isDoWhileStatement } from '@babel/types';
import Pdf from "react-to-pdf";
import ReactToPrint from "react-to-print";
import paramountLogo from "../../icon/paramountLogo.jpg"
import paraFooter from "../../icon/paraFooter.jpg"
import ImgsViewer from 'react-images-viewer'

const ref = React.createRef();


class ComponentToPrint extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            regexp: /^[0-9\b]+$/,

            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            isLoading           :"",

            id                  :"",
            client              :"",
            employeeName        :"",
            position            :"",
            employeeNo          :"",
            location            :"",
            date                :"",
            irReferenceNo       :"",
            paragraph           :"",
            addedparagraph1     :"",
            addedparagraph2     :"",
            addedparagraph3     :"",
            addedparagraph4     :"",
            addedparagraph5     :"",
            addedparagraph6     :"",
            quotedStatement     :"",
            preparedBy          :"",
            prepareByPosition   :"",
            notedBy             :"",
            receivedBy          :"",
            isDraft             :"",
            isDeleted           :"",
            createdby           :"",
            createddate         :"",
            modifiedby          :"",
            modifieddate        :"",
            isModified          :"",

            articleNo               :"",
            articleName             :"",
            sectionNo               :"",
            infraction              :"",
            category                :"",

            articleNo2              :"", 
            articleName2            :"",
            sectionNo2              :"",
            infraction2             :"",
            category2               :"",

            sectionNo3              :"",
            infraction3             :"",
            category3               :"",

            irDateIssued            :"",
            dateRecieveByER         :"",
            referenceNoNTE          :"",
            cocReferences           :"",
            eSignatureNotedBy       :"",    
            eSignaturePreparedBy    :"",       
            notedByPosition         :"",
            departmentCodeAutocomplete:"",
            irIdCheck               :"",
            dateNow                 :"2/31/2021",
            paragraph1              :"",            
            logo                    :"https://assets.bossjob.com/companies/13161/logo/3Mwts6BLTZy6I6WhqQm9B79bIhq8dILG7uqq7SHl.png",
            articleDataList:[],
            sectionDataList:[],

            articleDataList2:[],
            sectionDataList2:[],

            articleDataList3:[],
            sectionDataList3:[],

            articleDataList4:[],
            sectionDataList4:[],

            articleDataList5:[],
            sectionDataList5:[],
            insertImage:"",
        }
    }

    componentDidMount(){

        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        this.setState({isLoading:true,loadingText:"Loading client list..."})
        this.state.irIdCheck = JSON.parse(sessionStorage.getItem("irId"))
      
        this.getViewDraftMemo()
       
    }
    
    onChangePosition=(e)=>{
        this.setState({
            position:e.target.value
        })
    }

    getViewDraftMemo=()=>{
        let dataNOD = JSON.parse(sessionStorage.getItem("data"))
        let dataNOD1 = JSON.parse(sessionStorage.getItem("viewDraftMemoTmp"))

       /*  console.log("dataNOD")
        console.log(dataNOD.location)
        console.log("dataNOD") */

        let newDate = new Date(dataNOD.date)        
        let createddate = moment(newDate).format('MM');
        let createddate2 = moment(newDate).format('YYYY');
        let createddate3 = moment(newDate).format('DD');
        console.log(createddate)
        console.log(createddate2)

        let monthLeters = ""
        if(createddate == "01"){
            monthLeters = "January"
        }
        if(createddate == "02"){
            monthLeters = "February"
        }
        if(createddate == "03"){
            monthLeters = "March"
        }
        if(createddate == "04"){
            monthLeters = "April"
        }
        if(createddate == "05"){
            monthLeters = "May"
        }
        if(createddate == "06"){
            monthLeters = "June"
        }
        if(createddate == "07"){
            monthLeters = "July"
        }
        if(createddate == "08"){
            monthLeters = "August"
        }
        if(createddate == "09"){
            monthLeters = "September"
        }
        if(createddate == "10"){
            monthLeters = "October"
        }
        if(createddate == "11"){
            monthLeters = "November"
        }
        if(createddate == "12"){
            monthLeters = "December"
        }

        let wordsDate = createddate3+" "+monthLeters+" "+createddate2

        let dataCOCRefarticle1Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefarticle1"))
        let dataCOCRefarticle2Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefarticle2"))
        let dataCOCRefarticle3Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefarticle3"))
        let dataCOCRefarticle4Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefarticle4"))
        let dataCOCRefarticle5Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefarticle5"))

        let dataCOCRefsection1Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefsection1"))
        let dataCOCRefsection2Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefsection2"))
        let dataCOCRefsection3Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefsection3"))
        let dataCOCRefsection4Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefsection4"))
        let dataCOCRefsection5Tmp = JSON.parse(sessionStorage.getItem("dataCOCRefsection5"))


        this.setState({
            paragraph           :   dataNOD.paragraph,
            addedparagraph1     :   dataNOD.addedparagraph1,
            quotedStatement     :   dataNOD.quotedStatement,
            employeeName        :   dataNOD.employeeName,
            location            :   dataNOD.location,
            dateNow             :   wordsDate,
            preparedBy          :   dataNOD.preparedBy ,
            prepareByPosition   :   dataNOD.prepareByPosition ,
            notedBy             :   dataNOD.notedBy ,
            addedparagraph2     :   dataNOD.addedparagraph2,
            addedparagraph3     :   dataNOD.addedparagraph3,
            addedparagraph4     :   dataNOD.addedparagraph4,
            addedparagraph5     :   dataNOD.addedparagraph5,
            addedparagraph6     :   dataNOD.addedparagraph6,
            
            eSignatureNotedBy    :   dataNOD.eSignatureNotedBy,
            eSignaturePreparedBy :   dataNOD.eSignaturePreparedBy,
            //referenceNoNOD       :   dataNOD.referenceNoNOD,
            notedByPosition     :dataNOD.notedByPosition,

            articleDataList:dataNOD.articleDataList,
            sectionDataList:dataNOD.sectionDataList,

            articleDataList2:dataNOD.articleDataList2,
            sectionDataList2:dataNOD.sectionDataList2,

            articleDataList3:dataNOD.articleDataList3,
            sectionDataList3:dataNOD.sectionDataList3,

            articleDataList4:dataNOD.articleDataList4,
            sectionDataList4:dataNOD.sectionDataList4,

            articleDataList5:dataNOD.articleDataList5,
            sectionDataList5:dataNOD.sectionDataList5,
            insertImage           :   dataNOD.insertImage,

        })
      
    }

    setBack=()=>{
        let whenBack = 0
        sessionStorage.setItem("nextProcessCheck", JSON.stringify(whenBack)) 

    }

    render() {
        return( <>
            <Container className="z" fluid>
              
                        <Form style={{backgroundColor:"#FFFFFF"}}>
                            <Col>
                                <Image src={paramountLogo} style={{width:"84%", height:"115%", marginLeft:"20px" , marginTop:"30px",marginBottom:"-10px"}}/>
                            </Col>

                            <h1 as={Row}  style={{backgroundColor:'#ffffff', width:"100%",height:"60px", marginTop:"-20px" }}>
                                <Col>
                                <label style={{fontWeight : "bold" , fontSize: 24, marginTop:"15px", marginLeft:"25px"}}>    
                                    Memorandum
                                </label>
                               
                                <label style={{fontWeight : "bold" , fontSize: 24, marginLeft:"600px"  }}>
                                    {this.state.dateNow}
                                </label>
                                </Col>
                                
                            </h1>

                            <Form.Group as={Col} sm="10" style={{marginTop:"-10px" ,}}>                                    
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22,marginTop:"1px", marginLeft:"25px"}}>
                                    TO 
                                </Form.Label> 
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22,marginTop:"1px",marginLeft:"110px"}}>:</Form.Label> 
                                <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 22,marginLeft:"65px"}}>
                                    {this.state.employeeName}                                       
                                </Form.Label>                                
                            </Form.Group>
                            
                            <Form.Group as={Row} controlId="formPlaintextEmail"> 
                                <Col sm="11" style={{marginLeft:"153px", marginTop:"-20px",marginLeft:"249px" }}>
                                    <Form.Label row style={{fontWeight : "bold" ,fontSize: 22,}}>
                                    PHRMPC MEMBER ASSIGNED IN&nbsp;{this.state.location}
                                    </Form.Label>  
                                </Col>
                            </Form.Group>

                            <Form.Group as={Col} sm="10" style={{marginTop:"-10px" ,}}>                                    
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22, marginLeft:"25px"}}>
                                    FROM
                                </Form.Label> 
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22,marginTop:"-1px",marginLeft:"73px"}}>:</Form.Label> 
                                <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 22,marginLeft:"65px"}}>
                                    PHRMPC EMPLOYEE DISCPLINE TEAM                                    
                                </Form.Label>                                
                            </Form.Group>

                            <Form.Group as={Col} sm="10" style={{marginTop:"-10px" ,}}>                                    
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22, marginLeft:"25px"}}>
                                    SUBJECT
                                </Form.Label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22,marginTop:"-1px", marginLeft:"20px"}}>:</Form.Label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 22, marginLeft:"40px"}}>
                                    NOTICE OF DISCIPLINARY ACTION                         
                                </Form.Label>                                
                            </Form.Group>

                            <h1 className="mt-3" style={{fontWeight : "bold" ,backgroundColor:'#D5D5D5', width:"100%", height:"15px" }} > </h1>

                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px",whiteSpace: "pre-wrap", }}>
                                        {this.state.paragraph}
                                    </Form.Label>  
                                </Col>                                    
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label className="no-text-transform-li" row style={{ width:"85%", fontSize: 22, marginLeft:"85px", marginRight:"40px",fontWeight:"bold" ,whiteSpace: "pre-wrap",marginTop:"-10px", justifyContent:"-moz-initial" }}>
                                        <label style={{ fontSize: 22,fontWeight:"bold" ,marginLeft:"-10px" }}>"</label>{this.state.quotedStatement}<label style={{ fontSize: 22,fontWeight:"bold"  }}>"</label>
                                    </Form.Label>  
                                </Col>                                    
                            </Form.Group>
                            {<Form.Group as={Row} controlId="formPlaintextEmail"style={{/* minWidth:"300px", *//*  fontWeight : "bold",fontSize: 22, pageBreakBefore:"always" , */justifyContent:"center",marginTop:"-15px", /* backgroundColor:"#ff00ff", */display:"flex",textAlign:"center",alignItems:"center" }}>
                                <Form.Label column sm="1" >
                                                             
                                    <Image src={this.state.insertImage} style={{/* width:"900px", height:"110px", *//*  textAlign:"center", *//* justifyContent:"center", */  /* marginLeft:"60px", */}}/>

                                </Form.Label>
                            </Form.Group>}

                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap",marginTop:"-10px", }}>
                                        {this.state.addedparagraph1}
                                    </Form.Label>  
                                </Col>                                    
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap",marginTop:"-10px", }}>
                                        {this.state.addedparagraph2}
                                    </Form.Label>  
                                </Col>                                    
                            </Form.Group>

                            {this.state.articleDataList.length > 0 ?
                                this.state.articleDataList.map(x => <Form.Group key={x.id }  className="mt-3">

                                <Col sm="8" style={{  justifyContent:"center" ,textAlign:"center", marginLeft:"-40px", marginTop:"-10px",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22,/*  whiteSpace: "pre-wrap", */}}>
                                        { x.articleNo} &nbsp;&nbsp;&nbsp; {x.articleName}
                                    </Form.Label> 
                                </Col>

                            </Form.Group>):null}
                            {this.state.sectionDataList.length > 0 ?
                            <Form.Group as={Row} controlId="formPlaintextEmail" /* className="mt-1" */ style={{marginLeft:"70px", marginTop:"-20px"}}>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",}}>
                                        SECTION
                                    </Form.Label>
                                </Col> 
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",marginLeft:"30px",}}>
                                        INFRACTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",marginLeft:"70px",}}>
                                        CATEGORY
                                    </Form.Label>             
                                </Col> 
                            </Form.Group>:null}

                            {this.state.sectionDataList.length > 0 ?
                                this.state.sectionDataList.map(x => <Form.Group as={Row} key={x.id}  className="mt-3">

                                <Col style={{ textAlign:"center",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap", marginLeft:"-140px",marginTop:"-20px",}}>
                                        {x.sectionNo}
                                    </Form.Label>
                                </Col> 
                                <Col style={{textAlign:"center", marginLeft:"-100px" }}>
                                    <Form.Label className="no-text-transform-li" row style={{fontSize: 22 , whiteSpace: "pre-wrap", minWidth:"550px", maxWidth:"550px",marginTop:"-20px",}}>
                                        {x.infraction}
                                    </Form.Label>
                                </Col>
                                <Col style={{marginLeft:"75px", marginRight:"-125px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap", marginTop:"-20px",}}>
                                        {x.category}
                                    </Form.Label>                   
                                </Col> 

                            </Form.Group>):null}

                            {/* *********************article 2************** */}
                            {this.state.articleDataList2.length > 0 ?
                                this.state.articleDataList2.map(x => <Form.Group key={x.id }  className="mt-3">

                                <Col sm="8" style={{  justifyContent:"center" ,textAlign:"center", marginLeft:"-40px", marginTop:"-10px",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22,/*  whiteSpace: "pre-wrap", */}}>
                                        { x.articleNo} &nbsp;&nbsp;&nbsp; {x.articleName}
                                    </Form.Label> 
                                </Col>

                            </Form.Group>):null}
                            {this.state.sectionDataList2.length > 0 ?
                            <Form.Group as={Row} controlId="formPlaintextEmail" /* className="mt-1" */ style={{marginLeft:"70px", marginTop:"-20px"}}>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",}}>
                                        SECTION
                                    </Form.Label>
                                </Col> 
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",marginLeft:"30px",}}>
                                        INFRACTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",marginLeft:"70px",}}>
                                        CATEGORY
                                    </Form.Label>             
                                </Col> 
                            </Form.Group>:null}

                            {this.state.sectionDataList2.length > 0 ?
                                this.state.sectionDataList2.map(x => <Form.Group as={Row} key={x.id}  className="mt-3">

                                <Col style={{ textAlign:"center",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap", marginLeft:"-140px",marginTop:"-20px",}}>
                                        {x.sectionNo}
                                    </Form.Label>
                                </Col> 
                                <Col style={{textAlign:"center", marginLeft:"-100px" }}>
                                    <Form.Label className="no-text-transform-li" row style={{fontSize: 22 , whiteSpace: "pre-wrap", minWidth:"550px", maxWidth:"550px",marginTop:"-20px",}}>
                                        {x.infraction}
                                    </Form.Label>
                                </Col>
                                <Col style={{marginLeft:"75px", marginRight:"-125px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap", marginTop:"-20px",}}>
                                        {x.category}
                                    </Form.Label>                   
                                </Col> 

                            </Form.Group>):null}

                             {/* *********************article 3************** */}
                             {this.state.articleDataList3.length > 0 ?
                                this.state.articleDataList3.map(x => <Form.Group key={x.id }  className="mt-3">

                                <Col sm="8" style={{  justifyContent:"center" ,textAlign:"center", marginLeft:"-40px", marginTop:"-10px",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22,/*  whiteSpace: "pre-wrap", */}}>
                                        { x.articleNo} &nbsp;&nbsp;&nbsp; {x.articleName}
                                    </Form.Label> 
                                </Col>

                            </Form.Group>):null}
                            {this.state.sectionDataList3.length > 0 ?
                            <Form.Group as={Row} controlId="formPlaintextEmail" /* className="mt-1" */ style={{marginLeft:"70px", marginTop:"-20px"}}>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",}}>
                                        SECTION
                                    </Form.Label>
                                </Col> 
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",marginLeft:"30px",}}>
                                        INFRACTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",marginLeft:"70px",}}>
                                        CATEGORY
                                    </Form.Label>             
                                </Col> 
                            </Form.Group>:null}

                            {this.state.sectionDataList3.length > 0 ?
                                this.state.sectionDataList3.map(x => <Form.Group as={Row} key={x.id}  className="mt-3">

                                <Col style={{ textAlign:"center",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap", marginLeft:"-140px",marginTop:"-20px",}}>
                                        {x.sectionNo}
                                    </Form.Label>
                                </Col> 
                                <Col style={{textAlign:"center", marginLeft:"-100px" }}>
                                    <Form.Label className="no-text-transform-li" row style={{fontSize: 22 , whiteSpace: "pre-wrap", minWidth:"550px", maxWidth:"550px",marginTop:"-20px",}}>
                                        {x.infraction}
                                    </Form.Label>
                                </Col>
                                <Col style={{marginLeft:"75px", marginRight:"-125px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap", marginTop:"-20px",}}>
                                        {x.category}
                                    </Form.Label>                   
                                </Col> 

                            </Form.Group>):null}

                             {/* *********************article 4************** */}
                             {this.state.articleDataList4.length > 0 ?
                                this.state.articleDataList4.map(x => <Form.Group key={x.id }  className="mt-3">

                                <Col sm="8" style={{  justifyContent:"center" ,textAlign:"center", marginLeft:"-40px", marginTop:"-10px",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22,/*  whiteSpace: "pre-wrap", */}}>
                                        { x.articleNo} &nbsp;&nbsp;&nbsp; {x.articleName}
                                    </Form.Label> 
                                </Col>

                            </Form.Group>):null}
                            {this.state.sectionDataList4.length > 0 ?
                            <Form.Group as={Row} controlId="formPlaintextEmail" /* className="mt-1" */ style={{marginLeft:"70px", marginTop:"-20px"}}>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",}}>
                                        SECTION
                                    </Form.Label>
                                </Col> 
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",marginLeft:"30px",}}>
                                        INFRACTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",marginLeft:"70px",}}>
                                        CATEGORY
                                    </Form.Label>             
                                </Col> 
                            </Form.Group>:null}

                            {this.state.sectionDataList4.length > 0 ?
                                this.state.sectionDataList4.map(x => <Form.Group as={Row} key={x.id}  className="mt-3">

                                <Col style={{ textAlign:"center",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap", marginLeft:"-140px",marginTop:"-20px",}}>
                                        {x.sectionNo}
                                    </Form.Label>
                                </Col> 
                                <Col style={{textAlign:"center", marginLeft:"-100px" }}>
                                    <Form.Label className="no-text-transform-li" row style={{fontSize: 22 , whiteSpace: "pre-wrap", minWidth:"550px", maxWidth:"550px",marginTop:"-20px",}}>
                                        {x.infraction}
                                    </Form.Label>
                                </Col>
                                <Col style={{marginLeft:"75px", marginRight:"-125px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap", marginTop:"-20px",}}>
                                        {x.category}
                                    </Form.Label>                   
                                </Col> 

                            </Form.Group>):null}

                             {/* *********************article 5************** */}
                             {this.state.articleDataList5.length > 0 ?
                                this.state.articleDataList5.map(x => <Form.Group key={x.id }  className="mt-3">

                                <Col sm="8" style={{  justifyContent:"center" ,textAlign:"center", marginLeft:"-40px", marginTop:"-10px",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22,/*  whiteSpace: "pre-wrap", */}}>
                                        { x.articleNo} &nbsp;&nbsp;&nbsp; {x.articleName}
                                    </Form.Label> 
                                </Col>

                            </Form.Group>):null}
                            {this.state.sectionDataList5.length > 0 ?
                            <Form.Group as={Row} controlId="formPlaintextEmail" /* className="mt-1" */ style={{marginLeft:"70px", marginTop:"-20px"}}>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",}}>
                                        SECTION
                                    </Form.Label>
                                </Col> 
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",marginLeft:"30px",}}>
                                        INFRACTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",marginLeft:"70px",}}>
                                        CATEGORY
                                    </Form.Label>             
                                </Col> 
                            </Form.Group>:null}

                            {this.state.sectionDataList5.length > 0 ?
                                this.state.sectionDataList5.map(x => <Form.Group as={Row} key={x.id}  className="mt-3">

                                <Col style={{ textAlign:"center",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap", marginLeft:"-140px",marginTop:"-20px",}}>
                                        {x.sectionNo}
                                    </Form.Label>
                                </Col> 
                                <Col style={{textAlign:"center", marginLeft:"-100px" }}>
                                    <Form.Label className="no-text-transform-li" row style={{fontSize: 22 , whiteSpace: "pre-wrap", minWidth:"550px", maxWidth:"550px",marginTop:"-20px",}}>
                                        {x.infraction}
                                    </Form.Label>
                                </Col>
                                <Col style={{marginLeft:"75px", marginRight:"-125px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap", marginTop:"-20px",}}>
                                        {x.category}
                                    </Form.Label>                   
                                </Col> 

                            </Form.Group>):null}

                            

                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap", marginTop:"-10px",}}>
                                        {this.state.addedparagraph3}
                                    </Form.Label>  
                                </Col>                                    
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap",marginTop:"-10px", }}>
                                        {this.state.addedparagraph4}
                                    </Form.Label>  
                                </Col>
                            </Form.Group>

                            {this.state.addedparagraph5 !== "" ?
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Col>
                                        <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap",marginTop:"-10px", }}>
                                            {this.state.addedparagraph5}
                                        </Form.Label>  
                                    </Col>
                                </Form.Group>:null
                            }

                            {this.state.addedparagraph6 !== "" ?
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Col>
                                        <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap",marginTop:"-10px", }}>
                                            {this.state.addedparagraph6}
                                        </Form.Label>  
                                    </Col>
                                </Form.Group>:null
                            }

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column style={{fontWeight : "bold",fontSize: 22,marginLeft:"40px", whiteSpace: "pre-wrap",marginTop:"-10px" , }}>
                                    Prepared By :
                                </Form.Label>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 22, marginLeft:"20px",marginTop:"-23px"}}>
                                    <Col sm="5">                                       
                                       <Image src={this.state.eSignaturePreparedBy} style={{width:"240px", height:"60px", textAlign:"center",justifyContent:"center", }}/>
                                    </Col>
                                </Form.Label>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column style={{fontWeight : "bold",fontSize: 22, marginTop:"-25px",marginLeft:"40px",pageBreakBefore:"always" ,}}>
                                    {this.state.preparedBy}
                                </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 18, marginTop:"-19px", marginLeft:"40px",pageBreakBefore:"always" ,}}>
                                    {this.state.prepareByPosition}
                                </Form.Label>
                            </Form.Group>


                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="1" style={{fontWeight : "bold",fontSize: 22,marginLeft:"40px",pageBreakBefore:"always" ,marginTop:"-10px",}}>
                                    Noted By :
                                </Form.Label>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail"> </Form.Group>


                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 22, marginLeft:"20px",marginTop:"-35px"}}>
                                    <Col sm="5">                                       
                                       <Image src={this.state.eSignatureNotedBy} style={{width:"240px", height:"60px", textAlign:"center",justifyContent:"center", }}/>
                                    </Col>
                                </Form.Label>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 22,marginLeft:"40px" , marginTop:"-20px"}}>
                                    {this.state.notedBy}
                                </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 18, marginTop:"-19px", marginLeft:"40px"}}>
                                    {this.state.notedByPosition}
                                </Form.Label>
                            </Form.Group>
                            

                            <Form.Group as={Row} controlId="formPlaintextEmail">

                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 22, marginLeft:"40px",}}>
                                    Received by :
                                </Form.Label>

                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 22, marginLeft:"40px",marginTop:"-15px",}}>
                                    <Col sm="10">
                                        <Form.Control 
                                            type="text"
                                            name="employeeName"
                                            value={this.state.employeeName}
                                            //onChange={this.onChangePosition} 
                                            autoComplete="off"
                                            //placeholder="Enter Name"
                                            style={{fontWeight:"bold",width:"500px",borderTop:"#ffffff", borderLeft:"#ffffff", borderRightColor:"#ffffff",borderRadius: "0",fontSize: 22,marginLeft:"-13px",borderBottomColor:"#000000"}}
                                        />
                                    </Col>
                                </Form.Label>
                            </Form.Group>
                            
                            
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 18,marginTop:"-20px" , marginLeft:"38px"}}>
                                    Sign over printed name / Date
                                </Form.Label>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 18,marginTop:"-20px" , marginLeft:"38px"}}>
                                   cc:  201 File
                                </Form.Label>
                            </Form.Group>

                            { 
                             
                                <Image src={paraFooter} style={{marginLeft:"340px",/* width:"20%", */ height:"150px", textAlign:"center",justifyContent:"center"}}/>
                                
                            }
                        </Form>
                    
                
            </Container>
            <NoserLoading show={this.state.isloading} />

            <TrainingRegisterModal 
                show={this.state.modalTrainingRegisterShow}
                onHide={this.handleModalClose}
                onRefModal={ref => (this.child = ref)}
            />
           
            </>
            
        )
    }

}


class ExportNoda extends React.Component {
    render() {
      return (
        <div>
        
            <ComponentToPrint ref={(el) => (this.componentRef = el)} />
            <Col sm="3" className="mt-3" as={Row}>
                <ReactToPrint
                    trigger={() => <button>Print this out!</button>}
                    content={() => this.componentRef}
                />
                 <ButtonToolbar sm={12}>
                    <NavLink to="/EmployeeDiciplineMonitoring">
                        <Button variant="danger" href="/EmployeeDiciplineMonitoring" style={{minWidth:'60px',marginLeft:"10px"}} onClick={this.setBack}>
                            Close
                        </Button>
                    </NavLink>
                </ButtonToolbar>
            </Col>
        </div>
      );
    }
  }

export default ExportNoda
