//EXTERNAL REACT COMPONENTS
import axios from 'axios'
import moment from 'moment';
import ReactDOM from "react-dom";
import Helmet from 'react-helmet';
import { Alert } from 'reactstrap';
import TimePicker from 'rc-time-picker';
import {Redirect, NavLink} from 'react-router-dom';
import DatePicker from "react-datepicker";
import React, {Component } from "react"
import { Typeahead } from 'react-bootstrap-typeahead';
import BootstrapTable from "react-bootstrap-table-next";
import { AppConfiguration } from "read-appsettings-json";
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton, Accordion } from "react-bootstrap";
//import {CipherString} from './noser-sec'
import { Nav, Navbar, NavDropdown, Image } from "react-bootstrap";
import { DropdownSubmenu, NavDropdownMenu} from "react-bootstrap-submenu";
//import Banner from '../src/components/Nav/Banner'
/* import NoserAuthenticate from './authenticate' */
/* import LeaveModal from "../src/components/Timekeeping/Employee/LeaveModal" */
import NumberFormat from 'react-number-format';
//CSS

/* import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './noser-hris.css'; */




/* import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component'; */


//import { DropdownButton, Dropdown, Image,FormControl} from 'react-bootstrap';

import { Input } from 'reactstrap';
import { isDoWhileStatement } from '@babel/types';
import Pdf from "react-to-pdf";
import ReactToPrint from "react-to-print";
import paramountLogo from "../../icon/paramountLogo.jpg"
import paraFooter from "../../icon/paraFooter.jpg"
import ImgsViewer from 'react-images-viewer'

import paraLogoIr from "../../icon/paraLogoIr.jpg"

const ref = React.createRef();


class ComponentToPrint extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            regexp: /^[0-9\b]+$/,

            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete           :   [],
            clientAutocomplete          :   [],
            positionAutocomplete        :   [],
            trainingAutocomplete        :   [],
            scheduleAutocomplete        :   [],
            trainingRegisterTableList   :   [],
            batchNoAutocomplete         :   [],
            dateFrom                    :   new Date(),
            dateTo                      :   new Date(),
            modalTrainingRegisterShow   :   false,

            isLoading               :"",

            selectedEmployeeName    :[],
            selectedEmployeeId      :[],
            selectedEmployeeNo      :[],
            selectedEmployeeBranch  :[],

            getClientList           :[],
            getEmployeeList         :[],
            getEmployeeNoList       :[],

            irID                    :"",
            dateReported            :"",
            venueOfIncident         :"",
            dateOfIncident          :"",
            timeOfIncident          :"",
            employeeName            :"",
            reportedByEmployee      :"",
            position                :"",
            reportedByPosition      :"",
            reportedDept            :"",
            client                  :"",
            reportedByDept          :"",
            question1               :"",
            question2               :"",
            question3               :"",
            question4               :"",
           
            
            nonEmployee             :"",
            employeeNo              :"",
            irReferenceNo           :"",
         
            reportedByClient        :"",
            
            
            departmentCodeAutocomplete:"",
            irIdCheck:"",
            signature:"",
            
            logo :"https://assets.bossjob.com/companies/13161/logo/3Mwts6BLTZy6I6WhqQm9B79bIhq8dILG7uqq7SHl.png"

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        this.setState({isLoading:true,loadingText:"Loading client list..."})

       /*  this.state.userinfo = JSON.parse(sessionStorage.getItem("userData")) */
        this.state.irIdCheck = JSON.parse(sessionStorage.getItem("irId"))
      /*   console.log("filter start1")
        console.log(this.state.irIdCheck)
        console.log("filter start1") */

       
        //this.getIncidentReport()
        this.getViewDraftMemo()
       
    }
   
   /*  getIncidentReport =()=>{
        let irIdCheckTmp = JSON.parse(sessionStorage.getItem("irId"))
        //console.log("filter start")
        let filter_data ={}
       

        const getParams ={
            "_collection" : "IncidentReport",
            "filter_data" : filter_data
        }
            console.log("axios start")
            axios
            .post("http://134.209.99.190:8088/action/get" , getParams)
            .then(res =>{
                let data = res.data
                //console.log("res.data start")
                //console.log(res.data)
                //console.log("res.data end")
            
                const itemUsersDataLists = this.buildList(data["IncidentReport"])
                
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                    let obj = {
                        'id'                    :   this.state.itemUserDataList[i]['id'],
                        'client'                :   this.state.itemUserDataList[i]['client'],
                        'employeeName'          :   this.state.itemUserDataList[i]['employeeName'],
                        'position'              :   this.state.itemUserDataList[i]['position'],
                        'employeeNo'            :   this.state.itemUserDataList[i]['employeeNo'],
                        'nonEmployee'           :   this.state.itemUserDataList[i]['nonEmployee'],
                        'dateReported'          :   this.state.itemUserDataList[i]['dateReported'],
                        'dateOfIncident'        :   this.state.itemUserDataList[i]['dateOfIncident'],
                        'venueOfIncident'       :   this.state.itemUserDataList[i]['venueOfIncident'],
                        'timeOfIncident'        :   this.state.itemUserDataList[i]['timeOfIncident'],
                        'irReferenceNo'         :   this.state.itemUserDataList[i]['irReferenceNo'],
                        'question1'             :   this.state.itemUserDataList[i]['question1'],
                        'question2'             :   this.state.itemUserDataList[i]['question2'],
                        'question3'             :   this.state.itemUserDataList[i]['question3'],
                        'question4'             :   this.state.itemUserDataList[i]['question4'],
                        'reportedByClient'      :   this.state.itemUserDataList[i]['reportedByClient'],
                        'reportedByEmployee'    :   this.state.itemUserDataList[i]['reportedByEmployee'],
                        'reportedByPosition'    :   this.state.itemUserDataList[i]['reportedByPosition'],
                        'reportedByDept'        :   this.state.itemUserDataList[i]['reportedByDept'],
                        'isDraft'               :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'             :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'             :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'           :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'            :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'          :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'            :   this.state.itemUserDataList[i]['isModified'],
                        
                    }
                    List.push(obj)
                    
                    this.setState({
                        irID:obj.id,
                        dateReported:obj.dateReported,
                        venueOfIncident:obj.venueOfIncident,
                        dateOfIncident:obj.dateOfIncident,
                        timeOfIncident:obj.timeOfIncident,
                        employeeName:obj.employeeName,
                        reportedByEmployee:obj.reportedByEmployee,
                        position:obj.position,
                        reportedByPosition:obj.reportedByPosition,
                        reportedDept:obj.reportedDept,
                        client:obj.client,
                        reportedByDept:obj.reportedByDept,
                        question1:obj.question1,
                        question2:obj.question2,
                        question3:obj.question3,
                        question4:obj.question4,
                    })
                   
                }
                
               

            })
            .catch(error=>{
                //console.log("error: " + error)
            })
        } 
        
    buildList(data) {
        //console.log("data start")
        let itemList =[]
    
        let idList                      =[]
        let clientList                  =[]
        let employeeNameList            =[]
        let positionList                =[]
        let employeeNoList              =[]
        let nonEmployeeList             =[]
        let dateReportedList            =[]
        let dateOfIncidentList          =[]
        let venueOfIncidentList         =[]
        let timeOfIncidentList          =[]
        let irReferenceNoList           =[]
        let question1List               =[]
        let question2List               =[]
        let question3List               =[]
        let question4List               =[]
        let reportedByClientList        =[]
        let reportedByEmployeeList      =[]
        let reportedByPositionList      =[]
        let reportedByDeptList          =[]
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
        
    
        for (let i = 0; i < data.length; i++) {
            
    
            let s1 = data[i].split("':")
            
            let idClean                     = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let clientTmp                   = s1[2].split("',")[0]
            let employeeNameListTmp         = s1[3].split("',")[0]
            let positionListTmp             = s1[4].split("',")[0]
            let employeeNoListTmp           = s1[5].split(",")[0]
            let nonEmployeeListListTmp      = s1[6].split("',")[0]
            let dateReportedListTmp         = s1[7].split("',")[0]
            let dateOfIncidentListTmp       = s1[8].split("',")[0]
            let venueOfIncidentListTmp      = s1[9].split("',")[0]
            let timeOfIncidentListTmp       = s1[10].split("',")[0]
            let irReferenceNoListTmp        = s1[11].split("',")[0]
            let question1ListTmp            = s1[12].split("',")[0]
            let question2ListTmp            = s1[13].split("',")[0]
            let question3ListTmp            = s1[14].split("',")[0]
            let question4ListTmp            = s1[15].split("',")[0]
            let reportedByClientListTmp     = s1[16].split("',")[0]
            let reportedByEmployeeListTmp   = s1[17].split("',")[0]
            let reportedByPositionListTmp   = s1[18].split("',")[0]
            let reportedByDeptListTmp       = s1[19].split("',")[0]
            let isDraftListTmp              = s1[20].split("',")[0]
            let isDeletedListTmp            = s1[21].split("',")[0]
            let createdbyListTmp            = s1[22].split("',")[0]
            let createddateListTmp          = s1[23].split("',")[0]
            let modifiedbyListTmp           = s1[24].split("',")[0]
            let modifieddateListTmp         = s1[25].split("',")[0]
            let isModifiedListTmp           = s1[26].split("',")[0]

        
            idList.push(IdClean2.replace("'",""))
            clientList.push(clientTmp.replace("'",""))
            employeeNameList.push(employeeNameListTmp.replace("'",""))
            positionList.push(positionListTmp.replace("'",""))
            employeeNoList.push(employeeNoListTmp.replace("'","").replace(" '",""))
            nonEmployeeList.push(nonEmployeeListListTmp.replace("'",""))
            dateReportedList.push(dateReportedListTmp.replace("'",""))
            dateOfIncidentList.push(dateOfIncidentListTmp.replace("'",""))
            venueOfIncidentList.push(venueOfIncidentListTmp.replace("'",""))
            timeOfIncidentList.push(timeOfIncidentListTmp.replace("'",""))
            irReferenceNoList.push(irReferenceNoListTmp.replace("'",""))
            question1List.push(question1ListTmp.replace("'",""))
            question2List.push(question2ListTmp.replace("'",""))
            question3List.push(question3ListTmp.replace("'",""))
            question4List.push(question4ListTmp.replace("'",""))
            reportedByClientList.push(reportedByClientListTmp.replace("'",""))
            reportedByEmployeeList.push(reportedByEmployeeListTmp.replace("'",""))
            reportedByPositionList.push(reportedByPositionListTmp.replace("'",""))
            reportedByDeptList.push(reportedByDeptListTmp.replace("'",""))
            isDraftList.push(isDraftListTmp.replace("'",""))
            isDeletedList.push(isDeletedListTmp.replace("'",""))
            createdbyList.push(createdbyListTmp.replace("'",""))
            createddateList.push(createddateListTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'client'                :   clientList[i],
                'employeeName'          :   employeeNameList[i],
                'position'              :   positionList[i],
                'employeeNo'            :   employeeNoList[i],
                'nonEmployee'           :   nonEmployeeList[i],
                'dateReported'          :   dateReportedList[i],
                'dateOfIncident'        :   dateOfIncidentList[i],
                'venueOfIncident'       :   venueOfIncidentList[i],
                'timeOfIncident'        :   timeOfIncidentList[i],
                'irReferenceNo'         :   irReferenceNoList[i],
                'question1'             :   question1List[i],
                'question2'             :   question2List[i],
                'question3'             :   question3List[i],
                'question4'             :   question4List[i],
                'reportedByClient'      :   reportedByClientList[i],
                'reportedByEmployee'    :   reportedByEmployeeList[i],
                'reportedByPosition'    :   reportedByPositionList[i],
                'reportedByDept'        :   reportedByDeptList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }
        
       
        return itemList
    }  */

    getViewDraftMemo=()=>{
        let dataIR = JSON.parse(sessionStorage.getItem("data"))
        console.log("dataIR")
        console.log(dataIR)
        console.log("dataIR")

        this.setState({
          
            irID                : dataIR.id,
            dateReported        : dataIR.dateReported,
            venueOfIncident     : dataIR.venueOfIncident,
            dateOfIncident      : dataIR.dateOfIncident,
            timeOfIncident      : dataIR.timeOfIncident,
            irReferenceNo      : dataIR.irReferenceNo,
            employeeName        : dataIR.employeeName,
            reportedByEmployee  : dataIR.reportedByEmployee,
            position            : dataIR.position,
            reportedByPosition  : dataIR.reportedByPosition,
            reportedDept        : dataIR.reportedDept,
            client              : dataIR.client,
            reportedByDept      : dataIR.reportedByClient,
            question1           : dataIR.question1,
            question2           : dataIR.question2,
            question3           : dataIR.question3,
            question4           : dataIR.question4,
            signature           : dataIR.signature,
        })
      
    }

    setBack=()=>{
        let whenBack = 0
        sessionStorage.setItem("nextProcessCheck", JSON.stringify(whenBack)) 

    }

    render() {
        return( 
            
        <>
            <Container className="no-text-transform" /* fluid */>
               
            <Form style={{backgroundColor:"#FFFFFF" , textTransform:"none"}}>
                            
                               
                <Image src={paraLogoIr} style={{width:"50%", height:"120%", marginLeft:"280px" , marginTop:"30px",marginBottom:"-10px"}}/>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "bold" , fontSize: 20, marginLeft:"450px"}}>
                        INCIDENT REPORT FORM
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} sm="10" style={{marginTop:"30px" }}>                                    
                    
                    <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 18,  marginLeft:"55px"}}>
                    TIME / DATE :
                    </Form.Label> 
                   
                    <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 18, marginLeft:"330px"}}>
                    Incident Report No : {this.state.irReferenceNo}
                    </Form.Label> 
                            
                </Form.Group>

                <Form.Group as={Row} sm="10">                                    
                    
                    <Form.Label className="no-text-transform-li" row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"55px"}}>
                        Date Reported 
                    </Form.Label> 
                    <Form.Label row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"30px"}}>
                        :
                    </Form.Label>
                    <Form.Label row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginLeft:"40px",marginTop:"-6px" }}>
                        {this.state.dateReported}                                  
                    </Form.Label> 
                    <Form.Label className="no-text-transform-li" row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"165px"}}>
                        Venue Of Incident 
                    </Form.Label> 
                    <Form.Label row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"9px"}}>
                        :
                    </Form.Label>
                    <Form.Label row style={{fontWeight : "normal" ,fontSize: 18,marginLeft:"15px",marginTop:"-6px" }}>
                        {this.state.venueOfIncident}                             
                    </Form.Label>    
                    
                                                
                </Form.Group>
                <Form.Group as={Row} sm="10"style={{marginTop:"-40px"}}>                                    
                    
                    <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 18,marginTop:"-6px" , marginLeft:"55px"}}>
                        
                    </Form.Label> 
                    <Form.Label row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginLeft:"170px"}}>
                        ______________________                     
                    </Form.Label>    
                    <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 18,marginTop:"-6px" , marginLeft:"55px"}}>
                        
                    </Form.Label> 
                    <Form.Label row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginLeft:"220px"}}>
                        ______________________________              
                    </Form.Label> 
                                                
                </Form.Group>
                <Form.Group as={Row} sm="10">                                    
                    
                    <Form.Label className="no-text-transform-li" row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"55px"}}>
                        Date Of Incident 
                    </Form.Label> 
                    <Form.Label row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"15px"}}>
                        :
                    </Form.Label>
                    <Form.Label row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginLeft:"40px",marginTop:"-6px" }}>
                        {this.state.dateOfIncident}                                
                    </Form.Label> 
                    <Form.Label className="no-text-transform-li" row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"165px"}}>
                        Time Of Incident 
                    </Form.Label> 
                    <Form.Label row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"23px"}}>
                        :
                    </Form.Label>
                    <Form.Label row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginLeft:"15px",marginTop:"-6px" }}>
                        {this.state.timeOfIncident}                            
                    </Form.Label>    
                    
                                                
                </Form.Group>
                <Form.Group as={Row} sm="10"style={{marginTop:"-40px"}}>                                    
                    
                    <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 18,marginTop:"-6px" , marginLeft:"55px"}}>
                        
                    </Form.Label> 
                    <Form.Label row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginLeft:"170px"}}>
                        ______________________                     
                    </Form.Label>    
                    <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 18,marginTop:"-6px" , marginLeft:"55px"}}>
                        
                    </Form.Label> 
                    <Form.Label row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginLeft:"220px"}}>
                        ______________________________              
                    </Form.Label> 
                                                
                </Form.Group>

                <Form.Group as={Row} sm="10" /* style={{marginTop:"-6px" , marginLeft:"55px"}} */>                                    
                    
                    <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 18,  marginLeft:"55px"}}>
                    PERSON TO BE REPORTED:
                    </Form.Label> 
                   
                 {/*    <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 18, marginLeft:"220px"}}>
                    REPORTED BY :
                    </Form.Label>  */}
                            
                </Form.Group>

                <Form.Group as={Row} /* sm="10" */>    

                    <div style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" ,/*  marginLeft:"30px" , */ /* minWidth:"500px", maxWidth:"500px" */}}>
                        
                        <Form.Label className="no-text-transform-li" row style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"55px",}}>
                            Client&nbsp;:
                        </Form.Label> 
                        
                        <Form.Label row style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"5px" ,/*  minWidth:"385px" , maxWidth:"385px", */ textAlign:"left"}}>
                            {this.state.client}
                        </Form.Label> 
                        
                        
                    </div>  

                    {/* <div style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" ,}}>
                       
                        <Form.Label className="no-text-transform-li" row style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , }}>
                            Client&nbsp;:
                        </Form.Label> 
                        <Form.Label row style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"5px" , minWidth:"385px" , maxWidth:"385px", textAlign:"left"}}>
                            { this.state.reportedByDept }
                        </Form.Label> 
                    </div>  */}

                </Form.Group>  

                <Form.Group as={Row} sm="10"style={{marginTop:"-40px",marginLeft:"75px" }}>             

                    <div style={{fontWeight : "normal" ,fontSize: 18,/* marginTop:"-6px" , */ marginLeft:"20px" , /* minWidth:"500px", maxWidth:"500px" */}}>                        
                        <Form.Label row style={{fontWeight : "normal" ,fontSize: 18,/* marginTop:"-6px" , */ marginLeft:"5px" , minWidth:"375px" , maxWidth:"375px", textAlign:"left"}}>
                            ________________________________________________________________________________________________________
                        </Form.Label> 
                    </div>     
                    {/* <div style={{fontWeight : "normal" ,fontSize: 18,}}>
                        
                        <Form.Label row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginLeft:"70px"}}>
                        _______________________________________________
                        </Form.Label>
                    </div>       */}               
                             
                </Form.Group>

                
                <Form.Group as={Row} /* sm="10" */>    
                    <div style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" ,/*  marginLeft:"30px" , */ /* minWidth:"500px", maxWidth:"500px" */}}>
                        
                        <Form.Label className="no-text-transform-li" row style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"55px",}}>
                            NAME&nbsp;:
                        </Form.Label> 
                        
                        <Form.Label row style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"5px" , minWidth:"382px" , maxWidth:"382px"}}>
                            {this.state.employeeName}
                        </Form.Label> 
                        
                        
                    </div>  
                   {/*  <div style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" ,}}>
                        
                        <Form.Label className="no-text-transform-li" row style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , }}>
                            NAME&nbsp;:
                        </Form.Label>
                        <Form.Label row style={{fontWeight : "normal" ,fontSize: 18,marginLeft:"5px",marginTop:"-6px", }}>
                            { this.state.reportedByEmployee }        
                        </Form.Label> 
                       
                    </div>  */}
                </Form.Group>  

                <Form.Group as={Row} sm="10"style={{marginTop:"-40px"}}>                                    
                    
                    <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 18,marginTop:"-6px" , }}>
                        
                    </Form.Label> 
                    <Form.Label row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginLeft:"120px"}}>
                    ________________________________________________________________________________________________________
                    </Form.Label>    
                    <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 18,marginTop:"-6px" , marginLeft:"55px"}}>
                        
                    </Form.Label> 
                 {/*    <Form.Label row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginLeft:"120px"}}>
                        _______________________________________________
                    </Form.Label>  */}

                </Form.Group>

                <Form.Group as={Row} /* sm="10" */>    
                    <div style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" ,/*  marginLeft:"30px" , */ /* minWidth:"500px", maxWidth:"500px" */}}>
                        
                        <Form.Label className="no-text-transform-li" row style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"55px",}}>
                            Position&nbsp;:
                        </Form.Label> 
                        
                        <Form.Label row style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"5px" , minWidth:"370px" , maxWidth:"360px"}}>
                            {this.state.position}    
                        </Form.Label> 
                        
                        
                    </div>  
                    {/* <div style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , minWidth:"400px", maxWidth:"400px"}}>
                        
                        <Form.Label className="no-text-transform-li" row style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , }}>
                            Position&nbsp;:
                        </Form.Label> 
                        <Form.Label row style={{fontWeight : "normal" ,fontSize: 18,marginLeft:"5px",marginTop:"-6px", }}>
                            { this.state.reportedByPosition }        
                        </Form.Label> 
                    </div>  */}
                </Form.Group>  
                
                <Form.Group as={Row} sm="10"style={{marginTop:"-40px"}}>                                    
                    
                    <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 18,marginTop:"-6px" , }}>
                        
                    </Form.Label> 
                    <Form.Label row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginLeft:"135px"}}>
                        ______________________________________________________________________________________________________
                    </Form.Label>    
                    <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 18,marginTop:"-6px" , marginLeft:"55px"}}>
                        
                    </Form.Label> 
                   {/*  <Form.Label row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginLeft:"135px"}}>
                        _____________________________________________
                    </Form.Label>  */}

                </Form.Group>

                

                {/* <Form.Group as={Row} >    
                    <div style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" ,}}>
                        
                        <Form.Label className="no-text-transform-li" row style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"110px",}}>
                        
                        </Form.Label> 
                        
                        <Form.Label row style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"5px" , minWidth:"385px" , maxWidth:"385px", textAlign:"left"}}>
                        
                        </Form.Label> 
                        
                        
                    </div>  
                    <div style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" ,}}>
                       
                        <Form.Label className="no-text-transform-li" row style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , }}>
                            Signature&nbsp;:
                        </Form.Label> 
                    </div> 
                </Form.Group>  */} 

                {/* <Form.Group as={Row} sm="10"style={{marginTop:"-40px",marginLeft:"75px" }}>             

                    <div style={{fontWeight : "normal" ,fontSize: 18, marginLeft:"50px" , }}>                        
                        <Form.Label row style={{fontWeight : "normal" ,fontSize: 18, marginLeft:"5px" , minWidth:"375px" , maxWidth:"375px", textAlign:"left"}}>
                       
                        </Form.Label> 
                    </div>     
                    <div style={{fontWeight : "normal" ,fontSize: 18,}}>
                        
                        <Form.Label row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginLeft:"70px"}}>
                        _______________________________________________
                        </Form.Label>
                    </div>                     
                                       
                </Form.Group> */}

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "bold" , fontSize: 18, marginLeft:"40px"}}>
                        BRIEF DESCRIPTION OF INCIDENT :
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label className="no-text-transform-li" column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"60px"}}>
                    1. What happened?
                    </Form.Label>
                </Form.Group>
                <Form.Group as={Row} /* className="no-text-transform-li" */ style={{width:"93%",whiteSpace: "pre-wrap",textTransform:"none"}}>
                    <Form.Label className="no-text-transform-li" column style={{fontWeight : "normal" , fontSize: 18, marginLeft:"65px",textTransform:"none"}}>
                    { this.state.question1}
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                    _________________________________________________________________________________________________________________________________
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label className="no-text-transform-li" column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"60px"}}>
                    2. What physical evidence or document exist to support your report?
                    </Form.Label>
                </Form.Group>
                <Form.Group as={Row} className="mt-3" style={{width:"93%",whiteSpace: "pre-wrap",/* lineBreak:"auto" */}}>
                    <Form.Label className="no-text-transform-li" column style={{fontWeight : "normal" , fontSize: 18, marginLeft:"65px",}}>
                    { this.state.question2}
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                    _________________________________________________________________________________________________________________________________
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label className="no-text-transform-li" column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"60px"}}>
                    3. How did you know the incident and who are the possible witness/es?
                    </Form.Label>
                </Form.Group>
                <Form.Group as={Row} className="mt-3" style={{width:"93%",whiteSpace: "pre-wrap",/* lineBreak:"auto" */}}>
                    <Form.Label className="no-text-transform-li" column style={{fontWeight : "normal" , fontSize: 18, marginLeft:"65px",}}>
                    { this.state.question3 }
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                    _________________________________________________________________________________________________________________________________
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label className="no-text-transform-li" column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"60px", /* fontFamily:"sans-serif", */textTransform:"-moz-initial", /* font-family:garamond,serif; */}}>
                    4. Is there money involved ? If yes please specify the estimated amount?
                    </Form.Label>
                </Form.Group>
                <Form.Group as={Row} className="mt-3" style={{width:"93%",whiteSpace: "pre-wrap", /* fontFamily:"sans-serif", */ textTransform:"none", /* lineBreak:"auto" */}}>
                    <Form.Label className="no-text-transform-li" column style={{fontWeight : "normal" , fontSize: 18, marginLeft:"65px",textTransform:"none"}}>
                    {this.state.question4}
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                    _________________________________________________________________________________________________________________________________
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} sm="10" /* style={{marginTop:"-6px" , marginLeft:"55px"}} */>                                    
                    
                    <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 18,  marginLeft:"55px"}}>
                    REPORTED BY :
                    </Form.Label> 
                
                            
                </Form.Group>

                <Form.Group as={Row} /* sm="10" */>    

                    <div style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" ,/*  marginLeft:"30px" , */ /* minWidth:"500px", maxWidth:"500px" */}}>
                        
                        <Form.Label className="no-text-transform-li" row style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"55px",}}>
                            Client&nbsp;:
                        </Form.Label> 
                        
                        <Form.Label row style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"5px" , /* minWidth:"385px" , maxWidth:"385px", */ textAlign:"left"}}>
                            { this.state.reportedByDept }
                        </Form.Label> 
                        
                        
                    </div>  
                    {/* <div style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" ,}}>
                       
                        <Form.Label className="no-text-transform-li" row style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , }}>
                            Client&nbsp;:
                        </Form.Label> 
                        <Form.Label row style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"5px" , minWidth:"385px" , maxWidth:"385px", textAlign:"left"}}>
                            { this.state.reportedByDept }
                        </Form.Label> 
                    </div>  */}

                </Form.Group>  

                <Form.Group as={Row} sm="10"style={{marginTop:"-40px",marginLeft:"75px" }}>             

                    <div style={{fontWeight : "normal" ,fontSize: 18,/* marginTop:"-6px" , */ marginLeft:"20px" , /* minWidth:"500px", maxWidth:"500px" */}}>                        
                        <Form.Label row style={{fontWeight : "normal" ,fontSize: 18,/* marginTop:"-6px" , */ marginLeft:"5px" , minWidth:"375px" , maxWidth:"375px", textAlign:"left"}}>
                        ____________________________________________________________________________________________________________
                        </Form.Label> 
                    </div>     
                   {/*  <div style={{fontWeight : "normal" ,fontSize: 18,}}>
                        
                        <Form.Label row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginLeft:"70px"}}>
                        _______________________________________________
                        </Form.Label>
                    </div>      */}                
                  
                                                
                </Form.Group>

                <Form.Group as={Row} /* sm="10" */>    
                    <div style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" ,/*  marginLeft:"30px" , */ /* minWidth:"500px", maxWidth:"500px" */}}>
                        
                        <Form.Label className="no-text-transform-li" row style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"55px",}}>
                            NAME&nbsp;:
                        </Form.Label> 
                        
                        <Form.Label row style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"5px" , minWidth:"382px" , maxWidth:"382px"}}>
                            { this.state.reportedByEmployee }    
                        </Form.Label> 
                        
                        
                    </div>  
                    <div style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , /* minWidth:"600px", maxWidth:"600px" */}}>
                        
                        <Form.Label className="no-text-transform-li" row style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"15px", }}>
                            Position&nbsp;:
                        </Form.Label>
                        <Form.Label row style={{fontWeight : "normal" ,fontSize: 18,marginLeft:"5px",marginTop:"-6px", }}>
                            { this.state.reportedByPosition }       
                        </Form.Label> 
                       
                    </div> 
                </Form.Group>  

                <Form.Group as={Row} sm="10"style={{marginTop:"-40px"}}>                                    
                    
                    <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 18,marginTop:"-6px" , }}>
                        
                    </Form.Label> 
                    <Form.Label row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginLeft:"120px"}}>
                        ____________________________________
                    </Form.Label>    
                    <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 18,marginTop:"-6px" , marginLeft:"55px"}}>
                        
                    </Form.Label> 
                    <Form.Label row sm="3" style={{fontWeight : "normal" ,fontSize: 18,marginLeft:"130px"}}>
                        _______________________________________________
                    </Form.Label> 

                </Form.Group>

                

                <Form.Group as={Row} /* sm="10" */>    
                    <div style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" ,/*  marginLeft:"30px" , */ /* minWidth:"500px", maxWidth:"500px" */}}>
                        
                        <Form.Label className="no-text-transform-li" row style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"55px",}}>
                            Signature&nbsp;:
                        </Form.Label> 
                        
                        <Form.Label row style={{fontWeight : "normal" ,fontSize: 18,marginTop:"-6px" , marginLeft:"5px" , minWidth:"385px" , maxWidth:"385px", textAlign:"left"}}>
                        
                        </Form.Label> 
                        
                    </div>  
                   
                </Form.Group>  

                <Form.Group as={Row} sm="10"style={{marginTop:"-40px",marginLeft:"75px" }}>             

                    <div style={{fontWeight : "normal" ,fontSize: 18,/* marginTop:"-6px" , */ marginLeft:"50px" , /* minWidth:"500px", maxWidth:"500px" */}}>                        
                        <Form.Label row style={{fontWeight : "normal" ,fontSize: 18,/* marginTop:"-6px" , */ marginLeft:"5px" , minWidth:"375px" , maxWidth:"375px", textAlign:"left"}}>
                        _______________________________________________
                        </Form.Label> 
                    </div>                   
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                   
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 18, marginLeft:"63px" , marginTop:"-45px"}}>
                    
                    </Form.Label>
                </Form.Group>

                <h1 className="mt-3" style={{fontWeight : "bold" ,backgroundColor:'#D5D5D5', width:"100%", height:"7px",  }} > </h1>
                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" style={{fontWeight : "normal" , fontSize: 15, marginLeft:"75%" , marginTop:"-15px"}}>
                    PHRMPC HR-Administration
                    </Form.Label>
                </Form.Group>

                          {/*   <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" >
                                <Col sm="5" style={{width:"95%", }}>
                                    <Form.Label row style={{fontSize: 22, marginLeft:"40px"}}>
                                        {this.state.paragraph}  
                                    </Form.Label>                                                          
                                </Col>                                    
                            </Form.Group> */}

                            {/* <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label row style={{ width:"95%", fontSize: 22, marginLeft:"40px",whiteSpace: "pre-wrap", }}>
                                        {this.state.paragraph}
                                    </Form.Label>  
                                </Col>                                    
                            </Form.Group> */}


                         {/*    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" style={{width:"85%"}}>
                                <Col sm="5" >
                                    <Form.Label row style={{fontSize: 22, fontWeight:"bold" ,  marginLeft:"85px" , marginRight:"20px"}}>
                                        {this.state.quotedStatement}
                                    </Form.Label> 
                                </Col>                                    
                            </Form.Group> */}

                            {/* <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label row style={{ width:"85%", fontSize: 22, marginLeft:"85px", marginRight:"40px",fontWeight:"bold" ,whiteSpace: "pre-wrap", }}>
                                        {this.state.quotedStatement}
                                    </Form.Label>  
                                </Col>                                    
                            </Form.Group> */}

                           {/*  <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" >
                                <Col sm="5">
                                    <Form.Label row style={{width:"95%",fontSize: 22, marginLeft:"40px"}}>
                                        {this.state.addedparagraph1}
                                    </Form.Label> 
                                                            
                                </Col>                                    
                            </Form.Group> */}
                           {/*  <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap", }}>
                                        {this.state.addedparagraph1}
                                    </Form.Label>  
                                </Col>                                    
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col sm="8" style={{  justifyContent:"center" ,textAlign:"center", marginLeft:"-60px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22,}}>
                                        { this.state.articleNo } &nbsp;&nbsp;&nbsp; {this.state.articleName}
                                    </Form.Label> 
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col sm="8" style={{ justifyContent:"center" ,textAlign:"center", marginLeft:"-60px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22,}}>
                                        {this.state.articleNo2} &nbsp;&nbsp;&nbsp; {this.state.articleName2}
                                    </Form.Label>
                                </Col>
                            </Form.Group>
                            
                            <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginLeft:"150px", marginTop:"-10px"}}>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",}}>
                                        SECTION
                                    </Form.Label>
                                </Col> 
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",marginLeft:"-20px",}}>
                                        INFRACTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",}}>
                                        CATEGORY
                                    </Form.Label>             
                                </Col> 
                            </Form.Group> */}

                            {/* <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3" >
                                <Col style={{ marginLeft:"30px", textAlign:"center",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",}}>
                                        {this.state.sectionNo}
                                    </Form.Label>
                                </Col> 
                                <Col style={{textAlign:"center",marginRight:"20px", marginLeft:"-35px" }}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",}}>
                                        {this.state.infraction}
                                    </Form.Label>
                                </Col>
                                <Col style={{marginLeft:"100px", marginRight:"-75px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap", }}>
                                        {this.state.category}
                                    </Form.Label>                   
                                </Col> 
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3" >
                                <Col style={{ marginLeft:"45px", textAlign:"center",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",}}>
                                        {this.state.sectionNo2}
                                    </Form.Label>
                                </Col> 
                                <Col style={{textAlign:"center",marginRight:"20px", marginLeft:"-35px" }}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",}}>
                                        {this.state.infraction2}
                                    </Form.Label>
                                </Col>
                                <Col style={{marginLeft:"100px", marginRight:"-70px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap", }}>
                                        {this.state.category2}
                                    </Form.Label>                   
                                </Col> 
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3" >
                                <Col style={{ marginLeft:"45px", textAlign:"center",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",}}>
                                        {this.state.sectionNo3}
                                    </Form.Label>
                                </Col> 
                                <Col style={{textAlign:"center",marginRight:"20px", marginLeft:"-35px" }}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",}}>
                                        {this.state.infraction3}
                                    </Form.Label>
                                </Col>
                                <Col style={{marginLeft:"100px", marginRight:"-70px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap", }}>
                                        {this.state.category3}
                                    </Form.Label>                   
                                </Col> 
                            </Form.Group> */}

                            {/* <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap", }}>
                                        {this.state.addedparagraph2}
                                    </Form.Label>  
                                </Col>                                    
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap", }}>
                                        {this.state.addedparagraph3}
                                    </Form.Label>  
                                </Col>                                    
                            </Form.Group> */}

                            {/* <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap", }}>
                                        {this.state.addedparagraph4}
                                    </Form.Label>  
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column style={{fontWeight : "bold",fontSize: 22,marginLeft:"40px", whiteSpace: "pre-wrap", }}>
                                    Prepared By :
                                </Form.Label>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 22, marginLeft:"20px",marginTop:"-10px"}}>
                                    <Col sm="5">                                       
                                       <Image src={this.state.eSignaturePreparedBy} style={{width:"240px", height:"60px", textAlign:"center",justifyContent:"center", }}/>
                                    </Col>
                                </Form.Label>
                            </Form.Group> */}

                           {/*  <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column style={{fontWeight : "bold",fontSize: 22, marginTop:"-20px",marginLeft:"40px"}}>
                                    {this.state.preparedBy}
                                </Form.Label>
                            </Form.Group> */}


                           {/*  <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 22,marginLeft:"40px"}}>
                                    Noted By :
                                </Form.Label>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail"> </Form.Group> */}

                          {/*   <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 22, marginLeft:"40px"}}>
                                    <Col sm="10">
                                       
                                    </Col>
                                </Form.Label>
                            </Form.Group> */}

                            {/* <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 22, marginLeft:"20px",marginTop:"-25px"}}>
                                    <Col sm="5">                                       
                                       <Image src={this.state.eSignatureNotedBy} style={{width:"240px", height:"60px", textAlign:"center",justifyContent:"center", }}/>
                                    </Col>
                                </Form.Label>
                            </Form.Group> */}

                           {/*  <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2" style={{fontWeight : "bold",fontSize: 22,marginLeft:"40px" , marginTop:"-20px"}}>
                                    {this.state.notedBy}
                                </Form.Label>
                            </Form.Group> */}

                           {/*  <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2" style={{fontWeight : "bold",fontSize: 18, marginTop:"-19px", marginLeft:"40px"}}>
                                    {this.state.prepareByPosition}
                                </Form.Label>
                            </Form.Group> */}

                           {/*  <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2" style={{fontWeight : "bold",fontSize: 22, marginLeft:"40px"}}>
                                    Received by :
                                </Form.Label>

                            </Form.Group> */}

                            {/* <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 22, marginLeft:"40px"}}>
                                    <Col sm="10">
                                        <Form.Control 
                                            type="text"
                                            name="employeeName"
                                            value={this.state.employeeName}
                                            //onChange={this.onChangePosition} 
                                            autoComplete="off"
                                            //placeholder="Enter Name"
                                            style={{fontWeight:"bold",width:"500px",borderTop:"#ffffff", borderLeft:"#ffffff", borderRightColor:"#ffffff",borderRadius: "0",fontSize: 22,marginLeft:"-13px",borderBottomColor:"#000000"}}
                                        />
                                    </Col>
                                </Form.Label>
                            </Form.Group> */}
                            
                            
                            {/*   <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2" style={{fontWeight : "bold",fontSize: 18,marginTop:"-20px" , marginLeft:"38px"}}>
                                    Sign over printed name
                                </Form.Label>
                            </Form.Group>
                            */}
                        </Form>
                   
            </Container>
            
            </>
            
        )
    }

}


class ExportIncidentReport extends React.Component {
    render() {
      return (
        <div>
        
            <ComponentToPrint ref={(el) => (this.componentRef = el)} />
            <Col sm="3" className="mt-3" as={Row} style={{marginLeft:"10px"}}>
                <ReactToPrint
                    trigger={() => <button>Print this out!</button>}
                    content={() => this.componentRef}
                />
                <ButtonToolbar sm={12}>
                    <NavLink to="/EmployeeDiciplineMonitoring">
                        <Button variant="danger" href="/EmployeeDiciplineMonitoring" style={{minWidth:'60px',marginLeft:"10px"}} onClick={this.setBack}>
                            Close
                        </Button>
                    </NavLink>
                </ButtonToolbar>
            </Col>
            <Col sm="3" className="mt-3" as={Row}>
              
            </Col>
            <Col sm="3" className="mt-3" as={Row}>
              
            </Col>
            <Col sm="3" className="mt-3" as={Row}>
              
              </Col>
        </div>
      );
    }
  }

export default ExportIncidentReport
