import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Accordion
} 
from '../../../noser-hris-component';
import BillablePerTripModal from './BillablePerTripModal';



class BillablePerTrip extends Component {
    state = {
        userinfo        :   [],
        isloading       :   false,
        isshow          :   false,
        alerttype       :   "",
        message         :   "",
        color           :   "",
        fade            :   true,
        disableDeleteBtn:   true,
        openModal       :   false,
        
        clientDDL       :   [],
        periodDDL       :   [],
        payrollGRID     :   [],

        clientId        :   "",
        clientName      :   "",
        periodId        :   "",
        periodName      :   "",
        startDate       :   "",
        endDate         :   "",
    }

    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients()
    }
    handleModalShow = (e) =>{

        if(this.state.clientId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client.",
                fade        :   true
            })
            return
        }

        this.setState({openModal:true})
        let obj = {
            typeId:e,
            clientId:this.state.clientId,
            clientName:this.state.clientName,
            periodId:this.state.periodId,
            periodName:this.state.periodName,
            startDate:this.state.startDate,
            endDate:this.state.endDate
        }
        this.child.initialize(obj)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.handleSearchClick()
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" || 
                         this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            let arr = []
            
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL:arr.filter(x=>x.payrollTypeId==="7"),isloading:false})
            }
            else{
                this.setState({clientDDL:data.clients.filter(x=>x.payrollTypeId==="7"),isloading:false})
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetPayrollPeriods = async() => {
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   "",
            "IsProcessed"   :   ""
         };
         
        await axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollPeriods",  params
             )
             .then(res => {
                const data = res.data;
                this.setState({periodDDL:data.payrollPeriods,isloading:false})
            })
    }
    
    handleChangedClient = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.clientId= ''
            this.state.clientName=''
            return
        }
        this.state.clientId=e[0].id
        this.state.clientName=e[0].name
        this.GetPayrollPeriods()
    }
    handleChangedPeriod = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.periodId=''
            this.state.periodName=''
            this.state.startDate=''
            this.state.endDate=''
            return
        }
        this.state.periodId=e[0].periodId
        this.state.periodName=e[0].payPeriod
        this.state.startDate=e[0].startDate
        this.state.endDate=e[0].endDate
    }
    handleSearchClick = async()=>{
        this.setState({isloading:true})
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(this.state.clientId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client.",
                fade        :   true
            })
            return
        }

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "PayPeriodId":this.state.periodId,
        }
        
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPerTripBillableHours", params)
        .then(res => {
            const data = res.data
            console.log(data)
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    disableSaveBtn: false
                });
            }
            this.setState({isloading:false,payrollGRID:data.header});
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
                disableSaveBtn: false
            })
        })
    }
    
    handleDeleteClick = async() =>{
        this.setState({isloading:true,disableDeleteBtn:true})
        if(this.state.payrollGRID.filter(x=>x.isDeleted==="1").length==="0"){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "No selected data.",
                fade        :   true
            })
        }

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "tripdata":this.state.payrollGRID.filter(x=>x.isDeleted==="1"),
        }
        
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/EditPerTripBillableHours", params)
        .then(res => {
            const data = res.data
            console.log(data)
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    disableSaveBtn: false
                });
            }
            this.setState({isloading:false});
            this.handleSearchClick()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
                disableSaveBtn: false
            })
        })
    }
    handleClickDownload = async(row)=>{
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "FileName"      :   row.fileName
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/DownloadPerTripFile", params,{'responseType': 'blob'})
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', row.uploadFileName);
            document.body.appendChild(link);
            link.click();
            this.setState({isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    render() {
        const cols = [
            {
                dataField: 'clientName',
                text: 'CLIENT',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'60%' }},
                style:{textAlign:'left',width:'60%'},
            },
            {
                dataField: 'payPeriodName',
                text: 'Payroll Period',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%' }},
                style:{textAlign:'left',width:'10%'},
                sort:true,
            },
            // {
            //     dataField: 'totalTxn',
            //     text: 'TOTAL TXN',
            //     editable: true,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'10%' }},
            //     style:{textAlign:'center',width:'10%'},
            //     sort:true,
            // },
            {
                dataField: 'uploadFileName',
                text: 'FILE NAME',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'30%' }},
                style:{textAlign:'left',width:'30%'},
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}}
                            variant="link" onClick={e => this.handleClickDownload(row)}
                    >{cell}</Button>
                    );
                } 
            }
        ]
        const colDetails = [
            {
                dataField: 'employeeName',
                text: 'EMPLOYEE NAME',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%'}},
                style:{textAlign:'left',width:'20%'},
            },
            {
                dataField: 'employeeNo',
                text: 'EMPLOYEE NO',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center',width:'10%'},
            },
            { dataField: 'dtrDate', text: 'DTRDate',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center'}
            },
            { dataField: 'tripRate', text: 'TRIP RATE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'30%'}},
                style:{textAlign:'left'}
            },
            { dataField: 'noOfTrip', text: 'NO OF TRIP',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center'}
            },
            { dataField: 'basicRate', text: 'BASIC RATE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'allowanceRate', text: 'ALLOWANCE RATE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'right'}
            },
            
        ]
        const expandRow = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <BootstrapTable
                    keyField = "id"
                    data = { row.details }
                    columns = { colDetails }
                    rowClasses="noser-table-row-class"
                    striped
                    hover
                    condensed
                    expandRow
                    wrapperClasses="table-responsive"
                    rowClasses="noser-table-row-class no-checkbox"
                    selectRow={selectDetRow}
                    noDataIndication={ () => <div>No details.</div> }
                /> 
                
            ),
            showExpandColumn: true,
          };
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.payrollGRID.map(function(item,i){
                    if(item.id===row.id && item.statusId==="0")
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableDeleteBtn:isDisable})
            }
        };
        const selectDetRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
            }
        };
    return(
            <div>
                <Banner />
                    <Container className="themed-container" fluid={true}>
                        <Card className="mt-5">
                            <Card.Header>PAYROLL >> BILLABLE PER TRIP</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Card>
                                    <Accordion defaultActiveKey="1">
                                        <div className="card-header-tab">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="1" style={{color: "#FFFFFF"}}>
                                                SEARCH FILTER
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <Form>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <Typeahead
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangedClient}
                                                                options={this.state.clientDDL}
                                                                placeholder="Select CLient"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <Typeahead
                                                                labelKey='payPeriod'
                                                                id="basic-example"
                                                                onChange={this.handleChangedPeriod}
                                                                options={this.state.periodDDL}
                                                                placeholder="Select Period"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    {/* <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <Typeahead
                                                                labelKey='employeeName'
                                                                id="basic-example"
                                                                onChange={this.handleChangedEmployee}
                                                                options={this.state.employeeDDL}
                                                                placeholder="Select EMPLOYEE"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <Typeahead
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangedInclusionType}
                                                                options={this.state.inclusiontypeDDL}
                                                                placeholder="Select INCLUSION TYPE"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <Typeahead
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangedInclusion}
                                                                options={this.state.inclusionDDL}
                                                                placeholder="Select INCLUSION"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <Typeahead
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangedStatus}
                                                                options={this.state.statusDDL}
                                                                placeholder="Select STATUS"
                                                            />
                                                        </Col>
                                                    </Form.Group> */}
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <ButtonToolbar>
                                                            <Button variant="warning" className="noser-button-mr1" onClick={() => this.handleModalShow("1") }>Download</Button>
                                                            <Button variant="info" className="noser-button" onClick={() => this.handleModalShow("0") }>Upload</Button>
                                                            <Button variant="primary" className="ml-auto noser-button" onClick={ this.handleSearchClick }>Search</Button>
                                                            </ButtonToolbar>
                                                        </Col>
                                                    </Form.Group>
                                                </Form>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Accordion>
                                    
                                    </Card>
                                    
                                    <div className="mt-2">
                                    <Card.Header>LIST OF BILLABLE PER TRIP</Card.Header>
                                        <BootstrapTable
                                            keyField = "id"
                                            data = { this.state.payrollGRID }
                                            columns = { cols }
                                            selectRow = { selectRow }
                                            cellEdit = { cellEditFactory({ 
                                                    mode: 'dbclick', 
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => { 
                                                        this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            striped
                                            hover
                                            condensed
                                            loading={true}
                                            wrapperClasses="table-responsive"
                                            pagination={ paginationFactory({sizePerPageRenderer})}
                                            expandRow={ expandRow }
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        />
                                        <ButtonToolbar sm={12}>
                                            <Button variant="danger" className="ml-auto noser-button-mr1" disabled={this.state.disableDeleteBtn} onClick={this.handleDeleteClick}>Delete</Button>
                                        </ButtonToolbar>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                <NoserLoading show={this.state.isloading} />
                <BillablePerTripModal 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
                {/* <BillableOtherPayrollModal 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                /> */}
            </div> 
        )
    }

}

export  default BillablePerTrip 