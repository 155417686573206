import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../../noser-hris-component';

import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import NoserGrid from './AttendanceSummaryTable'
class AttendanceSummaryReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            clientDDL       :   [],
            periodDDL       :   [],
            attendanceGrid  :   [],

            clientId        :   "",
            periodId        :   ""

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients();
    }

    GetClients = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  param)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL:data.clients,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    GetPayPeriodList(isSpecial) {
        this.setState({isloading:true,alerttype:'',isshow:false,color:'',message:'',fade:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId": "",
            "IsProcessed":"1"
         };
         let api = ""
         if(isSpecial==="0"){
            api = "Timekeeping/GetPayrollPeriods"
         }
         else{
            api = "Timekeeping/GetSpecialPayrollPeriods"
         }
         axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + api,  params
             )
             .then(res => {
                const data = res.data;
                this.setState({periodDDL: data.payrollPeriods,isloading:false })
             })
    }
    handleClientChange = (e) => {
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.clientId=""
            return
        } 
        this.state.clientId = e[0].id
        this.GetPayPeriodList(e[0].isSpecial)
    }
    handlePeriodChange = (e) => {
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.periodId=""
            return
        } 
        this.state.periodId = e[0].periodId
    }
    handleSearchClick = async()=>{
        this.setState({isloading:true,alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(this.state.clientId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client.",
                fade        :   true
            });
            return;
        }
        if(this.state.periodId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select payroll period.",
                fade        :   true
            });
            return;
        }
        const clientParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "PayPeriodId": this.state.periodId,
            "LocationId": ""
        }
        await axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "TimekeepingReport/GetAttendanceSummary", clientParams
            )
            .then(res => {
                const data = res.data;
                this.setState({attendanceGrid: data.attendanceSummaries, isloading:false})
                if(data.status=="0"){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }
    render() {
        return(
            <div>
                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header>REPORT >> TIMEKEEPING - ATTENDANCE SUMMARY REPORT</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CLIENT
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleClientChange}
                                            options={this.state.clientDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CUT OFF
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='payPeriod'
                                            id="basic-example"
                                            onChange={this.handlePeriodChange}
                                            options={this.state.periodDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar sm={12}>
                                    <Button variant="success" className="ml-auto" onClick = { this.handleSearchClick }>
                                        SEARCH
                                    </Button>
                                </ButtonToolbar>
                                <div>
                                    
                                    <NoserGrid data={this.state.attendanceGrid} 
                                        exportCSV={true}
                                    />
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div> 
            
        )
    }
}

export default AttendanceSummaryReport;