import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown, Image,FormControl} from 'react-bootstrap';

import  TrainingRegisterModal from '../Training/TrainingModal/TrainingRegisterModal'
import { Input } from 'reactstrap';
import { isDoWhileStatement } from '@babel/types';
import Pdf from "react-to-pdf";
import ReactToPrint from "react-to-print";
import paramountLogo from "../../icon/paramountLogo.jpg"
import paraFooter from "../../icon/paraFooter.jpg"
import ImgsViewer from 'react-images-viewer'

const ref = React.createRef();


class ComponentToPrint extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            regexp          : /^[0-9\b]+$/,

            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            isLoading           :"",

            id                  :"",
            client              :"",
            employeeName        :"",
            position            :"",
            employeeNo          :"",
            location            :"",
            date                :"",
            irReferenceNo       :"",
            paragraph           :"",
            addedparagraph1     :"",
            addedparagraph2     :"",
            quotedStatement     :"",
            preparedBy          :"",
            prepareByPosition   :"",
            notedBy             :"",
            receivedBy          :"",
            isDraft             :"",
            isDeleted           :"",
            createdby           :"",
            createddate         :"",
            modifiedby          :"",
            modifieddate        :"",
            isModified          :"",

            articleNo               :"",
            articleName             :"",
            sectionNo               :"",
            infraction              :"",
            category                :"",

            articleNo2              :"", 
            articleName2            :"",
            sectionNo2              :"",
            infraction2             :"",
            category2               :"",

            sectionNo3              :"",
            infraction3             :"",
            category3               :"",

            irDateIssued            :"",
            dateRecieveByER         :"",
            referenceNoNTE          :"",
            cocReferences           :"",
            eSignatureNotedBy       :"",    
            eSignaturePreparedBy    :"",       
            
            departmentCodeAutocomplete: "",
            irIdCheck               :   "",
            dateNow                 :   "2/31/2021",
            paragraph1              :   "",            
            logo                    :   "https://assets.bossjob.com/companies/13161/logo/3Mwts6BLTZy6I6WhqQm9B79bIhq8dILG7uqq7SHl.png",
            address                 :   "",  
            lastName                :   "", 
            notedByPosition         :   "",  
            
            articleDataList:[],
            sectionDataList:[],

            articleDataList2:[],
            sectionDataList2:[],

            articleDataList3:[],
            sectionDataList3:[],

            articleDataList4:[],
            sectionDataList4:[],

            articleDataList5:[],
            sectionDataList5:[],

        }
    }

    componentDidMount(){

        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        this.setState({isLoading:true,loadingText:"Loading client list..."})
        this.state.irIdCheck = JSON.parse(sessionStorage.getItem("irId"))
    
        this.getViewDraftMemo()
       
    }
    
    onChangePosition=(e)=>{
        this.setState({
            position:e.target.value
        })
    }

    getViewDraftMemo=()=>{
        let dataNTE = JSON.parse(sessionStorage.getItem("data"))
        let dataNTE1 = JSON.parse(sessionStorage.getItem("viewDraftMemoTmp"))

       /*  console.log("dataNTE")
        console.log(dataNTE.location)
        console.log("dataNTE") */

        let newDate = new Date(dataNTE.createddate)        
        let createddate = moment(newDate).format('MM');
        let createddate2 = moment(newDate).format('YYYY');
        let createddate3 = moment(newDate).format('DD');
        console.log(createddate)
        console.log(createddate2)

        let monthLeters = ""
        if(createddate == "01"){
            monthLeters = "January"
        }
        if(createddate == "02"){
            monthLeters = "February"
        }
        if(createddate == "03"){
            monthLeters = "March"
        }
        if(createddate == "04"){
            monthLeters = "April"
        }
        if(createddate == "05"){
            monthLeters = "May"
        }
        if(createddate == "06"){
            monthLeters = "June"
        }
        if(createddate == "07"){
            monthLeters = "July"
        }
        if(createddate == "08"){
            monthLeters = "August"
        }
        if(createddate == "09"){
            monthLeters = "September"
        }
        if(createddate == "10"){
            monthLeters = "October"
        }
        if(createddate == "11"){
            monthLeters = "November"
        }
        if(createddate == "12"){
            monthLeters = "December"
        }

        let wordsDate = createddate3+" "+monthLeters+" "+createddate2
        let getLastName1 = dataNTE.employeeName.split(",")[0]
        this.setState({
            paragraph           :   dataNTE.paragraph,
            addedparagraph1     :   dataNTE.addedparagraph1,
            quotedStatement     :   dataNTE.quotedStatement,
            employeeName        :   dataNTE.employeeName,
            location            :   dataNTE.location,
            dateNow             :   wordsDate,
            preparedBy          :   dataNTE.preparedBy ,
            prepareByPosition   :   dataNTE.prepareByPosition ,
            notedBy             :   dataNTE.notedBy ,
            addedparagraph2     :   dataNTE.addedparagraph2,
            addedparagraph3     :   dataNTE.addedparagraph3,
            addedparagraph4     :   dataNTE.addedparagraph4,
            addedparagraph5     :   dataNTE.addedparagraph5,
            addedparagraph6     :   dataNTE.addedparagraph6,
            articleNo           :   dataNTE.articleNo , 
            articleName         :   dataNTE.articleName,
            sectionNo           :   dataNTE.sectionNo,
            infraction          :   dataNTE.infraction,
            category            :   dataNTE.category,

            articleNo2           :   dataNTE.articleNo2 , 
            articleName2         :   dataNTE.articleName2,
            sectionNo2           :   dataNTE.sectionNo2,
            infraction2          :   dataNTE.infraction2,
            category2            :   dataNTE.category2,

            sectionNo3           :   dataNTE.sectionNo3,
            infraction3          :   dataNTE.infraction3,
            category3            :   dataNTE.category3,
            eSignatureNotedBy    :   dataNTE.eSignatureNotedBy,
            eSignaturePreparedBy :   dataNTE.eSignaturePreparedBy,
            address              :   dataNTE.address,
            notedByPosition      :   dataNTE.notedByPosition,
            lastName             :   getLastName1,

            articleDataList:dataNTE.articleDataList,
            sectionDataList:dataNTE.sectionDataList,

            articleDataList2:dataNTE.articleDataList2,
            sectionDataList2:dataNTE.sectionDataList2,

            articleDataList3:dataNTE.articleDataList3,
            sectionDataList3:dataNTE.sectionDataList3,

            articleDataList4:dataNTE.articleDataList4,
            sectionDataList4:dataNTE.sectionDataList4,

            articleDataList5:dataNTE.articleDataList5,
            sectionDataList5:dataNTE.sectionDataList5,

        })
      
    }
    render() {
        return( <>
            <Container className="mt-0" fluid>
              
                        <Form style={{backgroundColor:"#FFFFFF",}}>
                            <Col>
                                <Image src={paramountLogo} style={{width:"82%", height:"110%", marginLeft:"20px" , marginTop:"15px"}}/>
                            </Col>

                            <h1 as={Row} className="mt-3" style={{ width:"100%",height:"60px" ,marginTop:"-20px"}}>

                                <Col>
                                    <label style={{fontWeight : "bold" , fontSize: 24, marginTop:"15px", marginLeft:"25px"}}>    
                                        PRIVATE & CONFIDENTIAL
                                    </label>
                                
                                    <label style={{fontWeight : "bold" , fontSize: 24, marginLeft:"500px",/*  marginTop:"-100px", */  }}>
                                        {this.state.dateNow}
                                    </label>
                                </Col>
                                
                            </h1>

                            <Form.Group as={Col} sm="10" className="mt-3">          
                                <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 22,marginLeft:"25px"}}>
                                    {this.state.employeeName}                                       
                                </Form.Label>                                
                            </Form.Group>
                            
                            <Form.Group as={Row} controlId="formPlaintextEmail"> 
                                <Col sm="2" style={{marginLeft:"40px", marginTop:"-20px", }}>
                                    <Form.Label row style={{fontSize: 22, minWidth:'400px',maxWidth:'400px',}}>
                                        {this.state.address}
                                    </Form.Label>  
                                </Col >
                            </Form.Group>

                            {/* <Form.Group as={Row} sm="10" style={{ whiteSpace: "pre-wrap",}}>      
                                                             
                                <Form.Label style={{fontWeight : "bold" ,fontSize: 22,marginLeft:"330px",}}>
                                    SUBJECT
                                </Form.Label>
                                <Form.Label sm="2"style={{fontWeight : "bold" ,fontSize: 22,marginLeft:"15px",}}>:</Form.Label>
                                
                                <Form.Label style={{fontWeight : "bold" ,fontSize: 22, whiteSpace: "pre-wrap",}}>
                                    NOTICE TO EXPLAIN AND REPORT BACK TO YOUR IMMEDIATE SUPERRIOR                        
                                </Form.Label>
                               
                            </Form.Group> */}

                            <Form.Group as={Row} sm="10">                                    
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22, marginLeft:"230px"}}>
                                    SUBJECT
                                </Form.Label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 22, marginLeft:"6px"}}>:</Form.Label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Form.Label row sm="3" style={{width:"38%",fontWeight : "bold" ,fontSize: 22, marginLeft:"31px",whiteSpace: "pre-wrap",}}>
                                    NOTICE TO EXPLAIN AND REPORT BACK TO YOUR IMMEDIATE SUPERIOR                              
                                </Form.Label>                                
                            </Form.Group>                            

                            <Form.Group as={Col} sm="10" className="mt-3">          
                                <Form.Label className="no-text-transform-li" row sm="3" style={{fontWeight : "bold" ,fontSize: 22,marginLeft:"25px"}}>
                                {"Dear"}&nbsp;Mr/Ms.&nbsp;{this.state.lastName},
                                </Form.Label>                                
                            </Form.Group>

                          {/*   <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" >
                                <Col sm="5" style={{width:"95%", }}>
                                    <Form.Label row style={{fontSize: 22, marginLeft:"40px"}}>
                                        {this.state.paragraph}  
                                    </Form.Label>                                                          
                                </Col>                                    
                            </Form.Group> */}

                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px" , whiteSpace: "pre-wrap",}}>
                                        {this.state.paragraph}
                                    </Form.Label>  
                                </Col>                                    
                            </Form.Group>


                         {/*    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" style={{width:"85%"}}>
                                <Col sm="5" >
                                    <Form.Label row style={{fontSize: 22, fontWeight:"bold" ,  marginLeft:"85px" , marginRight:"20px"}}>
                                        {this.state.quotedStatement}
                                    </Form.Label> 
                                </Col>                                    
                            </Form.Group> */}

                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label className="no-text-transform-li" row style={{ width:"85%", fontSize: 22, marginLeft:"85px", marginRight:"40px",fontWeight:"bold"  , whiteSpace: "pre-wrap",}}>
                                        {'"'+this.state.quotedStatement+'"'}
                                    </Form.Label>  
                                </Col>                                    
                            </Form.Group>

                           {/*  <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" >
                                <Col sm="5">
                                    <Form.Label row style={{width:"95%",fontSize: 22, marginLeft:"40px"}}>
                                        {this.state.addedparagraph1}
                                    </Form.Label> 
                                                            
                                </Col>                                    
                            </Form.Group> */}
                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap", }}>
                                        {this.state.addedparagraph1}
                                    </Form.Label>  
                                </Col>                                    
                            </Form.Group>

                            {this.state.articleDataList.length > 0 ?
                                this.state.articleDataList.map(x => <Form.Group key={x.id }  className="mt-3">

                                <Col sm="8" style={{  justifyContent:"center" ,textAlign:"center", marginLeft:"-40px", marginTop:"-10px",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22,/*  whiteSpace: "pre-wrap", */}}>
                                        { x.articleNo} &nbsp;&nbsp;&nbsp; {x.articleName}
                                    </Form.Label> 
                                </Col>

                            </Form.Group>):null}
                            {this.state.sectionDataList.length > 0 ?
                            <Form.Group as={Row} controlId="formPlaintextEmail" /* className="mt-1" */ style={{marginLeft:"70px", marginTop:"-20px"}}>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",}}>
                                        SECTION
                                    </Form.Label>
                                </Col> 
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",marginLeft:"30px",}}>
                                        INFRACTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",marginLeft:"70px",}}>
                                        CATEGORY
                                    </Form.Label>             
                                </Col> 
                            </Form.Group>:null}

                            {this.state.sectionDataList.length > 0 ?
                                this.state.sectionDataList.map(x => <Form.Group as={Row} key={x.id}  className="mt-3">

                                <Col style={{ textAlign:"center",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap", marginLeft:"-140px",marginTop:"-20px",}}>
                                        {x.sectionNo}
                                    </Form.Label>
                                </Col> 
                                <Col style={{textAlign:"center", marginLeft:"-100px" }}>
                                    <Form.Label className="no-text-transform-li" row style={{fontSize: 22 , whiteSpace: "pre-wrap", minWidth:"550px", maxWidth:"550px",marginTop:"-20px",}}>
                                        {x.infraction}
                                    </Form.Label>
                                </Col>
                                <Col style={{marginLeft:"75px", marginRight:"-125px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap", marginTop:"-20px",}}>
                                        {x.category}
                                    </Form.Label>                   
                                </Col> 

                            </Form.Group>):null}

                            {/* *********************article 2************** */}
                            {this.state.articleDataList2.length > 0 ?
                                this.state.articleDataList2.map(x => <Form.Group key={x.id }  className="mt-3">

                                <Col sm="8" style={{  justifyContent:"center" ,textAlign:"center", marginLeft:"-40px", marginTop:"-10px",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22,/*  whiteSpace: "pre-wrap", */}}>
                                        { x.articleNo} &nbsp;&nbsp;&nbsp; {x.articleName}
                                    </Form.Label> 
                                </Col>

                            </Form.Group>):null}
                            {this.state.sectionDataList2.length > 0 ?
                            <Form.Group as={Row} controlId="formPlaintextEmail" /* className="mt-1" */ style={{marginLeft:"70px", marginTop:"-20px"}}>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",}}>
                                        SECTION
                                    </Form.Label>
                                </Col> 
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",marginLeft:"30px",}}>
                                        INFRACTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",marginLeft:"70px",}}>
                                        CATEGORY
                                    </Form.Label>             
                                </Col> 
                            </Form.Group>:null}

                            {this.state.sectionDataList2.length > 0 ?
                                this.state.sectionDataList2.map(x => <Form.Group as={Row} key={x.id}  className="mt-3">

                                <Col style={{ textAlign:"center",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap", marginLeft:"-140px",marginTop:"-20px",}}>
                                        {x.sectionNo}
                                    </Form.Label>
                                </Col> 
                                <Col style={{textAlign:"center", marginLeft:"-100px" }}>
                                    <Form.Label className="no-text-transform-li" row style={{fontSize: 22 , whiteSpace: "pre-wrap", minWidth:"550px", maxWidth:"550px",marginTop:"-20px",}}>
                                        {x.infraction}
                                    </Form.Label>
                                </Col>
                                <Col style={{marginLeft:"75px", marginRight:"-125px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap", marginTop:"-20px",}}>
                                        {x.category}
                                    </Form.Label>                   
                                </Col> 

                            </Form.Group>):null}

                             {/* *********************article 3************** */}
                             {this.state.articleDataList3.length > 0 ?
                                this.state.articleDataList3.map(x => <Form.Group key={x.id }  className="mt-3">

                                <Col sm="8" style={{  justifyContent:"center" ,textAlign:"center", marginLeft:"-40px", marginTop:"-10px",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22,/*  whiteSpace: "pre-wrap", */}}>
                                        { x.articleNo} &nbsp;&nbsp;&nbsp; {x.articleName}
                                    </Form.Label> 
                                </Col>

                            </Form.Group>):null}
                            {this.state.sectionDataList3.length > 0 ?
                            <Form.Group as={Row} controlId="formPlaintextEmail" /* className="mt-1" */ style={{marginLeft:"70px", marginTop:"-20px"}}>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",}}>
                                        SECTION
                                    </Form.Label>
                                </Col> 
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",marginLeft:"30px",}}>
                                        INFRACTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",marginLeft:"70px",}}>
                                        CATEGORY
                                    </Form.Label>             
                                </Col> 
                            </Form.Group>:null}

                            {this.state.sectionDataList3.length > 0 ?
                                this.state.sectionDataList3.map(x => <Form.Group as={Row} key={x.id}  className="mt-3">

                                <Col style={{ textAlign:"center",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap", marginLeft:"-140px",marginTop:"-20px",}}>
                                        {x.sectionNo}
                                    </Form.Label>
                                </Col> 
                                <Col style={{textAlign:"center", marginLeft:"-100px" }}>
                                    <Form.Label className="no-text-transform-li" row style={{fontSize: 22 , whiteSpace: "pre-wrap", minWidth:"550px", maxWidth:"550px",marginTop:"-20px",}}>
                                        {x.infraction}
                                    </Form.Label>
                                </Col>
                                <Col style={{marginLeft:"75px", marginRight:"-125px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap", marginTop:"-20px",}}>
                                        {x.category}
                                    </Form.Label>                   
                                </Col> 

                            </Form.Group>):null}

                             {/* *********************article 4************** */}
                             {this.state.articleDataList4.length > 0 ?
                                this.state.articleDataList4.map(x => <Form.Group key={x.id }  className="mt-3">

                                <Col sm="8" style={{  justifyContent:"center" ,textAlign:"center", marginLeft:"-40px", marginTop:"-10px",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22,/*  whiteSpace: "pre-wrap", */}}>
                                        { x.articleNo} &nbsp;&nbsp;&nbsp; {x.articleName}
                                    </Form.Label> 
                                </Col>

                            </Form.Group>):null}
                            {this.state.sectionDataList4.length > 0 ?
                            <Form.Group as={Row} controlId="formPlaintextEmail" /* className="mt-1" */ style={{marginLeft:"70px", marginTop:"-20px"}}>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",}}>
                                        SECTION
                                    </Form.Label>
                                </Col> 
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",marginLeft:"30px",}}>
                                        INFRACTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",marginLeft:"70px",}}>
                                        CATEGORY
                                    </Form.Label>             
                                </Col> 
                            </Form.Group>:null}

                            {this.state.sectionDataList4.length > 0 ?
                                this.state.sectionDataList4.map(x => <Form.Group as={Row} key={x.id}  className="mt-3">

                                <Col style={{ textAlign:"center",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap", marginLeft:"-140px",marginTop:"-20px",}}>
                                        {x.sectionNo}
                                    </Form.Label>
                                </Col> 
                                <Col style={{textAlign:"center", marginLeft:"-100px" }}>
                                    <Form.Label className="no-text-transform-li" row style={{fontSize: 22 , whiteSpace: "pre-wrap", minWidth:"550px", maxWidth:"550px",marginTop:"-20px",}}>
                                        {x.infraction}
                                    </Form.Label>
                                </Col>
                                <Col style={{marginLeft:"75px", marginRight:"-125px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap", marginTop:"-20px",}}>
                                        {x.category}
                                    </Form.Label>                   
                                </Col> 

                            </Form.Group>):null}

                             {/* *********************article 5************** */}
                             {this.state.articleDataList5.length > 0 ?
                                this.state.articleDataList5.map(x => <Form.Group key={x.id }  className="mt-3">

                                <Col sm="8" style={{  justifyContent:"center" ,textAlign:"center", marginLeft:"-40px", marginTop:"-10px",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22,/*  whiteSpace: "pre-wrap", */}}>
                                        { x.articleNo} &nbsp;&nbsp;&nbsp; {x.articleName}
                                    </Form.Label> 
                                </Col>

                            </Form.Group>):null}
                            {this.state.sectionDataList5.length > 0 ?
                            <Form.Group as={Row} controlId="formPlaintextEmail" /* className="mt-1" */ style={{marginLeft:"70px", marginTop:"-20px"}}>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",}}>
                                        SECTION
                                    </Form.Label>
                                </Col> 
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap",marginLeft:"30px",}}>
                                        INFRACTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap",marginLeft:"70px",}}>
                                        CATEGORY
                                    </Form.Label>             
                                </Col> 
                            </Form.Group>:null}

                            {this.state.sectionDataList5.length > 0 ?
                                this.state.sectionDataList5.map(x => <Form.Group as={Row} key={x.id}  className="mt-3">

                                <Col style={{ textAlign:"center",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22 , whiteSpace: "pre-wrap", marginLeft:"-140px",marginTop:"-20px",}}>
                                        {x.sectionNo}
                                    </Form.Label>
                                </Col> 
                                <Col style={{textAlign:"center", marginLeft:"-100px" }}>
                                    <Form.Label className="no-text-transform-li" row style={{fontSize: 22 , whiteSpace: "pre-wrap", minWidth:"550px", maxWidth:"550px",marginTop:"-20px",}}>
                                        {x.infraction}
                                    </Form.Label>
                                </Col>
                                <Col style={{marginLeft:"75px", marginRight:"-125px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 22, whiteSpace: "pre-wrap", marginTop:"-20px",}}>
                                        {x.category}
                                    </Form.Label>                   
                                </Col> 

                            </Form.Group>):null}

                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap", whiteSpace: "pre-wrap", }}>
                                        {this.state.addedparagraph2}
                                    </Form.Label>  
                                </Col>                                    
                            </Form.Group>

                            {this.state.addedparagraph3 !== "" ?
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap", whiteSpace: "pre-wrap", }}>
                                        {this.state.addedparagraph3}
                                    </Form.Label>  
                                </Col>                                    
                                </Form.Group>:null
                            }

                            {this.state.addedparagraph4 !== "" ?
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap", whiteSpace: "pre-wrap", }}>
                                        {this.state.addedparagraph4}
                                    </Form.Label>  
                                </Col>                                    
                                </Form.Group>:null
                            }
                            {this.state.addedparagraph5 !== "" ?
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap", whiteSpace: "pre-wrap", }}>
                                        {this.state.addedparagraph5}
                                    </Form.Label>  
                                </Col>                                    
                                </Form.Group>:null
                            }
                            {this.state.addedparagraph6 !== "" ?
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label className="no-text-transform-li" row style={{ width:"95%", fontSize: 22, marginLeft:"40px", whiteSpace: "pre-wrap", whiteSpace: "pre-wrap", }}>
                                        {this.state.addedparagraph6}
                                    </Form.Label>  
                                </Col>                                    
                                </Form.Group>:null
                            }

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column style={{fontWeight : "bold",fontSize: 22,marginLeft:"40px"}}>
                                    Prepared By :
                                </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 22, marginLeft:"20px",marginTop:"-15px"}}>
                                    <Col sm="5">                                       
                                       <Image src={this.state.eSignaturePreparedBy} style={{width:"240px", height:"70px", textAlign:"center",justifyContent:"center", }}/>
                                    </Col>
                                </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column style={{fontWeight : "bold",fontSize: 22, marginTop:"-20px",marginLeft:"40px"}}>
                                    {this.state.preparedBy}
                                </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 18, marginTop:"-19px", marginLeft:"40px"}}>
                                    {this.state.prepareByPosition}
                                </Form.Label>
                            </Form.Group>


                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="1" style={{fontWeight : "bold",fontSize: 22,marginLeft:"40px"}}>
                                    Noted By :
                                </Form.Label>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail"> </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 22, marginLeft:"20px",marginTop:"-25px"}}>
                                    <Col sm="5">                                       
                                       <Image src={this.state.eSignatureNotedBy} style={{width:"240px", height:"70px", textAlign:"center",justifyContent:"center", }}/>
                                    </Col>
                                </Form.Label>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 22,marginLeft:"40px" , marginTop:"-10px"}}>
                                    {this.state.notedBy}
                                </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 18, marginTop:"-19px", marginLeft:"40px"}}>
                                    {this.state.notedByPosition}
                                </Form.Label>
                            </Form.Group>
                           

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 22, marginLeft:"40px"}}>
                                    Received by :
                                </Form.Label>

                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 22, marginLeft:"40px"}}>
                                    <Col sm="10">
                                        <Form.Control 
                                            type="text"
                                            name="employeeName"
                                            value={this.state.employeeName}
                                            //onChange={this.onChangePosition} 
                                            autoComplete="off"
                                            //placeholder="Enter Name"
                                            style={{fontWeight:"bold",width:"500px",borderTop:"#ffffff", borderLeft:"#ffffff", borderRightColor:"#ffffff",borderRadius: "0",fontSize: 22,marginLeft:"-13px",borderBottomColor:"#000000"}}
                                        />
                                    </Col>
                                </Form.Label>
                            </Form.Group>
                            
                            
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 18,marginTop:"-20px" , marginLeft:"38px"}}>
                                    Sign over printed name / Date
                                </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label className="no-text-transform-li" column sm="2" style={{fontWeight : "bold",fontSize: 18,marginTop:"-20px" , marginLeft:"38px"}}>
                                   cc:  201 File
                                </Form.Label>
                            </Form.Group>

                            <Image src={paraFooter} style={{marginLeft:"300px", height:"150px", textAlign:"center",justifyContent:"center"}}/>
                        </Form>
                    
                
            </Container>
            <NoserLoading show={this.state.isloading} />

            <TrainingRegisterModal 
                show={this.state.modalTrainingRegisterShow}
                onHide={this.handleModalClose}
                onRefModal={ref => (this.child = ref)}
            />
           
            </>
            
        )
    }

}


class ExportAwol2ndNotice extends React.Component {
    render() {
      return (
        <div>
        
            <ComponentToPrint ref={(el) => (this.componentRef = el)} />
            <Col sm="3" className="mt-3" as={Row}>
                <ReactToPrint
                    trigger={() => <button>Print this out!</button>}
                    content={() => this.componentRef}
                />
                 <ButtonToolbar sm={12}>
                    <NavLink to="/AwolMontoring">
                        <Button variant="danger" href="/AwolMontoring" style={{minWidth:'60px',marginLeft:"10px"}}>
                            Close
                        </Button>
                    </NavLink>
                </ButtonToolbar>
            </Col>
        </div>
      );
    }
  }

export default ExportAwol2ndNotice
