import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { tsNullKeyword } from '@babel/types';

 
class SenaCasesMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            statusTableList                     :   [],
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalTrainingRegisterShow           :   false,
            selectedClientName                  :   [],            
            getClientList                       :   [],
            getEmployeeList                     :   [],
            employeeDetailList                  :   [],
            particularList: [
                {
                    "id" : "Closed",
                    "name" : "Closed"
                },
                {
                    "id" : "Pending",
                    "name" : "Pending"
                },

            ],
            selectedparticular      :   '',
            selectedparticularId    :   '',
            countStatus             :'Pending',
            pendingsList            :[],
            closedList              :[],
            fliterClose             :'Closed',
            statusPendingList       :[],
            statusClosedList        :[],
            totalStatusList         :[],
            totalDoleCasesList      :[], 
            totalClosedCount        :[],  

            client                  :"",  
            selectedEmployeeName    :"", 
            selectedEmployeeId      :"", 
            selectedEmployeeNo      :"", 
            selectedEmployeeBranch  :"", 
            selectedPosition        :"", 
            summaryDetailsList      :[],
            summaryDetailsList2      :[],
            summaryDetailsList3      :[],
            summaryDetailsList4      :[],

            retailgroupData             :[
                {
                    "id":"1",
                    "name":"RETAIL 1"
                },
                {
                    "id":"2",
                    "name":"RETAIL 2"
                },
                {
                    "id":"3",
                    "name":"RETAIL 3"
                },
                {
                    "id":"4",
                    "name":"RETAIL 4"
                },
                {
                    "id":"5",
                    "name":"NON-RETAIL 1"
                },
                {
                    "id":"6",
                    "name":"NON-RETAIL 2"
                },
                {
                    "id":"7",
                    "name":"INTERNAL"
                },

            ],
            senaStatus            :[
                { 
                    "id":"1",
                    "label":"PENDING",
                    "value":"PENDING",
                },
                { 
                    "id":"2",
                    "label":"SETTLED",
                    "value":"SETTLED",
                },
                 { 
                    "id":"3",
                    "label":"FOR NLRC",
                    "value":"FOR NLRC",
                },
            ],

            selectedretailGroup     :"", 
            selectedsenaStaus       :"", 
            dateApplied             :"", 
            selectedClientId        :"", 
           
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getSummaryList();
        this.getMonitoringList();
        this.getClientList();;
        /* this.getMonitoryByClient();; */

       
        
    }

    getSummaryList =()=>{

        let filter_data ={}

        const getParams ={
            "_collection" : "SenaCases",
            "filter_data" : filter_data
        }

        console.log("axios start")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data
            console.log("res.data")
            console.log(res.data)
          
            if(data.SenaCases.length > 0){

                var obj = (data);
                var res = [];
                let newsettled  =   ""
                let newPENDING  =   ""
                let newTOLNRC   =   ""
                let arrynewsettled  = []
                let arrynewPENDING  = []
                let arrynewTOLNRC   = []
                let newPENDING3  =   []

                let newPOSSIBLE  =   []
                let newPROBABLE  =   []
                let newREMOTE  =   []
                let newCLOSED  =   []

                let newIlligalDismissal  =   []
                let newConstructiveDismissal  =   []
                let newIndemnityPay  =   []
                let newreinstatement  =   []
                let newbackwage  =   []
                let newnonpaymentsalaryWage  =   []
                let newovertimePay  =   []
                let newholidayPay  =   []
                let newPremSpecialDayPay  =   []
                let newPremRestDayPay  =   []
                let newServiceIncentiveLeave  =   []
                let newthirteenmonthPay  =   []
                let newnonpaymentseperationPay  =   []
                let newServiceCharge  =   []
                let newnightShift  =   []
                let newminimunwage  =   []
                let newCOLA  =   []
                let newDelayedPayment  =   []
                let newFinalPay  =   []
                let newRetirementBenef  =   []
                let newpaymentOfDivident  =   []
                let newrefundOfmembershipfee  =   []
                let newrefundOfcapitalbuildup  =   []
                let newIlligalDeduc  =   []
                let newilligalSuspension  =   []
                let newRegularContractual  =   []
                let newmoralExemplaryDmg  =   []
                let newattysFee  =   []
                let newhazardPay  =   []
                let newmaternityLeave  =   []
                let newpaternityLeave  =   []
                let newparentalForSolo  =   []
                let newVawcVictims  =   []
                let newilligalStrikeLockout  =   []
                let newHarrassmentMaltreat  =   []
                let newincentives  =   []
                let newallowance  =   []
                let newgovernmentmanContri  =   []
                let newgovernmentmanLoan  =   []
                let newgovernmentmanBenefits  =   []
                let newCBAviolation  =   []
                let newunexpiredPortionOfContract  =   []
                let newnonIssuanceCOE  =   []
                let newSafetyHealthVio  =   []
                let newOtherPersonalAction  =   []
                let newOthers  =   []

                data.SenaCases.map(function(itm,i){
                    let strJson = "{" + itm.substring(46, itm.length)
                    let json = JSON.parse(strJson.replace(/'/g,'"'));
                   
                    res.push(json)
                   

                    if(itm.senaStatus === "PENDING"){
                        newPENDING3.push(itm)
                    }
                })
              

                res.map(function(d){
                    // let strJson = "{" + d.substring(46, d.length)
                    // let json = JSON.parse(strJson.replace(/'/g,'"'));
                    //res.push(json)

                    //.table summary 1
                    if(d.senaStatus === "PENDING"){
                        arrynewPENDING.push(d)
                    }
                    if(d.senaStatus === "SETTLED"){
                        arrynewsettled.push(d)
                    }
                    if(d.senaStatus === "FOR NLRC"){
                        arrynewTOLNRC.push(d)
                    }
                    
                    ///table summary 3
                    if(d.caseliability === "POSSIBLE"){
                        newPOSSIBLE.push(d)
                    }
                    if(d.caseliability === "PROBABLE"){
                        newPROBABLE.push(d)
                    }
                    if(d.caseliability === "REMOTE"){
                        newREMOTE.push(d)
                    }
                    if(d.caseliability === "CLOSED"){
                        newCLOSED.push(d)
                    }

                    //table summary 4
                    if(d.IlligalDismissal === "Yes"){
                        newIlligalDismissal.push(d)
                    }  
                    if(d.ConstructiveDismissal === "Yes"){
                        newConstructiveDismissal.push(d)
                    } 
                    if(d.IndemnityPay === "Yes"){
                        newIndemnityPay.push(d)
                    } 
                    if(d.reinstatement === "Yes"){
                        newreinstatement.push(d)
                    } 
                    if(d.backwage === "Yes"){
                        newbackwage.push(d)
                    } 
                    if(d.nonpaymentsalaryWage === "Yes"){
                        newnonpaymentsalaryWage.push(d)
                    } 
                    if(d.overtimePay === "Yes"){
                        newovertimePay.push(d)
                    } 
                    if(d.holidayPay === "Yes"){
                        newholidayPay.push(d)
                    } 
                    if(d.PremSpecialDayPay === "Yes"){
                        newPremSpecialDayPay.push(d)
                    } 
                    if(d.PremRestDayPay === "Yes"){
                        newPremRestDayPay.push(d)
                    } 
                    if(d.ServiceIncentiveLeave === "Yes"){
                        newServiceIncentiveLeave.push(d)
                    } 
                    if(d.thirteenmonthPay === "Yes"){
                        newthirteenmonthPay.push(d)
                    } 
                    if(d.nonpaymentseperationPay === "Yes"){
                        newnonpaymentseperationPay.push(d)
                    } 
                    if(d.ServiceCharge === "Yes"){
                        newServiceCharge.push(d)
                    } 
                    if(d.nightShift === "Yes"){
                        newnightShift.push(d)
                    } 
                    if(d.minimunwage === "Yes"){
                        newminimunwage.push(d)
                    } 
                    if(d.COLA === "Yes"){
                        newCOLA.push(d)
                    } 
                    if(d.DelayedPayment === "Yes"){
                        newDelayedPayment.push(d)
                    } 
                    if(d.FinalPay === "Yes"){
                        newFinalPay.push(d)
                    }
                    if(d.RetirementBenef === "Yes"){
                        newRetirementBenef.push(d)
                    } 
                    if(d.paymentOfDivident === "Yes"){
                        newpaymentOfDivident.push(d)
                    }
                    if(d.refundOfmembershipfee === "Yes"){
                        newrefundOfmembershipfee.push(d)
                    } 
                    if(d.refundOfcapitalbuildup === "Yes"){
                        newrefundOfcapitalbuildup.push(d)
                    } 
                    if(d.IlligalDeduc === "Yes"){
                        newIlligalDeduc.push(d)
                    } 
                    if(d.illigalSuspension === "Yes"){
                        newilligalSuspension.push(d)
                    } 
                    if(d.RegularContractual === "Yes"){
                        newRegularContractual.push(d)
                    } 
                    if(d.moralExemplaryDmg === "Yes"){
                        newmoralExemplaryDmg.push(d)
                    } 
                    if(d.attysFee === "Yes"){
                        newattysFee.push(d)
                    } 
                    if(d.hazardPay === "Yes"){
                        newhazardPay.push(d)
                    } 
                    if(d.maternityLeave === "Yes"){
                        newmaternityLeave.push(d)
                    } 
                    if(d.paternityLeave === "Yes"){
                        newpaternityLeave.push(d)
                    } 
                    if(d.parentalForSolo === "Yes"){
                        newparentalForSolo.push(d)
                        console.log(d)
                    } 
                    if(d.VawcVictims === "Yes"){
                        newVawcVictims.push(d)
                    } 
                    if(d.illigalStrikeLockout === "Yes"){
                        newilligalStrikeLockout.push(d)
                    } 
                    if(d.HarrassmentMaltreat === "Yes"){
                        newHarrassmentMaltreat.push(d)
                    } 
                    if(d.incentives === "Yes"){
                        newincentives.push(d)
                    } 
                    if(d.allowance === "Yes"){
                        newallowance.push(d)
                    } 
                    if(d.governmentmanContri === "Yes"){
                        newgovernmentmanContri.push(d)
                    } 
                    if(d.governmentmanLoan === "Yes"){
                        newgovernmentmanLoan.push(d)
                    } 
                    if(d.governmentmanBenefits === "Yes"){
                        newgovernmentmanBenefits.push(d)
                    } 
                    if(d.CBAviolation === "Yes"){
                        newCBAviolation.push(d)
                    } 
                    if(d.unexpiredPortionOfContract === "Yes"){
                        newunexpiredPortionOfContract.push(d)
                    } 
                    if(d.nonIssuanceCOE === "Yes"){
                        newnonIssuanceCOE.push(d)
                    } 
                    if(d.SafetyHealthVio === "Yes"){
                        newSafetyHealthVio.push(d)
                    } 
                    if(d.OtherPersonalAction === "Yes"){
                        newOtherPersonalAction.push(d)
                    } 
                    if(d.others === "Yes"){
                        newOthers.push(d)
                    }                    
                    console.log("res.map 1 1 1")
                    console.log(d)
                })

                console.log("res asd asdas das d")
                console.log(res)

                newsettled = res.find(x => x.senaStatus == "SETTLED" )
                newPENDING = res.find(x => x.senaStatus == "PENDING" )
                newTOLNRC = res.find(x => x.senaStatus == "FOR NLRC" )

                

               /*  if(newsettled !== undefined || newsettled != undefined){
                    arrynewsettled.push(newsettled)
                }
                if(newPENDING !== undefined || newPENDING != undefined){
                    arrynewPENDING.push(newPENDING)
                }
                if(newTOLNRC !== undefined || newTOLNRC != undefined){
                    arrynewTOLNRC.push(newTOLNRC)
                } */

                let newSummary = data.SenaCases.length
                
                let arry1 = []
                let cases = {
                    "summary": String(newSummary),
                    "pending": String(arrynewPENDING.length),
                    "settled": String(arrynewsettled.length),
                    "elevateToNLRC": String(arrynewTOLNRC.length),
                }
                arry1.push(cases)
                //console.log(cases)

               
                let newRes = res[0].employeeDetails
                console.log("res data.SenaCases")
                console.log(newRes)
                let arrynewRetail1 = []
                let arrynewRetail2 = []
                let arrynewRetail3 = []
                let arrynewRetail4 = []
                let arrynewnonRetail1 = []
                let arrynewnonRetail2 = []
                let arrynewInternal = []

                //table summary 2
                newRes.map(function(n){
                   
                    if(n.retailGroup === "RETAIL 1"){
                        arrynewRetail1.push(n)
                        console.log(n)
                    }
                    if(n.retailGroup === "RETAIL 2"){
                        arrynewRetail1.push(n)
                        console.log(n)
                    }
                    if(n.retailGroup === "RETAIL 3"){
                        arrynewRetail1.push(n)
                        console.log(n)
                    }
                    if(n.retailGroup === "RETAIL 4"){
                        arrynewRetail1.push(n)
                        console.log(n)
                    }
                    if(n.retailGroup === "NON-RETAIL 1"){
                        arrynewRetail1.push(n)
                        console.log(n)
                    }
                    if(n.retailGroup === "NON-RETAIL 1"){
                        arrynewRetail1.push(n)
                        console.log(n)
                    }
                    if(n.retailGroup === "INTERNAL"){
                        arrynewRetail1.push(n)
                        console.log(n)
                    }
                })
                
                let retailGroupTmp = [{
                    "retail1":String(arrynewRetail1.length),
                    "retail2":String(arrynewRetail2.length),
                    "retail3":String(arrynewRetail3.length),
                    "retail4":String(arrynewRetail4.length),
                    "internal":String(arrynewInternal.length),
                    "nonRetail1":String(arrynewnonRetail1.length),
                    "nonRetail2":String(arrynewnonRetail2.length),
                }]
                console.log("retailGroupTmp")
                console.log(retailGroupTmp)

                let caseliabilityTMP = [
                    {
                        "POSSIBLE":String(newPOSSIBLE.length),
                        "PROBABLE":String(newPROBABLE.length),
                        "REMOTE":String(newREMOTE.length),
                        "CLOSED":String(newCLOSED.length),
                    }
                ]

                //TABLE SUMMARY 4
                let tablesummary4TMP = [
                    {
                        "IlligalDismissal":String(newIlligalDismissal.length),
                        "ConstructiveDismissal":String(newConstructiveDismissal.length),
                        "IndemnityPay":String(newIndemnityPay.length),
                        "reinstatement":String(newreinstatement.length),
                        "backwage":String(newbackwage.length),
                        "nonpaymentsalaryWage":String(newnonpaymentsalaryWage.length),
                        "overtimePay":String(newovertimePay.length),
                        "holidayPay":String(newholidayPay.length),
                        "PremSpecialDayPay":String(newPremSpecialDayPay.length),
                        "PremRestDayPay":String(newPremRestDayPay.length),
                        "ServiceIncentiveLeave":String(newServiceIncentiveLeave.length),
                        "thirteenmonthPay":String(newthirteenmonthPay.length),
                        "nonpaymentseperationPay":String(newnonpaymentseperationPay.length),
                        "ServiceCharge":String(newServiceCharge.length),
                        "nightShift":String(newnightShift.length),
                        "minimunwage":String(newminimunwage.length),
                        "COLA":String(newCOLA.length),
                        "DelayedPayment":String(newDelayedPayment.length),
                        "RetirementBenef":String(newRetirementBenef.length),
                        "paymentOfDivident":String(newpaymentOfDivident.length),
                        "refundOfmembershipfee":String(newrefundOfmembershipfee.length),
                        "refundOfcapitalbuildup":String(newrefundOfcapitalbuildup.length),
                        "IlligalDeduc":String(newIlligalDeduc.length),
                        "illigalSuspension":String(newilligalSuspension.length),
                        "RegularContractual":String(newRegularContractual.length),
                        "moralExemplaryDmg":String(newmoralExemplaryDmg.length),
                        "attysFee":String(newattysFee.length),
                        "hazardPay":String(newhazardPay.length),
                        "maternityLeave":String(newmaternityLeave.length),
                        "paternityLeave":String(newpaternityLeave.length),
                        "VawcVictims":String(newVawcVictims.length),
                        "illigalStrikeLockout":String(newilligalStrikeLockout.length),
                        "HarrassmentMaltreat":String(newHarrassmentMaltreat.length),
                        "incentives":String(newincentives.length),
                        "allowance":String(newallowance.length),
                        "governmentmanContri":String(newgovernmentmanContri.length),
                        "governmentmanLoan":String(newgovernmentmanLoan.length),
                        "governmentmanBenefits":String(newgovernmentmanBenefits.length),
                        "CBAviolation":String(newCBAviolation.length),
                        "unexpiredPortionOfContract":String(newunexpiredPortionOfContract.length),
                        "nonIssuanceCOE":String(newnonIssuanceCOE.length),
                        "SafetyHealthVio":String(newSafetyHealthVio.length),
                        "FinalPay":String(newFinalPay.length),
                        "OtherPersonalAction":String(newOtherPersonalAction.length),
                        "others":String(newOthers.length),
                        "parentalForSolo":String(newparentalForSolo.length),
                       
                    }
                ]


                this.setState({
                    summaryDetailsList: arry1,
                    summaryDetailsList2: retailGroupTmp,
                    summaryDetailsList3: caseliabilityTMP,
                    summaryDetailsList4: tablesummary4TMP,
                    
                })
                
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    } 

    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            //console.log("Client List start ");
            //console.log(res.data);
            //console.log("Client List end");
            const data = res.data
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }

    onChangeClientList = (e) => {
        let clear = []
       // this.state.employeeDetailList = clear
        if(e.length == 0) {
            this.state.client=""
            //this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.client = e[0].name
        this.setState({
            //isloading:true,
            //isshow:true,
            color:"",
            message:"",
            fade:true ,
            client: e[0].name
        })
       
        //this.getMonitoryByClient();;
        //this.getEmployees();;
       
        
    }

    getEmployees(){

        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            console.log("Employee List start ");
            console.log(res.data);
            console.log("Employee List end");
            const data = res.data
            if(data.status=="1")
                this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
            else
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })

        
    }

    onChangeEmployeesList = (e) => {
       

        if(e.length==0)
        {
            this.setState({getEmployeeListData: null, selectedPayrollpayPeriod: '', selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
          
            return
        }
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedEmployeeName = e[0].employeeName
        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeNo = e[0].employeeNo
        this.state.selectedEmployeeBranch = e[0].locationName
        this.state.selectedPosition = e[0].position
       
        // console.log("Work selectedEmployeeName   " + this.state.selectedEmployeeNo );
        // console.log("Employee List start ");
        // console.log(this.state.selectedEmployeeNo);
        // console.log("Employee List end");

        // this.setState({
        //     // position        :this.state.selectedPosition,
        //     // location        :this.state.selectedEmployeeBranch,
        //     // saveEmployeeNo  : e[0].employeeNo

        // })
        this.setState({isloading:false})
    }

    onChangeretailGroup = (e) => {
       

        if(e.length==0)
        {
            this.state.selectedretailGroup = ""
          
            return
        }
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedretailGroup = e[0].name
       
      
        this.setState({isloading:false})
    }

    onChangesenaStaus = (e) => {
       

        if(e.length==0)
        {
            this.state.selectedsenaStaus = ""
          
            return
        }
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedsenaStaus = e[0].name
       
      
        this.setState({isloading:false})
    }

    handleChangeDate = date => {
        
        this.setState({
            dateApplied: date,
            isshow:false,
        })

    }

   /*  showTrainingRegisterModal = (e) => {
        this.setState({modalTrainingRegisterShow: true})
    } */
    handleModalClose = (e) =>{
        //reload or refresh data here
        //alert("Data pass from child : " + e)
        this.setState({modalTrainingRegisterShow: false})
    }

    GridDataModified(oldValue, newValue, id, column) {
        //////console.log(id)
        this.state.employeeDetailList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }

    getMonitoringList =()=>{

        this.setState({ isloading:true })

        let filter_data ={}

        const getParams ={
            "_collection" : "SenaCases",
            "filter_data" : filter_data
        }

        console.log("axios start")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data
            console.log("res.data")
            console.log(res.data)
            if(data.SenaCases.length > 0){ 

                var obj = (data);
                var res = [];
                let employesstmp = []
               
                data.SenaCases.map(function(itm,i){
                    let employeenamejoin = ""
                    let clientjoin = ""
                    let locationjoin = ""
                    let positionjoin = ""
                    let retailGroupjoin = ""
                    let statusOfMemberjoin = ""
                    let strJson = "{" + itm.substring(46, itm.length)
                    let strJson1 = itm.substring(1, 44)
                    let strJson2 = strJson1.replace("ObjectId(" , "").replace(")" , "").replace("'_id':" , "").replace(" " , "").replace("'" , "").replace("'" , "")
                    
                   
                    let json = JSON.parse(strJson.replace(/'/g,'"'));

                    for (let i = 0; i < json.employeeDetails.length; i++) {
                        console.log("loop d222222   ")
                        let obj={
                            "employeeName" : json.employeeDetails[i]["employeeName"],
                            "ClientName" : json.employeeDetails[i]["ClientName"],
                            "locationName" : json.employeeDetails[i]["locationName"],
                            "position" : json.employeeDetails[i]["position"],
                            "retailGroup" : json.employeeDetails[i]["retailGroup"],
                            "statusOfMember" : json.employeeDetails[i]["statusOfMember"],
                        }

                        if(employeenamejoin !==""){
                            console.log("if d222222   ")
                            employeenamejoin = employeenamejoin + json.employeeDetails[i]["employeeName"]+";"+ " "
                            clientjoin = clientjoin + json.employeeDetails[i]["ClientName"]+";"+ " "
                            locationjoin = locationjoin + json.employeeDetails[i]["locationName"]+";"+ " "
                            positionjoin = positionjoin + json.employeeDetails[i]["position"]+";"+ " "
                            retailGroupjoin = retailGroupjoin + json.employeeDetails[i]["retailGroup"]+";"+ " "
                            statusOfMemberjoin = statusOfMemberjoin + json.employeeDetails[i]["statusOfMember"]+";"+ " "
                        }else{
                            console.log("else d222222   ")
                            employeenamejoin = json.employeeDetails[i]["employeeName"]+";"+ " "
                            clientjoin = json.employeeDetails[i]["ClientName"]+";" + " "
                            locationjoin = json.employeeDetails[i]["locationName"]+";" + " "
                            positionjoin = json.employeeDetails[i]["position"]+";"+ " "
                            retailGroupjoin = json.employeeDetails[i]["retailGroup"]+";"+ " "
                            statusOfMemberjoin = json.employeeDetails[i]["statusOfMember"]+";"+ " "
                        }

                       /*  if(employeenamejoin !==""){
                            console.log("if d222222   ")
                            employeenamejoin = employeenamejoin + json.employeeDetails[i]["employeeName"]+";"
                            clientjoin = clientjoin + json.employeeDetails[i]["ClientName"]+";"
                            locationjoin = locationjoin + json.employeeDetails[i]["locationName"]+";"
                            positionjoin = positionjoin + json.employeeDetails[i]["position"]+";"
                        }else{
                            console.log("else d222222   ")
                            employeenamejoin = json.employeeDetails[i]["employeeName"]+";"
                            clientjoin = json.employeeDetails[i]["ClientName"]+";"
                            locationjoin = json.employeeDetails[i]["locationName"]+";"`
                            positionjoin = json.employeeDetails[i]["position"]+";"
                        } */
                        
                    }

                    res.push(json)

                    res.map(function(d){
                        d.id = strJson2
                        d.employeeName = employeenamejoin
                        d.ClientName = clientjoin
                        d.locationName = locationjoin
                        d.position = positionjoin
                        d.retailGroup = retailGroupjoin
                        d.statusOfMember = statusOfMemberjoin
                        d.action = "EDIT"
                    })

                })
                let clear = []
             
                this.state.employeeDetailList = clear
                this.reloadGrid(res)
                
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    } 

    reloadGrid=(res)=>{

        this.setState({
            employeeDetailList: res,
            isloading:false
        })
        console.log("employeeDetailList")
        console.log(this.state.employeeDetailList)
    }

    handleChangeSearch = () => {
       

        let _fdata = this.buildFilterData()
        let _filterData = _fdata.split(",")
        let _objfd = {}

        let jsonData = {};
        for (let i = 0; i < _filterData.length - 1; i++) { 
            let columnName = _filterData[i].split(":")[0]
            _objfd[columnName] = _filterData[i].split(":")[1];
        }
        console.log("filter_data")
        console.log(_objfd)

        this.handleClickSearch(_objfd);
    }

    buildFilterData(){
        let finalfilterData = ""
        let arry1 = []
        let NTEDate             = moment(this.state.dateApplied).format('MM/DD/YYYY');
        let NTEDate2            = ""
        let selectedsenaStaus   = ""
        let selectedretailGroup = ""
        let selectedClientId    = ""

        if(this.state.dateApplied !== ""){
            NTEDate2 = "dateOfSenaReceived" + ":" +   NTEDate   + ","/* + " " */
        }
        if(this.state.selectedsenaStaus !== ""){
            selectedsenaStaus = "senaStatus"+ ":" +this.state.selectedsenaStaus+ ","/* + " " */
        }
        if(this.state.selectedretailGroup !== ""){
            selectedretailGroup = "retailGroup" + ":" + this.state.selectedretailGroup+ ","/* + " " */
        }
        if(this.state.selectedClientId !== ""){
            selectedClientId = "clientId" + ":" + this.state.selectedClientId+ ","/* + " " */
        }

        finalfilterData =  NTEDate2 + selectedsenaStaus + selectedretailGroup +  selectedClientId
        return finalfilterData
    
    }

    handleClickSearch =(_obj)=>{
        
        
        this.setState({ isloading:true })

        let filter_data ={}

        const getParams ={
            "_collection" : "SenaCases",
            "filter_data" : _obj
        }

        console.log("axios start")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data
            console.log("res.data")
            console.log(res.data)
            if(data.SenaCases.length > 0){ 
                
                var obj = (data);
                var res = [];
                
                data.SenaCases.map(function(itm,i){
                    let strJson = "{" + itm.substring(46, itm.length)
                    let json = JSON.parse(strJson.replace(/'/g,'"'));
                    res.push(json)
                })

                this.setState({
                    employeeDetailList: res,
                    isloading:false
                })
                
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    } 

    buildListDemandLetter(data) {
        // console.log("data start")
        // console.log(data )
        console.log("data start")
        console.log(data )
        console.log("data" )
        let itemList =[]
    
        let idList                      =[]
        let clientList                  =[]
        let ClientIdList                =[]
        let employeeNameList            =[]
        let employeeNoList              =[]
        let positionList                =[]
        let locationList                =[]
        let addressList                 =[]
        let amountOfNegativeClaimsList  =[]        
        let dateList                    =[]
        let demandLetterReferenceNoList =[]
        let paragraphList               =[]
        let addedparagraph1List         =[]
        let addedparagraph2List         =[]
        let addedparagraph3List         =[]
        let addedparagraph4List         =[]
        let addedparagraph5List         =[]
        let addedparagraph6List         =[]
        let preparedByList              =[]
        let prepareByPositionList       =[]
        let statusList                  =[]
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
        
        for (let i = 0; i < data.length; i++) {            
    
            let s2 = data[i].split("), ")
            let s1 = s2[1].split("', ")
            
          
            console.log("s2")   
            console.log(s2)

            console.log("s1")
            console.log(s1)
            let IdClean0                    = s2[0].split("': ")[1]  
           
            let idClean1                    = IdClean0.replace("ObjectId(","").replace(")","").replace("{","")/* .replace(", 'client","")  */   
            console.log("idClean1")   
            console.log(idClean1)    
            let clientTmp                   = s1[0].split("': ")[1]  
            let ClientIdTmp                 = s1[1].split("': ")[1]
            let employeeNameTmp             = s1[2].split("': ")[1]
            let employeeNoTmp               = s1[3].split("': ")[1]
            let positionTmp                 = s1[4].split("': ")[1]            
            let locationTmp                 = s1[5].split("': ")[1]  
            let addressTmp                  = s1[6].split("': ")[1] 
            let amountOfNegativeClaimsTmp   = s1[7].split("': ")[1]
            let dateTmp                     = s1[8].split("': ")[1]
            let demandLetterReferenceNoTmp  = s1[9].split("': ")[1]
            let paragraphTmp                = s1[10].split("': ")[1]
            let addedparagraph1Tmp          = s1[11].split("': ")[1]
            let addedparagraph2Tmp          = s1[12].split("': ")[1]
            let addedparagraph3Tmp          = s1[13].split("': ")[1]
            let addedparagraph4Tmp          = s1[14].split("': ")[1]
            let addedparagraph5Tmp          = s1[15].split("': ")[1]
            let addedparagraph6Tmp          = s1[16].split("': ")[1]
            let preparedByTmp               = s1[17].split("': ")[1]
            let prepareByPositionTmp        = s1[18].split("': ")[1]
            let statusTmp                   = s1[19].split("': ")[1]

            let isDraftTmp                  = s1[20].split("': ")[1]
            let isDeletedTmp                = s1[21].split("': ")[1]
            let createdbyTmp                = s1[22].split("': ")[1]          
            let createddateTmp              = s1[23].split("': ")[1]
            let modifiedbyListTmp           = s1[24].split("': ")[1]
            let modifieddateListTmp         = s1[25].split("': ")[1]
            let isModifiedListTmp           = s1[26].split("': ")[1]    

            idList.push(idClean1.replace("'","").replace("'","").replace(" ",""))
            addressList.push(addressTmp.replace("'","").replace("'",""))
            clientList.push(clientTmp.replace("'",""))
            ClientIdList.push(ClientIdTmp.replace("'",""))
            createdbyList.push(createdbyTmp.replace("'",""))
            createddateList.push(createddateTmp.replace("'",""))
            amountOfNegativeClaimsList.push(amountOfNegativeClaimsTmp.replace("'",""))
            dateList.push(dateTmp.replace("'",""))
            paragraphList.push(paragraphTmp.replace("'",""))
            addedparagraph1List.push(addedparagraph1Tmp.replace("'",""))
            addedparagraph2List.push(addedparagraph2Tmp.replace("'",""))
            addedparagraph3List.push(addedparagraph3Tmp.replace("'",""))
            addedparagraph4List.push(addedparagraph4Tmp.replace("'",""))
            addedparagraph5List.push(addedparagraph5Tmp.replace("'",""))
            addedparagraph6List.push(addedparagraph6Tmp.replace("'",""))
            employeeNameList.push(employeeNameTmp.replace("'",""))
            employeeNoList.push(employeeNoTmp.replace("'",""))
            isDeletedList.push(isDeletedTmp.replace("'",""))
            isDraftList.push(isDraftTmp.replace("'",""))            
            locationList.push(locationTmp.replace("'",""))
            positionList.push(positionTmp.replace("'",""))
            statusList.push(statusTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            preparedByList.push(preparedByTmp.replace("'",""))
            prepareByPositionList.push(prepareByPositionTmp.replace("'",""))
            demandLetterReferenceNoList.push(demandLetterReferenceNoTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'}",""))
            
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],                
                'client'                :   clientList[i],   
                'clientId'              :   ClientIdList[i],  
                'employeeName'          :   employeeNameList[i],
                'employeeNo'            :   employeeNoList[i],
                'location'              :   locationList[i],
                'position'              :   positionList[i],   
                'address'              :   addressList[i],      
                'amountOfNegativeClaims':   amountOfNegativeClaimsList[i],
                'date'                  :   dateList[i],
                'paragraph'             :   paragraphList[i],
                'addedparagraph1'       :   addedparagraph1List[i],
                'addedparagraph2'       :   addedparagraph2List[i],
                'addedparagraph3'       :   addedparagraph3List[i], 
                'addedparagraph4'       :   addedparagraph4List[i], 
                'addedparagraph5'       :   addedparagraph5List[i],
                'addedparagraph6'       :   addedparagraph6List[i],  
                'preparedBy'            :   preparedByList[i],
                'prepareByPosition'     :   prepareByPositionList[i],
                'demandLetterReferenceNo':   demandLetterReferenceNoList[i],               
                'status'                :   statusList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],

            }
            itemList.push(obj)
            console.log("itemList start")
            console.log(itemList )
            console.log("itemList" )
            
        }
        return itemList
    }


    buildListTestMonitoringStatus(data) {
        ////console.log("data start")
        let itemList =[]
    
        let idList                      =[]
        let statusList                  =[]
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
        
    
        for (let i = 0; i < data.length; i++) {           
    
            let s1 = data[i].split("':")
            
            let idClean                     = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let statusTmp                   = s1[2].split("',")[0]
            let isDraftListTmp              = s1[3].split("',")[0]
            let isDeletedListTmp            = s1[4].split("',")[0]
            let createdbyListTmp            = s1[5].split("',")[0]
            let createddateListTmp          = s1[6].split("',")[0]
            let modifiedbyListTmp           = s1[7].split("',")[0]
            let modifieddateListTmp         = s1[8].split("',")[0]
            let isModifiedListTmp           = s1[9].split("',")[0]

        
            idList.push(IdClean2.replace("'",""))
            statusList.push(statusTmp.replace("'",""))
            
            isDraftList.push(isDraftListTmp.replace("'",""))
            isDeletedList.push(isDeletedListTmp.replace("'",""))
            createdbyList.push(createdbyListTmp.replace("'",""))
            createddateList.push(createddateListTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'status'                :   statusList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }
               
        return itemList
    }

        
    buildList(data) {
        ////console.log("data start")
        let itemList =[]
    
        let idList                          =[]
        let selectedClientNameList          =[]
        let selectedEmployeeNameList        =[]
        let selectedPositionList            =[]
        let selectedEmployeeNoList          =[]
        let selectedEmployeeBranchList      =[]
        let groundOfSeperationList          =[]
        let caseNumeberList                 =[]
        let dateFiledList                   =[]
        let dateReceivedList                =[]
        let complaintList                   =[]
        let scheduleOfHearingList           =[]
        let venueList                       =[]
        let hearingOfficerList              =[]
        let caseNoNLRCList                  =[]
        let mandatoryDateFiledList          =[]
        let mandatoryDateReceivedList       =[]
        let mandatoryComplaintList          =[]
        let mandatoryScheduleOfHearingList  =[]
        let mandatoryVenueList              =[]
        let mandatoryLaborArbiterList       =[]
        let particularsMomStConfMeetingList =[]
        let particularsMomNdConfMeetingList =[]
        let particularsQuitclaimList        =[]
        let particularsMonetaryClaimList    =[]
        let positionPaperList               =[]
        let replyList                       =[]
        let rejoinderList                   =[]
        let decisionList                    =[]
        let counselList                     =[]
        let latestUpdateList                =[]
        let statusDateList                  =[]
        let remarksList                     =[]
        let updateStatusList                =[]
        let attachedDocList                 =[]
        let reportedByEmployeeList          =[]
        let reportedByPositionList          =[]
        let reportedByClientList            =[]

        let isDraftList                     =[]
        let isDeletedList                   =[]
        let createdbyList                   =[]
        let createddateList                 =[]
        let modifiedbyList                  =[]
        let modifieddateList                =[]
        let isModifiedList                  =[]
        
    
        for (let i = 0; i < data.length; i++) {
            
    
            let s1 = data[i].split("':")
            
            let idClean                         = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                        = idClean.split("',")[0]
            let selectedClientNameTmp           = s1[2].split("',")[0]
            let selectedEmployeeNameTmp         = s1[3].split("',")[0]
            let selectedPositionTmp             = s1[4].split("',")[0]
            let selectedEmployeeNoTmp           = s1[5].split("',")[0]
            let selectedEmployeeBranchTmp       = s1[6].split("',")[0]
            let groundOfSeperationTmp           = s1[7].split("',")[0]
            let caseNumeberTmp                  = s1[8].split("',")[0]
            let dateFiledTmp                    = s1[9].split("',")[0]
            let dateReceivedTmp                 = s1[10].split("',")[0]
            let complaintTmp                    = s1[11].split("',")[0]
            let scheduleOfHearingTmp            = s1[12].split("',")[0]
            let venueTmp                        = s1[13].split("',")[0]
            let hearingOfficerTmp               = s1[14].split("',")[0]
            let caseNoNLRCTmp                   = s1[15].split("',")[0]
            let mandatoryDateFiledTmp           = s1[16].split("',")[0]
            let mandatoryDateReceivedTmp        = s1[17].split("',")[0]
            let mandatoryComplaintTmp           = s1[18].split("',")[0]
            let mandatoryScheduleOfHearingTmp   = s1[19].split("',")[0]
            let mandatoryVenueTmp               = s1[20].split("',")[0]
            let mandatoryLaborArbiterTmp        = s1[21].split("',")[0]
            let particularsMomStConfMeetingTmp  = s1[22].split("',")[0]
            let particularsMomNdConfMeetingTmp  = s1[23].split("',")[0]
            let particularsQuitclaimTmp         = s1[24].split("',")[0]
            let particularsMonetaryClaimTmp     = s1[25].split("',")[0]
            let positionPaperTmp                = s1[26].split("',")[0]
            let replyTmp                        = s1[27].split("',")[0]
            let rejoinderTmp                    = s1[28].split("',")[0]
            let decisionTmp                     = s1[29].split("',")[0]
            let counselTmp                      = s1[30].split("',")[0]
            let latestUpdateTmp                 = s1[31].split("',")[0]
            let statusDateTmp                   = s1[32].split("',")[0]
            let remarksTmp                      = s1[33].split("',")[0]
            let updateStatusTmp                 = s1[34].split("',")[0]
            let attachedDocTmp                  = s1[35].split("',")[0]
            let reportedByEmployeeTmp           = s1[36].split("',")[0]
            let reportedByPositionTmp           = s1[37].split("',")[0]
            let reportedByClientTmp             = s1[38].split("',")[0]

            let isDraftListTmp                  = s1[39].split("',")[0]
            let isDeletedListTmp                = s1[40].split("',")[0]
            let createdbyListTmp                = s1[41].split("',")[0]
            let createddateListTmp              = s1[42].split("',")[0]
            let modifiedbyListTmp               = s1[43].split("',")[0]
            let modifieddateListTmp             = s1[44].split("',")[0]
            let isModifiedListTmp               = s1[45].split("',")[0]

        
            idList.push(IdClean2.replace(" '",""))
            selectedClientNameList.push(selectedClientNameTmp.replace(" '",""))
            selectedEmployeeNameList.push(selectedEmployeeNameTmp.replace(" '",""))
            selectedPositionList.push(selectedPositionTmp.replace(" '",""))
            selectedEmployeeNoList.push(selectedEmployeeNoTmp.replace(" '",""))
            selectedEmployeeBranchList.push(selectedEmployeeBranchTmp.replace(" '",""))
            groundOfSeperationList.push(groundOfSeperationTmp.replace(" '",""))
            caseNumeberList.push(caseNumeberTmp.replace(" '",""))
            dateFiledList.push(dateFiledTmp.replace(" '",""))
            dateReceivedList.push(dateReceivedTmp.replace(" '",""))
            complaintList.push(complaintTmp.replace(" '",""))
            scheduleOfHearingList.push(scheduleOfHearingTmp.replace(" '",""))
            venueList.push(venueTmp.replace(" '",""))
            hearingOfficerList.push(hearingOfficerTmp.replace(" '",""))
            caseNoNLRCList.push(caseNoNLRCTmp.replace(" '",""))
            mandatoryDateFiledList.push(mandatoryDateFiledTmp.replace(" '",""))
            mandatoryDateReceivedList.push(mandatoryDateReceivedTmp.replace(" '",""))
            mandatoryComplaintList.push(mandatoryComplaintTmp.replace(" '",""))
            mandatoryScheduleOfHearingList.push(mandatoryScheduleOfHearingTmp.replace(" '",""))
            mandatoryVenueList.push(mandatoryVenueTmp.replace(" '",""))
            mandatoryLaborArbiterList.push(mandatoryLaborArbiterTmp.replace(" '",""))
            particularsMomStConfMeetingList.push(particularsMomStConfMeetingTmp.replace(" '",""))
            particularsMomNdConfMeetingList.push(particularsMomNdConfMeetingTmp.replace(" '",""))
            particularsQuitclaimList.push(particularsQuitclaimTmp.replace(" '",""))
            particularsMonetaryClaimList.push(particularsMonetaryClaimTmp.replace(" '",""))
            positionPaperList.push(positionPaperTmp.replace(" '",""))
            replyList.push(replyTmp.replace(" '",""))
            rejoinderList.push(rejoinderTmp.replace(" '",""))
            decisionList.push(decisionTmp.replace(" '",""))
            counselList.push(counselTmp.replace(" '",""))
            latestUpdateList.push(latestUpdateTmp.replace(" '",""))
            statusDateList.push(statusDateTmp.replace(" '",""))
            remarksList.push(remarksTmp.replace(" '",""))
            updateStatusList.push(updateStatusTmp.replace(" '","")) 
            attachedDocList.push(attachedDocTmp.replace(" '",""))
            reportedByEmployeeList.push(reportedByEmployeeTmp.replace(" '",""))
            reportedByPositionList.push(reportedByPositionTmp.replace(" '",""))
            reportedByClientList.push(reportedByClientTmp.replace(" '",""))

            isDraftList.push(isDraftListTmp.replace(" '",""))
            isDeletedList.push(isDeletedListTmp.replace(" '",""))
            createdbyList.push(createdbyListTmp.replace(" '",""))
            createddateList.push(createddateListTmp.replace(" '",""))
            modifiedbyList.push(modifiedbyListTmp.replace(" '",""))
            modifieddateList.push(modifieddateListTmp.replace(" '",""))
            isModifiedList.push(isModifiedListTmp.replace(" '","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                            :   idList[i],
                'selectedClientName'            :   selectedClientNameList[i],
                'selectedEmployeeName'          :   selectedEmployeeNameList[i],
                'selectedPosition'              :   selectedPositionList[i],
                'selectedEmployeeNo'            :   selectedEmployeeNoList[i],
                'selectedEmployeeBranch'        :   selectedEmployeeBranchList[i],
                'groundOfSeperation'            :   groundOfSeperationList[i],
                'caseNumeber'                   :   caseNumeberList[i],
                'dateFiled'                     :   dateFiledList[i],
                'dateReceived'                  :   dateReceivedList[i],
                'complaint'                     :   complaintList[i],
                'scheduleOfHearing'             :   scheduleOfHearingList[i],
                'venue'                         :   venueList[i],
                'hearingOfficer'                :   hearingOfficerList[i],
                'caseNoNLRC'                    :   caseNoNLRCList[i],
                'mandatoryDateFiled'            :   mandatoryDateFiledList[i],
                'mandatoryDateReceived'         :   mandatoryDateReceivedList[i],
                'mandatoryComplaint'            :   mandatoryComplaintList[i],
                'mandatoryScheduleOfHearing'    :   mandatoryScheduleOfHearingList[i],
                'mandatoryVenue'                :   mandatoryVenueList[i],
                'mandatoryLaborArbiter'         :   mandatoryLaborArbiterList[i],
                'particularsMomStConfMeeting'   :   particularsMomStConfMeetingList[i],
                'particularsMomNdConfMeeting'   :   particularsMomNdConfMeetingList[i],
                'particularsQuitclaim'          :   particularsQuitclaimList[i],
                'particularsMonetaryClaim'      :   particularsMonetaryClaimList[i],
                'positionPaper'                 :   positionPaperList[i],
                'reply'                         :   replyList[i],
                'rejoinder'                     :   rejoinderList[i],
                'decision'                      :   decisionList[i],
                'counsel'                       :   counselList[i],
                'latestUpdate'                  :   latestUpdateList[i],
                'statusDate'                    :   statusDateList[i],
                'remarks'                       :   remarksList[i],
                'updateStatus'                  :   updateStatusList[i],
                'attachedDoc'                   :   attachedDocList[i],
                'reportedByEmployee'            :   reportedByEmployeeList[i],
                'reportedByPosition'            :   reportedByPositionList[i],
                'reportedByClient'              :   reportedByClientList[i],

                'isDraft'                       :   isDraftList[i],
                'isDeleted'                     :   isDeletedList[i],
                'createdby'                     :   createdbyList[i],
                'createddate'                   :   createddateList[i],
                'modifiedby'                    :   modifiedbyList[i],
                'modifieddate'                  :   modifieddateList[i],
                'isModified'                    :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }
        
        return itemList
    }

    getMonitoryByClient =()=>{
       
        let filter_data ={'client':this.state.client}

        const getParams ={
            "_collection" : "IncidentReport",
            "filter_data" : filter_data
        }

        console.log("axios start")

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data
            console.log("getMonitoringList Raw Json111")
            console.log(data)
            console.log("getMonitoringList End Raw Json111")
            var obj = (data);
            var res = [];
            
            data.IncidentReport.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                res.push(json)
            })

            res.map(function(d){
                d.id = res.length++
                d.action = "1"
            })

            this.setState({
                employeeDetailList: res,
                isloading:false
            })
            
        })
        .catch(error=>{
            console.log("error: " + error)
        })
             
    } 
    
    handleExportCVS=(e,props)=>{
       
        props.onExport();
        
    }

    onChangeparticular = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedparticular     :   '',
                selectedparticularId   :   ''
            })
            return
        }
        this.state.selectedparticular = e[0].name
        this.state.selectedparticularId = e[0].id
       
        this.setState({
            isshow   :   false,
           selectedparticular : e[0].name

        })
        //console.log(this.state.selectedparticular)
        
    }

    nextProcess=(row)=>{
        console.log(row)
        sessionStorage.setItem("SenaData", JSON.stringify(row))
        let nextProcessCheck = "1"
        sessionStorage.setItem("SenaProcess", JSON.stringify(nextProcessCheck)) 

        window.location.href='/SenaCases'

       /*  if(row.status === "IR" || row.status == "IR" ){
           
        } */
        /* if(row.status === "NTE" || row.status == "NTE" ){
            window.location.href='/NoticeOfDisiplinaryAction'
        } */

       /*  let rowtmp = row.id

       */

        
    }

    updateSenaCases=()=>{
        console.log("update")
    }


    render() {
        const { ExportCSVButton } = CSVExport;
        
        const statusColumn = [
            
            {
                dataField   : 'summary',
                text:'summary',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'pending',
                text:'pending',
                headerStyle : () => {
                    return { minWidth  : "375px"};
                }
            },
            {
                dataField: 'settled',
                text: 'SETTLED',
                headerStyle : () => {
                    return { minWidth  : "400px" };
                }
            },
            {
                dataField   : 'elevateToNLRC',
                text        : 'ELEVATE TO NLRC',
                headerStyle : () => {
                    return { minWidth  : "400px" };;
                }
            },
            /*{
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={this.showTrainingRegisterModal}
                        >View Details</Button>
                    );
                },
            }, */
        ]

        const statusColumn2 = [
            
            {
                dataField   : 'nonRetail1',
                text:'NON RETAIL 1',
                editable:false,
                headerStyle : () => {
                    return { width  : "230px"};
                }
            },
            {
                dataField   : 'nonRetail2',
                text:'NO RETAIL 2',
                editable:false,
                headerStyle : () => {
                    return { width  : "230px"};
                }
            },
            {
                dataField: 'retail1',
                text: 'RETAIL 1',
                editable:false,
                headerStyle : () => {
                    return { width  : "230px" };
                }
            },
            {
                dataField   : 'retail2',
                text        : 'RETAIL 2',
                editable:false,
                headerStyle : () => {
                    return { width  : "230px" };;
                }
            },
            {
                dataField   : 'retail3',
                text        : 'RETAIL 3',
                editable:false,
                headerStyle : () => {
                    return { width  : "230px" };;
                }
            },
            {
                dataField   : 'retail4',
                text        : 'RETAIL 4',
                editable:false,
                headerStyle : () => {
                    return { width  : "230px" };;
                }
            },
            {
                dataField   : 'internal',
                text        : 'INTERNAL',
                editable:false,
                headerStyle : () => {
                    return { width  : "230px" };;
                }
            },
            /*{
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={this.showTrainingRegisterModal}
                        >View Details</Button>
                    );
                },
            }, */
        ]

        const statusColumn3 = [
            
            {
                dataField   : 'POSSIBLE',
                text:'POSSIBLE',
                editable:false,
                headerStyle : () => {
                    return { width  : "400px" };
                }
            },
            {
                dataField   : 'PROBABLE',
                text:'PROBABLE',
                editable:false,
                headerStyle : () => {
                    return { width  : "400px" };
                }
            },
            {
                dataField: 'REMOTE',
                text: 'REMOTE',
                editable:false,
                headerStyle : () => {
                    return { width  : "400px" };
                }
            },
            {
                dataField   : 'CLOSED',
                text        : 'CLOSED',
                editable:false,
                headerStyle : () => {
                    return { width  : "400px" };
                }
            },
            /*{ 
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={this.showTrainingRegisterModal}
                        >View Details</Button>
                    );
                },
            }, */
        ]

        const statusColumn4 = [
            
            {
                dataField   : 'IlligalDismissal',
                text:'ACTUAL ILLEGAL DISMISSAL',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'ConstructiveDismissal',
                text:'CONSTRUCTIVE ILLEGAL DISMISSAL',
                headerStyle : () => {
                    return { minWidth  : "375px"};
                }
            },
            {
                dataField: 'IndemnityPay',
                text: 'CLAIM FOR SEPARATION PAY/INDEMNITY PAY',
                headerStyle : () => {
                    return { minWidth  : "400px" };
                }
            },
            {
                dataField   : 'reinstatement',
                text        : 'REINSTATEMENT',
                headerStyle : () => {
                    return { minWidth  : "400px" };;
                }
            },
            {
                dataField   : 'backwage',
                text:'BACKWAGES',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'nonpaymentsalaryWage',
                text:'NON-PAYMENT OF SALARY/WAGES',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'overtimePay',
                text:'NON-PAYMENT OF OVERTIME PAY',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'holidayPay',
                text:'NON-PAYMENT OF HOLIDAY PAY',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'PremSpecialDayPay',
                text:'NON-PAYMENT PREMIUM PAY FOR HOLIDAY',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'PremRestDayPay',
                text:'NON-PAYMENT FOR PREMIUM PAY FOR REST DAY',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'ServiceIncentiveLeave',
                text:'NON-PAYMENT SERVICE INCENTIVE LEAVE',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'thirteenmonthPay',
                text:'NON-PAYMENT FOR 13 MONTH PAY',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'nonpaymentseperationPay',
                text:'NON-PAYMENT FOR SEPARATION PAY',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'ServiceCharge',
                text:'NON-PAYMENT SERVICE CHARGES',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'nightShift',
                text:'NON-PAYMENT OF NIGHT SHIFT DIFFERENTIAL',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'minimunwage',
                text:'MINIMUM WAGE',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'COLA',
                text:'COLA',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'DelayedPayment',
                text:'DELAYED PAYMENT/TIME OF PAYMENT',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'FinalPay',
                text:'CLAIM FOR LAST SALARY/FINAL PAY',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'RetirementBenef',
                text:'CLAIM FOR RETIREMENT BENEFITS',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'paymentOfDivident',
                text:'PAYMENT OF DIVIDEND',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'refundOfmembershipfee',
                text:'REFUND OF MEMBERSHIP FEE',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'refundOfcapitalbuildup',
                text:'REFUND OF CAPITAL BUILD UP',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'IlligalDeduc',
                text:'ILLEGAL DEDUCTIONS',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'illigalSuspension',
                text:'ILLEGAL SUSPENSION',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'RegularContractual',
                text:'REGULARIZATION/CONTRACTUALIZATION',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'moralExemplaryDmg',
                text:'MORAL AND EXEMPLARY DAMAGES',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'attysFee',
                text:'ATTYS FEES',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'hazardPay',
                text:'HAZARD PAY',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'maternityLeave',
                text:'MATERNITY LEAVE',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'paternityLeave',
                text:'PATERNITY LEAVE',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'parentalForSolo',
                text:'PARENTAL LEAVE FOR SOLO PARENT',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'VawcVictims',
                text:'LEAVE FOR VICTIMS OF VAWC',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'illigalStrikeLockout',
                text:'ILLEGAL STRIKE/LOCKOUT',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'HarrassmentMaltreat',
                text:'MALTREATMENT/HARRASSMENT',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'incentives',
                text:'INCENTIVES',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'allowance',
                text:'ALLOWANCE',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'governmentmanContri',
                text:'GOVERNMENT MANDATORY CONTRIBUTION',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'governmentmanLoan',
                text:'GOVERNMENT MANDATORY LOAN',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'governmentmanBenefits',
                text:'GOVERNMENT MANDATORY BENEFITS',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'CBAviolation',
                text:'CBA VIOLATIONS',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'unexpiredPortionOfContract',
                text:'PAYMENT OF UNEXPIRED PORTION OF CONTRACT',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'nonIssuanceCOE',
                text:'NON-ISSUANCE OF CERTIFICATE OF EMPLOYMENT',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'SafetyHealthVio',
                text:'OCCUPATIONAL SAFETY AND HEALTH VIOLATIONS',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'OtherPersonalAction',
                text:'OTHER PERSONNEL ACTIONS',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            {
                dataField   : 'others',
                text:'OTHERS',
                headerStyle : () => {
                    return { minWidth  : "300px"};
                }
            },
            

            /*{
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={this.showTrainingRegisterModal}
                        >View Details</Button>
                    );
                },
            }, */
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.statusTableList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };

        const employeeDetailColumn = [


            {
                dataField   : 'dateOfSenaReceived',
                text        : 'date Of Sena Received',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'senaReferenceNo',
                text        : 'REFERENCE NUMBER',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'caseTitle',
                text        : 'CASE TITLE',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'caseNo',
                text        : 'CASE NUMBER',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'employeeName',
                text        : 'Employee Name',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                // formatter   :   (cell, row, isSelect , id) => {
                //     console.log("row ahjsgdjahgsdjhagshjdgahjgsdhjgasgdhjagshjdg")
                //     console.log(row.employeeDetails[1].employeeName)
                //     let employeetmp = row.employeeDetails[1].employeeName
                //     if (cell){
                //         return (
                //            /*  <Button style={{fontSize:'11px', textDecoration: "none"}}
                //                 variant="link" onClick={(e) =>this.handleClickBatchNo(row)}
                //             ><ArrowRight style={{color: "#f4d56e",}} size={19}/> */ <span style={{color: "#000000" }}>{employeetmp}</span>/*  </Button> */
                //         );
                //     }

                // },
            },
            {
                dataField   : 'respondent',
                text        : 'RESPONDENT',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'ClientName',
                text:'Client Involved',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'locationName',
                text        : 'Location',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'position',
                text        : 'Position',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },            
            {
                dataField   : 'memberStatus',
                text:'Status of member',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'retailGroup',
                text        : 'retail Group',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'senaConference1Date',
                text        : 'sena Conference 1 Date',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },  
            {
                dataField   : 'conference1Remarks',
                text        : 'REMARKS',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },            
            {
                dataField   : 'senaConference2Date',
                text        : 'sena Conference 2 Date',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            }, 
            {
                dataField   : 'conference2Remarks',
                text        : 'REMARKS',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            }, 
            {
                dataField   : 'senaConference3Date',
                text        : 'sena Conference 3 Date',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'conference3Remarks',
                text        : 'REMARKS',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            }, 
            {
                dataField   : 'senaConference4Date',
                text        : 'sena Conference 4 Date',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },        
            {
                dataField   : 'conference4Remarks',
                text        : 'REMARKS',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },    
            
            {
                dataField   : 'doleOffice',
                text        : 'dole Office',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'doleOfficer',
                text        : 'dole Officer',
                headerStyle : () => {
                    return { width  : "10%" };
                }
                
            }, 
            {
                dataField   : 'complaint',
                text        : 'CAUSE OF ACTION',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'senaStatus',
                text        : 'Sena case status',
                editable    : false,
               /*  editor: {
                    type: Type.SELECT,
                    options: this.state.senaStatus
                }, */
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'caseliability',
                text        : 'CASE LIABILITY',
                editable    : false,
                
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'senaCasesRemarks',
                text        : 'REMARKS',
                editable    : false,
                
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
           
            
            {
                dataField   : 'action',
                text        : 'EDIT',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                editable    : false,
                formatter   :   (cell, row, isSelect) => {
                    if (cell)
                    return (
                        <ButtonToolbar className="mt-1">     
                            <Button variant="Link" onClick={(e)=> this.nextProcess(row)} style={{color: "#1c6dfe"}}>Edit</Button>&nbsp;&nbsp;
                        </ButtonToolbar>  
                    );
                },
            },
        ]

        const selectemployeeDetailRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.employeeDetailList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEmployeeDetailEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
       
        
    return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header> Sena Cases Monitoring </Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>  

                                <Form.Group as={Row} controlId="formPlaintextEmail">  </Form.Group>

                                <Card.Header className="mt-3">Report Summary </Card.Header>
                                
                                <ToolkitProvider
                                    keyField="id"   
                                    data={ this.state.summaryDetailsList }
                                    columns={ statusColumn }
                                    exportCSV={ {
                                        fileName: "NOSER.csv",
                                    } }
                                >
                                    {
                                        props => (
                                            <div>
                                                <hr />
                                                <BootstrapTable { ...props.baseProps } />
                                                <ButtonToolbar>
                                                    <ExportCSVButton style={{marginBottom : "10px"}} className="btn btn-info ml-auto" { ...props.csvProps }>Export Report</ExportCSVButton>                                                       
                                                    
                                                </ButtonToolbar>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>

                                <ToolkitProvider
                                    keyField="id"   
                                    data={ this.state.summaryDetailsList2 }
                                    columns={ statusColumn2 }
                                    exportCSV={ {
                                        fileName: "NOSER.csv",
                                    } }
                                >
                                    {
                                        props => (
                                            <div>
                                                <hr />
                                                <BootstrapTable { ...props.baseProps } />
                                                <ButtonToolbar>
                                                    <ExportCSVButton style={{marginBottom : "10px"}} className="btn btn-info ml-auto" { ...props.csvProps }>Export Report</ExportCSVButton>                                                       
                                                    
                                                </ButtonToolbar>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>

                                <ToolkitProvider
                                    keyField="id"   
                                    data={ this.state.summaryDetailsList3 }
                                    columns={ statusColumn3 }
                                    exportCSV={ {
                                        fileName: "NOSER.csv",
                                    } }
                                >
                                    {
                                        props => (
                                            <div>
                                                <hr />
                                                <BootstrapTable { ...props.baseProps } />
                                                <ButtonToolbar>
                                                    <ExportCSVButton style={{marginBottom : "10px"}} className="btn btn-info ml-auto" { ...props.csvProps }>Export Report</ExportCSVButton>                                                       
                                                    
                                                </ButtonToolbar>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                                <ToolkitProvider
                                    keyField="id"   
                                    data={ this.state.summaryDetailsList4 }
                                    columns={ statusColumn4 }
                                    exportCSV={ {
                                        fileName: "NOSER.csv",
                                    } }
                                >
                                    {
                                        props => (
                                            <div>
                                                <hr />
                                                <BootstrapTable { ...props.baseProps } />
                                                <ButtonToolbar>
                                                    <ExportCSVButton style={{marginBottom : "10px"}} className="btn btn-info ml-auto" { ...props.csvProps }>Export Report</ExportCSVButton>                                                       
                                                    
                                                </ButtonToolbar>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                                
                                <Form.Group as={Row} controlId="formPlaintextEmail">  </Form.Group>

                                <Card.Header className="mt-3">REPORT FILTERING</Card.Header>

                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    Client
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeClientList}
                                            options={this.state.getClientList}
                                            placeholder="Select Client"
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Retail Group
                                    </Form.Label>
                                    <Col sm="4">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeretailGroup}
                                            options={this.state.retailgroupData}

                                        />
                                    </Col>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Sena Case Status
                                    </Form.Label>
                                    <Col sm="4">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangesenaStaus}
                                            options={this.state.senaStatus}

                                        />
                                    </Col>

                                </Form.Group>

                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        DATE SENA RECEIVED
                                    </Form.Label>
                                    <Col sm="3">
                                        <DatePicker
                                            ref='dateApplied'
                                            selected={this.state.dateApplied}
                                            onChange={this.handleChangeDate}
                                            minDate={this.minDate}
                                            value={this.props.dateApplied}
                                            dateFormat={"MM/dd/yyyy"}
                                            /* placeholderText="Current " */
                                            className="form-control"
                                        />
                                    </Col>

                                </Form.Group>
                                <ButtonToolbar>
                                    <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleChangeSearch}>
                                        Search
                                    </Button> 
                                    <Button variant="primary" style={{minWidth:'60px', marginLeft:"3px"}} onClick={this.getMonitoringList}>
                                        refresh
                                    </Button> 
                                </ButtonToolbar>

                                <BootstrapTable
                                    keyField = "id"
                                    data = { this.state.employeeDetailList }
                                    columns = { employeeDetailColumn }
                                    // pagination={ paginationFactory({sizePerPageRenderer}) }
                                    striped
                                    hover
                                    condensed
                                    noDataIndication={ () => <div>No record found.</div> }
                                    cellEdit = { cellEditFactory({
                                        mode: 'click',
                                        blurToSave: true,
                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                            this.GridDataModified(oldValue, newValue, row.id, column.dataField , row)
                                            }
                                        })
                                    }
                                    //defaultSorted={ defaultSorted }
                                    selectRow={selectemployeeDetailRow}

                                />

                                <ButtonToolbar>

                                    <Button variant="primary" className="ml-auto"  style={{minWidth:'60px', marginLeft:"3px"}} onClick={this.updateSenaCases}>
                                        Update
                                    </Button> &nbsp;&nbsp;
                                    
                                    <NavLink to="/home">
                                        <Button variant="danger"  href="/home" style={{minWidth:'60px'}}>
                                            Close
                                        </Button>
                                    </NavLink>
                                    
                                </ButtonToolbar>

                                
                                {/* <ToolkitProvider
                                    keyField="id"   
                                    data={ this.state.employeeDetailList }
                                    columns={ employeeDetailColumn }
                                    exportCSV={ {
                                        fileName: "NOSER.csv",
                                    } }
                                    striped
                                    hover
                                    condensed
                                    noDataIndication={ () => <div>No record found.</div> }
                                    cellEdit = { cellEditFactory({
                                        mode: 'click',
                                        blurToSave: true,
                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                            this.GridDataModified(oldValue, newValue, row.id, column.dataField , row)
                                            }
                                        })
                                    }
                                    //defaultSorted={ defaultSorted }
                                    selectRow={selectemployeeDetailRow}
                                >
                                    {
                                        props => (
                                            <div>
                                                <hr />
                                                <BootstrapTable { ...props.baseProps } />
                                                <ButtonToolbar>
                                                    <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export Report</ExportCSVButton>                                                       
                                                   
                                                    <NavLink to="/home">
                                                        <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                                            Close
                                                        </Button>
                                                    </NavLink>
                                                    <Button variant="primary" style={{minWidth:'60px', marginLeft:"3px"}} onClick={this.getMonitoringList}>
                                                        Update
                                                    </Button> 
                                                </ButtonToolbar>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider> */}
                            </Form>
                        </Card.Body>
                    </Card>                    
                </Container>
                <NoserLoading show={this.state.isloading} />
                  
            </div> 
        )
    }

}

export  default SenaCasesMonitoring 
