import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Container, Modal, DatePicker,Dropdown, DropdownButton, props, Redirect
} 
from '../../../noser-hris-component';


class FinanceModal extends Component {
    constructor(props) {
        super(props)
        this.state ={
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            date         : "",
            remarks  : "",
            payCardList     : [],
            payCardTypeId : "",

        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }
    componentDidMount() {
        this.setState({isloading:true})
        this.props.onRefModal(this)
        this.GetPayCard();
    }

    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }

    initialize = (e) => {
        this.setState({
        isloading       :   false,
        isshow          :   false,
        alerttype       :   "",
        message         :   "",
        color           :   "",
        fade            :   true,

        details         : e,
        headerId        : e.id,
        createdBy       : e.createdBy,
        employeeName    : e.employeeName,
        profileId       : e.profileId,
        employeeId      : e.employeeId,
        middleName      : e.middleName,
        suffixName      : e.suffixName,
        paycardType     : e.payCardType,
        natureId   : e.natureId,
        applicationDate   : e.applicationDate,
        atmCardNumber: "",

        })
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        console.log("initialize")
        console.log(e)
        if(e.natureId === "1"){
            if(e.payCardNumber === ""){
                this.setState({
                    payCardNumber: e.payCardNumber,
                    disbaleDetails : false,
                })
            }else{
                this.setState({
                    disbaleDetails : true,
                    payCardNumber: e.payCardNumber,
                })
            }
        }else {
            this.setState({
                disbaleDetails : true
            })
        }
        if(e.natureId === "4"){
            this.setState({
                payCardNumber: e.oldOayCardNumber
            })
        }
        if(e.statusId === "5"){
            this.setState({ 
                disabledCardOneReject : true,
                disabledCardOneTransmit : true,
                disabledCardTwo     : true,
                disabledCardThree   : true,
                disabledCardFour    : true,
                disabledCardFive    : true
            })
        }
        if(e.statusId === "7"){
            this.setState({ 
                disabledCardOneRecieve : false,
                disabledCardOneReject : true,
                disabledCardOneTransmit : true,
                disabledCardTwo     : true,
                disabledCardThree   : true,
                disabledCardFour    : true,
                disabledCardFive    : true
            })
        }
        if(e.statusId === "10"){
            this.setState({ 
                disabledCardOneRecieve : true,
                disabledCardOneReject : false,
                disabledCardOneTransmit : false,
                disabledCardTwo     : true,
                disabledCardThree   : true,
                disabledCardFour    : true,
                disabledCardFive    : true
            })
        }

        if(e.statusId === "12"){
            if(e.natureId === "2" && e.reasonId === "3"){
                this.setState({ 
                    disabledCardOneRecieve : true,
                    disabledCardOneReject : true,
                    disabledCardOneTransmit : true,
                    disabledCardOne     : true,
                    disabledCardTwo     : true,
                    disabledCardThree   : true,
                    disabledCardFour    : true,
                    disabledCardFive    : false
                })
            } else {
                this.setState({
                    disabledCardOneRecieve : true,
                    disabledCardOneReject : true,
                    disabledCardOneTransmit : true,
                    disabledCardOne     : true,
                    disabledCardThree   : true,
                    disabledCardFour    : true,
                    disabledCardFive    : true
                })
            }
        }

        if(e.statusId === "13" || e.statusId === "14"){
            this.setState({
                disabledCardOne     : true,
                disabledCardOneReject : true,
                disabledCardOneRecieve : true,
                disabledCardOneTransmit : true,
                disabledCardOneAp : true,
                disabledCardThree   : true,
                disabledCardFour    : true,
                disabledCardFive    : true
            })
        }
        
        

        if(e.statusId === "15"){
            
            if(e.natureId === "5"){
                this.setState({
                    disabledCardOneRecieve : true,
                    disabledCardOneReject : true,
                    disabledCardOneTransmit : true,
                    disabledCardTwo     : true,
                    disabledCardThree   : true,
                    disabledCardFour    : true,
                    disabledCardFive    : false
                })
            }else {
                this.setState({
                    disabledCardOneRecieve : true,
                    disabledCardOneReject : true,
                    disabledCardOneTransmit : true,
                    disabledCardOne     : true,
                    disabledCardTwo     : true,
                    disabledCardFour    : true,
                    disabledCardFive    : true
                })
            }
            
            if(e.natureId === "4"){
                this.setState({
                    disabledCardOneRecieve : true,
                    disabledCardOneReject : true,
                    disabledCardOneTransmit : true,
                    disabledCardTwo     : true,
                    disabledCardThree   : true,
                    disabledCardFour    : true,
                    disabledCardFive    : false
                })
            }else {
                this.setState({
                    disabledCardOneRecieve : true,
                    disabledCardOneReject : true,
                    disabledCardOneTransmit : true,
                    disabledCardOne     : true,
                    disabledCardTwo     : true,
                    disabledCardFour    : true,
                    disabledCardFive    : true
                })
            }
        }

        if(e.statusId === "16"){
            this.setState({
                disabledCardOne     : true,
                disabledCardTwo     : true,
                disabledCardThree   : true,
                disabledCardFive    : true
            })
        }

        if(e.statusId === "25"){
            this.setState({
                disabledCardOneRecieve : true,
                disabledCardOneReject : true,
                disabledCardOneTransmit : true,
                disabledCardOne     : true,
                disabledCardTwo     : true,
                disabledCardThree   : true,
                disabledCardFive    : true,
            })
        }

        if(e.statusId === "26"){
            this.setState({
                    disabledCardOneRecieve : true,
                    disabledCardOneReject : true,
                    disabledCardOneTransmit : true,
                disabledCardOne     : true,
                disabledCardTwo     : true,
                disabledCardThree   : true,
                disabledCardFour    : true,
            })
        }

        if(e.statusId === "31"){
            if(e.natureId === "2" && e.reasonId === "3"){
                this.setState({ 
                    disabledCardOneRecieve : true,
                    disabledCardOneReject : true,
                    disabledCardOneTransmit : true,
                    disabledCardOne     : true,
                    disabledCardTwo     : true,
                    disabledCardThree   : true,
                    disabledCardFour    : false,
                    disabledCardFive    : false
                })
            }
        }


    }

    onModalClose = () => {
        this.props.onHide();
    }
 
    handleChangeDate = date => {
        this.setState({
            date: date,
            isshow:false,
        })
    }

    handleChangeRemarks = (e) => {
        this.setState({ remarks: e.target.value.toUpperCase(),isshow:false });
    }
    handleRejectClick = () => {
        this.setState({isloading:true})
        if(this.state.date === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "please select date!",
                fade            :   true
            })
            return  
        }
        if(this.state.remarks === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "please input remarks!",
                fade            :   true
            })
            return  
        }

        const areaParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "HeaderId"      :   this.state.headerId,
            "StatusId"      :   "11",
            "RequestDate"   :   moment(this.state.date).format('MM/DD/YYYY'),
            "CreatedBy"     :   this.state.createdBy,
            "Remarks"       :   this.state.remarks,
         };

        console.log("Submit Params")
        console.log(areaParams)

        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Paycard/AddApplicationStatus",  areaParams)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true,
                    disabledCardOneReceive:true,
                    disabledCardOneReject : true,
                    disabledCardOneApproved: true
                });
            } else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
    }

    handleApprovedClick = () => {
        this.setState({isloading:true})
        if(this.state.date === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "please select date!",
                fade            :   true
            })
            return  
        }
        // if(this.state.remarks === ""){
        //     this.setState({
        //         isloading       :   false,
        //         alerttype       :   "Error!",
        //         isshow          :   true,
        //         color           :   "danger",
        //         message         :    "please input remarks!",
        //         fade            :   true
        //     })
        //     return  
        // }

        if(this.state.natureId === "5" || this.state.natureId === "4"){
            const areaParams = {
                "IpAddress"     :   "0.0.0.0",
                "ClientId"      :   this.state.userinfo.clientId,
                "UserId"        :   this.state.userinfo.userId,
                "HeaderId"      :   this.state.headerId,
                "StatusId"      :   "33",
                "RequestDate"   :   moment(this.state.date).format('MM/DD/YYYY'),
                "CreatedBy"     :   this.state.createdBy,
                "Remarks"       :   this.state.remarks,
            };

            console.log("Approved Completed")
            console.log(areaParams)

            axios
            .post( AppConfiguration.Setting().noserapiendpoint + "Paycard/AddApplicationStatus",  areaParams)
            .then(res => {
                const data = res.data;
                if(data.status=="1"){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true,
                        disabledCardTwo: true,
                        disabledCardThree : false,
                    });
                } else {
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
            })
        } else {
            const areaParams = {
                "IpAddress"     :   "0.0.0.0",
                "ClientId"      :   this.state.userinfo.clientId,
                "UserId"        :   this.state.userinfo.userId,
                "HeaderId"      :   this.state.headerId,
                "StatusId"      :   "15",
                "RequestDate"   :   moment(this.state.date).format('MM/DD/YYYY'),
                "CreatedBy"     :   this.state.createdBy,
                "Remarks"       :   this.state.remarks,
            };

            console.log("Submit Params")
            console.log(areaParams)

            axios
            .post( AppConfiguration.Setting().noserapiendpoint + "Paycard/AddApplicationStatus",  areaParams)
            .then(res => {
                const data = res.data;
                if(data.status=="1"){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true,
                    });
                } else {
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
            })
        }

        
    }
    
    IsDecimal(val)
    {
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        return !regex.test(val)
    }
    IsNumeric(evt){
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    onChangeNewPayCardNumber = (e) => {
        this.setState({ newpayCardNumber: e.target.value} );
    }
    onChangeATMCardNumber = (e) => {
        this.setState({ atmCardNumber: e.target.value} );
    }
    
    GetPayCard = () =>{
        this.setState({isloading:true})
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        }

        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayCardTypes",  getParams)
        .then(res => {
            const data = res.data;
            console.log("Get Reason");
            console.log(data);
            this.setState({ payCardList : data.payCardTypes });
         })
    }

    onChangePayCardList = (e) => {
        if(e.length == 0) {
            this.setState({isshow:false,selectedPayCardId:"",selectedPayCard:""})
            return
        }
        this.state.payCardTypeId=e[0].id
        this.state.selectedPayCard=e[0].name
        
    }
    
    handleUpdateManning = () => {
        this.setState({isloading:true})
        if(this.state.date === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "please select date!",
                fade            :   true
            })
            return  
        }
        if(this.state.natureId === "1"){
            if(this.state.payCardNumber === ""){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "Please input paycard number!",
                    fade        :   true
                });
                return
            }
        }
        if(this.state.natureId === "2"){
            if(this.state.payCardNumber === ""){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "Please input paycard number!",
                    fade        :   true
                });
                return
            }
        }
        if(this.state.natureId === "3"){
            if(this.state.payCardNumber === ""){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "Please input paycard number!",
                    fade        :   true
                });
                return
            }
        }
        if(!this.state.payCardTypeId){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select paycard type!",
                fade        :   true
            });
            return
        }
        if(this.state.payCardTypeId === "14"){
            if(this.state.newpayCardNumber.length !== 12){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "Invalid paycard number for Union Bank.",
                    fade            :   true
                })
                return 
            }
        }
        if(this.state.payCardTypeId === "6"){
            if(this.state.newpayCardNumber.length !== 13){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "Invalid paycard number for Metro Bank.",
                    fade            :   true
                })
                return 
            }
        }
        if(this.state.payCardTypeId === "2"){
            if(this.state.newpayCardNumber.length !== 16){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "Invalid paycard number for BDO.",
                    fade            :   true
                })
                return 
            }
        }
        if(this.state.payCardTypeId === "2"){
            if(this.state.atmCardNumber.length !== 16){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "Invalid atm card number for BDO.",
                    fade            :   true
                })
                return 
            }
        }
        if(this.state.payCardTypeId === "2"){
            if(this.state.atmCardNumber.length !== 13){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :    "Invalid atm card number for Metro Bank.",
                    fade            :   true
                })
                return 
            }
        }
        
        const areaParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "HeaderId"      :   this.state.headerId,
            "StatusId"      :   "27",
            "RequestDate"   :   moment(this.state.date).format('MM/DD/YYYY'),
            "CreatedBy"     :   this.state.createdBy,
            "Remarks"       :   this.state.remarks,
         };

        console.log("Submit Params")
        console.log(areaParams)

        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Paycard/AddApplicationStatus",  areaParams)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
                // this.setState({
                //     isloading   :   false,
                //     alerttype   :   "Success!",
                //     isshow      :   true,
                //     color       :   "success",
                //     message     :   data.message,
                //     fade        :   true,
                // });
                sessionStorage.setItem("paycardHeaderId", JSON.stringify(this.state.headerId))
                this.UpdateManning();
            } else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        //this.UpdateManning();
    }

    UpdateManning = () => {
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "UserEmployeeId":this.state.userinfo.employeeId,

            "EmployeeId":this.state.employeeId,
            "ProfileId":this.state.profileId,
            "EmployeeName":this.state.employeeName,
            
            "FirstName":"",
            "MiddleName":this.state.middleName,
            "SuffixName":this.state.suffixName,
            "LastName":"",
            "NickName":"",
            "PhoneNumber":"",
            "MobileNumber":"",
            "EmailAddress":"",
            "DateOfBirth":"",
            "PlaceOfBirth":"",
            "NationalityId":"",
            "ReligionId":"",
            "GenderId":"",
            "Gender":"",
            "CivilStatusId":"",
            "CivilStatus":"",
            "LivingArrangementId":"",
            "BloodTypeId":"",
            "Height":"",
            "Weight":"",
            "Hobbies":"",
            "LanguageSpoken":"",
            "Facebook":this.state.headerId,
            "Twitter":"",
            "SSSNumber":"",
            "PHICNumber":"",
            "HDMFNumber":"",
            "TINNumber":"",
            "EmergencyContactName":"",
            "EmergencyContactNumber":"",
            "EmergencyContactRelation":"",
            
            "IsDefaultLocation":"",
            "LocationId":"",
            "PositionId":"",
            "EmploymentStatusTypeId":"",
            "PayTypeId":"",
            "PayModeId":"",
            "PayCardTypeId":this.state.payCardTypeId,
            "PayCardNumber":this.state.newpayCardNumber,
            "SalaryOffered":"",
            "SeaValue":"",
            "EColaValue":"",
            "ColaValue":"",
            "DateHired":"",
            "ContractDateStart":"",
            "ContractDateEnd":"",
            "DateResigned":"",
            "ApproverId1":"",
            "ApproverId2":"",
            "Remarks":this.state.remarks,
            "address":[],
            "familyBackgrounds":[],
            "educations":[],
            "ATMCardNumber":this.state.atmCardNumber,
        };
        
        console.log("Submit Params Manning")
        console.log(params)
        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Manning/SubmitEmployeeInformation",  params)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true,
                    disabledCardFive : true
                });
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
    }

    handleClickDetails = () => {
        console.log("details")
        console.log(this.state.details)
        sessionStorage.setItem("_paycardviewdetails", JSON.stringify(this.state.details))
        this.setState({navigate:true})
    }
    
    handleSubmitClick = (statusId) => {
        if(statusId === "10"){
            this.setState({
                disabledCardOneRecieve:true,
                disabledCardOneReject : false,
                disabledCardOneTransmit: false
            })
        }
        if(statusId === "12"){
            this.setState({
                disabledCardOneRecieve:true,
                disabledCardOneReject : true,
                disabledCardOneTransmit: true,
                disabledCardTwo : false,
            })
        }
        if(statusId === "13"){
            this.setState({
                disabledCardThree : false,
            })
        }
        if(statusId === "26"){
            this.setState({
                disabledCardFour : true,
                disabledCardFive : false
            })
        }
        this.setState({isloading:true})
        if(this.state.date === ""){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "please select date!",
                fade            :   true
            })
            return  
        }

        const areaParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "HeaderId"      :   this.state.headerId,
            "StatusId"      :   statusId,
            "RequestDate"   :   moment(this.state.date).format('MM/DD/YYYY'),
            "CreatedBy"     :   this.state.createdBy,
            "Remarks"       :   this.state.remarks,
         };

        console.log("Submit Params")
        console.log(areaParams)

        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Paycard/AddApplicationStatus",  areaParams)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true,
                });
            } else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
    }
    

    render() {
        const {navigate} = this.state;
        if (navigate) {
            return <Redirect to="/paycardviewdetails" push={true} />;
        } 
        return(
        
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                 >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        RECEIVING / TRANSMITTAL / APPROVAL
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        autoComplete="off" 
                                        name="name"
                                        value={this.state.employeeName}
                                        disabled 
                                    /> 
                                </Form.Group>
                            </Form.Row>
                        <Card className="mt-5">
                            <Card.Header>FINANCE</Card.Header>
                            <Card.Body className="mt-3">
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>OFFICE IN-CHARGE</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            autoComplete="off" 
                                            name="name"
                                            value={this.state.userinfo.fullName}
                                            disabled 
                                        /> 
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>POSTION</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            autoComplete="off" 
                                            name="name"
                                            value={this.state.userinfo.roleName}
                                            disabled 
                                        /> 
                                    </Form.Group>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                        
                        <Card className="mt-5">
                            <Card.Header>Acknowledgement of Transmitted PayCard Application by ADMIN</Card.Header>
                            <Card.Body className="mt-3">
                                <Form.Row>
                                    <Form.Group as={Col} sm="3"  controlId="formGridEmail">
                                        <DatePicker
                                            ref='date'
                                            selected={this.state.date}
                                            onChange={this.handleChangeDate}
                                            minDate={this.minDate}
                                            value={this.props.date}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                            placeholderText="Select Date" 
                                            disabled={this.state.disabledCardOne}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridPassword">
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Remarks" 
                                            onChange={this.handleChangeRemarks} 
                                            value={this.state.remarks}
                                            autoComplete="off" 
                                            disabled={this.state.disabledCardOne}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridPassword">
                                        <ButtonToolbar>
                                            <Button disabled={this.state.disabledCardOneRecieve} variant="success" className="ml-auto noser-button" onClick={ () => this.handleSubmitClick('10')} >Receive</Button>&nbsp;&nbsp;
                                            <Button disabled={this.state.disabledCardOneReject} variant="success" className="noser-button-mr1" onClick={ this.handleRejectClick} >Reject</Button>&nbsp;&nbsp;
                                            <Button disabled={this.state.disabledCardOneTransmit} variant="success" className="noser-button" onClick={ () => this.handleSubmitClick('12')} >Approved</Button>
                                        </ButtonToolbar> 
                                    </Form.Group>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                        
                        <Card className="mt-5">
                            <Card.Header>PayCard Application on Process @ BANK</Card.Header>
                            <Card.Body className="mt-3">
                                <Form.Row>
                                    <Form.Group as={Col} sm="3" controlId="formGridEmail">
                                        <DatePicker
                                            ref='date'
                                            selected={this.state.date}
                                            onChange={this.handleChangeDate}
                                            minDate={this.minDate}
                                            value={this.props.date}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                            placeholderText="Select Date" 
                                            disabled={this.state.disabledCardTwo}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridPassword">
                                        <ButtonToolbar>
                                            <Button disabled={this.state.disabledCardTwo} variant="success" className="noser-button" onClick={ () => this.handleSubmitClick('13')} >SUBMIT</Button>
                                        </ButtonToolbar> 
                                    </Form.Group>
                                </Form.Row>
                                <hr/>
                                <Form.Group className="mt-2" as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column style={{fontWeight : "bold"}}>
                                        BANK APPROVAL RECEIVED
                                        </Form.Label>
                                        <Col>
                                            <DatePicker
                                                ref='date'
                                                selected={this.state.date}
                                                onChange={this.handleChangeDate}
                                                minDate={this.minDate}
                                                value={this.props.date}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                wrapperClassName="datepicker"
                                                placeholderText="Select Date" 
                                                disabled={this.state.disabledCardTwo}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Control 
                                                ref="remarks"
                                                name="remarks"
                                                onChange={this.handleChangeRemarks} 
                                                value={this.state.remarks}
                                                autoComplete="off"
                                                placeholder="Remarks" 
                                                disabled={this.state.disabledCardTwo}
                                            />
                                        </Col>
                                        <Col>
                                            <ButtonToolbar>
                                                <Button disabled={this.state.disabledCardTwo} variant="success" className="ml-auto noser-button-mr1" onClick={ () => this.handleSubmitClick('14')} >Reject</Button>&nbsp;&nbsp;
                                                <Button disabled={this.state.disabledCardTwo} variant="success" className="noser-button" onClick={ this.handleApprovedClick} >Approved</Button>
                                            </ButtonToolbar> 
                                        </Col>
                                    </Form.Group>
                            </Card.Body>
                        </Card>
                        <Form.Row className="mt-2">
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Card className="mt-5">
                                    <Card.Header>TRANSMITTAL OF BANK APPROVED PAYCARD APPLICATION</Card.Header>
                                    <Card.Body className="mt-3">
                                        <Form.Row>
                                            <Form.Group as={Col}  controlId="formGridEmail">
                                                <DatePicker
                                                    ref='date'
                                                    selected={this.state.date}
                                                    onChange={this.handleChangeDate}
                                                    minDate={this.minDate}
                                                    value={this.props.date}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    className="form-control"
                                                    wrapperClassName="datepicker"
                                                    placeholderText="Select Date" 
                                                    disabled={this.state.disabledCardThree}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridPassword">
                                                <ButtonToolbar>
                                                    <Button disabled={this.state.disabledCardThree} variant="success" className="noser-button" onClick={ () => this.handleSubmitClick('16')} >TRANSMIT</Button>
                                                </ButtonToolbar> 
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Card className="mt-5">
                                    <Card.Header>ACKNOWLEDGEMENT OF APPROVED PAYCARD RECEIVED BY ADMIN</Card.Header>
                                    <Card.Body className="mt-3">
                                        <Form.Row>
                                            <Form.Group as={Col}  controlId="formGridEmail">
                                                <DatePicker
                                                    ref='date'
                                                    selected={this.state.date}
                                                    onChange={this.handleChangeDate}
                                                    minDate={this.minDate}
                                                    value={this.props.date}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    className="form-control"
                                                    wrapperClassName="datepicker"
                                                    placeholderText="Select Date" 
                                                    disabled={this.state.disabledCardFour}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridPassword">
                                                <ButtonToolbar>
                                                    <Button disabled={this.state.disabledCardFour} variant="success" className="noser-button" onClick={ () => this.handleSubmitClick('26')} >RECEIVE</Button>
                                                </ButtonToolbar> 
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridPassword">
                                <Card className="mt-5" >
                                    <Card.Header>ACTIVATION OF PAYCARD NUMBER</Card.Header>
                                    <Card.Body className="mt-3">
                                        <Form.Row>
                                            <Form.Group as={Col} controlId="formGridAddress1">
                                                <Form.Label style={{fontWeight : "bold"}}>PayCard Type</Form.Label>
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangePayCardList}
                                                    options={this.state.payCardList}
                                                    placeholder="Select PayCard"
                                                    disabled={this.state.disabledCardFive}
                                                /> 
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridAddress1">
                                                <Form.Label style={{fontWeight : "bold"}}>PayCard No.</Form.Label>
                                                <Form.Control 
                                                    type="text"
                                                    placeholder="Enter New No." 
                                                    ref="newpayCardNumber"
                                                    autoComplete="off"
                                                    maxLength="16"
                                                    name="newpayCardNumber"
                                                    value={this.state.newpayCardNumber}
                                                    onChange={this.onChangeNewPayCardNumber}
                                                    onKeyPress={this.IsNumeric.bind(this)}
                                                    disabled={this.state.disabledCardFive}

                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridAddress1">
                                                <Form.Label style={{fontWeight : "bold"}}>ATM Card Number</Form.Label>
                                                <Form.Control 
                                                    type="text"
                                                    placeholder="Enter ATM Card Number." 
                                                    ref="atmCardNumber"
                                                    autoComplete="off"
                                                    maxLength="16"
                                                    name="atmCardNumber"
                                                    value={this.state.atmCardNumber}
                                                    onChange={this.onChangeATMCardNumber}
                                                    onKeyPress={this.IsNumeric.bind(this)}
                                                    disabled={this.state.disabledCardFive}

                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} sm="2" controlId="formGridEmail">
                                                <DatePicker
                                                    ref='date'
                                                    selected={this.state.date}
                                                    onChange={this.handleChangeDate}
                                                    minDate={this.minDate}
                                                    value={this.props.date}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    className="form-control"
                                                    wrapperClassName="datepicker"
                                                    placeholderText="Select Date" 
                                                    disabled={this.state.disabledCardFive}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridPassword">
                                                <ButtonToolbar>
                                                    <Button disabled={this.state.disabledCardFive} variant="success" className="noser-button" onClick={ this.handleUpdateManning} >UPDATE MANNING</Button>
                                                </ButtonToolbar> 
                                            </Form.Group>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button className="ml-auto" variant="primary" onClick={this.handleClickDetails}>
                            VIEW APPLICATION DETAILS
                        </Button>&nbsp;&nbsp;
                        <Button variant="danger" onClick={this.onModalClose}>
                            Close
                        </Button>
                    </ButtonToolbar>
            </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }

}
export  default FinanceModal
