import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Modal, DatePicker
} 
from '../../noser-hris-component';

class RequestTraining extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,      
            disable         :   true,
            disablectx      :   true,

            clientId        :   "",
            employeeId      :   "",
            profileId       :   "",
            employeeNo      :   "",
            locationName    :   "",
            positionName    :   "",
            deploymentDate  :   "",
            employmentStatus:   "",
            requesttypeId   :   "",
            trainingId      :   "",
            trainingName    :   "",
            isRequired      :   "",
            clientDDL       :   [],
            employeeDDL     :   [],
            trainingDDL     :   [],
            trainings       :   [],
            requestTypeDDL  :   [
                {"id":"1","name":"TRAINING"},
                {"id":"2","name":"RE TRAINING"},
                {"id":"4","name":"RE ORIENTATION"},
                //{"id":"7","name":"EXAM RETAKE"}
            ],
            currentDate     :   "",
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo        :   JSON.parse(sessionStorage.getItem("userData")),
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            disable         :   true,
            disablectx      :   true,

            clientId        :   "",
            profileId       :   "",
            employeeId      :   "",
            employeeNo      :   "",
            locationName    :   "",
            positionName    :   "",
            employmentStatus:   "",
            deploymentDate  :   "",
            requesttypeId   :   "",
            trainingId      :   "",
            isRequired      :   "",
            clientDDL       :   [],
            employeeDDL     :   [],
            trainingDDL     :   [],
            trainings       :   [],
            currentDate     :   moment(new Date).format('MM/DD/YYYY'),
            minDate         :   moment(new Date),
        })
        this.GetClients()
    }
    handleChangeClient = async(e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,isloading:false});
        if(e.length===0)
            this.state.clientId= ''
        else
            this.state.clientId= e[0].id

        this.GetEmployees()
    }
    handleChangeEmployee = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,disablectx:true,profileId:''});
        if(e.length===0)
        {
            this.state.profileId= ''
            this.state.disablectx=true
            this.setState({
                profileId:"",
                employeeNo:"",
                locationName:"",
                positionName:"",
                employmentStatus:"",
                disablectx:true,
                requesttypeId:"",
                trainingId:""
            })
            this.refs.requesttypeId.clear()
            this.refs.trainingId.clear()
            this.GetEmployeeTrainings("-1")
        }
        else{
            this.setState({
                employeeId:e[0].id,
                profileId:e[0].profileId,
                employeeNo:e[0].employeeNo,
                locationName:e[0].location,
                positionName:e[0].position,
                employmentStatus:e[0].isActive==="1" ? "ACTIVE" : "INACTIVE" ,
                disablectx:e[0].isActive==="1" ? false : true
            })
            this.GetEmployeeTrainings(e[0].profileId)
        }
    }
    handleChangedRequestType = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,isloading:false});
        if(e.length===0)
            this.state.requesttypeId=''
        else
            this.state.requesttypeId= e[0].id
    }
    handleChangedTraining = (e) => {
        
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,isloading:false});
        if(e.length===0){
            this.state.trainingId=''
            this.state.isRequired=''
            this.state.trainingName=''
            return
        }
        this.state.trainingId= e[0].id
        this.state.trainingName= e[0].name
        this.state.isRequired= e[0].isRequired
    }
    handleChangedDate = date => {
        this.setState({deploymentDate: date});
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL : data.clients,isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetEmployees = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "LocationId":this.state.locationId,
            "PositionId":this.state.positionId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeList", params)
        .then(res => {
            const data = res.data;
            this.setState({employeeDDL : data.employees})
            this.GetTrainings()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetTrainings = async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "LocationId":this.state.locationId,
            "PositionId":this.state.positionId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetTrainings", params)
        .then(res => {
            const data = res.data;
            this.setState({trainingDDL : data.trainings, isloading:false})
            //this.GetEmployeeTrainings()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetEmployeeTrainings = async(profileId) => {
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "ProfileId":profileId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetEmployeeTrainings", params)
        .then(res => {
            const data = res.data;
            this.setState({trainings : data.employeeTrainings, isloading:false})  
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    } 
    handleSubmitClick = async() => {
        this.setState({isloading:true,disable:true})

        if(this.state.requesttypeId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select Request Type.",
                fade        :   true,
                disable     :   false
            })
            return
        }

        if(this.state.deploymentDate===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter Deployment Date.",
                fade        :   true,
                disable     :   false
            })
            return
        }
        
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "requesttypeId":this.state.requesttypeId,
            "DeploymentDate": this.FormatDate(this.state.deploymentDate),
            "EmployeeId":this.state.employeeId,
            "ProfileId":this.state.profileId,
            "trainings": this.state.trainings.filter(x=>x.isModified==="1")
        }
        
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/RequestTraining", params)
        .then(res => {
            const data = res.data;
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    disable     :   false
                });
            } 
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true,
                    requesttypeId:  "",
                    deploymentDate: ""
                });
                this.refs.requesttypeId.clear()
            } 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    } 
    handleAddTraining = (e) => {
        let trainings = this.state.trainings
        if(this.state.trainingId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Warning!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select training.",
                fade        :   true
            })
            return
        }
        if(trainings.filter(x=>x.trainingId===this.state.trainingId).length>0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Warning!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Selected training already exist.",
                fade        :   true
            })
            return
        }
        
        let newId = 0;
        if(trainings.length===0){
            let today = new Date()
            newId = moment(today).format('HHMMSS');
        }
        else{
            let today = new Date()
            const ids = trainings.map(x => x.id);
            const sorted = ids.sort((a, b) => a - b);
            const uid = moment(today).format('SS');
            newId = parseInt(sorted[sorted.length-1])+1+uid;
        }
        const obj = {
            "id" :newId.toString(),
            "deploymentId":"",
            "requestTypeId":this.state.requesttypeId,
            "profileId":this.state.profileId,
            "employeeId":this.state.employeeId,
            "trainingId":this.state.trainingId,
            "training":this.state.trainingName,
            "required" : this.state.isRequired==="1" ? "MANDATORY" : "OPTIONAL"
        }
        trainings.push(obj)
        this.setState({trainings: trainings.sort((a, b) => parseInt(b["id"]) - parseInt(a["id"]))})
        this.refs.trainingId.clear()
        this.state.trainingId=""
        this.state.trainingName=""
    }
    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (('0' + (date.getMonth()+1)).slice(-2) + "/" + ('0' + (date.getDate())).slice(-2) + "/" + date.getFullYear()) : "")
    }
    render() { 

        const columns1 = [
        {
            dataField: 'training',
            text: 'TRAINING',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'80%'}
            },
            style:{textAlign:'left',width:'80%'}
        },
        {
            dataField: 'required',
            text: 'TYPE',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'20%'}
            },
            style:{textAlign:'center',width:'20%'}
        }] 
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: false,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                this.state.trainings.map(function(itm,idx){
                    if(itm.id===row.id)
                        itm.isModified = isSelect ? "1" : "0"
                    if (itm.isModified=="1")
                    disable =  false
                })
                this.setState({disable:disable})
            }
        };
        return(
                <>
                <Modal
                    {...this.props}
                    return
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        TRAINING DEPLOYMENT
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Container>       
                    <Form>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                CLIENT :
                            </Form.Label>
                            <Col sm="6">
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangeClient}
                                    options={this.state.clientDDL}
                                />
                            </Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                LOCATION :
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control 
                                    type="text" 
                                    value={this.state.locationName}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                EMPLOYEE :
                            </Form.Label>
                            <Col sm="6">
                                <Typeahead
                                    labelKey='employeeName'
                                    id="basic-example"
                                    onChange={this.handleChangeEmployee}
                                    options={this.state.employeeDDL}
                                />
                            </Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                EMPLOYEE NO :
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control 
                                    type="text" 
                                    value={this.state.employeeNo}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    POSITION :
                            </Form.Label>
                            <Col sm="6">
                                <Form.Control 
                                    type="text" 
                                    value={this.state.positionName}
                                    disabled
                                />
                            </Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                DATE :
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control 
                                    type="text" 
                                    value={this.state.currentDate}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    REQUEST TYPE :
                            </Form.Label>
                            <Col sm="7">
                                <Typeahead
                                    ref="requesttypeId"
                                    name='requesttypeId'
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangedRequestType}
                                    options={this.state.requestTypeDDL}
                                    disabled={this.state.disablectx}
                                />
                            </Col>
                            <Col sm="3">
                                <Form.Control 
                                    type="text" 
                                    value={this.state.employmentStatus}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    ADDT'L TRAINING :
                            </Form.Label>
                            <Col sm="6">
                                <Typeahead
                                    ref="trainingId"
                                    name='trainingId'
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangedTraining}
                                    options={this.state.trainingDDL}
                                    disabled={this.state.disablectx}
                                />
                            </Col>
                            <Button disabled={this.state.disablectx} variant="primary" className="ml-auto noser-button" onClick={this.handleAddTraining}>ADD</Button>
                            <Col>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    DEPLOYMENT DATE :
                            </Form.Label>
                            <Col sm="6">
                                <DatePicker
                                    selected={this.state.deploymentDate}
                                    onChange={this.handleChangedDate}
                                    minDate={this.minDate}
                                    value={this.state.deploymentDate}
                                    dateFormat={"MM/dd/yyyy"}
                                    className="form-control"
                                    disabled={this.state.disablectx}
                                />
                            </Col>
                        </Form.Group>
                        <Card.Header className="mt-5">List</Card.Header>
                        <div >
                            <BootstrapTable
                                keyField = "id"
                                data = { this.state.trainings }
                                columns = { columns1 }
                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                rowClasses="noser-table-row-class"
                                striped
                                hover
                                condensed
                                selectRow={selectRow}
                                cellEdit = { cellEditFactory({
                                    mode: 'dbclick',
                                    blurToSave: true,
                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                        //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                        }
                                    })
                                }
                            />
                        </div>
                    </Form>
                </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button disabled={this.state.disable} variant="success" className="ml-auto  noser-button-mr1" onClick = { this.handleSubmitClick }>SUBMIT</Button>
                        <Button className="noser-button" variant="danger" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
            </Modal>
            </>
        );
    }
}
export  default RequestTraining
