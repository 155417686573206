import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { tsNullKeyword } from '@babel/types';

 
class AwolMontoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            statusTableList                     :   [],
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalTrainingRegisterShow           :   false,          
            
            detailsOfED                  :   [],
            particularList: [
                {
                    "id" : "Closed",
                    "name" : "Closed"
                },
                {
                    "id" : "Pending",
                    "name" : "Pending"
                },
                
               
            ],
            selectedparticular      :   '',
            selectedparticularId    :   '',
            countStatus             :'Pending',
            pendingsList            :[],
            closedList              :[],
            fliterClose             :'Closed',
            statusPendingList       :[],
            statusClosedList        :[],
            totalStatusList         :[],
            totalDoleCasesList      :[], 
            totalClosedCount        :[],  

            getClientList           :   [],
            selectedClientId        :   '',
            getEmployeeList         :   [],
            selectedClientName      :   '',
            selectedEmployeeName    :   '',
            selectedEmployeeNo      :   '',
            selectedEmployeeBranch  :   '',
            selectedPosition        :   '',

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}        
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getEDMonitoring();
        this.getClientList();        
        
    }

    getEDMonitoring =()=>{

        let filter_data ={}

        const getParams ={
            "_collection" : "AWOLMonitoring",
            "filter_data" : filter_data
        }

        console.log("axios start")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data
            console.log("res.data")
            console.log(data)

            if(data.AWOLMonitoring.length > 0){

                const itemUsersDataLists = this.buildListAWOLMonitoring(data["AWOLMonitoring"])
            
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                        
                    let obj = {

                        'id'                        :   this.state.itemUserDataList[i]['id'],
                        'referenceNoAWOL'           :   this.state.itemUserDataList[i]['referenceNoAWOL'],
                        'employeeName'              :   this.state.itemUserDataList[i]['employeeName'],
                        'employeeNo'                :   this.state.itemUserDataList[i]['employeeNo'],
                       
                        'client'                    :   this.state.itemUserDataList[i]['client'],   
                        'ClientId'                  :   this.state.itemUserDataList[i]['ClientId'],   
                        'location'                  :   this.state.itemUserDataList[i]['location'],          
                        'address'                   :   this.state.itemUserDataList[i]['address'],
                        'position'                  :   this.state.itemUserDataList[i]['position'],
                        'lastDayOfDuty'             :   this.state.itemUserDataList[i]['lastDayOfDuty'],
                        'awol1stNoticeDate'                 :   this.state.itemUserDataList[i]['awol1stNoticeDate'],
                        'awol2ndNoticeDate'                 :   this.state.itemUserDataList[i]['awol2ndNoticeDate'],
                        'awolNOHDate'               :   this.state.itemUserDataList[i]['awolNOHDate'],
                       
                        'awolTerminationDate'       :   this.state.itemUserDataList[i]['awolTerminationDate'],              
                        'terminationDate'           :   this.state.itemUserDataList[i]['terminationDate'],
                        'statusDocument'            :   this.state.itemUserDataList[i]['statusDocument'],
                        'status'                    :   this.state.itemUserDataList[i]['status'],
                        
                        'isDraft'                   :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'                 :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'                 :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'               :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'                :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'              :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'                :   this.state.itemUserDataList[i]['isModified'],

                        'action'                    :   "1",

                    }

                    List.push(obj)

                }

                this.setState({
                    detailsOfED: List,
                    isloading:false
                })
                
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    } 

    buildListAWOLMonitoring(data) {
        // console.log("data start")
        // console.log(data )
        console.log("data start")
        console.log(data )
        console.log("data" )
        let itemList =[]
    
        let idList                      =[]
        let referenceNoAWOLList         =[]
        let employeeNameList            =[]
        let employeeNoList              =[]      
        let clientList                  =[]
        let ClientIdList                =[]
        let locationList                =[]
        let addressList                 =[]
        let positionList                =[]

        let createdbyList               =[]
        let createddateList             =[]
        let lastDayOfDutyList           =[]
       
        let awol1stNoticeDateList       =[]
        let awol2ndNoticeDateList       =[]
        let awolNOHDateList             =[]
     
        let awolTerminationDateList     =[]
        let isDeletedList               =[]
        let isDraftList                 =[]
        let isModifiedList              =[]
       
        let modifiedbyList              =[]
        let modifieddateList            =[]
       
        let terminationDateList         =[]
        let statusList                  =[]
        let statusDocumentList          =[]

        for (let i = 0; i < data.length; i++) {            
    
            let s1 = data[i].split(", '")
            console.log("s1 start")
            console.log(s1 )
            console.log("s1" )
          
            let IdClean0                            = s1[0].split("': ")[1]           
            let idClean1                            = IdClean0.replace("ObjectId(","").replace(")","").replace("{","").replace(", 'action","")  
            let referenceNoAWOLTmp                  = s1[1].split("': ")[1]    
            let employeeNameTmp                  = s1[2].split("': ")[1]
            let employeeNoTmp                    = s1[3].split("': ")[1]
            let clientTmp                        = s1[4].split("': ")[1]  
            let ClientIdTmp                      = s1[5].split("': ")[1]
            let locationTmp                      = s1[6].split("': ")[1]
            let addressTmp                       = s1[7].split("': ")[1]    
            let positionTmp                      = s1[8].split("': ")[1]  
            let lastDayOfDutyTmp                 = s1[9].split("': ")[1]           
            let awol1stNoticeDateTmp             = s1[10].split("': ")[1]
            let awol2ndNoticeDateTmp             = s1[11].split("': ")[1]
            let awolNOHDateTmp                   = s1[12].split("': ")[1]           
            let awolTerminationDateTmp           = s1[13].split("': ")[1]
            let terminationDateTmp               = s1[14].split("': ")[1]
            let statusDocumentTmp                = s1[15].split("': ")[1]
            let statusTmp                        = s1[16].split("': ")[1]          
            let isDeletedTmp                     = s1[17].split("': ")[1]
            let isDraftTmp                       = s1[18].split("': ")[1]
            let createdbyTmp                     = s1[19].split("': ")[1]          
            let createddateTmp                   = s1[20].split("': ")[1]
            let isModifiedListTmp                = s1[21].split("': ")[1]
            let modifiedbyListTmp                = s1[22].split("': ")[1]
            let modifieddateListTmp              = s1[23].split("': ")[1]

            idList.push(idClean1.replace("'","").replace("'","").replace(" ",""))
            referenceNoAWOLList.push(referenceNoAWOLTmp.replace("'","").replace("'",""))
               
            clientList.push(clientTmp.replace("'","").replace("'",""))
            createdbyList.push(createdbyTmp.replace("'","").replace("'",""))
            createddateList.push(createddateTmp.replace("'","").replace("'",""))
            lastDayOfDutyList.push(lastDayOfDutyTmp.replace("'","").replace("'",""))
            addressList.push(addressTmp.replace("'","").replace("'",""))
            awol1stNoticeDateList.push(awol1stNoticeDateTmp.replace("'","").replace("'",""))
            awol2ndNoticeDateList.push(awol2ndNoticeDateTmp.replace("'","").replace("'",""))
            awolNOHDateList.push(awolNOHDateTmp.replace("'","").replace("'",""))
            employeeNameList.push(employeeNameTmp.replace("'","").replace("'",""))
            employeeNoList.push(employeeNoTmp.replace("'","").replace("'",""))
            awolTerminationDateList.push(awolTerminationDateTmp.replace("'","").replace("'",""))
            isDeletedList.push(isDeletedTmp.replace("'","").replace("'",""))
            isDraftList.push(isDraftTmp.replace("'","").replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'",""))
            locationList.push(locationTmp.replace("'","").replace("'",""))
            positionList.push(positionTmp.replace("'","").replace("'",""))
            terminationDateList.push(terminationDateTmp.replace("'","").replace("'",""))
            statusList.push(statusTmp.replace("'","").replace("'",""))
            statusDocumentList.push(statusDocumentTmp.replace("'","").replace("'",""))
           
           modifiedbyList.push(modifiedbyListTmp.replace("'","").replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'","").replace("'","").replace("'}",""))
           
            ClientIdList.push(ClientIdTmp.replace("'","").replace("'",""))
            
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                         :   idList[i],
                'referenceNoAWOL'            :   referenceNoAWOLList[i],
              
                'client'                     :   clientList[i],              
                'lastDayOfDuty'              :   lastDayOfDutyList[i],
                'address'                    :   addressList[i],
                'awol1stNoticeDate'          :   awol1stNoticeDateList[i],
                'awol2ndNoticeDate'          :   awol2ndNoticeDateList[i],
                'awolNOHDate'                :   awolNOHDateList[i],
                'employeeName'               :   employeeNameList[i],
                'employeeNo'                 :   employeeNoList[i],
                'awolTerminationDate'        :   awolTerminationDateList[i],              
                'location'                   :   locationList[i],
                'position'                   :   positionList[i],
                'terminationDate'            :   terminationDateList[i],
                'status'                     :   statusList[i],
                'statusDocument'             :   statusDocumentList[i],
               
                'isDraft'                    :   isDraftList[i],
                'isDeleted'                  :   isDeletedList[i],
                'createdby'                  :   createdbyList[i],
                'createddate'                :   createddateList[i],
                'modifiedby'                 :   modifiedbyList[i],
                'modifieddate'               :   modifieddateList[i],
                'isModified'                 :   isModifiedList[i],
              
                'ClientId'                   :   ClientIdList[i],                
               
            
            }
            itemList.push(obj)
            console.log("itemList start")
            console.log(itemList )
            console.log("itemList" )
            
        }
        return itemList
    }

    getClientList(){

        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            //console.log("Client List start ");
            //console.log(res.data);
            //console.log("Client List end");
            const data = res.data
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }

    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.client=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        this.setState({
           // isloading:true,
            isshow:false,
            color:"",
            message:"",
            fade:true ,
            //clientName: e[0].name
        })
       //let clientTmp = e[0].name
       //console.log(clientTmp)
       //this.getEmployees();;
        
    }

    getEmployees(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            console.log("Employee List start ");
            console.log(res.data);
            console.log("Employee List end");
            const data = res.data

            if(data.status=="1")
                this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
            else
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 
                
        })
        .catch(error=>{

           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })

        })
        
    }

    onChangeEmployeesList = (e) => {       

        if(e.length==0)
        {
            this.setState({getEmployeeListData: null, selectedPayrollpayPeriod: '', selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedEmployeeName = e[0].employeeName
        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeNo = e[0].employeeNo
        this.state.selectedEmployeeBranch = e[0].locationName
        this.state.selectedPosition = e[0].position
        
        console.log("e[0]")
        console.log(e[0])
        console.log("e[0]")
        console.log("Work selectedEmployeeName   " + this.state.selectedEmployeeNo );
        console.log("Employee List start ");
        console.log(this.state.selectedEmployeeBranch);
        console.log("Employee List end");

        this.setState({isloading:false})

    }

    handleModalClose = (e) =>{
        this.setState({modalTrainingRegisterShow: false})
    }

    GridDataModified(oldValue, newValue, id, column) {
        //////console.log(id)
        this.state.detailsOfED.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }

    getMonitoring =()=>{
       
       let filter_data ={}

        const getParams ={
            "_collection" : "NTE",
            "filter_data" : filter_data
        }

        console.log("axios start")

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data
            console.log("Raw Json111")
            console.log(data)
            console.log("End Raw Json111")
            var obj = (data);
            var res = [];
            
            data.NTE.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                res.push(json)
            })

            res.map(function(d){
                d.id = res.length++
                d.action = "1"
            })


            this.setState({
                detailsOfED: res,
                isloading:false
            })
            
        })
        .catch(error=>{
            console.log("error: " + error)
        })
    }
    
    handleExportCVS=(e,props)=>{
       
        props.onExport();
        
    }

    onChangeparticular = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedparticular     :   '',
                selectedparticularId   :   ''
            })
            return
        }
        this.state.selectedparticular = e[0].name
        this.state.selectedparticularId = e[0].id
       
        this.setState({
            isshow   :   false,
           selectedparticular : e[0].name

        })
        //console.log(this.state.selectedparticular)
        this.getMemorandumListByStatus();
    }

    nextProcess=(row)=>{
        console.log(row)
        sessionStorage.setItem("AWOLDATA", JSON.stringify(row))
        let nextProcessCheck = "1"
        sessionStorage.setItem("nextProcessAwolCheck", JSON.stringify(nextProcessCheck)) 

        if(row.statusDocument === "AWOL1ST" || row.statusDocument == "AWOL1ST" ){
            window.location.href='/Awol2ndNotice'
        }       
        if(row.statusDocument === "AWOL2ND" || row.statusDocument == "AWOL2ND" ){
            window.location.href='/AwolNOH'
        }
        if(row.statusDocument === "AWOLNOH" || row.statusDocument == "AWOLNOH" ){
            window.location.href='/AwolTermination'
        }

        
    }

    handleClickSearch=()=>{
     
        this.setState({  isloading:true })

        let filter_data ={'ClientId' : this.state.selectedClientId}

        const getParams ={
            "_collection" : "AWOLMonitoring",
            "filter_data" : filter_data
        }

        console.log("axios start")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data

            if(data.AWOLMonitoring.length > 0){

                const itemUsersDataLists = this.buildListAWOLMonitoring(data["AWOLMonitoring"])
            
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                        
                    let obj = {

                        'id'                        :   this.state.itemUserDataList[i]['id'],
                        'referenceNoAWOL'           :   this.state.itemUserDataList[i]['referenceNoAWOL'],
                        'employeeName'              :   this.state.itemUserDataList[i]['employeeName'],
                        'employeeNo'                :   this.state.itemUserDataList[i]['employeeNo'],
                       
                        'client'                    :   this.state.itemUserDataList[i]['client'],   
                        'ClientId'                  :   this.state.itemUserDataList[i]['ClientId'],   
                        'location'                  :   this.state.itemUserDataList[i]['location'],          
                        'address'                   :   this.state.itemUserDataList[i]['address'],
                        'position'                  :   this.state.itemUserDataList[i]['position'],
                        'lastDayOfDuty'             :   this.state.itemUserDataList[i]['lastDayOfDuty'],
                        'awol1stNoticeDate'                 :   this.state.itemUserDataList[i]['awol1stNoticeDate'],
                        'awol2ndNoticeDate'                 :   this.state.itemUserDataList[i]['awol2ndNoticeDate'],
                        'awolNOHDate'               :   this.state.itemUserDataList[i]['awolNOHDate'],
                       
                        'awolTerminationDate'       :   this.state.itemUserDataList[i]['awolTerminationDate'],              
                        'terminationDate'           :   this.state.itemUserDataList[i]['terminationDate'],
                        'statusDocument'            :   this.state.itemUserDataList[i]['statusDocument'],
                        'status'                    :   this.state.itemUserDataList[i]['status'],
                        
                        'isDraft'                   :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'                 :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'                 :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'               :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'                :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'              :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'                :   this.state.itemUserDataList[i]['isModified'],

                        'action'                    :   "1",

                    }

                    List.push(obj)

                }

                this.setState({
                    detailsOfED: List,
                    isloading:false
                })
                
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleClickRefresh=()=>{
        this.setState({  isloading:true })

        let filter_data ={}

        const getParams ={
            "_collection" : "AWOLMonitoring",
            "filter_data" : filter_data
        }

        console.log("axios start")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data

            if(data.AWOLMonitoring.length > 0){

                const itemUsersDataLists = this.buildListAWOLMonitoring(data["AWOLMonitoring"])
            
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                        
                    let obj = {

                        'id'                        :   this.state.itemUserDataList[i]['id'],
                        'referenceNoAWOL'           :   this.state.itemUserDataList[i]['referenceNoAWOL'],
                        'employeeName'              :   this.state.itemUserDataList[i]['employeeName'],
                        'employeeNo'                :   this.state.itemUserDataList[i]['employeeNo'],
                       
                        'client'                    :   this.state.itemUserDataList[i]['client'],   
                        'ClientId'                  :   this.state.itemUserDataList[i]['ClientId'],   
                        'location'                  :   this.state.itemUserDataList[i]['location'],          
                        'address'                   :   this.state.itemUserDataList[i]['address'],
                        'position'                  :   this.state.itemUserDataList[i]['position'],
                        'lastDayOfDuty'             :   this.state.itemUserDataList[i]['lastDayOfDuty'],
                        'awol1stNoticeDate'                 :   this.state.itemUserDataList[i]['awol1stNoticeDate'],
                        'awol2ndNoticeDate'                 :   this.state.itemUserDataList[i]['awol2ndNoticeDate'],
                        'awolNOHDate'               :   this.state.itemUserDataList[i]['awolNOHDate'],
                       
                        'awolTerminationDate'       :   this.state.itemUserDataList[i]['awolTerminationDate'],              
                        'terminationDate'           :   this.state.itemUserDataList[i]['terminationDate'],
                        'statusDocument'            :   this.state.itemUserDataList[i]['statusDocument'],
                        'status'                    :   this.state.itemUserDataList[i]['status'],
                        
                        'isDraft'                   :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'                 :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'                 :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'               :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'                :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'              :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'                :   this.state.itemUserDataList[i]['isModified'],

                        'action'                    :   "1",

                    }

                    List.push(obj)

                }

                this.setState({
                    detailsOfED: List,
                    isloading:false
                })
                
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }


    render() {
        const { ExportCSVButton } = CSVExport;
        
        const statusColumn = [
            
            {
                dataField   : 'status',
                text:'Status',
                headerStyle : () => {
                    return { minWidth  : "865px"};
                }
            },
            {
                dataField: 'count',
                text: 'Count',
                headerStyle : () => {
                    return { minWidth  : "870px" };
                }
            },
           /*  {
                dataField   : 'persentage',
                text        : '%',
                headerStyle : () => {
                    return { width  : "33.3333333333%" };
                }
            }, */
            /*{
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={this.showTrainingRegisterModal}
                        >View Details</Button>
                    );
                },
            }, */
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.statusTableList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };

        const employeeDetailColumn = [

            {
                dataField   : 'employeeName',
                text        : 'Employee Name',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },            
            {
                dataField   : 'client',
                text:'Client',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            }, 
            {
                dataField   : 'referenceNoAWOL',
                text        : 'AWOL REFERENCE NUMBER',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'address',
                text        : 'ADDRESS',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },             
            {
                dataField   : 'lastDayOfDuty',
                text        : 'LAST DAY OF DUTY',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },            
            
            {
                dataField   : 'awol1stNoticeDate',
                text        : 'DATE AWOL NOTICE 1',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'awol2ndNoticeDate',
                text        : 'DATE AWOL NOTICE 2',
                headerStyle : () => {
                    return { minWidth  : "350px"};
                }
            },            
            {
                dataField   : 'awolNOHDate',
                text        : 'DATE AWOL NOH',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'awolTerminationDate',
                text        : 'DATE AWOL TERMINATION',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },

            {
                dataField   : 'terminationDate',
                text        : 'TERMINATION DATE',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'status',
                text        : 'STATUS',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'statusDocument',
                text        : 'Status of Document',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (cell)
                    return (
                        
                        <ButtonToolbar className="mt-1">                                               
                           
                            <Button variant="Link" onClick={(e)=> this.nextProcess(row)} style={{color: "#1c6dfe"}}>process</Button>&nbsp;&nbsp;
                            
                        </ButtonToolbar>  
                    );
                },
            },
        ]

        const selectemployeeDetailRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.detailsOfED.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEmployeeDetailEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
       
        
    return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header> AWOL MONITORING </Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>

                                {/* <Card.Header>SUMMARY</Card.Header>                                
                                
                                <Form.Group as={Row} controlId="formPlaintextEmail"> </Form.Group>

                                <ToolkitProvider
                                   
                                    striped
                                    hover
                                    condensed
                                    rowClasses="noser-table-row-class"
                                    rowEvents={ rowEvents }
                                    selectRow = { selectRow }
                                    keyField="id"   
                                    data={ this.state.statusTableList }
                                    columns={ statusColumn }
                                    exportCSV={ {
                                        fileName: "Dole Cases Monitoring Summary.csv",
                                    } }
                                >
                                    {
                                        props => (
                                            <div className="mt-1" >
                                                <hr />
                                                <BootstrapTable { ...props.baseProps } />
                                                <ButtonToolbar>
                                                    <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export Summary</ExportCSVButton>
                                                </ButtonToolbar>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider> */}

                                <Card.Header className="mt-3">REPORT FILTERING</Card.Header>

                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Client
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeClientList}
                                            options={this.state.getClientList}
                                            placeholder="Select Client"
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar>
                                    <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleClickSearch}>
                                        Search
                                    </Button> 
                                    <Button variant="primary" style={{minWidth:'60px', marginLeft:"3px"}} onClick={this.handleClickRefresh}>
                                        refresh
                                    </Button> 
                                </ButtonToolbar>
                               

                               {/*  <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Status
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeparticular}
                                            options={this.state.particularList}
                                            //placeholder="select penalty type"
                                        />
                                    </Col>
                                </Form.Group> */}
                                
                                <ToolkitProvider
                                    keyField="id"   
                                    data={ this.state.detailsOfED }
                                    columns={ employeeDetailColumn }
                                    exportCSV={ {
                                        fileName: "Dole Cases Monitoring.csv",
                                    } }
                                >
                                    {
                                        props => (
                                            <div>
                                                <hr />
                                                <BootstrapTable { ...props.baseProps } />
                                                <ButtonToolbar>
                                                    <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export Report</ExportCSVButton>                                                       
                                                    {/* <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleUpdateClick}>
                                                        Save
                                                    </Button> */}&nbsp;&nbsp;
                                                    <NavLink to="/home">
                                                        <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                                            Close
                                                        </Button>
                                                    </NavLink>
                                                </ButtonToolbar>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                            </Form>
                        </Card.Body>
                    </Card>                    
                </Container>
                <NoserLoading show={this.state.isloading} />
                  
            </div> 
        )
    }

}

export  default AwolMontoring 
