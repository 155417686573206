import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

class PoolingModal extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            name        :   '',
            disableBtn  :   true,

            clientDDL   :   [],
            positionDDL :   [],

            poolingId   :   "",
            applicationFormId:"",
            clientId    :   "",
            clientName  :   "",
            positionId  :   "",
            positionName:   "",
            employeeName:   "",
            referenceNo :   "",
            DateApplied :   "",
            age         :   "",
            btnLabel    :   "RE-PROCESS"
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        console.log(e)
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            name        :   '',
            disableBtn  :   false,

            clientDDL   :   [],
            positionDDL :   [],

            poolingId   :   e.poolingId,
            applicationFormId:e.id,
            clientId    :   e.clientId,
            clientName  :   e.client,
            positionId  :   e.positionId,
            positionName:   e.position,
            employeeName:   e.employeeName,
            referenceNo :   e.applicationFormNo,
            dateApplied :   e.dateApplied,
            age         :   e.age,
            statusId    :   e.statusId,
            btnLabel    :   e.statusId==="12" ? "CHANGE STATUS TO POOL" : "RE-PROCESS"
        })
        this.GetClients()
    }
    GetClients = async()=> {
        this.setState({isloading:true})
        const clientParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  clientParams)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL:data.clients});
            this.GetPositions()
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    GetPositions = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId"     :   "",
            "DepartmentId"  :   "",
            "Name"          :   ""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  params)
        .then(res => {
            const data = res.data;
            this.setState({positionDDL:data.positions,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangedClient = e => {
        //this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.clientId=''
            this.state.clientName=''
            return
        }
        this.state.clientId=e[0].id
        this.state.clientName=e[0].name
    }
    handleChangedPosition = (e) =>{
        //this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.positionId=""
            this.state.positionName=""
            return
        }
        this.state.positionId=e[0].id
        this.state.positionName=e[0].name
    }
    handleSubmit = async (StatusId) =>{
        this.setState({isloading:true,disableBtn:true})

        if(this.state.clientName==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client name.",
                fade        :   true
            })
            return 
        }
        if(this.state.positionName==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select position name.",
                fade        :   true
            })
            return 
        }
        const param = {
            "PoolingId":this.state.poolingId,

            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ApplicationFormId":this.state.applicationFormId,
            "PositionId":this.state.positionId,
            "StatusId": this.state.statusId==="12" ? "10" : "20" , /* --> blacklisted - 12, process - 3  */
        }
        
        await axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Pooling/SubmitPooling",  param
            )
            .then(res => {
                const data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true,
                    name        :   ''
                });
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true,
                    name        :   ''
                })
            })
    }
    handleModalClose = () => {
        this.props.onHide();            
    }
    
    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton className="card-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        RECRUITMENT >> POOLING
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                CLIENT
                            </Form.Label>
                            <Col sm="5">
                                <Typeahead
                                    id="clientId"
                                    name="clientId"
                                    labelKey='name'
                                    onChange={this.handleChangedClient}
                                    options={this.state.clientDDL}
                                    selected={[this.state.clientName]}
                                />
                            </Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                FULL NAME
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control 
                                    id="employeeName"
                                    name="employeeName"
                                    type="text" 
                                    value={this.state.employeeName}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                POSITION
                            </Form.Label>
                            <Col sm="5">
                                <Typeahead
                                    id="positionId"
                                    name="positionId"
                                    labelKey='name'
                                    onChange={this.handleChangedPosition}
                                    options={this.state.positionDDL}
                                    disabled={this.state.disable}
                                    selected={[this.state.positionName]}
                                />
                            </Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                REFERENCE NO
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control 
                                    id="referenceNo"
                                    name="referenceNo"
                                    type="text" 
                                    value={this.state.referenceNo}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                DATE APPLIED
                            </Form.Label>
                            <Col sm="5">
                                <Form.Control 
                                    id="dateApplied"
                                    name="dateApplied"
                                    type="text" 
                                    value={this.state.dateApplied}
                                    disabled
                                />
                            </Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                AGE
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control 
                                    id="age"
                                    name="age"
                                    type="text" 
                                    value={this.state.age}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = {() => this.handleSubmit('2')} disabled={this.state.disableBtn}>{this.state.btnLabel}</Button>
                        <Button variant="danger" className="noser-button-mr15" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default PoolingModal