import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';

import CreateJobSource from './Modal/CreateJobSource';

class JobSource extends Component {
    state = {
        userinfo        :   [],
        isloading       :   false,
        isshow          :   false,
        alerttype       :   "",
        message         :   "",
        color           :   "",
        fade            :   true,
        disableBtn      :   true,
        openModal       :   false,
        jobsourceGrid   :   [],
        jobsourcetDDL   :   [],
        jobsourceLst    :   [],
        name            :   "",
        yesNoDDL        :   [
            {"value" : "0", "label" : "NO"},
            {"value" : "1", "label" : "YES"}
        ]      
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetJobSource()
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.GetJobSource()
    }
    GetJobSource = async()=> {
        this.setState({isloading:true,disableBtn:true});
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":this.state.name
        };
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetJobOpenings",  params)
        .then(res => {
             const data = res.data
             console.log(data)
            if(data.status=="1"){
                this.setState({
                    jobsourceLst:data.jobOpenings,
                    jobsourcetDDL:data.jobOpenings,
                    jobsourceGrid:data.jobOpenings,
                    isloading:false
                });
            }
         })
         .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    handleChanged = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length===0)
        {
            this.state.name= ''
            return
        }
        this.state.name = e[0].name
    }
    handleSearchClick = event => {
        this.setState({isloading:true,disableBtn:true});
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":this.state.name
        };
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetJobOpenings",  params)
        .then(res => {
            const data = res.data
            if(data.status=="1"){
                this.setState({
                    isloading     :   false,
                    jobsourceGrid:   data.jobOpenings,
                });
            }
         })
         .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }

    handleSaveClick = event => {
        this.setState({isloading:true,disableBtn:true});
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "jobOpenings":this.state.jobsourceGrid.filter(x=>x.isModified=="1")
        };

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditJobOpening", params)
            .then(res => {
                const data = res.data;
                if(data.status=="1"){
                    this.GetJobSource()
                }
                this.setState({
                    isloading   :   false,
                    alerttype   :   data.status=="1" ? "Success!" : "Error!",
                    isshow      :   true,
                    color       :   data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true,
                    disableBtn:true
                });
             })
             .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true,
                })
            })
    }
    
    GridDataModified(oldValue, newValue, id, column) {
        let isDisable = true
        this.state.jobsourceGrid.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            if(item.isModified=="1")
                isDisable = false
        })
        this.setState({disableBtn:isDisable})
    }

    render() {
        const cols = [
            {
                dataField: 'name',
                text: 'Job Source',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%' }},
                style:{textAlign:'left',width:'20%'},
                
            },
            {
                dataField: 'hasReferral',
                text: 'HAS REFERRAL',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'80%' }},
                style:{textAlign:'left',width:'80%'},
                formatter: (cell, row) => {
                    if(row.hasReferral!='' && row.hasReferral!=null){
                        return this.state.yesNoDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.yesNoDDL
                }
            },
            
        ]
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.jobsourceGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableBtn:isDisable})
            }
        };
        const rowStyle = (row, rowIndex) => {
            if(row.name==="NCR")
            return {
                classes: "noser-table-row-class no-checkbox"
            }
            else
            return {
                classes: "noser-table-row-class"
            }
        };
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
        }
    };

    return(
            <div>
                <Banner />
                    <Container className="themed-container" fluid={true}>
                        <Card className="mt-5">
                            <Card.Header>COMMON MAINTNENANCE >> SOURCING CHANNEL</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Col sm={12}>
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChanged}
                                                options={this.state.jobsourcetDDL}
                                                placeholder="Select Job Source"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Col sm={12}>
                                            <ButtonToolbar>
                                                <Button variant="primary" className="ml-auto noser-button" onClick={ this.handleSearchClick }>Search</Button>
                                            </ButtonToolbar>
                                        </Col>
                                    </Form.Group>
                                    <div className="mt-1">
                                    <Card.Header>LIST OF SOURCING CHANNEL</Card.Header>
                                        <BootstrapTable
                                            keyField = "id"
                                            data = { this.state.jobsourceGrid }
                                            columns = { cols }
                                            selectRow = { selectRow }
                                            cellEdit = { cellEditFactory({ 
                                                    mode: 'dbclick', 
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => { 
                                                        this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }
                                            striped
                                            hover
                                            condensed
                                            loading={true}
                                            wrapperClasses="table-responsive"
                                            pagination={ paginationFactory({sizePerPageRenderer})}
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            rowStyle={rowStyle}
                                        />
                                        <ButtonToolbar sm={12}>
                                            <Button variant="success" className="ml-auto noser-button-mr1" disabled={this.state.disableBtn} onClick={this.handleSaveClick}>Save</Button>
                                            <Button variant="primary" className="noser-button" onClick={this.handleModalShow}>CREATE</Button>
                                        </ButtonToolbar>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                <NoserLoading show={this.state.isloading} />
                <CreateJobSource
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div> 
        )
    }

}

export  default JobSource 