import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer
} 
from '../../../noser-hris-component';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

class GenerateSchedule extends Component {

    constructor() {
        super()
        this.state = {
            isLoading:false,loadingText:"",
            alertType:"",
            show:false,
            message:"",
            color:"",
            fade:true,
            clientList:[],
            payperiodList:[],
            selectedpayPeriodId:"",
            selectedClientId: "",
            userinfo:[],
            noscheduleList:[]
        }
    }
    componentDidMount() {
        this.setState({isLoading:true,loadingText:"Loading client list..."})
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();
    }
    onChangePayPeriod = (e) => {
        this.setState(
            {
                isLoading:false,
                alertType:"",
                show:false,
                color:"",
                message:"",
                fade:false
            });
        if(e.length == 0) {
            this.state.selectedpayPeriodId = ""
            return
        } 

        this.state.selectedpayPeriodId = e[0].periodId
    }
    GetPayPeriods() {
        const periodListParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":'0',
            "UserId":this.state.userinfo.userId,
            "EmployeeId":"",
            "IsProcessed":""
         };
 
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollPeriods",  periodListParams
            )
            .then(res => {
                const data = res.data;
                console.log("Get Payroll Periods")
                console.log(data.payrollPeriods)
                this.setState({payperiodList: data.payrollPeriods})
                //console.log("data.employees list count: " + this.state.employeeList.length)
            })
    }
    getClientList(){
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            console.log(res.data)
            this.setState(
                {
                    isLoading:false,
                    clientList : res.data.clients ? res.data.clients : []
                });
        })
        .catch(error=>{
            this.setState(
            { 
                isLoading:false,
                alertType:"Error! ", 
                show:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })  
        })
    }
    onChangeClientList = (e) => {
        this.setState(
            {
                isLoading:false,
                alertType:"",
                show:false,
                color:"",
                message:"",
                fade:false
            });
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        this.GetPayPeriods()
    }
   
    handleGenerateClick = event => {
        this.setState({isLoading:true,loadingText:"Please wait..."})

        const generateParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "PayPeriodId": this.state.selectedpayPeriodId
        };
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GenerateWorkSchedules",  generateParams)
        .then(res => {
            const data = res.data;
            console.log(data)
                console.log(res)
                var alertType = (data.status=="1") ? "success" : "danger"
                this.setState(
                    {
                        isLoading:false,
                        alertType:(data.status=="1") ? "Success! " : "Error! ",
                        show:true,
                        color:alertType,
                        message:data.message ,
                        fade:true,
                        noscheduleList:data.employees
                    });
            })
            .catch(error=>{
            this.setState(
                {
                isLoading:false,
                alertType:"Error! ",
                show:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })
        })

    }
    render() {
        const columns = [
            {
                dataField   :   'employeeNo',
                text        :   'EMPLOYEE NO',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'left',width  : "15%",fontSize:'10px' };
                  },
                style:{textAlign:'left',fontSize:'10px'}
            },
            {
                dataField   :   'employeeName',
                text        :   'EMPLOYEE NAME',
                editable    :    false,
                headerStyle : () => {
                    return { width  : "20%",fontSize:'10px' };
                  },
                  style:{fontSize:'10px'}
            },
            {
                dataField   :   'remarks',
                text        :   'REMARKS',
                editable    :    false,
                headerStyle : () => {
                    return { width  : "65%",fontSize:'10px' };
                  },
                  style:{fontSize:'11px'}
            },
        ]
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {}
        };
        const { ExportCSVButton } = CSVExport;
        return(
            <div className="mt-5">
            <Banner />
                <Container className="mt-5">
                    <Card>
                        <Card.Header>Timekeeping >> Generate Manual Schedules</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.show} color={this.state.color} fade={this.state.fade} className={this.state.show ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.onChangeClientList}
                                        options={this.state.clientList}
                                        placeholder="Select Client"
                                    />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='payPeriod'
                                            id="basic-example"
                                            onChange={this.onChangePayPeriod}
                                            options={this.state.payperiodList}
                                            placeholder="Select Pay Period"
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar sm={12}>
                                    <Button variant="success" style={{minWidth:'60px'}}  className="ml-auto" onClick = { this.handleGenerateClick }>
                                        Generate
                                    </Button>&nbsp;&nbsp;
                                    <Button style={{minWidth:'60px'}} variant="danger" href="/banner"  >
                                        Close
                                    </Button>
                                </ButtonToolbar>
                                <div mt="2">
                                    <ToolkitProvider
                                        keyField="id"   
                                        data={ this.state.noscheduleList }
                                        columns = { columns }
                                        exportCSV={{fileName: "NoSchedules.csv",}}
                                        >
                                        {
                                            props => (
                                                    <div className="mt-2">
                                                        <hr />
                                                        <ExportCSVButton style={{fontSize:'10px'}} className="btn btn-success" { ...props.csvProps }>Export CSV!!</ExportCSVButton>
                                                        <BootstrapTable
                                                            rowClasses="noser-table-row-class"
                                                            striped
                                                            hover
                                                            condensed
                                                            pagination={ paginationFactory({sizePerPage:10,hideSizePerPage:true,hidePageListOnlyOnePage:true})}
                                                            keyField = "id"
                                                            data = { this.state.noscheduleList }
                                                            columns = { columns}
                                                            selectRow = { selectRow }
                                                        />
                                                    </div>
                                        )}
                                    </ToolkitProvider>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isLoading} text={this.state.loadingText}/>
            </div>
        )
    }

}

export  default GenerateSchedule