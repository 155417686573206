import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { tsNullKeyword } from '@babel/types';

 
class EmployeeDiciplineMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            statusTableList                     :   [],
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalTrainingRegisterShow           :   false,          
            
            detailsOfED                  :   [],
            particularList: [
                {
                    "id" : "Closed",
                    "name" : "Closed"
                },
                {
                    "id" : "Pending",
                    "name" : "Pending"
                },
                
               
            ],
            selectedparticular      :   '',
            selectedparticularId    :   '',
            countStatus             :'Pending',
            pendingsList            :[],
            closedList              :[],
            fliterClose             :'Closed',
            statusPendingList       :[],
            statusClosedList        :[],
            totalStatusList         :[],
            totalDoleCasesList      :[], 
            totalClosedCount        :[],  

            getClientList           :   [],
            selectedClientId        :   '',
            getEmployeeList         :   [],
            selectedClientName      :   '',
            selectedEmployeeName    :   '',
            selectedEmployeeNo      :   '',
            selectedEmployeeBranch  :   '',
            selectedPosition        :   '',
            ViewList:[
                {
                    "id":"1",
                    "label":"IR",
                    "value":"IR"
                },
                {
                    "id":"1",
                    "label":"NTE",
                    "value":"NTE"
                },
                {
                    "id":"1",
                    "label":"NOH",
                    "value":"NOH"
                },
                {
                    "id":"1",
                    "label":"NOD",
                    "value":"NOD"
                },
                {
                    "id":"1",
                    "label":"RecordNODPenalty",
                    "value":"RecordNODPenalty"
                },
            ],
            recordNODObjId:"",

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}        
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getEDMonitoring();
        this.getClientList();      
        
        
        
    }

    getEDMonitoring =()=>{

        let filter_data ={}

        const getParams ={
            "_collection" : "EDMonitoring",
            "filter_data" : filter_data
        }

        console.log("axios start")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data

            if(data.EDMonitoring.length > 0){

                const itemUsersDataLists = this.buildListEDMonitoring(data["EDMonitoring"])
            
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                        
                    let obj = {

                        'id'                        :   this.state.itemUserDataList[i]['id'],
                        'quotedStatement'           :   this.state.itemUserDataList[i]['quotedStatement'],
                        'dateOfNTE'                 :   this.state.itemUserDataList[i]['dateOfNTE'],
                        'client'                    :   this.state.itemUserDataList[i]['client'],              
                        'cycleOfDays'               :   this.state.itemUserDataList[i]['cycleOfDays'],
                        'dateOfIncident'            :   this.state.itemUserDataList[i]['dateOfIncident'],
                        'dateOfNOD'                 :   this.state.itemUserDataList[i]['dateOfNOD'],
                        'dateOfNOH'                 :   this.state.itemUserDataList[i]['dateOfNOH'],
                        'dateRecordNodaPenalty'     :   this.state.itemUserDataList[i]['dateRecordNodaPenalty'],
                        'employeeName'              :   this.state.itemUserDataList[i]['employeeName'],
                        'employeeNo'                :   this.state.itemUserDataList[i]['employeeNo'],
                        'irReferenceNo'             :   this.state.itemUserDataList[i]['irReferenceNo'],              
                        'location'                  :   this.state.itemUserDataList[i]['location'],
                        'position'                  :   this.state.itemUserDataList[i]['position'],
                        'retailgroup'               :   this.state.itemUserDataList[i]['retailgroup'],
                        'status'                    :   this.state.itemUserDataList[i]['status'],
                        'article'                   :   this.state.itemUserDataList[i]['article'],
                        'category'                  :   this.state.itemUserDataList[i]['category'],
                        'section'                   :   this.state.itemUserDataList[i]['section'],
                        'isDraft'                   :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'                 :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'                 :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'               :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'                :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'              :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'                :   this.state.itemUserDataList[i]['isModified'],
                        'NTEReferenceNo'            :   this.state.itemUserDataList[i]['NTEReferenceNo'],
                        'NODReferenceNo'            :   this.state.itemUserDataList[i]['NODReferenceNo'],
                        'NOHReferenceNo'            :   this.state.itemUserDataList[i]['NOHReferenceNo'],
                        'ClientId'                  :   this.state.itemUserDataList[i]['ClientId'],  
                        "NTHoffense"                :   this.state.itemUserDataList[i]['NTHoffense'],  
                        "penalty"                   :   this.state.itemUserDataList[i]['penalty'],  
                        "monetaryCharge"            :   this.state.itemUserDataList[i]['monetaryCharge'],  
                        "statusOfIssue"             :   this.state.itemUserDataList[i]['statusOfIssue'], 
                        'action'                    :   this.state.itemUserDataList[i]['status'] == "RecordNODPenalty" ? "":"process",
                        'selectView'                    :   "",
                        'buttonView'                    :   "1",

                    }

                    List.push(obj)

                }

                this.setState({
                    detailsOfED: List,
                    isloading:false
                })
                
            }else{

                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })

            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    } 

    buildListEDMonitoring(data) {
        // console.log("data start")
        // console.log(data )
        console.log("data start")
        console.log(data )
        console.log("data" )
        let itemList =[]
    
        let idList                      =[]
        let quotedStatementList         =[]
        let dateOfNTEList               =[]
        let clientList                  =[]
        let createdbyList               =[]
        let createddateList             =[]
        let cycleOfDaysList             =[]
        let dateOfIncidentList          =[]
        let dateOfNODList               =[]
        let dateOfNOHList               =[]
        let dateRecordNodaPenaltyList   =[]
        let employeeNameList            =[]
        let employeeNoList              =[]
        let irReferenceNoList           =[]
        let isDeletedList               =[]
        let isDraftList                 =[]
        let isModifiedList              =[]
        let locationList                =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let positionList                =[]
        let retailgroupList             =[]
        let statusList                  =[]
        let articleList                 =[]
        let categoryList                =[]
        let sectionList                 =[]
        let NTEReferenceNoList                 =[]
        let NODReferenceNoList                 =[]
        let NOHReferenceNoList                 =[]
        let ClientIdList                 =[]

        let NTHoffenseList                 =[]
        let penaltyList                 =[]
        let monetaryChargeList                 =[]
        let statusOfIssueList                 =[]

        for (let i = 0; i < data.length; i++) {            
    
            let s1 = data[i].split("', ")
            console.log("s1 start")
            console.log(s1 )
            console.log("s1" )
          
            let IdClean0                            = s1[0].split("': ")[1]           
            let idClean1                            = IdClean0.replace("ObjectId(","").replace(")","").replace("{","").replace(", 'action","")  
            let quotedStatementTmp                  = s1[1].split("': ")[1]    
            let dateOfNTETmp                        = s1[2].split("': ")[1]           
            let clientTmp                        = s1[3].split("': ")[1]        
            let createdbyTmp                     = s1[4].split("': ")[1]          
            let createddateTmp                   = s1[5].split("': ")[1]
            let cycleOfDaysTmp                   = s1[6].split("': ")[1]
            let dateOfIncidentTmp                = s1[7].split("': ")[1]
            let dateOfNODTmp                     = s1[8].split("': ")[1]
            let dateOfNOHTmp                     = s1[9].split("': ")[1]
            let dateRecordNodaPenaltyTmp         = s1[10].split("': ")[1]
            let employeeNameTmp                  = s1[11].split("': ")[1]
            let employeeNoTmp                    = s1[12].split("': ")[1]
            let irReferenceNoTmp                 = s1[13].split("': ")[1]
            let isDeletedTmp                     = s1[14].split("': ")[1]
            let isDraftTmp                       = s1[15].split("': ")[1]
            let isModifiedListTmp                = s1[16].split("': ")[1]            
            let locationTmp                      = s1[17].split("': ")[1]
            let modifiedbyListTmp                = s1[18].split("': ")[1]
            let modifieddateListTmp              = s1[19].split("': ")[1]
            let positionTmp                      = s1[20].split("': ")[1]
            let retailgroupTmp                   = s1[21].split("': ")[1]
            let statusTmp                        = s1[22].split("': ")[1]
            let articleTmp                       = s1[23].split("': ")[1]
            let categoryTmp                      = s1[24].split("': ")[1]
            let sectionTmp                       = s1[25].split("': ")[1]
            let NTEReferenceNoTmp                = s1[26].split("': ")[1]
            let NODReferenceNoTmp                = s1[27].split("': ")[1]
            let NOHReferenceNoTmp                = s1[28].split("': ")[1]
            let ClientIdTmp                      = s1[29].split("': ")[1]
            let NTHoffenseTmp                    = s1[30].split("': ")[1]
            let penaltyTmp                       = s1[31].split("': ")[1]
            let monetaryChargeTmp                = s1[32].split("': ")[1]
            let statusOfIssueTmp                 = s1[33].split("': ")[1]
            console.log("s1[33]")
           /*  console.log(s1[32])
            console.log(s1[28].split("': ")[1])
            console.log(s1[29].split("': ")[1]) */
          

            idList.push(idClean1.replace("'","").replace("'","").replace(" ",""))
            quotedStatementList.push(quotedStatementTmp.replace("'",""))
            dateOfNTEList.push(dateOfNTETmp.replace("'","").replace(" ",""))           
            clientList.push(clientTmp.replace("'",""))
            createdbyList.push(createdbyTmp.replace("'",""))
            createddateList.push(createddateTmp.replace("'",""))
            cycleOfDaysList.push(cycleOfDaysTmp.replace("'",""))
            dateOfIncidentList.push(dateOfIncidentTmp.replace("'",""))
            dateOfNODList.push(dateOfNODTmp.replace("'",""))
            dateOfNOHList.push(dateOfNOHTmp.replace("'",""))
            dateRecordNodaPenaltyList.push(dateRecordNodaPenaltyTmp.replace("'",""))
            employeeNameList.push(employeeNameTmp.replace("'",""))
            employeeNoList.push(employeeNoTmp.replace("'",""))
            irReferenceNoList.push(irReferenceNoTmp.replace("'",""))
            isDeletedList.push(isDeletedTmp.replace("'",""))
            isDraftList.push(isDraftTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'",""))
            locationList.push(locationTmp.replace("'",""))
            positionList.push(positionTmp.replace("'",""))
            retailgroupList.push(retailgroupTmp.replace("'",""))
            statusList.push(statusTmp.replace("'",""))
            articleList.push(articleTmp.replace("'",""))
            categoryList.push(categoryTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            sectionList.push(sectionTmp.replace("'",""))
            NTEReferenceNoList.push(NTEReferenceNoTmp.replace("'",""))
            NODReferenceNoList.push(NODReferenceNoTmp.replace("'",""))
            NOHReferenceNoList.push(NOHReferenceNoTmp.replace("'",""))
            ClientIdList.push(ClientIdTmp.replace("'",""))

            NTHoffenseList.push(NTHoffenseTmp.replace("'",""))
            penaltyList.push(penaltyTmp.replace("'",""))
            monetaryChargeList.push(monetaryChargeTmp.replace("'",""))
            statusOfIssueList.push(statusOfIssueTmp.replace("'","").replace("'}",""))

          
            
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                         :   idList[i],
                'quotedStatement'            :   quotedStatementList[i],
                'dateOfNTE'                  :   dateOfNTEList[i],
                'client'                     :   clientList[i],              
                'cycleOfDays'                :   cycleOfDaysList[i],
                'dateOfIncident'             :   dateOfIncidentList[i],
                'dateOfNOD'                  :   dateOfNODList[i],
                'dateOfNOH'                  :   dateOfNOHList[i],
                'dateRecordNodaPenalty'      :   dateRecordNodaPenaltyList[i],
                'employeeName'               :   employeeNameList[i],
                'employeeNo'                 :   employeeNoList[i],
                'irReferenceNo'              :   irReferenceNoList[i],              
                'location'                   :   locationList[i],
                'position'                   :   positionList[i],
                'retailgroup'                :   retailgroupList[i],
                'status'                     :   statusList[i],
                'article'                    :   articleList[i],
                'category'                   :   categoryList[i],
                'section'                    :   sectionList[i],
                'isDraft'                    :   isDraftList[i],
                'isDeleted'                  :   isDeletedList[i],
                'createdby'                  :   createdbyList[i],
                'createddate'                :   createddateList[i],
                'modifiedby'                 :   modifiedbyList[i],
                'modifieddate'               :   modifieddateList[i],
                'isModified'                 :   isModifiedList[i],
                'NTEReferenceNo'             :   NTEReferenceNoList[i],
                'NODReferenceNo'             :   NODReferenceNoList[i],
                'NOHReferenceNo'             :   NOHReferenceNoList[i],
                'ClientId'                   :   ClientIdList[i],                
                "NTHoffense"                 :   NTHoffenseList[i],
                "penalty"                    :   penaltyList[i],
                "monetaryCharge"             :   monetaryChargeList[i],
                "statusOfIssue"              :   statusOfIssueList[i],
            
            }
            itemList.push(obj)
            console.log("itemList start")
            console.log(itemList )
            console.log("itemList" )
            
        }
        return itemList
    }

    getClientList(){

        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            //console.log("Client List start ");
            //console.log(res.data);
            //console.log("Client List end");
            const data = res.data
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });

        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }

    onChangeClientList = (e) => {

        if(e.length == 0) {
            this.state.client=""
            return
        } 

        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        this.setState({
           // isloading:true,
            isshow:false,
            color:"",
            message:"",
            fade:true ,
            //clientName: e[0].name
        })

       //let clientTmp = e[0].name
       //console.log(clientTmp)
       this.getEmployees();;
        
    }

    getEmployees(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            console.log("Employee List start ");
            console.log(res.data);
            console.log("Employee List end");
            const data = res.data

            if(data.status=="1")
                this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
            else
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 
                
        })
        .catch(error=>{

           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })

        })
        
    }

    onChangeEmployeesList = (e) => {       

        if(e.length==0)
        {
            this.setState({getEmployeeListData: null, selectedPayrollpayPeriod: '', selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedEmployeeName = e[0].employeeName
        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeNo = e[0].employeeNo
        this.state.selectedEmployeeBranch = e[0].locationName
        this.state.selectedPosition = e[0].position
        
        console.log("e[0]")
        console.log(e[0])
        console.log("e[0]")
        console.log("Work selectedEmployeeName   " + this.state.selectedEmployeeNo );
        console.log("Employee List start ");
        console.log(this.state.selectedEmployeeBranch);
        console.log("Employee List end");

        this.setState({isloading:false})

    }

    handleModalClose = (e) =>{
        this.setState({modalTrainingRegisterShow: false})
    }

    GridDataModified(oldValue, newValue, id, column) {
        //////console.log(id)
        this.state.detailsOfED.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }

    getMonitoring =()=>{
       
       let filter_data ={}

        const getParams ={
            "_collection" : "NTE",
            "filter_data" : filter_data
        }

        console.log("axios start")

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data
            console.log("Raw Json111")
            console.log(data)
            console.log("End Raw Json111")
            var obj = (data);
            var res = [];
            
            data.NTE.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                res.push(json)
            })

            res.map(function(d){
                d.id = res.length++
                d.action = "1"
            })


            this.setState({
                detailsOfED: res,
                isloading:false
            })
            
        })
        .catch(error=>{
            console.log("error: " + error)
        })
    }
    
    handleExportCVS=(e,props)=>{
       
        props.onExport();
        
    }

    onChangeparticular = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedparticular     :   '',
                selectedparticularId   :   ''
            })
            return
        }
        this.state.selectedparticular = e[0].name
        this.state.selectedparticularId = e[0].id
       
        this.setState({
            isshow   :   false,
           selectedparticular : e[0].name

        })
        //console.log(this.state.selectedparticular)
        this.getMemorandumListByStatus();
    }

    nextProcess=(row)=>{
        console.log(row)
        sessionStorage.setItem("IRData", JSON.stringify(row))
        let nextProcessCheck = "1"
        sessionStorage.setItem("nextProcessCheck", JSON.stringify(nextProcessCheck)) 

        if(row.status === "IR" || row.status == "IR" ){
            window.location.href='/noticememorandum'
        }
       /*  if(row.status === "NTE" || row.status == "NTE" ){
            window.location.href='/NoticeOfHearing'
        } */
        if(row.status === "NTE" || row.status == "NTE" ){
            window.location.href='/NoticeOfHearing'
        }
        if(row.status === "NOH" || row.status == "NOH" ){
            window.location.href='/NoticeOfDisiplinaryAction'
        }
        if(row.status === "NOD" || row.status == "NOD" ){
            window.location.href='/RecordNodaPenalty'
        }

       /*  let rowtmp = row.id

       */

        
    }

    ViewProcess=(row)=>{
        console.log(row)
        sessionStorage.setItem("IRData", JSON.stringify(row))
        let nextProcessCheck = "2"
        sessionStorage.setItem("nextProcessCheck", JSON.stringify(nextProcessCheck)) 

        
        if(row.status === "IR" || row.status == "IR" ){

            if(row.selectView === "IR" || row.selectView == "IR" ){
                window.location.href='/incidentreportform'
            }

        }

        if(row.status === "NTE" || row.status == "NTE" ){
            if(row.selectView === "IR" || row.selectView == "IR" ){
                window.location.href='/incidentreportform'
            }
            if(row.selectView === "NTE" || row.selectView == "NTE" ){
                window.location.href='/noticememorandum'
            }
        }

        if(row.status === "NOH" || row.status == "NOH" ){
            if(row.selectView === "IR" || row.selectView == "IR" ){
                window.location.href='/incidentreportform'
            }
            if(row.selectView === "NTE" || row.selectView == "NTE" ){
                window.location.href='/noticememorandum'
            }
            if(row.selectView === "NOH" || row.selectView == "NOH" ){
                window.location.href='/NoticeOfHearing'
            }
        }

        if(row.status === "NOD" || row.status == "NOD" ){
            if(row.selectView === "IR" || row.selectView == "IR" ){
                window.location.href='/incidentreportform'
            }
            if(row.selectView === "NTE" || row.selectView == "NTE" ){
                window.location.href='/noticememorandum'
            }
            if(row.selectView === "NOH" || row.selectView == "NOH" ){
                window.location.href='/NoticeOfHearing'
            }
            if(row.selectView === "NOD" || row.selectView == "NOD" ){
                window.location.href='/NoticeOfDisiplinaryAction'
            }
        }

        if(row.status === "RecordNODPenalty" || row.status == "RecordNODPenalty" ){
            if(row.selectView === "IR" || row.selectView == "IR" ){
                window.location.href='/incidentreportform'
            }
            if(row.selectView === "NTE" || row.selectView == "NTE" ){
                window.location.href='/noticememorandum'
            }
            if(row.selectView === "NOH" || row.selectView == "NOH" ){
                window.location.href='/NoticeOfHearing'
            }
            if(row.selectView === "NOD" || row.selectView == "NOD" ){
                window.location.href='/NoticeOfDisiplinaryAction'
            }
            if(row.selectView === "RecordNODPenalty" || row.selectView == "RecordNODPenalty" ){
                window.location.href='/RecordNodaPenalty'
            }
        
        }

        // if(row.selectView === "IR" || row.selectView == "IR" ){
        //     window.location.href='/incidentreportform'
        // }
        // if(row.selectView === "NTE" || row.selectView == "NTE" ){
        //     window.location.href='/noticememorandum'
        // }
        // if(row.selectView === "NOH" || row.selectView == "NOH" ){
        //     window.location.href='/NoticeOfHearing'
        // }
        // if(row.selectView === "NOH" || row.selectView == "NOH" ){
        //     window.location.href='/NoticeOfDisiplinaryAction'
        // }
        // if(row.selectView === "NOD" || row.selectView == "NOD" ){
        //     window.location.href='/RecordNodaPenalty'
        // }

       /*  let rowtmp = row.id

       */

        
    }

    handleClickSearch=()=>{
     
        this.setState({  isloading:true })
        let filter_data ={}
        
        if(this.state.selectedEmployeeName !== ""){
            filter_data ={
                //'ClientId' : this.state.selectedClientId,
                "employeeName": this.state.selectedEmployeeName
            }
        }
        if(this.state.selectedEmployeeName == ""){
            filter_data ={
                'client' : this.state.selectedClientName,
            }
        }

        //let filter_data ={'ClientId' : this.state.selectedClientId & "employeeName": this.state.selectedEmployeeName}

        const getParams ={
            "_collection" : "EDMonitoring",
            "filter_data" : filter_data
        }

        console.log("axios start")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data

            if(data.EDMonitoring.length > 0){

                const itemUsersDataLists = this.buildListEDMonitoring(data["EDMonitoring"])
            
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                        
                    let obj = {

                        'id'                        :   this.state.itemUserDataList[i]['id'],
                        'quotedStatement'           :   this.state.itemUserDataList[i]['quotedStatement'],
                        'dateOfNTE'                 :   this.state.itemUserDataList[i]['dateOfNTE'],
                        'client'                    :   this.state.itemUserDataList[i]['client'],              
                        'cycleOfDays'               :   this.state.itemUserDataList[i]['cycleOfDays'],
                        'dateOfIncident'            :   this.state.itemUserDataList[i]['dateOfIncident'],
                        'dateOfNOD'                 :   this.state.itemUserDataList[i]['dateOfNOD'],
                        'dateOfNOH'                 :   this.state.itemUserDataList[i]['dateOfNOH'],
                        'dateRecordNodaPenalty'     :   this.state.itemUserDataList[i]['dateRecordNodaPenalty'],
                        'employeeName'              :   this.state.itemUserDataList[i]['employeeName'],
                        'employeeNo'                :   this.state.itemUserDataList[i]['employeeNo'],
                        'irReferenceNo'             :   this.state.itemUserDataList[i]['irReferenceNo'],              
                        'location'                  :   this.state.itemUserDataList[i]['location'],
                        'position'                  :   this.state.itemUserDataList[i]['position'],
                        'retailgroup'               :   this.state.itemUserDataList[i]['retailgroup'],
                        'status'                    :   this.state.itemUserDataList[i]['status'],
                        'article'                   :   this.state.itemUserDataList[i]['article'],
                        'category'                  :   this.state.itemUserDataList[i]['category'],
                        'section'                   :   this.state.itemUserDataList[i]['section'],
                        'isDraft'                   :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'                 :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'                 :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'               :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'                :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'              :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'                :   this.state.itemUserDataList[i]['isModified'],
                        'NTEReferenceNo'            :   this.state.itemUserDataList[i]['NTEReferenceNo'],
                        'NODReferenceNo'            :   this.state.itemUserDataList[i]['NODReferenceNo'],
                        'NOHReferenceNo'            :   this.state.itemUserDataList[i]['NOHReferenceNo'],
                        'ClientId'                  :   this.state.itemUserDataList[i]['ClientId'],  
                        "NTHoffense"                :   this.state.itemUserDataList[i]['NTHoffense'],  
                        "penalty"                   :   this.state.itemUserDataList[i]['penalty'],  
                        "monetaryCharge"            :   this.state.itemUserDataList[i]['monetaryCharge'],  
                        "statusOfIssue"             :   this.state.itemUserDataList[i]['statusOfIssue'],  
                        
                        'action'                    :   "1",
                        'selectView'                    :   "",
                        'buttonView'                    :   "1",

                    }

                    List.push(obj)

                }

                this.setState({
                    detailsOfED: List,
                    isloading:false
                })
                
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleClickRefresh=()=>{
        this.setState({  isloading:true })

        let filter_data ={}

        const getParams ={
            "_collection" : "EDMonitoring",
            "filter_data" : filter_data
        }

        console.log("axios start")
        console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data

            if(data.EDMonitoring.length > 0){

                const itemUsersDataLists = this.buildListEDMonitoring(data["EDMonitoring"])
            
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                        
                    let obj = {

                        'id'                        :   this.state.itemUserDataList[i]['id'],
                        'quotedStatement'           :   this.state.itemUserDataList[i]['quotedStatement'],
                        'dateOfNTE'                 :   this.state.itemUserDataList[i]['dateOfNTE'],
                        'client'                    :   this.state.itemUserDataList[i]['client'],              
                        'cycleOfDays'               :   this.state.itemUserDataList[i]['cycleOfDays'],
                        'dateOfIncident'            :   this.state.itemUserDataList[i]['dateOfIncident'],
                        'dateOfNOD'                 :   this.state.itemUserDataList[i]['dateOfNOD'],
                        'dateOfNOH'                 :   this.state.itemUserDataList[i]['dateOfNOH'],
                        'dateRecordNodaPenalty'     :   this.state.itemUserDataList[i]['dateRecordNodaPenalty'],
                        'employeeName'              :   this.state.itemUserDataList[i]['employeeName'],
                        'employeeNo'                :   this.state.itemUserDataList[i]['employeeNo'],
                        'irReferenceNo'             :   this.state.itemUserDataList[i]['irReferenceNo'],              
                        'location'                  :   this.state.itemUserDataList[i]['location'],
                        'position'                  :   this.state.itemUserDataList[i]['position'],
                        'retailgroup'               :   this.state.itemUserDataList[i]['retailgroup'],
                        'status'                    :   this.state.itemUserDataList[i]['status'],
                        'article'                   :   this.state.itemUserDataList[i]['article'],
                        'category'                  :   this.state.itemUserDataList[i]['category'],
                        'section'                   :   this.state.itemUserDataList[i]['section'],
                        'isDraft'                   :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'                 :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'                 :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'               :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'                :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'              :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'                :   this.state.itemUserDataList[i]['isModified'],
                        'NTEReferenceNo'            :   this.state.itemUserDataList[i]['NTEReferenceNo'],
                        'NODReferenceNo'            :   this.state.itemUserDataList[i]['NODReferenceNo'],
                        'NOHReferenceNo'            :   this.state.itemUserDataList[i]['NOHReferenceNo'],
                        'ClientId'                  :   this.state.itemUserDataList[i]['ClientId'],  
                        "NTHoffense"                :   this.state.itemUserDataList[i]['NTHoffense'],  
                        "penalty"                   :   this.state.itemUserDataList[i]['penalty'],  
                        "monetaryCharge"            :   this.state.itemUserDataList[i]['monetaryCharge'],  
                        "statusOfIssue"             :   this.state.itemUserDataList[i]['statusOfIssue'],  
                        
                        'action'                    :   "1",
                        'selectView'                    :   [],
                        'buttonView'                    :   "1",

                    }

                    List.push(obj)

                }

                this.setState({
                    detailsOfED: List,
                    isloading:false
                })
                
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    GridDataModified(oldValue, newValue, id, column) {
        ////console.log(id)
        //let employeeData = this.state.getEmployeeList
        this.state.detailsOfED.map(function(item,i) {
            
            if (item.id===id){
               // checktmp = "1"
                item.isModified = newValue!=oldValue ? "1" : "0"
               
                
            }
               
        })

    }


    render() {
        const { ExportCSVButton } = CSVExport;
        
        const statusColumn = [
            
            {
                dataField   : 'status',
                text:'Status',
                headerStyle : () => {
                    return { minWidth  : "865px"};
                }
            },
            {
                dataField: 'count',
                text: 'Count',
                headerStyle : () => {
                    return { minWidth  : "870px" };
                }
            },
           /*  {
                dataField   : 'persentage',
                text        : '%',
                headerStyle : () => {
                    return { width  : "33.3333333333%" };
                }
            }, */
            /*{
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={this.showTrainingRegisterModal}
                        >View Details</Button>
                    );
                },
            }, */
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.statusTableList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };

        const employeeDetailColumn = [

            {
                dataField   : 'employeeName',
                text        : 'Employee Name',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },            
            {
                dataField   : 'client',
                text:'Client',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            }, 
            {
                dataField   : 'location',
                text        : 'Location',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },             
            {
                dataField   : 'position',
                text        : 'Position',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },            
            {
                dataField   : 'irReferenceNo',
                text        : 'IR Reference No.',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'dateOfIncident',
                text        : 'Date Of Incident Report (Date Reported)',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'quotedStatement',
                text        : 'description Of Case (Quoted Statement)',
                editable    : false,
                headerStyle : () => {
                    return { minWidth  : "350px"};
                }
            },            
            {
                dataField   : 'dateOfNTE',
                text        : 'Date OF NOTICE TO EXPLAIN',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'dateOfNOH',
                text        : 'Date OF NOTICE OF HEARING',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },

            {
                dataField   : 'dateOfNOD',
                text        : 'Date Of Notice Of Decision',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'article',
                text        : 'article',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'section',
                text        : 'section',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'category',
                text        : 'category',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'NTHoffense',
                text        : 'NTH offense',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'penalty',
                text        : 'Penalty',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'monetaryCharge',
                text        : 'Monetary Charge',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'dateRecordNodaPenalty',
                text        : 'Date Record NODA Penalty',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
           
            {
                dataField   : 'retailgroup',
                text        : 'Retail Group',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'cycleOfDays',
                text        : 'cycle Of Days',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'statusOfIssue',
                text        : 'status',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'status',
                text        : 'Document status',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'action',
                text        : 'Action',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (cell)
                    return (
                        
                        <ButtonToolbar className="mt-1">                                               
                           
                            <Button variant="Link" onClick={(e)=> this.nextProcess(row)} style={{color: "#1c6dfe"}}>process</Button>&nbsp;&nbsp;
                            
                        </ButtonToolbar>  
                    );
                },
            },
            {
                dataField   : 'selectView',
                text        : 'Select View',
                editable    : true,
                editor: {
                    type: Type.SELECT,
                    options: this.state.ViewList,
                   
                },
                headerStyle : () => {
                    return { width  : "10%" };
                },
                
            },
            {
                dataField   : 'buttonView',
                text        : 'View/Edit File',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (cell)
                    return (
                        
                        <ButtonToolbar className="mt-1">                                               
                           
                            <Button variant="Link" onClick={(e)=> this.ViewProcess(row)} style={{color: "#1c6dfe"}}>View/Edit</Button>&nbsp;&nbsp;
                            
                        </ButtonToolbar>  
                    );
                },
            },
        ]

        const selectemployeeDetailRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.detailsOfED.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEmployeeDetailEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
       
        
    return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header> EMPLOYEE DISCIPLINE MONITORING </Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>

                                {/* <Card.Header>SUMMARY</Card.Header>                                
                                
                                <Form.Group as={Row} controlId="formPlaintextEmail"> </Form.Group>

                                <ToolkitProvider
                                   
                                    striped
                                    hover
                                    condensed
                                    rowClasses="noser-table-row-class"
                                    rowEvents={ rowEvents }
                                    selectRow = { selectRow }
                                    keyField="id"   
                                    data={ this.state.statusTableList }
                                    columns={ statusColumn }
                                    exportCSV={ {
                                        fileName: "Dole Cases Monitoring Summary.csv",
                                    } }
                                >
                                    {
                                        props => (
                                            <div className="mt-1" >
                                                <hr />
                                                <BootstrapTable { ...props.baseProps } />
                                                <ButtonToolbar>
                                                    <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export Summary</ExportCSVButton>
                                                </ButtonToolbar>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider> */}

                                <Card.Header className="mt-3">REPORT FILTERING</Card.Header>

                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Client
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeClientList}
                                            options={this.state.getClientList}
                                            placeholder="Select Client"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Employee Name
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.onChangeEmployeesList}
                                            options={this.state.getEmployeeList}
                                            placeholder="Select Employee"
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar>
                                    <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleClickSearch}>
                                        Search
                                    </Button> 
                                    <Button variant="primary" style={{minWidth:'60px', marginLeft:"3px"}} onClick={this.handleClickRefresh}>
                                        refresh
                                    </Button> 
                                </ButtonToolbar>
                               

                               {/*  <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Status
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeparticular}
                                            options={this.state.particularList}
                                            //placeholder="select penalty type"
                                        />
                                    </Col>
                                </Form.Group> */}
                                
                                {/* <ToolkitProvider
                                    keyField="id"   
                                    data={ this.state.detailsOfED }
                                    columns={ employeeDetailColumn }
                                    exportCSV={ {
                                        fileName: "Noser.csv",
                                    } }
                                    cellEdit = { cellEditFactory({
                                        mode: 'click',
                                        blurToSave: true,
                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                            this.GridDataModifiedsection(oldValue, newValue, row.id, column.dataField , row)
                                            }
                                        })
                                    }
                                >
                                    {
                                        props => (
                                            <div>
                                                <hr />
                                                <BootstrapTable { ...props.baseProps } />
                                                <ButtonToolbar>
                                                    <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export Report</ExportCSVButton>                                                       
                                                   &nbsp;&nbsp;
                                                    <NavLink to="/home">
                                                        <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                                            Close
                                                        </Button>
                                                    </NavLink>
                                                </ButtonToolbar>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider> */}

                                <BootstrapTable
                                    keyField = "irReferenceNo"
                                    data = { this.state.detailsOfED }
                                    columns = { employeeDetailColumn }
                                    // pagination={ paginationFactory({sizePerPageRenderer}) }
                                    striped
                                    hover
                                    condensed
                                    noDataIndication={ () => <div>No record found.</div> }
                                    cellEdit = { cellEditFactory({
                                        mode: 'click',
                                        blurToSave: true,
                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                            this.GridDataModified(oldValue, newValue, row.id, column.dataField , row)
                                            }
                                        })
                                    }
                                    //defaultSorted={ defaultSorted }
                                    //selectRow={selectemployeeDetailRow}
                                />
                            </Form>
                        </Card.Body>
                    </Card>                    
                </Container>
                <NoserLoading show={this.state.isloading} />
                  
            </div> 
        )
    }

}

export  default EmployeeDiciplineMonitoring 
